import { BusinessDocumentKind } from './business-document-kind'
import { TaxonomyTag } from './taxonomy-tag'

/**
 * Describes the slugs of the business documents.
 * commercial-invoice
 * voiding-invoice
 * proforma-invoice
 * voiding-credit-note
 * commercial-credit-note
 */
export enum BusinessDocumentSlugs {
  COMMERCIAL_INVOICE = 'commercial-invoice',
  CORRECTIVE_INVOICE = 'corrective-invoice',
  PROFORMA_INVOICE = 'proforma-invoice',
  VOIDING_CREDIT_NOTE = 'voiding-credit-note',
  COMMERCIAL_CREDIT_NOTE = 'commercial-credit-note'
}

/**
 * Returns true if the slug is a BusinessDocumentSlugs.
 * used to convert the string from the URI to the enum.
 * @param slug
 */
export function isBusinessDocumentSlugs(slug: string): slug is BusinessDocumentSlugs {
  return Object.values(BusinessDocumentSlugs).includes(slug as BusinessDocumentSlugs)
}

/**
 * Returns the BusinessDocumentKind from the URI param.
 * @param businessDocumentSlug
 */
export function returnDocumentKindFromURIParam(businessDocumentSlug: BusinessDocumentSlugs): BusinessDocumentKind {
  switch (businessDocumentSlug) {
    case BusinessDocumentSlugs.COMMERCIAL_INVOICE:
      return BusinessDocumentKind.INVOICE
    case BusinessDocumentSlugs.CORRECTIVE_INVOICE:
      return BusinessDocumentKind.INVOICE
    case BusinessDocumentSlugs.PROFORMA_INVOICE:
      return BusinessDocumentKind.INVOICE
    case BusinessDocumentSlugs.VOIDING_CREDIT_NOTE:
      return BusinessDocumentKind.CREDITNOTE
    case BusinessDocumentSlugs.COMMERCIAL_CREDIT_NOTE:
      return BusinessDocumentKind.CREDITNOTE
    default:
      return BusinessDocumentKind.INVOICE
  }
}

/**
 * Returns TaxonomyTag[] from the URI param.
 * @param businessDocumentSlug
 */
export function returnTaxonomyTagFromURIParam(businessDocumentSlug: BusinessDocumentSlugs): TaxonomyTag[] {
  switch (businessDocumentSlug) {
    case BusinessDocumentSlugs.COMMERCIAL_INVOICE:
      return [TaxonomyTag.COMMERCIAL_INVOICE]
    case BusinessDocumentSlugs.CORRECTIVE_INVOICE:
      return [TaxonomyTag.CORRECTIVE_INVOICE]
    case BusinessDocumentSlugs.VOIDING_CREDIT_NOTE:
      return [TaxonomyTag.VOIDING_CREDIT_NOTE]
    case BusinessDocumentSlugs.COMMERCIAL_CREDIT_NOTE:
      return [TaxonomyTag.COMMERCIAL_CREDIT_NOTE]
    default:
      return [TaxonomyTag.COMMERCIAL_INVOICE]
  }
}
/**
 * Calls Deep clone on an object.
 * @param obj
 */
export function deepClone(obj) {
  return deepCloneStrategyWithCircularDepMgt20221213(obj)
}


/**
 * Strategy to deep clone an object.
 * @param o
 */
// Reference https://stackoverflow.com/questions/728360/how-do-i-correctly-clone-a-javascript-object#answer-24648941
// Ref on performances https://stackoverflow.com/questions/728360/how-do-i-correctly-clone-a-javascript-object#answer-61523278
function deepCloneStrategyWithCircularDepMgt20221213(o) {
  // function body
  const gdcc = '__getDeepCircularCopy__'

  if (o !== Object(o)) {
    return o // primitive value
  }

  let set = gdcc in o,
    cache = o[gdcc],
    result

  if (set && typeof cache === 'function') {
    return cache()
  }

  // else
  o[gdcc] = function () {
    return result
  } // overwrite

  if (Array.isArray(o)) {
    result = [] as any
    for (let i = 0; i < o.length; i++) {
      result[i] = deepCloneStrategyWithCircularDepMgt20221213(o[i])
    }
  } else {
    result = {} as any
    for (let prop in o) {
      if (prop !== gdcc) {
        result[prop] = deepCloneStrategyWithCircularDepMgt20221213(o[prop])
      } else if (set) {
        result[prop] = deepCloneStrategyWithCircularDepMgt20221213(cache)
      }
    }
  }

  if (set) {
    o[gdcc] = cache // reset
  } else {
    delete o[gdcc] // unset again
  }

  return result
}
/*function deepCloneStrategyWithCircularDepMgt20221213(o) {
    const gdcc = "__getDeepCircularCopy__";
    if (o !== Object(o)) {
        return o; // primitive value
    }

    let set = gdcc in o,
        cache = o[gdcc],
        result;
    if (set && typeof cache == "function") {
        return cache();
    }
    // else
    o[gdcc] = function() { return result; }; // overwrite
    if (o instanceof Array) {
        result = [];
        for (let i=0; i<o.length; i++) {
            result[i] = deepCloneStrategyWithCircularDepMgt20221213(o[i]);
        }
    } else {
        result = {};
        for (let prop in o)
            {
                if (prop !== gdcc)
                    result[prop] = deepCloneStrategyWithCircularDepMgt20221213(o[prop]);
                else if (set)
                    result[prop] = deepCloneStrategyWithCircularDepMgt20221213(cache);
            }
    }
    if (set) {
        o[gdcc] = cache; // reset
    } else {
        delete o[gdcc]; // unset again
    }
    return result;
}*/


// Reference https://stackoverflow.com/questions/728360/how-do-i-correctly-clone-a-javascript-object
// problem of circular dependencies solved by deepCloneStrategyWithCircularDepMgt20221213()
function deepCloneStrategy20221213(obj) {
  // Handle the 3 simple types, and null or undefined
  if (null == obj || 'object' != typeof obj) return obj

  // Handle Date
  if (obj instanceof Date) {
    let copy = new Date()
    copy.setTime(obj.getTime())

    return copy
  }

  // Handle Array
  if (obj instanceof Array) {
    let copy = []
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCloneStrategy20221213(obj[i])
    }

    return copy
  }

  // Handle Object
  if (obj instanceof Object) {
    let copy = {}
    for (let attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = deepCloneStrategy20221213(obj[attr])
    }

    return copy
  }

  throw new Error('Unable to copy obj! Its type isn\'t supported.')
}


function testObjectAssign20221213() {
  let objA = {
    age: 42,
    name: 'Bob',
    family: {
      wife: 'Alice',
      daughter: 'Jane',
      father: undefined,
      mother: null
    }
  }
  if (objA.family.hasOwnProperty('father')) {
    /* console.log('father undefined but present') */
  } else {
    /* console.log('father undefined is lost!') */
  }
  let objB = Object.assign({}, objA)
  let objC = { ...objA }
  let objD = JSON.parse(JSON.stringify(objA, null, 0))
  let objE = deepCloneStrategy20221213(objA)
  let objF = deepCloneStrategyWithCircularDepMgt20221213(objA)
  /* console.log('objA raw', objA) */
  /* console.log('objA', JSON.stringify(objA, null, 3)) */
  /* console.log('objB', JSON.stringify(objB, null, 3)) */
  /* console.log('objC', JSON.stringify(objC, null, 3)) */
  /* console.log('objD', objD) */
  /* console.log('objD', JSON.stringify(objD, null, 3)) */
  /* console.log('objE', objE) */
  /* console.log('objE', JSON.stringify(objE, null, 3)) */
  /* console.log('objF', objF) */
  /* console.log('objF', JSON.stringify(objF, null, 3)) */

  objB.family.daughter = 'Anna'
  objB.age = 40
  objC.family.daughter = 'Charlotte'
  objC.age = 41
  objD.family.daughter = 'Isabelle'
  objD.age = 39
  objE.age = 60
  objE.family.daughter = 'Madeleine'
  objF.age = 55
  objF.family.daughter = 'Sabrina'

  /* console.log('after modification deep and shallow') */
  /* console.log('objA', objA) */
  /* console.log('objA', JSON.stringify(objA, null, 3)) */
  /* console.log('objB', JSON.stringify(objB, null, 3)) */
  /* console.log('objC', JSON.stringify(objC, null, 3)) */
  /* console.log('objD', objD) */
  /* console.log('objD', JSON.stringify(objD, null, 3)) */
  /* console.log('objE', objE) */
  /* console.log('objE', JSON.stringify(objE, null, 3)) */
  /* console.log('objF', objF) */
  /* console.log('objF', JSON.stringify(objF, null, 3)) */
}

import { Severity, remoteDebuggingService } from '$src/core-app/services/remote-debugging.service'
import { Customer } from '../../models/customer'
import { CustomersStore } from '../../stores/customers.store'

/**
 * Function to update an existing customer
 * @param localCustomer
 */
export function updateExistingCustomer(localCustomer: Customer): Promise<void> {
  return new Promise((resolve, reject): void => {
    try {
      CustomersStore.update((customers: Customer[]) => {
        customers[customers.findIndex((storeCustomer: Customer): boolean =>
          storeCustomer.company.companyId === localCustomer.company.companyId)]
                    = localCustomer
        
        return customers
      })
      resolve()
    } catch (err) {
      remoteDebuggingService.addInfo(err, Severity.Error)
      reject(err)
    }
  })
}

/**
 * Function to add a new customer
 * @param localCustomer
 */
export function addNewCustomer(localCustomer: Customer): Promise<void> {
  return new Promise((resolve, reject): void => {
    try {
      CustomersStore.update((customers: Customer[]) => {
        customers.push(localCustomer)
        
        return customers
      })
      resolve()
    } catch (err) {
      remoteDebuggingService.addInfo(err, Severity.Error)
      reject(err)
    }
  })
}

/**
 * Delete Customer
 * @param customerId
 */
export function deleteCustomerFromCustomerStore(customerId: string): Promise<void> {
  return new Promise<void>((resolve, reject): void => {
    try {
      CustomersStore.update((customers: Customer[]) => {
        customers.splice(customers.findIndex(
          (storeCustomer: Customer): boolean => storeCustomer.company.companyId === customerId), 1)
        
        return customers
      })
      resolve()
    } catch (err) {
      remoteDebuggingService.addInfo(err, Severity.Error)
      reject(err)
    }
  })
}

<script lang="ts">
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
    import type { BusinessDocument } from '../../../models/business-document'
    import { BusinessDocumentKind } from '../../../enums/business-document-kind.js'
    import { get } from 'svelte/store'
    import { SimpleDocumentsStore } from '../../../stores/business-documents.store.js'
    import type BusinessDocumentInstallmentRow from '../../../models/business-documents-installments.model'
    import {
      generateInstallmentRecapTableRows
    } from '../../../services/installment/installment-recap-table-rows.service'

    /** Exported Var **/
    export let businessDocument: BusinessDocument

    /** Local variables **/
    let installmentRows: BusinessDocumentInstallmentRow[]

    /** Populate the rows with the installment table rows */

    $: {
      installmentRows = generateInstallmentRecapTableRows(businessDocument, get(SimpleDocumentsStore), t('locales'), true, true, true, false, true)
      /* console.log('BusinessDocumentInstallmentsPreview.svelte generateInstallmentRecapTableRows installmentRows gave', installmentRows) */
    }
</script>

<div class="-mx-4 flex flex-col sm:-mx-6 md:mx-0">
    <table class="min-w-full divide-y divide-gray-300">
        <thead>
        <tr>
            {#if businessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE}
                <th scope="col"
                    class="w-80 py-2.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 md:pl-0">{t('invoices.createInvoice.installmentDetailsTitle')}</th>
            {:else if businessDocument.businessDocumentKind === BusinessDocumentKind.CREDITNOTE}
                <th scope="col"
                    class="w-80 py-2.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 md:pl-0">{t('invoices.createInvoice.detailsOfFirstInstallmentCreditNote')}</th>
            {:else}
                <th scope="col"
                    class="w-80 py-2.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 md:pl-0">{t('invoices.createInvoice.detailsOfFirstInstallmentOtherBusinessDocument')}</th>
            {/if}
            <th scope="col"
                class="hidden py-2.5 px-3 text-right text-xs font-semibold text-gray-900 sm:table-cell">{t('invoices.createInvoice.totalInvoicedPriceNoTaxLabel')}</th>
            <th scope="col"
                class="hidden py-2.5 px-3 text-right text-xs font-semibold text-gray-900 sm:table-cell">{t('invoices.createInvoice.finalInvoicedPriceTaxLabel')}</th>
            <th scope="col"
                class="py-2.5 pl-3 pr-4 text-right text-xs font-semibold text-gray-900 sm:pr-6 md:pr-0">{t('invoices.createInvoice.finalInvoicedTotalPriceWithTaxLabel')}</th>
        </tr>
        </thead>
        {#each installmentRows as installmentRow}
            <tr class="border-b border-gray-200">
                <td class="py-2 pl-4 pr-3 text-sm w-60 sm:pl-6 md:pl-0 break-words  align-top"
                    style="max-width: 250px;">
                    <div class="font-medium text-black text-xs">{
                        (function () {
                          // return installmentRow.businessDocumentNumber + " " + installmentRow.businessDocumentId + " - ";
                          return ''
                        })()
                    }
                        {installmentRow.installmentDescription}
                        {
                            (function () {
                              return ''
                              // return installmentRow.businessDocumentId === businessDocument.businessDocumentId ? t('invoices.createInvoice.recapThisDocument') : "(" + installmentRow.businessDocumentNumber + ")"
                            })()
                        }
                    </div>
                </td>
                <td class="hidden py-2 px-3 text-right text-xs text-paleSky sm:table-cell  align-top">
                    {installmentRow.installmentResultExcludingTaxScaledValueString}
                </td>
                <td class="hidden py-2 px-3 text-right text-xs text-paleSky sm:table-cell  align-top">
                    {installmentRow.installmentResultTaxScaledValueString}
                </td>
                <td class="py-2 pl-3 pr-4 text-right text-xs text-paleSky sm:pr-6 md:pr-0  align-top">
                    {installmentRow.installmentResultIncludingTaxScaledValueString}
                </td>
            </tr>
        {/each}
        <tbody>
    </table>
</div>

<script lang="ts">
    import { t } from '$core/lib/i18n/i18nextWrapper'
    import { Customer } from '$crm/models/customer'
    import type Invoice from '$dundy/models/invoice'
    import { DunningInvoicesStore } from '$dundy/stores/dunning-invoices.store'
    import { InvoiceStatus } from '$dundy/enums/invoice-status'
    import { DunningBusinessDocumentKind } from '$dundy/enums/dunning-business-document-kind'

    /** Exported */
    export let classes: string
    export let customer: Customer

    /** Local declarations */
    const outstandingInvoices: Invoice[] =
            $DunningInvoicesStore.filter(
              (invoice: Invoice) => invoice.toId === customer.company.companyId
                            && invoice.status === InvoiceStatus.OUTSTANDING
                            && invoice.documentKind === DunningBusinessDocumentKind.INVOICE
                            && !invoice.deleted
                            && invoice.completed
                            && invoice.isTracked)

    let overdueInvoices: Invoice[] = outstandingInvoices.filter(invoice => new Date(invoice.dateDue) < new Date())
</script>

<div class="flex items-center {classes}">
  <div class="main-label-initials flex flex-wrap content-center justify-center w-6 h-6 bg-loblolly text-white rounded-md mr-3">
    <span>{customer?.company?.formalName?.substring(0, 1).toUpperCase()}</span>
  </div>
  <div class="w-8/12 flex flex-col justify-center {classes}">
    <span class="inline-block truncate w-28 text-s font-semibold text-gray-900">{customer?.company?.formalName}</span>
    <p class="flex items-center truncate text-xxs leading-5 text-gray-500">
      <span class="truncate">{overdueInvoices?.length} {t('todo.account_position.lateInvoices', { count: overdueInvoices?.length })}</span>
    </p>
  </div>
</div>

<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { Contact } from '$src/crm-app/models/contact'
  import {t} from '$core/lib/i18n/i18nextWrapper'
  import Tooltip from "../../../core-app/lib/ui-kit/Tooltip/Tooltip.svelte";

  export let contacts:Contact[] = []
  export let contact:Contact

  const dispatch = createEventDispatcher()

  const onCreateNew = () => {
    dispatch('createNew')
  }

  const onSelect = (c:Contact) => {
    contact = c
    dispatch('select', c)
  }
</script>

<div class="px-4">
  <div class="flex items-center justify-between pb-2 border-b mb-4">
    <h2 class="font-semibold leading-6 text-black">{t('customerManage.form.contactsTitle')}
      <span class="number-badge">{contacts.length}</span></h2>
    <button class="h-6 w-6 text-xl leading-6 bg-black hover:bg-dundyOrange text-white rounded" on:click={() => onCreateNew()}>+</button>
  </div>
  <div class="flex flex-col">
    {#each contacts as c}
      <ul role="list" class="divide-y divide-athensGray">
        <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6 border-b border-gray-100">
          <div class="flex w-0 flex-1 items-center">
            <div
              class="contact-avatar w-10 h-10">{c?.firstName?.substring(0, 1).toUpperCase()}{c?.lastName?.substring(0, 1).toUpperCase()}</div>
            <div class="ml-4 flex flex-col min-w-0 flex-1">
              <span class="truncate font-medium">{c?.firstName} {c.lastName}</span>
              <span class="flex-shrink-0 text-gray-400">{c?.position} {#if c?.department}- {c?.department}{/if}</span>
            </div>
          </div>
          <div class="ml-4 flex-shrink-0">
            <Tooltip>
              <div slot="activator">
                <button class="edit" on:click={() => onSelect(c)}>
                  <svg class="h-6 w-6 text-center items-center m-auto p-0" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.75 19.25L9 18.25L18.9491 8.30083C19.3397 7.9103 19.3397 7.27714 18.9491 6.88661L17.1134 5.05083C16.7228 4.6603 16.0897 4.6603 15.6991 5.05083L5.75 15L4.75 19.25Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M14.0234 7.03906L17.0234 10.0391" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </button>
              </div>
              {t('grid.edit')}
            </Tooltip>
          </div>
        </li>
      </ul>
    {/each}
  </div>
</div>

<style lang="postcss">
  .contact-avatar {
    @apply bg-slate-100 rounded-md p-3 flex items-center align-middle justify-center border-athensGray text-baliHai ring-1 ring-gray-900/5 shadow-sm;
  }
  .edit {
    @apply border-2 border-transparent cursor-pointer flex w-10 h-10 justify-center items-center rounded text-center;
    display: inherit;

    &:active,
    &:hover {
      @apply bg-provincialPink border-dundyOrange;
    }
  }
  .number-badge {
    @apply bg-athensGray text-baliHai hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block;
  }
</style>
<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'

    export let totalTransactionAmount: string
    export let amountCashApplied: string
    export let percentageCashApplied: number = 0

    let percentageCashAppliedWithSign: string = ''

    $: percentageCashAppliedWithSign = new Intl.NumberFormat(t('locales'), {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(percentageCashApplied / 100)
</script>

<div class="w-full" data-ut-component="cash-application-row-completion">
    <div class="py-6 space-y-3">
        <div class="w-full flex justify-between">
            <h2 class="text-black text-sm font-medium">{t('cashApplication.cashApplicationTitle')}</h2>
            <span class="text-sm">{percentageCashAppliedWithSign}</span>
        </div>

        <div class="w-full h-2 bg-whisper rounded-full">
            <div class="h-full text-center text-xs text-white bg-black rounded-full"
                 style="width: {percentageCashApplied}%">
            </div>
        </div>
        <div class="flex items-center justify-end">
            <div class="flex items-center justify-between">
                <p class="text-black text-sm">
                    <span class="font-medium">{amountCashApplied}</span>
                    <span> / {totalTransactionAmount}</span>
                </p>
            </div>
        </div>
    </div>
</div>

import { TaxonomyTag } from '../../enums/taxonomy-tag'
import { deepClone } from '../../../core-app/util/object-deep-cloning'
import { isPartialBusinessDocument } from '../installment/is-partial-business-document'
import type { BusinessDocument } from '../../models/business-document'
import { BusinessDocumentRelation } from '../../models/business-document'
import { Decimal } from 'decimal.js'
import type { ComputedInstallmentValues } from '../../models/installment-computed-accumulated-values.model'
import {
  calculateCumulatedInvoicedInstallmentsIncludingTax,
  calculateRemainingInstallmentIncludingTaxStillToInvoiceIncludingAllSpecifiedDocumentsAndCurrentDocument
} from '../deal-balance-calculation/incomplete-deals-calculation.service'
import type { ComputedRemainingValues } from '../../models/installment-computed-remaining-values.model'
import {
  getDealFinalizedBusinessDocumentsForBalanceOfDealCalculus
} from '../deal-balance-document-make/get-deal-finalized-business-documents-for-balance-of-deal-calculus.service'
import { BusinessDocumentKind } from '../../enums/business-document-kind'
import { BusinessDocumentStatus } from '../../enums/business-document-status'
import { BusinessDocumentRelationKind } from '../../enums/business-document-relation-kind'

/**
 * REFERENCE Function that calculates Taxonomy Tags and provides OPTIONAL recommendations.
 * 1) Taxonomy Tags calculation: this is the only reference function of the application for this. Calculation is stateless and accurate whatever the previous business document state (aka it will provide the proper business document tags depending upon its content).
 * 2) OPTIONAL Recommendations: they are only valid if the currentBusinessDocument.hasSpecificInstallment has just changed from false to true or vice-versa. Otherwise, simply ignore the recommendations.
 * Nb: is supposed to do the exact same job as "scripts-dundy" back-end GetTaxonomyTagsFromBusinessDocumentV20230616v0_2_1() and GetTaxonomyTagsFromBusinessDocumentV20230823v0_2_9Now()
 * @param currentBusinessDocument
 * @param allBusinessDocuments
 */
export function getBusinessDocumentUpdatedInstallmentTaxonomyTags(currentBusinessDocument: BusinessDocument, allBusinessDocuments: BusinessDocument[]): {
  useCase: string,
  needNewDealIdNeeded: boolean,
  newTaxonomyTagsForBusinessDocument: TaxonomyTag[],
  needToConvertIntoFullBusinessDocument: boolean,
  weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: boolean,
  needToDiscardInstallmentChosenValueAndKind: boolean,
} {
  /* console.log('reference taxonomy tags determination getBusinessDocumentUpdatedInstallmentTaxonomyTags A getBusinessDocumentUpdatedInstallmentTaxonomyTags') */
  if (!currentBusinessDocument.relatedBusinessDocuments) {
    console.warn('getBusinessDocumentUpdatedInstallmentTaxonomyTags currentBusinessDocument.taxonomyTags', currentBusinessDocument.taxonomyTags,
      'currentBusinessDocument.relatedBusinessDocuments', currentBusinessDocument.relatedBusinessDocuments)
  }
  let newTaxonomyTags: TaxonomyTag[] = deepClone(currentBusinessDocument.taxonomyTags)
  if (!newTaxonomyTags) {
    newTaxonomyTags = <TaxonomyTag[]>[]
  }
  newTaxonomyTags = newTaxonomyTags.filterRemoveAllItemsAmong([TaxonomyTag.COMMERCIAL_INVOICE, TaxonomyTag.COMMERCIAL_CREDIT_NOTE, TaxonomyTag.FULL_BUSINESS_DOCUMENT, TaxonomyTag.PARTIAL_BUSINESS_DOCUMENT, TaxonomyTag.VOIDING_CREDIT_NOTE, TaxonomyTag.DEPOSIT_INVOICE, TaxonomyTag.INTERMEDIATE_INVOICE, TaxonomyTag.BALANCE_INVOICE])

  // we should always have a deal attached
  // NB: noOrCorruptedDealInformation only has an impact if the business document is partial
  // NB: but in fact, a business document without a deal id is an old draft before introducing deal ids, so it can be sorted out upfront
  const noOrCorruptedDealInformation: boolean = (!currentBusinessDocument.linkedDeal
        || !currentBusinessDocument.linkedDeal.dealId
        || (currentBusinessDocument.linkedDeal.dealId === ''))

  const parentRelatedBusinessDocument: BusinessDocumentRelation = !!(currentBusinessDocument.relatedBusinessDocuments)
    ? currentBusinessDocument.relatedBusinessDocuments.find(
      (br: BusinessDocumentRelation) =>
        currentBusinessDocument.businessDocumentStatus === BusinessDocumentStatus.FINAL
          ? br.toBusinessDocumentNumber === currentBusinessDocument.businessDocumentNumber
          : br.toBusinessDocumentId === currentBusinessDocument.businessDocumentId,
    )
    : undefined
  const isParentRelatedDocumentFound: boolean = (!!parentRelatedBusinessDocument)

  if (noOrCorruptedDealInformation) { // we can determine a deal id on all the parent invoices, but we will not because too many cases can occur (this is a manual repair operation, in this migration phase - 20230906)

    // cases A, B

    // TODO we could find out from the BusinessDocumentRelation[] what other BusinessDocuments need to be given the same DealId, see filterKeepOnlyPartialBusinessDocumentForTheSameDealAsRefBusinessDocument which knows how to walk through old business documents without deals
    // deal is missing: !!! err there should always be a deal attached for a full business document
    // we assume the caller will add a deal from scratch, then the document will be whether a DEPOSIT_INVOICE if partial or FULL_BUSINESS_DOCUMENT otherwise
    /* console.error('business document deal missing, will be added', currentBusinessDocument) */
    if (currentBusinessDocument.hasSpecificInstallment) { // makes no sense - before migration - need to repair
      newTaxonomyTags.push(TaxonomyTag.COMMERCIAL_INVOICE)
      newTaxonomyTags.push(TaxonomyTag.PARTIAL_BUSINESS_DOCUMENT)
      newTaxonomyTags.push(TaxonomyTag.DEPOSIT_INVOICE)
      /* console.log('xyz A') */
      
      return {
        useCase: 'A',
        needNewDealIdNeeded: true,
        newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
        needToConvertIntoFullBusinessDocument: false, // partial business document that stays a partial document
        weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
        needToDiscardInstallmentChosenValueAndKind: false
      }
    } else { // makes no sense - before migration - need to repair
      newTaxonomyTags.push(TaxonomyTag.COMMERCIAL_INVOICE)
      newTaxonomyTags.push(TaxonomyTag.FULL_BUSINESS_DOCUMENT)
      /* console.log('xyz B') */
      
      return {
        useCase: 'B',
        needNewDealIdNeeded: true,
        newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
        needToConvertIntoFullBusinessDocument: true, // !!!!!!!!!!!!! TBC
        weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
        needToDiscardInstallmentChosenValueAndKind: (currentBusinessDocument.totalIncludingTaxScaledValue !== currentBusinessDocument.installmentResultIncludingTaxScaledValue)
      }
    }
  }


  if (currentBusinessDocument.hasSpecificInstallment) {
    // if (isPartialBusinessDocument(currentBusinessDocument)) {

    // cases C, D, E

    // is partial business document
    // at this point, there is a linkedDeal and a DealId

    const allDealFinalizedBusinessDocuments: BusinessDocument[] = getDealFinalizedBusinessDocumentsForBalanceOfDealCalculus(
      allBusinessDocuments,
      currentBusinessDocument.businessDocumentId,
      currentBusinessDocument.linkedDeal.dealId,
    )
    // Calculate the remaining amount
    const computedRemainingToInvoiceAllFinalizedAndIncludingCurrentDocument: ComputedRemainingValues =
            calculateRemainingInstallmentIncludingTaxStillToInvoiceIncludingAllSpecifiedDocumentsAndCurrentDocument(
              currentBusinessDocument,
              allDealFinalizedBusinessDocuments,
            )
    /* console.warn('computedRemainingToInvoiceAllFinalizedAndIncludingCurrentDocument getBusinessDocumentUpdatedInstallmentTaxonomyTags', JSON.stringify(computedRemainingToInvoiceAllFinalizedAndIncludingCurrentDocument, null, 3)) */
    // Calculate the accumulated percentage from the related business documents
    const computedSumOfInvoicedOnlyFinalized: ComputedInstallmentValues = calculateCumulatedInvoicedInstallmentsIncludingTax(currentBusinessDocument.totalIncludingTaxScaledValue, allDealFinalizedBusinessDocuments)
    const totalPercentageOfDeal: Decimal = computedSumOfInvoicedOnlyFinalized.accumulatedInstallmentsPercentage.add(computedRemainingToInvoiceAllFinalizedAndIncludingCurrentDocument.remainingPercentage)
    /* console.warn('current status \'full invoice\', currentBusinessDocument.hasSpecificInstallment=', currentBusinessDocument.hasSpecificInstallment) */

    if (computedSumOfInvoicedOnlyFinalized.accumulatedInstallmentsPercentage.abs().lessThanOrEqualTo(new Decimal(0.001))) {
      /* console.log('computedSumOfInvoicedOnlyFinalized', computedSumOfInvoicedOnlyFinalized) */
      newTaxonomyTags.push(TaxonomyTag.COMMERCIAL_INVOICE)
      newTaxonomyTags.push(TaxonomyTag.PARTIAL_BUSINESS_DOCUMENT)
      newTaxonomyTags.push(TaxonomyTag.DEPOSIT_INVOICE)
      /* console.log('xyz C') */
      
      return {
        useCase: 'C',
        needNewDealIdNeeded: false,
        newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
        needToConvertIntoFullBusinessDocument: false, // partial business document that stays a partial document
        weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
        needToDiscardInstallmentChosenValueAndKind: false
      }
    } else if (computedRemainingToInvoiceAllFinalizedAndIncludingCurrentDocument.remainingPercentage.abs().lessThanOrEqualTo(new Decimal(0.001))) {
      newTaxonomyTags.push(TaxonomyTag.COMMERCIAL_INVOICE)
      newTaxonomyTags.push(TaxonomyTag.PARTIAL_BUSINESS_DOCUMENT)
      newTaxonomyTags.push(TaxonomyTag.BALANCE_INVOICE)
      /* console.log('xyz D') */
      
      return {
        useCase: 'D',
        needNewDealIdNeeded: false,
        newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
        needToConvertIntoFullBusinessDocument: false, // partial business document that stays a partial document
        weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
        needToDiscardInstallmentChosenValueAndKind: false
      }
    } else {
      newTaxonomyTags.push(TaxonomyTag.COMMERCIAL_INVOICE)
      newTaxonomyTags.push(TaxonomyTag.PARTIAL_BUSINESS_DOCUMENT)
      newTaxonomyTags.push(TaxonomyTag.INTERMEDIATE_INVOICE)
      /* console.log('xyz E') */
      
      return {
        useCase: 'E',
        needNewDealIdNeeded: false,
        newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
        needToConvertIntoFullBusinessDocument: false, // partial business document that stays a partial document
        weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
        needToDiscardInstallmentChosenValueAndKind: false
      }
    }

  } else {

    // here: is full business document
    // at this point, there is a linkedDeal and a DealId

    switch (currentBusinessDocument.businessDocumentKind) {
      case BusinessDocumentKind.INVOICE:

        // cases F, G, H, I, J, K, L

        if (!isParentRelatedDocumentFound) { // normal case
          newTaxonomyTags.push(TaxonomyTag.COMMERCIAL_INVOICE)
          newTaxonomyTags.push(TaxonomyTag.FULL_BUSINESS_DOCUMENT)
          /* console.log('xyz F') */
          
          return {
            useCase: 'F',
            needNewDealIdNeeded: false,
            newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
            needToConvertIntoFullBusinessDocument: false, // already a full business document
            weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
            needToDiscardInstallmentChosenValueAndKind: false
          }
        } else if ((parentRelatedBusinessDocument.relationKind === BusinessDocumentRelationKind.INVOICE_ON_CREDIT_NOTE)
                    || (parentRelatedBusinessDocument.relationKind === BusinessDocumentRelationKind.INVOICE_VOIDING_DEPRECATED)
                    || (parentRelatedBusinessDocument.relationKind === BusinessDocumentRelationKind.CREDIT_NOTE_ON_CREDIT_NOTE)) { // normal case
        // determination according to the taxonomy tags suggested by the parent RelatedBusinessDocument "to" Taxonomy Tags
        // see: getTagsForVoidedBusinessDocument()
          if (parentRelatedBusinessDocument.toBusinessDocumentTaxonomyTags.includes(TaxonomyTag.CORRECTIVE_INVOICE)) { // normal case
            newTaxonomyTags.push(TaxonomyTag.CORRECTIVE_INVOICE)
            newTaxonomyTags.push(TaxonomyTag.FULL_BUSINESS_DOCUMENT)
            /* console.log('xyz G') */
            
            return {
              useCase: 'G',
              needNewDealIdNeeded: false,
              newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
              needToConvertIntoFullBusinessDocument: false, // already a full business document
              weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
              needToDiscardInstallmentChosenValueAndKind: false
            }
          } else if (parentRelatedBusinessDocument.toBusinessDocumentTaxonomyTags.includes(TaxonomyTag.COMMERCIAL_INVOICE)) { // case is inconsistent / error
            newTaxonomyTags.push(TaxonomyTag.COMMERCIAL_INVOICE)
            newTaxonomyTags.push(TaxonomyTag.FULL_BUSINESS_DOCUMENT)
            /* console.log('xyz H') */
            console.error('error in reference taxonomy tags determination getBusinessDocumentUpdatedInstallmentTaxonomyTags A getBusinessDocumentUpdatedInstallmentTaxonomyTags',
              'err: the business document is an invoice, with parent relation as an invoice on credit note, but with \'to\' relation taxonomy as commercial invoice instead of corrective invoice',
              'case xyz H',
              'kind', currentBusinessDocument.businessDocumentKind,
              'hasSpecificInstallment', currentBusinessDocument.hasSpecificInstallment,
              'parentRelatedBusinessDocument', parentRelatedBusinessDocument,
              'is partial ? (slightly different than has Installment)', isPartialBusinessDocument(currentBusinessDocument),
              'has deal id', !noOrCorruptedDealInformation,
              'deal id (if available)', (!!currentBusinessDocument.linkedDeal ? (!!currentBusinessDocument.linkedDeal.dealId ? currentBusinessDocument.linkedDeal.dealId : 'n/a dealId') : 'n/a linkedDeal'),
              'current newTaxonomyTags value', newTaxonomyTags,
              'original newTaxonomyTags', currentBusinessDocument.taxonomyTags,
              'business document id', currentBusinessDocument.businessDocumentId,
              'is parentRelation found', isParentRelatedDocumentFound)
            
            return {
              useCase: 'H',
              needNewDealIdNeeded: false,
              newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
              needToConvertIntoFullBusinessDocument: false, // already a full business document
              weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
              needToDiscardInstallmentChosenValueAndKind: false
            }
          } else { // case is inconsistent / error
            newTaxonomyTags.push(TaxonomyTag.CORRECTIVE_INVOICE)
            newTaxonomyTags.push(TaxonomyTag.FULL_BUSINESS_DOCUMENT)
            /* console.log('xyz I') */
            console.error('error in reference taxonomy tags determination getBusinessDocumentUpdatedInstallmentTaxonomyTags A getBusinessDocumentUpdatedInstallmentTaxonomyTags',
              'err: the business document is an invoice, with parent relation as an invoice on credit note, but with \'to\' relation taxonomy missing corrective invoice',
              'case xyz I',
              'kind', currentBusinessDocument.businessDocumentKind,
              'hasSpecificInstallment', currentBusinessDocument.hasSpecificInstallment,
              'parentRelatedBusinessDocument', parentRelatedBusinessDocument,
              'is partial ? (slightly different than has Installment)', isPartialBusinessDocument(currentBusinessDocument),
              'has deal id', !noOrCorruptedDealInformation,
              'deal id (if available)', (!!currentBusinessDocument.linkedDeal ? (!!currentBusinessDocument.linkedDeal.dealId ? currentBusinessDocument.linkedDeal.dealId : 'n/a dealId') : 'n/a linkedDeal'),
              'current newTaxonomyTags value', newTaxonomyTags,
              'original newTaxonomyTags', currentBusinessDocument.taxonomyTags,
              'id', currentBusinessDocument.businessDocumentId,
              'parentRelation found', isParentRelatedDocumentFound)
            
            return {
              useCase: 'I',
              needNewDealIdNeeded: false,
              newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
              needToConvertIntoFullBusinessDocument: false, // already a full business document
              weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
              needToDiscardInstallmentChosenValueAndKind: false
            }
          }
        } else if (parentRelatedBusinessDocument.relationKind === BusinessDocumentRelationKind.INVOICE_DUPLICATION) { // normal case
          newTaxonomyTags.push(TaxonomyTag.COMMERCIAL_INVOICE)
          newTaxonomyTags.push(TaxonomyTag.FULL_BUSINESS_DOCUMENT)
          /* console.log('xyz J') */
          
          return {
            useCase: 'J',
            needNewDealIdNeeded: false,
            newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
            needToConvertIntoFullBusinessDocument: false, // already a full business document
            weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
            needToDiscardInstallmentChosenValueAndKind: false
          }
        } else if (parentRelatedBusinessDocument.relationKind === BusinessDocumentRelationKind.INVOICE_ON_INVOICE) { // case is inconsistent / error
          newTaxonomyTags.push(TaxonomyTag.CORRECTIVE_INVOICE)
          newTaxonomyTags.push(TaxonomyTag.FULL_BUSINESS_DOCUMENT)
          /* console.log('xyz K') */
          console.error('error in reference taxonomy tags determination getBusinessDocumentUpdatedInstallmentTaxonomyTags A getBusinessDocumentUpdatedInstallmentTaxonomyTags',
            'err: the business document is an invoice, with parent relation as an invoice on invoice, which is reserved to partial invoices, however the business document is a full document',
            'case xyz K',
            'kind', currentBusinessDocument.businessDocumentKind,
            'hasSpecificInstallment', currentBusinessDocument.hasSpecificInstallment,
            'parentRelatedBusinessDocument', parentRelatedBusinessDocument,
            'is partial ? (slightly different than has Installment)', isPartialBusinessDocument(currentBusinessDocument),
            'has deal id', !noOrCorruptedDealInformation,
            'deal id (if available)', (!!currentBusinessDocument.linkedDeal ? (!!currentBusinessDocument.linkedDeal.dealId ? currentBusinessDocument.linkedDeal.dealId : 'n/a dealId') : 'n/a linkedDeal'),
            'current newTaxonomyTags value', newTaxonomyTags,
            'original newTaxonomyTags', currentBusinessDocument.taxonomyTags,
            'id', currentBusinessDocument.businessDocumentId,
            'parentRelation found', isParentRelatedDocumentFound)
          
          return {
            useCase: 'K',
            needNewDealIdNeeded: false,
            newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
            needToConvertIntoFullBusinessDocument: false, // already a full business document
            weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
            needToDiscardInstallmentChosenValueAndKind: false
          }
        } else { // case is inconsistent / error
          newTaxonomyTags.push(TaxonomyTag.COMMERCIAL_INVOICE)
          newTaxonomyTags.push(TaxonomyTag.FULL_BUSINESS_DOCUMENT)
          /* console.log('xyz L') */
          console.error('error in reference taxonomy tags determination getBusinessDocumentUpdatedInstallmentTaxonomyTags A getBusinessDocumentUpdatedInstallmentTaxonomyTags',
            'err: the business document is an invoice, with unexpected parent relation to it',
            'case xyz L',
            'kind', currentBusinessDocument.businessDocumentKind,
            'hasSpecificInstallment', currentBusinessDocument.hasSpecificInstallment,
            'parentRelatedBusinessDocument', parentRelatedBusinessDocument,
            'is partial ? (slightly different than has Installment)', isPartialBusinessDocument(currentBusinessDocument),
            'has deal id', !noOrCorruptedDealInformation,
            'deal id (if available)', (!!currentBusinessDocument.linkedDeal ? (!!currentBusinessDocument.linkedDeal.dealId ? currentBusinessDocument.linkedDeal.dealId : 'n/a dealId') : 'n/a linkedDeal'),
            'current newTaxonomyTags value', newTaxonomyTags,
            'original newTaxonomyTags', currentBusinessDocument.taxonomyTags,
            'id', currentBusinessDocument.businessDocumentId,
            'parentRelation found', isParentRelatedDocumentFound)
          
          return {
            useCase: 'L',
            needNewDealIdNeeded: false,
            newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
            needToConvertIntoFullBusinessDocument: false, // already a full business document
            weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
            needToDiscardInstallmentChosenValueAndKind: false
          }
        }
        break
      case BusinessDocumentKind.CREDITNOTE:

        // cases M, N, O, P, Q, R, S

        if (!isParentRelatedDocumentFound) { // normal case
          newTaxonomyTags.push(TaxonomyTag.COMMERCIAL_CREDIT_NOTE)
          newTaxonomyTags.push(TaxonomyTag.FULL_BUSINESS_DOCUMENT)
          /* console.log('xyz M') */
          
          return {
            useCase: 'M',
            needNewDealIdNeeded: false,
            newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
            needToConvertIntoFullBusinessDocument: false, // already a full business document
            weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
            needToDiscardInstallmentChosenValueAndKind: false
          }
        } else if ((parentRelatedBusinessDocument.relationKind === BusinessDocumentRelationKind.CREDIT_NOTE_ON_INVOICE)) { // normal case
        // determination according to the taxonomy tags sugegsted by the parent RelatedBusinessDocument "to" Taxonomy Tags
        // see: getTagsForVoidedBusinessDocument()
          if (parentRelatedBusinessDocument.toBusinessDocumentTaxonomyTags.includes(TaxonomyTag.VOIDING_CREDIT_NOTE)) {
            newTaxonomyTags.push(TaxonomyTag.VOIDING_CREDIT_NOTE)
            newTaxonomyTags.push(TaxonomyTag.FULL_BUSINESS_DOCUMENT)
            /* console.log('xyz N') */
            
            return {
              useCase: 'N',
              needNewDealIdNeeded: false,
              newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
              needToConvertIntoFullBusinessDocument: false, // already a full business document
              weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
              needToDiscardInstallmentChosenValueAndKind: false
            }
          } else if (parentRelatedBusinessDocument.toBusinessDocumentTaxonomyTags.includes(TaxonomyTag.COMMERCIAL_CREDIT_NOTE)) { // case is inconsistent / error
            newTaxonomyTags.push(TaxonomyTag.COMMERCIAL_CREDIT_NOTE)
            newTaxonomyTags.push(TaxonomyTag.FULL_BUSINESS_DOCUMENT)
            /* console.log('xyz O') */
            console.error('error in reference taxonomy tags determination getBusinessDocumentUpdatedInstallmentTaxonomyTags A getBusinessDocumentUpdatedInstallmentTaxonomyTags',
              'err: the business document is a credit note, with parent relation as a credit note on invoice, but with \'to\' relation taxonomy as commercial credit note instead of voiding credit note',
              'case xyz O',
              'kind', currentBusinessDocument.businessDocumentKind,
              'hasSpecificInstallment', currentBusinessDocument.hasSpecificInstallment,
              'parentRelatedBusinessDocument', parentRelatedBusinessDocument,
              'is partial ? (slightly different than has Installment)', isPartialBusinessDocument(currentBusinessDocument),
              'has deal id', !noOrCorruptedDealInformation,
              'deal id (if available)', (!!currentBusinessDocument.linkedDeal ? (!!currentBusinessDocument.linkedDeal.dealId ? currentBusinessDocument.linkedDeal.dealId : 'n/a dealId') : 'n/a linkedDeal'),
              'current newTaxonomyTags value', newTaxonomyTags,
              'original newTaxonomyTags', currentBusinessDocument.taxonomyTags,
              'id', currentBusinessDocument.businessDocumentId,
              'parentRelation found', isParentRelatedDocumentFound)
            
            return {
              useCase: 'O',
              needNewDealIdNeeded: false,
              newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
              needToConvertIntoFullBusinessDocument: false, // already a full business document
              weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
              needToDiscardInstallmentChosenValueAndKind: false
            }
          } else { // case is inconsistent / error
            newTaxonomyTags.push(TaxonomyTag.VOIDING_CREDIT_NOTE)
            newTaxonomyTags.push(TaxonomyTag.FULL_BUSINESS_DOCUMENT)
            /* console.log('xyz P') */
            console.error('error in reference taxonomy tags determination getBusinessDocumentUpdatedInstallmentTaxonomyTags A getBusinessDocumentUpdatedInstallmentTaxonomyTags',
              'err: the business document is a credit note, with parent relation as a credit note on invoice, but with \'to\' relation taxonomy missing voiding credit note',
              'case xyz P',
              'kind', currentBusinessDocument.businessDocumentKind,
              'hasSpecificInstallment', currentBusinessDocument.hasSpecificInstallment,
              'parentRelatedBusinessDocument', parentRelatedBusinessDocument,
              'is partial ? (slightly different than has Installment)', isPartialBusinessDocument(currentBusinessDocument),
              'has deal id', !noOrCorruptedDealInformation,
              'deal id (if available)', (!!currentBusinessDocument.linkedDeal ? (!!currentBusinessDocument.linkedDeal.dealId ? currentBusinessDocument.linkedDeal.dealId : 'n/a dealId') : 'n/a linkedDeal'),
              'current newTaxonomyTags value', newTaxonomyTags,
              'original newTaxonomyTags', currentBusinessDocument.taxonomyTags,
              'id', currentBusinessDocument.businessDocumentId,
              'parentRelation found', isParentRelatedDocumentFound)
            
            return {
              useCase: 'P',
              needNewDealIdNeeded: false,
              newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
              needToConvertIntoFullBusinessDocument: false, // already a full business document
              weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
              needToDiscardInstallmentChosenValueAndKind: false
            }
          }
        } else if (parentRelatedBusinessDocument.relationKind === BusinessDocumentRelationKind.CREDIT_NOTE_DUPLICATION) { // normal case
          newTaxonomyTags.push(TaxonomyTag.COMMERCIAL_CREDIT_NOTE)
          newTaxonomyTags.push(TaxonomyTag.FULL_BUSINESS_DOCUMENT)
          /* console.log('xyz Q') */
          
          return {
            useCase: 'Q',
            needNewDealIdNeeded: false,
            newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
            needToConvertIntoFullBusinessDocument: false, // already a full business document
            weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
            needToDiscardInstallmentChosenValueAndKind: false
          }
        } else if (parentRelatedBusinessDocument.relationKind === BusinessDocumentRelationKind.CREDIT_NOTE_ON_CREDIT_NOTE) { // case is inconsistent / error
          newTaxonomyTags.push(TaxonomyTag.CORRECTIVE_INVOICE)
          newTaxonomyTags.push(TaxonomyTag.FULL_BUSINESS_DOCUMENT)
          /* console.log('xyz R') */
          console.error('error in reference taxonomy tags determination getBusinessDocumentUpdatedInstallmentTaxonomyTags A getBusinessDocumentUpdatedInstallmentTaxonomyTags',
            'err: the business document is a credit note, with parent relation as a credit note on credit note, hence it should be an invoice (business rule) and not a credit note',
            'case xyz R',
            'kind', currentBusinessDocument.businessDocumentKind,
            'hasSpecificInstallment', currentBusinessDocument.hasSpecificInstallment,
            'parentRelatedBusinessDocument', parentRelatedBusinessDocument,
            'is partial ? (slightly different than has Installment)', isPartialBusinessDocument(currentBusinessDocument),
            'has deal id', !noOrCorruptedDealInformation,
            'deal id (if available)', (!!currentBusinessDocument.linkedDeal ? (!!currentBusinessDocument.linkedDeal.dealId ? currentBusinessDocument.linkedDeal.dealId : 'n/a dealId') : 'n/a linkedDeal'),
            'current newTaxonomyTags value', newTaxonomyTags,
            'original newTaxonomyTags', currentBusinessDocument.taxonomyTags,
            'id', currentBusinessDocument.businessDocumentId,
            'parentRelation found', isParentRelatedDocumentFound)
          
          return {
            useCase: 'R',
            needNewDealIdNeeded: false,
            newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
            needToConvertIntoFullBusinessDocument: false, // already a full business document
            weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
            needToDiscardInstallmentChosenValueAndKind: false
          }
        } else { // case is inconsistent / error
          newTaxonomyTags.push(TaxonomyTag.COMMERCIAL_CREDIT_NOTE)
          newTaxonomyTags.push(TaxonomyTag.FULL_BUSINESS_DOCUMENT)
          /* console.log('xyz S') */
          console.error('error in reference taxonomy tags determination getBusinessDocumentUpdatedInstallmentTaxonomyTags A getBusinessDocumentUpdatedInstallmentTaxonomyTags',
            'err: the business document is an credit note, with unexpected parent relation to it',
            'case xyz S',
            'kind', currentBusinessDocument.businessDocumentKind,
            'hasSpecificInstallment', currentBusinessDocument.hasSpecificInstallment,
            'parentRelatedBusinessDocument', parentRelatedBusinessDocument,
            'is partial ? (slightly different than has Installment)', isPartialBusinessDocument(currentBusinessDocument),
            'has deal id', !noOrCorruptedDealInformation,
            'deal id (if available)', (!!currentBusinessDocument.linkedDeal ? (!!currentBusinessDocument.linkedDeal.dealId ? currentBusinessDocument.linkedDeal.dealId : 'n/a dealId') : 'n/a linkedDeal'),
            'current newTaxonomyTags value', newTaxonomyTags,
            'original newTaxonomyTags', currentBusinessDocument.taxonomyTags,
            'id', currentBusinessDocument.businessDocumentId,
            'parentRelation found', isParentRelatedDocumentFound)
          
          return {
            useCase: 'S',
            needNewDealIdNeeded: false,
            newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
            needToConvertIntoFullBusinessDocument: false, // already a full business document
            weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
            needToDiscardInstallmentChosenValueAndKind: false
          }
        }
        break
      default:

        // cases T

        newTaxonomyTags.push(TaxonomyTag.COMMERCIAL_INVOICE)
        newTaxonomyTags.push(TaxonomyTag.FULL_BUSINESS_DOCUMENT)
        /* console.log('xyz T') */
        console.error('error in reference taxonomy tags determination getBusinessDocumentUpdatedInstallmentTaxonomyTags A getBusinessDocumentUpdatedInstallmentTaxonomyTags',
          'err: the business document has unexpected kind, and cannot be tagged (maybe it is a quote or a purchase order, which still need to be implemented here)',
          'case xyz T',
          'kind', currentBusinessDocument.businessDocumentKind,
          'hasSpecificInstallment', currentBusinessDocument.hasSpecificInstallment,
          'parentRelatedBusinessDocument', parentRelatedBusinessDocument,
          'is partial ? (slightly different than has Installment)', isPartialBusinessDocument(currentBusinessDocument),
          'has deal id', !noOrCorruptedDealInformation,
          'deal id (if available)', (!!currentBusinessDocument.linkedDeal ? (!!currentBusinessDocument.linkedDeal.dealId ? currentBusinessDocument.linkedDeal.dealId : 'n/a dealId') : 'n/a linkedDeal'),
          'current newTaxonomyTags value', newTaxonomyTags,
          'original newTaxonomyTags', currentBusinessDocument.taxonomyTags,
          'id', currentBusinessDocument.businessDocumentId,
          'parentRelation found', isParentRelatedDocumentFound)
        
        return {
          useCase: 'T',
          needNewDealIdNeeded: false,
          newTaxonomyTagsForBusinessDocument: newTaxonomyTags,
          needToConvertIntoFullBusinessDocument: false, // already a full business document
          weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal: false,
          needToDiscardInstallmentChosenValueAndKind: false
        }
        break
    }
  }
}


// Array extension ("declare global" then "Array.prototype.xxx=")
// see: https://stackoverflow.com/questions/12802383/extending-array-in-typescript

declare global {
  interface Array<T> {
    filterRemoveAllItemsAmong(itemsToRemove: T[]): Array<T>;
  }
}
Array.prototype.filterRemoveAllItemsAmong = function <T>(itemsToRemove: T[]): T[] {
  return this.filter((tag: T) => !itemsToRemove.includes(tag))
}

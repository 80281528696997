<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { WorkflowMedias, WorkflowTemplates } from './WorkflowItem.d'
  import { t } from '$core/lib/i18n/i18nextWrapper'
  import { WorkflowMediaType, WorkflowModes, WorkflowModeType, WorkflowTemplateType } from '$dundy/data/workflow'

  export let name:Readonly<string> = ''
  export let index:Readonly<number | null> = null
  export let media:Readonly<WorkflowMediaType> = WorkflowMediaType.MAIL
  export let mode:Readonly<WorkflowModeType> = WorkflowModeType.MANUAL
  export let template:Readonly<WorkflowTemplateType> = WorkflowTemplateType.CUSTOM

  $:item = {
    media: WorkflowMedias.find(m => m.key === media),
    mode: WorkflowModes.find(m => m.key === mode),
    template: WorkflowTemplates.find(t => t.key === template)
  }

  const dispatch = createEventDispatcher()

  const onClickEdit = () => {
    dispatch('edit')
  }
</script>

<div class="flex m-2 mb-3">
  <div class="relative w-20 mr-4 flex flex-col items-center ">
    <div class="absolute top-[85%] h-[50%] border border-gray-200" />
    <div class="absolute top-[30%] size-3 rounded-full border border-blueStone bg-ottoman" />
    {#if index && !isNaN(index)}
      <div class="absolute mt-9 text-s text-paleSky">{t('workflow.step') + index}</div>
    {/if}
  </div>
 
  <button
    class="panel flex items-center border border-gray-200 rounded-xl pl-4 pr-6 py-3 w-full"
    on:click={() => onClickEdit()}
  >
    <div class="icon" />

    <div class="flex flex-col mx-4">
      {name}
      <div class="flex text-paleSky text-s">
        <span class="mr-12">{t('template.mail')}</span>
        <span class="mode manual">{t('template.manual')}</span>
      </div>
    </div>

  </button>

</div>

<style lang="postcss">
  .panel {
    @apply relative;

    &::after {
      content : '';
      mask: url("/img/icons/edit.svg");
      mask-repeat: no-repeat;
      @apply absolute size-5 bg-black right-0 mr-6;
    }
  }

  .icon {
    @apply border border-gray-300 rounded-lg size-8 relative;

    &::before {
      content : '';
      top:3px;
      left:3px;
      position: absolute;
      mask: url("/img/icons/mail.svg");
      mask-repeat: no-repeat;
      background: #a4a8ae;
      display: inline-block;
      height: 20px;
      width: 20px;
    }
  }

  .mode {
    @apply relative;

    &::before {
      content : '';
      top:-3px;
      left:-1.5rem;
      mask: url("/img/icons/finger-tap.svg");
      mask-repeat: no-repeat;
      @apply absolute size-5 bg-[#a4a8ae];
    }
  }

  .manual::before {
    mask: url("/img/icons/finger-tap.svg");
  }
</style>
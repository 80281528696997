import FECJournal, { FECGeneratorOptions } from '../models/fec-journal-items'
import { t } from '../../core-app/lib/i18n/i18nextWrapper'
import { get } from 'svelte/store'
import type { TaxonomyTag } from '../../voxy-app/enums/taxonomy-tag'
import { getBusinessDocumentMainTitle } from '../../voxy-app/services/business-documents-functions'
import type { BusinessDocument } from '../../voxy-app/models/business-document'
import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
import { getUserCompanyTimezone } from '../../core-app/util/timezone-utils'
import { BusinessDocumentKind } from '../../voxy-app/enums/business-document-kind'
import { SimpleDocumentsStore } from '../../voxy-app/stores/business-documents.store'
import { deepClone } from '../../core-app/util/object-deep-cloning'
import { BusinessDocumentStatus } from '../../voxy-app/enums/business-document-status'

/** Generate date in format YYYYMMDD
 * We use the locale en-ZA because it is the only one that supports the format YYYYMMDD
 * @param date
 * @param userTimeZone
 * */
const formatDateIntoYYYYMMDD = (date: string, userTimeZone: string): string => new Date(date).toLocaleString('en-ZA', {
  timeZone: userTimeZone,
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
})

/**
 *  Format amount into a string with two digits after decimal point
 *  used to remove the currency symbol
 * @param amount
 */
const formatAmount = (amount: number): string => {
  let invoiceFormatter: string

  invoiceFormatter = new Intl.NumberFormat(t('locales'), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount)

  return invoiceFormatter.replace(/\u202f/g, ' ')
}

/**
 * Create a new FEC Journal Item
 * This is the core function that creates the FEC Journal Item
 * @param index
 * @param businessDocument
 * @param userTimezone
 * @param options
 */
const createJournalItem = (index: number, businessDocument: BusinessDocument, userTimezone: string, options: FECGeneratorOptions): FECJournal[] => {
  const items: FECJournal[] = []
  let item: FECJournal = new FECJournal()

  // Item line
  item.JournalCode = options.accountReceivable ? 'VE' : 'AC'
  item.JournalLib = options.accountReceivable ? 'Ventes' : 'Achats'
  item.EcritureNum = index.toString()
  item.EcritureDate = formatDateIntoYYYYMMDD(businessDocument.dueDate.rfc3339, userTimezone).replaceAll('/', '')
  item.CompteNum = options.accountReceivable ? '411000' : '401000'
  item.CompteLib = 'CLIENTS'
  item.CompAuxNum = '00CLIENTS'
  item.CompAuxLib = businessDocument.customerCustomer.company.formalName?.toUpperCase()
  item.PieceRef = businessDocument.businessDocumentNumber
  item.PieceDate = formatDateIntoYYYYMMDD(businessDocument.dueDate.rfc3339, userTimezone).replaceAll('/', '')
  item.EcritureLib = (businessDocument.customerCustomer.company.formalName + ' ' +
    getBusinessDocumentMainTitle(BusinessDocumentKind.INVOICE, <TaxonomyTag[]>[], false) + '-' +
    businessDocument.businessDocumentNumber)?.toUpperCase()

  // Determine if Invoice is Voided & Should be credited to Receivable Account
  if (businessDocument.installmentResultIncludingTaxScaledValue < 0) {
    item.Debit = 0
    item.Credit = businessDocument.installmentResultIncludingTaxScaledValue ? Math.abs(businessDocument.installmentResultIncludingTaxScaledValue).toFixed(2).toString().replace('.', ',') : 0
  } else if (businessDocument.installmentResultIncludingTaxScaledValue > 0) {
    item.Debit = businessDocument.installmentResultIncludingTaxScaledValue ? businessDocument.installmentResultIncludingTaxScaledValue.toFixed(2).toString().replace('.', ',') : 0
    item.Credit = 0
  }
  item.EcritureLet = ''
  item.DateLet = ''
  item.ValidDate = item.EcritureDate
  item.Montantdevise = businessDocument.installmentResultIncludingTaxScaledValue ? Math.abs(businessDocument.installmentResultIncludingTaxScaledValue).toFixed(2).toString().replace('.', ',') : 0
  item.Idevise = businessDocument.currency ?? 'EUR0'

  items.push(item)

  // VAT line
  const vat: number = Math.abs(businessDocument.installmentResultIncludingTaxScaledValue
    ? businessDocument.installmentResultIncludingTaxScaledValue - businessDocument.installmentResultExcludingTaxScaledValue
    : 0)

  if (vat > 0) {
    const vatItem: FECJournal = deepClone(item)
    vatItem.CompAuxLib = ''
    vatItem.CompAuxNum = ''
    vatItem.CompteLib = options.accountReceivable ? 'TVA DEDUCTIBLE' : 'TVA COLLECTEE'
    vatItem.CompteNum = options.accountReceivable ? '445661' : '445711'

    if (businessDocument.installmentResultIncludingTaxScaledValue < 0) {
      vatItem.Credit = vat.toFixed(2).toString().replace('.', ',')
    } else {
      vatItem.Debit = vat.toFixed(2).toString().replace('.', ',')
    }
    vatItem.Montantdevise = vat.toFixed(2).toString().replace('.', ',')

    items.push(vatItem)
  }

  return items
}

const FECOptions = (): FECGeneratorOptions => {
  const siret: string = get(WorkspaceStore)?.company?.regulatory.frRegulScope.siret

  return {
    accountReceivable: true,
    accountPayable: false,
    fileTitle: `${siret}FEC${new Date().toISOString().slice(0, 10).replaceAll('-', '')}.txt`
  }
}

/**
 *  Generating a CSV file according to the French Administration requirements for the FEC report
 *
 *  31/01/2023 - 1.0.0 - Initial version
 * @param filename
 * @param rows
 * @param headers
 * **/

const exportToFile = (filename: string, rows: object[], headers?: string[]): void => {
  if (!rows || !rows.length) return

  const separator: string = '|'
  const keys: string[] = Object.keys(rows[0])
  const columnHeaders: string = (headers ?? keys).join(separator)

  const content: string = rows.map((row: Object) => keys.map((k: string) => {
    let cell = row[k] === null || row[k] === undefined ? '' : row[k]

    cell = cell instanceof Date
      ? cell.toLocaleString()
      : cell.toString().replace(/"/g, '')

    if (cell.search(/([",\n])/g) >= 0) cell = `${cell}`

    return cell
  }).join(separator)).join('\n')


  const csvContent: string = columnHeaders + '\n' + content

  const blob: Blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

  const link: HTMLAnchorElement = document.createElement('a')
  if (link.download !== undefined) {
    const url: string = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    link.style.visibility = 'hidden'
    document.documentElement.appendChild(link)
    link.click()
    document.documentElement.removeChild(link)
  }
}


/**
 * Generate a FEC file from a list of invoices
 * The file is generated in CSV format
 * The file is generated in UTF-8 encoding
 * The file is generated with a BOM (Byte Order Mark) to indicate the encoding
 * The file is generated with a header
 * Official FEC report only contains posted entries (finalized invoices)
 */
export function generateAndDownloadFECFile(startDate?: string, endDate?: string, invoice: boolean = true, creditNote: boolean = true): boolean {

  let header: string[] = [
    'JournalCode', //  0 - Journal entry code
    'JournalLib', //  1 - Journal entry label
    'EcritureNum', //  2 - Writing sequence number
    'EcritureDate', //  3 - The date on which the entry is posted
    'CompteNum', //  4 - Account number
    'CompteLib', //  5 - Account name
    'CompAuxNum', //  6 - Sub-account number
    'CompAuxLib', //  7 - Sub-account name
    'PieceRef', //  8 - Reference of the supporting document
    'PieceDate', //  9 - Date of supporting document
    'EcritureLib', // 10 - The wording of the accounting entry
    'Debit', // 11 - The debit amount
    'Credit', // 12 - Credit amount
    'EcritureLet', // 13 - Lettering for writing
    'DateLet', // 14 - Date of lettering
    'ValidDate', // 15 - Entry validation date
    'Montantdevise', // 16 - Currency amount
    'Idevise' // 17 - Currency identifier
  ]

  const options: FECGeneratorOptions = FECOptions()
  const userTimezone: string = getUserCompanyTimezone()
  const businessDocuments: BusinessDocument[] = get(SimpleDocumentsStore).filter((d: BusinessDocument) => {
    let res: boolean = d.businessDocumentStatus === BusinessDocumentStatus.FINAL
    if (res && invoice && creditNote) res = [BusinessDocumentKind.INVOICE, BusinessDocumentKind.CREDITNOTE].includes(d.businessDocumentKind)
    if (res && invoice && !creditNote) res = d.businessDocumentKind === BusinessDocumentKind.INVOICE
    if (res && creditNote && !invoice) res = d.businessDocumentKind === BusinessDocumentKind.CREDITNOTE

    const dte: string = d.createdDate.rfc3339.toString().substring(0, 10).replaceAll('-', '')
    if (res && startDate) res = dte >= startDate
    if (res && endDate) res = dte <= endDate

    return res
  })

  let rows: FECJournal[] = []
  for (const [index, businessDocument] of businessDocuments.entries()) {
    const journal: FECJournal[] = createJournalItem(index, businessDocument, userTimezone, options)
    rows.push(...journal)
  }

  if (rows.length > 0) {
    exportToFile(options.fileTitle, rows, header)

    return true
  }

  return false
}

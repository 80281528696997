import { dunningInvoicesService } from '../../dundy-app/services/dunning-invoices.service'
import { t } from '../../core-app/lib/i18n/i18nextWrapper'
import { todosService } from '../../dundy-app/services/todo.service'
import type { TodoListItem } from '../../dundy-app/models/todo-list-item'
import { formatToShortDate } from '../../core-app/util/date-utils'
import type Invoice from '../../dundy-app/models/invoice'
import { calculateDaysDue } from '../../order-to-cash-lib/helpers/calculate-days-due'
import * as XLSX from 'xlsx-js-style'
import FileSaver from 'file-saver'
import type { Workspace } from '../models/workspace'
import type InvoicesApiResponse from '../../dundy-app/models/invoices-api-response'
import { formatCurrencyWithOptions } from '../../voxy-app/utils/number-formatting-utils'
import { get } from 'svelte/store'
import { ComputedDataInvoicesStore } from '../../dundy-app/stores/computed-data-invoice.store'
import type { ColumnHeaderFormat } from '../models/export-workspace-to-file'
import { DataCellFormat } from '../models/export-workspace-to-file'

export function handleExportWorkspacesDataToExcel(workspaces: Workspace[]) {

  /* console.log('+++ workspaces nb #', workspaces.length) */
  const allWorkspacesInvoicesPromises: Promise<InvoicesApiResponse>[] = []
  const allTodosFromAllWorkspacesPromises: Promise<TodoListItem[]>[] = []
  for (const workspace of workspaces) {
    if (workspace.company && workspace.company.formalName) {
      const invoicesResponse: Promise<InvoicesApiResponse> = dunningInvoicesService.fetchInvoicesForWorkspace(workspace.workspaceId)
      allWorkspacesInvoicesPromises.push(invoicesResponse)
      const todos: Promise<TodoListItem[]> = todosService.fetchTodosListForWorkspace(workspace.workspaceId)
      allTodosFromAllWorkspacesPromises.push(todos)
    }
  }
  Promise.all<InvoicesApiResponse>(allWorkspacesInvoicesPromises)
    .then((allResults: InvoicesApiResponse[]) => allResults)
    .then((allWorkspacesInvoicesAPIResponses: InvoicesApiResponse[]) => {
      Promise.all<TodoListItem[]>(allTodosFromAllWorkspacesPromises)
        .then((allTodosFromAllWorkspaces: TodoListItem[][]) => {
          buildSpreadSheetDataThenDownload(allWorkspacesInvoicesAPIResponses, allTodosFromAllWorkspaces)
        })
        .catch(reason => console.error('Promise.all<TodoListItem[]>(allTodosFromAllWorkspacesPromises) failed', reason))
    })
    .catch(reason => console.error('Promise.all<InvoicesApiResponse>(allWorkspacesInvoicesPromises) failed', reason))
}

function buildSpreadSheetDataThenDownload(allWorkspacesInvoicesAPIResponses: InvoicesApiResponse[], allTodosFromAllWorkspaces: TodoListItem[][]) {
  const dataSheets: any = {}
  for (let anInvoicesResponse of allWorkspacesInvoicesAPIResponses) {
    const cumulatedAmountsByWorkspace = { due: 0, paid: 0 }
    const workspaceData = []
    let workspaceInvoices: Invoice[] = []
    if (!!anInvoicesResponse && !!anInvoicesResponse.invoices) {
      workspaceInvoices = anInvoicesResponse.invoices
    }
    const workspaceToDos: TodoListItem[] = allTodosFromAllWorkspaces.find((aWorkspaceTodoList: TodoListItem[]) => {
      if (aWorkspaceTodoList.length > 0) {
        return aWorkspaceTodoList[0].workspaceId === anInvoicesResponse.workspaceId
      } else {
        return false
      }
    })
    if (!!workspaceToDos) {
      workspaceToDos.forEach((todoListItem: TodoListItem, it: number) => {
        /* console.log('+++ todoListItem.scopeInvoiceNumbers', todoListItem.scopeInvoiceNumbers) */
        /* console.log('+++ workspaceInvoices', workspaceInvoices) */
        /* console.log('+++ workspaceInvoices invoiceNumbers', workspaceInvoices.map(d => d.invoiceNumber)) */
        const invoiceForTodo: Invoice = workspaceInvoices.find((invoice: Invoice) => invoice.invoiceNumber === todoListItem.scopeInvoiceNumbers[0])
        const todoDate: Date = new Date(todoListItem.created * 1000)
        if (!!invoiceForTodo) {
          /* console.log('+++ found invoice to do', invoiceForTodo) */
          if ((todoDate.getUTCMilliseconds() > ((new Date()).getUTCMilliseconds() - (7 * 24 * 60 * 60 * 1000)))) { // 7 days in ms
            workspaceData.push([
              (it + 1),
              formatToShortDate(todoDate),
              invoiceForTodo?.invoiceNumber,
              formatCurrencyWithOptions(invoiceForTodo?.amountIncludingTaxes, t('locales'), invoiceForTodo?.currency, true, 'symbol'),
              invoiceForTodo?.status,
              Math.abs(todoListItem.maxDueDays)
            ])
          }
        }
      })
    } else {
      workspaceData.push([])
      workspaceData.push([])
      workspaceData.push([])
      workspaceData.push([])
      workspaceData.push([])
      workspaceData.push([])
      workspaceData.push([ 'No ToDo here' ])
    }
    if (workspaceInvoices && workspaceInvoices.length) {
      workspaceInvoices.forEach((invoice: Invoice) => {
        const daysDue = calculateDaysDue(invoice.invoiceNumber, get(ComputedDataInvoicesStore))
        if (daysDue > 0 && daysDue < 7) {
          cumulatedAmountsByWorkspace.due += invoice.amountIncludingTaxes
        }
      })
      workspaceData.push([])
      workspaceData.push([])
      workspaceData.push([])
      workspaceData.push([])
      workspaceData.push([])
      workspaceData.push([])
      workspaceData.push([ t('workspaces.dataTable.footerText'), '', `${ formatCurrencyWithOptions(cumulatedAmountsByWorkspace.due, t('locales'), workspaceInvoices[0].currency, true, 'symbol') }` ])
    } else {
      workspaceData.push([])
      workspaceData.push([])
      workspaceData.push([])
      workspaceData.push([])
      workspaceData.push([])
      workspaceData.push([])
      workspaceData.push([ 'No Invoice here' ])
    }
    const dataSheetName: string = t('workspaces.dataTable.dataSheet') + (Object.keys(dataSheets).length + 1)
    dataSheets[dataSheetName] = [
      [ t('workspaces.dataTable.heading') ],
      [
        t('workspaces.dataTable.columnOneHeading'),
        t('workspaces.dataTable.columnTwoHeading'),
        t('workspaces.dataTable.columnThreeHeading'),
        t('workspaces.dataTable.columnFourHeading'),
        t('workspaces.dataTable.columnFiveHeading'),
        t('workspaces.dataTable.columnSixHeading')
      ],
      ...workspaceData
    ]

  }
  createXLSXAndDownloadWorkspaceReport(dataSheets, 'WorkspacesData.xlsx')
}


function createXLSXAndDownloadWorkspaceReport(dataSheets: any[], outputFileName: string) {

  const workBook: XLSX.WorkBook = XLSX.utils.book_new()

  Object.keys(dataSheets).forEach(k => {

    const data = dataSheets[k]

    // Create the sheet with data.
    XLSX.utils.book_append_sheet(workBook, XLSX.utils.aoa_to_sheet(data), k)

    /* console.log(workBook) */

    // Merge cells, resize columns and style cells.
    workBook.Sheets[k]['!cols'] = [ { wch: 20 }, { wch: 20 }, { wch: 15 }, { wch: 20 }, { wch: 15 }, { wch: 15 } ]
    workBook.Sheets[k]['!merges'] = [ { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } }, {
      s: {
        r: (data.length - 1),
        c: 0
      }, e: { r: (data.length - 1), c: 1 }
    } ]
    workBook.Sheets[k].A1.s = {
      font: {
        name: 'Arial',
        sz: 20,
        bold: true,
        color: { rgb: 'F47663' }
      },
      alignment: {
        horizontal: 'center'
      }
    }
    const columnHeaderFormat: ColumnHeaderFormat = {
      font: {
        name: 'Arial',
        sz: 14,
        bold: true,
        color: { rgb: '606F82' }
      },
      alignment: {
        horizontal: 'center'
      }
    }
    workBook.Sheets[k].A2.s = columnHeaderFormat
    workBook.Sheets[k].B2.s = columnHeaderFormat
    workBook.Sheets[k].C2.s = columnHeaderFormat
    workBook.Sheets[k].D2.s = columnHeaderFormat
    workBook.Sheets[k].E2.s = columnHeaderFormat
    workBook.Sheets[k].F2.s = columnHeaderFormat
    const dataCellFormat: DataCellFormat = {
      font: {
        name: 'Arial',
        sz: 12,
        bold: false,
        color: { rgb: '979797' }
      },
      alignment: {
        horizontal: 'center'
      }
    }
    data.forEach((row: any[], it: number) => {
      if (it > 1 && it < (data.length - 1)) {
        row.forEach((val, iter) => {
          const columnLetter: string = String.fromCharCode(iter + 'A'.charCodeAt(0))
          const rowPosition: number = it + 1
          workBook.Sheets[k][`${ columnLetter }${ rowPosition }`].s = it > 4 ? dataCellFormat : {
            ...dataCellFormat,
            font: { ...dataCellFormat.font, color: { rgb: 'C54A3E' } }
          }
        })
      } else if (it === (data.length - 1)) {
        workBook.Sheets[k][`A${ data.length }`].s = {
          font: {
            name: 'Arial',
            sz: 18,
            bold: false,
            color: { rgb: '606F82' }
          },
          alignment: {
            horizontal: 'center',
            vertical: 'middle'
          }
        }
        try {
          workBook.Sheets[k][`C${ data.length }`].s = {
            font: {
              name: 'Arial',
              sz: 18,
              bold: false,
              color: { rgb: '979797' }
            },
            alignment: {
              horizontal: 'left',
              vertical: 'middle'
            }
          }
        } catch (e) {
          /* console.log(e) */
        }
      }
    })
  })
  const excelBuffer: any = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' })
  const fileData: Blob = new Blob([ excelBuffer ], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
  FileSaver.saveAs(fileData, outputFileName)
}

<script lang="ts">
    import { formatCurrencyWithOptions } from '../../../../voxy-app/utils/number-formatting-utils'
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
    import type { AppliedDunningFilters } from '../../../models/applied-dunning-filters'

    export let color: string
    export let title: string
    export let totalAmount: number
    export let currency: string
    export let filters: AppliedDunningFilters
    export let filterKey: string
    const handleClick = () => {
      filters[filterKey].value = !filters[filterKey].value
    }

    /** Check if at least one filter is active */
    $: atLeastOneActive = Object.values(filters).some(filter => filter.value)

    /** Check if this filter is active */
    $: isActive = filters[filterKey]?.value
</script>

<div class={`flex flex-col text-xs cursor-pointer text-white fixed-height m-2 min-w-[13rem] ${atLeastOneActive && !isActive ? 'opacity-30' : ''}`}
     on:click={handleClick}
     role="button"
     tabindex="0"
     on:keydown={e => e.key === 'Enter' && handleClick()}>
    <div class="flex items-center">
        <div class={`rounded h-2 w-2 ${totalAmount > 0 ? color : 'bg-zinc-300'}`}>&nbsp;</div>
        <h6 class={`ml-2 text-s ${totalAmount > 0 ? '' : 'text-zinc-400'}`}>{title}</h6>
    </div>
    {#if totalAmount > 0}
        <div class="flex flex-col ml-4">
            <p class="text-zinc-300">{formatCurrencyWithOptions(totalAmount, t('locales'), currency, true, 'symbol')}</p>
        </div>
    {/if}
</div>

<style>
    .fixed-height {
        height: 40px;
        overflow: hidden;
    }
</style>

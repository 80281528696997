import type { Customer } from '../models/customer'
import { WorkspaceHelper } from './workspace-helper'
import { get } from 'svelte/store'
import { CustomersStore } from '../stores/customers.store'
import { DunningInvoicesStore } from '../../dundy-app/stores/dunning-invoices.store'
import { InvoiceStatus } from '../../dundy-app/enums/invoice-status'
import type Invoice from '../../dundy-app/models/invoice'
import { DunningBusinessDocumentKind } from '../../dundy-app/enums/dunning-business-document-kind'

export class CustomersHelper {
  static newCustomer(isPrivateIndividual:boolean = false): Customer {
    return {
      company: WorkspaceHelper.newCompany(),
      isPrivateIndividual
    }
  }
  /**
     * Returns true if the two customers have the same details, except for the companyId.
     * @param customerA
     * @param customerB
     */
  static sameCustomerDetails(customerA: Customer, customerB: Customer): boolean {
    return JSON.stringify(customerA, (k, v) => k === 'companyId' ? undefined : v) ===
            JSON.stringify(customerB, (k, v) => k === 'companyId' ? undefined : v)
  }
  static hasCustomerWithSameDetails(customer: Customer): boolean {
    return !!get(CustomersStore).find((c: Customer) => CustomersHelper.sameCustomerDetails(c, customer))
  }
  /**
     * Returns the total amount of outstanding invoices for the given customer.
     * @param customer
     */
  static calculateDueAmount(customer: Customer): number {
    const outstandingInvoices: Array<Invoice> =
            get(DunningInvoicesStore).filter((invoice: Invoice) => (
              new Date(invoice.dateDue) > new Date())
                && invoice.toId === customer.company.companyId
                && invoice.status === InvoiceStatus.OUTSTANDING
                && invoice.documentKind === DunningBusinessDocumentKind.INVOICE
                && !invoice.deleted
                && invoice.isTracked
                && invoice.completed)

    return outstandingInvoices.reduce((a: number, b: Invoice) => a + b.amountIncludingTaxes, 0)
  }
  /**
     * Returns the total amount of overdue invoices for the given customer.
     * @param customer
     */
  static calculateOverdueAmount(customer: Customer): number {
    const overdueInvoices: Array<Invoice> =
            get(DunningInvoicesStore).filter((invoice: Invoice) => (
              new Date(invoice.dateDue) < new Date())
                && invoice.toId === customer.company.companyId
                && invoice.status === InvoiceStatus.OUTSTANDING
                && invoice.documentKind === DunningBusinessDocumentKind.INVOICE
                && !invoice.deleted
                && invoice.isTracked
                && invoice.completed)

    return overdueInvoices.reduce((a: number, b: Invoice) => a + b.amountIncludingTaxes, 0)
  }

}
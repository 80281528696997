export enum FeedbackEntity {
  GENERAL = 'GENERAL',
  INVOICES = 'INVOICES',
  TODOS = 'TODOS',
  PROFILE = 'PROFILE',
  WORKSPACE = 'WORKSPACE',
  CUSTOMERS = 'CUSTOMERS',
  CONTACTS = 'CONTACTS',
  BBB_BANK_CONNECTION = 'BBB_BANK_CONNECTION'
}

export enum ToastType {
  Success = 'Success',
  Error = 'Error'
}
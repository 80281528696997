<script lang="ts">

  import type { Files } from 'filedrop-svelte'
  import { filedrop } from 'filedrop-svelte'
  import { createEventDispatcher, onDestroy } from 'svelte'
  import { t } from '../../../core-app/lib/i18n/i18nextWrapper'

  let files: Files
  let uploadProgress: number = 0
  let progressBarPercentage: number = 0
  let progressBarPercentageCalculationInterval: any = null

  const dispatch = createEventDispatcher()

  onDestroy(() => {
    clearInterval(progressBarPercentageCalculationInterval)
  })

  function startListeningToProgress() {
    if (progressBarPercentageCalculationInterval) {
      clearInterval(progressBarPercentageCalculationInterval)
    }
    let intervalTime = 500 // Set the initial interval time
    progressBarPercentageCalculationInterval = setInterval(() => {
      const totalProgress = 100 // Set the total progress to 100%
      const newProgressBarPercentage = Math.round((uploadProgress / totalProgress) * 100)

      // Slow down the progress bar towards the end
      const remainingProgress = totalProgress - newProgressBarPercentage
      if (remainingProgress <= 20) {
        intervalTime = 1000
      }
      progressBarPercentage = newProgressBarPercentage

      if (progressBarPercentage >= 100) {
        clearInterval(progressBarPercentageCalculationInterval)
        uploadProgress = 0
      }
    }, intervalTime)
  }

  function handleFileUpload() {

    const uploadedFiles: any[] = []

    startListeningToProgress()

    for (const file of files.accepted) {
      const reader = new FileReader()
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      reader.addEventListener('progress', (evt) => {
        uploadProgress = Math.round((evt.loaded / evt.total) * 100)
      })
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      reader.onload = (event) => {
        const fileContent = event?.target?.result as string
        uploadedFiles.push({
          fileName: file.name,
          type: file.type || 'text/plain',
          size: file.size,
          contentBase64: fileContent.substring(fileContent.lastIndexOf(',') + 1),
          originalFile: file
        })
        if (files.accepted.length === uploadedFiles.length) {
          dispatch('uploaded', uploadedFiles)
        }
      }

      reader.readAsDataURL(file)
    }
  }
</script>

<!--https://docs.sendgrid.com/ui/sending-email/attachments-with-digioh#-Limitations (recommends 10000000 bytes max.)-->
<div on:filedrop={(e) => {files = e.detail.files; handleFileUpload()}}
     use:filedrop={{ fileLimit: 1000, maxSize: 10000000 }}
     data-cy="attachment-upload-area"
     class="flex w-full align-center justify-center hover:border-mojo cursor-pointer border border-athensGray rounded p-8 bg-white mt-6">

  {#if !uploadProgress}
    <div class="w-full justify-self-center text-center">
                <span class="font-bold">{t('uploadAttachments.dragAndDrop')} <span
                  class="text-dundyOrange">{t('uploadAttachments.attachment')}</span></span><br/>
      <span class="text-sm">{t('uploadAttachments.or')} <span
        class="text-dundyOrange underline">{t('uploadAttachments.browseFiles')}</span> {t('uploadAttachments.onYourComputer')}</span>
    </div>
  {/if}
  {#if uploadProgress}
    <div class="w-full justify-self-center text-center">
      <span class="font-bold">{t('uploadAttachments.uploadingAttachment')}</span><br/>
      <span class="font-bold text-dundyOrange mb-2">{progressBarPercentage}%</span><br/>
      <div class="rounded-lg border border-loblolly bg-whisper h-3 mt-2" style="width: 100%"></div>
      <div class="rounded border border-hotCinnamon bg-dundyOrange h-3 -mt-3"
           style="width: {progressBarPercentage}%"></div>
    </div>
  {/if}
  {#if files && files.rejected && files.rejected.length}
    {#if files.rejected[0].error.code === 2 || files.rejected[0].error.code === 3}
      <span class="text-mojo">{t('uploadAttachments.errors.maxSize')}</span>
    {:else if files.rejected[0].error.code === 0}
      <span class="text-mojo">{t('uploadAttachments.errors.accept')}</span>
    {:else}
      <span class="text-mojo">{files.rejected[0].file.name} - {files.rejected[0].error.message}</span>
    {/if}
  {/if}
</div>
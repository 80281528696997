export class Feedback {
  numberOfInvalidItems: number
  feedbackDetails: FeedbackDetails[]
  feedbackLevel: 'Error' | 'Warning' | 'Info' | 'Success' | 'None'
  feedbackMessage: string
  responseStatus: number
}

export class FeedbackDetails {
  detailsOnProperties: Map<string, FeedbackOnProperty>
  itemId: string
  itemIncomplete: boolean
  itemIsNotTracked: boolean
  numberOfInvalidProperties: number
}

export class FeedbackOnProperty {
  isFieldValid: boolean
  validationLevel: string
  canTheItemBeFinalized: boolean
  comment: string
}
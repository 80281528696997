import { navigate } from 'svelte-routing'
import { auth0JsExpiresAt, auth0JsPostLoginRedirect } from '../lib/auth0authentication/authStore'
import { eventsManager } from '../events/event-manager'
import { EventType } from '../events/event-type'
import { get } from 'svelte/store'

let checkForAuthenticationInterval: any

/**
 * Redirects to the signin page if the user is not signed in.
 */
export function actionWhenNotSignedIn(): void {
  if (window.location.pathname === '/onboarding/start') return
  /* console.log('%cactionWhenNotSignedIn() window && window.location', 'background: orange;', window && window.location) */
  if (window.location.pathname !== '/signin') {
    localStorage.clear()
    auth0JsPostLoginRedirect.set(window.location.pathname)
    navigate('/signin')
  }
}

/**
 * Used to check if the authentication token is expired.
 */
export function checkIfAuthenticationTokenIsExpired(): boolean {
  // to test remove 71000000 millis. A few minutes less than 20h.
  return new Date(get(auth0JsExpiresAt) * 1000) < new Date()
}

/**
 * Event handler for the AUTHENTICATED event.
 */
eventsManager.on(EventType.AUTHENTICATED, () => {
  if (checkForAuthenticationInterval) {
    clearInterval(checkForAuthenticationInterval)
  }
  checkForAuthenticationInterval = setInterval(() => {
    if (location.pathname !== '/signin' && location.pathname !== '/error') {
      if (checkIfAuthenticationTokenIsExpired()) {
        actionWhenNotSignedIn()
      }
    }
  }, 10000)
}, 'auth.service.ts')


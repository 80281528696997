import { get, type Writable, writable } from 'svelte/store'
import { eventsManager } from '../../core-app/events/event-manager'
import { EventType } from '../../core-app/events/event-type'
import { encrypt256Hash } from '../../core-app/util/encryption'
import type { Contact } from '../models/contact'
import initializationStore from '../../core-app/stores/initialization.store'
import type GlobalInitializationStore from '../../core-app/models/initialization-store'
import { deepClone } from '../../core-app/util/object-deep-cloning'
import { ExchangeDate } from '../../core-app/models/exchange-date'

/* console.log('contacts.store.ts') */

const getContacts = (): Contact[] => {
  const existingValue: string = localStorage.getItem('ContactsStore')
  
  const emptyValue: Contact[] = <Contact[]>[]
  if (!existingValue || existingValue === 'undefined') {
    
    return emptyValue
  }
  
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  if (existingValue === '{}') {
    return emptyValue
  }
  
  return <Contact[]>JSON.parse(existingValue)
}
export let ContactsStore: Writable<Contact[]> = writable<Contact[]>(getContacts())

export const initializeContactsStore = (data: Contact[]) => {

  if (!get(initializationStore).contactsStoreInitialized) {

    initializationStore.update((store: GlobalInitializationStore) => {
      store.workspaceStoreInitialized = true
      
      return store
    })

    localStorage.setItem('ContactsStore', JSON.stringify(data, null, 4))
    ContactsStore.set(data)

    let isContactsStoreSubscriptionDefined: boolean = false
    // NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
    ContactsStore.subscribe(newContactList => {
      if (!isContactsStoreSubscriptionDefined) {
        /* console.log('ContactsStore subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently') */
        isContactsStoreSubscriptionDefined = true
        
        return // we avoid the .subscribe() execution at the subscription occurrence
      }
      if (newContactList) {

        const oldContactList = JSON.parse(localStorage.getItem('ContactsStore'))
        const oldContactListHash: string = encrypt256Hash(oldContactList)
        const newContactListHash: string = encrypt256Hash(newContactList)

        if (oldContactListHash !== newContactListHash) {
          newContactList.forEach((newContact: Contact) => {
            const oldContact = oldContactList.find((contact: Contact): boolean => contact.contactId === newContact.contactId)
            if (oldContact) {
              const oldContactHash: string = encrypt256Hash(oldContact)
              const newContactHash: string = encrypt256Hash(newContact)
              if (oldContactHash !== newContactHash) {
                const contactCreatedDate: ExchangeDate = (!!newContact?.createdDate ? newContact?.createdDate : ExchangeDate.newDate(new Date()))
                const newContactWithModifiedDate: Contact = <Contact>{
                  ...deepClone(newContact),
                  modifiedDate: ExchangeDate.newDate(new Date()),
                  createdDate: contactCreatedDate
                }
                eventsManager.emit(EventType.CONTACT_CHANGED, newContactWithModifiedDate, 'ContactsStore')
                Object.keys(newContactWithModifiedDate).forEach((k: string) => {
                  if (newContactWithModifiedDate[k] !== oldContact[k]) {
                    eventsManager.emit(EventType.CONTACT_PROPERTY_CHANGED, newContactWithModifiedDate[k], 'ContactsStore')
                  }
                })
              }
            } else {
              eventsManager.emit(EventType.CONTACT_CREATED, newContact, 'ContactsStore')
            }
          })
          oldContactList.forEach((oldContact: Contact) => {
            if (oldContact.contactId) {
              const contactFoundInNewList: Contact = newContactList.find((contact: Contact): boolean => oldContact.contactId === contact.contactId)
              if (!contactFoundInNewList) {
                eventsManager.emit(EventType.CONTACT_DELETED, oldContact, 'ContactsStore')
              }
            }
          })
          localStorage.setItem('ContactsStore', JSON.stringify(newContactList, null, 4))
          eventsManager.emit(EventType.CONTACT_LIST_CHANGED, newContactList, 'ContactsStore')
        }
      }
    })

    eventsManager.emit(EventType.CONTACT_LIST_LOADED, data, 'ContactsStore')

    /* console.log('%c ContactsStore initialized.', 'color: #8A99AC') */
  }
}

export const countriesEUMembersFR: { code: string, name: string } [] = [
  { 'code': 'AT', 'name': 'Autriche' },
  { 'code': 'BE', 'name': 'Belgique' },
  { 'code': 'BG', 'name': 'Bulgarie' },
  { 'code': 'HR', 'name': 'Croatie' },
  { 'code': 'CY', 'name': 'Chypre' },
  { 'code': 'CZ', 'name': 'République tchèque' },
  { 'code': 'DK', 'name': 'Danemark' },
  { 'code': 'EE', 'name': 'Estonie' },
  { 'code': 'FI', 'name': 'Finlande' },
  { 'code': 'FR', 'name': 'France' },
  { 'code': 'DE', 'name': 'Allemagne' },
  { 'code': 'GR', 'name': 'Grèce' },
  { 'code': 'HU', 'name': 'Hongrie' },
  { 'code': 'IE', 'name': 'Irlande' },
  { 'code': 'IT', 'name': 'Italie' },
  { 'code': 'LV', 'name': 'Lettonie' },
  { 'code': 'LT', 'name': 'Lituanie' },
  { 'code': 'LU', 'name': 'Luxembourg' },
  { 'code': 'MT', 'name': 'Malte' },
  { 'code': 'NL', 'name': 'Pays-Bas' },
  { 'code': 'PL', 'name': 'Pologne' },
  { 'code': 'PT', 'name': 'Portugal' },
  { 'code': 'RO', 'name': 'Roumanie' },
  { 'code': 'SK', 'name': 'Slovaquie' },
  { 'code': 'SI', 'name': 'Slovénie' },
  { 'code': 'ES', 'name': 'Espagne' },
  { 'code': 'SE', 'name': 'Suède' }
]
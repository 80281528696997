import type { ColDef } from 'ag-grid-community'
import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
import DatatableDateRenderer from '../../../../core-app/lib/ui-kit/DatatableDateRenderer'
import DatatableOperationRenderer from './Renderers/DatatableOperationRenderer'
import DatatableAmountOfMoneyWithLetterAndSignRenderer from './Renderers/DatatableAmountOfMoneyRendererWithLetterAndSign'
import DatatableCashApplicationStatusRenderer from './Renderers/DatatableCashApplicationStatusRenderer'


export const transactionsColumnDefs: ColDef[] = [
  {
    headerName: t('transactions.header.date'),
    field: 'date',
    cellRenderer: DatatableDateRenderer,
    sortable: true,
    editable: false,
    suppressColumnsToolPanel: true,
    minWidth: 150,
    unSortIcon: true
  },
  {
    headerName: t('transactions.header.operation'),
    field: 'bank_description',
    cellRenderer: DatatableOperationRenderer,
    sortable: false,
    editable: false,
    suppressColumnsToolPanel: true,
    minWidth: 570,
    unSortIcon: true

  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: false,
    editable: false,
    cellRenderer: DatatableCashApplicationStatusRenderer
  },
  {
    headerName: t('transactions.header.amount'),
    field: 'amount',
    headerClass: 'collapsible-column',
    cellRenderer: DatatableAmountOfMoneyWithLetterAndSignRenderer,
    sortable: true,
    editable: false,
    suppressColumnsToolPanel: true,
    unSortIcon: true
  }
]

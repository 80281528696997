import { writable } from 'svelte/store'

/* console.log('endToEndTest.store.ts') */

// name : isEndToEndTest
// Type : boolean
// Desc : is the "user is a test user" ? are the actions triggered by Cypress ?
// Persistence : localStorage
export let isEndToEndTest = writable((localStorage.getItem('isEndToEndTest') === 'true') || false)
// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
isEndToEndTest.subscribe((val) => {
  /* console.log('- localStorage.setItem("isEndToEndTest", val.toString())', val.toString()) */
  localStorage.setItem('isEndToEndTest', val.toString())
})

// name : isEndToEndTestStatusPersisted
// Type : boolean
// Desc : is the "user is a test user" fact persisted in the database ? is the fact actions are triggered by Cypress in the workspace in the database ?
// Persistence : localStorage
export let isEndToEndTestStatusPersisted = writable((localStorage.getItem('isEndToEndTestStatusPersisted') === 'true') || false)
// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
isEndToEndTest.subscribe((val) => {
  /* console.log('- localStorage.setItem("isEndToEndTestStatusPersisted", val.toString())', val.toString()) */
  localStorage.setItem('isEndToEndTestStatusPersisted', val.toString())
})

// name : fakeData
// Type : pure data Object
// Desc : the fake data injected programmatically (i.e. impossible to inject naturally by simulating user interaction, like open banking)
// Persistence : localStorage
const getFakeDataInitialValue = () => {
  const existingValue: string = localStorage.getItem('fakeData')
  const emptyValue = {}
  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  if (existingValue === '{}') {
    return emptyValue
  }
  
  return JSON.parse(existingValue)
}
export let fakeData = writable(getFakeDataInitialValue())
// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
fakeData.subscribe(val => {
  localStorage.setItem('fakeData', JSON.stringify(val, null, 4))
})

<script lang='ts'>
  import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
  import { createEventDispatcher } from 'svelte'
  import type Company from '$src/crm-app/models/company'

  const dispatch = createEventDispatcher()

  export let companies: Readonly<Company[]>
  export let isFetching: Readonly<boolean>
  export let newSearch: Readonly<boolean>
  export let noMore: Readonly<boolean>

  let elContainer:HTMLDivElement
  let elObserve:HTMLDivElement

  let observer = new IntersectionObserver(async (entries:IntersectionObserverEntry[]) => {
    for (const entry of entries) {
      if (entry.isIntersecting) dispatch('scroll')
    }
  }, {
    root: null,
    rootMargin: '0px',
    threshold: 0.1
  })
  
  $: {
    if (elContainer && newSearch) elContainer.scrollTo(0, 0)
    if (elObserve) observer.observe(elObserve)
  }

  const getActivity = (company:Company):string => {
    const activity = company?.regulatory.frRegulScope.inseeData.activity.level4FullyDetailed

    return activity.descFr + ' - ' + activity.code
  }

  const onSelectCompany = (company:any) => {
    dispatch('selectCompany', company)
  }
</script>

<div bind:this={elContainer} class="company" class:disabled='{isFetching}'>
  
  {#each companies as company}
    <a class="select" href=";" on:click|preventDefault={() => onSelectCompany(company)}>
      <span class="formal-name">
        {company.formalName}
        {#if company.usualName}
          <span class="usual-name">({company.usualName})</span>
        {/if}
      </span>
      <span class="address">{company.mailAddress.street},</span>
      <span class="address">{company.mailAddress.zipCode} {company.mailAddress.city}</span>
      <span class="naf">{getActivity(company)}</span>
    </a>
  {/each}
  
  {#if companies.length}
    <div>
      {#if noMore}
        <div class="py-2 text-paleSky italic text-sm text-center">
          {t('onboarding.companyInfo.list.noMore')}
        </div>
      {:else}
        <div bind:this={elObserve} class="text-center">...</div>
      {/if}
    </div>
  {/if}
  
</div>

<style lang="postcss">
  .company {
    max-height: 40vh;
    max-height: 40svh;
    @apply my-3 flex flex-col relative overflow-y-auto overflow-x-hidden;

    &.disabled {
      @apply blur-[2px] select-none pointer-events-none;
    }

    a {
      @apply mr-2
    }

    .select {
      @apply flex flex-col w-full relative mb-4 rounded-md border border-zinc-200 p-[15px_20px] cursor-pointer;

      &:hover {
        @apply border-[var(--primary-color)] border-2 p-[14px_19px];
      }

      span {
        @apply text-xs text-gray-500 m-0 px-2;

        &.formal-name {
          @apply uppercase text-black text-base font-bold;
        }
        &.usual-name {
          @apply uppercase text-paleSky text-base;
        }
        &.address {
          @apply text-black capitalize max-w-80;
        }
        &.naf {
          @apply mt-1
        }
      }
    }
  }
</style>
<script>
  import { Route, Router } from 'svelte-routing'
  import AgingBalance from '../pages/AgingBalance.svelte'
  import CustomerList from '../pages/customers/CustomerList.svelte'
  import CustomerManage from '../pages/customers/CustomerManage.svelte'
  import CustomerOverview from '../pages/customers/CustomerOverview.svelte'
</script>

<Router>
    <Route path="aging-balance">
        <AgingBalance/>
    </Route>
    <Route path="/manage">
      <CustomerList/>
    </Route>
    <Route path="new">
      <CustomerManage />
    </Route>
    <Route let:params path=":customerId">
        <CustomerOverview customerId={params.customerId}/>
    </Route>
    <Route let:params path=":customerId/overview">
        <CustomerOverview customerId={params.customerId}/>
    </Route>
    <Route let:params path=":customerId/edit">
      <CustomerManage customerId={params.customerId}/>
    </Route>
    <Route let:params path=":customerId/contacts/new">
      <CustomerManage customerId={params.customerId}/>
    </Route>
    <Route let:params path=":customerId/contacts/:contactId/edit">
      <CustomerManage customerId={params.customerId} contactId={params.contactId}/>
    </Route>
</Router>


<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte'
  import { doesLegalStructureRequireShareCapital } from '$src/core-app/services/companies-legal-structures'
  import TextInput from '$core/lib/ui-kit/TextInput.svelte'
  import Company from '$crm/models/company'
  import StyledSelect from '../../lib/ui-kit/StyledSelect.svelte'
  import {
    getCountries,
    getCountryNameForCountryCode,
    isClientCountryCodeAMemberOfEuropeanUnion
  } from '$core/services/countries-pure-functions'
  import { currentProfileLanguage, t } from '$core/lib/i18n/i18nextWrapper'
  import { validateEmail } from '$src/core-app/services/validator'
  import { isAPE, isSIREN, isSIRET, isVAT } from '$src/core-app/util/regexp-utils'
  import { EuCompanyRegulatory } from '$crm/models/company'
  import mixpanel from 'mixpanel-browser'
  import InputRadio from '$src/shared/components/input/InputRadio.svelte'
  import Checkbox from '$src/core-app/lib/ui-kit/Checkbox.svelte'
  import { getLegalCategoryLevel3ByCode, legalOldCategories } from '$shared/data/legalCategories'
  import { objectDeepClone } from '$src/shared/utils/object'
  import { getNafByCode } from '$src/shared/data/naf'

  export let company: Company | null
  export let firstName: string = ''
  export let lastName: string = ''
  export let mail: string = ''
  export let mobilePhone: string = ''
  export let officePhone: string = ''
  export let displayCancelButton: Readonly<boolean> = true
  export let isEditing: boolean
  export let isManual: Readonly<boolean>
  export let isPrivateIndividual: Readonly<boolean> = false
  export let isForeignCompany: Readonly<boolean> = false
  export let allowChangeCountry: boolean = true

  const dispatch = createEventDispatcher()

  const countriesList = getCountries(currentProfileLanguage())
  const defaultCountryCode: string = 'FR'
  const defaultCountryName: string = 'France'

  let initCompany: Company | null
  let legalStructure: string = ''
  let legalSubStructure: string = ''
  let isManualSaved: boolean = false

  let focus: Record<string, boolean> = {
    formalName: false,
    firstName: false,
    lastName: false,
    mail: false,
    mobilePhone: false,
    officePhone: false,
    country: false,
    naf: false,
    siren: false,
    vat: false,
    street: false,
    zipCode: false,
    city: false,
    shareCapital: false
  }

  $: {
    if (company && (legalSubStructure || legalStructure)) {
      const value = legalSubStructure || legalStructure
      const ls = legalOldCategories.find(c => c.value === value)
      
      if (ls?.level2 && ls?.level3) {
        if (company?.regulatory?.frRegulScope?.legalFormCodeLevel2) company.regulatory.frRegulScope.legalFormCodeLevel2 = ls.level2.level2CatCode
        if (company?.regulatory?.frRegulScope?.legalFormCodeLevel3) company.regulatory.frRegulScope.legalFormCodeLevel3 = ls.level3.level3CatCode
      }

      if (company?.regulatory?.frRegulScope && legalStructure !== 'EI/EIRL') {
        company.regulatory.frRegulScope.isAutoEntrepreneur = false
        company.regulatory.frRegulScope.eligibleToVAT = true
      }
    }
  }

  $:showShareCapital = doesLegalStructureRequireShareCapital(company?.regulatory?.frRegulScope?.legalFormCodeLevel2?.toString() ?? '')

  $:isEuropean = isClientCountryCodeAMemberOfEuropeanUnion(company?.mailAddress?.countryCode ?? '', currentProfileLanguage())

  $:isFrench = company?.mailAddress?.countryCode === 'FR'

  $:legalStructureOther = legalOldCategories
    .filter(c => !['EURL', 'SASU', 'EI/EIRL'].includes(c.value))
    .sort((a, b) => a.label.localeCompare(b.label))

  $:errors = {
    formalName: !isPrivateIndividual && company?.formalName?.length < 2 ? t('onboarding.companyInfo.edit.errMinSize', { n: 2 }) : '',
    firstName: isPrivateIndividual && firstName?.length < 2 ? t('onboarding.companyInfo.edit.errMinSize', { n: 2 }) : '',
    lastName: isPrivateIndividual && lastName?.length < 2 ? t('onboarding.companyInfo.edit.errMinSize', { n: 2 }) : '',
    shareCapital: showShareCapital ? company?.shareCapital ? '' : t('onboarding.companyInfo.edit.errMinValue', { v: 1 }) : '',
    street: company?.mailAddress?.street ? '' : t('onboarding.companyInfo.edit.errRequired'),
    zipCode: company?.mailAddress?.zipCode?.length ?? 0 > 1 ? '' : t('onboarding.companyInfo.edit.errMinSize', { n: 2 }),
    city: company?.mailAddress?.city !== '' ? '' : t('onboarding.companyInfo.edit.errRequired'),
    country: (isPrivateIndividual || isForeignCompany) && !company?.mailAddress?.countryCode ? t('onboarding.companyInfo.edit.errRequired') : '',
    mail: isPrivateIndividual && !validateEmail(mail) ? t('customerForm.email.error') : '',
    // mobilePhone: isPrivateIndividual && !officePhone && mobilePhone?.length < 10 ? t('customerForm.mobilePhone.error') : '',
    // officePhone: isPrivateIndividual && !mobilePhone && officePhone?.length < 10 ? t('customerForm.officePhone.error') : '',
    naf: !isPrivateIndividual && !company?.registrationPending && !isAPE(company?.mailAddress?.countryCode ?? '', company?.regulatory?.frRegulScope?.nafCodeLevel4 ?? '') ? 'ERR NAF' : '',
    vat: !isPrivateIndividual && !company?.registrationPending && isEuropean && !isVAT(company?.mailAddress?.countryCode ?? '', company?.regulatory?.euRegulScope?.euIntraVat ?? '', company?.regulatory?.frRegulScope?.siren) ? t('customerForm.vat.error') : '',
    siren: !isPrivateIndividual && !company?.registrationPending && !isSIREN(company?.regulatory?.frRegulScope?.siren ?? '') ? 'ERR siren' : '',
    siret: !isPrivateIndividual && !company?.registrationPending && !isSIRET(company?.regulatory?.frRegulScope?.siret ?? '', company?.regulatory?.frRegulScope?.siren) ? 'ERR siret' : ''
  }

  $:hasError = Object.values(errors).filter(v => v !== '').length > 0
  $:isOnboarding = window.location.pathname.includes('onboarding')

  const loadLegalStructure = () => {
    isManualSaved = false
    initCompany = objectDeepClone(company) as Company // backup

    if (legalStructure || legalSubStructure) return 

    const l3 = legalOldCategories.find(c => c.level3?.level3CatCode === company?.regulatory?.frRegulScope?.legalFormCodeLevel3)

    // new company
    if (!l3) {
      if (legalStructure === '') legalSubStructure = legalStructureOther[0].value

      return
    }

    // load existing
    if (['EURL', 'SASU', 'EI/EIRL'].includes(l3.value)) {
      legalStructure = l3.value
    } else {
      legalStructure = ''
      legalSubStructure = l3.value
    }
  }

  const onCompanyEdition = () => {
    if (isOnboarding) {
      mixpanel.track('onb.cpn.inf.C20.u onboarding company edition', {
        'Description': 'Click on button to edit selected company from CompanyEdition.svelte'
      })
    }

    isEditing = true
    loadLegalStructure()
  }

  const onCountryChange = (countryCode: string) => {
    if (!company?.mailAddress) return

    const changeForEuropeanCoountry = isClientCountryCodeAMemberOfEuropeanUnion(countryCode, currentProfileLanguage())
    if (changeForEuropeanCoountry && !company.regulatory.euRegulScope) company.regulatory.euRegulScope = new EuCompanyRegulatory()
    
    company.mailAddress.countryCode = countryCode
    company.mailAddress.country = getCountryNameForCountryCode(currentProfileLanguage(), countryCode)
  }

  const onCompanyStatusChange = (registrationPending:boolean) => {
    if (!company) return

    company = Company.empty()
    company.registrationPending = registrationPending
  }

  const onLegalStructureChange = (e:string) => {
    legalStructure = e
    legalSubStructure = ''

    if (e === '') legalSubStructure = legalStructureOther[0].value
  }

  const onCancel = () => {
    if (isOnboarding) {
      mixpanel.track('onb.cpn.inf.C22.u onboarding company cancel edition', {
        'Description': 'Click on cancel button from company edition from CompanyEdition.svelte'
      })
    }

    if (isManual && !isManualSaved) {
      company = null
    } else {
      company = objectDeepClone(initCompany) as Company
    }

    legalStructure = ''
    legalSubStructure = ''
    isEditing = false

    dispatch('cancel', company)
  }

  const onAutoEntrepreneurChange = () => {
    company.regulatory.frRegulScope.eligibleToVAT = false
  }

  const onSave = () => {
    focus = Object.keys(focus).reduce((acc, cur) => ({ ...acc, [cur]: true }), {}) 

    if (hasError) return
    
    if (window.location.pathname.includes('onboarding')) {
      mixpanel.track('onb.cpn.inf.C21.u onboarding company validate edition', {
        'Description': 'Click on save button from company edition from CompanyEdition.svelte'
      })
    }

    if (company?.regulatory?.frRegulScope?.nafCodeLevel4 && company?.regulatory?.frRegulScope?.nafCodeLevel4?.length > 2) company.regulatory.frRegulScope.nafCodeLevel4 = company?.regulatory?.frRegulScope?.nafCodeLevel4.slice(0, 2) + '.' + company?.regulatory?.frRegulScope?.nafCodeLevel4.slice(3)

    if (company?.regulatory?.frRegulScope?.siren && company?.regulatory?.frRegulScope?.siret?.length > 9) {
      const tmp = company?.regulatory?.frRegulScope?.siret.slice(9)
      company.regulatory.frRegulScope.siret = company?.regulatory?.frRegulScope?.siren + tmp
    }

    if (company?.mailAddress) {
      if (!company?.mailAddress?.state) company.mailAddress.state = defaultCountryName
      if (!company?.mailAddress?.countryCode) company.mailAddress.countryCode = defaultCountryCode
    }

    if (company && isEuropean) company.regulatory.isEuRegulated = true

    isEditing = false
    if (isManual) {
      isManualSaved = true
      isManual = false
      initCompany = objectDeepClone(company) as Company
    }

    dispatch('save', { company, firstName, lastName, mail, mobilePhone, officePhone })
  }

  onMount(()=> loadLegalStructure())
</script>

{#if isOnboarding && isManual}
  <div class="flex mt-6 text-center items-center justify-center ">
    <div class="flex space-x-1 rounded-lg bg-slate-100 p-1">
      <button class="manual" class:selected={!company?.registrationPending} on:click={() => onCompanyStatusChange(false)}>
        {t('onboarding.companyInfo.edit.choiceExisting')}
      </button>
      <button class="manual" class:selected={company?.registrationPending} on:click={() => onCompanyStatusChange(true)}>
        {t('onboarding.companyInfo.edit.choicePending')}
      </button>
    </div>
  </div>
{/if}

<div class="w-full mb-12 p-9 space-y-4 bg-white {isManual ? 'rounded-b-md' : 'rounded-md'}">
  {#if company}

    {#if isPrivateIndividual}
      <div class="border-b border-athensGray pb-6">
        <div class="grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <label for="first-name"
                   class="block text-sm font-medium leading-6 text-black">{t('customerForm.firstName.label')}</label>
            <div>
              {#if isEditing}
                <TextInput type="text" id="label" autocomplete="off"
                           bind:value={firstName}
                           on:focus={() => focus.firstName = true}
                           error={errors.firstName}
                           showError={focus.firstName && Boolean(errors.firstName)}
                />
              {:else}
                <span class:miss={!firstName}>
                  {firstName || t('onboarding.companyInfo.edit.missValue')}
                </span>
              {/if}
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="last-name"
                   class="block text-sm font-medium leading-6 text-black">{t('customerForm.lastName.label')}</label>
            <div>
              {#if isEditing}
                <TextInput type="text" id="label" autocomplete="off"
                           bind:value={lastName}
                           on:focus={() => focus.lastName = true}
                           error={errors.lastName}
                           showError={focus.lastName && Boolean(errors.lastName)}
                />
              {:else}
                <span class:miss={!lastName}>
                  {lastName || t('onboarding.companyInfo.edit.missValue')}
                </span>
              {/if}
            </div>
          </div>

          <div class="sm:col-span-6">
            <label for="email"
                   class="block text-sm font-medium leading-6 text-black">{t('customerForm.email.label')}</label>
            <div>
              {#if isEditing}
                <TextInput type="text" id="label" autocomplete="off"
                           bind:value={mail}
                           on:focus={() => focus.mail = true}
                           error={errors.mail}
                           showError={focus.mail && Boolean(errors.mail)}
                />
              {:else}
                <span class:miss={!mail}>
                  {mail || t('onboarding.companyInfo.edit.missValue')}
                </span>
              {/if}
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="first-name"
                   class="block text-sm font-medium leading-6 text-black">{t('customerForm.mobilePhone.label')}</label>
            <div>
              {#if isEditing}
                <TextInput type="number" id="label" autocomplete="off" hideSpin={true}
                           bind:value={mobilePhone}
                           on:focus={() => focus.mobilePhone = true}
                           error={errors.mobilePhone}
                           showError={focus.mobilePhone && Boolean(errors.mobilePhone)}
                />
              {:else}
                <span class:miss={!mobilePhone}>
                  {mobilePhone || t('onboarding.companyInfo.edit.missValue')}
                </span>
              {/if}
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="last-name"
                   class="block text-sm font-medium leading-6 text-black">{t('customerForm.officePhone.label')}</label>
            <div>
              {#if isEditing}
                <TextInput type="number" id="label" autocomplete="off" hideSpin={true}
                           bind:value={officePhone}
                           on:focus={() => focus.officePhone = true}
                           error={errors.officePhone}
                           showError={focus.officePhone && Boolean(errors.officePhone)}
                />
              {:else}
                <span class:miss={!officePhone}>
                  {officePhone || t('onboarding.companyInfo.edit.missValue')}
                </span>
              {/if}
            </div>
          </div>
        </div>
      </div>

    {:else}

      <div class="my-3">
        <div class="grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-6">
          <div class="sm:col-span-6">
            <div class="mb-4">
              {#if isManual && isEditing}
                <TextInput type="text" id="formalName" autocomplete="off" class="w-full"
                            bind:value={company.formalName}
                            on:focus={() => focus.formalName = true}
                            label={t('onboarding.companyInfo.edit.formalName')}
                            labelBold={true}
                            error={errors.formalName}
                            showError={focus.formalName && Boolean(errors.formalName)}
                />
              {:else}
                <h3 class="text-xl">{company?.formalName}</h3>

                {#if company?.regulatory?.frRegulScope?.nafCodeLevel4 && !isEditing}
                  <p class="text-sm text-baliHai">
                    {getNafByCode(company?.regulatory?.frRegulScope?.nafCodeLevel4)?.descFr}
                  </p>
                {/if}
              {/if}

            </div>
          </div>

          <div class="sm:col-span-6">
            <div>
              <span class="field">{t('onboarding.companyInfo.edit.usualName')}</span>
              {#if isEditing}
                <TextInput type="text" id="label" autocomplete="off" bind:value={company.usualName} />
              {:else}
                {#if company.usualName}
                  <span>{company.usualName}</span>
                {:else}
                  <span class="italic">{t('onboarding.companyInfo.edit.notAvailable')}</span>
                {/if}
              {/if}
            </div>
          </div>
          
        </div>
      </div>
    {/if}

    {#if allowChangeCountry && (isPrivateIndividual || isForeignCompany || isManual)}
      <div>
        <span class="field">{t('customerForm.country')}</span>
        {#if isEditing}
          <StyledSelect
            value={company.mailAddress.countryCode}
            disabled={false}
            isSearchable={true}
            items={countriesList}
            labelIdentifier='label'
            optionIdentifier='value'
            label={getCountryNameForCountryCode(currentProfileLanguage(), company?.mailAddress?.countryCode)}
            selected={company?.mailAddress?.countryCode}
            on:change={(e) => onCountryChange(e.detail)}
          />
        {:else}
          <span>{company?.mailAddress?.country}</span>
        {/if}
      </div>
    {/if}

    {#if !company.registrationPending && !isPrivateIndividual && isFrench}
      <dl class="grid grid-cols-1 mb-2 rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <div class="px-4 py-4 sm:col-span-1 text-center">
          <dt class="mb-2">{t('onboarding.companyInfo.edit.legalStructure')}</dt>

          {#if isEditing}
            <div class="flex justify-center">
              <InputRadio name="EURL" value={legalStructure} on:select={() => onLegalStructureChange('EURL')}>EURL</InputRadio>
              <InputRadio name="SASU" value={legalStructure} on:select={() => onLegalStructureChange('SASU')}>SASU</InputRadio>
              <InputRadio name="EI/EIRL" value={legalStructure} on:select={() => onLegalStructureChange('EI/EIRL')}>Entreprise individuelle</InputRadio>
              <InputRadio name="" value={legalStructure} on:select={() => onLegalStructureChange('')}>Autre</InputRadio>
            </div>

            <div class="mt-3 bg-white shadow-inner p-3">
              {#if legalStructure === 'EI/EIRL'}
                <Checkbox labelText="Auto-entrepreneur" bind:checked={company.regulatory.frRegulScope.isAutoEntrepreneur} on:change={() => onAutoEntrepreneurChange()}/>
              {:else if legalStructure === ''}
                <StyledSelect
                  value={legalSubStructure}
                  isSearchable={true}
                  items={legalStructureOther}
                  optionIdentifier='value'
                  labelIdentifier='label'
                  on:change={(e) => onLegalStructureChange(e.detail)}
                />
              {/if}
              {#if !company?.regulatory?.frRegulScope?.isAutoEntrepreneur}
                <div class="mt-3">
                  <Checkbox labelText="Assujetti à la TVA" bind:checked={company.regulatory.frRegulScope.eligibleToVAT}/>
                </div>
              {/if}
            </div>

          {:else}
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
              {#if legalSubStructure}
                <p>{legalSubStructure}</p>
              {/if}
              {getLegalCategoryLevel3ByCode(company?.regulatory?.frRegulScope?.legalFormCodeLevel3)?.level3CatFr}
            </dd>
          {/if}
        </div>
      </dl>
    {/if}

    {#if !company.registrationPending && !isPrivateIndividual && !!company.mailAddress.countryCode && isEuropean}
      <dl class="flex flex-col p-5 rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">

        {#if isFrench}
          <div class="px-4">
            <dt>{t('onboarding.companyInfo.edit.naf')}</dt>
            {#if isEditing}
              <div class="flex items-center">
                <TextInput
                  type="text"
                  id="label"
                  autocomplete="off"
                  class="col-span-full"
                  showValidationTick={false}
                  transform='uppercase'
                  bind:value={company.regulatory.frRegulScope.nafCodeLevel4}
                  on:focus={() => focus.naf = true}
                  error={errors.naf}
                  showErrorIcon={focus.naf && Boolean(errors.naf)}
                />
                <div class="ml-4 text-s">
                  {getNafByCode(company?.regulatory?.frRegulScope?.nafCodeLevel4)?.descFr || t('onboarding.companyInfo.edit.nafError')}
                </div>
              </div>
            {:else}
              <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2"
                class:miss={!company?.regulatory?.frRegulScope?.nafCodeLevel4}>
                {company?.regulatory?.frRegulScope?.nafCodeLevel4 || t('onboarding.companyInfo.edit.missValue')}
              </dd>
            {/if}
          </div>

          {#if !company.registrationPending}
            <div class="px-4 mt-2">
              <dt class="">{t('onboarding.companyInfo.edit.siren')}</dt>
              {#if isEditing}
                <TextInput
                  type="text"
                  id="label"
                  autocomplete="off"
                  class="col-span-full"
                  showValidationTick={false}
                  pattern={'[0-9]'}
                  bind:value={company.regulatory.frRegulScope.siren}
                  on:focus={() => focus.siren = true}
                  error={errors.siren}
                  showErrorIcon={focus.siren && Boolean(errors.siren)}
                />
              {:else}
                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                  {company?.regulatory?.frRegulScope?.siren}
                </dd>
                {/if}
            </div>

            <div class="px-4 mt-2">
              <dt class="">{t('onboarding.companyInfo.edit.siret')}</dt>
              {#if isEditing}
                <TextInput
                  type="text"
                  id="label"
                  autocomplete="off"
                  class="col-span-full"
                  showValidationTick={false}
                  pattern={'[0-9]'}
                  bind:value={company.regulatory.frRegulScope.siret}
                  on:focus={() => focus.siret = true}
                  error={errors.siret}
                  showErrorIcon={focus.siret && Boolean(errors.siret)}
                />
              {:else}
                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                  {company?.regulatory?.frRegulScope?.siret}
                </dd>
                {/if}
            </div>
          {/if}
        {/if}

        <div class="px-4 mt-2">
          <dt class="">{t('onboarding.companyInfo.edit.vat')}</dt>
          {#if isEditing}
            <TextInput
              type="text"
              id="label"
              autocomplete="off"
              class="col-span-full"
              showValidationTick={false}
              transform='uppercase'
              bind:value={company.regulatory.euRegulScope.euIntraVat}
              on:focus={() => focus.vat = true}
              error={errors.vat}
              showErrorIcon={focus.vat && Boolean(errors.vat)}
            />
          {:else}
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
              {company?.regulatory?.euRegulScope?.euIntraVat}
            </dd>
          {/if}

          {#if !isFrench}
            <p class="mt-2 text-sm">{t('onboarding.companyInfo.edit.vatInfo')}</p>
          {/if}
        </div>

      </dl>
    {/if}

    <div class="mt-0 mb-3">
      <span class="field">{t('onboarding.companyInfo.edit.street')}</span>
      {#if isEditing}
        <TextInput type="text" id="street" autocomplete="off"
                   bind:value={company.mailAddress.street}
                   on:focus={() => focus.street = true}
                   error={errors.street}
                   showError={focus.street && Boolean(errors.street)}
        />
      {:else}
        <span class="block">{company?.mailAddress?.street}</span>
      {/if}
    </div>

    <div class="my-3">
      <span class="field">{t('onboarding.companyInfo.edit.extraAddressLine')}</span>
      {#if isEditing}
        <TextInput type="text" id="street" autocomplete="off"
                   bind:value={company.mailAddress.extraAddressLine}
                   on:focus={() => focus.extraAddressLine = true}
        />
      {:else}
        <span>{company?.mailAddress?.extraAddressLine ?? ''}</span>
      {/if}
    </div>

    <div class="my-3">
      <div class="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
        <div class="sm:col-span-3">
          <label for="zipCode" class="block">{t('onboarding.companyInfo.edit.zipCode')}</label>
          <div>
            {#if isEditing}
              <TextInput id="zipCode" autocomplete="off" transform='uppercase'
                         bind:value={company.mailAddress.zipCode}
                         on:focus={() => focus.zipCode = true}
                         error={errors.zipCode}
                         showError={focus.zipCode && Boolean(errors.zipCode)}
              />
            {:else}
              <span>{company?.mailAddress?.zipCode}</span>
            {/if}
          </div>
        </div>

        <div class="sm:col-span-3">
          <label for="city" class="block">{t('onboarding.companyInfo.edit.city')}</label>
          <div>
            {#if isEditing}
              <TextInput type="text" id="city" autocomplete="off"
                         bind:value={company.mailAddress.city}
                         on:focus={() => focus.city = true}
                         error={errors.city}
                         showError={focus.city && Boolean(errors.city)}
              />
            {:else}
              <span>{company?.mailAddress?.city}</span>
            {/if}
          </div>
        </div>
      </div>

      {#if !isFrench}
        <div class="mt-3">
          <label for="state" class="block">
            {t('onboarding.companyInfo.edit.state')}
          </label>
          <div>
            {#if isEditing}
              <TextInput type="text" id="state" autocomplete="off" bind:value={company.mailAddress.state} />
            {:else}
              <span>{company?.mailAddress?.state}</span>
            {/if}
          </div>
        </div>
      {/if}
    </div>

    {#if showShareCapital}
      <div class="my-3">
        <span class="field">{t('onboarding.companyInfo.edit.shareCapital')}</span>
        {#if isEditing}
          <TextInput type="text" id="shareCapital" autocomplete="off"
                      bind:value={company.shareCapital}
                      on:focus={() => focus.shareCapital = true}
                      error={errors.shareCapital}
                      showError={focus.shareCapital && Boolean(errors.shareCapital)}
          />
        {:else if company}
          <span class:miss={!company?.shareCapital}>
            {company?.shareCapital || t('onboarding.companyInfo.edit.missValue')}
          </span>
        {/if}
      </div>
    {/if}

  {/if}

  <div
    class="mx-auto w-full justify-center mt-18 pt-6 text-center"
    class:shouldValidate={isEditing}
    class:shouldEdit={hasError && !isEditing}
  >
    {#if !isEditing}
      <span class:miss={hasError}>
        {hasError ? t('onboarding.companyInfo.edit.miss') : t('onboarding.companyInfo.edit.incorrect')}
      </span>

      <div class="flex justify-center items-center mt-2">
        <button type="button" class="btn action-default" on:click={onCompanyEdition}>
          <img src="/img/icons/edit.svg" alt="edit" class="mr-2"/>
          {t('onboarding.companyInfo.edit.edit')}
        </button>
      </div>
    {:else}
      {#if displayCancelButton}
        <button type="button" class="btn action-default h-10 mr-5" on:click={onCancel}>
          {t('onboarding.companyInfo.edit.cancel')}
        </button>
      {/if}

      <button type="button" class="btn action-bla primary" class:w-full={!displayCancelButton} on:click={onSave}>
        {t('onboarding.companyInfo.edit.validate')}
      </button>
    {/if}
  </div>

</div>

<style lang="postcss">
  .manual {
    @apply flex items-center rounded-md py-3 px-8 text-sm font-semibold shadow-none;

    &.selected {
      @apply bg-dundyOrange text-white shadow;
    }
  }

  .shouldValidate {
    @apply flex items-center space-x-6;
  }

  .shouldEdit {
    @apply rounded-md bg-creamBrulee text-center px-4 py-2.5;
  }

  span {
    @apply text-xs text-gray-500 m-0;

    &.field {
      @apply block text-black font-medium text-sm
    }

    &.miss {
      @apply text-dundyOrange font-bold text-sm tracking-tight
    }

    &:last-child {
      @apply text-sm;
    }
  }

  dt, label {
    @apply text-sm font-medium leading-6 text-gray-900;
  }
</style>
import type Country from '../models/country'
import { allCountriesInfoByCountryCode } from '../lib/i18n/countries-all'
import { countriesEUMembersEN } from '../lib/i18n/countries-members-EU-en'
import { countriesEUMembersFR } from '../lib/i18n/countries-members-EU-fr'
import { currentProfileLanguage } from '../lib/i18n/i18nextWrapper'

/**
 * Get the country code of the user
 * @returns {string}
 */
export function getUserCountryCode(): string {
  try {
    return Intl.DateTimeFormat().resolvedOptions().locale.split('-')[1]
  } catch (e) {
    /* console.log('--== getUserCountryCode -- error -- ', e) */

    return 'FR'
  }
}

/**
 * Get the list of countries for the current language
 * @param currentLangCodeLowerCase
 * @returns {Country[]} the list of countries
 */
export function getCountries(currentLangCodeLowerCase: string): Country[] {
  const countriesCorrespondence = allCountriesInfoByCountryCode[currentLangCodeLowerCase]
  const allCountryCodes: string[] = Object.keys(countriesCorrespondence)
  
  let allCountriesFormattedList: any[] = []

  const addCountries = (list:string[]) => {
    for (let item of list) {
      const newItem: { label: string, value: string } = {
        'label': getCountryMarkup(item),
        'value': item.toUpperCase()
      }
      allCountriesFormattedList.push(newItem)
    }
  }

  // Nearby Countries
  const nearbyCountries = ['FR', 'DE', 'BE', 'ES', 'IT', 'GB']
  addCountries(nearbyCountries)

  // Distant Countries
  const distantCountries = allCountryCodes.filter(c => !nearbyCountries.includes(c))
  addCountries(distantCountries)

  return allCountriesFormattedList
}

export function getCountryMarkup(countryCode) {
  return '<span class="mr-4 iti__flag iti__' + countryCode.toLowerCase() + '"></span> <span>' + getCountryNameForCountryCode(currentProfileLanguage(), countryCode) + '</span>'
}

/**
 * Get the country name for a country code
 * @param currentLangCodeLowerCase
 * @param countryCode
 * @returns {string} the country name
 */
export function getCountryNameForCountryCode(currentLangCodeLowerCase: string, countryCode: string): string {
  const countryCodeUpperCase: string = countryCode?.toUpperCase()
  if (currentLangCodeLowerCase && countryCode && allCountriesInfoByCountryCode[currentLangCodeLowerCase][countryCodeUpperCase]) {
    return allCountriesInfoByCountryCode[currentLangCodeLowerCase][countryCodeUpperCase].name
  } else {
    console.warn('Called getCountryNameForCountryCode with invalid parameters', currentLangCodeLowerCase, countryCode, allCountriesInfoByCountryCode[currentLangCodeLowerCase][countryCodeUpperCase])
    
    return countryCode
  }
}

/**
 * Check if the country code is in the EU
 * @param countryCode
 * @param language
 * @returns {boolean}
 */
export function isClientCountryCodeAMemberOfEuropeanUnion(countryCode: string, language: string = 'EN'): boolean {
  const countriesList: { code: string, name: string }[] = language === 'FR' ? countriesEUMembersFR : countriesEUMembersEN

  return countriesList.some((country: { code: string, name: string }): boolean => country.code === countryCode)
}


export const getTimeZoneIANACode = (countryCode: string, zipCode: string): string => {
  switch (countryCode?.toUpperCase()) {

    case 'FR': default:
      if (/974\d{2}/.test(zipCode)) return 'Indian/Reunion'
      if (/976\d{2}/.test(zipCode)) return 'Indian/Mayotte'
      if (/971\d{2}/.test(zipCode)) return 'America/Guadeloupe'
      if (/972\d{2}/.test(zipCode)) return 'America/Martinique'
      if (/973\d{2}/.test(zipCode)) return 'America/Cayenne'
      if (/98755/.test(zipCode)) return 'Pacific/Gambier'
      if (/56328/.test(zipCode)) return 'Pacific/Midway'
      if (/98600/.test(zipCode)) return 'Pacific/Wallis'
      if (/9874\d{1}/.test(zipCode)) return 'Pacific/Marquesas'
      if (/987\d{2}/.test(zipCode)) return 'Pacific/Tahiti'
      if (/988\d{2}/.test(zipCode)) return 'Pacific/Noumea'
      if (/984\d{2}/.test(zipCode)) return 'Antarctica/Troll'
      
      return 'Europe/Paris'
  }
}
<script lang="ts">
    import { createEventDispatcher, onMount, afterUpdate } from 'svelte'
    import { debounce } from '../../util/function-utils'
    import { t } from '../i18n/i18nextWrapper.js'

    /**
     * Specify the input value
     */
    export let value: string = ''

    /**
     * Set to `true` to auto focus the input on mount
     */
    export let autofocus: boolean = false

    /**
     * Specify the debounce value in milliseconds (ms)
     */
    export let debounceValue: number = 0

    /**
     * Specify the input label text
     */
    export let label = 'Label'

    /**
     * Set to `true` to visually hide the label
     */
    export let hideLabel = false

    /**
     * Specify an `id` for the `input`
     */
    export let id = 'search' + Math.random().toString(36)

    /**
     * Obtain a reference to the `input` element
     */
    export let ref: HTMLInputElement = null

    /**
     * Set to `true` to omit the form `role="search"` attribute
     */
    export let removeFormAriaAttributes = false

    /** Specify the `placeholder` attribute of the search input */
    export let placeholder = t('searchBar.placeholder')

    /** Specify the close button label text */
    export let closeButtonLabelText = 'Clear search input'


    /** Set to `true` to disable the search input */
    export let disabled = false


    const dispatch = createEventDispatcher()

    /**
     * Handle the `keyup` event
     * @param e
     */
    function handleKeyUp(e: Event) {
      const target = e.target as HTMLInputElement // TypeScript is okay with this
      if (target) {
        value = target.value
        search()
      }
    }

    /**
     * Dispatch a `search` event
     */
    function search(): void {
      dispatch('search', {
        text: value
      })
    }

    let prevValue: string = value
    let timeout: any = undefined

    onMount(() => {
      if (autofocus) {
        window.requestAnimationFrame(() => ref.focus())
      }
      
      return () => clearTimeout(timeout)
    })

    afterUpdate(() => {
      if (!value || value.length > 0 && value !== prevValue) {
        if (debounceValue > 0) {
          debounce(() => dispatch('type', value), debounceValue)
        } else {
          dispatch('type', value)
        }
      }

      if (!value || value.length === 0 && prevValue.length > 0) {
        dispatch('clear')
      }

      prevValue = value
    })
</script>

<div class="search-wrapper"
     id="{id}-container"
     role={removeFormAriaAttributes ? null : 'search'}
     aria-labelledby={removeFormAriaAttributes ? null : id}
     on:submit|preventDefault
     on:keyup={handleKeyUp}>
    <label id="{id}-label"
           style={hideLabel && 'position: absolute;height: 1px;width: 1px;overflow: hidden;clip: rect(1px 1px 1px 1px);clip: rect(1px, 1px, 1px, 1px);white-space: nowrap;'}>
        <slot name="label">{label}</slot>
    </label>
    <svg class="icon-search text-xl absolute left-2" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
              d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"></path>
    </svg>
    <input
            class="bg-whisper text-sm px-10 rounded border-athensGray border outline-none w-full h-full focus:ring-2 focus:ring-black focus:border-transparent"
            bind:this={ref}
            name="search"
            type="search"
            placeholder="{placeholder}"
            autocomplete="off"
            spellcheck="false"
            {...$$restProps}
            {id}
            bind:value
            on:input
            on:change
            on:focus
            on:blur
            on:keydown
            on:keydown="{({ key }) => {
              if (key === 'Escape') {
                value = ''
                dispatch('clear')
              }
            }}"
            on:keyup
            on:paste
    />
    <button
            class="absolute right-2"
            class:search-close--hidden="{value === ''}"
            type="button"
            aria-label="{closeButtonLabelText}"
            disabled="{disabled}"
            on:click
            on:click="{() => {
              value = ''
              ref.focus()
              dispatch('clear', {
                text: ''
              })
            }}">
        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  d="M4.75 12C4.75 7.99594 7.99594 4.75 12 4.75V4.75C16.0041 4.75 19.25 7.99594 19.25 12V12C19.25 16.0041 16.0041 19.25 12 19.25V19.25C7.99594 19.25 4.75 16.0041 4.75 12V12Z"></path>
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  d="M9.75 9.75L14.25 14.25"></path>
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  d="M14.25 9.75L9.75 14.25"></path>
        </svg>

    </button>
</div>
<style lang="postcss">
    .search-wrapper {
        @apply w-full relative flex flex-grow h-10 items-center;
        transition: all .5s cubic-bezier(.55,0,.1,1) .2s;
        will-change: width;
    }

    .search-close--hidden {
        visibility: hidden;
        opacity: 0;
    }

    [type="search"]::-webkit-search-cancel-button,
    [type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
        appearance: none;
    }
</style>
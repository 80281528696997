export default interface PaginatedDisplayListConfig {
  columns: Array<{
    dataKey: string,
    label: string,
    sortable: boolean,
    classes: string,
    componentClasses: string,
    headerClasses: string,
    type: PaginatedDisplayListType
  }>
  data: Array<any>;
  pageSize: number;
}

export enum PaginatedDisplayListType {
  CUSTOMER = 'customer',
  LATE_CUSTOMER = 'late-customer',
  CONTACT = 'contact',
  AMOUNTS = 'amounts',
  WORKSPACE = 'workspace',
  BANK_ACCOUNT = 'bank-account',
  TODO_TITLE = 'todo-title',
  TODO_AMOUNT = 'todo-amount',
  STATUS_LABEL = 'status-label',
  INVOICE_NUMBER = 'invoice-number',
  INVOICE_AMOUNT = 'invoice-amount',
  DUE_DATE = 'due-date',
  DUNNING_STEP = 'dunning-step',
  PARTIAL_BUSINESS_DOCUMENT = 'partial-business-document',
  NEW_BALANCE_BUSINESS_DOCUMENT = 'new-balance-business-document'

}

export enum PaginatedDisplayListSort {
  ASC = 'asc',
  DESC = 'desc',
  NEUTRAL = 'neutral'
}
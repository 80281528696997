import { Template } from '../models/template'

let templates: Template[] = []

/**
 * GET ALL TEMPLATE
 * @returns 
 */
export const getAllTemplate = async ():Promise<Template[]> => {
  // const reponse = await fetch(`baseUrl/${name}`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   }
  // })
  // return await reponse.json()

  // TODO : remove MOCK
  templates = [
    new Template('AZERTY', ['reminder'], ['mail'], '', '', '', '', '', '', '', 1),
    new Template('ABCDE', ['warning'], ['phone'], '', '', '', '', '', '', '', 2),
    new Template('012345', ['information', 'legal'], ['letter'], '', '', '', '', '', '', '', 3),
    new Template('DEFAUT', ['information', 'reminder', 'warning', 'legal'], ['mail'], '', '', '', '', '', '', '', 4)
  ]

  return templates
}

/**
 * FILTER TEMPLATES BY NAME
 * @param name 
 * @returns Template[]
 */
export const searchTemplate = (name : string):Template[] => templates.filter(c => 
  c.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()
    .indexOf(name.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) >= 0)

/**
 * GET TEMPLATE BY ID
 * @param {number} id 
 * @returns {Template}
 */
export const getTemplateById = async (id: number):Promise<Template | null> => {
  if (!id) return new Template('', [], [], '', '', '', '', '', '', '')

  let res = templates.find(t => t.id === id)
  if (res) return res

  // TODO : call API

  return new Template('', [], [], '', '', '', '', '', '', '')
}

/**
 * GET TEMPLATE BY NAME
 * @param {string} name 
 * @returns {Template}
 */
export const getTemplateByName = async (name: string):Promise<Template | null> => {
  if (!name) return null

  let res = templates.find(t => t.name === name)
  if (res) return res

  // TODO : call API

  return null
}

/**
 * CREATE NEW MAIL TEMPLATE
 * @param {Template} template 
 * @returns {boolean}
 */
export const createTemplate = async (template: Template):Promise<string> => {
  if (!template) return 'KO : '

  // TODO : call API

  return ''
}

/**
 * UPDATE EXISTING MAIL TEMPLATE
 * @param {number} id 
 * @param {Template} template 
 * @returns {boolean}
 */
export const updateTemplate = async (id: number, template: Template):Promise<string> => {
  if (id === 0 && !template) return 'KO : '

  // TODO : call API

  return ''
}

/**
 * DUPLICATE MAIL TEMPLATE
 * @param {number} id 
 * @returns {boolean}
 */
export const duplicateTemplate = async (id : number):Promise<string> => {
  if (id === 0) return 'KO : '

  // TODO : call API

  return ''
}

/**
 * DELETE MAIL TEMPLATE
 * @param {number} id 
 * @returns {boolean}
 */
export const removeTemplate = async (id : number):Promise<string> => {
  if (id === 0) return 'KO : '

  // TODO : call API

  return ''
}
import { JsonProperty } from '@paddls/ts-serializer'
import { Decimal } from 'decimal.js'

/*
    Ref: https://www.npmjs.com/package/decimal.js-light
    Adjust the global configuration if required (these are the defaults)
    Decimal.set({
      precision: 20,
      rounding: Decimal.ROUND_HALF_UP,
      toExpNeg: -7,
      toExpPos: 21
    });
 */
export class AmountDecimal {
  @JsonProperty('currencyCode') currencyCode: string = ''
  @JsonProperty({ field: 'scale', type: () => Decimal }) scale: Decimal = new Decimal(0)
  @JsonProperty({ field: 'scaledValue', type: () => Decimal }) scaledValue: Decimal = new Decimal(0)
  @JsonProperty({ field: 'unscaledValue', type: () => Decimal }) unscaledValue: Decimal = new Decimal(0)
  // get the float value of the amount of money
  //
  // scaledValue: float number representing the direct value in the currency (e.g. 1.25 for 1.25EUR)
  GetAmountDecimalScaledValue(): Decimal {
    return this.scaledValue ?? (new Decimal(0))
  }
  // converts the js prototype into the TypeScript class
  static PrototypeToClass(jsPrototype: AmountDecimal | AmountDecimalBasicObject): AmountDecimal {
    return <AmountDecimal>Object.assign(new AmountDecimal(), jsPrototype)
  }
  // scaledValue: float number representing the direct value in the currency (e.g. 1.25 for 1.25EUR)
  // scale: integer number representing the number of 0s, or otherwise the N power of 10 in 10^N (1 for 10, 2 for 100, 3 for 1000)
  // currencyCode: string of the currency code (REF: ISO 4217, e.g. https://www.iso.org/iso-4217-currency-codes.html or https://en.wikipedia.org/wiki/ISO_4217)
  // NB: unscaledValue in an integer number which, combined with the scale, must give the same result as scaledValue: e.g. unscaledValue is 3467 with a scale of 2 for a scaledValue of 34.67EUR (aka 3467/(10^2))
  static NewAmountOfMoneyWithDecimal(scaledValue: Decimal, scale: Decimal, currencyCode: string): AmountDecimal {
    return AmountDecimal.PrototypeToClass(<AmountDecimal>{
      currencyCode: currencyCode,
      scale: scale.round(),
      // scale: scale.toInteger(),
      // scaledValue:   (scaledValue.mul((new Decimal(10).toPower(scale.toInteger())))).toInteger().div((new Decimal(10).toPower(scale.toInteger()))), // Math.round(scaledValue * Math.pow(10, Math.round(scale))) / Math.pow(10, Math.round(scale)),
      // unscaledValue:  (scaledValue.mul((new Decimal(10).toPower(scale.toInteger())))).toInteger(), // equiv with Decimal to: Math.round(scaledValue * Math.pow(10, Math.round(scale))),
      scaledValue: (scaledValue.mul((new Decimal(10).toPower(scale.round())))).round().div((new Decimal(10).toPower(scale.round()))), // Math.round(scaledValue * Math.pow(10, Math.round(scale))) / Math.pow(10, Math.round(scale)),
      unscaledValue: (scaledValue.mul((new Decimal(10).toPower(scale.round())))).round() // equiv with Decimal to: Math.round(scaledValue * Math.pow(10, Math.round(scale))),
    })
  }

}

export class AmountDecimalBasicObject {
  @JsonProperty('currencyCode') currencyCode: string = ''
  @JsonProperty({ field: 'scale', type: () => Decimal }) scale: Decimal = new Decimal(0)
  @JsonProperty({ field: 'scaledValue', type: () => Decimal }) scaledValue: Decimal = new Decimal(0)
  @JsonProperty({ field: 'unscaledValue', type: () => Decimal }) unscaledValue: Decimal = new Decimal(0)
}

// scaledValue: float number representing the direct value in the currency (e.g. 1.25 for 1.25EUR)
// scale: integer number representing the number of 0s, or otherwise the N power of 10 in 10^N (1 for 10, 2 for 100, 3 for 1000)
// currencyCode: string of the currency code (REF: ISO 4217, e.g. https://www.iso.org/iso-4217-currency-codes.html or https://en.wikipedia.org/wiki/ISO_4217)
// NB: unscaledValue in an integer number which, combined with the scale, must give the same result as scaledValue: e.g. unscaledValue is 3467 with a scale of 2 for a scaledValue of 34.67EUR (aka 3467/(10^2))
export function NewAmountOfMoneyWithDecimal(scaledValue: Decimal, scale: Decimal, currencyCode: string): AmountDecimal {
  return AmountDecimal.NewAmountOfMoneyWithDecimal(scaledValue, scale, currencyCode)
}

import { v4 as uuidv4 } from 'uuid'
import Company from '../models/company'
import type { Workspace } from '../models/workspace'

export class WorkspaceHelper {

  static newCompany = (): Company => {
    const company = Company.empty(uuidv4())

    return <Company>company
  }
  static newWorkspace(userId: string): Workspace {
    return <Workspace>{
      workspaceId: null,
      bankConfig: null,
      contacts: [],
      testEndToEnd: false,
      ownerId: userId,
      mainUsersIds: [],
      teamUsersIds: [],
      customers: [],
      company: this.newCompany()
    }
  }
}
<script lang="ts">
    import { link } from 'svelte-routing'
    import { validateEmail } from '../services/validator'
    import auth from '../lib/auth0authentication/authService.js'
    import AlertError from '../lib/ui-kit/Alert/AlertError.svelte'
    import AlertSuccess from '../lib/ui-kit/Alert/AlertSuccess.svelte'
    import { Alert } from '../stores/alert.store'
    import { t } from '../lib/i18n/i18nextWrapper'
    import TextInput from '../lib/ui-kit/TextInput.svelte'

    let email = ''
    let isEmailValid: boolean

    $: isEmailValid = !email || validateEmail(email)

    function handleClickReset() {
      auth.askEmailForForgotMyPassword(email, (err: string) => {
        if (err) {
          /* console.error(err) */
          /* console.log(t('resetPassword.resetFailed')) */
          Alert.open(AlertError, {
            header: t('resetPassword.resetFailed'),
            message: err
          })
        } else {
          /* console.log(t('resetPassword.resetSuccessful')) */
          /* console.log(t('resetPassword.checkMail')) */
          Alert.open(AlertSuccess, {
            header: t('resetPassword.resetSuccessful'),
            message: t('resetPassword.checkMail')
          })
        }
      })
    }
</script>

<header class="banner">
    <div class="flex items-center w-60">
        <a class="btn small-secondary icon" href="https://dundy.co">
            <i class="icon-arrow-left text-2xl"/>
            {t('resetPassword.back')}
        </a>
    </div>
    <div class="flex items-center justify-center w-52 mx-auto text-center">
        <img alt="dundy logo" src="/img/dundy-icon.svg" class="h-14" />
    </div>
    <div class="flex items-center justify-end w-60">
        <a class="btn small-primary" href="/signin" use:link>
            {t('resetPassword.signIn')}
        </a>
    </div>
</header>

<div class="progress-background"/>

<main class="container flex flex-col items-center pt-20 max-w-2xl mx-auto">
    <h1 class="font-bold text-4xl text-center font-source">
        {t('resetPassword.title')}
        <br/>
        {t('resetPassword.subtitle')}
    </h1>

    <form
        class="flex flex-col mt-12 items-center"
        on:submit|preventDefault={handleClickReset}
    >
        <TextInput
            bind:value={email}
            class="w-full"
            error={t('resetPassword.errorEmail')}
            id="email"
            placeholder={t('resetPassword.email')}
            showError={!isEmailValid}
            type="email"
        />

        <button
            class="mt-4 btn big-primary animated"
            disabled={!validateEmail(email)}
            id="continue"
        >
            {t('resetPassword.reset')}
        </button>

        <p class="mt-20 note">
            {t('resetPassword.note')}
            <b class="text-xs text-dundyOrange"> {t('resetPassword.boldnote')} </b>
        </p>
    </form>
</main>

<style lang="postcss">
    form {
        @apply w-full sm:w-10/12 md:w-9/12 lg:w-8/12;
    }
</style>

import { AnalyticsElements } from '../enums/analytics-enums'

export class ValueForCurrentMonthAlongWithVariationMonthToMonth {
  currentMonthValue: number
  previousMonthValue: number
  monthToMonthVariationPercentage: string
  variationDirection: string
  variationFavorability: string
  /**
     *  Static method to create an instance with default values
     */
  static createDefault(): ValueForCurrentMonthAlongWithVariationMonthToMonth {
    return {
      currentMonthValue: 0,
      previousMonthValue: 0,
      monthToMonthVariationPercentage: '0',
      variationFavorability: 'neutral',
      variationDirection: 'neutral'
    }
  }
}

export type CalculationNature = AnalyticsElements;

export type PeriodDescription = {
  startDateCurrentPeriod: Date;
  endDateCurrentPeriod: Date;
  startDatePreviousPeriod: Date;
  endDatePreviousPeriod: Date;
};
import { get, writable } from 'svelte/store'
import type { Writable } from 'svelte/store'
import { encrypt256Hash } from '../util/encryption'
import initializationStore from './initialization.store'
import type { NavigationHistoryItem } from '../models/navigation-history-item'
import { navigate } from 'svelte-routing'
import { NewNavigationHistoryItem } from '../models/navigation-history-item'

/* console.log('navigationHistory.store.ts') */

export const isFetching: Writable<boolean> = writable(true)

// NB: we need to create our own navigation history because we need to set the previous URL to the attribute of some components
function getNavigationHistory(): NavigationHistoryItem[] {
  const existingValue: string = localStorage.getItem('NavigationHistoryStore')
  const emptyValue: NavigationHistoryItem[] = <NavigationHistoryItem[]>[]

  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  if (existingValue === '{}') {
    return emptyValue
  }

  return <NavigationHistoryItem[]>JSON.parse(existingValue)
}

export let NavigationHistoryStore: Writable<NavigationHistoryItem[]> = writable(getNavigationHistory())

export const initializeNavigationHistoryStore = (data: NavigationHistoryItem[]) => {

  if (!get(initializationStore).navigationHistoryStoreInitialized) {

    initializationStore.update(store => {
      store.navigationHistoryStoreInitialized = true

      return store
    })

    if (!Array.isArray(data)) {
      data = <NavigationHistoryItem[]>[]
    }

    localStorage.setItem('NavigationHistoryStore', JSON.stringify(data, null, 4))
    NavigationHistoryStore.set(data)
    isFetching.set(false)

    // NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
    NavigationHistoryStore.subscribe(newNavigationHistory => {

      if (!Array.isArray(newNavigationHistory)) {
        newNavigationHistory = <NavigationHistoryItem[]>[]
      }

      if (!!newNavigationHistory && newNavigationHistory.length && Array.isArray(newNavigationHistory)) {

        const oldNavigationHistory = JSON.parse(localStorage.getItem('NavigationHistoryStore'))
        const oldNavigationHistoryHash = encrypt256Hash(oldNavigationHistory)
        const newNavigationHistoryHash = encrypt256Hash(newNavigationHistory)

        if (oldNavigationHistoryHash !== newNavigationHistoryHash && oldNavigationHistory) {
          localStorage.setItem('NavigationHistoryStore', JSON.stringify(newNavigationHistory, null, 4))
        }
      } else {
        localStorage.setItem('NavigationHistoryStore', JSON.stringify([], null, 4))
      }
    })

    /* console.log('%c NavigationHistoryStore initialized.', 'color: #8A99AC') */
  } else {
    /* console.log('%c damn navigation history not initialized.', 'color: #FF5555') */
  }
}

export const updateNewNavigationHistoryItem = (whereFrom: string) => {
  if (!((get(NavigationHistoryStore).length > 0) && (currentNavigationHistoryItem().relativeURL === location.pathname))) {
    NavigationHistoryStore.set([...get(NavigationHistoryStore), <NavigationHistoryItem>{ relativeURL: location.pathname }])
  }

}

export const pushNewNavigationHistoryItem = (newNavigationHistoryItem: NavigationHistoryItem) => {
  NavigationHistoryStore.set([...get(NavigationHistoryStore), newNavigationHistoryItem])
  /* console.log('ccc pushNewNavigationHistoryItem get(NavigationHistoryStore)', get(NavigationHistoryStore)) */
}

export const currentNavigationHistoryItem = (): NavigationHistoryItem => {
  if (get(NavigationHistoryStore).length > 0) {
    return get(NavigationHistoryStore)[get(NavigationHistoryStore).length - 1]
  } else {
    return NewNavigationHistoryItem('')
  }
}

export const previousNavigationHistoryItem = (whereFrom: any): NavigationHistoryItem => {
  /* console.log('ccc get(NavigationHistoryStore)', get(NavigationHistoryStore), 'in', whereFrom) */
  if (get(NavigationHistoryStore).length > 1) {
    return get(NavigationHistoryStore)[get(NavigationHistoryStore).length - 1]
  } else if (get(NavigationHistoryStore).length === 1) {
    return get(NavigationHistoryStore)[0]
  } else {
    return NewNavigationHistoryItem('')
  }
}

export const navigationToHistoryItem = (destinationNavigationHistoryItem: NavigationHistoryItem): void => {
  /* console.log('ccc navigationToHistoryItem', destinationNavigationHistoryItem.relativeURL, destinationNavigationHistoryItem) */
  if (!!destinationNavigationHistoryItem.relativeURL && destinationNavigationHistoryItem.relativeURL !== '') {
    navigate(destinationNavigationHistoryItem.relativeURL)
  } else {
    navigate('/today')
  }
}

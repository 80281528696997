import type { BusinessDocument } from '../models/business-document'
import type { SavedBusinessDocumentResponse } from '../models/business-document'
import type { BusinessDocumentAllDataPersisted } from '../models/business-document'
import type { CoreDocument } from '../models/business-document'
import {
  getVoxyInvoiceWithCollateralDataFromServerById,
  saveVoxyBusinessDocumentCollateralDataToServer, saveVoxyInvoiceToServer
} from '../services/business-document-api/business-document-repository.api'

/**
 * This file contains pure functions that are used
 * as pass-through for the data from/to the server.
 */

/**
 * Function pass-through to save the invoice to the server
 * @param duplicatedBusinessDocumentAllDataPersisted
 */
export async function saveInvoiceToServer(duplicatedBusinessDocumentAllDataPersisted: BusinessDocumentAllDataPersisted): Promise<void | SavedBusinessDocumentResponse> {
  return saveVoxyInvoiceToServer(duplicatedBusinessDocumentAllDataPersisted.coreDocument.businessDocument)
}

/**
 * Function pass-through to save the collateral data to the server
 * @param duplicatedBusinessDocumentAllDataPersisted
 */
export async function saveCollateralDataToServer(duplicatedBusinessDocumentAllDataPersisted: BusinessDocumentAllDataPersisted): Promise<{ upsertResult: any } | void> {
  return saveVoxyBusinessDocumentCollateralDataToServer(duplicatedBusinessDocumentAllDataPersisted.collateralDocument.businessDocumentCollateralData)
}

/**
 * Function pass-through to get the business document with collateral data from the server
 * @param businessDocument
 */
export async function getInvoiceWithCollateralData(businessDocument: BusinessDocument): Promise<BusinessDocumentAllDataPersisted> {
  return getVoxyInvoiceWithCollateralDataFromServerById(businessDocument.businessDocumentId)
}

/**
 * Get the business document (without collateral data) from the store
 * @param businessDocumentId
 * @param businessDocumentsStore
 */
export function getBusinessDocumentWithoutCollateralFromStoreById(businessDocumentId: string, businessDocumentsStore: CoreDocument[]): Promise<CoreDocument | undefined> {
  return new Promise((resolve): void => {
    const foundDocument: CoreDocument = businessDocumentsStore.find(
      (businessDocument: CoreDocument): boolean =>
        businessDocument.businessDocument.businessDocumentId === businessDocumentId,
    )
    resolve(foundDocument)
  })
}
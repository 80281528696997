import type { BusinessDocument } from '../../models/business-document'

/**
 * Determine if the given business document is a partial invoice
 * @param businessDocument
 */
export function isPartialBusinessDocument(businessDocument: BusinessDocument): boolean {
  return businessDocument.hasSpecificInstallment && (
    businessDocument.installmentResultIncludingTaxScaledValue !== businessDocument.totalIncludingTaxScaledValue ||
        businessDocument.installmentResultExcludingTaxScaledValue !== businessDocument.totalExcludingTaxScaledValue ||
        businessDocument.installmentResultTaxScaledValue !== businessDocument.totalTaxScaledValue
  )
}

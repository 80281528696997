<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import type Invoice from '../../models/invoice'
    import type { TodoListItem } from '../../models/todo-list-item'
    import { navigate } from 'svelte-routing'
    import { formatToShortDate } from '../../../core-app/util/date-utils'
    import { TodosStore } from '../../stores/todos.store'
    import { DunningInvoicesStore } from '../../stores/dunning-invoices.store'
    import { feedbackService } from '../../../core-app/services/feedback.service'
    import { Feedback } from '../../../core-app/models/feedback'
    import Loader from '../../../core-app/lib/ui-kit/Loader.svelte'
    import { InvoicesHistoryStore } from '../../stores/invoices-history.store'
    import { type HistoryItemWithType, type InvoicesHistoryItem } from '../../models/invoices-history'

    /** Set invoice data */
    export let invoiceId: string

    /** Specify the exported class */
    let propClass: string = ''
    export { propClass as class }


    /** Const & Var declarations **/
    let invoice: Invoice
    let todo: TodoListItem
    let history: HistoryItemWithType[] = []
    let isContentLoading: boolean
    let isContentLoadedWithoutError: boolean
    let historyItems: InvoicesHistoryItem[] = []


    /** Reactive declarations **/
    $: {
      if (invoiceId) {
        isContentLoading = true
        invoice = $DunningInvoicesStore.find(storedInvoice => storedInvoice.dataId === invoiceId)
        if ($TodosStore && $TodosStore.length) {
          todo = $TodosStore.find(storedTodo => {
            return storedTodo && storedTodo.scopeInvoiceNumbers && storedTodo.scopeInvoiceNumbers.length && !!storedTodo.scopeInvoiceNumbers.find(n => n === invoiceId)
          })
        }
        if ($InvoicesHistoryStore && $InvoicesHistoryStore.length) {
          historyItems = $InvoicesHistoryStore.filter(historyItem => historyItem.invoiceNumber === invoice?.invoiceNumber)
        }

        /* console.log(historyItems, 'historyItems') */

        /*history = historyItems.reduce((combined, current) => {
                let invoiceEvents = current.invoiceEvents.map(evt => ({ ...evt, type: 'invoiceEvent', commonDate: new Date(evt.created * 1000) }));
                let doneToDoItems = current.doneToDoItems.map(item => ({ ...item, type: 'doneToDoItem', commonDate: new Date(item.dateDone * 1000) }));

                return [...combined, ...invoiceEvents, ...doneToDoItems];
            }, []);*/
        history = historyItems.reduce((combined, current) => {
          let invoiceEvents = current.invoiceEvents.map(evt => ({ ...evt, type: 'invoiceEvent', commonDate: new Date(evt.created * 1000) })) as HistoryItemWithType[]
          let doneToDoItems = current.doneToDoItems.map(item => ({ ...item, type: 'doneToDoItem', commonDate: new Date(item.dateDone * 1000) })) as HistoryItemWithType[]

          return [...combined, ...invoiceEvents, ...doneToDoItems]
        }, [])


        /* console.log(history, 'history') */

        // Sort by commonDate (Date Object) descending
        history.sort((a, b) => b.commonDate?.getTime() - a.commonDate?.getTime())


        isContentLoading = false
        isContentLoadedWithoutError = !!history.length
      } else {
        isContentLoading = false
        isContentLoadedWithoutError = false
        feedbackService.displayFeedback(<Feedback> {
          feedbackLevel: 'Error',
          feedbackMessage: t('todoAction.errorLoadingFeedback')
        })
      }
    }

</script>

<div data-ut-component="invoice-timeline" class="overflow-y-auto h-[400px] pb-10">
    {#if isContentLoading}
        <div class="grid place-items-center w-full z-[100]">
            <Loader/>
        </div>
    {:else if !isContentLoadedWithoutError}

        <div class="activity-card-wrapper">
            <div class="activity-card activity-done {propClass}">
                <div class=" flex-auto">
                    <p class="text-s text-baliHai italic">{t('todoAction.errorLoadingHistory')}</p>
                </div>
            </div>
        </div>
    {:else if isContentLoadedWithoutError}

        <!-- Do we need to show that we have no action at this stage? -->
        <!--{#if !todo && invoice?.status !== InvoiceStatus.PAID}
            <div class="activity-card-wrapper">
                <div class="activity-card activity-done {propClass}">
                    <div class="bg-cruise border border-blueStone absolute transform rounded-full z-10 mt-2 md:mt-0"
                         style="height: 9px; width: 9px"></div>
                    <div class="ml-8 flex-auto">
                        <p class="text-s text-baliHai">{t(`todoAction.noAction`)}</p>
                    </div>
                </div>
            </div>
        {/if}-->

        {#if todo && !todo?.isDone}
            <div class="activity-card-wrapper" on:click={() => navigate(`/action/${todo?.id}`)}>
                <div class="activity-card {todo?.isDone ? 'activity-done' : ''} {propClass}">
                    <div class="{todo?.isDone ? 'bg-cruise border border-blueStone' : 'bg-dundyOrange border border-mojo'} absolute transform rounded-full z-10 mt-2 md:mt-0"
                         style="height: 9px; width: 9px"></div>
                    <div class="ml-8 flex-auto">
                        <h4 class="text-sm font-bold">{t(`history.kind.${ todo?.actionKind }.timelineState`)}</h4>
                        <p class="text-s text-dundyOrange">{t('todoAction.actionValidateToday')}</p>
                    </div>
                </div>
            </div>
        {/if}

        {#each history as historyItem}
            {#if historyItem.type === 'doneToDoItem'}
                <div class="activity-card-wrapper">
                    <div class="activity-card activity-done {propClass}">
                        <div class="bg-cruise border border-blueStone absolute transform rounded-full z-10 mt-2 md:mt-0"
                             style="height: 9px; width: 9px"></div>
                        <div class="ml-8 flex-auto">
                            <h4 class="text-sm font-bold">{t(`history.kind.${ historyItem.doneToDoItem.actionKind }.timelineDoneState`)}</h4>
                            <p class="text-s text-baliHai">{t(`history.kind.${ historyItem.doneToDoItem.actionKind }.timelineDoneCopy`, { dateDone: formatToShortDate(new Date(historyItem.commonDate)) })}</p>
                        </div>
                    </div>
                </div>
            {:else if historyItem.type === 'invoiceEvent'}
                <div class="activity-card-wrapper">
                    <div class="activity-card activity-done {propClass}">
                        <div class="bg-cruise border border-blueStone absolute transform rounded-full z-10 mt-2 md:mt-0"
                             style="height: 9px; width: 9px"></div>
                        <div class="ml-8 flex-auto">
                            <h4 class="text-sm font-bold">{t(`history.kind.${ historyItem.eventKind }.timelineDoneState`)}</h4>
                            <p class="text-s text-baliHai">{t(`history.kind.${ historyItem.eventKind }.timelineDoneCopy`, { dateDone: formatToShortDate(new Date(historyItem.commonDate)) })}</p>
                        </div>
                    </div>
                </div>
            {/if}
        {/each}
    {/if}
</div>

<style lang="postcss">
    .activity-card {
        @apply border border-transparent relative z-0 transform transition duration-200 cursor-pointer focus:bg-white hover:border hover:border-athensGray focus:shadow-sm flex items-center px-6 py-4 rounded flex-col md:flex-row space-y-4 md:space-y-0;
    }

    .activity-card.activity-done {
        @apply cursor-default hover:border-transparent focus:shadow-none;
    }

    .activity-card-wrapper {
        @apply relative transform transition;
    }

    .activity-card-wrapper::after {
        @apply absolute z-0 transform transition;
        content: "";
        height: 35px;
        border-left: 1px solid #E8EBF0;
        bottom: -23px;
        left: 29px;
        z-index: -1;
    }

    .activity-card-wrapper:last-child::after {
        content: none;
    }
</style>

import { APICallOptions, APIEntity, apiGet } from '$src/core-app/services/api.service'
import type Company from '$src/crm-app/models/company'
import mixpanel from 'mixpanel-browser'
import BiFrInpiCompanyBySirenAPIResponse from '../models/inpi.model'
import { remoteDebuggingService } from '$src/core-app/services/remote-debugging.service'
import customLog from './custom-log.service'

/**
 * SEARCH COMPANIES BY NAME AND ZIPCODE (optional) 
 * @param {string} workspaceId 
 * @param {string} name 
 * @param {string} zipCode 
 * @param {number} index 
 * @param {number} maxItems 
 * @returns {Company[]}
 */
export const getCompaniesByName = async (workspaceId: string, name : string, zipCode?:string, index: number = 0, maxItems: number = 20):Promise<Company[]> => {
  let req = `/workspace/${workspaceId}/bi/fr/insee/companies?q=(raisonSociale:"*${encodeURIComponent(name)}*"`

  // SIREN / SIRET
  if (!isNaN(Number(name))) {
    req += ` OR siren:${encodeURIComponent(name)} OR siret:${encodeURIComponent(name)})`
  } else {
    // NAME
    const nameTrim = name.trim()
    const posSpace = nameTrim.indexOf(' ')

    if (posSpace > 0) {
      const namePart1 = nameTrim.slice(0, posSpace)
      const namePart2 = nameTrim.slice(posSpace + 1)

      if (namePart1 && namePart2)
        req += ` OR (
          ((nomUniteLegale:"*${encodeURIComponent(namePart1)}*" OR nomUsageUniteLegale:"${encodeURIComponent(namePart1)}") AND prenom1UniteLegale:"${encodeURIComponent(namePart2)}")
          ((nomUniteLegale:"*${encodeURIComponent(namePart2)}*" OR nomUsageUniteLegale:"${encodeURIComponent(namePart2)}") AND prenom1UniteLegale:"${encodeURIComponent(namePart1)}")
        ))`
    } else {
      req += ')'
    }
  }

  if (zipCode) req += ` AND codePostalEtablissement:${zipCode.length < 5 ? zipCode + '*' : zipCode}`
  req += ` AND etatAdministratifUniteLegale:A&maxItems=${maxItems}&startIndex=${index ?? 0}`
  
  try {
    const res:{ companies:Company[], header:any } = await apiGet(req, <APICallOptions>{
      entity: APIEntity.BI_FR_INSEE,
      ignoreFeedback: true
    })
    
    customLog.log('CompanySearched', { companySearchedInput: req, companySearchedOutput: JSON.stringify(res) })

    return res?.companies?.filter(c => !c.formalName.includes('[ND]'))
  } catch (err) {
    mixpanel.track('onb.cpn.inf.C12.t company search error api', {
      'Description': 'INSEE API Error from company.service.ts'
    })

    remoteDebuggingService.addInfo(err, 'error')
    
    return []
  }
}

/**
 * 
 * @param {string} workspaceId 
 * @param {string} siret 
 * @param {number} index 
 * @param {number} maxItems 
 * @returns {Company[]}
 */
export const getCompaniesBySiret = async (workspaceId: string, siret : string, index: number = 0, maxItems: number = 20):Promise<Company[]> => {
  let req = `/workspace/${workspaceId}/bi/fr/insee/companies?q=siret:${siret} AND etatAdministratifUniteLegale:A&maxItems=${maxItems}&startIndex=${index}`
  
  try {
    const res:{ companies:Company[], header:any } = await apiGet(req, <APICallOptions>{
      entity: APIEntity.BI_FR_INSEE,
      ignoreFeedback: true
    })
    
    return res?.companies?.filter(c => !c.formalName.includes('[ND]'))
  } catch (err) {
    remoteDebuggingService.addInfo(err, 'error')
    
    return []
  }
}

/**
 * GET INPI INFORMATIONS FOR COMPANY BY SIREN
 * @param workspaceId 
 * @param siren 
 * @returns 
 */
export const getInpiCompanyInfo = async (workspaceId: string, siren : string):Promise<BiFrInpiCompanyBySirenAPIResponse | null> => {
  let req = `/workspace/${workspaceId}/bi/fr/inpi/companies/siren/${siren}`

  try {
    const res:BiFrInpiCompanyBySirenAPIResponse = await apiGet(req, <APICallOptions>{
      entity: APIEntity.BI_FR_INPI,
      ignoreFeedback: true
    })
    
    return res
  } catch (err) {
    remoteDebuggingService.addInfo(err, 'error')

    return null
  }
}
<script lang="ts">
    import { t } from '../../i18n/i18nextWrapper'
    import { createEventDispatcher } from 'svelte'

    /** Helper to set classes from component */
    let propClass = ''
    export { propClass as class }

    /** Dispatch */
    const dispatch = createEventDispatcher()

    /** Pagination Declarations */
    export let totalResults: number
    export let pageSize: number
    export let startResult: number
    export let endResult: number
    export let currentPage: number
    export let totalPage: number

    /** Set an id for the input element */
    export let id: string = 'ccs-' + Math.random().toString(36)

</script>

<div data-ut-component="aggrid-custom-pagination" class="pagination-wrapper" id="{id}">
    <div class="{propClass} w-full flex flex-row items-center justify-between">
        <div class="flex flex-row">
        <span class="isolate inline-flex rounded-md">
          <button on:click={() => { dispatch('setPageSize', { size: 25 })} }
                  type="button"
                  class="pagination-size-tab pagination-size-tab-25 rounded-l-md"
                  class:pagination-size-tab-active={pageSize === Number(25)}>25</button>
          <button on:click={() => { dispatch('setPageSize', { size: 50 })}}
                  type="button"
                  class="pagination-size-tab pagination-size-tab-50 -ml-px"
                  class:pagination-size-tab-active={pageSize === Number(50)}>50</button>
          <button on:click={() => { dispatch('setPageSize', { size: 100 })}}
                  type="button"
                  class="pagination-size-tab pagination-size-tab-100 -ml-px rounded-r-md"
                  class:pagination-size-tab-active={pageSize === Number(100)}>100</button>
        </span>
        </div>
        <div class="flex flex-row flex-nowrap justify-end items-center">
            <div class="text-sm mr-1">{startResult}-{endResult} {t('agGrid.of')} {totalResults}</div>
            <span class:pagination-size-tab-inactive={currentPage === 0}
                  class="ml-0.5 border border-transparent hover:bg-whisper hover:border hover:border-athensGray rounded-l-md"
                  on:click={() => {dispatch('goToPreviousPage')}}>
            <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                      d="M13.25 8.75L9.75 12L13.25 15.25"></path>
            </svg>
        </span>
            <span class:pagination-size-tab-inactive={currentPage === (totalPage - 1)}
                  class="ml-0.5 border border-transparent hover:bg-whisper hover:border hover:border-athensGray rounded-r-md"
                  on:click={() => {dispatch('goToNextPage')}}>
            <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                      d="M10.75 8.75L14.25 12L10.75 15.25"></path>
            </svg>
        </span>
        </div>
    </div>
</div>

<style lang="postcss">
    .pagination-wrapper {
        @apply w-full;

    }
    .pagination-size-tab {
        @apply relative inline-flex items-center border border-gray-300 bg-white px-2 py-1.5 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-dundyOrange focus:outline-none focus:ring-1 focus:ring-dundyOrange;
    }

    .pagination-size-tab-inactive {
        @apply text-zinc-400 hover:bg-transparent hover:border-transparent;
    }

    .pagination-size-tab-active {
        @apply bg-whisper;
    }
</style>
import type { ColDef, GetQuickFilterTextParams, ValueGetterParams } from 'ag-grid-community'
import DatatableCompanyAndInvoiceNumberRenderer from './Renderers/DatatableCompanyAndInvoiceNumberRenderer'
import DatatableAmountIncludingTaxesAndIssueDateRenderer
  from './Renderers/DatatableAmountIncludingTaxesAndIssueDateRenderer'

export function cashApplicationReadyInvoicesColumnDefs(): ColDef[] {
  return [
    {
      headerName: 'Did',
      field: 'did',
      sortable: false,
      hide: true,
      suppressColumnsToolPanel: true,
      width: 150
    },
    {
      headerName: '',
      field: 'companyAndInvoiceNumber',
      cellRenderer: DatatableCompanyAndInvoiceNumberRenderer,
      sortable: false,
      editable: false,
      unSortIcon: true,
      valueGetter: (params: ValueGetterParams): { invoiceNumber: any, toName: any } => ({ toName: params.data.toName, invoiceNumber: params.data.invoiceNumber }),
      getQuickFilterText: function (params: GetQuickFilterTextParams<any>): string {
        const { toName, invoiceNumber } = params.data
        const quickFilterText: string = `${toName} ${invoiceNumber}`
        
        return params.colDef.hide ? '' : quickFilterText
      }
    },
    {
      headerName: '',
      field: 'amountIncludingTaxesAndIssueDate',
      cellRenderer: DatatableAmountIncludingTaxesAndIssueDateRenderer,
      sortable: false,
      editable: false,
      unSortIcon: true,
      cellClass: ['ag-cell--amountIncludingTaxesAndIssueDate'],
      valueGetter: (params: ValueGetterParams<any>): { amountIncludingTaxes: any, dateIssued: any } => ({ amountIncludingTaxes: params.data.amountIncludingTaxes, dateIssued: params.data.dateIssued }),
      getQuickFilterText: function (params: GetQuickFilterTextParams<any>): string {
        const { amountIncludingTaxes, dateIssued } = params.data
        const quickFilterText: string = `${amountIncludingTaxes} ${dateIssued}`
        
        return params.colDef.hide ? '' : quickFilterText
      }
    }
  ]
}



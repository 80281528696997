import { APICallOptions, APIEntity, apiGet, apiPut } from './api.service'
import { get } from 'svelte/store'
import { eventsManager } from '../events/event-manager'
import { EventType } from '../events/event-type'
import {
  authZJsFirstName,
  authZJsLastName,
  authZJsUserId,
  userUnderAuth0JsClient
} from '../lib/auth0authentication/authStore'
import { Langs } from '../enums/lang'
import { currentLanguageCode } from '../stores/i18n.store'
import { changeLanguageToProfile, currentProfileLanguage } from '../lib/i18n/i18nextWrapper'
import Profile from '../models/profile'
import type { DundyEvent } from '../../dundy-app/events/dundy-event'
import { JsonProperty } from '@paddls/ts-serializer'
import type { ApiError } from "$core/models/api-error";

class ProfileGetResponse {
  @JsonProperty({ field: 'profile', type: () => Profile }) profile: Profile
  // converts the js prototype into the TypeScript class
  static PrototypeToClass(jsPrototype: ProfileGetResponse): ProfileGetResponse {
    return <ProfileGetResponse> Object.assign(new ProfileGetResponse(), jsPrototype)
  }
}


class ProfileServiceClass {
  initialized: boolean
  initialize() {
    if (!this.initialized) {
      /* console.log('init Profile !!!!') */
      this.initialized = true

      eventsManager.on<Profile>(EventType.PROFILE_CHANGED, (e: DundyEvent<Profile>): void => {
        const newProfile: Profile = Profile.PrototypeToClass(e.data)
        if (get(currentLanguageCode) !== currentProfileLanguage()) {
          /* console.log('"', get(currentLanguageCode), '" is different from "', currentProfileLanguage(), '"') */
          changeLanguageToProfile(() => {
            /* console.log('language updated !') */
          })
        }
        this.save(newProfile)
          .then(() => {
            // handle successful save
          })
          .catch(error => {
            // handle error during save
            console.error(error)
          })
      }, 'App.svelte')

      this.fetchOrCreateNewProfile()
    }
  }
  private fetchOrCreateNewProfile() {
    apiGet<ProfileGetResponse>('/user/' + get(authZJsUserId) + '/profile', <APICallOptions> {
        entity: APIEntity.PROFILE,
        ignoreFeedback: true
      })
      .then((response: ProfileGetResponse) => ProfileGetResponse.PrototypeToClass(response))
      .then((response:ProfileGetResponse) => {
        console.log("fetchOrCreateNewProfile() (1) /user/xxx/profile", response)
        return this.delay<ProfileGetResponse>(0/*1268*/, response)
      })
      .then((response: ProfileGetResponse): void => {
        const newProfile: Profile = Profile.PrototypeToClass(response.profile)
        /* console.log('subscribe response loaded Profile=', newProfile) */
        if (!!response && !!response.profile) {
          eventsManager.emit(EventType.PROFILE_FETCHED, newProfile, 'profilesService')
        } else {
          console.error('failed to get the Profile 1, trying again from scratch. response= ', response)
          this.fetchOrCreateNewProfile()
        }
      })
      .catch((reason: ApiError) => {
        // TODO : dangerous cyclic calls fetchOrCreateNewProfile()->createNewProfile()->fetchOrCreateNewProfile()
        //   but in case of bad connection, unavoidable: hence the delay of 2134 ms between calls
        // do not create a new profile here, otherwise it will delete data in case of failed request after page reload
        if(reason.status === 404){
          this.createNewProfile()
        } else {
          console.error('failed to get the Profile 2, trying again from scratch. reason= ', reason)
          this.delay<ApiError>(3345, reason)
            .then((reason:ApiError)=>{
              this.fetchOrCreateNewProfile()
            })
        }
      })
  }

  private delay<T>(timeMilliseconds: number, passOnObject:T):Promise<T> {
    return new Promise<T>((resolve) => setTimeout(()=>{resolve(passOnObject)}, timeMilliseconds));
  }

  private createNewProfile(): void {
    const newProfile: Profile = Profile.PrototypeToClass(<Profile> {
      firstName: get(authZJsFirstName),
      lastName: get(authZJsLastName),
      lang: navigator?.language.indexOf('fr') === -1 ? Langs.en_US : Langs.fr_FR,
      email: get(userUnderAuth0JsClient).email
    })
    apiPut<Profile>(`/user/${ get(authZJsUserId) }/profile`, { profile: newProfile }, <APICallOptions> {
      entity: APIEntity.PROFILE
    })
      .then(() => {
        //TODO : dangerous cyclic calls fetchOrCreateNewProfile()->createNewProfile()->fetchOrCreateNewProfile()
        this.fetchOrCreateNewProfile()
      })
      .catch(reason => {
        console.error('failed to create the Profile, trying again from scratch', reason)
      })
  }
  private save(profile: Profile) {
    return apiPut(`/user/${ get(authZJsUserId) }/profile`, { profile }, <APICallOptions> {
      entity: APIEntity.PROFILE
    })
  }
}


export const profileService = new ProfileServiceClass()

import { get } from 'svelte/store'
import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
import type { ICellRendererParams } from 'ag-grid-community'
import { CustomersStore } from '../../stores/customers.store'
import type { Customer } from '../../models/customer'

export default class DatatableCustomerRenderer {
  eGui: HTMLSpanElement
  /**
     * Gets called once before the renderer is used
     * @param params
     */
  init(params: ICellRendererParams): void {
    // create the cell
    this.eGui = document.createElement('span')
    this.eGui.classList.add(
      'customer-label',
      'truncate',
    )
    const { newElementClass, newInnerHtml } = this.updateDatatableRenderedValue(params)
    this.eGui.classList.add(newElementClass)
    this.eGui.innerHTML = newInnerHtml
  }
  /**
     * Get GUI for the cell renderer
     */
  getGui(): HTMLSpanElement {
    return this.eGui
  }
  /**
     * Gets called when the cell refreshes.
     * return true to tell the grid we refreshed successfully
     * @param params
     */
  refresh(params: ICellRendererParams): boolean {
    /* console.log('REFRESH : invoice status') */
    this.eGui.classList.remove('enter-new-customer-label')
    this.eGui.classList.remove('customer-label')
    /* console.log('DatatableCustomerRenderer refresh this.updateDatatableRenderedValue') */
    const { newElementClass, newInnerHtml } = this.updateDatatableRenderedValue(params)
    this.eGui.classList.add(newElementClass)
    this.eGui.innerHTML = newInnerHtml
    
    return true
  }
  /**
     * Gets called when the cell is removed from the grid
     */
  destroy(): void {
  }
  /**
     * Update Rendered Value
     * @param params
     */
  updateDatatableRenderedValue(params: ICellRendererParams): { newElementClass: string, newInnerHtml: string } {
    let newElementClass: string
    let newInnerHtml: string
    const customerId = params.getValue()
    let emptyMessage: string
    if (customerId && customerId !== '') {
      newElementClass = 'customer-label'
      emptyMessage = t('invoices.datatable.missingCompanyName')
    } else {
      newElementClass = 'enter-new-customer-label'
      emptyMessage = t('invoices.datatable.chooseACustomer')
    }

    const customerLabel: string = get(CustomersStore).find(
      (c: Customer): boolean => c.company.companyId === params.getValue())?.company.formalName

    if (!customerLabel || customerLabel === '') {
      newInnerHtml = params.data.toName && (params.data.toName !== '') ? params.data.toName : emptyMessage
    } else {
      newInnerHtml = customerLabel
    }
    
    return { newElementClass, newInnerHtml }
  }
}

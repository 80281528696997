<script lang="ts">
  import { Contact } from '$crm/models/contact'
  import { ContactsStore } from '$src/crm-app/stores/contacts.store'
  import { DunningMessageKind } from '$dundy/models/dunning-message-template'
  import { feedbackService } from '$src/core-app/services/feedback.service'
  import { historyChange, historyClean, historyLoad } from '$shared/utils/misc'
  import { navigate } from 'svelte-routing'
  import { onDestroy, onMount } from 'svelte'
  import { placeholders } from '$src/dundy-app/data/placeholder'
  import { saveDunningTemplate } from '$dundy/services/dunning-template.service'
  import { t } from '$core/lib/i18n/i18nextWrapper'
  import { ToastType } from '$src/core-app/enums/feedback-enums'
  import { WorkspaceStore } from '$src/crm-app/stores/workspace.store'
  import PageModal from '$shared/components/templates/PageModal.svelte'
  import TemplateEditor from '../template/TemplateEditor.svelte'
  import type { Feedback } from '$src/core-app/models/feedback'
  import type { FieldsErrors } from '$src/dundy-app/data/template'
  import Variable from '$shared/components/list/Variable.svelte'
  import { isObject } from '$src/shared/utils/object'
  import { workflowSteps } from '$src/dundy-app/data/workflow'

  export let stepId:string = ''
  export let customerId:string = ''

  const toast = (msg: string, type: ToastType) => feedbackService.displayFeedback({
    feedbackMessage: msg,
    feedbackLevel: type
  } as Feedback)

  let allowSave:boolean = true

  // template editor
  let templateEditor:TemplateEditor
  let media:string = ''
  let language:string = ''
  let template:any = {}
  let inputKey:string = ''
  let formErrors:FieldsErrors = {}
  
  // local variables
  const stepName = workflowSteps.find(s => s.id = stepId)?.name
  const closeUrl = `/workflow${customerId ? '?cid=' + customerId : ''}`
  
  let inputAllowInject:boolean = true
  let variableSearchValue:string = ''
  let variablesVisible:boolean = false

  $:placeholdersFiltered = placeholders.filter(p => !p.fields || p.fields.includes(inputKey))

  $:contacts = $ContactsStore.reduce((acc:any[], cur:Contact) => {
    if ((customerId && cur.companyId === customerId) || cur.companyId === $WorkspaceStore.company.companyId) {

      if (media === DunningMessageKind.DunningEmail) {
        if (cur.email) acc.push({ key: cur.email, value: `${cur.firstName} ${cur.lastName} (${cur.email})` })
      } else if (media === DunningMessageKind.DunningPhoneCall) {
        if (cur.mobilePhone && cur.officePhone) {
          acc.push({ key: cur.mobilePhone, value: `${cur.firstName} ${cur.lastName} (${cur.mobilePhone} - ${cur.officePhone})` })
        } else {
          if (cur.mobilePhone) acc.push({ key: cur.mobilePhone, value: `${cur.firstName} ${cur.lastName} (${cur.mobilePhone})` })

          if (cur.officePhone) acc.push({ key: cur.officePhone, value: `${cur.firstName} ${cur.lastName} (${cur.officePhone})` })
        }
      } else if (media === DunningMessageKind.DunningPostalLetter) {
        if (cur.email) acc.push({ key: cur.email, value: `${cur.firstName} ${cur.lastName} (${cur.email})` })
      }
    }
  
    return acc
  }, [])

  $:variables = inputKey ? [...placeholdersFiltered, ...contacts].sort((a, b) => a.value.localeCompare(b.value)) : []

  // TEMPLATE EDITOR FUNCTIONS
  const onTemplateInputKeyChange = (value:string) => inputKey = value
  const onTemplateSearchChange = (value:string) => variableSearchValue = value
  const onTemplateChange = (value:any) => template = value
  const onTemplateMediaChange = (value:string) => {
    historyChange({ media: value }, 'workflow-template', { customerId, media: value })
  }

  // VARIABLES
  const onVariableSelected = (v:Variable) => {
    const isContactInput = ['emailFrom', 'emailsTo', 'emailsCC', 'emailsCCI', 'contactDestination', 'mailAddressDestination'].includes(inputKey)
    const separator = isContactInput ? ',' : ''
    const replaceWord = isContactInput && Boolean(variableSearchValue)
    
    if (templateEditor) templateEditor.injectPlaceholder(v.key, separator, replaceWord)
  }
 
  const onClose = () => navigate(closeUrl)
  
  const onSave = async () => {
    const { prefKey, prefValue, formFields } = template

    if (prefKey) template.prefKey.customerId = customerId || '*'
    if (prefValue) template.prefValue.kind = DunningMessageKind[media as keyof typeof DunningMessageKind]

    const res = await saveDunningTemplate(prefKey, prefValue, formFields, language)
    
    if (typeof res === 'string' && res) {
      toast(res, ToastType.Error)
    } else if (isObject(res)) {
      formErrors = { ...res as any }
    } else {
      toast(t('template.modal.saved'), ToastType.Success)
      onClose()
    }
  }

  onMount(async () => {
    const history = historyLoad('workflow-template')

    if (history) {
      media = history.data.media
      customerId = history.data.customerId
    }
  })

  onDestroy(() => historyClean('workflow-template'))
</script>

<PageModal title={t('workflow.editItem')} closeNavigation={closeUrl} width={'w-10/12'}>

  <svelte:fragment slot="left-side">
    {#if variablesVisible && placeholders.length > 0}
      <div class="text-sm font-semibold flex flex-col mt-4">
        <div class="flex items-center justify-between mb-2">
          {t('template.variable.title')}
        </div>

        <Variable
          bind:searchValue={variableSearchValue}
          {variables}
          placeholder={t('template.variable.filter')}
          disabled={!inputAllowInject}
          on:select={(v) => onVariableSelected(v.detail)}
        />
      </div>
    {/if}
    <span class="text-red-500">TODO : liste des variables placeholders</span>
  </svelte:fragment>

  <svelte:fragment slot="right-side">

    <div class="mb-4  text-s text-[#461ebd]">
      <span class="bg-[#F5EEFF] px-3 py-1 mr-1 rounded">{t(customerId ? 'template.modal.breadcrumbCustomer' : 'template.modal.breadcrumbWorkspace')}</span>
      /
      <span class="ml-1">{stepName}</span>
    </div>

    <div class="relative size-full flex flex-col bg-white border border-loblolly rounded p-6 overflow-hidden">

      <TemplateEditor
        {stepId}
        {customerId}
        {contacts}
        placeholders={placeholdersFiltered}
        bind:this={templateEditor}
        bind:media={media}
        bind:language={language}
        bind:inputAllowInject={inputAllowInject}
        bind:formErrors={formErrors}
        on:mediaChange={(value) => onTemplateMediaChange(value.detail)}
        on:inputKeyChange={(value) => onTemplateInputKeyChange(value.detail)}
        on:searchChange={(value) => onTemplateSearchChange(value.detail)}
        on:templateChange={(value) => onTemplateChange(value.detail)}
      />

      <div class="mt-4">
        <button class="btn action-cancel placeholder" class:placeholder-disabled={!variablesVisible} on:click={() => variablesVisible = !variablesVisible}>
          {variablesVisible ? t('template.variable.hide') : t('template.variable.show')}
        </button>
      </div>
        
    </div>
  </svelte:fragment>

  <svelte:fragment slot="footer">
      <button class="btn action-cancel mr-8" on:click|preventDefault|stopPropagation={() => onClose()}>
        {t('template.modal.cancel')}
      </button>
      <button class="btn action-bla primary" disabled={!allowSave} on:click|preventDefault|stopPropagation={() => onSave()}>
        {t('template.modal.save')}
      </button>
  </svelte:fragment>

</PageModal>

<style lang="postcss">
  .placeholder {
    @apply flex items-center;

    &::before {
      content : '';
      mask: url("/img/icons/braces-with-dots.svg");
      mask-repeat: no-repeat;
      background: #6738ea;
      height: 16px;
      width: 16px;
      margin-right: .2rem;
    }

    &-disabled {
      color: gray;

      &::before {
        background: gray;
      }
    }
  }
</style>

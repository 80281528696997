import { BusinessDocument } from '../../models/business-document'
import { BusinessDocumentStatus } from '../../enums/business-document-status'
import type { BusinessDocumentKind } from '$voxy/enums/business-document-kind'

export function filterAllOtherBusinessDocumentsButCurrentOne(currentBusinessDocumentId: string): (businessDocument: BusinessDocument) => boolean {
  return (businessDocument: BusinessDocument): boolean => businessDocument.businessDocumentId !== currentBusinessDocumentId
}

export function filterAllBusinessDocumentsWithCustomerId(customerId: string): (businessDocument: BusinessDocument) => boolean {
  return (businessDocument: BusinessDocument): boolean => businessDocument.customerCustomer.company.companyId === customerId
}

export function filterAllBusinessDocumentsWithDealId(dealId: string): (businessDocument: BusinessDocument) => boolean {
  return (businessDocument: BusinessDocument): boolean => businessDocument.linkedDeal.dealId === dealId
}

export function filterAllBusinessDocumentsWithBusinessDocumentId(businessDocumentId: string): (businessDocument: BusinessDocument) => boolean {
  return (businessDocument: BusinessDocument): boolean => businessDocument.businessDocumentId === businessDocumentId
}

export const filterAllBusinessDocumentsFinalized: (businessDocument: BusinessDocument) => boolean =
  (businessDocument: BusinessDocument): boolean => businessDocument.businessDocumentStatus === BusinessDocumentStatus.FINAL

export const filterAllBusinessDocumentsDraft: (businessDocument: BusinessDocument) => boolean =
  (businessDocument: BusinessDocument): boolean => businessDocument.businessDocumentStatus === BusinessDocumentStatus.DRAFT

export function filterAllBusinessDocumentsAccordingToKind(businessDocumentKind: BusinessDocumentKind): (businessDocument: BusinessDocument) => boolean {
  return (businessDocument: BusinessDocument): boolean => businessDocument.businessDocumentKind === businessDocumentKind
}

/**
 * GET INTERSECTION ITEMS BETWEEN TWO ARRAYS
 * @param any[] a 
 * @param any[] b 
 * @returns {any[]}
 */
export const arrayIntersection = (a:any[], b:any[]):any[] => a.filter(x => b.includes(x))

/**
 * GET DIFFERENCE ITEMS FROM TWO ARRAYS (values in just a)
 * @param {any[]} a 
 * @param {any[]} b 
 * @returns {any[]}
 */
export const arrayDifference = (a:any[], b:any[]):any[] => a.filter(x => !b.includes(x))

/**
 * GET DIFFERENCE ITEMS FROM TWO ARRAYS (exclusive OR)
 * @param any[] a 
 * @param any[] b 
 * @returns {any[]}
 */
export const arraySymmetricDifference = (a:any[], b:any[]):any[] => a.filter(x => !b.includes(x)).concat(b.filter(x => !a.includes(x)))
import { get } from 'svelte/store'
import { WorkspaceStore } from '$crm/stores/workspace.store'
import type { ApiError } from '$core/models/api-error'
import type { Workspace } from '$crm/models/workspace'
import type { BBBBankAccount } from '$bank/models/bbb-transactions-model'
import type { WorkspaceBankConfig } from "$pay/models/workspace-bank-config";
import { BBBItem, BBBItemsRespData } from "$bank/models/bbb-item-model";
import type BBBConnectionProcessUrlResponse from "$pay/models/bbb-connection-process-url-response-model";
import BBBAccountsConnectionStatusResponse from "$pay/models/bbb-connection-items-list-status-model";
import { deepClone } from "$core/util/object-deep-cloning";
import type BankAccount from "$pay/models/bank-account";
import { bankingTransactionsService } from "$bank/services/banking-transactions.service";
import { bbbAPIPrimitivesService } from "$pay/services/bbb-api-primitives.service";
import { feedbackService } from "$core/services/feedback.service";
import { Feedback } from "$core/models/feedback";
import { t } from "$core/lib/i18n/i18nextWrapper";
import mixpanel from "mixpanel-browser";

class BridgeByBankingServiceClass {

  getListOfBankAccountsToChooseFrom():Promise<BankAccount[]> {
    // STEP 2: log
    return new Promise<void>((resolve, reject) => {
      console.log("++ select bank after connection consent returned to app++ ", "STEP 3")
      resolve()
    })
      // STEP 3: load accounts list
      .then(() => {
        return bbbAPIPrimitivesService.getBBBWorkspaceBankAccountsListStandardized()
      })
      // STEP 4: mixpanel logs
      .then((bankAccounts: BBBBankAccount[]) => {
          console.log("++ select bank after connection consent returned to app++ ", "STEP 4")
          if (bridgeByBankingService.isFirstTimeBankConnectionConsentAtBBBConnectionProcessExitBackToApp()) {
            mixpanel.track('DE10 First-Time Bank Account Listing for Workspace', {
              'Description': 'Successfully listed bank accounts for workspace for first time'
            })
          } else {
            mixpanel.track('DE20 Bank Account Listing for Workspace', {
              'Description': 'Successfully listed bank accounts for workspace'
            })
          }
          return bankAccounts
        }
      )
      // STEP 5: determineBBBStatusForBankAccountsList
      .then((bankAccounts: BBBBankAccount[]) => {
        console.log("++ select bank after connection consent returned to app++ ", "STEP 5")
        return bridgeByBankingService.determineBBBStatusForBankAccountsList(bankAccounts)
      })
      // STEP 6: consequencesOfBankAccountsListStatus
      .then((accountsInfoResp: BBBAccountsConnectionStatusResponse) => {
        console.log("++ select bank after connection consent returned to app++ ", "STEP 6")
        return bridgeByBankingService.consequencesOfBankAccountsListStatus(accountsInfoResp)
      })
      // STEP 7: buildListOfBankAccounts
      .then((accountsInfoResp: BBBAccountsConnectionStatusResponse) => {
        console.log("++ select bank after connection consent returned to app++ ", "STEP 7")
        return bridgeByBankingService.buildListOfBankAccounts(accountsInfoResp.bankAccounts)
      })
  }



  // returns a Promise that responds a string: the front-end URL to navigate to BBB connection process
  getConnectUrlForWorkspace(bankConfig: WorkspaceBankConfig):Promise<BBBConnectionProcessUrlResponse> {
    return bbbAPIPrimitivesService.getBBBWorkspaceBankConnectionsItemsStandardized()
      .then((itemsResponse: BBBItemsRespData) =>
        this.determineBBBStatusForBankConnectionsItems(itemsResponse))
      .then((statusResponse: BBBAccountsConnectionStatusResponse) =>
        this.deleteBBBConnectionsItemsIfExpired(statusResponse))
      .then((ignorePassedResponse: any) =>
        bbbAPIPrimitivesService.delay<void>(1730, ignorePassedResponse))
      .then(() =>
        bbbAPIPrimitivesService.getBBBUserConnectionFunnelURLStandardized())
      .then((urlResponse: BBBConnectionProcessUrlResponse) =>
        bbbAPIPrimitivesService.delay<BBBConnectionProcessUrlResponse>(1730, urlResponse))
      .catch((error: ApiError):Promise<BBBConnectionProcessUrlResponse> => {
        console.error("getConnectUrlForWorkspace v2 error", error)
        if (error.status === 400 || error.status === 404) {
          return bbbAPIPrimitivesService.createBBBUserAsWorkspaceStandardized().then(response => {
            if (response) {
              return this.getConnectUrlForWorkspace(bankConfig)
            }else{
              return new Promise<BBBConnectionProcessUrlResponse>((resolve, reject) => {
                resolve({
                  ok: false,
                  data: "",
                  error: error.message,
                } as BBBConnectionProcessUrlResponse)
              })
            }
          })
        } else {
          return new Promise<BBBConnectionProcessUrlResponse>((resolve, reject) => {
            resolve({
              ok: false,
              data: "",
              error: error.message,
            } as BBBConnectionProcessUrlResponse)
          })
        }
      })
  }

  determineBBBStatusForBankConnectionsItems(bankConnectionsItemsResponse: BBBItemsRespData):Promise<BBBAccountsConnectionStatusResponse> {
    return new Promise<BBBAccountsConnectionStatusResponse>((resolve, reject) => {
      let isBankConnectionFoundExpired: boolean = false
      let accountItemId: number = 0
      let foundItem: BBBItem = {} as BBBItem
      let foundNonZeroCodeOrZero: number = 0
      let itemIdHavingStatus: number = 0
      // Account is described in https://docs.bridgeapi.io/reference/account-resource
      // Status is described in https://docs.bridgeapi.io/docs/items-status
      for (let item of bankConnectionsItemsResponse.resources) {
        accountItemId = item.id
        foundItem = item
        if(item.status === 402){
          console.log("-+-+- in determineBBBStatusForBankConnectionsItems: Expired Item code ", item.status, item.status_code_info, item.status_code_description)
          // Account Status 402 INVALID_CREDS and other codes:
          // Wrong credentials were provided.
          // All codes for 402: (see https://docs.bridgeapi.io/docs/items-status)
          // - INVALID_CREDS
          // - WRONG_ID
          // - WRONG_ID_FORMAT
          // - WRONG_PWD
          // - WRONG_PWD_FORMAT
          // - WRONG_PWD2
          // - WRONG_PWD2_FORMAT
          // - ACCOUNT_TEMPORARILY_BLOCKED
          // - ACCOUNT_BLOCKED
          // - ACCOUNT_BLOCKED_CONTACT_BANK
          isBankConnectionFoundExpired = true
          foundNonZeroCodeOrZero = item.status
          itemIdHavingStatus = item.id
          break
        }
        if(item.status === 1010){
          console.log("-+-+- in determineBBBStatusForBankConnectionsItems: Need SCA code ", item.status, item.status_code_info, item.status_code_description)
          // Account Status 1010 OTP_REQUIRED and other codes:
          // The item wasn't refreshed successfully because it required a Multi-Factor Authentication (MFA) or One-Time Password (OTP) that wasn't provided. Please see Strong Customer Authentication. https://docs.bridgeapi.io/docs/strong-customer-authentication
          // All codes for 1010: (see https://docs.bridgeapi.io/docs/items-status)
          // - OTP_REQUIRED
          // - OTP_FAILED
          // - WRONG_OTP
          // - EXPIRED_DEVICE
          // - INVALID_PHONE_NB
          // - BLOCKED_LIMIT_REACHED
          // - BLOCKED_SMS_LIMIT_REACHED
          // - OTP_NOT_SUPPORTED
          // - SCA_REQUIRED_WEBVIEW
          isBankConnectionFoundExpired = true
          foundNonZeroCodeOrZero = item.status
          itemIdHavingStatus = item.id
          break
        }
      }
      const result:BBBAccountsConnectionStatusResponse = {
        isBankConnectionFoundExpired: isBankConnectionFoundExpired,
        firstNonZeroStatusCodeOrZero: foundNonZeroCodeOrZero,
        bankAccounts: deepClone(bankConnectionsItemsResponse.resources),
        item_id_having_status: itemIdHavingStatus,
      } as BBBAccountsConnectionStatusResponse
      resolve(result)
    })
  }

  deleteBBBConnectionsItemsIfExpired(BBBAccountsConnectionStatusResponse: BBBAccountsConnectionStatusResponse):Promise<void> {
    if(BBBAccountsConnectionStatusResponse.isBankConnectionFoundExpired){
      return bbbAPIPrimitivesService.deleteBBBConnectionsItemsStandardized()
    } else {
      return new Promise<void>((resolve, reject): void => {
        resolve()
      })
    }
  }

  buildListOfBankAccounts(bankAccounts: BBBBankAccount[]): Promise<BankAccount[]> {
    return new Promise<BankAccount[]>((resolve, reject) => {
      let availableAccounts: BankAccount[] = []
      for(let account of bankAccounts) {
        if (account.iban && !availableAccounts.find(acc => acc.accountNumber === account.iban)) {
          const bankAccount = {} as BankAccount
          bankAccount.id = ((account as any).id || (account as any).ID).toString()
          bankAccount.bankId = ''+account.bank_id+''
          bankAccount.bankName = account.bank_name
          bankAccount.accountNumber = account.iban
          bankAccount.name = account.name
          bankAccount.itemId = account.item_id.toString()
          availableAccounts.push(bankAccount)
        }
      }
      resolve(availableAccounts)
    })
  }

  determineBBBStatusForBankAccountsList(bankAccounts: BBBBankAccount[]):Promise<BBBAccountsConnectionStatusResponse> {
    return new Promise<BBBAccountsConnectionStatusResponse>((resolve, reject) => {
      let isBankConnectionFoundExpired: boolean = false
      let accountItemId: number = 0
      let foundAccount: BBBBankAccount = {} as BBBBankAccount
      let foundNonZeroCodeOrZero: number = 0
      let accountIdHavingStatus: number = 0
      // Account is described in https://docs.bridgeapi.io/reference/account-resource
      // Status is described in https://docs.bridgeapi.io/docs/items-status
      for (let account of bankAccounts) {
        accountItemId = account.id
        foundAccount = account
        if(account.status === 402){
          console.log("-+-+- in determineBBBStatusForBankAccountsList: Expired Account code ", account.status, account.status_code_info, account.status_code_description)
          // Account Status 402 INVALID_CREDS and other codes:
          // Wrong credentials were provided.
          // All codes for 402: (see https://docs.bridgeapi.io/docs/items-status)
          // - INVALID_CREDS
          // - WRONG_ID
          // - WRONG_ID_FORMAT
          // - WRONG_PWD
          // - WRONG_PWD_FORMAT
          // - WRONG_PWD2
          // - WRONG_PWD2_FORMAT
          // - ACCOUNT_TEMPORARILY_BLOCKED
          // - ACCOUNT_BLOCKED
          // - ACCOUNT_BLOCKED_CONTACT_BANK
          isBankConnectionFoundExpired = true
          foundNonZeroCodeOrZero = account.status
          accountIdHavingStatus = account.item_id
          break
        }
        if(account.status === 1010){
          console.log("-+-+- in determineBBBStatusForBankAccountsList: Need SCA code ", account.status, account.status_code_info, account.status_code_description)
          // Account Status 1010 OTP_REQUIRED and other codes:
          // The item wasn't refreshed successfully because it required a Multi-Factor Authentication (MFA) or One-Time Password (OTP) that wasn't provided. Please see Strong Customer Authentication. https://docs.bridgeapi.io/docs/strong-customer-authentication
          // All codes for 1010: (see https://docs.bridgeapi.io/docs/items-status)
          // - OTP_REQUIRED
          // - OTP_FAILED
          // - WRONG_OTP
          // - EXPIRED_DEVICE
          // - INVALID_PHONE_NB
          // - BLOCKED_LIMIT_REACHED
          // - BLOCKED_SMS_LIMIT_REACHED
          // - OTP_NOT_SUPPORTED
          // - SCA_REQUIRED_WEBVIEW
          isBankConnectionFoundExpired = true
          foundNonZeroCodeOrZero = account.status
          accountIdHavingStatus = account.item_id
          break
        }
      }
      const result:BBBAccountsConnectionStatusResponse = {
        isBankConnectionFoundExpired: isBankConnectionFoundExpired,
        firstNonZeroStatusCodeOrZero: foundNonZeroCodeOrZero,
        bankAccounts: deepClone(bankAccounts),
        item_id_having_status: accountIdHavingStatus,
      } as BBBAccountsConnectionStatusResponse
      resolve(result)
    })
  }

  consequencesOfBankAccountsListStatus(statusResult: BBBAccountsConnectionStatusResponse):Promise<BBBAccountsConnectionStatusResponse> {
    return new Promise<BBBAccountsConnectionStatusResponse>((resolve, reject) => {
      switch (statusResult.firstNonZeroStatusCodeOrZero){
        case 402:
          bankingTransactionsService.setBBBConnectionAsExpiredGlobally()
          break
        case 1010:
          bankingTransactionsService.setBBBConnectionAsExpiredGlobally()
          // bbbAPIPrimitivesService.getScaUrlForWorkspaceStandardized(statusResult.item_id_having_status)
          //   .then((urlResponse: any) => {
          //     // bridgeByBankingService.getConnectUrlForWorkspace(bankConfig).then((urlResponse: BBBConnectionProcessUrlResponse) => {
          //     if (urlResponse.ok) {
          //       window.location.href = urlResponse.data
          //     }
          //   })
          break
      }
      resolve(statusResult)
    })
  }

  isFirstTimeBankConnectionConsentAtBBB(bankConfig: WorkspaceBankConfig): boolean {
    return !bankConfig.bankConnectionCompletedAtLeastOnce || bankConfig.bankConnectionFirstCompletedRFC3339 === ""
  }

  getBankConfigWithBankConnectionAsKnownActive(workspace: Workspace):Workspace {
    mixpanel.track('DD50 Renewed Bank Account Consent for Workspace Successfully', {
      'Description': 'Successfully given consent for bank account for workspace again'
    })
    let newWorkspace: Workspace = deepClone(workspace)
    newWorkspace.bankConfig.bankConnectionCurrentlyKnownActive = true
    newWorkspace.bankConfig.bankConnectionLastCompletedRFC3339 = new Date().toISOString()
    return newWorkspace
  }

  getBankConfigWithFirstTimeBankConnectionUpdated(workspace: Workspace):Workspace{
    mixpanel.track('DD30 First-Time Bank Account Consent for Workspace Granted Successfully', {
      'Description': 'Successfully given consent for bank account for workspace for first time'
    })
    let newWorkspace: Workspace = deepClone(workspace)
    newWorkspace.bankConfig.bankConnectionCompletedAtLeastOnce = true
    newWorkspace.bankConfig.bankConnectionFirstCompletedRFC3339 = new Date().toISOString()
    return newWorkspace
  }

  isFirstTimeBankConnectionConsentAtBBBConnectionProcessExitBackToApp(): boolean {
    const currentBankConfig: WorkspaceBankConfig = get(WorkspaceStore)?.bankConfig || {} as WorkspaceBankConfig
    return this.isFirstTimeBankConnectionConsentAtBBB(currentBankConfig)
  }

  saveFirstTimeBankConnectionConsentToStoreVariable() {
    return new Promise<void>((resolve, reject) => {
        console.log('%c IN saveFirstTimeBankConnectionConsentToStoreVariable', 'color:green;font-size: 1.5em;')
        this.setCurrentBankAsFirstTimeConnectionIfNeedBe()
        resolve()
    })
  }

  setCurrentBankConnectionAsKnownActive() {
    let workspace: Workspace = get(WorkspaceStore)
    let newWorkspace: Workspace = this.getBankConfigWithBankConnectionAsKnownActive(workspace)
    WorkspaceStore.set(newWorkspace)
  }

  setCurrentBankAsFirstTimeConnectionIfNeedBe() {
    let workspace: Workspace = get(WorkspaceStore)
    let newWorkspace1: Workspace
    if(this.isFirstTimeBankConnectionConsentAtBBB(workspace.bankConfig)){
      newWorkspace1 = this.getBankConfigWithFirstTimeBankConnectionUpdated(workspace)
    }else{
      newWorkspace1 = deepClone(workspace)
    }
    WorkspaceStore.set(newWorkspace1)
  }

  setCurrentBankConnectionAsKnownActiveAndFirstTimeConnectionIfNeedBe() {
    let workspace: Workspace = get(WorkspaceStore)
    let newWorkspace1: Workspace = this.getBankConfigWithBankConnectionAsKnownActive(workspace)
    let newWorkspace2: Workspace
    if(this.isFirstTimeBankConnectionConsentAtBBB(newWorkspace1.bankConfig)){
      newWorkspace2 = this.getBankConfigWithFirstTimeBankConnectionUpdated(newWorkspace1)
    }else{
      newWorkspace2 = deepClone(newWorkspace1)
    }
    WorkspaceStore.set(newWorkspace2)
  }

  removeBankConnection(){
    bbbAPIPrimitivesService.deleteBBBUserAsWorkspaceStandardized()
      .then(() => {
        let newWorkspace: Workspace = deepClone(get(WorkspaceStore))
        newWorkspace.bankConfig.selectedAccountBankName = ""
        newWorkspace.bankConfig.selectedAccountId = ""
        newWorkspace.bankConfig.selectedAccountName = ""
        newWorkspace.bankConfig.bankConnectionCurrentlyKnownActive = false
        newWorkspace.bankConfig.selectedAccountBankBIC = ""
        newWorkspace.bankConfig.selectedAccountBankIBAN = ""
        WorkspaceStore.set(newWorkspace)
      })
      .then(() => {
        feedbackService.displayFeedback(<Feedback>{
          feedbackLevel: 'Info',
          feedbackMessage: t('editBank.connectionRemoved')
        })
      })
  }

  /*
  hasBankNeverBeenLinkedOrBankLinkExpired and hasBankLinkExpired replace incoherent scattered way of testing if bank is linked or if link is expired
   */
  hasBankNeverBeenLinkedOrBankLinkExpired(bankConfig: WorkspaceBankConfig | undefined):boolean{
    if(!bankConfig){
      /*console.log('%c IN hasBankNeverBeenLinkedOrBankLinkExpired(), NO bankConfig', 'color:red;font-size: 1em;')*/
      return true
    }
/*
    if (!bankConfig.bankConnectionCompletedAtLeastOnce){
      console.log('%c IN hasBankNeverBeenLinkedOrBankLinkExpired(), bankConnectionCompletedAtLeastOnce FALSE', 'color:red;font-size: 1em;')
    } else if (bankConfig.bankConnectionFirstCompletedRFC3339===""){
      console.log('%c IN hasBankNeverBeenLinkedOrBankLinkExpired(), bankConfig.bankConnectionFirstCompletedRFC3339===""', 'color:red;font-size: 1em;')
    }else if (!bankConfig.bankConnectionCurrentlyKnownActive){
      console.log('%c IN hasBankNeverBeenLinkedOrBankLinkExpired(), !bankConfig.bankConnectionCurrentlyKnownActive', 'color:red;font-size: 1em;')
    }
*/
    return !bankConfig.bankConnectionCompletedAtLeastOnce || bankConfig.bankConnectionFirstCompletedRFC3339==="" || !bankConfig.bankConnectionCurrentlyKnownActive
  }

  setNewSelectedBankAccount( availableAccounts: BankAccount[], selectedBankAccountId: string, selectedAccountBankName: string, selectedBankAccountBIC: string): boolean {
    const selectedAccount: BankAccount = availableAccounts.find((account: BankAccount): boolean => account.id === selectedBankAccountId) || {} as BankAccount
    if(selectedAccount.id === ""){
      console.error("could not find bank account in setNewSelectedBankAccount")
      return false
    }

    let workspace: Workspace = get(WorkspaceStore)
    let newWorkspace: Workspace = deepClone(workspace)
    newWorkspace.bankConfig.selectedAccountBankBIC = selectedBankAccountBIC
    newWorkspace.bankConfig.selectedAccountBankIBAN = selectedAccount.accountNumber
    newWorkspace.bankConfig.selectedAccountBankName = selectedAccountBankName
    newWorkspace.bankConfig.selectedAccountId = selectedBankAccountId
    newWorkspace.bankConfig.selectedAccountName = selectedAccount.name
    WorkspaceStore.set(newWorkspace)
    return true
  }

  hasBankLinkExpired(bankConfig: WorkspaceBankConfig | undefined):boolean{
    if(!bankConfig){
      /*console.log('%c IN hasBankLinkExpired(), NO bankConfig', 'color:red;font-size: 1.5em;')*/
      return true
    }
/*
    if (!bankConfig.bankConnectionCurrentlyKnownActive){
      console.log('%c IN hasBankLinkExpired(), !bankConfig.bankConnectionCurrentlyKnownActive', 'color:red;font-size: 1.5em;')
    }
*/
    return !bankConfig.bankConnectionCurrentlyKnownActive
  }

  isBBBConnectionOK(bankConfig: WorkspaceBankConfig | undefined):boolean{
    if(!bankConfig){
      console.log('%c IN isBBBConnectionOKButBankAccountNotSelected(), NO bankConfig', 'color:red;font-size: 1.5em;')
      return true
    }
    if (!bankConfig.bankConnectionCurrentlyKnownActive){
      console.log('%c IN hasBankLinkExpired(), !bankConfig.bankConnectionCurrentlyKnownActive', 'color:red;font-size: 1.5em;')
    }
    return !bankConfig.bankConnectionCurrentlyKnownActive
  }

  isBankAccountSelected(bankConfig: WorkspaceBankConfig | undefined):boolean{
    if(!bankConfig){
      console.log('%c IN isBBBConnectionOKButBankAccountNotSelected(), NO bankConfig', 'color:red;font-size: 1.5em;')
      return true
    }
    if (!bankConfig.bankConnectionCurrentlyKnownActive){
      console.log('%c IN hasBankLinkExpired(), !bankConfig.bankConnectionCurrentlyKnownActive', 'color:red;font-size: 1.5em;')
    }
    return !bankConfig.bankConnectionCurrentlyKnownActive
  }
}

export const bridgeByBankingService: BridgeByBankingServiceClass = new BridgeByBankingServiceClass()

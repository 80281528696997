<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper.js'
    import { BusinessDocument } from '../../models/business-document'
    import { BusinessDocumentKind } from '../../enums/business-document-kind'
    import { onMount } from 'svelte'
    import { updateNewNavigationHistoryItem } from '../../../core-app/stores/navigationHistory.store'
    import { formatCurrencyWithOptions } from '../../utils/number-formatting-utils.js'

    /** Export declarations */
    export let businessDocument: BusinessDocument

    /** Let declarations */
    let isCreditNote: boolean

    /** Reactive declarations **/
    $: isCreditNote = businessDocument?.businessDocumentKind === BusinessDocumentKind.CREDITNOTE

    onMount(()=>{
      updateNewNavigationHistoryItem('ViewBusinessDocumentDetails.svelte')
    })

</script>
<div data-ut-component="view-business-document-details">
    <div class="my-7 border border-athensGray rounded-lg mx-8">
        <div class="space-y-6 py-7">
            <div class="px-12 sm:px-8">
                {#if isCreditNote}
                    <h4 class="font-medium text-sm mb-3">{t('invoices.viewInvoice.creditNoteDetails')}</h4>
                {:else}
                    <h4 class="font-medium text-sm mb-3">{t('invoices.viewInvoice.invoiceDetails')}</h4>
                {/if}

                <div class="">
                    <ul>
                        <li class="flex justify-between text-s py-1.5">
                            {#if isCreditNote}
                                <span class="text-paleSky">{t('invoices.viewInvoice.creditNoteLabel')}</span>
                            {:else}
                                <span class="text-paleSky">{t('invoices.viewInvoice.invoiceNumberLabel')}</span>
                            {/if}
                            <span class="font-medium">{businessDocument?.businessDocumentNumber}</span>
                        </li>
                        <li class="flex justify-between text-s py-1.5">
                            <span class="text-paleSky">{t('invoices.viewInvoice.issued')}</span>
                            <span class="font-medium">{new Intl.DateTimeFormat(t('locales'), {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric'
                            }).format(new Date(businessDocument?.issuedDate.unixSeconds * 1000))}</span>
                        </li>
                        <li class="flex justify-between text-s py-1.5">
                            <span class="text-paleSky">{t('invoices.viewInvoice.due')}</span>
                            <span class="font-medium">{new Intl.DateTimeFormat(t('locales'), {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric'
                            }).format(new Date(businessDocument?.dueDate.unixSeconds * 1000))}</span>
                        </li>
                        {#if !isCreditNote}
                            <li class="flex justify-between text-s py-1.5">
                                <span class="text-paleSky">{t('invoices.viewInvoice.collectedTax')}</span>
                                <span class="font-medium">
                                    {formatCurrencyWithOptions(businessDocument?.installmentResultTaxScaledValue, t('locales'), businessDocument?.currency, true, 'symbol')}
                                </span>
                            </li>
                        {/if}
                        {#if !isCreditNote}
                            <li class="flex justify-between text-s py-1.5">
                                <span class="text-paleSky">{t('invoices.viewInvoice.leftToCollect')}</span>
                                <span class="font-medium">
                                    {formatCurrencyWithOptions(businessDocument?.amountDueScaledValue, t('locales'), businessDocument?.currency, true, 'symbol')}
                                </span>
                            </li>
                        {/if}
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
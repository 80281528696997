<script lang="ts">
  import { debounce } from '$src/core-app/util/function-utils'
  import { feedbackService } from '$src/core-app/services/feedback.service'
  import { isUserWsOwner } from '$src/core-app/services/guard'
  import { onMount } from 'svelte'
  import { Router, Route, navigate } from 'svelte-routing'
  import { searchTemplate, removeTemplate, getAllTemplate, duplicateTemplate } from '../services/template.service'
  import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
  import { Template } from '$src/dundy-app/models/template'
  import { Tooltip } from '$src/core-app/lib/ui-kit/Tooltip'
  import ConfirmationModal from '$src/core-app/lib/ui-kit/ConfirmationModal.svelte'
  import EditTemplate from '../lib/template/EditTemplate.svelte'
  import SearchBar from '$src/core-app/lib/ui-kit/SearchBar.svelte'
  import TextInput from '$src/core-app/lib/ui-kit/TextInput.svelte'
  import type { Feedback } from '$src/core-app/models/feedback'

  enum Action {
    DUPLICATE,
    DELETE
  }
  let action: Action
  let displayModal:boolean = false
  let modalProps: {
    modalHeading:string,
    primaryButtonText:string,
    secondaryButtonText:string
    primaryColor: string
  }

  let templates:Template[] = []
  let searchName:string = ''
  let templateId:number
  let templateName:string

  $:showDuplicateError = Boolean(templates.find(n => n.name?.toUpperCase() === templateName?.toUpperCase()))

  const toast = (type:string, message:string) => feedbackService.displayFeedback({
    feedbackMessage: message,
    feedbackLevel: type
  } as Feedback)

  const onSearch = debounce( e => {
    searchName = e.detail?.text
    templates = searchTemplate(searchName)
  }, 500)


  const onMenuDuplicate = (id: number) => {
    templateId = id
    action = Action.DUPLICATE
    modalProps = {
      modalHeading: t('template.duplicateHeading'),
      primaryButtonText: t('template.duplicatePrimaryButton'),
      secondaryButtonText: t('template.duplicateSecondaryButton'),
      primaryColor: 'dundyOrange'
    }
    displayModal = true
  }

  const onMenuDelete = (id: number) => {
    templateId = id
    action = Action.DELETE
    modalProps = {
      modalHeading: t('template.deleteHeading'),
      primaryButtonText: t('template.deletePrimaryButton'),
      secondaryButtonText: t('template.deleteSecondaryButton'),
      primaryColor: 'mojo'
    }
    displayModal = true
  }

  const onModalValidate = async () => {
    let fn: Promise<string>
    let success:string = ''
    let error:string = ''

    switch (action) {
      case Action.DUPLICATE: 
        fn = duplicateTemplate(templateId)
        success = t('template.duplicateFeedback')
        break
      case Action.DELETE: 
        fn = removeTemplate(templateId)
        success = t('template.deleteFeedback')
        break
    }

    displayModal = false
    error = await fn

    if (!error) {
      toast('Success', success)
    } else {
      toast('Error', error)
    }
  }

  onMount(async () => {
    templates = await getAllTemplate()
  })
</script>

<svelte:head>
    <title>{t('topMenu.nav.team.label')} - Dundy</title>
</svelte:head>

{#if isUserWsOwner()}
  <main class="container mx-auto">

    <div class="flex justify-between">
      <h1 class="text-black text-3xl font-bold">{t('template.title')}</h1>

      <!-- TODO: rendre le composant bouton générique avec des svg eclatés -->
      <button class="btn action-primary add"
          on:click|preventDefault|stopPropagation={() => navigate('/template/new')}>
        {t('template.add')}
      </button>
    </div>

    <div class="my-7">
      <SearchBar placeholder={t('template.search')}
              on:search={e => onSearch(e)}
              on:clear={() => onSearch('')}
              value={searchName}
              hideLabel={true}/>
    </div>

    <!-- TODO: rendre le composant table générique -->
    <table class="min-w-full divide-y divide-zinc-300">
      <thead>
        <tr>
          <th scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{t('team.listMembers.header.name')}</th>
          <th colspan="3" scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
            {t('template.actions')}
          </th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
        {#each templates as template}
          <tr>
            <td class="w-full">{template.name}</td>
            <td>
              <Tooltip>
                <div slot="activator">
                  <button class="edit" on:click={() => navigate(`/template/edit/${template.id}`)} />
                </div>
                {t('template.edit')}
              </Tooltip>
            </td>
            <td>
              <Tooltip>
                <div slot="activator">
                  <button class="copy" on:click={() => onMenuDuplicate(template.id)} />
                </div>
                {t('template.duplicate')}
              </Tooltip>
            </td>
            <td>
              <Tooltip>
                <div slot="activator">
                  <button class="delete" on:click={() => onMenuDelete(template.id)} />
                </div>
                {t('template.delete')}
              </Tooltip>
            </td>
          </tr>
        {/each}
      </tbody>
    </table>

    <ConfirmationModal
      on:cancelAndClose={() => {displayModal = false}}
      on:confirmAction={() => onModalValidate()}
      open={displayModal}
      {...modalProps}
    >
      {#if action === Action.DUPLICATE}
        {t('template.duplicateInfo')}
        <TextInput type="text" 
          bind:value="{templateName}"
          error={t('template.duplicateError')}
          showError={showDuplicateError}
          showValidationTick={false}
          autocomplete="off"
          transform="uppercase"
          placeholder={t('template.duplicatePlaceholder')}
          class="mb-3"
        />
      {:else if action === Action.DELETE}
        {t('template.deleteInfo')}
      {/if}
    </ConfirmationModal>

    <Router>
      <Route path="new">
        <EditTemplate />
      </Route>
      <Route path="edit/:id">
        <EditTemplate />
      </Route>
    </Router>

  </main>
{:else}
    <p>Not allowed</p>
{/if}

<style lang="postcss">
  .add {
    @apply cursor-pointer;

    &::before { 
      content: '';
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 0.5rem;
      background-color: white;
      mask: url('/img/icons/plus.svg');
    }

    &:hover {
      &::before {
        background-color: black;
      }
    }
  }

  .edit {
    @apply bg-[url('/img/icons/edit.svg')];
  }
  .copy {
    @apply bg-[url('/img/icons/copy.svg')] ;
  }
  .delete {
    @apply bg-[url('/img/icons/delete.svg')];
  }

  table {
    td {
      @apply py-2 px-2;

      &:nth-child(1) {
        @apply px-6
      }
    }

    button {
      @apply size-7 p-5 bg-no-repeat bg-center rounded border-2 border-transparent;
      display: inherit;
      &:active,
      &:hover {
          @apply bg-provincialPink border-dundyOrange;
      }
    }
  }
</style>
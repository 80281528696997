<script lang="ts">
    import Invoice from '../../models/invoice'
    import type { Customer } from '../../../crm-app/models/customer'
    import { CustomersStore } from '../../../crm-app/stores/customers.store'

    export let invoice: Invoice

    /** Let Declarations */
    let customerInitial: string
    let customer: Customer

    /** Reactive declarations */
    $: customer = (invoice?.toId ? $CustomersStore.find(c => c.company.companyId === invoice?.toId) : (invoice?.toName ? $CustomersStore.find(c => c.company.formalName === invoice?.toName) : <Customer>{}))
    $: customerInitial = customer?.company?.formalName?.substring(0, 1)

</script>
<div data-ut-component="view-business-company-logo-name">
    <div class="space-y-6 py-7">
        <div class="px-12 sm:px-8">
            <div class="flex flex-row w-full justify-start items-center">
                <div class="h-10 w-10 p-3 pt-1.5 bg-ebonyClay rounded uppercase text-center">
                    <span class="text-white text-lg">{customerInitial}</span>
                </div>
                <div class="font-semibold ml-4 relative">{customer?.company?.formalName}</div>
            </div>
        </div>
    </div>
</div>
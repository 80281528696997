import { get, type Writable, writable } from 'svelte/store'
import type Invoice from '../models/invoice'
import { eventsManager } from '../../core-app/events/event-manager'
import { EventType } from '../../core-app/events/event-type'
import { encrypt256Hash } from '../../core-app/util/encryption'
import initializationStore from '../../core-app/stores/initialization.store'
import { dunningInvoicesService } from '../services/dunning-invoices.service'
import { DunningViewListOption } from '../enums/dunning-view-list-options'

const getInvoices = (): Invoice[] => {
  const existingValue: string | null = localStorage.getItem('DunningInvoicesStore')
  const emptyValue: Invoice[] = <Invoice[]>[]
  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  if (existingValue === '{}') {
    return emptyValue
  }

  return <Invoice[]>JSON.parse(existingValue)
}

export let DunningInvoicesStore: Writable<Invoice[]> = writable<Invoice[]>(getInvoices())

export let DoNotPersistInvoicesStoreChangeOnce: Writable<boolean> = writable<boolean>(false)

export const initializeInvoicesStore = (data: Invoice[]) => {
  if (!get(initializationStore).invoicesStoreInitialized) {

    initializationStore.update(store => {
      store.invoicesStoreInitialized = true

      return store
    })

    localStorage.setItem('DunningInvoicesStore', JSON.stringify(data, null, 4))
    dunningInvoicesService.setInvoicesStoreToFetchedInvoiceListOnlyWheneverMostUpToDate(data)

    let isInvoicesStoreSubscriptionDefined: boolean = false
    // NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
    DunningInvoicesStore.subscribe((newInvoiceList: Invoice[]) => {
      if (!isInvoicesStoreSubscriptionDefined) {
        /* console.log('DunningInvoicesStore subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently') */
        isInvoicesStoreSubscriptionDefined = true

        return // we avoid the .subscribe() execution at the subscription occurrence
      }
      /* console.log('important newInvoiceList DunningInvoicesStore.subscribe with ' + (newInvoiceList?.length) + ' invoices') */

      if (newInvoiceList) {

        // we do not want to ask the store to save changes to DunningInvoicesStore in the following case where it is changed from outside (not from Dundy UI by user):
        // - we know the user has changed an invoice in Voxy (created or modified)
        // - those changes have been sent to the Voxy API
        // - the Voxy API has published a related event
        // - the event system has inserted a change for this Voxy invoice as a single Dundy invoice
        // - the Dundy invoice list for this workspace has been updated
        // So we have :
        // 1/ updated Voxy locally
        // 2/ this update has remotely updated the Dundy invoice
        // 3/ we have asked the Dundy web app to update the Dundy invoices from the API
        // 4/ so we replace the whole DunningInvoicesStore store content with the Dundy invoices newly downloaded from the backend
        // 5/ but we do not want the DunningInvoicesStore store to react to this change and save it back to the back-end... it is already coming from the back-end
        //    hence we set DoNotPersistInvoicesStoreChangeOnce to false (it will be useful just once)
        if (get(DoNotPersistInvoicesStoreChangeOnce)) {
          /* console.log('avoid to persist invoices store change this time') */
          DoNotPersistInvoicesStoreChangeOnce.set(false)
          /* console.log('important replacing local storage with new ' + (newInvoiceList.length) + ' invoices') */
          localStorage.setItem('DunningInvoicesStore', JSON.stringify(newInvoiceList, null, 4))

          return
        }

        /* console.log('important persisting local storage changes in new ' + (newInvoiceList.length) + ' invoices') */
        const existingInvoiceList = JSON.parse(<string>localStorage.getItem('DunningInvoicesStore'))
        const existingInvoiceListHash: string = encrypt256Hash(existingInvoiceList)
        const newInvoiceListHash: string = encrypt256Hash(newInvoiceList)

        if (existingInvoiceListHash !== newInvoiceListHash) {
          existingInvoiceList.forEach((existingInvoice: Invoice) => {
            const sameInvoiceInNewList: Invoice | undefined = newInvoiceList.find((invoice: Invoice): boolean => invoice.dataId === existingInvoice.dataId)
            if (sameInvoiceInNewList) {
              const existingInvoiceHash: string = encrypt256Hash(existingInvoice)
              const sameInvoiceInNewListHash: string = encrypt256Hash(sameInvoiceInNewList)
              if (existingInvoiceHash !== sameInvoiceInNewListHash) {
                /* console.log('existingInvoice supposedly changed', JSON.stringify(existingInvoice, null, '   ')) */
                /* console.log('sameInvoiceInNewList supposedly changed', JSON.stringify(sameInvoiceInNewList, null, '   ')) */
                eventsManager.emit(EventType.INVOICE_CHANGED, sameInvoiceInNewList, 'DunningInvoicesStore')
                // Typescript cannot guarantee the existence of a dynamically accessed property
                // so we need to cast the object to Record<string, any> to avoid the error
                // but we are bypassing the type checking
                Object.keys(sameInvoiceInNewList).forEach((k: string) => {
                  const existingInvoiceRecord: Record<string, any> = existingInvoice as Record<string, any>
                  const sameInvoiceInNewListRecord: Record<string, any> = sameInvoiceInNewList as Record<string, any>

                  const existingInvoicePropertyHash: string = encrypt256Hash(existingInvoiceRecord[k])
                  const sameInvoiceInNewListPropertyHash: string = encrypt256Hash(sameInvoiceInNewListRecord[k])

                  if (sameInvoiceInNewListPropertyHash !== existingInvoicePropertyHash) {
                    /* console.log('*-*-*- INVOICE_PROPERTY_CHANGED on k =', k, '\n where existing is = ', existingInvoiceRecord[k], '\n and same invoice in new list is = ', sameInvoiceInNewListRecord[k]) */
                    eventsManager.emit(EventType.INVOICE_PROPERTY_CHANGED, sameInvoiceInNewListRecord[k], 'DunningInvoicesStore')
                  }
                })

                if (!existingInvoice.completed && sameInvoiceInNewList.completed) {
                  eventsManager.emit(EventType.INVOICE_MARKED_COMPLETED, sameInvoiceInNewList, 'DunningInvoicesStore')
                }
              }
            }
          })
          newInvoiceList.forEach((newInvoice: Invoice) => {
            const existingInvoice = existingInvoiceList.find((invoice: Invoice): boolean => invoice.dataId === newInvoice.dataId)
            if (!existingInvoice) {
              eventsManager.emit(EventType.INVOICE_CREATED, newInvoice, 'DunningInvoicesStore')
            }
          })
          /* console.log('important localStorage.setItem("DunningInvoicesStore"') */
          localStorage.setItem('DunningInvoicesStore', JSON.stringify(newInvoiceList, null, 4))
          const invoicesWithoutDeletedItems = newInvoiceList.filter(invoice => !invoice.deleted)
          /* console.log('important emit(EventType.INVOICE_LIST_CHANGED) -=*- invoicesWithoutDeletedItems ', invoicesWithoutDeletedItems) */
          eventsManager.emit(EventType.INVOICE_LIST_CHANGED, invoicesWithoutDeletedItems, 'DunningInvoicesStore')
        }
      } else {
        /* console.log('important localStorage.setItem("DunningInvoicesStore"') */
        localStorage.setItem('DunningInvoicesStore', JSON.stringify(newInvoiceList, null, 4))
      }
    })

    eventsManager.emit(EventType.INVOICE_LIST_LOADED, data.filter(invoice => !invoice.deleted), 'DunningInvoicesStore')

    /* console.log('%c DunningInvoicesStore initialized.', 'color: #8A99AC') */
  } else {
    /* console.log('won\'t initialize data again') */
  }
}

const getDunningListViewChoiceInitialValue = (): DunningViewListOption => {
  const existingValue: string = localStorage.getItem('dunningListViewChoice')
  const defaultValue: DunningViewListOption.TRACKING = DunningViewListOption.TRACKING
  if (!existingValue || existingValue === 'undefined') {
    return defaultValue
  }
  if (existingValue === 'null') {
    return defaultValue
  }
  if (existingValue === '') {
    return defaultValue
  }
  if (existingValue === '{}') {
    return defaultValue
  }

  return <DunningViewListOption>existingValue
}
export let dunningListViewChoice: Writable<DunningViewListOption> = writable(getDunningListViewChoiceInitialValue())

let isDunningListViewChoiceSubscriptionDefined: boolean = false

// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
dunningListViewChoice.subscribe(val => {
  if (!isDunningListViewChoiceSubscriptionDefined) {
    /* console.log('dunningListViewChoice subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently') */
    isDunningListViewChoiceSubscriptionDefined = true

    return // we avoid the .subscribe() execution at the subscription occurrence
  }
  localStorage.setItem('dunningListViewChoice', val)
})

import { BusinessDocument } from '../../models/business-document'
import {
  newGenerateBalanceBusinessDocumentFromOriginalCoreDocument
} from './balance-installment-calculations.service'
import {
  filterAllBusinessDocumentsFinalized,
  filterAllBusinessDocumentsWithDealId
} from '../business-document-array-filters/business-documents-filters.service'
import { InvoicingBalanceLimits } from '../../models/invoicing-balance-limits.model'
import { BalanceOfDealBusinessDocumentBuildingData } from '../../models/partial-business-document-building-data.model'
import { Workspace } from '$crm/models/workspace'
import { Customer } from '$crm/models/customer'
import { Contact } from '$crm/models/contact'
import { Decimal } from 'decimal.js'

/**
 * Generate New Balance Business Document from Selected Deal
 * @param selectedDealIdForBalanceInvoiceCreation
 * @param allBusinessDocuments
 * @param currentWorkspace
 * @param allCustomers
 * @param allContacts
 */
export function newBalanceBusinessDocumentAndCollateralDataFromUserSelectedDeal(
  selectedDealIdForBalanceInvoiceCreation: string,
  allBusinessDocuments: BusinessDocument[],
  currentWorkspace: Workspace,
  allCustomers: Customer[],
  allContacts: Contact[]
): BalanceOfDealBusinessDocumentBuildingData {
  const latestBusinessDocumentOfSelectedDealToBalance: BusinessDocument = getLatestFinalBusinessDocumentWithDealId(
    selectedDealIdForBalanceInvoiceCreation,
    allBusinessDocuments)
  if (!latestBusinessDocumentOfSelectedDealToBalance) {
    return BalanceOfDealBusinessDocumentBuildingData.empty()
  }
  const partiallyCalculatedInvoicingBalanceLimits: InvoicingBalanceLimits = <InvoicingBalanceLimits>{
    balanceInvoicingMetricsUpdated: false,
    dealTotalAmountToInvoiceScaledValueIncludingTaxes: new Decimal(latestBusinessDocumentOfSelectedDealToBalance.totalIncludingTaxScaledValue),
    dealInvoicedPercentDraftedOnly: new Decimal(0),
    dealInvoicedAmountScaledValueDraftedOnly: new Decimal(0),
    dealMaxRemainingPercentToInvoiceOnlyFinalized: new Decimal(0),
    dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesOnlyFinalized: new Decimal(0),
    dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesIncludingCurrentDraft: new Decimal(0),
    dealMaxRemainingPercentToInvoiceIncludingCurrentDraft: new Decimal(0)
  }

  return newGenerateBalanceBusinessDocumentFromOriginalCoreDocument(
    latestBusinessDocumentOfSelectedDealToBalance,
    allBusinessDocuments,
    currentWorkspace,
    allCustomers,
    allContacts,
    partiallyCalculatedInvoicingBalanceLimits,
  )
}

/**
 *
 * @param dealId
 * @param allBusinessDocuments
 */
export function getLatestFinalBusinessDocumentWithDealId(dealId: string, allBusinessDocuments: BusinessDocument[]): BusinessDocument {
  const businessDocumentsForDeal: BusinessDocument[] = allBusinessDocuments
    .filter(filterAllBusinessDocumentsFinalized)
    .filter(filterAllBusinessDocumentsWithDealId(dealId))
  businessDocumentsForDeal.sort(sortBusinessDocumentsByAntiChronologicalOrderAkaLatestFirst)

  return businessDocumentsForDeal[0]
}

// see test in sort-array-js.spec.ts
function sortBusinessDocumentsByAntiChronologicalOrderAkaLatestFirst(a: BusinessDocument, b: BusinessDocument): number {
  return b.createdDate.unixSeconds - a.createdDate.unixSeconds
}

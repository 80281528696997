<script lang="ts">
    import { link, navigate } from 'svelte-routing'
    import { t } from '../../core-app/lib/i18n/i18nextWrapper.js'
</script>

<section data-ut-component="single-bank-account-marketing-section">
    <div class="w-full">
        <svg class="mt-8 m-auto left-0 right-0" width="70" height="70"
             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <filter x="-27.9%" y="-24%" width="155.8%" height="155.8%"
                        filterUnits="objectBoundingBox" id="a">
                    <feMorphology radius="1.5" operator="dilate" in="SourceAlpha"
                                  result="shadowSpreadOuter1"/>
                    <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
                    <feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1"
                                    result="shadowBlurOuter1"/>
                    <feColorMatrix
                            values="0 0 0 0 0.121568627 0 0 0 0 0.145098039 0 0 0 0 0.2 0 0 0 0.04 0"
                            in="shadowBlurOuter1"/>
                </filter>
                <rect id="b" x="0" y="0" width="52" height="52" rx="8"/>
            </defs>
            <g fill="none" fill-rule="evenodd">
                <g transform="translate(9 7)">
                    <use fill="#000" filter="url(#a)" xlink:href="#b"/>
                    <use fill="#F47663" xlink:href="#b"/>
                </g>
                <path d="M21.58 43.207c0-.277.233-.503.501-.503h25.838c.276 0 .5.216.5.503v2.61a.507.507 0 0 1-.5.503H22.081a.495.495 0 0 1-.5-.503v-2.61Zm3.254-13.743a.5.5 0 0 1 .508-.497h2.237c.28 0 .508.22.508.497v10.574a.5.5 0 0 1-.508.497h-2.237a.502.502 0 0 1-.508-.497V29.464Zm8.946 0a.5.5 0 0 1 .508-.497h2.237c.28 0 .508.22.508.497v10.574a.5.5 0 0 1-.508.497h-2.237a.502.502 0 0 1-.508-.497V29.464Zm8.133 0a.5.5 0 0 1 .508-.497h2.237c.28 0 .508.22.508.497v10.574a.5.5 0 0 1-.508.497H42.42a.502.502 0 0 1-.508-.497V29.464Zm-20.332-3.16c0 .273.232.493.5.493h25.838c.276 0 .5-.22.5-.492V25.07a.78.78 0 0 0-.462-.671l-12.495-4.824a1.492 1.492 0 0 0-.924 0l-12.495 4.824a.775.775 0 0 0-.462.67v1.236Z"
                      fill="#FFF"/>
            </g>
        </svg>
        <h3 class="font-black mb-1 text-center px-12 py-3 text-base">{t('editBank.whyConnectTitle')}</h3>
        <div class="w-7/12 mx-auto">
            <ul class="list-inside px-8 mt-4">
                <li class="flex items-center my-2.5">
                    <svg class="w-8 mr-1" width="14" height="10" xmlns="http://www.w3.org/2000/svg">
                        <path d="m6.026 8.536-1.21 1.178L.581 5.589l1.21-1.178 4.235 4.125Zm6.05-8.25 1.21 1.178-7.26 7.072-1.21-1.179 7.26-7.071Z"
                              fill="#F47663"/>
                    </svg>
                    <p class="w-11/12 ml-1 text-sm">{t('editBank.whyConnectBullet1')}</p>
                </li>
                <li class="flex items-center my-2.5">
                    <svg class="w-8 mr-1" width="14" height="10" xmlns="http://www.w3.org/2000/svg">
                        <path d="m6.026 8.536-1.21 1.178L.581 5.589l1.21-1.178 4.235 4.125Zm6.05-8.25 1.21 1.178-7.26 7.072-1.21-1.179 7.26-7.071Z"
                              fill="#F47663"/>
                    </svg>
                    <p class="w-11/12 ml-1 text-sm">{t('editBank.whyConnectBullet2')}</p>
                </li>
                <li class="flex items-center my-2.5">
                    <svg class="w-8 mr-1" width="14" height="10" xmlns="http://www.w3.org/2000/svg">
                        <path d="m6.026 8.536-1.21 1.178L.581 5.589l1.21-1.178 4.235 4.125Zm6.05-8.25 1.21 1.178-7.26 7.072-1.21-1.179 7.26-7.071Z"
                              fill="#F47663"/>
                    </svg>
                    <p class="w-11/12 ml-1 text-sm">{t('editBank.whyConnectBullet3')}</p>
                </li>
            </ul>
            <span
                    role="button"
                    tabindex="0"
                    on:click={() => navigate('connect-new')}
                    on:keyup={(e) => {if (e.key === 'Enter') navigate('connect-new')}}
                    class="btn action-primary cursor-pointer left-0 right-0 m-auto w-6/12 mt-16">{t('todo.bank_sidebar.connectAccount')}</span>
        </div>
        <div class="notice-bank-security pb-0 pt-4 m-auto mt-8 px-4">
            <div class="mx-auto mb-4 mt-2">
                <p class="text-left text-xs">{@html t('editBank.privacyNotice')} <a
                        href="https://dundy.co/security" use:link
                        class="font-bold text-dundyOrange">{t('todo.bank_sidebar.privacyLink')}</a>
                </p>
            </div>
        </div>
    </div>
</section>
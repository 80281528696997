import { writable } from 'svelte/store'
import type { Writable } from 'svelte/store'
import type GlobalInitializationStore from '../models/initialization-store'

/* console.log('initialization.store.ts') */

const initializationStore: Writable<GlobalInitializationStore> = writable(<GlobalInitializationStore>{
  navigationHistoryStoreInitialized: false,
  workspaceStoreInitialized: false,
  profileStoreInitialized: false,
  invoicesStoreInitialized: false,
  customersStoreInitialized: false,
  contactsStoreInitialized: false,
  todosStoreInitialized: false,
  invoicesHistoryStoreInitialized: false,
  computedInvoicesStoreInitialized: false,
  feedbacksStoreInitialized: false,
  voxyPreferencesStoreInitialized: false,
  transactionsStoreInitialized: false,
  cashApplicationDeclaredEventsStoreInitialized: false,
  businessDocumentsStoreInitialized: false
})
export default initializationStore

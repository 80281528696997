<script lang="ts">
    import { slide } from 'svelte/transition'
    import { backInOut } from 'svelte/easing'
    import { DunningViewListOption } from '../../../enums/dunning-view-list-options.js'
    import { dunningListViewChoice } from '../../../stores/dunning-invoices.store'
    import Checkbox from '../../../../core-app/lib/ui-kit/Checkbox.svelte'
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper.js'
    import { AppliedDunningFilters } from '../../../models/applied-dunning-filters'

    /** Export variables */
    export let openFilters: boolean = false
    export let invoicesCurrency: string = 'EUR'
    export let activeFilters: boolean[]

    /** Export variables */
    export let filters: AppliedDunningFilters = {
      checkboxFilterMoreThan90TillDue: { id: 'checkboxFilterMoreThan90TillDue', index: 0, value: false, totalAmount: 0, color: 'bg-greenHaze', isFirstVisible: false, isLastVisible: false },
      checkboxFilterBetween61And90DaysTillDue: { id: 'checkboxFilterBetween61And90DaysTillDue', index: 1, value: false, totalAmount: 0, color: 'bg-persianGreen', isFirstVisible: false, isLastVisible: false },
      checkboxFilterBetween31And60DaysTillDue: { id: 'checkboxFilterBetween31And60DaysTillDue', index: 2, value: false, totalAmount: 0, color: 'bg-bermuda', isFirstVisible: false, isLastVisible: false },
      checkboxFilterBetween0And30DaysTillDue: { id: 'checkboxFilterBetween0And30DaysTillDue', index: 3, value: false, totalAmount: 0, color: 'bg-cruise', isFirstVisible: false, isLastVisible: false },
      checkboxFilterBetween1And30DaysOverdue: { id: 'checkboxFilterBetween1And30DaysOverdue', index: 4, value: false, totalAmount: 0, color: 'bg-creamBrulee', isFirstVisible: false, isLastVisible: false },
      checkboxFilterBetween31And60DaysOverdue: { id: 'checkboxFilterBetween31And60DaysOverdue', index: 5, value: false, totalAmount: 0, color: 'bg-yellowOrange', isFirstVisible: false, isLastVisible: false },
      checkboxFilterBetween61And90DaysOverdue: { id: 'checkboxFilterBetween61And90DaysOverdue', index: 6, value: false, totalAmount: 0, color: 'bg-hotCinnamon', isFirstVisible: false, isLastVisible: false },
      checkboxFilterMoreThan90DaysOverdue: { id: 'checkboxFilterMoreThan90DaysOverdue', index: 7, value: false, totalAmount: 0, color: 'bg-mojo', isFirstVisible: false, isLastVisible: false },
      checkboxFilterAmountLessThan500: { id: 'checkboxFilterAmountLessThan500', index: 8, value: false, totalAmount: 0, color: '', isFirstVisible: false, isLastVisible: false },
      checkboxFilterAmountBetween500And5000: { id: 'checkboxFilterAmountBetween500And5000', index: 9, value: false, totalAmount: 0, color: '', isFirstVisible: false, isLastVisible: false },
      checkboxFilterAmountBetween5000And20000: { id: 'checkboxFilterAmountBetween5000And20000', index: 10, value: false, totalAmount: 0, color: '', isFirstVisible: false, isLastVisible: false },
      checkboxFilterAmountBetween20000And75000: { id: 'checkboxFilterAmountBetween20000And75000', index: 11, value: false, totalAmount: 0, color: '', isFirstVisible: false, isLastVisible: false },
      checkboxFilterAmountBetween75000And150000: { id: 'checkboxFilterAmountBetween75000And150000', index: 12, value: false, totalAmount: 0, color: '', isFirstVisible: false, isLastVisible: false },
      checkboxFilterAmountMoreThan150000: { id: 'checkboxFilterAmountMoreThan150000', index: 13, value: false, totalAmount: 0, color: '', isFirstVisible: false, isLastVisible: false }
    }


    /** Determine if DunningViewListOption is PAID */
    let isActiveViewPaid: boolean

    /** Reactive variables */
    $: isActiveViewPaid = $dunningListViewChoice === DunningViewListOption.PAID
    $: activeFilters = Object.values(filters).filter(filter => filter.value) as unknown as boolean[]

    $: if (isActiveViewPaid) {
      removeAllActiveFilters()
    }

    const removeAllActiveFilters = () => {
      for (let key in filters) {
        if (filters.hasOwnProperty(key)) {
          filters[key].value = false
        }
      }
    }

</script>
<div data-ut-component="dunning-filters" class="dunning-filters-panel-class">
    <section aria-labelledby="filter-heading" class="relative">
        <h2 id="filter-heading" class="sr-only">Filters</h2>
        {#if openFilters}
            <div in:slide|local="{{ easing: backInOut }}"
                 out:slide|local="{{ easing: backInOut }}"
                 class="border border-whisper py-10 bg-whisper relative" id="disclosure-1">
                {#if openFilters}
                    {#if activeFilters.length > 0}
                        <div class="absolute top-6 left-8">
                            <button
                                    on:click={() => {removeAllActiveFilters()}}
                                    type="button"
                                    class="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs text-gray-500 ring-1 ring-inset ring-gray-200 hover:text-dundyOrange focus:text-dundyOrange">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 15.25L4.75 13L11.2929 6.45711C11.6834 6.06658 12.3166 6.06658 12.7071 6.45711L15.5429 9.29289C15.9334 9.68342 15.9334 10.3166 15.5429 10.7071L11 15.25H7Z"
                                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                          stroke-linejoin="round"></path>
                                    <path d="M12.75 19.25H19.25" stroke="currentColor" stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>

                                {t('dunning.filters.clearAllFilters', { count: activeFilters.length })}
                            </button>
                        </div>
                    {/if}
                    <div class="pl-6 absolute top-6 right-8">
                        <button
                                on:click={() => {openFilters = false}}
                                type="button"
                                class="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs text-gray-500 ring-1 ring-inset ring-gray-200 hover:text-dundyOrange focus:text-dundyOrange">
                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="1.5"
                                      d="M4.75 12C4.75 7.99594 7.99594 4.75 12 4.75V4.75C16.0041 4.75 19.25 7.99594 19.25 12V12C19.25 16.0041 16.0041 19.25 12 19.25V19.25C7.99594 19.25 4.75 16.0041 4.75 12V12Z"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="1.5" d="M9.75 9.75L14.25 14.25"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="1.5" d="M14.25 9.75L9.75 14.25"></path>
                            </svg>

                            {t('dunning.filters.closeFilters')}
                        </button>
                    </div>
                {/if}
                <div class="mx-auto grid max-w-7xl grid-cols-2 gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8 mt-10">
                    <div class="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
                        <fieldset>
                            <legend class="block font-medium">{t('dunning.filters.headerOnTime')}</legend>
                            <div class="space-y-4 pt-6 sm:space-y-4 sm:pt-4">
                                <Checkbox bind:checked={filters.checkboxFilterBetween0And30DaysTillDue.value}
                                          labelText={t('dunning.filters.checkboxFilterBetween0And30DaysTillDue')}
                                          disabled={isActiveViewPaid}/>

                                <Checkbox bind:checked={filters.checkboxFilterBetween31And60DaysTillDue.value}
                                          labelText={t('dunning.filters.checkboxFilterBetween31And60DaysTillDue')}
                                          disabled={isActiveViewPaid}/>

                                <Checkbox bind:checked={filters.checkboxFilterBetween61And90DaysTillDue.value}
                                          labelText={t('dunning.filters.checkboxFilterBetween61And90DaysTillDue')}
                                          disabled={isActiveViewPaid}/>

                                <Checkbox bind:checked={filters.checkboxFilterMoreThan90TillDue.value}
                                          labelText={t('dunning.filters.checkboxFilterMoreThan90TillDue')}
                                          disabled={isActiveViewPaid}/>
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend class="block font-medium">{t('dunning.filters.headerLate')}</legend>
                            <div class="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                                <Checkbox bind:checked={filters.checkboxFilterBetween1And30DaysOverdue.value}
                                          labelText={t('dunning.filters.checkboxFilterBetween1And30DaysOverdue')}
                                          disabled={isActiveViewPaid}/>
                                <Checkbox bind:checked={filters.checkboxFilterBetween31And60DaysOverdue.value}
                                          labelText={t('dunning.filters.checkboxFilterBetween31And60DaysOverdue')}
                                          disabled={isActiveViewPaid}/>

                                <Checkbox bind:checked={filters.checkboxFilterBetween61And90DaysOverdue.value}
                                          labelText={t('dunning.filters.checkboxFilterBetween61And90DaysOverdue')}
                                          disabled={isActiveViewPaid}/>

                                <Checkbox bind:checked={filters.checkboxFilterMoreThan90DaysOverdue.value}
                                          labelText={t('dunning.filters.checkboxFilterMoreThan90DaysOverdue')}
                                          disabled={isActiveViewPaid}/>
                            </div>
                        </fieldset>
                    </div>
                    <div class="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
                        <fieldset>
                            <legend class="block font-medium">{t('dunning.filters.headerAmount')}</legend>
                            <div class="space-y-6 pt-6 sm:space-y-4 sm:pt-4">

                                <Checkbox bind:checked={filters.checkboxFilterAmountLessThan500.value}
                                          labelText={t('dunning.filters.checkboxFilterAmountLessThan500', { currency: invoicesCurrency })}/>

                                <Checkbox bind:checked={filters.checkboxFilterAmountBetween500And5000.value}
                                          labelText={t('dunning.filters.checkboxFilterAmountBetween500And5000', { currency: invoicesCurrency })}/>

                                <Checkbox bind:checked={filters.checkboxFilterAmountBetween5000And20000.value}
                                          labelText={t('dunning.filters.checkboxFilterAmountBetween5000And20000', { currency: invoicesCurrency })}/>

                                <Checkbox bind:checked={filters.checkboxFilterAmountBetween20000And75000.value}
                                          labelText={t('dunning.filters.checkboxFilterAmountBetween20000And75000', { currency: invoicesCurrency })}/>

                                <Checkbox bind:checked={filters.checkboxFilterAmountBetween75000And150000.value}
                                          labelText={t('dunning.filters.checkboxFilterAmountBetween75000And150000', { currency: invoicesCurrency })}/>

                                <Checkbox bind:checked={filters.checkboxFilterAmountMoreThan150000.value}
                                          labelText={t('dunning.filters.checkboxFilterAmountMoreThan150000', { currency: invoicesCurrency })}/>

                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        {/if}
    </section>
</div>
<script lang="ts">
    /**
     * @event {boolean} check
     */

    /** Specify whether the checkbox is checked */
    export let checked = false

    /** Specify whether the checkbox is indeterminate */
    export let indeterminate = false

    /** Set to `true` to disable the checkbox */
    export let disabled = false

    /** Specify the label text */
    export let labelText = ''

    /** Set a name for the input element */
    export let name = ''
    /**
     * Specify the title attribute for the label element
     * @type {string}
     */
    export let title = undefined

    /** Set an id for the input label */
    export let id = 'ccs-' + Math.random().toString(36)

    /** Obtain a reference to the input HTML element */
    export let ref = null

    /** Set to `true` to visually hide the label text */
    export let hideLabel = false

    let refLabel = null

    /** Set custom class for the element */
    let propClass = ''
    export { propClass as class }

    import { createEventDispatcher } from 'svelte'

    const dispatch = createEventDispatcher()

    $: dispatch('check', checked)
</script>

<div class="{propClass} relative flex items-center text-base sm:text-sm" class:disabled={disabled}>
    <input bind:this="{ref}"
           checked="{checked}"
           class="opacity-0 absolute h-4 w-4"
           disabled="{disabled}"
           id="{id}"
           indeterminate="{indeterminate}"
           name="{name}"
           on:blur
           on:change
           on:change="{() => {
             checked = !checked
           }}"
           type="checkbox"
    />
    <div
        class="bg-white border rounded border-loblolly w-4 h-4 flex flex-shrink-0 justify-center items-center focus:border-carnation focus-within:border-carnation">
        <svg class="hidden text-white w-4 h-4 text-carnation pointer-events-none" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.75 12.75L10 15.25L16.25 8.75" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
    </div>
    <label for="{id}" title="{title}" class="ml-3 min-w-0 flex items-center flex-1 cursor-pointer">
      <span
              bind:this="{refLabel}"
              class:hidden="{hideLabel}"
      >
        <slot name="labelText">
          {labelText}
        </slot>
      </span>
    </label>
</div>

<style lang="postcss">
    input:checked + div {
        @apply inline-flex items-center bg-persianGreen border-persianGreen;
    }

    input:checked + div svg {
        @apply block;
    }

    .disabled {
        pointer-events: none;
        opacity: 0.4;
    }
</style>
<script lang="ts">
    import type Invoice from '../../../models/invoice'
    import { DunningInvoicesStore } from '../../../stores/dunning-invoices.store'
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
    import { feedbackService } from '../../../../core-app/services/feedback.service'
    import { Feedback } from '../../../../core-app/models/feedback'
    import { DocumentSourceKind } from '../../../../order-to-cash-lib/models/document-source-kind'

    export let selectedInvoices: Invoice[]

    /** Local declarations */
    let deleteOrElseRecover: boolean = true
    let aNonDeletableInvoiceIsSelected: boolean
    let deleteOrElseRecoverButtonDisabled: boolean

    /** Reactive declarations */
    $: deleteOrElseRecover = !selectedInvoices.length || selectedInvoices.some((invoice) => !invoice.deleted)
    $: aNonDeletableInvoiceIsSelected = !!selectedInvoices.find((anInvoice: Invoice) => { // is there an invoice that cannot be deleted
      if (!anInvoice.documentSource) {
        return false // if there is no .documentSource property, it is an old Dundy invoice bottom line
      }
      if (!anInvoice.documentSource.sourceKind) {
        return false // if there is no .documentSource.sourceKind property, it is an old Dundy invoice bottom line
      }
      if (anInvoice.documentSource.sourceKind === DocumentSourceKind.FROMDUNDYDUNNINGMANUALLY) {
        return false
      } else if (anInvoice.documentSource.sourceKind === DocumentSourceKind.FROMVOXYINVOICING) {
        return true // cannot delete a Voxy invoice here for the moment
      } else if (anInvoice.documentSource.sourceKind === DocumentSourceKind.UNKNOWNSOURCE) {
        return true // true for the sake of preventing the deletion on unknown source
      } else {
        return true // true for the sake of preventing the deletion on unexpected source
      }
    })
    $: deleteOrElseRecoverButtonDisabled = (selectedInvoices.length < 1) || aNonDeletableInvoiceIsSelected


    /**
     * Switch the status of the selected invoices between deleted and not deleted
     */
    function switchDeleteStatus() {
      if (!selectedInvoices || !selectedInvoices.length) {
        return
      }

      DunningInvoicesStore.update((invoices): Invoice[] => invoices.map(invoice => {
        const isInvoiceSelected: boolean = !!selectedInvoices.find(aSelectedInvoice => (aSelectedInvoice.dataId === invoice.dataId))
        const isInvoiceDeletable: boolean = !invoice.documentSource || !invoice.documentSource.sourceKind || (invoice.documentSource.sourceKind === DocumentSourceKind.FROMDUNDYDUNNINGMANUALLY)
        
        return isInvoiceSelected && isInvoiceDeletable ? {
          ...invoice,
          deleted: deleteOrElseRecover
        } : invoice
      }))

      feedbackService.displayFeedback(<Feedback>{
        feedbackMessage: t('invoices.deleted', { count: selectedInvoices.length }),
        feedbackLevel: 'Success'
      })
    }

</script>

<button class="btn action-secondary" disabled={deleteOrElseRecoverButtonDisabled} on:click={switchDeleteStatus}>
    {#if deleteOrElseRecover}
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.75 7.75L6.59115 17.4233C6.68102 18.4568 7.54622 19.25 8.58363 19.25H14.4164C15.4538 19.25 16.319 18.4568 16.4088 17.4233L17.25 7.75H5.75Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M9.75 10.75V16.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M13.25 10.75V16.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M8.75 7.75V6.75C8.75 5.64543 9.64543 4.75 10.75 4.75H12.25C13.3546 4.75 14.25 5.64543 14.25 6.75V7.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M4.75 7.75H18.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>

        {t('invoices.controls.deleteSelectedInvoice', { count: selectedInvoices.length })}
    {:else}
        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 4.75L4.75 9L9.25 13.25"></path>
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5.5 9H15.25C17.4591 9 19.25 10.7909 19.25 13V19.25"></path>
        </svg>

        {t('invoices.controls.restore')}
    {/if}
</button>

<script lang="ts">
  import { debounce } from '$src/core-app/util/function-utils'
  import { createEventDispatcher } from 'svelte'

  export let value:string = ''
  export let placeholder:Readonly<string> = ''
  export let label:Readonly<string> = ''
  export let debounceMs:Readonly<number> = 0

  export let displayLabel:Readonly<boolean> = true
  export let displayIconSearch:Readonly<boolean> = true
  export let displayIconClear:Readonly<boolean> = true

  const dispatch = createEventDispatcher()
  const id:string = 'input-search-' + Math.random().toString(36)
  let inputClass = ''

  $: if (displayIconSearch && displayIconClear) {
    inputClass = 'px-10'
  } else if (displayIconSearch) {
    inputClass = 'pl-10'
  } else if (displayIconClear) {
    inputClass = 'pr-10'
  }

  const clear = () => {
    value = ''
    dispatch('clear')
  }

  const search = () => {
    dispatch('search', value)
  }

  const onInput = debounce((e) => {
    value = e.target.value
    search()
  }, debounceMs)

  const onKeydown = (e:KeyboardEvent) => {
    if (e.code === 'Escape') clear()
    if (e.code === 'Enter' && value) search()
  }
</script>

<div class="flex flex-col relative">
  {#if displayLabel && label}
    <label for={id} class="h-7">
      <slot>{label}</slot>
    </label>
  {/if}
 
  {#if displayIconSearch}
    <button class="icon search" on:click={() => search()}/>
  {/if}

  <input type="text"
    class={`bg-whisper text-sm rounded border-athensGray border outline-none w-full h-full focus:border-black ${inputClass}`}
    autocomplete="off"
    spellcheck="false"
    {id}
    {placeholder}
    value={value} 
    on:input={e => onInput(e)}
    on:keydown={e => onKeydown(e)}
  >

  {#if displayIconClear}
    <button class="icon clear" on:click={() => clear()} />
  {/if}
</div>

<style lang="postcss">
  button {
    @apply absolute bottom-1.5 cursor-pointer bg-black;

    &.icon {
      mask-repeat: no-repeat;
      background: black;
      height: 24px;
      width: 24px;
      transition: 300ms;
    }

    &.search {
      mask: url("/img/icons/search.svg");
      @apply left-2;
    }

    &.clear {
      mask: url("/img/icons/clear.svg");
      @apply right-2;
    }
  }
</style>

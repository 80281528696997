import { t } from '../../core-app/lib/i18n/i18nextWrapper'
import { getUserCompanyTimezone } from '../../core-app/util/timezone-utils'
import { get } from 'svelte/store'
import { ProfileStore } from '../../core-app/stores/profile.store'
import { WorkspaceStore } from '../../crm-app/stores/workspace.store'

/**
 * Get translated placeholder for the language
 * @param languageCode
 */
export function getTranslatedPlaceholderForLanguage(languageCode: string): Array<{ label: string, value: string }> {
  return [
    { 'label': 'YYYY', 'value': t('automaticNumbering.replacementsNumbering.YYYY', { lng: languageCode }) },
    { 'label': 'YY', 'value': t('automaticNumbering.replacementsNumbering.YY', { lng: languageCode }) },
    { 'label': 'MM', 'value': t('automaticNumbering.replacementsNumbering.MM', { lng: languageCode }) },
    { 'label': 'DD', 'value': t('automaticNumbering.replacementsNumbering.DD', { lng: languageCode }) }
  ]
}

/**
 * Get the placeholder replacements for the date
 * @param languageCode
 */
export function getNumberingPlaceholderReplacementsForDate(languageCode: string): Array<{ label: string, value: string | number }> {
  const timeZone: string = getUserCompanyTimezone(get(WorkspaceStore))
  const languageFullCode: string = get(ProfileStore).lang
  
  return [
    {
      'label': t('automaticNumbering.replacementsNumberingWithParentheses.YYYY', { lng: languageCode }),
      'value': new Date().toLocaleString(languageFullCode, { timeZone, year: 'numeric' })
    },
    {
      'label': t('automaticNumbering.replacementsNumberingWithParentheses.YY', { lng: languageCode }),
      'value': new Date().toLocaleString(languageFullCode, { timeZone, year: '2-digit' })
    },
    {
      'label': t('automaticNumbering.replacementsNumberingWithParentheses.MM', { lng: languageCode }),
      'value': new Date().toLocaleString(languageFullCode, { timeZone, month: '2-digit' })
    },
    {
      'label': t('automaticNumbering.replacementsNumberingWithParentheses.DD', { lng: languageCode }),
      'value': new Date().toLocaleString(languageFullCode, { timeZone, day: '2-digit' })
    }
  ]
}

/**
 * Get the replacements for the server side placeholders
 * @param languageCode
 */
export function getNumberingPlaceholdersReplacementsForServerSide(languageCode: string): Array<{ label: string, value: string | number }> {
  const timeZone: string = getUserCompanyTimezone(get(WorkspaceStore))
  const languageFullCode: string = get(ProfileStore).lang
  
  return [
    {
      'label': t('automaticNumbering.replacementsServerSideValues.YYYY', { interpolation: { skipOnVariables: true } }),
      'value': new Date().toLocaleString(languageFullCode, { timeZone, year: 'numeric' })
    },
    {
      'label': t('automaticNumbering.replacementsServerSideValues.YY', { interpolation: { skipOnVariables: true } }),
      'value': new Date().toLocaleString(languageFullCode, { timeZone, year: '2-digit' })
    },
    {
      'label': t('automaticNumbering.replacementsServerSideValues.MM', { interpolation: { skipOnVariables: true } }),
      'value': new Date().toLocaleString(languageFullCode, { timeZone, month: '2-digit' })
    },
    {
      'label': t('automaticNumbering.replacementsServerSideValues.DD', { interpolation: { skipOnVariables: true } }),
      'value': new Date().toLocaleString(languageFullCode, { timeZone, day: '2-digit' })
    }
  ]
}

/**
 * Get the letters for the templates according to the language
 * @param languageCode
 */
function getLettersForNumberingTemplatesAccordingToLanguage(languageCode: string): Array<{ label: string, value: string }> {
  return [
    {
      'value': 'I',
      'label': t('automaticNumbering.replacementsDefaultLettersForTemplates.Invoice', { lng: languageCode })
    },
    {
      'value': 'C',
      'label': t('automaticNumbering.replacementsDefaultLettersForTemplates.CreditNote', { lng: languageCode })
    },
    {
      'value': 'P',
      'label': t('automaticNumbering.replacementsDefaultLettersForTemplates.PurchaseOrder', { lng: languageCode })
    },
    {
      'value': 'Q',
      'label': t('automaticNumbering.replacementsDefaultLettersForTemplates.Quote', { lng: languageCode })
    }

  ]
}

/**
 * Replace the default first letter of the template with the translated one
 * @param originalString
 * @param languageCode
 */
export function replaceDefaultFirstLetterOfStringFromServerWithTranslatedOne(originalString: string, languageCode: string): string {
  if (languageCode === 'en') {
    return originalString
  }
  getLettersForNumberingTemplatesAccordingToLanguage(languageCode).forEach((letter) => {
    // no flags: "g" here in RegExp() to just process replacement once in the string
    // "^"+... to only replace if at beginning of string
    originalString = originalString.replace(new RegExp('^' + letter.value, ''), letter.label)
  })
  let result = originalString

  /* console.log('replaceDefaultFirstLetterOfStringFromServerWithTranslatedOne', result) */

  return result
}

/**
 * Deletes the double accolades from the template and replaces the placeholders with the values
 * @param key
 * @param languageCode
 */
export function deleteDoubleAccoladesAndReplacePlaceholdersWithDateValues(key: string, languageCode: string): string {
  let templateWithReplacedPlaceholders = key
  getNumberingPlaceholdersReplacementsForServerSide(languageCode).forEach((replacement: { label: string, value: string | any }) => {
    templateWithReplacedPlaceholders = templateWithReplacedPlaceholders.replace(new RegExp(replacement.label, 'g'), replacement.value)
  })
  
  return removeSEQUENCEPlaceholderFromTemplate(templateWithReplacedPlaceholders)
}

/**
 * Replaces the number of digits shown to the user with zeroes
 * @param number
 * @param numberOfDigits
 */
export function replaceNumberOfDigitsWithZeroes(number: number, numberOfDigits: number): string {
  let numberAsString = number.toString()
  while (numberAsString.length < numberOfDigits) {
    numberAsString = '0' + numberAsString
  }
  
  return numberAsString
}

/**
 * Replaces the number of digits shown to the user with Xs
 * @param numberOfDigits
 */
export function replaceNumberOfDigitsWithXs(numberOfDigits: number): string {
  let numberAsString = ''
  for (let i = 0; i < numberOfDigits; i++) {
    numberAsString += '●' // "&#9679;"; // ●
  }
  if (numberOfDigits === 0) {
    numberAsString = '●' // "&#9679;"; // ●
  }
  
  return numberAsString
}

/**
 * Replaces double accolades with parentheses and translates the placeholders
 * @param template
 * @param languageCode
 */
export function replaceDoubleAccoladesWithParenthesesAndTranslatePlaceholders(template: string, languageCode: string): string {
  let templateWithReplacedPlaceholders = removeSEQUENCEPlaceholderFromTemplate(template)
  getTranslatedPlaceholderForLanguage(languageCode).forEach((replacement: { label: string, value: string | any }) => {
    templateWithReplacedPlaceholders = templateWithReplacedPlaceholders.replace(new RegExp(replacement.label, 'g'), replacement.value)
  })
  
  return templateWithReplacedPlaceholders.replace(/{{/g, '(').replace(/}}/g, ')')
}

/**
 * Removes the {{SEQ}} placeholder from the template
 * @param template
 */
function removeSEQUENCEPlaceholderFromTemplate(template: string): string {
  return template.replace(/\{\{SEQ\}\}/g, '')
}

/**
 * Escapes parentheses to be used in a RegExp
 * @param originalString
 */
function escapeStringToBeSubstituted(originalString: string): string {
  return originalString.replace('(', '\\(').replace(')', '\\)')
}

/**
 * Replaces all parentheses placeholders with values
 * @param text
 * @param languageCode
 */
export function replaceAllParenthesesPlaceholdersWithValues(text: string, languageCode: string): string {
  let replacements: any = getNumberingPlaceholderReplacementsForDate(languageCode)
  let result: string = text
  replacements.forEach((replacement: { label: string, value: string | any }) => {
    result = result.replace(new RegExp(escapeStringToBeSubstituted(replacement.label), 'g'), replacement.value)
  })
  
  return result
}

/**
 * Appends the placeholder {{SEQ}} to the template
 * @param template
 */
export function appendSequenceNumberForBackend(template: string): string {
  return template + '{{SEQ}}'
}

/**
 * Replaces all parentheses with double accolades and translates the placeholders
 * @param text
 */
export function replaceParenthesesWithDoubleAccoladesAndTextInEnglish(text: string): string {
  return text
    .replace(t('automaticNumbering.replacementsNumberingWithParentheses.YYYY'), '{{YYYY}}')
    .replace(t('automaticNumbering.replacementsNumberingWithParentheses.YY'), '{{YY}}')
    .replace(t('automaticNumbering.replacementsNumberingWithParentheses.MM'), '{{MM}}')
    .replace(t('automaticNumbering.replacementsNumberingWithParentheses.DD'), '{{DD}}')
}

/**
 * Calculates the number of digits in the sequence number
 * @param sequenceNumber
 */
export function calculateNumberOfDigitsInSequenceNumber(sequenceNumber: string): number {
  return sequenceNumber.length
}

<script lang="ts">
    import { eventsManager } from '../../../core-app/events/event-manager'
    import { EventType } from '../../../core-app/events/event-type'
    import { onDestroy, onMount } from 'svelte'
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import type { BBBTransaction } from '../../models/bbb-transactions-model'
    import { get } from 'svelte/store'
    import { WorkspaceStore } from '../../../crm-app/stores/workspace.store.js'
    import { navigate } from 'svelte-routing'
    import Tooltip from '../../../core-app/lib/ui-kit/Tooltip/Tooltip.svelte'
    import CashAppRowCompletion from './CashAppRowCompletion.svelte'
    import type { CashApplicationDeclaredEvent } from '../../../cash-application-app/models/cash-application-declared-model'
    import { CashApplicationDeclaredEventsStore } from '../../../cash-application-app/stores/cash-application.store'
    import CashAppliedInvoicesRow from './CashAppliedInvoicesRow.svelte'
    import {
      formatCurrencyWithOptions
    } from '../../../voxy-app/utils/number-formatting-utils'
    import { TransactionsStore } from '../../stores/transactions.store'
    import type { CADEStatusForTransaction } from '../../../cash-application-app/models/cades-status-for-transaction'
    import {
      findCashApplicationDeclaredEventForTransaction, fuseCashApplicationDeclaredEvents,
      isTransactionRequiringCashApplication,
      updateCashApplicationStatusForTransaction
    } from '../../../cash-application-app/services/cash-application-pure-functions'
    import type { DundyEvent } from '../../../dundy-app/events/dundy-event'
    import mixpanel from 'mixpanel-browser'

    export let collapsed: boolean

    /** Local declaration */
    let transaction: BBBTransaction

    /** Set transactionAmount */
    let transactionAmount: string

    /** Set transactionDate */
    let transactionDate: string

    /** Set cashApplicationBoolean */
    let requiresCashApplication: boolean

    let cadeStatusForTransaction: CADEStatusForTransaction

    /** Set cashApplicationDeclaredEventsInUI */
    let cashApplicationDeclaredEventsInUI: CashApplicationDeclaredEvent[]

    let unSubscribeOnTransactionRowSelected: Function

    /** Functions */

    /**
     * Close the dynamic table
     * Emits an event to the parent component
     */
    function closeDynamicTable() {
      eventsManager.emit(EventType.TRANSACTIONS_CLOSE_PANEL, null, 'TransactionSidebar.svelte')
    }

    onMount(() => {
      // TODO never Destroyed: beware proper initialization for each clicked transaction (should probably be Destroyed/Mounted each time)
      mixpanel.track('HA10 Transactions Collapsible Page Sidebar Open', {
        'Description': 'Open Collapsible TransactionSidebar.svelte'
      })
      if (!!unSubscribeOnTransactionRowSelected) {
        unSubscribeOnTransactionRowSelected()
        unSubscribeOnTransactionRowSelected = null
      }
      unSubscribeOnTransactionRowSelected = eventsManager.on(EventType.TRANSACTIONS_ROW_SELECTED, (e: DundyEvent<any>) => {
        transaction = e.data?.transaction
        transactionAmount = formatCurrencyWithOptions(transaction?.amount, t('locales'), transaction?.currency_code, true)
        cashApplicationDeclaredEventsInUI = fuseCashApplicationDeclaredEvents(findCashApplicationDeclaredEventForTransaction(transaction, get(CashApplicationDeclaredEventsStore)))
        /* console.log('init fused cashApplicationDeclaredEventsInUI', cashApplicationDeclaredEventsInUI) */
        transactionDate = new Intl.DateTimeFormat(t('locales'), {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }).format(new Date(transaction?.date))
        cadeStatusForTransaction = updateCashApplicationStatusForTransaction(transaction, cashApplicationDeclaredEventsInUI, t('locales'))
        requiresCashApplication = isTransactionRequiringCashApplication(transaction.id.toString(), get(TransactionsStore).transactions, get(CashApplicationDeclaredEventsStore))
      }, 'TransactionSidebar.svelte')
    })

    onDestroy(() => {
      if (!!unSubscribeOnTransactionRowSelected) {
        unSubscribeOnTransactionRowSelected()
        unSubscribeOnTransactionRowSelected = null
      }
    })

</script>

<div class="dynamic-table-wrapper-column"
     class:visible={collapsed}
     style="width: 392px;"
     data-ut-component="transaction-dynamic-table">
    <div class="dynamic-table-card">
        <div class="dynamic-table-card-header">
            <div class="flex justify-between items-center">
                <div class="font-bold">{t('cashApplication.cashApplicationSidebarTitle')}</div>
                <div class="bg-whisper rounded-md h-8 w-8 flex items-center justify-center cursor-pointer hover:bg-zinc-300"
                     role="button"
                     aria-label="Close dynamic table"
                     tabindex="0"
                     on:click={() => {closeDynamicTable()}}
                     on:keydown={(e) => {if (e.key === 'Enter') {closeDynamicTable()}}}>
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                              d="M17.25 6.75L6.75 17.25"></path>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                              d="M6.75 6.75L17.25 17.25"></path>
                    </svg>
                </div>
            </div>
        </div>
        <div class="dynamic-table-card-details">
            <div class="flex flex-col justify-center items-center bg-black rounded-lg my-4 mx-4 p-4">

                {#if requiresCashApplication}
                  <div class="flex-none rounded-full bg-peachCream/10 px-2 py-1 text-xs font-medium text-peachCream ring-1 ring-inset ring-peachCream/30 sm:order-none">{t('cashApplication.status.toCashApply')}</div>
                {:else}
                  <div class="flex-none rounded-full bg-frenchLilac/10 px-2 py-1 text-xs font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30 sm:order-none">{t('cashApplication.status.cashApplied')}</div>
                {/if}

                <div class="flex flex-col justify-center items-center space-y-1 mt-4">
                    <h1 class="text-3xl text-white relative flex items-center mt-2">{transactionAmount}</h1>
                    <div class="text-zinc-100 text-sm font-light truncate">{transaction?.clean_description}</div>
                    <div class="text-zinc-400 text-s font-light truncate">{transactionDate}</div>
                </div>

                <div class="flex items-center border text-sm bg-black border-rhino rounded-md px-2.5 py-2 my-4">
                    <div class="w-6 h-6 bg-rhino rounded p-3 flex items-center align-middle justify-center font-medium text-xxs text-zinc-200">
                        {(get(WorkspaceStore).bankConfig?.selectedAccountBankName || "error").substring(0, 1).toUpperCase()}
                    </div>
                    <div class="ml-4 text-zinc-300">{get(WorkspaceStore).bankConfig?.selectedAccountBankName}
                        ••••{transaction?.account_id}</div>
                </div>

            </div>
        </div>
        <div class="w-full">
            <div class="px-4">
                <CashAppRowCompletion
                        bind:totalTransactionAmount={transactionAmount}
                        amountCashApplied={cadeStatusForTransaction?.amountCashApplied}
                        percentageCashApplied={cadeStatusForTransaction?.percentageCashApplied}
                />
            </div>
        </div>
        <div class="w-full border-t border-b border-athensGray">
            <div class="px-4 py-6">
                <div class="relative flex items-center justify-between">
                    <h2 class="text-black text-sm font-medium">{t('cashApplication.appliedInvoices.title')}</h2>
                    <Tooltip>
                        <div slot="activator" class="h-6">
                            <button on:click={() => { navigate(`/cash-application/view/${transaction?.id}`)} }
                                    class="text-sm text-zinc-500 hover:text-zinc-700">
                                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="1.5" d="M12 5.75V18.25"></path>
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="1.5" d="M18.25 12L5.75 12"></path>
                                </svg>
                            </button>
                        </div>
                        {t('cashApplication.appliedInvoices.tooltip')}
                    </Tooltip>
                </div>
                <div class="flex flex-col space-y-4 mt-4">
                    {#if Array.isArray(cashApplicationDeclaredEventsInUI)}

                        {#each cashApplicationDeclaredEventsInUI as cashApplicationDeclaredEvent}
                            <CashAppliedInvoicesRow
                                    cashApplicationDeclaredEvent={cashApplicationDeclaredEvent}
                            />
                        {/each}
                    {:else}
                        <!-- handle the case where cashApplicationDeclaredEventsInUI is not an array -->
                        <p>No cash application declared events found.</p>
                    {/if}
                </div>
            </div>
        </div>
        <!--&lt;!&ndash; IF CASH APPLICATION NEEDS TO BE DONE &ndash;&gt;
        <div class="flex flex-col border-bottom border-athensGray mt-4 px-4 py-6">
            <div class="flex bg-frenchLilac rounded-lg p-4 mb-4 text-sm text-grape" role="alert">
                <svg class="mr-3" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.75 10.25H19.25L13.75 4.75" stroke="currentColor" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M19.25 13.75H4.75L10.25 19.25" stroke="currentColor" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <div>
                    <span class="font-medium">1 facture correspondante détectée</span><br>Faites le rapprochement et
                    stoppez automatiquement la relance.
                    <a href="/cash-application/view/{transaction?.id}" class="flex button">Rapprocher</a>
                </div>
            </div>
            &lt;!&ndash;<div class="py-6 px-4 bg-peachCream border border-bourbon rounded">
                <h2 class="text-lg font-medium">Rapprochement de factures</h2>
                <p class="text-s text-zinc-500">2 factures ont été détectées qui matchent la transaction.</p>
                <a href="#" class="button">Rapprocher</a>
            </div>&ndash;&gt;
        </div>-->
    </div>
</div>

<style global lang="postcss">
    .dynamic-table-wrapper-column {
        @apply absolute h-full transition-all duration-300 ease-in-out opacity-0 bg-white border border-athensGray rounded-xl z-50;
        transition: all .4s ease;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05);
        width: 392px;
        top: -70px;
        right: 0;
    }

    .dynamic-table-wrapper-column:not(.visible) {
        @apply pointer-events-none;
        transform: translateX(200px);
        opacity: 0;
        z-index: 0;
    }

    .dynamic-table-wrapper-column.visible {
        opacity: 1;
        transition-delay: .1s;
        z-index: 9999;
    }

    .dynamic-table-card {
        @apply flex items-start flex-col sticky top-0 w-full;
    }

    .dynamic-table-card-header {
        @apply w-full p-4;

    }

    .dynamic-table-card-details {
        @apply relative flex flex-col justify-start w-full;
    }
</style>

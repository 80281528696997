export function substTiphaine(companyId: string, fieldName: string, originalValue: any): any {
  const tiphaineInfo = {
    // "firstName":"Tiphaine",
    // "lastName":"Hennegrave",
    // "email":"thennegrave@optalis-audit.fr",
    'businessDocumentFromEmail': 'comptabilite@cebp.fr',
    'businessDocumentFromFullName': 'Comptabilité CEBP',
    'dunningMessageFromEmail': 'comptabilite@cebp.fr',
    'dunningMessageFromFullName': 'Comptabilité CEBP',
    'dunningMessageReplyToEmail': 'comptabilite@cebp.fr',
    'dunningMessageReplyToFullName': 'Comptabilité CEBP'
  }
  // if (workspaceId==='98601303-d37b-468a-b32f-e7b70f115a56'){
  if (companyId === '27e9249b-c396-4855-85cb-94d35dfe471e') {
    if (tiphaineInfo.hasOwnProperty(fieldName)) {
      return tiphaineInfo[fieldName]
    } else {
      throw fieldName + ' is not available for tiphaineInfo'
    }
  } else {
    return originalValue
  }
}
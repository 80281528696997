<script lang="ts">
    import { onMount } from 'svelte'
    import { fly } from 'svelte/transition'
    import { navigate } from 'svelte-routing'
    import { t } from '../i18n/i18nextWrapper.js'
    import { WorkspaceStore } from '../../../crm-app/stores/workspace.store.js'
    import auth from '../auth0authentication/authService.js'
    import { clickOutside } from '../../util/layout-utils'
    import { featureToggling } from '../../../config/feature-toggling'

    /** Set to true to open Mobile Menu */
    export let openMobileMenu: boolean

    /** Reactive declarations */
    $: document.body.classList[openMobileMenu ? 'add' : 'remove']('no-scroll')

    const mediaQueryHandler = e => {
      if (!e.matches) {
        openMobileMenu = false
      }
    }

    function handleClickOutside() {
      openMobileMenu = false
    }

    onMount(() => {
      const mediaListener = window.matchMedia('(max-width: 1023px)')
      mediaListener.addEventListener('change', (e) => {
        mediaQueryHandler(e)
      })
    })

</script>
<div data-ut-component="mobile-navigation">
    {#if openMobileMenu}
        <div class="nav-menu {open ? 'open' : ''}" style="z-index: 999" in:fly="{{ x: -200, duration: 200 }}"
             use:clickOutside on:click_outside={handleClickOutside}>
            <nav>
                <ul role="list">
                    <li class="relative mt-6">
                        <h2 class="text-xl font-semibold text-zinc-900">{$WorkspaceStore.company.formalName}</h2>
                        <div class="relative mt-3">
                            <ul role="list">
                                <li class="relative">
                                    <a
                                            class="flex justify-between my-3 gap-2 py-3 pr-3 text-sm transition pl-4 text-zinc-900 bg-white border border-zinc-300 hover:bg-zinc-200 rounded-md cursor-pointer"
                                            on:click|preventDefault|stopPropagation={ () => {
                                              openMobileMenu = false
                                              /* console.log('navigate in', 'MobileNavigation.svelte.ts mobile menu topMenu.nav.dashboard on:click()', ': at window.location.pathname=', window.location.pathname, 'nextNavigationURI=', '/today') */
                                              navigate('/today')
                                            }}
                                    >
                                        <span class="flex items-center">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.75 17.25H17.25C18.3546 17.25 19.25 16.3546 19.25 15.25V8.75C19.25 7.64543 18.3546 6.75 17.25 6.75H6.75C5.64543 6.75 4.75 7.64543 4.75 8.75V15.25C4.75 16.3546 5.64543 17.25 6.75 17.25H7.25"
                                                      stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                                      stroke-linejoin="round"></path>
                                                <path d="M12 19C13.1046 19 14 18.1046 14 17H12.5C12.5 17.2761 12.2761 17.5 12 17.5V19ZM14 17C14 15.8954 13.1046 15 12 15V16.5C12.2761 16.5 12.5 16.7239 12.5 17H14ZM11.5 17C11.5 16.7239 11.7239 16.5 12 16.5V15C10.8954 15 10 15.8954 10 17H11.5ZM12 17.5C11.7239 17.5 11.5 17.2761 11.5 17H10C10 18.1046 10.8954 19 12 19V17.5Z"
                                                      fill="currentColor"></path>
                                                <path d="M11 16L8.75 11.75" stroke="currentColor" stroke-width="1.5"
                                                      stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M12 9.75V10.25" stroke="currentColor" stroke-width="1.5"
                                                      stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M15.625 10.7213L15.375 11.1543" stroke="currentColor"
                                                      stroke-width="1.5"
                                                      stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        <span class="truncate ml-2">{t('topMenu.nav.dashboard')}</span>
                                        </span>
                                    </a>
                                </li>
                                <li class="relative">
                                    <a class="flex justify-between my-3 gap-2 py-3 pr-3 text-sm transition pl-4 text-zinc-900 bg-white border border-zinc-300 hover:bg-zinc-200 rounded-md cursor-pointer"
                                       on:click|preventDefault|stopPropagation={ () => {
                                         openMobileMenu = false
                                         /* console.log('navigate in', 'MobileNavigation.svelte.ts mobile menu topMenu.nav.transactions on:click()', ': at window.location.pathname=', window.location.pathname, 'nextNavigationURI=', '/transactions') */
                                         navigate('/transactions')
                                       }}
                                    >
                                        <span class="flex items-center">
                                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                <path stroke="currentColor" stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      stroke-width="1.5"
                                                      d="M19.25 8.25V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V6.75"></path>
                                                <path stroke="currentColor" stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      d="M16.5 13C16.5 13.2761 16.2761 13.5 16 13.5C15.7239 13.5 15.5 13.2761 15.5 13C15.5 12.7239 15.7239 12.5 16 12.5C16.2761 12.5 16.5 12.7239 16.5 13Z"></path>
                                                <path stroke="currentColor" stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      stroke-width="1.5"
                                                      d="M17.25 8.25H6.5C5.5335 8.25 4.75 7.4665 4.75 6.5C4.75 5.5335 5.5335 4.75 6.5 4.75H15.25C16.3546 4.75 17.25 5.64543 17.25 6.75V8.25ZM17.25 8.25H19.25"></path>
                                            </svg>
                                            <span class="truncate ml-2">{t('topMenu.nav.transactions')}</span>
                                        </span>
                                    </a>
                                </li>
                                {#if featureToggling().isKotlyEnabled}
                                    <li class="relative">
                                        <a class="flex justify-between my-3 gap-2 py-3 pr-3 text-sm transition pl-4 text-zinc-900 bg-white border border-zinc-300 hover:bg-zinc-200 rounded-md cursor-pointer"
                                           on:click|preventDefault|stopPropagation={ () => {
                                             openMobileMenu = false
                                             navigate('/quotes')
                                           }}>
                                        <span class="flex items-center">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.75 15.75L8.25 19.25" stroke="currentColor"
                                                      stroke-width="1.5" stroke-linecap="round"
                                                      stroke-linejoin="round"></path>
                                                <path d="M8.25 15.75L4.75 19.25" stroke="currentColor"
                                                      stroke-width="1.5" stroke-linecap="round"
                                                      stroke-linejoin="round"></path>
                                                <path d="M11.75 19.25H15.25" stroke="currentColor" stroke-width="1.5"
                                                      stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M8.75 8.75H15.25" stroke="currentColor" stroke-width="1.5"
                                                      stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M9.75 11.75H14.25" stroke="currentColor" stroke-width="1.5"
                                                      stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M19.25 19.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V12.25"
                                                      stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                                      stroke-linejoin="round"></path>
                                            </svg>

                                            <span class="truncate ml-2">{t('topMenu.nav.quotes')}</span>
                                        </span>
                                        </a>
                                    </li>
                                {/if}
                                <li class="relative">
                                    <a class="flex justify-between my-3 gap-2 py-3 pr-3 text-sm transition pl-4 text-zinc-900 bg-white border border-zinc-300 hover:bg-zinc-200 rounded-md cursor-pointer"
                                       on:click|preventDefault|stopPropagation={ () => {
                                         openMobileMenu = false
                                         navigate('/invoices')
                                       }}>
                                        <span class="flex items-center">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.75 14V11C4.75 10.4477 5.19772 9.99996 5.75 9.99996H6.75C6.75 9.99996 6.75 6.74996 10 6.74996V4.74996C10 4.74996 12 4.49996 12 6.74996H15.25C17.4591 6.74996 19.25 8.54082 19.25 10.75V13.25C19.25 15.4591 17.4591 17.25 15.25 17.25H9C6.75 17.25 6.75 15 6.75 15H5.75C5.19772 15 4.75 14.5522 4.75 14Z"
                                                      stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                                      stroke-linejoin="round"></path>
                                                <path d="M10.5 11C10.5 11.2761 10.2761 11.5 10 11.5C9.72386 11.5 9.5 11.2761 9.5 11C9.5 10.7239 9.72386 10.5 10 10.5C10.2761 10.5 10.5 10.7239 10.5 11Z"
                                                      stroke="currentColor"></path>
                                                <path d="M8.75 17.5V19.25" stroke="currentColor" stroke-width="1.5"
                                                      stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M15.25 17.5V19.25" stroke="currentColor" stroke-width="1.5"
                                                      stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            <span class="truncate ml-2">{t('topMenu.nav.invoices')}</span>
                                        </span>
                                    </a>
                                </li>
                                <li class="relative">
                                    <a class="flex justify-between my-3 gap-2 py-3 pr-3 text-sm transition pl-4 text-zinc-900 bg-white border border-zinc-300 hover:bg-zinc-200 rounded-md cursor-pointer"
                                       on:click|preventDefault|stopPropagation={ () => {
                                         openMobileMenu = false
                                         navigate('/dunning')
                                       }}>
                                        <span class="flex items-center">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.75 14V11C4.75 10.4477 5.19772 9.99996 5.75 9.99996H6.75C6.75 9.99996 6.75 6.74996 10 6.74996V4.74996C10 4.74996 12 4.49996 12 6.74996H15.25C17.4591 6.74996 19.25 8.54082 19.25 10.75V13.25C19.25 15.4591 17.4591 17.25 15.25 17.25H9C6.75 17.25 6.75 15 6.75 15H5.75C5.19772 15 4.75 14.5522 4.75 14Z"
                                                      stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                                      stroke-linejoin="round"></path>
                                                <path d="M10.5 11C10.5 11.2761 10.2761 11.5 10 11.5C9.72386 11.5 9.5 11.2761 9.5 11C9.5 10.7239 9.72386 10.5 10 10.5C10.2761 10.5 10.5 10.7239 10.5 11Z"
                                                      stroke="currentColor"></path>
                                                <path d="M8.75 17.5V19.25" stroke="currentColor" stroke-width="1.5"
                                                      stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M15.25 17.5V19.25" stroke="currentColor" stroke-width="1.5"
                                                      stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            <span class="truncate ml-2">{t('topMenu.nav.dunning')}</span>
                                        </span>
                                    </a>
                                </li>
                                <li class="relative">
                                    <a class="flex justify-between my-3 gap-2 py-3 pr-3 text-sm transition pl-4 text-zinc-900 bg-white border border-zinc-300 hover:bg-zinc-200 rounded-md cursor-pointer"
                                       on:click|preventDefault|stopPropagation={ () => {
                                         openMobileMenu = false
                                         navigate('/clients')
                                       }}>
                                    <span class="flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                             viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="1.5"
                                                  d="M16.75 4.75h1.5a1 1 0 0 1 1 1v2.5m-2.5-3.5h-9a3 3 0 0 0-3 3v8.5a3 3 0 0 0 3 3h9m0-14.5v3.5m0 11h1.5a1 1 0 0 0 1-1v-2.5m-2.5 3.5v-3.5m0-7.5h2.5m-2.5 0V12m2.5-3.75V12m-2.5 3.75h2.5m-2.5 0V12m2.5 3.75V12m-2.5 0h2.5m-10.5 3.25s.675-1.5 2.25-1.5 2.25 1.5 2.25 1.5m-1-5.25a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Z"></path>
                                        </svg>
                                        <span class="truncate ml-2">{t('topMenu.nav.clients.manage')}</span>
                                    </span>
                                    </a>
                                </li>
                                <li class="relative">
                                    <a class="flex justify-between my-3 gap-2 py-3 pr-3 text-sm transition pl-4 text-zinc-900 bg-white border border-zinc-300 hover:bg-zinc-200 rounded-md cursor-pointer"
                                       on:click|preventDefault|stopPropagation={ () => {
                                         openMobileMenu = false
                                         navigate('/clients/aging-balance')
                                       }}>
                                    <span class="flex items-center">
                                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V6.75Z"></path>
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.75 15.25V9.75"></path>
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.25 15.25V9.75"></path>
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 15.25V12.75"></path>
                                        </svg>
                                        <span class="truncate ml-2">{t('topMenu.nav.clients.agingBalance')}</span>
                                    </span>
                                    </a>
                                </li>
                                <li class="relative">
                                    <a class="flex justify-between my-3 gap-2 py-3 pr-3 text-sm transition pl-4 text-zinc-900 bg-white border border-zinc-300 hover:bg-zinc-200 rounded-md cursor-pointer"
                                       on:click|preventDefault|stopPropagation={ () => {
                                         openMobileMenu = false
                                         navigate('/team/manage')
                                       }}>
                                    <span class="flex items-center">
                                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="1.5"
                                                  d="M5.78168 19.25H13.2183C13.7828 19.25 14.227 18.7817 14.1145 18.2285C13.804 16.7012 12.7897 14 9.5 14C6.21031 14 5.19605 16.7012 4.88549 18.2285C4.773 18.7817 5.21718 19.25 5.78168 19.25Z"></path>
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="1.5"
                                                  d="M15.75 14C17.8288 14 18.6802 16.1479 19.0239 17.696C19.2095 18.532 18.5333 19.25 17.6769 19.25H16.75"></path>
                                            <circle cx="9.5" cy="7.5" r="2.75" stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round" stroke-width="1.5"></circle>
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="1.5"
                                                  d="M14.75 10.25C16.2688 10.25 17.25 9.01878 17.25 7.5C17.25 5.98122 16.2688 4.75 14.75 4.75"></path>
                                        </svg>
                                        <span class="truncate ml-2">{t('topMenu.nav.team.label')}</span>
                                    </span>

                                    </a>
                                </li>

                            </ul>
                        </div>
                    </li>
                    <li class="relative mt-6">
                        <div class="relative flex justify-between gap-2 mt-3 bg-white border border-zinc-300 rounded-md ">
                            <ul role="list" class="flex-grow">
                                <li class="relative">
                                    <a class="flex justify-between my-3 gap-2 py-3 text-sm transition text-zinc-900 hover:bg-zinc-200 cursor-pointer"
                                       on:click|preventDefault|stopPropagation={ () => {
                                         openMobileMenu = false
                                         navigate('/settings/invoicing-app/edit')
                                       }}>
                                    <span class="flex items-center ml-12">
                                        <span class="truncate">{t('topMenu.nav.settings.invoicingSettings')}</span>
                                    </span>

                                    </a>
                                </li>
                                <li class="relative">
                                    <a class="flex justify-between my-3 gap-2 py-3 text-sm transition text-zinc-900 hover:bg-zinc-200 cursor-pointer"
                                       on:click|preventDefault|stopPropagation={ () => {
                                         openMobileMenu = false
                                         navigate('/settings/profile/edit')
                                       }}>
                                    <span class="flex items-center ml-12">
                                        <span class="truncate">{t('topMenu.nav.settings.profileSettings')}</span>
                                    </span>

                                    </a>
                                </li>
                                <li class="relative">
                                    <a class="flex justify-between my-3 gap-2 py-3 text-sm transition text-zinc-900 hover:bg-zinc-200 cursor-pointer"
                                       on:click|preventDefault|stopPropagation={ () => {
                                         openMobileMenu = false
                                         navigate('/settings/company/edit')
                                       }}>
                                    <span class="flex items-center ml-12">
                                        <span class="truncate">{t('topMenu.nav.settings.companySettings')}</span>
                                    </span>

                                    </a>
                                </li>
                                <li class="relative">
                                    <a class="flex justify-between my-3 gap-2 py-3 text-sm transition text-zinc-900 hover:bg-zinc-200 cursor-pointer"
                                       on:click|preventDefault|stopPropagation={ () => {
                                         openMobileMenu = false
                                         navigate('/settings/bank/view')
                                       }}>
                                    <span class="flex items-center ml-12">
                                        <span class="truncate">{t('topMenu.nav.settings.bankAccounts')}</span>
                                    </span>

                                    </a>
                                </li>
                                <li class="relative">
                                    <a class="flex justify-between my-3 gap-2 py-3 text-sm transition text-zinc-900 hover:bg-zinc-200 cursor-pointer"
                                       on:click|preventDefault|stopPropagation={ () => {
                                         openMobileMenu = false
                                         navigate('/workspaces')
                                       }}>
                                    <span class="flex items-center ml-12">
                                        <span class="truncate">{t('topMenu.userNav.manageWorkspaces')}</span>
                                    </span>

                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <ul role="list">
                        <li class="relative">
                            <a
                                    class="flex justify-between my-3 gap-2 py-3 pr-3 text-sm transition pl-4 text-zinc-900 bg-white border border-zinc-300 hover:bg-zinc-200 rounded-md cursor-pointer"
                                    on:click|preventDefault|stopPropagation={ () => {
                                      openMobileMenu = false
                                      auth.logout()
                                    }}>
                                    <span class="flex items-center">
                                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="1.5" d="M15.75 8.75L19.25 12L15.75 15.25"></path>
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="1.5" d="M19 12H10.75"></path>
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="1.5"
                                                  d="M15.25 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H15.25"></path>
                                        </svg>
                                        <span class="truncate ml-2">{t('topMenu.nav.logout')}</span>
                                    </span>
                            </a>
                        </li>
                    </ul>
                </ul>
            </nav>
        </div>
        <div class="fixed inset-0 top-14 bg-zinc-400/20 backdrop-blur-sm opacity-100" style="z-index: 998"></div>
    {/if}
</div>


<style lang="postcss">
    .nav-menu {
        @apply fixed left-0 top-14 bottom-0 w-full overflow-y-auto bg-zinc-100 px-4 pt-6 pb-4 shadow-lg  min-[416px]:max-w-sm sm:px-6 sm:pb-10 -translate-x-[17rem] will-change-transform transition-transform duration-300 ease-linear;
    }

    .nav-menu.open {
        @apply translate-x-0 ease-linear;
    }
</style>

import { BusinessDocumentKind } from '../../enums/business-document-kind'
import { BusinessDocument, InstallmentComputationKind } from '../../models/business-document'
import { TaxonomyTag } from '../../enums/taxonomy-tag'
import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
import { formatCurrencyWithOptions } from '../../utils/number-formatting-utils'

/**
 * Get Installment Description
 * @param businessDocumentId
 * @param businessDocumentKind
 * @param installmentChosenKind
 * @param installmentChosenValue
 * @param installmentCurrencyCode
 * @param taxonomyTags
 */
export const getInstallmentDescription = (
  businessDocumentId: string,
  businessDocumentKind: BusinessDocumentKind,
  installmentChosenKind: InstallmentComputationKind,
  installmentChosenValue: number,
  installmentCurrencyCode: string,
  taxonomyTags: TaxonomyTag[]): string => {

  /* console.log('in getInstallmentDescription') */
  // Detect invoice type based on taxonomy tags
  let invoiceType: string = ''
  if (taxonomyTags?.includes(TaxonomyTag.DEPOSIT_INVOICE)) {
    invoiceType = 'Deposit'
  } else if (taxonomyTags?.includes(TaxonomyTag.INTERMEDIATE_INVOICE)) {
    invoiceType = 'Intermediate'
  } else if (taxonomyTags?.includes(TaxonomyTag.BALANCE_INVOICE)) {
    invoiceType = 'Balance'
  }

  let desc: string = ''
  switch (installmentChosenKind) {
    case InstallmentComputationKind.PERCENT_OF_TOTAL_AMOUNT:
      const installmentChosenValueFormatted: number = Math.round(installmentChosenValue * 10) / 10 // rounding displayed percent (only at display level)
      switch (businessDocumentKind) {
        case BusinessDocumentKind.CREDITNOTE:
          desc = t('invoices.createInvoice.partialInstallmentDescriptionCreditNotePercentageOfAmount', {
            calculatedPercentage: installmentChosenValueFormatted
          })
          break
        case BusinessDocumentKind.INVOICE:
          switch (invoiceType) {
            case 'Deposit':
              desc = t('invoices.createInvoice.partialDepositInstallmentDescriptionInvoicePercentageOfAmount', {
                calculatedPercentage: installmentChosenValueFormatted
              })
              break
            case 'Intermediate':
              desc = t('invoices.createInvoice.partialIntermediateInstallmentDescriptionInvoicePercentageOfAmount', {
                calculatedPercentage: installmentChosenValueFormatted
              })
              break
            case 'Balance':
              desc = t('invoices.createInvoice.partialBalanceInstallmentDescriptionInvoicePercentageOfAmount', {
                calculatedPercentage: installmentChosenValueFormatted
              })
              break
            default:
              /* console.error('getInstallmentDescription case A not expected businessDocumentId', businessDocumentId, 'installmentChosenKind', installmentChosenKind, 'businessDocumentKind', businessDocumentKind, 'invoiceType', invoiceType, 'input taxonomyTags', taxonomyTags) */
              desc = t('invoices.createInvoice.partialInstallmentDescriptionInvoicePercentageOfAmount', {
                calculatedPercentage: installmentChosenValueFormatted
              })
          }
          break
        default:
          /* console.error('getInstallmentDescription case B not expected businessDocumentId', businessDocumentId, 'installmentChosenKind', installmentChosenKind, 'businessDocumentKind', businessDocumentKind, 'invoiceType', invoiceType, 'input taxonomyTags', taxonomyTags) */
          desc = t('invoices.createInvoice.partialInstallmentDescriptionDocumentKindPercentageOfAmount', {
            calculatedPercentage: installmentChosenValueFormatted
          })
          break
      }
      break
    case InstallmentComputationKind.ABSOLUTE_AMOUNT_INCLUDING_TAX:
      switch (businessDocumentKind) {
        case BusinessDocumentKind.CREDITNOTE:
          desc = t('invoices.createInvoice.partialInstallmentDescriptionCreditNoteAbsoluteAmount', {
            absoluteValue: formatCurrencyWithOptions(installmentChosenValue, t('locales'), installmentCurrencyCode, true)
          })
          break
        case BusinessDocumentKind.INVOICE:
          switch (invoiceType) {
            case 'Deposit':
              desc = t('invoices.createInvoice.partialDepositInstallmentDescriptionInvoiceAbsoluteAmount', {
                absoluteValue: formatCurrencyWithOptions(installmentChosenValue, t('locales'), installmentCurrencyCode, true)
              })
              break
            case 'Intermediate':
              desc = t('invoices.createInvoice.partialIntermediateInstallmentDescriptionInvoiceAbsoluteAmount', {
                absoluteValue: formatCurrencyWithOptions(installmentChosenValue, t('locales'), installmentCurrencyCode, true)
              })
              break
            case 'Balance':
              desc = t('invoices.createInvoice.partialBalanceInstallmentDescriptionInvoiceAbsoluteAmount', {
                absoluteValue: formatCurrencyWithOptions(installmentChosenValue, t('locales'), installmentCurrencyCode, true)
              })
              break
            default:
              /* console.warn('getInstallmentDescription case C not expected businessDocumentId', businessDocumentId, 'installmentChosenKind', installmentChosenKind, 'businessDocumentKind', businessDocumentKind, 'invoiceType', invoiceType, 'input taxonomyTags', taxonomyTags) */
              desc = t('invoices.createInvoice.partialInstallmentDescriptionInvoiceAbsoluteAmount', {
                absoluteValue: formatCurrencyWithOptions(installmentChosenValue, t('locales'), installmentCurrencyCode, true)
              })
          }
          break
        default:
          /* console.error('getInstallmentDescription case D not expected businessDocumentId', businessDocumentId, 'installmentChosenKind', installmentChosenKind, 'businessDocumentKind', businessDocumentKind, 'invoiceType', invoiceType, 'input taxonomyTags', taxonomyTags) */
          desc = t('invoices.createInvoice.partialInstallmentDescriptionDocumentKindAbsoluteAmount', {
            absoluteValue: formatCurrencyWithOptions(installmentChosenValue, t('locales'), installmentCurrencyCode, true)
          })
      }
      break
    default:
      /* console.error('getInstallmentDescription case E not expected businessDocumentId', businessDocumentId, 'installmentChosenKind', installmentChosenKind, 'businessDocumentKind', businessDocumentKind, 'invoiceType', invoiceType, 'input taxonomyTags', taxonomyTags) */
      desc = t('invoices.createInvoice.partialInstallmentDescriptionDocumentKindChosenKind', {
        partialValue: installmentChosenValue
      })
  }

  return desc
}

export const getAmountOfInstallmentValueLabel = (businessDocument: BusinessDocument): string => {
  switch (businessDocument.businessDocumentKind) {
    case BusinessDocumentKind.INVOICE:
      return t('invoices.createInvoice.amountOfInstallmentValueForInvoiceLabel')
    case BusinessDocumentKind.CREDITNOTE:
      return t('invoices.createInvoice.amountOfInstallmentValueForCreditNoteLabel')
    default:
      return t('invoices.createInvoice.amountOfInstallmentValueForBusinessDocumentLabel')
  }
}

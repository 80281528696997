<script lang="ts">
  import type {Customer} from '$crm/models/customer'
  import AmountsMeter from '../../../core-app/lib/ui-kit/AmountsMeter.svelte'
  import {DunningInvoicesStore} from '$dundy/stores/dunning-invoices.store'
  import {CustomersHelper} from '$crm/helpers/customers-helper'
  import {deriveMostUsedCurrencyFromStores} from "$core/services/main-currency-identification-from-stores"
  import {SimpleDocumentsStore} from "$voxy/stores/business-documents.store"
  import type {Readable} from "svelte/store"

  /** Export declarations */
  export let classes: string = ''
  export let customer: Customer
  export let showTooltip: boolean = true
  export let showAmountBars: boolean = true

  /** Local declarations */
  let dueAmount: number
  let overdueAmount: number
  let totalAmount: number
  let duePercentage: number
  let overduePercentage: number
  let stackLabelsOnSmallerScreens: boolean = true
  const invoicesCurrency: Readable<string> = deriveMostUsedCurrencyFromStores(DunningInvoicesStore, SimpleDocumentsStore)

  /** Reactive declarations */
  $: dueAmount = CustomersHelper.calculateDueAmount(customer)
  $: overdueAmount = CustomersHelper.calculateOverdueAmount(customer)
  $: totalAmount = dueAmount + overdueAmount
  $: duePercentage = (dueAmount / totalAmount) * 100
  $: overduePercentage = (overdueAmount / totalAmount) * 100

</script>

<AmountsMeter {classes}
              {dueAmount}
              {overdueAmount}
              {duePercentage}
              {overduePercentage}
              invoicesCurrency={$invoicesCurrency}
              {stackLabelsOnSmallerScreens}
              {showTooltip}
              {showAmountBars}
/>

<script context="module" lang="ts">
    // for passing focus on to the next Modal in the queue.
    // A module context level object is shared among all its component instances. [Read More Here](https://svelte.dev/tutorial/sharing-code)
    const modalList = []
</script>
<script lang="ts">

    import { createEventDispatcher, onDestroy, onMount } from 'svelte'

    export let id = 'dundy-modal'
    export let isCloseable: boolean = true

    export let showFooter: boolean = true

    let propClass = ''
    export { propClass as class }

    let show: boolean = false

    const dispatch = createEventDispatcher()

    /**
     * Close the modal
     * only if isCloseable is true
     */
    const close = () => {
      if (!isCloseable) return
      dispatch('close')
    }

    function keydown(e) {
      e.stopPropagation()
      if (e.key === 'Escape') {
        close()
      }
    }


    function modalAction(node) {
      const returnFn = []
      // for accessibility
      if (document.body.style.overflow !== 'hidden') {
        const original = document.body.style.overflow
        document.body.style.overflow = 'hidden'
        returnFn.push(() => {
          document.body.style.overflow = original
        })
      }
      node.addEventListener('keydown', keydown)
      node.focus()
      modalList.push(node)
      returnFn.push(() => {
        node.removeEventListener('keydown', keydown)
        modalList.pop()
        // Optional chaining to guard against empty array.
        modalList[modalList.length - 1]?.focus()
      })

      return {
        destroy: () => returnFn.forEach((fn) => fn())
      }
    }

    onMount(() => {
      show = true
    })

    onDestroy(() => {
      show = false
    })

</script>

<div {id}
     use:modalAction
     tabindex={0}
     aria-labelledby="invoice-modal"
     aria-hidden="false"
     class="modal slide-in from-bottom {propClass} {show && 'show'} w-full">

    <div class="modal-close-btn {isCloseable ? '' : 'disabled hidden' }" style="z-index: 99999">
        {#if isCloseable}
            <i class="icon-close text-5xl relative bottom-0.5" on:click={close}></i>
        {:else}
            <i class="icon-close text-5xl relative bottom-0.5"></i>
        {/if}
    </div>

    <div class="modal-wrapper">

        <div class="modal-content">
            <slot name="content"></slot>
        </div>

        {#if showFooter}
            <div>
                <slot name="footer"></slot>
            </div>
        {/if}

    </div>

</div>

<style lang="postcss">

    .modal {
        @apply fixed top-0 left-0 right-0 shadow-lg bg-white z-[100] outline-none overflow-hidden h-full;
    }

    .modal-close-btn {
        @apply cursor-pointer flex justify-center align-top h-12 w-12 bg-whisper rounded absolute top-3 right-3 z-50 hover:bg-athensGray;
    }

    .modal-close-btn.disabled {
        @apply cursor-not-allowed hover:bg-whisper opacity-50;
    }

    .modal-wrapper {
        @apply mx-auto w-auto h-full overflow-hidden z-10;
    }

    .modal-content {
      @apply flex-auto overflow-hidden pointer-events-auto fixed w-full h-full;
      overflow-y: auto;
    }

    div.modal:not(:focus-within) {
        transition: opacity 0.1ms;
        opacity: 1;
    }

    .slide-in {
        position: fixed;
        overflow: hidden;
        transition: transform .3s ease-in-out;
    }

    .slide-in.from-bottom {
        transform: translateY(100%);
        -webkit-transform: translateY(100%);
    }

    .slide-in.show {
        transform: translateY(0);
        -webkit-transform: translateY(0);
    }


</style>

<script lang="ts">
    import { WorkspaceBankConfig } from '../models/workspace-bank-config'
    import { t } from '../../core-app/lib/i18n/i18nextWrapper.js'

    /** Export Declarations */
    export let localBankConfig: WorkspaceBankConfig
    export let formattedIBAN: string
</script>
<div class="flex p-6">
    <div class="w-16 h-16 bg-zinc-100 rounded-xl p-3 flex items-center align-middle justify-center font-medium text-base text-zinc-300">
        {localBankConfig?.selectedAccountBankName?.substring(0, 1).toUpperCase()}
    </div>

    <div class="flex flex-col ml-4">
        <div class="flex items-center space-x-4">
            <span class="text-xl text-black font-bold items-center">{localBankConfig?.selectedAccountBankName}</span>
            <div class="justify-between flex rounded-md bg-green-100 px-2 text-xs font-semibold text-green-800">
                <span class="text-black text-xs">{t('editBank.activeBank')}</span>
            </div>
        </div>
        <span class="text-sm font-medium">{formattedIBAN}</span>
    </div>
</div>
import { get } from 'svelte/store'
import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
import { eventsManager } from '../../core-app/events/event-manager'
import { EventType } from '../../core-app/events/event-type'
import { APICallOptions, APIEntity, apiGet, apiPut } from '../../core-app/services/api.service'
import { feedbackService } from '../../core-app/services/feedback.service'
import type { Feedback } from '../../core-app/models/feedback'
import type { NumberingScheme, NumberingSchemeSettings, VoxyPreferences } from '../models/voxy-preferences'
import { VoxyPreferencesStore } from '../stores/voxy-preference.store'
import { GetAllBusinessDocumentKinds } from '../enums/business-document-kind'
import { VoxyHelper } from '../helpers/voxy-helper'
import { NewVoxyActionToPipeline } from '../stores/voxy-action-pipeline.store'
import type { DundyEvent } from '../../dundy-app/events/dundy-event'


class VoxyPreferencesServiceClass {
  initialized
  initialize(originOfCall: string) {
    if (!this.initialized) {

      this.initialized = true

      // NB: the eventsManager.on<VoxyPreferences>(EventType.VOXY_PREFERENCES_FETCHED, e => {})
      // is done in state-management.service.ts by the global initialize()

      // eventsManager.on<VoxyPreferences>(EventType.VOXY_PREFERENCES_FETCHED, e => {

      //     VoxyPreferencesStore.set(e.data);
      // }, 'VoxyPreferencesService');

      eventsManager.on<NumberingSchemeSettings>(EventType.VOXY_PREFERENCES_SCHEME_SETTING_CHANGED, (e: DundyEvent<NumberingSchemeSettings>): void => {
        /* console.log('adding task  vxp to change voxy preference') */
        // can't use it directly in saveCurrentNumberingSchemeToServer otherwise it will be incoherent if workspace has changed when the Promise is eventually executed
        const currentWorkspaceId = get(WorkspaceStore).workspaceId
        
        NewVoxyActionToPipeline(() =>
        /* console.log('about to save change of voxy preference to back-end  vxp !!!!!!!!', 'for e.data ', JSON.stringify(e.data, null, 3)) */

          this.saveCurrentNumberingSchemeToServer(currentWorkspaceId, e.data)
        , 'new Voxy numbering settings for \'' + e.data.applicableDocumentKind + '\' with template \'' + e.data.documentNumberTemplate + '\' and ' + e.data.sequenceNumberOfDigits + ' digits (' + e.data.description + ')', 0)
      }, 'App.svelte')

      /* console.log('this.getVoxyPreferencesFromBackEndAPI().then(() => {  vxp !!!!!!', 'workspace at this point', get(WorkspaceStore)) */
      this.getVoxyPreferencesFromBackEndAPI('VoxyPreferencesServiceClass initialize() : from ' + originOfCall).then(() => {
        /* console.log('DONE initialized Voxy PREFERENCES vxp ') */
      })
    }
  }
  saveCurrentNumberingSchemeToServer(currentWorkspaceId: string, newNumberingSchemeSetting?: NumberingSchemeSettings) {
    /* console.log(' vxp The NumberingSchemeSettings being saved', newNumberingSchemeSetting, 'on currentWorkspaceId', currentWorkspaceId) */
    return apiPut<{
      currentNumberingScheme: NumberingSchemeSettings;
    }>(`/workspaces/${currentWorkspaceId}/voxy-business-document-numbering`,
      newNumberingSchemeSetting,
            <APICallOptions>{
              entity: APIEntity.VOXY_BUSINESS_DOCUMENTS,
              overrideContentType: 'application/json',
              ignoreFeedback: true
            })
      .then((response: { currentNumberingScheme: NumberingSchemeSettings }) =>
      /* console.log(' vxp Response from Put Request', response) */

        response
      ).catch(e => feedbackService.displayFeedback(<Feedback>{
        feedbackLevel: 'Error',
        feedbackMessage: e.message || e.detail || e
      }))
  }
  getVoxyPreferencesFromBackEndAPI(causeOfCall: string) {
    /* console.log('causeOfCall  vxp !!!!!!', causeOfCall) */

    return apiGet<VoxyPreferences>(`/workspaces/${get(WorkspaceStore).workspaceId}/voxy-preferences`, <APICallOptions>{
      entity: APIEntity.VOXY_BUSINESS_DOCUMENTS,
      overrideContentType: 'application/json',
      ignoreFeedback: true
    })
      .then((response: VoxyPreferences) => {
        let publishedResponse: VoxyPreferences = response

        eventsManager.once<NumberingSchemeSettings>(EventType.VOXY_PREFERENCES_STORE_UPDATE_CYCLE_COMPLETED, () => {
          /* console.log('!!!!!! vxp  Response from Get Request', publishedResponse) */
          /* console.log('!!!!!! vxp  publishedResponse.numberingSchemesByBusinessDocument', publishedResponse.numberingSchemesByBusinessDocument) */

          // phase I :  subscribe to the consequences of phase II
          //            if the back-end sent us empty settings, the UX strategy is that we replace them by a default setting defined by the front-end
          //            each time we update the back-end with the new settings
          /* console.log('phase I :  subscribe to the consequences of phase II - ppp!!!! vxp  running VoxyPreferencesStore.update() with VoxyHelper.newVoxyDefaultNumberingScheme()') */
          VoxyPreferencesStore.update(s => {
            for (let aKind of GetAllBusinessDocumentKinds()) {
              /* console.log('!!!!!! vxp VoxyPreferencesStore.update aKind', aKind) */
              if (!publishedResponse.numberingSchemesByBusinessDocument[aKind].isFirstSchemeSetupCompleted ||
                                !publishedResponse.numberingSchemesByBusinessDocument[aKind].currentNumberingScheme ||
                                !publishedResponse.numberingSchemesByBusinessDocument[aKind].currentNumberingScheme.documentNumberTemplate ||
                                publishedResponse.numberingSchemesByBusinessDocument[aKind].currentNumberingScheme.documentNumberTemplate === '') {
                // setup default numbering values here
                const newNumberingScheme = VoxyHelper.newVoxyDefaultNumberingScheme(aKind)
                publishedResponse.numberingSchemesByBusinessDocument[aKind].currentNumberingScheme = newNumberingScheme
                /* console.log('updating VoxyPreferencesStore with new value s for kind  vxp !!!!!', s, aKind) */
                if (!!s && !s.hasOwnProperty('numberingSchemesByBusinessDocument')) {
                  /* console.log('old s  vxp !!!!!', s) */
                  s.numberingSchemesByBusinessDocument = new Map()
                }
                if (!!s && s.hasOwnProperty('numberingSchemesByBusinessDocument') && !s.numberingSchemesByBusinessDocument.hasOwnProperty(aKind)) {
                  /* console.log('old s.numberingSchemesByBusinessDocument[aKind]  vxp !!!!!', s.numberingSchemesByBusinessDocument[aKind]) */
                  s.numberingSchemesByBusinessDocument[aKind] = <NumberingScheme>{}
                }
                if (!!s && s.hasOwnProperty('numberingSchemesByBusinessDocument') && s.numberingSchemesByBusinessDocument.hasOwnProperty(aKind) && s.numberingSchemesByBusinessDocument[aKind].hasOwnProperty('currentNumberingScheme')) {
                  /* console.log('old s.numberingSchemesByBusinessDocument[aKind].currentNumberingScheme  vxp !!!!!', s.numberingSchemesByBusinessDocument[aKind].currentNumberingScheme) */
                  s.numberingSchemesByBusinessDocument[aKind].currentNumberingScheme = <NumberingSchemeSettings>{}
                }
                /* console.log('old s.numberingSchemesByBusinessDocument[aKind].currentNumberingScheme  vxp !!!!!', s.numberingSchemesByBusinessDocument[aKind].currentNumberingScheme) */
                /* console.log('new s.numberingSchemesByBusinessDocument[aKind].currentNumberingScheme  vxp !!!!!', newNumberingScheme) */
                s.numberingSchemesByBusinessDocument[aKind].currentNumberingScheme = newNumberingScheme
              }
            }
            /* console.log('updated old to new  vxp !!!!!!') */

            return s
          })
          eventsManager.emit(EventType.VOXY_PREFERENCES_FETCHED, publishedResponse, 'voxyPreferencesService-1')
        }, 'voxyPreferencesService')

        // phase II : save the voxy preferences locally as received from back-end
        /* console.log('phase II : save the voxy preferences locally as received from back-end - ppp!!!! vxp  running VoxyPreferencesStore.update() for saving publishedResponse') */
        VoxyPreferencesStore.update(s =>
        /* console.log('updating VoxyPreferencesStore directly from back-end response  vxp !!!!!!') */

          publishedResponse
        )
        eventsManager.emit(EventType.VOXY_PREFERENCES_FETCHED, publishedResponse, 'voxyPreferencesService-2')
      }).catch((e) => {
        /* console.log('error updating VoxyPreferencesStore with new value s  vxp !!!!!', e.message) */
        feedbackService.displayFeedback(<Feedback>{
          feedbackLevel: 'Error',
          feedbackMessage: e.message || e.detail || e
        })
      })
  }

}

export const voxyPreferencesService = new VoxyPreferencesServiceClass()

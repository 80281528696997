<script context="module" lang="ts">
    import { writable, type Writable } from 'svelte/store'

    export interface AccordionCtxType {
      flush: boolean;
      selected?: Writable<object>;
    }
</script>
<script lang="ts">
    import { setContext } from 'svelte'

    /**
     * Specify alignment of accordion item chevron icon
     * @type {'start' | 'end'}
     */
    export let align: string = 'end'

    /**
     * Specify the size of the accordion
     * @type {'sm' | 'xl'}
     */
    export let size: string = undefined

    export let multiple: boolean = false
    export let flush: boolean = false

    export let disabled: boolean = false

    const ctx: AccordionCtxType = {
      flush,
      selected: multiple ? undefined : writable()
    }
    setContext<AccordionCtxType>('ctx', ctx)

</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->

<ul {...$$restProps}
    class:accordion="{true}"
    class:accordion--end="{align === 'end'}"
    class:accordion--sm="{size === 'sm'}"
    class:accordion--start="{align === 'start'}"
    class:accordion--xl="{size === 'xl'}"
    class:disabled="{disabled}"
    on:click
    on:mouseenter
    on:mouseleave
    on:mouseover>
    <slot/>
</ul>

<style>
    .disabled {
        @apply opacity-60 select-none pointer-events-none;
    }
</style>

<script lang="ts">
  import { onMount } from 'svelte'
  import { navigate } from 'svelte-routing'
  import { t } from '../../core-app/lib/i18n/i18nextWrapper'
  import SearchBar from '../../core-app/lib/ui-kit/SearchBar.svelte'
  import { WorkspaceListStore, WorkspaceStore } from '../stores/workspace.store'
  import { workspacesService } from '../services/workspace.service'
  import PaginatedDisplayList from '../../core-app/lib/ui-kit/paginated-list/PaginatedDisplayList.svelte'
  import { eventsManager } from '../../core-app/events/event-manager'
  import { EventType } from '../../core-app/events/event-type'
  import objectPath from 'object-path'
  import Loader from '../../core-app/lib/ui-kit/Loader.svelte'
  import type PaginatedDisplayListConfig from '../../core-app/lib/ui-kit/paginated-list/PaginatedListConfig'
  import type { Workspace } from '../models/workspace'
  import { handleExportWorkspacesDataToExcel } from '../services/export-workspace-data-to-file.js'
  import type { Unsubscriber } from 'svelte/store'
  import mixpanel from 'mixpanel-browser'

  /** Local declarations */
  let loadingWorkspacesData: boolean = false
  let workspaces: Workspace[] = []

  let unSubscribeWorkspaceList: Unsubscriber
  /** Reactive declarations */
  // $: workspaces = $WorkspaceListStore && $WorkspaceListStore.length ? $WorkspaceListStore : [];

  eventsManager.on<Workspace[]>(EventType.WORKSPACE_LIST_LOADED, e => {
    if (e.data && e.data.length) {
      workspaces = e.data
      populateListData()
    }
  }, 'Workspaces.svelte')
  eventsManager.on<Workspace[]>(EventType.WORKSPACE_LIST_CHANGED, e => {
    if (e.data && e.data.length) {
      workspaces = e.data
      populateListData()
    }
    populateListData()
  }, 'Workspaces.svelte')

  const listConfig: PaginatedDisplayListConfig = <PaginatedDisplayListConfig>{
    columns: [{
      dataKey: 'workspace',
      label: t('workspace'),
      type: 'workspace'
    }, {
      dataKey: 'workspace',
      label: t('bankAccount'),
      type: 'bank-account'
    }],
    data: [],
    pageSize: 5
  }

  const populateListData = () => {
    if (workspaces) {
      listConfig.data = workspaces.map(workspace => ({
        workspace
      }))
    }
  }

  const handleSearch = (text: string) => {
    if (text) {
      listConfig.data = filterByCustomerListResultsThatContainTextPart(text).map(workspace => ({
        workspace
      }))
    } else {
      populateListData()
    }
  }

  const checkIfObjectPropertyContainsText = (object: object, objectPropertyPath: Array<number | string> | number | string, text: string) => {
    const value = object && objectPath.get(object, objectPropertyPath)
    if (value && typeof value === 'string') {
      return value.toLowerCase().indexOf(text?.toLowerCase()) !== -1
    } else {
      return false
    }
  }

  const filterByCustomerListResultsThatContainTextPart = (text: string) => workspaces.filter(row => (!text || checkIfObjectPropertyContainsText(row, 'company.formalName', text) ||
    checkIfObjectPropertyContainsText(row, 'bankConfig.selectedAccountName', text)))

  const handleSelectWorkspace = (e: CustomEvent) => {
    // NewWorkspaceRequestedByUserWorkspaceId.set(e.detail.workspace.workspaceId);
    // stateManagementService.reset("in Workspace.svelte !!!!!!, chosen new workspace and currently running handleSelectWorkspace()");
    // navigate("/today");
    // feedbackService.displayFeedback(<Feedback>{
    //     feedbackLevel: 'Success',
    //     feedbackMessage: t('workspaces.selectSuccess')
    // });
    workspacesService.changeToSelectedWorkspace(e.detail.workspace.workspaceId, '/today', true, 'workspaces.selectSuccess')
  }


  onMount(() => {
    mixpanel.track('NG10 List of Workspaces Page', {
      'Description': 'Open Workspaces.svelte'
    })
    if (!!unSubscribeWorkspaceList) {
      unSubscribeWorkspaceList()
    }
    unSubscribeWorkspaceList = WorkspaceListStore.subscribe((newWorkspaceList: Workspace[]) => {
      workspaces = !!newWorkspaceList && !!newWorkspaceList.length ? newWorkspaceList : []
      populateListData()
    })
  })

</script>

<svelte:head>
    <title>{t('topMenu.nav.workspaces')} - Dundy</title>
</svelte:head>

<main class="flex flex-col items-center">
    <div class="flex w-full flex-col flex-1">
        <div>
            <h1 class="text-black text-3xl font-bold">{t('topMenu.nav.workspaces')}</h1>
        </div>
        <div class="w-full flex justify-end my-10 relative space-x-5">
            {#if !loadingWorkspacesData}
                <button class="btn btn-action-simple justify-self-end position-end flex flex-row"
                        on:click={() => handleExportWorkspacesDataToExcel(workspaces)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.75 19.25H17.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                        <path d="M12 15.25V4.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                        <path d="M7.75 9.25L12 4.75L16.25 9.25" stroke="currentColor" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    <span class="align-left inline-block">{t('workspaces.export')}</span>
                </button>
            {/if}
            <button class="btn action-bla primary"
                    data-cy="add-new-workspace-button"
                    on:click={() => navigate('/workspaces/new')}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.75 6.75V8.25C4.75 9.35457 5.64543 10.25 6.75 10.25H8.25C9.35457 10.25 10.25 9.35457 10.25 8.25V6.75C10.25 5.64543 9.35457 4.75 8.25 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75Z"
                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path d="M14.75 7H19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path d="M17 4.75L17 9.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path d="M4.75 15.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H8.25C9.35457 19.25 10.25 18.3546 10.25 17.25V15.75C10.25 14.6454 9.35457 13.75 8.25 13.75H6.75C5.64543 13.75 4.75 14.6454 4.75 15.75Z"
                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path d="M13.75 15.75V17.25C13.75 18.3546 14.6454 19.25 15.75 19.25H17.25C18.3546 19.25 19.25 18.3546 19.25 17.25V15.75C19.25 14.6454 18.3546 13.75 17.25 13.75H15.75C14.6454 13.75 13.75 14.6454 13.75 15.75Z"
                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                </svg>
                <span class="hidden md:block ml-1.5">{t('workspaces.add')}</span>
            </button>
        </div>
        <div class="flex flex-row w-full">
            <SearchBar placeholder={t('workspaces.search.placeholder')}
                       on:search={e => handleSearch(e.detail.text)}
                       on:clear={() => handleSearch('')}
                       hideLabel={true}
            />
        </div>

        <div class="w-full">

            {#if loadingWorkspacesData}
                <Loader i18nKey="workspaces.loadingData"/>
            {:else}
                {#if listConfig.data.length > 0}
                    <PaginatedDisplayList
                            config={listConfig}
                            on:selectRow={handleSelectWorkspace.bind(this)}
                            highlightRowFn={(rowData) => rowData.workspace.workspaceId === $WorkspaceStore.workspaceId}
                    />

                {:else}
                    <h1 class="font-medium text-2xl mb-3 text-center">{t('workspaces.empty.title')}</h1>
                    <p class="text-lg text-center">{t('workspaces.empty.first_paragraph')}</p>
                {/if}
            {/if}
        </div>
    </div>
</main>

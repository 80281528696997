<script lang="ts">
    import { link } from 'svelte-routing'
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper.js'
    import { Customer } from '../../models/customer'

    export let customer: Customer
</script>

<nav class="flex mb-3" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4">
        <li>
            <div class="flex items-center">
                <a href="/clients/manage" use:link class="text-sm font-medium text-gray-500 hover:text-dundyOrange">{t('topMenu.nav.clients.manage')}</a>
            </div>
        </li>
        <li>
            <div class="flex items-center">
                <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                </svg>
                <span class="ml-4 text-sm font-medium text-dundyOrange truncate" aria-current="page">{customer?.company.formalName}</span>
            </div>
        </li>
    </ol>
</nav>
<script lang="ts">
    import Tooltip from '../../../core-app/lib/ui-kit/Tooltip/Tooltip.svelte'
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper.js'
    import { ComputedDataInvoicesStore } from '../../stores/computed-data-invoice.store'
    import { DunningInvoicesStore } from '../../stores/dunning-invoices.store'
    import { onMount } from 'svelte'
    import { feedbackService } from '../../../core-app/services/feedback.service'
    import { Feedback } from '../../../core-app/models/feedback'
    import type ComputedDataInvoice from '../../models/computed-data-invoice'
    import { get } from 'svelte/store'
    import { invoiceAttachmentDownload } from '../../services/dundy-file-mgt/dundy-pdf-attachment.api'
    import { WorkspaceStore } from '../../../crm-app/stores/workspace.store'

    export let invoiceId: string

    /** Let & Const declarations */
    let currentInvoiceAttachment: any = null
    let isPDFFileLoading: boolean = false

    const doesCurrentInvoiceHaveAttachment = ():boolean=>{
      const invoicesStoreContent: ComputedDataInvoice[] = get(ComputedDataInvoicesStore)
      if (!!invoicesStoreContent) {
        return !!(invoicesStoreContent.find(computedInvoice => invoice && computedInvoice.invoiceNumber === invoice.invoiceNumber)?.invoiceMetadata?.businessDocumentAttachmentMetadata?.fileName)
      } else {
        return false
      }
    }

    /** Reactive declarations **/
    $: invoice = $DunningInvoicesStore.find((invoice) => invoice.dataId === invoiceId)

    onMount(() => {
      isPDFFileLoading = true
      if (doesCurrentInvoiceHaveAttachment()) {
        invoiceAttachmentDownload(invoice, get(WorkspaceStore).workspaceId)
          .then((attachment) => {
            setTimeout(() => {
              currentInvoiceAttachment = attachment
              isPDFFileLoading = false
            }, 1000)
          })
          .catch((e) => {
            feedbackService.displayFeedback(<Feedback>{
              feedbackLevel: 'Error',
              feedbackMessage: e.message || e.detail || e
            })
            isPDFFileLoading = false
          })
      } else {
        isPDFFileLoading = false
      }
    })
</script>

<div data-ut-component="download-pdf-button">
    {#if doesCurrentInvoiceHaveAttachment()}
        {#if !isPDFFileLoading && currentInvoiceAttachment && currentInvoiceAttachment.contentBase64 }
            <Tooltip>
                <div slot="activator">
                    <a download={currentInvoiceAttachment.fileName}
                       href={`data:application/pdf;base64,${currentInvoiceAttachment.contentBase64}`}
                       class="relative group h-12 w-12 flex items-center justify-center rounded hover:border hover:border-black hover:text-black focus:z-10 focus:ring-2 focus:ring-whisper"
                       aria-label="{t('invoices.viewInvoice.buttons.downloadButtonLabel')}"
                       type="link">
                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="1.5"
                                  d="M4.75 14.75V16.25C4.75 17.9069 6.09315 19.25 7.75 19.25H16.25C17.9069 19.25 19.25 17.9069 19.25 16.25V14.75"></path>
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="1.5" d="M12 14.25L12 4.75"></path>
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="1.5" d="M8.75 10.75L12 14.25L15.25 10.75"></path>
                        </svg>
                    </a>
                </div>
                {t('invoices.viewInvoice.buttons.downloadButtonLabel')}
            </Tooltip>
        {:else}
            <!-- svelte-ignore a11y-invalid-attribute -->
            <a href="#" class="h-12 w-12 flex items-center justify-center rounded disabled shadow-none hover:shadow-none cursor-default opacity-40"
               aria-label="{t('invoices.viewInvoice.buttons.downloadButtonLabel')}" type="link">
                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M4.75 14.75V16.25C4.75 17.9069 6.09315 19.25 7.75 19.25H16.25C17.9069 19.25 19.25 17.9069 19.25 16.25V14.75"></path>
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="1.5" d="M12 14.25L12 4.75"></path>
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="1.5" d="M8.75 10.75L12 14.25L15.25 10.75"></path>
                </svg>
            </a>
        {/if}
    {/if}
</div>
import type { Contact } from '../models/contact'

/**
 * Get the list of contacts from the company workspace
 * @param workspaceCompanyId
 * @param contactsStore
 */
export function getListOfContactsFromCompanyWorkspace(workspaceCompanyId: string, contactsStore: Contact[]): Contact[] {
  return contactsStore.filter((contact: Contact): boolean => contact.companyId === workspaceCompanyId)
}

<script lang="ts">
    import { createEventDispatcher, onDestroy, onMount } from 'svelte'
    import { type CellValueChangedEvent, Grid, type GridApi, type GridOptions, type RowSelectedEvent } from 'ag-grid-community'
    import 'ag-grid-community/styles/ag-grid.css'
    import 'ag-grid-community/styles/ag-theme-alpine.css'
    import type Invoice from '../../../dundy-app/models/invoice'
    import { EventType } from '../../events/event-type'
    import { eventsManager } from '../../events/event-manager'
    import type { CellValueChangeItem } from '../../../dundy-app/models/cell-value-change-item'
    import { showProperEditOrViewOnRowClick } from '../../helpers/ag-grid-helper'
    import AgGridCustomPagination from './AgGrid/AgGridCustomPagination.svelte'


    const dispatch = createEventDispatcher()

    let propClass = ''
    export { propClass as class }

    export let spreadsheetData: unknown[] = []
    export let grid
    export let columnDefs = []

    export let options: GridOptions = {
      defaultColDef: {
        filter: true
      },
      suppressFieldDotNotation: true,
      animateRows: true
    }

    /**
     * This is the HTML element that will contain the ag-grid component.
     */
    let ref: HTMLElement

    /**
     * This is the main entry point for the ag-grid component.
     */
    let api: GridApi

    /** Pagination Declarations */
    let currentPage: number
    let totalPage: number
    let totalResults: number
    let pageSize: number
    let startResult: number
    let endResult: number

    function onGoToNextPage() {
      grid?.gridOptions.api.paginationGoToNextPage()
      onCustomPaginationChanged()
    }

    function onGoToPreviousPage() {
      grid?.gridOptions.api.paginationGoToPreviousPage()
      onCustomPaginationChanged()
    }

    function getPaginationPageSize(): number {
      // fallback to return first page size, when grid is loaded from provided array
      return grid ? grid.gridOptions.api.paginationGetPageSize() : 50
    }

    function onPageSizeChangedToggleClassForThisElementAndRemoveClassFromOtherElements(size: number): void {
      const paginationSizeTab = document.querySelectorAll('.pagination-size-tab')
      paginationSizeTab.forEach((element) => {
        element.classList.remove('pagination-size-tab-active')
      })
      const paginationSizeTabActive = document.querySelector(`.pagination-size-tab-${ size }`)
      paginationSizeTabActive.classList.add('pagination-size-tab-active')
    }

    function onPageSizeChanged(size: number): void {
      if (!!grid) {
        grid.gridOptions.cacheBlockSize = size
      }
      grid?.gridOptions.api.paginationSetPageSize(Number(size))
      onPageSizeChangedToggleClassForThisElementAndRemoveClassFromOtherElements(size)
      onCustomPaginationChanged()
    }

    const onCustomPaginationChanged = () => {
      currentPage = grid?.gridOptions.api.paginationGetCurrentPage()
      totalPage = grid?.gridOptions.api.paginationGetTotalPages()
      totalResults = grid?.gridOptions.api.paginationGetRowCount()
      pageSize = getPaginationPageSize()
      startResult = currentPage * pageSize + 1
      endResult = Math.min(startResult + pageSize - 1, totalResults)
    }

    const onSelectionChanged = (event: RowSelectedEvent) => {
      const selectedRows = api.getSelectedRows()
      eventsManager.emit(EventType.INVOICES_TABLE_ROW_SELECTED, selectedRows, 'InvoicesDatatable.svelte')
    }

    const onCellValueChanged = (e: CellValueChangedEvent) => {
      /* console.log("the $DunningInvoicesStore store variable is already updated at this point if we do not care to deep copy the input displayed list upfront");
         console.log("onCellValueChanged Datatable");
         console.log("CellValueChangedEvent e.type = ", e.type); */
      // triggers subscribeToEventCellValueChanged
      let allChanges: CellValueChangeItem[] = [
            <CellValueChangeItem>{
              row: e.rowIndex,
              rowData: e.data,
              type: e.type,
              colId: e.column.getColId(),
              oldValue: e.oldValue,
              newValue: e.newValue,
              api: grid.api,
              node: e.node
            }
      ]
      if (e.column.getColId() === 'completed' && e.oldValue === false && e.newValue === true) {
        // we want to automatically track the invoice as soon as it is declared as completed
        const otherChange: CellValueChangeItem = <CellValueChangeItem>{
          row: e.rowIndex,
          rowData: e.data,
          type: e.type,
          colId: 'isTracked',
          oldValue: false,
          newValue: true,
          api: grid.api,
          node: e.node
        }
        allChanges.push(otherChange)
      }
      eventsManager.emit(EventType.CELL_VALUE_CHANGED, allChanges, 'InvoicesDatatable.svelte')
    }

    const onGridReady = () => {
      api = grid.gridOptions.api

      api.hideOverlay()
    }


    const onPaginationChanged = () => {
      eventsManager.emit(EventType.INVOICES_PAGINATION_CHANGED, null, 'InvoicesDatatable.svelte')
    }

    const updateData = (data: any[]) => {
      if (grid && api) {
        api.setRowData(data)
        api.setColumnDefs(columnDefs)
      }
    }

    const refreshCells = () => {

      if (api) {
        // https://www.ag-grid.com/javascript-data-grid/view-refresh/#refresh-cells
        // https://www.ag-grid.com/javascript-data-grid/grid-api/#reference-refresh-refreshCells
        api.refreshCells()
        api.forEachNode(node => {
          api.refreshCells({
            force: true,
            rowNodes: [node],
            columns: ['status', 'completed']
          })
          api.refreshCells({
            force: false,
            rowNodes: [node],
            columns: ['toId', 'toName']
          })
        })
      }
    }

    const reactToInvoiceListChanged = eventsManager.on<Array<Invoice>>(EventType.INVOICE_LIST_CHANGED, e => {
      refreshCells()
      onCustomPaginationChanged()
    }, 'InvoicesDatatable.svelte')
    const reactToInvoicesFeedbackChanged = eventsManager.on<Array<Invoice>>(EventType.INVOICES_FEEDBACK_CHANGED, e => {

      refreshCells()
    }, 'InvoicesDatatable.svelte')
    const reactToComputedInvoicesDataChanged = eventsManager.on<Array<Invoice>>(EventType.COMPUTED_INVOICE_LIST_FETCHED, e => {
      refreshCells()
    }, 'InvoicesDatatable.svelte')

    const reactToInvoicesPaginationChanged = eventsManager.on(EventType.INVOICES_PAGINATION_CHANGED, () => {
      refreshCells()
      onCustomPaginationChanged()
    }, 'Invoices.svelte')

    const reactToEventCellValueChange = eventsManager.on<Array<CellValueChangeItem>>(EventType.CELL_VALUE_CHANGED, e => {
      api.forEachNode(rowNode => {
        e.data.forEach(cell => {
          if (rowNode.rowIndex === cell.row) {
            if (!rowNode.data.hasOwnProperty(cell.colId) || (rowNode.data[cell.colId] !== cell.newValue)) {
              rowNode.setDataValue(cell.colId, cell.newValue)
            }
          }
        })
      })
    }, 'InvoicesDatatable.svelte')


    onMount(() => {
      grid = new Grid(ref, {
        ...options,
        columnDefs,
        rowData: JSON.parse(JSON.stringify(spreadsheetData)), // we make a copy first, otherwise we would directly alter the original variable without control
        onGridReady,
        onRowClicked: showProperEditOrViewOnRowClick,
        onCellValueChanged,
        onSelectionChanged,
        onPaginationChanged
      })
    })

    onDestroy(() => {
      reactToEventCellValueChange()
      reactToInvoiceListChanged()
      reactToInvoicesFeedbackChanged()
      reactToComputedInvoicesDataChanged()
      reactToInvoicesPaginationChanged()
      if (grid) {
        grid.destroy()
      }
    })

    $: updateData(spreadsheetData)
</script>

<div class="datatable {propClass}">
    <div
            bind:this={ref}
            class="ag-theme-alpine"
            style="height: 100%; width:100%; font-family: 'Space Grotesk', sans-serif; position: relative;"></div>

    <div class="sticky flex items-center bottom-0 bg-white py-4 px-0"
         style="box-shadow: inset 0 1px 0 #efeffd; height: 56px; margin-top: 56px; z-index: 0;">
        <AgGridCustomPagination
                bind:totalResults
                bind:pageSize
                bind:startResult
                bind:endResult
                bind:currentPage
                bind:totalPage
                on:setPageSize={(e) => {onPageSizeChanged(e.detail.size)}}
                on:goToNextPage={onGoToNextPage}
                on:goToPreviousPage={onGoToPreviousPage}
        />
    </div>
</div>


<script lang="ts">
  import { WorkspaceStore } from '$crm/stores/workspace.store'
  import { navigate } from 'svelte-routing'
  import { t } from '../../lib/i18n/i18nextWrapper'
  import { onMount } from 'svelte'
  import TextInput from '../../lib/ui-kit/TextInput.svelte'
  import { OnboardingStore } from '../../stores/onboarding.store'
  import StyledSelect from '../../lib/ui-kit/StyledSelect.svelte'
  import { ProfileStore } from '../../stores/profile.store'
  import { getUserJobTitles } from '../../services/user-job-titles-list'
  import { Workspace } from '$crm/models/workspace'
  import Profile from '../../models/profile'
  import { OnboardingStep } from '../../enums/onboarding-steps'
  import mixpanel from 'mixpanel-browser'
  import { navigateToMissingOnboardingStepIfNeeded } from '$src/core-app/services/onboarding.service'
  import OnboardingLayout from '$core/lib/onboarding/OnboardingLayout.svelte'

  /** Local variables */
  const userJobTitles = getUserJobTitles()
  let workspace = Workspace.StoreToClass()
  let profile = Profile.StoreToClass()
  let showCustomRoleInput: boolean
  let canContinue: boolean

  /** Reactive functions */
  $: {
    canContinue = profile.isOnboardingProfileInfoPlusDataComplete() && workspace.isOnboardingCompanyInfoPlusDataComplete()
  }

  $: if (profile.roleInCompany === 'other') {
    showCustomRoleInput = true
  } else {
    showCustomRoleInput = false
    profile.roleInCompanyCustom = ''
  }

  /**
   * Handle click on continue button
   * update the store and navigate to the next step
   */
  const onClickNext = (e: MouseEvent) => {
    e.preventDefault()
    if (!canContinue) return

    mixpanel.track('onb.cpn.pls.D01.u onboarding company info plus next step', {
      'Description': 'Click on next step button'
    })

    localStorage.setItem('onboarding', new Date().toISOString())

    WorkspaceStore.set(workspace)
    ProfileStore.set(profile)

    navigateToMissingOnboardingStepIfNeeded()
  }

  /**
   * Handle click on back button
   * update the store and navigate to the previous step
   */
  const onClickBack = (e: MouseEvent) => {
    e.preventDefault()

    mixpanel.track('onb.cpn.pls.D02.u onboarding company info plus previous step', {
      'Description': 'Click on previous step button'
    })
    
    $OnboardingStore.currentOnboardingStep = OnboardingStep.COMPANY_INFO
    navigate('/onboarding/company-info')
  }

  onMount(() => {
    mixpanel.track('onb.cpn.pls.D00.u onboarding company info plus load', {
      'Description': 'Open CompanyInfoPlus.svelte'
    })

    $OnboardingStore.currentOnboardingStep = OnboardingStep.COMPANY_INFO_PLUS
  })
</script>

<OnboardingLayout>
  <div slot="onboarding-sidebar">
    <div class="flex flex-col w-full text-center align-center">
      <div class="flex mt-36">
        <img alt="dundy logo" class="mx-auto" src="/img/zero-euro.svg"/>
      </div>
      <span class="text-base block mt-16 font-semibold text-white">
        {t('onboarding.companyInfoPlus.sidebar.title')}
      </span>
      <div class="flex flex-col w-full -ml-2 mt-4">
        <span class="text-baliHai mt-4 text-sm/6 w-9/12 mx-auto relative left-2">
          {t('onboarding.companyInfoPlus.sidebar.text')}
          </span>
      </div>
    </div>
  </div>
  <div slot="onboarding-content">
    <section class="onboarding">
      <div>
        <h1>
          {t('onboarding.companyInfoPlus.main.title')} {workspace.company.formalName}
        </h1>
        <form
          class="flex justify-center flex-col mt-5 w-full max-w-xl mx-auto"
          data-cy="company-info-form-plus"
        >
          <div class="flex justify-self-start mt-5">
            <span class="input-label">{t('onboarding.companyInfoPlus.main.form.phoneNumber')}</span>
            <span class="mandatory-marker"> * </span>
          </div>
          <TextInput
            bind:value={profile.mobilePhone}
            dataCy="phoneNumber"
            error={t('form.validation.invalidPhoneNumber')}
            pattern="[.-\\+ 0-9]"
            placeholder={t('onboarding.companyInfoPlus.main.form.phoneNumberPlaceholder')}
            showError={profile.mobilePhone?.length > 0 && profile.mobilePhone?.length < 10}
          />

          <div class="flex justify-self-start mt-5">
          <span
            class="input-label mb-3">{t('onboarding.companyInfoPlus.main.form.role', { company: workspace.company.formalName })}</span>
            <span class="mandatory-marker"> * </span>
          </div>
          <StyledSelect
            bind:value={profile.roleInCompany}
            class="col-span-full"
            dataCy="roleInCompany"
            isSearchable={false}
            items={userJobTitles}
            placeholder={t('onboarding.placeholders.role')}
          />
          {#if showCustomRoleInput}
            <TextInput
              class="mt-5"
              bind:value={profile.roleInCompanyCustom}
              dataCy="roleInCompanyCustom"
              label={t('onboarding.companyInfoPlus.main.form.customRole')}
            />
          {/if}

          <div class="mt-9 flex items-center justify-between">
            <button type="button" class="btn action-default mr-5 h-10" on:click={e => { onClickBack(e)}}>
              {t('onboarding.previous')}
            </button>
            <button type="button" class="btn action-bla primary h-10" disabled={!canContinue}
                    on:click={e => onClickNext(e)}>
              {t('onboarding.next')}
            </button>
          </div>

          <span
            class="text-xs mt-16 text-baliHai"><sup> * </sup>{t('onboarding.companyInfoPlus.main.form.mandatory')}</span>
        </form>
      </div>

    </section>
  </div>
</OnboardingLayout>

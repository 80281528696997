<script lang="ts">
    import Select from 'svelte-select'
    import { createEventDispatcher } from 'svelte'
    import { t } from '../i18n/i18nextWrapper'

    const dispatch = createEventDispatcher()

    let propClass: string = ''

    export { propClass as class }

    export let value: any = ''

    export let dataCy: string = ''

    export let items: any = []

    export let placeholder: string = t('smallSelect.placeholder')

    export let error: string = ''

    export let showError = false

    export let label: string = ''

    export let isSearchable: boolean

    export let isClearable: boolean = false

    export let showIndicator: boolean = true

    export let disabled: boolean = false

    export let optionIdentifier: string = 'value'

    export let labelIdentifier:string = 'label'

    /** Set an id for the input element */
    export let id = 'ccs-' + Math.random().toString(36)

    export let multiple:boolean = false

    export let multiFullItemClearable:boolean = false

    export let zIndex:number | null = null

    let indicator = '<i class=\'icon-chevron-down text-2xl text-black\'/>'

    export let selected: any = ''
    $: selected = items.find((i: any) => (i[optionIdentifier] || i) === (value && value[optionIdentifier] ? value[optionIdentifier] : value))
    $: {
      const foundItem = items.find((i: any) => (i[optionIdentifier] || i) === (value && value[optionIdentifier] ? value[optionIdentifier] : value))
      label = !!foundItem ? foundItem.label : ''
    }

    function handleSelect(e: CustomEvent) {
      value = e.detail.value
      dispatch('input', value)
      dispatch('change', value)
      dispatch('select', e.detail)
    }
</script>

<div class={propClass + ' small-select'} style={zIndex ? `z-index:${zIndex}` : ''} data-cy={dataCy}>
    <Select
            ChevronIcon={indicator}
            clearable={isClearable}
            searchable={isSearchable}
            itemId={optionIdentifier}
            label={labelIdentifier}
            {items}
            {showIndicator}
            on:select={handleSelect}
            {placeholder}
            value={selected}
            disabled={disabled}
            showChevron={showIndicator}
            multiple={multiple}
            multiFullItemClearable={multiFullItemClearable}
    >
      <div slot="item" let:item let:index>
        {@html item.label}
      </div>
      <div slot="selection" let:selection>
        {@html selection.label}
      </div>
      <slot name="chevron-icon">
        <div class="icon-chevron-down text-2xl text-black"></div>
      </slot>
  </Select>
    {#if showError}
        <p class="mt-2 text-sm text-cabaret" id="{id}-error">{error}</p>
    {/if}
</div>

<style lang="postcss" global>
    .small-select {
        --border: solid 1px #c1c8d1;
        --border-hover-color: #c1c8d1;
        --border-radius: 5px;
        --height: 40px;
        --input-padding: 0 10px;
        --item-padding: 0px 10px;
        --item-first-border-radius: 5px;
        --item-color: #1f2533;
        --item-is-active-color: #c54a3e;
        --item-is-active-bg: #fdefed;
        --item-hover-bg: #fdefed;
        --list-border-radius: 5px;
        --indicators-top: 3px;
        --chevron-width: 30px;
        --chevron-height: 33.625px;
      --font-size: 14px;
    }

    .small-select .svelte-select.focused {
        @apply ring-2 ring-dundyOrange;
        border: solid 1px transparent !important;
    }

    .small-select .icon {
        transition: transform 0.4s ease-in-out;
        display: grid;
        place-items: center;
    }

    .small-select .svelte-select.focused.list-open .icon {
        @apply rotate-180;
    }

    .small-select .svelte-select.focused .icon i {
        @apply text-dundyOrange;
    }

    .small-select .svelte-select > :not(.icon):last-child {
        width: 180px !important;
    }

    .small-select .svelte-select .svelte-select-list {
        padding: 13px;
    }

    .small-select .svelte-select .item {
        @apply rounded text-sm flex items-center;
    }

    .small-select .selection {
        display: flex;
        align-items: center;
    }
</style>

import { CustomerOverviewActiveTab } from '../../enums/customer-overview-active-tab'

/**
 * UI
 * Returns the invoices of a specific customer for the given tab.
 * @param tab
 * @param overdueInvoices
 * @param dueInvoices
 * @param paidInvoices
 * @param untrackedInvoices
 */
export const getCustomerInvoicesForTab = (
  tab: CustomerOverviewActiveTab,
  overdueInvoices: any[],
  dueInvoices: any[],
  paidInvoices: any[],
  untrackedInvoices: any[],
): any[] => {
  switch (tab) {
    case CustomerOverviewActiveTab.Overdue:
      return overdueInvoices
    case CustomerOverviewActiveTab.Due:
      return dueInvoices
    case CustomerOverviewActiveTab.Paid:
      return paidInvoices
    case CustomerOverviewActiveTab.Untracked:
      return untrackedInvoices
    default:
      return []
  }
}

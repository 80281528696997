<script lang="ts">
    import InvoiceTimeline from '../action/InvoiceTimeline.svelte'
    import { onMount } from 'svelte'
    import { updateNewNavigationHistoryItem } from '../../../core-app/stores/navigationHistory.store'
    export let invoiceId: string

    onMount(()=>{
      updateNewNavigationHistoryItem('ViewActionReceivableInvoiceTimeline.svelte')
    })
</script>

<div class="w-full mx-auto relative mt-2 pb-5">
    <InvoiceTimeline invoiceId={invoiceId} class=""/>
</div>
import type {
  BusinessDocument,
  BusinessDocumentAllDataPersisted,
  BusinessDocumentCollateralData,
  BusinessDocumentLineItem,
  BusinessDocumentRelation,
  BusinessDocumentSource
} from '../../models/business-document'
import { voxyInvoicingService } from '../business-document-voxy/voxy.service'
import { v4 as uuidv4 } from 'uuid'
import { deepClone } from '../../../core-app/util/object-deep-cloning'
import { ExchangeDate } from '../../../core-app/models/exchange-date'
import { BusinessDocumentKind } from '../../enums/business-document-kind'
import { BusinessDocumentStatus } from '../../enums/business-document-status'
import { BusinessDocumentRelationKind } from '../../enums/business-document-relation-kind'
import type Company from '../../../crm-app/models/company'
import type { Customer } from '../../../crm-app/models/customer'
import type { Contact } from '../../../crm-app/models/contact'
import type { Workspace } from '../../../crm-app/models/workspace'
import {
  getNewBusinessDocumentSource,
  getNewContactAccount,
  getNewCustomerAccount
} from '../business-document-various-functions'
import { getCurrentAccountCompany } from '../../../crm-app/services/workspace.pure-functions'
import type { TaxonomyTag } from '../../enums/taxonomy-tag'
import { getTagsForVoidedBusinessDocument } from '../taxonomy/taxonomy-tag-pure-functions'
import { getInstallmentDescription } from '../installment/installment.service'
import {
  generateNewCollateralData
} from '../business-document-collateral-data/business-document-collateral-data.service'
import { setNewRelationKind } from '../business-document-relation/update-business-document-relations.service'


/**
 * Helper Function to Call Void FUnction
 * @param businessDocumentAllDataPersisted
 */
export async function voidInvoiceData(businessDocumentAllDataPersisted: BusinessDocumentAllDataPersisted): Promise<BusinessDocumentAllDataPersisted> {
  return voxyInvoicingService.createVoxyCreditNoteThatVoidsAnInvoice(businessDocumentAllDataPersisted)
}


/**
 * Voids the BusinessDocument and Returns Copy with Voided Status
 * Depending on BusinessKind, the Voided BusinessDocument should be a CreditNote or an Invoice
 * If the BusinessDocument is a CreditNote, the lineItems should be negative
 *
 **/
export function transformationVoidBusinessDocument(
  originalBusinessDocumentAllDataPersisted: BusinessDocumentAllDataPersisted,
  workspaceStore: Workspace,
  customersStore: Customer[],
  contactsStore: Contact[],
): BusinessDocumentAllDataPersisted {
  if (originalBusinessDocumentAllDataPersisted.coreDocument.businessDocument.businessDocumentStatus !== BusinessDocumentStatus.FINAL) {
    throw new Error('impossible to void an businessDocument that is not finalized/open')
  }

  const now: Date = new Date()
  const newBusinessDocumentId: string = uuidv4()
  const newBusinessDocumentKind: BusinessDocumentKind = getKindForVoidedBusinessDocument(originalBusinessDocumentAllDataPersisted.coreDocument.businessDocument.businessDocumentKind, originalBusinessDocumentAllDataPersisted.coreDocument.businessDocument.taxonomyTags)
  let newTaxonomyTags: TaxonomyTag[] = getTagsForVoidedBusinessDocument(originalBusinessDocumentAllDataPersisted.coreDocument.businessDocument.businessDocumentKind, originalBusinessDocumentAllDataPersisted.coreDocument.businessDocument.taxonomyTags)
  const newBusinessDocumentNumber = ''
  const newBusinessDocumentIssuedDate: ExchangeDate = ExchangeDate.newDate(now)
  const originalBusinessDocument: BusinessDocument = originalBusinessDocumentAllDataPersisted.coreDocument.businessDocument
  const originalCollateralData: BusinessDocumentCollateralData = originalBusinessDocumentAllDataPersisted.collateralDocument.businessDocumentCollateralData

  const newBusinessDocumentSource: BusinessDocumentSource = getNewBusinessDocumentSource(originalBusinessDocument.businessDocumentSource, now)
  const refreshedAccountCompany: Company = getCurrentAccountCompany(workspaceStore)
  const newCustomerAccount: Customer = getNewCustomerAccount(customersStore, originalBusinessDocument.customerCustomer.company.companyId)
  const newContactAccount: Contact = getNewContactAccount(contactsStore, originalBusinessDocument.customerContact.contactId)
  const newRelatedBusinessDocuments: BusinessDocumentRelation[] = getNewRelatedBusinessDocumentsWhenVoidingBusinessDocument(originalBusinessDocument, newBusinessDocumentId, newBusinessDocumentKind, newTaxonomyTags, newBusinessDocumentNumber, newBusinessDocumentSource, newBusinessDocumentIssuedDate, now)
  const newGeneratedBusinessDocument: BusinessDocument = generateNewVoidingBusinessDocument(
    originalBusinessDocument,
    now,
    newBusinessDocumentId,
    newBusinessDocumentKind,
    newTaxonomyTags,
    newBusinessDocumentSource,
    newCustomerAccount,
    newContactAccount,
    refreshedAccountCompany,
    newBusinessDocumentIssuedDate,
    newRelatedBusinessDocuments)

  return {
    collateralDocument: {
      businessDocumentCollateralData: generateNewCollateralData(originalCollateralData, newBusinessDocumentId),
      modifiedDate: ExchangeDate.newDate(now),
      requesterId: originalBusinessDocumentAllDataPersisted.coreDocument.requesterId,
      workspaceId: originalBusinessDocumentAllDataPersisted.coreDocument.workspaceId
    },
    coreDocument: {
      requesterId: originalBusinessDocumentAllDataPersisted.coreDocument.requesterId,
      workspaceId: originalBusinessDocumentAllDataPersisted.coreDocument.workspaceId,
      businessDocument: newGeneratedBusinessDocument,
      modifiedDate: ExchangeDate.newDate(now)
    }
  }
}


/**
 * Copy And Push New Related Business Documents
 * @param originalBusinessDocument
 * @param newBusinessDocumentId
 * @param newBusinessDocumentKind
 * @param newBusinessDocumentTaxonomyTags
 * @param newBusinessDocumentNumber
 * @param newBusinessDocumentSource
 * @param newBusinessDocumentIssuedDate
 * @param now
 */
function getNewRelatedBusinessDocumentsWhenVoidingBusinessDocument(
  originalBusinessDocument: BusinessDocument,
  newBusinessDocumentId: string,
  newBusinessDocumentKind: BusinessDocumentKind,
  newBusinessDocumentTaxonomyTags: TaxonomyTag[],
  newBusinessDocumentNumber: string,
  newBusinessDocumentSource: BusinessDocumentSource,
  newBusinessDocumentIssuedDate: ExchangeDate,
  now: Date,
): BusinessDocumentRelation[] {
  const newRelatedBusinessDocuments: BusinessDocumentRelation[] = []

  if (!!originalBusinessDocument.relatedBusinessDocuments) {
    originalBusinessDocument.relatedBusinessDocuments.forEach((relatedDoc: BusinessDocumentRelation): void => {
      /*const newRelationKind: BusinessDocumentRelationKind = setNewRelationKind(originalBusinessDocument, newBusinessDocumentKind);*/
      const newRelatedBusinessDocument: BusinessDocumentRelation = {
        ...relatedDoc
      }
      newRelatedBusinessDocuments.push(newRelatedBusinessDocument)
    })
  }

  const newRelationKind: BusinessDocumentRelationKind = setNewRelationKind(originalBusinessDocument, newBusinessDocumentKind)
  const newRelatedBusinessDocument: BusinessDocumentRelation = {
    fromBusinessDocumentId: originalBusinessDocument.businessDocumentId,
    fromBusinessDocumentKind: originalBusinessDocument.businessDocumentKind,
    fromBusinessDocumentTaxonomyTags: originalBusinessDocument.taxonomyTags,
    fromBusinessDocumentNumber: originalBusinessDocument.businessDocumentNumber,
    fromBusinessDocumentSource: originalBusinessDocument.businessDocumentSource,
    fromBusinessDocumentIssuedDate: originalBusinessDocument.issuedDate,
    toBusinessDocumentId: newBusinessDocumentId,
    toBusinessDocumentKind: newBusinessDocumentKind,
    toBusinessDocumentTaxonomyTags: newBusinessDocumentTaxonomyTags,
    toBusinessDocumentNumber: newBusinessDocumentNumber,
    toBusinessDocumentSource: newBusinessDocumentSource,
    toBusinessDocumentIssuedDate: newBusinessDocumentIssuedDate,
    relationKind: newRelationKind,
    createdDate: ExchangeDate.newDate(now)
  }
  newRelatedBusinessDocuments.push(newRelatedBusinessDocument)

  return newRelatedBusinessDocuments
}


/**
 * Create the credit note from the original invoice
 * @param originalBusinessDocument
 * @param now
 * @param newBusinessDocumentId
 * @param newBusinessDocumentKind
 * @param newTaxonomyTags
 * @param newBusinessDocumentSource
 * @param newCustomerAccount
 * @param newContactAccount
 * @param refreshedAccountCompany
 * @param newBusinessDocumentIssuedDate
 * @param newRelatedBusinessDocuments
 */
function generateNewVoidingBusinessDocument(
  originalBusinessDocument: BusinessDocument,
  now: Date,
  newBusinessDocumentId: string,
  newBusinessDocumentKind: BusinessDocumentKind,
  newTaxonomyTags: TaxonomyTag[],
  newBusinessDocumentSource: BusinessDocumentSource,
  newCustomerAccount: Customer,
  newContactAccount: Contact,
  refreshedAccountCompany: Company,
  newBusinessDocumentIssuedDate: ExchangeDate,
  newRelatedBusinessDocuments: BusinessDocumentRelation[]): BusinessDocument {

  const newCreditNote: BusinessDocument = deepClone(originalBusinessDocument)
  newCreditNote.businessDocumentStatus = BusinessDocumentStatus.DRAFT
  newCreditNote.businessDocumentKind = newBusinessDocumentKind
  newCreditNote.taxonomyTags = newTaxonomyTags
  newCreditNote.customerCustomer = newCustomerAccount
  newCreditNote.customerContact = newContactAccount
  newCreditNote.accountCompany = refreshedAccountCompany
  newCreditNote.businessDocumentId = newBusinessDocumentId
  newCreditNote.businessDocumentSource = newBusinessDocumentSource
  newCreditNote.businessDocumentNumber = ''
  newCreditNote.relatedBusinessDocuments = newRelatedBusinessDocuments
  newCreditNote.finalizedDate = null
  newCreditNote.deleted = false
  newCreditNote.deletedDate = null
  newCreditNote.createdDate = ExchangeDate.newDate(now)
  newCreditNote.modifiedDate = ExchangeDate.newDate(now)
  newCreditNote.issuedDate = newBusinessDocumentIssuedDate
  newCreditNote.timeZoneIANACode = newCustomerAccount.company.timeZoneIANACode

  /** Turn numbers into negative */
  newCreditNote.subtotalExcludingTaxScaledValue = -originalBusinessDocument.subtotalExcludingTaxScaledValue
  newCreditNote.subtotalIncludingTaxScaledValue = -originalBusinessDocument.subtotalIncludingTaxScaledValue
  newCreditNote.totalTaxScaledValue = -originalBusinessDocument.totalTaxScaledValue
  newCreditNote.totalExcludingTaxScaledValue = -originalBusinessDocument.totalExcludingTaxScaledValue
  newCreditNote.totalIncludingTaxScaledValue = -originalBusinessDocument.totalIncludingTaxScaledValue

  newCreditNote.hasSpecificInstallment = originalBusinessDocument.hasSpecificInstallment
  newCreditNote.installmentChosenKind = originalBusinessDocument.installmentChosenKind
  newCreditNote.installmentDescription = getInstallmentDescription(newBusinessDocumentId, newBusinessDocumentKind, originalBusinessDocument.installmentChosenKind, originalBusinessDocument.installmentChosenValue, originalBusinessDocument.currency, originalBusinessDocument.taxonomyTags)
  newCreditNote.installmentChosenValue = originalBusinessDocument.installmentChosenValue
  newCreditNote.installmentResultTaxScaledValue = -originalBusinessDocument.installmentResultTaxScaledValue
  newCreditNote.installmentResultExcludingTaxScaledValue = -originalBusinessDocument.installmentResultExcludingTaxScaledValue
  newCreditNote.installmentResultIncludingTaxScaledValue = -originalBusinessDocument.installmentResultIncludingTaxScaledValue

  /** Turn all line items into negative */
  newCreditNote.lineItems.forEach((li: BusinessDocumentLineItem): void => {
    li.taxScaledValue = -li.taxScaledValue
    li.itemPrice.scaledValue = -li.itemPrice.scaledValue
    li.lineItemTotalIncludingTaxScaledValue = -li.lineItemTotalIncludingTaxScaledValue
    li.lineItemTotalExcludingTaxScaledValue = -li.lineItemTotalExcludingTaxScaledValue
  })

  return newCreditNote
}


/**
 * Returns the BusinessDocumentKind for the voided BusinessDocument
 * @param originalBusinessDocumentKind
 * @param originalBusinessDocumentTaxonomyTags
 */
export function getKindForVoidedBusinessDocument(originalBusinessDocumentKind: BusinessDocumentKind, originalBusinessDocumentTaxonomyTags: TaxonomyTag[]): BusinessDocumentKind {
  if (originalBusinessDocumentKind === BusinessDocumentKind.INVOICE) {
    return BusinessDocumentKind.CREDITNOTE
  } else if (originalBusinessDocumentKind === BusinessDocumentKind.CREDITNOTE) {
    return BusinessDocumentKind.INVOICE
  }
}

<script lang="ts">

    /** Set selected File */
    let selectedFile: File

    /** Set error message */
    let errorMsg: string

    /** Set json data */
    let jsonData: any

    /**
     * Handles the file input change event.
     * @param event The event object.
     */
    function handleFileChange(event: Event) {
      const input = event.target as HTMLInputElement
      selectedFile = (input.files as FileList)[0]
    }

    /**
     * Handles the form submission.
     */
    function handleSubmit() {
      /* console.log('submit') */
      if (!selectedFile) {
        errorMsg = 'Please select a CSV file to upload.'

        return
      }

      const reader = new FileReader()

      reader.onload = function () {
        /* console.log(reader.result) */

        const csvData = reader.result as string
        const jsonData = convertCsvToJson(csvData)
        console.log(jsonData)
      }

      reader.readAsText(selectedFile)

      reader.onerror = function () {
        errorMsg = 'Error reading file, please try again.'
      }
    }

    /**
     * Converts a CSV string to a JSON array.
     * @param csvData The CSV string to convert.
     */
    function convertCsvToJson(csvData: string): Record<string, string>[] {
      const [headerSection, dataSection] = csvData.split('\n\n')
      const headers = headerSection.split('\n').map((line) => line.replace(/"/g, '').trim())
      const dataLines = dataSection.trim().split('\n').slice(1)

      return dataLines.map((line) => {
        const values = line.split(';').map((value) => value.replace(/"/g, '').trim())

        return headers.reduce((obj: Record<string, string>, header, index) => {
          obj[header] = values[index]

          return obj
        }, {})
      })
    }

</script>

<template>
    <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div class="px-4 py-6 sm:px-0">
            <h2 class="text-2xl font-bold mb-4">Convert CSV to JSON</h2>
            <form class="flex flex-col gap-4" on:submit|preventDefault={handleSubmit}>
                <label for="csv-file" class="font-medium text-gray-700">Choose a CSV file to upload:</label>
                <input type="file" id="csv-file" accept=".csv"
                       class="border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border rounded-md p-2"
                       on:change={handleFileChange}/>
                {#if errorMsg}
                    <p class="text-red-500">{errorMsg}</p>
                {/if}
                <button type="submit" class="rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 disabled:bg-transparent disabled:border-athensGray disabled:text-loblolly disabled:shadow-none disabled:cursor-not-allowed">Convert
                    to JSON
                </button>
            </form>
            {#if jsonData}

                <div class="mt-6">
                    <h3 class="text-lg font-bold mb-4">JSON output:</h3>
                    <pre class="bg-gray-100 p-4 rounded-md">{JSON.stringify(jsonData, null, 2)}</pre>
                </div>
            {/if}
        </div>
    </div>
</template>

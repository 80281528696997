import { get, type Writable, writable } from 'svelte/store'
import type ComputedDataInvoice from '../models/computed-data-invoice'
import { eventsManager } from '../../core-app/events/event-manager'
import { EventType } from '../../core-app/events/event-type'
import initializationStore from '../../core-app/stores/initialization.store'
import { encrypt256Hash } from '../../core-app/util/encryption'

/* console.log('computed-data-dunning-invoices.store.ts') */

const getComputedDataInvoices = (): ComputedDataInvoice[] => {
  const existingValue: string = localStorage.getItem('ComputedDataInvoicesStore')
  const emptyValue: ComputedDataInvoice[] = <ComputedDataInvoice[]>[]
  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  if (existingValue === '{}') {
    return emptyValue
  }

  return <ComputedDataInvoice[]>JSON.parse(existingValue)
}
export let ComputedDataInvoicesStore: Writable<ComputedDataInvoice[]> = writable<ComputedDataInvoice[]>(getComputedDataInvoices())

export const initializeComputedDataInvoicesStore = (data) => {

  if (!get(initializationStore).computedInvoicesStoreInitialized) {

    initializationStore.update(store => {
      store.computedInvoicesStoreInitialized = true

      return store
    })

    localStorage.setItem('ComputedDataInvoicesStore', JSON.stringify(data, null, 4))
    ComputedDataInvoicesStore.set(data)

    let isComputedDataInvoicesStoreSubscriptionDefined: boolean = false
    // NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
    ComputedDataInvoicesStore.subscribe(newComputedDataInvoiceList => {
      if (!isComputedDataInvoicesStoreSubscriptionDefined) {
        /* console.log('ComputedDataInvoicesStore subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently') */
        isComputedDataInvoicesStoreSubscriptionDefined = true

        return // we avoid the .subscribe() execution at the subscription occurrence
      }

      if (newComputedDataInvoiceList) {

        const oldComputedDataInvoiceList = JSON.parse(localStorage.getItem('ComputedDataInvoicesStore'))
        const oldComputedDataInvoiceListHash = encrypt256Hash(oldComputedDataInvoiceList)
        const newComputedDataInvoiceListHash = encrypt256Hash(newComputedDataInvoiceList)

        if (oldComputedDataInvoiceListHash !== newComputedDataInvoiceListHash) {
          oldComputedDataInvoiceList.forEach(oldComputedDataInvoice => {
            const newComputedDataInvoice = newComputedDataInvoiceList.find(invoice => invoice.invoiceDataId === oldComputedDataInvoice.invoiceDataId)
            if (newComputedDataInvoice) {
              const oldComputedDataInvoiceHash = encrypt256Hash(oldComputedDataInvoice)
              const newComputedDataInvoiceHash = encrypt256Hash(newComputedDataInvoice)
              if (oldComputedDataInvoiceHash !== newComputedDataInvoiceHash) {
                eventsManager.emit(EventType.COMPUTED_INVOICE_CHANGED, newComputedDataInvoice, 'ComputedDataInvoicesStore')
                Object.keys(newComputedDataInvoice).forEach(k => {
                  if (newComputedDataInvoice[k] !== oldComputedDataInvoice[k]) {
                    eventsManager.emit(EventType.COMPUTED_INVOICE_PROPERTY_CHANGED, newComputedDataInvoice[k], 'ComputedDataInvoicesStore')
                  }
                })
              }
            }
          })
          newComputedDataInvoiceList.forEach(newComputedDataInvoice => {
            const oldComputedDataInvoice = oldComputedDataInvoiceList.find(invoice => invoice.invoiceDataId === newComputedDataInvoice.invoiceDataId)
            if (!oldComputedDataInvoice) {
              eventsManager.emit(EventType.COMPUTED_INVOICE_CREATED, newComputedDataInvoice, 'ComputedDataInvoicesStore')
            }
          })
          localStorage.setItem('ComputedDataInvoicesStore', JSON.stringify(newComputedDataInvoiceList, null, 4))
          eventsManager.emit(EventType.COMPUTED_INVOICE_LIST_CHANGED, newComputedDataInvoiceList, 'ComputedDataInvoicesStore')
        }
      }
    })

    /* console.log('%c ComputedDataInvoicesStore initialized.', 'color: #8A99AC') */
  }
}

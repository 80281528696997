import { BusinessDocumentKind } from '../enums/business-document-kind'
import {
  BusinessDocument,
  BusinessDocumentRelation,
  CoreDocument,
  InstallmentComputationKind
} from '../models/business-document'
import { t } from '../../core-app/lib/i18n/i18nextWrapper'
import { formatCurrencyWithOptions } from '../utils/number-formatting-utils'
import { TaxonomyTag } from '../enums/taxonomy-tag'
import { BusinessDocumentRelationKind } from '../enums/business-document-relation-kind'
import type { NumberingScheme, NumberingSchemeSettings } from '../models/voxy-preferences'
import { get } from 'svelte/store'
import { VoxyPreferencesStore } from '../stores/voxy-preference.store'
import {
  deleteDoubleAccoladesAndReplacePlaceholdersWithDateValues,
  replaceDefaultFirstLetterOfStringFromServerWithTranslatedOne,
  replaceNumberOfDigitsWithXs
} from '../utils/numbering-utils'
import { getBusinessDocumentWithoutCollateralFromStoreById } from '../helpers/business-document-data-passthrough'
import { DocumentSourceKind } from '../../order-to-cash-lib/models/document-source-kind'
import { sortRelatedDocumentsByDate } from './business-document-relation/business-document-relation-sorting.service'

/**
 * Get Business Document Main Title - v2
 * uses the businessDocumentKind as a key to access the title string in a predefined object.
 * If the businessDocumentKind is not found, it defaults to 'documentPreviewAndPDF' or 'installmentDocumentPreviewAndPDF' based on the hasSpecificInstallment flag.
 * @param businessDocumentKind
 * @param taxonomyTags
 * @param hasSpecificInstallment
 */
export const getBusinessDocumentMainTitle = (businessDocumentKind: BusinessDocumentKind, taxonomyTags: TaxonomyTag[], hasSpecificInstallment: boolean): string => {
  let finalDocumentMainTitle: string = ''

  // no specific Installment - not a partial business document
  if (!hasSpecificInstallment) {
    switch (businessDocumentKind) {
      case BusinessDocumentKind.INVOICE:
        if (!!taxonomyTags && taxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1) {
          finalDocumentMainTitle = t('invoices.businessDocument.mainHeaderTitle.commercialInvoicePreviewAndPDF')
          break
        } else if (!!taxonomyTags && taxonomyTags.indexOf(TaxonomyTag.CORRECTIVE_INVOICE) !== -1) {
          finalDocumentMainTitle = t('invoices.businessDocument.mainHeaderTitle.correctiveInvoicePreviewAndPDF')
          break
        } else {
          finalDocumentMainTitle = t('invoices.businessDocument.mainHeaderTitle.invoicePreviewAndPDF')
          break
        }
      case BusinessDocumentKind.CREDITNOTE:
        if (!!taxonomyTags && taxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1) {
          finalDocumentMainTitle = t('invoices.businessDocument.mainHeaderTitle.commercialCreditNotePreviewAndPDF')
          break
        } else if (!!taxonomyTags && taxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1) {
          finalDocumentMainTitle = t('invoices.businessDocument.mainHeaderTitle.correctiveCreditNotePreviewAndPDF')
          break
        } else {
          finalDocumentMainTitle = t('invoices.businessDocument.mainHeaderTitle.creditNotePreviewAndPDF')
          break
        }
      case BusinessDocumentKind.QUOTE:
        finalDocumentMainTitle = t('invoices.businessDocument.mainHeaderTitle.quotePreviewAndPDF')
        break
      case BusinessDocumentKind.PURCHASEORDER:
        finalDocumentMainTitle = t('invoices.businessDocument.mainHeaderTitle.purchaseOrderPreviewAndPDF')
        break
      default:
        finalDocumentMainTitle = t('invoices.businessDocument.mainHeaderTitle.documentPreviewAndPDF')
        break
    }
  } else {
    // with specific Installment - is a partial business document
    switch (businessDocumentKind) {
      case BusinessDocumentKind.INVOICE:
        if (!!taxonomyTags && taxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1) {
          let title: string = 'invoices.businessDocument.mainHeaderTitle.commercialInstallmentInvoicePreviewAndPDF'

          const type: TaxonomyTag = taxonomyTags[taxonomyTags.length - 1]
          switch (type) {
            case TaxonomyTag.BALANCE_INVOICE:
              title = 'invoices.businessDocument.mainHeaderTitle.balanceInvoicePreviewAndPDF'
              break
            case TaxonomyTag.DEPOSIT_INVOICE:
              title = 'invoices.businessDocument.mainHeaderTitle.depositInvoicePreviewAndPDF'
              break
            case TaxonomyTag.INTERMEDIATE_INVOICE:
              title = 'invoices.businessDocument.mainHeaderTitle.intermediateInvoicePreviewAndPDF'
              break
          }

          finalDocumentMainTitle = t(title)
        } else if (!!taxonomyTags && taxonomyTags.indexOf(TaxonomyTag.CORRECTIVE_INVOICE) !== -1) {
          finalDocumentMainTitle = t('invoices.businessDocument.mainHeaderTitle.correctiveInstallmentInvoicePreviewAndPDF')
        } else {
          finalDocumentMainTitle = t('invoices.businessDocument.mainHeaderTitle.installmentInvoicePreviewAndPDF')
        }
        break
      case BusinessDocumentKind.CREDITNOTE:
        if (!!taxonomyTags && taxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1) {
          finalDocumentMainTitle = t('invoices.businessDocument.mainHeaderTitle.commercialInstallmentCreditNotePreviewAndPDF')
          break
        } else if (!!taxonomyTags && taxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1) {
          finalDocumentMainTitle = t('invoices.businessDocument.mainHeaderTitle.correctiveInstallmentCreditNotePreviewAndPDF')
          break
        } else {
          finalDocumentMainTitle = t('invoices.businessDocument.mainHeaderTitle.installmentCreditNotePreviewAndPDF')
          break
        }
      case BusinessDocumentKind.QUOTE:
      // finalDocumentMainTitle = t('invoices.createInvoice.installmentInvoiceLabel');
        finalDocumentMainTitle = t('invoices.businessDocument.mainHeaderTitle.installmentQuotePreviewAndPDF')
        break
      case BusinessDocumentKind.PURCHASEORDER:
      // finalDocumentMainTitle = t('invoices.createInvoice.installmentInvoiceLabel');
        finalDocumentMainTitle = t('invoices.businessDocument.mainHeaderTitle.installmentPurchaseOrderPreviewAndPDF')
        break
      default:
        finalDocumentMainTitle = t('invoices.businessDocument.mainHeaderTitle.installmentDocumentPreviewAndPDF')
        break
    }
  }

  /* console.log('getBusinessDocumentUpdatedInstallmentTaxonomyTags getBusinessDocumentMainTitle finalDocumentMainTitle=', finalDocumentMainTitle) */
  
  return finalDocumentMainTitle
}


/**
 * Get the relation kind string
 * Used in getBusinessDocumentSubjectMainTitleMainDescriptionRelatedDocumentsListDescription()
 * @param relationKind
 * @param fromBusinessDocumentKind
 * @param toBusinessDocumentKind
 * @param fromTaxonomyTags
 * @param toTaxonomyTags
 * @param replacements
 * @param hasSpecificInstallment
 */
export function getRelationKindString(relationKind: BusinessDocumentRelationKind, fromBusinessDocumentKind: BusinessDocumentKind, toBusinessDocumentKind: BusinessDocumentKind, fromTaxonomyTags: TaxonomyTag[], toTaxonomyTags: TaxonomyTag[], replacements: any, hasSpecificInstallment: boolean): string {
  /* console.log('getRelationKindString relationKind case', 'relationKind', relationKind, 'hasSpecificInstallment', hasSpecificInstallment, 'fromBusinessDocumentKind', fromBusinessDocumentKind, 'fromTaxonomyTags', fromTaxonomyTags, 'toBusinessDocumentKind', toBusinessDocumentKind, 'toTaxonomyTags', toTaxonomyTags) */
  let suffix: string = ''
  // suffix = " [" + relationKind + "]";
  let postSuffix: string = ''
  let resultingRelationDescriptionString: string = ''
  if (hasSpecificInstallment) {
    switch (relationKind) {
      case BusinessDocumentRelationKind.QUOTE_INVOICING:
        if (!!toTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1) {
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCommercialQuoteInvoicing', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.CORRECTIVE_INVOICE) !== -1) {
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCorrectiveQuoteInvoicing', replacements) + suffix + postSuffix
        } else {
        // postSuffix = " default 1";
          /* console.error('relationKind unknown case 1', 'relationKind', relationKind, 'hasSpecificInstallment', hasSpecificInstallment, 'fromBusinessDocumentKind', fromBusinessDocumentKind, 'fromTaxonomyTags', fromTaxonomyTags, 'toBusinessDocumentKind', toBusinessDocumentKind, 'toTaxonomyTags', toTaxonomyTags) */
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialQuoteInvoicing', replacements) + suffix + postSuffix
        }
        break
      case BusinessDocumentRelationKind.INVOICE_DUPLICATION:
        resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialInvoiceDuplication', replacements) + suffix + postSuffix
        break
      case BusinessDocumentRelationKind.VOIDING_NOT_IMPLEMENTED:
        resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialInvoiceVoiding', replacements) + suffix + postSuffix
        break
      case BusinessDocumentRelationKind.CREDIT_NOTE_ON_CREDIT_NOTE: // we create a Credit Note based upon a Credit Note - from a Credit Note to a Credit Note
        if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1) {
        // Kind: CREDIT_NOTE_ON_CREDIT_NOTE - FROM tag COMMERCIAL_CREDIT_NOTE - TO tag COMMERCIAL_CREDIT_NOTE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCommercialCreditNoteOnCommercialInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1) {
        // Kind: CREDIT_NOTE_ON_CREDIT_NOTE - FROM tag VOIDING_CREDIT_NOTE - TO tag COMMERCIAL_CREDIT_NOTE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCommercialCreditNoteOnCorrectiveInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1) {
        // Kind: CREDIT_NOTE_ON_CREDIT_NOTE - FROM any other Credit Note tag - TO tag COMMERCIAL_CREDIT_NOTE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCommercialCreditNoteOnInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1) {
        // Kind: CREDIT_NOTE_ON_CREDIT_NOTE - FROM tag VOIDING_CREDIT_NOTE - TO tag VOIDING_CREDIT_NOTE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialVoidingCreditNoteOnCommercialInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1) {
        // Kind: CREDIT_NOTE_ON_CREDIT_NOTE - FROM tag COMMERCIAL_CREDIT_NOTE - TO tag VOIDING_CREDIT_NOTE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialVoidingCreditNoteOnCorrectiveInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1) {
        // Kind: CREDIT_NOTE_ON_CREDIT_NOTE - FROM any other Credit Note - TO tag VOIDING_CREDIT_NOTE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialVoidingCreditNoteOnInvoice', replacements) + suffix + postSuffix
        } else {
        // postSuffix = " default 2";
          /* console.error('relationKind unknown case 2', 'relationKind', relationKind, 'hasSpecificInstallment', hasSpecificInstallment, 'fromBusinessDocumentKind', fromBusinessDocumentKind, 'fromTaxonomyTags', fromTaxonomyTags, 'toBusinessDocumentKind', toBusinessDocumentKind, 'toTaxonomyTags', toTaxonomyTags) */
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCreditNoteOnInvoice', replacements) + suffix + postSuffix
        }
        break
      case BusinessDocumentRelationKind.CREDIT_NOTE_ON_INVOICE: // we create a Credit Note based upon an Invoice - from an Invoice to a Credit Note
        if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1) {
        // Kind: CREDIT_NOTE_ON_INVOICE - FROM tag COMMERCIAL_INVOICE - TO tag COMMERCIAL_CREDIT_NOTE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCommercialCreditNoteOnCommercialInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.CORRECTIVE_INVOICE) !== -1) {
        // Kind: CREDIT_NOTE_ON_INVOICE - FROM tag CORRECTIVE_INVOICE - TO tag COMMERCIAL_CREDIT_NOTE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCommercialCreditNoteOnCorrectiveInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1) {
        // Kind: CREDIT_NOTE_ON_INVOICE - FROM any other Invoice tag - TO tag COMMERCIAL_CREDIT_NOTE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCommercialCreditNoteOnInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.DEPOSIT_INVOICE) !== -1) {
        // Kind: CREDIT_NOTE_ON_INVOICE - FROM tag DEPOSIT_INVOICE && COMMERCIAL_INVOICE - TO tag VOIDING_CREDIT_NOTE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialVoidingCreditNoteOnDepositCommercialInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.INTERMEDIATE_INVOICE) !== -1) {
        // Kind: CREDIT_NOTE_ON_INVOICE - FROM tag BALANCE_INVOICE && INTERMEDIATE_INVOICE && COMMERCIAL_INVOICE - TO tag VOIDING_CREDIT_NOTE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialVoidingCreditNoteOnIntermediateCommercialInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.BALANCE_INVOICE) !== -1) {
        // Kind: CREDIT_NOTE_ON_INVOICE - FROM tag COMMERCIAL_INVOICE - TO tag VOIDING_CREDIT_NOTE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialVoidingCreditNoteOnBalanceCommercialInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1) {
        // Kind: CREDIT_NOTE_ON_INVOICE - FROM tag COMMERCIAL_INVOICE - TO tag VOIDING_CREDIT_NOTE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialVoidingCreditNoteOnCommercialInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.CORRECTIVE_INVOICE) !== -1) {
        // Kind: CREDIT_NOTE_ON_INVOICE - FROM tag CORRECTIVE_INVOICE - TO tag VOIDING_CREDIT_NOTE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialVoidingCreditNoteOnCorrectiveInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1) {
        // Kind: CREDIT_NOTE_ON_INVOICE - FROM any other Invoice tag - TO tag VOIDING_CREDIT_NOTE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialVoidingCreditNoteOnInvoice', replacements) + suffix + postSuffix
        } else {
        // postSuffix = " default 3";
          /* console.error('relationKind unknown case 3', 'relationKind', relationKind, 'hasSpecificInstallment', hasSpecificInstallment, 'fromBusinessDocumentKind', fromBusinessDocumentKind, 'fromTaxonomyTags', fromTaxonomyTags, 'toBusinessDocumentKind', toBusinessDocumentKind, 'toTaxonomyTags', toTaxonomyTags) */
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCreditNoteOnInvoice', replacements) + suffix + postSuffix
        }
        break
      case BusinessDocumentRelationKind.INVOICE_ON_CREDIT_NOTE: // we create an Invoice based upon a Credit Note - from a Credit Note to an Invoice
        if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1 && toTaxonomyTags.indexOf(TaxonomyTag.DEPOSIT_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1) {
        // Kind: INVOICE_ON_CREDIT_NOTE - FROM tag COMMERCIAL_CREDIT_NOTE - TO tag COMMERCIAL_INVOICE && DEPOSIT_INVOICE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCommercialDepositInvoiceOnCommercialCreditNote', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1 && toTaxonomyTags.indexOf(TaxonomyTag.INTERMEDIATE_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1) {
        // Kind: INVOICE_ON_CREDIT_NOTE - FROM tag COMMERCIAL_CREDIT_NOTE - TO tag COMMERCIAL_INVOICE && INTERMEDIATE_INVOICE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCommercialIntermediateInvoiceOnCommercialCreditNote', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1 && toTaxonomyTags.indexOf(TaxonomyTag.BALANCE_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1) {
        // Kind: INVOICE_ON_CREDIT_NOTE - FROM tag COMMERCIAL_CREDIT_NOTE - TO tag COMMERCIAL_INVOICE && BALANCE_INVOICE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCommercialBalanceInvoiceOnCommercialCreditNote', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1) {
        // Kind: INVOICE_ON_CREDIT_NOTE - FROM tag COMMERCIAL_CREDIT_NOTE - TO tag COMMERCIAL_INVOICE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCommercialInvoiceOnCommercialCreditNote', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1 && toTaxonomyTags.indexOf(TaxonomyTag.DEPOSIT_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1) {
        // Kind: INVOICE_ON_CREDIT_NOTE - FROM tag VOIDING_CREDIT_NOTE - TO tag COMMERCIAL_INVOICE && DEPOSIT_INVOICE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCommercialDepositInvoiceOnCorrectiveCreditNote', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1 && toTaxonomyTags.indexOf(TaxonomyTag.INTERMEDIATE_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1) {
        // Kind: INVOICE_ON_CREDIT_NOTE - FROM tag VOIDING_CREDIT_NOTE - TO tag COMMERCIAL_INVOICE && INTERMEDIATE_INVOICE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCommercialIntermediateInvoiceOnCorrectiveCreditNote', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1 && toTaxonomyTags.indexOf(TaxonomyTag.BALANCE_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1) {
        // Kind: INVOICE_ON_CREDIT_NOTE - FROM tag VOIDING_CREDIT_NOTE - TO tag COMMERCIAL_INVOICE && BALANCE_INVOICE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCommercialBalanceInvoiceOnCorrectiveCreditNote', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1) {
        // Kind: INVOICE_ON_CREDIT_NOTE - FROM tag VOIDING_CREDIT_NOTE - TO tag COMMERCIAL_INVOICE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCommercialInvoiceOnCorrectiveCreditNote', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1) { // other cases of "from credit note" than [COMMERCIAL_CREDIT_NOTE, VOIDING_CREDIT_NOTE]
        // Kind: INVOICE_ON_CREDIT_NOTE - FROM any other Credit Note tag - TO tag COMMERCIAL_INVOICE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCommercialInvoiceOnCreditNote', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.CORRECTIVE_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1) {
        // Kind: INVOICE_ON_CREDIT_NOTE - FROM tag COMMERCIAL_CREDIT_NOTE - TO tag CORRECTIVE_INVOICE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCorrectiveInvoiceOnCommercialCreditNote', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.CORRECTIVE_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1) {
        // Kind: INVOICE_ON_CREDIT_NOTE - FROM tag VOIDING_CREDIT_NOTE - TO tag CORRECTIVE_INVOICE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCorrectiveInvoiceOnCorrectiveCreditNote', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.CORRECTIVE_INVOICE) !== -1) {
        // Kind: INVOICE_ON_CREDIT_NOTE - FROM any other Credit Note tag - TO tag CORRECTIVE_INVOICE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialCorrectiveInvoiceOnCreditNote', replacements) + suffix + postSuffix
        } else {
        // postSuffix = " default 4";
          /* console.error('relationKind unknown case 4', 'relationKind', relationKind, 'hasSpecificInstallment', hasSpecificInstallment, 'fromBusinessDocumentKind', fromBusinessDocumentKind, 'fromTaxonomyTags', fromTaxonomyTags, 'toBusinessDocumentKind', toBusinessDocumentKind, 'toTaxonomyTags', toTaxonomyTags) */
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialInvoiceOnCreditNote', replacements) + suffix + postSuffix
        }
        break
      case BusinessDocumentRelationKind.INVOICE_ON_INVOICE: // we create an Invoice based upon an Invoice - from an Invoice to an Invoice
        if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.INTERMEDIATE_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.DEPOSIT_INVOICE) !== -1) {
        // Kind: INVOICE_ON_INVOICE - FROM tag DEPOSIT_INVOICE - TO tag INTERMEDIATE_INVOICE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.intermediateInvoiceAfterDepositInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.INTERMEDIATE_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.INTERMEDIATE_INVOICE) !== -1) {
        // Kind: INVOICE_ON_INVOICE - FROM tag INTERMEDIATE_INVOICE - TO tag INTERMEDIATE_INVOICE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.intermediateInvoiceAfterIntermediateInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.BALANCE_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.INTERMEDIATE_INVOICE) !== -1) {
        // Kind: INVOICE_ON_INVOICE - FROM tag INTERMEDIATE_INVOICE - TO tag BALANCE_INVOICE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.balanceInvoiceAfterIntermediateInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.BALANCE_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.DEPOSIT_INVOICE) !== -1) {
        // Kind: INVOICE_ON_INVOICE - FROM tag DEPOSIT_INVOICE - TO tag BALANCE_INVOICE
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.balanceInvoiceAfterDepositInvoice', replacements) + suffix + postSuffix
        } else {
        // postSuffix = " default 5";
          /* console.error('relationKind unknown case 5', 'relationKind', relationKind, 'hasSpecificInstallment', hasSpecificInstallment, 'fromBusinessDocumentKind', fromBusinessDocumentKind, 'fromTaxonomyTags', fromTaxonomyTags, 'toBusinessDocumentKind', toBusinessDocumentKind, 'toTaxonomyTags', toTaxonomyTags) */
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.partialInvoiceOnInvoice', replacements) + suffix + postSuffix
        }
        break
      default:
        /* console.error('relationKind unknown kind case 6', 'relationKind', relationKind, 'hasSpecificInstallment', hasSpecificInstallment, 'fromBusinessDocumentKind', fromBusinessDocumentKind, 'fromTaxonomyTags', fromTaxonomyTags, 'toBusinessDocumentKind', toBusinessDocumentKind, 'toTaxonomyTags', toTaxonomyTags) */
        // postSuffix = " default 6";
        resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.other', replacements) + suffix + postSuffix
        break
    }
  } else {
    switch (relationKind) {
      case BusinessDocumentRelationKind.QUOTE_INVOICING:
        if (!!toTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1) {
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.commercialQuoteInvoicing', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.CORRECTIVE_INVOICE) !== -1) {
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.correctiveQuoteInvoicing', replacements) + suffix + postSuffix
        } else {
        // postSuffix = " default 7";
          /* console.error('relationKind unknown case 7', 'relationKind', relationKind, 'hasSpecificInstallment', hasSpecificInstallment, 'fromBusinessDocumentKind', fromBusinessDocumentKind, 'fromTaxonomyTags', fromTaxonomyTags, 'toBusinessDocumentKind', toBusinessDocumentKind, 'toTaxonomyTags', toTaxonomyTags) */
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.quoteInvoicing', replacements) + suffix + postSuffix
        }
        break
      case BusinessDocumentRelationKind.INVOICE_DUPLICATION:
        resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.invoiceDuplication', replacements) + suffix + postSuffix
        break
      case BusinessDocumentRelationKind.VOIDING_NOT_IMPLEMENTED:
        resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.invoiceVoiding', replacements) + suffix + postSuffix
        break
      case BusinessDocumentRelationKind.CREDIT_NOTE_ON_INVOICE:
        if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1) {
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.commercialCreditNoteOnCommercialInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.CORRECTIVE_INVOICE) !== -1) {
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.commercialCreditNoteOnCorrectiveInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1) {
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.commercialCreditNoteOnInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1) {
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.voidingCreditNoteOnCommercialInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.CORRECTIVE_INVOICE) !== -1) {
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.voidingCreditNoteOnCorrectiveInvoice', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1) {
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.voidingCreditNoteOnInvoice', replacements) + suffix + postSuffix
        } else {
        // postSuffix = " default 8";
          /* console.error('relationKind unknown case 8', 'relationKind', relationKind, 'hasSpecificInstallment', hasSpecificInstallment, 'fromBusinessDocumentKind', fromBusinessDocumentKind, 'fromTaxonomyTags', fromTaxonomyTags, 'toBusinessDocumentKind', toBusinessDocumentKind, 'toTaxonomyTags', toTaxonomyTags) */
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.creditNoteOnInvoice', replacements) + suffix + postSuffix
        }
        break
      case BusinessDocumentRelationKind.INVOICE_ON_CREDIT_NOTE:
        if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1) {
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.commercialInvoiceOnCommercialCreditNote', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1) {
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.commercialInvoiceOnCorrectiveCreditNote', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1) {
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.commercialInvoiceOnCreditNote', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.CORRECTIVE_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1) {
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.correctiveInvoiceOnCommercialCreditNote', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && !!fromTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.CORRECTIVE_INVOICE) !== -1 && fromTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1) {
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.correctiveInvoiceOnCorrectiveCreditNote', replacements) + suffix + postSuffix
        } else if (!!toTaxonomyTags && toTaxonomyTags.indexOf(TaxonomyTag.CORRECTIVE_INVOICE) !== -1) {
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.correctiveInvoiceOnCreditNote', replacements) + suffix + postSuffix
        } else {
        // postSuffix = " default 9";
          /* console.error('relationKind unknown case 9', 'relationKind', relationKind, 'hasSpecificInstallment', hasSpecificInstallment, 'fromBusinessDocumentKind', fromBusinessDocumentKind, 'fromTaxonomyTags', fromTaxonomyTags, 'toBusinessDocumentKind', toBusinessDocumentKind, 'toTaxonomyTags', toTaxonomyTags) */
          resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.invoiceOnCreditNote', replacements) + suffix + postSuffix
        }
        break
      default:
      // postSuffix = " default 10";
        /* console.error('relationKind unknown kind case 10', 'relationKind', relationKind, 'hasSpecificInstallment', hasSpecificInstallment, 'fromBusinessDocumentKind', fromBusinessDocumentKind, 'fromTaxonomyTags', fromTaxonomyTags, 'toBusinessDocumentKind', toBusinessDocumentKind, 'toTaxonomyTags', toTaxonomyTags) */
        resultingRelationDescriptionString = t('invoices.businessDocument.businessDocumentRelationKind.other', replacements) + suffix + postSuffix
        break
    }
  }
  /* console.warn('relationKind resultingRelationDescriptionString', resultingRelationDescriptionString) */
  
  return resultingRelationDescriptionString
}


/**
 * Returns the designation of the business document kind
 * @param businessDocumentKind
 */
export function getBusinessDocumentKindDesignation(businessDocumentKind: BusinessDocumentKind): string {
  switch (businessDocumentKind) {
  //TODO have the label depend on whether there is an installment or not (as it changes the way to designate the business document: requires to have more details on the related BusinessDocument)
    case BusinessDocumentKind.QUOTE:
      return t('invoices.businessDocument.kindName.quoteKindName')
    case BusinessDocumentKind.INVOICE:
      return t('invoices.businessDocument.kindName.invoiceKindName')
    case BusinessDocumentKind.PURCHASEORDER:
      return t('invoices.businessDocument.kindName.purchaseOrderKindName')
    case BusinessDocumentKind.CREDITNOTE:
      return t('invoices.businessDocument.kindName.creditNoteKindName')
    default:
      return t('invoices.businessDocument.kindName.documentKindName')
  }
}



import type { TaxRate } from '../models/tax-rate'
import {
  AmountOfTaxesWithExcludingAndIncludingDecimal,
  TaxesAmountFromExcludingTaxesCalculusMethod
} from '../models/tax-rate'
import { Decimal } from 'decimal.js'
import { NewAmountOfMoneyWithDecimal } from '../../cash-application-app/models/amount-of-money-decimal-model'

export function roundScaledAmountToTheCentDecimal(amountScaledValue: Decimal): Decimal {
  // NB: we are using .toInteger() instead of .round()
  // because we try to use decimal.js-light (where .round() is missing)
  // instead of the full API decimal.js (where .round() is available)
  return amountScaledValue.mul(100).round().div(100)
}

/**
 * determine a coherent Decimal triplet (amountExcludingTaxes ; taxesAmount ; amountIncludingTaxes)
 */
export function getAmountIncludingTaxesAndTaxesAmountFromAmountExcludingTaxesAndTaxRateUsingDecimal(amountScaledExcludingTaxes: Decimal, taxRate: TaxRate, currencyCode: string, taxesCalculus: TaxesAmountFromExcludingTaxesCalculusMethod): AmountOfTaxesWithExcludingAndIncludingDecimal {
  switch (taxesCalculus) {
    case TaxesAmountFromExcludingTaxesCalculusMethod.Basic:
      return <AmountOfTaxesWithExcludingAndIncludingDecimal>{
        amountExcludingTaxes: NewAmountOfMoneyWithDecimal(amountScaledExcludingTaxes, new Decimal(2), currencyCode),
        amountIncludingTaxes: NewAmountOfMoneyWithDecimal(amountScaledExcludingTaxes.mul((new Decimal(1)).plus(new Decimal(taxRate.percentage).div(new Decimal(100)))), new Decimal(2), currencyCode),
        taxesAmount: NewAmountOfMoneyWithDecimal(amountScaledExcludingTaxes.mul((new Decimal(1)).plus(new Decimal(taxRate.percentage).div(new Decimal(100)))).minus(amountScaledExcludingTaxes), new Decimal(2), currencyCode)
      }
    case TaxesAmountFromExcludingTaxesCalculusMethod.RoundedAmounts: {
      const roundedAmountExcludingTaxes = roundScaledAmountToTheCentDecimal(amountScaledExcludingTaxes)
      const roundedAmountIncludingTaxes = roundScaledAmountToTheCentDecimal(roundedAmountExcludingTaxes.mul((new Decimal(1)).plus(new Decimal(taxRate.percentage).div(new Decimal(100)))))
      const roundedTaxesAmount = roundScaledAmountToTheCentDecimal(roundedAmountIncludingTaxes.minus(roundedAmountExcludingTaxes))
      
      return <AmountOfTaxesWithExcludingAndIncludingDecimal>{
        amountExcludingTaxes: NewAmountOfMoneyWithDecimal(roundedAmountExcludingTaxes, new Decimal(2), currencyCode),
        amountIncludingTaxes: NewAmountOfMoneyWithDecimal(roundedAmountIncludingTaxes, new Decimal(2), currencyCode),
        taxesAmount: NewAmountOfMoneyWithDecimal(roundedTaxesAmount, new Decimal(2), currencyCode)
      }
    }
    case TaxesAmountFromExcludingTaxesCalculusMethod.RoundedAmountsAndTaxesAdjustmentByOneCent: {
      const roundedAmountExcludingTaxes = roundScaledAmountToTheCentDecimal(amountScaledExcludingTaxes)
      const roundedAmountIncludingTaxes = roundScaledAmountToTheCentDecimal(roundedAmountExcludingTaxes.mul((new Decimal(1)).plus(new Decimal(taxRate.percentage).div(new Decimal(100)))))
      let roundedTaxesAmount = roundScaledAmountToTheCentDecimal(roundedAmountIncludingTaxes.minus(roundedAmountExcludingTaxes))
      if (roundScaledAmountToTheCentDecimal(roundedAmountExcludingTaxes.plus(roundedTaxesAmount)).lessThan(roundedAmountIncludingTaxes)) {
        roundedTaxesAmount = roundedTaxesAmount.add(new Decimal(0.01))
      }
      if (roundScaledAmountToTheCentDecimal(roundedAmountExcludingTaxes.plus(roundedTaxesAmount)).greaterThan(roundedAmountIncludingTaxes)) {
        roundedTaxesAmount = roundedTaxesAmount.minus(new Decimal(0.01))
      }
      
      return <AmountOfTaxesWithExcludingAndIncludingDecimal>{
        amountExcludingTaxes: NewAmountOfMoneyWithDecimal(roundedAmountExcludingTaxes, new Decimal(2), currencyCode),
        amountIncludingTaxes: NewAmountOfMoneyWithDecimal(roundedAmountIncludingTaxes, new Decimal(2), currencyCode),
        taxesAmount: NewAmountOfMoneyWithDecimal(roundedTaxesAmount, new Decimal(2), currencyCode)
      }
    }
    case TaxesAmountFromExcludingTaxesCalculusMethod.RoundedAmountsAndTaxesAdjustmentByOneCentWithCorrectedRound: {
      const roundedAmountExcludingTaxes = roundScaledAmountToTheCentDecimal(amountScaledExcludingTaxes)
      const roundedAmountIncludingTaxes = roundScaledAmountToTheCentDecimal(roundedAmountExcludingTaxes.mul((new Decimal(1)).plus(new Decimal(taxRate.percentage).div(new Decimal(100)))))
      let roundedTaxesAmount = roundScaledAmountToTheCentDecimal(roundedAmountIncludingTaxes.minus(roundedAmountExcludingTaxes))
      if (roundScaledAmountToTheCentDecimal(roundedAmountExcludingTaxes.plus(roundedTaxesAmount)).lessThan(roundedAmountIncludingTaxes)) {
        roundedTaxesAmount = roundedTaxesAmount.add(new Decimal(0.01))
      }
      if (roundScaledAmountToTheCentDecimal(roundedAmountExcludingTaxes.plus(roundedTaxesAmount)).greaterThan(roundedAmountIncludingTaxes)) {
        roundedTaxesAmount = roundedTaxesAmount.minus(new Decimal(0.01))
      }
      
      return <AmountOfTaxesWithExcludingAndIncludingDecimal>{
        amountExcludingTaxes: NewAmountOfMoneyWithDecimal(roundedAmountExcludingTaxes, new Decimal(2), currencyCode),
        amountIncludingTaxes: NewAmountOfMoneyWithDecimal(roundedAmountIncludingTaxes, new Decimal(2), currencyCode),
        taxesAmount: NewAmountOfMoneyWithDecimal(roundedTaxesAmount, new Decimal(2), currencyCode)
      }
    }
    default:
      throw new Error('unknown specified taxesCalculus=\'' + taxesCalculus + '\'')
  }
}

/*

/!**
 *
 * @param originalAmountAndTaxes
 * @param percentToKeepFromOriginalAmount
 *!/
export function calculateInstallmentChangeResultingValuesFromPercentInputChange(originalAmountAndTaxes: AmountOfTaxesWithExcludingAndIncludingDecimal, percentToKeepFromOriginalAmount: Decimal): InstallmentChangeResultingValues {
    const amountScaledExcludingTaxes: Decimal = roundScaledAmountToTheCentDecimal(originalAmountAndTaxes.amountExcludingTaxes.GetAmountDecimalScaledValue().mul(percentToKeepFromOriginalAmount).div(100));
    const amountScaledIncludingTaxes: Decimal = roundScaledAmountToTheCentDecimal(originalAmountAndTaxes.amountIncludingTaxes.GetAmountDecimalScaledValue().mul(percentToKeepFromOriginalAmount).div(100));
    // const taxesScaledAmountFromRatio: Decimal = roundScaledAmountToTheCentDecimal(originalAmountAndTaxes.taxesAmount.GetAmountDecimalScaledValue().mul(percentToKeepFromOriginalAmount).div(100));
    // const taxesScaledAmountFromDifference: Decimal = roundScaledAmountToTheCentDecimal(amountScaledIncludingTaxes.minus(amountScaledExcludingTaxes));
    // if (!taxesScaledAmountFromRatio.eq(taxesScaledAmountFromDifference)) {
    //     const originalAmountAndTaxesJSON: string = JSON.stringify(originalAmountAndTaxes, null, 3);
    //     const error: string = "taxesScaledAmountFromRatio='" + taxesScaledAmountFromRatio.toString() + "' is different from taxesScaledAmountFromDifference='"
    //         + taxesScaledAmountFromDifference.toString() + "' with originalAmountAndTaxes=" + originalAmountAndTaxesJSON
    //         + " and percentToKeepFromOriginalAmount='" + percentToKeepFromOriginalAmount.toString() + "'";
    
    //     throw new Error(error);
    // }
    const taxesScaledAmount: Decimal = roundScaledAmountToTheCentDecimal(amountScaledIncludingTaxes.minus(amountScaledExcludingTaxes));
    const resultingAmountAndTaxes: AmountOfTaxesWithExcludingAndIncludingDecimal = <AmountOfTaxesWithExcludingAndIncludingDecimal>{
        amountExcludingTaxes: NewAmountOfMoneyWithDecimal(amountScaledExcludingTaxes, new Decimal(2), originalAmountAndTaxes.amountExcludingTaxes.currencyCode),
        amountIncludingTaxes: NewAmountOfMoneyWithDecimal(amountScaledIncludingTaxes, new Decimal(2), originalAmountAndTaxes.amountIncludingTaxes.currencyCode),
        taxesAmount: NewAmountOfMoneyWithDecimal(taxesScaledAmount, new Decimal(2), originalAmountAndTaxes.taxesAmount.currencyCode),
    };
    return {
        resultingInstallmentPercentageDecimal: percentToKeepFromOriginalAmount,
        resultingBusinessDocumentInstallmentAmountAndTaxes: resultingAmountAndTaxes,
        resultingInstallmentChosenKind: InstallmentComputationKind.PERCENT_OF_TOTAL_AMOUNT,
        resultingInstallmentChosenValue: percentToKeepFromOriginalAmount,
    }
}

export function calculateInstallmentChangeResultingValuesFromAbsoluteValueInputChange(
    originalAmountAndTaxes: AmountOfTaxesWithExcludingAndIncludingDecimal,
    targetScaledAmountIncludingTaxes: Decimal,
): InstallmentChangeResultingValues {
    const finalTargetScaledAmountIncludingTaxes: Decimal =
        roundScaledAmountToTheCentDecimal(targetScaledAmountIncludingTaxes);
    const ratioOriginalScaledAmountIncludingTaxesToFinalTargetScaledAmountIncludingTaxes: Decimal =
        finalTargetScaledAmountIncludingTaxes.div(originalAmountAndTaxes.amountIncludingTaxes.GetAmountDecimalScaledValue());
    const resultingTargetScaledAmountExcludingTaxes: Decimal =
        roundScaledAmountToTheCentDecimal(
            originalAmountAndTaxes.amountExcludingTaxes.scaledValue.mul(ratioOriginalScaledAmountIncludingTaxesToFinalTargetScaledAmountIncludingTaxes)
        );
    const resultingTargetScaledTaxesAmount: Decimal =
        roundScaledAmountToTheCentDecimal(
            finalTargetScaledAmountIncludingTaxes.minus(resultingTargetScaledAmountExcludingTaxes)
        );
    const finalPercent: Decimal =
        roundScaledAmountToTheCentDecimal(
            ratioOriginalScaledAmountIncludingTaxesToFinalTargetScaledAmountIncludingTaxes.mul(100 * 100)
        ).div(100);
    const resultingAmountAndTaxes: AmountOfTaxesWithExcludingAndIncludingDecimal = <AmountOfTaxesWithExcludingAndIncludingDecimal>{
        amountExcludingTaxes: NewAmountOfMoneyWithDecimal(resultingTargetScaledAmountExcludingTaxes, new Decimal(2), originalAmountAndTaxes.amountExcludingTaxes.currencyCode),
        amountIncludingTaxes: NewAmountOfMoneyWithDecimal(finalTargetScaledAmountIncludingTaxes, new Decimal(2), originalAmountAndTaxes.amountIncludingTaxes.currencyCode),
        taxesAmount: NewAmountOfMoneyWithDecimal(resultingTargetScaledTaxesAmount, new Decimal(2), originalAmountAndTaxes.taxesAmount.currencyCode),
    };
    return {
        resultingInstallmentPercentageDecimal: finalPercent,
        resultingBusinessDocumentInstallmentAmountAndTaxes: resultingAmountAndTaxes,
        resultingInstallmentChosenKind: InstallmentComputationKind.ABSOLUTE_AMOUNT_INCLUDING_TAX,
        resultingInstallmentChosenValue: resultingAmountAndTaxes.amountIncludingTaxes.GetAmountDecimalScaledValue(),
    }
}
*/

import { get, type Unsubscriber, type Writable, writable } from 'svelte/store'
import { Workspace } from '../models/workspace'
import { eventsManager } from '$core/events/event-manager'
import { EventType } from '$core/events/event-type'
import { encrypt256Hash } from '$core/util/encryption'
import initializationStore from '../../core-app/stores/initialization.store'
import type GlobalInitializationStore from '../../core-app/models/initialization-store'
import type { DundyEvent } from '$dundy/events/dundy-event'
import { ExchangeDate } from '$core/models/exchange-date'
import mixpanel from 'mixpanel-browser'
import { getCompaniesBySiret } from '$src/shared/services/company.service'
import CompanyLegalIdentification from '$crm/models/company-legal-identification'
import type Company from '$crm/models/company'

/* console.log('workspace.store.ts') */

let unsubscribeWorkspaceListStore: Unsubscriber

function getWorkspace(): Workspace {
  const existingValue: string = localStorage.getItem('WorkspaceStore')
  const emptyValue: Workspace = new Workspace()

  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  if (existingValue === '{}') {
    return emptyValue
  }

  return <Workspace>JSON.parse(existingValue)
}

export let WorkspaceStore: Writable<Workspace> = writable(getWorkspace())
export let unUnsubscribeWorkspaceStore: Unsubscriber

export const initializeWorkspaceStore = async (data: Workspace): Promise<void> => {

  if (!get(initializationStore).workspaceStoreInitialized) {

    initializationStore.update((store: GlobalInitializationStore) => {
      store.workspaceStoreInitialized = true

      return store
    })
    // un-subscribe first if needed
    // NB: needs to be done BEFORE the localStorage.setItem() AND before WorkspaceStore.set():
    // indeed, for the initialization, we need to set the store without triggering a previous potential subscribe
    /* console.log('unUnsubscribeWorkspaceStore in initializeWorkspaceStore()', unUnsubscribeWorkspaceStore) */
    if (!!unUnsubscribeWorkspaceStore) {
      /* console.log('---p!!!!!!--- vxp  unsubscribing !!!!! unUnsubscribeWorkspaceStore()') */
      unUnsubscribeWorkspaceStore()
    }

    localStorage.setItem('WorkspaceStore', JSON.stringify(data, null, 4))
    WorkspaceStore.set(data)
    mixpanel.track('BM35 Select New Workspace', {
      'Description': 'Choosing a new workspace',
      'workspaceId': data?.workspaceId,
      'workspaceCompany': data?.company?.formalName
    })
    mixpanel.register({
      'workspaceId': data?.workspaceId,
      'workspaceCompany': data?.company?.formalName
    })

    let isWorkspaceStoreSubscriptionDefined: boolean = false
    // NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
    unUnsubscribeWorkspaceStore = WorkspaceStore.subscribe((newWorkspace: Workspace) => {
      /*console.log('%c IN WorkspaceStore.subscribe, input newWorkspace %s', 'color:red;font-size: 1.5em;', JSON.stringify(newWorkspace, null, 3))*/
      if (!isWorkspaceStoreSubscriptionDefined) {
        /* console.log('WorkspaceStore subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently') */
        isWorkspaceStoreSubscriptionDefined = true
        /*console.log('%c IN WorkspaceStore.subscribe, !isWorkspaceStoreSubscriptionDefined', 'color:red;font-size: 1.5em;')*/

        return // we avoid the .subscribe() execution at the subscription occurrence
      }
      if (newWorkspace && newWorkspace.ownerId) {
        /*console.log('%c IN WorkspaceStore.subscribe, newWorkspace && newWorkspace.ownerId', 'color:green;font-size: 1.5em;')*/

        const oldWorkspace = JSON.parse(localStorage.getItem('WorkspaceStore') || "")
        const oldWorkspaceHash = encrypt256Hash(oldWorkspace)
        const newWorkspaceHash = encrypt256Hash(newWorkspace)

        if (oldWorkspaceHash !== newWorkspaceHash) {
          /*console.log('%c IN WorkspaceStore.subscribe, oldWorkspaceHash !== newWorkspaceHash', 'color:green;font-size: 1.5em;')*/
          if (JSON.stringify(oldWorkspace.teamUsersIds) !== JSON.stringify(newWorkspace.teamUsersIds)) {
            oldWorkspace.teamUsersIds.forEach((oldWorkspaceTeamUserId: string) => {
              if (!newWorkspace.teamUsersIds.find((newWorkspaceTeamUserId: string): boolean => oldWorkspaceTeamUserId === newWorkspaceTeamUserId)) {
                eventsManager.emit(EventType.WORKSPACE_TEAM_MEMBER_DELETED, {
                  newWorkspace,
                  oldWorkspaceTeamUserId
                }, 'WorkspaceStore')
              }
            })
          }
          if (JSON.stringify(oldWorkspace.bankConfig) !== JSON.stringify(newWorkspace.bankConfig)) {
            /*console.log('%c IN WorkspaceStore.subscribe, JSON.stringify(oldWorkspace.bankConfig) !== JSON.stringify(newWorkspace.bankConfig)', 'color:green;font-size: 1.5em;')*/
            console.error("bank config about to be saved")
            /* console.log('bankConfig changed, emitting WORKSPACE_BANK_ACCOUNT_CHANGED') */
            // after bank account info is updated, wait just a few seconds before loading transactions
            // (allow test environment success.402 or success.1010 to do accounts first with success then transactions with resp. 402 or 1010)
            // setTimeout(()=>{
              eventsManager.emit(EventType.WORKSPACE_BANK_ACCOUNT_CHANGED, newWorkspace, 'WorkspaceStore')
            // }, 100)
          }
          if (oldWorkspace.testEndToEnd !== newWorkspace.testEndToEnd) {
            eventsManager.emit(EventType.WORKSPACE_TEST_END_TO_END_CHANGED, newWorkspace, 'WorkspaceStore')
          }
          if (newWorkspace.company && newWorkspace.company.companyId && JSON.stringify(oldWorkspace.company) !== JSON.stringify(newWorkspace.company)) {
            const customerCreatedDate: ExchangeDate = (!!newWorkspace?.company?.createdDate ? newWorkspace?.company?.createdDate : ExchangeDate.newDate(new Date()))
            newWorkspace.company.modifiedDate = ExchangeDate.newDate(new Date())
            newWorkspace.company.createdDate = customerCreatedDate
            eventsManager.emit(EventType.WORKSPACE_COMPANY_CHANGED, newWorkspace, 'WorkspaceStore')
          }
          localStorage.setItem('WorkspaceStore', JSON.stringify(newWorkspace, null, 4))
        }
      }
    })

    /* console.log('%c WorkspaceStore initialized.', 'color: #8A99AC') */
    eventsManager.emit(EventType.WORKSPACE_STORE_INITIALIZED, null, 'WorkspaceStore')
  }
}

export let NewWorkspaceRequestedByUserWorkspaceId: Writable<string> = writable(String(localStorage.getItem('NewWorkspaceRequestedByUserWorkspaceId') || ''))
// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
NewWorkspaceRequestedByUserWorkspaceId.subscribe((v: string) => localStorage.setItem('NewWorkspaceRequestedByUserWorkspaceId', v))

function getWorkspaceList(): Array<Workspace> {
  const existingValue: string = localStorage.getItem('WorkspaceListStore')
  const emptyValue: Array<Workspace> = new Array<Workspace>()

  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  if (existingValue === '[]') {
    return emptyValue
  }

  return <Array<Workspace>>JSON.parse(existingValue)
}


if (!unsubscribeWorkspaceListStore) {
  /* console.log('no need to unsubscribe') */
} else {
  /* console.log('unsubscribing first') */
  unsubscribeWorkspaceListStore()
  unsubscribeWorkspaceListStore = null
}
export let WorkspaceListStore: Writable<Workspace[]> = writable(getWorkspaceList())

/* console.log('just after export let WorkspaceListStore = writable(getWorkspaceList());') */

eventsManager.on<Array<Workspace>>(EventType.WORKSPACE_LIST_FETCHED, (e: DundyEvent<Workspace[]>) => {
  if (e.data && e.data.length) {
    localStorage.setItem('WorkspaceListStore', JSON.stringify(e.data, null, 4))
    /* console.log('updating WorkspaceListStore in workspace.store.ts on EventType.WORKSPACE_LIST_FETCHED') */
    WorkspaceListStore.set(e.data)
  }
}, 'WorkspaceListStore')

/** NB: the WorkspaceListStore has a more basic purpose than any other store:
 * It is only here to keep track of the list of workspace that the user is linked to.
 * It is here to track added items, removed items or to refresh from the back-end.
 * But it is not intended to persist changes inside items.
 * Item changes mean workspace details changes, and are only allowed if the workspace is active/loaded as current/working workspace.
 * Therefore, they are not triggered by WorkspaceListStore svelte store variable but by WorkspaceStore svelte store variable.
 * Deleting a workspace has no specific meaning. Whether a user should not be team user anymore, or ownership should be transferred to another user
 * or a workspace should be put on hold/archived and candidate for eventual deletion (but we need to keep all the data for a legal period of N years).
 *
 * The link webapp between memory data, webapp store variables, webapp local storage and back-end data is the following:
 * See tech doc for more details at :
 *  - https://ut-tech-doc-dev-20220923.dundy-dev.com/reactive-architecture-back-end-front-end-and-implementation/front-end-custom-events-explained/
 *  - https://ut-tech-doc-dev-20220923.dundy-dev.com/reactive-architecture-back-end-front-end-and-implementation/overall-reactive-architecture-intro/
 * For a list of items:
 * - to add a new item in a list, you must ask for a new item from the back-end first, then ask to refresh the store variable list.
 * - to modify an existing item, just modify it in the list (except list of workspaces as mentioned in WorkspaceListStore definition) as it will be detected by the store variable central subscribe and change will be persisted by it as well.
 * - to delete an item, just delete it in the list (except list of workspaces as mentioned in WorkspaceListStore definition) as it will be detected by the store variable central subscribe and change will be persisted by it as well.
 * - to load it for the first time, we need to have its initialized class variable set to false (in its service.ts) and execute its class method initialize().
 * - to refresh it, we need to emit its event like REQUIRE_TO_REFRESH which will trigger the subscribed code to this event in its service.ts which will load data from the back-end and re-populate both the svelte store variable and the local storage.
 * For a single entity:
 * - same as above except we cannot really add nor delete a single entity.
 **/

/* console.log('just before WorkspaceListStore.subscribe in workspace.store.ts', get(WorkspaceListStore)) */
let isWorkspaceListStoreSubscriptionDefined: boolean = false

// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable,
// then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
unsubscribeWorkspaceListStore = WorkspaceListStore.subscribe((newWorkspaceList: Workspace[]) => {
  if (!isWorkspaceListStoreSubscriptionDefined) {
    /* console.log('WorkspaceListStore subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently') */
    isWorkspaceListStoreSubscriptionDefined = true

    return // we avoid the .subscribe() execution at the subscription occurrence
  }
  // console.trace()
  /* console.log('!!!= WorkspaceListStore.subscribe newWorkspaceList =', newWorkspaceList) */
  if (!!newWorkspaceList && newWorkspaceList.length) {
    const oldWorkspaceList = JSON.parse(localStorage.getItem('WorkspaceListStore') || "")
    const oldWorkspaceListHash: string = encrypt256Hash(oldWorkspaceList)
    const newWorkspaceListHash: string = encrypt256Hash(newWorkspaceList)

    let didTheListChange: boolean = false
    const validNewWorkspaceList: Workspace[] = newWorkspaceList.filter((aWorkspace: Workspace) => (!!aWorkspace.workspaceId && aWorkspace.workspaceId !== ''))
    if (oldWorkspaceListHash !== newWorkspaceListHash) {
      // detecting added workspaces
      validNewWorkspaceList.forEach((newWorkspace: Workspace) => {
        const oldWorkspaceVersion = oldWorkspaceList.find((workspace: Workspace): boolean => workspace.workspaceId === newWorkspace.workspaceId)
        if (!oldWorkspaceVersion && newWorkspace.workspaceId === '') { // is there really a reason to have this case if we pick up new items from the back-end directly ? hence never have creations from the svelte store variable / in other words never add a new item by adding the item to the svelte store variable...
          didTheListChange = true
          /* console.log('!!!= WorkspaceListStore.subscribe new item added =', newWorkspace) */
          eventsManager.emit(EventType.WORKSPACE_ADDED, newWorkspace, 'WorkspaceListStore')
        } else {
          if (encrypt256Hash(oldWorkspaceVersion) !== encrypt256Hash(newWorkspace)) {
            didTheListChange = true
            /* console.log('!!!= WorkspaceListStore.subscribe list item changed: oldWorkspaceVersion =', oldWorkspaceVersion, 'changed into new version =', newWorkspace) */
            eventsManager.emit(EventType.WORKSPACE_LIST_CHANGED, newWorkspace, 'WorkspaceListStore')
          }
        }
      })
      // detecting removed workspaces
      oldWorkspaceList.forEach((oldWorkspace: Workspace): void => {
        const newWorkspaceVersion: Workspace = validNewWorkspaceList.find((workspace: Workspace): boolean => workspace.workspaceId === oldWorkspace.workspaceId) || {} as Workspace
        if (!newWorkspaceVersion || newWorkspaceVersion.workspaceId === "") {
          didTheListChange = true
          /* console.log('!!!= WorkspaceListStore.subscribe old workspace removed for user =', oldWorkspace) */
          eventsManager.emit(EventType.WORKSPACE_DELETED, oldWorkspace, 'WorkspaceListStore')
        }
      })
      if (didTheListChange) {
        localStorage.setItem('WorkspaceListStore', JSON.stringify(validNewWorkspaceList, null, 4))
        eventsManager.emit(EventType.WORKSPACE_LIST_CHANGED, validNewWorkspaceList, 'WorkspaceListStore')
      }
    } else {
      /* console.log('same workspace list hash') */
    }
    eventsManager.emit(EventType.WORKSPACE_LIST_LOADED, newWorkspaceList, 'WorkspaceListStore')
  }
})
/* console.log('finished WorkspaceListStore.subscribe in workspace.store.ts') */

<script lang='ts'>
  import TextInput from '$core/lib/ui-kit/TextInput.svelte'
  import { createEventDispatcher } from 'svelte'
  import { t } from '$core/lib/i18n/i18nextWrapper'
  import CompanyList from './CompanyList.svelte'
  import { companyGetInpiInfo, companySearch } from '$src/core-app/services/onboarding.service'
  import type Company from '$src/crm-app/models/company'
  import type BiFrInpiCompanyBySirenAPIResponse from '$src/shared/models/inpi.model'
  import { FiscalYearDetails } from '$src/crm-app/models/company'
  import { remoteDebuggingService } from '$src/core-app/services/remote-debugging.service'
  import mixpanel from 'mixpanel-browser'

  export let display:Readonly<string> = 'none'

  const dispatch = createEventDispatcher()
  const searchCount:number = 20

  export let isFetching: boolean = false
  let isFetched: boolean = false
  let searchName: string = ''
  let searchZipCode:string = ''
  let companies: Company[] = []
  let noMore: boolean = false
  let newSearch: boolean = true
  let searchNumber: number = searchCount + 1

  $:resultCount = 
    companies?.length === 0 ? 
      t('onboarding.companyInfo.search.foundNone')
      : companies?.length === 1 ?
        t('onboarding.companyInfo.search.foundOne')
        : t('onboarding.companyInfo.search.foundMany', { n: companies?.length })

  const onSearchCompany = async (loadMore:boolean = false) => {
    isFetched = false
    newSearch = !loadMore
    
    if (!searchName) {
      noMore = true
      companies = []
      
      return
    }

    const siren = searchName.replaceAll(' ', '')
    if (!isNaN(Number(siren))) searchName = siren

    isFetching = true
    
    const res = await companySearch(searchName, searchZipCode, loadMore ? searchNumber : 0)
    noMore = !res?.length
    
    if (newSearch) {
      companies = res
    } else {
      if (!noMore) {
        if (loadMore === true) {
          searchNumber += searchCount
          companies = [...companies, ...res]
        } else {
          companies = res
        }
      }
    }

    isFetching = false
    isFetched = true
  }

  const onFormSubmit = () => {
    if (window.location.pathname.includes('onboarding')) {
      mixpanel.track('onb.cpn.inf.C10.u onboarding company search button', {
        'Description': 'Click on search company from CompanySearch.svelte'
      })
    }

    onSearchCompany()
  }

  const onListScroll = () => {
    onSearchCompany(true)
  }

  const onSelectCompany = async (company:any) => {
    if (window.location.pathname.includes('onboarding')) {
      mixpanel.track('onb.cpn.inf.C12.u onboarding select company', {
        'Description': 'Click on company item from CompanyList.svelte (dispatch)'
      })
    }

    isFetching = true

    try {
      const siren = company?.regulatory?.frRegulScope?.siren
      if (!siren) return
      
      const inpi:BiFrInpiCompanyBySirenAPIResponse = await companyGetInpiInfo(siren)
      if (inpi) {
        if (inpi?.shareCapitalAmount) company.shareCapital = inpi?.shareCapitalAmount.toString() + ' ' + inpi?.shareCapitalCurrency
        company.fyDetails = FiscalYearDetails.fromString(inpi?.fiscalYearClosingDay, inpi?.firstFiscalYearClosingDate)
        if (!company.regulatory.frRegulScope.nafCodeLevel4) company.regulatory.frRegulScope.nafCodeLevel4 = inpi?.apeCode
      }
    } catch (err) {
      remoteDebuggingService.addInfo(String(err))
    }
    
    isFetching = false

    dispatch('selectCompany', company)
  }
</script>

<form style:display={display} on:submit|preventDefault={() => onFormSubmit()} class="w-full relative">
  <TextInput
    bind:value={searchName}
    id="companyName"
    autocomplete="off"
    disabled={isFetching}
    showValidationTick={false}
    placeholder={t('onboarding.companyInfo.search.placeholder')}
  />

  <div class="flex items-center text-sm my-3">
    <p class="mt-1.5 text-baliHai">{t('onboarding.companyInfo.search.filter')}</p>
    <TextInput
      bind:value={searchZipCode}
      class="w-20 ml-2"
      id="zipCode"
      autocomplete="off"
      type="number"
      hideSpin={true}
      disabled={isFetching}
      showValidationTick={false}
    />
  </div>

  {#if isFetching}
    <div class="text-center h-6 py-6">
      <div class="loading" />
    </div>
  {:else}
    <input class="btn big-primary animated w-full h-10 my-6" class:disabled={isFetching} type="submit" value={t('onboarding.companyInfo.search.button')} />
  {/if}

  <CompanyList
    bind:isFetching={isFetching}
    companies={companies}
    newSearch={newSearch}
    noMore={noMore}
    on:selectCompany={c => onSelectCompany(c.detail)}
    on:scroll={() => onListScroll()}
  />

  {#if isFetched}
    <span>{resultCount}</span>
  {/if}

  {#if isFetched}
    <div class="aside-note text-xs mt-9 mb-2">
      <img src="/img/flag-FR.png" alt="fr" class="h-4 mr-4"/>
      <p>{t('onboarding.companyInfo.search.disclaimer')}</p>
    </div>
  {/if}

</form>

<style lang="postcss">
  .aside-note {
    @apply bg-creamBrulee flex items-center border border-warmGray rounded-md px-6 py-4;
    border-color: hsl(47, 65%, 84%);
    background-color: hsl(47, 87%, 94%);
  }

  .disabled {
    @apply blur-[2px] select-none pointer-events-none;
  }
  .loading {
    @apply pointer-events-none relative;

    &::before {
      content: '';
      @apply absolute w-5 h-5 z-10 -ml-px rounded-full border-2 border-r-dundyOrange;
      animation: disabled-loader 1s infinite linear;
    }
  }
</style>
<script lang="ts">
    import { BusinessDocument } from '../../../../voxy-app/models/business-document'
    import { formatCurrencyWithOptions } from '../../../../voxy-app/utils/number-formatting-utils.js'
    import { t } from '../../i18n/i18nextWrapper.js'
    import { BusinessDocumentPartialKindFormatter } from '../../../../voxy-app/helpers/business-document-partial-data'

    /** Export let */
    export let classes: string
    export let originalBusinessDocument: BusinessDocument

    /** Get the right kind of Partial Invoice along with its percentage */
    let thisBusinessDocumentPartialKindFormatter: BusinessDocumentPartialKindFormatter = new BusinessDocumentPartialKindFormatter(t, originalBusinessDocument)
</script>

<div class="flex flex-col {classes}">
    <dl class="-my-3 py-4 text-sm leading-6">
            <p class="text-sm font-semibold leading-6 text-gray-900">{originalBusinessDocument?.businessDocumentNumber}</p>
            <p class="mt-0.5 py-0.5 text-xs font-medium text-zinc-500">
                {thisBusinessDocumentPartialKindFormatter.businessDocumentDescription || originalBusinessDocument?.installmentDescription || ''}
            </p>
            <p class="flex items-baseline gap-x-1 truncate">
                <span class="font-bold tracking-tight text-gray-900">{formatCurrencyWithOptions(originalBusinessDocument?.installmentResultIncludingTaxScaledValue, t('locales'), originalBusinessDocument?.currency, true, 'symbol')}</span>
                <span class="leading-6 text-s text-zinc-800">/ {formatCurrencyWithOptions(originalBusinessDocument?.totalIncludingTaxScaledValue, t('locales'), originalBusinessDocument?.currency, true, 'symbol')}</span>
            </p>
    </dl>
</div>
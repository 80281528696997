export const mapEnabledReplacer = (key: any, value: any[]): any => {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries())
    }
  } else {
    return value
  }
}

export const mapEnabledReviver = (key: any, value: { dataType: string; value: Iterable<readonly [unknown, unknown]>; }): any => {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value)
    }
  }
  
  return value
}

export class MapEnabledJson {
  static parse(json: string): any {
    return JSON.parse(json, mapEnabledReviver)
  }
  static stringify(object: any): string {
    return JSON.stringify(object, mapEnabledReplacer, 4)
  }
}
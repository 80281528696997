
type sortArg<T> = keyof T | `-${string & keyof T}`

/**
 * Returns a comparator for objects of type T that can be used by sort
 * functions, were T objects are compared by the specified T properties.
 *
 * @param sortBy - the names of the properties to sort by, in precedence order.
 *                 Prefix any name with `-` to sort it in descending order.
 */
export function byPropertiesOf<T extends object>(sortBy: Array<sortArg<T>>) {
  function compareByProperty<T>(arg: sortArg<T>) {
    let key: keyof T
    let sortOrder: number = 1
    if (typeof arg === 'string' && arg.startsWith('-')) {
      sortOrder = -1
      // Typescript is not yet smart enough to infer that substring is keyof T
      key = arg.substring(1) as keyof T // Replaced substr with substring
    } else {
      // Likewise it is not yet smart enough to infer that arg here is keyof T
      key = arg as keyof T
    }
    
    return function (a: T, b: T) {
      const result = a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0

      return result * sortOrder
    }
  }


  return function (obj1: T, obj2: T) {
    let i: number = 0
    let result: number = 0
    const numberOfProperties: number = sortBy?.length
    while (result === 0 && i < numberOfProperties) {
      result = compareByProperty(sortBy[i])(obj1, obj2)
      i++
    }

    return result
  }
}

/**
 * Sorts an array of T by the specified properties of T.
 *
 * @param arr - the array to be sorted, all of the same type T
 * @param sortBy - the names of the properties to sort by, in precedence order.
 *                 Prefix any name with `-` to sort it in descending order.
 */
export function sort<T extends object>(arr: T[], ...sortBy: Array<sortArg<T>>): void {
  arr.sort(byPropertiesOf<T>(sortBy))
}


/**
 * Safely parses a JSON string and returns undefined if it fails
 * @param data
 */
export function safelyParseJson<T = any>(data: string): T | undefined {
  try {
    return JSON.parse(data)
  } catch {
    return undefined
  }
}

/**
 * Type guard function that checks if the input is an array and if every item in the array is of the expected type
 * @param input
 * @param checkItem
 */
function isTypedArray<T>(input: unknown, checkItem: (item: unknown) => item is T): input is T[] {
  return Array.isArray(input) && input.every(checkItem)
}

/**
 * Safely returns an array, or an empty array if the data is not an array
 * - first check if the input is an array and if every item in the array is of the expected type.
 * - If both conditions are met, we return the input array. Otherwise, we return an empty array.
 * @param input
 * @param checkItem
 * @returns {T[]}
 */
export function safelyGetArray<T>(input: unknown, checkItem: (item: unknown) => item is T): T[] {
  if (isTypedArray(input, checkItem)) {
    return input
  }
  
  return []
}
export class DundyEvent<T> {
  metadata: {
    eventTag: string,
    publisher: string,
    eventTimeStamp: Date,
    eventTimeStampUnixSeconds: number,
    eventTimeStampUnixMilliseconds: number,
    // ISO8601 vs RFC3339 : https://stackoverflow.com/questions/522251/whats-the-difference-between-iso-8601-and-rfc-3339-date-formats
    eventTimeStampRFC3339: string,
    // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones e.g. Europe/Paris or Etc/UTC
    eventTimeZoneIANACode: string
  }
  data: T
}
import { Decimal } from 'decimal.js'

export class InvoicingBalanceLimits {
  balanceInvoicingMetricsUpdated: boolean
  dealTotalAmountToInvoiceScaledValueIncludingTaxes: Decimal
  dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesIncludingCurrentDraft: Decimal
  dealMaxRemainingPercentToInvoiceIncludingCurrentDraft: Decimal
  dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesOnlyFinalized: Decimal
  dealMaxRemainingPercentToInvoiceOnlyFinalized: Decimal
  dealInvoicedAmountScaledValueDraftedOnly: Decimal
  dealInvoicedPercentDraftedOnly: Decimal

  constructor(
    balanceInvoicingMetricsUpdated: boolean,
    dealTotalAmountToInvoiceScaledValueIncludingTaxes: Decimal | number,
    dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesIncludingCurrentDraft: Decimal | number,
    dealMaxRemainingPercentToInvoiceIncludingCurrentDraft: Decimal | number,
    dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesOnlyFinalized: Decimal | number,
    dealMaxRemainingPercentToInvoiceOnlyFinalized: Decimal | number,
    dealInvoicedAmountScaledValueDraftedOnly: Decimal | number,
    dealInvoicedPercentDraftedOnly: Decimal | number,
  ) {
    this.balanceInvoicingMetricsUpdated = balanceInvoicingMetricsUpdated;
    this.dealTotalAmountToInvoiceScaledValueIncludingTaxes = new Decimal(dealTotalAmountToInvoiceScaledValueIncludingTaxes)
    this.dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesIncludingCurrentDraft = new Decimal(dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesIncludingCurrentDraft)
    this.dealMaxRemainingPercentToInvoiceIncludingCurrentDraft = new Decimal(dealMaxRemainingPercentToInvoiceIncludingCurrentDraft)
    this.dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesOnlyFinalized = new Decimal(dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesOnlyFinalized)
    this.dealMaxRemainingPercentToInvoiceOnlyFinalized = new Decimal(dealMaxRemainingPercentToInvoiceOnlyFinalized)
    this.dealInvoicedAmountScaledValueDraftedOnly = new Decimal(dealInvoicedAmountScaledValueDraftedOnly)
    this.dealInvoicedPercentDraftedOnly = new Decimal(dealInvoicedPercentDraftedOnly)
  }

  static empty(): InvoicingBalanceLimits {
    return new InvoicingBalanceLimits(
      false,
      new Decimal(0),
      new Decimal(0),
      new Decimal(0),
      new Decimal(0),
      new Decimal(0),
      new Decimal(0),
      new Decimal(0)
    )
  }
}

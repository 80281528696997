import type { ICellRendererParams } from 'ag-grid-community'
import { BusinessDocumentState } from '$voxy/enums/business-document-state'
import { getStatus } from '$voxy/services/business-document-voiding/is-business-document-voided.service'

export default class BusinessDocumentStatusRenderer {
  eGui: HTMLSpanElement
  originalParams: ICellRendererParams
  /**
     * gets called once before the renderer is used
     * @param params
     */
  init(params: ICellRendererParams) {
    // create the cell
    // build the button properly (https://blog.ag-grid.com/cell-renderers-in-ag-grid-every-different-flavour/#vanillajs)
    this.originalParams = params

    this.buildElement(params.data)
  }
  buildElement(elementToGenerateParamsData: any) {
    this.eGui = document.createElement('span')

    let kind = elementToGenerateParamsData.businessDocumentKind
    if ( kind) kind = kind.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()

    const status = getStatus(elementToGenerateParamsData)

    this.eGui.classList.add('label')
    this.eGui.classList.add('action-primary', ['voiding-and-last'].includes(status.state) ? kind : status.state)
    this.eGui.insertAdjacentHTML('beforeend', status.label)

    // disable relaunch in contextual menu
    if ([BusinessDocumentState.VOIDED, BusinessDocumentState.VOIDING, BusinessDocumentState.VOIDING_AND_VOIDED].includes(status.state) ) this.eGui.setAttribute('hide-relaunch', 'true')
  }
  getGui(): HTMLSpanElement {
    return this.eGui
  }
  // gets called whenever the cell refreshes
  refresh(params: { data: any; }): boolean {
    // return true to tell the grid we refreshed successfully
    
    
    this.buildElement(params.data)
    
    return true
  }
  // gets called when the cell is removed from the grid
  destroy() {
  }
}

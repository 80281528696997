import { writable } from 'svelte/store'
import { eventsManager } from '../../events/event-manager'
import { EventType } from '../../events/event-type'
import customLog from '$src/shared/services/custom-log.service'

// name : auth0JsPostLoginRedirect
// Type : string
// Desc : URI to redirect to, once login is completed
// Persistence : localStorage
export let auth0JsPostLoginRedirect = writable(localStorage.getItem('auth0JsPostLoginRedirect'))
let isAuth0JsPostLoginRedirectStoreSubscriptionDefined = false
// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
auth0JsPostLoginRedirect.subscribe(val => {
  if (!isAuth0JsPostLoginRedirectStoreSubscriptionDefined) {
    /* console.log('auth0JsPostLoginRedirect subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently') */
    isAuth0JsPostLoginRedirectStoreSubscriptionDefined = true
    
    return // we avoid the .subscribe() execution at the subscription occurrence
  }
  localStorage.setItem('auth0JsPostLoginRedirect', val)
})

// name : isAuthenticatedUnderAuth0JsClient
// Type : boolean
// Desc : is the user logged with all corresponding data set ?
// Persistence : localStorage
export let isAuthenticatedUnderAuth0JsClient = writable((localStorage.getItem('isAuthenticatedUnderAuth0JsClient') === 'true') || false)
// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
isAuthenticatedUnderAuth0JsClient.subscribe((val) => {
  localStorage.setItem('isAuthenticatedUnderAuth0JsClient', val.toString())
})


// name : userUnderAuth0JsClient
// Type : pure data Object
// Desc : the logged user data
// Persistence : localStorage
const getUserUnderAuth0JsClientInitialValue = () => {
  const existingValue = localStorage.getItem('userUnderAuth0JsClient')
  const emptyValue = {}
  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  if (existingValue === '{}') {
    return emptyValue
  }
  
  return JSON.parse(existingValue)
}
export let userUnderAuth0JsClient/*:Writable<Auth0User>*/ = writable(getUserUnderAuth0JsClientInitialValue())
// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
userUnderAuth0JsClient.subscribe(val => {
  localStorage.setItem('userUnderAuth0JsClient', JSON.stringify(val, null, 4))
})


// Name : auth0JsClient
// Type : complex Object with methods
// Desc : the auth0 client
// Persistence : volatile
export let auth0JsClient = writable(null)


// Name : authZeroJsAccessToken
// Type : string
// Desc : the auth0 access token to pass to API requests (bearer token)
// Persistence : localStorage
const getAuth0JsAccessTokenInitialValue = () => {
  const existingValue = localStorage.getItem('authZeroJsAccessToken')
  const emptyValue = ''
  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  
  return existingValue
}
export let authZeroJsAccessToken = writable(getAuth0JsAccessTokenInitialValue())
// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
authZeroJsAccessToken.subscribe(val => {
  if (!!val) {
    if (val.length > 5) {
      if (isAuthenticatedUnderAuth0JsClient) {
        eventsManager.emit(EventType.LOGGED_IN, val, 'authStore')
      }
    }
    localStorage.setItem('authZeroJsAccessToken', val)
  } else {
    /* console.log('not logged in anymore') */
    localStorage.setItem('authZeroJsAccessToken', '')
  }
})

// name : auth0JsExpiresIn
// Type : number
// Desc : access token expiration
// Persistence : localStorage
const getAuth0JsExpiresInInitialValue = () => {
  const existingValue = localStorage.getItem('auth0JsExpiresIn')
  const emptyValue = 0
  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  
  return parseInt(existingValue, 10)
}
export let auth0JsExpiresIn = writable(getAuth0JsExpiresInInitialValue())
// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
auth0JsExpiresIn.subscribe(val => {
  if (!!val) {
    localStorage.setItem('auth0JsExpiresIn', val.toString())
  } else {
    localStorage.setItem('auth0JsExpiresIn', '')
  }
})


// name : auth0JsExpiresAt
// Type : number
// Desc : access token expiration unix time in seconds
// Persistence : localStorage
const getAuth0JsExpiresAtInitialValue = () => {
  const existingValue = localStorage.getItem('auth0JsExpiresAt')
  const emptyValue = 0
  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  
  return parseInt(existingValue, 10)
}
export let auth0JsExpiresAt = writable(getAuth0JsExpiresAtInitialValue())
// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
auth0JsExpiresAt.subscribe(val => {
  if (!!val) {
    localStorage.setItem('auth0JsExpiresAt', val.toString())
  } else {
    localStorage.setItem('auth0JsExpiresAt', '')
  }
})


// Name : auth0JsIdToken
// Type : string
// Desc : the auth0 id token
// Persistence : localStorage
const getAuth0JsIdTokenInitialValue = () => {
  const existingValue = localStorage.getItem('auth0JsIdToken')
  const emptyValue = ''
  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  
  return existingValue
}
export let auth0JsIdToken = writable(getAuth0JsIdTokenInitialValue())
// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
auth0JsIdToken.subscribe(val => {
  localStorage.setItem('auth0JsIdToken', val)
})


// Name : auth0JsRefreshToken
// Type : string
// Desc : the auth0 refresh token
// Persistence : localStorage
const getAuth0JsRefreshTokenInitialValue = () => {
  const existingValue = localStorage.getItem('auth0JsRefreshToken')
  const emptyValue = ''
  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  
  return existingValue
}
export let auth0JsRefreshToken = writable(getAuth0JsRefreshTokenInitialValue())
// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
auth0JsRefreshToken.subscribe(val => {
  localStorage.setItem('auth0JsRefreshToken', val)
})


// Name : authZJsFirstName
// Type : string
// Desc : the user first name in auth0
// Persistence : localStorage
const getAuth0JsFirstNameInitialValue = () => {
  const existingValue = localStorage.getItem('authZJsFirstName')
  const emptyValue = ''
  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  
  return existingValue
}
export let authZJsFirstName = writable(getAuth0JsFirstNameInitialValue())
// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
authZJsFirstName.subscribe(val => {
  localStorage.setItem('authZJsFirstName', val)
})


// Name : authZJsFullName
// Type : string
// Desc : the full name in auth0, if the user is a test user, then the full name starts with "testUser/"
// Persistence : localStorage
const getAuth0JsFullNameInitialValue = () => {
  const existingValue = localStorage.getItem('authZJsFullName')
  const emptyValue = ''
  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  
  return existingValue
}
export let authZJsFullName = writable(getAuth0JsFullNameInitialValue())
// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
authZJsFullName.subscribe(val => {
  localStorage.setItem('authZJsFullName', val)
})


// Name : authZJsLastName
// Type : string
// Desc : the user last name in auth0
// Persistence : localStorage
const getAuth0JsLastNameInitialValue = () => {
  const existingValue = localStorage.getItem('authZJsLastName')
  const emptyValue = ''
  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  
  return existingValue
}
export let authZJsLastName = writable(getAuth0JsLastNameInitialValue())
// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
authZJsLastName.subscribe(val => {
  localStorage.setItem('authZJsLastName', val)
})


// name : auth0JsUserRights
// Type : pure data Object
// Desc : the logged user rights
// Persistence : localStorage
const getAuth0JsUserRightsInitialValue = () => {
  const existingValue = localStorage.getItem('auth0JsUserRights')
  const emptyValue = {}
  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  if (existingValue === '{}') {
    return emptyValue
  }
  
  return JSON.parse(existingValue)
}
export let auth0JsUserRights = writable(getAuth0JsUserRightsInitialValue())
// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
auth0JsUserRights.subscribe(val => {
  localStorage.setItem('auth0JsUserRights', JSON.stringify(val, null, 4))
})


// Name : authZJsUserId
// Type : string
// Desc : the user id in auth0
// Persistence : localStorage
const getAuth0JsUserIdInitialValue = () => {
  const existingValue = localStorage.getItem('authZJsUserId')
  const emptyValue = ''
  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  
  return existingValue
}
export let authZJsUserId = writable(getAuth0JsUserIdInitialValue())
// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
authZJsUserId.subscribe(val => {
  localStorage.setItem('authZJsUserId', val)
})

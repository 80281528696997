import type {
  BusinessDocument,
  BusinessDocumentCollateralData,
  BusinessDocumentItemPrice,
  BusinessDocumentLineItem,
  BusinessDocumentRelation
} from '../models/business-document'
import { DealDetails, InstallmentComputationKind } from '../models/business-document'
import { get } from 'svelte/store'
import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
import { ProfileStore } from '../../core-app/stores/profile.store'
import { userUnderAuth0JsClient } from '../../core-app/lib/auth0authentication/authStore'
import { v4 as uuidv4 } from 'uuid'
import { ExchangeDate } from '../../core-app/models/exchange-date'
import { BusinessDocumentKind } from '../enums/business-document-kind'
import { BusinessDocumentStatus } from '../enums/business-document-status'
import { BusinessDocumentCollateralDataModelKind } from '../enums/business-document-collateral-model-kind'
import type { BusinessDocumentAttachmentMetadata } from '../../core-app/models/attachment-metadata'
import type { NumberingSchemeSettings } from '../models/voxy-preferences'
import type { TaxRate } from '../models/tax-rate'
import { getUserCompanyTimezone } from '../../core-app/util/timezone-utils'
import { t } from '../../core-app/lib/i18n/i18nextWrapper'
import { getDefaultUnitType } from '../services/unit-types-list'
import { getDefaultCurrencyAccordingToCountry } from '../../core-app/services/currency-list'
import { getUserCountryCode } from '../../core-app/services/countries-pure-functions'
import { calculateBankWireReference, getUpdatedIsBankInformationMissing } from './voxy-helper-pure-functions'
import { Workspace } from '../../crm-app/models/workspace'
import type { WorkspaceBankConfig } from '../../pay-app/models/workspace-bank-config'
import Profile from '../../core-app/models/profile'
import type { TaxonomyTag } from '../enums/taxonomy-tag'
import { Customer } from '$src/crm-app/models/customer'

export class VoxyHelper {

  static newVoxyDefaultNumberingScheme(businessDocumentKind?: BusinessDocumentKind): NumberingSchemeSettings {
    return <NumberingSchemeSettings>{
      applicableDocumentKind: businessDocumentKind,
      automaticNumberingMode: true,
      continueExistingDocumentNumberSequence: true,
      documentNumberTemplate: t(`automaticNumbering.defaultAutomaticNumberingAccordingToKind.${businessDocumentKind}`, { interpolation: { skipOnVariables: true } }),
      inTimeZoneIANACode: getUserCompanyTimezone(get(WorkspaceStore)),
      initialDocumentSequenceNumber: 1,
      sequenceNumberOfDigits: 2,
      description: 'default first time numbering setting'
    }
  }
  static newVoxyCollateralData(businessDocumentId: string, businessDocumentKind: BusinessDocumentKind, isBankConnectionActive: boolean, businessDocumentNumber?: string): BusinessDocumentCollateralData {
    const workspaceStore: Workspace = get(WorkspaceStore)

    return <BusinessDocumentCollateralData>{
      businessDocumentId: businessDocumentId,
      modelKind: BusinessDocumentCollateralDataModelKind.SIMPLE2022,
      modelVersion: 1,
      businessDocumentKind: businessDocumentKind,
      businessDocumentNumber: businessDocumentNumber,
      paymentConditionsCustomText: '',
      showLateFeesAndPenaltyText: true,
      showNoDiscountForEarlyBirds: true,
      isBankConnectionActive: isBankConnectionActive,
      showDiscountModule: false,
      isBankInformationMissing: getUpdatedIsBankInformationMissing(workspaceStore.bankConfig || <WorkspaceBankConfig>{}),
      paymentInformation: {
        paymentURL: null
      },
      showPaymentLink: isBankConnectionActive,
      isPaidWhenFinalized: false,
      fileAttachment: <BusinessDocumentAttachmentMetadata>{
        fileName: null,
        fileSizeBytes: 0,
        fileType: null,
        workspaceId: workspaceStore.workspaceId,
        businessDocumentId: null,
        businessDocumentKind: BusinessDocumentKind.INVOICE,
        businessDocumentNumber: null,
        createdDate: null
      }
    }
  }
  static newVoxyInvoice(businessDocumentKind?: BusinessDocumentKind, taxonomyTag?: TaxonomyTag[], currency: string = getDefaultCurrencyAccordingToCountry(getUserCountryCode())): BusinessDocument {
    const dueFifteenDaysFromNow: Date = new Date()
    dueFifteenDaysFromNow.setDate(dueFifteenDaysFromNow.getDate() + 15)

    const userUnderAuth0 = get(userUnderAuth0JsClient)
    const workspaceStore = get(WorkspaceStore)
    const profileStore = get(ProfileStore)

    return <BusinessDocument>{
      modelKind: 'SIMPLE2022',
      modelVersion: 1,
      businessDocumentId: uuidv4(),
      businessDocumentKind: businessDocumentKind,
      linkedDeal: <DealDetails>{
        hasDealInfo: false,
        dealId: uuidv4(),
        dealKind: null,
        dealSupplierReference: null,
        dealCustomerReference: null,
        dealTitle: null,
        dealDescription: null,
        dealDate: ExchangeDate.newDate(new Date()),
        hasDealStart: false,
        dealStart: ExchangeDate.newDate(new Date()),
        hasDealEnd: false,
        dealEnd: null
      },
      taxonomyTags: taxonomyTag,
      timeZoneIANACode: getUserCompanyTimezone(get(WorkspaceStore)),
      businessDocumentSource: {
        sourceLabel: '',
        sourceKind: null,
        sourceAccountReference: '',
        sourceLogoURL: '',
        resourceURL: '',
        attachmentURLs: [],
        lastSourceUpdated: null
      },
      relatedBusinessDocuments: <BusinessDocumentRelation[]>[],
      relatedPurchaseOrderNumbers: [],
      businessDocumentReason: null,
      reasonDetails: '',
      accountCompany: workspaceStore?.company,
      accountContact: {
        contactId: null,
        companyId: workspaceStore?.company?.companyId,
        firstName: profileStore?.firstName,
        lastName: profileStore.lastName,
        department: null,
        email: userUnderAuth0.email,
        officePhone: profileStore.officePhone ?? '',
        mobilePhone: profileStore.mobilePhone ?? '',
        position: null
      },
      accountBankingInformation: {
        bankAccountHolder: workspaceStore?.company?.formalName,
        bankAccountIBAN: workspaceStore?.bankConfig?.selectedAccountBankIBAN ?? '',
        bankAccountBIC: workspaceStore?.bankConfig?.selectedAccountBankBIC ?? '',
        bankWireReference: calculateBankWireReference(10)
      },
      customerCustomer: Customer.empty(),
      customerContact: {
        contactId: null,
        companyId: null,
        firstName: null,
        lastName: null,
        department: null,
        email: null,
        officePhone: null,
        mobilePhone: null,
        position: null
      },
      customerTaxExempt: null,
      businessDocumentNumber: '',
      memo: null,
      issuedDate: ExchangeDate.newDate(new Date()),
      dueDate: ExchangeDate.newDate(dueFifteenDaysFromNow),
      finalizedDate: null,
      lineItems: <BusinessDocumentLineItem[]>[
        VoxyHelper.newVoxyInvoiceItem(0, currency)
      ],
      currency: currency,
      subtotalExcludingTaxScaledValue: 0,
      subtotalIncludingTaxScaledValue: 0,
      totalDiscountExcludingTaxChosenValue: 0,
      totalDiscountExcludingTaxChosenUnit: '%',
      totalDiscountExcludingTaxDescription: '',
      totalDiscountExcludingTaxResultScaledValue: 0,
      totalTaxScaledValue: 0,
      totalExcludingTaxScaledValue: 0,
      totalIncludingTaxScaledValue: 0,
      hasSpecificInstallment: false,
      installmentChosenValue: 0,
      installmentChosenKind: InstallmentComputationKind.PERCENT_OF_TOTAL_AMOUNT,
      installmentDescription: '',
      installmentResultTaxScaledValue: 0,
      installmentResultExcludingTaxScaledValue: 0,
      installmentResultIncludingTaxScaledValue: 0,
      amountDueScaledValue: 0, // should be equal to totalIncludingTaxScaledValue by default (as long as collateral isPaidWhenFinalized is false -like by default-)
      paymentConditions: null,
      legalMentions: null,
      businessDocumentStatus: BusinessDocumentStatus.DRAFT,
      deleted: false,
      deletedDate: null,
      createdDate: ExchangeDate.newDate(new Date()),
      modifiedDate: ExchangeDate.newDate(new Date())
    }
  }
  static newVoxyInvoiceItem(index: number, currency: string): BusinessDocumentLineItem {
    return {
      businessDocumentLineItemId: uuidv4(),
      lineItemNumber: index + 1,
      title: '',
      description: '',
      supplierReference: '',
      customerReference: '',
      itemPrice: <BusinessDocumentItemPrice>{
        unit: getDefaultUnitType().value,
        currency: currency,
        scaledValue: 0
      },
      quantity: 1,
      taxRate: <TaxRate>{
        taxRateId: 'f4c55549-314d-4525-8105-22e4123e0002',
        displayName: '0%',
        percentage: 0,
        description: '',
        jurisdiction: 'FR'
      },
      taxScaledValue: 0,
      lineItemTotalIncludingTaxScaledValue: 0,
      lineItemTotalExcludingTaxScaledValue: 0
    }
  }

  // /**
  //  * Search function used by Ag-grid to filter invoices
  //  * Returns true if the invoice contains the text in its toName, toId, invoiceNumber, amountIncludingTaxes, currency, dateDue, dateIssued
  //  * @param businessDocument
  //  * @param text
  //  */
  // static isBusinessDocumentContainingText(businessDocument: BusinessDocument, text: string): boolean {
  //     const regexp: RegExp = new RegExp(escapeRegExp(text), 'i');
  //
  //     let lookIntoDocument: AgGridBusinessDocument = <AgGridBusinessDocument>{
  //         toId: businessDocument.customerCustomer.company.companyId,
  //         label: businessDocument.customerCustomer.company.label,
  //         formalName: businessDocument.customerCustomer.company.formalName,
  //         businessDocumentNumber: businessDocument.businessDocumentNumber,
  //         issuedDate: new Intl.DateTimeFormat(t('locales'), { year: 'numeric', month: 'long', day: 'numeric' })
  //             .format(new Date(businessDocument.issuedDate.rfc3339)),
  //         dueDate: new Intl.DateTimeFormat(t('locales'), { year: 'numeric', month: 'long', day: 'numeric' })
  //             .format(new Date(businessDocument.dueDate.rfc3339)),
  //         totalAmount: businessDocument.hasSpecificInstallment ? businessDocument.installmentResultIncludingTaxScaledValue : businessDocument.totalIncludingTaxScaledValue,
  //         businessDocumentStatus: '',
  //         actions: '',
  //     };
  //     delete lookIntoDocument.toId;
  //
  //     for (const prop of Object.keys(lookIntoDocument)) {
  //         if (typeof lookIntoDocument[prop] === 'string' && lookIntoDocument[prop].search(regexp) !== -1) {
  //             return true;
  //         } else if (typeof lookIntoDocument[prop] === 'number') {
  //             const numberAsString: string = new Intl.NumberFormat(t("locales")).format(lookIntoDocument[prop]);
  //             if (numberAsString.search(regexp) !== -1) {
  //                 return true;
  //             }
  //         }
  //     }
  //
  //     return false;
  // }

  /**
     * Update the currency of all line items according to the currency of the business document
     * @param businessDocument
     */
  static updateBusinessLineItemsCurrencyAccordingToBusinessDocumentCurrency(businessDocument: BusinessDocument): BusinessDocument {
    return {
      ...businessDocument,
      lineItems: businessDocument.lineItems.map(lineItem => ({
        ...lineItem,
        itemPrice: {
          ...lineItem.itemPrice,
          currency: businessDocument.currency
        }
      }))
    }
  }
  /** Prevent Percentage from going above 100% **/
  static checkValueToMakeItValidPercentage(e: Event & { currentTarget: EventTarget & HTMLInputElement; }, value: string): boolean {
    const target: HTMLInputElement = e.currentTarget as HTMLInputElement

    if (!value) {
      return false
    }
    if (!target.validity.valid) {
      target.value = value.toString().substring(0, value.length - 1)

      return false
    }
    let index: number = value.toString().indexOf('.')
    if (index >= 0) {
      if (value.length - index > 3) {
        target.value = value.substring(0, value.length - 1)

        return false
      }
    }

    return true
  }


}

<script lang="ts">
  import {t} from '$core/lib/i18n/i18nextWrapper'
  import type {PeriodDescription} from '$core/models/dashboard-statistics-analytics-model'
  import {ValueForCurrentMonthAlongWithVariationMonthToMonth} from '$core/models/dashboard-statistics-analytics-model'
  import Tooltip from '../../../core-app/lib/ui-kit/Tooltip/Tooltip.svelte'
  import StatItem from "$bank/components/stats/StatItem.svelte"

  /** Main currency in transactions */
  export let mainCurrencyInTransactions: string | null

  /** Period description */
  export let periodDescription: PeriodDescription

  /** User timezone */
  export let userTimezone: string

  /** Revenue Object */
  export let calculatedRevenueForCurrentMonthWithVariation: ValueForCurrentMonthAlongWithVariationMonthToMonth

  /** Expenses Object */
  export let calculatedExpensesForCurrentMonthWithVariation: ValueForCurrentMonthAlongWithVariationMonthToMonth

  /** Cashburn Object */
  export let calculatedCashBurnForCurrenMonthWithVariation: ValueForCurrentMonthAlongWithVariationMonthToMonth

  /**
   * Format Date For UI
   * @param date
   * @param userTimezone
   */
  function formatDate(date: Date, userTimezone: string): string {
    const formatter = new Intl.DateTimeFormat(t('locales'), {
      month: 'long',
      timeZone: userTimezone
    })

    const day = date.getDate()
    const month = formatter.format(date)
    const year = date.getFullYear()

    return `${day} ${month} ${year}`
  }
</script>

<div class="grid sm:grid-cols-1 lg:grid-cols-3 gap-4">
  <StatItem
    title={t('transactions.hud.revenue.title')}
    data={calculatedRevenueForCurrentMonthWithVariation}
    currency={mainCurrencyInTransactions}
    hasTooltip={true}
  >
    <div slot="tooltip">
      <Tooltip width="300px">
        <div slot="activator">
          <svg class="text-paleSky" width="16" height="16" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2" d="M12 13V15"/>
            <circle cx="12" cy="9" r="1" fill="currentColor"/>
            <circle cx="12" cy="12" r="7.25" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1.5"/>
          </svg>
        </div>
        <p>{t('transactions.hud.revenue.tooltip')}</p>
      </Tooltip>
    </div>
  </StatItem>
  <StatItem
    title={t('transactions.hud.expenses.title')}
    data={calculatedExpensesForCurrentMonthWithVariation}
    currency={mainCurrencyInTransactions}
    hasTooltip={true}
  >
    <div slot="tooltip">
      <Tooltip width="300px">
        <div slot="activator">
          <svg class="text-paleSky" width="16" height="16" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2" d="M12 13V15"/>
            <circle cx="12" cy="9" r="1" fill="currentColor"/>
            <circle cx="12" cy="12" r="7.25" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1.5"/>
          </svg>
        </div>
        <p>{t('transactions.hud.expenses.tooltip')}</p>
      </Tooltip>
    </div>
  </StatItem>
  <StatItem
    title={t('transactions.hud.cashburn.title')}
    data={calculatedCashBurnForCurrenMonthWithVariation}
    currency={mainCurrencyInTransactions}
    hasTooltip={true}
  >
    <div slot="tooltip">
      <Tooltip width="300px">
        <div slot="activator">
          <svg class="text-paleSky" width="16" height="16" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2" d="M12 13V15"/>
            <circle cx="12" cy="9" r="1" fill="currentColor"/>
            <circle cx="12" cy="12" r="7.25" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1.5"/>
          </svg>
        </div>
        <p>{t('transactions.hud.cashburn.tooltip')}</p>
      </Tooltip>
    </div>
  </StatItem>
</div>

<div class="timetable-info">
  <svg class="h-4 w-4 text-zinc-500" viewBox="0 0 24 24" fill="none">
    <path
      d="M5.75 4.75H18.25M6.75 4.75H17.25V6C17.25 8.89949 14.8995 11.25 12 11.25C9.10051 11.25 6.75 8.8995 6.75 6V4.75Z"
      stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M9 10H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round"></path>
    <path
      d="M5.75 19.25H18.25M6.75 19.25H17.25V17.5C17.25 14.6005 14.8995 12.25 12 12.25C9.10051 12.25 6.75 14.6005 6.75 17.5V19.25Z"
      stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
  </svg>
  <p class="text-xs text-paleSky ml-2">
    {t('transactions.hud.periodTime',
      {
        startDateCurrentPeriod: formatDate(periodDescription?.startDateCurrentPeriod, userTimezone),
        endDateCurrentPeriod: formatDate(periodDescription?.endDateCurrentPeriod, userTimezone),
        startDatePreviousPeriod: formatDate(periodDescription?.startDatePreviousPeriod, userTimezone),
        endDatePreviousPeriod: formatDate(periodDescription?.endDatePreviousPeriod, userTimezone)
      })}
  </p>
</div>

<style lang="postcss">
  .timetable-info {
    @apply bg-black py-3 px-6 flex items-center mt-2 rounded-lg;
  }
</style>
<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper.js'
    import { Link } from 'svelte-routing'

    export let workspaceId: string
    export let customerId: string
    export let invoiceId: string

    function getProps({ href, isPartiallyCurrent, isCurrent }) {
      const isActive = href === 'action/' + `${workspaceId} + '/' + ${customerId} + '/' + ${invoiceId}/overview` ? isCurrent : isPartiallyCurrent || isCurrent

      if (isActive) {
        return { class: 'tab-link active' }
      }

      return { class: 'tab-link' }
    }

</script>
<div data-ut-component="view-business-document-navigation">
    <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select id="tabs" name="tabs"
                class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
            <option>{t('invoices.viewInvoice.menu.itemDetails')}</option>
            <!--<option>Paiement</option>-->
        </select>
    </div>

    <div class="hidden sm:block mt-10">
        <div class="border-b border-gray-200">
            <nav class="-mb-px px-8 flex space-x-8" aria-label="Tabs">
                <Link to="/action/{workspaceId}/{customerId}/{invoiceId}/overview"
                      {getProps}>
                    {t('todoAction.navigation.timeline')}
                </Link>
                <Link to="/action/{workspaceId}/{customerId}/{invoiceId}/tips"
                      {getProps}>
                    {t('todoAction.navigation.tips')}
                </Link>
            </nav>
        </div>
    </div>

</div>
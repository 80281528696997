import type PaginatedDisplayListConfig from '../../ui-kit/paginated-list/PaginatedListConfig'
import { t } from '../../i18n/i18nextWrapper'

export function generateListConfig(columns = []): PaginatedDisplayListConfig {
  return {
    columns: columns.length ? columns : [{
      dataKey: 'customer',
      label: t('partialInvoices.columns.client'),
      type: 'customer',
      classes: ''
    }, {
      dataKey: 'originalDocument',
      label: t('partialInvoices.columns.existingBusinessDocument'),
      type: 'partial-business-document',
      classes: ''
    }, {
      dataKey: 'newBalanceBusinessDocument',
      label: t('partialInvoices.columns.newBalanceBusinessDocument'),
      type: 'new-balance-business-document',
      classes: ''
    }],
    data: [],
    pageSize: 10
  }
}

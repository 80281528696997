import type UpdatedCompany from '../models/updated-company'
import { cryptoRandomString } from '../../core-app/util/encryption'
import { APIEntity, APIUploadCallOptions, apiUploadV2 } from '../../core-app/services/api.service'
import { get } from 'svelte/store'
import { WorkspaceStore } from '../stores/workspace.store'
import type ApiUploadArgs from '../models/api-upload-args'
import type Company from '../models/company'
import process from 'process'
import type NewCompanyLogo from '../models/new-company-logo'
import type { Workspace } from '../models/workspace'
import { deepClone } from '../../core-app/util/object-deep-cloning'
import { workspacesService } from './workspace.service'
import { ExchangeDate } from '../../core-app/models/exchange-date'
import mixpanel from 'mixpanel-browser'

/**
 * Uploads the company logo and updates the current workspace with the updated company data
 * @param changedCompany
 */
export function uploadCompanyLogoAndUpdateCurrentWorkspaceUpdatedCompany(changedCompany: UpdatedCompany): void {
  /* console.log('+*!!! onSaveCompanyChanges(changedCompany)', changedCompany) */
  if (!changedCompany) {
    /* console.log('+*!!! failed onSaveCompanyChanges(changedCompany): !changedCompany', changedCompany) */

    return
  }
  mixpanel.track('ND10 Updating Workspace', {
    'Description': 'Updating workspace in uploadCompanyLogoAndUpdateCurrentWorkspaceUpdatedCompany',
    'updatedCompanyId': changedCompany?.company?.companyId,
    'updatedCompany': changedCompany?.company?.formalName
  })
  uploadCompanyLogoAndReturnURL((get(WorkspaceStore).workspaceId), changedCompany.company.companyId, changedCompany.newCompanyLogo)
    .then((newLogoURL: string) => {
      if (newLogoURL !== '') {
        let updatedCompanyData: Company = changedCompany.company
        updatedCompanyData.emailLogoURL = newLogoURL
        WorkspaceStore.set(<Workspace>{
          ...get(WorkspaceStore),
          company: updatedCompanyData
        })
        /* console.log('workspace company logo and data successfully updated') */
        /* console.log('updatedCompanyData.emailLogoURL') */
        /* console.log(updatedCompanyData.emailLogoURL) */

      } else {
        WorkspaceStore.set(<Workspace>{
          ...get(WorkspaceStore),
          company: changedCompany.company
        })
        /* console.log('workspace company data successfully updated') */
      }
    })
    .catch((reason) => {
      console.log('reason for failed apiUploadV2', reason)
    })
}

/**
 * NEW COMPANY
 * Uploads the company logo and updates the workspace with the updated company data
 * used when updating the company data from the company settings page
 * @param changedCompany
 * @param workspaceId
 */
export function uploadCompanyLogoAndUpdateWorkspaceWithUpdatedCompany(changedCompany: UpdatedCompany, workspaceId: string): Promise<Workspace> {
  return new Promise<Workspace>((resolve, reject) => {
    mixpanel.track('NA10 Saving New Workspace', {
      'Description': 'Saving New Workspace in uploadCompanyLogoAndUpdateWorkspaceWithUpdatedCompany',
      'newWorkspaceId': workspaceId,
      'newCompanyId': changedCompany?.company?.companyId,
      'newCompany': changedCompany?.company?.formalName
    })
    /* console.log('+*!!! uploadCompanyLogoAndUpdateWorkspaceWithUpdatedCompany', changedCompany) */
    if (!changedCompany) {
      /* console.log('+*!!! failed uploadCompanyLogoAndUpdateWorkspaceWithUpdatedCompany: !changedCompany', changedCompany) */
      reject('no changed company')
    }
    uploadCompanyLogoAndReturnURL(workspaceId, changedCompany.company.companyId, changedCompany.newCompanyLogo)
      .then((newLogoURL: string) => {
        let newCompanyClone: Company = <Company>deepClone(changedCompany.company)
        if (newLogoURL !== '') {
          newCompanyClone.emailLogoURL = newLogoURL
        }
        const workspaceCompanyCreatedDate: ExchangeDate = (!!newCompanyClone?.createdDate ? newCompanyClone?.createdDate : ExchangeDate.newDate(new Date()))
        newCompanyClone.modifiedDate = ExchangeDate.newDate(new Date())
        newCompanyClone.createdDate = workspaceCompanyCreatedDate
        workspacesService.updateWorkspaceCompany(workspaceId, newCompanyClone)
          .then(() => workspacesService.fetchAllUserWorkspacesFromBackEnd())
          .then((allWorkspacesFromBackEnd: Workspace[]) => {
            /* console.log('allWorkspacesFromBackEnd', allWorkspacesFromBackEnd) */
            const updatedWorkspaceWithNewCompanyDataFreshFromBackEnd: Workspace | undefined = allWorkspacesFromBackEnd.find((aWorkspace: Workspace): boolean => aWorkspace.workspaceId === workspaceId)
            if (updatedWorkspaceWithNewCompanyDataFreshFromBackEnd) {
              resolve(updatedWorkspaceWithNewCompanyDataFreshFromBackEnd)
            } else {
              reject('No matching workspace found') // or resolve with a default value if possible
            }
          })
      })
      .catch((reason) => {
        reject('uploadCompanyLogoAndReturnURL failed: ' + reason)
      })
  })
}

/**
 * Uploads the company logo and returns the URL of the uploaded logo
 * @param workspaceId
 * @param companyId
 * @param newCompanyLogo
 */
export function uploadCompanyLogoAndReturnURL(workspaceId: string, companyId: string, newCompanyLogo: NewCompanyLogo): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    if (!!(newCompanyLogo.logoBase64) && newCompanyLogo.logoBase64 !== '') {
      /* console.log('+*!!! uploading logo for companyId', companyId) */
      const fileSpecificTag = 'email-logo'
      const storedFileNameRandomRoot = 'image-' + cryptoRandomString()
      /* console.log('+*!!! storedFileNameRandomRoot', storedFileNameRandomRoot) */
      apiUploadV2(`/workspaces/${workspaceId}/companies/${companyId}/logos/${fileSpecificTag}/target-file/${storedFileNameRandomRoot}`, null, <APIUploadCallOptions>{
        entity: APIEntity.INVOICE_FILE,
        formItems: new Map<string, ApiUploadArgs>([
          ['base64Encoded', <ApiUploadArgs>{
            isFormValue: true,
            isFileValue: false,
            contents: false
          }],
          ['companyLogoBase64', <ApiUploadArgs>{
            isFormValue: true,
            isFileValue: false,
            contents: newCompanyLogo.logoBase64
          }], // if base64Encoded === true
          ['companyLogo', <ApiUploadArgs>{
            isFormValue: false,
            isFileValue: true, // true
            contents: newCompanyLogo.logoBase64
          }], // if base64Encoded === false (raw file, not base 64 encoded)
          ['companyLogoFileName', <ApiUploadArgs>{
            isFormValue: true,
            isFileValue: false,
            contents: newCompanyLogo.logoFileName
          }],
          ['companyLogoMIMEContentType', <ApiUploadArgs>{
            isFormValue: true,
            isFileValue: false,
            contents: newCompanyLogo.logoMIMEContentType
          }],
          ['companyLogoWidthPixels', <ApiUploadArgs>{
            isFormValue: true,
            isFileValue: false,
            contents: newCompanyLogo.logoWidthPixels || 0
          }],
          ['companyLogoHeightPixels', <ApiUploadArgs>{
            isFormValue: true,
            isFileValue: false,
            contents: newCompanyLogo.logoHeightPixels || 0
          }],
          ['companyLogoLastModifiedUnixMilliseconds', <ApiUploadArgs>{
            isFormValue: true,
            isFileValue: false,
            contents: newCompanyLogo.logoFileLastModifiedUnixMilliseconds || 0
          }]
        ])
      }).then((result) => {
        console.log('Workspace Company File upload result', result)
        const fileExtension = getFileExtension(newCompanyLogo.logoMIMEContentType)
        const newEmailLogoURL = process.env.PUBLIC_FILE_BASE_URL + '/companyLogos/' + workspaceId + '/' + (companyId) + '/' + fileSpecificTag + '/' + storedFileNameRandomRoot + '.' + fileExtension
        /* console.log('workspace company logo and metadata successfully updated') */
        resolve(newEmailLogoURL)
      }).catch((reason) => {
        /* console.log('reason for failed apiUploadV2', reason) */
        reject('failed apiUploadV2: ' + reason)
      })
    } else {
      resolve('')
    }
  })
}

/**
 * Returns the file extension from the MIME content type
 * @param fileMIMEContentType
 */
function getFileExtension(fileMIMEContentType: string): string {
  const filenameParts: string[] = fileMIMEContentType.split('/')
  const fileExtension: string = filenameParts[filenameParts.length - 1].toLowerCase()
  /* console.log('*!!! fileExtension', fileExtension) */

  return fileExtension
}

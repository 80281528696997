<script lang="ts">
    import { onMount } from 'svelte'
    import { scale } from 'svelte/transition'
    import { BusinessDocument } from '../../../models/business-document'
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper.js'
    import { navigate } from 'svelte-routing'
    import type { Contact } from '../../../../crm-app/models/contact'

    /** Set current selected value */
    export let businessDocument: BusinessDocument

    /** Set list of customer contacts value */
    export let customerContacts: Contact[]

    /** Set menu state */
    let show = false

    /** Set menu wrapper DOM ref */
    let menu = null


    $: if (!!businessDocument) {
      if (!businessDocument.customerContact && !!customerContacts && (customerContacts.length > 0)) {
        businessDocument.customerContact = customerContacts[0]
      }
    }

    onMount(() => {
      const handleOutsideClick = (event: Event) => {
        if (show && !menu.contains(event.target)) {
          show = false
        }
      }

      const handleEscape = (event: KeyboardEvent) => {
        if (show && event.key === 'Escape') {
          show = false
        }
      }

      // add events when element is added to the DOM
      document.addEventListener('click', handleOutsideClick, false)
      document.addEventListener('keyup', handleEscape, false)

      // remove events when element is removed from the DOM
      return () => {
        document.removeEventListener('click', handleOutsideClick, false)
        document.removeEventListener('keyup', handleEscape, false)
      }
    })
</script>

<div class="relative cursor-pointer" bind:this={menu}>
    
    {#if !customerContacts.length}
        <button class="inline-flex justify-center w-full rounded-md text-sm py-3 px-4 bg-slate-900 text-white hover:bg-slate-700"
                on:click={() => { navigate(`/clients/${businessDocument.customerCustomer.company.companyId}/contacts/new?from=invoice&client=${businessDocument.customerCustomer.company.companyId}`) }}>
            {t('invoices.createInvoice.createContactForClient')}
        </button>
    {:else}
        <div
                class="w-full h-12 flex items-center justify-between rounded border border-athensGray px-2 py-2 overflow-hidden"
                role="button"
                tabindex="0"
                on:click|preventDefault|stopPropagation={() => (show = !show)}
                on:keydown|preventDefault|stopPropagation={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    show = !show
                  }
                }}>
            {#if businessDocument?.customerContact}
                <div class="flex items-center">
                    <div class="contact-avatar w-6 h-6">{businessDocument?.customerContact?.firstName?.substring(0, 1).toUpperCase()}{businessDocument?.customerContact?.lastName?.substring(0, 1).toUpperCase()}</div>
                    <div class="flex flex-col justify-start ml-2">
                        <h6 class="text-xxs font-bold">{businessDocument?.customerContact?.firstName || ''} {businessDocument?.customerContact?.lastName || ''}</h6>
                        <p class="text-xxs text-paleSky">{businessDocument?.customerContact?.email}</p>
                    </div>
                </div>
            {:else}
                <div class="flex items-center">
                    <div class="contact-avatar w-6 h-6">{customerContacts[0].firstName?.substring(0, 1).toUpperCase()}{customerContacts[0].lastName?.substring(0, 1).toUpperCase()}</div>
                    <div class="flex flex-col justify-start ml-2">
                        <h6 class="text-xxs font-bold">{customerContacts[0].firstName || ''} {customerContacts[0].lastName || ''}</h6>
                        <p class="text-xxs text-paleSky">{customerContacts[0].email}</p>
                    </div>
                </div>
            {/if}
            <div class="ml-2 flex justify-center items-center text-gray-700">
                <svg class="fill-current h-4 w-4 {show ? 'rotate-180 transition duration-200 ease-in-out transform' : 'transition duration-200 ease-in-out'}"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                </svg>
            </div>
        </div>
    {/if}

    {#if show}
        <div in:scale={{ duration: 100, start: 0.95 }}
             out:scale={{ duration: 75, start: 0.95 }}
             class="origin-top-right absolute right-0 w-full px-2 py-2 mt-1 bg-white border border-athensGray rounded shadow-md"
             style="z-index: 99999;">
            {#each customerContacts as contact}
                <div
                        class="flex items-center w-full px-2 py-2 text-sm leading-5 text-left rounded hover:bg-whisper"
                        role="button"
                        tabindex="0"
                         on:click|preventDefault|stopPropagation={() => {
                           show = !show
                           businessDocument.customerContact = contact
                         }}
                        on:keydown|preventDefault|stopPropagation={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            show = !show
                            businessDocument.customerContact = contact
                          }
                        }}>
                    <div class="contact-avatar w-6 h-6">{contact?.firstName?.substring(0, 1).toUpperCase()}{contact?.lastName?.substring(0, 1).toUpperCase()}</div>
                    <div class="flex flex-col justify-start ml-2">
                        <h6 class="text-xxs font-bold">{contact?.firstName || ''} {contact?.lastName || ''}</h6>
                        <p class="text-xxs text-paleSky">{contact?.position}</p>
                    </div>
                </div>
            {/each}
        </div>
    {/if}
</div>

<style lang="postcss">
    .contact-avatar {
        @apply bg-dundyOrange rounded-full p-3 flex items-center align-middle justify-center font-medium text-xxs text-white;
    }
</style>
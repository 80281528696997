import { get } from 'svelte/store'
import { t } from '../../core-app/lib/i18n/i18nextWrapper'
import type { Feedback } from '../../core-app/models/feedback'
import { APICallOptions, APIEntity, apiPost } from '../../core-app/services/api.service'
import { feedbackService } from '../../core-app/services/feedback.service'
import { ProfileStore } from '../../core-app/stores/profile.store'
import type { Contact } from '../../crm-app/models/contact'
import type { EmailAttachment } from '../models/email-attachment'
import type {
  SendGridEmailAttachment,
  SendGridEmailConfig,
  SendGridEmailPersonalization,
  SendGridEmailRecipient
} from '../models/sendgrid-email-configuration'
import { baseEmailConfig, emailTemplateIds } from './dundy-config'


class EmailServiceClass {

  /**
     * Converts a list of contacts to a list of SendGridEmailRecipient
     * required for the SendGrid API
     * @param selectedContacts
     */
  fromSelectedContactsToSendGridDestinationList = (selectedContacts: Contact[]): SendGridEmailRecipient[] => selectedContacts.map<SendGridEmailRecipient>((aSelectedContact: Contact): SendGridEmailRecipient => {
    if (((!!aSelectedContact.firstName) && (aSelectedContact.hasOwnProperty('firstName')) && (aSelectedContact.firstName !== '')) || ((!!aSelectedContact.lastName) && (aSelectedContact.hasOwnProperty('lastName')) && (aSelectedContact.lastName !== ''))) {
      return <SendGridEmailRecipient>{
        name: aSelectedContact.firstName + ((aSelectedContact.firstName !== '') && (aSelectedContact.lastName !== '') ? ' ' : '') + aSelectedContact.lastName,
        email: aSelectedContact.email
      }
    } else {
      return <SendGridEmailRecipient>{
        name: aSelectedContact.email,
        email: aSelectedContact.email
      }
    }
  })
  /**
     * Sends the welcome email to the user
     * @param emailAddress
     * @param firstName
     * @param language (get(ProfileStore).lang)
     */
  sendWelcomeEmail(emailAddress, firstName, language): Promise<any> {
    return apiPost('/dynamic-template', {
      emailSettingContent: JSON.stringify({
        ...baseEmailConfig,
        template_id: emailTemplateIds.successfulJoin,
        personalizations: [<SendGridEmailPersonalization>{
          to: [<SendGridEmailRecipient>{
            email: emailAddress,
            name: firstName
          }],
          subject: t('email.welcome.subject'),
          dynamic_template_data: {
            subject: t('email.welcome.subject'),
            firstName,
            isFrench: language === 'fr-FR' || language === 'fr'
          }
        }]
      })
    }, <APICallOptions>{
      entity: APIEntity.EMAIL,
      dontRedirectToSignInOnUnAuthorized: true
    })
      .then(response => 
      /* console.log('%c emailService sendWelcomeEmail() response', 'color: purple;', response) */
        
        response
      )
  }
  /**
     * Sends the invitation email to an invitee
     * @param emailAddress
     * @param guestFirstName
     * @param guestLastName
     * @param hostFirstName
     * @param hostLastName
     * @param language (get(ProfileStore).lang)
     */
  sendInvitationEmail(emailAddress: string, guestFirstName: string, guestLastName: string, hostFirstName: string, hostLastName: string, language: string): Promise<any> {
    return apiPost('/dynamic-template', {
      emailSettingContent: JSON.stringify({
        ...baseEmailConfig,
        template_id: emailTemplateIds.firstInvite,
        personalizations: [<SendGridEmailPersonalization>{
          to: [<SendGridEmailRecipient>{
            email: emailAddress,
            name: `${guestFirstName} ${guestLastName}`
          }],
          subject: t('email.invitation.subject'),
          dynamic_template_data: {
            subject: t('email.invitation.subject'),
            langCode: language,
            userFirstName: hostFirstName,
            userFullName: `${hostFirstName} ${hostLastName}`,
            inviteeFirstName: encodeURIComponent(guestFirstName),
            inviteeLastName: encodeURIComponent(guestLastName),
            emailAddress: encodeURIComponent(emailAddress),
            daysToExpire: 11,
            baseURL: process.env.APP_BASE_URL,
            isFrench: language === 'fr-FR' || language === 'fr'
          }
        }]
      })
    }, <APICallOptions>{
      entity: APIEntity.EMAIL,
      dontRedirectToSignInOnUnAuthorized: true
    })
      .then(response => 
      /* console.log('%c emailService sendInvitationEmail() response', 'color: purple;', response) */
        
        response
      )
  }
  /**
     * Sends the dunning email to our user's customer
     * @param toList
     * @param fromName
     * @param replyToEmail
     * @param replyToFullName
     * @param headerContent
     * @param subject
     * @param content
     * @param attachment
     */
  sendTodoActionEmail(toList: SendGridEmailRecipient[], ccList: SendGridEmailRecipient[], bccList: SendGridEmailRecipient[], fromName, replyToEmail, replyToFullName, headerContent, subject, content, attachments?: Array<EmailAttachment>): Promise<any> {
    /*if (validateEmail(emailAddress)) {*/

    const personalizations = []
    const personalization = <SendGridEmailPersonalization>{
      to: toList,
      subject,
      dynamic_template_data: {
        subject,
        emailHeader: headerContent,
        emailContent: content,
        isFrench: get(ProfileStore).lang === 'fr-FR' || get(ProfileStore).lang === 'fr'
      }
    }
    if (ccList && ccList.length) {
      personalization.cc = ccList
    }
    if (bccList && bccList.length) {
      personalization.bcc = bccList
    }
    personalizations.push(personalization)

    const mailConfig: SendGridEmailConfig = {
      ...{
        ...baseEmailConfig,
        from: {
          ...baseEmailConfig.from,
          // TODO MODIFY TO ALLOW SENDING FROM DIFFERENT EMAIL THAN ACCOUNT EMAIL
          name: fromName
        }
        // reply_to: ...whatever...,
      },
      template_id: emailTemplateIds.todoActionEmail,
      personalizations
    }

    if (!!replyToEmail && replyToEmail !== '' && !!replyToFullName && replyToFullName !== '') {
      const replyToObject = {
        email: replyToEmail,
        name: replyToFullName
      }
      mailConfig.reply_to = replyToObject
    }

    if (attachments && attachments.length) {
      mailConfig.attachments = attachments.map((emailAttachment: EmailAttachment) => ({
        filename: emailAttachment.fileName,
        type: emailAttachment.type,
        content: emailAttachment.contentBase64,
        disposition: 'attachment'
      } as SendGridEmailAttachment))
    }

    let body: { emailSettingContent: string } = {
      emailSettingContent: JSON.stringify(mailConfig)
    }

    return apiPost('/dynamic-template', body, <APICallOptions>{
      entity: APIEntity.EMAIL,
      dontRedirectToSignInOnUnAuthorized: true
    })
      .then(response => 
      /* console.log('%c emailService sendTodoActionEmail() response', 'color: purple;', response) */
        
        response
      ).catch(e => feedbackService.displayFeedback(<Feedback>{
        feedbackLevel: 'Error',
        feedbackMessage: e.message || e.detail || e
      }))
  }
}

export const emailService: EmailServiceClass = new EmailServiceClass()

/**
 * An invoice is either a CommercialInvoice or a CorrectiveInvoice
 * Finally, an invoice can have a third tag if it is a PartialBusinessDocument: DepositInvoice, IntermediateInvoice or BalanceInvoice
 *
 * In all business documents cases, it can be a PartialBusinessDocument or a FullBusinessDocument
 *
 * A Credit Note is either a CommercialCreditNote or a VoidingCreditNote
 * A CommercialCreditNote can be a CorrectiveCreditNoteToRefund or a CorrectiveCreditNoteToRefundForNextInvoice
 * A VoidingCreditNote can be a VoidingCreditNoteToSend or a VoidingCreditNoteInternal
 * TaxonomyTags possible=
 * (INVOICE)
 * ⎣_ COMMERCIAL_INVOICE
 *     ⎣_ FULL_BUSINESS_DOCUMENT
 *     ⎣_ PARTIAL_BUSINESS_DOCUMENT
 *         ⎣_ DEPOSIT_INVOICE
 *         ⎣_ INTERMEDIATE_INVOICE
 *         ⎣_ BALANCE_INVOICE
 * ⎣_ CORRECTIVE_INVOICE
 *     ⎣_ FULL_BUSINESS_DOCUMENT
 *     ⎣_ PARTIAL_BUSINESS_DOCUMENT
 *
 * (CREDIT_NOTE)
 * ⎣_ COMMERCIAL_CREDIT_NOTE
 *     ⎣_ FULL_BUSINESS_DOCUMENT
 *         ⎣_ COMMERCIAL_CREDIT_NOTE_TO_REFUND
 *         ⎣_ COMMERCIAL_CREDIT_NOTE_TO_CREDIT_ON_NEXT_INVOICE
 * ⎣_ VOIDING_CREDIT_NOTE
 *     ⎣_ VOIDING_CREDIT_NOTE_TO_SEND
 *     ⎣_ VOIDING_CREDIT_NOTE_INTERNAL
 *
 * (INTERNAL ONLY)
 * ⎣_ DELIBERATE_ERR
 * 
 * Example: {COMMERCIAL_INVOICE, PARTIAL_BUSINESS_DOCUMENT, INTERMEDIATE_INVOICE}
 */
export enum TaxonomyTag {
  COMMERCIAL_INVOICE = 'CommercialInvoice', // a usual invoice for a service/product/financial transaction between a supplier and a customer
  CORRECTIVE_INVOICE = 'CorrectiveInvoice', // an invoice aimed at voiding/compensating a credit note
  FULL_BUSINESS_DOCUMENT = 'FullBusinessDocument', // Appears if business document fully bills client - no user-applied installment - installment is exactly equal to document total /an business document whose amount covers the full range of the agreed service/product amount
  PARTIAL_BUSINESS_DOCUMENT = 'PartialBusinessDocument', // Appears if installment is active / a business document whose amount does not cover the whole agreed service/product amount but is aimed at covering an agreed instalment of a deal
  DEPOSIT_INVOICE = 'DepositInvoice', // Appears on first installment / an invoice representing the first due payment of a deal
  INTERMEDIATE_INVOICE = 'IntermediateInvoice', //Appears on subsequent installments an invoice after the first/deposit invoice and before the last/balance invoice
  BALANCE_INVOICE = 'BalanceInvoice', // Appears on last installment / an invoice representing the last/balance due payment of a deal
  COMMERCIAL_CREDIT_NOTE = 'CommercialCreditNote', // a usual credit note, i.e. an invoice representing the opposite of a commercial invoice where money is rather due by the supplier to the final customer (like a refund or a discount to an invoice that was sent to a customer, or simply a way to cancel an invoice sent to a customer)
  COMMERCIAL_CREDIT_NOTE_TO_REFUND = 'CorrectiveCreditNoteToRefund', // a credit note issued when an invoice was sent to the customer with wrong information wrong
  COMMERCIAL_CREDIT_NOTE_TO_CREDIT_ON_NEXT_INVOICE = 'CorrectiveCreditNoteToCreditOnNextInvoice', // a credit note issued when an invoice was sent to the customer with wrong information wrong
  VOIDING_CREDIT_NOTE = 'VoidingCreditNote', // used to void / cancel a finalized invoice so as to be fully compliant with EU regulation and E-Invoicing Reform of 2024
  VOIDING_CREDIT_NOTE_TO_SEND = 'VoidingCreditNoteToSend', // a compliant credit note voiding an invoice that needs to be sent to the customer for their books
  VOIDING_CREDIT_NOTE_INTERNAL = 'VoidingCreditNoteInternal', // a compliant credit note voiding an invoice that wasn't sent to the client and should only update our user's books
  DELIBERATE_ERR = 'DeliberateError',
  INTERNAL_BUSINESS_DOCUMENT = 'InternalBusinessDocument' // internal : no communicate to customers => hide in lists
}

export const GetAllTaxonomyTags = (): Array<TaxonomyTag> => [
  TaxonomyTag.COMMERCIAL_INVOICE,
  TaxonomyTag.CORRECTIVE_INVOICE,
  TaxonomyTag.FULL_BUSINESS_DOCUMENT,
  TaxonomyTag.PARTIAL_BUSINESS_DOCUMENT,
  TaxonomyTag.DEPOSIT_INVOICE,
  TaxonomyTag.INTERMEDIATE_INVOICE,
  TaxonomyTag.BALANCE_INVOICE,
  TaxonomyTag.COMMERCIAL_CREDIT_NOTE,
  TaxonomyTag.COMMERCIAL_CREDIT_NOTE_TO_REFUND,
  TaxonomyTag.COMMERCIAL_CREDIT_NOTE_TO_CREDIT_ON_NEXT_INVOICE,
  TaxonomyTag.VOIDING_CREDIT_NOTE,
  TaxonomyTag.VOIDING_CREDIT_NOTE_TO_SEND,
  TaxonomyTag.VOIDING_CREDIT_NOTE_INTERNAL,
  TaxonomyTag.DELIBERATE_ERR,
  TaxonomyTag.INTERNAL_BUSINESS_DOCUMENT
]
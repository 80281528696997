<script lang="ts">
  import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
  import Page from '$src/shared/components/templates/Page.svelte'
  import { Route, Router, navigate } from 'svelte-routing'
  import WorkflowItem from '../components/workflow/WorkflowItem.svelte'
  import WorkflowEditor from '../components/workflow/WorkflowEditor.svelte'
  import { CustomersStore } from '$src/crm-app/stores/customers.store'
  import { onDestroy, onMount } from 'svelte'
  import CheckboxList from '$src/core-app/lib/ui-kit/CheckboxList.svelte'
  import languages from '$core/lib/i18n/languages-all'
  import { getDunningWorkflow, saveDunningWorkflow, type DunningWorkflow } from '../services/dunning-workflow.service'
  import { arraySymmetricDifference } from '$src/shared/utils/array'
  import { workflowSteps, type WorkflowStep } from '../data/workflow'

  let customerId:string | null = new URL(window.location.toString()).searchParams?.get('cid')
  let locales:any[] = []
  let dunningWorkflow:DunningWorkflow | null
  let dunningWorkflowLocales:string[] = []
  let loading:boolean = false

  $:customer = customerId ? $CustomersStore.find(c => c.company.companyId === customerId) : null
  $:pageTitle = t('topMenu.nav.settings.reminderWorkflow') + (customer ? ' - ' + customer?.company?.formalName : '')

  const loadLocales = async () => {
    const resLng = await getDunningWorkflow(customerId || '')
    if (resLng) {
      dunningWorkflow = resLng.workflow
      dunningWorkflowLocales = dunningWorkflow?.prefItem?.dunningWorkflowL10ns || []
    }
    
    locales = languages.map(l => ({ ...l, checked: dunningWorkflowLocales.includes(l.value) }))
  }

  const onItemEdit = (item:WorkflowStep) => {
    navigate(`/workflow/edit/${item.id}${customerId ? '?cid=' + customerId : ''}`)
  }

  const onLocalesChange = async () => {
    if (!dunningWorkflow) return

    const localesToSave = locales.reduce((acc, cur) => {
      if (cur.checked) acc.push(cur.value)
      
      return acc
    }, [])
    
    if (arraySymmetricDifference(dunningWorkflowLocales, localesToSave).length > 0) {
      loading = true
      dunningWorkflow.prefItem.isSystemDefault = false
      dunningWorkflow.prefItem.dunningWorkflowL10ns = localesToSave

      await saveDunningWorkflow(dunningWorkflow, customerId || '')
      await loadLocales()
      loading = false
    }
  }

  // DETECT WHEN URL SEARCH PARAM CHANGE
  let originalPushState = history.replaceState

  onMount(async () => {
    originalPushState = history.replaceState

    window.history.replaceState = new Proxy(window.history.replaceState, {
      apply: (target, thisArg, argArray) => {
        if (window.location.href.includes('edit')) return // do not check on modal edit page
        customerId = new URL(window.location.origin + '/' + argArray[2]).searchParams?.get('client')
        target.apply(thisArg, argArray)
      }
    })

    await loadLocales()
  })

  onDestroy(() => {
    window.history.replaceState = originalPushState
  })
</script>

<Page title={pageTitle} subtitle={t('workflow.subtitle')}>
  <div class="text-red-500">
    TODO<br>
    - call API pour l'utilisation customer des langues<br>
    - icon du mode (mail, lettre, tel)<br>
  </div>

  {#if !customerId}
    <div class="w-2/3">
      <h2 class="text-lg font-bold mb-1">{t('template.languages')}</h2>
      <CheckboxList
        height="h-28"
        {loading}
        bind:list={locales}
        on:change={() => onLocalesChange()}
      />
    </div>
  {/if}

  <div class="mt-8 w-2/3">
    <h2 class="text-lg font-bold">Timeline</h2>
    {#each workflowSteps as s, i}
      <WorkflowItem {...s} index={i + 1} on:edit={() => onItemEdit(s)} />
    {/each}
  </div>

</Page>

<Router>
  <Route path="edit/:id" let:params>
    <WorkflowEditor stepId={params.id} {customerId} />
  </Route>
</Router>
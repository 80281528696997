<script lang="ts">

    import type Invoice from '../../../../models/invoice'

    export let invoice: Invoice

</script>

<span class="whitespace-nowrap text-s font-medium text-gray-900">
    <span>{invoice.invoiceNumber}</span>
</span>
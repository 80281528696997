<script lang="ts">
    /** Determine the direction: 'up', 'down', or 'neutral' */
    export let direction: string

    /** Determine the favorability: 'favorable', 'unfavorable', or 'neutral' */
    export let favorability: string
</script>

{#if direction === 'up'}
    <span class={favorability === 'favorable' ? 'text-aquamarine' : 'text-froly'}>
      <svg width="12" height="8">
        <path fill="currentColor" fill-rule="evenodd" d="M12 4a.666.666 0 1 1-1.333 0V2.469L7.839 5.767a.666.666 0 0 1-.849.138L4.147 4.199 1.179 7.76a.668.668 0 0 1-1.025-.853l3.333-4a.667.667 0 0 1 .856-.145l2.85 1.71 2.691-3.139H8A.666.666 0 1 1 8 0h3.333c.083 0 .165.018.243.05.033.012.058.033.088.05.035.02.072.033.103.06.006.006.01.014.015.02.037.033.062.074.09.115.022.032.048.06.063.094.017.036.022.075.032.113.012.047.026.092.028.14 0 .009.005.016.005.025V4Z"/>
      </svg>
    </span>
{:else if direction === 'down'}
    <span class={favorability === 'favorable' ? 'text-aquamarine' : 'text-froly'}>

      <svg width="12" height="8">
        <path fill="currentColor" fill-rule="evenodd" d="M12 4a.666.666 0 1 0-1.333 0v1.531L7.839 2.233a.666.666 0 0 0-.849-.138L4.147 3.801 1.179.24a.668.668 0 0 0-1.025.853l3.333 4a.667.667 0 0 0 .856.145l2.85-1.71 2.691 3.139H8A.666.666 0 1 0 8 8h3.333a.652.652 0 0 0 .243-.05c.033-.012.058-.033.088-.05.035-.02.072-.033.103-.06.006-.006.01-.014.015-.02.037-.033.062-.074.09-.115.022-.032.048-.06.063-.094.017-.036.022-.075.032-.113.012-.047.026-.092.028-.14 0-.009.005-.016.005-.025V4Z"/>
      </svg>
   </span>
{/if}

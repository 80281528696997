import { derived, type Readable } from 'svelte/store'
import type { BusinessDocument } from '$voxy/models/business-document'
import type Invoice from '$dundy/models/invoice'
import { getDefaultCurrencyAccordingToCountry } from '$core/services/currency-list'
import { getUserCountryCode } from '$core/services/countries-pure-functions'


/**
 * Service function to derive invoicesCurrency based on DunningInvoicesStore and SimpleDocumentsStore.
 * @param dunningInvoicesStore A readable store for dunning invoices.
 * @param simpleDocumentsStore A readable store for simple documents.
 * @returns A derived store for invoicesCurrency.
 */
export function deriveMostUsedCurrencyFromStores(
  dunningInvoicesStore: Readable<Invoice[]>,
  simpleDocumentsStore: Readable<BusinessDocument[]>
): Readable<string> {
  return derived(
    [dunningInvoicesStore, simpleDocumentsStore],
    ([$DunningInvoicesStore, $SimpleDocumentsStore]) => {
      const currencyFrequencyMap: Map<string, number> = new Map()

      // Tally the occurrences of each currency in DunningInvoicesStore
      $DunningInvoicesStore?.forEach(invoice => {
        const { currency } = invoice
        const frequency = currencyFrequencyMap.get(currency) || 0
        currencyFrequencyMap.set(currency, frequency + 1)
      })

      // Tally the occurrences of each currency in SimpleDocumentsStore
      $SimpleDocumentsStore.forEach(document => {
        const { currency } = document
        const frequency = currencyFrequencyMap.get(currency) || 0
        currencyFrequencyMap.set(currency, frequency + 1)
      })

      // Find the currency with the highest frequency
      let mainCurrency = ''
      let maxFrequency = 0
      currencyFrequencyMap.forEach((frequency, currency) => {
        if (frequency > maxFrequency) {
          maxFrequency = frequency
          mainCurrency = currency
        }
      })

      // Return the main currency if found, otherwise fallback to default
      return mainCurrency || getDefaultCurrencyAccordingToCountry(getUserCountryCode())
    }
  )
}

import Invoice from '../../models/invoice'
import StorageBucketObject from '../../../file-mgt-domain/models/storage-bucket-object'
import { pdfAttachmentDownload } from '../../../file-mgt-domain/services/pdf-attachment-download.api'

/***
 * Downloads the latest file attachment for a given invoice
 * @param invoice
 */
export async function invoiceAttachmentDownload(invoice: Invoice, workspaceId: string): Promise<StorageBucketObject> {
  /* console.log('invoiceAttachmentDownload', invoice) */
  const isBusinessDocumentFinalizedForVoxyDocument: boolean = (invoice.completed)

  return pdfAttachmentDownload(invoice.invoiceNumber, invoice.dataId, workspaceId, isBusinessDocumentFinalizedForVoxyDocument)
}

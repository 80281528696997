<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper.js'
    import { createEventDispatcher } from 'svelte'
    import { TodoUIDunningEscalationTabs } from '../../models/todo-ui-dunning-escalation-tabs'

    const dispatch = createEventDispatcher()
    export let isUIReady: boolean 
    export let selectedTab: TodoUIDunningEscalationTabs

      /*let tabs = document.querySelectorAll(".escalation-tab")
    let indicator: HTMLElement = document.querySelector(".indicator")
    let panels = document.querySelectorAll(".tab-panel")

    indicator.style.width = tabs[0].getBoundingClientRect().width + 'px'
    indicator.style.left = tabs[0].getBoundingClientRect().left - tabs[0].parentElement.getBoundingClientRect().left + 'px'

    tabs.forEach(tab => {
        tab.addEventListener("click", () => {
            let tabTarget = tab.getAttribute("aria-controls")

            indicator.style.width = tab.getBoundingClientRect().width + 'px'
            indicator.style.left = tab.getBoundingClientRect().left - tab.parentElement.getBoundingClientRect().left + 'px'


            panels.forEach(panel => {
                let panelId = panel.getAttribute("id")
                if (tabTarget === panelId) {
                    panel.classList.remove("invisible", "opacity-0")
                    panel.classList.add("visible", "opacity-100")
                } else {
                    panel.classList.add("invisible", "opacity-0")
                }
            })
        })
    })
*/


</script>

<div data-ut-component="todo-escalation-navigation">
    <div class="mb-1">
        <div class="sm:flex sm:items-baseline">
            <div class="flex items-center w-full">
                <nav class="relative flex rounded bg-whisper border border-athensGray px-1.5 py-1.5  mx-auto w-full"
                     role="tablist"
                     aria-label="tabs"
                >
                    <div class="absolute indicator h-11 my-auto top-0 bottom-0 left-0 rounded-full bg-white shadow-md"></div>
                    <button class="escalation-tab"
                            role="tab"
                            disabled={!isUIReady}
                            aria-selected={selectedTab === TodoUIDunningEscalationTabs.INFORMATION}
                            class:active={selectedTab === TodoUIDunningEscalationTabs.INFORMATION}
                            id="tab-1"
                            tabindex="0"
                            on:click={() => {
                              selectedTab = TodoUIDunningEscalationTabs.INFORMATION
                              dispatch('escalationChanged', { tabSelected: TodoUIDunningEscalationTabs.INFORMATION })
                            }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.25 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V5.75C4.75 5.19771 5.19772 4.75 5.75 4.75H14.25C14.8023 4.75 15.25 5.19772 15.25 5.75V10"
                                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path d="M17.5227 9.75H15.25V17.25C15.25 18.3546 16.1454 19.25 17.25 19.25C18.3546 19.25 19.25 18.3546 19.25 17.25V11.4773C19.25 10.5233 18.4767 9.75 17.5227 9.75Z"
                                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path d="M7.75 8.75C7.75 8.19772 8.19772 7.75 8.75 7.75H11.25C11.8023 7.75 12.25 8.19772 12.25 8.75V10.25C12.25 10.8023 11.8023 11.25 11.25 11.25H8.75C8.19772 11.25 7.75 10.8023 7.75 10.25V8.75Z"
                                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path d="M8 13.75H12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path d="M8 16.25H12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                        </svg>
                        <span class="lg:ml-2">{t('todoAction.dunningEscalationTab.information')}</span>
                    </button>

                    <button class="escalation-tab"
                            role="tab"
                            disabled={!isUIReady}
                            aria-selected={selectedTab === TodoUIDunningEscalationTabs.REMINDER}
                            class:active={selectedTab === TodoUIDunningEscalationTabs.REMINDER}
                            id="tab-2"
                            tabindex="-1"
                            on:click={() => {
                              selectedTab = TodoUIDunningEscalationTabs.REMINDER
                              dispatch('escalationChanged', { tabSelected: TodoUIDunningEscalationTabs.REMINDER })
                            }}>
                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="1.5"
                                  d="M4.75 6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V14.25C19.25 15.3546 18.3546 16.25 17.25 16.25H14.625L12 19.25L9.375 16.25H6.75C5.64543 16.25 4.75 15.3546 4.75 14.25V6.75Z"></path>
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  d="M9.5 11C9.5 11.2761 9.27614 11.5 9 11.5C8.72386 11.5 8.5 11.2761 8.5 11C8.5 10.7239 8.72386 10.5 9 10.5C9.27614 10.5 9.5 10.7239 9.5 11Z"></path>
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  d="M12.5 11C12.5 11.2761 12.2761 11.5 12 11.5C11.7239 11.5 11.5 11.2761 11.5 11C11.5 10.7239 11.7239 10.5 12 10.5C12.2761 10.5 12.5 10.7239 12.5 11Z"></path>
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  d="M15.5 11C15.5 11.2761 15.2761 11.5 15 11.5C14.7239 11.5 14.5 11.2761 14.5 11C14.5 10.7239 14.7239 10.5 15 10.5C15.2761 10.5 15.5 10.7239 15.5 11Z"></path>
                        </svg>
                        <span class="lg:ml-2">{t('todoAction.dunningEscalationTab.reminder')}</span>
                    </button>
                    <button class="escalation-tab"
                            role="tab"
                            disabled={!isUIReady}
                            aria-selected={selectedTab === TodoUIDunningEscalationTabs.WARNING}
                            class:active={selectedTab === TodoUIDunningEscalationTabs.WARNING}
                            id="tab-3"
                            tabindex="-1"
                            on:click={() => {
                              selectedTab = TodoUIDunningEscalationTabs.WARNING
                              dispatch('escalationChanged', { tabSelected: TodoUIDunningEscalationTabs.WARNING })
                            }}>
                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="1.5"
                                  d="M4.75 6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V14.25C19.25 15.3546 18.3546 16.25 17.25 16.25H14.625L12 19.25L9.375 16.25H6.75C5.64543 16.25 4.75 15.3546 4.75 14.25V6.75Z"></path>
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M12 8V10"></path>
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  d="M12.5 13C12.5 13.2761 12.2761 13.5 12 13.5C11.7239 13.5 11.5 13.2761 11.5 13C11.5 12.7239 11.7239 12.5 12 12.5C12.2761 12.5 12.5 12.7239 12.5 13Z"></path>
                        </svg>
                        <span class="lg:ml-2">{t('todoAction.dunningEscalationTab.warning')}</span>
                    </button>
                    <button class="escalation-tab"
                            role="tab"
                            disabled={!isUIReady}
                            aria-selected={selectedTab === TodoUIDunningEscalationTabs.LEGAL}
                            class:active={selectedTab === TodoUIDunningEscalationTabs.LEGAL}
                            id="tab-4"
                            tabindex="-1"
                            on:click={() => {
                              selectedTab = TodoUIDunningEscalationTabs.LEGAL
                              dispatch('escalationChanged', { tabSelected: TodoUIDunningEscalationTabs.LEGAL })
                            }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 14.25L5.75 10L7 8.75L7.5 9.25L10 6.75L9.5 6.25L11 4.75L15.25 9L14 10.25L13.5 9.75L10.75 12.5L11.25 13L10 14.25Z"
                                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path d="M12 12L19.25 19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path d="M4.75 18.75V19.25H13.25V18.75C13.25 17.6454 12.3546 16.75 11.25 16.75H6.75C5.64543 16.75 4.75 17.6454 4.75 18.75Z"
                                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path d="M9 8L12 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                        </svg>
                        <span class="lg:ml-2">{t('todoAction.dunningEscalationTab.legal')}</span>
                    </button>
                </nav>
            </div>
        </div>
    </div>
</div>

<style lang="postcss">
    .escalation-tab {
        @apply cursor-pointer mr-1 px-3 py-2 flex align-middle items-center justify-center whitespace-nowrap font-medium rounded-md text-zinc-400 text-s xl:text-sm border border-transparent hover:text-zinc-700 hover:bg-zinc-50 hover:border-zinc-700;
    }

    .escalation-tab.active {
        @apply flex items-center rounded-md border border-black  bg-white text-black shadow-lg ;
    }

    .escalation-tab:disabled {
        @apply cursor-not-allowed text-zinc-300 bg-zinc-50 border-zinc-200;
    }

    .indicator {
        transition: left .4s;
    }
</style>
<script lang="ts">
    import { t } from '../i18n/i18nextWrapper'
    import { createEventDispatcher, onMount } from 'svelte'
    import UpdatedFileUpload from '../../../crm-app/models/updated-file-upload'
    import FileInputDebug from './fileInputComponents/FileInputDebug.svelte'
    import ConditionalFileInputWrapper from './fileInputComponents/ConditionalFileInputWrapper.svelte'
    import { filedrop } from 'filedrop-svelte'
    import type { Files, FileDropOptions } from 'filedrop-svelte'

    // Usage like:
    /*
        <FileInput
                chooseANewFileMessageTEntry="companyEdit.selectNewLogo"
                fileLimitationsMessageTEntry="companyEdit.uploadLimitations"
                fileUploadHelpMessageTEntry="companyEdit.uploadHelp"
                cancelChangesMessageTEntry="companyEdit.resetLogo"
                timeZoneIANACode="Europe/Paris"
                languageAndCountryCode="fr-FR"
                existingFileURL={!!localCompany.emailLogoURL ? localCompany.emailLogoURL :"https://static.thenounproject.com/png/1003548-200.png"}
                existingFileName={!!localCompany.emailLogoURL ? "company logo" :"choose a company logo"}
                existingFileSizeBytes=0
                existingFileLastModifiedUnixMilliseconds=0
                acceptedExtensions=".jpg, .jpeg, .png"
                triggerNewFileSelectModal={false}
                triggerProgrammaticallyResetSelectedFile={triggerProgrammaticallyResetSelectedFile}
                addBoundingDivs={false}
                showImage={true}
                showNewUploadButton={true}
                showCancelChangesButton={true}
                showUploadHelpMessage={true}
                showAdditionalUploadHelpMessage={true}
                showAdditionalUploadHelpAlwaysMessage={true}
                showFileMetadata={false}
                specificFileValidator={companyLogoImageUploadValidator}
                newFileUploadAvailable={newFileUploadAvailable}
                on:chosenNewFileUpload={newFileUpload}
                on:discardedFileUpload={discardedFileUpload}
        />
     */

    // Reference for file upload: https://svelte.dev/repl/b17c13d4f1bb40799ccf09e0841ddd90?version=3.55.0

    export let chooseANewFileMessageTEntry: string = 'companyEdit.selectNewLogo' // defaults values, overridden by passed values
    export let fileLimitationsMessageTEntry: string = 'companyEdit.uploadLimitations' // defaults values, overridden by passed values
    export let fileUploadHelpMessageTEntry: string = 'companyEdit.uploadHelp' // defaults values, overridden by passed values
    export let cancelChangesMessageTEntry: string = 'companyEdit.resetLogo' // defaults values, overridden by passed values
    export let timeZoneIANACode: string = 'Europe/Paris' // defaults values, overridden by passed values
    export let languageAndCountryCode: string = 'fr-FR' // defaults values, overridden by passed values

    export let addBoundingDivs: boolean = true // defaults values, overridden by passed values

    export let existingFileURL: string = 'https://thumbs.dreamstime.com/b/amazon-logo-editorial-vector-illustration-market-136495269.jpg' // defaults values, overridden by passed values
    export let existingFileName: string = 'amazon-logo-editorial-vector-illustration-market-136495269.jpg' // defaults values, overridden by passed values
    export let existingFileSizeBytes: number = 14294 // defaults values, overridden by passed values
    export let existingFileLastModifiedUnixMilliseconds: number = 1672502195 * 1000 // defaults values, overridden by passed values

    export let maxFileSizeBytes: number = 1024 * 1024 * 2 // 1024*1024*2 for 2MB max // defaults values, overridden by passed values
    export let acceptedExtensions: string = '.jpg, .jpeg, .png' // defaults values, overridden by passed values

    export let triggerNewFileSelectModal: boolean = false // defaults values, overridden by passed values
    export let triggerProgrammaticallyResetSelectedFile: boolean = false // defaults values, overridden by passed values

    export let debugInfoVisible: boolean = false // defaults values, overridden by passed values
    export let showImage: boolean = true // defaults values, overridden by passed values
    export let showNewUploadButton: boolean = true // defaults values, overridden by passed values
    export let showCancelChangesButton: boolean = true // defaults values, overridden by passed values
    export let showUploadHelpMessage: boolean = true // defaults values, overridden by passed values
    export let showAdditionalUploadHelpMessage: boolean = true // defaults values, overridden by passed values
    export let showAdditionalUploadHelpAlwaysMessage: boolean = true // defaults values, overridden by passed values
    export let showFileMetadata: boolean = true // defaults values, overridden by passed values
    export let newFileUploadAvailable: boolean = false // output / read only // defaults values, overridden by passed values

    export let specificFileValidator: (fileMIMEContentType: string, fileName: string, fileSizeBytes: number, fileLastModifiedUnixMilliseconds: number) => boolean

    /** Specify a name for the cypress selector */
    export let dataCy:string = ''

    // main return from component:
    //   on:chosenNewFileUpload=<UpdatedFileUpload>{...}
    //   on:discardedFileUpload=<UpdatedFileUpload>{} (empty UpdatedFileUpload)

    let newChosenUploadedFileBase64: string | ArrayBuffer
    let newChosenUploadedFileMIMEContentType: string
    let newChosenUploadedOriginalFileName: string
    let newChosenUploadedOriginalFileSizeBytes: number
    let newChosenUploadedOriginalFileLastModifiedUnixMilliseconds: number

    let fileUploadInputButton
    const dispatch = createEventDispatcher()
    let noNewFileChosenByUserYet: boolean
    let fileDropOptions: FileDropOptions = {
      clickToUpload: false,
      fileLimit: 1,
      maxSize: maxFileSizeBytes,
      accept: acceptedExtensions
    }
    let droppedFiles: Files

    let isDraggingFiles: boolean = false

    function handleCancelChangesRemoveUploadedFile() {
      newChosenUploadedFileBase64 = ''
      newChosenUploadedFileMIMEContentType = ''
      newChosenUploadedOriginalFileName = ''
      newChosenUploadedOriginalFileSizeBytes = 0
      newChosenUploadedOriginalFileLastModifiedUnixMilliseconds = 0
      newFileUploadAvailable = false
      dispatch('discardedFileUpload', <UpdatedFileUpload>{
        fileBase64: '',
        fileMIMEContentType: '',
        fileName: '',
        fileSizeBytes: 0,
        fileLastModifiedUnixMilliseconds: 0
      })
    }

    function validateUpload(fileMIMEContentType: string, fileName: string, fileSizeBytes: number, fileLastModifiedUnixMilliseconds: number): boolean {
      if (!specificFileValidator) {
        /* console.log('no specific file validator, returning file valid=true') */

        return true
      } else {
        /* console.log('using specific file validator: specificFileValidator') */

        return specificFileValidator(fileMIMEContentType, fileName, fileSizeBytes, fileLastModifiedUnixMilliseconds)
      }
    }

    function getFileMetadataAsHTML(fileName: string, fileSizeBytes: number, fileLastModifiedUnixMilliseconds: number): string {
      let msg = []
      let finalMsg = ''
      if (!!fileSizeBytes) {
        msg.push(formatBytes(fileSizeBytes))
      } else {
        msg.push('(size n/a)')
      }
      if (!!fileLastModifiedUnixMilliseconds) {
        msg.push(formatLastModifiedDate(fileLastModifiedUnixMilliseconds))
      } else {
        msg.push('(last modified date n/a)')
      }
      if (msg.length > 0) {
        finalMsg = ' (' + msg.join(' - ') + ')'
      }
      finalMsg = fileName + finalMsg

      return finalMsg
    }

    function uploadSelectedFileFromLocalComputerToWebApp(chosenFile: File, postOperationFunction: Function) {
      if (!!chosenFile) {
        if (!validateUpload(chosenFile.type, chosenFile.name, chosenFile.size, chosenFile.lastModified)) {
          /* console.log('*!!! validateUpload(file) returned false') */

          return
        }
        const fileReader = new FileReader()
        // get ready for the outcome (event "load") of readAsDataURL:
        fileReader.addEventListener('load', function () {
          if (typeof fileReader.result === 'string') {
            newChosenUploadedFileBase64 = fileReader.result
            newChosenUploadedFileMIMEContentType = chosenFile.type
            newChosenUploadedOriginalFileName = chosenFile.name
            newChosenUploadedOriginalFileSizeBytes = chosenFile.size
            newChosenUploadedOriginalFileLastModifiedUnixMilliseconds = chosenFile.lastModified
            newFileUploadAvailable = true
            /* console.log('dispatching event chosenNewFileUpload from FileInput') */
            dispatch('chosenNewFileUpload', <UpdatedFileUpload>{
              fileBase64: fileReader.result,
              fileMIMEContentType: chosenFile.type,
              fileName: chosenFile.name,
              fileSizeBytes: chosenFile.size,
              fileLastModifiedUnixMilliseconds: chosenFile.lastModified
            })
            // allows to reset/free the UI once the upload is completed or do any necessary action once completed
            if (!!postOperationFunction) {
              postOperationFunction()
            }
          }
        })
        // now run readAsDataURL:
        fileReader.readAsDataURL(chosenFile)
        /* console.log('chosenFile', chosenFile) */
      }
    }

    function handleUploadChange(e) {
      const newChosenFile: File = e.target.files[0]
      /* console.log('*!!! e.target.files[0]', newChosenFile) */
      uploadSelectedFileFromLocalComputerToWebApp(newChosenFile, () => {
        // ref: https://stackoverflow.com/questions/73870151/upload-file-does-not-accept-the-same-file-twice-after-i-delete-it
        // allows to choose the same file back again over and over (otherwise, the input would not see any change and would not trigger its events)
        e.target.value = null
      })
    }

    // Ref: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
    function formatBytes(bytes, decimals = 2) {
      if (!+bytes) {
        return '0 Bytes'
      }
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    function formatLastModifiedDate(lastModifiedDateUnixMilliseconds: number): string {
      const dateFromLastModifiedDateUnixMilliseconds = new Date(lastModifiedDateUnixMilliseconds)
      // ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#options
      let options = { dateStyle: 'medium', timeStyle: 'long', timeZone: timeZoneIANACode }

      return new Intl.DateTimeFormat(languageAndCountryCode, options).format(dateFromLastModifiedDateUnixMilliseconds)
    }

    $: {
      noNewFileChosenByUserYet =
                (!newChosenUploadedFileBase64 || newChosenUploadedFileBase64 === '')
                && (!!existingFileURL || existingFileURL !== '')
    }

    $: {
      if (triggerNewFileSelectModal) {
        /* console.log('+*!!! triggerNewFileSelectModal === true action in FileInput') */
        setTimeout(() => {
          fileUploadInputButton.click()
          triggerNewFileSelectModal = false
          /* console.log('+*!!! triggerNewFileSelectModal = false in FileInput') */
        }, 100)
      }
    }

    $: {
      if (triggerProgrammaticallyResetSelectedFile) {
        /* console.log('+*!!! triggerProgrammaticallyResetSelectedFile === true action in FileInput') */
        setTimeout(() => {
          handleCancelChangesRemoveUploadedFile()
          triggerProgrammaticallyResetSelectedFile = false
          /* console.log('+*!!! triggerProgrammaticallyResetSelectedFile = false in FileInput') */
        }, 100)
      }
    }

    onMount(() => {


      newFileUploadAvailable = false
    })
</script>


<div data-ut-component="file-input">
    <ConditionalFileInputWrapper condition={addBoundingDivs}>

        <div class="{isDraggingFiles ? 'dragging-ongoing' : 'dragging-stopped'}"
             use:filedrop={fileDropOptions}
             on:filedragover={()=>{isDraggingFiles = true}}
             on:filedragleave={()=>{isDraggingFiles = false}}
             on:filedrop={(e) => {
               droppedFiles = e.detail.files
               isDraggingFiles = false
               /* console.log('files dropped:', e.detail.files) */
               /* console.log('file dropped kept:', e.detail.files.accepted[0]) */
               uploadSelectedFileFromLocalComputerToWebApp(
                 e.detail.files.accepted[0],
                 ()=>{
                   fileDropOptions.disabled = true
                   setTimeout(()=>{
                     fileDropOptions.disabled = false
                   }, 200)
                 },
               )
             }}
        >


            {#if debugInfoVisible}
                <FileInputDebug
                        existingFileURL={existingFileURL}
                        newChosenUploadedFileBase64={newChosenUploadedFileBase64}
                />
            {/if}


            <!-- [component] the file preview -->
            {#if noNewFileChosenByUserYet}
                <!-- replace existing file UI + input file + current file -->
                <!-- display file: if we happen to have an existing file-->
                {#if (!!existingFileURL || existingFileURL !== '')}
                    {#if showImage}
                        <div class="relative rounded-lg text-center border border-athensGray border-dotted flex justify-center items-center overflow-hidden w-full h-32 w-32">
                            <img class="rounded-lg overflow-hidden flex align-middle relative z-10 object-scale-down h-full w-full"
                                 data-ut-img="file-input-existing"
                                 src={existingFileURL}
                                 on:click|preventDefault|stopPropagation={()=>{
                                   /* console.log('*!!! click') */
                                   fileUploadInputButton.click()
                                 }}
                                 alt="upload"/>
                        </div>
                    {/if}
                {/if}
            {:else}
                <!-- specific UI when we have just chosen a new file
                    - new image if the uploaded file is an image
                -->
                {#if !!newChosenUploadedFileBase64}
                    {#if showImage}
                        <div class="relative rounded-lg text-center border border-athensGray border-dotted flex justify-center items-center overflow-hidden w-full h-32 w-32">
                            <img class="rounded-lg overflow-hidden flex align-middle relative z-10 object-scale-down h-full w-full"
                                 data-ut-img="file-input-new"
                                 src={newChosenUploadedFileBase64}
                                 on:click|preventDefault|stopPropagation={()=>{
                                   /* console.log('*!!! click') */
                                   fileUploadInputButton.click()
                                 }}
                                 alt="upload"/>
                        </div>
                    {/if}
                {/if}
            {/if}


            <!-- [component] the file data info -->
            {#if noNewFileChosenByUserYet}
                <!-- replace existing file UI + input file + current file -->
                <!-- display file: if we happen to have an existing file-->
                {#if (!!existingFileURL || existingFileURL !== '')}
                    {#if showFileMetadata}
                        <div class="relative rounded-lg text-center border border-athensGray border-dotted flex justify-center items-center w-full w-96"
                             on:click|preventDefault|stopPropagation={()=>{/* console.log('*!!! click'); fileUploadInputButton.click() */}}
                        >
                            {#if !!existingFileName}
                                <div>{ getFileMetadataAsHTML(
                                  existingFileName,
                                  existingFileSizeBytes,
                                  existingFileLastModifiedUnixMilliseconds) }
                                </div>
                            {:else}
                                <div>no file chosen yet</div>
                            {/if}
                        </div>
                    {/if}
                {/if}
            {:else}
                <!-- specific UI when we have just chosen a new file
                    - show file metadata (name, size, last modified date)
                -->
                {#if !!newChosenUploadedFileBase64}
                    {#if showFileMetadata}
                        <div class="relative rounded-lg text-center border border-athensGray border-dotted flex justify-center items-center overflow-hidden w-full w-96"
                             on:click|preventDefault|stopPropagation={()=>{/* console.log('*!!! click'); fileUploadInputButton.click() */}}
                        >
                            {#if !!newChosenUploadedOriginalFileName}
                                <div>{ getFileMetadataAsHTML(
                                  newChosenUploadedOriginalFileName,
                                  newChosenUploadedOriginalFileSizeBytes,
                                  newChosenUploadedOriginalFileLastModifiedUnixMilliseconds) }
                                </div>
                            {:else}
                                <div>no file chosen yet</div>
                            {/if}
                        </div>
                    {/if}
                {/if}
            {/if}


            <div class="flex flex-col ml-5 space-y-3">


                <!-- [component] the action buttons -->
                {#if noNewFileChosenByUserYet}
                    <!-- replace existing file UI + current file -->
                    {#if showNewUploadButton}
                        <!-- trick where we use a simple button that clicks on the actual input button -->
                        <!-- <label for="file-upload"-->
                        <!--        class="btn action-default cursor-pointer w-64">-->
                        <!--     {t(chooseANewFileMessageTEntry)}-->
                        <!-- </label>-->
                        <button
                                class="inline-flex items-center justify-center rounded-md cursor-pointer bg-black text-white border border-black hover:bg-black hover:text-white focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 shadow-sm px-4 py-2 text-sm font-medium sm:w-auto"
                                on:click|preventDefault|stopPropagation={()=>{ fileUploadInputButton.click()}}
                        >
                            <span>{t(chooseANewFileMessageTEntry)}</span>
                        </button>
                    {/if}
                {:else}
                    <!-- specific UI when we have just chosen a new file
                        - button to remove new file / cancel changes
                    -->
                    {#if showCancelChangesButton}
                        <button on:click={handleCancelChangesRemoveUploadedFile}
                                class="btn action-default cursor-pointer h-8 w-64">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.75 7.75L6.59115 17.4233C6.68102 18.4568 7.54622 19.25 8.58363 19.25H14.4164C15.4538 19.25 16.319 18.4568 16.4088 17.4233L17.25 7.75H5.75Z"
                                      stroke="currentColor" stroke-width="1.5"
                                      stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M9.75 10.75V16.25" stroke="currentColor" stroke-width="1.5"
                                      stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M13.25 10.75V16.25" stroke="currentColor"
                                      stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                <path d="M8.75 7.75V6.75C8.75 5.64543 9.64543 4.75 10.75 4.75H12.25C13.3546 4.75 14.25 5.64543 14.25 6.75V7.75"
                                      stroke="currentColor" stroke-width="1.5"
                                      stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M4.75 7.75H18.25" stroke="currentColor" stroke-width="1.5"
                                      stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>

                            <span>{t(cancelChangesMessageTEntry)}</span>
                        </button>
                    {/if}
                {/if}


                <!-- [component] the invisible input box, no need to move it around, it is invisible -->
                <!-- trick where this input button is bound to fileUploadInputButton for other components to manipulate it -->
                <!-- input is hidden and controlled by other ui components -->
                <input id="file-upload"
                       style="display:none"
                       name="file-upload"
                       type="file"
                       accept={acceptedExtensions}
                       class="sr-only"
                       bind:this={fileUploadInputButton}
                       data-cy="{dataCy}"
                       on:change={handleUploadChange}
                />


                <p class="text-sm text-black dark:text-gray-400 w-96">


                    <!-- [component] the upload help message -->
                    <!-- replace existing file UI + input file + current file -->
                    {#if showUploadHelpMessage}
                        <!-- bottom text on file expected specifications -->
                        <div class="text-sm text-black dark:text-gray-400 w-96 my-0">{t(fileUploadHelpMessageTEntry)}</div>
                    {/if}


                    <!-- [component] the additional help message -->
                    {#if noNewFileChosenByUserYet || showAdditionalUploadHelpAlwaysMessage}
                        <!-- replace existing file UI + input file + current file -->
                        {#if showAdditionalUploadHelpMessage || showAdditionalUploadHelpAlwaysMessage}
                            <!-- bottom text on file expected specifications -->
                            <div class="text-s text-slate-500 dark:text-gray-400 w-96 my-0">{t(fileLimitationsMessageTEntry)}</div>
                        {:else}
                            <!-- keep same text but transparent to hide it still keep same real estate -->
                            <div class="text-s text-black dark:text-gray-400 w-96 my-0"
                                 style="color: rgba(156, 163, 175, 0);">{t(fileLimitationsMessageTEntry)}</div>
                        {/if}
                    {:else}
                        <!-- keep same text but transparent to hide it still keep same real estate -->
                        <div class="text-s text-black dark:text-gray-400 w-96 my-0"
                             style="color: rgba(156, 163, 175, 0);">{t(fileLimitationsMessageTEntry)}</div>
                    {/if}


                </p>
            </div>


        </div>


    </ConditionalFileInputWrapper>
</div>


<style lang="postcss">
    /* dragging area: dragging ongoing style */
    .dragging-ongoing {
        @apply rounded-md border border-dashed border-loblolly bg-whisper;
    }

    /* dragging area: dragging stopped style */
    .dragging-stopped {
        @apply flex;
    }
</style>

<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import { createEventDispatcher } from 'svelte'
    import { TodoItemMessageKind } from '../../models/todo-list-item.js'
    import { TodoUIDunningEscalationTabs } from '../../models/todo-ui-dunning-escalation-tabs'

    const dispatch = createEventDispatcher()

    export let isUIReady: boolean
    export let activeItemMessageTab: TodoItemMessageKind
    export let activeDunningEscalationTab: TodoUIDunningEscalationTabs

</script>
{#if activeDunningEscalationTab !== TodoUIDunningEscalationTabs.LEGAL}
    <div data-ut-component="todo-action-navigation">
        <div class="bg-whisper border border-athensGray rounded">
            <div class="nav-actions">
                <button class:active={activeItemMessageTab === TodoItemMessageKind.EMAIL}
                        disabled={!isUIReady}
                        on:click={() => {
                          activeItemMessageTab = TodoItemMessageKind.EMAIL
                          dispatch('actionKindChanged', { tabSelected: TodoItemMessageKind.EMAIL })
                        }}>
                    <i class="icon-mail text-xl leading-none mr-1"></i> {t('todoAction.dunningMeansTab.email')}
                </button>
                <button class:active={activeItemMessageTab === TodoItemMessageKind.PHONE_CALL }
                        disabled={!isUIReady}
                        on:click={() => {
                          activeItemMessageTab = TodoItemMessageKind.PHONE_CALL
                          dispatch('actionKindChanged', { tabSelected: TodoItemMessageKind.PHONE_CALL })
                        }}>
                    <i class="icon-phone text-xl leading-none mr-1"></i> {t('todoAction.dunningMeansTab.phoneCall')}
                </button>
                <button class:active={activeItemMessageTab === TodoItemMessageKind.EMAIL_AND_REGULAR_POST_MAIL}
                        disabled={!isUIReady}
                        on:click={() => {
                          activeItemMessageTab = TodoItemMessageKind.EMAIL_AND_REGULAR_POST_MAIL
                          dispatch('actionKindChanged', { tabSelected: TodoItemMessageKind.EMAIL_AND_REGULAR_POST_MAIL })
                        }}>
                    <i class="icon-mailbox text-xl leading-none mr-1"></i> {t('todoAction.dunningMeansTab.postalLetter')}
                </button>

            </div>
            {#if activeItemMessageTab === TodoItemMessageKind.PHONE_CALL}
                <hr>
                <p class="text-xxs text-paleSky text-center px-1.5 py-2">{t('todoAction.copyExplainerPhoneCall')}</p>
            {/if}
        </div>
    </div>
{/if}
<style lang="postcss">
    .nav-actions {
        @apply flex align-middle items-center px-1.5 py-1.5 space-x-6;
    }

    .nav-actions button {
        @apply cursor-pointer px-2 py-1 flex align-middle items-center justify-center text-s leading-none opacity-30 text-black border border-transparent;
    }

    .nav-actions button:disabled,
    .nav-actions button[disabled],
    .nav-actions button.active:disabled {
        @apply cursor-not-allowed text-zinc-300 bg-zinc-50 border-zinc-200;
    }

    .nav-actions button.active {
        @apply text-black bg-white border border-black shadow-sm rounded opacity-100;
    }
</style>
<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import { WorkspaceListStore } from '../../stores/workspace.store'
    import type { Workspace } from '../../models/workspace'
    import { workspacesService } from '../../services/workspace.service'
    import { onMount } from 'svelte'
    import auth from '../../../core-app/lib/auth0authentication/authService.js'
  import customLog from '$src/shared/services/custom-log.service'

    onMount(() => {
      /* console.log('mounted SelectWorkspace') */
    })

    /** Functions **/
    const handleSelectWorkspace = (workspace: Workspace) => {
      // NewWorkspaceRequestedByUserWorkspaceId.set(workspace.workspaceId);
      // stateManagementService.reset("in SelectWorkspace.svelte !!!!!!, chosen new workspace and currently running handleSelectWorkspace()");
      // navigate("/today");
      // feedbackService.displayFeedback(<Feedback>{
      //     feedbackLevel: 'Success',
      //     feedbackMessage: t('workspaces.selectSuccess')
      // });é
      workspacesService.changeToSelectedWorkspace(workspace.workspaceId, '/today', true, 'workspaces.selectSuccess')
    }

    const onLogout = async () => {
      await customLog.closeJourney('LogoutFormWorkspaceSelection')
      auth.logout()
    }
</script>

<div class="relative z-[100]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-white transition-opacity"></div>
    <div class="fixed inset-0 z-90 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left  transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                    <svg class="mx-auto items-center justify-center " width="48" height="48" viewBox="0 0 24 24"
                         fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 7.33333C5 6.04467 6.04467 5 7.33333 5H9.66667V9.66667H5V7.33333Z"
                              stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                        <path d="M18.9999 7.33333C18.9999 6.04467 17.9552 5 16.6666 5H14.3333V9.66667H18.9999V7.33333Z"
                              stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                        <path d="M18.9999 12C18.9999 13.2887 17.9552 14.3334 16.6666 14.3334H14.3333V9.66669H18.9999V12Z"
                              stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                        <path d="M5 16.6666C5 17.9553 6.04467 19 7.33333 19H9.66667V14.3333H5V16.6666Z"
                              stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                        <path d="M14.3334 16.6666C14.3334 17.9553 13.2887 19 12.0001 19H9.66675V14.3333H14.3334V16.6666Z"
                              stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                        <path d="M5 9.66669H9.66667V14.3334H5V9.66669Z" stroke="currentColor" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M9.66675 5H14.3334V9.66667H9.66675V5Z" stroke="currentColor" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M9.66675 9.66669H14.3334V14.3334H9.66675V9.66669Z" stroke="currentColor"
                              stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    <div class="text-center">
                        <h3 class="text-lg font-medium leading-6 text-gray-900"
                            id="modal-title">{t('workspaces.selectWorkspace')}</h3>
                        <div class="mt-6">
                            <fieldset>
                                <legend class="sr-only">{t('workspaces.selectWorkspace')}</legend>
                                <div class="space-y-4">
                                    {#if !!$WorkspaceListStore}
                                        {#each $WorkspaceListStore as workspace}
                                            <div class="workspace-selection-item"
                                                 on:click={() => { handleSelectWorkspace(workspace) }}>
                                            <span class="flex items-center">
                                                {#if workspace?.company?.emailLogoURL }
                                                    <img class="inline-block h-8 w-8 rounded-md mr-3"
                                                         src={workspace?.company?.emailLogoURL}
                                                         alt="{workspace?.company?.formalName}"/>
                                                {:else}
                                                    <div class="main-label-initials flex flex-wrap content-center justify-center h-12 w-12 bg-zinc-700 text-white rounded-md border border-solid border-black mr-3">
                                                        <span>{workspace?.company?.formalName?.substring(0, 1).toUpperCase()}</span>
                                                    </div>
                                                {/if}
                                                <span class="flex flex-col text-sm">
                                                    <span id="server-size-0-label"
                                                          class="font-bold  text-left text-lg">{workspace?.company?.formalName}</span>
                                                    <span id="server-size-0-description-0" class="text-gray-500">
                                                        <span class="block sm:inline">
                                                          {
                                                            workspace?.company?.regulatory?.frRegulScope?.inseeData?.legalForm?.level3CatFr ||
                                                            workspace?.company?.legalStructure?.legalStructureName
                                                          }
                                                          </span>
                                                    </span>
                                                </span>
                                            </span>
                                            </div>
                                        {/each}
                                    {/if}
                                </div>
                            </fieldset>
                        </div>
                        <div class="w-full mt-4 py-1 cursor-pointer justify-center" role="none">
                            <a class="cursor-pointer text-gray-400 flex w-full px-4 py-2 text-sm leading-5 text-center justify-center hover:text-black"
                               on:click={() => onLogout()}
                               role="menuitem"
                               tabindex="3">{t('workspaces.logout')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<style lang="postcss">
    .workspace-selection-item {
        @apply cursor-pointer relative block rounded-lg border-2 bg-white px-6 py-4 hover:bg-whisper hover:shadow-sm hover:text-black hover:border-black focus:outline-none sm:flex sm:justify-between;
    }

    .workspace-selection-item:hover .main-label-initials,
    .workspace-selection-item:focus .main-label-initials {
        @apply border-whisper;
    }
</style>

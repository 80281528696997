import { t } from '$src/core-app/lib/i18n/i18nextWrapper'

export type Placeholder = {
  key: string,
  value:string,
  fields?: string[],
  information?: string
}

export type Placeholders = Placeholder[]

export const placeholders:Placeholders = [
  {
    key: '{amount}',
    value: t('template.variable.keywords.amount'),
    fields: ['letterSubject', 'subject', 'callCanevas', 'letterBody', 'body', 'phone'] }, 
  {
    key: '{clientName}',
    value: t('template.variable.keywords.clientName'),
    fields: ['letterSubject', 'subject', 'callCanevas', 'letterBody', 'body', 'phone'] }, 
  {
    key: '{clientSurname}',
    value: t('template.variable.keywords.clientSurname'),
    fields: ['letterSubject', 'subject', 'callCanevas', 'letterBody', 'body', 'phone'] }, 
  {
    key: '{companyAddress}',
    value: t('template.variable.keywords.companyAddress'),
    fields: ['letterSubject', 'subject', 'callCanevas', 'letterBody', 'body', 'phone'] },
  {
    key: '{companyName}',
    value: t('template.variable.keywords.companyName'),
    fields: ['letterSubject', 'subject', 'callCanevas', 'letterBody', 'body', 'phone'] }, 
  {
    key: '{invoiceNumber}',
    value: t('template.variable.keywords.invoiceNumber'),
    fields: ['letterSubject', 'subject', 'callCanevas', 'letterBody', 'body'] },
  {
    key: '{issueDate}',
    value: t('template.variable.keywords.issueDate'),
    fields: ['letterSubject', 'subject', 'callCanevas', 'letterBody', 'body'] }, 
  {
    key: '{mailCommercial}',
    value: t('template.variable.keywords.mailCommercial'),
    fields: [
      'emailFrom', 'emailsTo', 'emailsCC', 'emailsCCI', 'contactDestination', 'mailAddressDestination', 'letterSubject', 'subject', 'callCanevas', 'letterBody', 'body'
    ] },
  {
    key: '{mailContact}',
    value: t('template.variable.keywords.mailContact'),
    fields: ['emailFrom', 'emailsTo', 'emailsCC', 'emailsCCI', 'letterSubject', 'subject', 'callCanevas', 'letterBody', 'body'] },
  {
    key: '{maturityDate}',
    value: t('template.variable.keywords.maturityDate'),
    fields: ['letterSubject', 'subject', 'callCanevas', 'letterBody', 'body'] }, 
  {
    key: '{phoneContact}',
    value: t('template.variable.keywords.phoneContact'),
    fields: ['letterSubject', 'subject', 'callCanevas', 'letterBody', 'body', 'phone'] },
  {
    key: '{remainingAmount}',
    value: t('template.variable.keywords.remainingAmount'),
    fields: ['letterSubject', 'subject', 'callCanevas', 'letterBody', 'body', 'phone']
  } 
]
import type { AgingBalanceContent } from '../../../models/aging-balance'
import { AppliedDunningFilters } from '../../../models/applied-dunning-filters'

/**
 * Update the styles of the filter bar
 * @param filters
 * @param totalAmountsForAllClientsAndTimeSlots
 */
export function updateFilterBarStyles(
  filters: AppliedDunningFilters,
  totalAmountsForAllClientsAndTimeSlots: AgingBalanceContent,
): AppliedDunningFilters {
  if (totalAmountsForAllClientsAndTimeSlots) {
    // First pass: create all the bar objects with their widths
    for (let filterName in filters) {
      if (filters.hasOwnProperty(filterName)) {
        let filterData = filters[filterName]
        filterData.width = calculateWidthBarForDunningFilterSelection(filterData.totalAmount, totalAmountsForAllClientsAndTimeSlots)
      }
    }

    // Second pass: determine the first and last visible bars
    const allFilters: any[] = Object.values(filters)
    const firstVisibleFilterIndex: number = allFilters.findIndex(filterData => filterData.width !== '0%')
    if (firstVisibleFilterIndex !== -1) {
      allFilters[firstVisibleFilterIndex].isFirstVisible = true
    }
    const lastVisibleFilterIndex: number = allFilters.slice().reverse().findIndex(filterData => filterData.width !== '0%')
    if (lastVisibleFilterIndex !== -1) {
      const actualLastVisibleIndex: number = allFilters.length - 1 - lastVisibleFilterIndex
      allFilters[actualLastVisibleIndex].isLastVisible = true
    }
  }
  
  return filters
}

/**
 * Calculate the width of the bar
 * @param value
 * @param totalAmountsForAllClientsAndTimeSlots
 */
function calculateWidthBarForDunningFilterSelection(value: string, totalAmountsForAllClientsAndTimeSlots: AgingBalanceContent): string | '0%' {
  const total: number = totalAmountsForAllClientsAndTimeSlots.outstandingAmount
  
  return total > 0 ? `${ Math.round((Number(value) / total) * 100) }%` : '0%'
}

<script lang="ts">
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
    import { createEventDispatcher, onMount } from 'svelte'

    const dispatch = createEventDispatcher()

    /** Set menu state */
    let open = false

    /** Set menu wrapper DOM ref */
    let menu = null

    function addInvoice() {
      dispatch('addDundyInvoice')
    }

    function quickAddInvoice() {
      dispatch('quickAddDundyInvoice')
    }

    /*function importByOCR() {
        dispatch('importByOCR');
    }*/

    onMount(() => {
      const handleOutsideClick = (event: Event) => {
        if (open && !menu.contains(event.target)) {
          open = false
        }
      }

      const handleEscape = (event: KeyboardEvent) => {
        if (open && event.key === 'Escape') {
          open = false
        }
      }

      // add events when element is added to the DOM
      document.addEventListener('click', handleOutsideClick, false)
      document.addEventListener('keyup', handleEscape, false)

      // remove events when element is removed from the DOM
      return () => {
        document.removeEventListener('click', handleOutsideClick, false)
        document.removeEventListener('keyup', handleEscape, false)
      }
    })
</script>

<div class="inline-flex rounded-md shadow-sm" bind:this={menu}>
    <button type="button"
            on:click|preventDefault|stopPropagation={addInvoice}
            class="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 font-medium text-s ring-1 ring-inset ring-athensGray hover:bg-gray-50 focus:z-10">{t('dunning.actionMenu.existingInvoice')}</button>
    <div class="relative -ml-px block">
        <button on:click|preventDefault|stopPropagation={() => {open = !open}}
                type="button"
                class="h-full relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-athensGray hover:bg-gray-50 focus:z-10" id="option-menu-button" aria-expanded="true" aria-haspopup="true">
            <span class="sr-only">Open options</span>
            <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" aria-hidden="true">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.25 10.75L12 14.25L8.75 10.75"></path>
            </svg>
        </button>

        <div class="absolute right-0 z-10 -mr-1 mt-2 px-2 py-1 w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none {open ? 'block transform opacity-100 scale-100' : 'hidden transform opacity-0 scale-95' }" role="menu" aria-orientation="vertical" aria-labelledby="option-menu-button" tabindex="-1">
            <div class="py-1" role="none">
                <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                <button
                        data-cy="add-new-invoice"
                        on:click={addInvoice}
                        class="w-full group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-whisper rounded-md" role="menuitem" tabindex="-1" id="option-menu-item-0">{t('dunning.actionMenu.existingInvoice')}</button>
                <button data-cy="quick-add-new-invoice"
                        on:click={quickAddInvoice}
                        class="w-full group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-whisper rounded-md"
                        role="menuitem"
                        tabindex="-1"
                        id="option-menu-item-1">{t('dunning.actionMenu.quickAddInvoiceExcerpt')}</button>
            </div>
        </div>
    </div>
</div>


import './app.css'
import App from './App.svelte'
import AppMobile from './AppMobile.svelte'
import * as Sentry from '@sentry/svelte'
import { captureConsoleIntegration, contextLinesIntegration } from '@sentry/integrations'

import { stateManagementService } from './core-app/services/state-management.service'
import mixpanel from 'mixpanel-browser'

if (process.env.APP_ENV !== 'local') {
  Sentry.init({
    release: `${process.env.APP_NAME_FOR_SENTRY}@${process.env.APP_VERSION}`,
    dsn: 'https://8b5fb4c37d94ee238045cdcfbd8b3a93@o4505905883840512.ingest.sentry.io/4505905990467584',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true
      }),
      captureConsoleIntegration({ levels: ['error'] }),
      contextLinesIntegration({ frameContextLines: 7 })
    ],
    tracePropagationTargets: ['127.0.0.1', /^https:\/\/u-trade-io.sentry\.io\/api/],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.APP_ENV
  })
}

mixpanel.init('89cd81c64eaf747900565790d443d689', 
  {
    debug: (process.env.APP_ENV !== 'prod remote'),
    track_pageview: true,
    persistence: 'localStorage'
  })

mixpanel.register({
  'origin': 'dundy.co',
  'appVersion': process.env.APP_VERSION,
  'appEnv': process.env.APP_ENV
})

stateManagementService.initialize('in main.ts before instantiating App.svelte !!!!!!')

// CHECK IF MOBILE DEVICE
const isMobile = Math.min(window.screen.width, window.screen.height) < 600 && navigator.userAgent.indexOf('Mobi') > -1

let app: App 

if (isMobile) {
  app = new AppMobile({
    target: document.getElementById('app') as HTMLElement
  })
} else {
  app = new App({
    target: document.getElementById('app') as HTMLElement
  })
}

export default app

import { JsonProperty } from '@paddls/ts-serializer'
import type { ApiOkStatusResponse } from '../../core-app/models/api-standard-responses'
import { ExchangeDate } from '../../core-app/models/exchange-date'
import { v4 as uuidv4 } from 'uuid'

export class Contact {
  @JsonProperty('contactId') contactId: string
  @JsonProperty('companyId') companyId: string
  @JsonProperty('firstName') firstName: string
  @JsonProperty('lastName') lastName: string
  @JsonProperty('department') department: string
  @JsonProperty('email') email: string
  @JsonProperty('officePhone') officePhone: string
  @JsonProperty('mobilePhone') mobilePhone: string
  @JsonProperty('position') position: string
  @JsonProperty('isWorkspaceMember') isWorkspaceMember: boolean
  /**
     * @deprecated phone is replaced by officePhone and mobilePhone
     */
  @JsonProperty('phone') phone: string
  @JsonProperty({ field: 'createdDate', type: () => ExchangeDate }) createdDate: ExchangeDate
  @JsonProperty({ field: 'modifiedDate', type: () => ExchangeDate }) modifiedDate: ExchangeDate
  static readonly empty = (id?:string, companyId?:string):Contact => <Contact>{
    contactId: id || uuidv4(),
    companyId: companyId ?? '',
    firstName: '',
    lastName: '',
    department: '',
    email: '',
    officePhone: '',
    mobilePhone: '',
    phone: '',
    position: '',
    createdDate: ExchangeDate.newDate(new Date()),
    modifiedDate: ExchangeDate.newDate(new Date())
  }
}

export class ApiContactCreated {
  @JsonProperty('contactuuid') contactuuid: string
  @JsonProperty('mongoReturned') mongoReturned: ApiOkStatusResponse
}
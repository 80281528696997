<script lang="ts">
  import { t } from '$src/core-app/lib/i18n/i18nextWrapper.js'
  import { toast } from '@zerodevx/svelte-toast'
  import { generateAndDownloadFECFile } from '$src/dundy-app/services/generate-FEC-file'
  import { generateAndDownloadZIPFile } from '$src/dundy-app/services/generate-ZIP-file'
  import { onMount } from 'svelte'
  import mixpanel from 'mixpanel-browser'

  const currentYear = new Date().getFullYear()
  const firstDayOfYear = new Date(currentYear, 0, 2).toISOString().substring(0, 10)
  const now = new Date().toISOString().substring(0, 10)

  const zip = {
    startDate: firstDayOfYear,
    endDate: now,
    loading: false
  }
  const fec = {
    startDate: firstDayOfYear,
    endDate: now,
    invoice: true,
    creditNote: true,
    loading: false
  }

  $:_zip = {
    startDate: zip.startDate.toString().replaceAll('-', ''),
    endDate: zip.endDate.toString().replaceAll('-', ''),
    error: zip.startDate > zip.endDate ? t('exports.errorDate') : ''
  }
  $:_fec = {
    startDate: fec.startDate.toString().replaceAll('-', ''),
    endDate: fec.endDate.toString().replaceAll('-', ''),
    error: fec.startDate > fec.endDate ? t('exports.errorDate') : !fec.invoice && !fec.creditNote ? t('exports.errorCheck') : ''
  }

  const onExportZIP = async () => {
    zip.loading = true
    const res = await generateAndDownloadZIPFile(_zip.startDate, _zip.endDate)
    if (!res) toast.push(t('exports.toastEmpty'))
    zip.loading = false
  }

  const onExportFEC = () => {
    fec.loading = true
    const res = generateAndDownloadFECFile(_fec.startDate, _fec.endDate, fec.invoice, fec.creditNote)
    if (!res) toast.push(t('exports.toastEmpty'))
    fec.loading = false
  }

  onMount(()=>{
    mixpanel.track('MA10 Exports Page', {
      'Description': 'Open Exports.svelte'
    })
  })
</script>

<svelte:head>
    <title>{t('topMenu.nav.exports')} - Dundy</title>
</svelte:head>

<main class="container mx-auto flex flex-col items-center">
    <div class="flex w-full flex-col">
        <h1 class="text-black text-3xl font-bold">{t('exports.pageTitle')}</h1>

        <section>
            <h2>{t('exports.zip')}</h2>

            <div>
                <label class="w-40" for="zipStartDate">Date de départ</label>
                <input bind:value={zip.startDate} class="w-44" id="zipStartDate" type="date">
            </div>
            <div>
                <label class="w-40" for="zipEndDate">Date de fin</label>
                <input bind:value={zip.endDate} class="w-44" id="zipEndDate" type="date">
            </div>
            {#if _zip.error}
                <div class="text-rose-500">{_zip.error}</div>
            {:else}
                <div class="relative ml-40 mt-4">
                    {#if zip.loading}
                        <div class="loading"></div>
                    {:else}
                        <button class='btn action-bla primary cursor-pointer w-44' on:click={onExportZIP}>
                            Exporter
                        </button>
                    {/if}
                </div>
            {/if}
        </section>

        <section>
            <h2>{t('exports.fec')}</h2>

            <div class="flex items-center">
                <label class="w-40" for="fecStartDate">Date de départ</label>
                <input bind:value={fec.startDate} class="w-44" id="fecStartDate" type="date"/>
            </div>
            <div>
                <label class="w-40" for="fecEndDate">Date de fin</label>
                <input bind:value={fec.endDate} class="w-44" id="fecEndDate" type="date"/>
            </div>
            <div>
                <label class="w-40" for="fecInvoice">Invoices</label>
                <input bind:checked={fec.invoice} id="fecInvoice" type="checkbox"/>
            </div>
            <div>
                <label class="w-40" for="fecCreditNote">Credit Notes</label>
                <input bind:checked={fec.creditNote} id="fecCreditNote" type="checkbox"/>
            </div>
            {#if _fec.error}
                <div class="text-rose-500">{_fec.error}</div>
            {:else}
                <div class="relative ml-40 mt-4">
                    {#if fec.loading}
                        <div class="loading"></div>
                    {:else}
                        <button class='btn action-bla primary cursor-pointer w-44' on:click={onExportFEC}>
                            Exporter
                        </button>
                    {/if}
                </div>
            {/if}
        </section>

    </div>

</main>

<style>
    h2 {
        @apply uppercase font-bold;
    }

    section {
        @apply flex flex-col mt-8;
    }

    section div {
        @apply flex items-center mt-2 h-11;
    }

    div.loading {
        @apply pointer-events-none;

        &::before {
            content: '';
            @apply absolute top-6 left-20 w-5 h-5 z-10 -ml-px rounded-full border-2 border-r-dundyOrange;
            animation: disabled-loader 1s infinite linear;
        }
    }
</style>

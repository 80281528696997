<script lang="ts">
  import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
  import { fly, slide } from 'svelte/transition'
  import { backInOut } from 'svelte/easing'
  import { createEventDispatcher, onMount } from 'svelte'
  import TextInput from '../../../../core-app/lib/ui-kit/TextInput.svelte'
  import {
    appendSequenceNumberForBackend,
    replaceAllParenthesesPlaceholdersWithValues,
    replaceDefaultFirstLetterOfStringFromServerWithTranslatedOne,
    replaceDoubleAccoladesWithParenthesesAndTranslatePlaceholders,
    replaceNumberOfDigitsWithZeroes,
    replaceParenthesesWithDoubleAccoladesAndTextInEnglish
  } from '../../../utils/numbering-utils'
  import { BusinessDocumentKind } from '../../../enums/business-document-kind'
  import { VoxyPreferencesStore } from '../../../stores/voxy-preference.store'
  import { get } from 'svelte/store'
  import { ProfileStore } from '../../../../core-app/stores/profile.store'
  import type { NumberingSchemeSettings } from '../../../models/voxy-preferences'
  import { NumberingScheme } from '../../../models/voxy-preferences'
  import { getUserCompanyTimezone } from '../../../../core-app/util/timezone-utils'
  import { WorkspaceStore } from '../../../../crm-app/stores/workspace.store'
  import { deepClone } from '../../../../core-app/util/object-deep-cloning'
  import { currentLanguageCode } from '../../../../core-app/stores/i18n.store'


  export let documentKind: BusinessDocumentKind

  const dispatch = createEventDispatcher()
  let isPreferencesSaving: boolean = false
  let documentNumberTemplate: string
  let scheme: NumberingScheme
  let sequenceNumberOfDigits: number
  let invoiceTemplateValue: string
  let invoiceTemplatePreview: string
  let nextSequenceNumber: number
  let nextInvoiceNumber: string

  $:if (invoiceTemplateValue) {
    documentNumberTemplate = appendSequenceNumberForBackend(replaceParenthesesWithDoubleAccoladesAndTextInEnglish(invoiceTemplateValue))
    invoiceTemplatePreview = replaceAllParenthesesPlaceholdersWithValues(invoiceTemplateValue, get(ProfileStore).lang.split('-')[0].toLocaleLowerCase()) + nextInvoiceNumber
  }

  function loadPreferences() {
    const store = get(VoxyPreferencesStore)
    const numberingSchemesByBusinessDocument = store.numberingSchemesByBusinessDocument
    scheme = numberingSchemesByBusinessDocument[documentKind]
    const numberingSchemeSettings = scheme.currentNumberingScheme
    documentNumberTemplate = replaceDefaultFirstLetterOfStringFromServerWithTranslatedOne(numberingSchemeSettings.documentNumberTemplate, get(ProfileStore).lang)
    sequenceNumberOfDigits = numberingSchemeSettings.sequenceNumberOfDigits
    
    if (!numberingSchemeSettings.continueExistingDocumentNumberSequence && scheme.noAllocationSinceNumberingSchemeChanged) {
      nextSequenceNumber = numberingSchemeSettings.initialDocumentSequenceNumber
    } else {
      nextSequenceNumber = scheme.lastDocumentSequenceNumbering.number + 1
    }

    nextInvoiceNumber = replaceNumberOfDigitsWithZeroes(nextSequenceNumber, sequenceNumberOfDigits)
    invoiceTemplateValue = replaceDoubleAccoladesWithParenthesesAndTranslatePlaceholders(numberingSchemeSettings.documentNumberTemplate, get(currentLanguageCode))
  }

  /**
   *  Close the window
   *  **/
  function deactivateInvoiceNumberingWindow() {
    dispatch('deactivateInvoiceNumberingWindow')
  }

  /**
   * Saves the new numbering scheme for the given document kind
   */
  const saveAutomaticDocumentNumbering = () => {
    isPreferencesSaving = true

    let newVoxyPreferences = get(VoxyPreferencesStore)
    let currentNumbering: NumberingScheme = newVoxyPreferences?.numberingSchemesByBusinessDocument[documentKind]
    let newNumbering: NumberingScheme = <NumberingScheme>deepClone(currentNumbering)
    
    let updatedNumberingSchemeForInvoice: NumberingSchemeSettings = {
      applicableDocumentKind: documentKind,
      automaticNumberingMode: true,
      continueExistingDocumentNumberSequence: !scheme.noAllocationSinceNumberingSchemeChanged && nextSequenceNumber === parseInt(nextInvoiceNumber),
      documentNumberTemplate,
      inTimeZoneIANACode: getUserCompanyTimezone(get(WorkspaceStore)),
      initialDocumentSequenceNumber: parseInt(nextInvoiceNumber),
      sequenceNumberOfDigits: nextInvoiceNumber.length,
      description: 'user chose new settings in Voxy business document edit'
    }

    newNumbering.currentNumberingScheme = updatedNumberingSchemeForInvoice
    newVoxyPreferences.numberingSchemesByBusinessDocument[documentKind] = newNumbering

    VoxyPreferencesStore.set(newVoxyPreferences)

    isPreferencesSaving = false
    deactivateInvoiceNumberingWindow()
  }

  const onInputTemplate = (e:CustomEvent) => {
    invoiceTemplateValue = e.detail
  }

  const onInputNextNumber = (e:CustomEvent) => {
    nextInvoiceNumber = replaceNumberOfDigitsWithZeroes((e.detail), e.detail.length)
    sequenceNumberOfDigits = e.detail.length
  }

  onMount(() => {
    loadPreferences()
  })
</script>

<div class="bg-white rounded-md" in:fly|local="{{ y: 100, duration: 1000, easing: backInOut }}"
     out:slide|local="{{ easing: backInOut }}">
    <div class="flex flex-1 flex-col justify-between px1-2">
        <div class="divide-y divide-gray-200 px-12">
            <div class="space-y-6 py-7">
                <div class="mt-2">
                    <h4 class="text-2xl font-bold">{t(`automaticNumbering.${documentKind}.title`)}</h4>
                    <p class="py-4 text-sm">{t(`automaticNumbering.${documentKind}.copy`)}</p>
                </div>
                <fieldset class="mt-4 grid grid-cols-2 gap-x-6 gap-y-4">
                    <TextInput
                            label={t(`automaticNumbering.${documentKind}.inputModelLabel`)}
                            on:input={e => onInputTemplate(e)}
                            placeholder={t(`automaticNumbering.${documentKind}.inputModelPlaceholder`)}
                            showValidationTick={false}
                            value={invoiceTemplateValue}
                    />
                    <TextInput
                            label={t(`automaticNumbering.${documentKind}.inputStartLabel`)}
                            min="1"
                            on:input={e => onInputNextNumber(e)}
                            placeholder={t(`automaticNumbering.${documentKind}.inputStartPlaceholder`)}
                            showValidationTick={false}
                            type="number"
                            value={nextInvoiceNumber}
                    />
                </fieldset>

                <div class="space-y-1">
                    <div class="flex items-center">
                        <i class="relative text-lg text-black mr-2">
                            <svg fill="none" height="24" viewBox="0 0 24 24" width="24">
                                <path d="M12 13V15" stroke="currentColor" stroke-linecap="round"
                                      stroke-linejoin="round" stroke-width="2"></path>
                                <circle cx="12" cy="9" fill="currentColor" r="1"></circle>
                                <circle cx="12" cy="12" r="7.25" stroke="currentColor" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="1.5"></circle>
                            </svg>

                        </i>
                        <p class="text-s">{@html t(`automaticNumbering.${documentKind}.nextInvoiceNumberPreview`, { previewFinalBusinessDocumentNumber: invoiceTemplatePreview })}</p>
                    </div>
                </div>

                <div class="aside-help w-full col-span-full">
                    {@html t(`automaticNumbering.${documentKind}.help`)}
                </div>
            </div>
        </div>
        <div class="divide-y divide-gray-200 px-12">
            <div class="space-y-6 py-7">
                <div class="flex justify-end space-x-6">
                    <button class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-dundyOrange focus:ring-offset-2"
                            on:click={() => deactivateInvoiceNumberingWindow()}
                            type="button">
                        {t(`automaticNumbering.${documentKind}.cancelButton`)}
                    </button>
                    <button class="inline-flex justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                            on:click={() => saveAutomaticDocumentNumbering()}
                            type="submit">
                        {#if !isPreferencesSaving}
                            {t(`automaticNumbering.${documentKind}.saveButton`)}
                        {:else}
                            <svg class="animate-spin h-5 w-5 text-dundyOrange"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        {/if}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<style lang="postcss">
    .aside-help {
        @apply bg-creamBrulee border border-warmGray rounded-md px-6 py-4;
        border-color: hsl(47, 65%, 84%);
        background-color: hsl(47, 87%, 94%);
    }
</style>

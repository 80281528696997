<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import { navigate } from 'svelte-routing'
</script>

<div class="flex items-center justify-between align-middle space-x-2 my-5">
    <div class="shadow-lg rounded w-full p-4 bg-barleyWhite relative border border-goldSand overflow-hidden">
        <div class="flex flex-col justify-between mt-2 mb-5">
            <p class="text-black font-bold text-base">
                {t('todoAction.missingBankDetailNotice.header')}
            </p>
            <p class="text-black text-s">
                {t('todoAction.missingBankDetailNotice.copy')}
            </p>
        </div>
        <a href={'/settings/bank/view'}
           on:click|preventDefault={() => navigate('/settings/bank/view')}
           on:keydown={(e) => e.key === 'Enter' && navigate('/settings/bank/view')}
           class="btn big-primary cursor-pointer">{t('todoAction.missingBankDetailNotice.button')}
        </a>
    </div>
</div>
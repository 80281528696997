<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import { navigate } from 'svelte-routing'
    import type { Contact } from '../../models/contact'

    export let classes: string
    export let contact: Contact

</script>

<div class="flex {classes}">
    {#if contact && contact.firstName && contact.email}
        <div class="data-item-initials flex flex-wrap content-center justify-center w-8 h-8 bg-dundyOrange rounded-full text-white text-xs mr-2">
            <span>
                {`${ contact?.firstName?.substring(0, 1).toUpperCase() }${ contact?.lastName?.substring(0, 1).toUpperCase() }`}
            </span>
        </div>
        <div class="flex flex-col">
            <span class="text-xs font-medium">{contact?.firstName || ''} {contact?.lastName || ''}</span>
            <span class="text-xxs text-dundyOrange">{contact?.email || ''}</span>

        </div>
    {:else}
        <div class="flex flex-wrap content-center justify-center w-8 h-8 text-base mr-2">
            <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                <path d="m9.983.746.144.135 4.992 4.992a3.008 3.008 0 0 1 .135 4.11l-.135.144-4.992 4.992-.144.135a3.008 3.008 0 0 1-3.966 0l-.144-.135-4.992-4.992-.135-.144a3.008 3.008 0 0 1 0-3.966l.135-.144L5.873.881l.144-.135a3.008 3.008 0 0 1 3.966 0ZM7.048 1.838l-.114.104-4.992 4.992-.104.114c-.45.553-.45 1.351 0 1.904l.104.114 4.992 4.992a1.508 1.508 0 0 0 2.018.104l.114-.104 4.992-4.992.104-.114c.45-.553.45-1.351 0-1.904l-.104-.114-4.992-4.992-.114-.104a1.508 1.508 0 0 0-1.904 0Zm.962 8.412.102.007a.75.75 0 0 1 0 1.486l-.102.007H8l-.102-.007a.75.75 0 0 1 0-1.486L8 10.25h.01ZM8 4a.75.75 0 0 1 .743.648l.007.102v3.5a.75.75 0 0 1-1.493.102L7.25 8.25v-3.5A.75.75 0 0 1 8 4Z"
                      fill="#DE496D" fill-rule="nonzero"/>
            </svg>
        </div>
        <div class="flex flex-col cursor-pointer"
             role="button"
             tabindex="0"
             on:click|preventDefault|stopPropagation={() => { navigate(`/clients/${contact.companyId}/edit/contacts`)}}
             on:keydown|preventDefault|stopPropagation={(e) => { if (e.key === 'Enter') { navigate(`/clients/${contact.companyId}/edit/contacts`) }}}>
            <span class="text-sm">{t('missingContact.text')}</span>
            <span class="text-xxs text-cabaret">{t('missingContact.link')}</span>
        </div>
    {/if}
</div>

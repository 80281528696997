<script lang="ts">
    import { link } from 'svelte-routing'
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
</script>

<div class="flex flex-col justify-center items-center">
    <a class="flex flex-col align-middle justify-between items-center px-5 py-4 text-center" href="/clients/new"
       use:link>
        <!--<img alt="Create new Invoice" />-->
        <div class="w-full">
            <h4 class="font-bold text-2xl">
                {t('todo.firstEmptyState.createCustomerDrive')}
            </h4>
            <p>{t('todo.firstEmptyState.createCustomerSubtext')}</p>
        </div>
        <div class="mx-auto mt-4">
            <a class="btn action-default" href="/clients/new" use:link><span
                    class="icon-plus text-lg mr-1"></span>{t('todo.firstEmptyState.createCustomerButton')}</a>
        </div>
    </a>
</div>

<script lang="ts">
    import ConfirmEmail from './onboarding/ConfirmEmail.svelte'
    import Signup from './onboarding/SignUp.svelte'
    import Start from './onboarding/Start.svelte'
    import Guard from './onboarding/Guard.svelte'
    import { link, Route, Router } from 'svelte-routing'
    import { globalHistory } from 'svelte-routing/src/history.js'
    import auth from '../lib/auth0authentication/authService.js'
    import { onDestroy, onMount } from 'svelte'
    import Url from '../services/url'
    import { t } from '../lib/i18n/i18nextWrapper'
    import { OnboardingStep } from '../enums/onboarding-steps'
    import BankView from '../../pay-app/pages/BankView.svelte'
    import CompanyInfo from './onboarding/CompanyInfo.svelte'
    import AppIntro from './onboarding/AppIntro.svelte'
    import CompanyInfoPlus from './onboarding/CompanyInfoPlus.svelte'
    import { BANK_CONNECTION_LAST_STEP, SHOW_STEPS_COMPLETED_PAGE } from '../services/core-app-config'
    import mixpanel from 'mixpanel-browser'

    /** Variables declarations */
    let stepCount = 7
    const steps: OnboardingStep[] = <OnboardingStep[]>[
      OnboardingStep.START, // 1
      OnboardingStep.SIGNUP, // 2
      OnboardingStep.CONFIRM, // 3
      OnboardingStep.COMPANY_INFO, // 4
      OnboardingStep.COMPANY_INFO_PLUS, // 5
      OnboardingStep.COMPANY_INFO_FINAL // 6
    ]
    if (BANK_CONNECTION_LAST_STEP) {
      if (SHOW_STEPS_COMPLETED_PAGE) {
        steps.push(OnboardingStep.APP_INTRO)
      }
      steps.push(OnboardingStep.CONNECT_BANK)
      steps.push(OnboardingStep.SELECT_ACCOUNT)
    } else {
      steps.push(OnboardingStep.CONNECT_BANK)
      steps.push(OnboardingStep.SELECT_ACCOUNT)
      if (SHOW_STEPS_COMPLETED_PAGE) {
        steps.push(OnboardingStep.APP_INTRO)
      }
    }
    let unsubHistory: () => void
    let pathname: string = Url.getLastPath(window.location.pathname)

    /** Reactive declarations */
    $: stepCount = steps.indexOf(pathnameToOnboardingStep(pathname) as OnboardingStep) + 1

    /**
     * Utility function that attempts to convert a pathname to an OnboardingStep
     * @param pathname
     */
    function pathnameToOnboardingStep(pathname: string): OnboardingStep | null {
      return OnboardingStep[pathname as keyof typeof OnboardingStep] || null
    }


    onMount(() => {
      mixpanel.track('CA00 Onboarding Page', {
        'Description': 'Open Onboarding.svelte'
      })
      unsubHistory = globalHistory.listen(({ location }) => {
        pathname = Url.getLastPath(location.pathname)
      })
    })

    onDestroy(() => {
      if (unsubHistory) {
        unsubHistory()
      }
    })

    /* console.log('reaching for onboarding stepCount', stepCount, pathname) */

</script>

<svelte:head>
    <style>
        body {
            background: white;
        }
    </style>
</svelte:head>
<!--
<header class="w-full flex">
    <div class="hidden md:block md:w-3/12 h-36 max-w-lg bg-gradient-to-t from-[#152c3c] to-[#152d3d]">
        <div class="flex justify-left relative p-10 pl-12 max-w-72">
            <img alt="dundy logo" src="/img/dundy-mark.svg" class="h-16"/>
        </div>
    </div>
    <div class="w-full md:w-8/12 2xl:w-9/12">
        <div class="flex flex-col md:flex-row justify-left mt-10 relative md:items-center">
            <div class="flex container mt-6 md:mt-0 order-last md:order-1 justify-center items-center">
                <div class="flex items-center {(stepCount === 1 || stepCount === 2 || stepCount === 3) && 'active'}">
                    <div class="progress-icon">&nbsp;</div>
                    <span class="progress-text">{t('onboarding.head.progress.step1')}</span>
                </div>
                <hr class="progress-divider"/>
                <div class="flex items-center {(stepCount === 4 || stepCount === 5 || stepCount === 6) && 'active'}">
                    <div class="progress-icon">&nbsp;</div>
                    <span class="progress-text">{t('onboarding.head.progress.step2')}</span>
                </div>
                <hr class="progress-divider"/>
                <div class="flex items-center {stepCount === 7 && 'active'}">
                    <div class="progress-icon">&nbsp;</div>
                    <span class="progress-text">{t('onboarding.head.progress.step3')}</span>
                </div>
            </div>
            <div class="flex justify-end md:justify-start md:w-2/12 mr-6 md:absolute -right-12 -top-2">
                {#if stepCount < 4}
                    <a href="/signin"
                       class="cursor-pointer bg-black text-white border border-transparent hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 rounded-md shadow-sm px-4 py-2 text-sm font-medium sm:w-auto"
                       use:link>
                        {t('onboarding.signIn')}
                    </a>
                {:else}
                    <button
                            on:click={() => auth.logout()}
                            on:keydown={ (e) => { if (e.key === 'Enter') {auth.logout()} } }
                            class="btn small-primary cursor-pointer">
                        {t('topMenu.userNav.logOut')}
                    </button>
                {/if}
            </div>
        </div>
    </div>
</header>-->

<!--<div class="relative flex-none overflow-hidden px-6 lg:pointer-events-none lg:fixed lg:inset-0 lg:z-40 lg:flex lg:px-0">
  <div class="absolute inset-0 -z-10 overflow-hidden bg-black lg:right-[calc(max(2rem,50%-28rem)+40rem)] lg:min-w-[28rem]">
    Test
  </div>
  <div class="relative flex w-full lg:pointer-events-auto lg:mr-[calc(max(2rem,50%-38rem)+40rem)] lg:min-w-[32rem] lg:overflow-y-auto lg:overflow-x-hidden lg:pl-[max(4rem,calc(50%-38rem))]">
    <div class="mx-auto max-w-lg lg:mx-0 lg:flex lg:w-96 lg:max-w-none lg:flex-col lg:before:flex-1 lg:before:pt-6">
      <div class="pb-16 pt-20 sm:pb-20 sm:pt-32 lg:py-20">
      </div>
    </div>
  </div>
</div>-->

<Router>
    <Route path={OnboardingStep.START}>
        <Start/>
    </Route>
    <Route path={OnboardingStep.SIGNUP}>
        <Signup/>
    </Route>
    <Route path={OnboardingStep.CONFIRM}>
        <ConfirmEmail/>
    </Route>
    <Route path={OnboardingStep.CONNECT_BANK}>
        <BankView />
    </Route>
    <Route path={OnboardingStep.SELECT_ACCOUNT}>
        <BankView />
    </Route>
    <Route path={OnboardingStep.COMPANY_INFO_PLUS}>
        <CompanyInfoPlus/>
    </Route>
    <Route path={OnboardingStep.APP_INTRO}>
        <AppIntro/>
    </Route>
    <Route path={OnboardingStep.COMPANY_INFO}>
        <CompanyInfo/>
    </Route>
    <Route path="*">
        <Guard {steps}/>
    </Route>
</Router>

<!--<style lang="postcss">
    .progress-icon {
        @apply rounded-full h-2 w-2 bg-athensGray mr-2 relative;
    }

    .progress-text {
        @apply text-sm text-paleSky font-semibold;
    }

    .active .progress-icon {
        @apply bg-dundyOrange;
    }

    .active .progress-text {
        @apply text-black;
    }

    .progress-divider {
        @apply border bg-[#B8BDC3] w-[4vw] relative mx-4;
    }
</style>-->

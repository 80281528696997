<script lang="ts">
    import { get } from 'svelte/store'
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import FullSizeModal from '../../../core-app/lib/ui-kit/FullSizeModal.svelte'
    import ContactForm from '../../components/ui-kit/ContactForm.svelte'
    import { Contact } from '../../models/contact'
    import { ContactsHelper } from '../../helpers/contacts-helper'
    import { ContactsStore } from '../../stores/contacts.store'
    import { WorkspaceStore } from '../../stores/workspace.store'
    import type { Feedback } from '../../../core-app/models/feedback'
    import { feedbackService } from '../../../core-app/services/feedback.service'
    import Switch from '../../../core-app/lib/ui-kit/Switch.svelte'
    import { workspacesService } from '../../services/workspace.service'
    import { emailService } from '../../../dundy-app/services/email.service'
    import { ProfileStore } from '../../../core-app/stores/profile.store'
    import type { Workspace } from '../../models/workspace'
    import type Profile from '../../../core-app/models/profile'
    import { onMount } from 'svelte'
    import mixpanel from 'mixpanel-browser'

    export let contactId: string = null

    /** Vars **/
    let newContact: Contact = ContactsHelper.newContact($WorkspaceStore.workspaceId)
    let existingContact: Contact
    let isLoading: boolean = false
    let isInvitingUser: boolean = false
    let isInvitedMember: boolean = false

    /** Reactive **/
    $: existingContact = contactId && $ContactsStore.find((c: Contact) => c.contactId === contactId)

    /**
     * Invite Team Member And Send Email
     *
     * @param isInvitedMember
     * @param newContact
     * @param workspaceStore
     * @param profileStore
     */
    function inviteTeamMemberAndSendEmail(
      isInvitedMember: boolean,
      newContact: Contact,
      workspaceStore: Workspace,
      profileStore: Profile) {
      if (!isInvitedMember) {
        return
      }

      isInvitingUser = true

      workspacesService.inviteTeamMember(workspaceStore, newContact.email, newContact.firstName, newContact.lastName)
        .then(() => emailService.sendInvitationEmail(newContact.email, newContact.firstName, newContact.lastName, profileStore.firstName, profileStore.lastName, profileStore.lang))
        .then(() => {
          feedbackService.displayFeedback(<Feedback> {
            feedbackMessage: t('email.invitation.success'),
            feedbackLevel: 'Success'
          })
          isInvitingUser = false
        })
        .catch(() => {
          feedbackService.displayFeedback(<Feedback> {
            feedbackMessage: t('email.invitation.error'),
            feedbackLevel: 'Error'
          })
          isInvitingUser = false
        })
    }


    /**
     * Handle Contact Form Submit By User
     */
    async function handleContactFormSubmit() {
      isLoading = true
      // This setTimeout serves only the purpose of displaying the loader for enough time for the user to see it, instead of looking like a glitch!
      setTimeout(() => {
        const contactsStore = get(ContactsStore)
        if (existingContact) {
          existingContact.isWorkspaceMember = isInvitedMember
          const updatedContactsStore: Contact[] = []
          contactsStore.forEach((contact: Contact) => {
            let existingStoreContact = existingContact.contactId === contact.contactId
            updatedContactsStore.push(existingStoreContact ? existingContact : contact)
          })
          ContactsStore.set(updatedContactsStore)
          inviteTeamMemberAndSendEmail(isInvitedMember, existingContact, $WorkspaceStore, $ProfileStore)
          isLoading = false

          feedbackService.displayFeedback(<Feedback> {
            feedbackMessage: t('editCustomer.contactUpdated'),
            feedbackLevel: 'Success'
          })
          close()

        } else {
          /* console.log('newContact on Add Member', newContact) */
          newContact.isWorkspaceMember = isInvitedMember
          contactsStore.push(newContact)
          ContactsStore.set(contactsStore)
          inviteTeamMemberAndSendEmail(isInvitedMember, newContact, $WorkspaceStore, $ProfileStore)
          feedbackService.displayFeedback({
            feedbackMessage: t('editCustomer.contactAdded'),
            feedbackLevel: 'Success'
          } as Feedback)
          isLoading = false
          close()
        }
      }, 500)
    }

    async function handleContactFormCancel() {
      close()
    }

    const close = () => {
      history.go(-1)
    }

    onMount(()=>{
      mixpanel.track('XB10 Edit Team Member Page', {
        'Description': 'Open AddEditNewUser.svelte'
      })
    })
</script>

<FullSizeModal
        isCloseable={true}
        showFooter={true}
        class="w-full overflow-y-auto h-full"
        on:close={close}>

    <div slot="content" class="grid w-full h-full overflow-y-auto pb-24">
        <div class="w-full md:w-8/12 lg:w-5/12 mx-auto mb-8 mt-8 px-6 md:px-0">
            <div class="py-5">
                <h2 class="text-black text-3xl font-bold">Ajouter un nouvel utilisateur</h2>
            </div>
            <div class="py-5">
                <p class="text-sm py-6 aside-note flex">
                    <span class="mr-0.5"><svg width="32" height="32" fill="none" viewBox="0 0 24 24"> <circle cx="12"
                                                                                                              cy="12"
                                                                                                              r="7.25"
                                                                                                              stroke="currentColor"
                                                                                                              stroke-linecap="round"
                                                                                                              stroke-linejoin="round"
                                                                                                              stroke-width="1.5"/> <circle
                            cx="12" cy="12" r="3.25" stroke="currentColor" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="1.5"/> <path stroke="currentColor"
                                                                               stroke-linecap="round"
                                                                               stroke-linejoin="round"
                                                                               stroke-width="1.5" d="M7 17L9.5 14.5"/> <path
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                            d="M17 17L14.5 14.5"/> <path stroke="currentColor" stroke-linecap="round"
                                                         stroke-linejoin="round" stroke-width="1.5" d="M9.5 9.5L7 7"/> <path
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                            d="M14.5 9.5L17 7"/> </svg></span>
                    <span>Invitez un nouveau collaborateur pour vous aider sur la facturation / relance.<br> Vous pouvez également ajouter un contact interne de votre entreprise qui recevra les reportings quotidiens ou un département qui figurera sur les en-têtes de facture.</span>
                </p>
            </div>
            {#if isInvitingUser}
                <div class="flex flex-col flex-grow max-w-xl mt-32 place-items-center justify-center items-center z-[100]">
                    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-dundyOrange"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <div class="text-dundyOrange mt-2">{t('team.inviteMember.loading')}</div>
                </div>
            {:else if isLoading}
                <div class="flex flex-col flex-grow max-w-xl mt-32 place-items-center justify-center items-center z-[100]">
                    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-dundyOrange"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            {:else}
                <div class="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
                    <ContactForm editedContact={existingContact || newContact} let:canSubmitContact>
                        <div class="rounded-md bg-zinc-50 px-4 border border-whisper mt-5">
                            <label for="invite-switch" class="rounded-md rounded-tr-md relative flex cursor-pointer focus:outline-none items-center py-3.5">
                                <span id="invite-switch">
                                    <Switch bind:toggled={isInvitedMember}/>
                                </span>
                                <span class="ml-3 flex flex-col">
                                <span id="payment-url-setting-0-label"
                                    class="block text-sm font-medium text-black">Inviter à collaborer</span>
                                <span id="payment-url-setting-0-description" class="block text-s text-gray-800">Travaillez efficacement en équipe en invitant votre collaborateur sur votre espace.</span>
                                </span>
                            </label>
                        </div>
                        <div class="flex mt-8  justify-center space-x-3">
                            <button class="btn action-cancel"
                                    on:click|preventDefault|stopPropagation={()=> handleContactFormCancel()}>
                                {t('editCustomer.cancel')}
                            </button>
                            {#if canSubmitContact}
                                <button on:click|preventDefault|stopPropagation={()=> handleContactFormSubmit()}
                                        class="btn action-bla primary">{t('editCustomer.saveContact')}</button>
                            {:else}
                                <button disabled
                                        class="btn action-bla primary">{t('editCustomer.saveContact')}</button>
                            {/if}
                        </div>
                    </ContactForm>
                </div>
            {/if}
        </div>
    </div>
</FullSizeModal>

<style lang="postcss">
    .aside-note {
        @apply bg-creamBrulee border border-warmGray rounded-md px-6 py-4;
        border-color: hsl(47, 65%, 84%);
        background-color: hsl(47, 87%, 94%);
    }
</style>

<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { SortOrder, type Columns } from './grid.d'
  import { objectFindByPath } from '$shared/utils/object'

  // DATA
  export let columns:Readonly<Columns> = []
  export let rows:Readonly<any[]> = []

  // UI
  export let allowSelect:boolean = true
  export let showAvatar:boolean = true
  export let allowActions:boolean = true
  export let allowEdit:boolean = true
  export let allowDuplicate:boolean = true
  export let allowDelete:boolean = true

  // UI EVENT
  export let sort:string = ''
  export let sortOrder:SortOrder = SortOrder.NONE

  const dispatch = createEventDispatcher()

  const onSort = (column:string) => {
    if (sort !== column) {
      sortOrder = SortOrder.ASC
    } else {
      switch (sortOrder) {
        case SortOrder.ASC:
          sortOrder = SortOrder.DESC
          break
        case SortOrder.DESC:
          sortOrder = SortOrder.NONE
          break
        default:
          sortOrder = SortOrder.ASC
          break
      }
    }
    sort = column
    
    dispatch('sort', { sort, sortOrder })
  }

  const onSelect = (value:any) => {
    dispatch('select', value)
  }

  const onEdit = (value:any) => {
    dispatch('edit', value)
  }

  const onDuplicate = (value:any) => {
    dispatch('duplicate', value)
  }

  const onDelete = (value:any) => {
    dispatch('delete', value)
  }
</script>

<div class="size-full overflow-y-auto overflow-x-hidden">
  <table class="min-w-full table-auto border-collapse">
    <thead class="sticky top-0 z-10 bg-white">
      <tr>
        {#each columns as c}
          <th on:click={() => onSort(c.path)}>
            <span class="float-left mr-2">{c.name}</span>
            <img src={`/img/icons/sort-${sort === c.path ? sortOrder : 'none'}.svg`} alt="sort" width="14px" class="mt-1" />
          </th>
        {/each}

        {#if allowActions && (allowEdit || allowDuplicate || allowDelete)}
          <th class="relative py-3.5 w-12" />
        {/if}
      </tr>
    </thead>
    <tbody class="bg-white divide-y divide-gray-200">
      {#each rows as r}
        <tr>
          {#each columns as c, i}
            <td
               class={c.color ? `text-${c.color}` : ''}
               class:first={i === 0 && allowSelect}
               on:click={() => allowSelect ? onSelect(r) : {}}
            >
              {#if i === 0 && showAvatar}
                <div class="size-10 leading-10 mr-2 float-left rounded-lg text-center text-2xl align-middle bg-slate-100 justify-center border-athensGray text-baliHai ring-1 ring-gray-900/5 shadow-sm uppercase">
                  {objectFindByPath(r, c.path)[0]}
                </div>
              {/if}
              {objectFindByPath(r, c.path)}
            </td>
          {/each}

          {#if allowActions}
            <td class="flex z-0">
              {#if allowEdit}
                <button class="edit" on:click={() => onEdit(r)} />
              {/if}
              {#if allowDuplicate}
                <button class="copy" on:click={() => onDuplicate(r)} />
              {/if}
              {#if allowDelete}
                <button class="delete" on:click={() => onDelete(r)} />
              {/if}
            </td>
          {/if}
        </tr> 
      {/each}
    </tbody>
  </table>
</div>

<style lang="postcss">
  table {
    th {
      @apply px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer;

      &:last-child {
        @apply cursor-default;
      }
    }
    td {
      @apply whitespace-nowrap py-4 pl-4 pr-3 text-sm;

      &.first {
        @apply cursor-pointer leading-10;
      }
      &:last-child {
        @apply px-0;
      }
    }
    tbody tr:hover {
      @apply bg-whisper;
    }

    button {
      @apply size-7 p-5 bg-no-repeat bg-center rounded border-2 border-transparent;
      display: inherit;

      &:active,
      &:hover {
          @apply bg-provincialPink border-dundyOrange;
      }

      &.edit {
        @apply bg-[url('/img/icons/edit.svg')];
      }
      &.copy {
        @apply bg-[url('/img/icons/copy.svg')] ;
      }
      &.delete {
        @apply bg-[url('/img/icons/delete.svg')];
      }
    }
  }
</style>
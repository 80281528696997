<script lang="ts">
    import type { TodoListItem } from '../../../../dundy-app/models/todo-list-item'
    import type Invoice from '../../../../dundy-app/models/invoice'
    import { TodoType } from '../../../../dundy-app/enums/todo-type'
    import { t } from '../../i18n/i18nextWrapper'
    import { DunningInvoicesStore } from '../../../../dundy-app/stores/dunning-invoices.store'
    import { InvoiceStatus } from '../../../../dundy-app/enums/invoice-status'
    import { calculateDaysDue } from '../../../../order-to-cash-lib/helpers/calculate-days-due'
    import { ComputedDataInvoicesStore } from '../../../../dundy-app/stores/computed-data-invoice.store'
    import { maxDaysSinceDueDateToHigherEscalation } from '../../../../dundy-app/services/dundy-config.js'


    /** Export declarations */
    export let classes: string
    export let todo: TodoListItem
    export let invoice: Invoice

    /** Local */
    let invoiceDueDays: number = 0

    /** Reactive declarations */
    $: invoice = $DunningInvoicesStore.find(storedInvoice => todo?.scopeInvoiceNumbers.find(scopedInvoiceNumber => scopedInvoiceNumber === storedInvoice.invoiceNumber)) || invoice
    $: invoiceDueDays = calculateDaysDue(invoice?.invoiceNumber, $ComputedDataInvoicesStore)

</script>

<div class="flex {classes}">
    {#if invoice && invoice?.status === InvoiceStatus.PAID}
        <span class="label success-outlined paid-status">
            <span>{t('invoiceStatus.paid')}</span>
        </span>
    {:else if todo}
        {#if todo.maxDueDays <= 0}
            <span class="inline-flex justify-between align-middle items-center rounded py-1 px-2 my-1 bg-ottoman border border-blueStone/20 text-blueStone text-xs">
                <span class="hidden md:block">{t('todo.item.status.onTime')}</span>
            </span>
        {:else if todo.kind === TodoType.DUNNING && todo.status === 'disputed'}
            <span class="inline-flex justify-between align-middle items-center rounded py-1 px-2 my-1 bg-provincialPink border border-almond text-mojo text-xxs">
                <i class="md:mr-1 text-xl icon-warning inline-flex align-middle items-center justify-center text-center w-4 h-4"></i>
                <span class="hidden md:block">{t('todo.item.status.dispute', { count: todo.scopeInvoiceNumbers.length })}</span>
            </span>
        {:else if todo.kind === TodoType.DUNNING && todo.status !== 'disputed' && (todo.escalation === 'CourtesyReminder' && (new Date() < new Date(invoice ? invoice.dateDue : 0)))}
            <span class="inline-flex justify-between align-middle items-center rounded py-1 px-2 my-1 bg-blackSqueeze border border-anakiwa text-blueStone text-xs">
                <!--<i class="md:mr-1 icon-happy w-4 h-4 flex align-middle items-center justify-center text-xl"></i>-->
                <span class="hidden md:block">{t('todo.item.status.onTime')}</span>
            </span>
        {:else if todo.kind === TodoType.DUNNING && todo.status !== 'disputed' && (todo.escalation === 'FirstReminder' || (todo.escalation === 'CourtesyReminder' && (new Date() > new Date(invoice ? invoice.dateDue : 0))))}
            <span class="inline-flex justify-between align-middle items-center rounded py-1 px-2 my-1 bg-bananaMania border border-robRoy text-fireBush text-xxs">
                <svg class="md:mr-1" width="10" height="10" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 0a5 5 0 1 1 0 10A5 5 0 0 1 5 0Zm0 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z" fill="#E6923A"
                          fill-rule="nonzero"/></svg>
                <span class="hidden md:block">{t('todo.item.status.firstReminder')}</span>
            </span>
        {:else if todo.kind === TodoType.DUNNING && todo.status !== 'disputed' && todo.escalation === 'SecondReminder'}
            <span class="inline-flex justify-between align-middle items-center rounded py-1 px-2 my-1 bg-colonialWhite border border-peachOrange text-tango text-xxs">
                <svg class="md:mr-1" width="15" height="17" xmlns="http://www.w3.org/2000/svg">
                    <g transform="rotate(39 1.998 14.56)" stroke="#F27C17" stroke-width="2" fill="none"
                       fill-rule="evenodd"><circle cx="2.321" cy="2.321" r="2.321"/>
                        <circle fill="#F27C17" cx="2.321" cy="10.679" r="2.321"/></g>
                </svg>
                <span class="hidden md:block">{t('todo.item.status.secondReminder')}</span>
            </span>
        {:else if todo.kind === TodoType.DUNNING && todo.status !== 'disputed' && todo.escalation === 'LastCall' }
            <span class="inline-flex justify-between align-middle items-center rounded py-1 px-2 my-1 bg-apricotPeach border border-hotCinnamon text-mojo text-xxs">
                <i class="md:mr-1 icon-thunder w-4 h-4 flex align-middle items-center justify-center text-xl"></i>
                <span class="hidden md:block">{t('todo.item.status.lastCall')}</span>
            </span>
        {:else if todo.kind === TodoType.DUNNING && todo.status !== 'disputed' && todo.escalation === 'LegalAction' }
            <!-- <span class="inline-flex justify-between align-middle items-center rounded py-1 px-2 my-1 bg-apricotPeach border border-hotCinnamon text-mojo text-xxs">
                 &lt;!&ndash;<i class="md:mr-1 icon-gavel w-4 h-4 flex align-middle items-center justify-center text-xl"></i>&ndash;&gt;
                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M12 14.25C11.5858 14.25 11.25 14.5858 11.25 15C11.25 15.4142 11.5858 15.75 12 15.75V14.25ZM12.01 15.75C12.4242 15.75 12.76 15.4142 12.76 15C12.76 14.5858 12.4242 14.25 12.01 14.25V15.75ZM12 15.75H12.01V14.25H12V15.75Z" fill="currentColor"></path>
                 <path d="M10.4033 5.41136L10.9337 5.94169L10.4033 5.41136ZM5.41136 10.4033L4.88103 9.87301L4.88103 9.87301L5.41136 10.4033ZM5.41136 13.5967L5.94169 13.0663L5.94169 13.0663L5.41136 13.5967ZM10.4033 18.5886L10.9337 18.0583L10.4033 18.5886ZM13.5967 18.5886L14.127 19.119L14.127 19.119L13.5967 18.5886ZM18.5886 10.4033L19.119 9.87301L19.119 9.87301L18.5886 10.4033ZM13.5967 5.41136L14.127 4.88103L14.127 4.88103L13.5967 5.41136ZM9.87301 4.88103L4.88103 9.87301L5.94169 10.9337L10.9337 5.94169L9.87301 4.88103ZM4.88103 14.127L9.87301 19.119L10.9337 18.0583L5.94169 13.0663L4.88103 14.127ZM14.127 19.119L19.119 14.127L18.0583 13.0663L13.0663 18.0583L14.127 19.119ZM19.119 9.87301L14.127 4.88103L13.0663 5.94169L18.0583 10.9337L19.119 9.87301ZM19.119 14.127C20.2937 12.9523 20.2937 11.0477 19.119 9.87301L18.0583 10.9337C18.6472 11.5226 18.6472 12.4774 18.0583 13.0663L19.119 14.127ZM9.87301 19.119C11.0477 20.2937 12.9523 20.2937 14.127 19.119L13.0663 18.0583C12.4774 18.6472 11.5226 18.6472 10.9337 18.0583L9.87301 19.119ZM4.88103 9.87301C3.70632 11.0477 3.70632 12.9523 4.88103 14.127L5.94169 13.0663C5.35277 12.4774 5.35277 11.5226 5.94169 10.9337L4.88103 9.87301ZM10.9337 5.94169C11.5226 5.35277 12.4774 5.35277 13.0663 5.94169L14.127 4.88103C12.9523 3.70632 11.0477 3.70632 9.87301 4.88103L10.9337 5.94169Z" fill="currentColor"></path>
                 <path d="M12 8.75V12.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                 </svg>

                 <span class="hidden md:block">{t("todo.item.status.critical")}</span>
             </span>-->
            <div class="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-red-700 bg-red-50 ring-red-600/10">{t('todo.item.status.critical')}</div>

        {/if}
    {:else}
        {#if invoiceDueDays <= 0}
            <span class="inline-flex justify-between align-middle items-center rounded py-1 px-2 my-1 bg-ottoman border border-blueStone/20 text-blueStone text-xs">
                <span class="hidden md:block">{t('todo.item.status.onTime')}</span>
            </span>
        {:else}
            <span class="label text-xxs px-1 py-0.5>
                {invoiceDueDays > maxDaysSinceDueDateToHigherEscalation ? 'error late-payment-status high-escalation' : 'warning just-late-payment-status low-escalation'}">
                {invoiceDueDays}{t('invoiceStatus.daysLate', { count: invoiceDueDays || 0 })}
            </span>
        {/if}
    {/if}
</div>
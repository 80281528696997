import { type Writable, writable } from 'svelte/store'

interface AlertProps {
  header: string;
  message: string;
}

interface ComponentProps {
  component: any;
  props: AlertProps;
}

interface AlertStore extends Writable<ComponentProps | null> {
  open: (
    component: any,
    props: AlertProps,
    duration?: number,
    callback?: Function
  ) => void;
  close: () => void;
}

/**
 * Creates a store for the alert component
 * Sets the store value to an instance of a component. The open method creates an instance of the given component using the new keyword,
 * and sets the store to this new instance.
 *
 * Please note that this assumes the component constructor takes an AlertOptions object as a parameter and creates an instance of AlertComponent.
 * The AlertComponent interface is used to ensure that the component constructor takes the correct parameters and returns the correct type.
 */

function createAlert(): AlertStore {
  const { subscribe, set, update } = writable<ComponentProps | null>(null)

  let timeout: NodeJS.Timeout | undefined

  const store: AlertStore = {
    subscribe,
    set,
    update,
    open: (
      component: any,
      props: AlertProps,
      duration = 5000,
      callback?: Function
    ) => {
      set({ component, props })
      if (timeout) {
        clearTimeout(timeout)
      }
      if (duration === 0) {
        return
      }
      timeout = setTimeout(() => {
        set(null)
        if (callback) callback()
      }, duration)
    },
    close: () => set(null)
  }

  return store
}

export const Alert: AlertStore = createAlert()

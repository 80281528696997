<script lang="ts">
    import { get } from 'svelte/store'
    import { t } from '$core/lib/i18n/i18nextWrapper'
    import { DunningInvoicesStore } from '$dundy/stores/dunning-invoices.store'
    import { CustomersStore } from '../stores/customers.store'
    import { navigate } from 'svelte-routing'
    import { getUserCountryCode } from '$core/services/countries-pure-functions'
    import { getDefaultCurrencyAccordingToCountry } from '$core/services/currency-list'
    import { formatCurrencyWithOptions } from '$voxy/utils/number-formatting-utils'
    import {
      calculateTotalsForAllClients,
      mapInvoicesAccordingToDualKeyToIDAndTimeSlotCategoryWithAmounts,
      timeslotCategoriesAccordingToDaysDue, transformMapIntoArrayWithTotalDueOverdueAndCustomerInfo
    } from '../lib/aging-balance/aging-balance.pure-functions'
    import { getUserCompanyTimezone } from '$core/util/timezone-utils'
    import { WorkspaceStore } from '../stores/workspace.store'
    import SearchBar from '../../core-app/lib/ui-kit/SearchBar.svelte'
    import { onMount } from 'svelte'
    import mixpanel from 'mixpanel-browser'
    import BankWall from '$src/core-app/components/BankWall.svelte'
    import { bridgeByBankingService } from "$pay/services/bridge-by-banking.service";

    /** Set the invoiceStore */
    const invoiceStore = get(DunningInvoicesStore)

    /** Set Invoice Currency */
    const invoicesCurrency = invoiceStore.find((invoice) => (invoice.currency))?.currency || getDefaultCurrencyAccordingToCountry(getUserCountryCode())

    let searchTerm = ''
    let hasBankNeverBeenLinkedOrBankLinkExpired: boolean
    let hasBankLinkExpired: boolean

    /** Generate Map and Final Array with Aging Balance Info */
    const mappedInvoicesAccordingToCustomerIdAndTimeSlotCategories = mapInvoicesAccordingToDualKeyToIDAndTimeSlotCategoryWithAmounts(invoiceStore, getUserCompanyTimezone(get(WorkspaceStore)), timeslotCategoriesAccordingToDaysDue)
    const dataArrayWithCorrespondingCustomerInfo = transformMapIntoArrayWithTotalDueOverdueAndCustomerInfo(mappedInvoicesAccordingToCustomerIdAndTimeSlotCategories, get(CustomersStore))
    const totalAmountsForAllClientsAndTimeSlots = calculateTotalsForAllClients(mappedInvoicesAccordingToCustomerIdAndTimeSlotCategories)

    onMount(()=>{
      mixpanel.track('QA10 Aging Balance Reporting Page', {
        'Description': 'Opened AgingBalance.svelte'
      })

      hasBankNeverBeenLinkedOrBankLinkExpired = bridgeByBankingService.hasBankNeverBeenLinkedOrBankLinkExpired($WorkspaceStore?.bankConfig)
      hasBankLinkExpired = bridgeByBankingService.hasBankLinkExpired($WorkspaceStore?.bankConfig)
    })
</script>
<svelte:head>
    <title>{t('topMenu.nav.clients.agingBalance')} - Dundy</title>
</svelte:head>

{#if hasBankNeverBeenLinkedOrBankLinkExpired}
  <div class="background">
    <img src="/img/bank-wall/bg-transactions.png" alt="transactions" />
    <BankWall deprecated={hasBankLinkExpired}/>
  </div>
{:else}
  <main class="flex flex-col items-center">
      <div class="flex w-full flex-col flex-1">
          <h1 class="text-black text-3xl font-bold">{t('agingBalance.title')}</h1>
      </div>
      <div class="w-full flex flex-row justify-between my-5 space-x-5 items-center">
          <div class="search-bar-container flex flex-row w-full">
              <div class="justify-self-start w-full">
                  <SearchBar placeholder={t('agingBalance.search.placeholder')}
                            on:search={e => searchTerm = e.detail.text}
                            on:clear={() => searchTerm = ''}
                            hideLabel={true}/>
              </div>
          </div>
      </div>

      <div class="w-full flex flex-col">
          <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <table class="min-w-full divide-y divide-athensGray rounded-md border border-athensGray">
                  <thead>
                      <tr class="border-b border-athensGray">
                          <th scope="colgroup" colspan="2"
                              class="bg-whisper rounded-tl-md py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"></th>
                          <th scope="colgroup" colspan="4"
                              class="bg-whisper border-t border-athensGray py-3.5 px-3 text-center text-sm font-semibold text-gray-900">
                              {t('agingBalance.header.due')}
                          </th>
                          <th scope="colgroup" colspan="4"
                              class="bg-whisper border-t border-athensGray py-3.5 px-3 text-center text-sm font-semibold text-gray-900 rounded-tr-md">
                              {t('agingBalance.header.overdue')}
                          </th>

                      </tr>
                      <tr>
                          <th scope="col"
                              class="bg-whisper py-3.5 px-3 text-left text-xs font-semibold uppercase">
                              {t('agingBalance.header.client')}
                          </th>
                          <th scope="col"
                              class="bg-whisper py-3.5 px-3 text-left text-xs font-semibold uppercase">
                              {t('agingBalance.header.leftToPay')}
                          </th>
                          <th scope="col"
                              class="bg-apricotPeach py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              {@html t('agingBalance.header.above90Days')}
                          </th>
                          <th scope="col"
                              class="bg-colonialWhite py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              {t('agingBalance.header.From90To61Days')}
                          </th>
                          <th scope="col"
                              class="bg-bananaMania py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              {t('agingBalance.header.From60To31Days')}
                          </th>
                          <th scope="col"
                              class="bg-pearlLusta py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              {t('agingBalance.header.From31To1Day')}
                          </th>
                          <th scope="col"
                              class="bg-ottoman py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              {t('agingBalance.header.From0To30Days')}
                          </th>
                          <th scope="col"
                              class="bg-grannyApple py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              {t('agingBalance.header.From031To60Days')}
                          </th>
                          <th scope="col"
                              class="bg-cruise py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              {t('agingBalance.header.From61To90Days')}
                          </th>
                          <th scope="col"
                              class="bg-bermuda py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              {@html t('agingBalance.header.MoreThan90DaysAway')}
                          </th>
                      </tr>

                      </thead>
                      <tbody class="divide-y divide-gray-200">
                      {#each dataArrayWithCorrespondingCustomerInfo.filter(agingRow => !searchTerm || agingRow.customer.company.formalName.toLowerCase().includes(searchTerm.toLowerCase()) || agingRow.customer.company.formalName.toLowerCase().includes(searchTerm.toLowerCase())) as agingRow}

                          <tr class="cursor-pointer hover:bg-whisper"
                              on:click={() => {navigate(`/clients/${agingRow.customer?.company.companyId}/overview`)}}>
                              <td class="border-r border-athensGray whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                                  <div class="flex flex-row w-full justify-start items-center align-middle pt-1 ml-1.5">
                                      <div class="h-6 w-6 py-0.2 bg-black flex justify-center items-center rounded-md uppercase text-center text-sm">
                                          <span class="text-white">{agingRow.customer?.company?.formalName ? agingRow.customer.company.formalName.substring(0, 1) : agingRow.customer?.company?.formalName.substring(0, 1) }</span>
                                      </div>
                                      <div class="flex flex-col ml-3">
                                          <span class="text-sm font-medium text-gray-900">{agingRow.customer?.company?.formalName ? agingRow.customer?.company?.formalName : agingRow.customer?.company?.formalName }</span>
                                      </div>
                                  </div>
                              </td>
                              <td class="border-r border-athensGray whitespace-nowrap py-2 px-3 text-sm font-semibold text-gray-900">
                                  {formatCurrencyWithOptions(agingRow.total, t('locales'), invoicesCurrency, true, 'symbol')}
                              </td>
                              <td class="border-r border-athensGray whitespace-nowrap py-2 px-3 text-sm text-gray-900">
                                  {formatCurrencyWithOptions(agingRow.overdueMoreThan90dInvoicesAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                              </td>
                              <td class="border-r border-athensGray whitespace-nowrap py-2 px-3 text-sm text-gray-900">
                                  {formatCurrencyWithOptions(agingRow.overdue61And90dInvoicesAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                              </td>
                              <td class="border-r border-athensGray whitespace-nowrap py-2 px-3 text-sm text-gray-900">
                                  {formatCurrencyWithOptions(agingRow.overdue31And60dInvoicesAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                              </td>
                              <td class="border-r border-athensGray whitespace-nowrap py-2 px-3 text-sm text-black">
                                  {formatCurrencyWithOptions(agingRow.overdue30And1dInvoicesAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                              </td>
                              <td class="border-r border-athensGray whitespace-nowrap py-2 px-3 text-sm text-black">
                                  {formatCurrencyWithOptions(agingRow.due0And30dInvoicesAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                              </td>
                              <td class="border-r border-athensGray whitespace-nowrap py-2 px-3 text-sm text-gray-900">
                                  {formatCurrencyWithOptions(agingRow.due31And60dInvoicesAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                              </td>
                              <td class="border-r border-athensGray whitespace-nowrap py-2 px-3 text-sm text-gray-900">
                                  {formatCurrencyWithOptions(agingRow.due61And90dInvoicesAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                              </td>
                              <td class="border-r border-athensGray whitespace-nowrap py-2 px-3 text-sm text-gray-900">
                                  {formatCurrencyWithOptions(agingRow.dueMoreThan90dInvoicesAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                              </td>
                          </tr>
                      {/each}
                      {#if !searchTerm}
                          <tr>
                              <td class="bg-whisper border-r border-b border-athensGray whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-3">{t('agingBalance.header.total')}</td>
                              <td class="bg-whisper border-r border-b border-athensGray whitespace-nowrap py-2 px-3 text-sm font-semibold text-gray-900">
                                  {formatCurrencyWithOptions(totalAmountsForAllClientsAndTimeSlots.outstandingAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                              </td>
                              <td class="bg-whisper border-r border-b border-athensGray whitespace-nowrap py-2 px-3 text-sm font-semibold text-gray-900">
                                  {formatCurrencyWithOptions(totalAmountsForAllClientsAndTimeSlots.overdueMoreThan90dInvoicesAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                              </td>
                              <td class="bg-whisper border-r border-b border-athensGray whitespace-nowrap py-2 px-3 text-sm font-semibold text-gray-900">
                                  {formatCurrencyWithOptions(totalAmountsForAllClientsAndTimeSlots.overdue61And90dInvoicesAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                              </td>
                              <td class="bg-whisper border-r border-b border-athensGray whitespace-nowrap py-2 px-3 text-sm font-semibold text-gray-900">
                                  {formatCurrencyWithOptions(totalAmountsForAllClientsAndTimeSlots.overdue31And60dInvoicesAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                              </td>
                              <td class="bg-whisper border-r border-b border-athensGray whitespace-nowrap py-2 px-3 text-sm font-semibold text-gray-900">
                                  {formatCurrencyWithOptions(totalAmountsForAllClientsAndTimeSlots.overdue30And1dInvoicesAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                              </td>
                              <td class="bg-whisper border-r border-b border-athensGray whitespace-nowrap py-2 px-3 text-sm font-semibold text-gray-900">
                                  {formatCurrencyWithOptions(totalAmountsForAllClientsAndTimeSlots.due0And30dInvoicesAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                              </td>
                              <td class="bg-whisper border-r border-b border-athensGray whitespace-nowrap py-2 px-3 text-sm font-semibold text-gray-900">
                                  {formatCurrencyWithOptions(totalAmountsForAllClientsAndTimeSlots.due31And60dInvoicesAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                              </td>
                              <td class="bg-whisper border-r border-b border-athensGray whitespace-nowrap py-2 px-3 text-sm font-semibold text-gray-900">
                                  {formatCurrencyWithOptions(totalAmountsForAllClientsAndTimeSlots.due61And90dInvoicesAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                              </td>
                              <td class="bg-whisper border-r border-b border-athensGray whitespace-nowrap py-2 px-3 text-sm font-semibold text-gray-900">
                                  {formatCurrencyWithOptions(totalAmountsForAllClientsAndTimeSlots.dueMoreThan90dInvoicesAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                              </td>
                          </tr>
                      {/if}
                      </tbody>
                      <!-- Todo ADD PAGINATION -->
                      <!--<tfoot class="divide-y divide-gray-200 bg-white">
                      <tr class="border-top border-athensGray p-3 px-5">
                          <td class="px-5 pb-3">
                              <div class="text-paleSky text-xs mt-2">

                              </div>
                          </td>
                          <td></td>
                          <td class="p-3 px-5" colspan="8">

                          </td>
                      </tr>
                      </tfoot>-->
                  </table>
              </div>
          </div>
      </div>
      <div class="mt-8 bg-peachCream border border-athensGray py-2 px-3 rounded-md flex text-xs">
          <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M12 13V15"></path>
              <circle cx="12" cy="9" r="1" fill="currentColor"></circle>
              <circle cx="12" cy="12" r="7.25" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="1.5"></circle>
          </svg>
          <div class="ml-1">
              <h3 class="font-bold">{t('agingBalance.sideNote.title')}</h3>
              <p class="italic">{t('agingBalance.sideNote.copy_FirstLine')}</p>
              <p class="italic">{t('agingBalance.sideNote.copy_SecondLine')}</p>
          </div>
      </div>
  </main>
{/if}

<style global lang="postcss">
  .background {
    position: absolute;
    top: 1rem;
    left:1rem;
    right: 1rem;
    height: calc(100vh - 2rem);
  }
</style>
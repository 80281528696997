// Ref: https://www.html-code-generator.com/javascript/json/country-names
// All countries
// length 252
export const countriesEn = {
  'AF': { 'name': 'Afghanistan', 'symbol': '؋' },
  'AX': { 'name': 'Aland Islands', 'symbol': '€' },
  'AL': { 'name': 'Albania', 'symbol': 'Lek' },
  'DZ': { 'name': 'Algeria', 'symbol': 'دج' },
  'AS': { 'name': 'American Samoa', 'symbol': '$' },
  'AD': { 'name': 'Andorra', 'symbol': '€' },
  'AO': { 'name': 'Angola', 'symbol': 'Kz' },
  'AI': { 'name': 'Anguilla', 'symbol': '$' },
  'AQ': { 'name': 'Antarctica', 'symbol': '$' },
  'AG': { 'name': 'Antigua and Barbuda', 'symbol': '$' },
  'AR': { 'name': 'Argentina', 'symbol': '$' },
  'AM': { 'name': 'Armenia', 'symbol': '֏' },
  'AW': { 'name': 'Aruba', 'symbol': 'ƒ' },
  'AU': { 'name': 'Australia', 'symbol': '$' },
  'AT': { 'name': 'Austria', 'symbol': '€' },
  'AZ': { 'name': 'Azerbaijan', 'symbol': 'm' },
  'BS': { 'name': 'Bahamas', 'symbol': 'B$' },
  'BH': { 'name': 'Bahrain', 'symbol': '.د.ب' },
  'BD': { 'name': 'Bangladesh', 'symbol': '৳' },
  'BB': { 'name': 'Barbados', 'symbol': 'Bds$' },
  'BY': { 'name': 'Belarus', 'symbol': 'Br' },
  'BE': { 'name': 'Belgium', 'symbol': '€' },
  'BZ': { 'name': 'Belize', 'symbol': '$' },
  'BJ': { 'name': 'Benin', 'symbol': 'CFA' },
  'BM': { 'name': 'Bermuda', 'symbol': '$' },
  'BT': { 'name': 'Bhutan', 'symbol': 'Nu.' },
  'BO': { 'name': 'Bolivia', 'symbol': 'Bs.' },
  'BQ': { 'name': 'Bonaire, Sint Eustatius and Saba', 'symbol': '$' },
  'BA': { 'name': 'Bosnia and Herzegovina', 'symbol': 'KM' },
  'BW': { 'name': 'Botswana', 'symbol': 'P' },
  'BV': { 'name': 'Bouvet Island', 'symbol': 'kr' },
  'BR': { 'name': 'Brazil', 'symbol': 'R$' },
  'IO': { 'name': 'British Indian Ocean Territory', 'symbol': '$' },
  'BN': { 'name': 'Brunei Darussalam', 'symbol': 'B$' },
  'BG': { 'name': 'Bulgaria', 'symbol': 'Лв.' },
  'BF': { 'name': 'Burkina Faso', 'symbol': 'CFA' },
  'BI': { 'name': 'Burundi', 'symbol': 'FBu' },
  'KH': { 'name': 'Cambodia', 'symbol': 'KHR' },
  'CM': { 'name': 'Cameroon', 'symbol': 'FCFA' },
  'CA': { 'name': 'Canada', 'symbol': '$' },
  'CV': { 'name': 'Cape Verde', 'symbol': '$' },
  'KY': { 'name': 'Cayman Islands', 'symbol': '$' },
  'CF': { 'name': 'Central African Republic', 'symbol': 'FCFA' },
  'TD': { 'name': 'Chad', 'symbol': 'FCFA' },
  'CL': { 'name': 'Chile', 'symbol': '$' },
  'CN': { 'name': 'China', 'symbol': '¥' },
  'CX': { 'name': 'Christmas Island', 'symbol': '$' },
  'CC': { 'name': 'Cocos (Keeling) Islands', 'symbol': '$' },
  'CO': { 'name': 'Colombia', 'symbol': '$' },
  'KM': { 'name': 'Comoros', 'symbol': 'CF' },
  'CG': { 'name': 'Congo', 'symbol': 'FC' },
  'CD': { 'name': 'Congo, Democratic Republic of the Congo', 'symbol': 'FC' },
  'CK': { 'name': 'Cook Islands', 'symbol': '$' },
  'CR': { 'name': 'Costa Rica', 'symbol': '₡' },
  'CI': { 'name': 'Cote D\'Ivoire', 'symbol': 'CFA' },
  'HR': { 'name': 'Croatia', 'symbol': 'kn' },
  'CU': { 'name': 'Cuba', 'symbol': '$' },
  'CW': { 'name': 'Curacao', 'symbol': 'ƒ' },
  'CY': { 'name': 'Cyprus', 'symbol': '€' },
  'CZ': { 'name': 'Czech Republic', 'symbol': 'Kč' },
  'DK': { 'name': 'Denmark', 'symbol': 'Kr.' },
  'DJ': { 'name': 'Djibouti', 'symbol': 'Fdj' },
  'DM': { 'name': 'Dominica', 'symbol': '$' },
  'DO': { 'name': 'Dominican Republic', 'symbol': '$' },
  'EC': { 'name': 'Ecuador', 'symbol': '$' },
  'EG': { 'name': 'Egypt', 'symbol': 'ج.م' },
  'SV': { 'name': 'El Salvador', 'symbol': '$' },
  'GQ': { 'name': 'Equatorial Guinea', 'symbol': 'FCFA' },
  'ER': { 'name': 'Eritrea', 'symbol': 'Nfk' },
  'EE': { 'name': 'Estonia', 'symbol': '€' },
  'ET': { 'name': 'Ethiopia', 'symbol': 'Nkf' },
  'FK': { 'name': 'Falkland Islands (Malvinas)', 'symbol': '£' },
  'FO': { 'name': 'Faroe Islands', 'symbol': 'Kr.' },
  'FJ': { 'name': 'Fiji', 'symbol': 'FJ$' },
  'FI': { 'name': 'Finland', 'symbol': '€' },
  'FR': { 'name': 'France', 'symbol': '€' },
  'GF': { 'name': 'French Guiana', 'symbol': '€' },
  'PF': { 'name': 'French Polynesia', 'symbol': '₣' },
  'TF': { 'name': 'French Southern Territories', 'symbol': '€' },
  'GA': { 'name': 'Gabon', 'symbol': 'FCFA' },
  'GM': { 'name': 'Gambia', 'symbol': 'D' },
  'GE': { 'name': 'Georgia', 'symbol': 'ლ' },
  'DE': { 'name': 'Germany', 'symbol': '€' },
  'GH': { 'name': 'Ghana', 'symbol': 'GH₵' },
  'GI': { 'name': 'Gibraltar', 'symbol': '£' },
  'GR': { 'name': 'Greece', 'symbol': '€' },
  'GL': { 'name': 'Greenland', 'symbol': 'Kr.' },
  'GD': { 'name': 'Grenada', 'symbol': '$' },
  'GP': { 'name': 'Guadeloupe', 'symbol': '€' },
  'GU': { 'name': 'Guam', 'symbol': '$' },
  'GT': { 'name': 'Guatemala', 'symbol': 'Q' },
  'GG': { 'name': 'Guernsey', 'symbol': '£' },
  'GN': { 'name': 'Guinea', 'symbol': 'FG' },
  'GW': { 'name': 'Guinea-Bissau', 'symbol': 'CFA' },
  'GY': { 'name': 'Guyana', 'symbol': '$' },
  'HT': { 'name': 'Haiti', 'symbol': 'G' },
  'HM': { 'name': 'Heard Island and McDonald Islands', 'symbol': '$' },
  'VA': { 'name': 'Holy See (Vatican City State)', 'symbol': '€' },
  'HN': { 'name': 'Honduras', 'symbol': 'L' },
  'HK': { 'name': 'Hong Kong', 'symbol': '$' },
  'HU': { 'name': 'Hungary', 'symbol': 'Ft' },
  'IS': { 'name': 'Iceland', 'symbol': 'kr' },
  'IN': { 'name': 'India', 'symbol': '₹' },
  'ID': { 'name': 'Indonesia', 'symbol': 'Rp' },
  'IR': { 'name': 'Iran, Islamic Republic of', 'symbol': '﷼' },
  'IQ': { 'name': 'Iraq', 'symbol': 'د.ع' },
  'IE': { 'name': 'Ireland', 'symbol': '€' },
  'IM': { 'name': 'Isle of Man', 'symbol': '£' },
  'IL': { 'name': 'Israel', 'symbol': '₪' },
  'IT': { 'name': 'Italy', 'symbol': '€' },
  'JM': { 'name': 'Jamaica', 'symbol': 'J$' },
  'JP': { 'name': 'Japan', 'symbol': '¥' },
  'JE': { 'name': 'Jersey', 'symbol': '£' },
  'JO': { 'name': 'Jordan', 'symbol': 'ا.د' },
  'KZ': { 'name': 'Kazakhstan', 'symbol': 'лв' },
  'KE': { 'name': 'Kenya', 'symbol': 'KSh' },
  'KI': { 'name': 'Kiribati', 'symbol': '$' },
  'KP': { 'name': 'Korea, Democratic People\'s Republic of', 'symbol': '₩' },
  'KR': { 'name': 'Korea, Republic of', 'symbol': '₩' },
  'XK': { 'name': 'Kosovo', 'symbol': '€' },
  'KW': { 'name': 'Kuwait', 'symbol': 'ك.د' },
  'KG': { 'name': 'Kyrgyzstan', 'symbol': 'лв' },
  'LA': { 'name': 'Lao People\'s Democratic Republic', 'symbol': '₭' },
  'LV': { 'name': 'Latvia', 'symbol': '€' },
  'LB': { 'name': 'Lebanon', 'symbol': '£' },
  'LS': { 'name': 'Lesotho', 'symbol': 'L' },
  'LR': { 'name': 'Liberia', 'symbol': '$' },
  'LY': { 'name': 'Libyan Arab Jamahiriya', 'symbol': 'د.ل' },
  'LI': { 'name': 'Liechtenstein', 'symbol': 'CHf' },
  'LT': { 'name': 'Lithuania', 'symbol': '€' },
  'LU': { 'name': 'Luxembourg', 'symbol': '€' },
  'MO': { 'name': 'Macao', 'symbol': '$' },
  'MK': { 'name': 'Macedonia, the Former Yugoslav Republic of', 'symbol': 'ден' },
  'MG': { 'name': 'Madagascar', 'symbol': 'Ar' },
  'MW': { 'name': 'Malawi', 'symbol': 'MK' },
  'MY': { 'name': 'Malaysia', 'symbol': 'RM' },
  'MV': { 'name': 'Maldives', 'symbol': 'Rf' },
  'ML': { 'name': 'Mali', 'symbol': 'CFA' },
  'MT': { 'name': 'Malta', 'symbol': '€' },
  'MH': { 'name': 'Marshall Islands', 'symbol': '$' },
  'MQ': { 'name': 'Martinique', 'symbol': '€' },
  'MR': { 'name': 'Mauritania', 'symbol': 'MRU' },
  'MU': { 'name': 'Mauritius', 'symbol': '₨' },
  'YT': { 'name': 'Mayotte', 'symbol': '€' },
  'MX': { 'name': 'Mexico', 'symbol': '$' },
  'FM': { 'name': 'Micronesia, Federated States of', 'symbol': '$' },
  'MD': { 'name': 'Moldova, Republic of', 'symbol': 'L' },
  'MC': { 'name': 'Monaco', 'symbol': '€' },
  'MN': { 'name': 'Mongolia', 'symbol': '₮' },
  'ME': { 'name': 'Montenegro', 'symbol': '€' },
  'MS': { 'name': 'Montserrat', 'symbol': '$' },
  'MA': { 'name': 'Morocco', 'symbol': 'DH' },
  'MZ': { 'name': 'Mozambique', 'symbol': 'MT' },
  'MM': { 'name': 'Myanmar', 'symbol': 'K' },
  'NA': { 'name': 'Namibia', 'symbol': '$' },
  'NR': { 'name': 'Nauru', 'symbol': '$' },
  'NP': { 'name': 'Nepal', 'symbol': '₨' },
  'NL': { 'name': 'Netherlands', 'symbol': '€' },
  'AN': { 'name': 'Netherlands Antilles', 'symbol': 'NAf' },
  'NC': { 'name': 'New Caledonia', 'symbol': '₣' },
  'NZ': { 'name': 'New Zealand', 'symbol': '$' },
  'NI': { 'name': 'Nicaragua', 'symbol': 'C$' },
  'NE': { 'name': 'Niger', 'symbol': 'CFA' },
  'NG': { 'name': 'Nigeria', 'symbol': '₦' },
  'NU': { 'name': 'Niue', 'symbol': '$' },
  'NF': { 'name': 'Norfolk Island', 'symbol': '$' },
  'MP': { 'name': 'Northern Mariana Islands', 'symbol': '$' },
  'NO': { 'name': 'Norway', 'symbol': 'kr' },
  'OM': { 'name': 'Oman', 'symbol': '.ع.ر' },
  'PK': { 'name': 'Pakistan', 'symbol': '₨' },
  'PW': { 'name': 'Palau', 'symbol': '$' },
  'PS': { 'name': 'Palestinian Territory', 'symbol': '₪' },
  'PA': { 'name': 'Panama', 'symbol': 'B/.' },
  'PG': { 'name': 'Papua New Guinea', 'symbol': 'K' },
  'PY': { 'name': 'Paraguay', 'symbol': '₲' },
  'PE': { 'name': 'Peru', 'symbol': 'S/.' },
  'PH': { 'name': 'Philippines', 'symbol': '₱' },
  'PN': { 'name': 'Pitcairn', 'symbol': '$' },
  'PL': { 'name': 'Poland', 'symbol': 'zł' },
  'PT': { 'name': 'Portugal', 'symbol': '€' },
  'PR': { 'name': 'Puerto Rico', 'symbol': '$' },
  'QA': { 'name': 'Qatar', 'symbol': 'ق.ر' },
  'RE': { 'name': 'Reunion', 'symbol': '€' },
  'RO': { 'name': 'Romania', 'symbol': 'lei' },
  'RU': { 'name': 'Russian Federation', 'symbol': '₽' },
  'RW': { 'name': 'Rwanda', 'symbol': 'FRw' },
  'BL': { 'name': 'Saint Barthelemy', 'symbol': '€' },
  'SH': { 'name': 'Saint Helena', 'symbol': '£' },
  'KN': { 'name': 'Saint Kitts and Nevis', 'symbol': '$' },
  'LC': { 'name': 'Saint Lucia', 'symbol': '$' },
  'MF': { 'name': 'Saint Martin', 'symbol': '€' },
  'PM': { 'name': 'Saint Pierre and Miquelon', 'symbol': '€' },
  'VC': { 'name': 'Saint Vincent and the Grenadines', 'symbol': '$' },
  'WS': { 'name': 'Samoa', 'symbol': 'SAT' },
  'SM': { 'name': 'San Marino', 'symbol': '€' },
  'ST': { 'name': 'Sao Tome and Principe', 'symbol': 'Db' },
  'SA': { 'name': 'Saudi Arabia', 'symbol': '﷼' },
  'SN': { 'name': 'Senegal', 'symbol': 'CFA' },
  'RS': { 'name': 'Serbia', 'symbol': 'din' },
  'CS': { 'name': 'Serbia and Montenegro', 'symbol': 'din' },
  'SC': { 'name': 'Seychelles', 'symbol': 'SRe' },
  'SL': { 'name': 'Sierra Leone', 'symbol': 'Le' },
  'SG': { 'name': 'Singapore', 'symbol': '$' },
  'SX': { 'name': 'St Martin', 'symbol': 'ƒ' },
  'SK': { 'name': 'Slovakia', 'symbol': '€' },
  'SI': { 'name': 'Slovenia', 'symbol': '€' },
  'SB': { 'name': 'Solomon Islands', 'symbol': 'Si$' },
  'SO': { 'name': 'Somalia', 'symbol': 'Sh.so.' },
  'ZA': { 'name': 'South Africa', 'symbol': 'R' },
  'GS': { 'name': 'South Georgia and the South Sandwich Islands', 'symbol': '£' },
  'SS': { 'name': 'South Sudan', 'symbol': '£' },
  'ES': { 'name': 'Spain', 'symbol': '€' },
  'LK': { 'name': 'Sri Lanka', 'symbol': 'Rs' },
  'SD': { 'name': 'Sudan', 'symbol': '.س.ج' },
  'SR': { 'name': 'Suriname', 'symbol': '$' },
  'SJ': { 'name': 'Svalbard and Jan Mayen', 'symbol': 'kr' },
  'SZ': { 'name': 'Swaziland', 'symbol': 'E' },
  'SE': { 'name': 'Sweden', 'symbol': 'kr' },
  'CH': { 'name': 'Switzerland', 'symbol': 'CHf' },
  'SY': { 'name': 'Syrian Arab Republic', 'symbol': 'LS' },
  'TW': { 'name': 'Taiwan, Province of China', 'symbol': '$' },
  'TJ': { 'name': 'Tajikistan', 'symbol': 'SM' },
  'TZ': { 'name': 'Tanzania, United Republic of', 'symbol': 'TSh' },
  'TH': { 'name': 'Thailand', 'symbol': '฿' },
  'TL': { 'name': 'Timor-Leste', 'symbol': '$' },
  'TG': { 'name': 'Togo', 'symbol': 'CFA' },
  'TK': { 'name': 'Tokelau', 'symbol': '$' },
  'TO': { 'name': 'Tonga', 'symbol': '$' },
  'TT': { 'name': 'Trinidad and Tobago', 'symbol': '$' },
  'TN': { 'name': 'Tunisia', 'symbol': 'ت.د' },
  'TR': { 'name': 'Turkey', 'symbol': '₺' },
  'TM': { 'name': 'Turkmenistan', 'symbol': 'T' },
  'TC': { 'name': 'Turks and Caicos Islands', 'symbol': '$' },
  'TV': { 'name': 'Tuvalu', 'symbol': '$' },
  'UG': { 'name': 'Uganda', 'symbol': 'USh' },
  'UA': { 'name': 'Ukraine', 'symbol': '₴' },
  'AE': { 'name': 'United Arab Emirates', 'symbol': 'إ.د' },
  'GB': { 'name': 'United Kingdom', 'symbol': '£' },
  'US': { 'name': 'United States', 'symbol': '$' },
  'UM': { 'name': 'United States Minor Outlying Islands', 'symbol': '$' },
  'UY': { 'name': 'Uruguay', 'symbol': '$' },
  'UZ': { 'name': 'Uzbekistan', 'symbol': 'лв' },
  'VU': { 'name': 'Vanuatu', 'symbol': 'VT' },
  'VE': { 'name': 'Venezuela', 'symbol': 'Bs' },
  'VN': { 'name': 'Viet Nam', 'symbol': '₫' },
  'VG': { 'name': 'Virgin Islands, British', 'symbol': '$' },
  'VI': { 'name': 'Virgin Islands, U.s.', 'symbol': '$' },
  'WF': { 'name': 'Wallis and Futuna', 'symbol': '₣' },
  'EH': { 'name': 'Western Sahara', 'symbol': 'MAD' },
  'YE': { 'name': 'Yemen', 'symbol': '﷼' },
  'ZM': { 'name': 'Zambia', 'symbol': 'ZK' },
  'ZW': { 'name': 'Zimbabwe', 'symbol': '$' }
}
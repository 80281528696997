import { t } from '$core/lib/i18n/i18nextWrapper'
import { type BarChoice } from '$shared/components/bar/bar.d'

export type Field = {
  key:string,
  label?:string,
  hasLocale?:boolean,
  multiline?:boolean
}

export type FormField = Field & {
  value:any,
  placeholder:any
}

export type Media = 'dunningEmail' | 'dunningPhoneCall' | 'dunningPostalLetter'
export type Fields = Record<Media, Field[]>
export type FieldsErrors = { [key: string]: string }

export const fields:Fields = {
  dunningEmail: [
    { key: 'emailFrom', label: t('template.modal.from') },
    { key: 'emailsTo', label: t('template.modal.to') },
    { key: 'emailsCC', label: t('template.modal.cc') },
    { key: 'emailsCCI', label: t('template.modal.cci') },
    { key: 'subject', label: t('template.modal.subject'), hasLocale: true },
    { key: 'body', hasLocale: true, multiline: true }
  ],
  dunningPhoneCall: [
    { key: 'contactDestination', label: t('template.modal.to') },
    { key: 'callCanevas', hasLocale: true, multiline: true }
  ],
  dunningPostalLetter: [
    { key: 'contactDestination', label: t('template.modal.attentionOf') },
    { key: 'mailAddressDestination', label: t('template.modal.mailTo') },
    { key: 'letterSubject', label: t('template.modal.subject'), hasLocale: true },
    { key: 'letterBody', hasLocale: true, multiline: true }
  ]
}

export const medias:BarChoice[] = [
  { key: 'DunningEmail', label: t('todoAction.dunningMeansTab.email'), icon: '/img/icons/mail.svg' },
  { key: 'DunningPhoneCall', label: t('todoAction.dunningMeansTab.phoneCall'), icon: '/img/icons/phone.svg' },
  { key: 'DunningPostalLetter', label: t('todoAction.dunningMeansTab.postalLetter'), icon: '/img/icons/mailbox.svg' }
]
  
export const modes:BarChoice[] = [
  { key: 'edit', label: t('template.modal.edit') },
  { key: 'preview', label: t('template.modal.preview') }
]
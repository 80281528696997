<script lang="ts">
  import { createTemplate, updateTemplate, getTemplateById, getTemplateByName } from '$src/dundy-app/services/template.service'
  import { feedbackService } from '$src/core-app/services/feedback.service'
  import { navigate } from 'svelte-routing'
  import { onMount, tick } from 'svelte'
  import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
  import { Template } from '$src/dundy-app/models/template'
  import Bar from './components/Bar.svelte' 
  import FullSizeModal from '$src/core-app/lib/ui-kit/FullSizeModal.svelte'
  import Input from './components/Input.svelte'
  import type { Feedback } from '$src/core-app/models/feedback'
  import type { Keyword, Keywords } from './components/template.model'
  import Variable from './components/Variable.svelte'

  // type EscalationProps = {
  //   optionIdentifier: string,
  //   labelIdentifier: string,
  //   items: { key:string, label: string }[]
  // }

  // TODO: utiliser setContext https://svelte.dev/docs/svelte#setcontext
  const keywords:Keywords = [
    { key: '{invoiceNumber}', value: t('template.variable.keywords.invoiceNumber'), tags: ['subject', 'body'] },
    { key: '{issueDate}', value: t('template.variable.keywords.issueDate'), tags: ['subject', 'body'] }, 
    { key: '{maturityDate}', value: t('template.variable.keywords.maturityDate'), tags: ['subject', 'body'] }, 
    { key: '{remainingAmount}', value: t('template.variable.keywords.remainingAmount'), tags: ['subject', 'body', 'phone'] }, 
    { key: '{amount}', value: t('template.variable.keywords.amount'), tags: ['subject', 'body', 'phone'] }, 
    { key: '{clientSurname}', value: t('template.variable.keywords.clientSurname'), tags: ['subject', 'body', 'phone'] }, 
    { key: '{clientName}', value: t('template.variable.keywords.clientName'), tags: ['subject', 'body', 'phone'] }, 
    { key: '{companyName}', value: t('template.variable.keywords.companyName'), tags: ['subject', 'body', 'phone'] }, 
    { key: '{companyAddress}', value: t('template.variable.keywords.companyAddress'), tags: ['subject', 'body', 'phone'] },
    { key: '{phoneContact}', value: t('template.variable.keywords.phoneContact'), tags: ['subject', 'body', 'phone'] },
    { key: '{mailContact}', value: t('template.variable.keywords.mailContact'), tags: ['from', 'to', 'cc', 'cci', 'subject', 'body'] },
    { key: '{mailCommercial}', value: t('template.variable.keywords.mailCommercial'), tags: ['from', 'to', 'cc', 'cci', 'subject', 'body'] }
  ]

  const escalation = [
    { key: 'information', label: t('todoAction.dunningEscalationTab.information'), icon: '/img/icons/information.svg' },
    { key: 'reminder', label: t('todoAction.dunningEscalationTab.reminder'), icon: '/img/icons/reminder.svg' },
    { key: 'warning', label: t('todoAction.dunningEscalationTab.warning'), icon: '/img/icons/warning.svg' },
    { key: 'legal', label: t('todoAction.dunningEscalationTab.legal'), icon: '/img/icons/legal.svg' }
  ]

  const medias = [
    { key: 'mail', label: t('todoAction.dunningMeansTab.email'), icon: '/img/icons/mail.svg' },
    { key: 'phone', label: t('todoAction.dunningMeansTab.phoneCall'), icon: '/img/icons/phone.svg' },
    { key: 'letter', label: t('todoAction.dunningMeansTab.postalLetter'), icon: '/img/icons/mailbox.svg' }
  ]

  const forms: Record<string, string[]> = {
    mail: ['from', 'to', 'cc', 'cci', 'subject'],
    phone: ['subject', 'phone'],
    letter: ['from', 'to', 'cc', 'subject']
  }

  let input:Input
  let inputName:string
  let loading:boolean = true
  let template:Template = new Template('', [], [], '', '', '', '', '', '')
  let templateId:number

  $:allowForm = template.escalation.length && template.media.length
  $:form = forms[template.media]

  const isValid = ():boolean => {
    const empty = form.some((i: string) => !template[i])
    if (empty) return false
    
    if (!template.name || !template.body || !template.escalation) return false

    return true
  }

  const toast = (type:string, message:string) => feedbackService.displayFeedback({
    feedbackMessage: message,
    feedbackLevel: type
  } as Feedback)

  // let escalationProps: EscalationProps = { 
  //   optionIdentifier: 'key',
  //   labelIdentifier: 'label',
  //   items: Object.values(TodoUIDunningEscalationTabs).map(e => ({ key: e, label: t(`todoAction.dunningEscalationTab.${e?.toLowerCase()}`) }))
  // } 
  // let escalation:any

  const onVariableSelected = (v:Keyword) => {
    input.injectKeyword(v)
  }

  const onInputFocus = (e:any, name: string) => {
    inputName = name
    input = e.detail

  }

  // const onSelectEscalation = (e:any) => {
  //   template.escalation = e.detail
  // }

  const onClose = () => {
    navigate('/template')
  }

  const onSave = async () => {
    if (!isValid()) {
      toast('Error', t('template.missPropertiesFeedback'))
      
      return
    }

    let apiRes:string

    if (templateId) {
      apiRes = await updateTemplate(templateId, template)
    } else {
      const exists = await getTemplateByName(template.name)
      if (exists) {
        toast('Error', t('template.existingTemplateFeedback'))
        
        return
      }
      apiRes = await createTemplate(template)
    }
    
    // OK
    if (!apiRes) {
      toast('Success', templateId ? t('template.updateFeedback') : t('template.createFeedback'))

      tick()
      onClose()
      
      return
    }

    // ERROR
    toast('Error', apiRes)
  }

  onMount(async () => {
    // retrieve keywords from api
    // TODO : retrieve keywords from api

    // retrieve id for edition
    const tmp = /edit\/(\d+)/.exec(location.pathname)
    if (tmp) templateId = Number(tmp[1])
    if (templateId) {
      const tp = await getTemplateById(templateId)
      if (tp) {
        template = tp
        if (template.media.length > 0) form = forms[template.media[0]]
      }
    }

    loading = false
  })
</script>

<FullSizeModal class="size-full overflow-y-auto" isCloseable={true} on:close={() => onClose()}>

  <div slot="content" class="w-full h-full overflow-y-auto flex">
    <div class="flex flex-col p-12 basis-[25rem] border-r border-athensGray">
      <h1 class="font-bold text-2xl">{t('template.modal.title')}</h1>

      <div class="my-8 h-full">
        {#if input && inputName !== 'name'}
          <Variable placeholder={t('template.variable.filter')} {keywords} tag={inputName} on:select={(v) => onVariableSelected(v.detail)} />
        {:else}
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nobis amet soluta deserunt molestiae a eos officia quo modi, porro tenetur exercitationem culpa officiis eius ea? Delectus placeat laudantium repellendus harum?
        {/if}
        <br>
      </div>
    </div>

    <div class="size-full flex flex-col bg-slate-50">

      <div class="form mx-auto flex-1 m-3 mt-20 flex flex-col w-8/12">
        {#if !loading}
          <div>
            <Bar choices={escalation} bind:value={template.escalation} multiple={true} />
          </div>
          <div>
            <Bar choices={medias} bind:value={template.media} />
          </div>
        {/if}
        {#if allowForm}
          <!-- <div>
            <label for="escalation">{t('template.modal.escalation')}</label>
            <div class="w-full">
              <StyledSelect value={escalation} isSearchable={false} {...escalationProps} on:select={e => onSelectEscalation(e)} />
            </div>
          </div> -->
          <div>
            <label for="name">{t('template.modal.name')}</label>
            <Input id="name" {keywords} bind:value={template.name} on:focus={e => onInputFocus(e, 'name')} />
          </div>
          {#if form.includes('from')}
            <div>
              <label for="from">{t('template.modal.from')}</label>
              <Input id="from" {keywords} bind:value={template.from} on:focus={e => onInputFocus(e, 'from')} />
            </div>
          {/if}
          {#if form.includes('to')}
            <div>
              <label for="to">{t('template.modal.to')}</label>
              <Input id="to" {keywords} bind:value={template.to} on:focus={e => onInputFocus(e, 'to')} />
            </div>
          {/if}
          {#if form.includes('cc')}
            <div>
              <label for="cc">{t('template.modal.cc')}</label>
              <Input id="cc" {keywords} bind:value={template.cc} on:focus={e => onInputFocus(e, 'cc')} />
            </div>
          {/if}
          {#if form.includes('cci')}
            <div>
              <label for="cci">{t('template.modal.cci')}</label>
              <Input id="cci" {keywords} bind:value={template.cci} on:focus={e => onInputFocus(e, 'cci')} />
            </div>
          {/if}
          {#if form.includes('subject')}
            <div>
              <label for="subject">{t('template.modal.subject')}</label>
              <Input id="subject" {keywords} bind:value={template.subject} on:focus={e => onInputFocus(e, 'subject')} />
            </div>
          {/if}
          {#if form.includes('phone')}
            <div>
              <label for="phone">{t('template.modal.phone')}</label>
              <Input id="phone" {keywords} bind:value={template.phone} on:focus={e => onInputFocus(e, 'to')} />
            </div>
          {/if}
          <div class="flex-1">
            <Input id="body" {keywords} bind:value={template.body} on:focus={e => onInputFocus(e, 'body')} />
          </div>
        {/if}
      </div>

      <div class="w-full flex justify-center py-8 border-t border-whisper bg-white">
        <button class="btn action-cancel mr-8" on:click|preventDefault|stopPropagation={() => onClose()}>
          {t('template.modal.cancel')}
        </button>
        <button class="btn action-bla primary" disabled={!allowForm} on:click|preventDefault|stopPropagation={() => onSave()}>
          {t('template.modal.save')}
        </button>
      </div>

    </div>

  </div>
</FullSizeModal>  

<style lang="postcss">
  .form {
    > div {
      @apply flex items-center h-10 mt-4;

      label {
        @apply w-2/12 text-sm;
      }
    }
  }
</style>
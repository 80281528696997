<script lang="ts">
    /** On Mount **/
    import { onMount } from 'svelte'
    import { updateNewNavigationHistoryItem } from '../../../../core-app/stores/navigationHistory.store'
    import { BusinessDocument } from '../../../models/business-document'
    import StyledSelect from '../../../../core-app/lib/ui-kit/StyledSelect.svelte'
    import { getSupportedCurrenciesList } from '../../../../core-app/services/currency-list'
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper.js'
    import { VoxyHelper } from '../../../helpers/voxy-helper.js'

    /** Exported Var **/
    export let businessDocument: BusinessDocument

    /** Local var */
    const supportedCurrenciesList = getSupportedCurrenciesList()

    /** On Change Currency **/
    $: if (businessDocument?.currency) {
      businessDocument = VoxyHelper.updateBusinessLineItemsCurrencyAccordingToBusinessDocumentCurrency(businessDocument)
    }

    onMount(() => {
      updateNewNavigationHistoryItem('BusinessDocumentRelatedDocumentsPanel.svelte')
    })
</script>

<div class="sm:px-6">
    <div class="space-y-6 py-7">
        <div class="px-12 sm:px-6">
            <div class="col-span-2">
                <div class="text-[0.8125rem] leading-5 text-slate-900">
                    <h2 class="block text-2xl font-bold text-gray-900 mb-3">{t('invoices.createInvoice.settings.currencyTitle')}</h2>
                    <div class="flex flex-col w-full">
                        <p class="text-s">{t('invoices.createInvoice.settings.currencyCopy')}</p>
                        <div class="mt-1.5 small-select shadow-sm h-10 w-full">
                            <StyledSelect
                                    bind:value={businessDocument.currency}
                                    isSearchable={false}
                                    items={supportedCurrenciesList}
                                    placeholder="EUR"
                                    label="Currency"
                                    disabled={false}
                                    selected={businessDocument.currency}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

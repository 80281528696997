import { get, type Unsubscriber, writable } from 'svelte/store'
import VoxyAction from '../models/voxy-action'
import { v4 as uuidv4 } from 'uuid'
import { isAuthenticatedUnderAuth0JsClient } from '../../core-app/lib/auth0authentication/authStore'
import { Subscription, timer } from 'rxjs'
import { voxyPreferencesService } from '../services/voxy-preferences.service'

// Reference: https://medium.com/@karenmarkosyan/how-to-manage-promises-into-dynamic-queue-with-vanilla-javascript-9d0d1f8d4df5

const STANDARD_VOXY_ACTION_PIPELINE_FIRST_TASK_DELAY_MS = 100
const STANDARD_VOXY_ACTION_PIPELINE_INTERVAL_MS = 10000

export let VoxyActionPipelineStore = writable<VoxyAction[]>([])

let unSubscriberVoxyActionPipelineStore: Unsubscriber

// export let VoxyActionPipelineIntervalId: NodeJS.Timer = null; // initializeVoxyActionPipelineInterval(); // NB: let isAuthenticatedUnderAuth0JsClient.subscribe((isAuth) start the pipeline
export let subscriptionVoxyActionPipelineTimer: Subscription = undefined // initializeVoxyActionPipelineInterval(); // NB: let isAuthenticatedUnderAuth0JsClient.subscribe((isAuth) start the pipeline

let VoxyActionPipelineIsRunningStore = writable<boolean>(false)
let VoxyActionPipelineIsEnabledStore = writable<boolean>(false)

// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
isAuthenticatedUnderAuth0JsClient.subscribe((isAuth) => {
  if (isAuth) {
    VoxyActionPipelineIsEnabledStore.set(true)
  } else {
    VoxyActionPipelineIsEnabledStore.set(false)
  }
})

// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
if (!!unSubscriberVoxyActionPipelineStore) {
  unSubscriberVoxyActionPipelineStore()
}
VoxyActionPipelineStore.subscribe(newVoxyActionPipeline => {
  if (!!newVoxyActionPipeline) {
    /* console.log('%c VoxyActionPipelineStore changed and has ' + (newVoxyActionPipeline.filter((d:VoxyAction)=>true).length) + ' item(s) to execute.  vxp ***-***', 'color: #8A99AC') */
  } else {
    /* console.log('%c VoxyActionPipelineStore NOT initialized.  vxp ***-***', 'color: #FF99AC') */
  }
})

/**
 * 
 * @param {number} interval time for each cycle in milliseconds (default = STANDARD_VOXY_ACTION_PIPELINE_INTERVAL_MS)
 */
const startVoxyActionPipelineInterval = (interval?:number) => {
  // // we make sure VoxyActionPipelineIntervalId is non null or non undefined otherwise we start the pipeline
  // if (!VoxyActionPipelineIntervalId) {
  //     if (get(VoxyActionPipelineIsEnabledStore)) {

  //         VoxyActionPipelineIntervalId = initializeVoxyActionPipelineInterval(STANDARD_VOXY_ACTION_PIPELINE_INTERVAL_MS);


  //     } else {


  //     }
  // } else {
  //     if (get(VoxyActionPipelineIsEnabledStore)) {

  //     } else {


  //     }
  // }
  // we make sure subscriptionVoxyActionPipelineTimer is non null or non undefined otherwise we start the pipeline
  if (!subscriptionVoxyActionPipelineTimer) {
    if (get(VoxyActionPipelineIsEnabledStore)) {
      /* console.log('starting VoxyObservableActionPipeline  vxp ***-***') */
      subscriptionVoxyActionPipelineTimer = initializeVoxyActionPipelineIntervalAndStartImmediately(
        STANDARD_VOXY_ACTION_PIPELINE_FIRST_TASK_DELAY_MS,
        interval ?? STANDARD_VOXY_ACTION_PIPELINE_INTERVAL_MS)
      /* console.log('subscriptionVoxyActionPipelineTimer  vxp ***-***', subscriptionVoxyActionPipelineTimer) */
      /* console.log('subscriptionVoxyActionPipelineTimer  vxp ***-***, typeof', typeof subscriptionVoxyActionPipelineTimer) */
      /* console.log('subscriptionVoxyActionPipelineTimer  vxp ***-***, is instanceof Subscription', subscriptionVoxyActionPipelineTimer instanceof Subscription) */
      /* console.log('subscriptionVoxyActionPipelineTimer  vxp ***-***, is instanceof Observable', subscriptionVoxyActionPipelineTimer instanceof Observable) */
      /* console.log('subscriptionVoxyActionPipelineTimer  vxp ***-***, is instanceof Object', subscriptionVoxyActionPipelineTimer instanceof Object) */
      /* console.log('subscriptionVoxyActionPipelineTimer  vxp ***-***, is instanceof VoxyAction', subscriptionVoxyActionPipelineTimer instanceof VoxyAction) */
      /* console.log('subscriptionVoxyActionPipelineTimer  vxp ***-***, is instanceof BusinessDocument', subscriptionVoxyActionPipelineTimer instanceof BusinessDocument) */
    } else {
      /* console.log('disabled VoxyObservableActionPipeline !  vxp ***-***') */
      /* console.error('error in startVoxyObservableActionPipelineInterval: cannot start a new voxy action to pipeline without being enabled vxp ') */
    }
  } else {
    if (get(VoxyActionPipelineIsEnabledStore)) {
      /* console.log('already started VoxyObservableActionPipeline  vxp ***-***') */
    } else {
      /* console.log('disabled VoxyObservableActionPipeline !  vxp ***-***') */
      /* console.error('error in startVoxyActionPipelineInterval: cannot start a new voxy Observable action to pipeline without being enabled') */
    }
  }
}

const stopVoxyActionPipelineInterval = async () => {
  
  // if (!VoxyActionPipelineIntervalId) {

  // } else {

  //     clearInterval(VoxyActionPipelineIntervalId)
  // }
  if (!subscriptionVoxyActionPipelineTimer) {
    /* console.log('already stopped subscriptionVoxyActionPipelineTimer  vxp ***-***') */
  } else {
    /* console.log('stopping subscriptionVoxyActionPipelineTimer  vxp ***-***, typeof', typeof subscriptionVoxyActionPipelineTimer) */
    subscriptionVoxyActionPipelineTimer.unsubscribe()
    subscriptionVoxyActionPipelineTimer = undefined

    await voxyPreferencesService.getVoxyPreferencesFromBackEndAPI('voxy-action-pipeline.store.ts - refresh store when pipeline is empty')
  }
}

function executeATaskInThePipeline() {
  /* console.log('in action loop  vxp ***-***') */
  if (!get(VoxyActionPipelineIsRunningStore) && get(VoxyActionPipelineIsEnabledStore)) {
    VoxyActionPipelineIsRunningStore.set(true)
    if (get(VoxyActionPipelineStore).length > 0) {
      /* console.log('running a new action  vxp ***-***') */
      const listOfActions = get(VoxyActionPipelineStore)
      const newAction = listOfActions.shift()

      VoxyActionPipelineStore.set(listOfActions)

      newAction.promise()
        .then(() => {
          /* console.log('OK DONE executing Voxy action from pipeline  vxp ***-***', newAction.actionId, newAction.timestamp, newAction.description) */
          VoxyActionPipelineIsRunningStore.set(false)
        })
        .catch((reason => {
          console.error('ERROR when executing Voxy action from pipeline  vxp ***-***', newAction.actionId, newAction.timestamp, newAction.description, reason)
          VoxyActionPipelineIsRunningStore.set(false)
        }))
    } else {
      /* console.log('idle (empty pipeline)  vxp ***-***') */
      VoxyActionPipelineIsRunningStore.set(false)
      /* console.log('idle (empty pipeline) => need to stop until new VoxyActionPipeline workload  vxp ***-***') */
      stopVoxyActionPipelineInterval()
    }
  } else {
    if (get(VoxyActionPipelineIsRunningStore)) {
      /* console.log('already running an action  vxp ***-***') */
    } else if (!get(VoxyActionPipelineIsEnabledStore)) {
      /* console.log('now disabled => need to stop interval until new VoxyActionPipeline workload  vxp ***-***') */
      stopVoxyActionPipelineInterval()
    } else {
      throw 'condition unreachable reached in initializeVoxyActionPipelineInterval'
    }
  }
}

function initializeVoxyActionPipelineInterval(intervalDelayInMilliSeconds: number): NodeJS.Timer {
  return setInterval(() => {
    executeATaskInThePipeline()
  }, intervalDelayInMilliSeconds) // timeout in ms, e.g. 5000 for 5s, 250 for 250ms
}

// initializeVoxyActionPipelineIntervalAndStartImmediately uses:
// - startAfterMilliSeconds: first delay after which the first task is excuted in ms, e.g. 5000 for 5s, 250 for 250ms
// - intervalDelayInMilliSeconds: subsequent delays between tasks (not first delay before first task) in ms, e.g. 5000 for 5s, 250 for 250ms
// RxJS timer: https://www.learnrxjs.io/learn-rxjs/operators/creation/timer
//             https://rxjs.dev/api/index/function/timer
function initializeVoxyActionPipelineIntervalAndStartImmediately(startAfterMilliSeconds: number, intervalDelayInMilliSeconds: number): Subscription {
  const observableTaskTimer: Subscription = timer(startAfterMilliSeconds, intervalDelayInMilliSeconds).subscribe(() => {
    executeATaskInThePipeline()
  })

  return observableTaskTimer
}

export const NewVoxyActionToPipeline = (newPromise: () => Promise<any>, newDescription: string, interval?:number) => {
  /* console.log('adding a new action to the Voxy action pipeline  vxp ***-***') */
  const listOfActions = get(VoxyActionPipelineStore)
  listOfActions.push(<VoxyAction>{
    actionId: uuidv4(),
    timestamp: Date.now(),
    description: newDescription,
    promise: newPromise
  })
  VoxyActionPipelineStore.set(listOfActions)
  /* console.log('after adding new action  vxp ***-***', JSON.stringify(get(VoxyActionPipelineStore), null, 3)) */
  startVoxyActionPipelineInterval(interval)
}

import { get } from 'svelte/store'
import { ProfileStore } from '../stores/profile.store'
import * as timeago from 'timeago.js'
import { t } from '../lib/i18n/i18nextWrapper'

/**
 * Create a date object according to the user timezone
 * uses toLocaleString instead of Intl.DateTimeFormat to create a date object adjusted to the specified timezone
 * @param timeZone
 */
export function createCurrentDateObjectAccordingToUserTimezone(timeZone: string): Date {
  const now: Date = new Date();
  const formatted: string = now.toLocaleString('en-US', { timeZone })

  const matchResult: RegExpMatchArray | null = formatted.match(/\d+|AM|PM/g)
  if (!matchResult) {
    throw new Error('Failed to parse date string.')
  }

  const [month, day, year, hour, minute, second, amPm] = matchResult
  const hour24: number = amPm === 'PM' && +hour < 12 ? +hour + 12 : +hour === 12 && amPm === 'AM' ? 0 : +hour

  return new Date(+year, +month - 1, +day, hour24, +minute, +second)
}

/**
 * Format a date to a short date string
 * @param date
 */
export const formatToShortDate = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  } as Intl.DateTimeFormatOptions

  return new Intl.DateTimeFormat(get(ProfileStore).lang, options).format(date)
}

/**
 * Format a date with slashes
 * @param date
 * @param overrideLocale
 */
export const formatToDateWithSlashes = (date: Date | string, overrideLocale?: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  } as Intl.DateTimeFormatOptions

  let dateObj: Date;
  if (date instanceof Date) {
    dateObj = date
  } else {
    dateObj = new Date(date)
  }

  if (isNaN(dateObj.getTime())) {
    console.error('Invalid date provided:', date)
    return 'Invalid date'
  }

  // Assuming `get(ProfileStore).lang` safely returns a string
  const locale: string = overrideLocale || get(ProfileStore).lang

  return new Intl.DateTimeFormat(locale, options).format(dateObj)
};

/**
 * Return a human-readable time ago string
 * @param date
 * @param locale
 */
export const formatTimeAgo = (date: timeago.TDate, locale: string) => timeago.format(date, locale)

/**
 * Time ago locale function dictionary
 * defining locales as an array of tuples with exactly two strings each
 * so typescript can infer the type of the tuple
 * @param index
 */
function localeFunc(index: number): [string, string] {
  const locales: [string, string][] = [
    [t('timeAgo.now'), t('timeTo.now')],
    [t('timeAgo.secondsAgo'), t('timeAgo.secondsTo')],
    [t('timeAgo.minuteAgo'), t('timeAgo.minuteTo')],
    [t('timeAgo.minutesAgo'), t('timeAgo.minutesTo')],
    [t('timeAgo.hourAgo'), t('timeAgo.hourTo')],
    [t('timeAgo.hoursAgo'), t('timeAgo.hoursTo')],
    [t('timeAgo.dayAgo'), t('timeAgo.dayTo')],
    [t('timeAgo.daysAgo'), t('timeAgo.daysTo')],
    [t('timeAgo.weekAgo'), t('timeAgo.weekTo')],
    [t('timeAgo.weeksAgo'), t('timeAgo.weeksTo')],
    [t('timeAgo.monthAgo'), t('timeAgo.monthTo')],
    [t('timeAgo.monthsAgo'), t('timeAgo.monthsTo')],
    [t('timeAgo.yearAgo'), t('timeAgo.yearTo')],
    [t('timeAgo.yearsAgo'), t('timeAgo.yearsTo')]
  ]

  return locales[index]
}

timeago.register(t('locales'), localeFunc)
<script lang="ts">
    import type { TodoListItem } from '../../models/todo-list-item'
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import { formatToShortDate } from '../../../core-app/util/date-utils'

    export let todo: TodoListItem
</script>

<div class="flex items-center relative">
    {#if todo && todo.isDone}
        <div class="w-3 h-3 bg-cruise border border-blueStone absolute transform rounded-full z-10 mt-2 md:mt-0"></div>
        <div class="ml-6 flex-auto">
            <h4 class="text-xxs font-medium">{t(`history.kind.${todo?.actionKind}.timelineDoneState`)}</h4>
            <p class="text-xxs">{t(`history.kind.${todo?.actionKind}.timelineDoneCopy`, { dateDone: formatToShortDate(new Date(todo?.dateDone * 1000)) })}</p>
        </div>
    {:else if todo}
        <div class="w-3 h-3 bg-dundyOrange border border-fieryOrange absolute transform rounded-full z-10 mt-2 md:mt-0"></div>
        <div class="ml-6 flex-auto">
            <h4 class="text-xxs font-medium">{t(`history.kind.${todo?.actionKind}.timelineState`)}</h4>
            <p class="text-xxs text-dundyOrange">{t('todoAction.actionValidateToday')}</p>
        </div>
    {:else}
        <div class="w-3 h-3 bg-zinc-100 border border-athensGray absolute transform rounded-full z-10 mt-2 md:mt-0"></div>
        <div class="ml-6 flex-auto">
            <p class="text-xxs">{t('todoAction.noAction')}</p>
        </div>
    {/if}
</div>
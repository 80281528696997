import { BusinessDocument, BusinessDocumentRelation } from '../../models/business-document'
import { sortRelatedDocumentsByDate } from '../business-document-relation/business-document-relation-sorting.service'
import {
  getBusinessDocumentKindDesignation,
  getRelationKindString
} from '../business-documents-functions'
import { BusinessDocumentKind } from '../../enums/business-document-kind'
import { NumberingScheme, NumberingSchemeSettings } from '../../models/voxy-preferences'
import { get } from 'svelte/store'
import { VoxyPreferencesStore } from '../../stores/voxy-preference.store'
import {
  deleteDoubleAccoladesAndReplacePlaceholdersWithDateValues,
  replaceDefaultFirstLetterOfStringFromServerWithTranslatedOne, replaceNumberOfDigitsWithXs
} from '../../utils/numbering-utils'

/**
 * Returns the list of related documents description
 * @param businessDocument
 * @param lang
 */
export function getBusinessDocumentSubjectMainTitleMainDescriptionRelatedDocumentsListDescription(businessDocument: BusinessDocument, lang: string): string[] {
  if (!businessDocument.relatedBusinessDocuments) {
    return []
  }

  const sortedRelatedDocuments: BusinessDocumentRelation[] = sortRelatedDocumentsByDate(businessDocument.relatedBusinessDocuments)

  const desc: string[] = buildRelatedDocumentsDescriptionList(
    sortedRelatedDocuments,
    businessDocument.hasSpecificInstallment,
    businessDocument.businessDocumentId,
    lang,
  )
  /* console.log('getBusinessDocumentUpdatedInstallmentTaxonomyTags getBusinessDocumentSubjectMainTitleMainDescriptionRelatedDocumentsListDescription businessDocument', businessDocument) */
  /* console.log('getBusinessDocumentUpdatedInstallmentTaxonomyTags getBusinessDocumentSubjectMainTitleMainDescriptionRelatedDocumentsListDescription', desc) */

  return desc
}

/**
 * Returns the description of the related documents
 * @param sortedRelatedDocuments
 * @param hasSpecificInstallment
 * @param businessDocumentId
 * @param lang
 */
function buildRelatedDocumentsDescriptionList(sortedRelatedDocuments: BusinessDocumentRelation[], hasSpecificInstallment: boolean, businessDocumentId: string, lang: string): string[] {
  const result: string[] = []
  for (let relatedDocument of sortedRelatedDocuments) {
    const relationDescription: string = getDescriptionForRelatedDocument(
      relatedDocument,
      hasSpecificInstallment,
      businessDocumentId,
      lang,
    )
    result.push(relationDescription)
  }

  return result
}

/**
 * Returns the description of the related document
 * @param relatedDocument
 * @param hasSpecificInstallment
 * @param businessDocumentId
 * @param lang
 */
function getDescriptionForRelatedDocument(relatedDocument: BusinessDocumentRelation, hasSpecificInstallment: boolean, businessDocumentId: string, lang: string): string {
  return getBusinessRelationKind(
    relatedDocument,
    hasSpecificInstallment,
    businessDocumentId,
    lang,
  )
}

/**
 * Get the relations history between business documents
 * @param businessDocumentRelation
 * @param hasSpecificInstallment
 * @param currentBusinessDocumentId
 * @param lang
 */
export function getBusinessRelationKind(businessDocumentRelation: BusinessDocumentRelation, hasSpecificInstallment: boolean, currentBusinessDocumentId: string, lang: string): string {

  const showedFromNumber: string = getShowedNumber(
    businessDocumentRelation.fromBusinessDocumentId,
    businessDocumentRelation.fromBusinessDocumentNumber,
    currentBusinessDocumentId,
    businessDocumentRelation.fromBusinessDocumentKind,
    lang,
  )

  const showedToNumber: string = getShowedNumber(
    businessDocumentRelation.toBusinessDocumentId,
    businessDocumentRelation.toBusinessDocumentNumber,
    currentBusinessDocumentId,
    businessDocumentRelation.toBusinessDocumentKind,
    lang,
  )

  const replacements: any = {
    'fromNumber': showedFromNumber,
    'toNumber': showedToNumber,
    'fromDocumentKindName': getBusinessDocumentKindDesignation(businessDocumentRelation.fromBusinessDocumentKind),
    'toDocumentKindName': getBusinessDocumentKindDesignation(businessDocumentRelation.toBusinessDocumentKind)
  }

  return getRelationKindString(businessDocumentRelation.relationKind, businessDocumentRelation.fromBusinessDocumentKind, businessDocumentRelation.toBusinessDocumentKind, businessDocumentRelation.fromBusinessDocumentTaxonomyTags, businessDocumentRelation.toBusinessDocumentTaxonomyTags, replacements, hasSpecificInstallment)
}

/**
 * Get the showed number
 * @param businessDocumentId
 * @param businessDocumentNumber
 * @param currentBusinessDocumentId
 * @param businessDocumentKind
 * @param lang
 */
export function getShowedNumber(businessDocumentId: string, businessDocumentNumber: string, currentBusinessDocumentId: string, businessDocumentKind: BusinessDocumentKind, lang: string): string {
  if (businessDocumentId === currentBusinessDocumentId && businessDocumentNumber === '') {
    return getNumberingPreview(businessDocumentKind, lang)
  }

  return businessDocumentNumber
}

/**
 * Get the numbering preview
 * @param businessDocumentKind
 * @param lang
 */
function getNumberingPreview(businessDocumentKind: BusinessDocumentKind, lang: string): string {
  const numberingSchemesByBusinessDocument: Map<BusinessDocumentKind, NumberingScheme> = get(VoxyPreferencesStore)?.numberingSchemesByBusinessDocument
  const numberingSchemeSettings: NumberingSchemeSettings = numberingSchemesByBusinessDocument[businessDocumentKind]?.currentNumberingScheme
  const documentNumberTemplate: string = replaceDefaultFirstLetterOfStringFromServerWithTranslatedOne(numberingSchemeSettings.documentNumberTemplate, lang)
  const sequenceNumberOfDigits: number = numberingSchemeSettings.sequenceNumberOfDigits

  return deleteDoubleAccoladesAndReplacePlaceholdersWithDateValues(documentNumberTemplate, lang.split('-')[0].toLocaleLowerCase()) + replaceNumberOfDigitsWithXs(sequenceNumberOfDigits)
}

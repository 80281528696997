<script lang="ts">
    import TextInput from '../lib/ui-kit/TextInput.svelte'
    import AlertError from '../lib/ui-kit/Alert/AlertError.svelte'
    import { validatePassword } from '../services/validator'
    import { Alert } from '../stores/alert.store'
    import { t } from '../lib/i18n/i18nextWrapper'

    let currentPassword, newPassword, newPasswordAgain

    let isCurrentPasswordValid, isNewPasswordValid, isNewPasswordIdentical, canSubmit: boolean
    $: isCurrentPasswordValid = !currentPassword || validatePassword(currentPassword)
    $: isNewPasswordValid = !newPassword || validatePassword(newPassword)
    $: isNewPasswordIdentical = !newPasswordAgain || newPassword === newPasswordAgain

    $: canSubmit =
        validatePassword(currentPassword) &&
        validatePassword(newPassword) &&
        newPassword === newPasswordAgain

    async function handleSubmit() {
      try {
        // put your changePassword method here
      } catch (err) {
        /* console.log(t('editPassword.error')) */
        /* console.error(err) */
        Alert.open(AlertError, {
          header: t('editPassword.error'),
          message: err
        })
      }
    }
</script>

<section class="flex-grow  max-w-xl">
    <h1 class="mb-3 text-loblolly font-black uppercase">{t('editPassword.title')}</h1>

    <form
        class="flex flex-col"
        id="profile-form"
        on:submit|preventDefault={handleSubmit}
    >
        <TextInput
            bind:value={currentPassword}
            error={t('editPassword.currentPassword.error')}
            label={t('editPassword.currentPassword.label')}
            showError={!isCurrentPasswordValid}
            type="password"
        />
        <TextInput
            bind:value={newPassword}
            class="my-3"
            error={t('editPassword.newPassword.error')}
            label={t('editPassword.newPassword.label')}
            showError={!isNewPasswordValid}
            type="password"
        />
        <TextInput
            bind:value={newPasswordAgain}
            error={t('editPassword.newPassword.noMatch')}
            label={t('editPassword.newPassword.label')}
            showError={!isNewPasswordIdentical}
            type="password"
        />
    </form>

    <div class="flex justify-between mt-12">
        <button class="btn big-secondary">{t('editPassword.cancel')}</button>
        <button
            class="btn big-primary"
            disabled={!canSubmit}
            form="profile-form"
            type="submit">{t('editPassword.save')}</button
        >
    </div>
</section>

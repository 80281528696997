import { eventsManager } from '$core/events/event-manager'
import { EventType } from '$core/events/event-type'


export default class DatatableInvoiceActionsRenderer {
  private readonly eGui: HTMLElement
  constructor() {
    this.eGui = document.createElement('div')
  }
  public init(params: any) {
    const contextualMenuContainer: HTMLDivElement = document.createElement('div')
    contextualMenuContainer.classList.add('invoices-contextual-menu-container')

    const contextualMenuIcon: HTMLElement = document.createElement('i')
    contextualMenuIcon.classList.add('icon-more-vertical')
    contextualMenuIcon.classList.add('cursor-pointer')
    contextualMenuIcon.classList.add('text-3xl')
    contextualMenuIcon.classList.add('text-black')
    contextualMenuIcon.classList.add('text-bold')
    contextualMenuIcon.classList.add('align-middle')
    contextualMenuIcon.classList.add('static')
    contextualMenuIcon.classList.add('right-0')
    contextualMenuIcon.innerHTML = '&nbsp;'
    contextualMenuIcon.addEventListener('click', (event: Event & { target: HTMLButtonElement }) => {
      // Get the cell position using params provided by AG Grid.
      const cellPosition = params.eGridCell.getBoundingClientRect()

      // Define the position for the contextual menu.
      const menuPosition = {
        x: cellPosition.left + cellPosition.width,
        y: cellPosition.top + window.scrollY
      }


      // Try to find the filter panel element.
      const filterPanelElement: Element | null = document.querySelector('.dunning-filters-panel-class')
      /* console.log('Before adjusting:', menuPosition) */


      if (filterPanelElement) {
        /* console.log(filterPanelElement, filterPanelElement.getBoundingClientRect(), 'filterPanelElement') */
        // Calculate the height of the filters panel.
        /* console.log(filterPanelElement) */
        const filtersPanelHeight: number = filterPanelElement.getBoundingClientRect().height
        // If the height is non-zero, adjust the y position of the menu.
        if (filtersPanelHeight > 0) {
          menuPosition.y -= filtersPanelHeight
        }
      }

      /* console.log('After adjusting:', menuPosition) */
      eventsManager.emit(EventType.INVOICES_CONTEXTUAL_MENU_CLICKED, {
        invoice: params.data,
        hideRelaunch: event.target.closest('.ag-row'),
        xPosition: menuPosition.x,
        yPosition: menuPosition.y - 450
      }, 'DatatableInvoiceActionsRenderer')
    })

    contextualMenuContainer.appendChild(contextualMenuIcon)
    this.eGui.appendChild(contextualMenuContainer)
  }
  public getGui(): HTMLElement {
    return this.eGui
  }
  public destroy(): void {}
}

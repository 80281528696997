<script lang="ts">
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
    import { get } from 'svelte/store'
    import type Invoice from '../../../models/invoice'
    import { DunningInvoicesStore } from '../../../stores/dunning-invoices.store'
    import { feedbackService } from '../../../../core-app/services/feedback.service'
    import { Feedback } from '../../../../core-app/models/feedback'
    import { dunningInvoicesService } from '../../../services/dunning-invoices.service'
    import { DunningViewListOption } from '../../../enums/dunning-view-list-options.js'
    import { InvoiceStatus } from '../../../enums/invoice-status'
    import { dunningListViewChoice } from '../../../stores/dunning-invoices.store'

    export let selectedInvoices: Invoice[]
    export let isTracked: boolean = false

    /** Local declarations */
    let pauseOrResumeButtonDisabled: boolean
    let aNonTrackableInvoiceIsSelected = false

    /** Reactive declarations */
    $: isTracked =
            !selectedInvoices?.length ||
            selectedInvoices?.some((invoice) => !invoice.isTracked)
    $: pauseOrResumeButtonDisabled = (selectedInvoices?.length < 1)
    $: aNonTrackableInvoiceIsSelected = !!selectedInvoices?.find((anInvoice: Invoice) => !anInvoice.completed || anInvoice.status === InvoiceStatus.PAID)

    /** Updates the Invoices Shown in AG GRID programmatically
     *  @param newInvoices
     * */
    function updateInvoicesStoreVariableWithPauseOrResumeInvoiceInAgGrid(newInvoices: Invoice[]) {
      dunningInvoicesService.setInvoicesStoreToFetchedInvoiceListOnlyWheneverMostUpToDate(newInvoices)
    }

    /**
     * Switches the tracking status of the selected invoices
     * @param selectedInvoices
     */
    function switchTrackOrPauseStatus(selectedInvoices: Invoice[]) {
      if (!selectedInvoices || !selectedInvoices.length) return

      const newInvoices: Invoice[] = get(DunningInvoicesStore).map((invoice: Invoice) => {
        const isInvoiceSelected = !!selectedInvoices.find(aSelectedInvoice => (aSelectedInvoice.dataId === invoice.dataId))
        
        return isInvoiceSelected && !aNonTrackableInvoiceIsSelected ? {
          ...invoice,
          isTracked: isTracked
        } : invoice
      })

      updateInvoicesStoreVariableWithPauseOrResumeInvoiceInAgGrid(newInvoices)

      isTracked ? dunningListViewChoice.set(DunningViewListOption.PAUSED) : dunningListViewChoice.set(DunningViewListOption.TRACKING)
      feedbackService.displayFeedback(<Feedback> {
        feedbackMessage: !isTracked ?
          '<div class="flex items-center">' +
                    '<svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                    '<path d="M18.25 15.0314C17.7575 15.1436 17.2459 15.2027 16.7209 15.2027C12.8082 15.2027 9.63607 11.9185 9.63607 7.86709C9.63607 6.75253 9.87614 5.69603 10.3057 4.75C7.12795 5.47387 4.75 8.40659 4.75 11.9143C4.75 15.9657 7.9221 19.25 11.8348 19.25C14.6711 19.25 17.1182 17.5242 18.25 15.0314Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
                    '</svg>\n' +
                    '         <p>' + t('dunning.feedback.trackingIsPaused', { count: selectedInvoices.length }) + '</p></div>' :
          '<div class="flex items-center">' +
                    '<svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M18.2499 14C18.2499 18 15.5 19.25 11.9999 19.25C8 19.25 5.75 16.4 5.75 14C5.75 11.6 7 9.41667 8 8.75C8 11.55 10.6666 13.3333 11.9999 13.25C9.59994 9.65 11.6666 5.66667 12.9999 4.75C12.9999 9.25 18.2499 10 18.2499 14Z" stroke="#FFE6A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg> \n' +
                    '         <p>' + t('dunning.feedback.trackingIsResumed', { count: selectedInvoices.length }) + '</p></div>'
        ,
        feedbackLevel: 'Success'
      })
    }

</script>

<button class="btn"
        class:action-positive={isTracked}
        class:action-secondary={!isTracked}
        disabled={pauseOrResumeButtonDisabled}
        on:click={() => { switchTrackOrPauseStatus(selectedInvoices) }}
>
    {#if !isTracked || pauseOrResumeButtonDisabled}
        <i>
            <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.25 6.75V17.25"></path>
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.75 6.75V17.25"></path>
            </svg>

        </i>
        {t('dunning.controls.pauseTracking')}
    {:else}
        <i>
            <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                      d="M18.25 12L5.75 5.75V18.25L18.25 12Z"></path>
            </svg>
        </i>
        {t('dunning.controls.resumeTracking', { count: selectedInvoices.length })}
    {/if}
</button>

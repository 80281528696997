import { get, writable } from 'svelte/store'
import type { Writable } from 'svelte/store'
import { encrypt256Hash } from '../../core-app/util/encryption'
import initializationStore from '../../core-app/stores/initialization.store'
import type { InvoicesHistoryItem } from '../models/invoices-history'

/* console.log('invoices-history.store.ts') */

export const isFetching: Writable<boolean> = writable(true)

export let InvoicesHistoryStore: Writable<InvoicesHistoryItem[]> = writable()

export const initializeInvoicesHistoryStore = (data) => {

  if (!get(initializationStore).invoicesHistoryStoreInitialized) {

    initializationStore.update(store => {
      store.invoicesHistoryStoreInitialized = true

      return store
    })

    if (!Array.isArray(data)) {
      data = <InvoicesHistoryItem[]>[]
    }

    InvoicesHistoryStore.set(data)
    isFetching.set(false)

    let isInvoicesHistoryStoreSubscriptionDefined: boolean = false
    // NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
    InvoicesHistoryStore.subscribe(() => {
      if (!isInvoicesHistoryStoreSubscriptionDefined) {
        /* console.log('InvoicesHistoryStore subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently') */
        isInvoicesHistoryStoreSubscriptionDefined = true
      }
    })

    /* console.log('%c InvoicesHistoryStore initialized.', 'color: #8A99AC') */
  } else {
    /* console.log('%c InvoicesHistoryStore not initialized.', 'color: #FF5555') */
  }
}

import datepicker from 'js-datepicker'
import { get } from 'svelte/store'
import { Langs } from '../../enums/lang'
import { ProfileStore } from '../../stores/profile.store'
import { t } from '../i18n/i18nextWrapper'
import type { ICellRendererParams } from 'ag-grid-community'

export default class DateEditor {
  value: string
  div: HTMLDivElement
  input: HTMLInputElement
  button: HTMLButtonElement
  init(params: ICellRendererParams) {
    this.value = params.value

    // input
    this.input = document.createElement('input')
    this.input.className = 'datatable-date-picker'
    this.input.type = 'text'
    this.input.value = new Intl.DateTimeFormat(t('locales'), {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }).format(new Date(this.value))

    document.addEventListener('input', (event) => {
      const lang = get(ProfileStore).lang

      let newDate: Date

      if (lang === Langs.fr_FR) {
        const splitDate = this.input.value.split('/')
        if (splitDate.length === 3)
          newDate = new Date(`${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`)
      } else newDate = new Date(this.input.value)

      if (newDate && newDate.toString() !== 'Invalid Date')
        this.value = newDate.toISOString()
    })

    // calendar btn
    this.button = document.createElement('button')
    this.button.classList.add('icon-calendar', 'text-2xl')
    this.button.onclick = () => {
      datepicker('.datatable-date-picker', {
        formatter: (input, date, instance) => {
          input.value = new Intl.DateTimeFormat(t('locales'), {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
          }).format(new Date(date))
          this.value = date.toISOString()
        },
        startDay: 1, // Calendar week starts on a Monday.
        customDays: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        alwaysShow: true,
        dateSelected: new Date(this.value),
        startDate: new Date(this.value),
        showAllDates: true,
        disableYearOverlay: true
      })
    }

    // root div
    this.div = document.createElement('div')
    this.div.classList.add(
      'flex',
      'items-center',
      'h-full',
      'bg-white',
      'rounded',
      'px-4',
    )
    this.div.append(this.input)
    this.div.append(this.button)
    setTimeout(() => {
      this.button.click()
    }, 100)
  }
  getGui() {
    return this.div
  }
  getValue() {
    return this.value
  }
  isCancelAfterEnd() {
    // our editor will reject any value if:
    return false
  }
  isPopup() {
    return true
  }
  // after this component has been created and inserted into the grid
  afterGuiAttached() {
    this.input.focus()
  }
}

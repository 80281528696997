<script lang="ts">
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper.js'
    import { onMount } from 'svelte'
    import { BusinessDocument, CoreDocument } from '../../../models/business-document'
    import RelatedBusinessDocumentItem from './RelatedBusinessDocumentItem.svelte'
    import Loader from '../../../../core-app/lib/ui-kit/Loader.svelte'
    import { updateNewNavigationHistoryItem } from '../../../../core-app/stores/navigationHistory.store'
    import { get } from 'svelte/store'
    import { BusinessDocumentsStore } from '../../../stores/business-documents.store'
    import {
      getAllRelatedBusinessDocumentsDetails
    } from '../../../services/business-document-relation/get-all-business-documents-relations.service'

    /** Exported Var **/
    export let businessDocument: BusinessDocument

    /** Local Var **/
    let isContentLoading: boolean = false
    let relatedBusinessDocuments: CoreDocument[] = <CoreDocument[]>[]

    onMount(() => {
      updateNewNavigationHistoryItem('BusinessDocumentRelatedDocumentsPanel.svelte')
      isContentLoading = true

      if (businessDocument.relatedBusinessDocuments && businessDocument.relatedBusinessDocuments.length > 0) {
        getAllRelatedBusinessDocumentsDetails(businessDocument, get(BusinessDocumentsStore))
          .then((relatedCoreDocumentsList: CoreDocument[]) => {
            relatedBusinessDocuments = relatedCoreDocumentsList
            isContentLoading = false
          })
          .catch((e) => {
            console.error(e)
            isContentLoading = false
          })
      } else {
        isContentLoading = false
      }
    })
</script>

<div class="divide-y divide-gray-200 border-t border-whisper sm:px-6">
    <div class="space-y-6 py-7">
        <div class="px-12 sm:px-6">
            <div class="col-span-2">
                {#if isContentLoading}
                    <div class="grid h-24 place-items-center w-full z-[100]">
                        <Loader/>
                    </div>
                {:else}
                    <div class="divide-y divide-slate-400/20 bg-whisper border border-athensGray rounded text-[0.8125rem] leading-5 text-slate-900">
                        {#each relatedBusinessDocuments as bdList}
                            <RelatedBusinessDocumentItem {bdList} />
                        {:else}
                            <div class="flex items-center p-4">
                                <div class="ml-4 flex-auto">
                                    <div class="font-medium">
                                        {t('invoices.createInvoice.noLinkedDocumentFound')}
                                    </div>
                                </div>
                            </div>
                        {/each}
                    </div>
                {/if}
            </div>
        </div>
    </div>
</div>

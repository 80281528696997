<script lang="ts">
  import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
  import { navigate, Route, Router } from 'svelte-routing'
  import CompanyEdit from '../../crm-app/components/ui-kit/CompanyEdit.svelte'
  import ProfileEdit from './ProfileEdit.svelte'
  import BankView from '../../pay-app/pages/BankView.svelte'
  import Password from './EditPassword.svelte'
  import EditVoxySettings from '../../voxy-app/pages/EditVoxySettings.svelte'
  import { get } from 'svelte/store'
  import FileInput from '../lib/ui-kit/FileInput.svelte'
  import FileInputWithCropper from '../lib/ui-kit/FileInputWithCropper.svelte'
  import WorkspaceCompanyEditPage from '../../crm-app/pages/WorkspaceCompanyEditPage.svelte'
  import BankConnectionModal from '../../pay-app/pages/BankConnectionModal.svelte'
  import BankAccountSelectModal from '../../pay-app/pages/BankAccountSelectModal.svelte'
  import Exports from '../../voxy-app/pages/Exports.svelte'


  function workspaceCompanyUpdated() {
    /* console.log('*!!! *!!! saving the new workspace company here') */
  }

  const mySpecificValidator = (fileMIMEContentType: string, fileName: string, fileSizeBytes: number, fileLastModifiedUnixMilliseconds: number): boolean =>
  /* console.log('*!!! my specific validator') */
  /* console.log('*!!! my specific validator fileMIMEContentType', fileMIMEContentType) */
  /* console.log('*!!! my specific validator fileName', fileName) */
  /* console.log('*!!! my specific validator fileSizeBytes', fileSizeBytes) */
  /* console.log('*!!! my specific validator fileLastModifiedUnixMilliseconds', fileLastModifiedUnixMilliseconds) */

    true

</script>

<main class="pb-16">
    <Router>
        <Route path="profile/edit">
            <ProfileEdit/>
        </Route>
        <Route path="profile/password">
            <Password/>
        </Route>
        <Route path="invoicing-app/edit">
            <EditVoxySettings/>
        </Route>
        <Route path="company/edit">
            <WorkspaceCompanyEditPage/>
            <!-- <CompanyEdit/>-->
        </Route>
        <!-- v1: let's try to have a reactive store variable passed to edit component -->
        <Route path="company/new-edit">
            <CompanyEdit localCompanyInput={get(WorkspaceStore).company}
                         on:saveCompanyChanges={workspaceCompanyUpdated}/>
        </Route>

        <Route path="company/image-input">
            <FileInput
                    acceptedExtensions=".jpg, .jpeg, .png"
                    cancelChangesMessageTEntry="companyEdit.resetLogo"
                    chooseANewFileMessageTEntry="companyEdit.selectNewLogo"
                    existingFileLastModifiedUnixMilliseconds={1672502195 * 1000}
                    existingFileName="amazon-logo-editorial-vector-illustration-market-136495269.jpg"
                    existingFileSizeBytes={14294}
                    existingFileURL="https://thumbs.dreamstime.com/b/amazon-logo-editorial-vector-illustration-market-136495269.jpg"
                    fileLimitationsMessageTEntry="companyEdit.uploadLimitations"
                    fileUploadHelpMessageTEntry="companyEdit.uploadHelp"
                    languageAndCountryCode="fr-FR"
                    showAdditionalUploadHelpAlwaysMessage={false}
                    showAdditionalUploadHelpMessage={true}
                    showCancelChangesButton={true}
                    showFileMetadata={false}
                    showImage={true}
                    showNewUploadButton={true}
                    showUploadHelpMessage={true}
                    specificFileValidator={mySpecificValidator}
                    timeZoneIANACode="Europe/Paris"
            />
        </Route>
        <Route path="company/image-crop">
            <FileInputWithCropper
                    acceptedExtensions=".jpg, .jpeg, .png"
                    cancelChangesMessageTEntry="companyEdit.resetLogo"
                    cancelReFramingMessageTEntry="companyEdit.cancelReFraming"
                    chooseANewFileMessageTEntry="companyEdit.selectNewLogo"
                    confirmReFramingMessageTEntry="companyEdit.confirmReFraming"
                    existingFileLastModifiedUnixMilliseconds={1672502195 * 1000}
                    existingFileName="amazon-logo-editorial-vector-illustration-market-136495269.jpg"
                    existingFileSizeBytes={14294}
                    existingFileURL="https://thumbs.dreamstime.com/b/amazon-logo-editorial-vector-illustration-market-136495269.jpg"
                    fileLimitationsMessageTEntry="companyEdit.uploadLimitations"
                    fileUploadHelpMessageTEntry="companyEdit.uploadHelp"
                    languageAndCountryCode="fr-FR"
                    reFramePictureMessageTEntry="companyEdit.reFramePicture"
                    resizePictureMessageTEntry="companyEdit.resizePicture"
                    showAdditionalUploadHelpAlwaysMessage={false}
                    showAdditionalUploadHelpMessage={true}
                    showCancelChangesButton={true}
                    showFileMetadata={false}
                    showImage={true}
                    showNewUploadButton={true}
                    showUploadHelpMessage={true}
                    specificFileValidator={mySpecificValidator}
                    timeZoneIANACode="Europe/Paris"
            />
        </Route>
        <!--{#if isUserWsOwner()}-->
        <Route path="bank/view">
            <BankView/>
        </Route>
        <Route path="view">
            <BankView/>
        </Route>
        <Route path="bank/connect-new">
            <BankConnectionModal on:close={() => navigate('view')}/>
        </Route>
        <Route path="connect-new">
            <BankConnectionModal on:close={() => navigate('view')}/>
        </Route>
        <Route path="bank/select-account">
            <BankAccountSelectModal on:close={() => navigate('view')}/>
        </Route>
        <Route path="select-account">
            <BankAccountSelectModal on:close={() => navigate('view')}/>
        </Route>
        <Route path="exports">
            <Exports/>
        </Route>
        <!--{/if}-->
    </Router>
</main>


<style lang="postcss">
    @media (max-width: 767px) {
        main {
            margin-top: -4em;
        }
    }
</style>

<script lang="ts">
    import { t } from '../i18n/i18nextWrapper.js'
    import Tooltip from '../ui-kit/Tooltip/Tooltip.svelte'
    import DSOProgressBar from './dso/DSOProgressBar.svelte'
    import {
      calculateSimpleDSO,
      type collateralCalculusForDashboard
    } from '../../services/dashboard-update-calculus'
    import Invoice from '../../../dundy-app/models/invoice'
    import {
      getOutstandingInvoicesExcludingUntrackedAndDeleted
    } from '$dundy/services/invoices.pure-functions'
    import { DunningInvoicesStore } from '$dundy/stores/dunning-invoices.store'
    import { ComputedDataInvoicesStore } from '$dundy/stores/computed-data-invoice.store'

    export let computedCalculusForDashboard: collateralCalculusForDashboard

    /** local declarations */
    let simpleDSOOver30Days: number
    let isValidDSO: boolean
    let averageAgeOfOutstandingInvoices: number

    /**
     * Calculate average age of outstanding invoices
     * For each outstanding invoice, we try to find its corresponding computed data in ComputedDataInvoicesStore using the invoiceNumber.
     * We only consider positive values of daysOverdueNormalized to accumulate the overdue days and increment the count of overdue invoices.
     * We then accumulate the daysOverdueNormalized for each matched computed data.
     * Finally, we calculate the average overdue days by dividing the total overdue days by the number of outstanding invoices.
     * @param outstandingInvoices
     */
    function calculateAverageAgeOfOutstandingInvoices(outstandingInvoices: Invoice[]): number {
      if (outstandingInvoices.length === 0) return 0

      let totalOverdueDays = 0
      let overdueInvoiceCount = 0

      outstandingInvoices.forEach(invoice => {
        const computedData = $ComputedDataInvoicesStore.find(data => data.invoiceNumber === invoice.invoiceNumber)

        if (computedData && computedData.daysOverdueNormalized > 0) {
          totalOverdueDays += computedData.daysOverdueNormalized
          overdueInvoiceCount++
        }
      })

      // If no invoices are overdue, return 0
      if (overdueInvoiceCount === 0) return 0

      return totalOverdueDays / overdueInvoiceCount
    }

    /**
     * UI Function Compute DSO
     */
    function computeDSO(averageAgeOfOutstandingInvoices: number) {
      if (
        computedCalculusForDashboard?.overdueInvoicesAmount !== 0 &&
                computedCalculusForDashboard?.outstandingInvoicesAmount !== 0
      ) {
        isValidDSO = true
        simpleDSOOver30Days = calculateSimpleDSO(
          computedCalculusForDashboard.overdueInvoicesAmount,
          computedCalculusForDashboard.outstandingInvoicesAmount,
          averageAgeOfOutstandingInvoices,
        )
      } else {
        isValidDSO = false
        /* console.error('Invalid DSO') */
      }
    }

    /**
     * Reactive statements
     */
    $: if ($DunningInvoicesStore) {
      averageAgeOfOutstandingInvoices = calculateAverageAgeOfOutstandingInvoices(getOutstandingInvoicesExcludingUntrackedAndDeleted($DunningInvoicesStore))
    }
    $: if (computedCalculusForDashboard) {
      computeDSO(averageAgeOfOutstandingInvoices)
    }

</script>

<div class="text-baliHai text-sm">
    <div class="flex items-center justify-between">
        <div class="flex items-center">
            <h3 class="mr-2">DSO</h3>
            <Tooltip width="300px">
                <div slot="activator">
                    <svg width="16" height="16" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="2" d="M12 13V15"/>
                        <circle cx="12" cy="9" r="1" fill="currentColor"/>
                        <circle cx="12" cy="12" r="7.25" stroke="currentColor" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="1.5"/>
                    </svg>
                </div>
                <p>{t('todo.stats.dsoTooltip')}</p>
            </Tooltip>
        </div>
        {#if isValidDSO}
            <p class="text-white">{simpleDSOOver30Days} {t('todo.stats.dsoUnit', { count: simpleDSOOver30Days })}</p>
        {:else}
            <p class="text-zinc-400 text-xs">{t('todo.stats.dsoInvalid')}</p>
        {/if}
    </div>

    <DSOProgressBar
            isValidDSO={isValidDSO}
            dsoValue={simpleDSOOver30Days}
            minDsoValue={0}
            maxDsoValue={180}/>
</div>

<script lang="ts">
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
    import { createEventDispatcher } from 'svelte'


    /** Local declarations **/
    let isPartialBusinessDocumentsWindowLoading: boolean = false
    const dispatch = createEventDispatcher()
    export let showDissociateButton: boolean = false

    /**
     * This function is used to activate the invoice numbering window.
     */
    function openDealsAvailableForInvoicingSelectionWindow() {
      /* console.log('opening DealsAvailableForInvoicingSelectionWindow') */
      isPartialBusinessDocumentsWindowLoading = true
      setTimeout(() => {
        dispatch('openDealsAvailableForInvoicingSelectionWindow')
      }, 200)
    }

    function dissociateLinkedDeal() {
      /* console.log('dissociating deal dissociateLinkedDeal') */
      isPartialBusinessDocumentsWindowLoading = false
      setTimeout(() => {
        dispatch('dissociateLinkedDeal')
      }, 200)
    }

</script>
<div class="aside-help w-full flex items-center justify-between mx-auto my-4">
    <svg class="h-20 w-20 mr-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.75 15.75L8.25 19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round"></path>
        <path d="M8.25 15.75L4.75 19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round"></path>
        <path d="M11.75 19.25H15.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round"></path>
        <path d="M8.75 8.75H15.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round"></path>
        <path d="M9.75 11.75H14.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round"></path>
        <path d="M19.25 19.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V12.25"
              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>


    <p class="text-s">{@html t('invoices.createInvoice.generateIntermediateInvoices.copy')}</p>
    {#if showDissociateButton}
        <div class="w-64 flex justify-end">
            <button type="button"
                    on:click={() => {
                      dissociateLinkedDeal()
                    }}
                    class="inline-flex h-8 items-center justify-center rounded border border-gray-300 bg-white px-2.5 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-dundyOrange focus:ring-offset-2">
                {#if !isPartialBusinessDocumentsWindowLoading}
                    {t('invoices.createInvoice.generateIntermediateInvoices.resetDissociateBusinessDocument')}
                {:else}
                    <svg class="animate-spin h-5 w-5 text-dundyOrange"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                {/if}
            </button>
        </div>
    {/if}
    <div class="w-64 flex justify-end">
        <button type="button"
                on:click={() => {
                  isPartialBusinessDocumentsWindowLoading = !isPartialBusinessDocumentsWindowLoading
                  openDealsAvailableForInvoicingSelectionWindow()
                }}
                class="inline-flex h-8 items-center justify-center rounded border border-gray-300 bg-white px-2.5 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-dundyOrange focus:ring-offset-2">
            {#if !isPartialBusinessDocumentsWindowLoading}
                {t('invoices.createInvoice.generateIntermediateInvoices.accessButtonLabel')}
            {:else}
                <svg class="animate-spin h-5 w-5 text-dundyOrange"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                            stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            {/if}
        </button>
    </div>

</div>
<style lang="postcss">
    .aside-help {
        @apply bg-creamBrulee border border-warmGray rounded-md px-4 py-3;
        border-color: hsl(47, 65%, 84%);
        background-color: hsl(47, 87%, 94%);
    }
</style>

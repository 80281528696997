
export interface Template {
  id?: number,
  name:string,
  from?: string,
  to?: string, 
  cc?: string,
  cci?: string,
  escalation: string[],
  media: string[],
  subject: string,
  body: string,
}

export class Template implements Template {
  id?: number
  name:string
  from?: string
  to?: string
  cc?: string
  cci?: string
  phone?:string
  escalation: string[]
  media: string[]
  subject: string
  body: string
  constructor( name:string, escalation: string[], media: string[], subject: string, body: string, from?: string, to?: string, cc?: string, cci?: string, phone?:string, id?: number) {
    if (id) this.id = id
    this.name = name
    if (from) this.from = from
    if (to) this.to = to
    if (cc) this.cc = cc
    if (cci) this.cci = cci
    if (phone) this.phone = phone
    this.media = media
    this.escalation = escalation
    this.subject = subject
    this.body = body
  }

}
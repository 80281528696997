<script lang='ts'>
  import {navigate} from 'svelte-routing'
  import {DunningInvoicesStore} from '$dundy/stores/dunning-invoices.store'
  import {ComputedDataInvoicesStore} from '$dundy/stores/computed-data-invoice.store'
  import {onDestroy, onMount, tick} from 'svelte'
  import {t} from '../lib/i18n/i18nextWrapper'
  import Loader from '../lib/ui-kit/Loader.svelte'
  import {CustomersStore} from '$crm/stores/customers.store'
  import {ContactsStore} from '$crm/stores/contacts.store'
  import {WorkspaceStore} from '$crm/stores/workspace.store'
  import type {Workspace} from '$crm/models/workspace'
  import {navigateToMissingOnboardingStepIfNeeded} from '../services/onboarding.service'
  import {derived, type Readable, type Unsubscriber, writable} from 'svelte/store'
  import {
    type CollateralCalculusForDashboard,
    updateCollateralCalculusOnInvoicesUpdate
  } from '../services/dashboard-update-calculus'
  import DashboardHUD from '../lib/dashboard/DashboardHUD.svelte'
  import MainDebtors from '../lib/dashboard/MainDebtors.svelte'
  import ListOfDealsWithBalanceDocumentsToGenerate
    from '../lib/dashboard/partial-invoices/ListOfDealsWithBalanceDocumentsToGenerate.svelte'
  import {SimpleDocumentsStore} from '$voxy/stores/business-documents.store'
  import {DealWithRemainingBalanceToInvoice} from '$voxy/models/deal-with-remaining-balance-to-invoice.model'
  import mixpanel from 'mixpanel-browser'
  import Tasks from "$core/lib/dashboard/Tasks.svelte"
  import initializationStore from "$core/stores/initialization.store"
  import {deriveMostUsedCurrencyFromStores} from "$core/services/main-currency-identification-from-stores"
  import {
    generateDealsWithRemainingBalanceToInvoice,
    listOfBusinessDocumentsWithDealHavingARemainingAmountToInvoice
  } from "$voxy/services/deal-balance-calculation/incomplete-deals-list.service"
  import {BusinessDocumentKind} from "$voxy/enums/business-document-kind"

  /** Variables */
  let computedCalculusForDashboard: CollateralCalculusForDashboard
  let unsubscribeWorkspaceStore: Unsubscriber
  let listOfDealsWithBalanceInvoicesToFinalize: DealWithRemainingBalanceToInvoice[] = []

  /** Constants */
  const isBankConfigLoaded = writable<boolean>(false)
  const isLoading = derived(
    initializationStore,
    () => {
      const {
        todosStoreInitialized,
        customersStoreInitialized,
        contactsStoreInitialized,
        invoicesStoreInitialized,
        businessDocumentsStoreInitialized
      } = $initializationStore

      return !todosStoreInitialized
        && !customersStoreInitialized
        && !contactsStoreInitialized
        && !invoicesStoreInitialized
        && !businessDocumentsStoreInitialized
    },
    false
  )

  const invoicesCurrency: Readable<string> = deriveMostUsedCurrencyFromStores(DunningInvoicesStore, SimpleDocumentsStore)

  /**
   * Reactive statements
   */
  $: computedCalculusForDashboard = updateCollateralCalculusOnInvoicesUpdate($DunningInvoicesStore, $ComputedDataInvoicesStore, $CustomersStore, t('locales'), $invoicesCurrency)
  $: if ($SimpleDocumentsStore && $CustomersStore && $ContactsStore) {
    const listOfBusinessDocumentsWithDealsToFinalize = listOfBusinessDocumentsWithDealHavingARemainingAmountToInvoice($SimpleDocumentsStore, BusinessDocumentKind.INVOICE)
    listOfDealsWithBalanceInvoicesToFinalize = generateDealsWithRemainingBalanceToInvoice(listOfBusinessDocumentsWithDealsToFinalize, $WorkspaceStore, $CustomersStore, $ContactsStore)
  }

  onMount(() => {
    mixpanel.track('PA10 Today Page', {
      'Description': 'Opened Today.svelte'
    })

    computedCalculusForDashboard = updateCollateralCalculusOnInvoicesUpdate($DunningInvoicesStore, $ComputedDataInvoicesStore, $CustomersStore, t('locales'), $invoicesCurrency)

    if (!!unsubscribeWorkspaceStore) {
      unsubscribeWorkspaceStore()
    }

    unsubscribeWorkspaceStore = WorkspaceStore.subscribe((newWorkspaceStore: Workspace) => {
      tick()
      navigateToMissingOnboardingStepIfNeeded();
      const isLoaded = !!newWorkspaceStore?.bankConfig?.selectedAccountName
        && !!newWorkspaceStore?.bankConfig?.selectedAccountBankIBAN
        && !!newWorkspaceStore?.bankConfig?.selectedAccountBankBIC

      isBankConfigLoaded.set(isLoaded)
    })
  })

  onDestroy(() => {
    if (!!unsubscribeWorkspaceStore) {
      unsubscribeWorkspaceStore()
    }
  })

</script>

<svelte:head>
  <title>{t('topMenu.nav.dashboard')} - Dundy</title>
  <style lang='postcss'>
    body {
      @apply bg-white;
    }
  </style>
</svelte:head>

<DashboardHUD
  {computedCalculusForDashboard}
  currency={$invoicesCurrency}
  isBankConfigLoaded={$isBankConfigLoaded}
/>

<div class='w-full flex flex-col lg:flex-row py-4 xl:py-6 space-y-4 lg:space-y-0  lg:space-x-4 items-stretch'>
  {#if $isLoading}
    <div class='w-full'>
      <div class='grid h-96 place-items-center w-full z-[100]'>
        <Loader i18nKey='dashboard.todoModule.loading'/>
      </div>
    </div>
  {:else}
    <div class="w-full lg:w-5/12">
      <Tasks isBankConfigLoaded={$isBankConfigLoaded}
      />
    </div>
    <div class="w-full lg:w-4/12">
      <ListOfDealsWithBalanceDocumentsToGenerate
        {listOfDealsWithBalanceInvoicesToFinalize}
      />
    </div>
    <div class='w-full lg:w-3/12'>
      <MainDebtors
        overdueClients={computedCalculusForDashboard.overdueClients}
        on:selectRow={e => navigate(`/clients/${e.detail.companyId}/overview`)}
      />
    </div>
  {/if}
</div>
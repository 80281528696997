<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import { navigate } from 'svelte-routing'
    import type { Customer } from '../../../crm-app/models/customer'

    export let customer: Customer
</script>

<div class="mt-2 flex items-center justify-between align-middle space-x-2 my-2">
    <div class="shadow-lg rounded w-full p-4 bg-barleyWhite border border-goldSand relative overflow-hidden">
        <div class="flex flex-col justify-between mt-2 mb-5">
            <p class="text-black font-bold text-base">
                {t('todoAction.missingContactNotice.header')}
            </p>
            <p class="text-black text-s">
                {t('todoAction.missingContactNotice.copy')}
            </p>
        </div>
        <a href={`/clients/${customer?.company?.companyId}/edit/contacts`}
           on:click={(e) => {e.preventDefault(); navigate(`/clients/${customer?.company?.companyId}/edit/contacts`)}}
           class="btn big-primary cursor-pointer">
            {t('todoAction.missingContactNotice.button')}
        </a>

    </div>
</div>
import { BusinessDocumentKind } from '../../voxy-app/enums/business-document-kind'
import type Invoice from '../models/invoice'
import { t } from '../../core-app/lib/i18n/i18nextWrapper'

/**
 * Get Invoice Excerpt Main Title
 * @param invoiceExcerpt
 */
export const getInvoiceExcerptMainTitle = (invoiceExcerpt: Invoice): string => {
  switch (invoiceExcerpt.documentKind) {
    case BusinessDocumentKind.INVOICE:
      return t('invoices.businessDocument.mainHeaderTitle.invoicePreviewAndPDF')
    case BusinessDocumentKind.CREDITNOTE:
      return t('invoices.businessDocument.mainHeaderTitle.creditNotePreviewAndPDF')
    case BusinessDocumentKind.QUOTE:
      return t('invoices.businessDocument.mainHeaderTitle.quotePreviewAndPDF')
    case BusinessDocumentKind.PURCHASEORDER:
      return t('invoices.businessDocument.mainHeaderTitle.purchaseOrderPreviewAndPDF')
    default:
      return t('invoices.businessDocument.mainHeaderTitle.documentPreviewAndPDF')
  }
}
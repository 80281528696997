<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import Loader from './Loader.svelte'

  /** Set to `true` to open the modal */
  export let open: boolean = false

  /** Set an id for the top-level element */
  export let id: string = 'ccs-' + Math.random().toString(36)

  /** Obtain a reference to the top-level HTML element */
  export let ref: HTMLElement = null

  /** Obtain a reference to the button element */
  let buttonRef: HTMLElement

  let loading: boolean = false
  /**
   * Set to `true` for the "submit" and "click:button--primary" events
   * to be dispatched when pressing "Enter"
   */
  export let shouldSubmitOnEnter: boolean = true

  /** Specify the primary button text */
  export let primaryButtonText: string = ''

  /** Set to `true` to disable the primary button */
  export let primaryButtonDisabled: boolean = false

  /** Specify the secondary button text */
  export let secondaryButtonText: string = ''

  export let primaryColor: string = 'mojo'

  /**
   * Specify the modal heading
   */
  export let modalHeading: string = undefined

  /**
   * Create a dispatcher for custom events
   */
  const dispatch = createEventDispatcher()

  const dispatchConfirmation = () => {
    loading = true
    dispatch('confirmAction')
  }

  const dispatchCancelAndClose = () => {
    loading = true
    dispatch('cancelAndClose')
  }

  /**
   * Handle keydown events
   * @param e
   */
  function handleKeydown(e: KeyboardEvent) {
    if (open) {
      if (e.key === 'Escape') {
        open = false
      } else if (e.key === 'Tab') {
        // trap focus
        const selectorTabbable = `
        a[href], area[href], input:not([disabled]):not([tabindex='-1']),
        button:not([disabled]):not([tabindex='-1']),select:not([disabled]):not([tabindex='-1']),
        textarea:not([disabled]):not([tabindex='-1']),
        iframe, object, embed, *[tabindex]:not([tabindex='-1']):not([disabled]), *[contenteditable=true]
      `

        const tabbable = Array.from(ref.querySelectorAll(selectorTabbable))

        let index = tabbable.indexOf(document.activeElement)
        if (index === -1 && e.shiftKey) index = 0

        index += tabbable.length + (e.shiftKey ? -1 : 1)
        index %= tabbable.length

        // Using bracket notation to bypass type checking
        if (typeof tabbable[index].focus === 'function') {
          tabbable[index].focus()
        }
        e.preventDefault()
      } else if (shouldSubmitOnEnter && e.key === 'Enter' && !primaryButtonDisabled) {
        dispatch('submit')
        dispatch('click:button--primary')
      }
    }
  }

  $: if (open) {
    if (buttonRef) buttonRef.focus()
    loading = false
  }
</script>

<div
  bind:this={ref}
  data-ut-component="confirmation-modal"
  {id}
  class="fixed ease-in-out {open ? 'opacity-100 block z-[9999999]' : 'opacity-0 hidden z-20'}"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
  {...$$restProps}
>
  <button
    bind:this={buttonRef}
    class="invisible-button"
    tabindex="0"
    on:keydown={(e) => {
      handleKeydown(e)
    }}
  >
  </button>

  <div class="overlay"></div>

  <div class="fixed inset-0 overflow-y-auto">
    <div
      class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 {open
        ? 'opacity-100 translate-y-0 sm:scale-100'
        : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'}"
    >
      <div
        class="relative transform overflow-hidden rounded-lg bg-black px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
      >
        <div class="sm:flex sm:items-start">
          <div
            class={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full  sm:mx-0 sm:h-10 sm:w-10 bg-${primaryColor}`}
          >
            <!-- Heroicon name: outline/exclamation-triangle -->
            <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M4.9522 16.3536L10.2152 5.85658C10.9531 4.38481 13.0539 4.3852 13.7913 5.85723L19.0495 16.3543C19.7156 17.6841 18.7487 19.25 17.2613 19.25H6.74007C5.25234 19.25 4.2854 17.6835 4.9522 16.3536Z"
              ></path>
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10V12"
              ></path>
              <circle cx="12" cy="16" r="1" fill="currentColor"></circle>
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg font-medium leading-6 text-gray-100" id="modal-title">
              <slot name="heading">{modalHeading}</slot>
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-400">
                <slot />
              </p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          {#if loading}
            <Loader></Loader>
          {:else}
            <button
              type="button"
              disabled={primaryButtonDisabled}
              on:click|preventDefault|stopPropagation={(e) => {
                e.preventDefault()
                e.stopPropagation()
                dispatchConfirmation()
              }}
              class={`inline-flex w-full justify-center rounded-md border border-transparent  px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm bg-${primaryColor} hover:bg-${primaryColor} focus:ring-${primaryColor}`}
            >
              {primaryButtonText}
            </button>
            <button
              type="button"
              on:click|preventDefault|stopPropagation={(e) => {
                e.preventDefault()
                e.stopPropagation()
                dispatchCancelAndClose()
              }}
              class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-athensGray focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            >
              {secondaryButtonText}
            </button>
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="postcss">
  .overlay {
    @apply fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity;
  }
</style>

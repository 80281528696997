<script lang="ts">
  import {onMount} from 'svelte'
  import {slide} from 'svelte/transition'
  import {ProfileStore} from "$core/stores/profile.store"
  import auth from "$core/lib/auth0authentication/authService"
  import { t } from '../i18n/i18nextWrapper.js'

  let isOpen = false

  function toggleMenu() {
    isOpen = !isOpen
  }

  onMount(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(event.target as Element).closest('.dropdown-container')) {
        isOpen = false
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  })
</script>

<div class="relative dropdown-container">
  <button type="button" class="-m-1.5 flex items-center p-1.5" id="user-menu-button" aria-expanded={isOpen}
          aria-haspopup="true" on:click={toggleMenu}>
    <span class="sr-only">Open user menu</span>
    <span class="flex flex-wrap content-center justify-center h-8 w-8 rounded-full bg-gray-50 text-baliHai">
      {$ProfileStore?.firstName.substring(0, 1).toUpperCase()}{$ProfileStore?.lastName.substring(0, 1).toUpperCase()}
    </span>
    <span class="hidden lg:flex lg:items-center">
          <span class="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">{$ProfileStore?.firstName} {$ProfileStore?.lastName}</span>
          <svg class="ml-2 text-baliHai" width="8" height="5" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M6.7.24a.75.75 0 1 1 1.1 1.02l-3.25 3.5a.75.75 0 0 1-1.1 0L.2 1.26A.75.75 0 0 1 1.3.24l2.699 2.907L6.7.24Z"/>
          </svg>
        </span>
  </button>

  {#if isOpen}
    <div
      transition:slide={{ duration: 100 }}
      class="absolute right-0 z-10 mt-2.5 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none overflow-hidden"
      role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
      <a href="/settings/profile/edit" class="block w-full py-2 text-left pl-6 text-sm leading-6 text-black hover:bg-whisper" role="menuitem" tabindex="-1"
         id="user-menu-item-0">{t('topMenu.nav.settings.profileSettings')}</a>
      <button on:click={() => auth.logout()} class="block w-full py-2 text-left pl-6 text-sm leading-6 text-black hover:bg-provincialPink hover:text-dundyOrange" role="menuitem" tabindex="-1"
         id="user-menu-item-1">{t('topMenu.userNav.logOut')}</button>
    </div>
  {/if}
</div>
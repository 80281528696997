export class UponMountingOnBankAccountSelectModalStatus {
  kind: string
  comingFrom: string
  userConnectedToBankDuringBBBProcess: boolean
  userCancelledBBBConsent: boolean
  aBBBUserAccountWasCreated: boolean
  rawConditions: any
}

export enum UponMountingOnBankAccountSelectModalKind {
  FROM_BRIDGE_CONNECTION_SUCCESS = "FROM_BRIDGE_CONNECTION_SUCCESS", // FromBridgeConnectionSuccess
  FROM_BRIDGE_CONNECTION_PROCESS_CANCELLED_NO_CHANGE = "FROM_BRIDGE_CONNECTION_PROCESS_CANCELLED_NO_CHANGE", // FromBridgeConnectionProcessCancelledNoChange
  FROM_BRIDGE_CONNECTION_PROCESS_CANCELLED_ACCOUNT_CREATED = "FROM_BRIDGE_CONNECTION_PROCESS_CANCELLED_ACCOUNT_CREATED", // FromBridgeConnectionProcessCancelledAccountCreated
  FROM_DUNDY_NOT_FROM_BRIDGE_PROBABLY_TO_DEFINE_OR_CHANGE_BANK_ACCOUNT = "FROM_DUNDY_NOT_FROM_BRIDGE_PROBABLY_TO_DEFINE_OR_CHANGE_BANK_ACCOUNT", // FromDundyNotFromBridgeProbablyToDefineOrChangeBankAccount
  UNDEFINED = "UNDEFINED", // undefined
}

<script lang="ts">
  import { onMount } from 'svelte'

  export let windowInject:boolean = false
  export let position: 'left' | 'right' = 'left'
  import { fly } from 'svelte/transition'

  onMount(() => {
    const tooltipContainer = document.querySelector('.tooltip-container') as HTMLElement
    let parent:HTMLElement | null = null
    
    if (windowInject) {
      parent = tooltipContainer.parentElement
      document.body.appendChild(tooltipContainer)
    }
    
    if (tooltipContainer && tooltipContainer.parentNode instanceof HTMLElement) {
      
      if (windowInject && parent && tooltipContainer.parentNode === document.body) {
        // WINDOW INJECTION
        const pos = parent.getBoundingClientRect()
        tooltipContainer.style.top = `${pos.top - tooltipContainer.offsetHeight / 4}px`

        if (position === 'left') {
          tooltipContainer.style.left = `${pos.left - tooltipContainer.offsetWidth - 25}px`
        } else {
          tooltipContainer.style.right = `${pos.left + pos.width + 25}px`
        }
      } else {
        // INSIDE TEXTINPUT
        tooltipContainer.style.top = `-${tooltipContainer.clientHeight / 4}px`
        if (position === 'left') {
          tooltipContainer.style.right = `${tooltipContainer.parentNode.clientWidth + 25}px`
        } else {
          tooltipContainer.style.left = `${tooltipContainer.parentNode.clientWidth + 25}px`
        }
      }
    } else {
      /* console.error('TextInputTooltip.svelte: CANNOT FIND THE TOOLTIP TO POSITION!') */
    }
  })
</script>

<div class="tooltip-container {position}" in:fly={{ y: 50 }} out:fly={{ y: 50 }}>
  <div class="container__arrow container__arrow--tl"></div>
  <slot></slot>
</div>

<style lang="postcss">
  .tooltip-container {
    @apply absolute w-[21vw] max-w-sm border border-[#E9C59A] bg-barleyWhite rounded p-6 text-sm z-50;
  }

  .tooltip-container:after, .tooltip-container:before {
    top: 30%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .tooltip-container.left:after {
    border-left-color: #FFF2CF;
    border-width: 20px;
    margin-top: -12px;
    margin-left: -0.1em;
  }

  .tooltip-container.left:before {
    border-left-color: #E9C59A;
    border-width: 19.5px;
    margin-top: -11.5px;
  }

  .tooltip-container.right:after {
    border-right-color: #FFF2CF;
    border-width: 19.5px;
    margin-top: -11.5px;
    margin-right: -0.1em;
  }

  .tooltip-container.right:before {
    border-right-color: #E9C59A;
    border-width: 20px;
    margin-top: -12px;
  }

  .tooltip-container.right:after, .tooltip-container.right:before {
    right: 100%;
  }

  .tooltip-container.left:after, .tooltip-container.left:before {
    left: 100%;
  }
</style>

import type { BusinessDocument } from '../../models/business-document'
import { deepClone } from '../../../core-app/util/object-deep-cloning'
import { BusinessDocumentRelation } from '../../models/business-document'
import { BusinessDocumentKind } from '../../enums/business-document-kind'
import { BusinessDocumentRelationKind } from '../../enums/business-document-relation-kind'

export function updateBusinessDocumentRelationsWithNewTaxonomy(businessDocument: BusinessDocument): BusinessDocumentRelation[] {
  const updatedBusinessDocument: BusinessDocument = <BusinessDocument>deepClone(businessDocument)
  
  return updatedBusinessDocument.relatedBusinessDocuments.map((aBusinessDocumentRelation) => {
    const newRelation: BusinessDocumentRelation = <BusinessDocumentRelation>deepClone(aBusinessDocumentRelation)
    if (newRelation.toBusinessDocumentId === updatedBusinessDocument.businessDocumentId) {
      newRelation.toBusinessDocumentTaxonomyTags = updatedBusinessDocument.taxonomyTags
    }
    if (newRelation.fromBusinessDocumentId === updatedBusinessDocument.businessDocumentId) {
      newRelation.fromBusinessDocumentTaxonomyTags = updatedBusinessDocument.taxonomyTags
    }
    
    return newRelation
  })
}


/**
 * Determine Relation Kind
 * If the original document is an invoice and the new document is a credit note, the relation kind is CREDIT_NOTE_ON_INVOICE
 * If the original document is a credit note and the new document is an invoice, the relation kind is INVOICE_ON_CREDIT_NOTE
 * Otherwise the relation kind is VOIDING_NOT_IMPLEMENTED
 * @param originalBusinessDocument
 * @param newBusinessDocumentKind
 * TODO MAKE SURE THIS IS THE CORRECT INTERPRETATION OF THE REQUIREMENTS
 */
export function setNewRelationKind(originalBusinessDocument: BusinessDocument, newBusinessDocumentKind: BusinessDocumentKind): BusinessDocumentRelationKind {
  let relationKind: BusinessDocumentRelationKind
  if (originalBusinessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE && newBusinessDocumentKind === BusinessDocumentKind.CREDITNOTE) {
    relationKind = BusinessDocumentRelationKind.CREDIT_NOTE_ON_INVOICE
  } else if (originalBusinessDocument.businessDocumentKind === BusinessDocumentKind.CREDITNOTE && newBusinessDocumentKind === BusinessDocumentKind.INVOICE) {
    relationKind = BusinessDocumentRelationKind.INVOICE_ON_CREDIT_NOTE
  } else {
    relationKind = BusinessDocumentRelationKind.VOIDING_NOT_IMPLEMENTED
  }
  
  return relationKind
}

<script lang="ts">
  import { navigate } from 'svelte-routing'
  import { t } from '../lib/i18n/i18nextWrapper'

  export let deprecated:boolean = false
</script>

<div class="bank-wall-container">
  <div id="stripes">
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div class="flex flex-col items-center justify-center relative w-full">
    <div class="w-7/12 mx-auto flex flex-col items-center justify-center text-center py-10">
      <h1 class="text-3xl text-white font-bold relative">{t('bankWall.title')}</h1>
      <span class="text-base text-zinc-200 max-w-[460px] my-2">{t('bankWall.baseline')}</span>
    </div>
  </div>

  <div class="bank-wall-container_grid">
    <div class="bank-wall-container_panel">
      <img src="/img/bank-wall/benefit_one.png" alt="pay"/>
      <div>
        <h2 class="font-bold text-black my-2">{t('bankWall.payTitle')}</h2>
        <span class="leading-6 text-sm text-paleSky">{t('bankWall.payBaseline')}</span>
      </div>
    </div>
    <div class="bank-wall-container_panel">
      <img src="/img/bank-wall/benefit_two.png" alt="reconciling"/>
      <div>
        <h2 class="font-bold text-black my-2">{t('bankWall.reconcilingTitle')}</h2>
        <span class="leading-6 text-sm text-paleSky">{t('bankWall.reconcilingBaseline')}</span>
      </div>
    </div>
    <div class="bank-wall-container_panel">
      <img src="/img/bank-wall/benefit_three.png" alt="realtime"/>
      <div>
        <h2 class="font-bold text-black my-2">{t('bankWall.realtimeTitle')}</h2>
        <span class="leading-6 text-sm text-paleSky">{t('bankWall.realtimeBaseline')}</span>
      </div>
    </div>
  </div>

  {#if deprecated}
    <span class="w-[80%] mt-2 mx-auto text-dundyOrange text-sm text-center">
      {t('bankWall.deprecated')}
    </span>
  {/if}

  <div class="flex justify-center my-8">
    <button class="btn big-primary px-12" on:click={() => { navigate('/settings/bank/connect-new') }}>
      {t('bankWall.button')}
    </button>
  </div>

  <div class="w-full mx-auto flex items-center text-baliHai bg-slate-50 absolute bottom-0 px-24 py-4">
    <span class="bank-icon_lock"></span>
    <span class="bank-wall_disclaimer">{@html t('bankWall.security')}</span>
  </div>

</div>


<style lang="postcss">
  #stripes {
    @apply absolute top-0 w-full;
    height: 200px;
    background: #031E35; /* Dominant dark background */
    clip-path: polygon(100% 0, 100% 70%, 0 99%, 0 0);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);
  }

  #stripes :nth-child(1) {
    grid-area: 5 / 5 / span 3 / span 3;
    background-color: #ffc400; /* Vivid amber */
    clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 100%);
    transform: translateY(90px);
  }

  #stripes :nth-child(2) {
    grid-area: 2 / 8 / span 5 / span 4;
    background-color: #ff8c00; /* Dark orange */
    clip-path: polygon(0 25%, 100% 0%, 100% 100%, 0% 100%);
    transform: translateY(120px);
  }

  #stripes :nth-child(3) {
    grid-area: 8 / 1 / span 5 / span 4;
    background-color: #ff4500; /* Orange red */
    clip-path: polygon(0 23%, 100% 0%, 100% 100%, 0% 100%);
    transform: translateY(50px);
  }

  .bank-wall-container {
    @apply bg-white border-athensGray ring-1 ring-gray-900/5 absolute lg:h-[600px] w-[920px] top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] flex flex-col select-none -mt-16 overflow-hidden shadow-lg;
    border-radius: 16px;
  }

  .bank-wall-container_grid {
    @apply mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-3 my-5 px-8;
  }

  .bank-wall-container_panel {
    @apply flex max-w-xl flex-col items-start justify-between text-center;
  }

  .bank-wall-container_panel img {
    @apply h-14 w-14 mb-4 shrink-0 mx-auto;
  }

  .bank-wall-container_panel div {
    @apply flex-col;
  }
  .bank-wall_disclaimer {
    @apply text-xxxs;
  }

  .bank-icon_lock {
      mask: url("/img/icons/lock.svg");
      mask-repeat: no-repeat;
      background: #FFB42D;
      height: 24px;
      width: 24px;
      margin-top: 16px;
  }
</style>
import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
import type { ColDef } from 'ag-grid-community'
import DatatableDateRenderer from '../../../../core-app/lib/ui-kit/DatatableDateRenderer'
import DatatableDateEditor from '../../../../core-app/lib/ui-kit/DatatableDateEditor'
import DatatableAmountOfMoneyRendererWithoutCurrencySymbol
  from '../../../../core-app/lib/ui-kit/DatatableAmountOfMoneyRendererWithCurrencyInLetter'
import DatatableInvoiceActionsRenderer from '../../../../dundy-app/lib/dunning/ag-grid/DatatableInvoiceActionsRenderer'
import BusinessDocumentStatusRenderer from './BusinessDocumentStatusRenderer'

export const invoicesColumnDefs: ColDef[] = [
  {
    headerName: 'Did',
    field: 'did',
    sortable: false,
    hide: true,
    suppressColumnsToolPanel: true
  },
  {
    headerName: t('invoices.columns.client'),
    field: 'customerCustomer.company.formalName',
    /*cellRenderer: DatatableCustomerRenderer,*/
    sortable: true,
    cellStyle: { 'text-overflow': 'ellipsis', 'white-space': 'nowrap', 'overflow': 'hidden' },
    cellClass: 'default',
    unSortIcon: true
  },
  {
    headerName: 'toId',
    field: 'toId',
    cellClass: 'font-medium',
    sortable: true,
    editable: false,
    hide: true,
    suppressColumnsToolPanel: true
  },
  {
    headerName: 'completed',
    field: 'completed',
    cellClass: 'font-medium',
    sortable: true,
    editable: false,
    cellRenderer: (params: { value: any; }) => params.value ? '[x]' : '[ ]',
    hide: true,
    suppressColumnsToolPanel: true
  },
  {
    headerName: t('invoices.columns.customerName'),
    field: 'customerCustomer.company.formalName',
    cellClass: 'default',
    sortable: false,
    editable: false,
    hide: true,
    suppressColumnsToolPanel: true
  },
  {
    headerName: t('invoices.columns.invoiceNumber'),
    field: 'businessDocumentNumber',
    /*cellRenderer: InvoiceNumberRenderer,*/
    cellClass: 'default',
    sortable: true,
    cellStyle: { 'text-overflow': 'ellipsis', 'white-space': 'nowrap', 'overflow': 'hidden' },
    unSortIcon: true
  },
  {
    headerName: t('invoices.columns.issuedDate'),
    field: 'issuedDate.rfc3339',
    cellClass: 'default',
    cellRenderer: DatatableDateRenderer,
    cellEditor: DatatableDateEditor,
    sortable: true,
    unSortIcon: true
  },
  {
    headerName: t('invoices.columns.dueDate'),
    field: 'dueDate.rfc3339',
    cellClass: 'default',
    cellRenderer: DatatableDateRenderer,
    cellEditor: DatatableDateEditor,
    sortable: true,
    unSortIcon: true
  },
  {
    headerName: t('invoices.columns.installmentIncludingTaxes'),
    field: 'installmentResultIncludingTaxScaledValue',
    cellClass: 'totalAmount',
    cellRenderer: DatatableAmountOfMoneyRendererWithoutCurrencySymbol,
    /*valueSetter: (params) => DunningInvoicesHelper.numberSetter(params, "totalIncludingTaxScaledValue"),*/
    sortable: true,
    unSortIcon: true
  },
  {
    headerName: t('invoices.columns.totalIncludingTaxes'),
    field: 'totalIncludingTaxScaledValue',
    cellClass: 'totalAmount',
    cellRenderer: DatatableAmountOfMoneyRendererWithoutCurrencySymbol,
    /*valueSetter: (params) => DunningInvoicesHelper.numberSetter(params, "totalIncludingTaxScaledValue"),*/
    sortable: true,
    unSortIcon: true
  },
  {
    headerName: t('invoices.columns.status'),
    field: 'businessDocumentStatus',
    cellRenderer: BusinessDocumentStatusRenderer,
    sortable: false,
    width: 170,
    minWidth: 170
  },

  {
    headerName: '',
    field: 'actions',
    cellClass: 'default',
    cellRenderer: DatatableInvoiceActionsRenderer,
    sortable: false,
    width: 20,
    maxWidth: 20,
    minWidth: 20
  }
]

import { APICallOptions, APIEntity, apiGet, apiPost } from '$core/services/api.service'
import { get } from 'svelte/store'
import { getPreferenceByKind, savePreference } from '$shared/services/preference.service'
import { WorkspaceStore } from '$crm/stores/workspace.store'
import { DunningMessageKind, type DunningMessageTemplateItem, type DunningMessageTemplatePrefKey } from '../models/dunning-message-template'
import type { PersistedPreference } from '$shared/models/preference.model'
import { v4 as uuidv4 } from 'uuid'
import { ContactsStore } from '$crm/stores/contacts.store'
import { stringNormalize } from '$src/shared/utils/string'
import { remoteDebuggingService } from '$src/core-app/services/remote-debugging.service'
import { validateEmail } from '$src/core-app/services/validator'
import { placeholders } from '../data/placeholder'
import type { FieldsErrors } from '../data/template'

enum DunningKind {
  DUNNINGPHONECALL = 'DunningPhoneCall',
  DUNNINGPOSTALLETTER = 'DunningPostalLetter',
  DUNNINGEMAIL = 'DunningEmail'
}

type DunningTemplateResponse = {
  err: any[]
  prefKey: DunningMessageTemplatePrefKey | null;
  prefValue: DunningMessageTemplateItem | null;
  prefValueDefault: DunningMessageTemplateItem | null;
}

/**
 * CONVERT RAW TEXT (from db with \n) TO HTML (to UI with <div>)
 * @param {string} text 
 * @returns {string}
 */
export const convertRaw2Html = (text:string): string => {
  if (!text) return text
  
  const regex = /[^\n]*\n?/g
  const sentences = text.match(regex)
  if (sentences) return sentences.map((p:string) => {
    const trimmedSentence = p.trim()

    return trimmedSentence ? `<div>${trimmedSentence}</div>` : '<div><br></div>'
  }).slice(0, -1).join('')

  return text
}

/**
 * CONVERT HTML (from UI with <div>) TO RAW TEXT (to db with \n)
 * @param {string} text 
 * @returns {string}
 */
const convertHtml2Raw = (text:string):string => {
  if (!text) return text
  
  return text.replace(/<div>|<p>/g, '')
    .replace(/<\/div>|<\/p>/g, '\n')
    .replace(/<br>/g, '\n\n')
}


const convertEditor2Data = (data:any) => {
  if (typeof data === 'string') return convertHtml2Raw(data)

  if (typeof data === 'object') {
    return Object.entries(data).reduce((acc, [key, value]) => {
      acc[key] = convertHtml2Raw(value as string)
        
      return acc
    }, {})
  }
}

/**
 * RETRIEVE DUNNNING TEMPLATE FROM WORKFLOW AND STEP
 * @param {string} workflowKind 
 * @param {string} workflowStep 
 * @returns 
 */
export const getDunningTemplate = async (workflowKind: DunningWorkflowKind, workflowStep: string):Promise<DunningTemplateResponse> => {
  const workspaceId: string = get(WorkspaceStore).workspaceId
  
  const res = await getPreferenceByKind(workspaceId, 'DunningMessageTemplate', [
    {
      workspaceId: workspaceId,
      workflowStep: {
        workflowKind,
        workflowStep
      }
    }
  ])

  let err: any[] = res?.failures
  let prefKey: DunningMessageTemplatePrefKey | null = null
  let prefValue: DunningMessageTemplateItem | null = null
  let prefValueDefault: DunningMessageTemplateItem | null = null
  
  if (!err.length) {
    const tmp = res?.successes[0]

    let hasErr: boolean = false
    if (!tmp?.sortedDunningMessageTemplatePrefs) {
      err.push('')
      hasErr = true
    }
    if (!tmp?.sortedDunningMessageTemplateItems) {
      err.push('')
      hasErr = true
    }

    if (!hasErr) {
      // system default value for input placeholder
      prefValueDefault = tmp.sortedDunningMessageTemplateItems?.find((t:any) => t.isSystemDefault) ?? null

      // current template for edition
      const prf = tmp.sortedDunningMessageTemplatePrefs?.at(0)

      if (prf) {
        prefKey = prf?.prefKey
        if (!prefKey) {
          err.push('')
        } else {
          prefValue = tmp.sortedDunningMessageTemplateItems.find((p:any) => p.templateItemRef === prf.templateItemRef)
          if (!prefValue) {
            err.push('')
          } else {
            prefValue.workspaceId = workspaceId
          }
        }
      }
    }
  }
  
  return {
    err,
    prefKey,
    prefValue,
    prefValueDefault
  }
}

type TemplateItemResponse = {
  successs: any[],
  failures: any[]
}

/**
 * SAVE DunningMessageTemplateItem FOR A WORKSPACE
 * @param worskpaceId
 * @param templateItem
 * @returns
 */
const saveDunningMessageTemplateItem = (
  worskpaceId: string,
  templateItem: DunningMessageTemplateItem
): Promise<TemplateItemResponse> =>
  apiPost(`/workspace/${worskpaceId}/dunning-message-template-items`, [templateItem], <APICallOptions>{
    entity: APIEntity.TOTUM_PREFERENCE,
    ignoreFeedback: true
  })

/**
 * CONVERT FORM INPUTS TO PAYLOAD VALUES : CONTACT / LITERAL / PLACEHOLDER
 * @param value 
 * @returns 
 */
const stringToValue = (value:string, kind:DunningMessageKind) => {
  if (typeof value === 'boolean') return value

  const separators = /[,; ]+/
  const arr = value.split(separators)
  
  if (!arr) return null

  const contacts:any[] = []

  arr.forEach((v:string) => {
    if (!v) return
    // placeholder
    if (v.startsWith('{') && v.endsWith('}')) {
      contacts.push({ dunningPlaceHolder: v })
    } else {
      // contact
      const ctt = get(ContactsStore).find(c => {
        if (kind === DunningMessageKind.DunningEmail) {
          return c.email === v
        } else if (kind === DunningMessageKind.DunningPhoneCall) {
          return c.mobilePhone === v || c.officePhone === v
        } else if (kind === DunningMessageKind.DunningPostalLetter) {
          // TODO : a vérifier avec Franck... en fonction du kind
          return c.firstName + ' ' + c.lastName === v
        }
      })
      if (ctt) {
        contacts.push({ contactReference: ctt.contactId })
      } else {
        // literal
        contacts.push({ literalEmail: v }) // TODO : a vérifier avec Franck... en fonction du kind
      }
    }
  })
  
  return contacts.length ? contacts : null
}

const decodeHtml = (text:string):string => {
  const textArea = document.createElement('textarea')
  textArea.innerHTML = text
  
  return textArea.value
}

const checkMails = (mails:string[]):string => {
  if (!mails) return ''

  const err = mails.reduce((acc:string[], cur:any) => {
    if (cur.literalEmail && !validateEmail(cur.literalEmail)) {
      acc.push(cur.literalEmail)
    } else if (cur.dunningPlaceHolder && !placeholders.find(p => p.key === cur.dunningPlaceHolder)) {
      acc.push(cur.dunningPlaceHolder)
    }

    return acc
  }, [])

  return err?.map((m:any) => decodeHtml(m))?.join(', ')
}


/**
 * 
 * @param kind 
 * @param template 
 * @returns 
 */
const checkTemplate = (kind:string, template:any): null | FieldsErrors => {
  let errors:FieldsErrors = {}
  
  // TODO : faire les traductions

  if (kind === 'DunningEmail') {
    let mails

    if (!template.dunningEmail?.emailFrom) {
      errors.emailFrom = 'Mail manquant'
    } else {
      if (template.dunningEmail?.emailFrom?.length > 1) errors.emailFrom = 'Un seul mail est autorisé'
      
      mails = checkMails(template.dunningEmail.emailFrom)
      if (mails) errors.emailFrom = mails
    } 

    if (!template.dunningEmail?.emailsTo) {
      errors.emailsTo = 'Mail manquant'
    } else {
      mails = checkMails(template.dunningEmail.emailsTo)
      if (mails) errors.emailsTo = mails
    }

    mails = checkMails(template.dunningEmail.emailsCC)
    if (mails) errors.emailsCC = mails 

    mails = checkMails(template.dunningEmail.emailsCCI)
    if (mails) errors.emailsCCI = mails 

    if (!template.dunningEmail.subject) errors.subject = 'Champs vide'
    if (!template.dunningEmail.body) errors.body = 'Champs vide'
  } else if (kind === 'DunningPostalLetter') {
    // TODO : Voir avec @Franck
  } else if (kind === 'DunningPhoneCall') {
    if (!template.dunningPhoneCall?.contactDestination) errors.contactDestination = 'Champs vide'
    if (!template.dunningPhoneCall?.callCanevas) errors.callCanevas = 'Champs vide'
  }

  return errors || null
}

/**
 * GENERATE TEMPLATE ITEM (API PAYLOAD) FROM FORM
 * @param {DunningMessageTemplateItem} prefValue 
 * @param {any} form 
 * @returns 
 */
const genTemplateFromForm = (prefValue: DunningMessageTemplateItem, form:any, language: string) => {
  const excludeKeys = [null, undefined, 'updated', 'updatedByUserId', 'isSystemDefault']
  const contactKeys = ['emailFrom', 'emailsTo', 'emailsCC', 'emailsCCI', 'contactDestination']
  
  // create key for DunningMessageTemplateItem object
  const kindKey = prefValue.kind[0].toLowerCase() + prefValue.kind.slice(1)
  if (!prefValue[kindKey as keyof DunningMessageTemplateItem]) prefValue = { ...prefValue, [kindKey]: {} }

  // generate template
  const template = Object.entries(prefValue).reduce((acc:any, cur) => {
    const [key, value] = cur

    if (excludeKeys.includes(key) || [null, undefined].includes(<any>value)) return acc
    
    // convert object with value from DunningMessageKind / media
    if (typeof value === 'object' && (stringNormalize(key) === stringNormalize(kindKey))) {
      // remove empty keys or replace value from form
      form.forEach((f:any) => {
        if (!f.value) {
          delete value[f.key]
        } else {
          value[f.key] = f.value
        }
      })
      
      // convert value string to object
      acc[key] = Object.entries(value).reduce((a: any, c: any) => {
        const [k, v] = c
        const values = contactKeys.includes(k) ? stringToValue(v, prefValue.kind) : convertEditor2Data(v)

        a[k] = values

        return a
      }, {})
    } else {
      acc[key] = value
    }
      
    return acc
  }, {} as DunningMessageTemplateItem)

  return template
}

/**
 * SAVE DUNNING TEMPLATE AND PREFERENCE FROM FORM
 * @param {DunningMessageTemplatePrefKey} prefKey 
 * @param {DunningMessageTemplateItem} prefValue 
 * @param {any} form 
 * @returns 
 */
export const saveDunningTemplate = async (
  prefKey: DunningMessageTemplatePrefKey,
  prefValue: DunningMessageTemplateItem,
  form: any,
  language: string
):Promise<any[] | string | FieldsErrors | null> => {
  const workspaceId: string = get(WorkspaceStore).workspaceId

  try {
    const template = genTemplateFromForm(prefValue, form, language)
    const templateError = checkTemplate(prefValue.kind, template)

    if (templateError) return templateError
    
    if (template.kind === DunningKind.DUNNINGEMAIL) {
      template.dunningEmail.emailFrom = template.dunningEmail.emailFrom[0]
    }

    // Prohibits overwriting the default template. generates a new id
    if (template.isSystemDefault) {
      template.templateItemRef = uuidv4()
      template.isSystemDefault = false
    }
    
    const resTemplateItem = await saveDunningMessageTemplateItem(workspaceId, template)

    if (resTemplateItem.failures?.length) {
      remoteDebuggingService.addInfo(resTemplateItem.failures, 'error')
      
      return resTemplateItem.failures[0]
    } else {
      const preference: PersistedPreference = {
        kind: 'DunningMessageTemplate',
        workspaceId,
        dunningMessageTemplate: {
          prefKey,
          'templateItemRef': template.templateItemRef
        }
      }
      
      const resPreference = await savePreference(workspaceId, preference)
      if (resPreference.failures?.length) {
        remoteDebuggingService.addInfo(resPreference.failures, 'error')
        
        return resPreference.failures[0]
      } else {
        return ''
      }
    }

  } catch (err) {
    remoteDebuggingService.addInfo(err, 'error')
    debugger
    
    return String(err)
  }
}

/**
 * GET LIST OF DunningMessageTemplateItem FRO SPECIFIC WORKSPACE
 * @param worskpaceId
 * @returns {DunningMessageTemplateItem[]}
 */
export const getDunningMessageTemplateItems = async () => {
  const workspaceId: string = get(WorkspaceStore).workspaceId

  try {
    return await apiGet(`/workspace/${workspaceId}/dunning-message-template-items`, <APICallOptions>{
      entity: APIEntity.TOTUM_PREFERENCE,
      ignoreFeedback: true
    })
  } catch (err) {
    remoteDebuggingService.addInfo(err, 'error')
  }
}

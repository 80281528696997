import { JsonProperty } from "@paddls/ts-serializer";
import { BBBBankAccount } from "$bank/models/bbb-transactions-model";

export class BridgeUserModelResponse {
  @JsonProperty('ok') ok: boolean
  @JsonProperty('data') data: BridgeUserModel
}

export class BridgeUserModel {
  @JsonProperty('workspaceId') workspaceId: string
  @JsonProperty('emailForBBB') emailForBBB: string
  @JsonProperty('createdByDundyUserId') createdByDundyUserId: string
  @JsonProperty('bridge_uuid') bridge_uuid: string
  @JsonProperty('updatedByDundyUserId') updatedByDundyUserId: string
  @JsonProperty('accounts') accounts: BBBBankAccount[]
  @JsonProperty('synced') synced: boolean
}


<script lang="ts">
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
    import { v4 as uuidv4 } from 'uuid'
    import type { BusinessDocument } from '../../../models/business-document'
    import {
      BusinessDocumentLineItem,
      BusinessDocumentRelation,
      DealDetails,
      InstallmentComputationKind
    } from '../../../models/business-document'
    import { SimpleDocumentsStore } from '../../../stores/business-documents.store'
    import { get } from 'svelte/store'
    import {
      applyInstallmentGenericallyChange,
      calculateInstallmentChangeResultingValuesFromAbsoluteValueInputChange,
      calculateInstallmentChangeResultingValuesFromPercentInputChange,
      initiallyDisplayedInputAbsoluteValue,
      initiallyDisplayedInputPercentage,
      initInvoicingBalanceLimits,
      sanitizeInstallmentAbsoluteInputValue,
      sanitizeInstallmentPercentInputValue
    } from '../../../services/ui-services/businessDocumentInstallments/business-document-installments.ui'
    import { onMount } from 'svelte'
    import {
      NewBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange
    } from '../../../models/business-documents-installments.model'
    import {
      getUpdatedTaxonomyAccordingToInstallmentComputationKind
    } from '../../../services/taxonomy/business-document-and-relations-taxonomy-calculation.service'
    import { Decimal } from 'decimal.js'
    import { InvoicingBalanceLimits } from '../../../models/invoicing-balance-limits.model'
    import { TaxonomyTag } from '../../../enums/taxonomy-tag'
    import {
      updateBusinessDocumentRelationsWithNewTaxonomy
    } from '../../../services/business-document-relation/update-business-document-relations.service'
    import { BusinessDocumentRelationKind } from '../../../enums/business-document-relation-kind'
    import { VoxyHelper } from '../../../helpers/voxy-helper'
    import { BusinessDocumentKind } from '../../../enums/business-document-kind'
    import {
      getUpToDateInvoicingBalanceLimits
    } from '../../../services/deal-balance-calculation/invoicing-balance-limits-update.service'
    import { calculateTotalPrice, calculateTotalVAT } from '../../../helpers/voxy-helper-pure-functions'
    import {
      getBusinessDocumentUpdatedInstallmentTaxonomyTags
    } from '../../../services/taxonomy/determine-business-document-taxonomy-tags'
    import {
      isUserAllowedToChangeInstallment
    } from '../../../services/accounting-business-rules/is-user-allowed-to-change-installment.service'
    import {
      isUserAllowedToChangeBetweenFullAndPartialDocument
    } from '../../../services/accounting-business-rules/is-user-allowed-to-change-between-full-and-partial.service'
    import {
      getDealFinalizedBusinessDocumentsForBalanceOfDealCalculus
    } from '../../../services/deal-balance-document-make/get-deal-finalized-business-documents-for-balance-of-deal-calculus.service'
    import { ComputedInstallmentValues } from '../../../models/installment-computed-accumulated-values.model'
    import {
      calculateCumulatedInvoicedInstallmentsIncludingTax
    } from '../../../services/deal-balance-calculation/incomplete-deals-calculation.service'
    import NumberInputForInstallment from '../../../component/NumberInputForInstallment.svelte'


    export let businessDocument: BusinessDocument
    export let invoicingBalanceLimits: InvoicingBalanceLimits = initInvoicingBalanceLimits() // partial invoicing balance limits
    export let refreshInstallmentRelatedValuesAfterPotentialLineItemsChange: boolean = false

    let userInputPercentage: number // percent input value
    let userInputAbsoluteValue: number // absolute input value
    let hadSpecificInstallment: boolean = businessDocument.hasSpecificInstallment
    let userAllowedToChangeInstallment: boolean = false
    let userAllowedToChangeBetweenFullAndPartialDocument: boolean = false

    let inputPercentage = 0
    let inputAbsolute = 0

    /**
     * New Master UI function that updates the changed installment values (percent if absolute value was modified by user, or vice versa) and recalculates the total amounts and taxes
     * @param valueInputByUser
     * @param computeValueChangeEffectFunction
     * @param currentBusinessDocument
     * @param allConcernedBusinessDocuments
     */
    const newApplyInstallmentValueChangeUI = <T>(
      valueInputByUser: T,
      computeValueChangeEffectFunction: (newValue: T, businessDocument: BusinessDocument, allConcernedBusinessDocuments: BusinessDocument[], invoicingBalanceLimits: InvoicingBalanceLimits) => NewBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange,
      currentBusinessDocument: BusinessDocument,
      allConcernedBusinessDocuments: BusinessDocument[]
    ) => {
      if (userInputAbsoluteValue === undefined) return // input value is empty : cannot compute !

      const allDealFinalizedBusinessDocuments: BusinessDocument[] = getDealFinalizedBusinessDocumentsForBalanceOfDealCalculus(
        allConcernedBusinessDocuments,
        currentBusinessDocument.businessDocumentId,
        currentBusinessDocument.linkedDeal.dealId,
      )

      const computedSumOfInvoicedOnlyFinalized: ComputedInstallmentValues = calculateCumulatedInvoicedInstallmentsIncludingTax(currentBusinessDocument.totalIncludingTaxScaledValue, allDealFinalizedBusinessDocuments)

      invoicingBalanceLimits = getUpToDateInvoicingBalanceLimits(businessDocument.totalIncludingTaxScaledValue, computedSumOfInvoicedOnlyFinalized.accumulatedInstallmentsSum.toNumber(), userInputAbsoluteValue)
      // adjustedInvoicingBalanceLimits.dealMaxRemainingPercentToInvoiceIncludingCurrentDraft = new Decimal(-1 * adjustedInvoicingBalanceLimits.dealMaxRemainingPercentToInvoiceIncludingCurrentDraft)
      // adjustedInvoicingBalanceLimits.dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesIncludingCurrentDraft = new Decimal(-1 * adjustedInvoicingBalanceLimits.dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesIncludingCurrentDraft)

      const newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange: NewBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange =
            computeValueChangeEffectFunction(valueInputByUser, {
              ...currentBusinessDocument,
              hasSpecificInstallment: true
            }, allConcernedBusinessDocuments, invoicingBalanceLimits)

      // update remaining values to invoice (balance or intermediate key values)
      const newBizDoc = newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange.newBusinessDocument
      invoicingBalanceLimits = getUpToDateInvoicingBalanceLimits(newBizDoc.totalIncludingTaxScaledValue, computedSumOfInvoicedOnlyFinalized.accumulatedInstallmentsSum.toNumber(), newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange.newUserInputAbsoluteValue)

      if (!!newBizDoc && !!newBizDoc.linkedDeal && !!newBizDoc.linkedDeal.dealId) {
        /* console.log('new invoicingBalanceLimits', invoicingBalanceLimits) */
        // Update Taxonomy based on the installment computation kind
        let updatedTaxonomy: TaxonomyTag[] = getUpdatedTaxonomyAccordingToInstallmentComputationKind(
          invoicingBalanceLimits,
          newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange.newBusinessDocument,
          get(SimpleDocumentsStore),
        )

        /* console.log('new updatedTaxonomy', updatedTaxonomy) */
        // this must come first
        newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange.newBusinessDocument.taxonomyTags = updatedTaxonomy
        // then we can do that after
        newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange.newBusinessDocument.relatedBusinessDocuments =
                updateBusinessDocumentRelationsWithNewTaxonomy(newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange.newBusinessDocument)

        if (!newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange.doNothing) {
          businessDocument = newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange.newBusinessDocument

          // update the UI values
          userInputPercentage = newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange.newUserInputPercentage
          userInputAbsoluteValue = newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange.newUserInputAbsoluteValue

          /* console.warn('businessDocument ui', businessDocument) */
        }
      }

      inputPercentage = JSON.parse(JSON.stringify(userInputPercentage))
      inputAbsolute = JSON.parse(JSON.stringify(userInputAbsoluteValue))
    }

    const updateInstallmentValueChangeUI = (inputValue: number, isPercent: boolean) => {
      const fnSanitize = isPercent ? sanitizeInstallmentPercentInputValue : sanitizeInstallmentAbsoluteInputValue
      const fnCalculate = isPercent ? calculateInstallmentChangeResultingValuesFromPercentInputChange : calculateInstallmentChangeResultingValuesFromAbsoluteValueInputChange

      newApplyInstallmentValueChangeUI<Decimal>(
        new Decimal(inputValue ?? 0),
        applyInstallmentGenericallyChange(
          fnSanitize,
          fnCalculate,
          invoicingBalanceLimits,
        ),
        { ...businessDocument, hasSpecificInstallment: true },
        get(SimpleDocumentsStore),
      )
    }

    const onAmountChange = (e, isPercent: boolean) => {
      const input = e.target?.value ?? e.detail
      businessDocument.hasSpecificInstallment = true
      if (!invoicingBalanceLimits) invoicingBalanceLimits = initInvoicingBalanceLimits()
      updateInstallmentValueChangeUI(input, isPercent)
    }

    onMount(() => {
      /* console.error('%c BusinessDocumentInstallments onMount()', 'color: green; font-size: 1.2em') */
      userInputPercentage = initiallyDisplayedInputPercentage(businessDocument)
      userInputAbsoluteValue = initiallyDisplayedInputAbsoluteValue(businessDocument)
    })

    /** Reset if no totalExcludingTax
     * NB: We have to allow an installment on a Credit Note (we have to allow partial Credit Notes).
     * */
    $: if (!businessDocument?.totalExcludingTaxScaledValue) {
      /* console.error('%c BusinessDocumentInstallments reactive B', 'color: green; font-size: 1.2em', 'Action: arbitrarily setting business document to FULL mode with user input set to 0') */
      businessDocument.hasSpecificInstallment = false
      userInputPercentage = 0
      userInputAbsoluteValue = 0
    }

    $: {
      /**
         * ALLOW USER TO CHANGE VALUES
         */
      /* console.error('%c BusinessDocumentInstallments reactive A', 'color: green; font-size: 1.2em', 'Action: check allow user to change installment ?') */
      // userAllowedToChangeInstallment depends on / and must be triggered with changes in...
      if (!!businessDocument?.businessDocumentKind && businessDocument?.linkedDeal?.dealId && !!businessDocument?.businessDocumentId) {
        userAllowedToChangeInstallment = isUserAllowedToChangeInstallment(businessDocument, get(SimpleDocumentsStore))
        userAllowedToChangeBetweenFullAndPartialDocument = isUserAllowedToChangeBetweenFullAndPartialDocument(businessDocument, get(SimpleDocumentsStore))
      } else {
        userAllowedToChangeInstallment = false
        userAllowedToChangeBetweenFullAndPartialDocument = false
      }


      /**
         * CHANGE UI PERCENT AND ABSOLUTE VALUES
         */
      /* console.error('%c BusinessDocumentInstallments reactive C', 'color: green; font-size: 1.2em', 'Action: react on user changes ...') */
      if (refreshInstallmentRelatedValuesAfterPotentialLineItemsChange && businessDocument.hasSpecificInstallment) {
        /* console.error('%c BusinessDocumentInstallments reactive C', 'color: blue; font-size: 1.1em', 'Action: in VoxyInvoiceAddModal.svelte installment was changed since the reactive expression that recalculates totals was run... this is triggered down to BusinessDocumentInstallments.svelte here') */
        /* console.warn('change reset installment') */

        if (!invoicingBalanceLimits) invoicingBalanceLimits = initInvoicingBalanceLimits()

        if (businessDocument.installmentChosenKind === InstallmentComputationKind.PERCENT_OF_TOTAL_AMOUNT) {
          /* console.error('%c BusinessDocumentInstallments reactive C', 'color: blue; font-size: 1.1em', 'Action: percent changed') */
          updateInstallmentValueChangeUI(businessDocument.installmentChosenValue, true)
        }
        if (businessDocument.installmentChosenKind === InstallmentComputationKind.ABSOLUTE_AMOUNT_INCLUDING_TAX) {
          /* console.error('%c BusinessDocumentInstallments reactive C', 'color: blue; font-size: 1.1em', 'Action: absolute value changed') */
          updateInstallmentValueChangeUI(businessDocument.installmentChosenValue, false)
        }
      } else if (businessDocument.businessDocumentKind === BusinessDocumentKind.CREDITNOTE && !businessDocument.hasSpecificInstallment) {
        /* console.error('%c BusinessDocumentInstallments reactive C', 'color: blue; font-size: 1.1em', 'Action: reset credit note to 100%') */
        userInputPercentage = 100
        userInputAbsoluteValue = businessDocument.totalIncludingTaxScaledValue
      }

      /**
         * ???
         */
      /* console.error('%c BusinessDocumentInstallments reactive D', 'color: green; font-size: 1.2em', 'Action: determine new Taxonomy Tags by recalculating a balance business document if installment type changed') */
      if (hadSpecificInstallment !== businessDocument.hasSpecificInstallment && !!businessDocument?.totalIncludingTaxScaledValue) {
        /* console.error('%c BusinessDocumentInstallments reactive D', 'color: blue; font-size: 1.1em', 'Action: installment type changed has changed, we re-calculate Taxonomy Tags') */
        if (hadSpecificInstallment !== businessDocument.hasSpecificInstallment && businessDocument.hasSpecificInstallment) {
          /* console.error('%c BusinessDocumentInstallments reactive D', 'color: blue; font-size: 1.1em', 'Action: installment type changed has changed, now PARTIAL, we reset installment values') */
          userInputAbsoluteValue = 0
          userInputPercentage = 0
          businessDocument.installmentChosenValue = 0
          businessDocument.installmentResultIncludingTaxScaledValue = 0
          businessDocument.installmentResultExcludingTaxScaledValue = 0
          businessDocument.installmentResultTaxScaledValue = 0
        }
        /* console.error('%c BusinessDocumentInstallments reactive D', 'color: blue; font-size: 1.1em', 'Action: determine new Taxonomy Tags and other things ' + (businessDocument.hasSpecificInstallment ? 'PARTIAL mode' : 'FULL mode')) */
        const res = getBusinessDocumentUpdatedInstallmentTaxonomyTags(businessDocument, get(SimpleDocumentsStore))
        hadSpecificInstallment = businessDocument.hasSpecificInstallment

        if (res.needNewDealIdNeeded) {
          /* console.error('%c BusinessDocumentInstallments reactive D', 'color: blue; font-size: 1.1em', 'Action: setting new deal id') */
          if (!businessDocument.linkedDeal) {
            businessDocument.linkedDeal = <DealDetails>{}
          }
          businessDocument.linkedDeal.dealId = uuidv4()
          businessDocument.linkedDeal.hasDealInfo = true
        }

        if (res.needToConvertIntoFullBusinessDocument && !res.weHaveAFullBusinessDocumentOnlyBecauseWeHaveAPartialBusinessDocumentWithInstallmentAsHighAsTotal) {
          /* console.error('%c BusinessDocumentInstallments reactive D', 'color: blue; font-size: 1.1em', 'Action: converting to FULL mode but not due to values being as high as total, probably because user changed to FULL mode') */
          businessDocument.hasSpecificInstallment = false
          if (!businessDocument.linkedDeal) {
            businessDocument.linkedDeal = <DealDetails>{}
          }
          businessDocument.linkedDeal.dealId = uuidv4()
          businessDocument.linkedDeal.hasDealInfo = true

          userInputAbsoluteValue = 0
          userInputPercentage = 0
          businessDocument.installmentResultIncludingTaxScaledValue = businessDocument.totalIncludingTaxScaledValue
          businessDocument.installmentResultExcludingTaxScaledValue = businessDocument.totalExcludingTaxScaledValue
          businessDocument.installmentResultTaxScaledValue = businessDocument.totalTaxScaledValue
          businessDocument.hasSpecificInstallment = true
        }

        if (res.needToDiscardInstallmentChosenValueAndKind) {
          /* console.error('%c BusinessDocumentInstallments reactive D', 'color: blue; font-size: 1.1em', 'Action: need to discard installment chosen value and kind + recalulated the rest of the business document totals + deal id') */
          businessDocument.relatedBusinessDocuments = businessDocument.relatedBusinessDocuments.filter((relation: BusinessDocumentRelation) => !(/*(relation.toBusinessDocumentId === businessDocument.businessDocumentId) &&*/
            ((relation.relationKind === BusinessDocumentRelationKind.INVOICE_ON_INVOICE)
                        || (relation.relationKind === BusinessDocumentRelationKind.INVOICE_ON_CREDIT_NOTE)
                        || (relation.relationKind === BusinessDocumentRelationKind.CREDIT_NOTE_ON_INVOICE)
            )))
          businessDocument.lineItems = <BusinessDocumentLineItem[]>[
            VoxyHelper.newVoxyInvoiceItem(0, businessDocument.currency)
          ]

          // calculating totals
          businessDocument.subtotalExcludingTaxScaledValue = Math.round((calculateTotalPrice(businessDocument.lineItems) + Number.EPSILON) * 100) / 100
          businessDocument.totalTaxScaledValue = Math.round((calculateTotalVAT(businessDocument.lineItems) + Number.EPSILON) * 100) / 100
          businessDocument.subtotalIncludingTaxScaledValue = Math.round(((businessDocument.subtotalExcludingTaxScaledValue + businessDocument.totalTaxScaledValue) + Number.EPSILON) * 100) / 100
          businessDocument.totalExcludingTaxScaledValue = Math.round(((businessDocument.subtotalExcludingTaxScaledValue - businessDocument.totalDiscountExcludingTaxResultScaledValue) + Number.EPSILON) * 100) / 100
          businessDocument.totalIncludingTaxScaledValue = Math.round(((businessDocument.subtotalExcludingTaxScaledValue - businessDocument.totalDiscountExcludingTaxResultScaledValue) + businessDocument.totalTaxScaledValue + Number.EPSILON) * 100) / 100

          // reset installment
          /* console.log('A getBusinessDocumentUpdatedInstallmentTaxonomyTags reset installment') */
          businessDocument.hasSpecificInstallment = false
          businessDocument.installmentChosenValue = 0
          businessDocument.installmentChosenKind = InstallmentComputationKind.ABSOLUTE_AMOUNT_INCLUDING_TAX
          businessDocument.installmentResultIncludingTaxScaledValue = businessDocument.totalIncludingTaxScaledValue
          businessDocument.installmentResultExcludingTaxScaledValue = businessDocument.totalExcludingTaxScaledValue
          businessDocument.installmentResultTaxScaledValue = businessDocument.totalTaxScaledValue

          if (!businessDocument.linkedDeal) {
            businessDocument.linkedDeal = <DealDetails>{}
          }
          businessDocument.linkedDeal.dealId = uuidv4()
          businessDocument.linkedDeal.hasDealInfo = true

          invoicingBalanceLimits = getUpToDateInvoicingBalanceLimits(businessDocument.totalIncludingTaxScaledValue, 0, 0)

          invoicingBalanceLimits.balanceInvoicingMetricsUpdated = false
        }

        businessDocument.taxonomyTags = res.newTaxonomyTagsForBusinessDocument
        // then we can do that after
        businessDocument.relatedBusinessDocuments = updateBusinessDocumentRelationsWithNewTaxonomy(businessDocument)

        /* console.log('getBusinessDocumentUpdatedInstallmentTaxonomyTags', JSON.stringify(res, null, 3)) */
        /* console.log('getBusinessDocumentUpdatedInstallmentTaxonomyTags current tags', JSON.stringify(businessDocument.taxonomyTags, null, 3)) */
      }
    }

</script>

<div class="divide-y divide-gray-200 border-t border-whisper sm:px-6">
    <div class="space-y-6 py-7">
        <div class="px-12 sm:px-6">
            <h2 class="block text-2xl font-bold text-gray-900 mb-3">{t('invoices.createInvoice.installmentsTitle')}</h2>
            <fieldset class="space-y-2">
                <div>
                    <p class="text-sm text-gray-500">{t('invoices.createInvoice.installmentsSubTitle')}</p>
                    {#if userAllowedToChangeBetweenFullAndPartialDocument}

                        <fieldset class="my-4 py-4">
                            <legend class="sr-only">{t('invoices.createInvoice.installmentsConditions')}</legend>
                            <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                <div class="flex items-center">
                                    <input id="no-installment"
                                           name="specific-installment"
                                           type="radio"
                                           bind:group={businessDocument.hasSpecificInstallment}
                                           value={false}
                                           class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
                                    <label for="no-installment"
                                           class="block text-sm font-medium leading-6 text-gray-900">{t('invoices.createInvoice.noInstallmentChoice')}</label>
                                </div>

                                <div class="flex items-center">
                                    <input id="has-installment"
                                           name="specific-installment"
                                           type="radio"
                                           bind:group={businessDocument.hasSpecificInstallment}
                                           value={true}
                                           class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
                                    <label for="has-installment"
                                           class="ml-3 block text-sm font-medium leading-6 text-gray-900">{t('invoices.createInvoice.yesInstallmentChoice')}</label>
                                </div>

                            </div>
                        </fieldset>
                    {:else }
                        {#if businessDocument?.hasSpecificInstallment}
                            <div
                                    class="ml-3 block text-sm font-medium leading-6 text-gray-900">{t('invoices.createInvoice.yesInstallmentChoice')}</div>
                        {:else }
                            <div
                                    class="block text-sm font-medium leading-6 text-gray-900">{t('invoices.createInvoice.noInstallmentChoice')}</div>
                        {/if}
                    {/if}
                    {#if businessDocument.hasSpecificInstallment}
                        <section class="relative bg-whisper border border-athensGray rounded py-3 px-4 my-4">
                            {#if businessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE}
                                <div class="flex justify-between mx-1.5 my-4">
                                    <h4 class="font-medium">{t('invoices.createInvoice.partialAmountInvoiceTitle')}</h4>
                                </div>
                            {:else if businessDocument.businessDocumentKind === BusinessDocumentKind.CREDITNOTE}
                                <div class="flex justify-between mx-1.5 my-4">
                                    <h4 class="font-medium">{t('invoices.createInvoice.partialAmountCreditNoteTitle')}</h4>
                                </div>
                            {:else}
                                <div class="flex justify-between mx-1.5 my-4">
                                    <h4 class="font-medium">{t('invoices.createInvoice.partialAmountAnyTitle')}</h4>
                                </div>
                            {/if}

                            <div class="flex py-2">
                                {#if userAllowedToChangeInstallment}
                                    <div class="flex flex-1 px-1 flex-col pr-1 w-56">
                                        <NumberInputForInstallment
                                                showPrefix={true}
                                                prefixLabel={t('invoices.createInvoice.discountPercentageValueLabel')}
                                                label={t('invoices.createInvoice.installmentPercentage')}
                                                bind:value={inputPercentage}
                                                on:input={e => onAmountChange(e, true)}
                                                placeholder="0%"
                                                min={0}
                                                max={100}
                                                showValidationTick={false}
                                                id="installmentPercentage"
                                        />
                                    </div>
                                    <div class="flex flex-1 px-1 flex-col pr-1 w-56">
                                        <NumberInputForInstallment
                                                showPrefix={true}
                                                prefixLabel={businessDocument?.currency}
                                                label={t('invoices.createInvoice.installmentAmountInclTaxes')}
                                                bind:value={inputAbsolute}
                                                on:input={e => onAmountChange(e, false)}
                                                placeholder="0,00"
                                                showValidationTick={false}
                                                id="installmentAmount"/>
                                    </div>
                                {:else }
                                    <div class="flex flex-1 px-1 flex-col pr-1 w-56"> {userInputPercentage + ' %'}</div>
                                    <div
                                            class="flex flex-1 px-1 flex-col pr-1 w-56"> {userInputAbsoluteValue + ' ' + businessDocument.currency}</div>
                                {/if}
                            </div>
                        </section>
                    {/if}

                </div>

            </fieldset>
        </div>
    </div>
</div>

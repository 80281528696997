<script lang="ts">
    import { SignUpStoreEmail } from '../../stores/signup.store'
    import { OnboardingStore } from '../../stores/onboarding.store'
    import { t } from '../../lib/i18n/i18nextWrapper'
    import { OnboardingStep } from '../../enums/onboarding-steps'
    import { onMount } from 'svelte'
    import OnboardingLayout from "$core/lib/onboarding/OnboardingLayout.svelte";

    onMount(() => {
      $OnboardingStore.currentOnboardingStep = OnboardingStep.CONFIRM
    })
</script>
<OnboardingLayout>
  <div slot="onboarding-sidebar">
    <div class="flex flex-col w-full text-center align-center">
      <div class="flex flex-col w-10/12 mx-auto mt-40 items-center">
        <img alt="warning-indicator" src="/img/warning-sun.svg" class="h-12 w-12"/>
        <div class="flex flex-col ">
          <span class="text-lg font-bold mt-3 mb-2 text-white">{t('onboarding.confirmEmail.sidebar.title')}</span>
          <span class="text-sm leading-6 text-athensGray">{t('onboarding.confirmEmail.sidebar.text1')}</span>
          <span class="text-sm leading-6 text-athensGray">{t('onboarding.confirmEmail.sidebar.text2')}</span>
        </div>
      </div>
    </div>
  </div>
  <div slot="onboarding-content">
    <section class="onboarding">
      <div>
        <img alt="dundy logo" src="/img/you-have-mail.svg" class="h-48 w-80 m-auto"/>
        <h1>{t('onboarding.confirmEmail.main.title')}</h1>
        <div>
          <p class="font-bold">{t('onboarding.confirmEmail.main.subTitle')} <span
            class="text-dundyOrange">{$SignUpStoreEmail}</span></p>
          <p class="mt-6">
            {t('onboarding.confirmEmail.main.text1')} <br/>
            {t('onboarding.confirmEmail.main.text2')} <span
            class="text-dundyOrange">{t('onboarding.confirmEmail.main.text3')}</span> {t('onboarding.confirmEmail.main.text4')} {t('onboarding.confirmEmail.main.text5')}
          </p>
          <p class="mt-6">
            {t('onboarding.confirmEmail.main.text6')}<br/>
            {t('onboarding.confirmEmail.main.text7')}
          </p>
        </div>
      </div>
    </section>
  </div>
</OnboardingLayout>

<!--
<main class="flex">
    <section
            class="flex-col w-3/12 justify-left relative max-w-lg p-10 pl-12  h-screen hidden md:block bg-gradient-to-t from-[#0d081e] to-[#152c3c] text-[#8CA3BE]">
        <div class="flex flex-col mt-48 justify-start">
            <img alt="warning-indicator" src="/img/warning-sun.svg" class="h-8 w-8 mr-4 ml-[8vw]"/>
            <span class="text-sm font-semibold mt-4">{t('onboarding.confirmEmail.sidebar.title')}</span>
            <span class="text-sm">{t('onboarding.confirmEmail.sidebar.text1')}</span>
            <span class="text-sm">{t('onboarding.confirmEmail.sidebar.text2')}</span>
        </div>
    </section>
    <section class="flex w-full md:w-7/12 2xl:w-9/12">
        <div class="flex flex-col w-7/12 2xl:w-4/12 py-10 max-w-md mx-auto">
            <div class="mx-auto mb-6 text-center">
                <img alt="dundy logo" src="/img/you-have-mail.svg" class="h-48 w-80"/>
                <span class="text-3xl font-bold mb-8">{t('onboarding.confirmEmail.main.title')}</span>
            </div>
            <div>
                <p class="font-bold">{t('onboarding.confirmEmail.main.subTitle')} <span
                        class="text-dundyOrange">{$SignUpStoreEmail}</span></p>
                <p class="mt-6">
                    {t('onboarding.confirmEmail.main.text1')} <br/>
                    {t('onboarding.confirmEmail.main.text2')} <span
                        class="text-dundyOrange">{t('onboarding.confirmEmail.main.text3')}</span> {t('onboarding.confirmEmail.main.text4')} {t('onboarding.confirmEmail.main.text5')}
                </p>
                <p class="mt-6">
                    {t('onboarding.confirmEmail.main.text6')}<br/>
                    {t('onboarding.confirmEmail.main.text7')}
                </p>
            </div>
        </div>
    </section>
</main>-->

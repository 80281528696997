/**
 * This version of debounce is typed to accept a function F and return a new function with the same parameters and return type as F.
 * It uses TypeScript's Parameters and ReturnType utility types to infer the types of the parameters and return type of F.
 *
 * Note that in the returned function, we return undefined as ReturnType<F>. This is because the original function is called asynchronously,
 * so we can't actually return its return value in the debounced function.
 * However, to satisfy TypeScript's type checking, we need to return a value of the same type, so we use undefined as a stand-in.
 * This should be fine in most cases, as the return value of a debounced function is usually ignored.
 * @param func
 * @param wait
 */
export function debounce<F extends (...args: any[]) => any>(func: F, wait: number) {
  let timeout: ReturnType<typeof setTimeout> | null = null

  return function (...args: Parameters<F>): ReturnType<F> {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    // The original function may have a return value, so to maintain
    // the function signature, we should return the same type.
    // However, because the function is called asynchronously, we can't
    // actually return its return value here, so we return `undefined`
    // (which is a valid value for any type).
    return undefined as any as ReturnType<F>
  }
}

<script lang="ts">
    import { navigate, Route, Router } from 'svelte-routing'
    import CompanyInfoPlus from './CompanyInfoPlus.svelte'
    import { onMount } from 'svelte'
    import { checkSignInOrRedirect } from '../../services/guard'
    import { OnboardingStep } from '../../enums/onboarding-steps'

    export let steps: string[]

    onMount(() => {
      checkSignInOrRedirect()

      if (!steps.find((route) => '/onboarding/' + route === window.location.pathname)) {
        /* console.log('navigate in Guard.svelte: at window.location.pathname and going to /', window.location.pathname) */
        navigate('/')
      }
    })
</script>

<Router>
    <Route path={OnboardingStep.COMPANY_INFO_PLUS}>
        <CompanyInfoPlus/>
    </Route>
</Router>

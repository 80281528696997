import type { Workspace } from '../models/workspace'
import type Company from '../models/company'

/**
 * Returns the refreshed account company
 * @param workspaceStore
 */
export function getCurrentAccountCompany(workspaceStore: Workspace): Company {
  return workspaceStore.company
}

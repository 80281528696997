import { t } from '../../../../../core-app/lib/i18n/i18nextWrapper'
import type { ICellRendererParams } from 'ag-grid-community'
import { get } from 'svelte/store'
import { TransactionsStore } from '../../../../stores/transactions.store'
import { CashApplicationDeclaredEventsStore } from '../../../../../cash-application-app/stores/cash-application.store'
import {
  isTransactionPartiallyCashApplied,
  isTransactionRequiringCashApplication
} from '../../../../../cash-application-app/services/cash-application-pure-functions'

export default class DatatableCashApplicationStatusRenderer {
  private cellContent: HTMLElement
  public content: HTMLElement
  constructor() {
  }
  public init(params: ICellRendererParams) {
    const { data } = params

    let requiresCashApplication: boolean
    let partialCashApplication: boolean
    const transactionId = data.id.toString()
    requiresCashApplication = isTransactionRequiringCashApplication(transactionId, get(TransactionsStore).transactions, get(CashApplicationDeclaredEventsStore))
    partialCashApplication = isTransactionPartiallyCashApplied(transactionId, get(TransactionsStore).transactions, get(CashApplicationDeclaredEventsStore))
    this.cellContent = document.createElement('span')
    this.cellContent.classList.add('flex')
    this.content = this.cellContent
    if (data.amount > 0) {
      if (requiresCashApplication) {
        if (partialCashApplication) {
          this.content.innerHTML = `
                        <div class="flex w-full justify-end items-center">
                           <span class="inline-flex items-center rounded-md bg-mercury border border-paleSky px-2.5 py-0.5 text-xs font-medium text-paleSky">${t('cashApplication.status.partiallyCashApply')}</span>
                        </div>
                        `
        } else {
          this.content.innerHTML = `
                        <div class="flex w-full justify-end items-center">
                           <span class="inline-flex items-center rounded-md bg-peachCream border border-bourbon px-2.5 py-0.5 text-xs font-medium text-bourbon">${t('cashApplication.status.toCashApply')}</span>
                        </div>
                        `
        }
      } else {
        this.content.innerHTML = `
                    <div class="flex w-full justify-end items-center">
                       <span class="inline-flex items-center rounded-md bg-frenchLilac border border-grape px-2.5 py-0.5 text-xs font-medium text-grape">${t('cashApplication.status.cashApplied')}</span>
                    </div>
                    `
      }
    } else if (data.amount < 0) {
      this.content.innerHTML = ''
    }
  }
  // gets called whenever the cell refreshes
  refresh(params) {
    // set value into cell again
    // this.content.innerHTML = this.getValueToDisplay(params);

    // return true to tell the grid we refreshed successfully
    /* console.log('refreshing request on AgGrid', params) */

    return true
  }
  public getGui() {
    return this.cellContent
  }
  public destroy() {
  }
}

import type { UIAmountAndDateTransaction } from '$core/models/ui-transaction-statistics.model'
import type Invoice from '../../../../dundy-app/models/invoice'
import {
  getOverdueInvoicesExcludingUntrackedAndDeleted
} from '$dundy/services/invoices.pure-functions'
import type ComputedDataInvoice from '../../../../dundy-app/models/computed-data-invoice'
import { DunningBusinessDocumentKind } from '$dundy/enums/dunning-business-document-kind'
import { getStartAndEndDates } from '$core/services/dashboard-statistics-pure-functions'

/**
 * Calculate 12Months Not Yet Due Invoices In Monthly Sums
 * for each month, simply Sum the amount of the invoices that are not yet due
 * and not deleted
 * Returns an object monthlySums12MonthsNotYetDueInvoices that contains
 * the number of not yet due invoices for each month in the format YYYY-MM.
 * @param invoices
 * @param dateInTimezone
 *
 */
export function calculate12MonthsNotYetDueInvoicesInMonthlySums(
  invoices: Invoice[],
  dateInTimezone: Date,
): Record<string, number> {

  const newDateInTimezone: Date = new Date(dateInTimezone.getTime())
  const currentMonth: number = newDateInTimezone.getMonth()
  const currentYear: number = newDateInTimezone.getFullYear()

  // Pre-populate `acc` with the last 12 months, set to 0
  const acc: Record<string, number> = create12MonthsAccumulator(currentMonth, currentYear)

  for (let i = 0; i < 12; i++) {
    const targetMonth: Date = new Date(newDateInTimezone.getTime())
    targetMonth.setMonth(newDateInTimezone.getMonth() - i)

    const nextMonth: Date = new Date(targetMonth)
    nextMonth.setMonth(targetMonth.getMonth() + 1)

    const currentMonthDates: { startDate: Date, endDate: Date } = getStartAndEndDates(targetMonth)

    // Filtering invoices based on the new conditions
    const notYetDueInvoices: Invoice[] = invoices.filter(
      (invoice: Invoice) => new Date(invoice.dateDue) > currentMonthDates.endDate && // invoice's due date is beyond the end of the current month
                    new Date(invoice.dateIssued) <= currentMonthDates.endDate && // invoice was issued up to the end of the current month
                    invoice.documentKind === DunningBusinessDocumentKind.INVOICE &&
                    !invoice.deleted &&
                    invoice.completed &&
                    invoice.isTracked)

    // Sum the amounts for the not yet due invoices
    const totalAmountForMonth: number = notYetDueInvoices.reduce((acc: number, invoice: Invoice): number => {
      if (typeof invoice.amountIncludingTaxes !== 'number') {

        return acc // return the accumulator unchanged
      }

      return acc + invoice.amountIncludingTaxes
    }, 0)

    const key: string = `${targetMonth.getFullYear()}-${targetMonth.getMonth()}`
    acc[key] = totalAmountForMonth
  }

  return acc
}

/**
 * Todo - THIS SHOULD USE THE CADE FUNCTIONS
 * SO WE CAN COMPUTE WHEN THE INVOICE WAS PAID AND DETERMINE IF IT WAS LATE
 * AT ANY GIVEN POINT IN TIME
 * @param invoices
 * @param computedDataInvoiceStore
 * @param dateInTimezone
 */
export function calculate12MonthsLateInvoicesInMonthlySums(
  invoices: Invoice[],
  computedDataInvoiceStore: ComputedDataInvoice[],
  dateInTimezone: Date,
): Record<string, number> {

  if (!dateInTimezone) {
    /* console.error('Invalid dateInTimezone:', dateInTimezone) */

    return {}
  }
  const newDateInTimezone: Date = new Date(dateInTimezone?.getTime())
  const currentMonth: number = newDateInTimezone.getMonth()
  const currentYear: number = newDateInTimezone.getFullYear()

  // Pre-populate `acc` with the last 12 months, set to 0
  const acc: Record<string, number> = create12MonthsAccumulator(currentMonth, currentYear)

  for (let i = 0; i < 12; i++) {
    const targetMonth: Date = new Date(newDateInTimezone.getTime())
    targetMonth.setMonth(newDateInTimezone.getMonth() - i)

    const currentMonthDates: { startDate: Date, endDate: Date } = getStartAndEndDates(targetMonth)

    const currentMonthOverdueInvoices: Invoice[] = invoices.filter(
      (invoice: Invoice): boolean => new Date(invoice.dateDue) <= currentMonthDates.endDate,
    )

    const currentMonthFilteredOverdueInvoices: Invoice[] = getOverdueInvoicesExcludingUntrackedAndDeleted(currentMonthOverdueInvoices, computedDataInvoiceStore)

    const currentMonthOverdueAmount: number = currentMonthFilteredOverdueInvoices.reduce(
      (total: number, invoice: Invoice) => total + invoice.amountIncludingTaxes, 0)

    const key: string = `${targetMonth.getFullYear()}-${targetMonth.getMonth()}`
    acc[key] = currentMonthOverdueAmount
  }

  /* console.log('acc', acc) */

  return acc
}


/**
 * Returns an object monthlySums12MonthsRevenue that contains
 * sums of transaction amounts for each month in the format YYYY-MM.
 * @param positiveTransactions
 * @param currentMonth
 * @param currentYear
 */
export function calculate12MonthsRevenueInMonthlySums(
  positiveTransactions: UIAmountAndDateTransaction[],
  currentMonth: number,
  currentYear: number): Record<string, number> {

  // Pre-populate `acc` with the last 12 months, set to 0
  const acc: Record<string, number> = create12MonthsAccumulator(currentMonth, currentYear)

  return positiveTransactions.reduce((accum: Record<string, number>, transaction: UIAmountAndDateTransaction) => {
    const date: Date = new Date(transaction.date)
    const month: number = date.getMonth()
    const year: number = date.getFullYear()
    const key: string = `${ year }-${ month }`

    if (accum[key] !== undefined) {
      accum[key] += transaction.amount
    }

    return accum
  }, acc)
}

/**
 * Returns an object monthlySums12MonthsRevenue that contains
 * sums of transaction amounts for each month in the format YYYY-MM.
 * returns the absolute value of the amount to make it positive
 * @param negativeTransactions
 * @param currentMonth
 * @param currentYear
 */
export function calculate12MonthsExpenseInMonthlySums(
  negativeTransactions: UIAmountAndDateTransaction[],
  currentMonth: number,
  currentYear: number): Record<string, number> {

  const acc: Record<string, number> = create12MonthsAccumulator(currentMonth, currentYear)

  /* console.log('acc for expenses', acc) */

  return negativeTransactions.reduce(
    (accum: Record<string, number>, transaction: UIAmountAndDateTransaction) => {
      const date: Date = new Date(transaction.date)
      const month: number = date.getMonth()
      const year: number = date.getFullYear()
      const key: string = `${ year }-${ month }`

      // Use the absolute value to make it positive
      if (accum[key] !== undefined) {
        accum[key] += Math.abs(transaction.amount)
      }

      return accum
    }, acc)
}


/**
 * Returns an object with 12 keys,
 * each representing a month in the format YYYY-MM.
 * The values are all set to 0.
 * @param currentMonth
 * @param currentYear
 */
function create12MonthsAccumulator(currentMonth: number, currentYear: number): Record<string, number> {
  return Array.from({ length: 12 }, (_, i: number): { [p: string]: number } => {
    const month: number = (currentMonth - i + 12) % 12
    const year: number = (month <= currentMonth) ? currentYear : currentYear - 1

    return { [`${ year }-${ month }`]: 0 }
  }).reduce((a: { [p: string]: number }, b: { [p: string]: number }) => ({ ...a, ...b }), {})
}


import type { GetLocaleTextParams, GridOptions } from 'ag-grid-community'
import { agGridCommonService } from '../../../dundy-app/services/ag-grid-common.service'
import { t } from '../../../core-app/lib/i18n/i18nextWrapper'

export const quotesOptions: GridOptions<GridOptions> = {
  defaultColDef: {
    flex: 1,
    resizable: false,
    headerCheckboxSelection: agGridCommonService.isFirstColumn,
    checkboxSelection: agGridCommonService.isFirstColumn
  },
  rowHeight: 64,
  domLayout: 'autoHeight', // autoHeight or normal (fixed)
  rowClassRules: { // add class depending on conditions
    'dundy-invoice-row-deleted': params => params.api.getValue('deleted', params.node),
    'dundy-invoice-row-active': params => !params.api.getValue('deleted', params.node)
  },
  singleClickEdit: false,
  suppressClickEdit: false,
  suppressRowClickSelection: true,
  stopEditingWhenCellsLoseFocus: true,
  suppressRowHoverHighlight: true,
  debug: true,
  rowSelection: 'multiple',
  enableRangeSelection: false,
  pagination: true,
  paginationPageSize: 50,
  suppressPaginationPanel: true,
  getLocaleText: (params: GetLocaleTextParams<GridOptions>): string => {
    // Look up the translation using i18next
    const translation: string = t(`agGrid.${params.key}`)
    
    return translation === `agGrid.${params.key}` ? params.key : translation
  }
}
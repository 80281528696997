import { BusinessDocument, BusinessDocumentRelation } from '../../models/business-document'
import { TaxonomyTag } from '../../enums/taxonomy-tag'
import { BusinessDocumentRelationKind } from '../../enums/business-document-relation-kind'
import { InvoicingBalanceLimits } from '../../models/invoicing-balance-limits.model'
import { isReferenceArrayContainingAtLeastOneItemInSeekedArray } from './list-has-at-least-one-item-of-another-list'
import { isPartialBusinessDocument } from '../installment/is-partial-business-document'
import {
  getDealFinalizedBusinessDocumentsForBalanceOfDealCalculus
} from '../deal-balance-document-make/get-deal-finalized-business-documents-for-balance-of-deal-calculus.service'
import { getBusinessDocumentUpdatedInstallmentTaxonomyTags } from './determine-business-document-taxonomy-tags'

/**
 TODO merge updateTaxonomyTagsForInstallmentInvoice() updateForPartialBusinessDocument() and getUpdatedTaxonomyAccordingToInstallmentComputationKind()
 * Update the Taxonomy tags according to the installment computation kind
 * - checks whether hasSpecificInstallment is true and whether either of installmentResultIncludingTaxScaledValue or installmentResultExcludingTaxScaledValue is not equal to their respective totals.
 * - If this condition is met, it adds the PARTIAL_BUSINESS_DOCUMENT taxonomy tag to the taxonomyTags array and removes the FULL_BUSINESS_DOCUMENT taxonomy tag if it exists.
 * - Otherwise, it adds the FULL_BUSINESS_DOCUMENT taxonomy tag to the taxonomyTags array and removes the PARTIAL_BUSINESS_DOCUMENT taxonomy tag if it exists.
 * - Then, if hasSpecificInstallment is true, it further refines the taxonomy tag to one of DEPOSIT_INVOICE, INTERMEDIATE_INVOICE, or BALANCE_INVOICE.
 *
 * @param invoicingBalanceLimits
 * @param businessDocument
 * @param allBusinessDocuments
 */
export function getUpdatedTaxonomyAccordingToInstallmentComputationKind(
  invoicingBalanceLimits: InvoicingBalanceLimits,
  businessDocument: BusinessDocument,
  allBusinessDocuments: BusinessDocument[],
): TaxonomyTag[] {
  let taxonomyTags: TaxonomyTag[] = Array.isArray(businessDocument.taxonomyTags) ? businessDocument.taxonomyTags : []

  if (isPartialBusinessDocument(businessDocument)) {
    return updateForPartialBusinessDocument(invoicingBalanceLimits, businessDocument, allBusinessDocuments)
  } else {
    return updateForFullBusinessDocument(taxonomyTags)
  }
}

/**
 TODO merge updateTaxonomyTagsForInstallmentInvoice() updateForPartialBusinessDocument() and getUpdatedTaxonomyAccordingToInstallmentComputationKind()
 * Update Taxonomy tags for partial invoices
 * @param invoicingBalanceLimits
 * @param businessDocument
 * @param allInvoices
 */
function updateForPartialBusinessDocument(
  invoicingBalanceLimits: InvoicingBalanceLimits,
  businessDocument: BusinessDocument,
  allInvoices: BusinessDocument[],
): TaxonomyTag[] {
  return updateTaxonomyTagsForInstallmentInvoice(invoicingBalanceLimits, businessDocument, allInvoices)
}

/**
 * TODO merge updateTaxonomyTagsForInstallmentInvoice() updateForPartialBusinessDocument() and getUpdatedTaxonomyAccordingToInstallmentComputationKind()
 * Update the Taxonomy tags according to the installment computation kind and the context of other installments
 * - The function first checks if hasSpecificInstallment is set to false.
 * - If so, it returns the current taxonomy tags without changes.
 * - Then, it filters out the invoices that are related to the same deal.
 * - Then it checks if any of these related invoices already has the DEPOSIT_INVOICE tag.
 * - If so, it checks if the total value of all related invoices, including the current one, equals the total value of the current invoice.
 * - If they are equal, it tags the current invoice as a BALANCE_INVOICE.
 * - If not, it's tagged as an INTERMEDIATE_INVOICE. If there's no deposit invoice yet, the current invoice gets the DEPOSIT_INVOICE tag.
 *
 * @param invoicingBalanceLimits
 * @param businessDocument
 * @param allInvoices
 */
function updateTaxonomyTagsForInstallmentInvoice(
  invoicingBalanceLimits: InvoicingBalanceLimits,
  businessDocument: BusinessDocument,
  allInvoices: BusinessDocument[],
): TaxonomyTag[] {
  let allDealFinalizedBusinessDocuments: BusinessDocument[] = getDealFinalizedBusinessDocumentsForBalanceOfDealCalculus(allInvoices, businessDocument.businessDocumentId, businessDocument.linkedDeal.dealId)
  const res = getBusinessDocumentUpdatedInstallmentTaxonomyTags(businessDocument, allDealFinalizedBusinessDocuments)
  
  return res.newTaxonomyTagsForBusinessDocument
}


function filterKeepOnlyPartialBusinessDocumentForTheSameDealAsRefBusinessDocument(refBusinessDocument: BusinessDocument): (thisBusinessDocument: BusinessDocument) => boolean {
  if (!refBusinessDocument) {
    return (businessDocument: BusinessDocument) => false
  }
  // solution 1: alternative but subtly different solution:
  // if (!refBusinessDocument.linkedDeal || !refBusinessDocument.linkedDeal.dealId) {
  //     return (businessDocument: BusinessDocument) => refBusinessDocument.relatedBusinessDocuments?.atLeastOneOfTheRelationsIsAPartialInvoiceOfTheTypeBalanceOrIntermediateOrDepositToOrFromTheRefDoc(refBusinessDocument.businessDocumentId, businessDocument.businessDocumentId);
  // } else {
  //     return filterAllBusinessDocumentsWithDealId(refBusinessDocument.linkedDeal.dealId)
  // }
  // solution 2: more direct and more subtly correct
  return (thisBusinessDocument: BusinessDocument) => (!!refBusinessDocument.linkedDeal && (thisBusinessDocument.linkedDeal.dealId === refBusinessDocument.linkedDeal.dealId)) ||
        (refBusinessDocument.relatedBusinessDocuments?.atLeastOneOfTheRelationsIsAPartialInvoiceOfTheTypeBalanceOrIntermediateOrDepositToOrFromTheRefDoc(thisBusinessDocument.businessDocumentId, refBusinessDocument.businessDocumentId))
}

// Array extension ("declare global" then "Array.prototype.xxx=")
// see: https://stackoverflow.com/questions/12802383/extending-array-in-typescript

declare global {
  interface Array<T> {
    atLeastOneOfTheRelationsIsAPartialInvoiceOfTheTypeBalanceOrIntermediateOrDepositToOrFromTheRefDoc(refDocumentId: string, thisBusinessDocumentId: string): boolean;
  }
}
Array.prototype.atLeastOneOfTheRelationsIsAPartialInvoiceOfTheTypeBalanceOrIntermediateOrDepositToOrFromTheRefDoc = function <T extends BusinessDocumentRelation>(refDocumentId: string, thisBusinessDocumentId: string): boolean {
  return this.some((relation: T) =>
    relation.relationKind === BusinessDocumentRelationKind.INVOICE_ON_INVOICE
            // && relation.fromBusinessDocumentId === thisBusinessDocumentId // TODO check if we need this condition
            && (
              relation.toBusinessDocumentId === refDocumentId //  initially written this way
                || relation.fromBusinessDocumentId === refDocumentId
            )
            && (relation.fromBusinessDocumentTaxonomyTags.includes(TaxonomyTag.COMMERCIAL_INVOICE)
                && relation.fromBusinessDocumentTaxonomyTags.includes(TaxonomyTag.PARTIAL_BUSINESS_DOCUMENT)
                && (
                  relation.fromBusinessDocumentTaxonomyTags.includes(TaxonomyTag.DEPOSIT_INVOICE)
                    || relation.fromBusinessDocumentTaxonomyTags.includes(TaxonomyTag.INTERMEDIATE_INVOICE)
                    || relation.fromBusinessDocumentTaxonomyTags.includes(TaxonomyTag.BALANCE_INVOICE)
                )
            )
            && (relation.toBusinessDocumentTaxonomyTags.includes(TaxonomyTag.COMMERCIAL_INVOICE)
                && relation.toBusinessDocumentTaxonomyTags.includes(TaxonomyTag.PARTIAL_BUSINESS_DOCUMENT)
                && (
                  relation.toBusinessDocumentTaxonomyTags.includes(TaxonomyTag.DEPOSIT_INVOICE)
                    || relation.toBusinessDocumentTaxonomyTags.includes(TaxonomyTag.INTERMEDIATE_INVOICE)
                    || relation.toBusinessDocumentTaxonomyTags.includes(TaxonomyTag.BALANCE_INVOICE)
                )
            ),
  )
}


/**
 * Update Taxonomy tags for full invoices
 * by removing all other invoice tags and adding the FULL_BUSINESS_DOCUMENT tag
 * @param tags
 */
function updateForFullBusinessDocument(tags: TaxonomyTag[]): TaxonomyTag[] {
  return addOrRemoveTag(tags, TaxonomyTag.FULL_BUSINESS_DOCUMENT, [TaxonomyTag.PARTIAL_BUSINESS_DOCUMENT, TaxonomyTag.VOIDING_CREDIT_NOTE, TaxonomyTag.DEPOSIT_INVOICE, TaxonomyTag.INTERMEDIATE_INVOICE, TaxonomyTag.BALANCE_INVOICE])
}

/**
 * Add or remove a tag from a list of tags
 * @param tags
 * @param tagToAdd
 * @param tagsToRemove
 */
function addOrRemoveTag(tags: TaxonomyTag[], tagToAdd: TaxonomyTag, tagsToRemove: TaxonomyTag[]): TaxonomyTag[] {
  let updatedTags: TaxonomyTag[] = tags.filter((tag: TaxonomyTag) => !tagsToRemove.includes(tag))
  if (!updatedTags.includes(tagToAdd)) {
    updatedTags.push(tagToAdd)
  }
  
  return updatedTags
}


// Array extension ("declare global" then "Array.prototype.xxx=")
// see: https://stackoverflow.com/questions/12802383/extending-array-in-typescript

declare global {
  interface Array<T> {
    isContainingAtLeastOneItemAmong(itemsToContain: T[]): boolean;
  }
}
Array.prototype.isContainingAtLeastOneItemAmong = function <T>(itemsToContain: T[]): boolean {
  return isReferenceArrayContainingAtLeastOneItemInSeekedArray(this, itemsToContain)
}


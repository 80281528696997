<script lang="ts">
    import { createEventDispatcher, onMount, tick } from 'svelte'
    import { scale } from 'svelte/transition'
    import type { Contact } from '../../../crm-app/models/contact'

    const dispatch = createEventDispatcher()

    /** Set current selected value */
    export let selectedContact: Contact

    /** Set list of customer contacts value */
    export let customerContacts: Contact[]

    /** Set menu state */
    let show = false

    /** Set menu wrapper DOM ref */
    let menu = null

    $: if (selectedContact) {
      tick().then(() => {
        dispatch('selectedContacts', selectedContact)
      })
    }


    onMount(() => {
      const handleOutsideClick = (event: Event) => {
        if (show && !menu.contains(event.target)) {
          show = false
        }
      }

      const handleEscape = (event: KeyboardEvent) => {
        if (show && event.key === 'Escape') {
          show = false
        }
      }

      // add events when element is added to the DOM
      document.addEventListener('click', handleOutsideClick, false)
      document.addEventListener('keyup', handleEscape, false)

      // remove events when element is removed from the DOM
      return () => {
        document.removeEventListener('click', handleOutsideClick, false)
        document.removeEventListener('keyup', handleEscape, false)
      }
    })
</script>

<div class="relative cursor-pointer" bind:this={menu}>
    <div
            class="w-full h-12 flex items-center justify-between rounded border border-athensGray px-2 py-2 overflow-hidden"
            role="button"
            tabindex="0"
            on:click|preventDefault|stopPropagation={() => (show = !show)}
            on:keydown|preventDefault|stopPropagation={(e) => {
              if (e.key === 'Enter') {
                show = !show
              }
            }}
    >
        {#if !!selectedContact}
            <div class="flex items-center">
                <div class="contact-avatar w-6 h-6">{selectedContact?.firstName?.substring(0, 1).toUpperCase()}{selectedContact?.lastName?.substring(0, 1).toUpperCase()}</div>
                <div class="flex flex-col justify-start ml-2">
                    <h6 class="text-xxs font-bold">{selectedContact?.firstName || ''} {selectedContact?.lastName || ''}</h6>
                    <p class="text-xxs text-paleSky">{selectedContact?.email}</p>
                </div>
            </div>
        {:else}
            <div class="flex justify-center items-center text-gray-700">
                <p class="text-s ">Veuillez sélectionner un contact</p>
            </div>
        {/if}
        <div class="ml-2 flex justify-center items-center text-gray-700">
            <svg class="fill-current h-4 w-4 {show ? 'rotate-180 transition duration-200 ease-in-out transform' : 'transition duration-200 ease-in-out'}"
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
            </svg>
        </div>
    </div>

    {#if show}
        <div in:scale={{ duration: 100, start: 0.95 }}
             out:scale={{ duration: 75, start: 0.95 }}
             class="origin-top-right absolute right-0 w-full px-2 py-2 mt-1 bg-white border border-athensGray rounded shadow-md"
             style="z-index: 99999;">
            {#each customerContacts as contact}
                <div class="flex items-center w-full px-2 py-2 text-sm leading-5 text-left rounded hover:bg-whisper"
                     role="button"
                     tabindex="0"
                     on:click|preventDefault|stopPropagation={() => {
                       show = !show
                       selectedContact = contact
                       /* console.log('selectedContactChanged', contact) */
                     }}
                     on:keydown|preventDefault|stopPropagation={(e) => {
                       if (e.key === 'Enter') {
                         show = !show
                         selectedContact = contact
                         /* console.log('selectedContactChanged', contact) */
                       }
                     }}
                >
                    <div class="contact-avatar w-6 h-6">{contact?.firstName?.substring(0, 1).toUpperCase()}{contact?.lastName?.substring(0, 1).toUpperCase()}</div>
                    <div class="flex flex-col justify-start ml-2">
                        <h6 class="text-xxs font-bold">{contact?.firstName || ''} {contact?.lastName || ''}</h6>
                        <p class="text-xxs text-paleSky">{contact?.position}</p>
                    </div>
                </div>
            {/each}
        </div>
    {/if}
</div>

<style lang="postcss">
    .contact-avatar {
        @apply bg-dundyOrange rounded-full p-3 flex items-center align-middle justify-center font-medium text-xxs text-white;
    }
</style>

export class NavigationHistoryEvent {
  detail: NavigationHistoryItem
}
export class NavigationHistoryItem {
  relativeURL: string
}


export function NewNavigationHistoryItem(relativeURL: string): NavigationHistoryItem {
  return <NavigationHistoryItem>{ relativeURL: relativeURL }
}
<script lang="ts">
    import { getContext, onMount } from 'svelte'
    import { writable } from 'svelte/store'
    import { fade, blur, fly, slide } from 'svelte/transition'
    import type { TransitionParamTypes } from './types'
    import AccordionCtxType from './Accordion.svelte'

    export let open: boolean = false
    export let transitionType: string = 'slide'
    export let transitionParams: TransitionParamTypes = {}

    /**
     * Specify the title of the accordion item heading
     * Alternatively, use the "title" slot (e.g., <div slot="title">...</div>)
     */
    export let title: string = 'title'

    /** Set to display an icon */
    export let icon: string = ''

    /** Specify the ARIA label for the accordion item chevron icon */
    export let iconDescription: string = 'Expand/Collapse'

    /** Set to `true` to disable the accordion item */
    export const disabled: boolean = false

    export let urls: string[] = []

    let animation = undefined

    /** make a custom transition function that returns the desired transition */
    const multiple = (node: HTMLElement, params: any) => {
      switch (transitionType) {
        case 'blur':
          return blur(node, params)
        case 'fly':
          return fly(node, params)
        case 'fade':
          return fade(node, params)
        default:
          return slide(node, params)
      }
    }

    const ctx = getContext<AccordionCtxType>('ctx') ?? {}


    /**
     * If the parent accordion is single-select, then this is the selected item
      */
    const self = {}
    const selected = ctx.selected ?? writable()

    let _open: boolean = open
    open = false

    /**
     * Check if the current url matches any of the terms in the array
     * @param terms
     */
    function checkUrlForTerms(terms: any) {
      const currentUrl = window.location.pathname
      
      return !!terms.includes(currentUrl)
    }

    onMount(() => {
      const matchFound = checkUrlForTerms(urls)
      if (matchFound) {
        _open = true
      }

      if (_open) {
        $selected = self
      }
      // this will trigger unsubscribe on destroy
      // NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
      return selected.subscribe((x) => (open = x === self))
    })

    const handleToggle = (_: Event) => selected.set(open ? {} : self)

</script>

<li {...$$restProps}>
    <h2 aria-expanded={open} class="group">
        <button
                type="button"
                on:click={handleToggle}
                class="accordion__heading">

            <i class="icon-chevron-down text-3xl text-white accordion__arrow {open ? 'rotate-180' : '' }"
               aria-label="{iconDescription}"/>


            <span class:accordion__title="{true}">
                <slot name="title">{title}</slot>
            </span>

            <span class:accordion__icon="{true}">
                <slot name="icon">{@html icon}</slot>
            </span>
        </button>
    </h2>
    {#if open}
        <div transition:multiple={transitionParams}>
            <div>
                <slot/>
            </div>
        </div>
    {/if}
</li>
<style lang="postcss">
    .accordion__heading {
        @apply flex rounded-md bg-none relative w-full text-white cursor-pointer items-center justify-between appearance-none py-1 px-3 hover:bg-rhino border-t border-transparent hover:border-warmGray hover:border-opacity-10;
        flex-direction: row-reverse;
        transition: all .1s ease-in-out;
    }

    .accordion__heading:hover .accordion__icon {
        @apply text-white;
    }

    .accordion__title {
        @apply w-full text-sm pl-2 text-left z-10;
    }

    .accordion__icon {
        @apply flex text-zinc-500;
    }

    .accordion__arrow {
        transition: all .11s cubic-bezier(.2, 0, .38, .9);
    }
</style>
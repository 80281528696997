import { t } from '../lib/i18n/i18nextWrapper'


/**
 * Get the default currency according to the user's country code
 * @param countryCode
 */
export function getDefaultCurrencyAccordingToCountry(countryCode: string): string {
  switch (countryCode) {
    case 'FR':
      return 'EUR'
    case 'US':
      return 'USD'
    default:
      return 'EUR'
  }
}

/**
 * Get the list of supported currencies
 */
export function getSupportedCurrenciesList() {
  return [
    {
      label: t('supportedCurrenciesList.euroLabel'),
      value: 'EUR'
    },
    {
      label: t('supportedCurrenciesList.dollarLabel'),
      value: 'USD'
    },
    {
      label: t('supportedCurrenciesList.poundLabel'),
      value: 'GBP'
    },
    {
      label: t('supportedCurrenciesList.swissFrancLabel'),
      value: 'CHF'
    },
    {
      label: t('supportedCurrenciesList.canadianDollarLabel'),
      value: 'CAD'
    },
    {
      label: t('supportedCurrenciesList.australianDollarLabel'),
      value: 'AUD'
    },
    {
      label: t('supportedCurrenciesList.newZealandDollarLabel'),
      value: 'NZD'
    }
  ]
}
import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
import { agGridCommonService } from '../../../../dundy-app/services/ag-grid-common.service'
import type { GetLocaleTextParams, GridOptions, RowClassParams } from 'ag-grid-community'
import { BusinessDocumentStatus } from '../../../enums/business-document-status'

export const invoicesOptions: GridOptions<GridOptions> = {
  defaultColDef: {
    flex: 1,
    resizable: false,
    headerCheckboxSelection: agGridCommonService.isFirstColumn,
    checkboxSelection: agGridCommonService.isFirstColumn
  },
  rowHeight: 64,
  domLayout: 'autoHeight', // autoHeight or normal (fixed)
  rowClassRules: { // add class depending on conditions
    'voxy-invoice-row-deleted': (params: RowClassParams<GridOptions<any>, any>) => params.api.getValue('deleted', params.node),
    'voxy-invoice-row-active': (params: RowClassParams<GridOptions<any>, any>) => !params.api.getValue('deleted', params.node),
    'voxy-invoice-row-final': (params: RowClassParams<GridOptions<any>, any>): boolean => params.api.getValue('businessDocument.businessDocumentStatus', params.node) === BusinessDocumentStatus.FINAL,
    'voxy-invoice-row-draft': (params: RowClassParams<GridOptions<any>, any>): boolean => params.api.getValue('businessDocument.businessDocumentStatus', params.node) === BusinessDocumentStatus.DRAFT,
    'voxy-invoice-row-disabled': (params: RowClassParams<GridOptions<any>, any>): boolean => {
      if (params.data?.collateralData?.savingStateExpiration === 0) return false

      return params.data?.collateralData?.savingStateExpiration >= Math.floor(new Date().getTime() / 1000)
    }
  },
  singleClickEdit: false,
  suppressClickEdit: false,
  suppressRowClickSelection: true,
  stopEditingWhenCellsLoseFocus: true,
  suppressRowHoverHighlight: true,
  debug: true,
  rowSelection: 'multiple',
  enableRangeSelection: false,
  pagination: true,
  paginationPageSize: 50,
  suppressPaginationPanel: true,
  getLocaleText: (params: GetLocaleTextParams<GridOptions>): string => {
    // Look up the translation using i18next
    const translation = t(`agGrid.${params.key}`)

    return translation === `agGrid.${params.key}` ? params.key : translation
  }
}
<script lang="ts">
    export let existingFileURL: string = 'https://thumbs.dreamstime.com/b/amazon-logo-editorial-vector-illustration-market-136495269.jpg'
    export const existingFileName: string = 'amazon-logo-editorial-vector-illustration-market-136495269.jpg'
    export const existingFileSizeBytes: number = 14294
    export const existingFileLastModifiedUnixMilliseconds: number = 1672502195 * 1000
    export let newChosenUploadedFileBase64: string | ArrayBuffer
    export const newChosenUploadedFileMIMEContentType: string = ''
    export const newChosenUploadedOriginalFileName: string = ''
    export const newChosenUploadedOriginalFileSizeBytes: number = 0
    export const newChosenUploadedOriginalFileLastModifiedUnixMilliseconds: number = 0
</script>
<!-- show raw data debug info -->
<div class="mt-4 w-96 overflow-hidden">
    <div class="space-y-4 space-x-3 items-start py-3">
        <div class="">
            <div>newChosenUploadedFileBase64</div>
            <div>{typeof newChosenUploadedFileBase64}</div>
            <div>{ newChosenUploadedFileBase64}</div>
            <div>existingFileURL</div>
            <div>{typeof existingFileURL}</div>
            <div>{ existingFileURL}</div>
        </div>
    </div>
</div>

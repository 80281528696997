<script lang="ts">
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
    import { fade } from 'svelte/transition'
    import { getVATRates } from '../../../models/tax-rate-list'
    import Select from 'svelte-select'
    import { getUnitTypes, UnitType } from '../../../services/unit-types-list'
    import {
      formatCurrencyWithOptions, hasMoreThanTwoDecimals, removeDecimalsBeyondHundredth
    } from '../../../utils/number-formatting-utils.js'
    import { getDefaultUnitType } from '../../../services/unit-types-list.js'
    import StyledSelect from '../../../../core-app/lib/ui-kit/StyledSelect.svelte'
    import { BusinessDocumentLineItem } from '../../../models/business-document'
    import NumberInput from '../../../../core-app/lib/ui-kit/NumberInput.svelte'
    import HTMLEditor from '../../../../core-app/lib/ui-kit/HTMLEditor/HTMLEditor.svelte'
    import BusinessDocumentFormItemTitle from './BusinessDocumentFormItemTitle.svelte'
    import { deepClone } from '../../../../core-app/util/object-deep-cloning.js'
    import BusinessDocumentFormItemSupplierReference from './BusinessDocumentFormItemSupplierReference.svelte'
    import { TaxRate } from '../../../models/tax-rate'

    /** Export Declarations */
    export let totalItemsLength: number
    export let item: BusinessDocumentLineItem
    export let favoriteBusinessDocumentLineItems: BusinessDocumentLineItem[]
    export let removeRow: Function
    export let index: number
    export let isLocked: boolean

    /** Load VAT rates List */
    const vatRatesList: TaxRate[] = getVATRates()

    /** Load Unit Types List */
    const unitTypesList: UnitType[] = getUnitTypes()

    /** Configuration For Bare Select */
    const optionIdentifier: string = 'percentage'
    const labelIdentifier: string = 'displayName'
    let indicator: string = '<i class=\'icon-chevron-down text-2xl text-black\'/>'
    let showIndicator: boolean = true

    /**
     * Handle Item Title Update From Event Dispatcher
     * @param selectedItem
     */
    function updateItemFromTitleSelection(selectedItem: BusinessDocumentLineItem) {
      item = deepClone(selectedItem)
    }

    /**
     * Handle Select Change & Update Item
     * @param index
     * @param item
     */
    function handleSelectChange(index: number, item: BusinessDocumentLineItem): void {
      item.taxRate = item.taxRate
    }

    /**
     * Handle HTML Editor Change
     * @param html
     */
    const handleBodyChange = (html: string) => {
      item.description = html
    }

    /** Reactive declarations */
    $: if (hasMoreThanTwoDecimals(item.itemPrice.scaledValue)) {

      item.itemPrice.scaledValue = parseFloat(String(removeDecimalsBeyondHundredth(item.itemPrice.scaledValue)))
    }

    $: if (item?.itemPrice.unit === '' || item?.itemPrice.unit === null) {
      item.itemPrice.unit = getDefaultUnitType().value
    }
    $: item.lineItemTotalExcludingTaxScaledValue = item?.quantity * item?.itemPrice.scaledValue
    $: item.taxScaledValue = (item?.lineItemTotalExcludingTaxScaledValue * item?.taxRate.percentage) / 100
    $: item.lineItemTotalIncludingTaxScaledValue = item?.lineItemTotalExcludingTaxScaledValue + (item?.lineItemTotalExcludingTaxScaledValue * item?.taxRate.percentage) / 100

    /*$: console.log(isLocked, 'isLocked', item.businessDocumentLineItemId);*/

</script>

<section class="relative bg-whisper border border-athensGray rounded py-3 px-4 my-4" transition:fade>
    <div class="flex justify-between mx-1.5 my-4">
        <h4 class="font-medium">{t('invoices.createInvoice.itemHeader', { count: index + 1 }) }</h4>
        {#if isLocked}
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                      d="M5.75 11.75C5.75 11.1977 6.19772 10.75 6.75 10.75H17.25C17.8023 10.75 18.25 11.1977 18.25 11.75V17.25C18.25 18.3546 17.3546 19.25 16.25 19.25H7.75C6.64543 19.25 5.75 18.3546 5.75 17.25V11.75Z"></path>
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                      d="M7.75 10.5V10.3427C7.75 8.78147 7.65607 7.04125 8.74646 5.9239C9.36829 5.2867 10.3745 4.75 12 4.75C13.6255 4.75 14.6317 5.2867 15.2535 5.9239C16.3439 7.04125 16.25 8.78147 16.25 10.3427V10.5"></path>
            </svg>

        {:else}
            <div id="item-{index + 1}"
                 class="tooltip-target"
                 aria-describedby="item-{index + 1}-tooltip"
                 aria-expanded="false">
                {#if totalItemsLength > 1}
            <span on:click={() => { if (!isLocked) {removeRow(item?.businessDocumentLineItemId)} }}
                  class="cursor-pointer">
                <svg class="h-6 w-6" class:deactivated={isLocked} viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.75 7.75L6.59115 17.4233C6.68102 18.4568 7.54622 19.25 8.58363 19.25H14.4164C15.4538 19.25 16.319 18.4568 16.4088 17.4233L17.25 7.75H5.75Z"
                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path d="M9.75 10.75V16.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path d="M13.25 10.75V16.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path d="M8.75 7.75V6.75C8.75 5.64543 9.64543 4.75 10.75 4.75H12.25C13.3546 4.75 14.25 5.64543 14.25 6.75V7.75"
                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path d="M4.75 7.75H18.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                </svg>
            </span>
                {/if}
            </div>
        {/if}
    </div>
    <div class="flex py-2">
        {#if isLocked}
            <div class="flex flex-1 w-96 px-1 flex-col pr-1">
                <label class="block text-sm font-normal text-black mb-1">{t('invoices.createInvoice.itemTitle')}</label>
                <div class="relative">
                    <input type="text"
                           class="block w-full rounded-md h-[40px] border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                           placeholder={item.title} disabled/>
                </div>
            </div>
        {:else}
            <BusinessDocumentFormItemTitle
                    label={t('invoices.createInvoice.itemTitle')}
                    placeholder={t('invoices.createInvoice.itemTitlePlaceholder')}
                    bind:item={item}
                    favoriteBusinessDocumentLineItems={favoriteBusinessDocumentLineItems}
                    on:selectItem={(e) => {updateItemFromTitleSelection(e.detail.item)}}
            />
        {/if}

    </div>
    <div class="flex py-2">
        <div class="flex flex-1 w-96 px-1 flex-col pr-1">
            <label class="block text-sm font-normal text-black mb-1">{t('invoices.createInvoice.itemDescription')}</label>
            {#if isLocked}
                <div class="block bg-white w-full rounded-md border-0 min-h-[180px] px-3 py-1.5 text-zinc-400 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    {@html item.description}
                </div>
            {:else}
                <HTMLEditor
                        dataCy="business-document-item-description"
                        id={`description_${index}`}
                        html={item.description}
                        onchange={handleBodyChange}
                        focus_body_onload={false}
                        show_editor_toolbar={false}/>
            {/if}
        </div>
    </div>
    <div class="flex py-2">
        {#if isLocked}
            <div class="flex flex-1 w-96 px-1 flex-col pr-1">
                <label class="block text-sm font-normal text-black mb-1">{t('invoices.createInvoice.itemSupplierReference')}</label>
                <div class="relative">
                    <input type="text"
                           class="block w-full rounded-md h-[40px] border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                           placeholder={item.supplierReference} disabled/>
                </div>
            </div>
        {:else}
            <BusinessDocumentFormItemSupplierReference
                    label={t('invoices.createInvoice.itemSupplierReference')}
                    optional={t('invoices.createInvoice.itemOptional')}
                    placeholder={t('invoices.createInvoice.itemSupplierReferencePlaceholder')}
                    bind:item={item}
                    favoriteBusinessDocumentLineItems={favoriteBusinessDocumentLineItems}
                    on:selectItem={(e) => {updateItemFromTitleSelection(e.detail.item)}}
            />
        {/if}
    </div>
    <div class="flex py-2">
        <div class="flex flex-1 px-1 flex-col pr-1 w-56">
            {#if isLocked}
                <label class="block text-sm font-normal text-black">{t('invoices.createInvoice.unitPriceNoTaxes')}</label>
                <div class="relative mt-1.5 rounded-md shadow-sm">
                    <input type="text" name="price" id="price"
                           class="invoice-input"
                           placeholder={item.itemPrice.scaledValue.toString()}
                           disabled
                           aria-describedby="price-currency">
                    <div class="invoice-input-prefix-wrapper">
                        <span class="text-zinc-600 sm:text-xs" id="price-currency">{item?.itemPrice.currency}</span>
                    </div>
                </div>
            {:else}
                <NumberInput
                        showPrefix={true}
                        prefixLabel={item?.itemPrice.currency}
                        label={t('invoices.createInvoice.unitPriceNoTaxes')}
                        bind:value={item.itemPrice.scaledValue}
                        placeholder="0,00"
                        showValidationTick={false}
                        id={`unitPrice_${index}`}
                        type="number"/>
            {/if}

        </div>
        <div class="flex px-1 flex-col pr-1 w-28">
            <label
                    class="block text-sm font-normal text-black">{t('invoices.createInvoice.unitTypeLabel')}</label>
            <div class="mt-1.5 small-select shadow-sm h-10">
                {#if isLocked}
                    <input type="text"
                           class="block w-full rounded-md h-[40px] border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                           placeholder={getDefaultUnitType().label} disabled/>
                {:else}
                    <StyledSelect
                            bind:value={item.itemPrice.unit}
                            isSearchable={true}
                            items={unitTypesList}
                            placeholder={getDefaultUnitType().label}
                            label=""
                            disabled={false}
                            selected=""
                            on:input={() => handleSelectChange(index, item)}
                    />
                {/if}
            </div>
        </div>

        <div class="custom-number-input px-1 h-10 w-32">
            <label for="{`units_${index}`}"
                   class="block text-sm font-normal text-black">{t('invoices.createInvoice.quantity')}</label>

            {#if isLocked}
                <div class="flex flex-row h-10 w-full  relative bg-transparent mt-1.5 ">
                    <button data-action="decrement"
                            disabled
                            class="relative inline-flex items-center rounded-l-md border border-loblolly bg-white text-zinc-400 px-2 py-1 text-sm font-medium hover:text-black hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-dundyOrange focus:border-transparent">
                        <span class="sr-only">Decrement</span>
                        <span class="m-auto text-2xl font-thin">−</span>
                    </button>
                    <input
                            type="number"
                            min="1"
                            placeholder={item.quantity.toString()}
                            class="invoice-input-middle -ml-px"
                            name={`units_${index}`}/>
                    <button data-action="increment"
                            disabled
                            class="relative -ml-px inline-flex items-center rounded-r-md border border-loblolly bg-white text-zinc-400 px-2 py-1 text-sm font-medium hover:text-black hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-dundyOrange focus:border-transparent">
                        <span class="sr-only">Increment</span>
                        <span class="m-auto text-2xl font-thin">+</span>
                    </button>
                </div>
            {:else}
                <div class="flex flex-row h-10 w-full  relative bg-transparent mt-1.5 ">
                    <button data-action="decrement"
                            on:click={() => {
                              if (item.quantity > 1) {
                                item.quantity--
                              }
                            }}
                            class="relative inline-flex items-center rounded-l-md border border-loblolly bg-white text-black px-2 py-1 text-sm font-medium hover:text-black hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-dundyOrange focus:border-transparent">
                        <span class="sr-only">Decrement</span>
                        <span class="m-auto text-2xl font-thin">−</span>
                    </button>
                    <input id={`units_${index}`}
                           type="number"
                           min="1"
                           bind:value={item.quantity}
                           placeholder="1"
                           class="invoice-input-middle -ml-px"
                           name={`units_${index}`}/>
                    <button data-action="increment"
                            on:click={() => {
                              item.quantity++
                            }}
                            class="relative -ml-px inline-flex items-center rounded-r-md border border-loblolly bg-white text-black px-2 py-1 text-sm font-medium hover:text-black hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-dundyOrange focus:border-transparent">
                        <span class="sr-only">Increment</span>
                        <span class="m-auto text-2xl font-thin">+</span>
                    </button>
                </div>
            {/if}
        </div>
    </div>
    <div class="flex py-2 items-center">
        <div class="px-1 w-32">
            <label class="block text-sm font-normal text-black">{t('invoices.createInvoice.vatRate')}</label>
            <div class="mt-1.5 small-select shadow-sm">
                {#if isLocked}
                    <input type="text"
                           class="block w-full rounded-md h-[40px] border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                           placeholder={item.taxRate.percentage + '%'}
                           disabled/>
                {:else}
                    <Select
                            ChevronIcon={indicator}
                            clearable={false}
                            searchable={true}
                            items={vatRatesList}
                            showChevron={showIndicator}
                            itemId={optionIdentifier}
                            label={labelIdentifier}
                            on:input={() => handleSelectChange(index, item)}
                            placeholder="0%"
                            bind:value={item.taxRate}
                    />
                {/if}
            </div>

        </div>

        <div class="flex px-1 mt-6 justify-end items-center">
            <p class="text-sm">{formatCurrencyWithOptions(item?.taxScaledValue, t('locales'), item?.itemPrice.currency, true, 'symbol')}</p>
        </div>

        <div class="flex px-1 mt-6 flex-1 justify-end items-center">
            <p class="font-normal text-xxs mr-1 pr-2">{t('invoices.createInvoice.totalPriceNoTaxLabel')}</p>
            <p class="text-2xl "
               style="height: 38px; border-radius: 5px;">
                {formatCurrencyWithOptions(item?.lineItemTotalExcludingTaxScaledValue, t('locales'), item?.itemPrice.currency, true, 'symbol')}
            </p>
        </div>
    </div>
</section>

<style lang="postcss">
    .invoice-input {
        @apply border-t border-b border-r border-loblolly box-border bg-white focus:ring-2 focus:ring-dundyOrange focus:border-transparent text-s h-10 px-3 py-2 w-full;
        border-radius: 5px;
    }

    .invoice-input-middle {
        @apply border border-loblolly box-border bg-white focus:ring-2 focus:ring-dundyOrange focus:border-transparent h-10 py-2 px-0 items-center justify-center text-center w-full;
        -moz-appearance: textfield;
    }

    .invoice-input-middle::-webkit-outer-spin-button,
    .invoice-input-middle::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .invoice-input-prefix-wrapper {
        @apply pointer-events-none absolute right-0 top-0 flex items-center px-2.5 overflow-hidden border-l border-loblolly h-10;
        border-radius: 0 5px 5px 0;
        background: #e1e1;
    }

    .small-select.shadow-sm {
      box-shadow: none;
    }

    .deactivated {
        @apply opacity-30;
    }
</style>

import type { Contact } from '../../models/contact'
import { ContactsStore } from '../../stores/contacts.store'
import type { Customer } from '../../models/customer'

/**
 * Function to update an existing contact
 * @param existingContact
 * @param contactsStore
 */
export function updateExistingContact(existingContact: Contact, contactsStore: Contact[]): Promise<void> {
  return new Promise<void>((resolve, reject): void => {
    try {
      const updatedContactsStore: Contact[] = contactsStore.map((contact: Contact): Contact =>
        existingContact.contactId === contact.contactId ? existingContact : contact,
      )
      ContactsStore.set(updatedContactsStore)
      resolve()
    } catch (err) {
      reject(err)
    }
  })
}


/**
 * Function to add a new contact
 * @param newContact
 * @param localCustomer
 * @param contactsStore
 */
export function addNewContact(newContact: Contact, localCustomer: Customer, contactsStore: Contact[]): Promise<void> {
  return new Promise<void>((resolve, reject): void => {
    try {
      newContact.companyId = localCustomer.company.companyId
      contactsStore.push(newContact)
      ContactsStore.set(contactsStore)
      resolve()
    } catch (err) {
      reject(err)
    }
  })
}

/**
 * Delete Contact
 * @param contact
 */
export function deleteContactFromContactsStore(contact: Contact): Promise<void> {
  return new Promise<void>((resolve, reject): void => {
    try {
      ContactsStore.update((contacts: Contact[]) => {
        contacts.splice(contacts.findIndex(
          (c: Contact): boolean => c.contactId === contact.contactId), 1)
        
        return contacts
      })
      resolve()
    } catch (err) {
      reject(err)
    }
  })
}

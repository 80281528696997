<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { get } from 'svelte/store'
  import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
  import { ProfileStore } from '../../../core-app/stores/profile.store'
  import type { Contact } from '../../../crm-app/models/contact'
  import { WorkspaceStore } from '../../../crm-app/stores/workspace.store'
  import { substTiphaine } from '../../../voxy-app/services/specialOptalis/tiphaine.service.js'
  import type { EmailAttachment } from '../../models/email-attachment'
  import type Invoice from '../../models/invoice'
  import ContactsSearch from '../composer/ContactsSearch.svelte'
  import AttachmentsArea from '../dundy-file-mgt/AttachmentsArea.svelte'
  import TodoActionFillOutBankDetailsArea from '../todo/TodoActionFillOutBankDetailsArea.svelte'

  /** Export declarations **/
  export let invoice: Invoice
  export let renderedBodyMessage: any
  export let renderedSubjectMessage: any
  export let customerContacts: Array<Contact>
  export let allContacts: Array<Contact>
  export let selectedContact: Contact
  export let attachments: Array<EmailAttachment>
  export let showInvoiceAttachment: boolean

  /** Var & Const declarations **/
  const dispatch = createEventDispatcher()
  const dispatchBodyMessageReset = () => {
    dispatch('bodyMessageReset')
  }

  let allowContentEditable: boolean
  let companyId: string = get(WorkspaceStore).company.companyId

  let message = JSON.parse(JSON.stringify({
    from: <Contact[]>[],
    to: <Contact[]>[],
    cc: <Contact[]>[],
    bcc: <Contact[]>[],
    body: renderedBodyMessage ? renderedBodyMessage : '',
    subject: renderedSubjectMessage ? renderedSubjectMessage : '',
    send_at: <Date>null,
    file_ids: <string[]>[]
  }))

  const update = (key: string, value: unknown): void => {
    message = { ...message, [key]: value }
  }
  const handleContactsChange = (field: string) => (data: Contact[]): Promise<void> => {
    update(field, data)

    return Promise.resolve()
  }

  /** Reactive declarations **/
  $: allowContentEditable = !!($WorkspaceStore.bankConfig.selectedAccountBankIBAN && $WorkspaceStore.bankConfig.selectedAccountBankBIC && $WorkspaceStore.bankConfig.selectedAccountBankName && selectedContact)

</script>

<div class="my-6 px-4">
    <div class="flex items-center my-2 border-b border-whisper pb-2">
        <p class="w-1/12 text-s">{t('todoAction.fromLabel')}</p>
        <p class="w-11/12 text-s text-black">{substTiphaine(companyId, 'dunningMessageFromFullName', `${get(ProfileStore)?.firstName} ${get(ProfileStore).lastName} ${t('todoAction.emailFrom')} ${get(WorkspaceStore).company?.formalName}`)}
            <!--<span class="text-xxs text-paleSky">contact@dundy-icarus.info</span>-->
        </p>
    </div>

    {#if !$WorkspaceStore.bankConfig.selectedAccountBankIBAN}
        <TodoActionFillOutBankDetailsArea/>
    {/if}

    <div class="contacts-wrapper border-b border-whisper pb-2">
        <ContactsSearch
                dataCy="to-field"
                placeholder={t('todoAction.toLabel')}
                change={handleContactsChange('to')}
                contacts={customerContacts}
                on:selectedContacts={(e) => {dispatch('selectedContacts', e.detail)}}
                value={message.to}/>
    </div>

    <div class="contacts-wrapper border-b border-whisper pb-0">
        <ContactsSearch
                dataCy="cc-field"
                placeholder={t('todoAction.ccLabel')}
                change={handleContactsChange('cc')}
                contacts={allContacts}
                on:selectedContacts={(e) => {dispatch('selectedCcContacts', e.detail)}}
                value={message.cc}/>
    </div>

    <div class="contacts-wrapper border-b border-whisper pb-0">
        <ContactsSearch
                dataCy="bcc-field"
                placeholder={t('todoAction.bccLabel')}
                change={handleContactsChange('bcc')}
                contacts={allContacts}
                on:selectedContacts={(e) => {dispatch('selectedBccContacts', e.detail)}}
                value={message.bcc}/>
    </div>

    <div class="w-full flex items-center align-middle my-2 space-x-3">
        <p class="text-sm">{t('todoAction.subjectLabel')}</p>
        <div class="flex flex-1 items-center justify-between align-middle rounded relative">
            {#if allowContentEditable}
                <p class="w-full h-full px-2 py-2 text-s"
                   contenteditable=true
                   bind:innerHTML={renderedSubjectMessage}
                />
            {:else }
                <p class="w-full h-full px-2 py-2 text-s bg-whisper rounded"
                   contenteditable=false
                   bind:innerHTML={renderedSubjectMessage}></p>
            {/if}
        </div>
    </div>
    <div class="w-full mt-4 rounded border border-athensGray my-6 text-s relative">
        <button class="btn tiny absolute top-4 left-5"
                on:click={() => dispatchBodyMessageReset()}>
            <i class="icon-undo text-sm mr-1"></i> {t('todoAction.resetBodyMessage')}</button>

        {#if allowContentEditable}
            <div
                    class="w-full h-full px-8 pt-16 pb-6 {allowContentEditable ? 'edit-mode' : 'read-mode'}"
                    contenteditable=true
                    id="renderedBody"
                    bind:innerHTML={renderedBodyMessage}>
            </div>
        {:else }
            <div class="inline-flex items-center w-full bg-cabaret text-white py-3 px-3 absolute">
                <i class="icon-lock text-sm mr-1"></i>
                <p>{t('todoAction.contentLocked')}</p>
            </div>
            <div
                    class="w-full h-full px-8 pt-16 pb-6 {allowContentEditable ? 'edit-mode' : 'read-mode'}"
                    contenteditable=false
                    id="renderedBodyUnEditable"
                    bind:innerHTML={renderedBodyMessage}>
            </div>
        {/if}
    </div>
    <AttachmentsArea {invoice} bind:attachments={attachments} showAutomaticAttachments={showInvoiceAttachment}/>
</div>

<style lang="postcss">
    [contenteditable]:focus {
        @apply rounded;
        outline: 2px solid #f47663;
    }

    .contacts-wrapper {
        position: relative;
        text-decoration: none;
        @apply text-black;
    }
</style>
import { ExchangeDate } from '../../core-app/models/exchange-date'
import type { BankTransaction } from '../../cash-application-app/models/cash-application-declared-model'
import { NewAmountOfMoney } from '../../cash-application-app/models/amount-of-money-model'
import { JsonProperty } from '@paddls/ts-serializer'
import { BridgeUserModel } from "$bank/models/bbb-user-response";

// BBBItem is described in https://docs.bridgeapi.io/reference/list-items and in https://docs.bridgeapi.io/reference/item-resource
export class BBBItem {
  @JsonProperty('id') id: number
  // Status is described in https://docs.bridgeapi.io/docs/items-status
  @JsonProperty('status') status: number
  // Detailed on the Items status page (https://docs.bridgeapi.io/docs/items-status)
  @JsonProperty('status_code_info') status_code_info: string
  // Detailed on the Items status page (https://docs.bridgeapi.io/docs/items-status)
  @JsonProperty('status_code_description') status_code_description: string

  @JsonProperty('bank_id') bank_id: number
}

export class BBBItemsResp {
  @JsonProperty('ok') ok: boolean
  @JsonProperty('data') data: BBBItemsRespData
}

export class BBBItemsRespData {
  @JsonProperty('pagination') pagination: Pagination
  @JsonProperty('resources') resources: BBBItem[]
}

export class Pagination {
  @JsonProperty('next_uri') next_uri: string | undefined
}

import type { ICellRendererParams } from 'ag-grid-community'
import { EventType } from '../../../../../core-app/events/event-type'
import { eventsManager } from '../../../../../core-app/events/event-manager'
import { InvoicesHistoryItem } from '../../../../../dundy-app/models/invoices-history'
import type { DundyEvent } from '../../../../../dundy-app/events/dundy-event'

export default class DatatableCompanyAndInvoiceNumberRenderer {
  private params: ICellRendererParams
  private cellContent: HTMLElement
  public content: HTMLElement
  constructor() {}
  public init(params: ICellRendererParams) {

    const { data } = params
    const { toName, invoiceNumber } = data
    const customerInitial = toName ? toName.charAt(0) : ''

    this.cellContent = document.createElement('div')
    this.content = this.cellContent
    this.content.classList.add('cash-app-company-invoice')

    this.content.innerHTML = `
        <div class="flex flex-row w-full justify-start items-center">
            <div class="flex mx-auto items-center align-middle justify-center w-8 h-8 bg-black text-white rounded-md border border-solid border-black mr-3">
                <span class="text-white text-sm">${ customerInitial }</span>
            </div>
            <div class="flex flex-col leading-normal">
                <div class="font-medium text-gray-700 truncate">${ toName }</div>
                <div class="text-zinc-500 truncate">${ invoiceNumber }</div>
            </div>
        </div>
    `

    /* eventsManager.on(EventType.TRANSACTION_FULLY_CASH_APPLIED, (e: DundyEvent<any>) => {
/!*
             console.log(e, '+++ calling fetch cash application from CELL RENDERER ON TRANSACTION_FULLY_CASH_APPLIED');
*!/
            if (e.data) {
                this.content.style.background = '#F6F5F9';
                this.content.style.pointerEvents = 'none';
                const cells = this.content.querySelectorAll('.ag-cell');
                cells.forEach((cell: HTMLElement) => {
                    cell.style.color = '#C1C8D1';
                    cell.style.fontStyle = 'italic';
                });
            } else {
                this.content.style.background = 'none';
                this.content.style.pointerEvents = 'auto';
                const cells = this.content.querySelectorAll('.ag-cell');
                cells.forEach((cell: HTMLElement) => {
                    cell.style.color = 'inherit';
                    cell.style.fontStyle = 'normal';
                });
            }
        }, 'CashApplicationService');*/

  }
  public getGui() {
    return this.cellContent
  }
  public destroy() {}

}

<script lang="ts">
    import { t } from '../../../../../core-app/lib/i18n/i18nextWrapper'
    import type Invoice from '../../../../models/invoice'
    import { formatCurrencyWithOptions } from '../../../../../voxy-app/utils/number-formatting-utils.js'

    /** Export let */
    export let classes: string
    export let invoice: Invoice
</script>

<div class="flex flex-col {classes}">
    <h6 class="text-sm">
        {formatCurrencyWithOptions(invoice?.amountIncludingTaxes, t('locales'), invoice?.currency, true, 'symbol')}
    </h6>
</div>
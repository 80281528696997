
export interface ToolbarItem {
  title: string;
  state?: () => boolean;
  result: () => void;
  active?: boolean;
  icon?: string;
}

export interface ReplaceFields {
  from: string;
  to: string;
}

const queryCommandState = (command: string): boolean =>
  document.queryCommandState(command)
export const exec = (command: string, value?: string): boolean =>
  document.execCommand(command, false, value)

/*export const isCommandEnabled = (command: string): boolean => {
    const selection = window.getSelection();
    if (!selection) {
        return false;
    }
    return document.queryCommandEnabled(command);
};

export const executeCommand = (command: string, value?: string): boolean => {
    const selection = window.getSelection();
    if (!selection) {
        return false;
    }
    if (!isCommandEnabled(command)) {
        return false;
    }
    document.execCommand(command, false, value);
    return true;
};*/

export const defaultActions: ToolbarItem[] = [
  {
    title: 'Bold',
    state: (): boolean => queryCommandState('bold'),
    result: (): boolean => exec('bold'),
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '        <path d="M6.75 4.75H12.5C14.5711 4.75 16.25 6.42893 16.25 8.5C16.25 10.5711 14.5711 12.25 12.5 12.25H6.75V4.75Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '            <path d="M6.75 12.25H13.75C15.683 12.25 17.25 13.817 17.25 15.75C17.25 17.683 15.683 19.25 13.75 19.25H6.75V12.25Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '            </svg>'

  },
  {
    title: 'Italic',
    state: (): boolean => queryCommandState('italic'),
    result: (): boolean => exec('italic'),
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M14 4.75H11.75M14 4.75H16.25M14 4.75L10 19.25M10 19.25H7.75M10 19.25H12.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '</svg>'
  },
  {
    title: 'Underline',
    state: (): boolean => queryCommandState('underline'),
    result: (): boolean => exec('underline'),
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M4.75 19.25H19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '<path d="M16.25 4.75V11C16.25 13.3472 14.3472 15.25 12 15.25C9.65279 15.25 7.75 13.3472 7.75 11V4.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '</svg>'
  },
  {
    title: 'Justify Left',
    state: (): boolean => queryCommandState('justifyLeft'),
    result: (): boolean => exec('justifyLeft'),
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M4.75 5.75H14.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '<path d="M4.75 18.25H14.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '<path d="M4.75 12H19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '</svg>'
  },
  {
    title: 'Justify Right',
    state: (): boolean => queryCommandState('justifyRight'),
    result: (): boolean => exec('justifyRight'),
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M9.75 5.75H19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '<path d="M9.75 18.25H19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '<path d="M4.75 12H19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '</svg>'
  },
  {
    title: 'Justify Center',
    state: (): boolean => queryCommandState('justifyCenter'),
    result: (): boolean => exec('justifyCenter'),
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M7.75 5.75H16.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '<path d="M7.75 18.25H16.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '<path d="M4.75 12H19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '</svg>'
  },
  {
    title: 'Justify Full',
    state: (): boolean => queryCommandState('justifyFull'),
    result: (): boolean => exec('justifyFull'),
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M4.75 5.75H19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '<path d="M4.75 18.25H19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '<path d="M4.75 12H19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '</svg>'
  },
  {
    title: 'Ordered List',
    result: (): boolean => exec('insertOrderedList'),
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M4.75 6.25L6.25 4.75V10.25M6.25 10.25H4.75M6.25 10.25H7.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '<path d="M13.25 14.25H11.6562C10.9255 14.25 10.5182 13.4359 10.9058 12.8574C10.9535 12.7861 11.0211 12.7311 11.0925 12.6836L12.8924 11.486C12.9638 11.4384 13.0312 11.3832 13.0799 11.3126C13.5253 10.6678 13.0713 9.75 12.2526 9.75H10.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '<path d="M16.75 14.75H18.1964C19.4308 14.75 19.6499 16.6376 18.5101 16.9556C18.4549 16.971 18.397 16.9772 18.3398 16.9792L17.75 17L18.3398 17.0208C18.397 17.0228 18.4549 17.0289 18.5101 17.0444C19.6499 17.3624 19.4308 19.25 18.1964 19.25H16.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '</svg>'
  },
  {
    title: 'Unordered List',
    result: (): boolean => exec('insertUnorderedList'),
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M6.5 6C6.5 6.27614 6.27614 6.5 6 6.5C5.72386 6.5 5.5 6.27614 5.5 6C5.5 5.72386 5.72386 5.5 6 5.5C6.27614 5.5 6.5 5.72386 6.5 6Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '<path d="M6.5 12C6.5 12.2761 6.27614 12.5 6 12.5C5.72386 12.5 5.5 12.2761 5.5 12C5.5 11.7239 5.72386 11.5 6 11.5C6.27614 11.5 6.5 11.7239 6.5 12Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '<path d="M6.5 18C6.5 18.2761 6.27614 18.5 6 18.5C5.72386 18.5 5.5 18.2761 5.5 18C5.5 17.7239 5.72386 17.5 6 17.5C6.27614 17.5 6.5 17.7239 6.5 18Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '<path d="M9.75 6H18.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '<path d="M9.75 12H18.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '<path d="M9.75 18H18.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
            '</svg>'
  },
  {
    title: 'Link',
    result: (): boolean => {
      const url = prompt('Enter a URL:', 'https://')
      
      return exec('createLink', url || '')
    },
    icon: '<svg width="24" height="24" fill="none" viewBox="0 0 24 24">\n' +
            '<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16.75 13.25L18 12C19.6569 10.3431 19.6569 7.65685 18 6V6C16.3431 4.34315 13.6569 4.34315 12 6L10.75 7.25"></path>\n' +
            '<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7.25 10.75L6 12C4.34315 13.6569 4.34315 16.3431 6 18V18C7.65685 19.6569 10.3431 19.6569 12 18L13.25 16.75"></path>\n' +
            '<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.25 9.75L9.75 14.25"></path>\n' +
            '</svg>'
  }
]
<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import { get } from 'svelte/store'
    import { TodoType } from '../../enums/todo-type'
    import type { TodoListItem } from '../../models/todo-list-item'
    import type { Customer } from '../../../crm-app/models/customer'
    import Company from '../../../crm-app/models/company'
    import MailAddress from '../../../crm-app/models/mail-address'
    import type { Contact } from '../../../crm-app/models/contact'
    import { CustomersStore } from '../../../crm-app/stores/customers.store'
    import { ContactsStore } from '../../../crm-app/stores/contacts.store'

    /** Exported Var **/
    export let classes: string
    export let todo: TodoListItem

    /** Let declarations **/
    let customerId: string
    let thisCustomer: Customer
    let thisCustomerLabel: string
    let contacts: Contact[]
    let customersFirstContact: any

    /** Reactive declarations **/
    $: customerId = todo?.customerId
    $: thisCustomer = get(CustomersStore).find((customer: Customer) => customer.company.companyId === customerId) || <Customer>{ company: <Company>{ mailAddress: <MailAddress>{} } }
    $: thisCustomerLabel = thisCustomer?.company?.formalName
    $: contacts = $ContactsStore.filter(c => c.companyId === thisCustomer?.company?.companyId)
    $: customersFirstContact = (contacts && contacts.length && contacts[0].firstName) || 'Contact'
</script>

<div class="flex {classes}">
    <div class="flex w-6 mr-6">
        {#if todo.isDone}
            <div class="w-6 h-6">
                <i class="w-6 h-6 flex justify-center align-middle items-center text-center text-5xl icon-square-check text-dundyOrange"></i>
            </div>
        {:else}
            <div class="w-6 h-6">
                <span class="w-6 h-6 rounded-lg flex justify-center align-middle items-center text-center border-2 {todo.kind === TodoType.CASH_APPLICATION ? 'border-chateauGreen' : 'border-athensGray'} hover:border-dundyOrange focus:border-dundyOrange text-dundyOrange ">
                </span>
            </div>
        {/if}
    </div>
    <div class="flex">
        <h4 class="font-bold text-base ">
            {#if todo.kind === TodoType.CASH_APPLICATION}
                Confirm new payments
            {/if}
            {#if todo.kind === TodoType.DUNNING && customersFirstContact && todo.actionKind === 'InvoiceIssuance'}
                <p>{t('todo.item.naturalLanguageByMessageKind.InvoiceIssuance', {
                  contactName: customersFirstContact,
                  customerName: thisCustomerLabel,
                  invoiceNumbers: '\'' + todo.scopeInvoiceNumbers.join(' - ') + '\''
                })}</p>
            {:else if todo.kind === TodoType.DUNNING && customersFirstContact && todo.actionKind !== 'InvoiceIssuance'}
                <p>{t(`todo.item.naturalLanguageByMessageKind.${ todo.messageKind }`, {
                  contactName: customersFirstContact,
                  customerName: thisCustomerLabel,
                  invoiceNumbers: '\'' + todo.scopeInvoiceNumbers.join(' - ') + '\''
                })}</p>
            {/if}
        </h4>
    </div>
</div>
<script lang="ts">
    /** Export variables */

    export let isValidDSO: boolean
    export let dsoValue: number = 0
    export let minDsoValue: number = 0
    export let maxDsoValue: number = 100
    export let greenValue: number = 0
    export let redValue: number = 100

    /** Let declarations */
    let progressBarColor: string = ''
    let progressBarWidth: number = 0

    /** Reactive statements to update color and width whenever input variables change */
    $: if (dsoValue) {
      progressBarColor = calculateColor()
      progressBarWidth = calculateWidth()
    }

    $: if (!isValidDSO) {
      progressBarWidth = 0
    }

    /**
     * Define the colors of the progress bar
     */
    const greenColor = { r: 64, g: 255, b: 206 }
    const yellowColor = { r: 255, g: 230, b: 160 }
    const redColor = { r: 247, g: 115, b: 88 }

    /**
     * Calculate the color of the progress bar based on the dsoValue
     */
    function calculateColor() {
      const progress = (dsoValue - greenValue) / (redValue - greenValue)
      let r:number, g: number, b: number

      if (progress <= 0.4) {
        const subProgress = progress / 0.4
        r = Math.round(greenColor.r + subProgress * (yellowColor.r - greenColor.r))
        g = Math.round(greenColor.g + subProgress * (yellowColor.g - greenColor.g))
        b = Math.round(greenColor.b + subProgress * (yellowColor.b - greenColor.b))
      } else {
        const subProgress = (progress - 0.4) / 0.6
        r = Math.round(yellowColor.r + subProgress * (redColor.r - yellowColor.r))
        g = Math.round(yellowColor.g + subProgress * (redColor.g - yellowColor.g))
        b = Math.round(yellowColor.b + subProgress * (redColor.b - yellowColor.b))
      }

      return `rgba(${r},${g},${b},1)`
    }

    /**
     * Calculate the width of the progress bar based on the dsoValue
     */
    function calculateWidth() {
      const progress = (dsoValue - minDsoValue) / (maxDsoValue - minDsoValue)
      
      return Math.min(Math.max(progress * 100, 0), 100)
    }
</script>

<div class="progress-bar"
     class:invalid={!isValidDSO}>
    <div class="progress-value"
         style="width: {progressBarWidth}%; background-color: {progressBarColor}"
    ></div>
</div>

<style lang="postcss">
    .progress-bar {
        @apply bg-transparent border border-zinc-700 rounded-md overflow-hidden h-2.5 my-2;
    }

    .progress-bar.invalid {
        @apply bg-transparent;
    }

    .progress-value {
        height: 100%;
        transition: all 1s ease-in-out;
    }
</style>
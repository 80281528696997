import { BusinessDocument } from '../../models/business-document'
import { Decimal } from 'decimal.js'
import {
  roundScaledAmountToTheCentDecimal
} from '../taxes-excluding-and-including-calculus-using-decimal-js.pure-functions'
import {
  AmountDecimal,
  NewAmountOfMoneyWithDecimal
} from '$cash/models/amount-of-money-decimal-model'
import type { ComputedRemainingValues } from '../../models/installment-computed-remaining-values.model'
import type { ComputedInstallmentValues } from '../../models/installment-computed-accumulated-values.model'

/**
 * Calculate the accumulated Installments Sum and Percentage from related business documents with the same dealId -- NOT WORKING??????
 * @param originalBusinessDocumentTotalIncludingTaxScaledValue
 * @param allRelevantBusinessDocuments
 */
export function calculateCumulatedInvoicedInstallmentsIncludingTax(originalBusinessDocumentTotalIncludingTaxScaledValue: number, allRelevantBusinessDocuments: BusinessDocument[]): ComputedInstallmentValues {
  // Calculate the sum of installmentResultIncludingTaxScaledValue for the related business documents
  let sumOfAllRelevantInstallmentResultsIncludingTax: Decimal = new Decimal(0)
  for (let aRelevantDocument of allRelevantBusinessDocuments) {
    sumOfAllRelevantInstallmentResultsIncludingTax = sumOfAllRelevantInstallmentResultsIncludingTax
      .add(new Decimal(aRelevantDocument.installmentResultIncludingTaxScaledValue))
  }

  let accumulatedInstallmentsPercentage: Decimal
  if (Math.abs(originalBusinessDocumentTotalIncludingTaxScaledValue) < 0.0001) {
    accumulatedInstallmentsPercentage = new Decimal('999999999999999')
  } else {
    // Calculate the accumulated percentage
    accumulatedInstallmentsPercentage = roundScaledAmountToTheCentDecimal(sumOfAllRelevantInstallmentResultsIncludingTax.div(new Decimal(originalBusinessDocumentTotalIncludingTaxScaledValue)).mul(new Decimal(100)))
  }


  return <ComputedInstallmentValues>{
    accumulatedInstallmentsSum: sumOfAllRelevantInstallmentResultsIncludingTax,
    accumulatedInstallmentsPercentage: accumulatedInstallmentsPercentage
  }
}

/**
 * Calculate the remaining amount and percentage based on total due, installment total, and relevant related business documents
 * We use the scaled values for precise calculations and then convert them back to numbers
 * @param originalBusinessDocument
 * @param allRelevantBusinessDocuments
 */
export function calculateRemainingInstallmentIncludingTaxStillToInvoiceIncludingAllSpecifiedDocumentsAndCurrentDocument(originalBusinessDocument: BusinessDocument, allRelevantBusinessDocuments: BusinessDocument[]): ComputedRemainingValues {
  try {
    // Convert scaled values to AmountDecimal for precise calculations
    let totalIncludingTaxScaledValue: AmountDecimal = NewAmountOfMoneyWithDecimal(
      new Decimal(originalBusinessDocument.totalIncludingTaxScaledValue),
      new Decimal(2),
      originalBusinessDocument.currency,
    )
    let installmentResultIncludingTaxScaledValue: AmountDecimal = NewAmountOfMoneyWithDecimal(
      new Decimal(originalBusinessDocument.installmentResultIncludingTaxScaledValue),
      new Decimal(2),
      originalBusinessDocument.currency,
    )

    // Calculate the sum of installmentResultIncludingTaxScaledValue for the related business documents
    const relatedInstallmentSum: Decimal = allRelevantBusinessDocuments.reduce((sum, doc) => {
      if (doc.businessDocumentId === originalBusinessDocument.businessDocumentId) {
        return sum // skip
      } else {
        return sum.add(new Decimal(doc.installmentResultIncludingTaxScaledValue)) // add
      }
    }, new Decimal(0),
    )

    // Calculate the remaining amount
    const remainingAmount: Decimal = totalIncludingTaxScaledValue.GetAmountDecimalScaledValue()
      .minus(installmentResultIncludingTaxScaledValue.GetAmountDecimalScaledValue())
      .minus(relatedInstallmentSum)

    // Calculate the remaining percentage
    // todo totalSum was the bug: has to be totalIncludingTaxScaledValue only and not .plus(relatedInstallmentSum)
    const totalSum: Decimal = totalIncludingTaxScaledValue.GetAmountDecimalScaledValue()
    if (totalSum.abs().lessThanOrEqualTo(0.001)) {
      /* console.error('totalSum is zero, cannot div by 0') */

      return <ComputedRemainingValues>{
        remainingAmount: remainingAmount,
        remainingPercentage: new Decimal(999999999999)
      }
    }
    const remainingPercentage: Decimal = roundScaledAmountToTheCentDecimal(
      totalSum.abs().lessThanOrEqualTo(0.001) ? new Decimal(0) : remainingAmount.div(totalSum).times(100))

    // Convert Decimal back to JavaScript number
    return <ComputedRemainingValues>{
      remainingAmount: remainingAmount,
      remainingPercentage: remainingPercentage
    }
  } catch (e) {
    /* console.error(e) */

    return <ComputedRemainingValues>{
      remainingAmount: new Decimal(0),
      remainingPercentage: new Decimal(0)
    }
  }
}


export function isDealOpenToInvoicing(cumulated: ComputedInstallmentValues): boolean {
  return !isDealCancelled(cumulated) && !isDealCompletelyInvoiced(cumulated)
}

export function isDealCompletelyInvoiced(cumulated: ComputedInstallmentValues): boolean {
  return (cumulated.accumulatedInstallmentsPercentage).abs().greaterThanOrEqualTo(new Decimal(99.999))
}

export function isDealCancelled(cumulated: ComputedInstallmentValues): boolean {
  return (cumulated.accumulatedInstallmentsPercentage).abs().lessThanOrEqualTo(0.001)
}

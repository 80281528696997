export const bytesToHighestUnitDisplay = (bytes: number, decimalPlaces: number) => {
  if (bytes != null && decimalPlaces != null) {
    if (bytes === 0) {
      return '0'
    }
    if (bytes <= 1024) {
      return bytes + 'bytes'
    }
    let sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    let i = Math.floor(Math.log(bytes) / Math.log(1024))

    return (
      (bytes / Math.pow(1024, i)).toFixed(decimalPlaces) + ' ' + sizes[i]
    )
  }
}
export const encodeFileToBase64 = (file: File): Promise<string> => new Promise<string>((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    const fullReadFileWithBase64 = reader.result as string
    const searchForPattern = 'base64,'
    const base64OfFileContent = fullReadFileWithBase64.substring(fullReadFileWithBase64.indexOf(searchForPattern) + searchForPattern.length)
    resolve(base64OfFileContent)
  }
  reader.onerror = function (error) {
    reject(error)
  }
})
import type { BBBTransaction } from '$bank/models/bbb-transactions-model'
import type {
  PeriodDescription,
  ValueForCurrentMonthAlongWithVariationMonthToMonth
} from '$core/models/dashboard-statistics-analytics-model'
import {
  calculateVariationDirection,
  calculateVariationFavorability,
  calculateVariationMonthToMonth,
  isSameMonth,
  subMonths
} from '$core/services/dashboard-statistics-pure-functions'
import { AnalyticsElements } from '$core/enums/analytics-enums'


/**
 * Calculates cash burn for current month along with variation to previous month
 * Cash burn is defined as the sum of all transactions (both positive and negative) within the target month.
 * @param transactions
 * @param currentMonth
 */
export function calculateCashBurnForCurrentMonthAlongWithVariationToPreviousMonth(
  transactions: BBBTransaction[],
  currentMonth: Date,
): ValueForCurrentMonthAlongWithVariationMonthToMonth {

  // Validate transactions
  validateTransactions(transactions)

  const currentMonthCashBurn: number = Math.abs(calculateCashBurnForMonth(transactions, currentMonth))
  const previousMonth: Date = subMonths(currentMonth, 1)
  const previousMonthCashBurn: number = Math.abs(calculateCashBurnForMonth(transactions, previousMonth))
  const rawVariationMonthToMonth: number = calculateVariationMonthToMonth(
    currentMonthCashBurn,
    previousMonthCashBurn,
  ) ?? 0;

  let variationMonthToMonthInPercentWithPositiveOrNegativeSign: number
  if (previousMonthCashBurn === 0) {
    variationMonthToMonthInPercentWithPositiveOrNegativeSign = (currentMonthCashBurn === 0) ? 0 : 100
  } else {
    variationMonthToMonthInPercentWithPositiveOrNegativeSign = (rawVariationMonthToMonth / previousMonthCashBurn) * 100
  }

  const variationMonthToMonth: string = Intl.NumberFormat('en-US', { style: 'percent' }).format(variationMonthToMonthInPercentWithPositiveOrNegativeSign / 100)
  const variationDirection: string = calculateVariationDirection(rawVariationMonthToMonth)
  const variationFavorability: string = calculateVariationFavorability(rawVariationMonthToMonth, AnalyticsElements.CASHBURN)

  return {
    currentMonthValue: currentMonthCashBurn,
    previousMonthValue: previousMonthCashBurn,
    monthToMonthVariationPercentage: variationMonthToMonth,
    variationDirection: variationDirection,
    variationFavorability: variationFavorability
  }
}


/**
 * Calculates expenses for current month along with variation to previous month
 * @param transactions
 * @param currentMonth
 * @returns {ValueForCurrentMonthAlongWithVariationMonthToMonth}
 */
export function calculateExpensesForCurrentMonthAlongWithVariationToPreviousMonth(
  transactions: BBBTransaction[],
  currentMonth: Date,
): ValueForCurrentMonthAlongWithVariationMonthToMonth {

  // Validate transactions
  validateTransactions(transactions)

  const currentMonthTransactions: BBBTransaction[] = transactions.filter(
    (transaction: BBBTransaction) => isSameMonth(transaction.date, currentMonth),
  )
  const currentMonthExpenses: number = Math.abs(calculateExpensesForCurrentMonth(
    currentMonthTransactions, currentMonth,
  ))
  const previousMonth: Date = subMonths(currentMonth, 1)
  const previousMonthTransactions: BBBTransaction[] = transactions.filter(
    (transaction: BBBTransaction) => isSameMonth(transaction.date, previousMonth),
  )
  const previousMonthExpenses: number = Math.abs(calculateExpensesForCurrentMonth(
    previousMonthTransactions, previousMonth,
  ))
  const rawVariationMonthToMonth: number = calculateVariationMonthToMonth(
    currentMonthExpenses,
    previousMonthExpenses,
  ) ?? 0;

  // Calculate the variation in percent with positive or negative sign
  let variationMonthToMonthInPercentWithPositiveOrNegativeSign: number
  if (previousMonthExpenses === 0) {
    variationMonthToMonthInPercentWithPositiveOrNegativeSign = 0
  } else {
    variationMonthToMonthInPercentWithPositiveOrNegativeSign = rawVariationMonthToMonth / previousMonthExpenses * 100
  }
  // Calculate the variation direction
  const variationDirection: string = calculateVariationDirection(rawVariationMonthToMonth)
  const variationFavorability: string = calculateVariationFavorability(rawVariationMonthToMonth, AnalyticsElements.EXPENSES)


  return {
    currentMonthValue: currentMonthExpenses,
    previousMonthValue: previousMonthExpenses,
    monthToMonthVariationPercentage: variationMonthToMonthInPercentWithPositiveOrNegativeSign.toFixed(2) + '%',
    variationDirection: variationDirection,
    variationFavorability: variationFavorability

  }
}

/**
 * Calculates the revenue (cash entry) for a given month as well as the variation to the previous month.
 * @param transactions
 * @param currentMonth
 * @returns {ValueForCurrentMonthAlongWithVariationMonthToMonth}
 */
export function calculateRevenueForCurrentMonthAlongWithVariationToPreviousMonth(
  transactions: BBBTransaction[],
  currentMonth: Date,
): ValueForCurrentMonthAlongWithVariationMonthToMonth {

  // Validate transactions
  validateTransactions(transactions)

  const currentMonthTransactions: BBBTransaction[] = transactions.filter(
    (transaction: BBBTransaction) => isSameMonth(transaction.date, currentMonth),
  )
  const currentMonthRevenue: number = Math.abs(calculateRevenueForCurrentMonth(
    currentMonthTransactions, currentMonth,
  ))
  const previousMonth: Date = subMonths(currentMonth, 1)
  const previousMonthTransactions: BBBTransaction[] = transactions.filter(
    (transaction: BBBTransaction) => isSameMonth(transaction.date, previousMonth),
  )
  const previousMonthRevenue: number = Math.abs(calculateRevenueForCurrentMonth(
    previousMonthTransactions, previousMonth,
  ))
  const rawVariationMonthToMonth: number = calculateVariationMonthToMonth(
    currentMonthRevenue,
    previousMonthRevenue,
  ) ?? 0;

  // Calculate the variation in percent with positive or negative sign
  let variationMonthToMonthInPercentWithPositiveOrNegativeSign: number
  if (previousMonthRevenue === 0) {
    variationMonthToMonthInPercentWithPositiveOrNegativeSign = (currentMonthRevenue === 0) ? 0 : 100
  } else {
    variationMonthToMonthInPercentWithPositiveOrNegativeSign = (rawVariationMonthToMonth / previousMonthRevenue) * 100
  }

  const variationMonthToMonth: string = Intl.NumberFormat('en-US', { style: 'percent' }).format(variationMonthToMonthInPercentWithPositiveOrNegativeSign / 100)

  // Calculate the variation direction
  const variationDirection: string = calculateVariationDirection(rawVariationMonthToMonth)
  const variationFavorability: string = calculateVariationFavorability(rawVariationMonthToMonth, AnalyticsElements.REVENUE)

  return {
    currentMonthValue: currentMonthRevenue,
    previousMonthValue: previousMonthRevenue,
    monthToMonthVariationPercentage: variationMonthToMonth,
    variationDirection: variationDirection,
    variationFavorability: variationFavorability
  }
}


/**
 * Calculates the cash burn for a given month
 * @param transactions
 * @param targetMonth
 */
function calculateCashBurnForMonth(transactions: BBBTransaction[], targetMonth: Date): number {
  const filteredTransactions: BBBTransaction[] = transactions.filter(
    (transaction: BBBTransaction) => isSameMonth(transaction.date, targetMonth),
  )

  return filteredTransactions.reduce(
    (acc: number, transaction: BBBTransaction) => acc + transaction.amount, 0)
}

/**
 * Calculates the expenses for a given month
 * Filters negative transactions from start of month to end of month
 * @param transactions
 * @param currentMonth
 */
function calculateExpensesForCurrentMonth(transactions: BBBTransaction[], currentMonth: Date): number {

  // Validate transactions
  validateTransactions(transactions)

  const currentMonthNegativeTransactions: BBBTransaction[] = transactions.filter(
    (transaction: BBBTransaction) => {
      return transaction.amount < 0 && isSameMonth(transaction.date, currentMonth)
    },
  )

  const expenses: number = currentMonthNegativeTransactions.reduce(
    (acc: number, transaction: BBBTransaction) => acc + transaction.amount, 0)

  // returns a positive number to the UI
  return Math.abs(expenses)
}


/**
 * Calculate cash revenues for current month
 * Filters positive transactions from start of month to end of month
 * @param transactions
 * @param currentMonth
 */
function calculateRevenueForCurrentMonth(transactions: BBBTransaction[], currentMonth: Date): number {

  // Validate transactions
  validateTransactions(transactions)

  const currentMonthPositiveTransactions: BBBTransaction[] = transactions.filter(
    (transaction: BBBTransaction) => {
      return transaction.amount > 0 && isSameMonth(transaction.date, currentMonth)
    },
  )

  return currentMonthPositiveTransactions.reduce(
    (acc: number, transaction: BBBTransaction) => acc + transaction.amount, 0)
}

/**
 * Generate a period description object
 * Used to generate the period description in the UI
 * @param currentMonth
 */
export function generatePeriodDescription(currentMonth: Date): PeriodDescription {
  const currentDate: Date = new Date(currentMonth)
  const startDateCurrentPeriod: Date = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate() + 1)
  const endDatePreviousPeriod: Date = new Date(startDateCurrentPeriod.getFullYear(), startDateCurrentPeriod.getMonth(), startDateCurrentPeriod.getDate() - 1)
  const startDatePreviousPeriod: Date = subMonths(startDateCurrentPeriod, 1)

  return {
    startDateCurrentPeriod,
    endDateCurrentPeriod: currentDate,
    startDatePreviousPeriod,
    endDatePreviousPeriod
  }
}


/**
 * Return the most used currency in transactions as a symbol
 * Used to format amounts in the UI
 * Uses an object to count the occurrences of each currency and then finding the most used currency using the reduce method.
 * This ensures that vast amounts of transactions can be processed without performance issues.
 * @param transactions
 */
export function returnMostUsedCurrencyInTransactionsAsSymbol(transactions: BBBTransaction[]): string | null {

  // Validate transactions
  validateTransactions(transactions)

  // create an object to count the occurrences of each currency
  const currencyCount: { [key: string]: number } = {}

  // Count the occurrences of each currency
  transactions.forEach((transaction: BBBTransaction): void => {

    if (currencyCount[transaction.currency_code]) {
      currencyCount[transaction.currency_code]++
    } else {
      currencyCount[transaction.currency_code] = 1
    }
  })

  // Handle the edge case when there are no transactions
  if (transactions.length === 0) {
    return null
  }

  // Find the most used currency
  return Object.keys(currencyCount).reduce((a: string, b: string): string => currencyCount[a] > currencyCount[b] ? a : b)
}


/**
 * Calculate the cash balance for the current month
 * @param transactions
 */
export function calculateCashBalanceForCurrentMonth(transactions: BBBTransaction[]): number {
  return transactions.reduce((sum: number, transaction: BBBTransaction) => sum + transaction.amount, 0)
}

/**
 * Validates that transactions is an array of BBBTransaction objects
 * @param transactions
 */
function validateTransactions(transactions: BBBTransaction[]): void {
  if (!Array.isArray(transactions) || transactions.some((t: BBBTransaction): boolean => typeof t !== 'object')) {
    throw new Error('Invalid input: transactions should be an array of BBBTransaction objects')
  }
}
<script lang="ts">
  import {
    SignUpStoreEmail,
    SignUpStoreFirstName,
    SignUpStoreLastName,
    SignUpStorePassword
  } from '../../stores/signup.store'
  import { navigate } from 'svelte-routing'
  import { validateEmail, validatePasswordDetails } from '../../services/validator'
  import auth from '../../lib/auth0authentication/authService'
  import { t } from '../../lib/i18n/i18nextWrapper'
  import { Alert } from '../../stores/alert.store'
  import AlertError from '../../lib/ui-kit/Alert/AlertError.svelte'
  import { isEndToEndTest } from '../../stores/endToEndTest.store'
  import TextInput from '../../lib/ui-kit/TextInput.svelte'
  import { OnboardingStore } from '../../stores/onboarding.store'
  import { ErrorWithDescription, PasswordValidationDetails } from '../../models/errors-and-validation'
  import { OnboardingStep } from '../../enums/onboarding-steps'
  import type { SignUpFormStore } from '../../models/profile'
  import OnboardingLayout from '$core/lib/onboarding/OnboardingLayout.svelte'
  import { onMount } from 'svelte'
  import mixpanel from 'mixpanel-browser'

  /** Let declarations */
  let profile: SignUpFormStore = <SignUpFormStore>{
    firstName: $SignUpStoreFirstName,
    lastName: $SignUpStoreLastName,
    email: $SignUpStoreEmail,
    password: $SignUpStorePassword
  }
  let isEmailValid: boolean
  let isPasswordValid: boolean
  let validPasswordDetails: PasswordValidationDetails | null = null
  let canContinue: boolean

  /** Reactive declarations */
  $: isEmailValid = !profile.email || validateEmail(profile.email)
  $: validPasswordDetails = profile.password ? validatePasswordDetails(profile.password) : null
  $: isPasswordValid = !profile.password || Object.values(validPasswordDetails).every((v) => v === true)
  $: canContinue =
    !!(profile.email) && isEmailValid === true && profile.password && isPasswordValid
  $OnboardingStore.currentOnboardingStep = OnboardingStep.SIGNUP

  const css = {
    inject: (id: string, content: string): HTMLElement => {
      let el = document.createElement('style')
      el.id = id
      el.innerText = content
      document.head.appendChild(el)

      return el
    },
    remove: (id: string): boolean => {
      const el = document.getElementById(id)
      if (el) el.remove()

      return Boolean(el)
    }
  }

  /**
   * Handle click on continue button
   * - If the user is not valid, show an error
   * - If the user is valid, create the user and navigate to the next step
   */
  function handleClickContinue() {
    mixpanel.track('onb.sgn.B01.u onboardind signup next step', {
      'Description': 'Clic on next step button from SignUp.svelte'
    })

    auth.customSignUpWithFirstNameAndLastName(
      profile.email,
      profile.password,
      profile.firstName,
      profile.lastName,
      (err: ErrorWithDescription) => {
        if (!!err) {
          /* console.log(t('signUpForm.error'), err.description) */
          /* console.error(err) */
          // mettre unid au style injecté
          css.inject('tmp_alert', '.alert { position: absolute }')

          return Alert.open(AlertError, {
            header: t('signUpForm.error'),
            message: ''
          }, undefined, () => {
            css.remove('tmp_alert')
          })
        } else {
          return navigate('confirm')
        }
      },
      ($isEndToEndTest ? 'test user' : null),
    )
  }

  onMount(() => {
    mixpanel.track('onb.sgn.B00.i onboardind signup load', {
      'Description': 'Open SignUp.svelte'
    })
  })
</script>
<OnboardingLayout>
  <div slot="onboarding-sidebar">
    <div class="flex flex-col w-full text-center align-center">
      <div class="flex flex-col w-10/12 mx-auto">
        <div class="flex mt-32 justify-start">
          <span class="text-xl text-white">{t('onboarding.signup.sidebar.testimony')}</span>
        </div>
        <div class="w-6/12 mx-auto flex mt-8">
          <img alt="dundy logo" src="/img/day-mikes.svg" class="h-14"/>
          <div class="flex flex-col ml-4">
            <span class="text-base font-semibold text-white">{t('onboarding.signup.sidebar.name')}</span>
            <span class="text-sm text-paleSky">{t('onboarding.signup.sidebar.role')}</span>
          </div>
        </div>
      </div>
      <dl
        class="w-full px-10 mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-white sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <div class="flex flex-col gap-y-3">
          <dt class="text-sm leading-5 text-athensGray">{t('onboarding.signup.sidebar.firstKPI.description')}</dt>
          <dd class="order-first text-3xl font-semibold tracking-tight text-aquamarine">{t('onboarding.signup.sidebar.firstKPI.title')}</dd>
        </div>
        <div class="flex flex-col gap-y-3 border-l border-white/10 pl-6">
          <dt class="text-sm leading-5 text-athensGray">{t('onboarding.signup.sidebar.secondKPI.description')}</dt>
          <dd class="order-first text-3xl font-semibold tracking-tight text-aquamarine">{t('onboarding.signup.sidebar.secondKPI.title')}</dd>
        </div>
        <div class="flex flex-col gap-y-3 border-l border-white/10 pl-6">
          <dt class="text-sm leading-5 text-athensGray">{t('onboarding.signup.sidebar.thirdKPI.description')}</dt>
          <dd class="order-first text-3xl font-semibold tracking-tight text-aquamarine">{t('onboarding.signup.sidebar.thirdKPI.title')}</dd>
        </div>
      </dl>
    </div>
  </div>
  <div slot="onboarding-content">
    <section class="onboarding">
      <div>
        <h1>{t('onboarding.signup.main.title')}</h1>
        <form
          class="flex justify-center flex-col mt-10 w-80 max-w-sm mx-auto relative"
          data-cy="sign-up-form"
          on:submit|preventDefault={handleClickContinue}
        >
          <div class="flex justify-self-start mt-4">
            <span class="input-label">{t('onboarding.signup.main.form.email')}</span>
            <span class="mandatory-marker">*</span>
          </div>
          <TextInput
            bind:value={profile.email}
            class="w-full"
            dataCy="email"
            error={t('signUpForm.email.error')}
            placeholder={t('signUpForm.email.placeholder')}
            showError={isEmailValid !== true}
            type="email"
          />
          <div class="flex justify-self-start mt-4">
            <span class="input-label">{t('onboarding.signup.main.form.password')}</span>
            <span class="mandatory-marker">*</span>
          </div>
          <TextInput
            bind:value={profile.password}
            class="w-full"
            dataCy="password"
            error={t('signUpForm.password.error')}
            placeholder={t('signUpForm.password.placeholder')}
            showError={!isPasswordValid}
            type="password"
            displayTooltipOnFocus={true}
            displayTooltipAlways={false}
            tooltipWindowInjection={true}
          >
            <div slot="tooltip">
              <p class="font-medium">{t('signUpForm.note.title')}</p>
              <ul class="mt-4">
                <li class="mt-2">
                  <i
                    class="text-2xl icon-circle-checked align-middle {validPasswordDetails && validPasswordDetails.minimumChars ? 'text-black' : 'text-loblolly'}"></i>
                  <span class="align-middle">{t('signUpForm.note.one')}</span>
                </li>
                <li class="mt-2">
                  <i
                    class="text-2xl icon-circle-checked align-middle {validPasswordDetails && validPasswordDetails.upperCaseAndLoweCase ? 'text-black' : 'text-loblolly'}"></i>
                  <span class="align-middle">{t('signUpForm.note.two')}</span>
                </li>
                <li class="mt-2">
                  <i
                    class="text-2xl icon-circle-checked align-middle {validPasswordDetails && validPasswordDetails.oneDigit ? 'text-black' : 'text-loblolly'}"></i>
                  <span class="align-middle">{t('signUpForm.note.three')}</span>
                </li>
                <li class="mt-2">
                  <i
                    class="text-2xl icon-circle-checked align-middle {validPasswordDetails && validPasswordDetails.specialChar ? 'text-black' : 'text-loblolly'}"></i>
                  <span class="align-middle">{t('signUpForm.note.four')}</span>
                </li>
              </ul>
              <p class="mt-8">{t('onboarding.signup.main.form.passwordHint')}</p>
            </div>
          </TextInput>
          <span class="text-xs mt-8">
                  {t('signUpForm.footer.one')} <a class="text-dundyOrange" href="https://dundy.co/terms"
                                                  target="_blank">{t('signUpForm.footer.two')}</a>
            {t('signUpForm.footer.three')} <a class="text-dundyOrange" href="https://dundy.co/privacy"
                                              target="_blank"> {t('signUpForm.footer.four')}</a>.
                </span>

          <button type="submit" class="btn action-bla primary h-10 mt-10" disabled={!canContinue}>
            {t('onboarding.next')}
          </button>

          <span class="text-xs mt-12 text-baliHai"><sup> * </sup>{t('onboarding.signup.main.form.mandatory')}</span>
        </form>
      </div>
    </section>
  </div>
</OnboardingLayout>
<!--

<main class="flex">
    <svelte:component this={$Alert?.component} {...$Alert?.props} />
    <section
            class="w-3/12 justify-left relative max-w-lg p-10 pl-12 h-screen hidden md:block bg-gradient-to-t from-[#0d081e] to-[#152c3c] text-[#8CA3BE]">
        <div class="flex flex-col">
            <div class="flex mt-48 justify-start">
                <span class="text-base">{t('onboarding.signup.sidebar.testimony')}</span>
            </div>
            <div class="flex mt-8">
                <img alt="dundy logo" src="/img/day-mikes.svg" class="h-14"/>
                <div class="flex flex-col ml-4">
                    <span class="text-base font-semibold">{t('onboarding.signup.sidebar.name')}</span>
                    <span class="text-sm">{t('onboarding.signup.sidebar.role')}</span>
                </div>
            </div>
        </div>
    </section>
    <section class="flex w-full md:w-7/12 2xl:w-9/12">
        <div class="flex flex-col md:w-6/12 2xl:w-4/12 py-10 max-w-md mx-auto">
            <span class="text-3xl font-bold mb-8 text-center">{t('onboarding.signup.main.title')}</span>
            <div class="flex">
                <img alt="warning-indicator" src="/img/warning-sun.svg" class="h-8 mr-4"/>
                <span class="text-xs">{t('onboarding.signup.main.note')}</span>
            </div>
            <form
                    class="flex justify-center flex-col mt-10 w-80 max-w-sm mx-auto relative"
                    data-cy="sign-up-form"
                    on:submit|preventDefault={handleClickContinue}
            >
                <div class="flex justify-self-start mt-4">
                    <span class="input-label">{t('onboarding.signup.main.form.email')}</span>
                    <span class="mandatory-marker">*</span>
                </div>
                <TextInput
                        bind:value={profile.email}
                        class="w-full"
                        dataCy="email"
                        error={t('signUpForm.email.error')}
                        placeholder={t('signUpForm.email.placeholder')}
                        showError={isEmailValid !== true}
                        type="email"
                />
                <div class="flex justify-self-start mt-4">
                    <span class="input-label">{t('onboarding.signup.main.form.password')}</span>
                    <span class="mandatory-marker">*</span>
                </div>
                <TextInput
                        bind:value={profile.password}
                        class="w-full"
                        dataCy="password"
                        error={t('signUpForm.password.error')}
                        placeholder={t('signUpForm.password.placeholder')}
                        showError={!isPasswordValid}
                        type="password"
                        displayTooltipOnFocus={true}
                        displayTooltipAlways={false}
                >
                    <div slot="tooltip">
                        <p class="font-medium">{t('signUpForm.note.title')}</p>
                        <ul class="mt-4">
                            <li class="mt-2">
                                <i class="text-2xl icon-circle-checked align-middle {validPasswordDetails && validPasswordDetails.minimumChars ? 'text-black' : 'text-loblolly'}"></i>
                                <span class="align-middle">{t('signUpForm.note.one')}</span>
                            </li>
                            <li class="mt-2">
                                <i class="text-2xl icon-circle-checked align-middle {validPasswordDetails && validPasswordDetails.upperCaseAndLoweCase ? 'text-black' : 'text-loblolly'}"></i>
                                <span class="align-middle">{t('signUpForm.note.two')}</span>
                            </li>
                            <li class="mt-2">
                                <i class="text-2xl icon-circle-checked align-middle {validPasswordDetails && validPasswordDetails.oneDigit ? 'text-black' : 'text-loblolly'}"></i>
                                <span class="align-middle">{t('signUpForm.note.three')}</span>
                            </li>
                            <li class="mt-2">
                                <i class="text-2xl icon-circle-checked align-middle {validPasswordDetails && validPasswordDetails.specialChar ? 'text-black' : 'text-loblolly'}"></i>
                                <span class="align-middle">{t('signUpForm.note.four')}</span>
                            </li>
                        </ul>
                        <p class="mt-8">{t('onboarding.signup.main.form.passwordHint')}</p>
                    </div>
                </TextInput>
                <span class="text-sm mt-8">
                  {t('signUpForm.footer.one')} <a class="text-dundyOrange" href="https://dundy.co/terms"
                                                  target="_blank">{t('signUpForm.footer.two')}</a>
                    {t('signUpForm.footer.three')} <a class="text-dundyOrange" href="https://dundy.co/privacy"
                                                      target="_blank"> {t('signUpForm.footer.four')}</a>.
                </span>

                <button type="submit" class="btn action-bla primary h-10 mt-10" disabled={!canContinue}>
                  {t('onboarding.next')}
                </button>

                <span class="text-xs mt-16 text-baliHai"><sup> * </sup>{t('onboarding.signup.main.form.mandatory')}</span>
            </form>
        </div>
    </section>
</main>-->

export enum EventType {
  // DATA EVENTS
  AUTHENTICATED,
  ONBOARDING_STARTED,
  LOGGED_IN,
  INVOICE_LIST_FETCHED,
  INVOICE_LIST_LOADED,
  INVOICE_LIST_CHANGED,
  INVOICE_CHANGED,
  INVOICE_CREATED,
  INVOICE_MARKED_COMPLETED,
  INVOICE_PROPERTY_CHANGED,
  COMPUTED_INVOICE_LIST_FETCHED,
  COMPUTED_INVOICE_LIST_CHANGED,
  COMPUTED_INVOICE_CHANGED,
  COMPUTED_INVOICE_CREATED,
  COMPUTED_INVOICE_PROPERTY_CHANGED,
  TODO_LIST_FETCHED,
  TODO_LIST_CHANGED,
  TODO_CHANGED,
  TODO_PROPERTY_CHANGED,
  TODO_MARKED_DONE,
  CUSTOMER_LIST_LOADED,
  CUSTOMER_LIST_CHANGED,
  CUSTOMER_CHANGED,
  CUSTOMER_CREATED,
  CUSTOMER_DELETED,
  CUSTOMER_PROPERTY_CHANGED,
  CONTACT_LIST_LOADED,
  CONTACT_LIST_CHANGED,
  CONTACT_CHANGED,
  CONTACT_CREATED,
  CONTACT_DELETED,
  CONTACT_PROPERTY_CHANGED,
  WORKSPACE_FETCHED,
  WORKSPACE_LIST_LOADED,
  WORKSPACE_LIST_FETCHED,
  WORKSPACE_LIST_CHANGED,
  WORKSPACE_FETCHED_AS_TEAM_USER,
  WORKSPACE_FETCHED_AS_OWNER,
  WORKSPACE_CREATED,
  WORKSPACE_CHANGED,
  WORKSPACE_ADDED,
  WORKSPACE_DELETED,
  WORKSPACE_BANK_ACCOUNT_CHANGED,
  WORKSPACE_BANK_ACCOUNT_SAVED,
  WORKSPACE_TEAM_MEMBER_INVITED,
  WORKSPACE_TEAM_MEMBER_DELETED,
  WORKSPACE_COMPANY_CHANGED,
  WORKSPACE_STORE_INITIALIZED,
  WORKSPACE_TEST_END_TO_END_CHANGED,
  PROFILE_FETCHED,
  PROFILE_CHANGED,
  CASH_APPLICATION_LIST_FETCHED,
  CASH_APPLICATION_LIST_CHANGED,
  CASH_APPLICATION_CHANGED,
  CASH_APPLICATION_PROPERTY_CHANGED,
  FEEDBACK_CHANGED,
  GENERAL_FEEDBACK_CHANGED,
  INVOICES_FEEDBACK_CHANGED,
  TODOS_FEEDBACK_CHANGED,
  PROFILE_FEEDBACK_CHANGED,
  WORKSPACE_FEEDBACK_CHANGED,
  CUSTOMERS_FEEDBACK_CHANGED,
  CONTACTS_FEEDBACK_CHANGED,
  BBB_BANK_CONNECTION_FEEDBACK_CHANGED,
  HISTORY_LIST_FETCHED,
  MERGED_HISTORY_LIST_FETCHED,
  // UI EVENTS (events that are not related to the data sent and received from the API)
  INVOICES_SEARCH_VALUE_CHANGED,
  INVOICES_TABLE_ROW_SELECTED,
  INVOICES_PAGINATION_CHANGED,
  CELL_VALUE_CHANGED,
  INVOICES_CONTEXTUAL_MENU_CLICKED,
  FILE_UPLOAD_PROGRESS_CHANGED,
  INVOICE_UPLOADED,
  PAGINATED_LIST_SEARCH_OCCURRED,
  REQUEST_INVOICE_LIST_AND_COMPUTED_INVOICE_LIST_TO_REFRESH,
  VOXY_DOCUMENT_CREATED,
  VOXY_DOCUMENT_CHANGED,
  VOXY_PREFERENCES_FETCHED,
  VOXY_PREFERENCES_STORE_UPDATE_CYCLE_COMPLETED,
  VOXY_PREFERENCES_SCHEME_SETTING_CHANGED,
  AGGRID_PAGINATION_CHANGED,
  AGGRID_TABLE_ROW_SELECTED,
  DUNNING_SEARCH_VALUE_CHANGED,
  DUNNING_TABLE_ROW_SELECTED,
  DUNNING_PAGINATION_CHANGED,
  DUNNING_CONTEXTUAL_MENU_CLICKED,
  TRANSACTIONS_ROW_SELECTED,
  TRANSACTIONS_CLOSE_PANEL,
  TRANSACTIONS_LIST_FETCHED,
  TRANSACTIONS_LIST_CHANGED,
  TRANSACTIONS_PROPERTY_CHANGED,
  TRANSACTION_FULLY_CASH_APPLIED,
  CASH_APPLICATION_LIST_REFRESH_REQUESTED,
  CASH_APPLICATION_LIST_AND_SELECTION_REFRESH_REQUESTED,
  QUOTES_SEARCH_VALUE_CHANGED,
  QUOTES_TABLE_ROW_SELECTED,
  QUOTES_CONTEXTUAL_MENU_CLICKED,
  BUSINESS_DOCUMENTS_LIST_FETCHED,
  BUSINESS_DOCUMENTS_LIST_LOADED,
  BUSINESS_DOCUMENTS_LIST_CHANGED,
  BUSINESS_DOCUMENT_CHANGED,
  BUSINESS_DOCUMENT_PROPERTY_CHANGED,
  BUSINESS_DOCUMENT_DELETED,
  BUSINESS_DOCUMENTS_DELETED_SUCCESSFULLY,
  BUSINESS_DOCUMENTS_DELETED_FAILED,
  AGGRID_ADD_TEMPORARY_DOCUMENT,
  AGGRID_ADD_TEMPORARY_DOCUMENT_ACKNOWLEDGE
}

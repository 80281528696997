export enum BusinessDocumentRelationKind {
  QUOTE_INVOICING = 'QuoteInvoicing',
  INVOICE_DUPLICATION = 'InvoiceDuplication',
  CREDIT_NOTE_DUPLICATION = 'CreditNoteDuplication',
  VOIDING_NOT_IMPLEMENTED = 'VoidingNotImplemented',
  // deprecated
  INVOICE_VOIDING_DEPRECATED = 'InvoiceVoiding',
  CREDIT_NOTE_ON_INVOICE = 'CreditNoteOnInvoice',
  INVOICE_ON_CREDIT_NOTE = 'InvoiceOnCreditNote',
  INVOICE_ON_INVOICE = 'InvoiceOnInvoice',
  TBD = 'TBD',
  UNKNOWN_ON_INVOICE = 'UnknownOnInvoice',
  UNKNOWN_ON_CREDIT_NOTE = 'UnknownOnCreditNote',
  WHATEVER_ON_UNKNOWN = 'WhateverOnUnknown',
  CREDIT_NOTE_ON_CREDIT_NOTE = 'CreditNoteOnCreditNote'
}
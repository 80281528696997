<script>
  import treeComponents from './tree-components.json'
</script>

<ul>
    {#each Object.keys(treeComponents) as component}
        <li class="main">{component}
            {#if treeComponents[component].length > 0}
                <ul>
                    {#each treeComponents[component] as subComponent}
                        <li class="sub">- {subComponent}</li>
                    {/each}
                </ul>
            {/if}
        </li>
    {/each}
</ul>

<style>
    .main {
        color: #00ADA2;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .sub {
        color: black;
        font-weight: normal;
    }

    ul {
        list-style-type: none;
    }
</style>

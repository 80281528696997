export enum QuotesViewListOption {
  INVOICED = 'invoiced',
  DECLINED = 'declined',
  ACCEPTED = 'accepted',
  DELETED = 'deleted',
  OUTSTANDING = 'outstanding',
  DRAFT = 'draft',
  TO_SEND = 'to_send',
  ALL = 'all'
}

import { DocumentSourceKind } from '$src/order-to-cash-lib/models/document-source-kind'
import { BusinessDocument, BusinessDocumentRelation } from '../../models/business-document'
import { BusinessDocumentRelationKind } from '../../enums/business-document-relation-kind'
import { BusinessDocumentKind } from '../../enums/business-document-kind'
import { BusinessDocumentStatus } from '$voxy/enums/business-document-status'
import { HistoryStore } from '$src/voxy-app/stores/business-documents.store'
import { get } from 'svelte/store'
import { BusinessDocumentState } from '$voxy/enums/business-document-state'
import { t } from '$core/lib/i18n/i18nextWrapper'
// voided : bool
// statusIfFound: string

/**
 * Check if the business document relation kind is invoice voiding
 * @param source
 * @param businessDocument
 * @param allBusinessDocuments
 */
export function isBusinessDocumentVoidedByAnotherOne(source: DocumentSourceKind, businessDocument: BusinessDocument, allBusinessDocuments: BusinessDocument[]): BusinessDocumentStatus | null {
  /* console.log('businessDocument?.relatedBusinessDocuments', businessDocument?.relatedBusinessDocuments) */
  if (!businessDocument || source !== DocumentSourceKind.FROMVOXYINVOICING) {
    /* console.error('wrong call to isBusinessDocumentVoidedByAnotherOne') */

    return null
  }

  let voidedRelation = null

  const voided = allBusinessDocuments.find(aBusinessDocument => {
    const voidingBusinessDocumentRelation: BusinessDocumentRelation = aBusinessDocument.relatedBusinessDocuments?.find((r: BusinessDocumentRelation) => (r.relationKind === BusinessDocumentRelationKind.INVOICE_VOIDING_DEPRECATED || r.relationKind === BusinessDocumentRelationKind.CREDIT_NOTE_ON_INVOICE || r.relationKind === BusinessDocumentRelationKind.INVOICE_ON_CREDIT_NOTE)
                && (r.fromBusinessDocumentId === businessDocument.businessDocumentId))

    if (!!voidingBusinessDocumentRelation) voidedRelation = voidingBusinessDocumentRelation
    
    return !!voidingBusinessDocumentRelation
  })

  if (!voided) return null
  const dependency = allBusinessDocuments.find(e => e.businessDocumentId === voidedRelation.toBusinessDocumentId)
  if (dependency) return dependency.businessDocumentStatus
  
  return null

}

export function getStatus(businessDocument: BusinessDocument): { state: BusinessDocumentState, label: string } | undefined {
  const store: any[] = get(HistoryStore)
  
  if (!store) return 
  
  if (businessDocument.businessDocumentStatus === BusinessDocumentStatus.DRAFT) {
    switch (businessDocument.businessDocumentKind) {
      case BusinessDocumentKind.CREDITNOTE: return { state: BusinessDocumentState.CREDIT_NOTE_DRAFT, label: t('invoiceStatus.draftCreditNote') }
      case BusinessDocumentKind.INVOICE: return { state: BusinessDocumentState.INVOICE_DRAFT, label: t('invoiceStatus.draftInvoice') }
      case BusinessDocumentKind.PURCHASEORDER: return { state: BusinessDocumentState.PURCHASE_ORDER_DRAFT, label: t('invoiceStatus.draftPurchaseOrder') }
      case BusinessDocumentKind.QUOTE: return { state: BusinessDocumentState.QUOTE_DRAFT, label: t('invoiceStatus.draftQuote') }
    }
  } else {
    switch (businessDocument.businessDocumentKind) {
      case BusinessDocumentKind.CREDITNOTE: return { state: BusinessDocumentState.CREDIT_NOTE, label: t('invoiceStatus.creditNote') }
      case BusinessDocumentKind.INVOICE: return { state: BusinessDocumentState.INVOICE, label: t('invoiceStatus.invoice') }
      case BusinessDocumentKind.PURCHASEORDER: return { state: BusinessDocumentState.PURCHASE_ORDER, label: t('invoiceStatus.purchaseOrder') }
      case BusinessDocumentKind.QUOTE: return { state: BusinessDocumentState.QUOTE, label: t('invoiceStatus.quote') }
    }
  }
}

export function getStatusFull(businessDocument: BusinessDocument): { state: BusinessDocumentState, label: string } | undefined {
  const store: any[] = get(HistoryStore)
  
  if (!store) return 
  
  const status = store.find(h => h.has(businessDocument?.businessDocumentId))
  if (!status) return
  const state = status.get(businessDocument.businessDocumentId).state

  const isDraft: boolean = businessDocument.businessDocumentStatus === BusinessDocumentStatus.DRAFT

  // Last document in history
  if (state === BusinessDocumentState.VOIDING_AND_LAST) {
    switch (businessDocument.businessDocumentKind) {
      case BusinessDocumentKind.CREDITNOTE: return { state, label: isDraft ? t('invoiceStatus.pendingVoidingCreditNote') : t('invoiceStatus.voidingCreditNote') }
      case BusinessDocumentKind.PURCHASEORDER: return { state, label: isDraft ? t('invoiceStatus.pendingVoidingPurchaseOrder') : t('invoiceStatus.voidingPurchaseOrder') }
      case BusinessDocumentKind.INVOICE: return { state, label: isDraft ? t('invoiceStatus.pendingVoidingInvoice') : t('invoiceStatus.voidingInvoice') }
      case BusinessDocumentKind.QUOTE: return { state, label: isDraft ? t('invoiceStatus.pendingVoidingQuote') : t('invoiceStatus.voidingQuote') }
    }
  }

  // Intermediate document in history
  // Voided Voiding document
  if (state === BusinessDocumentState.VOIDING_AND_VOIDED) {
    switch (businessDocument.businessDocumentKind) {
      case BusinessDocumentKind.CREDITNOTE: return { state, label: t('invoiceStatus.voidedVoidingCreditNote') }
      case BusinessDocumentKind.PURCHASEORDER: return { state, label: t('invoiceStatus.voidedVoidingPurchaseOrder') }
      case BusinessDocumentKind.INVOICE: return { state, label: t('invoiceStatus.voidedVoidingInvoice') }
      case BusinessDocumentKind.QUOTE: return { state, label: t('invoiceStatus.voidedVoidingQuote') }
    }
  }
  // Voided document
  if (state === BusinessDocumentState.VOIDED) {
    switch (businessDocument.businessDocumentKind) {
      case BusinessDocumentKind.CREDITNOTE: return { state, label: t('invoiceStatus.voidedCreditNote') }
      case BusinessDocumentKind.PURCHASEORDER: return { state, label: t('invoiceStatus.voidedPurchaseOrder') }
      case BusinessDocumentKind.INVOICE: return { state, label: t('invoiceStatus.voidedInvoice') }
      case BusinessDocumentKind.QUOTE: return { state, label: t('invoiceStatus.voidedQuote') }
    }
  }
  // Voiding document
  if (state === BusinessDocumentState.VOIDING) {
    switch (businessDocument.businessDocumentKind) {
      case BusinessDocumentKind.CREDITNOTE: return { state, label: t('invoiceStatus.voidingCreditNote') }
      case BusinessDocumentKind.PURCHASEORDER: return { state, label: t('invoiceStatus.voidingPurchaseOrder') }
      case BusinessDocumentKind.INVOICE: return { state, label: t('invoiceStatus.voidingInvoice') }
      case BusinessDocumentKind.QUOTE: return { state, label: t('invoiceStatus.voidingQuote') }
    }
  }
  // Voided document with voiding draft
  if (state === BusinessDocumentState.PENDING_VOIDED) {
    switch (businessDocument.businessDocumentKind) {
      case BusinessDocumentKind.CREDITNOTE: return { state, label: ('invoiceStatus.pendingVoidedCreditNote') }
      case BusinessDocumentKind.PURCHASEORDER: return { state, label: t('invoiceStatus.pendingVoidedPurchaseOrder') }
      case BusinessDocumentKind.INVOICE: return { state, label: t('invoiceStatus.pendingVoidedInvoice') }
      case BusinessDocumentKind.QUOTE: return { state, label: t('invoiceStatus.pendingVoidedQuote') }
    }
  }
  // Voiding Draft
  if (state === BusinessDocumentState.PENDING_VOIDING) {
    switch (businessDocument.businessDocumentKind) {
      case BusinessDocumentKind.CREDITNOTE: return { state, label: t('invoiceStatus.pendingVoidingCreditNote') }
      case BusinessDocumentKind.PURCHASEORDER: return { state, label: t('invoiceStatus.pendingVoidingPurchaseOrder') }
      case BusinessDocumentKind.INVOICE: return { state, label: t('invoiceStatus.pendingVoidingInvoice') }
      case BusinessDocumentKind.QUOTE: return { state, label: t('invoiceStatus.pendingVoidingQuote') }
    }
  }

  // Document without history
  switch (state) {
    case BusinessDocumentState.CREDIT_NOTE: return { state, label: t('invoiceStatus.creditNote') }
    case BusinessDocumentState.CREDIT_NOTE_DRAFT: return { state, label: t('invoiceStatus.draftCreditNote') }
    case BusinessDocumentState.DRAFT: return { state, label: t('invoiceStatus.draft') }
    case BusinessDocumentState.INVOICE: return { state, label: t('invoiceStatus.invoice') }
    case BusinessDocumentState.INVOICE_DRAFT: return { state, label: t('invoiceStatus.draftInvoice') }
    case BusinessDocumentState.PURCHASE_ORDER: return { state, label: t('invoiceStatus.purchaseOrder') }
    case BusinessDocumentState.PURCHASE_ORDER_DRAFT: return { state, label: t('invoiceStatus.draftPurchaseOrder') }
    case BusinessDocumentState.QUOTE: return { state, label: t('invoiceStatus.quote') }
    case BusinessDocumentState.QUOTE_DRAFT: return { state, label: t('invoiceStatus.draftQuote') }
  }
}
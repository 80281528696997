/**
 * Data Element Description    Information   Field Name    EBS 12 Journal Entries Report    Data Type
 * 1. The journal entry code of the accounting entry    Journal Sequence Name    JournalCode    JE_CATEGORY_NAME    Alphanumeric
 * 2. The journal entry label/name of the accounting entry    Journal (Accounting Entry) Header description    JournalLib    Category description (corresponding to field1)    Alphanumeric
 * 3. The number of the accounting entry (in chronological and sequential numbering)    Sequential Numbering of Journal (Accounting Entry) line    EcritureNum    Document number of the journal header    Alphanumeric
 * 4.The accounting date    Accounting Date    EcritureDate    GL_DATE    Date
 * 5.The account number (using French accounting terminology where first 3 characters meet French acctg standard    French Statutory Natural Account value    CompteNum    NATURAL_ACCOUNT    Alphanumeric
 * 6.The account heading under the French Accounting Number    French Statutory Natural Account description (in French)    CompteLib    NATURAL_ACCOUNT_DESCRIPTION    Alphanumeric
 * 7.The subsidiary ledger account number (field must be left empty if not used)    "Third Party Account Number ( empty if not used)
 *  "    CompAuxNum    PARTY_NUMBER    Alphanumeric
 * 8.The subsidiary ledger account heading (field must be left empty if not used)    Third Party Account Name (Field might be left empty if not used)    CompAuxLib    PARTY_NAME    Alphanumeric
 * 9.Reference of the relevant supporting document    Document/Transaction Number    PieceRef    "TRANSACTION_NUMBER
 * (“Pas de PieceRef” if the field is empty)"    ALphnumeric
 * 10.Date of the relevant supporting document    Transaction Date    PieceDate    "TRANSACTION_DATE
 * "    Date
 * 11.Label of the accounting entry    Transaction Description (Journal (Accounting Entry) Line description)    EcritureLib    LINE_DESCRIPTION    Alphanumeric
 * 12.The debit amount    Debit Amount    Debit    "ACCOUNTED_DR
 * (0,00 if empty)"    Numeric
 * 13.The credit amount    Credit Amount    Credit    "ACCOUNTED_CR
 * (0,00 if empty)"    Numeric
 * 14.The lettering/Marking of the accounting entry (field must be left empty if not used)    Accounting Entry lines reconciliation number – open item key    EcritureLet    RECONCILIATION_REFERENCE    Alphanumeric
 * 15.Lettering date (kept blank if unused)    Accounting Entry lines reconciliation date    DateLet    RECONCILIATION_DATE    Date
 * 16.Validation date for the journal entry    Journal (Accouting Entry) line posting date    ValidDate    POSTED_DATE    Date
 * 17.Amount in currency (blank if unused)    Amount in Currency (blank if unused)    Montantdevise    "ENTERED_DR or ENTERED_CR
 * (0,00 if empty)"    Numeric
 * 18. Identifying code the currency (leave blank if none used)    Currency Code (blank if not used)    Idevise    ENTERED_CURRENCY    Alphanumeric*/

export default class FECJournal {
  JournalCode: string
  JournalLib: string
  EcritureNum: string
  EcritureDate: string
  CompteNum: string
  CompteLib: string
  CompAuxNum: string
  CompAuxLib: string
  PieceRef: string
  PieceDate: string
  EcritureLib: string
  Debit: string | number
  Credit: string | number
  EcritureLet: string
  DateLet: string
  ValidDate: string
  Montantdevise: string | number
  Idevise: string
}

export class FECGeneratorOptions {
  accountReceivable: boolean
  accountPayable: boolean
  fileTitle: string
}

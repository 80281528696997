<script lang="ts">
    import { DocumentSourceKind } from '../models/document-source-kind'
    import { calculateDaysDue } from '../helpers/calculate-days-due'
    import Invoice from '../../dundy-app/models/invoice'
    import { DunningInvoicesStore } from '$dundy/stores/dunning-invoices.store'
    import { BusinessDocument } from '$voxy/models/business-document'
    import { InvoiceStatus } from '$dundy/enums/invoice-status'
    import { t } from '$core/lib/i18n/i18nextWrapper'
    import { onMount } from 'svelte'
    import { feedbackService } from '$core/services/feedback.service'
    import { Feedback } from '$core/models/feedback'
    import { maxDaysSinceDueDateToHigherEscalation } from '$dundy/services/dundy-config'
    import { ComputedDataInvoicesStore } from '$dundy/stores/computed-data-invoice.store'
    import {
      getBusinessDocumentById
    } from '$voxy/services/business-document-api/business-document-repository.api'
    import {
      getStatus
    } from '$voxy/services/business-document-voiding/is-business-document-voided.service'
    import { BusinessDocumentState } from '$voxy/enums/business-document-state'

    /** Export declarations **/
    export let invoiceId: string
    export let businessDocument: BusinessDocument | null = null

    /** Let & Const declarations **/
    let invoice: Invoice
    let invoiceDueDays: number

    /** Reactive Declarations **/
    $: kind = businessDocument?.businessDocumentKind?.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
    $: status = businessDocument ? getStatus(businessDocument) : ''
    $: classItem = ['voiding-and-last'].includes(status?.state) ? kind : status?.state
    $: invoice = $DunningInvoicesStore.find((i) => i?.dataId === invoiceId)
    $: invoiceDueDays = calculateDaysDue(invoice?.invoiceNumber, $ComputedDataInvoicesStore)

    onMount(() => {
      if (!businessDocument && invoice?.documentSource.sourceKind === DocumentSourceKind.FROMVOXYINVOICING) {
        try {
          getBusinessDocumentById(invoiceId ? <string>invoiceId : '')
            .then((businessDocumentFromServer: BusinessDocument) => {
              businessDocument = businessDocumentFromServer
              /* console.log(businessDocument, 'businessDocument') */
            })
            .catch(() => {
              /* console.log('Error fetching businessDocument') */
              feedbackService.displayFeedback(<Feedback>{
                feedbackLevel: 'Error',
                feedbackMessage: t('invoices.editInvoice.invoiceNotFound')
              })
            })
        } catch (e) {
          console.error(e)
        }
      }
    })

</script>

{#if [BusinessDocumentState.INVOICE, BusinessDocumentState.CREDIT_NOTE, BusinessDocumentState.VOIDED, BusinessDocumentState.VOIDING, BusinessDocumentState.VOIDING_AND_LAST, BusinessDocumentState.VOIDING_AND_VOIDED, BusinessDocumentState.PENDING_VOIDED, BusinessDocumentState.PENDING_VOIDING].includes(status?.state)}
  <span class="label action-primary {classItem}">
    {status?.label}
  </span>
{:else}
    {#if !invoiceDueDays || invoiceDueDays < 0 }
        <span class="mb-4 label text-xxs success on-time-status px-1 py-1">
            {t('invoiceStatus.onTime')}
        </span>
    {:else if invoice?.status === InvoiceStatus.PAID}
        <span class="label success-outlined paid-status">
            {t('invoiceStatus.paid')}
        </span>
    {:else}
        <span class="mb-4 label text-xxs px-1 py-0.5
             {invoiceDueDays > maxDaysSinceDueDateToHigherEscalation ? 'error late-payment-status high-escalation' : 'warning just-late-payment-status low-escalation'}">
             {invoiceDueDays}{t('invoiceStatus.daysLate', { count: invoiceDueDays || 0 })}
        </span>
    {/if}
{/if}

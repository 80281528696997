<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount, tick } from 'svelte'
  import { fly } from 'svelte/transition'
  import { currentProfileLanguage, t } from '../../core-app/lib/i18n/i18nextWrapper'
  import { Link, navigate, Route, Router } from 'svelte-routing'
  import Loader from '../../core-app/lib/ui-kit/Loader.svelte'
  import FullSizeModal from '../../core-app/lib/ui-kit/FullSizeModal.svelte'
  import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
  import { ComputedDataInvoicesStore } from '../../dundy-app/stores/computed-data-invoice.store'
  import { DoNotPersistInvoicesStoreChangeOnce } from '../../dundy-app/stores/dunning-invoices.store'
  import type { Feedback } from '../../core-app/models/feedback'
  import type {
    BusinessDocument,
    BusinessDocumentAllDataPersisted,
    BusinessDocumentCollateralData,
    BusinessDocumentLineItem
  } from '../models/business-document'
  import { CollateralDocument, DealDetails, PaymentLink } from '../models/business-document'
  import BusinessDocumentFormDiscount from '../lib/invoice-creation/form/BusinessDocumentFormDiscount.svelte'
  import BusinessDocumentFormCustomerAndContact from '../lib/invoice-creation/form/BusinessDocumentFormCustomerAndContact.svelte'
  import CreditNoteInformationNotice from '../lib/invoice-creation/form/CreditNoteInformationNotice.svelte'
  import BusinessDocumentPaymentConditions from '../lib/invoice-creation/form/BusinessDocumentPaymentConditions.svelte'
  import BusinessDocumentIssuedAndDueDates from '../lib/invoice-creation/form/BusinessDocumentIssuedAndDueDates.svelte'
  import BusinessDocumentRelatedDocumentsPanel from '../lib/invoice-creation/form/BusinessDocumentRelatedDocumentsPanel.svelte'
  import { VoxyHelper } from '../helpers/voxy-helper'
  import { EventType } from '../../core-app/events/event-type'
  import { eventsManager } from '../../core-app/events/event-manager'
  import { feedbackService } from '../../core-app/services/feedback.service'
  import type Invoice from '../../dundy-app/models/invoice'
  import type ComputedDataInvoice from '../../dundy-app/models/computed-data-invoice'
  import { BusinessDocumentStatus } from '../enums/business-document-status'
  import { dunningInvoicesService } from '../../dundy-app/services/dunning-invoices.service'
  import { bridgeByBankingService } from '../../pay-app/services/bridge-by-banking.service'
  import { voxyInvoicingService } from '../services/business-document-voxy/voxy.service'
  import { BusinessDocumentKind } from '../enums/business-document-kind'
  import { BusinessDocumentViewListOption } from '../enums/business-documents-view-list-options'
  import BusinessDocumentNumber from '../lib/invoice-creation/form/BusinessDocumentNumber.svelte'
  import BusinessDocumentNumberPreferences from '../lib/invoice-creation/form/BusinessNumberPreferencePanel.svelte'
  import { isNegative } from '../../core-app/util/number-utils'
  import BusinessDocumentLivePreview from '../lib/invoice-creation/preview/BusinessDocumentLivePreview.svelte'
  import { get, type Unsubscriber } from 'svelte/store'
  import { deepClone } from '../../core-app/util/object-deep-cloning.js'
  import BusinessDocumentFormItem from '../lib/invoice-creation/form/BusinessDocumentFormItem.svelte'
  import BusinessDocumentSettingsPanel from '../lib/invoice-creation/form/BusinessDocumentSettingsPanel.svelte'
  import {
    isBankConnectionActive,
    isBankInformationComplete
  } from '../../pay-app/helpers/bank-connection-pure-functions'
  import { VoxyPreferencesStore } from '../stores/voxy-preference.store'
  import type { VoxyPreferences } from '../models/voxy-preferences'
  import BusinessDocumentInstallments from '../lib/invoice-creation/form/BusinessDocumentInstallments.svelte'
  import { generateLegalBoilerplateForAGivenLegalStructure } from '../services/generate-voxy-legal-information'
  import { substDayMikesEmailWhenCreatingInvoice } from '../services/specialDayMikes/day-mikes.service'
  import {
    BusinessDocumentSlugs,
    isBusinessDocumentSlugs,
    returnDocumentKindFromURIParam,
    returnTaxonomyTagFromURIParam
  } from '../enums/business-document-slugs'
  import { businessDocumentsListViewChoice, SimpleDocumentsStore } from '../stores/business-documents.store'
  import PreviousPartialBusinessDocumentsForCustomerPanel from '../lib/invoice-creation/form/PreviousPartialBusinessDocumentsForCustomerPanel.svelte'
  import PreviousPartialBusinessDocumentsForCustomer from '../lib/invoice-creation/form/PreviousPartialBusinessDocumentsForCustomer.svelte'
  import { createPDFAndHandleFileUpload } from '../services/pdf-make/business-document-pdf-generator'
  import { listOfBusinessDocumentsWithDealHavingARemainingAmountToInvoiceForGivenCustomerCompany } from '../services/deal-balance-calculation/incomplete-deals-list.service'
  import {
    isLockedLineItem,
    isLockedLineItemListInBusinessDocument
  } from '../services/business-document-line-items-locking/business-document-line-items-locking.service'
  import {
    getBusinessDocumentWithCollateralDataById,
    getVoxyInvoiceFromServer,
    getVoxyInvoiceWithCollateralDataFromServerById,
    saveVoxyBusinessDocumentCollateralDataToServer,
    saveVoxyInvoiceToServer
  } from '../services/business-document-api/business-document-repository.api'
  import type { InvoicingBalanceLimits } from '../models/invoicing-balance-limits.model'
  import { initInvoicingBalanceLimits } from '../services/ui-services/businessDocumentInstallments/business-document-installments.ui'
  import { getBusinessDocumentUpdatedInstallmentTaxonomyTags } from '../services/taxonomy/determine-business-document-taxonomy-tags'
  import { calculateTotalPrice, calculateTotalVAT } from '../helpers/voxy-helper-pure-functions'
  import { v4 as uuidv4 } from 'uuid'
  import RelatedBusinessDocumentItem from '../lib/invoice-creation/form/RelatedBusinessDocumentItem.svelte'
  import { getUpToDateInvoicingBalanceLimits } from '../services/deal-balance-calculation/invoicing-balance-limits-update.service'
  import { BalanceOfDealBusinessDocumentBuildingData } from '../models/partial-business-document-building-data.model'
  import { newBalanceBusinessDocumentAndCollateralDataFromUserSelectedDeal } from '../services/deal-balance-document-make/balance-business-document-and-collateral-for-selected-deal.service'
  import { CustomersStore } from '../../crm-app/stores/customers.store'
  import { ContactsStore } from '../../crm-app/stores/contacts.store'
  import { hasAtLeastAnotherFinalizedBusinessDocumentWithSameDeal } from '../services/installment/at-least-another-finalized-business-document-with-same-deal.service'
  import { voxyPreferencesService } from '../services/voxy-preferences.service'
  import mixpanel from 'mixpanel-browser'
  import { isClientCountryCodeAMemberOfEuropeanUnion } from '$src/core-app/services/countries-pure-functions'
  import customLog from '$src/shared/services/custom-log.service'
  import { bbbAPIPrimitivesService } from "$pay/services/bbb-api-primitives.service";

  /** Exported Var **/
  export let invoiceId: string = null
  export let businessDocumentSlug: string

  /**
   * First we assert the type of the businessDocumentSlug
   * converting from string passed from router to BusinessDocumentSlugs
   */
  let validatedBusinessDocumentSlug: BusinessDocumentSlugs
  if (isBusinessDocumentSlugs(businessDocumentSlug)) {
    validatedBusinessDocumentSlug = businessDocumentSlug
  }

  /** Local declarations **/
  /** TODO: should be managed in onMount() and onDestroy() **/
  const dispatch = createEventDispatcher()
  /** TODO: should be managed in onMount() and onDestroy() **/
  let businessDocument: BusinessDocument = VoxyHelper.newVoxyInvoice(
    returnDocumentKindFromURIParam(validatedBusinessDocumentSlug),
    returnTaxonomyTagFromURIParam(validatedBusinessDocumentSlug),
  )

  let businessDocumentFetched: boolean = false

  /** Exception For Day-Mikes - TODO Refactor Contact & Remove - implement custom/default sender for everyone */
  /** TODO: should be managed in onMount() and onDestroy() **/
  businessDocument.accountContact = substDayMikesEmailWhenCreatingInvoice(
    get(WorkspaceStore).company.companyId,
    businessDocument.accountContact,
  )

  /** TODO: should be managed in onMount() and onDestroy() **/
  let invoiceCollateralData: BusinessDocumentCollateralData = VoxyHelper.newVoxyCollateralData(
    businessDocument.businessDocumentId,
    businessDocument.businessDocumentKind,
    isBankConnectionActive(get(WorkspaceStore)),
    businessDocument.businessDocumentNumber,
  )
  let unsubscribeToVoxyPreferences: Unsubscriber = null
  /** TODO: should be managed in onMount() and onDestroy() **/
  let accountCompanyLegalIdentificationTypeFRSIREN: string = businessDocument?.accountCompany?.regulatory?.frRegulScope?.siren
  /** TODO: should be managed in onMount() and onDestroy() **/
  let accountCompanyLegalIdentificationTypeFRVAT: string = businessDocument?.accountCompany?.regulatory?.euRegulScope?.euIntraVat
  let customerCompanyLegalIdentificationTypeFRVAT: string
  let favoriteBusinessDocumentLineItems: BusinessDocumentLineItem[] = []

  let refreshInstallmentRelatedValuesAfterPotentialLineItemsChange: boolean = false

  /**
   * Booleans used to control the ability to save/track or close the businessDocument modal
   * @type {boolean}
   */
  let isContentLoading: boolean = false
  let canFinalizeAndTrackInvoice: boolean
  let savingDraftToServer: boolean = false
  let savingFinalizedToServer: boolean = false
  let disableFinalizeButton: boolean
  let disableDraftButton: boolean
  let isCloseable: boolean
  let isFullSizeNumberingPanelActivated: boolean = false
  let isFullSizePartialPanelActivated: boolean = false

  /** Accessible Boolean For Invoice Type **/
  let isCreditNote: boolean
  let isInvoice: boolean
  let isProformaInvoice: boolean = false

  /** Getting Installments for specific customers */
  /** TODO: should be managed in onMount() and onDestroy() **/
  let listOfIncompleteBusinessDocumentsForChosenCustomer: BusinessDocument[] = []

  /** the partial invoicing balance limits */
  /** TODO: should be managed in onMount() and onDestroy() **/
  let invoicingBalanceLimits: InvoicingBalanceLimits = initInvoicingBalanceLimits()
  let rightInstallmentSignWithRegardsDocumentKind: boolean = true
  let nonNullInstallment: boolean = true
  let aFinalCustomerChoiceIsMissing: boolean = true
  let anItemPriceIsMissing: boolean = true
  let anItemTitleIsMissing: boolean = true
  let rightCreditNoteGlobalAmountSign: boolean = true
  let rightInvoiceGlobalAmountSign: boolean = true
  let wrongCreditNoteGlobalAmountSignWithRegardsDocumentKind: boolean = true
  let wrongInvoiceGlobalAmountSignWithRegardsDocumentKind: boolean = true
  let installmentAmountIsVeryCloseToFullBusinessDocumentAmount: boolean = false

  let isCatch: boolean = false

  let lockWindow:any = null
  let lockWindowEvent = (e:any) => {
    e.preventDefault()
    e.stopPropagation()
    e.stopImmediatePropagation()
    e.returnValue = 'Traitement en cours, veuillez patienter'

    return e.returnValue
  }

  /**
   * UN/LOCK CLOSE WINDOW EVENT
   * @param lock
   * @param unlockDelay
   */
  const lockWindowClose = (lock: boolean = true, unlockDelay: number = 2000) => {
    if (lock) {
      window.addEventListener('beforeunload', lockWindowEvent)

      return
    }

    if (lockWindow) clearTimeout(lockWindow)
    lockWindow = setTimeout(() => {
      window.removeEventListener('beforeunload', lockWindowEvent)
    }, unlockDelay)
  }

  $:customer = $CustomersStore.find(c => c.company.companyId === businessDocument?.customerCustomer?.company?.companyId)

  $: {
    /* console.error('%c VoxyInvoiceAddModal reactive A', 'color: orange; font-size: 1.2em', 'Action: refresh user help to properly fill amount signs') */
    rightCreditNoteGlobalAmountSign =
      businessDocument.businessDocumentKind === BusinessDocumentKind.CREDITNOTE
        ? businessDocument.installmentResultIncludingTaxScaledValue < 0 &&
          businessDocument.installmentResultExcludingTaxScaledValue < 0 &&
          businessDocument.totalExcludingTaxScaledValue < 0
        : true
    rightInvoiceGlobalAmountSign =
      businessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE
        ? businessDocument.installmentResultIncludingTaxScaledValue > 0 &&
          businessDocument.installmentResultExcludingTaxScaledValue > 0 &&
          businessDocument.totalExcludingTaxScaledValue > 0
        : true

    rightInstallmentSignWithRegardsDocumentKind = rightCreditNoteGlobalAmountSign && rightInvoiceGlobalAmountSign
    nonNullInstallment = Math.abs(businessDocument.installmentResultIncludingTaxScaledValue) > 0.001

    wrongCreditNoteGlobalAmountSignWithRegardsDocumentKind =
      !rightInstallmentSignWithRegardsDocumentKind &&
      nonNullInstallment &&
      businessDocument.businessDocumentKind === BusinessDocumentKind.CREDITNOTE
    wrongInvoiceGlobalAmountSignWithRegardsDocumentKind =
      !rightInstallmentSignWithRegardsDocumentKind &&
      nonNullInstallment &&
      businessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE

    installmentAmountIsVeryCloseToFullBusinessDocumentAmount =
      !businessDocument ||
      !businessDocument.totalIncludingTaxScaledValue ||
      Math.abs(businessDocument.totalIncludingTaxScaledValue) < 0.01 ||
      !businessDocument.hasSpecificInstallment
        ? false
        : Math.abs(
          (businessDocument.installmentResultIncludingTaxScaledValue -
              businessDocument.totalIncludingTaxScaledValue) /
              businessDocument.totalIncludingTaxScaledValue,
        ) *
            100 <=
            5 || // closer than 5% to full amount
          Math.abs(
            businessDocument.installmentResultIncludingTaxScaledValue - businessDocument.totalIncludingTaxScaledValue,
          ) <= 1 // less than 1 unit of currency to full amount
  }

  /** Reactive declarations **/
  $: {
    /* console.error('%c VoxyInvoiceAddModal reactive B', 'color: orange; font-size: 1.2em', 'Action: refresh if is a credit note') */
    isCreditNote = businessDocument?.businessDocumentKind === BusinessDocumentKind.CREDITNOTE
  }
  $: {
    /* console.error('%c VoxyInvoiceAddModal reactive C', 'color: orange; font-size: 1.2em', 'Action: refresh if is an invoice') */
    isInvoice = businessDocument?.businessDocumentKind === BusinessDocumentKind.INVOICE
  }
  $: {
    /* console.error('%c VoxyInvoiceAddModal reactive D', 'color: orange; font-size: 1.2em', 'Action: refresh business document legal id') */
    customerCompanyLegalIdentificationTypeFRVAT = businessDocument?.customerCustomer.company?.regulatory?.euRegulScope?.euIntraVat
  }
  $: {
    /* console.error('%c VoxyInvoiceAddModal reactive F', 'color: orange; font-size: 1.2em', 'Action: refresh finalize button') */
    disableFinalizeButton = !canFinalizeAndTrackInvoice || savingDraftToServer
  }
  $: {
    /* console.error('%c VoxyInvoiceAddModal reactive G', 'color: orange; font-size: 1.2em', 'Action: refresh save draft button') */
    disableDraftButton =
      savingDraftToServer ||
      savingFinalizedToServer ||
      businessDocument.businessDocumentStatus === BusinessDocumentStatus.FINAL ||
      isFullSizePartialPanelActivated ||
      isFullSizeNumberingPanelActivated
  }
  $: {
    /* console.error('%c VoxyInvoiceAddModal reactive H', 'color: orange; font-size: 1.2em', 'Action: refresh closeable window') */
    isCloseable = !(savingDraftToServer || savingFinalizedToServer) || isCatch
  }
  $: {
    /* console.error('%c VoxyInvoiceAddModal reactive K', 'color: orange; font-size: 1.2em', 'Action: updatingDocumentCheck, if FULL installment then update taxonomy tags and update installment values') */

    updatingDocumentCheck()
    // installment refresh for full business document
    if (!businessDocument.hasSpecificInstallment) {
      // update business document taxonomy tags
      const res = getBusinessDocumentUpdatedInstallmentTaxonomyTags(businessDocument, get(SimpleDocumentsStore)) // only for the sake on taxonomy (because we cannot guarantee the hasSpecificInstallment changed here)

      businessDocument.taxonomyTags = res.newTaxonomyTagsForBusinessDocument

      businessDocument.installmentResultIncludingTaxScaledValue = businessDocument.totalIncludingTaxScaledValue
      businessDocument.installmentResultExcludingTaxScaledValue = businessDocument.totalExcludingTaxScaledValue
      businessDocument.installmentResultTaxScaledValue = businessDocument.totalTaxScaledValue
      /* console.error('%c VoxyInvoiceAddModal reactive K', 'color: orange; font-size: 1.2em', 'Action: FULL installment: update taxonomy tags and update installment values = ' + res.newTaxonomyTagsForBusinessDocument.join(' - ')) */
    } else {
      // this case was handled here-above when treating the response of getBusinessDocumentUpdatedInstallmentTaxonomyTags()
    }
  }

  $: {
    /* console.error('%c VoxyInvoiceAddModal reactive L', 'color: orange; font-size: 1.2em', 'Action: updatingDocumentCheck, update totals, reset installment after line items change') */
    updatingDocumentCheck()
    /* console.log('refresh business document + resetInstallmentAfterLineItemsChange - from the beginning') */
    /** Update Invoice Calculus */
    businessDocument.subtotalExcludingTaxScaledValue =
      Math.round((calculateTotalPrice(businessDocument.lineItems) + Number.EPSILON) * 100) / 100
    businessDocument.totalTaxScaledValue =
      Math.round((calculateTotalVAT(businessDocument.lineItems) + Number.EPSILON) * 100) / 100
    businessDocument.subtotalIncludingTaxScaledValue =
      Math.round(
        (businessDocument.subtotalExcludingTaxScaledValue + businessDocument.totalTaxScaledValue + Number.EPSILON) *
          100,
      ) / 100
    businessDocument.totalExcludingTaxScaledValue =
      Math.round(
        (businessDocument.subtotalExcludingTaxScaledValue -
          businessDocument.totalDiscountExcludingTaxResultScaledValue +
          Number.EPSILON) *
          100,
      ) / 100
    businessDocument.totalIncludingTaxScaledValue =
      Math.round(
        (businessDocument.subtotalExcludingTaxScaledValue -
          businessDocument.totalDiscountExcludingTaxResultScaledValue +
          businessDocument.totalTaxScaledValue +
          Number.EPSILON) *
          100,
      ) / 100
    resetInstallmentAfterLineItemsChange()
  }

  $: {
    /* console.error('%c VoxyInvoiceAddModal reactive N', 'color: orange; font-size: 1.2em', 'Action: recalculate discount') */
    if (businessDocument.totalDiscountExcludingTaxChosenUnit === '%') {
      businessDocument.totalDiscountExcludingTaxResultScaledValue =
        Math.round(
          ((businessDocument.subtotalExcludingTaxScaledValue * businessDocument.totalDiscountExcludingTaxChosenValue) /
            100 +
            Number.EPSILON) *
            100,
        ) / 100
    } else if (businessDocument.totalDiscountExcludingTaxChosenUnit === 'EUR') {
      businessDocument.totalDiscountExcludingTaxResultScaledValue =
        Math.round((businessDocument.totalDiscountExcludingTaxChosenValue + Number.EPSILON) * 100) / 100
    }
  }

  /** Update DocumentKind depending on total amount **/
  $: {
    /* console.error('%c VoxyInvoiceAddModal reactive O', 'color: orange; font-size: 1.2em', 'Action: update collateral data businessDocumentKind if business document businessDocumentKind changes') */
    invoiceCollateralData.businessDocumentKind = businessDocument.businessDocumentKind
  }

  $: {
    /* console.error('%c VoxyInvoiceAddModal reactive E', 'color: orange; font-size: 1.2em', 'Action: refresh user help to allow finalizing / draft saving / missing line item info') */
    /* console.log(businessDocument.lineItems.length) */
    aFinalCustomerChoiceIsMissing = missingUIChoiceOfFinalCustomer()
    anItemPriceIsMissing = missingUIALineItemPrice()
    anItemTitleIsMissing = missingUIALineItemTitle()

    canFinalizeAndTrackInvoice = 
    !!businessDocument.customerCustomer.company &&
    !!businessDocument.customerCustomer.company?.companyId &&
    (customer?.isPrivateIndividual || !!businessDocument.customerContact) &&
    !!accountCompanyLegalIdentificationTypeFRSIREN &&
    (customer?.isPrivateIndividual || !customer?.company.regulatory.isEuRegulated || (customer?.company.regulatory.isEuRegulated && !!customerCompanyLegalIdentificationTypeFRVAT)) &&
    isBankInformationComplete($WorkspaceStore) &&
    !!(
      businessDocument.subtotalExcludingTaxScaledValue !== 0 &&
      businessDocument.subtotalIncludingTaxScaledValue !== 0 &&
      businessDocument.businessDocumentStatus === BusinessDocumentStatus.DRAFT
    ) &&
    ((businessDocument.hasSpecificInstallment && businessDocument.installmentResultIncludingTaxScaledValue !== 0) ||
      !businessDocument.hasSpecificInstallment) &&
    rightInstallmentSignWithRegardsDocumentKind &&
    !aFinalCustomerChoiceIsMissing &&
    !anItemPriceIsMissing &&
    !anItemTitleIsMissing
  }

  /** Reactively update legalMentions whenever the dependencies change */
  $: if ($WorkspaceStore || businessDocument.currency) {
    /* console.error('%c VoxyInvoiceAddModal reactive I', 'color: orange; font-size: 1.2em', 'Action: update legal mentions on WorkspaceStore or businessDocument Currency change') */
    updateLegalMentions()
  }

  /** Show Discount Module & Update Discount Calculus */
  $: if (
    businessDocument.totalDiscountExcludingTaxChosenValue !== 0 &&
    businessDocument.totalDiscountExcludingTaxResultScaledValue !== 0
  ) {
    /* console.error('%c VoxyInvoiceAddModal reactive M', 'color: orange; font-size: 1.2em', 'Action: show discount component') */
    invoiceCollateralData.showDiscountModule = true
  }

  /** Show partial invoices that need balance invoices */
  $: if (!!businessDocument.customerCustomer.company) {
    /* console.error('%c VoxyInvoiceAddModal reactive P', 'color: orange; font-size: 1.2em', 'Action: listOfIncompleteBusinessDocumentsForChosenCustomer = listOfBusinessDocumentsWithDealHavingARemainingAmountToInvoiceForGivenCustomerCompany') */
    listOfIncompleteBusinessDocumentsForChosenCustomer =
      listOfBusinessDocumentsWithDealHavingARemainingAmountToInvoiceForGivenCustomerCompany(
        businessDocument.customerCustomer.company,
        get(SimpleDocumentsStore),
        businessDocument.businessDocumentKind,
      )
  } else {
    /* console.error('%c VoxyInvoiceAddModal reactive Q', 'color: orange; font-size: 1.2em', 'Action: reset listOfIncompleteBusinessDocumentsForChosenCustomer') */
    listOfIncompleteBusinessDocumentsForChosenCustomer = []
  }

  /** Call the generateLegalBoilerplateForAGivenLegalStructure function **/
  const updateLegalMentions = () => {
    const isEuropean:boolean = isClientCountryCodeAMemberOfEuropeanUnion(customer?.company?.mailAddress?.countryCode, currentProfileLanguage())
    
    /* console.error('%c VoxyInvoiceAddModal updateLegalMentions()', 'color: orange; font-size: 1.2em', 'Action: populate legal mentions to business document') */
    businessDocument.legalMentions = generateLegalBoilerplateForAGivenLegalStructure(
      $WorkspaceStore,
      accountCompanyLegalIdentificationTypeFRVAT,
      accountCompanyLegalIdentificationTypeFRSIREN,
      businessDocument.currency,
      customer?.isPrivateIndividual,
      isEuropean
    )
  }

  function updatingDocumentCheck() {
    /* console.error('%c VoxyInvoiceAddModal updatingDocumentCheck()', 'color: orange; font-size: 1.2em', '') */
    aFinalCustomerChoiceIsMissing = missingUIChoiceOfFinalCustomer()
    anItemPriceIsMissing = missingUIALineItemPrice()
    anItemTitleIsMissing = missingUIALineItemTitle()
  }

  function resetInstallmentAfterLineItemsChange() {
    /* console.error('%c VoxyInvoiceAddModal resetInstallmentAfterLineItemsChange()', 'color: orange; font-size: 1.2em', 'Action: set refreshInstallmentRelatedValuesAfterPotentialLineItemsChange to true to trigger it in BusinessDocumentInstallments.svelte') */

    refreshInstallmentRelatedValuesAfterPotentialLineItemsChange = true
    setTimeout(() => {
      refreshInstallmentRelatedValuesAfterPotentialLineItemsChange = false
    }, 50)
  }

  async function newResumePartialInvoicingFromSelectedRow(selectedDealIdForBalanceInvoiceCreation: string) {
    /* console.error('%c VoxyInvoiceAddModal newResumePartialInvoicingFromSelectedRow()', 'color: orange; font-size: 1.2em', 'Action: determine balance business document from selected deal') */

    isContentLoading = true
    if (!selectedDealIdForBalanceInvoiceCreation) {
      isContentLoading = false

      return
    }

    const newBalanceOfDealBusinessDocumentBuildingData: BalanceOfDealBusinessDocumentBuildingData =
      newBalanceBusinessDocumentAndCollateralDataFromUserSelectedDeal(
        selectedDealIdForBalanceInvoiceCreation,
        get(SimpleDocumentsStore),
        get(WorkspaceStore),
        get(CustomersStore),
        get(ContactsStore)
      )

    // TODO maybe replace store variable potential draft content with new item
    /* console.warn('a new document was built with installment data', 'installmentChosenValue', businessDocument.installmentChosenValue, 'installmentChosenKind', businessDocument.installmentChosenKind, 'installmentResultIncludingTaxScaledValue', businessDocument.installmentResultIncludingTaxScaledValue) */
    await tick()
    businessDocument = newBalanceOfDealBusinessDocumentBuildingData?.businessDocument
    // console.log(businessDocument, 'business Document from new deal')

    if (!invoicingBalanceLimits.balanceInvoicingMetricsUpdated) {
      // update invoicing balance limits (they depend on the currently edited draft balance or intermediate business document)
      invoicingBalanceLimits = newBalanceOfDealBusinessDocumentBuildingData.invoicingBalanceLimitsForBalanceInvoice
      /* console.log('new invoicingBalanceLimits', JSON.stringify(invoicingBalanceLimits, null, 3)) */
    }

    // extract the needed associated collateral data
    getVoxyInvoiceWithCollateralDataFromServerById(
      newBalanceOfDealBusinessDocumentBuildingData.collateralDataBusinessDocumentId,
    )
      .then((foundBusinessDocumentAndCollateralDataForId: BusinessDocumentAllDataPersisted) => {
        /* console.error('%c VoxyInvoiceAddModal newResumePartialInvoicingFromSelectedRow()', 'color: orange; font-size: 1.2em', 'Action: update collateral data of chosen business document') */
        const wipCollateralData: CollateralDocument = <CollateralDocument>(
          deepClone(foundBusinessDocumentAndCollateralDataForId.collateralDocument)
        )
        wipCollateralData.businessDocumentCollateralData.businessDocumentKind =
          newBalanceOfDealBusinessDocumentBuildingData.collateralDataBusinessDocumentKind
        if (!!wipCollateralData.businessDocumentCollateralData.paymentInformation) {
          wipCollateralData.businessDocumentCollateralData.paymentInformation.paymentURL = ''
        }
        wipCollateralData.businessDocumentCollateralData.businessDocumentNumber = ''
        wipCollateralData.businessDocumentCollateralData.isPaidWhenFinalized = false
        wipCollateralData.businessDocumentCollateralData.fileAttachment = null
        invoiceCollateralData = <BusinessDocumentCollateralData>(
          deepClone(wipCollateralData.businessDocumentCollateralData)
        )
        isContentLoading = false
      })
      .catch((err) => {
        feedbackService.displayFeedback(<Feedback>{
          feedbackLevel: 'Error',
          feedbackMessage: err.message || err.detail || err
        })

        isContentLoading = false
      })
  }

  /** Switch Tabs Route **/
  const getProps = ({ href, isPartiallyCurrent, isCurrent }) => {
    /* console.error('%c VoxyInvoiceAddModal getProps()', 'color: orange; font-size: 1.2em', 'Action: get url parameter args') */
    const isActive =
      href === '/receivables/edit/' + `${businessDocument.businessDocumentId}`
        ? isCurrent
        : isPartiallyCurrent || isCurrent
    const isAltActive = href === '/receivables/new/' ? isCurrent : isPartiallyCurrent || isCurrent
    if (isActive || isAltActive) {
      return { class: 'tab-link active' }
    }

    return { class: 'tab-link' }
  }

  /**
   * Close the panel
   */
  const close = (onlyClose:boolean) => {
    /* console.error('%c VoxyInvoiceAddModal close()', 'color: orange; font-size: 1.2em', 'Action: dispatch close window event if possible') */
    if (
      savingFinalizedToServer ||
      savingDraftToServer ||
      isFullSizeNumberingPanelActivated ||
      isFullSizePartialPanelActivated
    ) {
      return
    }

    if (!onlyClose && isCreditNote && businessDocument.relatedBusinessDocuments.length) {
      dispatch('saved', { type: 'voided' })
      
      return
    }
    
    dispatch('close')
  }

  /**
   * Activate full size panel
   * @param value
   * @param kind
   */
  const activateFullSizePanel = (value: boolean, kind: string) => {
    /* console.error('%c VoxyInvoiceAddModal activateFullSizePanel()', 'color: orange; font-size: 1.2em', 'Action: activate full size panel') */
    if (value && !isFullSizeNumberingPanelActivated && !isFullSizePartialPanelActivated) {
      disableDraftButton = true
      disableFinalizeButton = true

      isCloseable = false
      if (kind === 'number') {
        isFullSizeNumberingPanelActivated = true
      } else {
        isFullSizePartialPanelActivated = true
      }
    } else {
      isFullSizeNumberingPanelActivated = false
      isFullSizePartialPanelActivated = false
      if (canFinalizeAndTrackInvoice) {
        disableFinalizeButton = false
      }
      disableDraftButton = false
      isCloseable = true
    }
  }

  const uiDissociateUnlinkDeal = () => {
    /* console.error('%c VoxyInvoiceAddModal uiDissociateUnlinkDeal()', 'color: orange; font-size: 1.2em', 'Action: dissociate deal and reset business document but keep chosen customer') */
    businessDocument.lineItems = <BusinessDocumentLineItem[]>[
      VoxyHelper.newVoxyInvoiceItem(0, businessDocument.currency)
    ]

    businessDocument.relatedBusinessDocuments = <RelatedBusinessDocumentItem[]>[]
    businessDocument.linkedDeal = <DealDetails>{
      dealId: uuidv4()
    }

    invoicingBalanceLimits = getUpToDateInvoicingBalanceLimits(businessDocument.totalIncludingTaxScaledValue, 0, 0)
    invoicingBalanceLimits.balanceInvoicingMetricsUpdated = false

    businessDocument.hasSpecificInstallment = false
  }

  /**
   * Generate Payment Link with BridgeByBankin
   * @param voxyInvoiceToBeSaved
   * @param invoiceCollateralData
   */
  const safeConditionalBridgeByBankinPaymentLinkGenerationPromise = (
    voxyInvoiceToBeSaved: BusinessDocument,
    invoiceCollateralData: BusinessDocumentCollateralData,
  ) =>
  /* console.error('%c VoxyInvoiceAddModal safeConditionalBridgeByBankinPaymentLinkGenerationPromise()', 'color: orange; font-size: 1.2em', 'Action: get bank information and possible payment link') */

    new Promise<string>(function (resolve) {
      if (invoiceCollateralData.showPaymentLink && !isNegative(voxyInvoiceToBeSaved.totalIncludingTaxScaledValue)) {
        /* console.error('%c VoxyInvoiceAddModal safeConditionalBridgeByBankinPaymentLinkGenerationPromise()', 'color: orange; font-size: 1.2em', 'Action: generate payment link') */
        bbbAPIPrimitivesService.generatePaymentLinkStandardized(
            businessDocument.businessDocumentNumber,
            businessDocument.totalIncludingTaxScaledValue,
            businessDocument.customerCustomer.company?.formalName || "",
            t('todoAction.paymentDescription', { invoiceNumber: businessDocument.businessDocumentNumber }),
          )
          .then((link) => {
            /* console.error('%c VoxyInvoiceAddModal safeConditionalBridgeByBankinPaymentLinkGenerationPromise()', 'color: orange; font-size: 1.2em', 'Action: populate bbb link data') */

            resolve(link)
          })
          .catch((err) => {
            console.log(
              '%c " VoxyInvoiceAddModal safeConditionalBridgeByBankinPaymentLinkGenerationPromise() err: "+err.toString()',
              'color: red; font-size: 1.4em',
              err,
            )
            invoiceCollateralData.showPaymentLink = false
            resolve('')
          })
      } else {
        /* console.error('%c VoxyInvoiceAddModal safeConditionalBridgeByBankinPaymentLinkGenerationPromise()', 'color: orange; font-size: 1.2em', 'Action: no payment link to generate') */
        invoiceCollateralData.showPaymentLink = false
        resolve('')
      }
    })

  const checkBusinessDocumentExistBeforeClose = (type: string) =>
    new Promise(() => {
      let interval

      const clear = () => {
        clearInterval(interval)
        dispatch('saved', { type })
      }

      interval = setInterval(() => {
        if (isCatch) clear()
        if (!businessDocumentFetched) return

        const businessDocumentExist = get(SimpleDocumentsStore).some(
          (d) => d.businessDocumentId === businessDocument.businessDocumentId,
        )
        if (businessDocumentExist) clear()
      }, 1000)
    })

  /**
   * Save Invoice as Finalized
   */
  const saveAsFinalizedInvoice = () => {
    isCatch = false

    // LOG
    if (window.location.pathname.startsWith('/receivables/new/')) {
      if (businessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE) customLog.log('NewInvoiceIsFinalized', { businessDocument })
      if (businessDocument.businessDocumentKind === BusinessDocumentKind.CREDITNOTE) customLog.log('NewCreditNoteIsFinalized', { businessDocument })
    } else if (window.location.pathname.startsWith('/receivables/edit/')) {
      if (businessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE) customLog.log('DraftInvoiceIsFinalized', { businessDocument })
      if (businessDocument.businessDocumentKind === BusinessDocumentKind.CREDITNOTE) customLog.log('DraftCreditNoteIsFinalized', { businessDocument })
    }

    let now = new Date()
    now.setSeconds(now.getSeconds() + 17)
    invoiceCollateralData.savingStateExpiration = Math.floor(now.getTime() / 1000)

    /* console.error('%c VoxyInvoiceAddModal saveAsFinalizedInvoice()', 'color: orange; font-size: 1.2em', 'Action: save and finalize business document') */
    if (!canFinalizeAndTrackInvoice) {
      /* console.error('%c VoxyInvoiceAddModal saveAsFinalizedInvoice()', 'color: orange; font-size: 1.2em', 'Action: cannot finalize business document, not ready') */
      feedbackService.displayFeedback(<Feedback>{
        feedbackLevel: 'Info',
        feedbackMessage: t('invoices.createInvoice.errorSavingFinal')
      })

      return
    }

    /** TODO workaround until we have a reactive architecture between the front-end and the back-end (websocket or equivalent) **/
    lockWindowClose()
    eventsManager.emit(
      EventType.AGGRID_ADD_TEMPORARY_DOCUMENT,
      {
        businessDocument,
        invoiceCollateralData
      },
      'VoxyInvoiceAddModal',
    )

    businessDocumentFetched = false
    businessDocument.businessDocumentStatus = BusinessDocumentStatus.FINAL
    checkBusinessDocumentExistBeforeClose(businessDocument?.businessDocumentKind.toLowerCase())
    eventsManager.once(
      EventType.BUSINESS_DOCUMENTS_LIST_FETCHED,
      () => (businessDocumentFetched = true),
      'VoxyInvoiceAddModal',
    )

    savingFinalizedToServer = true
    disableDraftButton = true

    const voxyInvoiceToBeSaved = <BusinessDocument>{
      ...deepClone(businessDocument),
      businessDocumentStatus: BusinessDocumentStatus.FINAL
    }

    /* console.error('%c VoxyInvoiceAddModal saveAsFinalizedInvoice()', 'color: orange; font-size: 1.2em', 'Action: get bank data and payment link for pdf') */
    safeConditionalBridgeByBankinPaymentLinkGenerationPromise(voxyInvoiceToBeSaved, invoiceCollateralData)
      .then((bbbLink: string) => {
        if (!invoiceCollateralData.paymentInformation) {
          invoiceCollateralData.paymentInformation = <PaymentLink>{}
        }
        invoiceCollateralData.paymentInformation.paymentURL = bbbLink === '' ? 'no payment link' : bbbLink
        /* console.error('%c VoxyInvoiceAddModal saveAsFinalizedInvoice()', 'color: orange; font-size: 1.2em', 'Action: bank data and payment link updated, now save to server and get number in return') */
        saveVoxyInvoiceToServer(voxyInvoiceToBeSaved)
          .then(() => {
            /* console.error('%c VoxyInvoiceAddModal saveAsFinalizedInvoice()', 'color: orange; font-size: 1.2em', 'Action: save collateral data') */
            saveVoxyBusinessDocumentCollateralDataToServer(invoiceCollateralData)
              .then(() => {
                /** TODO workaround until we have a reactive architecture between the front-end and the back-end (websocket or equivalent) **/
                /* console.error('%c VoxyInvoiceAddModal saveAsFinalizedInvoice()', 'color: orange; font-size: 1.2em', 'Action: subscribe to INVOICE_LIST_FETCHED and get ready to create pdf consequently and save it') */
                const eventListFetched = eventsManager.on<Invoice[]>(
                  EventType.INVOICE_LIST_FETCHED,
                  (e) => {
                    /* console.log('important received once EventType.INVOICE_LIST_FETCHED') */
                    if (!e.data.find(i => i.dataId === voxyInvoiceToBeSaved.businessDocumentId)) return
                    DoNotPersistInvoicesStoreChangeOnce.set(true)
                    dunningInvoicesService.setInvoicesStoreToFetchedInvoiceListOnlyWheneverMostUpToDate(e.data)
                    eventListFetched()
                  },
                  'VoxyInvoiceAddModal.svelte > saveAsFinalizedInvoice',
                )

                /* console.error('%c VoxyInvoiceAddModal saveAsFinalizedInvoice()', 'color: orange; font-size: 1.2em', 'Action: in INVOICE_LIST_FETCHED load fresh finalized business document') */
                getVoxyInvoiceFromServer(voxyInvoiceToBeSaved.businessDocumentId)
                  .then((readBackSavedBusinessDocumentResponse) => {
                    if (
                      readBackSavedBusinessDocumentResponse.businessDocument.businessDocumentStatus ===
                      BusinessDocumentStatus.FINAL
                    ) {
                      /* console.error('%c VoxyInvoiceAddModal saveAsFinalizedInvoice()', 'color: orange; font-size: 1.2em', 'Action: in INVOICE_LIST_FETCHED is finalized, start pdf') */
                      createPDFAndHandleFileUpload(
                        invoiceCollateralData.isPaidWhenFinalized,
                        invoiceCollateralData.paymentInformation.paymentURL,
                        readBackSavedBusinessDocumentResponse.businessDocument,
                        businessDocument?.accountCompany?.regulatory?.frRegulScope?.siret ?? '',
                        businessDocument?.accountCompany?.regulatory?.euRegulScope?.euIntraVat ?? '',
                        invoiceCollateralData.showPaymentLink
                      )

                      if (
                        readBackSavedBusinessDocumentResponse.businessDocument.businessDocumentKind ===
                        BusinessDocumentKind.INVOICE
                      ) {
                        feedbackService.displayFeedback(<Feedback>{
                          feedbackMessage:
                            '<div class="flex items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M18.2499 14C18.2499 18 15.5 19.25 11.9999 19.25C8 19.25 5.75 16.4 5.75 14C5.75 11.6 7 9.41667 8 8.75C8 11.55 10.6666 13.3333 11.9999 13.25C9.59994 9.65 11.6666 5.66667 12.9999 4.75C12.9999 9.25 18.2499 10 18.2499 14Z" stroke="#FFE6A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg> \n' +
                            '         <p>' +
                            t('invoices.createInvoice.successfullySavedFinalInvoice') +
                            '</p></div>',
                          feedbackLevel: 'Success'
                        })
                      } else if (
                        readBackSavedBusinessDocumentResponse.businessDocument.businessDocumentKind ===
                        BusinessDocumentKind.CREDITNOTE
                      ) {
                        feedbackService.displayFeedback(<Feedback>{
                          feedbackMessage:
                            '<div class="flex items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M18.2499 14C18.2499 18 15.5 19.25 11.9999 19.25C8 19.25 5.75 16.4 5.75 14C5.75 11.6 7 9.41667 8 8.75C8 11.55 10.6666 13.3333 11.9999 13.25C9.59994 9.65 11.6666 5.66667 12.9999 4.75C12.9999 9.25 18.2499 10 18.2499 14Z" stroke="#FFE6A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg> \n' +
                            '         <p>' +
                            t('invoices.createInvoice.successfullySavedFinalCreditNote') +
                            '</p></div>',
                          feedbackLevel: 'Success'
                        })
                      }

                      setTimeout(() => {
                        DoNotPersistInvoicesStoreChangeOnce.set(true)
                        /* console.log('request to update all when saving voxy while creating voxy PDF') */
                        eventsManager.emit(
                          EventType.REQUEST_INVOICE_LIST_AND_COMPUTED_INVOICE_LIST_TO_REFRESH,
                          null,
                          'VoxyInvoiceAddModal.svelte',
                        )
                      }, 16000)

                      /* console.log('Saving as FINALIZED response', readBackSavedBusinessDocumentResponse) */
                    } else {
                      /* console.error('%c VoxyInvoiceAddModal saveAsFinalizedInvoice()', 'color: orange; font-size: 1.2em', 'Action: in INVOICE_LIST_FETCHED is not finalized yet, navigate to list of invoices and ask to refresh dundy docs') */
                      businessDocumentsListViewChoice.set(BusinessDocumentViewListOption.INVOICES)
                      navigate('/invoices')
                      savingFinalizedToServer = false
                      disableDraftButton = false
                      setTimeout(() => {
                        /* console.log('request to update all when voxy saved as final but error when reading it back (non final when read back)') */
                        eventsManager.emit(
                          EventType.REQUEST_INVOICE_LIST_AND_COMPUTED_INVOICE_LIST_TO_REFRESH,
                          null,
                          'VoxyInvoiceAddModal.svelte',
                        )
                      }, 16000)
                      feedbackService.displayFeedback(<Feedback>{
                        feedbackLevel: 'Error',
                        feedbackMessage: t('invoices.createInvoice.invoiceNotFinalized')
                      })
                      /* console.log('Error saving as Finalized Response', readBackSavedBusinessDocumentResponse) */
                    }
                  })
                  .catch((e) => {
                    console.error('VoxyInvoiceAddModal getVoxyInvoiceFromServer', JSON.stringify(e))
                    // console.error('%c VoxyInvoiceAddModal saveAsFinalizedInvoice()', 'color: orange; font-size: 1.2em', 'Action: in INVOICE_LIST_FETCHED error when finalizing, err=' + e)
                    isCatch = true
                    feedbackService.displayFeedback(<Feedback>{
                      feedbackLevel: 'Error',
                      feedbackMessage: e.message || e.detail || e
                    })
                  })
                  .finally(() => {
                    voxyInvoicingService.fetchBusinessDocumentsAndRefreshStore()
                    voxyPreferencesService.getVoxyPreferencesFromBackEndAPI(
                      'voxy-action-pipeline.store.ts - refresh store when pipeline is empty',
                    )
                    lockWindowClose(false)
                  })

                /* console.error('%c VoxyInvoiceAddModal saveAsFinalizedInvoice()', 'color: orange; font-size: 1.2em', 'Action: subscribe to COMPUTED_INVOICE_LIST_FETCHED and update computed data invoice store') */
                eventsManager.once<ComputedDataInvoice[]>(
                  EventType.COMPUTED_INVOICE_LIST_FETCHED,
                  (e) => {
                    /* console.error('%c VoxyInvoiceAddModal saveAsFinalizedInvoice()', 'color: orange; font-size: 1.2em', 'Action: in COMPUTED_INVOICE_LIST_FETCHED: updating computed data invoice store') */
                    ComputedDataInvoicesStore.set(e.data)
                  },
                  'VoxyInvoiceAddModal.svelte',
                )
              })
              .catch((err) => {
                isCatch = true
                console.error('VoxyInvoiceAddModal saveVoxyBusinessDocumentCollateralDataToServer', err)
              })
          })
          .catch((e) => {
            console.error('VoxyInvoiceAddModal saveVoxyInvoiceToServer', e)
            /* console.error('%c VoxyInvoiceAddModal saveAsFinalizedInvoice()', 'color: orange; font-size: 1.2em', 'Action: promise error, err=' + e) */
            isCatch = true
            savingFinalizedToServer = false
            disableDraftButton = false
            feedbackService.displayFeedback(<Feedback>{
              feedbackLevel: 'Error',
              feedbackMessage: e.message || e.detail || e
            })
          })
      })
      .catch((err) => {
        isCatch = true
        console.error('VoxyInvoiceAddModal safeConditionalBridgeByBankinPaymentLinkGenerationPromise', err)
      })
  }

  /**
   * Save the invoice as a draft
   */
  const saveAsDraftInvoice = () => {
    isCatch = false
    lockWindowClose()

    // LOG
    if (businessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE) customLog.log('DraftInvoiceSaved', { businessDocument })
    if (businessDocument.businessDocumentKind === BusinessDocumentKind.CREDITNOTE) customLog.log('DraftCreditNoteSaved', { businessDocument })

    /** TODO workaround until we have a reactive architecture between the front-end and the back-end (websocket or equivalent) **/
    businessDocumentFetched = false
    eventsManager.once(
      EventType.BUSINESS_DOCUMENTS_LIST_CHANGED,
      () => (businessDocumentFetched = true),
      'VoxyInvoiceAddModal',
    )
    checkBusinessDocumentExistBeforeClose(
      businessDocument?.businessDocumentKind === BusinessDocumentKind.INVOICE
        ? 'draft'
        : businessDocument?.businessDocumentKind.toLowerCase(),
    )

    // Estimated backend processing time
    let now = new Date()
    now.setSeconds(now.getSeconds() + 4)
    invoiceCollateralData.savingStateExpiration = Math.floor(now.getTime() / 1000)

    /* console.log('business document to draft save', businessDocument) */
    /* console.error('%c VoxyInvoiceAddModal saveAsDraftInvoice()', 'color: orange; font-size: 1.2em', 'Action: saving draft of business document') */
    savingDraftToServer = true
    saveVoxyInvoiceToServer(businessDocument)
      .then(() => {
        /* console.error('%c VoxyInvoiceAddModal saveAsDraftInvoice()', 'color: orange; font-size: 1.2em', 'Action: saving draft of business document') */
        /* console.log('invoiceCollateralData to draft save', JSON.stringify(invoiceCollateralData, null, 3)) */
        saveVoxyBusinessDocumentCollateralDataToServer(invoiceCollateralData)
          .then(() => {
            /** TODO workaround until we have a reactive architecture between the front-end and the back-end (websocket or equivalent) **/
            /* console.error('%c VoxyInvoiceAddModal saveAsDraftInvoice()', 'color: orange; font-size: 1.2em', 'Action: draft saved, refresh lists') */
            const eventListFetched = eventsManager.on<Invoice[]>(
              EventType.INVOICE_LIST_FETCHED,
              (e) => {
                /* console.log('important received once EventType.INVOICE_LIST_FETCHED') */
                if (!e.data.find(i => i.dataId === businessDocument.businessDocumentId)) return
                DoNotPersistInvoicesStoreChangeOnce.set(true)
                dunningInvoicesService.setInvoicesStoreToFetchedInvoiceListOnlyWheneverMostUpToDate(e.data)
                eventListFetched()
              },
              'VoxyInvoiceAddModal.svelte > saveAsDraftInvoice',
            )

            let toBeSavedBusinessDocument: BusinessDocument = businessDocument

            getVoxyInvoiceFromServer(toBeSavedBusinessDocument.businessDocumentId)
              .then(() => {
                /** TODO workaround until we have a reactive architecture between the front-end and the back-end (websocket or equivalent) **/
                setTimeout(() => {
                  /* console.log('request to update all when saving voxy as draft: after saving voxy then saving its collateral, then reading it back') */
                  DoNotPersistInvoicesStoreChangeOnce.set(true)
                  /* eventsManager.emit(EventType.REQUEST_INVOICE_LIST_AND_COMPUTED_INVOICE_LIST_TO_REFRESH, null, 'VoxyInvoiceAddModal.svelte'); */
                  savingDraftToServer = false
                  businessDocumentsListViewChoice.set(BusinessDocumentViewListOption.DRAFT)
                }, 2000)
                feedbackService.displayFeedback(<Feedback>{
                  feedbackMessage:
                    '<div class="flex items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M18.2499 14C18.2499 18 15.5 19.25 11.9999 19.25C8 19.25 5.75 16.4 5.75 14C5.75 11.6 7 9.41667 8 8.75C8 11.55 10.6666 13.3333 11.9999 13.25C9.59994 9.65 11.6666 5.66667 12.9999 4.75C12.9999 9.25 18.2499 10 18.2499 14Z" stroke="#FFE6A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg> \n' +
                    '         <p>' +
                    t('invoices.createInvoice.successfullySavedDraft') +
                    '</p></div>',
                  feedbackLevel: 'Success'
                })
                /* console.log('Saving as draft response', response) */
                if (window.location.pathname.indexOf('new') > -1) {
                  window.history.pushState(
                    '',
                    '',
                    '/receivables/edit/' + `${toBeSavedBusinessDocument.businessDocumentId}/details`,
                  )
                }
              })
              .catch((error) => {
                isCatch = true
                /* console.error('%c VoxyInvoiceAddModal saveAsDraftInvoice()', 'color: orange; font-size: 1.2em', 'Action: err while saving draft in promise , err=' + e) */
                feedbackService.displayFeedback(<Feedback>{
                  feedbackLevel: 'Error',
                  feedbackMessage: error.message || error.detail || error
                })
              })
              .finally(() => {
                voxyInvoicingService.fetchBusinessDocumentsAndRefreshStore()
                lockWindowClose(false)
              })
          })
          .catch((err) => {
            isCatch = true
            console.error('saveAsDraftInvoice catch', err)
          })
      })
      .catch((e) => {
        isCatch = true

        /* console.error('%c VoxyInvoiceAddModal saveAsDraftInvoice()', 'color: orange; font-size: 1.2em', 'Action: err while saving draft , err=' + e) */
        savingDraftToServer = false
        feedbackService.displayFeedback(<Feedback>{
          feedbackLevel: 'Error',
          feedbackMessage: e.message || e.detail || e
        })
      })
  }

  const missingUIALineItemTitle = () =>
    /* console.error('%c VoxyInvoiceAddModal missingUIALineItemTitle()', 'color: orange; font-size: 1.2em', 'Action: refresh if missing item title') */
    !businessDocument.lineItems || businessDocument.lineItems.find((l) => l.title === '')

  const missingUIALineItemPrice = () =>
    /* console.error('%c VoxyInvoiceAddModal missingUIALineItemPrice()', 'color: orange; font-size: 1.2em', 'Action: refresh if missing item price') */
    !businessDocument.lineItems || businessDocument.lineItems.find((l) => l.itemPrice.scaledValue === 0)

  const missingUIChoiceOfFinalCustomer = () =>
    /* console.error('%c VoxyInvoiceAddModal missingUIChoiceOfFinalCustomer()', 'color: orange; font-size: 1.2em', 'Action: refresh if missing customer choice') */
    !businessDocument.customerCustomer.company ||
    !businessDocument.customerCustomer.company?.companyId ||
    businessDocument.customerCustomer.company?.companyId === ''

  /**
   * Add a blank BusinessDocumentLineItem to the invoice
   * @param index
   */
  const addInvoiceRow = (index: number): void => {
    /* console.error('%c VoxyInvoiceAddModal addInvoiceRow()', 'color: orange; font-size: 1.2em', 'Action: add blank business document item') */
    let blankRow: BusinessDocumentLineItem = VoxyHelper.newVoxyInvoiceItem(index, businessDocument.currency)
    businessDocument.lineItems = [...businessDocument.lineItems, blankRow]
  }

  /**
   * Remove a BusinessDocumentLineItem from the invoice
   * @param id
   */
  const removeInvoiceRow = (id: string) =>
    /* console.error('%c VoxyInvoiceAddModal removeInvoiceRow()', 'color: orange; font-size: 1.2em', 'Action: removing business document item') */
    (businessDocument.lineItems = businessDocument.lineItems.filter((r) => r.businessDocumentLineItemId !== id))

  onMount(() => {
    mixpanel.track('IC10 Voxy Invoices Add Modal', {
      'Description': 'Open VoxyInvoiceAddModal.svelte'
    })

    // LOG
    if (window.location.pathname === '/receivables/new/commercial-invoice/details') customLog.log('NewInvoiceOpened')
    if (window.location.pathname === '/receivables/new/commercial-credit-note/details') customLog.log('NewCreditNoteOpened')

    /* console.error('%c VoxyInvoiceAddModal onMount()', 'color: orange; font-size: 1.2em', '') */
    isContentLoading = true
    if (!!unsubscribeToVoxyPreferences) {
      unsubscribeToVoxyPreferences()
    }

    unsubscribeToVoxyPreferences = VoxyPreferencesStore.subscribe((newVoxyPreferences: VoxyPreferences) => {
      favoriteBusinessDocumentLineItems =
        !!newVoxyPreferences?.favoriteBusinessDocumentLineItems
        && !!newVoxyPreferences?.favoriteBusinessDocumentLineItems.length
          ? newVoxyPreferences?.favoriteBusinessDocumentLineItems
          : []
      /* console.log('favoriteBusinessDocumentLineItems from VoxyInvoiceAddModal', favoriteBusinessDocumentLineItems) */
    })

    // if we come from today page : partial-invoices
    const dealId = new URL(window.location).searchParams?.get('deal-id')
    if (dealId) {
      newResumePartialInvoicingFromSelectedRow(dealId)

      return
    }

    if (!invoiceId) {
      isContentLoading = false

      return
    }

    // if we come from invoices page
    getBusinessDocumentWithCollateralDataById(invoiceId)
      .then((persistedBusinessDocumentResponse: BusinessDocumentAllDataPersisted) => {
        if (
          persistedBusinessDocumentResponse.coreDocument.businessDocument.businessDocumentStatus !==
          BusinessDocumentStatus.DRAFT
        ) {
          feedbackService.displayFeedback(<Feedback>{
            feedbackLevel: 'Error',
            feedbackMessage: t('invoices.editInvoice.invoiceNotDraft')
          })
          navigate(`/receivables/view/${invoiceId}/details`)
        }

        /* console.log('BusinessDocumentFromAPI', persistedBusinessDocumentResponse.coreDocument.businessDocument) */
        businessDocument = persistedBusinessDocumentResponse.coreDocument.businessDocument
        invoiceCollateralData = persistedBusinessDocumentResponse.collateralDocument.businessDocumentCollateralData

        // LOG
        if (window.location.pathname.startsWith('/receivables/edit/')) {
          if (businessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE) customLog.log('DraftInvoiceOpened', { businessDocument })
          if (businessDocument.businessDocumentKind === BusinessDocumentKind.CREDITNOTE) customLog.log('DraftCreditNoteOpened', { businessDocument })
        }

        isContentLoading = false
      })
      .catch(() => {
        /* console.log('Error fetching businessDocument') */
        isContentLoading = false
        feedbackService.displayFeedback(<Feedback>{
          feedbackLevel: 'Error',
          feedbackMessage: t('invoices.editInvoice.invoiceNotFound')
        })
      })
  })

  onDestroy(() => {
    /* console.error('%c VoxyInvoiceAddModal onDestroy()', 'color: orange; font-size: 1.2em', '') */
    isContentLoading = false
  })
</script>

<FullSizeModal class="w-full overflow-hidden h-full" {isCloseable} on:close={() => close(true)}>
  <div
    class="grid w-full h-full"
    class:grid-cols-2={!isContentLoading}
    slot="content"
    style={!isContentLoading ? 'grid-template-columns: 580px 1fr;' : ''}
  >
    {#if isContentLoading}
      <div class="grid h-screen place-items-center w-full z-[100]">
        <Loader />
      </div>
      <div class="absolute top-0 left-0 w-full h-full opacity-20 bg-whisper z-50 min-h-screen"></div>
    {:else}
      <div
        class="flex flex-col {isFullSizePartialPanelActivated
          ? ''
          : 'overflow-y-auto overflow-x-auto'}  row-span-1 relative z-0"
      >
        {#if isFullSizeNumberingPanelActivated}
          <BusinessDocumentNumberPreferences
            documentKind={businessDocument.businessDocumentKind}
            on:deactivateInvoiceNumberingWindow={() => activateFullSizePanel(false, 'number')}
          />
        {:else if isFullSizePartialPanelActivated}
          <PreviousPartialBusinessDocumentsForCustomerPanel
            listOfPreviousPartialBusinessDocumentsForCustomer={listOfIncompleteBusinessDocumentsForChosenCustomer}
            on:closeDealsAvailableForInvoicingSelectionWindow={() => activateFullSizePanel(false, 'partial')}
            on:closeDealSelectionWindowAndApplyNewSelectedDealForBalanceInvoiceEdition={(
              selectedDealIdForBalanceInvoiceCreationCustomEvent,
            ) => newResumePartialInvoicingFromSelectedRow(selectedDealIdForBalanceInvoiceCreationCustomEvent.detail)}
          />
        {:else}
          <div class="pt-12 pb-10">
            {#if invoiceId}
              {#if isInvoice}
                <h1 class="font-bold text-4xl px-12 mb-4">{t('invoices.createInvoice.draftInvoiceHeader')}</h1>
              {:else if isCreditNote}
                <h1 class="font-bold text-4xl px-12 mb-4">{t('invoices.createCreditNote.draftCreditNoteHeader')}</h1>
              {/if}
            {:else if isInvoice}
              <h1 class="font-bold text-4xl px-12 mb-4">{t('invoices.createInvoice.newInvoiceHeader')}</h1>
            {:else if isCreditNote}
              <h1 class="font-bold text-4xl px-12 mb-4">{t('invoices.createCreditNote.newCreditNoteHeader')}</h1>
            {/if}

            <div class="sm:hidden">
              <label for="tabs" class="sr-only">Select a tab</label>
              <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
              <select
                id="tabs"
                name="tabs"
                class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              >
                <option>{t('invoices.viewInvoice.menu.itemDetails')}</option>
                <!--<option>Paiement</option>-->
              </select>
            </div>

            <div class="hidden sm:block">
              <div class="border-b border-gray-200">
                <nav class="-mb-px px-12 flex space-x-8" aria-label="Tabs">
                  {#if invoiceId}
                    <Link to="/receivables/edit/{businessDocument.businessDocumentId}/details" {getProps}>
                      {t('invoices.viewInvoice.menu.itemDetails')}
                    </Link>
                    {#if businessDocument.relatedBusinessDocuments.length}
                      <Link to="/receivables/edit/{businessDocument.businessDocumentId}/documents" {getProps}>
                        {t('invoices.createInvoice.linkedDocuments_one', {
                          count: businessDocument.relatedBusinessDocuments.length
                        })}
                        <span
                          class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800 ml-2"
                          >{businessDocument.relatedBusinessDocuments.length}</span
                        >
                      </Link>
                    {/if}
                    <Link to="/receivables/edit/{businessDocument.businessDocumentId}/settings" {getProps}>
                      {t('invoices.viewInvoice.menu.itemSettings')}
                    </Link>
                  {:else}
                    <Link to="/receivables/new/{businessDocumentSlug}/details" {getProps}>
                      {t('invoices.viewInvoice.menu.itemDetails')}
                    </Link>
                    <Link to="/receivables/new/{businessDocumentSlug}/settings" {getProps}>
                      {t('invoices.viewInvoice.menu.itemSettings')}
                    </Link>
                  {/if}
                </nav>
              </div>
            </div>

            <Router>
              <Route path="details">
                {#if isCreditNote}
                  <CreditNoteInformationNotice bind:businessDocument />
                {/if}
                <div class="flex flex-1 flex-col justify-between px1-2">
                  <div class="divide-y divide-gray-200 sm:px-6">
                    <div class="space-y-6 py-7">
                      <div class="px-12 sm:px-6">
                        <BusinessDocumentFormCustomerAndContact bind:businessDocument />
                      </div>
                      {#if listOfIncompleteBusinessDocumentsForChosenCustomer && listOfIncompleteBusinessDocumentsForChosenCustomer.length > 0 && !isCreditNote}
                        <div class="px-12 sm:px-6" in:fly={{ y: 25, duration: 500 }}>
                          <PreviousPartialBusinessDocumentsForCustomer
                            on:openDealsAvailableForInvoicingSelectionWindow={() =>
                              activateFullSizePanel(true, 'partial')}
                            on:dissociateLinkedDeal={() => uiDissociateUnlinkDeal()}
                            showDissociateButton={hasAtLeastAnotherFinalizedBusinessDocumentWithSameDeal(
                              businessDocument,
                              get(SimpleDocumentsStore),
                            )}
                          />
                        </div>
                      {/if}
                    </div>
                  </div>

                  <div class="divide-y divide-gray-200 border-t border-whisper sm:px-6">
                    <div class="space-y-6 py-7">
                      <div class="px-12 sm:px-6">
                        <h2 class="block text-2xl font-bold text-gray-900 mb-3">
                          {#if isCreditNote}
                            {t('invoices.createCreditNote.creditNoteDetailsTitle')}
                          {:else if isInvoice}
                            {t('invoices.createInvoice.invoiceDetailsTitle')}
                          {:else if isProformaInvoice}
                            {t('invoices.createInvoice.invoiceDetailsTitle')}
                          {/if}
                        </h2>
                        <fieldset class="grid grid-cols-2 gap-x-6 gap-y-4">
                          <BusinessDocumentNumber
                            documentKind={businessDocument.businessDocumentKind}
                            bind:businessDocument
                            on:activateInvoiceNumberingWindow={() => activateFullSizePanel(true, 'number')}
                          />
                          <BusinessDocumentIssuedAndDueDates bind:businessDocument />
                        </fieldset>
                      </div>
                    </div>
                  </div>

                  <div class="divide-y divide-gray-200 border-t border-whisper sm:px-6">
                    <div class="space-y-6 py-7">
                      <div class="px-12 sm:px-6">
                        <h2 class="block text-2xl font-bold text-gray-900 mb-3">
                          {t('invoices.createInvoice.itemsTitle')}
                        </h2>
                        {#if !businessDocument || !businessDocument?.lineItems}
                          <div class="no-data-wrapper">{t('invoices.createInvoice.emptyItemsState')}</div>
                        {:else}
                          {#each businessDocument?.lineItems as item, index}
                            <BusinessDocumentFormItem
                              isLocked={isLockedLineItem(
                                item.businessDocumentLineItemId,
                                businessDocument,
                                get(SimpleDocumentsStore),
                              )}
                              totalItemsLength={businessDocument?.lineItems.length}
                              bind:item
                              {favoriteBusinessDocumentLineItems}
                              {index}
                              removeRow={removeInvoiceRow}
                            />
                          {:else}
                            <div class="no-data-wrapper">{t('invoices.createInvoice.emptyItemsState')}</div>
                          {/each}
                        {/if}
                        {#if invoiceCollateralData.showDiscountModule}
                          <BusinessDocumentFormDiscount
                            bind:businessDocument
                            on:removeDiscountModule={() => {
                              invoiceCollateralData.showDiscountModule = false
                              businessDocument.totalDiscountExcludingTaxChosenUnit = ''
                              businessDocument.totalDiscountExcludingTaxChosenValue = 0
                              businessDocument.totalDiscountExcludingTaxDescription = ''
                              businessDocument.totalDiscountExcludingTaxResultScaledValue = 0
                            }}
                          />
                        {/if}
                        <div class="flex-row">
                          {#if wrongInvoiceGlobalAmountSignWithRegardsDocumentKind}
                            <div class="btn w-fit text-xs text-dundyOrange">
                              {t('invoices.createInvoice.totalOfInvoiceShouldBePositive')}
                            </div>
                          {/if}
                          {#if wrongCreditNoteGlobalAmountSignWithRegardsDocumentKind}
                            <div class="btn w-fit text-xs text-dundyOrange">
                              {t('invoices.createInvoice.totalOfCreditNoteShouldBeNegative')}
                            </div>
                          {/if}
                          {#if aFinalCustomerChoiceIsMissing}
                            <div class="btn w-fit text-xs text-dundyOrange">
                              {t('invoices.createInvoice.mustDefineAFinalCustomer')}
                            </div>
                          {/if}
                          {#if anItemPriceIsMissing}
                            <div class="btn w-fit text-xs text-dundyOrange">
                              {t('invoices.createInvoice.aLineItemPriceIsMissing')}
                            </div>
                          {/if}
                          {#if anItemTitleIsMissing}
                            <div class="btn w-fit text-xs text-dundyOrange">
                              {t('invoices.createInvoice.aLineItemTitleIsMissing')}
                            </div>
                          {/if}
                          {#if installmentAmountIsVeryCloseToFullBusinessDocumentAmount}
                            <div class="btn w-fit text-xs text-dundyOrange">
                              {t('invoices.createInvoice.switchToFullToSetTheFullAmount')}
                            </div>
                          {/if}
                        </div>
                        <div class="flex justify-between items-center mt-6">
                          {#if !isLockedLineItemListInBusinessDocument(businessDocument, get(SimpleDocumentsStore))}
                            <button
                              on:click={() => addInvoiceRow(businessDocument.lineItems.length)}
                              class="flex items-center justify-center transition hover:bg-whisper text-gray-700 py-2 px-4 text-sm border border-gray-300 rounded shadow-sm"
                            >
                              <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.5"
                                  d="M12 5.75V18.25"
                                ></path>
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.5"
                                  d="M18.25 12L5.75 12"
                                ></path>
                              </svg>
                              {t('invoices.createInvoice.addLine')}
                            </button>
                            <button
                              class="btn text-xs text-dundyOrange"
                              on:click={() => (invoiceCollateralData.showDiscountModule = true)}
                              disabled={invoiceCollateralData.showDiscountModule}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.75 11.25H18.25V17.25C18.25 18.3546 17.3546 19.25 16.25 19.25H7.75C6.64543 19.25 5.75 18.3546 5.75 17.25V11.25Z"
                                  stroke="currentColor"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M4.75 7.75H19.25V11.25H4.75V7.75Z"
                                  stroke="currentColor"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M12 19V11.5"
                                  stroke="currentColor"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M12.5 7.5L15.25 4.75"
                                  stroke="currentColor"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M11.5 7.5L8.75 4.75"
                                  stroke="currentColor"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                              {t('invoices.createInvoice.addDiscount')}
                            </button>
                          {/if}
                        </div>
                      </div>
                    </div>
                  </div>
                  {#if businessDocument?.businessDocumentKind === BusinessDocumentKind.INVOICE}
                    <BusinessDocumentInstallments
                      bind:businessDocument
                      bind:invoicingBalanceLimits
                      {refreshInstallmentRelatedValuesAfterPotentialLineItemsChange}
                    />
                  {:else}
                    <BusinessDocumentInstallments
                      {businessDocument}
                      bind:invoicingBalanceLimits
                      {refreshInstallmentRelatedValuesAfterPotentialLineItemsChange}
                    />
                  {/if}

                  <BusinessDocumentPaymentConditions bind:businessDocument bind:invoiceCollateralData />
                </div>
              </Route>
              <Route path="documents">
                {#if businessDocument.relatedBusinessDocuments.length}
                  <BusinessDocumentRelatedDocumentsPanel bind:businessDocument />
                {/if}
              </Route>
              <Route path="settings">
                <BusinessDocumentSettingsPanel bind:businessDocument />
              </Route>
            </Router>
          </div>
        {/if}
      </div>

      <aside class="bg-slate-50 border-l border-whisper relative overflow-y-auto">
        <div
          class="fixed top-0 w-9/12 z-20 text-right py-3.5 bg-white border-b border-whisper flex flex-col justify-center items-center"
          style="width: calc(100% - 580px);"
        >
          <div>
            {#if isCreditNote && businessDocument.relatedBusinessDocuments.length}
              <div class="warn">
                {t('invoices.cancelInvoice.warn')}
              </div>
            {/if}
            <div class="flex justify-center">
              
              <button
                class="btn action-cancel"
                disabled={disableDraftButton}
                on:click|preventDefault={saveAsDraftInvoice}
              >
                {#if !savingDraftToServer}
                  {t('invoices.createInvoice.saveAsDraftButton')}
                {:else}
                  <svg
                    class="animate-spin -ml-1 mr-3 h-5 w-5 text-dundyOrange"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  {t('invoices.createInvoice.savingInProgress')}
                {/if}
              </button>

              <button
                class="ml-6 inline-flex items-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 disabled:bg-transparent disabled:border-athensGray disabled:text-loblolly disabled:shadow-none disabled:cursor-not-allowed"
                on:click|preventDefault={saveAsFinalizedInvoice}
                disabled={disableFinalizeButton}
              >
                {#if !savingFinalizedToServer}
                  {#if businessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE}
                    {t('invoices.createInvoice.saveAndTrackInvoiceButton')}
                  {:else if businessDocument.businessDocumentKind === BusinessDocumentKind.CREDITNOTE}
                    {t('invoices.createInvoice.saveAsCreditNoteButton')}
                  {/if}
                {:else}
                  <svg
                    class="animate-spin -ml-1 mr-3 h-5 w-5 text-dundyOrange"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  {#if businessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE}
                    {t('invoices.createInvoice.finalizingInvoiceInProgress')}
                  {:else if businessDocument.businessDocumentKind === BusinessDocumentKind.CREDITNOTE}
                    {t('invoices.createInvoice.finalizingCreditNoteInProgress')}
                  {/if}
                {/if}
              </button>
            </div>
          </div>
        </div>
        <div class="mt-8">
          <BusinessDocumentLivePreview {businessDocument} {invoiceCollateralData} />
        </div>
      </aside>
    {/if}
  </div>
</FullSizeModal>

<style lang="postcss">
  .no-data-wrapper {
    @apply text-xs text-black;
  }

  .warn {
    @apply bg-creamBrulee border border-warmGray rounded-md px-4 py-2 mb-3 text-sm text-center;
    border-color: hsl(47, 65%, 84%);
    background-color: hsl(47, 87%, 94%);
  }
</style>

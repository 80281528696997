import type { Unsubscriber, Writable } from 'svelte/store'
import { get, writable } from 'svelte/store'
import Profile from '../models/profile'
import { eventsManager } from '../events/event-manager'
import { EventType } from '../events/event-type'
import { encrypt256Hash } from '../util/encryption'
import initializationStore from './initialization.store'
import type GlobalInitializationStore from '../models/initialization-store'

function getAuthenticatedUserProfile(): Profile {
  const existingValue: string = localStorage.getItem('ProfileStore')
  const emptyValue: Profile = new Profile()

  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  if (existingValue === 'null') {
    return emptyValue
  }
  if (existingValue === '') {
    return emptyValue
  }
  if (existingValue === '{}') {
    return emptyValue
  }

  return <Profile>JSON.parse(existingValue)
}

export let ProfileStore: Writable<Profile> = writable(getAuthenticatedUserProfile())
export let unsubscribeProfileStore: Unsubscriber

/**
 * Initialize the ProfileStore with the given data
 * NB: this function is called only once, when the app is initialized
 * ref: https://svelte.dev/tutorial/writable-stores
 * NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable,
 * then it is executed immediately (including when the store.ts file is imported at the beginning of the web app)
 * and its argument is whatever the store variable contains at this point
 * @param data
 */
export const initializeProfileStore = (data: Profile): void => {

  /* console.log('initializeProfileStore()') */

  if (!get(initializationStore).profileStoreInitialized) {

    initializationStore.update((store: GlobalInitializationStore) => {
      store.profileStoreInitialized = true

      return store
    })
    // un-subscribe first if needed
    // NB: needs to be done BEFORE the localStorage.setItem() AND before ProfileStore.set():
    // indeed, for the initialization, we need to set the store without triggering a previous potential subscribe
    /* console.log('unsubscribeProfileStore in initializeProfileStore()', unsubscribeProfileStore) */
    if (!!unsubscribeProfileStore) {
      /* console.log('---p!!!!!!--- vxp  unsubscribing !!!!! unsubscribeProfileStore()') */
      unsubscribeProfileStore()
    }

    localStorage.setItem('ProfileStore', JSON.stringify(data, null, 4))
    ProfileStore.set(data)

    let isProfileStoreSubscriptionDefined: boolean = false
    // NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
    unsubscribeProfileStore = ProfileStore.subscribe((aNewProfile: Profile): void => {
      if (!isProfileStoreSubscriptionDefined) {
        /* console.log('ProfileStore subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently') */
        isProfileStoreSubscriptionDefined = true

        return // we avoid the .subscribe() execution at the subscription occurrence
      }
      /* console.log('initializeProfileStore() > ProfileStore.subscribe()') */

      const newProfile: Profile = Profile.PrototypeToClass(aNewProfile)

      /* console.log('subscribe newProfile', newProfile) */
      /* console.log('subscribe ProfileStore', get(ProfileStore)) */
      // console.trace()

      if (!!newProfile && !!aNewProfile) {

        const oldProfile: Profile = <Profile>JSON.parse(localStorage.getItem('ProfileStore'))
        const oldProfileHash: string = encrypt256Hash(oldProfile)
        const newProfileHash: string = encrypt256Hash(newProfile)

        if (oldProfileHash !== newProfileHash) {
          localStorage.setItem('ProfileStore', JSON.stringify(newProfile, null, 4))
          eventsManager.emit(EventType.PROFILE_CHANGED, newProfile, 'ProfileStore')
        }
      }
    })

    /* console.log('%c ProfileStore initialized.', 'color: #8A99AC') */
  }
}

<script lang="ts">
    import { createEventDispatcher, onMount } from 'svelte'
    import FileInput from './FileInput.svelte'
    import UpdatedFileUpload from '../../../crm-app/models/updated-file-upload'
    import ConditionalFileInputWrapper from './fileInputComponents/ConditionalFileInputWrapper.svelte'

    // Post-Processing: make the input image square using browser-built-in Canvas functions
    // Usage like:
    /*
        <FileInputWithResizingToSquare
                chooseANewFileMessageTEntry="companyEdit.selectNewLogo"
                fileLimitationsMessageTEntry="companyEdit.uploadLimitations"
                fileUploadHelpMessageTEntry="companyEdit.uploadHelp"
                cancelChangesMessageTEntry="companyEdit.resetLogo"
                timeZoneIANACode="Europe/Paris"
                languageAndCountryCode="fr-FR"
                existingFileURL={!!localCompany.emailLogoURL ? localCompany.emailLogoURL :"https://static.thenounproject.com/png/1003548-200.png"}
                existingFileName={!!localCompany.emailLogoURL ? "company logo" :"choose a company logo"}
                existingFileSizeBytes=0
                existingFileLastModifiedUnixMilliseconds=0
                maxFileSize={maxFileSize}
                acceptedExtensions=".jpg, .jpeg, .png"
                triggerNewFileSelectModal={false}
                triggerProgrammaticallyResetSelectedFile={triggerProgrammaticallyResetSelectedFile}
                addBoundingDivs={false}
                debugInfoVisible={false}
                showImage={true}
                showNewUploadButton={true}
                showCancelChangesButton={true}
                showUploadHelpMessage={true}
                showAdditionalUploadHelpMessage={true}
                showAdditionalUploadHelpAlwaysMessage={true}
                showFileMetadata={false}
                specificFileValidator={companyLogoImageUploadValidator}
                newPostProcessedFileUploadAvailable={newPostProcessedFileUploadAvailable}
                on:chosenNewFileUpload={newFileUpload}
                on:chosenNewFileUploadAfterProcessing={newPostProcessedFileUpload}
                on:discardedFileUpload={discardedFileUpload}
        />
     */

    // The image processing is done using purely standard HTML Canvas

    export let chooseANewFileMessageTEntry: string = 'companyEdit.selectNewLogo' // defaults values, overridden by passed values
    export let fileLimitationsMessageTEntry: string = 'companyEdit.uploadLimitations' // defaults values, overridden by passed values
    export let fileUploadHelpMessageTEntry: string = 'companyEdit.uploadHelp' // defaults values, overridden by passed values
    export let cancelChangesMessageTEntry: string = 'companyEdit.resetLogo' // defaults values, overridden by passed values
    // export let reFramePictureMessageTEntry: string = 'companyEdit.reFramePicture'; // defaults values, overridden by passed values
    // export let resizePictureMessageTEntry: string = 'companyEdit.reSizePicture'; // defaults values, overridden by passed values
    // export let confirmReFramingMessageTEntry: string = 'companyEdit.confirmReFraming'; // defaults values, overridden by passed values
    // export let autoAdjustMessageTEntry: string = 'companyEdit.autoAdjust'; // defaults values, overridden by passed values
    // export let cancelReFramingMessageTEntry: string = 'companyEdit.cancelReFraming'; // defaults values, overridden by passed values
    export let timeZoneIANACode: string = 'Europe/Paris' // defaults values, overridden by passed values
    export let languageAndCountryCode: string = 'fr-FR' // defaults values, overridden by passed values

    export let addBoundingDivs: boolean = true // defaults values, overridden by passed values

    export let existingFileURL: string = 'https://thumbs.dreamstime.com/b/amazon-logo-editorial-vector-illustration-market-136495269.jpg' // defaults values, overridden by passed values
    export let existingFileName: string = 'amazon-logo-editorial-vector-illustration-market-136495269.jpg' // defaults values, overridden by passed values
    export let existingFileSizeBytes: number = 14294 // defaults values, overridden by passed values
    export let existingFileLastModifiedUnixMilliseconds: number = 1672502195 * 1000 // defaults values, overridden by passed values

    export let maxFileSizeBytes: number = 1024 * 1024 * 2 // 1024*1024*2 for 2MB max // defaults values, overridden by passed values
    export let acceptedExtensions: string = '.jpg, .jpeg, .png' // defaults values, overridden by passed values

    export let triggerNewFileSelectModal: boolean = false // defaults values, overridden by passed values
    export let triggerProgrammaticallyResetSelectedFile: boolean = false // defaults values, overridden by passed values

    export let debugInfoVisible: boolean = false // defaults values, overridden by passed values
    export let showImage: boolean = true // defaults values, overridden by passed values
    export let showNewUploadButton: boolean = true // defaults values, overridden by passed values
    export let showCancelChangesButton: boolean = true // defaults values, overridden by passed values
    export let showUploadHelpMessage: boolean = true // defaults values, overridden by passed values
    export let showAdditionalUploadHelpMessage: boolean = true // defaults values, overridden by passed values
    export let showAdditionalUploadHelpAlwaysMessage: boolean = true // defaults values, overridden by passed values
    export let showFileMetadata: boolean = true // defaults values, overridden by passed values
    export let newPostProcessedFileUploadAvailable: boolean = false // output / read only // defaults values, overridden by passed values

    export let specificFileValidator: (fileMIMEContentType: string, fileName: string, fileSizeBytes: number, fileLastModifiedUnixMilliseconds: number) => boolean

    /** Specify a name for the cypress selector */
    export let dataCy:string = ''

    // main return from component:
    //   on:chosenNewFileUpload=<UpdatedFileUpload>{...}
    //   on:chosenNewFileUploadAfterProcessing=<UpdatedFileUpload>{...}
    //   on:discardedFileUpload=<UpdatedFileUpload>{} (empty UpdatedFileUpload)

    let newFileUploadAvailable: boolean // output / read only from FileInput component

    let newChosenUploadedFileBase64: string | ArrayBuffer
    let newChosenUploadedFileMIMEContentType: string
    let newChosenUploadedOriginalFileName: string
    let newChosenUploadedOriginalFileSizeBytes: number
    let newChosenUploadedOriginalFileLastModifiedUnixMilliseconds: number

    let newChosenUploadedPostProcessedFileBase64: string | ArrayBuffer
    let newChosenUploadedPostProcessedFileMIMEContentType: string
    let newChosenUploadedPostProcessedFileName: string
    let newChosenUploadedPostProcessedFileSizeBytes: number
    let newChosenUploadedPostProcessedFileLastModifiedUnixMilliseconds: number

    const dispatch = createEventDispatcher()

    let DELAY_BETWEEN_IMAGE_PROCESSES = 0
    const MAX_IMAGE_NB_OF_PIXELS_H_OR_V = 1200
    let OUTPUT_POST_PROCESSED_FILE_MIME_CONTENT_TYPE = 'image/png'
    let OUTPUT_POST_PROCESSED_FILE_EXTENSION = 'png'
    let IMAGE_PROCESSING_BACKGROUND_COLOR = '#F6F5F9' // "#FFFFFF";

    function showCanvasImageProcessingStatus(title: string, canvasContext: CanvasRenderingContext2D) {
      /* console.log('----------------------------------') */
      /* console.log(`-- ${title} --`) */
      /* console.log('----------------------------------') */
      /* console.log('canvasContext ' + title, 'w', canvasContext.canvas.width, 'h', canvasContext.canvas.height) */
    }

    const preProcessSteps = {
      putBase64ImgToCanvas: (imageBase64: string, whenToDoIt: number) => new Promise<HTMLCanvasElement>(function (resolve, reject) {
        /* console.log('putBase64ImgToCanvas in ', whenToDoIt, ' ms') */
        setTimeout(() => {
          /* console.log('---------- 1 putBase64ImgToCanvas ------------') */
          const img = new Image() //document.createElement("img")
          const newImageSrcLoaded = (imageBase64: string) => {
            /* console.log('newImageSrcLoaded') */
            img.src = imageBase64
            if (img.width === 0) {
              reject('input image has 0px width')

              return
            }
            if (img.height === 0) {
              reject('input image has 0px height')

              return
            }
            // just put the img into canvas 0 (unchanged)
            const canvas0: HTMLCanvasElement = document.createElement('canvas')
            let canvasContext0: CanvasRenderingContext2D = canvas0.getContext('2d')
            let newCanvasWidth = img.width
            let newCanvasHeight = img.height
            canvas0.width = newCanvasWidth
            canvas0.height = newCanvasHeight
            if (debugInfoVisible) {
              showCanvasImageProcessingStatus('before putBase64ImgToCanvas', canvasContext0)
            }
            canvasContext0.drawImage(img, 0, 0, img.width, img.height, 0, 0, newCanvasWidth, newCanvasHeight)
            img.src = '' // reset img
            if (debugInfoVisible) {
              showCanvasImageProcessingStatus('after putBase64ImgToCanvas', canvasContext0)
            }
            // img. = null;
            resolve(canvas0)
          }
          img.addEventListener('load', newImageSrcLoaded(imageBase64))
          img.addEventListener('error', (e) => {
            /* console.log('putBase64ImgToCanvas img.addEventListener error', e) */
          })
        }, whenToDoIt)
      }),
      copyApplyMaxImageSize: (canvas0: HTMLCanvasElement, maxSideSize: number, whenToDoIt: number) => new Promise<HTMLCanvasElement>(function (resolve, reject) {
        /* console.log('copyApplyMaxImageSize in ', whenToDoIt, ' ms') */
        setTimeout(() => {
          /* console.log('---------- 2 copyApplyMaxImageSize ------------') */
          if (canvas0.width === 0) {
            reject('input canvas0 has 0px width')

            return
          }
          if (canvas0.height === 0) {
            reject('input canvas0 has 0px height')

            return
          }
          const canvas1: HTMLCanvasElement = document.createElement('canvas')
          let canvasContext1: CanvasRenderingContext2D = canvas1.getContext('2d')
          // resize the canvas0 into canvas 1 with max side size (do not shape it as square)
          let newCanvasWidth = canvas0.width
          let newCanvasHeight = canvas0.height
          if (canvas0.width > maxSideSize) {
            newCanvasWidth = maxSideSize
            newCanvasHeight = canvas0.height * maxSideSize / canvas0.width
          }
          if (canvas0.height > maxSideSize) {
            newCanvasWidth = canvas0.width * maxSideSize / canvas0.height
            newCanvasHeight = maxSideSize
          }
          canvas1.width = newCanvasWidth
          canvas1.height = newCanvasHeight
          canvasContext1.drawImage(canvas0, 0, 0, canvas0.width, canvas0.height, 0, 0, newCanvasWidth, newCanvasHeight)
          if (debugInfoVisible) {
            showCanvasImageProcessingStatus('after copyApplyMaxImageSize', canvasContext1)
          }
          resolve(canvas1)
        }, whenToDoIt)
      }),
      makeASquareImageOfIt: (canvas1: HTMLCanvasElement, whenToDoIt: number) => new Promise<HTMLCanvasElement>(function (resolve, reject) {
        /* console.log('makeASquareImageOfIt in ', whenToDoIt, ' ms') */
        setTimeout(() => {
          /* console.log('---------- 3 makeASquareImageOfIt ------------') */
          if (canvas1.width === 0) {
            reject('input canvas1 has 0px width')

            return
          }
          if (canvas1.height === 0) {
            reject('input canvas1 has 0px height')

            return
          }
          const canvas2: HTMLCanvasElement = document.createElement('canvas')
          let canvasContext2: CanvasRenderingContext2D = canvas2.getContext('2d')
          // just copy canvas 1 into canvas 2 as a square
          const squareSideSize = Math.max(canvas1.width, canvas1.height)
          canvas2.width = squareSideSize
          canvas2.height = squareSideSize
          if (debugInfoVisible) {
            showCanvasImageProcessingStatus('before makeASquareImageOfIt', canvasContext2)
          }
          // canvasContext2.clearRect(0, 0, squareSideSize, squareSideSize);
          // canvasContext2.beginPath();
          // canvasContext2.rect(0, 0, squareSideSize, squareSideSize);
          // canvasContext2.closePath();
          // canvasContext2.moveTo(squareSideSize/2,squareSideSize/2);
          canvasContext2.fillStyle = IMAGE_PROCESSING_BACKGROUND_COLOR
          // canvasContext2.fill();
          canvasContext2.fillRect(0, 0, squareSideSize, squareSideSize)
          // canvasContext2.fillStyle = "blue";
          // canvasContext2.fillRect(0, 0, canvas2.width, canvas2.height);
          const centerShift_x = (canvas2.width - canvas1.width) / 2
          const centerShift_y = (canvas2.height - canvas1.height) / 2
          canvasContext2.drawImage(canvas1, 0, 0, canvas1.width, canvas1.height,
            centerShift_x, centerShift_y, canvas1.width, canvas1.height)
          if (debugInfoVisible) {
            showCanvasImageProcessingStatus('after makeASquareImageOfIt', canvasContext2)
          }
          resolve(canvas2)
        }, whenToDoIt)
      })
    }

    // Functions to download data to a file
    async function downloadBase64DataURI(base64DataURI, filename, fileContentType) {
      const blob = await convertBase64DataURIToBlob(base64DataURI)
      downloadBlob(blob, filename, fileContentType)
    }

    async function convertBase64DataURIToBlob(base64DataURI) {
      return (await fetch(base64DataURI)).blob()
    }

    function downloadBlob(blob, filename, fileContentType) {
      const file = convertBlobToFile(blob, filename, fileContentType)
      downloadFile(file, filename)
    }

    function blobToBase64DataURI(blob: Blob): Promise<string | ArrayBuffer> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    }

    function convertBlobToFile(blob, filename, fileContentType) {
      return new File(
        [blob],
        filename,
                <FilePropertyBag>{
                  type: fileContentType,
                  lastModified: new Date()
                },
      )
    }

    function convertBase64DataURIToFile(base64DataURI: string, filename: string) {
      const arr = base64DataURI.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1])
      let n = bstr.length,
        u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    }

    function downloadFile(file, filename) {
      // var file = new Blob([data], { type: type });
      if (!!window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename)
      else { // Others
        var a = document.createElement('a'),
          url = URL.createObjectURL(file)
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        setTimeout(function () {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
        }, 0)
      }
    }

    // Inspiration: https://stackoverflow.com/questions/29848678/resize-image-need-a-good-library
    // Ref: https://stackoverflow.com/questions/23104582/scaling-an-image-to-fit-on-canvas
    // Ref: https://stackoverflow.com/questions/13405129/create-and-save-a-file-with-javascript
    // Ref: https://stackoverflow.com/questions/17774928/js-get-image-width-and-height-from-the-base64-code
    // Ref: https://medium.com/weekly-webtips/how-to-resize-an-image-using-client-side-javascript-and-html5-canvas-2fff73d15d0
    // Ref: https://codeburst.io/creating-and-drawing-on-an-html5-canvas-using-javascript-93da75f001c1
    // Ref: https://microsoft.github.io/PowerBI-JavaScript/interfaces/_node_modules_typedoc_node_modules_typescript_lib_lib_dom_d_.filepropertybag.html
    // Ref: https://stackoverflow.com/questions/6850276/how-to-convert-dataurl-to-file-object-in-javascript
    // Ref: https://medium.com/mop-developers/image-processing-in-go-5ba9a9043bc2
    // Ref: https://stackoverflow.com/questions/35068954/uploading-an-image-cropped-using-a-cropper-js-plugin
    function resizeImg(imageBase64, maxSideSize): Promise<string> {
      return preProcessSteps.putBase64ImgToCanvas(imageBase64, DELAY_BETWEEN_IMAGE_PROCESSES)
        .then((canvas0: HTMLCanvasElement) => preProcessSteps.copyApplyMaxImageSize(canvas0, maxSideSize, DELAY_BETWEEN_IMAGE_PROCESSES))
        .then((canvas1: HTMLCanvasElement) => preProcessSteps.makeASquareImageOfIt(canvas1, DELAY_BETWEEN_IMAGE_PROCESSES))
        .then((canvas2: HTMLCanvasElement) => {
          const dataURI: string = canvas2.toDataURL(OUTPUT_POST_PROCESSED_FILE_MIME_CONTENT_TYPE)

          return dataURI
        })
    }

    // step 1: choose a source image and process it
    function chosenNewFileUpload(newFileUploadData: CustomEvent<UpdatedFileUpload>) {
      // bubble up the event
      dispatch('chosenNewFileUpload', newFileUploadData.detail)
      // then fill with data
      /* console.log('*!!! newFileUploadData', newFileUploadData) */
      newChosenUploadedFileBase64 = newFileUploadData.detail.fileBase64
      newChosenUploadedFileMIMEContentType = newFileUploadData.detail.fileMIMEContentType
      newChosenUploadedOriginalFileName = newFileUploadData.detail.fileName
      newChosenUploadedOriginalFileSizeBytes = newFileUploadData.detail.fileSizeBytes
      newChosenUploadedOriginalFileLastModifiedUnixMilliseconds = newFileUploadData.detail.fileLastModifiedUnixMilliseconds

      resizeImg(newChosenUploadedFileBase64, MAX_IMAGE_NB_OF_PIXELS_H_OR_V)
        .then((dataURI: string) => {
          saveSquareResizedImage(dataURI)
        })
        .catch(reason => {
          console.log('resizeImg failed for: ' + reason)
        })
    }

    // step 2: save the post-processed image
    function saveSquareResizedImage(postProcessedImageInBase64: string) {
      /* console.log('*!!! shall we save the image now or wait for an official \'save\' action?') */

      // we keep companyEmailLogoOriginalName unchanged here
      newChosenUploadedPostProcessedFileBase64 = postProcessedImageInBase64
      newChosenUploadedPostProcessedFileMIMEContentType = OUTPUT_POST_PROCESSED_FILE_MIME_CONTENT_TYPE
      newChosenUploadedPostProcessedFileName = newChosenUploadedOriginalFileName
      newChosenUploadedPostProcessedFileSizeBytes = newChosenUploadedPostProcessedFileBase64.length
      newChosenUploadedPostProcessedFileLastModifiedUnixMilliseconds = newChosenUploadedOriginalFileLastModifiedUnixMilliseconds
      newPostProcessedFileUploadAvailable = true

      const newFileName = 'square-image-' + (Date.now()) + '.' + OUTPUT_POST_PROCESSED_FILE_EXTENSION
      if (debugInfoVisible) {
        downloadBase64DataURI(newChosenUploadedPostProcessedFileBase64, newFileName, newChosenUploadedPostProcessedFileMIMEContentType)
          .then(() => {
            /* console.log('file downloaded') */
          })
      }
      convertBase64DataURIToBlob(newChosenUploadedPostProcessedFileBase64)
        .then((finalBlob) => {
          /* console.log('dispatching event chosenNewFileUploadAfterProcessing from FileInputWithResizingToSquare') */
          const finalFile = convertBlobToFile(finalBlob, newFileName, newChosenUploadedPostProcessedFileMIMEContentType)
          dispatch('chosenNewFileUploadAfterProcessing', <UpdatedFileUpload>{
            fileBase64: finalFile,
            fileMIMEContentType: newChosenUploadedPostProcessedFileMIMEContentType,
            fileName: newChosenUploadedPostProcessedFileName,
            fileSizeBytes: newChosenUploadedPostProcessedFileSizeBytes,
            fileLastModifiedUnixMilliseconds: newChosenUploadedPostProcessedFileLastModifiedUnixMilliseconds
          })
        })
    }

    function resetFileUpload(cancelFileUploadData: CustomEvent<UpdatedFileUpload>) {
      // bubble up the event
      dispatch('discardedFileUpload', <UpdatedFileUpload>{
        fileBase64: '',
        fileMIMEContentType: '',
        fileName: '',
        fileSizeBytes: 0,
        fileLastModifiedUnixMilliseconds: 0
      })
    }

    function resetImageProcessingStage() {
      newChosenUploadedPostProcessedFileBase64 = ''
      newChosenUploadedPostProcessedFileMIMEContentType = ''
      newChosenUploadedPostProcessedFileName = ''
      newChosenUploadedPostProcessedFileSizeBytes = 0
      newChosenUploadedPostProcessedFileLastModifiedUnixMilliseconds = 0
      newPostProcessedFileUploadAvailable = false
      // ask FileInput to reset / clean the place
      triggerProgrammaticallyResetSelectedFile = true
      // same effect as receiving a 'cancelFileUploadData' event
      // bubble up the event
      dispatch('discardedFileUpload', <UpdatedFileUpload>{
        fileBase64: '',
        fileMIMEContentType: '',
        fileName: '',
        fileSizeBytes: 0,
        fileLastModifiedUnixMilliseconds: 0
      })
    }

    $: {
      if (!!newChosenUploadedPostProcessedFileBase64 && newChosenUploadedPostProcessedFileBase64 !== '') {
        showImage = false
      } else {
        showImage = true
      }
    }

    $: {
      if (!newFileUploadAvailable) {
        resetImageProcessingStage()
        showImage = true
      }
    }

    onMount(() => {
      newPostProcessedFileUploadAvailable = false
    })
</script>

<svelte:head>
    <!-- https://stackoverflow.com/questions/8155064/how-to-programmatically-empty-browser-cache -->
    <meta http-equiv='cache-control' content='no-cache'>
    <meta http-equiv='expires' content='0'>
    <meta http-equiv='pragma' content='no-cache'>
</svelte:head>


<div data-ut-component="file-input-with-cropper">


    <ConditionalFileInputWrapper condition={addBoundingDivs}>


        {#if !showImage}
            <div class="relative rounded-lg text-center border border-athensGray border-dotted flex justify-center items-center overflow-hidden w-full h-32 w-32">
                <img class="rounded-lg overflow-hidden flex align-middle relative z-10 object-scale-down h-full w-full"
                     data-ut-img="file-input-post-processed-to-square"
                     on:click|preventDefault|stopPropagation={()=>{
                       /* console.log('*!!! click') */
                       /* console.log('+*!!! triggerNewFileSelectModal = true in FileInputWithCropper') */
                       triggerNewFileSelectModal = true
                     }}
                     src={newChosenUploadedPostProcessedFileBase64} alt="post processed image source"
                >
            </div>
        {/if}
        <FileInput
                chooseANewFileMessageTEntry={chooseANewFileMessageTEntry}
                fileLimitationsMessageTEntry={fileLimitationsMessageTEntry}
                fileUploadHelpMessageTEntry={fileUploadHelpMessageTEntry}
                cancelChangesMessageTEntry={cancelChangesMessageTEntry}
                timeZoneIANACode={timeZoneIANACode}
                languageAndCountryCode={languageAndCountryCode}
                addBoundingDivs={false}
                existingFileURL={existingFileURL}
                existingFileName={existingFileName}
                existingFileSizeBytes={existingFileSizeBytes}
                existingFileLastModifiedUnixMilliseconds={existingFileLastModifiedUnixMilliseconds}
                maxFileSizeBytes={maxFileSizeBytes}
                acceptedExtensions={acceptedExtensions}
                bind:triggerNewFileSelectModal={triggerNewFileSelectModal}
                bind:triggerProgrammaticallyResetSelectedFile={triggerProgrammaticallyResetSelectedFile}
                debugInfoVisible={debugInfoVisible}
                showImage={showImage}
                showNewUploadButton={showNewUploadButton}
                showCancelChangesButton={showCancelChangesButton}
                showUploadHelpMessage={showUploadHelpMessage}
                showAdditionalUploadHelpMessage={showAdditionalUploadHelpMessage}
                showAdditionalUploadHelpAlwaysMessage={showAdditionalUploadHelpAlwaysMessage}
                showFileMetadata={showFileMetadata}
                specificFileValidator={specificFileValidator}
                bind:newFileUploadAvailable={newFileUploadAvailable}
                dataCy="{dataCy}"
                on:chosenNewFileUpload={chosenNewFileUpload}
                on:discardedFileUpload={resetFileUpload}
        />


    </ConditionalFileInputWrapper>


</div>


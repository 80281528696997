import type { Decimal } from 'decimal.js'
import type { Readable } from "svelte/store";

/**
 * Format a number to a currency string
 * with options to specify the currency code and the number of fractions
 * currencyDisplay is either 'code' or 'symbol'
 * @param value
 * @param locale (string like 'de-DE' for German, 'fr-FR' for France French, etc.) e.g. from t('locales')
 * @param currencyCode (string like 'EUR', 'USD', etc.)
 * @param cleanNarrowNoBreakSpace
 * @param currencyDisplay
 * @param fractionsLimit
 */
export const formatCurrencyWithOptions = (
  value: Decimal | number | undefined,
  locale: string,
  currencyCode: Readable<string>| string | null,
  cleanNarrowNoBreakSpace: boolean,
  currencyDisplay?: string,
  fractionsLimit: number = 2,
): string => {
  if (value === undefined) {
    /* console.error('Error: Value is undefined') */

    return ''
  }

  const options = {
    style: 'currency',
    currency: currencyCode || 'EUR',
    currencyDisplay: currencyDisplay || 'code',
    maximumFractionDigits: fractionsLimit
  }

  try {
    const formatter: Intl.NumberFormat = new Intl.NumberFormat(locale, options)
    if (cleanNarrowNoBreakSpace) {
      return formatter.format(<number>value).replace(/\u202f/g, ' ') // \u202f is narrow no-break space
    } else {
      return formatter.format(<number>value)
    }
  } catch (e) {
    /* console.error('Error formatting currency:', e, 'value', value, 'locale', locale, 'currencyCode', currencyCode, 'cleanNarrowNoBreakSpace', cleanNarrowNoBreakSpace, 'currencyDisplay', currencyDisplay, 'fractionsLimit', fractionsLimit) */
    if (cleanNarrowNoBreakSpace) {
      return value.toString().replace(/\u202f/g, ' ') // \u202f is narrow no-break space
    } else {
      return value.toString()
    }
  }
}


export const formatCurrencyWithSignForTransaction = (value: number, locale: string, currencyCode?: string): string => {
  const options = {
    style: 'currency',
    signDisplay: 'exceptZero',
    currency: currencyCode || 'EUR',
    currencyDisplay: 'code'
  }

  try {
    // @ts-ignore because of the signDisplay option not available in Typescript
    const formatter = new Intl.NumberFormat(locale, options)

    return formatter.format(value)
  } catch (e) {
    /* console.error('Error formatting currency:', e) */

    return value.toString()
  }
}

/**
 * Tests if a number has more than two decimals
 * @param initialNumber
 */
export function hasMoreThanTwoDecimals(initialNumber: number): boolean {
  if (Number.isNaN(initialNumber) || !initialNumber || initialNumber === 0) {
    return false
  }
  const numberConvertedToString = initialNumber.toString()
  const decimalIndex = numberConvertedToString.indexOf('.')
  if (decimalIndex === -1) {
    // The number doesn't have a decimal point, so it has at most two decimals.
    return false
  }
  const numberOfDecimals = numberConvertedToString.length - decimalIndex - 1

  return numberOfDecimals > 2
}

/**
 * Format a number to a number with 2 decimals
 * @param value
 */
export function removeDecimalsBeyondHundredth(value: number): number {
  const valueAsNumber: number = parseFloat(String(value))
  if (Number.isNaN(valueAsNumber) || !valueAsNumber) {
    return 0
  }

  return parseFloat(String(valueAsNumber.toFixed(2)))
}

<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import { createEventDispatcher, onMount } from 'svelte'

    const dispatch = createEventDispatcher()

    /** Set menu state */
    let open = false

    /** Set menu wrapper DOM ref */
    let menu = null

    function createNewVoxyInvoice() {
      dispatch('createVoxyInvoice')
    }

    function createNewCreditNote() {
      dispatch('createCreditNote')
    }

    onMount(() => {
      const handleOutsideClick = (event: Event) => {
        if (open && !menu.contains(event.target)) {
          open = false
        }
      }

      const handleEscape = (event: KeyboardEvent) => {
        if (open && event.key === 'Escape') {
          open = false
        }
      }

      // add events when element is added to the DOM
      document.addEventListener('click', handleOutsideClick, false)
      document.addEventListener('keyup', handleEscape, false)

      // remove events when element is removed from the DOM
      return () => {
        document.removeEventListener('click', handleOutsideClick, false)
        document.removeEventListener('keyup', handleEscape, false)
      }
    })
</script>

<div class="relative inline-block text-left" bind:this={menu}>
    <div>
        <button type="button"
                on:click|preventDefault|stopPropagation={() => {open = !open}}
                class="inline-flex items-center rounded-full border border-transparent bg-dundyOrange p-3 text-white shadow-lg hover:bg-dundyOrange hover:border-mojo focus:outline-none focus:ring-2 focus:ring-dundyOrange focus:ring-offset-2">
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                 stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15"/>
            </svg>
        </button>
    </div>
    {#if open}
        <div class="absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none {open ? 'transform opacity-100 scale-100' : 'transform opacity-0 scale-95' }"
             role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
            <div class="py-1 px-1" role="none">
                <button
                        data-cy="create-new-invoice"
                        on:click={createNewVoxyInvoice}
                        class="w-full group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-whisper rounded-md"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0">{t('invoices.actionMenu.createInvoice')}</button>
                <button
                        data-cy="create-new-credit-note"
                        on:click={createNewCreditNote}
                        class="w-full group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-whisper rounded-md"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-1">{t('invoices.actionMenu.createNewCreditNote')}</button>
            </div>
        </div>
    {/if}
</div>
import StorageBucketObject from '../../../file-mgt-domain/models/storage-bucket-object'
import { pdfAttachmentDownload } from '../../../file-mgt-domain/services/pdf-attachment-download.api'

/***
 * Downloads the latest file attachment for a given business document
 * @param businessDocumentNumber
 * @param businessDocumentId
 * @param workspaceId
 */
export async function businessDocumentAttachmentDownload(businessDocumentNumber: string, businessDocumentId: string, workspaceId: string): Promise<StorageBucketObject> {
  /* console.log('businessDocumentAttachmentDownload', businessDocumentId, businessDocumentNumber) */
  const isBusinessDocumentFinalizedForVoxyDocument: boolean = (!!businessDocumentNumber && (businessDocumentNumber !== ''))

  return pdfAttachmentDownload(businessDocumentNumber, businessDocumentId, workspaceId, isBusinessDocumentFinalizedForVoxyDocument)
}

// filtering for a deal also filters for the customer of the deal (hence no need to additionally filter for a customer)
import type { BusinessDocument } from '../../models/business-document'
import {
  filterAllBusinessDocumentsFinalized,
  filterAllBusinessDocumentsWithDealId, filterAllOtherBusinessDocumentsButCurrentOne
} from '../business-document-array-filters/business-documents-filters.service'

export function getDealFinalizedBusinessDocumentsForBalanceOfDealCalculus(
  allBusinessDocuments: BusinessDocument[],
  currentBusinessDocumentId: string,
  dealId: string,
): BusinessDocument[] {
  return allBusinessDocuments
    .filter(filterAllBusinessDocumentsWithDealId(dealId))
    .filter(filterAllBusinessDocumentsFinalized)
    .filter(filterAllOtherBusinessDocumentsButCurrentOne(currentBusinessDocumentId))
}

import { Random } from 'random-js'
import sha256 from 'crypto-js/sha256'

const random: Random = new Random() // uses the nativeMath engine

/**
 * Encrypts a value using SHA256
 * @param value
 * @param replacer
 */

export const encrypt256Hash = (value: any, replacer = null): string => {
  const json: string = JSON.stringify(value, replacer, 4)
  
  return sha256(json).toString()
}

export const cryptoRandomString = (): string => 
// https://github.com/ckknight/random-js
  `${random.integer(1, 100000000)}`


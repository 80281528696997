import { ExchangeDate } from './exchange-date'
import { BusinessDocumentKind } from '../../voxy-app/enums/business-document-kind'
import { JsonProperty } from '@paddls/ts-serializer'

export enum AttachmentFileSource {
  DUNDY = 'Dundy',
  VOXY = 'Voxy',
  UNKNOWN = 'Unknown'
}

export class BusinessDocumentAttachmentMetadata {
  @JsonProperty('fileName') fileName: string
  @JsonProperty('fileSizeBytes') fileSizeBytes: number
  @JsonProperty('fileType') fileType: string
  @JsonProperty('workspaceId') workspaceId: string
  @JsonProperty({ field: 'source', groups: Object.values(AttachmentFileSource) }) source: AttachmentFileSource
  @JsonProperty('businessDocumentId') businessDocumentId: string
  @JsonProperty({ field: 'businessDocumentKind', groups: Object.values(BusinessDocumentKind) }) businessDocumentKind: BusinessDocumentKind
  @JsonProperty('businessDocumentNumber') businessDocumentNumber: string
  @JsonProperty({ field: 'createdDate', type: () => ExchangeDate }) createdDate: ExchangeDate
}

<script lang='ts'>
  import {
    DealWithRemainingBalanceToInvoice
  } from '$voxy/models/deal-with-remaining-balance-to-invoice.model'
  import { Customer } from '$crm/models/customer'
  import { getNewCustomerAccount } from '$voxy/services/business-document-various-functions'
  import { CustomersStore } from '$crm/stores/customers.store'
  import { generateListConfig } from './generate-config-list-for-deals-with-balance-documents'
  import { navigate } from 'svelte-routing'
  import Loader from '../../ui-kit/Loader.svelte'
  import { t } from '$core/lib/i18n/i18nextWrapper'
  import Tooltip from '../../ui-kit/Tooltip/Tooltip.svelte'
  import { formatCurrencyWithOptions } from '$voxy/utils/number-formatting-utils'

  /** Exported declarations */
  export let listOfDealsWithBalanceInvoicesToFinalize: DealWithRemainingBalanceToInvoice[] = []

  /** Local declarations */
  const listConfig = generateListConfig()

  /** reactive declarations */
  $: if (listOfDealsWithBalanceInvoicesToFinalize && listOfDealsWithBalanceInvoicesToFinalize.length > 0 && $CustomersStore) {
    populateListData()
  }

  /**
   * Populates the listConfig.data with the data from the listOfDealsWithBalanceInvoicesToFinalize
   */
  const populateListData = () => {
    if (listOfDealsWithBalanceInvoicesToFinalize && $CustomersStore) {
      listConfig.data = listOfDealsWithBalanceInvoicesToFinalize
        .filter((deal: DealWithRemainingBalanceToInvoice) => deal && deal.existingLatestBusinessDocument && deal.existingLatestBusinessDocument.customerCustomer.company)
        .map((deal: DealWithRemainingBalanceToInvoice) => {
          const customer: Customer = getNewCustomerAccount($CustomersStore, deal.existingLatestBusinessDocument.customerCustomer.company.companyId)
          if (!customer) {
            return null
          }

          return {
            customer,
            originalDocument: deal.existingLatestBusinessDocument,
            newBalanceBusinessDocument: deal.newBalanceBusinessDocument
          }
        })
        .filter(item => item !== null)
    }
  }
</script>
{#if !listOfDealsWithBalanceInvoicesToFinalize}
  <div class='grid h-24 place-items-center w-full z-[100]'>
    <Loader/>
  </div>
{:else}
  <div class="main-invoices-to-generate-module">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto flex items-center py-8 px-8">
        <h2 class="text-base leading-6 text-black">{t('dashboard.invoicesToGenerateModule.title')}</h2>
        <Tooltip width="300px">
          <div slot="activator">
            <svg class="text-paleSky ml-1" width="16" height="16" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2" d="M12 13V15"/>
              <circle cx="12" cy="9" r="1" fill="currentColor"/>
              <circle cx="12" cy="12" r="7.25" stroke="currentColor" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="1.5"/>
            </svg>
          </div>
          <p>{t('dashboard.invoicesToGenerateModule.tooltip')}</p>
        </Tooltip>
      </div>
    </div>

    {#if listOfDealsWithBalanceInvoicesToFinalize.length > 0}
      <ul class="divide-y divide-athensGray overflow-y-auto overflow-x-hidden max-h-80  pb-16">
        {#each listOfDealsWithBalanceInvoicesToFinalize as deal}
          <li class="sm:flex sm:justify-between py-3 bg-transparent hover:bg-whisper cursor-pointer px-8" on:click|preventDefault|stopPropagation={() => { navigate(`/receivables/new/commercial-invoice/details?deal-id=${deal.dealId}`)}}>
            <div class="mt-4 sm:mt-0 sm:flex sm:flex-col sm:items-end">
              <div>
                <h3 class="text-s font-bold capitalize">{deal.existingLatestBusinessDocument.customerCustomer.company.formalName}</h3>
                <p class="mt-1 text-xs text-paleSky">
                  {t('dashboard.invoicesToGenerateModule.latestInvoice')}{Intl.DateTimeFormat(t('locales'), { year: 'numeric', month: 'long', day: 'numeric' })
                    .format(new Date(deal.existingLatestBusinessDocument.issuedDate.rfc3339))}
                </p>
              </div>
            </div>
            <div class="mt-4 sm:mt-0 sm:ml-6 sm:flex sm:flex-col sm:items-end">
              <p class="text-xxs text-paleSky">{t('dashboard.invoicesToGenerateModule.amountAllTaxes')}</p>
              <p class="mt-1 text-s font-medium text-oceanGreen">
                {formatCurrencyWithOptions(deal.newBalanceBusinessDocument.businessDocument.installmentResultIncludingTaxScaledValue, t('locales'), deal.newBalanceBusinessDocument.businessDocument.currency, false, 'symbol')}
              </p>
            </div>
          </li>
        {/each}
      <!--  {#if listOfDealsWithBalanceInvoicesToFinalize.length > 3}
          <li class="py-4 text-center text-s text-paleSky">...</li>
        {/if}-->
      </ul>
      <!--<span class="main-invoices-to-generate-button">
        <a class="text-center" href="/clients/manage" use:link>{t('dashboard.invoicesToGenerateModule.seeAll', {count: listOfDealsWithBalanceInvoicesToFinalize.length} )}</a>
      </span>-->
    {:else}
      <div class="grid mt-4 mb-6 place-items-center w-full z-[100] px-3 text-center">
        <img src="/img/dashboard/invoices-generate-empty.png" alt="{t('dashboard.invoicesToGenerateModule.noInvoices.copy')}" class="h-32 mb-6">
        <p class="my-3 text-s leading-5 text-black px-12">{t('dashboard.invoicesToGenerateModule.noInvoices.copy')}</p>
      </div>
    {/if}
  </div>
{/if}
<style lang="postcss">
  .main-invoices-to-generate-module {
    @apply border border-athensGray h-full relative overflow-hidden;
    border-radius: 20px;
  }
</style>
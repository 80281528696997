import { BusinessDocument, BusinessDocumentRelation, CoreDocument } from '../../models/business-document'
import { getBusinessDocumentWithoutCollateralFromStoreById } from '../../helpers/business-document-data-passthrough'

/**
 * Get RelatedBusinessDocuments Details And store them
 *
 * @param businessDocument
 * @param businessDocumentsStore
 * **/
export function getAllRelatedBusinessDocumentsDetails(businessDocument: BusinessDocument, businessDocumentsStore: CoreDocument[]): Promise<CoreDocument[]> {
  const allRelatedBusinessDocumentsIds: string[] = businessDocument.relatedBusinessDocuments.map(
    (bd: BusinessDocumentRelation) => bd.fromBusinessDocumentId,
  )

  const requests: Promise<CoreDocument>[] = allRelatedBusinessDocumentsIds.map((id: string) =>
    getBusinessDocumentWithoutCollateralFromStoreById(id, businessDocumentsStore),
  )

  let relatedBusinessDocuments: CoreDocument[] = []

  return Promise.all(requests)
    .then((response: CoreDocument[]) => {
      response.forEach((res: CoreDocument): void => {
        if (res) {
          relatedBusinessDocuments.push(res)
        }
      })

      return relatedBusinessDocuments
    })
    .catch((err) => {
      console.log(err)
      throw err
    })
}

import type {
  FirstDataRenderedEvent,
  GridOptions,
  IRowNode,
  RowValueChangedEvent
} from 'ag-grid-community'
import { agGridCommonService } from '$dundy/services/ag-grid-common.service'
import type { GetLocaleTextParams } from 'ag-grid-community'
import { t } from '$core/lib/i18n/i18nextWrapper'
import type Invoice from '../../../../dundy-app/models/invoice'
import type { InvoiceNumber } from '$cash/models/cash-application-model'


export const getCashApplicationReadyInvoicesOptions = (selectedInvoiceNumbersSet: Set<InvoiceNumber>): GridOptions<GridOptions> => ({
  defaultColDef: {
    flex: 1,
    resizable: false,
    headerCheckboxSelection: agGridCommonService.isFirstColumn,
    checkboxSelection: agGridCommonService.isFirstColumn
  },
  rowHeight: 64,
  domLayout: 'autoHeight', // autoHeight or normal (fixed)
  singleClickEdit: false,
  suppressClickEdit: false,
  suppressCellFocus: true,
  rowMultiSelectWithClick: true,
  stopEditingWhenCellsLoseFocus: true,
  rowSelection: 'multiple',
  enableRangeSelection: false,
  pagination: true,
  paginationPageSize: 50,
  suppressPaginationPanel: true,
  onFirstDataRendered: (event: FirstDataRenderedEvent): void => {
    event.api.forEachNode((node: IRowNode<Invoice>) => {
      const isSelected: boolean = !!node.data && selectedInvoiceNumbersSet.has(node.data.invoiceNumber)
      node.setSelected(isSelected)
    })
  },
  onRowValueChanged(event: RowValueChangedEvent):void {
    event.api.forEachNode((node: IRowNode<Invoice>) => {
      const isSelected: boolean = !!node.data && selectedInvoiceNumbersSet.has(node.data.invoiceNumber)
      node.setSelected(isSelected)
    })
  },
  getLocaleText: (params: GetLocaleTextParams<GridOptions>): string => {
    const translation: string = t(`agGrid.${params.key}`)
    return translation !== `agGrid.${params.key}` ? translation : params.key
  }
})



<script lang="ts">
  import { EmailAttachmentTag } from "../../../dundy-app/models/email-attachment";
  import { bytesToHighestUnitDisplay } from "../../../core-app/util/file-utils";
  import { createEventDispatcher } from "svelte";

  export let attachment;

  const dispatch = createEventDispatcher()

  function download(attachment) {
    const element = document.createElement("a");
    element.setAttribute("href", `data:${attachment.fileType};base64,${encodeURIComponent(attachment.contentBase64)}`);
    element.setAttribute("download", attachment.fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  function removeAttachment(attachment) {
    dispatch("removeAttachment", attachment);
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="flex justify-between w-full items-center hover:border-mojo cursor-pointer border-athensGray border rounded p-8 bg-white mt-6"
>
  <div class="flex">
    <svg width="44px" height="52px" viewBox="0 0 44 52" xmlns="http://www.w3.org/2000/svg">
      <g id="Screens" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="9---Attachments---View-Attachment" transform="translate(-820.000000, -640.000000)">
          <g id="Attachment-Actions&amp;info-ONHOVER" transform="translate(808.000000, 628.000000)">
            <g id="Group-3" transform="translate(12.000000, 12.000000)">
              <rect id="Rectangle" stroke="#E8EBF0" fill="#FFFFFF" x="0.5" y="0.5" width="43" height="51" rx="6"></rect>
              <rect id="Rectangle" fill="#D8D8D8" x="30" y="9" width="7" height="3" rx="1"></rect>
              <rect id="Rectangle" fill="#D8D8D8" x="24" y="14" width="13" height="3" rx="1"></rect>
              <rect id="Rectangle" fill="#D8D8D8" x="8" y="26" width="29" height="3" rx="1"></rect>
              <rect id="Rectangle" fill="#E8EBF0" x="8" y="32" width="29" height="3" rx="1"></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <div class="flex flex-col ml-4">
      <span class="text-ebonyClay font-semibold text-sm" data-cy="attachment-attachment-file-name"
        >{attachment.fileName}</span
      >
      <span class="text-baliHai text-s"
        >{attachment.type? attachment.type.split("/")[1].toUpperCase() : ''} &middot; {bytesToHighestUnitDisplay(attachment.size, 1)}</span
      >
    </div>
  </div>
  <div class="relative flex space-x-2">
    {#if !attachment.tags.includes(EmailAttachmentTag.Manual)}
      <!-- svelte-ignore a11y-invalid-attribute -->
      <a href="#" on:click={() => download(attachment)}>
        <i class="icon-download text-ebonyClay text-3xl"></i>
      </a>
    {/if}
    {#if attachment.tags.includes(EmailAttachmentTag.Manual)}
      <span on:click={e => {
        e.stopPropagation()
        removeAttachment(attachment)
      }}>
        <i class="icon-outline-close text-ebonyClay text-3xl"></i>
      </span>
    {/if}
  </div>
</div>

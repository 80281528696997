import { get, writable, type Writable } from 'svelte/store'
import type { TodoListItem } from '../models/todo-list-item'
import initializationStore from '../../core-app/stores/initialization.store'
import type GlobalInitializationStore from '../../core-app/models/initialization-store'

/* console.log('todos.store.ts')*/

/**
 * Boolean flag to indicate if the store is initialized
 */
export const isFetching: Writable<boolean> = writable(true)

export const TodosStore: Writable<TodoListItem[]> = writable<TodoListItem[]>([]);



/**
 * Check if the input data is an array of TodoListItem
 * @param data
 */
function isTodoListItemArray(data: unknown): data is TodoListItem[] {
  if (!Array.isArray(data)) {
    return false
  }

  return data.every(item => typeof item === 'object' && 'actionKind' in item && 'actionMessage' in item && 'id' in item)
}

/**
 * Initialize the TodosStore
 * - Sets the todosStoreInitialized flag to true in the initializationStore.
 * - Uses the safelyGetArray utility function to ensure the input data is an array.
 * - Sets the initial value of the TodosStore and isFetching variables.
 * - Subscribes to the TodosStore, handling updates with the extracted functions and emitting appropriate events.
 * @param data
 */
export const initializeTodosStore = (data: TodoListItem[] | []): void => {
  if (!get(initializationStore).todosStoreInitialized) {

    initializationStore.update((store: GlobalInitializationStore) => {
      store.todosStoreInitialized = true

      return store
    })

    const todoList: TodoListItem[] = isTodoListItemArray(data) ? data : []

    TodosStore.set(todoList)
    isFetching.set(false)

    let isTodosStoreSubscriptionDefined: boolean = false

    // Subscribe to the TodosStore, handling updates with the extracted functions and emitting appropriate events.
    TodosStore.subscribe((): void => {
      if (!isTodosStoreSubscriptionDefined) isTodosStoreSubscriptionDefined = true
    })

    /* console.log('%c TodosStore initialized.', 'color: #8A99AC') */
  }
}

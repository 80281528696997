<script lang="ts">
    import { fade } from 'svelte/transition'
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper.js'
    import { formatCurrencyWithOptions } from '../../../utils/number-formatting-utils.js'
    import { BusinessDocumentLineItem } from '../../../models/business-document'

    /** Export let */
    export let item: BusinessDocumentLineItem
</script>

<tr class="border-b border-gray-200" transition:fade>
    <td class="py-4 pl-4 pr-3 text-sm w-60 sm:pl-6 md:pl-0 break-words  align-top" style="max-width: 250px;">
        <div class="font-medium text-black text-xs leading-3 mb-0.5">{#if item.supplierReference}{item.supplierReference} - {/if} {item?.title}</div>
        <div class="text-black text-xxs leading-3">{@html item?.description}</div>
    </td>
    <td class="hidden py-4 px-3 text-right text-sm text-paleSky sm:table-cell  align-top">
        {item?.quantity} {t('unitTypesList.' + item?.itemPrice.unit, { count: item?.quantity })}
    </td>
    <td class="hidden py-4 px-3 text-right text-sm text-paleSky sm:table-cell  align-top">
        {formatCurrencyWithOptions(item?.itemPrice.scaledValue, t('locales'), item?.itemPrice.currency, true, 'symbol')}
    </td>
    <td class="hidden py-4 px-3 text-right text-sm text-paleSky sm:table-cell  align-top">{item?.taxRate.displayName}</td>
    <td class="py-4 pl-3 pr-4 text-right text-sm text-paleSky sm:pr-6 md:pr-0  align-top">
        {formatCurrencyWithOptions(item?.lineItemTotalExcludingTaxScaledValue, t('locales'), item?.itemPrice.currency, true, 'symbol')}
    </td>
</tr>

<style>
    td {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }
</style>
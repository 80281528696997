<script lang="ts">
  import { BarOrientation, type BarWidth, type BarChoices, type BarChoice } from './bar.d'

  export let choices:BarChoices = []
  export let value:string | string[] = ''
  export let multiple:boolean = false
  export let allowEmpty:boolean = false
  export let disabled:boolean = false
  export let width:BarWidth = ''
  export let orientation:BarOrientation = BarOrientation.HORIZONTAL

  const onSelect = (item:BarChoice) => {
    // SINGLE ITEM
    if (!multiple) {
      if (allowEmpty) {
        if (JSON.stringify(value) === JSON.stringify([item.key])) {
          value = ''
        } else {
          value = item.key
        }
      } else {
        if ([item.key]) value = item.key
      }
      
      return
    }

    // MULTIPLE ITEMS
    const exist = (<string[]>value).find(v => v === item.key)
    if (!exist) {
      value = [...value, item.key]
      
      return
    }

    const tmp = (<string[]>value).filter(v => v !== item.key)
    
    if (!allowEmpty && tmp.length === 0) return
    value = tmp
  }

</script>


<div class="relative justify-center rounded bg-whisper border border-athensGray px-1.5 py-1.5 {orientation === BarOrientation.VERTICAL ? 'inline-block' : 'flex'} {width === 'full' ? 'w-full' : width}">

  {#each choices as choice, i}
    <button value={choice.label} class='choice {orientation === BarOrientation.VERTICAL && i < choices.length - 1 ? 'mb-2' : ''}'
      class:active={value.includes(choice.key)}
      class:disabled={disabled}
      on:click={() => onSelect(choice)}>

      <span style={`mask: url(${choice.icon}); mask-repeat: no-repeat;`} />

      {choice.label}

    </button>
  {/each}

</div>

<style lang="postcss">
  .choice {
    @apply mr-1 px-3 py-2 w-full flex items-center whitespace-nowrap font-medium rounded-md text-zinc-400 text-s xl:text-sm border border-transparent;

    span {
      @apply block size-5 bg-zinc-400 mr-2 -mt-1;
    }

    &:hover {
      @apply text-zinc-700 bg-zinc-50 border-zinc-700;

      span {
        @apply bg-zinc-700;
      }
    }

    &.active {
      @apply flex items-center rounded-md border border-black  bg-white text-black shadow-lg;

      span {
        @apply bg-zinc-700;
      }
    }

    &.disabled {
        @apply pointer-events-none text-zinc-300 bg-zinc-50 border-zinc-200;

      span {
        @apply bg-zinc-300;
      }
    }
  }
</style>
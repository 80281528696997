import { doesLegalStructureRequireShareCapital } from '../../core-app/services/companies-legal-structures'
import { get } from 'svelte/store'
import { WorkspaceStore } from '../stores/workspace.store'
import type { Contact } from './contact'
import type { Customer } from './customer'
import type { WorkspaceBankConfig } from '../../pay-app/models/workspace-bank-config'
import type Company from './company'
import { isAPE, isSIREN, isSIRET, isVAT } from '$src/core-app/util/regexp-utils'

export class Workspace {
  ownerId: string
  workspaceId: string
  mainUsersIds: string[]
  teamUsersIds: string[]
  customers: Customer[]
  contacts: Contact[]
  company: Company
  bankConfig: WorkspaceBankConfig
  testEndToEnd: boolean
  isOnboardingCompanyInfoDataComplete(): boolean {
    console.log(this.company.formalName, "isOnboardingCompanyInfoDataComplete running with regulatory=", this.company.regulatory)
    if (!this) {
      console.log("isOnboardingCompanyInfoDataComplete false: !this")
      return false
    }
    if (!this.workspaceId) {
      console.log("isOnboardingCompanyInfoDataComplete false: !this.workspaceId")
      return false
    }
    if (!this.company.formalName) {
      console.log("isOnboardingCompanyInfoDataComplete false: !this.company.formalName")
      return false
    }
    
    const street = this.company.mailAddress.street
    const zipCode = this.company.mailAddress.zipCode
    const city = this.company.mailAddress.city
    const country = this.company.mailAddress.countryCode
    if (!(street && zipCode && city && country)) {
      console.log("isOnboardingCompanyInfoDataComplete false: !(street && zipCode && city && country)")
      return false
    }

    const isFrench:boolean = country === 'FR'
    const isEuropean:boolean = this.company.regulatory.isEuRegulated

    // const ape:boolean = !isFrench || (isFrench && !this.company.registrationPending && isAPE(country, this.company?.regulatory?.frRegulScope?.nafCodeLevel4 ?? ''))
    // if (!ape) {
    //   console.log("isOnboardingCompanyInfoDataComplete false: !ape")
    //   console.log("isOnboardingCompanyInfoDataComplete false: isFrench=",isFrench)
    //   console.log("isOnboardingCompanyInfoDataComplete false: !this.company.registrationPending=",!this.company.registrationPending)
    //   console.log("isOnboardingCompanyInfoDataComplete false: country=",country)
    //   console.log("isOnboardingCompanyInfoDataComplete false: inseeData?.activity=",this.company?.regulatory?.frRegulScope?.inseeData?.activity)
    //   console.log("isOnboardingCompanyInfoDataComplete false: this.company?.regulatory?.frRegulScope?.nafCodeLevel4 ?? ''=",this.company?.regulatory?.frRegulScope?.nafCodeLevel4 ?? '')
    //   console.log("isOnboardingCompanyInfoDataComplete false: isAPE(country, this.company?.regulatory?.frRegulScope?.nafCodeLevel4 ?? '')=",isAPE(country, this.company?.regulatory?.frRegulScope?.nafCodeLevel4 ?? ''))
    //   return false
    // }

    const vat:boolean = !isEuropean || (isEuropean && !this.company.registrationPending && isVAT(country, this.company?.regulatory?.euRegulScope?.euIntraVat ?? '', this.company?.regulatory?.frRegulScope?.siren))
    if (!vat) {
      console.log("isOnboardingCompanyInfoDataComplete false: !vat")
      return false
    }
    
    const siren:boolean = !isFrench || (isFrench && !this.company.registrationPending && isSIREN(this.company?.regulatory?.frRegulScope?.siren ?? ''))
    if (!siren) {
      console.log("isOnboardingCompanyInfoDataComplete false: !siren")
      return false
    }

    const siret:boolean = !isFrench || (isFrench && !this.company.registrationPending && isSIRET(this.company?.regulatory?.frRegulScope?.siret ?? '', this.company?.regulatory?.frRegulScope?.siren))
    if (!siret) {
      console.log("isOnboardingCompanyInfoDataComplete false: !siret")
      return false
    }

    const legalStructure = this.company?.regulatory?.frRegulScope?.inseeData?.legalForm?.level2CatCodeRaw ?? ''
    const mustShareCapital = doesLegalStructureRequireShareCapital(legalStructure)
    const legalStructureValid = !!legalStructure && ((mustShareCapital && !!this.company.shareCapital ) || !mustShareCapital)
    if (!legalStructureValid) {
      console.log("isOnboardingCompanyInfoDataComplete false: !legalStructureValid")
      return false
    }

    return true
  }
  isOnboardingCompanyInfoPlusDataComplete(): boolean {
    if (!this.company) {
      /* console.error('no workspace.company') */
    }
    if (!!this.company && !this.company.mailAddress) {
      /* console.error('no workspace.company.mailAddress') */
    }
    if (!!this.company && !!this.company.mailAddress && !this.company.mailAddress.street) {
      /* console.error('no workspace.company.mailAddress.street') */
    }
    if (!!this.company && !!this.company.mailAddress && !this.company.mailAddress.city) {
      /* console.error('no workspace.company.mailAddress.city') */
    }
    if (!!this.company && !!this.company.mailAddress && !this.company.mailAddress.zipCode) {
      /* console.error('no workspace.company.mailAddress.zipCode') */
    }

    return true
  }
  // converts the WorkspaceStore Svelte store into the Workspace TypeScript class
  static StoreToClass(): Workspace {
    const profileStore: Workspace = get(WorkspaceStore)

    return <Workspace>Object.assign(new Workspace(), profileStore)
  }
}

// converts the js prototype into the TypeScript class
export function WorkspacePrototypeToClass(jsPrototype: Workspace): Workspace {
  return <Workspace>Object.assign(new Workspace(), jsPrototype)
}

import { deepClone } from '../../core-app/util/object-deep-cloning'
import type { BBBTransaction } from '../../bank-app/models/bbb-transactions-model'
import type Invoice from '../../dundy-app/models/invoice'
import { v4 as uuidv4 } from 'uuid'
import { ExchangeDate } from '../../core-app/models/exchange-date'
import { isEqual } from 'lodash'
import { Amount, AmountBasicObject, NewAmountOfMoney } from '../models/amount-of-money-model'
import type { InvoiceNumber } from '../models/cash-application-model'
import {
  filterCADEsForSelectedTransaction,
  findCashApplicationDeclaredEventForTransaction,
  fuseCashApplicationDeclaredEvents,
  getNewCADEsForInvoicesNewlySelectedByUserHenceNotPresentInCADEsInUIYet,
  getInvoiceNumbersSetFromCADEs,
  newCashApplicationDeclaredEventFromInvoiceNumber, getRemainingAmountToApplyForInvoiceExceptWithGivenTransaction
} from './cash-application-pure-functions'
import type { CashApplicationDeclaredEvent } from '../models/cash-application-declared-model'
import type { CashApplicationContext } from './cash-application-helper-pure-functions'
import type { NewCashApplicationUserSelectionForTransaction } from '../models/cash-application-ui-model'


/**
 * Compile cash application declared events for backend according to the UI array
 * Returns an array of CashApplicationDeclaredEvent objects that can be sent to the backend
 * @param selectedTransaction
 * @param cashApplicationDeclaredEventsUIForTransaction
 * @param cashApplicationDeclaredEventsInStore
 */
export function compileCashApplicationDeclaredEventsForBackendFromCashApplicationDeclaredEventsUI(
  selectedTransaction: BBBTransaction,
  cashApplicationDeclaredEventsUIForTransaction: CashApplicationDeclaredEvent[],
  cashApplicationDeclaredEventsInStore: CashApplicationDeclaredEvent[],
): CashApplicationDeclaredEvent[] {

  const existingCADEsInStoreForTransaction: CashApplicationDeclaredEvent[] = fuseCashApplicationDeclaredEvents(cashApplicationDeclaredEventsInStore)
    .filter(filterCADEsForSelectedTransaction(selectedTransaction))
  /* console.log('existingCADEsInStoreForTransaction', existingCADEsInStoreForTransaction) */
  /* console.log('cashApplicationDeclaredEventsUIForTransaction', cashApplicationDeclaredEventsUIForTransaction) */

  const newCADEsInUIForTransaction: CashApplicationDeclaredEvent[] = cashApplicationDeclaredEventsUIForTransaction
    .filter(filterCADEsForSelectedTransaction(selectedTransaction))

  const existingCADEsInStoreForTransactionMap = new Map<string, CashApplicationDeclaredEvent>
  for (const existingCADEInStore of existingCADEsInStoreForTransaction) {
    existingCADEsInStoreForTransactionMap.set(existingCADEInStore.eventId, existingCADEInStore)
  }

  const newCADEsInUIMap = new Map<string, CashApplicationDeclaredEvent>
  for (const event of cashApplicationDeclaredEventsUIForTransaction) {
    newCADEsInUIMap.set(event.eventId, event)
  }

  // CADEsInUI follow the rules:
  // - voided / cancelled former CADEs are removed from the list in UI
  // - modified CADEs are same CADEs (ie for same transaction and same invoice) with different amounts just carry the different amount in UI
  // - new CADEs (ie with a new combination of transaction and invoice) are added to the UI list
  const listOfCADEsDeltas = determineCADEsDeltasBetweenCADEsInUIAndExistingCADEInStoreForCashAppliedTransaction(newCADEsInUIForTransaction, existingCADEsInStoreForTransactionMap)
  const listOfCADEsCompensationsForVoidance = determineVoidedCADEsInUIForCashAppliedTransaction(newCADEsInUIMap, existingCADEsInStoreForTransaction)
  /* console.log('newly voidances', listOfCADEsCompensationsForVoidance.length, listOfCADEsCompensationsForVoidance) */
  /* console.log('newly deltas', listOfCADEsDeltas.length, listOfCADEsDeltas) */
  /* console.log('newly fused deltas and voidances', [...listOfCADEsDeltas, ...listOfCADEsCompensationsForVoidance].length, [...listOfCADEsDeltas, ...listOfCADEsCompensationsForVoidance]) */

  return [...listOfCADEsDeltas, ...listOfCADEsCompensationsForVoidance]
}

function determineCADEsDeltasBetweenCADEsInUIAndExistingCADEInStoreForCashAppliedTransaction(
  newCADEsInUIForTransaction: CashApplicationDeclaredEvent[],
  existingCADEsInStoreForTransactionMap: Map<string, CashApplicationDeclaredEvent>,
): CashApplicationDeclaredEvent[] {
  const determinedCADEsDeltas: CashApplicationDeclaredEvent[] = []
  for (const newCashApplicationDeclaredInUI of newCADEsInUIForTransaction) {
    const testIfCashApplicationIsModified: { isModified: boolean, deltaAmount: Amount, newAmountDueAfterApplication: Amount, newAmountDueBeforeApplication: Amount } =
            isCashApplicationModified(newCashApplicationDeclaredInUI, existingCADEsInStoreForTransactionMap)
    if (testIfCashApplicationIsModified.isModified) {
      const newEvent: CashApplicationDeclaredEvent = deepClone(newCashApplicationDeclaredInUI)
      newEvent.eventId = uuidv4()
      newEvent.created = Math.round((new Date()).getTime() / 1000.0)
      newEvent.createdRFC3339 = new Date().toISOString()
      newEvent.cashApplication.applicationId = uuidv4()
      newEvent.cashApplication.appliedAmount = deepClone(testIfCashApplicationIsModified.deltaAmount)
      newEvent.cashApplication.invoiceAmountDueBeforeApplication = deepClone(testIfCashApplicationIsModified.newAmountDueBeforeApplication)
      newEvent.cashApplication.invoiceAmountDueAfterApplication = deepClone(testIfCashApplicationIsModified.newAmountDueAfterApplication)
      newEvent.creatorEndpoint = 'webApp, is modified, delta sent'
      determinedCADEsDeltas.push(newEvent)
    }
    const testIfCashApplicationIsNew = isCashApplicationNew(newCashApplicationDeclaredInUI, existingCADEsInStoreForTransactionMap)
    if (testIfCashApplicationIsNew.isNew) {
      const newEvent: CashApplicationDeclaredEvent = deepClone(newCashApplicationDeclaredInUI)
      newEvent.creatorEndpoint = 'webApp, is added, new cash application declared event sent'
      determinedCADEsDeltas.push(newEvent)
    }
  }

  return determinedCADEsDeltas
}

function determineVoidedCADEsInUIForCashAppliedTransaction(
  newCADEsInUIMap: Map<string, CashApplicationDeclaredEvent>,
  existingCADEsInStoreForTransaction: CashApplicationDeclaredEvent[],
): CashApplicationDeclaredEvent[] {
  const determinedCADEsCompensationsForVoidance: CashApplicationDeclaredEvent[] = []
  for (const anExistingCADEInStore of existingCADEsInStoreForTransaction) {
    const testIfCashApplicationIsVoided = isCashApplicationVoided(anExistingCADEInStore, newCADEsInUIMap)
    if (testIfCashApplicationIsVoided.isVoided) {
      const newEvent: CashApplicationDeclaredEvent = deepClone(anExistingCADEInStore)
      newEvent.eventId = uuidv4()
      newEvent.created = Math.round((new Date()).getTime() / 1000.0)
      newEvent.createdRFC3339 = new Date().toISOString()
      newEvent.cashApplication.applicationId = uuidv4()
      newEvent.cashApplication.created = ExchangeDate.newDate(new Date())
      newEvent.cashApplication.appliedAmount = NewAmountOfMoney(-Amount.PrototypeToClass(anExistingCADEInStore.cashApplication.appliedAmount).GetAmountScaledValue(), 2, anExistingCADEInStore.cashApplication.appliedAmount.currencyCode)
      newEvent.cashApplication.invoiceAmountDueBeforeApplication = deepClone(anExistingCADEInStore.cashApplication.invoiceAmountDueAfterApplication)
      newEvent.cashApplication.invoiceAmountDueAfterApplication = deepClone(anExistingCADEInStore.cashApplication.invoiceAmount)
      newEvent.creatorEndpoint = 'webApp, is removed, void data sent'
      determinedCADEsCompensationsForVoidance.push(newEvent)
    }
  }

  return determinedCADEsCompensationsForVoidance
}

function isCashApplicationNew(newCashApplicationDeclaredInUI: CashApplicationDeclaredEvent, existingCashApplicationDeclaredInStore: Map<string, CashApplicationDeclaredEvent>): { isNew: boolean } {
  let correspondingExistingCashApplicationDeclaredEventInStore: CashApplicationDeclaredEvent
  for (let anExistingCADEInStoreKey of existingCashApplicationDeclaredInStore.keys()) {
    if (existingCashApplicationDeclaredInStore.get(anExistingCADEInStoreKey).cashApplication.bankTransaction.transactionId === newCashApplicationDeclaredInUI.cashApplication.bankTransaction.transactionId
            && existingCashApplicationDeclaredInStore.get(anExistingCADEInStoreKey).invoiceNumber === newCashApplicationDeclaredInUI.invoiceNumber) {
      correspondingExistingCashApplicationDeclaredEventInStore = existingCashApplicationDeclaredInStore.get(anExistingCADEInStoreKey)
      /* console.log('newly found correspondingExistingCashApplicationDeclaredEventInStore') */
    }
  }
  // correspondingExistingCashApplicationDeclaredEventInStore= existingCashApplicationDeclaredInStore.get(newCashApplicationDeclaredInUI.eventId);
  const newCashApplicationDeclaredEventNotInStore = !correspondingExistingCashApplicationDeclaredEventInStore

  return { isNew: newCashApplicationDeclaredEventNotInStore }
}

function isCashApplicationModified(newCashApplicationDeclaredInUI: CashApplicationDeclaredEvent, existingCashApplicationDeclaredInStore: Map<string, CashApplicationDeclaredEvent>): { isModified: boolean, deltaAmount: Amount, newAmountDueAfterApplication: Amount, newAmountDueBeforeApplication: Amount } {
  let correspondingExistingCashApplicationDeclaredEventInStore: CashApplicationDeclaredEvent
  let list: string[] = []
  existingCashApplicationDeclaredInStore.forEach((anExistingCADEInStore) => {
    list.push('CADE: ' + anExistingCADEInStore.eventId + ' ... '
            + anExistingCADEInStore.cashApplication.bankTransaction.transactionId + ' x '
            + anExistingCADEInStore.cashApplication.invoiceNumber + ' = '
            + anExistingCADEInStore.cashApplication.appliedAmount.scaledValue + ' EUR')
  })
  /* console.log('newly existingCashApplicationDeclaredInStore', existingCashApplicationDeclaredInStore) */
  console.log('newly newCashApplicationDeclaredInUI to search', 'CADE: ' + newCashApplicationDeclaredInUI.cashApplication.bankTransaction.transactionId + ' x '
        + newCashApplicationDeclaredInUI.cashApplication.invoiceNumber + ' = '
        + newCashApplicationDeclaredInUI.cashApplication.appliedAmount.scaledValue + ' EUR')
  /* console.log('newly existingCashApplicationDeclaredInStore list', list) */
  for (let anExistingCADEInStoreKey of existingCashApplicationDeclaredInStore.keys()) {
    if (existingCashApplicationDeclaredInStore.get(anExistingCADEInStoreKey).cashApplication.bankTransaction.transactionId === newCashApplicationDeclaredInUI.cashApplication.bankTransaction.transactionId
            && existingCashApplicationDeclaredInStore.get(anExistingCADEInStoreKey).invoiceNumber === newCashApplicationDeclaredInUI.invoiceNumber) {
      correspondingExistingCashApplicationDeclaredEventInStore = existingCashApplicationDeclaredInStore.get(anExistingCADEInStoreKey)
      /* console.log('newly found correspondingExistingCashApplicationDeclaredEventInStore') */
    }
  }
  // correspondingExistingCashApplicationDeclaredEventInStore = existingCashApplicationDeclaredInStore.get(newCashApplicationDeclaredInUI.eventId);
  const newCashApplicationDeclaredEventIsAlreadyInStore = !!correspondingExistingCashApplicationDeclaredEventInStore
  const newCashApplicationDeclaredEventHasDifferentAmount = !isEqual(Amount.PrototypeToClass(newCashApplicationDeclaredInUI.cashApplication.appliedAmount), Amount.PrototypeToClass(correspondingExistingCashApplicationDeclaredEventInStore?.cashApplication.appliedAmount))
  let amountDelta: Amount
  let newAmountDueAfterApplication: Amount
  let newAmountDueBeforeApplication: Amount
  /* console.log('newly isCashApplicationModified') */
  /* console.log('newly newCashApplicationDeclaredEventIsAlreadyInStore', newCashApplicationDeclaredEventIsAlreadyInStore) */
  /* console.log('newly newCashApplicationDeclaredEventHasDifferentAmount', newCashApplicationDeclaredEventHasDifferentAmount) */
  if (newCashApplicationDeclaredEventIsAlreadyInStore && newCashApplicationDeclaredEventHasDifferentAmount) {
    /* console.log('newly amount after (new ui amount)', Amount.PrototypeToClass(newCashApplicationDeclaredInUI.cashApplication.appliedAmount).GetAmountScaledValue()) */
    /* console.log('newly amount before (current store amount)', Amount.PrototypeToClass(correspondingExistingCashApplicationDeclaredEventInStore.cashApplication.appliedAmount).GetAmountScaledValue()) */
    /* console.log('newly amount delta', Amount.PrototypeToClass(newCashApplicationDeclaredInUI.cashApplication.appliedAmount).GetAmountScaledValue()
            - Amount.PrototypeToClass(correspondingExistingCashApplicationDeclaredEventInStore.cashApplication.appliedAmount).GetAmountScaledValue()
    amountDelta = NewAmountOfMoney(
      Amount.PrototypeToClass(newCashApplicationDeclaredInUI.cashApplication.appliedAmount).GetAmountScaledValue()
            - Amount.PrototypeToClass(correspondingExistingCashApplicationDeclaredEventInStore.cashApplication.appliedAmount).GetAmountScaledValue(),
      2, newCashApplicationDeclaredInUI.cashApplication.appliedAmount.currencyCode,
    )*/
    /* console.log('newly amountDelta for Tra=' + newCashApplicationDeclaredInUI.cashApplication.bankTransaction.transactionId + ' and Inv=' + newCashApplicationDeclaredInUI.invoiceNumber, amountDelta) */
    // a positive delta amount of cash application, means it covers more of the invoice, means the new amount due is less (we must apply the opposite of the delta)
    // a negative delta amount of cash application, means it covers less of the invoice, means the new amount due is more (we must apply the opposite of the delta)
    newAmountDueAfterApplication = NewAmountOfMoney(Amount.PrototypeToClass(correspondingExistingCashApplicationDeclaredEventInStore.cashApplication.invoiceAmountDueAfterApplication).GetAmountScaledValue() - amountDelta.GetAmountScaledValue(), 2, newCashApplicationDeclaredInUI.cashApplication.appliedAmount.currencyCode)
    newAmountDueBeforeApplication = Amount.PrototypeToClass(correspondingExistingCashApplicationDeclaredEventInStore.cashApplication.invoiceAmountDueAfterApplication)
  }

  return {
    isModified: newCashApplicationDeclaredEventIsAlreadyInStore && newCashApplicationDeclaredEventHasDifferentAmount,
    deltaAmount: amountDelta,
    newAmountDueAfterApplication: newAmountDueAfterApplication,
    newAmountDueBeforeApplication: newAmountDueBeforeApplication
  }
}

function isCashApplicationVoided(anExistingCashApplicationDeclaredInStore: CashApplicationDeclaredEvent, newCashApplicationDeclaredEventsInUI: Map<string, CashApplicationDeclaredEvent>): { isVoided: boolean } {
  let correspondingNewCashApplicationDeclaredEventInUI: CashApplicationDeclaredEvent
  for (let aNewCADEInUIKey of newCashApplicationDeclaredEventsInUI.keys()) {
    if (newCashApplicationDeclaredEventsInUI.get(aNewCADEInUIKey).cashApplication.bankTransaction.transactionId === anExistingCashApplicationDeclaredInStore.cashApplication.bankTransaction.transactionId
            && newCashApplicationDeclaredEventsInUI.get(aNewCADEInUIKey).invoiceNumber === anExistingCashApplicationDeclaredInStore.invoiceNumber) {
      correspondingNewCashApplicationDeclaredEventInUI = newCashApplicationDeclaredEventsInUI.get(aNewCADEInUIKey)
      /* console.log('newly found correspondingNewCashApplicationDeclaredEventInUI') */
    }
  }
  // correspondingNewCashApplicationDeclaredEventInUI= newCashApplicationDeclaredEventsInUI.get(anExistingCashApplicationDeclaredInStore.eventId);
  const existingCashApplicationDeclaredEventInStoreNotInUI = !correspondingNewCashApplicationDeclaredEventInUI

  return { isVoided: existingCashApplicationDeclaredEventInStoreNotInUI }
}

/**
 * Generate Cash Application Declared Events (CADEs) in UI
 * CADEs in UI have changes coming from:
 * - CADEs changed in the store (...InStore)
 * - CADEs changed by the user (...InUI)
 * @param selectedTransaction
 * @param userSelectedInvoiceNumbersSet
 * @param currentCADEsInUI
 * @param latestCADEsInStore is representing the CADEs in Store that will change over time (optimistic changes and updates from back-end), used in getNewCADEsInStoreNewItemsVSLatestCADESInStoreForUserSelectedInvoices()
 * @param newCADEsInStore
 * @param allInvoices
 * @param currentCashApplicationContext
 * @param newCashApplicationUserSelectionForTransaction
 */
export function updateCADEsInUI(
  selectedTransaction: BBBTransaction,
  userSelectedInvoiceNumbersSet: Set<InvoiceNumber>,
  currentCADEsInUI: CashApplicationDeclaredEvent[],
  latestCADEsInStore: CashApplicationDeclaredEvent[],
  newCADEsInStore: CashApplicationDeclaredEvent[],
  allInvoices: Invoice[],
  currentCashApplicationContext: CashApplicationContext,
  newCashApplicationUserSelectionForTransaction: NewCashApplicationUserSelectionForTransaction): { newCADEsInUI: CashApplicationDeclaredEvent[], newLatestCADEsInStore: CashApplicationDeclaredEvent[] } {
  /* console.log('updateCADEsInUI()') */
  const callId: string = uuidv4().slice(0, 6)


  // consider only CADEsInStore for the currently selected transaction
  const relevantLatestCADEsInStoreForTransaction = findCashApplicationDeclaredEventForTransaction(selectedTransaction, latestCADEsInStore)
  const relevantNewCADEsInStoreForTransaction = findCashApplicationDeclaredEventForTransaction(selectedTransaction, newCADEsInStore)

  // nextLatestCADEsInStore: the new list of CADEs in UI is the combination of:
  //
  // A - current CADEs in UI where the invoices are still selected by user (then we keep the applied amount present in current CADEInUI because it may have been altered by user)
  //         Hence we discard CADEs in UI where the invoices are no longer selected by user.
  //
  // B - we shall add CADEs corresponding to newly selected invoices by the user (where new selected invoices will have the full invoice amount as applied amount)
  //         We add the items using the latestCADEsInStore, not the items of the newCADEsInStore. Otherwise, we could have duplicate items when adding the difference between the latestCADEsInStore and the newCADEsInStore.
  //         We can possibly return several CADEs for a pair of TransactionId and InvoiceNumber since we source our data from a CADEsInStore.
  //
  // C - new CADEs changes from Store (add everything new when comparing un-compiled events between latestCADEsInStore and newCADEsInStore)
  //         Only keep CADEs changes corresponding to a currently user-selected invoice.
  //
  // D - compile/fuse the resulting CADEs to build the resulting newCADEsInUI
  //
  // NB:
  // - In A, shouldn't we discard CADEs in UI where the invoices are no longer selected by user... unless there is a new item in newCADEInStore (vs latestCADEInStore) for this invoice ?
  // A
  let wipANextCADEsInUI: CashApplicationDeclaredEvent[] = getCurrentCADEsWhereInvoicesAreStillInTheUserSelection(currentCADEsInUI, userSelectedInvoiceNumbersSet)
  if (!!newCashApplicationUserSelectionForTransaction && !newCashApplicationUserSelectionForTransaction.isRemovedItem && !!newCashApplicationUserSelectionForTransaction.addedInvoiceByUser) {
    // replace in relevantNewCADEsInStoreForTransaction the added CADE item (from existing allCADEsInStore) by a brand-new CADE for this Transaction x Invoice (avoid weird old values)
    // remove the one just being selected
    wipANextCADEsInUI = wipANextCADEsInUI
      .filter((aCADE: CashApplicationDeclaredEvent) => aCADE.invoiceNumber !== newCashApplicationUserSelectionForTransaction.addedInvoiceByUser.invoiceNumber)
    // add a new one
    const replacementCADE: CashApplicationDeclaredEvent = newCashApplicationDeclaredEventFromInvoiceNumber(selectedTransaction, newCashApplicationUserSelectionForTransaction.addedInvoiceByUser.invoiceNumber, allInvoices, currentCashApplicationContext)
    replacementCADE.cashApplication.appliedAmount = Amount.NewAmountOfMoney(
      getRemainingAmountToApplyForInvoiceExceptWithGivenTransaction(
        newCashApplicationUserSelectionForTransaction.addedInvoiceByUser.invoiceNumber,
        Amount.NewAmountOfMoney(
          newCashApplicationUserSelectionForTransaction.addedInvoiceByUser.amountIncludingTaxes,
          2,
          newCashApplicationUserSelectionForTransaction.addedInvoiceByUser.currency,
        ),
        latestCADEsInStore,
        selectedTransaction.id.toString(),
      ),
      2,
      newCashApplicationUserSelectionForTransaction.addedInvoiceByUser.currency)
    wipANextCADEsInUI.push(replacementCADE)
  }
  // B
  const currentCADEsInUIInvoiceNumbersSet: Set<InvoiceNumber> = getInvoiceNumbersSetFromCADEs(currentCADEsInUI)


  const wipBNextCADEsInUI: CashApplicationDeclaredEvent[] = getNewCADEsForInvoicesNewlySelectedByUserHenceNotPresentInCADEsInUIYet(currentCADEsInUIInvoiceNumbersSet, userSelectedInvoiceNumbersSet, selectedTransaction, allInvoices, currentCashApplicationContext)
  // C
  const wipCNextCADEsInUI: CashApplicationDeclaredEvent[] = getNewCADEsInStoreNewItemsVSLatestCADESInStoreForUserSelectedInvoices(relevantLatestCADEsInStoreForTransaction, relevantNewCADEsInStoreForTransaction, userSelectedInvoiceNumbersSet)
  // D
  const compositeCADEsInUI: CashApplicationDeclaredEvent[] = <CashApplicationDeclaredEvent[]>deepClone(<CashApplicationDeclaredEvent[]>[
    ...<CashApplicationDeclaredEvent[]>deepClone(wipANextCADEsInUI),
    ...<CashApplicationDeclaredEvent[]>deepClone(wipBNextCADEsInUI),
    ...<CashApplicationDeclaredEvent[]>deepClone(wipCNextCADEsInUI)
  ])

  let nextCADEsInUI: CashApplicationDeclaredEvent[] = fuseCashApplicationDeclaredEvents(compositeCADEsInUI)

  const updatedCADEsInUIResponse = {
    newCADEsInUI: nextCADEsInUI,
    newLatestCADEsInStore: <CashApplicationDeclaredEvent[]>deepClone(newCADEsInStore)
  }
  /* console.log(callId, ' selekt updatedCADEsInUIResponse', updatedCADEsInUIResponse) */

  return updatedCADEsInUIResponse
}

// A - current CADEs in UI where the invoices are still selected by user (then we keep the applied amount present in current CADEInUI because it may have been altered by user)
//         Hence we discard CADEs in UI where the invoices are no longer selected by user.
function getCurrentCADEsWhereInvoicesAreStillInTheUserSelection(currentCADEsInUI: CashApplicationDeclaredEvent[], selectedInvoiceNumbersSet: Set<InvoiceNumber>): CashApplicationDeclaredEvent[] {
  /* console.log('getCurrentCADEsWhereInvoicesAreStillInTheUserSelection()') */
  const nextCADEsInUI: CashApplicationDeclaredEvent[] = <CashApplicationDeclaredEvent[]>[]
  /* console.log('init currentCADEsInUI', currentCADEsInUI) */
  for (let aCurrentCADEInUI of currentCADEsInUI) {
    if (selectedInvoiceNumbersSet.has(aCurrentCADEInUI.invoiceNumber)) {
      nextCADEsInUI.push(aCurrentCADEInUI)
    }
  }
  /* console.log('nextCADEsInUI', nextCADEsInUI) */

  return nextCADEsInUI
}

// C - new CADEs changes from Store (add everything new when comparing un-compiled events between latestCADEsInStore and newCADEsInStore)
//         Only keep CADEs changes corresponding to a currently user-selected invoice.
function getNewCADEsInStoreNewItemsVSLatestCADESInStoreForUserSelectedInvoices(latestCADEsInStore: CashApplicationDeclaredEvent[], newCADEsInStore: CashApplicationDeclaredEvent[], selectedInvoiceNumbersSet: Set<InvoiceNumber>): CashApplicationDeclaredEvent[] {
  /* console.log('getNewCADEsInStoreNewItemsVSLatestCADESInStoreForUserSelectedInvoices()') */
  const additionalCADEsInStore: CashApplicationDeclaredEvent[] = <CashApplicationDeclaredEvent[]>[]
  for (let aNewCADEInStore of newCADEsInStore) {
    let alreadyInLatest: boolean = false
    for (let aLatestCADEInStore of latestCADEsInStore) {
      if ((aLatestCADEInStore.eventId === aNewCADEInStore.eventId)) {
        alreadyInLatest = true
        break // we found it, not a new item, go next
      }
    }
    if (alreadyInLatest) {
      continue
    }
    if (selectedInvoiceNumbersSet.has(aNewCADEInStore.invoiceNumber)) {
      additionalCADEsInStore.push(aNewCADEInStore)
    }
  }
  /* console.log('additionalCADEsInStore', additionalCADEsInStore) */

  return additionalCADEsInStore
}

export function getInitialCashApplicationDeclaredEventsUIForTransactionId(selectedTransaction: BBBTransaction, allCADEsInStore: CashApplicationDeclaredEvent[]) {
  const filterCADEsForTransaction = (cade: CashApplicationDeclaredEvent) => (cade.cashApplication.bankTransaction.transactionId === selectedTransaction.id.toString())

  return fuseCashApplicationDeclaredEvents(allCADEsInStore.filter(filterCADEsForTransaction))
}

/**
 * Returns the user selected invoices from AG GRID
 * @param selectedInvoicesInUIList
 */
export function userSelectedInvoiceNumbersAccordingToUserSelectionInUIList(selectedInvoicesInUIList: Invoice[]): Set<InvoiceNumber> {
  const correspondingCADEsInvoicesSet: Set<InvoiceNumber> = new Set(selectedInvoicesInUIList.map((selectedRow: Invoice) => (selectedRow.invoiceNumber as InvoiceNumber)))

  return correspondingCADEsInvoicesSet
}

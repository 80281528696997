import type { BusinessDocument } from '../../models/business-document'
import {
  filterAllBusinessDocumentsFinalized
} from '../business-document-array-filters/business-documents-filters.service'

export function hasAtLeastAnotherFinalizedBusinessDocumentWithSameDeal(businessDocument: BusinessDocument, allBusinessDocuments: BusinessDocument[]): boolean {
  return !!anyOtherFinalizedBusinessDocumentWithSameDeal(businessDocument, allBusinessDocuments)
}

/**
 * Returns undefined if none found or if the deal does not exist or is not relevant
 * @param businessDocument
 * @param allBusinessDocuments
 */
export function anyOtherFinalizedBusinessDocumentWithSameDeal(businessDocument: BusinessDocument, allBusinessDocuments: BusinessDocument[]): BusinessDocument {
  if (isBusinessDocumentDealRelevant(businessDocument)) {
    // it is enough to find at least another business document with the same deal id
    const otherFinalizedOneWithSameDeal: BusinessDocument = allBusinessDocuments
      .filter(filterAllBusinessDocumentsFinalized)
      .find(b => ((b.businessDocumentId !== businessDocument.businessDocumentId) && (b.linkedDeal.dealId === businessDocument.linkedDeal.dealId)))
    
    return otherFinalizedOneWithSameDeal // is undefined if no other finalized document of the same deal found
  } else {
    return undefined // is undefined if deal does not exist or is not relevant
  }
}

export function isBusinessDocumentDealRelevant(businessDocument: BusinessDocument): boolean {
  return !!businessDocument.linkedDeal && !!businessDocument.linkedDeal.dealId && businessDocument.linkedDeal.dealId !== ''
}
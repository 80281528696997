import type { EscalationTabToMessageKind } from '../models/todo-ui-dunning-escalation-tabs'
import { TodoUIDunningEscalationTabs } from '../models/todo-ui-dunning-escalation-tabs'
import { TodoItemMessageKind } from '../models/todo-list-item'
import type { SendGridEmailConfig, SendGridEmailRecipient } from '../models/sendgrid-email-configuration'

/** Contains All Configuration for Dundy Environment **/
// TODO - Move this to a config file and read from there
const dundyConfig = {
  /** Max Days Since Due Date To Higher Escalation */
  maxDaysSinceDueDateToHigherEscalation: 6.0,
  /** Escalation Tab To Message Kind */
  escalationTabToMessageKind: {
    [TodoUIDunningEscalationTabs.INFORMATION]: TodoItemMessageKind.EMAIL,
    [TodoUIDunningEscalationTabs.REMINDER]: TodoItemMessageKind.EMAIL,
    [TodoUIDunningEscalationTabs.WARNING]: TodoItemMessageKind.EMAIL_AND_REGULAR_POST_MAIL,
    [TodoUIDunningEscalationTabs.LEGAL]: TodoItemMessageKind.LAWYER_AND_OTHER_LEGAL_SERVICES
  },
  /** Base Email Configuration */
  baseEmailConfig: <SendGridEmailConfig>{
    from: <SendGridEmailRecipient>{
      email: 'contact@dundy-icarus.info',
      name: 'U-Trade'
    }
  },
  /** Sendgrid Email Template Ids */
  emailTemplateIds: {
    successfulJoin: 'd-9a6d56aecbf445aa98fbe06a3f0b35dc',
    successfulInvitation: 'd-e8704d9833ea4f01b5a2e7604a865925',
    firstReminder: 'd-46c92fa0183f407c8d54e8e05bd79ce1',
    firstInvite: 'd-40533ddbb09d43fb8a85b437558a60de',
    todayDigest: 'd-23bc0a08ab564357a2322dfcbf00c4d3',
    authenticateEmail: 'd-f6ac0b2661c744d4937c11ae8ce6e19a',
    todoActionEmail: 'd-9fddfcea4e99475e922fb2778ae06f97'
  }

}

/**
 * Returns the Dundy Configuration
 * //TODO - Move this to a config file and read from there
 * No usages yet
 */
export function getDundyConfig() {
  return dundyConfig
}

/**
 * Date Threshold to escalate to the next level
 * Defines the maximum number of days since the due date to escalate to the next level
 */
export const maxDaysSinceDueDateToHigherEscalation = 6.0

/**
 * The Lookup table for the escalation tab to message kind
 * Configuration for the escalation tab to message kind
 */
export const escalationTabToMessageKind: EscalationTabToMessageKind = {
  [TodoUIDunningEscalationTabs.INFORMATION]: TodoItemMessageKind.EMAIL,
  [TodoUIDunningEscalationTabs.REMINDER]: TodoItemMessageKind.EMAIL,
  [TodoUIDunningEscalationTabs.WARNING]: TodoItemMessageKind.EMAIL_AND_REGULAR_POST_MAIL,
  [TodoUIDunningEscalationTabs.LEGAL]: TodoItemMessageKind.LAWYER_AND_OTHER_LEGAL_SERVICES
}

/**
 * The base email configuration
 *
 */
export const baseEmailConfig: SendGridEmailConfig = <SendGridEmailConfig>{
  from: <SendGridEmailRecipient>{
    email: 'contact@dundy-icarus.info',
    name: 'U-Trade'
  }
}

/**
 * The Sendgrid email template ids
 * List of Sendgrid templates
 */
export const emailTemplateIds = {
  successfulJoin: 'd-9a6d56aecbf445aa98fbe06a3f0b35dc',
  successfulInvitation: 'd-e8704d9833ea4f01b5a2e7604a865925',
  firstReminder: 'd-46c92fa0183f407c8d54e8e05bd79ce1',
  firstInvite: 'd-40533ddbb09d43fb8a85b437558a60de',
  todayDigest: 'd-23bc0a08ab564357a2322dfcbf00c4d3',
  authenticateEmail: 'd-f6ac0b2661c744d4937c11ae8ce6e19a',
  todoActionEmail: 'd-9fddfcea4e99475e922fb2778ae06f97'
  /*todoActionEmail: 'd-455055df9a1f4630aa4b15f85baec0e6',*/
}


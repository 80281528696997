import { BusinessDocumentCollateralData } from '../../models/business-document'
import { deepClone } from '../../../core-app/util/object-deep-cloning'

/**
 * Create collateral data for new credit note
 * @param originalCollateralData
 * @param newBusinessDocumentId
 */
export function generateNewCollateralData(originalCollateralData: BusinessDocumentCollateralData, newBusinessDocumentId: string): BusinessDocumentCollateralData {
  let newCollateralData: BusinessDocumentCollateralData = deepClone(originalCollateralData)
  newCollateralData.businessDocumentId = newBusinessDocumentId
  newCollateralData.paymentInformation.paymentURL = ''
  
  return newCollateralData
}

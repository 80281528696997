import { get, type Writable, writable } from 'svelte/store'
import initializationStore from '../../core-app/stores/initialization.store'
import { eventsManager } from '$core/events/event-manager'
import { EventType } from '$core/events/event-type'
import type { BBBTransactionsResponse } from '../models/bbb-transactions-model'

export let TransactionsStore: Writable<BBBTransactionsResponse> = writable<BBBTransactionsResponse>()

export const initializeTransactionsStore = (data: BBBTransactionsResponse): void => {

  if (!get(initializationStore).transactionsStoreInitialized) {

    initializationStore.update(store => {
      store.transactionsStoreInitialized = true

      return store
    })

    TransactionsStore.set(data)

    // NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
    TransactionsStore.subscribe((newTransactionResponse: BBBTransactionsResponse) => {
      if (!!newTransactionResponse) {
        eventsManager.emit(EventType.TRANSACTIONS_LIST_CHANGED, newTransactionResponse.transactions, 'TransactionsStore')
      } else {
        eventsManager.emit(EventType.TRANSACTIONS_LIST_CHANGED, [], 'TransactionsStore')
      }
    })

    /* console.log('%c TransactionsStore initialized.', 'color: #8A99AC') */
  }
}

import { JsonProperty } from '@paddls/ts-serializer'
import { ProfileStore } from '../stores/profile.store'
import { get } from 'svelte/store'


/**
 * Temporary SignUp Store for onboarding users
 */
export class SignUpFormStore {
  @JsonProperty('email') email: string
  @JsonProperty('password') password: string
  @JsonProperty('firstName') firstName: string
  @JsonProperty('lastName') lastName: string
}

/**
 * Profile class for user profile
 */
export default class Profile {
  @JsonProperty('firstName') firstName: string
  @JsonProperty('lastName') lastName: string
  @JsonProperty('email') email: string
  @JsonProperty('officePhone') officePhone?: string
  @JsonProperty('mobilePhone') mobilePhone?: string
  @JsonProperty('roleInCompany') roleInCompany?: string
  @JsonProperty('roleInCompanyCustom') roleInCompanyCustom?: string
  @JsonProperty('companyActivity') companyActivity?: string
  @JsonProperty('companyMonthlyInvoices') companyMonthlyInvoices?: string
  @JsonProperty('companyInvoicingSoftware') companyInvoicingSoftware?: string
  @JsonProperty('companyInvoicingSoftwareCustom') companyInvoicingSoftwareCustom?: string
  @JsonProperty('companyNumberOfEmployees') companyNumberOfEmployees?: string
  @JsonProperty('lang') lang: string
  constructor() {
    this.firstName = ''
    this.lastName = ''
    this.email = ''
    this.officePhone = ''
    this.mobilePhone = ''
    this.roleInCompany = ''
    this.roleInCompanyCustom = ''
    this.companyActivity = ''
    this.companyMonthlyInvoices = ''
    this.companyInvoicingSoftware = ''
    this.companyInvoicingSoftwareCustom = ''
    this.companyNumberOfEmployees = ''
    this.lang = 'fr'
  }
  // converts the js prototype into the TypeScript class
  static PrototypeToClass(jsPrototype: Profile): Profile {
    return <Profile>Object.assign(new Profile(), jsPrototype)
  }
  // converts the ProfileStore Svelte store into the Profile TypeScript class
  static StoreToClass(): Profile {
    const profileStore: Profile = get(ProfileStore)

    return <Profile>Object.assign(new Profile(), profileStore)
  }
  isOnboardingProfileInfoDataComplete(): boolean {
    if (!this.firstName) {
      /* console.error('no profile firstName') */
    }
    if (!this.lastName) {
      /* console.error('no profile lastName') */
    }

    return !!this.mobilePhone && !!this.roleInCompany
  }
  isOnboardingProfileInfoPlusDataComplete(): boolean {
    if (!this.roleInCompany) {
      /* console.error('no roleInCompany') */
    }
    if (!this.mobilePhone) {
      /* console.error('no mobilePhone') */
    }

    return !!this.roleInCompany && !!this.mobilePhone && this.mobilePhone?.length >= 9
  }
  isOnboardingProfileInfoFinalDataComplete(): boolean {
    if (!this.companyMonthlyInvoices) {
      /* console.error('no companyMonthlyInvoices') */
    }
    if (!this.companyInvoicingSoftware) {
      /* console.error('no companyInvoicingSoftware') */
    }
    if (!this.companyNumberOfEmployees) {
      /* console.error('no companyNumberOfEmployees') */
    }

    return !!this.companyMonthlyInvoices && !!this.companyInvoicingSoftware && !!this.companyNumberOfEmployees
  }
}

// Function to get a default profile
export function getDefaultProfile(): Profile {
  return new Profile()
}


<script lang="ts">
    import { eventsManager } from '$core/events/event-manager'
    import { EventType } from '$core/events/event-type'
    import { t } from '$core/lib/i18n/i18nextWrapper'
    import {
      WorkspaceStore
    } from '$crm/stores/workspace.store'
    import { Workspace } from '$crm/models/workspace'
    import type BankAccount from '../models/bank-account'
    import Loader from '../../core-app/lib/ui-kit/Loader.svelte'
    import { bridgeByBankingService } from '../services/bridge-by-banking.service'
    import FullSizeModal from '../../core-app/lib/ui-kit/FullSizeModal.svelte'
    import { navigate } from 'svelte-routing'
    import { createEventDispatcher, onDestroy, onMount } from 'svelte'
    import { bankingTransactionsService } from '$bank/services/banking-transactions.service'
    import { WorkspaceBankConfig } from '../models/workspace-bank-config'
    import mixpanel from 'mixpanel-browser'
    import { deepClone } from "$core/util/object-deep-cloning";
    import { get } from "svelte/store";
    import type { DundyEvent } from "$dundy/events/dundy-event";
    import {
      UponMountingOnBankAccountSelectModalKind, UponMountingOnBankAccountSelectModalStatus
    } from "$pay/models/upon-mounting-bank-account-modal-status-model";

    /** Local declarations */
    let receivedBBBConnectedItemId: string
    // let completedOnce = $WorkspaceListStore && $WorkspaceListStore.length && $WorkspaceListStore.find(w => w.bankConfig.bankConnectionCompletedAtLeastOnce)
    const dispatch = createEventDispatcher()
    let loading: boolean = true
    let availableBankAccounts: BankAccount[]
    let submitButtonEl = null
    let bicInputEl = null
    let unsubscriberWorkspaceStoreInitialized: Function
    let unsubscriberWorkspaceBankAccountSaved: Function

    let initialSelectedBankAccountId: string
    let initialSelectedBankBIC: string
    let isDundyAppLoadingStabilized: boolean
    let temporarySelectedBankAccountId: string
    let temporaryInputBankBIC: string
    let connectedBankName: string = "----"

    /** Local functions */

    const getSituation = ():UponMountingOnBankAccountSelectModalStatus =>  {
      const urlParams: URLSearchParams = new URLSearchParams(window.location.search)
      const url = window.location.href
      const pureURLParams = url.substring(url.lastIndexOf('/')+1)
      let originSituation: UponMountingOnBankAccountSelectModalStatus = {
        kind: UponMountingOnBankAccountSelectModalKind.UNDEFINED,
        rawConditions: {
          urlParamsString: pureURLParams,
          // urlParamsMap: allUrlParamKeys,
        },
      } as UponMountingOnBankAccountSelectModalStatus
      switch (true){
        case urlParams.has('step') && (urlParams.get('step') === "sync_success")
          && urlParams.has('source') && (urlParams.get('source') === "connect")
          && urlParams.has('item_id') && (urlParams.get('item_id') !== "")
          && urlParams.has('success') && (urlParams.get('success') === "true"):
          originSituation = {
            kind: UponMountingOnBankAccountSelectModalKind.FROM_BRIDGE_CONNECTION_SUCCESS,
            comingFrom: "bbb",
            userConnectedToBankDuringBBBProcess: true,
            userCancelledBBBConsent: false,
            aBBBUserAccountWasCreated: true,
            rawConditions: {
              urlParamsString: pureURLParams,
              // urlParamsMap: allUrlParamKeys,
            },
          } as UponMountingOnBankAccountSelectModalStatus
          break
        case urlParams.has('step') && ["highlighted_banks", "list_banks_search", "bank_creds_form"].includes(urlParams.get('step') || '')
          && urlParams.has('source') && (urlParams.get('source') === "connect")
          && (!urlParams.has('item_id') || (urlParams.get('item_id') === ""))
          && urlParams.has('success') && (urlParams.get('success') === "false"):
          // step:
          // - highlighted_banks
          // - list_banks_search
          // - bank_creds_form
          // - sync_in_progress (we already have an item id, and we will fail to reconnect to the same bank)
          originSituation = {
            kind: UponMountingOnBankAccountSelectModalKind.FROM_BRIDGE_CONNECTION_PROCESS_CANCELLED_NO_CHANGE,
            comingFrom: "bbb",
            userConnectedToBankDuringBBBProcess: false,
            userCancelledBBBConsent: true,
            aBBBUserAccountWasCreated: false,
            rawConditions: {
              urlParamsString: pureURLParams,
              // urlParamsMap: allUrlParamKeys,
            },
          } as UponMountingOnBankAccountSelectModalStatus
          break
        case urlParams.has('step') && !["highlighted_banks", "list_banks_search", "bank_creds_form"].includes(urlParams.get('step') || '')
          && urlParams.has('source') && (urlParams.get('source') === "connect")
          && urlParams.has('item_id') && (urlParams.get('item_id') !== "")
          && urlParams.has('success') && (urlParams.get('success') === "false"):
          // step:
          // - !highlighted_banks
          // - !list_banks_search
          // - !bank_creds_form
          // - sync_in_progress (we already have an item id, and we will fail to reconnect to the same bank)
          originSituation = {
            kind: UponMountingOnBankAccountSelectModalKind.FROM_BRIDGE_CONNECTION_PROCESS_CANCELLED_ACCOUNT_CREATED,
            comingFrom: "bbb",
            userConnectedToBankDuringBBBProcess: true,
            userCancelledBBBConsent: true,
            aBBBUserAccountWasCreated: true,
            rawConditions: {
              urlParamsString: pureURLParams,
              // urlParamsMap: allUrlParamKeys,
            },
          } as UponMountingOnBankAccountSelectModalStatus
          break
        case !urlParams.has('step') && !urlParams.has('success'):
          originSituation = {
            kind: UponMountingOnBankAccountSelectModalKind.FROM_DUNDY_NOT_FROM_BRIDGE_PROBABLY_TO_DEFINE_OR_CHANGE_BANK_ACCOUNT,
            comingFrom: "dundy",
            userConnectedToBankDuringBBBProcess: false, // irrelevant
            userCancelledBBBConsent: false, // irrelevant
            aBBBUserAccountWasCreated: false, // irrelevant
            rawConditions: {
              urlParamsString: pureURLParams,
              // urlParamsMap: allUrlParamKeys,
            },
          } as UponMountingOnBankAccountSelectModalStatus
          break
      }
      /*console.log('%c originSituation = %s', 'color:purple;font-size: 1em;', JSON.stringify(originSituation,null,3))*/
      return originSituation
    }

    const replacingAccount = () => {
      return !!initialSelectedBankAccountId || (get(WorkspaceStore)?.bankConfig || {} as WorkspaceBankConfig).bankConnectionCurrentlyKnownActive
    }

    const setCurrentBankConnectionFirstTimeConnectionIfNeedBe = () => {
      bridgeByBankingService.setCurrentBankAsFirstTimeConnectionIfNeedBe()
    }

    const setCurrentBankConnectionAsActiveAndSetFirstTimeConnectionIfNeedBe = () => {
      bridgeByBankingService.setCurrentBankConnectionAsKnownActiveAndFirstTimeConnectionIfNeedBe()
    }

    const displayListOfBankAccountsToChooseFrom = () => {
      // STEP 1: just start loading animation + initiate promises chain
      new Promise<void>((resolve, reject) => {
        console.log("++ select bank after connection consent returned to app++ ", "STEP 1")
        loading = true
        resolve()
      })
        // STEP 2-3-4-5-6-7: build list
        .then(() => {
          return bridgeByBankingService.getListOfBankAccountsToChooseFrom()
        })
        // STEP 8: update availableBankAccounts and bank name
        .then((builtAccountsList: BankAccount[]) => {
          console.log("++ select bank after connection consent returned to app++ ", "STEP 8")
          availableBankAccounts = deepClone(builtAccountsList)
          connectedBankName = getBankName(availableBankAccounts)
        })
        // STEP 9: end happy path + end loading animation
        .then(() => {
          console.log("++ select bank after connection consent returned to app++ ", "STEP 9 end")
          loading = false
        })
        .catch((reason) => {
          console.error("++ select bank after connection consent returned to app++ ", "STEP x ERROR", reason)
          availableBankAccounts = []
          connectedBankName = "+x+x+x+x+"
          loading = false
        })
    }

    const getBankName = (currentAccounts: BankAccount[]): string => {
      let bankName: string
      if (receivedBBBConnectedItemId) {
        bankName = (currentAccounts.find(account => account.itemId === receivedBBBConnectedItemId) || {} as BankAccount).bankName
      } else if (currentAccounts?.length) {
        bankName = currentAccounts[0].bankName
      } else {
        console.error("could not find bank account name in getBankName")
        return 'error no bank name'
      }
      return bankName
    }

    const updateWorkspaceBankAccount = (selectedBankAccountId: string) => {
      bridgeByBankingService.setNewSelectedBankAccount(availableBankAccounts, selectedBankAccountId, getBankName(availableBankAccounts), temporaryInputBankBIC)

      eventsManager.once<Workspace>(EventType.WORKSPACE_BANK_ACCOUNT_SAVED, async () => {
        // after bank account info is updated, wait just a few seconds before loading transactions
        // (allow test environment success.402 or success.1010 to do accounts first with success then transactions with resp. 402 or 1010)
        setTimeout(async ()=>{
          mixpanel.track('DF10 New Workspace Bank Account Info Saved Successfully', {
            'Description': 'Successfully updated bank account data for workspace'
          })

          bankingTransactionsService.reloadTransactions()
        }, 2000)

      }, 'BankAccountSelectModal -> retrieve transaction after bank account changed')

      // if (!completedOnce && !BANK_CONNECTION_LAST_STEP && SHOW_STEPS_COMPLETED_PAGE) {
      //   navigate('/onboarding/app-intro')
      // } else {
        if (replacingAccount()) {
          dispatch('close')
        } else {
          navigate('/today')
        }
      // }
    }

    const onAccountSelection = e => {
      temporarySelectedBankAccountId = e
      if(e === initialSelectedBankAccountId){
        temporaryInputBankBIC = initialSelectedBankBIC
      } else {
        temporaryInputBankBIC = ""
      }
    }

    const getBBBConnectionItemIdFromURLParamsIfAvailable = (): string => {
      let urlParams = new URLSearchParams(window.location.search)
      return urlParams.get('item_id') || ''
    }

    const onClose = () => {
      dispatch('close')
    }

    onMount(() => {
      loading = true
      connectedBankName = "----"
      isDundyAppLoadingStabilized = false
      /*console.log('%c onMount()  ->  BankAccountSelectModal.svelte', 'color:green;font-size: 1.5em;')*/
      receivedBBBConnectedItemId = getBBBConnectionItemIdFromURLParamsIfAvailable()
      // completedOnce = $WorkspaceListStore && $WorkspaceListStore.length && $WorkspaceListStore.find(w => w.bankConfig.bankConnectionCompletedAtLeastOnce)
      // select bank account from list given by new bbb connection
      mixpanel.track('DD10 Bank Account Modal', {
        'Description': 'Open BankAccountSelectModal.svelte'
      })
      const situation: UponMountingOnBankAccountSelectModalStatus = getSituation()
      switch(situation.kind){
        case UponMountingOnBankAccountSelectModalKind.FROM_BRIDGE_CONNECTION_SUCCESS:
          // NB: at this point we have been coming back from the Bridge pages as the Bridge connection process to the Dundy App
          //     hence the app is currently reloading everything in the background
          //     we will need to change the bank config in the workspace
          //     so before we can start this bank config changes, we need to wait until the app settles down with the workspace reloaded
          //    for that reason, the next steps will only be triggered upon WORKSPACE_FETCHED
          if (!!unsubscriberWorkspaceStoreInitialized) {
            unsubscriberWorkspaceStoreInitialized()
          }
          unsubscriberWorkspaceStoreInitialized = eventsManager.once<void>(EventType.WORKSPACE_STORE_INITIALIZED, async (e: DundyEvent<void>): Promise<void> => {
            // subscribe first to even B
            if (!!unsubscriberWorkspaceBankAccountSaved) {
              unsubscriberWorkspaceBankAccountSaved()
            }
            unsubscriberWorkspaceBankAccountSaved = eventsManager.once<Workspace>(EventType.WORKSPACE_BANK_ACCOUNT_SAVED, async (e: DundyEvent<Workspace>): Promise<void> => {

              { // --- ACTION ---
                // user is not coming from cancelled bridge bank connection process
                // *** SUCCESS CASE / STEP 2/2:
                isDundyAppLoadingStabilized = true
                /*console.log('%c IN BankAccountSelectModal.svelte,  starting the back-from-bridge consequence after dundy app loaded', 'color:blue;font-size: 1.5em;')*/
                displayListOfBankAccountsToChooseFrom()
              }

            },"BankAccountSelectModal")

            { // --- ACTION ---
              // then run what will trigger the event B that will be caught by the above subscription
              // *** SUCCESS CASE / STEP 1/2:
              setCurrentBankConnectionAsActiveAndSetFirstTimeConnectionIfNeedBe()
            }

          },"BankAccountSelectModal")
          break
        case UponMountingOnBankAccountSelectModalKind.FROM_BRIDGE_CONNECTION_PROCESS_CANCELLED_NO_CHANGE:
          if (!!unsubscriberWorkspaceStoreInitialized) {
            unsubscriberWorkspaceStoreInitialized()
          }
          unsubscriberWorkspaceStoreInitialized = eventsManager.once<void>(EventType.WORKSPACE_STORE_INITIALIZED, async (e: DundyEvent<void>): Promise<void> => {

              { // --- ACTION ---
                // *** CANCEL NO CHANGE CASE / STEP 1/1:
                isDundyAppLoadingStabilized = true
                /*console.log('%c onMount()  ->  CANCEL BBB Flow -> FROM_BRIDGE_CONNECTION_PROCESS_CANCELLED_NO_CHANGE', 'color:orange;font-size: 1.5em;')*/
                dispatch('close') // NB: should navigate to BankView.svelte according to usual usage: <BankAccountSelectModal on:close={() => navigate('view')}/>
              }

          },"BankAccountSelectModal")
          break
        case UponMountingOnBankAccountSelectModalKind.FROM_BRIDGE_CONNECTION_PROCESS_CANCELLED_ACCOUNT_CREATED:
          if (!!unsubscriberWorkspaceStoreInitialized) {
            unsubscriberWorkspaceStoreInitialized()
          }
          unsubscriberWorkspaceStoreInitialized = eventsManager.once<void>(EventType.WORKSPACE_STORE_INITIALIZED, async (e: DundyEvent<void>): Promise<void> => {

              { // --- ACTION ---
                // *** CANCEL WITH BBB ACCOUNT CREATED CASE / STEP 1/1:
                isDundyAppLoadingStabilized = true
                /*console.log('%c onMount()  ->  CANCEL BBB Flow -> FROM_BRIDGE_CONNECTION_PROCESS_CANCELLED_ACCOUNT_CREATED', 'color:orange;font-size: 1.5em;')*/
                // need to delete bbb user which will also delete all related bank connections
                setCurrentBankConnectionFirstTimeConnectionIfNeedBe()
                bridgeByBankingService.removeBankConnection()
                dispatch('close') // NB: should navigate to BankView.svelte according to usual usage: <BankAccountSelectModal on:close={() => navigate('view')}/>
              }

          },"BankAccountSelectModal")
          break
        case UponMountingOnBankAccountSelectModalKind.FROM_DUNDY_NOT_FROM_BRIDGE_PROBABLY_TO_DEFINE_OR_CHANGE_BANK_ACCOUNT:
          // --- ACTION ---
          isDundyAppLoadingStabilized = true
          connectedBankName = (get(WorkspaceStore)?.bankConfig || {} as WorkspaceBankConfig).selectedAccountBankName || "++++"
          temporaryInputBankBIC = (get(WorkspaceStore)?.bankConfig || {} as WorkspaceBankConfig).selectedAccountBankBIC
          temporarySelectedBankAccountId = (get(WorkspaceStore)?.bankConfig || {} as WorkspaceBankConfig).selectedAccountId
          initialSelectedBankBIC = (get(WorkspaceStore)?.bankConfig || {} as WorkspaceBankConfig).selectedAccountBankBIC
          initialSelectedBankAccountId = (get(WorkspaceStore)?.bankConfig || {} as WorkspaceBankConfig).selectedAccountId
          displayListOfBankAccountsToChooseFrom()
          break
        case UponMountingOnBankAccountSelectModalKind.UNDEFINED:
          // --- ACTION ---
          isDundyAppLoadingStabilized = true
          connectedBankName = "error unimplemented BankAccountSelectModal.svelte onMount() case"
          /*console.log('%c onMount()  ->  CANCEL BBB Flow -> redirect to /settings/bank/view BankView.svelte', 'color:brown;font-size: 1.5em;')*/
          loading = false
          navigate('/settings/bank/view')
          break
      }
      /*console.log('%c onMount()  ->  finished', 'color:orange;font-size: 1.5em;')*/
    })

    onDestroy(() => {
      if(!!unsubscriberWorkspaceStoreInitialized){
        unsubscriberWorkspaceStoreInitialized()
      }
      if(!!unsubscriberWorkspaceBankAccountSaved){
        unsubscriberWorkspaceBankAccountSaved()
      }
    })

</script>

<FullSizeModal class="w-full overflow-y-auto h-full"
               isCloseable={true}
               on:close={() => onClose()}>
    <div slot="content">
        <div class="w-full md:w-5/12 mx-auto mb-8 mt-8">
            <div class="py-5 mb-10">
                <h2 class="text-black text-3xl font-bold">{t('selectAccount.title')}</h2>
            </div>
            <section class="step pb-16">
                <div class="flex mb-8 items-start">
                    <div class="w-12 h-12 bg-black rounded p-3 flex items-center align-middle justify-center font-medium text-base text-loblolly">
                        {connectedBankName.substring(0, 1).toUpperCase()}
                    </div>
                    <div class="flex flex-col ml-4 relative bottom-1 space-y-0.5">
                        <span class="text-lg text-black font-bold">{connectedBankName}</span>
                        <span class="text-xs">{t('selectAccount.bankCaption1', { bankName: connectedBankName })}</span>
                    </div>
                </div>
                <hr class="w-full bg-loblolly"/>
                <div class="w-full mx-auto my-4">
                    {#if !loading}
                        {#if availableBankAccounts && availableBankAccounts.length}
                            <div class="rounded w-full p-4 bg-barleyWhite border border-goldSand relative overflow-hidden">

                                <span class="text-sm font-bold ">{t('selectAccount.chooseText')}</span>
                            </div>
                            <form
                                    id="select-account-form"
                                    class="flex flex-col my-4 items-start overflow-x-auto h-48">
                                {#each availableBankAccounts as availableAccount}
                                    <div class="w-full text-left my-2">
                                        <input
                                                type="radio"
                                                id={availableAccount.id}
                                                name="company"
                                                bind:group={temporarySelectedBankAccountId}
                                                value={availableAccount.id}
                                                class="h-10 w-10"
                                                on:click|stopPropagation={() => onAccountSelection(availableAccount.id)}
                                        />
                                        <label id={availableAccount.id + '-label'}
                                               for={availableAccount.id}
                                               class="multiline"
                                               style="font-size: 1rem">
                                            {availableAccount.name} - {availableAccount.id}
                                            <span class="block font-normal mt-1 text-sm text-zinc-500">{availableAccount.accountNumber}</span>
                                        </label>
                                    </div>
                                {/each}
                            </form>
                        {:else}
                            <div class="flex justify-center mx-auto w-6/12 ml-4/12 align-center">
                                <p class="justify-self-center my-12 ml-4/12 text-lg text-paleSky">{t('selectAccount.noResults')}</p>
                            </div>
                        {/if}
                    {:else}
                        <div class="grid place-items-center mt-20 w-full z-[100]">
                            <Loader/>
                        </div>
                    {/if}
                </div>
                <div class="flex items-center space-x-4 mt-2 mb-8">
                    <label class="block font-medium text-gray-700 w-36"
                           for="bank-bic-number">{t('editBank.bankBICLabel')}</label>
                    <div class="mt-1 relative flex-grow">
                        <input bind:this={bicInputEl}
                               bind:value={temporaryInputBankBIC}
                               class="block w-full rounded-md focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-black"
                               data-cy="select-account-bic"
                               id="bank-bic-number"
                               name="bank-bic-number"
                               on:blur={() => {
                                 submitButtonEl.blur()
                                 bicInputEl.focus()
                               }}
                               placeholder="XXXXXXX"
                               type="text"
                        />
                    </div>
                </div>
            </section>
        </div>
        <div class="w-full md:w-8/12 lg:w-5/12 sticky px-4 mt-8 py-6 bg-gradient-to-t from-white flex bottom-0 left-1/2 transform -translate-x-1/2 justify-end items-start">
            <span class="cursor-pointer btn action-cancel mr-5" on:click={() => onClose()}
                  role="presentation">{t('invoices.editInvoice.cancel')}
            </span>
            <button bind:this={submitButtonEl} class="cursor-pointer btn action-bla primary justify-self-end"
                    data-cy="select-account-submit"
                    disabled={!isDundyAppLoadingStabilized || !temporarySelectedBankAccountId || !temporaryInputBankBIC || temporaryInputBankBIC?.length < 8}
                    on:click|stopPropagation={() => {
                      updateWorkspaceBankAccount(temporarySelectedBankAccountId)
                    }}>{t('selectAccount.submit')}</button>
        </div>
    </div>
</FullSizeModal>

<style lang="postcss">
    [type="radio"] + label {
        @apply pl-12;
    }

    .step {
        @apply flex flex-col justify-between py-6 px-6 border border-athensGray mb-4 rounded-lg;
    }
</style>

import { get } from 'svelte/store'
import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
import {
  reduceInvoicesSumOfAmount,
  reduceInvoicesSumOfAmountWithOptionalField
} from '../../../../dundy-app/services/list-reduce'
import { BusinessDocumentViewListOption } from '../../../enums/business-documents-view-list-options'
import { getDefaultCurrencyAccordingToCountry } from '../../../../core-app/services/currency-list'
import { getUserCountryCode } from '../../../../core-app/services/countries-pure-functions'
import { BusinessDocumentsStore } from '../../../stores/business-documents.store'
import type { BusinessDocument, CoreDocument } from '../../../models/business-document'
import { BusinessDocumentKind } from '../../../enums/business-document-kind'
import { BusinessDocumentStatus } from '../../../enums/business-document-status'
import type { InvoicesHistoryItem } from '../../../../dundy-app/models/invoices-history'
import { InvoicesHistoryStore } from '../../../../dundy-app/stores/invoices-history.store'
import type UIBusinessDocumentsUpdatedCounts from '../../../models/business-documents-ui-updated-counts'
import { isBusinessDocumentContainingText } from '../../../helpers/voxy-helper-pure-functions'
import { getStatus } from '../../business-document-voiding/is-business-document-voided.service'
import { BusinessDocumentState } from '$voxy/enums/business-document-state'

class BusinessDocumentsFilterServiceClass {
  /**
   * Filters invoices from store based on the chosen option
   * Sorts the list of business documents based on the modifiedDate from the most recent to the oldest
   * @param sourceList
   * @param listViewChosenOption
   * @param searchValue
   */
  filterInvoicesFromStore(
    sourceList: BusinessDocument[],
    listViewChosenOption: BusinessDocumentViewListOption,
    searchValue: string
  ): BusinessDocument[] {
    if (!sourceList) return []
    if (sourceList?.length === 0) return sourceList

    let businessDocuments: BusinessDocument[]

    switch (listViewChosenOption) {
      /** Show All invoices + credit notes regardless of status / finalized / completed */
      case BusinessDocumentViewListOption.ALL:
        businessDocuments = sourceList.filter(
          (businessDocument?: BusinessDocument) =>
            !businessDocument.deleted &&
            (!searchValue || isBusinessDocumentContainingText(businessDocument, searchValue, t('locales')))
        )
        break

      /** Show Invoices only - finalized / completed */
      case BusinessDocumentViewListOption.INVOICES:
        businessDocuments = sourceList.filter(
          (businessDocument?: BusinessDocument) => 
            businessDocument.businessDocumentStatus === BusinessDocumentStatus.FINAL &&
            businessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE &&
            !businessDocument.deleted &&
            [BusinessDocumentState.INVOICE, BusinessDocumentState.VOIDING_AND_LAST].includes(getStatus(businessDocument)?.state) &&
            (!searchValue || isBusinessDocumentContainingText(businessDocument, searchValue, t('locales')))
        )
        break

      /** Show Invoices + credit notes that need to be sent to the client - issuance
       * Excluding Invoices that have more than one historyEvent and only allowing InvoiceIssued kind (invoice was created) */
      case BusinessDocumentViewListOption.TO_SEND:
        businessDocuments = sourceList.filter((businessDocument?: BusinessDocument): boolean => {
          if (
            businessDocument.businessDocumentStatus === BusinessDocumentStatus.FINAL &&
            businessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE &&
            !businessDocument.deleted &&
            (!searchValue || isBusinessDocumentContainingText(businessDocument, searchValue, t('locales')))
          ) {
            /** TODO This is missing the PAID Status from ComputedDATA or will be complete if BE actually returns PAID event as invoiceEvent */
            const history: InvoicesHistoryItem[] = get(InvoicesHistoryStore)?.filter(
              (historyItem: InvoicesHistoryItem): boolean =>
                historyItem.invoiceNumber === businessDocument.businessDocumentNumber &&
                historyItem.invoiceEvents.length === 1 &&
                historyItem.invoiceEvents[0].eventKind === 'InvoiceIssued'
            )

            return history?.length === 1
          }
        })
        break

      /** Show only credit notes & voided invoices that are finalized */
      case BusinessDocumentViewListOption.CREDIT_NOTES_AND_VOIDED_INVOICES:
        businessDocuments = sourceList.filter(
          (businessDocument?: BusinessDocument) =>
            businessDocument.businessDocumentKind === BusinessDocumentKind.CREDITNOTE &&
            businessDocument.businessDocumentStatus === BusinessDocumentStatus.FINAL &&
            !businessDocument.deleted &&
            [BusinessDocumentState.CREDIT_NOTE].includes(getStatus(businessDocument)?.state) &&
            (!searchValue || isBusinessDocumentContainingText(businessDocument, searchValue, t('locales')))
        )
        break

      /** Show draft invoices & credit notes */
      case BusinessDocumentViewListOption.DRAFT:
        businessDocuments = sourceList.filter(
          (businessDocument?: BusinessDocument) =>
            businessDocument.businessDocumentStatus === BusinessDocumentStatus.DRAFT &&
            !businessDocument.deleted &&
            [BusinessDocumentState.CREDIT_NOTE_DRAFT, BusinessDocumentState.INVOICE_DRAFT, BusinessDocumentState.PURCHASE_ORDER_DRAFT, BusinessDocumentState.QUOTE_DRAFT, BusinessDocumentState.PENDING_VOIDING].includes(getStatus(businessDocument)?.state) &&
            (!searchValue || isBusinessDocumentContainingText(businessDocument, searchValue, t('locales')))
        )
        break

      /** Show voided invoices & credit notes */
      case BusinessDocumentViewListOption.VOIDED:
        businessDocuments = sourceList.filter(
          (businessDocument?: BusinessDocument) =>
            businessDocument.businessDocumentStatus === BusinessDocumentStatus.FINAL &&
            !businessDocument.deleted &&
            [BusinessDocumentState.PENDING_VOIDED, BusinessDocumentState.VOIDED, BusinessDocumentState.VOIDING, BusinessDocumentState.VOIDING_AND_VOIDED].includes(getStatus(businessDocument)?.state) && 
            (!searchValue || isBusinessDocumentContainingText(businessDocument, searchValue, t('locales')))
        )
        break

      default:
        businessDocuments = []
    }

    // Sort the list of business documents based on the modifiedDate
    businessDocuments.sort(
      (a, b) => new Date(b.modifiedDate.rfc3339).getTime() - new Date(a.modifiedDate.rfc3339).getTime()
    )

    return businessDocuments
  }
  /**
   * Collateral calculus
   * This function is called when the list of invoices is updated, and when the search value is updated.
   * @param sourceList
   * @param searchValue
   */
  updateCollateralCalculus(sourceList: BusinessDocument[], searchValue: string): UIBusinessDocumentsUpdatedCounts {
    const invoicesCurrency: string =
      get(BusinessDocumentsStore).find((coreDocument: CoreDocument) => coreDocument.businessDocument.currency)
        ?.businessDocument.currency || getDefaultCurrencyAccordingToCountry(getUserCountryCode())
    let invoiceFormatter: Intl.NumberFormat = new Intl.NumberFormat(t('locales'), {
      style: 'currency',
      currency: invoicesCurrency
    })

    const allInvoices: BusinessDocument[] = invoicesCollateralCalculusAndFilterUi.filterInvoicesFromStore(
      sourceList,
      BusinessDocumentViewListOption.ALL,
      searchValue
    )
    const finalizedInvoices: BusinessDocument[] = invoicesCollateralCalculusAndFilterUi.filterInvoicesFromStore(
      sourceList,
      BusinessDocumentViewListOption.INVOICES,
      searchValue
    )
    const toSendInvoices: BusinessDocument[] = invoicesCollateralCalculusAndFilterUi.filterInvoicesFromStore(
      sourceList,
      BusinessDocumentViewListOption.TO_SEND,
      searchValue
    )
    const creditNoteDocuments: BusinessDocument[] = invoicesCollateralCalculusAndFilterUi.filterInvoicesFromStore(
      sourceList,
      BusinessDocumentViewListOption.CREDIT_NOTES_AND_VOIDED_INVOICES,
      searchValue
    )
    const draftInvoices: BusinessDocument[] = invoicesCollateralCalculusAndFilterUi.filterInvoicesFromStore(
      sourceList,
      BusinessDocumentViewListOption.DRAFT,
      searchValue
    )
    const voidedDocuments: BusinessDocument[] = invoicesCollateralCalculusAndFilterUi.filterInvoicesFromStore(
      sourceList,
      BusinessDocumentViewListOption.VOIDED,
      searchValue
    )

    /** Returns the sum of all the amounts of the invoices in the list except for credit notes */
    let allBusinessDocuments: BusinessDocument[] = []
    if (allInvoices?.length > 0) {
      allInvoices.filter(
        (businessDocument: BusinessDocument): boolean =>
          businessDocument.businessDocumentStatus === BusinessDocumentStatus.FINAL &&
          businessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE &&
          !businessDocument.deleted
      )
    }

    const allInvoicesAmountNormalized: string = invoiceFormatter.format(
      reduceInvoicesSumOfAmountWithOptionalField(
        allBusinessDocuments,
        'installmentResultIncludingTaxScaledValue',
        'totalIncludingTaxScaledValue'
      ) || 0
    )

    const finalizedInvoicesAmountNormalized: string = invoiceFormatter.format(
      reduceInvoicesSumOfAmountWithOptionalField(
        finalizedInvoices,
        'installmentResultIncludingTaxScaledValue',
        'totalIncludingTaxScaledValue'
      ) || 0
    )

    const toSendInvoicesAmountNormalized: string = invoiceFormatter.format(
      reduceInvoicesSumOfAmount(
        toSendInvoices.filter(
          (businessDocument: BusinessDocument): boolean =>
            businessDocument.businessDocumentStatus === BusinessDocumentStatus.FINAL &&
            businessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE
        ),
        'installmentResultIncludingTaxScaledValue'
      ) || 0
    )

    const creditNoteDocumentsAmountNormalized: string = invoiceFormatter.format(
      reduceInvoicesSumOfAmountWithOptionalField(
        creditNoteDocuments,
        'installmentResultIncludingTaxScaledValue',
        'totalIncludingTaxScaledValue'
      ) || 0
    )

    const draftInvoicesAmountNormalized: string = invoiceFormatter.format(
      reduceInvoicesSumOfAmountWithOptionalField(
        draftInvoices,
        'installmentResultIncludingTaxScaledValue',
        'totalIncludingTaxScaledValue'
      ) || 0
    )

    const voidedDocumentAmountNormalized: string = invoiceFormatter.format(
      reduceInvoicesSumOfAmountWithOptionalField(
        voidedDocuments,
        'installmentResultIncludingTaxScaledValue',
        'totalIncludingTaxScaledValue'
      ) || 0
    )

    const numberOfAllInvoices: number = allInvoices.length
    const numberOfFinalizedInvoices: number = finalizedInvoices.length
    const numberOfToSendInvoices: number = toSendInvoices.length
    const numberOfCreditNoteDocuments: number = creditNoteDocuments.length
    const numberOfDraftInvoices: number = draftInvoices.length
    const numberOfVoidedDocuments: number = voidedDocuments.length

    return <UIBusinessDocumentsUpdatedCounts>{
      allInvoicesAmountNormalized,
      finalizedInvoicesAmountNormalized,
      toSendInvoicesAmountNormalized,
      draftInvoicesAmountNormalized,
      creditNoteDocumentsAmountNormalized,
      voidedDocumentAmountNormalized,
      numberOfAllInvoices,
      numberOfFinalizedInvoices,
      numberOfToSendInvoices,
      numberOfCreditNoteDocuments,
      numberOfDraftInvoices,
      numberOfVoidedDocuments
    }
  }
}

export const invoicesCollateralCalculusAndFilterUi: BusinessDocumentsFilterServiceClass =
  new BusinessDocumentsFilterServiceClass()

<script lang="ts">
    import { BusinessDocument } from '../../models/business-document'
    import type { Customer } from '../../../crm-app/models/customer'
    import { CustomersStore } from '../../../crm-app/stores/customers.store'

    export let businessDocument: BusinessDocument

    /** Let Declarations */
    let customerInitial: string
    let customer: Customer

    /** Reactive declarations */
    $: customer = findCustomer(businessDocument, $CustomersStore)
    $: customerInitial = customer?.company?.formalName?.substring(0, 1)

    function findCustomer(businessDocument: BusinessDocument, customers: Customer[]) {
      if (businessDocument?.customerCustomer?.company?.companyId) {
        return customers.find(c => c.company.companyId === businessDocument?.customerCustomer?.company.companyId)
      }

      if (businessDocument?.customerCustomer?.company?.formalName) {
        return customers.find(c => c.company.formalName === businessDocument?.customerCustomer?.company.formalName)
      }

      return <Customer>{}
    }

</script>
<div data-ut-component="view-business-company-logo-name">
    <div class="space-y-6 py-7">
        <div class="px-12 sm:px-8">
            <div class="flex flex-row w-full justify-start items-center">
                <div class="h-10 w-10 p-3 pt-1.5 bg-ebonyClay rounded uppercase text-center">
                    <span class="text-white text-lg">{customerInitial}</span>
                </div>
                <div class="font-semibold ml-4 relative">{customer?.company?.formalName}</div>
            </div>
        </div>
    </div>
</div>
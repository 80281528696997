import { get, type Unsubscriber, type Writable, writable } from 'svelte/store'
import type { NumberingScheme, NumberingSchemeSettings, VoxyPreferences } from '../models/voxy-preferences'
import initializationStore from '../../core-app/stores/initialization.store'
import { eventsManager } from '../../core-app/events/event-manager'
import { EventType } from '../../core-app/events/event-type'
import { BusinessDocumentKind, GetAllBusinessDocumentKinds } from '../enums/business-document-kind'
import { VoxyHelper } from '../helpers/voxy-helper'
import { ExchangeDate } from '../../core-app/models/exchange-date'
import { authZJsUserId } from '../../core-app/lib/auth0authentication/authStore'
import { v4 as uuidv4 } from 'uuid'
import { deepClone } from '../../core-app/util/object-deep-cloning'


let oldVoxyPreferences: VoxyPreferences = null

export let VoxyPreferencesStore: Writable<VoxyPreferences> = writable<VoxyPreferences>(<VoxyPreferences>{})
export let unUnsubscribeVoxyPreferencesStore: Unsubscriber


function getNumberingBasicInfoSafely(numberingSchemesByBusinessDocument: Map<BusinessDocumentKind, NumberingScheme>, documentKind: BusinessDocumentKind): string {
  if (!!numberingSchemesByBusinessDocument) {
    if (!!numberingSchemesByBusinessDocument[documentKind]) {
      if (!!numberingSchemesByBusinessDocument[documentKind].currentNumberingScheme) {
        if (!!numberingSchemesByBusinessDocument[documentKind].currentNumberingScheme.documentNumberTemplate) {
          return '(numbering=[template:\'' + numberingSchemesByBusinessDocument[documentKind].currentNumberingScheme.documentNumberTemplate + '\'])'
        } else {
          return '(no documentNumberTemplate)'
        }
      } else {
        return '(no currentNumberingScheme)'
      }
    } else {
      return '(no documentKind ' + documentKind + ')'
    }
  } else {
    return '(no numberingSchemesByBusinessDocument)'
  }
}

function testASpecificProperty(propertyName: string,
  aType: BusinessDocumentKind,
  oldCurrentNumberingSchemesForBusinessDocument: NumberingSchemeSettings,
  newCurrentNumberingSchemesForBusinessDocument: NumberingSchemeSettings,
  needToUpdate: boolean): boolean {
  const oldProperty = oldCurrentNumberingSchemesForBusinessDocument[propertyName]
  const newProperty = newCurrentNumberingSchemesForBusinessDocument[propertyName]
  if (oldProperty !== newProperty) {
    /* console.log('---p!!!!!!--- ', 'change in \'' + propertyName + '\' for \'' + aType.toString() + '\': oldProperty=', oldProperty, 'newProperty=', newProperty) */

    return true
  }

  return needToUpdate
}

export const initializeVoxyPreferencesStore = (data) => {

  if (get(initializationStore).voxyPreferencesStoreInitialized) return

  initializationStore.update(store => {
    store.voxyPreferencesStoreInitialized = true

    return store
  })
  // un-subscribe first if needed
  // NB: needs to be done BEFORE the localStorage.setItem() AND before VoxyPreferencesStore.set():
  // indeed, for the initialization, we need to set the store without triggering a previous potential subscribe
  /* console.log('unUnsubscribeVoxyPreferencesStore in initializeVoxyPreferencesStore()', unUnsubscribeVoxyPreferencesStore) */
  if (!!unUnsubscribeVoxyPreferencesStore) {
    /* console.log('---p!!!!!!--- vxp  unsubscribing !!!!! unUnsubscribeVoxyPreferencesStore()') */
    unUnsubscribeVoxyPreferencesStore()
  }

  /* console.log('initializeVoxyPreferencesStore - vxp ppp!!!! running VoxyPreferencesStore.set() in voxy-preferences.store.ts') */
  oldVoxyPreferences = deepClone(get(VoxyPreferencesStore))
  VoxyPreferencesStore.set(data)

  let isVoxyPreferencesStoreSubscriptionDefined: boolean = true // false = no .subscribe() execution at subscription / true = .subscribe() is executing and subscribing altogether
  // NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
  /* console.log('---p!!!!!!--- vxp  unUnsubscribeVoxyPreferencesStore = VoxyPreferencesStore.subscribe(()') */
  /* console.log('initializeVoxyPreferencesStore - ppp!!!! vxp  running VoxyPreferencesStore.subscribe() in voxy-preferences.store.ts') */
  if (!!unUnsubscribeVoxyPreferencesStore) {
    unUnsubscribeVoxyPreferencesStore()
    unUnsubscribeVoxyPreferencesStore = null
  }
  unUnsubscribeVoxyPreferencesStore = VoxyPreferencesStore.subscribe((newVoxyPreferences: VoxyPreferences) => {
    /* console.log('isVoxyPreferencesStoreSubscriptionDefined in VoxyPreferencesStore.subscribe()', isVoxyPreferencesStoreSubscriptionDefined) */
    /* console.log('err in VoxyPreferencesStore.subscribe() for debug only') */
    /* console.log('newVoxyPreferences received in VoxyPreferencesStore.subscribe()', newVoxyPreferences) */
    if (!isVoxyPreferencesStoreSubscriptionDefined) {
      /* console.log('VoxyPreferencesStore  vxp subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently in VoxyPreferencesStore.subscribe()') */
      isVoxyPreferencesStoreSubscriptionDefined = true

      return // we avoid the .subscribe() execution at the subscription occurrence
    }

    if (!!newVoxyPreferences) {
      /* console.log('VoxyPreferencesStore.subscribe executed  vxp !!!!! in VoxyPreferencesStore.subscribe()') */
      /* console.log('VoxyPreferencesStore JSON.parse(localStorage.getItem(\'VoxyPreferencesStore\'))  vxp !!!!! in VoxyPreferencesStore.subscribe()', JSON.parse(localStorage.getItem('VoxyPreferencesStore'))) */
      // if (!(JSON.parse(localStorage.getItem('VoxyPreferencesStore')))) {
      //   /* console.error('err in VoxyPreferencesStore.subscribe(): localStorage.getItem(\'VoxyPreferencesStore\') is Null') */
      // }
      // const noPriorVoxyPreferencesStore: boolean = !(JSON.parse(localStorage.getItem('VoxyPreferencesStore')))
      // const oldVoxyPreferencesNumberingSchemesByBusinessDocument: Map<BusinessDocumentKind, NumberingScheme> = (noPriorVoxyPreferencesStore ? null : <Map<BusinessDocumentKind, NumberingScheme>>JSON.parse(localStorage.getItem('VoxyPreferencesStore')).numberingSchemesByBusinessDocument)
      const oldVoxyPreferencesNumberingSchemesByBusinessDocument: Map<BusinessDocumentKind, NumberingScheme> = oldVoxyPreferences.numberingSchemesByBusinessDocument
      const newVoxyPreferencesNumberingSchemesByBusinessDocument: Map<BusinessDocumentKind, NumberingScheme> = newVoxyPreferences.numberingSchemesByBusinessDocument

      // const oldVoxyPreferencesNumberingSchemesByBusinessDocumentHash = encrypt256Hash(oldVoxyPreferencesNumberingSchemesByBusinessDocument);
      // const newVoxyPreferencesNumberingSchemesByBusinessDocumentHash = encrypt256Hash(newVoxyPreferences.numberingSchemesByBusinessDocument);
      /* console.log('---p!!!!!!--- vxp  oldVoxyPreferencesNumberingSchemesByBusinessDocument !!!!! in VoxyPreferencesStore.subscribe()', oldVoxyPreferencesNumberingSchemesByBusinessDocument) */
      /* console.log('---p!!!!!!--- vxp  newVoxyPreferencesNumberingSchemesByBusinessDocument !!!!! in VoxyPreferencesStore.subscribe()', newVoxyPreferencesNumberingSchemesByBusinessDocument) */


      // if (oldVoxyPreferencesNumberingSchemesByBusinessDocumentHash !== newVoxyPreferencesNumberingSchemesByBusinessDocumentHash) {
      for (let aType of GetAllBusinessDocumentKinds()) {
        const noPriorVoxyPreferencesStore: boolean = !oldVoxyPreferencesNumberingSchemesByBusinessDocument[aType].isFirstSchemeSetupCompleted

        let needToUpdate: boolean = false
        // if (!noPriorVoxyPreferencesStore && !oldVoxyPreferencesNumberingSchemesByBusinessDocument[aType]) {
        //   /* console.log('---p!!!!!!--- vxp  in VoxyPreferencesStore.subscribe() missing oldVoxyPreferencesNumberingSchemesByBusinessDocument for BusinessDocumentKind=\'' + aType.toString() + '\'') */
        // }

        if (!newVoxyPreferencesNumberingSchemesByBusinessDocument[aType]) {
          /* console.log('---p!!!!!!--- vxp  in VoxyPreferencesStore.subscribe() missing newVoxyPreferencesNumberingSchemesByBusinessDocument for BusinessDocumentKind=\'' + aType.toString() + '\' ... adding a default') */
          /* console.log('---p!!!!!!--- vxp  in VoxyPreferencesStore.subscribe() ', 'change in \'' + 'NumberingScheme' + '\' for \'' + aType.toString() + '\': oldProperty=', 'none', 'newProperty=', 'NumberingScheme{currentNumberingScheme:newVoxyDefaultNumberingScheme,etc.}') */
          newVoxyPreferencesNumberingSchemesByBusinessDocument[aType] = <NumberingScheme>{
            currentNumberingScheme: VoxyHelper.newVoxyDefaultNumberingScheme(aType),
            firstSchemeSetupDate: ExchangeDate.newDate(new Date()),
            isFirstSchemeSetupCompleted: true,
            lastDocumentNumberNumberingSchemeID: '',
            lastDocumentSequenceNumbering: null,
            noAllocationSinceNumberingSchemeChanged: true,
            numberingSchemeId: uuidv4(),
            schemeSetupByRequesterId: get(authZJsUserId),
            schemeSetupDateAndPeriod: null
          }
          needToUpdate = true
        }
        // if (!noPriorVoxyPreferencesStore && !!oldVoxyPreferencesNumberingSchemesByBusinessDocument[aType] && !oldVoxyPreferencesNumberingSchemesByBusinessDocument[aType].currentNumberingScheme) {
        //   /* console.log('---p!!!!!!--- vxp  in VoxyPreferencesStore.subscribe() missing currentNumberingScheme in oldVoxyPreferencesNumberingSchemesByBusinessDocument for BusinessDocumentKind=\'' + aType.toString() + '\'') */
        // }
        if (!!newVoxyPreferencesNumberingSchemesByBusinessDocument[aType] && !newVoxyPreferencesNumberingSchemesByBusinessDocument[aType].currentNumberingScheme) {
          /* console.log('---p!!!!!!--- vxp  in VoxyPreferencesStore.subscribe() missing currentNumberingScheme in newVoxyPreferencesNumberingSchemesByBusinessDocument for BusinessDocumentKind=\'' + aType.toString() + '\' ... adding a default') */
          /* console.log('---p!!!!!!--- vxp  in VoxyPreferencesStore.subscribe() ', 'change in \'' + 'currentNumberingScheme' + '\' for \'' + aType.toString() + '\': oldProperty=', 'none', 'newProperty=', 'newVoxyDefaultNumberingScheme') */
          newVoxyPreferencesNumberingSchemesByBusinessDocument[aType].currentNumberingScheme = VoxyHelper.newVoxyDefaultNumberingScheme(aType)
          needToUpdate = true
        }
        // const oldNumberingSchemesByBusinessDocumentHash = encrypt256Hash(oldVoxyPreferencesNumberingSchemesByBusinessDocument[aType]["currentNumberingScheme"]);
        // const newNumberingSchemesByBusinessDocumentHash = encrypt256Hash(newVoxyPreferencesNumberingSchemesByBusinessDocument[aType].currentNumberingScheme);
        // console.log(
        //     "p!!!!! vxp  oldVoxyPreferencesNumberingSchemesByBusinessDocument[aType][\"currentNumberingScheme\"][\"documentNumberTemplate\"]",
        //     getNumberingBasicInfoSafely(oldVoxyPreferencesNumberingSchemesByBusinessDocument, aType));
        // console.log(
        //     "p!!!!! vxp  newVoxyPreferences[aType][\"currentNumberingScheme\"][\"documentNumberTemplate\"]",
        //     getNumberingBasicInfoSafely(newVoxyPreferencesNumberingSchemesByBusinessDocument, aType));
        if (!!newVoxyPreferencesNumberingSchemesByBusinessDocument[aType] && !!newVoxyPreferencesNumberingSchemesByBusinessDocument[aType].currentNumberingScheme) {
          if (!noPriorVoxyPreferencesStore) {
            const oldCurrentNumberingSchemesForBusinessDocument: NumberingSchemeSettings = oldVoxyPreferencesNumberingSchemesByBusinessDocument[aType].currentNumberingScheme
            const newCurrentNumberingSchemesForBusinessDocument: NumberingSchemeSettings = newVoxyPreferencesNumberingSchemesByBusinessDocument[aType].currentNumberingScheme

            needToUpdate = testASpecificProperty('automaticNumberingMode',
              aType,
              oldCurrentNumberingSchemesForBusinessDocument,
              newCurrentNumberingSchemesForBusinessDocument,
              needToUpdate,
            )
            needToUpdate = testASpecificProperty('documentNumberTemplate',
              aType,
              oldCurrentNumberingSchemesForBusinessDocument,
              newCurrentNumberingSchemesForBusinessDocument,
              needToUpdate,
            )
            needToUpdate = testASpecificProperty('sequenceNumberOfDigits',
              aType,
              oldCurrentNumberingSchemesForBusinessDocument,
              newCurrentNumberingSchemesForBusinessDocument,
              needToUpdate,
            )
            needToUpdate = testASpecificProperty('inTimeZoneIANACode',
              aType,
              oldCurrentNumberingSchemesForBusinessDocument,
              newCurrentNumberingSchemesForBusinessDocument,
              needToUpdate,
            )
            needToUpdate = testASpecificProperty('continueExistingDocumentNumberSequence',
              aType,
              oldCurrentNumberingSchemesForBusinessDocument,
              newCurrentNumberingSchemesForBusinessDocument,
              needToUpdate,
            )
            needToUpdate = testASpecificProperty('initialDocumentSequenceNumber',
              aType,
              oldCurrentNumberingSchemesForBusinessDocument,
              newCurrentNumberingSchemesForBusinessDocument,
              needToUpdate,
            )
          } else {
            needToUpdate = true
          }

          if (needToUpdate) {
            needToUpdate = false
            /* console.log('---p!!!!!!--- vxp  in VoxyPreferencesStore.subscribe() VOXY_PREFERENCES_SCHEME_SETTING_CHANGED about to be triggered sp!!!!!') */
            eventsManager.emit(EventType.VOXY_PREFERENCES_SCHEME_SETTING_CHANGED, newVoxyPreferencesNumberingSchemesByBusinessDocument[aType].currentNumberingScheme, 'VoxyPreferencesStore')
          }
        } else {
          if (needToUpdate) {
            needToUpdate = false
            /* console.log('---p!!!!!!--- vxp  in VoxyPreferencesStore.subscribe() VOXY_PREFERENCES_SCHEME_SETTING_CHANGED about to be triggered sp!!!!!') */
            eventsManager.emit(EventType.VOXY_PREFERENCES_SCHEME_SETTING_CHANGED, newVoxyPreferencesNumberingSchemesByBusinessDocument[aType].currentNumberingScheme, 'VoxyPreferencesStore')
          }
        }

        // if (oldNumberingSchemesByBusinessDocumentHash !== newNumberingSchemesByBusinessDocumentHash) {
        // eventsManager.emit(EventType.VOXY_PREFERENCES_SCHEME_SETTING_CHANGED, newVoxyPreferencesNumberingSchemesByBusinessDocument[aType].currentNumberingScheme, 'VoxyPreferencesStore');
        // }
      }
      // } else {

      oldVoxyPreferences = deepClone(get(VoxyPreferencesStore))

      // }
    } else {
      /* console.log('---p!!!!!!--- vxp  in VoxyPreferencesStore.subscribe() VoxyPreferencesStore.subscribe ignored because newVoxyPreferences undefined or null !!!!!!!') */
    }
    eventsManager.emit(EventType.VOXY_PREFERENCES_STORE_UPDATE_CYCLE_COMPLETED, newVoxyPreferences, 'VoxyPreferencesStore')
  })

  /* console.log('%c VoxyPreferencesStore initialized.  vxp ', 'color: #00000') */

}

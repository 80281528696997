
/**
 * CHECK IF VARIABLE IS OBJECT TYPE
 * @param {any} value 
 * @returns {boolean}
 */
export const isObject = (value:any):boolean => typeof value === 'object' && value !== null && !Array.isArray(value)

/**
 * 
 * @param obj 
 * @returns {Object}
 */
export const objectDeepClone = (obj: any): Object => {
  if (obj === null) return obj
  let clone = Object.assign({}, obj)
  Object.keys(clone).forEach(
    key =>
      (clone[key] =
        typeof obj[key] === 'object' ? objectDeepClone(obj[key]) : obj[key])
  )
  if (Array.isArray(obj)) {
    clone.length = obj.length
    
    return Array.from(clone)
  }
  
  return clone
}

/**
 * REMOVE PROPERTIES FROM OBJECT
 * @param {Object} obj 
 * @param {string[]} props 
 * @param {boolean} clone 
 * @returns {Object}
 */
export const objectRemoveProperties = (obj:any, props:string[], clone:boolean = true): Object => {
  const o = clone ? objectDeepClone(obj) : obj

  props.forEach((p: any) => delete o[p])

  return o
}

/**
 * FIND OBJECT INSIDE ANOTHER BY PATH
 * @param {Object} obj 
 * @param {string} path 
 * @returns {Object|undefined}
 */
export const objectFindByPath = (obj:any, path:any): object => {
  if ( !obj) return path
  
  let paths = path.split('.')
  let current = obj
  let i:number

  for (i = 0; i < paths.length; ++i) {
    if (current[paths[i]] == undefined) {
      return {}
    } else {
      current = current[paths[i]]
    }
  }
    
  return current
}
<script lang="ts">
    import { onDestroy } from 'svelte'
    import { Alert as AlertStore } from '../../../stores/alert.store'

    onDestroy(() => {
      AlertStore.close()
    })
</script>

{#if $AlertStore && $AlertStore.component}
    <div class="bg">
        <div class="window-wrap">
            <div class="window" role="dialog" aria-modal="true">
                <svelte:component this={$AlertStore.component} {...$AlertStore.props}/>
            </div>
        </div>
    </div>
{/if}


<style>
    * {
        box-sizing: border-box;
    }

    .bg {
        position: fixed;
        z-index: 1000;
        top: 130px;
        left: 50%;
        margin-left: -422.5px;
    }

    .window-wrap {
        position: relative;
        max-height: 100%;
    }

    .window {
        position: relative;
        width: 845px;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        color: black;
        border-radius: 0.5rem;
        background: white;
    }
</style>

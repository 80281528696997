<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { fade } from 'svelte/transition'
  import { BusinessDocument } from '../../../models/business-document'
  import { t } from '../../../../core-app/lib/i18n/i18nextWrapper.js'
  import { VoxyHelper } from '../../../helpers/voxy-helper.js'

  /** Export let declarations **/
  export let businessDocument: BusinessDocument
  export let activeDiscountValue: string | number = businessDocument?.totalDiscountExcludingTaxChosenUnit ? businessDocument?.totalDiscountExcludingTaxChosenUnit : 1

  const dispatch = createEventDispatcher()

  export function onClickRemoveDiscountModule() {
    dispatch('removeDiscountModule')
  }

  const handleClick = (activeValue: number) => () => {
    activeDiscountValue = activeValue
    if (activeDiscountValue === 1) {
      businessDocument.totalDiscountExcludingTaxChosenUnit = '%'
      businessDocument.totalDiscountExcludingTaxChosenValue = 0
    } else if (activeDiscountValue === 2) {
      businessDocument.totalDiscountExcludingTaxChosenUnit = 'EUR'
      businessDocument.totalDiscountExcludingTaxChosenValue = 0
    }
  }


</script>

<section class="relative bg-whisper border border-athensGray rounded py-3 px-4 my-4" transition:fade>
    <div class="flex justify-between mx-1.5 my-4">
        <h4 class="font-medium">{t('invoices.createInvoice.discountLabel')}</h4>
        <div aria-describedby="tooltip"
             aria-expanded="false"
             class="tooltip-target"
             id="invoice-discount">
            <span class="cursor-pointer" on:click={() => onClickRemoveDiscountModule() }>
                <svg fill="none" height="24" viewBox="0 0 24 24" width="24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.75 7.75L6.59115 17.4233C6.68102 18.4568 7.54622 19.25 8.58363 19.25H14.4164C15.4538 19.25 16.319 18.4568 16.4088 17.4233L17.25 7.75H5.75Z"
                          stroke="currentColor" stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"></path>
                    <path d="M9.75 10.75V16.25" stroke="currentColor" stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"></path>
                    <path d="M13.25 10.75V16.25" stroke="currentColor"
                          stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="1.5"></path>
                    <path d="M8.75 7.75V6.75C8.75 5.64543 9.64543 4.75 10.75 4.75H12.25C13.3546 4.75 14.25 5.64543 14.25 6.75V7.75"
                          stroke="currentColor" stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"></path>
                    <path d="M4.75 7.75H18.25" stroke="currentColor" stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"></path>
                </svg>
            </span>
        </div>
    </div>

    <div class="flex items-center">
        <div aria-orientation="horizontal"
             class="flex space-x-1 rounded bg-athensGray p-1 w-fit mx-auto"
             role="tablist">
            <button
                    aria-selected={activeDiscountValue === 1 || businessDocument.totalDiscountExcludingTaxChosenUnit === '%'}
                    class="flex items-center rounded-md py-[0.4375rem] px-4 text-s font-medium {activeDiscountValue === 1 || businessDocument.totalDiscountExcludingTaxChosenUnit === '%' ? 'bg-white shadow' : ''}"
                    on:click|preventDefault={handleClick(1)}
                    role="tab"
                    tabindex="-1"
                    type="button">
                <span class="sr-only lg:not-sr-only font-bold {activeDiscountValue === 1 || businessDocument.totalDiscountExcludingTaxChosenUnit === '%' ? 'text-black' : 'text-baliHai'}">{t('invoices.createInvoice.discountPercentageValueLabel')}</span>
            </button>
            <button
                    aria-selected={activeDiscountValue === 2 || businessDocument.totalDiscountExcludingTaxChosenUnit === 'EUR'}
                    class="flex items-center rounded-md py-[0.4375rem] px-4 text-sm font-medium {activeDiscountValue === 2 || businessDocument.totalDiscountExcludingTaxChosenUnit === 'EUR' ? 'bg-white shadow' : ''} "
                    on:click|preventDefault={handleClick(2)}
                    role="tab"
                    tabindex="0"
                    type="button">
                <span class="sr-only lg:not-sr-only {activeDiscountValue === 2 || businessDocument.totalDiscountExcludingTaxChosenUnit === 'EUR' ? 'text-black' : 'text-baliHai'}">
                    EUR
                </span>
            </button>
        </div>
        <div class="flex flex-1 px-1 flex-col pr-1">
            {#if activeDiscountValue === 1 || businessDocument.totalDiscountExcludingTaxChosenUnit === '%'}
                <div class="relative rounded-md shadow-sm">
                    <div class="invoice-input-prefix-wrapper">
                        <span class="text-black text-xxs">{t('invoices.createInvoice.discountPercentageValueLabel')}</span>
                    </div>
                    <input
                            on:input={(e) => VoxyHelper.checkValueToMakeItValidPercentage(e, businessDocument.totalDiscountExcludingTaxChosenValue?.toString())}
                            bind:value={businessDocument.totalDiscountExcludingTaxChosenValue}
                            id='amount-discount-percent'
                            type="number"
                            min="1"
                            max="100"
                            step="0.01"
                            placeholder="0%"
                            aria-describedby="amount-discount-percent"
                            class="invoice-input-prefix"/>

                </div>
            {/if}
            {#if activeDiscountValue === 2 || businessDocument.totalDiscountExcludingTaxChosenUnit === 'EUR'}
                <div class="relative rounded-md shadow-sm">
                    <div class="invoice-input-prefix-wrapper">
                        <span class="text-black text-xxs">{t('invoices.createInvoice.discountEurValueLabel')}</span>
                    </div>
                    <input
                            id='amount-discount-numerary'
                            bind:value={businessDocument.totalDiscountExcludingTaxChosenValue}
                            type="number"
                            placeholder="0,00"
                            aria-describedby="amount-discount-numerary"
                            class="invoice-input-prefix "/>
                </div>
            {/if}
        </div>
    </div>
</section>

<style lang="postcss">
    .invoice-input-prefix-wrapper {
        @apply pointer-events-none absolute left-0 top-0 flex items-center px-2.5 overflow-hidden border-r border-loblolly;
        border-radius: 5px 0 0 5px;
        background: #e1e1;
        height: 38px;
        line-height: 38px;
    }

    .invoice-input-prefix {
        @apply border border-loblolly box-border bg-white focus:ring-2 focus:ring-dundyOrange focus:border-transparent pl-12 pr-12;
        border-radius: 5px;
        height: 38px;
        width: 100%;
    }
</style>

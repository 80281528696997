<script lang="ts">
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
    import { formatToDateWithSlashes } from '../../../../core-app/util/date-utils'
    import { BusinessDocument } from '../../../models/business-document'
    import TextInput from '../../../../core-app/lib/ui-kit/TextInput.svelte'
    import { ExchangeDate } from '../../../../core-app/models/exchange-date'
    // @ts-ignore
    import datepicker from 'js-datepicker'
    import { onDestroy } from 'svelte'

    /** Export let declarations **/
    export let businessDocument: BusinessDocument

    /** Let declarations **/
    let currentDatePicker: HTMLElement
    let invoiceIssuedDateReadable: string
    let invoiceDueDateReadable: string

    $: invoiceIssuedDateReadable = formatToDateWithSlashes(new Date(businessDocument?.issuedDate.unixSeconds * 1000))
    $: invoiceDueDateReadable = formatToDateWithSlashes(new Date(businessDocument?.dueDate.unixSeconds * 1000))

    /**
     * Update DatePicker With Selected Date from Button
     * @param currentDatePicker
     * @param date
     */
    const updateDatePicker = (currentDatePicker: any, date: any) => {
      /* console.log(currentDatePicker) */
      currentDatePicker.setDate(date)
      businessDocument.dueDate = ExchangeDate.newDate(date)
      /* console.log('datepicker and business document due date updated with:' + date) */
      closeDatePicker()
    }

    /**
     * Append Buttons To Datepicker
     * @param currentDatePicker
     */
    const appendButtonsToDatepicker = (currentDatePicker: any): void => {
      const datepickerContainer: HTMLDivElement = document.querySelector('.qs-datepicker-container')

      if (datepickerContainer.querySelector('.qs-additional-buttons')) {
        // The buttons have already been appended, so just return
        return
      }
      // Create a new div to contain the buttons
      const buttonsContainer = document.createElement('div')
      buttonsContainer.className = 'qs-additional-buttons'

      const date: Date = new Date(businessDocument.issuedDate.unixSeconds * 1000)

      const uponReceiptButton = document.createElement('button')
      uponReceiptButton.innerText = t('calendar.timelineSelection.uponReceipt')
      uponReceiptButton.addEventListener('click', (e) => {
        e.stopPropagation()
        date.setDate(date.getDate())
        updateDatePicker(currentDatePicker, date)
      })

      const sevenDaysButton = document.createElement('button')
      sevenDaysButton.innerText = t('calendar.timelineSelection.inSevenDays')
      sevenDaysButton.addEventListener('click', (e) => {
        e.stopPropagation()
        date.setDate(date.getDate() + 7)
        updateDatePicker(currentDatePicker, date)
      })

      const fifteenDaysButton = document.createElement('button')
      fifteenDaysButton.innerText = t('calendar.timelineSelection.inFifteenDays')
      fifteenDaysButton.addEventListener('click', (e) => {
        e.stopPropagation()
        date.setDate(date.getDate() + 15)
        updateDatePicker(currentDatePicker, date)
      })

      const thirtyDaysButton = document.createElement('button')
      thirtyDaysButton.innerText = t('calendar.timelineSelection.inThirtyDays')
      thirtyDaysButton.addEventListener('click', (e) => {
        e.stopPropagation()
        date.setDate(date.getDate() + 30)
        updateDatePicker(currentDatePicker, date)
      })

      const fortyFiveDaysButton = document.createElement('button')
      fortyFiveDaysButton.innerText = t('calendar.timelineSelection.inFortyFiveDays')
      fortyFiveDaysButton.addEventListener('click', (e) => {
        e.stopPropagation()
        date.setDate(date.getDate() + 45)
        updateDatePicker(currentDatePicker, date)
      })

      const sixtyDaysButton = document.createElement('button')
      sixtyDaysButton.innerText = t('calendar.timelineSelection.inSixtyDays')
      sixtyDaysButton.addEventListener('click', (e) => {
        e.stopPropagation()
        date.setDate(date.getDate() + 60)
        updateDatePicker(currentDatePicker, date)
      })

      buttonsContainer.appendChild(uponReceiptButton)
      buttonsContainer.appendChild(sevenDaysButton)
      buttonsContainer.appendChild(fifteenDaysButton)
      buttonsContainer.appendChild(thirtyDaysButton)
      buttonsContainer.appendChild(fortyFiveDaysButton)
      buttonsContainer.appendChild(sixtyDaysButton)

      datepickerContainer.appendChild(buttonsContainer)
      datepickerContainer.style.width = '460px'
    }

    /** Close datepicker */
    const closeDatePicker = () => {
      if (currentDatePicker) {
        currentDatePicker.remove()
        currentDatePicker = null
      }
      const modalFooter: HTMLDivElement = document.querySelector('.modal-footer')
      if (modalFooter) {
        modalFooter.style.zIndex = '20'
      }
      document.removeEventListener('click', handleOutsideClick, false)
    }

    /** Open Datepicker */
    const openDatePicker = (clazz: string) => {
      closeDatePicker()

      // Init the datepicker with default value and min date
      let initializationDate: ExchangeDate = businessDocument.issuedDate
      let dateSelected = new Date(initializationDate.unixSeconds * 1000)
      const dateIssued: Date = new Date(businessDocument.issuedDate.unixSeconds * 1000)
      const dateDue: Date = new Date(businessDocument.dueDate.unixSeconds * 1000)
      let minDate = null

      // If the selected Date is before the issued date, set the selected date to the issued date
      if (dateDue < dateIssued) {
        /* console.log('we re in the condition of dateselected inferior to minDate') */
        invoiceDueDateReadable = formatToDateWithSlashes(new Date(dateIssued))
        businessDocument.dueDate = ExchangeDate.newDate(dateIssued)
      }

      // If the datepicker is for the due date, set the min date to the issued date
      if (clazz === 'dateDue') {
        const dateIssued = new Date(businessDocument.issuedDate.unixSeconds * 1000)
        initializationDate = businessDocument.dueDate
        if (dateSelected < minDate) {
          /* console.log('we re in the condition of dateselected inferior to minDate') */
          dateSelected = dateIssued
          minDate = dateIssued
          initializationDate = businessDocument.issuedDate
          this.setMin(minDate)
        } else {
          dateSelected = new Date(initializationDate.unixSeconds * 1000)
          minDate = new Date(businessDocument.issuedDate.unixSeconds * 1000)
        }
      }

      /* console.log(initializationDate, 'initializationDate') */
      /* console.log(dateSelected, 'dateSelected') */
      /* console.log('init') */
      datepicker(`.${clazz}`, {
        position: 'br',
        formatter: (input: any, date: any) => {
          input.value = formatToDateWithSlashes(new Date(date))
        },
        startDay: 1, // Calendar week starts on a Monday.
        customDays: [t('calendar.days.sunday'), t('calendar.days.monday'), t('calendar.days.tuesday'), t('calendar.days.wednesday'), t('calendar.days.thursday'), t('calendar.days.friday'), t('calendar.days.saturday')],
        customMonths: [t('calendar.months.january'), t('calendar.months.february'), t('calendar.months.march'), t('calendar.months.april'), t('calendar.months.may'), t('calendar.months.june'), t('calendar.months.july'), t('calendar.months.august'), t('calendar.months.september'), t('calendar.months.october'), t('calendar.months.november'), t('calendar.months.december')],
        alwaysShow: true,
        dateSelected,
        minDate,
        showAllDates: true,
        disableYearOverlay: true,
        onSelect: (instance: any, date: any) => {

          if (clazz === 'dateDue') {
            /* console.log('date for invoice due :', date, minDate) */
            const dateIssued: Date = new Date(businessDocument.issuedDate.unixSeconds * 1000)
            if (dateIssued > date) {
              /* console.log(businessDocument.dueDate, 'businessDocument.dueDate after min date is lower than issued date') */
              invoiceDueDateReadable = formatToDateWithSlashes(new Date(dateIssued))
              businessDocument.dueDate = ExchangeDate.newDate(dateIssued)
            }
            businessDocument.dueDate = ExchangeDate.newDate(date)
            invoiceDueDateReadable = formatToDateWithSlashes(new Date(date))
            instance.setMin(date)
          } else {
            /* console.log('date for invoice issued :', date, minDate) */

            businessDocument.issuedDate = ExchangeDate.newDate(date)
            invoiceIssuedDateReadable = formatToDateWithSlashes(new Date(date))

            // Update the minDate property
            instance.setMin(date)
          }

          /** Check if needed Update Reactivity */
          businessDocument = businessDocument

          closeDatePicker()

        },
        onShow: (picker: any) => {
          if (clazz === 'dateDue') {
            appendButtonsToDatepicker(picker)
          }
          currentDatePicker = picker
          setTimeout(() => {
            document.addEventListener('click', handleOutsideClick, false)
          }, 500)
          const modalFooter: HTMLDivElement = document.querySelector('.modal-footer')
          if (modalFooter) {
            modalFooter.style.zIndex = '1'
          }
        },
        onHide: () => {
          const modalFooter: HTMLDivElement = document.querySelector('.modal-footer')
          if (modalFooter) {
            modalFooter.style.zIndex = '20'
          }
        }
      })
    }

    /**
     * Handle Outside Click
     * @param event
     */
    const handleOutsideClick = (event: Event) => {
      const targetElement = event.target as HTMLElement // Casting to HTMLElement
      const foundInDatePicker = document.querySelector(`.qs-datepicker-container ${targetElement.nodeName}${targetElement.getAttribute('class') ? ('.' + targetElement.getAttribute('class').split(' ')[0]) : ''}`)
      if (!foundInDatePicker) {
        closeDatePicker()
      }
    }


    onDestroy(() => {
      document.removeEventListener('click', handleOutsideClick, false)
    })

</script>
<div class="datatable">
    <TextInput
            on:focus={() => openDatePicker('dateIssued')}
            dataCy="businessDocument-issued-date"
            type="text"
            label={t('invoices.form.dateIssued.label')}
            error={t('invoices.form.dateIssued.error')}
            inputStyle="padding-left: 2.5em;"
            inputClass="input-date-picker dateIssued text-paleSky"
            class="form-input"
            bind:value={invoiceIssuedDateReadable}>
        <i slot="icon"
           class="icon-calendar text-2xl absolute left-2 text-ebonyClay cursor-pointer"
           style="top: 15%;"
           on:click={() => openDatePicker('dateIssued')}
           on:keydown={(e) => { if (e.key === 'Enter' || e.key === ' ') {openDatePicker('dateIssued')} }}
           role="button"
           tabindex="-1">
        </i>
    </TextInput>
</div>
<div class="datatable">
    <TextInput
            on:focus={() => openDatePicker('dateDue')}
            dataCy="businessDocument-due-date"
            type="text"
            label={t('invoices.form.dateDue.label')}
            error={t('invoices.form.dateDue.error')}
            inputStyle="padding-left: 2.5em;"
            inputClass="input-date-picker dateDue text-paleSky"
            class="form-input"
            bind:value={invoiceDueDateReadable}>
        <i slot="icon"
           class="icon-calendar text-2xl absolute left-2 text-ebonyClay cursor-pointer"
           style="top: 15%;"
           on:click={() => openDatePicker('dateDue')}
           on:keydown={(e) => { if (e.key === 'Enter' || e.key === ' ') {openDatePicker('dateDue')} }}
           role="button"
           tabindex="0">
        </i>
    </TextInput>
</div>

<script lang="ts">
  import { onMount } from 'svelte'
  // import { remoteDebuggingService } from './core-app/services/remote-debugging.service'
  // import customLog from './shared/services/log-user-event.service'
  // import mixpanel from 'mixpanel-browser'

  onMount(() => {
    // customLog('')
    // remoteDebuggingService.addInfo('')
    // mixpanel.track('mob.A00.u mobile page loaded', {
    //   'Description': 'User open app with a mobile device'
    // })
  })
</script>

<div class="text-center h-screen w-screen">
  <div class="bg-gray-900 p-7 mb-10">
    <img alt="Dundy" src="/img/dundy-icon.svg"/>
  </div>

  <h1 class="text-2xl mb-12">Bienvenue sur Dundy !</h1>
  <p>L'application est disponible pour Ordinateur et Tablettes. Nous travaillons sur la version mobile.</p>
  <p class="mt-8">Pour toute question, cliquer sur la bulle en bas de l'écran</p>
</div>

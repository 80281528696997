<script lang="ts">
    import { writable } from 'svelte/store'
    import type { CollateralCalculusForDashboard } from '$core/services/dashboard-update-calculus'
    import { t } from '$core/lib/i18n/i18nextWrapper'

    /** Export let */
    export let computedCalculusForDashboard: CollateralCalculusForDashboard

    /** Local variables */
    let activeIndex = writable(0)

    let dateNow: string = new Intl.DateTimeFormat(t('locales'), {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    }).format(new Date())

    const items = [
      {
        title: t('dunning.visualisationHUD.outstandingAmountTitle'),
        subtitle: t('dunning.visualisationHUD.outstandingAmountSubtitle', { dateNow: dateNow }),
        amount: computedCalculusForDashboard.outstandingInvoicesAmountNormalized
      },
      {
        title: t('dunning.visualisationHUD.dueAmountTitle'),
        subtitle: t('dunning.visualisationHUD.dueAmountSubtitle', { dateNow: dateNow }),
        amount: computedCalculusForDashboard.dueInvoicesAmountNormalized
      },
      {
        title: t('dunning.visualisationHUD.overdueAmountTitle'),
        subtitle: t('dunning.visualisationHUD.overdueAmountSubtitle', { dateNow: dateNow }),
        amount: computedCalculusForDashboard.overdueInvoicesAmountNormalized
      }
    ]

    /**
     * Go to next slide, wrap around to start if at end
     */
    function next() {
      activeIndex.update(n => (n + 1) % items.length)
    }

    /**
     * Go to previous slide, wrap around to end if at start
     */
    function prev() {
      activeIndex.update(n => (n - 1 + items.length) % items.length)
    }
</script>

<div class="w-full p-6 overflow-hidden lg:border-r border-rhino bg-[#1A1F38]">
    <div class="flex flex-col space-y-2">
        {#each items as item, index}
            {#if $activeIndex === index}
                <div class="flex flex-col text-center lg:text-left">
                    <span class="text-sm font-medium text-gray-100">{item.title}</span>
                    <span class="text-xs text-zinc-400">{item.subtitle}</span>
                    <h4 class="w-full flex-none text-3xl leading-10 tracking-tight text-white truncate">{item.amount}</h4>
                </div>
            {/if}
        {/each}
        <div class="flex justify-between items-center">
            <button class="text-zinc-500 hover:text-white" on:click={prev}>
                <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                          d="M10.25 6.75L4.75 12L10.25 17.25"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                          d="M19.25 12H5"></path>
                </svg>
            </button>
            <div class="flex space-x-2">
                {#each items as _, index}
                    <span
                            class={$activeIndex === index ? 'dot active' : 'dot'}
                            on:click={() => activeIndex.set(index)}
                            on:keydown={(e) => { if (e.key === 'Enter') { activeIndex.set(index) }}}
                            role="button"
                            tabindex="{index}"
                    ></span>
                {/each}
            </div>
            <button class="text-zinc-500 hover:text-white" on:click={next}>
                <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                          d="M13.75 6.75L19.25 12L13.75 17.25"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                          d="M19 12H4.75"></path>
                </svg>
            </button>
        </div>
    </div>
</div>

<style lang="postcss">
    .dot {
        @apply h-2 w-2 bg-zinc-600 rounded-full inline-block cursor-pointer;
    }

    .active {
        @apply bg-dundyOrange;
    }
</style>

<script lang="ts">
  import customLog from '$src/shared/services/custom-log.service'

  let journeys:any[] = []
  let count:number = 1
  let isLoading:boolean = false

  const onClick = async () => {
    isLoading = true
    journeys = await customLog.getJourney(count)
    isLoading = false
  }
</script>


<div>
  <div class="mb-6">
    <input type="number" min="1" max="1000" disabled={isLoading} bind:value={count} />
    <button type="button" disabled={isLoading} on:click={() => onClick()}>Load</button>
  </div>

  {#each journeys as journey}
    <pre class="text-xs mb-7">{JSON.stringify(journey, null, 2)}</pre>
  {/each}
</div>

<style lang="postcss">
      button {
        color: white;
        background: rgb(155, 131, 117);
        padding: 0.5rem 1rem;
    }
</style>
<script lang="ts">
    import { Tooltip } from '../../../../core-app/lib/ui-kit/Tooltip'
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
    import { navigate } from 'svelte-routing'
    import { CoreDocument } from '../../../models/business-document'
    import { formatCurrencyWithOptions } from '../../../utils/number-formatting-utils.js'
    import { getBusinessDocumentMainTitle } from '../../../services/business-documents-functions'

    /** Export let Var */
    export let bdList: CoreDocument

</script>

<div class="flex items-center p-4">
    <div class="flex-auto">
        <div class="font-medium">
            {getBusinessDocumentMainTitle(bdList?.businessDocument.businessDocumentKind, bdList?.businessDocument.taxonomyTags, bdList?.businessDocument.hasSpecificInstallment)}
            {bdList?.businessDocument.businessDocumentNumber}</div>
        <div class="text-slate-700">
            <p class="text-xl text-black">
                {formatCurrencyWithOptions(bdList?.businessDocument?.totalIncludingTaxScaledValue, t('locales'), bdList?.businessDocument?.currency, true, 'symbol')}
            </p>
            {t('invoices.createInvoice.issuedOn')} {new Intl.DateTimeFormat(t('locales'), {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            }).format(new Date(bdList?.businessDocument.issuedDate.unixSeconds * 1000))} <span
                class="text-loblolly px-1">|</span> {t('invoices.createInvoice.dueOn')} {new Intl.DateTimeFormat(t('locales'), {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                }).format(new Date(bdList?.businessDocument.dueDate.unixSeconds * 1000))}
        </div>
    </div>
    <Tooltip>
        <div slot="activator">
            <div class="cursor-pointer pointer-events-auto ml-4 flex-none rounded-md py-[0.3125rem] px-2 font-medium text-cabaret shadow-sm ring-1 ring-cabaret hover:bg-slate-50 disabled:text-loblolly">
                <svg width="24" height="24" viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.75 12.25L18 12C19.6569 10.3431 19.6569 7.65685 18 6C16.3431 4.34314 13.6569 4.34314 12 6L11.75 6.25"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path d="M6.24996 11.75L5.99996 12C4.34311 13.6569 4.34311 16.3431 5.99996 18C7.65682 19.6569 10.3431 19.6569 12 18L12.25 17.75"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path d="M8.25 4.75V6.25"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path d="M15.75 17.75V19.25"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path d="M6.25 8.25H4.75"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path d="M19.25 15.75H17.75"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"></path>
                </svg>
            </div>
        </div>
        {t('invoices.createInvoice.buttons.unlinkTooltip')}
    </Tooltip>
    <Tooltip>
        <div slot="activator">
            <div on:click={() => { navigate(`/receivables/view/${bdList?.businessDocument.businessDocumentId}/details`)}}
                    on:keydown={(e) => { if (e.key === 'Enter' || e.key === ' ') { navigate(`/receivables/view/${bdList?.businessDocument.businessDocumentId}/details`)} }}
                    role="button"
                    tabindex="0"
                    class="cursor-pointer pointer-events-auto ml-4 flex-none rounded-md py-[0.3125rem] px-2 font-medium text-slate-700 shadow-sm ring-1 ring-slate-700/10 hover:bg-slate-50">
                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                          d="M19.25 12C19.25 13 17.5 18.25 12 18.25C6.5 18.25 4.75 13 4.75 12C4.75 11 6.5 5.75 12 5.75C17.5 5.75 19.25 11 19.25 12Z"></path>
                    <circle cx="12" cy="12" r="2.25" stroke="currentColor" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="1.5"></circle>
                </svg>

            </div>
        </div>
        {t('invoices.createInvoice.buttons.viewDocumentTooltip')}
    </Tooltip>
</div>
import translations from './translations'
import i18next, { type Resource } from 'i18next'
import { get } from 'svelte/store'
import { ProfileStore } from '../../stores/profile.store'
import { currentLanguageCode, currentLocales } from '../../stores/i18n.store'

/** Let declarations */
let initiated: boolean = false
let initiating: boolean = false

/**
 * Returns the current language code of the app
 */
export function currentProfileLanguage(): string {
  let languageCode: string = 'fr'
  if (get(ProfileStore)) /* console.log('get(ProfileStore).lang', get(ProfileStore).lang) */
    if (get(ProfileStore) && get(ProfileStore).lang) {
      languageCode = get(ProfileStore).lang.split('-')[0].toLocaleLowerCase()
    } else {
      if (navigator.language) {
        if (navigator.language.indexOf('-') > -1) {
          languageCode = navigator.language.split('-')[0].toLocaleLowerCase()
        } else {
          languageCode = navigator.language.toLocaleLowerCase()
        }
      }
    }

  return languageCode
}

/**
 * Initialize the i18next translation system
 * @param callback
 */
function initTranslationSystem(callback: { (): void; (): void; (): void; }) {
  /*console.trace();
       console.log("initializing i18next") */
  if (!initiated && !initiating) {
    initiating = true
    i18next.init({
      lng: currentProfileLanguage(),
      fallbackLng: 'fr',
      debug: true,
      defaultNS: 'common',
      fallbackNS: 'common',
      resources: <Resource>translations,
      returnObjects: true,
      interpolation: {
        escapeValue: false
      }
    }, function () {
      currentLanguageCode.set(currentProfileLanguage())
      initiated = true
      initiating = false
      currentLocales.set(t('locales'))
      callback()
    })
  }
}

/**
 * Change the language of the app to the language of the current profile
 * @param callback
 */
export function changeLanguageToProfile(callback: { (): void; (): void; (): void; }) {
  if (initiated && !initiating) {
    i18next.changeLanguage(currentProfileLanguage())
      .then(() => {
        currentLanguageCode.set(currentProfileLanguage())
        currentLocales.set(t('locales'))
        callback()
      })
      .catch((err) => {
        console.log('something went wrong in changeLanguageToProfile', err)
      })
  }
}

/**
 * Change the language of the app to the given language code
 * @param key
 * @param replacements
 */
export function t(key: string, replacements?: Record<string, unknown>): string {
  if (initiated && !initiating) {
    return i18next.t(key, replacements)
  } else {
    /* console.log('warning : calling function initTranslationSystem from t in i18next.store') */
    initTranslationSystem((): void => {
    })
    if (initiated && !initiating) {
      return i18next.t(key, replacements)
    } else {
      return key
    }
  }
}

initTranslationSystem(() => {
  changeLanguageToProfile(() => {
  })
})


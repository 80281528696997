<script lang="ts">
  import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
    import { BusinessDocument } from '../../../models/business-document'

    export let businessDocument: BusinessDocument

    const legalMentionVAT = businessDocument?.accountCompany?.regulatory?.euRegulScope?.euIntraVat
      ? t('invoices.createInvoice.vatNumberWithDataLabel', { tva: businessDocument?.accountCompany?.regulatory?.euRegulScope?.euIntraVat })
      : t('invoices.createInvoice.notEligibleToVAT')
    
    const legalMentionRCS = businessDocument?.accountCompany?.regulatory?.frRegulScope?.rcs ?? ''
    
    const legalMentions:string = [businessDocument.legalMentions, legalMentionVAT, legalMentionRCS].join('. ')
</script>

<div class="py-3">
    <p class="text-xxxs text-black font-medium">
        {@html legalMentions}
    <p class="text-xxxs text-gray-600 mt-1">
        {@html businessDocument.paymentConditions}
    </p>
</div>
import type { Workspace } from '../../crm-app/models/workspace'

const DEFAULT_TIME_ZONE: string = 'Europe/Paris'

/**
 * Get the user company timezone
 * @param workspace
 */
export function getUserCompanyTimezone(workspace: Workspace = <Workspace>{}): string {
  try {
    if (!workspace) {
      return DEFAULT_TIME_ZONE
    }
    if (workspace?.company?.timeZoneIANACode === '') {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    } else {
      return workspace.company.timeZoneIANACode
    }
  } catch (e) {
    if (!workspace || !workspace.company) {
      if (workspace === null || workspace === undefined || workspace.company === null || workspace.company === undefined) { // strictly check null (which is intentionally done in WorkspaceHelper.newCompany())
        return DEFAULT_TIME_ZONE
      } else {
        /* console.error('getUserCompanyTimezone 1 b error:', e) */
        
        return DEFAULT_TIME_ZONE
      }
    }
    /* console.error('getUserCompanyTimezone 2 b error:', e) */
    
    return DEFAULT_TIME_ZONE
  }
}



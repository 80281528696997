import type { WorkspaceBankConfig } from '../../pay-app/models/workspace-bank-config'
import type { BusinessDocument } from '../models/business-document'
import { escapeRegExp } from '../../core-app/util/regexp-utils'
import type AgGridBusinessDocument from '../models/ag-grid-business-document'

/**
 * Generates a random string of characters for BankWireReference
 * @param length
 */
export function calculateBankWireReference(length: number): string {
  let result: string = ''
  const characters: string = 'ACDEFGHJKLMNPQRTWXYZ2346799'
  let charactersLength: number = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
            charactersLength))
  }
  
  return result
}

export function getUpdatedIsBankInformationMissing(workspaceBankConfig: WorkspaceBankConfig): boolean {
  return !workspaceBankConfig.selectedAccountBankIBAN || !workspaceBankConfig.selectedAccountBankBIC || !workspaceBankConfig.selectedAccountBankName
}

/**
 * Search function used by Ag-grid to filter invoices
 * Returns true if the invoice contains the text in its toName, toId, invoiceNumber, amountIncludingTaxes, currency, dateDue, dateIssued
 * @param businessDocument
 * @param text
 * @param tLocales is = t('locales') e.g. 'fr-FR'
 */
export function isBusinessDocumentContainingText(businessDocument: BusinessDocument, text: string, tLocales: string): boolean {
  const regexp: RegExp = new RegExp(escapeRegExp(text), 'i')

  let lookIntoDocument: AgGridBusinessDocument = <AgGridBusinessDocument>{
    toId: businessDocument.customerCustomer.company.companyId,
    label: businessDocument.customerCustomer.company.formalName,
    formalName: businessDocument.customerCustomer.company.formalName,
    businessDocumentNumber: businessDocument.businessDocumentNumber,
    issuedDate: new Intl.DateTimeFormat(tLocales, { year: 'numeric', month: 'long', day: 'numeric' })
      .format(new Date(businessDocument.issuedDate.rfc3339)),
    dueDate: new Intl.DateTimeFormat(tLocales, { year: 'numeric', month: 'long', day: 'numeric' })
      .format(new Date(businessDocument.dueDate.rfc3339)),
    totalAmount: businessDocument.hasSpecificInstallment ? businessDocument.installmentResultIncludingTaxScaledValue : businessDocument.totalIncludingTaxScaledValue,
    businessDocumentStatus: '',
    actions: ''
  }
  
  delete lookIntoDocument.toId

  for (const prop of Object.keys(lookIntoDocument)) {
    if (typeof lookIntoDocument[prop] === 'string' && lookIntoDocument[prop].search(regexp) !== -1) {
      return true
    } else if (typeof lookIntoDocument[prop] === 'number') {
      const numberAsString: string = new Intl.NumberFormat(tLocales).format(lookIntoDocument[prop])
      if (numberAsString.search(regexp) !== -1) {
        return true
      }
    }
  }

  return false
}

/**
 * Accumulator that calculates the total price of the items
 * @param items
 */
export function calculateTotalPrice(items: any[]) {
  return items.reduce((acc: number, item: { quantity: number; itemPrice: { scaledValue: number; }; }) => acc + item.quantity * item.itemPrice.scaledValue, 0)
}


/**
 * Accumulator that calculates the total VAT of the items
 * @param items
 */
export function calculateTotalVAT(items: any[]) {
  return items.reduce((acc, item) => (
    acc +
            (item.quantity *
                item.itemPrice.scaledValue *
                item.taxRate.percentage) /
            100
  ), 0)
}

<script lang="ts">
  import { t } from '../../lib/i18n/i18nextWrapper'
  import { navigate } from 'svelte-routing'
  import { onDestroy, onMount } from 'svelte'
  import { WorkspaceStore } from '$crm/stores/workspace.store'
  import { isUserWsOwner } from '$core/services/guard'
  import type { Unsubscriber } from 'svelte/store'
  import auth from '$core/lib/auth0authentication/authService'
  import mixpanel from 'mixpanel-browser'

  let isUserWorkspaceOwner: boolean = false
  let unSubscriberWorkspace: Unsubscriber

  const onButtonClick = (e:MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    mixpanel.track('onb.str.cnl.A02.u onboardind cancel with connection button', {
      'Description': 'Click on connection button (top right)'
    })

    if (isUserWorkspaceOwner) {
      auth.logout() 
    } else {
      navigate('/signin')
    }
  }

  onMount(() => {
    if (!!unSubscriberWorkspace) {
      unSubscriberWorkspace()
    }
    unSubscriberWorkspace = WorkspaceStore.subscribe(() => {
      isUserWorkspaceOwner = isUserWsOwner()
    })
  })

  onDestroy(() => {
    if (!!unSubscriberWorkspace) {
      unSubscriberWorkspace()
    }
  })
</script>


<div class="flex min-h-full bg-white relative">
  <aside
    class="relative flex-none w-full lg:w-[32rem] hidden lg:block lg:fixed lg:inset-y-0 lg:z-40 bg-black  overflow-y-auto">
    <div class="px-6 lg:px-0">
      <div class="flex justify-left relative p-10 pl-12 max-w-72">
        <a href="/">
          <img alt="dundy logo" src="/img/dundy-mark.svg" class="h-10"/>
        </a>
      </div>
      <div class="mx-auto max-w-lg lg:w-full">
        <slot name="onboarding-sidebar"></slot>
      </div>
    </div>
  </aside>

  {#if window.location.pathname === '/onboarding/confirm'}
    <button
      type="button"
      class="absolute right-4 top-4 z-[999] -m-2.5 p-2.5 cursor-pointer px-2 py-1 text-xs font-semibold text-black hover:text-dundyOrange"
      on:click={(e) => onButtonClick(e)}
    >
      {isUserWorkspaceOwner ? t('onboarding.signOut') : t('onboarding.signIn')}
    </button>
  {/if}

  <div class="flex-auto lg:ml-[32rem] overflow-y-auto">
    <div class="py-20 space-y-20 sm:space-y-32 sm:py-24 px-12">
      <slot name="onboarding-content"></slot>
    </div>
  </div>
</div>

import StorageBucketObject from '../models/storage-bucket-object'
import { APICallOptions, APIEntity, apiUpload } from '../../core-app/services/api.service'

export function pdfAttachmentUpload(businessDocumentNumber: string, businessDocumentId: string, workspaceId: string, isBusinessDocumentFinalized: boolean, firstSelectedFile: File): Promise<StorageBucketObject> {
  return apiUpload(`/workspace/${workspaceId}/invoice/${window.btoa(isBusinessDocumentFinalized ? businessDocumentNumber : businessDocumentId).replace(/=/g, '')}/file-attachments`, null, <APICallOptions>{
    entity: APIEntity.INVOICE_FILE,
    file: firstSelectedFile,
    isB64: true, // that is an arbitrary choice to always send base64 encoded files to the back-end, but it is a safe choice when viewing the terminal or the logs
    invoiceDataId: businessDocumentId,
    invoiceNumber: businessDocumentNumber
  })
}
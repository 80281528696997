export type InvoiceStatusType = string

export enum InvoiceStatus {
  OUTSTANDING = 'Outstanding',
  VOIDED = 'Voided',
  DISPUTED = 'Disputed',
  PAID = 'Paid',
  ACCEPTED = 'Accepted',
  RESOLVED = 'Resolved',
  TO_BE_DEFINED = 'ToBeDefined'
}

import { TodoItemActionKind, TodoItemMessageKind, type TodoItemMessageKindType, TodoListItem } from '../models/todo-list-item'
import { type EscalationTabToMessageKind, TodoUIDunningEscalationTabs } from '../models/todo-ui-dunning-escalation-tabs'
import type { InvoicesHistoryItem, TaskDoneEventPersisted, InvoiceEvent } from '../models/invoices-history'


/**
 * Sets the current escalation tab according to the actionKind property in TodoListItem or the user setting
 * if the user setting is not set, the default escalation tab is set
 * if the user setting is set, the user setting is used
 * @param todo
 * @param activeDunningEscalationTab
 */
export function setCurrentEscalationTabAccordingToTodoEscalationOrUserSetting(todo: TodoListItem, activeDunningEscalationTab?: TodoUIDunningEscalationTabs): TodoUIDunningEscalationTabs {
  if (activeDunningEscalationTab && todo) {
    return activeDunningEscalationTab
  }

  if (!todo) {
    return TodoUIDunningEscalationTabs.INFORMATION
  }

  return getEscalationTabForTodoItem(todo)
}

/**
 * Sets the current escalation tab according to the actionKind property in TodoListItem or the user setting
 * @param todo
 */
export function getEscalationTabForTodoItem(todo: TodoListItem): TodoUIDunningEscalationTabs {
  switch (todo.actionKind) {
    case TodoItemActionKind.INVOICE_ISSUANCE:
      return TodoUIDunningEscalationTabs.INFORMATION
    case TodoItemActionKind.COURTESY_REMINDER_SHORTLY_BEFORE_DUE_DATE:
      return TodoUIDunningEscalationTabs.INFORMATION
    case TodoItemActionKind.REMINDER_JUST_AFTER_DUE_DATE:
      return TodoUIDunningEscalationTabs.REMINDER
    case TodoItemActionKind.REMINDER_SHORTLY_AFTER_DUE_DATE:
      return TodoUIDunningEscalationTabs.REMINDER
    case TodoItemActionKind.DIRECT_REMINDER_SOMETIME_AFTER_DUE_DATE:
      return TodoUIDunningEscalationTabs.WARNING
    case TodoItemActionKind.STRONG_LAST_REMINDER_AFTER_DUE_DATE:
      return TodoUIDunningEscalationTabs.WARNING
    case TodoItemActionKind.COLLECTION_LITIGATION:
      return TodoUIDunningEscalationTabs.LEGAL
    default:
      return TodoUIDunningEscalationTabs.INFORMATION
  }
}


/**
 * Get most recent TodoItemActionKind for specific invoice
 * uses InvoiceHistoryStore to get the history
 * if no history item is found, the default TodoItemActionKind is returned (TodoItemActionKind.INVOICE_ISSUANCE, which means sending out the invoice to customer)
 * if a doneToDoItem item is found, the most recent TodoItemActionKind is returned
 * TODO Convert to InvoicesHistoryStore to remove dependency on deprecated MergedHistoryStore
 * @param invoiceNumber
 * @param history
 */
export function getMostRecentActionKindForInvoice(invoiceNumber: string, history: InvoicesHistoryItem[]): TodoItemActionKind {
  // If no history is found, return default action kind
  if (!history || !history.length) {
    return TodoItemActionKind.INVOICE_ISSUANCE
  }

  // Filter only history items for the invoice
  const invoiceHistory: InvoicesHistoryItem[] = history.filter(
    (historyItem: InvoicesHistoryItem): boolean => historyItem.invoiceNumber === invoiceNumber,
  )

  // If no history item is found, return default action kind
  if (!invoiceHistory.length) {
    return TodoItemActionKind.INVOICE_ISSUANCE
  }

  // Find most recent doneToDoItem
  const mostRecentDoneToDoItem: TaskDoneEventPersisted | null = invoiceHistory
    .flatMap((historyItem: InvoicesHistoryItem) => historyItem.doneToDoItems)
    .reduce((mostRecent: TaskDoneEventPersisted, current: TaskDoneEventPersisted): TaskDoneEventPersisted => {
      if (!mostRecent || current.doneToDoItem.dateDone > mostRecent.doneToDoItem.dateDone) {
        return current
      }
      
      return mostRecent
    }, null)

  // If the most recent doneToDoItem is found, return its actionKind
  if (mostRecentDoneToDoItem) {
    return mostRecentDoneToDoItem.doneToDoItem.actionKind
  }

  // If there's an InvoiceIssued event, return INVOICE_ISSUANCE
  if (invoiceHistory.some((historyItem: InvoicesHistoryItem) => historyItem.invoiceEvents.some((event: InvoiceEvent): boolean => event.eventKind === TodoItemActionKind.INVOICE_ISSUED))) {
    return TodoItemActionKind.INVOICE_ISSUANCE
  }

  // If there's no matching event, return INVOICE_ISSUANCE by default
  return TodoItemActionKind.INVOICE_ISSUANCE
}


/**
 * Returns the next TodoItemActionKind based on the latest action
 * @param latestAction
 */
export function returnNextActionInTodoItemActionKindBasedOnLatestAction(latestAction: TodoItemActionKind): TodoItemActionKind {
  switch (latestAction) {
    case TodoItemActionKind.INVOICE_ISSUANCE:
      return TodoItemActionKind.COURTESY_REMINDER_SHORTLY_BEFORE_DUE_DATE
    case TodoItemActionKind.COURTESY_REMINDER_SHORTLY_BEFORE_DUE_DATE:
      return TodoItemActionKind.REMINDER_JUST_AFTER_DUE_DATE
    case TodoItemActionKind.REMINDER_JUST_AFTER_DUE_DATE:
      return TodoItemActionKind.REMINDER_SHORTLY_AFTER_DUE_DATE
    case TodoItemActionKind.REMINDER_SHORTLY_AFTER_DUE_DATE:
      return TodoItemActionKind.DIRECT_REMINDER_SOMETIME_AFTER_DUE_DATE
    case TodoItemActionKind.DIRECT_REMINDER_SOMETIME_AFTER_DUE_DATE:
      return TodoItemActionKind.STRONG_LAST_REMINDER_AFTER_DUE_DATE
    case TodoItemActionKind.STRONG_LAST_REMINDER_AFTER_DUE_DATE:
      return TodoItemActionKind.COLLECTION_LITIGATION
    case TodoItemActionKind.COLLECTION_LITIGATION:
      return TodoItemActionKind.COLLECTION_LITIGATION
    default:
      return TodoItemActionKind.INVOICE_ISSUANCE
  }
}


/**
 * Returns the next TodoItemMessageKind based on the active dunning escalation tab
 * if the todo item has a messageKind property, it will be returned
 * if not, the messageKind will be determined based on the active dunning escalation tab
 * @param todo
 * @param activeDunningEscalationTab
 * @param escalationTabToMessageKind
 */

export function returnSuggestionForTodoItemMessageKindBasedOnActiveDunningEscalationTab(
  todo: TodoListItem | undefined,
  activeDunningEscalationTab: TodoUIDunningEscalationTabs,
  escalationTabToMessageKind: EscalationTabToMessageKind,
): TodoItemMessageKind {

  const messageKind: TodoItemMessageKindType = todo?.messageKind

  if (messageKind) {
    return <TodoItemMessageKind>messageKind
  } else {
    const suggestion: TodoItemMessageKind = escalationTabToMessageKind[activeDunningEscalationTab]
    
    return suggestion ?? TodoItemMessageKind.EMAIL
  }
}


/**
 * Returns the next TodoItemActionKind based on the latest action and the active dunning escalation tab
 * @param invoiceNumber
 * @param activeDunningEscalationTab
 * @param invoicesHistoryStore InvoicesHistoryItem[]
 */
export function determineNextActionKindBasedOnActiveDunningEscalationTabAndInvoiceHistory(invoiceNumber: string, activeDunningEscalationTab: TodoUIDunningEscalationTabs, invoicesHistoryStore: InvoicesHistoryItem[]): TodoItemActionKind {

  // If the invoice history is empty, return INVOICE_ISSUANCE immediately
  if (!invoicesHistoryStore || !invoicesHistoryStore.length) {
    return TodoItemActionKind.INVOICE_ISSUANCE
  }

  // If the active dunning escalation tab is null or undefined, return INVOICE_ISSUANCE immediately
  if (activeDunningEscalationTab === null || activeDunningEscalationTab === undefined) {
    return TodoItemActionKind.INVOICE_ISSUANCE
  }

  // If the invoice number doesn't exist in the invoice history, return INVOICE_ISSUANCE immediately
  if (!invoicesHistoryStore.some((historyItem: InvoicesHistoryItem): boolean => historyItem.invoiceNumber === invoiceNumber)) {
    return TodoItemActionKind.INVOICE_ISSUANCE
  }

  let latestActionKind: TodoItemActionKind = getMostRecentActionKindForInvoice(invoiceNumber, invoicesHistoryStore)

  // if there's no history or the most recent action is invoiceIssued (eg. invoice created), return InvoiceIssuance (send the invoice to user)
  if (latestActionKind === TodoItemActionKind.INVOICE_ISSUED || latestActionKind === null || latestActionKind === undefined) {
    return TodoItemActionKind.INVOICE_ISSUANCE
  }
  let nextActionKind: TodoItemActionKind = returnNextActionInTodoItemActionKindBasedOnLatestAction(latestActionKind)

  /** DEFINING ALL INFORMATION STEPS */
  if (activeDunningEscalationTab === TodoUIDunningEscalationTabs.INFORMATION && nextActionKind === TodoItemActionKind.INVOICE_ISSUANCE) {
    return TodoItemActionKind.INVOICE_ISSUANCE
  }
  if (activeDunningEscalationTab === TodoUIDunningEscalationTabs.INFORMATION && nextActionKind === TodoItemActionKind.COURTESY_REMINDER_SHORTLY_BEFORE_DUE_DATE) {
    return TodoItemActionKind.COURTESY_REMINDER_SHORTLY_BEFORE_DUE_DATE
  }
  if ((activeDunningEscalationTab === TodoUIDunningEscalationTabs.INFORMATION) && nextActionKind !== TodoItemActionKind.INVOICE_ISSUANCE && nextActionKind !== TodoItemActionKind.COURTESY_REMINDER_SHORTLY_BEFORE_DUE_DATE) {
    return TodoItemActionKind.COURTESY_REMINDER_SHORTLY_BEFORE_DUE_DATE
  }

  /** DEFINING ALL REMINDER STEPS */
  if (activeDunningEscalationTab === TodoUIDunningEscalationTabs.REMINDER && nextActionKind === TodoItemActionKind.INVOICE_ISSUANCE) {
    return TodoItemActionKind.REMINDER_JUST_AFTER_DUE_DATE
  }
  if (activeDunningEscalationTab === TodoUIDunningEscalationTabs.REMINDER && nextActionKind === TodoItemActionKind.COURTESY_REMINDER_SHORTLY_BEFORE_DUE_DATE) {
    return TodoItemActionKind.REMINDER_JUST_AFTER_DUE_DATE
  }
  if (activeDunningEscalationTab === TodoUIDunningEscalationTabs.REMINDER && nextActionKind === TodoItemActionKind.REMINDER_JUST_AFTER_DUE_DATE) {
    return TodoItemActionKind.REMINDER_SHORTLY_AFTER_DUE_DATE
  }
  if (activeDunningEscalationTab === TodoUIDunningEscalationTabs.REMINDER && nextActionKind === TodoItemActionKind.REMINDER_SHORTLY_AFTER_DUE_DATE) {
    return TodoItemActionKind.REMINDER_SHORTLY_AFTER_DUE_DATE
  }
  if (activeDunningEscalationTab === TodoUIDunningEscalationTabs.REMINDER && nextActionKind === TodoItemActionKind.DIRECT_REMINDER_SOMETIME_AFTER_DUE_DATE) {
    return TodoItemActionKind.REMINDER_SHORTLY_AFTER_DUE_DATE
  }
  if (activeDunningEscalationTab === TodoUIDunningEscalationTabs.REMINDER && nextActionKind === TodoItemActionKind.STRONG_LAST_REMINDER_AFTER_DUE_DATE) {
    return TodoItemActionKind.REMINDER_SHORTLY_AFTER_DUE_DATE
  }
  if (activeDunningEscalationTab === TodoUIDunningEscalationTabs.REMINDER && nextActionKind === TodoItemActionKind.COLLECTION_LITIGATION) {
    return TodoItemActionKind.REMINDER_SHORTLY_AFTER_DUE_DATE
  }

  /** DEFINING ALL WARNING STEPS */
  if (activeDunningEscalationTab === TodoUIDunningEscalationTabs.WARNING && nextActionKind === TodoItemActionKind.INVOICE_ISSUANCE) {
    return TodoItemActionKind.DIRECT_REMINDER_SOMETIME_AFTER_DUE_DATE
  }
  if (activeDunningEscalationTab === TodoUIDunningEscalationTabs.WARNING && nextActionKind === TodoItemActionKind.COURTESY_REMINDER_SHORTLY_BEFORE_DUE_DATE) {
    return TodoItemActionKind.DIRECT_REMINDER_SOMETIME_AFTER_DUE_DATE
  }
  if (activeDunningEscalationTab === TodoUIDunningEscalationTabs.WARNING && nextActionKind === TodoItemActionKind.REMINDER_JUST_AFTER_DUE_DATE) {
    return TodoItemActionKind.DIRECT_REMINDER_SOMETIME_AFTER_DUE_DATE
  }
  if (activeDunningEscalationTab === TodoUIDunningEscalationTabs.WARNING && nextActionKind === TodoItemActionKind.REMINDER_SHORTLY_AFTER_DUE_DATE) {
    return TodoItemActionKind.DIRECT_REMINDER_SOMETIME_AFTER_DUE_DATE
  }
  if (activeDunningEscalationTab === TodoUIDunningEscalationTabs.WARNING && nextActionKind === TodoItemActionKind.DIRECT_REMINDER_SOMETIME_AFTER_DUE_DATE) {
    return TodoItemActionKind.DIRECT_REMINDER_SOMETIME_AFTER_DUE_DATE
  }
  if (activeDunningEscalationTab === TodoUIDunningEscalationTabs.WARNING && nextActionKind === TodoItemActionKind.STRONG_LAST_REMINDER_AFTER_DUE_DATE) {
    return TodoItemActionKind.STRONG_LAST_REMINDER_AFTER_DUE_DATE
  }
  if (activeDunningEscalationTab === TodoUIDunningEscalationTabs.WARNING && nextActionKind === TodoItemActionKind.COLLECTION_LITIGATION) {
    return TodoItemActionKind.STRONG_LAST_REMINDER_AFTER_DUE_DATE
  }

  /** DEFINING ALL COLLECTION STEPS */
  if (activeDunningEscalationTab === TodoUIDunningEscalationTabs.LEGAL) {
    return TodoItemActionKind.COLLECTION_LITIGATION
  }
}

/**
 * ESCAPE A STRING TO BE USED IN A REGULAR EXPRESSION
 * @param text
 */
export const escapeRegExp = (text: string) => 
  text.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string


const vatPatterns: { [countryCode: string]: RegExp } = {
  AT: /^ATU\d{8}$/,
  BE: /^BE0\d{9}$/,
  BG: /^BG\d{9,10}$/,
  CY: /^CY\d{8}[A-Z]$/,
  CZ: /^CZ\d{8,10}$/,
  DE: /^DE\d{9}$/,
  DK: /^DK\d{8}$/,
  EE: /^EE\d{9}$/,
  EL: /^EL\d{9}$/,
  ES: /^ES[A-Z0-9]\d{7}[A-Z0-9]$/,
  FI: /^FI\d{8}$/,
  FR: /^FR[A-HJ-NP-Z0-9]{2}\d{9}$/,
  HR: /^HR\d{11}$/,
  HU: /^HU\d{8}$/,
  IE: /^IE\d[A-Z0-9+*]\d{5}[A-Z]$/,
  IT: /^IT\d{11}$/,
  LT: /^LT\d{9,12}$/,
  LU: /^LU\d{8}$/,
  LV: /^LV\d{11}$/,
  MT: /^MT\d{8}$/,
  NL: /^NL\d{9}B\d{2}$/,
  PL: /^PL\d{10}$/,
  PT: /^PT\d{9}$/,
  RO: /^RO\d{2,10}$/,
  SE: /^SE\d{12}$/,
  SI: /^SI\d{8}$/,
  SK: /^SK\d{10}$/
}

/**
 * CALCULATE VAT FRENCH KEY FROM SIREN
 * @param {string} siren 
 * @returns {string}
 */
const calculateFrenchVATKey = (siren: string): string => {
  const sirenNumber = parseInt(siren, 10)
  const key = (12 + 3 * (sirenNumber % 97)) % 97
  
  return key < 10 ? `0${key}` : key.toString()
}

/**
 * CHECK IF EUROPEAN COMPANY VAT CODE IS VALID (currently only french)
 * @param {string} countryCode 
 * @param {string} vat 
 * @param {string} siren 
 * @returns {boolean}
 */
export const isVAT = (countryCode: string, vat: string, siren?: string): boolean => {
  const pattern = vatPatterns[countryCode]

  if (pattern && pattern.test(vat)) {
    if (countryCode === 'FR') {
      const vatSiren = vat.slice(4, 13) // Extracting SIREN part for France
      if (vatSiren !== siren) return false

      const expectedKey = calculateFrenchVATKey(vatSiren)
      const actualKey = vat.slice(2, 4) // Extracting the key part of the VAT number
      
      return expectedKey === actualKey
    }
    
    return true
  }
  
  return false
}


/**
 * CHECK IF COMPANY APE/NAF CODE IS VALID (currently only french)
 * @param {string} countryCode 
 * @param {string} code
 * @returns {boolean}
 */
export const isAPE = (countryCode: string, code: string):boolean => {
  let rx

  switch (countryCode) {
    case 'FR' :
    default:
      rx = new RegExp(/^\d{2}\.\d{2}[A-Za-z]$/)
      break
  }

  return rx.test(code)
}

/**
 * LUHN VALIDATOR
 * @param {string} number 
 * @returns {boolean}
 */
const isLuhn = (number: string): boolean => {
  let sum = 0

  for (let i = 0; i < number.length; i++) {
    let digit = parseInt(number[i])

    if (i % 2 === (number.length % 2)) {
      digit *= 2

      if (digit > 9) {
        digit -= 9
      }
    }

    sum += digit
  }

  return sum % 10 === 0
}

/**
 * CHECK IF FRENCH COMPANY SIREN CODE IS VALID
 * @param {string} siren
 * @returns {boolean}
 */
export const isSIREN = (siren: string):boolean => {
  if (siren.length !== 9 || !/^\d{9}$/.test(siren)) return false

  return isLuhn(siren)
}

/**
 * CHECK IF FRENCH COMPANY SIRET CODE IS VALID
 * @param {string} siret 
 * @param {string} siren 
 * @returns {boolean}
 */
export const isSIRET = (siret: string, siren?: string):boolean => {
  if (siret.length !== 14) return false

  const rx = siren ? new RegExp(`^${siren}\\d{5}$`) : new RegExp(/^\d{14}$/)
  if (!rx.test(siret)) return false

  return isLuhn(siret)
}

<script lang="ts">
    import InvoiceLifeCycleStatus from '../../../order-to-cash-lib/components/InvoiceLifeCycleStatus.svelte'
    import { BusinessDocument } from '../../models/business-document'
    import { t } from '$core/lib/i18n/i18nextWrapper'
    import { formatCurrencyWithOptions } from '../../utils/number-formatting-utils.js'

    /** Export declaration */
    export let businessDocument: BusinessDocument
</script>

<div data-ut-component="view-invoice-key-info">
    <div class="px-12 sm:px-8">
        <InvoiceLifeCycleStatus
                businessDocument={businessDocument}
                invoiceId={businessDocument.businessDocumentId}/>
        <h1 class="mt-4 text-4xl font-bold">
            {#if businessDocument?.hasSpecificInstallment}
                {formatCurrencyWithOptions(businessDocument?.installmentResultIncludingTaxScaledValue, t('locales'), businessDocument?.currency, true, 'symbol')}
            {:else}
                {formatCurrencyWithOptions(businessDocument?.totalIncludingTaxScaledValue, t('locales'), businessDocument?.currency, true, 'symbol')}
            {/if}
        </h1>
    </div>
</div>

import { ExchangeDate } from '../../core-app/models/exchange-date'
import type { BankTransaction } from '../../cash-application-app/models/cash-application-declared-model'
import { NewAmountOfMoney } from '../../cash-application-app/models/amount-of-money-model'
import { JsonProperty } from '@paddls/ts-serializer'

// BBBBankAccount is described in https://docs.bridgeapi.io/reference/account-resource
export class BBBBankAccount {
  @JsonProperty('bank_id') bank_id: number
  @JsonProperty('bank_name') bank_name: string
  @JsonProperty('iban') iban: string
  // Account's unique identifier
  @JsonProperty('id') id: number
  // Representation of the account's item (https://docs.bridgeapi.io/reference/item-resource)
  // An item represents a connection to a bank account using a set of user credentials.
  // It contains one or more accounts which contain transactions.
  // Some fields are: id (Item's unique identifier), status (Item status codes are detailed on web page), bank_id (Bank's unique identifier), etc.
  @JsonProperty('item_id') item_id: number
  // Account's name taken from the bank's website
  @JsonProperty('name') name: string
  // Status is described in https://docs.bridgeapi.io/docs/items-status
  @JsonProperty('status') status: number
  // Detailed on the Items status page (https://docs.bridgeapi.io/docs/items-status)
  @JsonProperty('status_code_info') status_code_info: string
  // Detailed on the Items status page (https://docs.bridgeapi.io/docs/items-status)
  @JsonProperty('status_code_description') status_code_description: string
  // checking (Checking account), savings (Savings account), brokerage (Brokerage account), card (Credit or debit card)
  // loan (Loan), shared_saving_plan (French 'PEA', Plan d'Epargne en Actions), pending (A virtual account that contains all the pending transactions),
  // life_insurance (Life insurance), special (Temporary type. Set while the account is synchronizing), unknown (Unknown)
  @JsonProperty('type') type: string
  // Flag to indicate that the account is a business bank account
  @JsonProperty('is_pro') is_pro: number
  // Account's balance
  @JsonProperty('balance') balance: number
  // 3 letters ISO 4217 currency code
  @JsonProperty('currency_code') currency_code: number
  // Timestamp recording when the account was last refreshed
  @JsonProperty('updated_at') updated_at: Date
}

export class BBBTransaction {
  @JsonProperty('id') id: number
  @JsonProperty('clean_description') clean_description: string
  @JsonProperty('bank_description') bank_description: string
  @JsonProperty('amount') amount: number
  @JsonProperty('date') date: string
  @JsonProperty('updated_at') updated_at: string // RFC3339 with milliseconds, ready to work as argument of 'new Date(updated_at)'
  @JsonProperty('currency_code') currency_code: string
  @JsonProperty('account_id') account_id: number
  @JsonProperty('category_id') category_id: number
  @JsonProperty('is_deleted') is_deleted: boolean
  @JsonProperty('is_future') is_future: boolean
  @JsonProperty('show_client_side') show_client_side: boolean
}

export class BBBTransactionsResponse {
  @JsonProperty({ field: 'accounts', type: () => BBBBankAccount }) accounts: BBBBankAccount[]
  @JsonProperty('bbbUserId') bbbUserId: string
  @JsonProperty({ field: 'created', type: () => ExchangeDate }) created: ExchangeDate
  @JsonProperty('dundyUserId') dundyUserId: string
  @JsonProperty('id') id: string
  @JsonProperty('nextParams') nextParams: string
  @JsonProperty('ok') ok: boolean
  @JsonProperty({ field: 'transactions', type: () => BBBTransaction }) transactions: BBBTransaction[]
}

export function ConvertToBankTransaction(bbbTransaction: BBBTransaction): BankTransaction {
  return <BankTransaction>{
    amount: NewAmountOfMoney(bbbTransaction.amount, 2, bbbTransaction.currency_code),
    bankAccountId: '' + bbbTransaction.account_id + '',
    bankAccountName: '',
    bankId: '',
    bankName: '',
    cleanDescription: bbbTransaction.clean_description,
    extractDate: ExchangeDate.newDateRFC3339(bbbTransaction.updated_at),
    origin: 'BridgeByBankin',
    originVersion: 'BridgeByBankinV1m0',
    rawDescription: bbbTransaction.bank_description,
    transactionDate: ExchangeDate.newDate(new Date(bbbTransaction.date)),
    transactionId: bbbTransaction.id.toString()
  }
}
export enum BusinessDocumentState {
  CREDIT_NOTE = 'credit-note',
  CREDIT_NOTE_DRAFT = 'credit-note-draft',
  DRAFT = 'draft',
  INVOICE = 'invoice',
  INVOICE_DRAFT = 'invoice-draft',
  PENDING_VOIDED = 'pending-voided',
  PENDING_VOIDING = 'pending-voiding',
  PURCHASE_ORDER = 'purchase-order',
  PURCHASE_ORDER_DRAFT = 'purchase-order-draft',
  QUOTE = 'quote',
  QUOTE_DRAFT = 'quote-draft',
  VOIDED = 'voided',
  VOIDING = 'voiding',
  VOIDING_AND_LAST = 'voiding-and-last',
  VOIDING_AND_VOIDED = 'voiding-and-voided'
}
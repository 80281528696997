<svelte:options immutable={true}/>

<script lang="ts">
    import { createEventDispatcher, tick } from 'svelte'
    import type {
      ChangeCallback,
      BlurOptions
    } from './types/ContactsSearch'
    import type { Contact } from '../../../crm-app/models/contact'
    import { debounce } from '../../../core-app/util/function-utils'
    import Loader from '../../../core-app/lib/ui-kit/Loader.svelte'
    import { validateEmail } from '../../../core-app/services/validator'

    export let contacts: Contact[]
    export let value: Contact[] = []
    export let placeholder: string = 'To'
    export let single: boolean = false
    export let change: ChangeCallback | void
    export let show_dropdown: boolean = true
    export let dataCy: string = 'contacts-search-field'

    let selectedContacts: (Contact | { email: string })[] = []
    let term: string = ''
    let currentContactIndex = 0
    let loading = false
    let searchField: HTMLInputElement
    let contactsList: Contact[] = []
    let isOpen = false
    const dispatch = createEventDispatcher()

    /**
     * Initial state setup
     */
    const initialSetup = () => {
      if (Array.isArray(contacts)) {
        contactsList = contacts
      }
      if (Array.isArray(value)) {
        selectedContacts = value
      }
    }

    const customerContacts = (term: string): any[] => []

    /**
     * Init function to get contacts
     */
    const getContactsSetup = (term: string): void => {
      loading = true
      contactsList = []
      getContacts(term)
    }

    /**
     * Debounced function to get contacts
     */
    const getContacts = debounce(async (term: string) => {
      loading = true
      const getContactsFunc =
                typeof contacts === 'function' ? contacts : customerContacts
      try {
        const contactsResponse = getContactsFunc(term)
        if (Array.isArray(contactsResponse)) {
          contactsList = contactsResponse
        }

        loading = false
      } catch (error) {
        // TODO: add error handling
        loading = false
      }
    }, 350)

    /**
     * Removes a contact
     * @param email
     */
    const removeContact = (email: string) => {
      selectedContacts = selectedContacts.filter((c) => c.email !== email)
      focusSearch()
    }

    /**
     * Selects a contact
     * @param contact
     */
    const selectContact = (contact: Contact) => {
      if (single && selectedContacts.length === 1) {
        selectedContacts = [contact]
        isOpen = false

        return
      }
      if (!isSelected(contact.email)) {
        selectedContacts = [...selectedContacts, contact]
      }
      focusSearch()
    }

    /**
     * Focuses the search field
     * @param e
     */
    const focusSearch = (e?: MouseEvent) => {
      if (e?.target && searchField && (!single || !selectedContacts.length)) {
        const element = e.target as HTMLElement
        if (element.tagName !== 'INPUT') {
          // handles bug when input is clicked, the input is blurred
          searchField.focus()
        }
        isOpen = true
      }
    }

    $: if (searchField) {
      searchField.setAttribute('tabindex', '-1')
      searchField.focus()
      searchField.removeAttribute('tabindex')
    }

    /**
     * Blurs the search field
     * @param options
     */
    const blurSearch = (options: BlurOptions = {}) => {
      setTimeout(() => {
        if (options.addContact && !filteredContacts.length && term) {
          handleSubmit()
        }
        isOpen = false
        term = ''
        if (searchField) {
          searchField.blur()
        }
      }, options.blurIn || 250)
    }

    /**
     * Handles the keydown event
     * @param event
     */
    const handleKeydown = (event: KeyboardEvent) => {
      // Backspace
      focusSearch()

      if (event.key === 'Backspace' && selectedContacts.length && !term) {
        selectedContacts = selectedContacts.slice(0, selectedContacts.length - 1)
      }
      if (event.key === 'Tab') {
        handleSubmit()
      }
      // DownArrow
      if (event.key === 'ArrowDown' && contactsList.length) {
        if (currentContactIndex <= contactsList.length) {
          currentContactIndex += 1
        }
      }
      // UpArrow
      if (event.key === 'ArrowUp' && contactsList.length) {
        if (currentContactIndex !== 0) {
          currentContactIndex -= 1
        }
      }
      if (event.key === 'Escape' && contactsList.length) {
        blurSearch()
      }
    }
    const setActiveContact = (index: number) => {
      currentContactIndex = index
    }
    const handleSubmit = () => {
      if (single && selectedContacts.length === 1) {
        return
      }
      if (filteredContacts.length) {
        if (!isSelected(filteredContacts[currentContactIndex].email)) {
          selectedContacts = [
            ...selectedContacts,
            filteredContacts[currentContactIndex]
          ]
          currentContactIndex = 0
          blurSearch({ blurIn: 0 })
        }
      }
      if (!isSelected(term) && term) {
        if (validateEmail(term)) {
          selectedContacts = [...selectedContacts, { email: term }]
          currentContactIndex = 0
          blurSearch({ blurIn: 0 })
        }
      }
    }
    const isSelected = (email: string) => selectedContacts.map((c) => c.email).includes(email)

    $: if (selectedContacts && change) {
      tick().then(() => {
        dispatch('selectedContacts', selectedContacts)
        if (typeof change === 'function') {
          change(selectedContacts)
        }
      })
    }
    $: if (contacts || value) {
      initialSetup()
    }
    $: if (isOpen && typeof contacts === 'function') {
      getContactsSetup(term)
    }
    $: filteredContacts = contactsList.filter((item) => {
      const trm: string = term ? term.toLowerCase() : ''
      const name: string = item.firstName && item.lastName ? item.firstName.toLowerCase() + item.lastName.toLowerCase() : ''
      const email: string = item.email ? item.email.toLowerCase() : ''
      const includesName = name.includes(trm)
      const includesEmail = email.includes(trm)
      const matched = includesName || includesEmail

      return matched && !isSelected(item.email)
    })

    /*$:  console.log(contactsList, 'contactsList') */

    $: if (filteredContacts) {
      currentContactIndex = 0
    }

    /*$:  console.log(selectedContacts, 'selectedContacts') */


</script>

<div class="dropdown" data-cy={dataCy}>
    <div class="contacts-container"
         on:click={focusSearch}
         on:keydown={(e) => { if (e.key === 'Enter') {focusSearch} }}
    >
        <div class="contacts-results">
            <div class="contacts-placeholder">{placeholder}</div>
            <div class="contacts-results-inner">
                {#each selectedContacts as contact (contact.email)}
                    <div class="contact-item">
                        <span class="contact-item__name">
                          {#if contact.firstName && contact.lastName}
                              <strong>{contact.firstName} {contact.lastName}</strong>
                              {`<${contact.email}>`}
                          {:else}
                              {contact.email}
                          {/if}
                        </span>
                        <button
                                type="button"
                                name="term"
                                on:click={() => removeContact(contact.email)}
                        >&times;
                        </button>
                    </div>
                {/each}
            </div>
        </div>

        {#if (single && !selectedContacts.length) || !single}
            <form on:submit|preventDefault={handleSubmit} class="search-form" autocomplete="off">
                <input
                        data-cy="contacts-search-field"
                        type="text"
                        name="search"
                        autocomplete="off"
                        autocapitalize="off"
                        autocorrect="off"
                        class="search-field"
                        bind:this={searchField}
                        on:keydown={handleKeydown}
                        on:blur={() => {blurSearch({ addContact: true })}}
                        bind:value={term}/>
            </form>
        {/if}
    </div>
    {#if isOpen && show_dropdown}
        <div class="dropdown-content">
            {#if loading && !filteredContacts.length}
                <p class="dropdown-item">
                    <Loader/>
                </p>
            {:else if filteredContacts.length}
                {#each filteredContacts as contact, i}
                    <div class="dropdown-item"
                         class:active={currentContactIndex === i}
                         class:selected={isSelected(contact.email)}
                         on:mousedown={() => selectContact(contact)}
                         on:mouseenter={() => setActiveContact(i)}>
                        {#if contact.firstName && contact.lastName}
                            <div class="dropdown-item__name">{contact.firstName} {contact.lastName}</div>
                            <div class="dropdown-item__email">{contact.email}</div>
                        {:else}
                            <div class="dropdown-item__name">{contact.email}</div>
                        {/if}
                    </div>
                {/each}
            {/if}
        </div>
    {/if}

</div>

<style lang="postcss">
    :root {
        font-family: sans-serif;
    }

    .contacts-container {
        @apply focus:outline-4 focus:outline-blue-500 focus:outline-offset-2;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-bottom: 1px solid var(--border);
    }

    .contacts-container > div {
        padding-bottom: 0.1rem;
        padding-right: 0.1rem;
    }

    .contact-item {
        @apply inline-flex bg-whisper items-center rounded-md px-2 py-1 text-slate-500;
        margin-right: 0.25rem;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .contact-item > button {
        color: #000;
        border: none;
        background: none;
        padding: 2px 4px;
        font-weight: bold;
        font-size: 13px;
        cursor: pointer;
    }

    .contact-item__name {
        @apply text-black text-s pr-1;
    }

    .dropdown {
        width: 100%;
        position: relative;
        display: inline-block;
    }

    .dropdown-content {
        @apply text-slate-500 w-full bg-white border border-gray-200  rounded-md shadow-lg outline-none px-2 py-2;
        display: block;
        position: absolute;
        max-height: 350px;
        left: 0;
        right: 0;
        overflow-y: auto;
        z-index: 999999;
    }

    .dropdown-item {
        @apply text-black px-2 py-2 cursor-pointer text-s;
        padding: 8px;
        z-index: 9999;
    }

    .dropdown-item.active {
        @apply rounded-md bg-athensGray text-black;
    }

    .dropdown-item.selected {
        opacity: 0.3;
    }

    .dropdown-item__name {
        @apply inline-flex;
    }

    .dropdown-item__email {
        @apply inline-flex text-slate-500 ml-0.5;
    }

    .search-field {
        @apply bg-white text-black text-s min-w-full border-none;
    }

    .search-field:focus {
        width: 100px;
    }

    .search-form {
        flex-grow: 1;
        display: flex;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        align-items: center;
        min-width: 320px;
    }

    .contacts-results {
        display: flex;
        align-items: center;
    }

    .contacts-placeholder {
        @apply text-sm mr-4 flex text-black;
        min-width: 30px;
    }
</style>

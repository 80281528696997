<script lang="ts">
  import { createEventDispatcher } from 'svelte'

  export let current:number = 1
  export let count:Readonly<number> = 1

  const dispatch = createEventDispatcher()
  const step:number = 1

  $:previousDisabled = current === 1
  $:nextDisabled = current === count

  const onPrevious = () => {
    current -= step
    dispatch('previous', current)
  }

  const onNext = () => {
    current += step
    dispatch('next', current)
  }
</script>

<div class="navigation">
  <button class="previous" class:disabled={previousDisabled} on:click={onPrevious}/>
  <span>{current} / {count}</span>
  <button class="next" class:disabled={nextDisabled} on:click={onNext}/>
</div>

<style lang="postcss">
  .navigation {
    @apply flex items-center;

    span {
      @apply mx-2;
    }

    button {
      @apply p-1 cursor-pointer bg-black size-6;
      mask-repeat: no-repeat;

      &.previous {
        mask: url("/img/icons/previous.svg");
      }
      &.next {
        mask: url("/img/icons/next.svg");
      }

      &.disabled {
        @apply pointer-events-none bg-gray-300;
      }
    }
  }
</style>


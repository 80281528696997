import { authZeroJsAccessToken } from '../lib/auth0authentication/authStore'
import { actionWhenNotSignedIn, checkIfAuthenticationTokenIsExpired } from './auth.service'
import { feedbackService } from './feedback.service'
import { FeedbackEntity } from '../enums/feedback-enums'
import { t } from '../lib/i18n/i18nextWrapper'
import type { ApiError } from '../models/api-error'
import axios, { type AxiosRequestConfig, type AxiosResponse, type AxiosError } from 'axios'
import { eventsManager } from '../events/event-manager'
import { EventType } from '../events/event-type'
import { encodeFileToBase64 } from '../util/file-utils'
import { encrypt256Hash } from '../util/encryption'
import { get } from 'svelte/store'
import type ApiUploadArgs from '../../crm-app/models/api-upload-args'

export const dundyAPIBaseRootURL = process.env.API_BASE_URL + '/api'
export const bbbAPIBaseRootURL = process.env.BBB_BASE_URL
export const biFrInseeAPIBaseRootURL = process.env.BI_FR_INSEE_BASE_API_URL
export const biFrInpiAPIBaseRootURL = process.env.BI_FR_INPI_BASE_API_URL
export const totumCrmAPIBaseRootURL = process.env.TOTUM_CRM_BASE_API_URL
export const totumUserEventAPIBaseRootURL = process.env.TOTUM_USER_EVENT_BASE_API_URL
export const totumPreferenceAPIBaseRootURL = process.env.TOTUM_PREFERENCE_BASE_API_URL
export const utSendGridAPIBaseRootURL = process.env.UT_SENDGRID_BASE_API_URL
export const voxyInvoicingAPIBaseRootURL = process.env.VOXY_INVOICING_BASE_API_URL
export const fileManagementAPIBaseRootURL = process.env.FILE_MANAGEMENT_BASE_URL

let lastRequestHash: string = ''
let lastRequestDate: Date = new Date()

export type HumanReadableRequestOperation = 'create' | 'read' | 'update' | 'delete'

export enum APIEntity {
  INVOICES = 'INVOICES',
  TODOS = 'TODOS',
  HISTORY = 'HISTORY',
  PROFILE = 'PROFILE',
  WORKSPACE = 'WORKSPACE',
  CUSTOMERS = 'CUSTOMERS',
  CONTACTS = 'CONTACTS',
  BBB_BANK_CONNECTION = 'BBB_BANK_CONNECTION',
  EMAIL = 'EMAIL',
  INVOICE_FILE = 'INVOICE_FILE',
  VOXY_BUSINESS_DOCUMENTS = 'VOXY_BUSINESS_DOCUMENTS',
  CASH_APPLICATION = 'CASH_APPLICATION',
  BI_FR_INSEE = 'BI_FR_INSEE',
  BI_FR_INPI = 'BI_FR_INPI',
  TOTUM_CRM = 'TOTUM_CRM',
  TOTUM_USER_EVENT = 'TOTUM_USER_EVENT',
  TOTUM_PREFERENCE = 'TOTUM_PREFERENCE'
}

export function apiCallConfigToAPIBaseRootURL(apiCallConfig: APICallConfig): string {
  let url: string = ''
  switch (apiCallConfig.entity) {
    case APIEntity.INVOICES: url = dundyAPIBaseRootURL as string // old deprecated soon to be migrated
      break
    case APIEntity.TODOS: url = dundyAPIBaseRootURL as string // old deprecated soon to be migrated
      break
    case APIEntity.HISTORY: url = dundyAPIBaseRootURL as string // old deprecated soon to be migrated
      break
    case APIEntity.PROFILE: url = totumCrmAPIBaseRootURL as string
      break
    case APIEntity.WORKSPACE: url = totumCrmAPIBaseRootURL as string
      break
    case APIEntity.CUSTOMERS: url = totumCrmAPIBaseRootURL as string
      break
    case APIEntity.CONTACTS: url = totumCrmAPIBaseRootURL as string
      break
    case APIEntity.BBB_BANK_CONNECTION: url = bbbAPIBaseRootURL as string
      break
    case APIEntity.EMAIL: url = utSendGridAPIBaseRootURL as string
      break
    case APIEntity.INVOICE_FILE: url = fileManagementAPIBaseRootURL as string
      break
    case APIEntity.VOXY_BUSINESS_DOCUMENTS: url = voxyInvoicingAPIBaseRootURL as string
      break
    case APIEntity.CASH_APPLICATION: url = dundyAPIBaseRootURL as string // old deprecated soon to be migrated
      break
    case APIEntity.BI_FR_INSEE: url = biFrInseeAPIBaseRootURL as string
      break
    case APIEntity.BI_FR_INPI: url = biFrInpiAPIBaseRootURL as string
      break
    case APIEntity.TOTUM_CRM: url = totumCrmAPIBaseRootURL as string
      break
    case APIEntity.TOTUM_USER_EVENT: url = totumUserEventAPIBaseRootURL as string
      break
    case APIEntity.TOTUM_PREFERENCE: url = totumPreferenceAPIBaseRootURL as string
      break
    default:
      url = "unknown" // dundyAPIBaseRootURL
      console.error('APIBaseRootURL for request: unknown apiCallConfig.entity with value= ' + apiCallConfig.entity, ' to build request: ', apiCallConfigToAPIEndpointURLWithoutAPIBaseRootUrl(apiCallConfig))
      throw new Error('APIBaseRootURL for request: unknown apiCallConfig.entity with value= ' + apiCallConfig.entity + ' to build request: ' + apiCallConfigToAPIEndpointURLWithoutAPIBaseRootUrl(apiCallConfig))
      break
  }
  return url
}

export class APICallOptions {
  entity: APIEntity
  ignoreFeedback?: boolean
  dontRedirectToSignInOnUnAuthorized?: boolean
  overrideContentType?: string
  file?: File
  isB64?: boolean
  invoiceDataId?: string
  invoiceNumber?: string
}

class APICallConfig extends APICallOptions {
  method: string
  path: string
  body?: any
}

export class APIUploadCallOptions {
  entity: APIEntity
  ignoreFeedback?: boolean
  formItems?: Map<string, any>
}

class APIUploadCallConfig extends APIUploadCallOptions {
  method: string
  path: string
  body?: any
}

const isJson = (apiEntity: APIEntity): boolean => true

const populateFeedback = (operation: HumanReadableRequestOperation, entity: APIEntity, errorMessage: string, status: number) => {
  feedbackService.populateFeedbackWithErrorMessageAndStatus(
    FeedbackEntity[APIEntity[entity]] || FeedbackEntity.GENERAL,
    t('feedback.apiError', {
      action: t(`feedback.action.${operation}`),
      entity: t(`entities.${entity.toLowerCase()}`),
      errorMessage
    }),
    status)
}

function uploadFile(apiCallConfig: APICallConfig) {
  return new Promise<any>(async (resolve, reject) => {

    const bodyFormData = new FormData()
    bodyFormData.append('invoiceFileName', apiCallConfig.file.name)
    bodyFormData.append('invoiceFileType', apiCallConfig.file.type)
    bodyFormData.append('invoiceDataId', apiCallConfig.invoiceDataId)
    bodyFormData.append('invoiceNumber', apiCallConfig.invoiceNumber)
    if (apiCallConfig.isB64) {
      bodyFormData.append('invoiceAttachmentBase64', await encodeFileToBase64(apiCallConfig.file))
      bodyFormData.append('base64Encoded', 'true')
    } else {
      bodyFormData.append('invoiceAttachment', apiCallConfig.file, apiCallConfig.file.name)

    }

    const url: string = apiCallConfigToFullAPIURL(apiCallConfig)

    axios({
      method: 'post',
      url: url,
      data: bodyFormData,
      timeout: 5 * 60 * 1000, // 5*60*1000 = 5 minutes timeout for slow upload or big files
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + get(authZeroJsAccessToken)
      },
      onUploadProgress: function (progressEvent) {
        /* console.log('onUploadProgress', progressEvent) */
        eventsManager.emit(
          EventType.FILE_UPLOAD_PROGRESS_CHANGED,
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
          'APIService',
        )
      }
    })
      .then(function (res) {
        resolve(res.data)
      })
      .catch(function (err) {
        reject(err.message)
        if (!apiCallConfig.ignoreFeedback) {
          feedbackService.populateFeedbackWithErrorMessageAndStatus(FeedbackEntity.GENERAL, err.message, err.status)
        }
      })
  })
}

function uploadFileV2(apiCallConfig: APIUploadCallConfig) {
  return new Promise<any>((resolve, reject) => {

    /* console.log('+*!!! uploadFileV2 start') */
    const bodyFormData = new FormData()
    if (!!apiCallConfig && !!apiCallConfig.formItems) { // formItems is the dictionary to be sent to the api
      apiCallConfig.formItems.forEach((value: ApiUploadArgs, key: string) => {
        if (value.isFormValue) {
          /* console.log('adding', value.contents) */
          bodyFormData.append(key, value.contents)
        }
        if (value.isFileValue) {
          /* console.log('adding f', value.contents) */
          // https://stackoverflow.com/questions/40869679/how-to-convert-string-to-file-object-in-javascript
          const blob = new Blob([value.contents], { type: apiCallConfig.formItems.get('companyLogoMIMEContentType').contents })
          const file = new File([blob], apiCallConfig.formItems.get('companyLogoFileName').contents, { type: apiCallConfig.formItems.get('companyLogoMIMEContentType').contents })
          bodyFormData.append(key, file, apiCallConfig.formItems.get('companyLogoFileName').contents)
        }
      })
    }
    /* console.log('+*!!! uploadFileV2 next') */

    const url: string = apiCallConfigToFullAPIURL(apiCallConfig)

    axios({
      method: 'post',
      url: url,
      data: bodyFormData,
      timeout: 5 * 60 * 1000, // 5*60*1000 = 5 minutes timeout for slow upload or big files
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + get(authZeroJsAccessToken)
      },
      onUploadProgress: function (progressEvent) {
        /* console.log('onUploadProgress', progressEvent) */
        eventsManager.emit(
          EventType.FILE_UPLOAD_PROGRESS_CHANGED,
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
          'APIService',
        )
      }
    })
      .then(function (res) {
        resolve(res.data)
      })
      .catch(function (err) {
        reject(err.message)
        if (!apiCallConfig.ignoreFeedback) {
          feedbackService.populateFeedbackWithErrorMessageAndStatus(FeedbackEntity.GENERAL, err.message, err.status)
        }
      })
  })
}

function getHumanReadableOperationFromHttpVerb(apiCallConfig: APICallConfig): HumanReadableRequestOperation {
  let operation: HumanReadableRequestOperation
  switch (apiCallConfig.method) {
    case 'get':
      operation = 'read'
      break
    case 'put':
      operation = 'update'
      break
    case 'post':
      operation = 'create'
      break
    case 'delete':
      operation = 'delete'
      break
    default:
      console.error('APIBaseRootURL for request: unknown apiCallConfig.method with value= ' + apiCallConfig.method, ' to build request: ', apiCallConfigToAPIEndpointURLWithoutAPIBaseRootUrl(apiCallConfig))
      throw new Error('APIBaseRootURL for request: unknown apiCallConfig.method with value= ' + apiCallConfig.method + ' to build request: ' + apiCallConfigToAPIEndpointURLWithoutAPIBaseRootUrl(apiCallConfig))
      break
  }
  return operation
}

function performCall<T>(apiCallConfig: APICallConfig) {

  /* console.log('%c Performing Call to API', 'font-size: 1.2em; color: #606F82;', apiCallConfig) */
  console.log("performCall","config",apiCallConfig)

  const url: string = apiCallConfigToFullAPIURL(apiCallConfig)

  const config = <AxiosRequestConfig>{
    url,
    method: apiCallConfig.method,
    headers: {
      Authorization: 'Bearer ' + get(authZeroJsAccessToken)
    },
    data: apiCallConfig.body,
    responseType: isJson(apiCallConfig.entity) ? 'json' : 'text'
  }

  if (apiCallConfig.overrideContentType) {
    config.headers['Content-Type'] = apiCallConfig.overrideContentType
  }

  return new Promise<T>((resolve, reject) => {

    const currentRequestHash = encrypt256Hash(JSON.stringify(apiCallConfig))
    if (lastRequestHash === currentRequestHash && (new Date() < new Date(lastRequestDate.getTime() + 1000))) {
      /*reject('Duplicated request.');*/
      /* console.log('This request is duplicated.', JSON.stringify(apiCallConfig, null, '   ')) */
      // return;
    }

    lastRequestHash = currentRequestHash
    lastRequestDate = new Date()

    if (checkIfAuthenticationTokenIsExpired()) {
      actionWhenNotSignedIn()
      reject(<any>'TOKEN_EXPIRED')
      
      return
    }

    axios(config)
      .then((response: AxiosResponse<any, any>) => {
        resolve(<T>response.data)
      })
      .catch((error) => {
        let operation: HumanReadableRequestOperation = getHumanReadableOperationFromHttpVerb(apiCallConfig)
        if (error && error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 401 && !apiCallConfig.dontRedirectToSignInOnUnAuthorized) {
            actionWhenNotSignedIn()
          } else if (error.response.status === 404) {
            console.log("request err 404: ",operation, apiCallConfig.entity, "response status: "+error.response.status, error.response.data?.error ||
              error.response.data?.message || error.response.statusText, error.response.status)
          } else {
            console.error('response error', error)
            if (!apiCallConfig.ignoreFeedback) {
              populateFeedback(operation, apiCallConfig.entity, error.response.data?.error ||
                              error.response.data?.message || error.response.statusText, error.response.status)
            }
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser
          if (!apiCallConfig.ignoreFeedback) {
            populateFeedback(operation, apiCallConfig.entity, error.request.statusText, error.request.status)
          }
        } else {
          // Something happened in setting up the request that triggered an Error
          if (!apiCallConfig.ignoreFeedback) {
            populateFeedback(operation, apiCallConfig.entity, error.message, 0)
          }
        }
        reject(<ApiError>{
          message: error.response?.statusText || error.request?.statusMessage || error.message,
          status: error.response?.status || error.request?.status || 0,
          response: error.response || error.request || {}
        })
      })
  })
}

export function apiCallConfigToAPIEndpointURLWithoutAPIBaseRootUrl(apiCallConfig: APICallConfig): string {
  const prefix: string = apiCallConfig.path.startsWith('/') ? '' : '/'
  return `${prefix}${apiCallConfig.path}`
}

export function apiCallConfigToFullAPIURL(apiCallConfig: APICallConfig): string {
  const apiEndpointURLWithoutAPIBaseRootUrl: string = apiCallConfigToAPIEndpointURLWithoutAPIBaseRootUrl(apiCallConfig)
  const apiBaseRootUrl: string = apiCallConfigToAPIBaseRootURL(apiCallConfig)
  return `${apiBaseRootUrl}${apiEndpointURLWithoutAPIBaseRootUrl}`
}

export async function apiGet<T>(path: string, options?: APICallOptions): Promise<T> {
  const config = <APICallConfig>{ ...options, path, method: 'get' }

  return performCall<T>(config).then(data => 
  // if (classType) {
  //   const res = deserialize(data as string, classType)

    //   return res as T
    // } else {
    data
    // }
  )
}

export async function apiPost<T>(path: string, body: any, options?: APICallOptions): Promise<T> {
  const config = <APICallConfig>{ ...options, path, body, method: 'post' }
  
  return performCall<T>(config)
}

export async function apiPut<T>(path: string, body: any, options?: APICallOptions): Promise<T> {
  const config = <APICallConfig>{ ...options, path, body, method: 'put' }
  
  return performCall<T>(config)
}

export async function apiDelete<T>(path: string, body: any, options?: APICallOptions): Promise<T> {
  const config = <APICallConfig>{ ...options, path, body, method: 'delete' }

  return performCall<T>(config)
}

export async function apiUpload<T>(path: string, body: any, options?: APICallOptions): Promise<T> {
  const config = <APICallConfig>{ ...options, path, body, method: 'post' }
  
  return uploadFile(config)
}

export async function apiUploadV2<T>(path: string, body: any, options?: APIUploadCallOptions): Promise<T> {
  const config = <APIUploadCallConfig>{ ...options, path, body, method: 'post' }
  
  return uploadFileV2(config)
}

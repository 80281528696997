import { InvoicingBalanceLimits } from '../../models/invoicing-balance-limits.model'
import { Decimal } from 'decimal.js'

export function getUpToDateInvoicingBalanceLimits(newTotalIncludingTaxScaledValue: number, newSumOfInstallmentAlreadyFinalizedIncludingTaxScaledValue: number, newInstallmentCurrentlyDraftedIncludingTaxScaledValue: number): InvoicingBalanceLimits {
  // calculate new limits for the remaining amount to invoice on current deal
  const newDealTotalAmountToInvoiceScaledValueIncludingTaxes: Decimal =
        (new Decimal(newTotalIncludingTaxScaledValue))
  const newDealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesOnlyFinalized: Decimal =
        (new Decimal(newTotalIncludingTaxScaledValue))
          .sub(new Decimal(newSumOfInstallmentAlreadyFinalizedIncludingTaxScaledValue))
  const newDealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesIncludingCurrentDraft: Decimal =
        (new Decimal(newTotalIncludingTaxScaledValue))
          .sub(new Decimal(newSumOfInstallmentAlreadyFinalizedIncludingTaxScaledValue))
          .sub(new Decimal(newInstallmentCurrentlyDraftedIncludingTaxScaledValue))
  const newDealInvoicedAmountScaledValueDraftedOnly: Decimal = new Decimal(newInstallmentCurrentlyDraftedIncludingTaxScaledValue)
  /* console.log('invoicingBalanceLimits newDealTotalAmountToInvoiceScaledValueIncludingTaxes', newDealTotalAmountToInvoiceScaledValueIncludingTaxes.toNumber()) */
  /* console.log('invoicingBalanceLimits newDealTotalAmountToInvoiceScaledValueIncludingTaxes.abs()', newDealTotalAmountToInvoiceScaledValueIncludingTaxes.abs().toNumber()) */

  /* console.log('invoicingBalanceLimits newDealTotalAmountToInvoiceScaledValueIncludingTaxes.abs().lessThanOrEqualTo(0.001)', newDealTotalAmountToInvoiceScaledValueIncludingTaxes.abs().lessThanOrEqualTo(0.001)) */

  let newDealMaxRemainingPercentToInvoiceIncludingCurrentDraft: Decimal
  let newDealMaxRemainingPercentToInvoiceOnlyFinalized: Decimal
  let newDealInvoicedPercentDraftedOnly: Decimal
  if (newDealTotalAmountToInvoiceScaledValueIncludingTaxes.abs().greaterThan(0.001)) {
    newDealMaxRemainingPercentToInvoiceIncludingCurrentDraft =
            newDealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesIncludingCurrentDraft
              .div(newDealTotalAmountToInvoiceScaledValueIncludingTaxes)
              .mul(new Decimal(100))
    newDealMaxRemainingPercentToInvoiceOnlyFinalized =
            newDealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesOnlyFinalized
              .div(newDealTotalAmountToInvoiceScaledValueIncludingTaxes)
              .mul(new Decimal(100))
    newDealInvoicedPercentDraftedOnly =
            newDealInvoicedAmountScaledValueDraftedOnly
              .div(newDealTotalAmountToInvoiceScaledValueIncludingTaxes)
              .mul(new Decimal(100))
  } else {
    newDealMaxRemainingPercentToInvoiceIncludingCurrentDraft = new Decimal('99999999999999999')
    newDealMaxRemainingPercentToInvoiceOnlyFinalized = new Decimal('9999999999999999999')
    newDealInvoicedPercentDraftedOnly = new Decimal('9999999999999999999')
  }


  const newInvoicingBalanceLimits: InvoicingBalanceLimits = <InvoicingBalanceLimits>{
    dealTotalAmountToInvoiceScaledValueIncludingTaxes: newDealTotalAmountToInvoiceScaledValueIncludingTaxes,
    dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesIncludingCurrentDraft: newDealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesIncludingCurrentDraft,
    dealMaxRemainingPercentToInvoiceIncludingCurrentDraft: newDealMaxRemainingPercentToInvoiceIncludingCurrentDraft,
    dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesOnlyFinalized: newDealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesOnlyFinalized,
    dealMaxRemainingPercentToInvoiceOnlyFinalized: newDealMaxRemainingPercentToInvoiceOnlyFinalized,
    dealInvoicedAmountScaledValueDraftedOnly: newDealInvoicedAmountScaledValueDraftedOnly,
    dealInvoicedPercentDraftedOnly: newDealInvoicedPercentDraftedOnly,
    balanceInvoicingMetricsUpdated: true
  }
  /* console.warn('new invoicingBalanceLimits', JSON.stringify(newInvoicingBalanceLimits, null, 4)) */

  return newInvoicingBalanceLimits
}

import { JsonProperty } from '@paddls/ts-serializer'

/**
 * @deprecated use AmountDecimal class instead
 */
export class Amount {
  @JsonProperty('currencyCode') currencyCode: string
  @JsonProperty('scale') scale: number
  @JsonProperty('scaledValue') scaledValue: number
  @JsonProperty('unscaledValue') unscaledValue: number
  // get the float value of the amount of money
  //
  // scaledValue: float number representing the direct value in the currency (e.g. 1.25 for 1.25EUR)
  /**
     * @deprecated use GetAmountDecimalScaledValue() of AmountDecimal class instead
     */
  GetAmountScaledValue(): number {
    return this.scaledValue ?? 0
  }
  // converts the js prototype into the TypeScript class
  /**
     * @deprecated use PrototypeToClass() of AmountDecimal class instead
     */
  static PrototypeToClass(jsPrototype: Amount | AmountBasicObject): Amount {
    return <Amount>Object.assign(new Amount(), jsPrototype)
  }
  // scaledValue: float number representing the direct value in the currency (e.g. 1.25 for 1.25EUR)
  // scale: integer number representing the number of 0s, or otherwise the N power of 10 in 10^N (1 for 10, 2 for 100, 3 for 1000)
  // currencyCode: string of the currency code (REF: ISO 4217, e.g. https://www.iso.org/iso-4217-currency-codes.html or https://en.wikipedia.org/wiki/ISO_4217)
  // NB: unscaledValue in an integer number which, combined with the scale, must give the same result as scaledValue: e.g. unscaledValue is 3467 with a scale of 2 for a scaledValue of 34.67EUR (aka 3467/(10^2))
  /**
     * @deprecated use NewAmountOfMoneyWithDecimal() of AmountDecimal class instead
     */
  static NewAmountOfMoney(scaledValue: number, scale: number, currencyCode: string): Amount {
    return Amount.PrototypeToClass(<Amount>{
      currencyCode: currencyCode,
      scale: Math.round(scale),
      scaledValue: Math.round(scaledValue * Math.pow(10, Math.round(scale))) / Math.pow(10, Math.round(scale)),
      unscaledValue: Math.round(scaledValue * Math.pow(10, Math.round(scale)))
    })
  }

}

/**
 * @deprecated use AmountDecimalBasicObject class instead
 */
export class AmountBasicObject {
  @JsonProperty('currencyCode') currencyCode: string
  @JsonProperty('scale') scale: number
  @JsonProperty('scaledValue') scaledValue: number
  @JsonProperty('unscaledValue') unscaledValue: number
}

// scaledValue: float number representing the direct value in the currency (e.g. 1.25 for 1.25EUR)
// scale: integer number representing the number of 0s, or otherwise the N power of 10 in 10^N (1 for 10, 2 for 100, 3 for 1000)
// currencyCode: string of the currency code (REF: ISO 4217, e.g. https://www.iso.org/iso-4217-currency-codes.html or https://en.wikipedia.org/wiki/ISO_4217)
// NB: unscaledValue in an integer number which, combined with the scale, must give the same result as scaledValue: e.g. unscaledValue is 3467 with a scale of 2 for a scaledValue of 34.67EUR (aka 3467/(10^2))
/**
 * @deprecated use NewAmountOfMoneyWithDecimal() of AmountDecimal instead
 * @param scaledValue
 * @param scale
 * @param currencyCode
 * @constructor
 */
export function NewAmountOfMoney(scaledValue: number, scale: number, currencyCode: string): Amount {
  return Amount.NewAmountOfMoney(scaledValue, scale, currencyCode)
}


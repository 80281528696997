import type { BusinessDocument } from '../models/business-document'
import {
  BusinessDocumentAllDataPersisted,
  BusinessDocumentSource,
  CoreDocument,
  InstallmentComputationKind
} from '../models/business-document'
import type { Customer } from '../../crm-app/models/customer'
import type { Contact } from '../../crm-app/models/contact'
import { deepClone } from '../../core-app/util/object-deep-cloning.js'
import {
  roundScaledAmountToTheCentDecimal
} from './taxes-excluding-and-including-calculus-using-decimal-js.pure-functions'
import { Decimal } from 'decimal.js'
import { encrypt256Hash } from '../../core-app/util/encryption'
import { eventsManager } from '../../core-app/events/event-manager'
import { EventType } from '../../core-app/events/event-type'
import { ExchangeDate } from '../../core-app/models/exchange-date'
import {
  filterAllBusinessDocumentsWithBusinessDocumentId
} from './business-document-array-filters/business-documents-filters.service'


/**
 * Takes array of CoreDocument objects, and will return an array of businessDocument objects,
 * effectively stripping off the requesterId, workspaceId, and modifiedDate fields
 * @param coreDocuments
 */
export function extractBusinessDocumentsFromCoreDocumentsList(coreDocuments: BusinessDocumentAllDataPersisted[]): BusinessDocument[] {
  return coreDocuments?.map((doc: BusinessDocumentAllDataPersisted) => ({
    ...doc.coreDocument.businessDocument,
    collateralData: doc.collateralDocument.businessDocumentCollateralData
  }))
}

/**
 * Get the installment percentage for input init
 * @param businessDocument
 */
export const getInstallmentPercentageForInputInit = (businessDocument: BusinessDocument): number => {

  switch (businessDocument.installmentChosenKind) {
    case InstallmentComputationKind.PERCENT_OF_TOTAL_AMOUNT:
      return businessDocument.installmentChosenValue
    case InstallmentComputationKind.ABSOLUTE_AMOUNT_INCLUDING_TAX:
      const decimalInstallmentTotal: Decimal = new Decimal(businessDocument.installmentChosenValue)
      const decimalBusinessDocumentTotalIncludingTax: Decimal = new Decimal(businessDocument.totalIncludingTaxScaledValue)
      if (decimalBusinessDocumentTotalIncludingTax.abs().lessThanOrEqualTo(0.001)) {
      /* console.error('decimalBusinessDocumentTotalIncludingTax is zero, cannot div by 0') */

        return 9999999999
      }

      return roundScaledAmountToTheCentDecimal(decimalInstallmentTotal.div(decimalBusinessDocumentTotalIncludingTax).mul(new Decimal(100))).toNumber()
    default:
      return 0
  }
}

/**
 * Get the installment absolute value for input init
 * @param businessDocument
 */
export const getInstallmentAbsoluteValueForInputInit = (businessDocument: BusinessDocument): number => {

  switch (businessDocument.installmentChosenKind) {
    case InstallmentComputationKind.PERCENT_OF_TOTAL_AMOUNT:
      const decimalInstallmentPercent: Decimal = new Decimal(businessDocument.installmentChosenValue)
      const decimalBusinessDocumentTotalIncludingTax: Decimal = new Decimal(businessDocument.totalIncludingTaxScaledValue)

      return roundScaledAmountToTheCentDecimal(decimalBusinessDocumentTotalIncludingTax.mul(decimalInstallmentPercent).div(new Decimal(100))).toNumber()
    case InstallmentComputationKind.ABSOLUTE_AMOUNT_INCLUDING_TAX:
      return businessDocument.installmentChosenValue
    default:
      return 0
  }
}


/**
 * Find the matching document in the new document list
 * @param oldDocument
 * @param newDocumentList
 */
export function findMatchingDocument(oldDocument: BusinessDocument, newDocumentList: BusinessDocument[]): BusinessDocument | undefined {
  return newDocumentList.find(filterAllBusinessDocumentsWithBusinessDocumentId(oldDocument?.businessDocumentId))
}

/**
 * Emit a document changed event if the document has changed
 * compare the hash of the old and new document and emit an event if the hash has changed
 * @param oldDocument
 * @param newDocument
 */
export function emitDocumentChangedEvent(oldDocument: BusinessDocument, newDocument: BusinessDocument): void {
  const oldDocumentHash: string = encrypt256Hash(oldDocument)
  const newDocumentHash: string = encrypt256Hash(newDocument)

  if (oldDocumentHash !== newDocumentHash) {
    eventsManager.emit(
      EventType.BUSINESS_DOCUMENT_CHANGED,
      newDocument,
      'BusinessDocumentStore',
    )
  }
}

/**
 * Emit a property changed event if the property has changed
 * goes through all the properties of the document and emit an event if the property has changed
 * @param oldDocument
 * @param newDocument
 */
export function emitPropertyChangedEvents(oldDocument: BusinessDocument, newDocument: BusinessDocument): void {
  Object.keys(newDocument).forEach((k: string): void => {
    if (newDocument[k] !== oldDocument[k]) {
      eventsManager.emit(
        EventType.BUSINESS_DOCUMENT_PROPERTY_CHANGED,
        newDocument[k],
        'BusinessDocumentStore',
      )
    }
  })
}

/**
 * Emits an BUSINESS_DOCUMENT_DELETED if one or more documents are missing :
 * - the missingItems are those items in oldBusinessDocumentList that are not present in newBusinessDocumentList
 * - we map our missing items to their business document ids
 * - And we emit one event with the array of missing ids
 * @param oldBusinessDocumentList
 * @param newBusinessDocumentList
 */
export function emitBusinessDocumentsMissingEvents(oldBusinessDocumentList: BusinessDocumentAllDataPersisted[], newBusinessDocumentList: BusinessDocumentAllDataPersisted[]): void {
  type CustomDocument = CoreDocument & {
    savingStateExpiration: number
  }

  const oldList: CustomDocument[] = oldBusinessDocumentList.map((d: BusinessDocumentAllDataPersisted) => ({ ...d.coreDocument, savingStateExpiration: d.collateralDocument.businessDocumentCollateralData.savingStateExpiration }))
  const newList: CustomDocument[] = newBusinessDocumentList.map((d: BusinessDocumentAllDataPersisted) => ({ ...d.coreDocument, savingStateExpiration: d.collateralDocument.businessDocumentCollateralData.savingStateExpiration }))

  // Check missing documents
  const missingItems: any[] = oldList.filter((o: CustomDocument) => !newList.some((n: CustomDocument) => o.businessDocument.businessDocumentId === n.businessDocument.businessDocumentId))
  if (missingItems.length > 0) {
    // Check if missing documents come from VoxyInvoiceAddModal.svelte
    const unixDate: number = Math.round((new Date()).getTime() / 1000.0)
    const notNew: CustomDocument[] = missingItems.filter((d: CustomDocument) => !(d.savingStateExpiration && d.savingStateExpiration - unixDate > 0))

    // Only emit the event if there are TRUE missing
    if (notNew.length > 0) {
      const ids: string[] = missingItems.map((d: CustomDocument) => d.businessDocument.businessDocumentId)
      eventsManager.emit(EventType.BUSINESS_DOCUMENT_DELETED, ids, 'BusinessDocumentVariousFunction')
      /* console.log('missing items found - event fired') */
    } else {
      /* console.log('No missing items found') */
    }
  }
}

/***
 * Emits events when a BusinessDocument is changed
 * uses the oldBusinessDocumentList to find the matching document in the newBusinessDocumentList
 * @param oldBusinessDocumentList
 * @param newBusinessDocumentList
 */
export function emitBusinessDocumentsChangedEvents(oldBusinessDocumentList: BusinessDocument[], newBusinessDocumentList: BusinessDocument[]): void {
  oldBusinessDocumentList.forEach((oldBusinessDocument: BusinessDocument): void => {

    const newBusinessDocument: BusinessDocument = findMatchingDocument(oldBusinessDocument, newBusinessDocumentList)

    if (!!newBusinessDocument) {
      emitDocumentChangedEvent(
        oldBusinessDocument,
        newBusinessDocument,
      )
      emitPropertyChangedEvents(
        oldBusinessDocument,
        newBusinessDocument,
      )
    } else {
      /* console.log('emitBusinessDocumentsChangedEvents: could not find matching document') */
    }
  })
}

/**
 * Helper Function to get New Customer Account
 * @param customersStore
 * @param customerId
 */
export function getNewCustomerAccount(customersStore: Customer[], customerId: string): Customer {
  return customersStore.find((c: Customer): boolean => c.company.companyId === customerId)
}

/**
 * Helper Function to get New Contact Account
 * @param contactsStore
 * @param contactId
 */
export function getNewContactAccount(contactsStore: Contact[], contactId: string): Contact {
  return contactsStore.find((c: Contact): boolean => c.contactId === contactId)
}

/**
 * Helper Function to get New Business Document Source from an existing one
 * @param originalBusinessDocumentSource
 * @param now
 */
export function getNewBusinessDocumentSource(originalBusinessDocumentSource: BusinessDocumentSource, now: Date): BusinessDocumentSource {
  let newBusinessDocumentSource: BusinessDocumentSource = deepClone(originalBusinessDocumentSource)
  newBusinessDocumentSource.resourceURL = ''
  newBusinessDocumentSource.attachmentURLs = []
  newBusinessDocumentSource.lastSourceUpdated = ExchangeDate.newDate(now)

  return newBusinessDocumentSource
}

<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte'
  import { feedbackService } from '../../core-app/services/feedback.service'
  import { t } from '../../core-app/lib/i18n/i18nextWrapper'
  import { Feedback } from '../../core-app/models/feedback'
  import FullSizeModal from '../../core-app/lib/ui-kit/FullSizeModal.svelte'
  import type { Workspace } from '../models/workspace'
  import { WorkspaceHelper } from '../helpers/workspace-helper'
  import { WorkspaceListStore } from '../stores/workspace.store'
  import CompanyEdit from '../components/ui-kit/CompanyEdit.svelte'
  import UpdatedCompany from '../models/updated-company'
  import { workspacesService } from '../services/workspace.service'
  import {
    uploadCompanyLogoAndUpdateWorkspaceWithUpdatedCompany
  } from '../services/save-new-workspace-or-update-company-workspace'
  import FileUploadProgressBar from '../../core-app/lib/ui-kit/FileUploadProgressBar.svelte'
  import { deepClone } from '../../core-app/util/object-deep-cloning'
  import mixpanel from 'mixpanel-browser'


  const dispatch = createEventDispatcher()
  const close = () => {
    dispatch('close')
  }

  // let the user define a new company with its logo data
  // then we create and save a new empty workspace
  // then we put the company into it, save the resulting workspace update
  // and save the company logo
  function onSaveCompanyChanges(updatedCompany: CustomEvent<UpdatedCompany>) {
    const changedCompany: UpdatedCompany = updatedCompany.detail
    /* console.log('onSaveCompanyChanges !', changedCompany) */

    workspacesService.addNewWorkspaceWithCompany(changedCompany.company, 'NewWorkspaceAddModal onSaveCompanyChanges() create blank workspace first then change its company - no workspace provided')
      .then((newWorkspace: Workspace) => {
        // we now have a blank workspace but with a workspaceId
        // AS WELL AS a blank company inside BUT it has a granted companyId !!!!!!!
        const newUpdatedCompanyWithFreshCompanyFromBackEnd: UpdatedCompany = <UpdatedCompany>{
          company: newWorkspace.company,
          newCompanyLogo: changedCompany.newCompanyLogo
        }
        /* console.log('!!!= WorkspaceListStore before .update', get(WorkspaceListStore), get(WorkspaceListStore).length) */

        mixpanel.track('BM40 Created New Workspace', {
          'Description': 'Successfully workspacesService.addNewWorkspaceWithCompany',
          'newWorkspaceId': newWorkspace?.workspaceId,
          'newWorkspaceCompanyId': newWorkspace?.company?.companyId,
          'newWorkspaceCompany': newWorkspace?.company?.formalName
        })

        return uploadCompanyLogoAndUpdateWorkspaceWithUpdatedCompany(newUpdatedCompanyWithFreshCompanyFromBackEnd, newWorkspace.workspaceId)
      })
      .then((newWorkspaceBlankReadBackFromBackEndWithIdsAndCompanyContentPlusLogoURL: Workspace) => {
        /* console.log('!!!= let\'s persist the fully up-to-date new workspace -----------') */
        const newWorkspaceFullyUpToDate: Workspace = <Workspace>deepClone(newWorkspaceBlankReadBackFromBackEndWithIdsAndCompanyContentPlusLogoURL)
        /* console.log('updating WorkspaceListStore in NewWorkspaceAddModal.svelte') */
        WorkspaceListStore.update(oldWorkspaces => {
          /* console.log('!!!= WorkspaceListStore.update() from workspacesService.createAndSaveNewWorkspace in NewWorkspaceAddModal.svelte add blank workspace with added company', newWorkspaceFullyUpToDate) */
          let foundExisting: boolean = false
          let newListOfWorkspaces: Workspace[] = []
          for (let aWorkspace of oldWorkspaces) {
            if (aWorkspace.workspaceId === newWorkspaceFullyUpToDate.workspaceId) {
              newListOfWorkspaces.push(newWorkspaceFullyUpToDate)
              foundExisting = true
            } else {
              newListOfWorkspaces.push(aWorkspace)
            }
          }
          if (!foundExisting) {
            newListOfWorkspaces.push(newWorkspaceFullyUpToDate)
          }
          /* console.log('!!!= WorkspaceListStore in .update', newListOfWorkspaces, newListOfWorkspaces.length) */

          return newListOfWorkspaces
        })
        feedbackService.displayFeedback({
          feedbackMessage: t('editWorkspace.added'),
          feedbackLevel: 'Success'
        } as Feedback)
        dispatch('close')
      })
  }

  onMount(()=>{
    mixpanel.track('BM30 Add New Workspace Modal', {
      'Description': 'Open NewWorkspaceAddModal.svelte'
    })
  })

</script>

<FullSizeModal
        class="w-full overflow-y-auto h-full"
        isCloseable={true}
        on:close={close}>
    <div class="grid w-full h-full overflow-y-auto" slot="content">
        <div class="px-6 lg:w-6/12 xl:w-5/12 mx-auto">

            <div class="py-24 pb-36">
                <div>
                    <h2 class="text-black text-3xl font-bold">{t('workspaces.add')}</h2>
                    <p class="mt-2 text-sm">{t('workspaces.addSubtitle')}</p>
                </div>

                <FileUploadProgressBar titleTMessageEntry='settings.uploadLogo.uploadingLogo'/>

                <CompanyEdit
                        dataCy="cy-new-workspace-add-modal"
                        localCompanyInput={WorkspaceHelper.newCompany()}
                        needsAtLeastOneLegalIdentificationEntry={true}
                        on:saveCompanyChanges={onSaveCompanyChanges}
                        showFormButtons={true}
                        withLegalStructureInfoUI={true}
                        withLogoUI={true}
                        withTimeZoneInfoUI={true}
                />
            </div>
        </div>
    </div>
</FullSizeModal>

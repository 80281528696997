<script lang="ts">
  import { currentProfileLanguage, t } from '../../../core-app/lib/i18n/i18nextWrapper'
  import { createEventDispatcher, onMount } from 'svelte'
  import { fade } from 'svelte/transition'
  import TextInput from '../../../core-app/lib/ui-kit/TextInput.svelte'
  import StyledSelect from '../../../core-app/lib/ui-kit/StyledSelect.svelte'
  import type Company from '../../models/company'
  import { getCountries, getCountryNameForCountryCode } from '../../../core-app/services/countries-pure-functions'
  import { getTimezones } from '../../../core-app/services/timezones-list'
  import { doesLegalStructureRequireShareCapital } from '../../../core-app/services/companies-legal-structures'
  import { deepClone } from '../../../core-app/util/object-deep-cloning'
  import UpdatedCompany from '../../models/updated-company'
  import UpdatedFileUpload from '../../models/updated-file-upload'
  import CompanyLogo from './CompanyLogo.svelte'
  import { v4 as uuidv4 } from 'uuid'
  import NewCompanyLogo from '../../models/new-company-logo'
  import mixpanel from 'mixpanel-browser'
  import { MailAddress } from '../../models/company'
  import { stringCapitalize } from '$src/shared/utils/string'

  // localCompanyInput is taken as is in read-only. It is then deep cloned into the actually locally used/manipulated localCompany.
  export let localCompanyInput: Company = <Company>{ companyId: uuidv4() } // locally input company, not altered / read-only, cloned to localCompany (with default if none provided, hence new implied)
  export let withLogoUI: boolean = true
  export let showFormButtons: boolean = true
  export let withLegalStructureInfoUI: boolean = true
  export let withTimeZoneInfoUI: boolean = true
  export let needsAtLeastOneLegalIdentificationEntry: boolean = true
  /** Specify a name for the cypress selector */
  export let dataCy: string = ''

  /** Const & Var declarations **/
  let isDuringComponentInitializationPhase: boolean = true
  let localCompany: Company = <Company>{} // locally edited company, eventually a deep clone of localCompanyInput, cloned in onMount()
  let originalLocalCompany: Company // originalLocalCompany is a deep clone of the provided localCompanyInput, cloned in onMount()
  let isOriginalLocalCompanyBackedUp: boolean = false

  const countriesList = getCountries(currentProfileLanguage())
  const timezonesList = getTimezones()

  const dispatch = createEventDispatcher()

  let companyEmailLogoBase64: string
  let companyEmailLogoType: string
  let companyEmailLogoOriginalName: string
  let companyEmailLogoSizeBytes: number
  let companyEmailLogoLastModifiedUnixMilliseconds: number
  let triggerProgrammaticallyResetSelectedFile: boolean = false // defaults values, overridden by passed values

  let isShareCapitalVisible: boolean = false
  let localCompanyDataChangedSinceLastSaveOrLoad: boolean = false

  let inputFocus:any = null
  let closingDate:string = ''


  // formerly handleSelectChange()
  function handleCountrySelectionChange(e) {
    localCompany.mailAddress.countryCode = e.detail
    if (!isDuringComponentInitializationPhase) {
      /* console.log('+*!!! handleCountrySelectionChange') */
      localCompanyDataChangedSinceLastSaveOrLoad = true
    }
  }

  // formerly handleSelectChange()
  function handleTimeZoneSelectionChange() {
    if (!isDuringComponentInitializationPhase) {
      /* console.log('+*!!! handleTimeZoneSelectionChange') */
      localCompanyDataChangedSinceLastSaveOrLoad = true
    }
  }

  function handleInput() {
    if (!isDuringComponentInitializationPhase) {
      /* console.log('+*!!! handleInput') */
      localCompanyDataChangedSinceLastSaveOrLoad = true
    }
  }

  function handleSubmit() {
    // NB: we must leave the actual data update up to the caller of this component who has the right responsibility for that
    // NB: we do not want to assume that we are working on $WorkspaceStore.company, which is too specific
    /* console.log('dispatching event saveCompanyChanges from CompanyEdit') */
    dispatch('saveCompanyChanges', <UpdatedCompany>{
      company: localCompany,
      newCompanyLogo: <NewCompanyLogo>{
        logoBase64: companyEmailLogoBase64,
        logoMIMEContentType: companyEmailLogoType,
        logoFileName: companyEmailLogoOriginalName,
        logoFileSizeBytes: companyEmailLogoSizeBytes,
        logoFileLastModifiedUnixMilliseconds: companyEmailLogoLastModifiedUnixMilliseconds
      }
    })
    mixpanel.track('OA10 Save Temporary Company Changes', {
      'Description': 'Open CompanyEdit.svelte',
      'newTempCompanyId': localCompany?.companyId,
      'newTempCompany': localCompany?.formalName
    })
    localCompanyDataChangedSinceLastSaveOrLoad = false
  }

  function lockChangeDetectionLogic() {
    isDuringComponentInitializationPhase = true
  }

  function unlockChangeDetectionLogic() {
    setTimeout(() => {
      isDuringComponentInitializationPhase = false
    }, 250)
  }

  function cancelChanges() {
    // lock ui
    lockChangeDetectionLogic()
    // reset data
    localCompanyDataChangedSinceLastSaveOrLoad = false
    localCompany = deepClone(originalLocalCompany)
    companyEmailLogoBase64 = ''
    companyEmailLogoType = ''
    companyEmailLogoOriginalName = ''
    companyEmailLogoSizeBytes = 0
    companyEmailLogoLastModifiedUnixMilliseconds = 0
    triggerProgrammaticallyResetSelectedFile = true
    inputFocus = null
    // unlock ui
    unlockChangeDetectionLogic()
  }

  const newFileUpload = (updatedFileUpload: CustomEvent<UpdatedFileUpload>) => {
    /* console.log('*-+= newFileUpload', updatedFileUpload.detail) */
  }

  const newPostProcessedFileUpload = (updatedFileUpload: CustomEvent<UpdatedFileUpload>) => {
    /* console.log('*-+= newPostProcessedFileUpload', updatedFileUpload.detail) */
    companyEmailLogoBase64 = updatedFileUpload.detail.fileBase64
    companyEmailLogoType = updatedFileUpload.detail.fileMIMEContentType
    companyEmailLogoOriginalName = updatedFileUpload.detail.fileName
    companyEmailLogoSizeBytes = updatedFileUpload.detail.fileSizeBytes
    companyEmailLogoLastModifiedUnixMilliseconds = updatedFileUpload.detail.fileLastModifiedUnixMilliseconds
    handleInput()
  }

  const discardedFileUpload = (cancelledFileUpload: CustomEvent<UpdatedFileUpload>) => {
    /* console.log('*-+= discardedFileUpload', cancelledFileUpload) */
    companyEmailLogoBase64 = cancelledFileUpload.detail.fileBase64
    companyEmailLogoType = cancelledFileUpload.detail.fileMIMEContentType
    companyEmailLogoOriginalName = cancelledFileUpload.detail.fileName
    companyEmailLogoSizeBytes = cancelledFileUpload.detail.fileSizeBytes
    companyEmailLogoLastModifiedUnixMilliseconds = cancelledFileUpload.detail.fileLastModifiedUnixMilliseconds
  }

  onMount(() => {
    if (!!localCompanyInput && localCompanyInput.companyId !== '') {
      lockChangeDetectionLogic()
      localCompany = deepClone(localCompanyInput)
      originalLocalCompany = deepClone(localCompanyInput)

      unlockChangeDetectionLogic()
      /* console.log('*!!! localCompanyInput onmount', localCompanyInput) */
      /* console.log('*!!! localCompany onmount', localCompany) */
      /* console.log('*!!! originalLocalCompany onmount', originalLocalCompany) */
    } else {
      /* console.log('*!!! no localCompanyInput yet - onmount') */
    }
  })

  $: isShareCapitalVisible = doesLegalStructureRequireShareCapital(localCompany?.regulatory?.frRegulScope?.inseeData?.legalForm?.level2CatFr)

  $:canSubmit =
    !!localCompany &&
    !!localCompany.formalName &&
    !!(needsAtLeastOneLegalIdentificationEntry ? localCompany?.regulatory?.frRegulScope?.siren : true) &&
    !!(localCompany.mailAddress.street) &&
    !!(localCompany.mailAddress.city) &&
    !!(localCompany.mailAddress.zipCode) &&
    !!(localCompany.mailAddress.countryCode)
    && (localCompany.shareCapital && isShareCapitalVisible || !isShareCapitalVisible)

  $: {
    if (!!localCompany) {
      if (!localCompany.mailAddress) {
        localCompany.mailAddress = new MailAddress()
      }
      if (!localCompany.mailAddress.countryCode) {
        localCompany.mailAddress.countryCode = 'FR'
      }
    }

    // TODO extend to other countries whenever needed
    if (!!localCompany && !!localCompany.mailAddress && !!localCompany.mailAddress.countryCode && localCompany.mailAddress.countryCode === 'FR') {
      localCompany.timeZoneIANACode = 'Europe/Paris'
    }

    if (!isOriginalLocalCompanyBackedUp && !!localCompany && !!(localCompany.companyId) && localCompany.companyId !== '') {
      isOriginalLocalCompanyBackedUp = true
      originalLocalCompany = deepClone(localCompany)
      /* console.log('*!!! localCompany reactive', localCompany) */
      /* console.log('*!!! originalLocalCompany reactive', originalLocalCompany) */
    }

    let dte = new Date(localCompany?.regulatory?.frRegulScope?.inseeData?.regDateRaw)
    if (dte) closingDate = dte.getDay() + ' ' + stringCapitalize(dte.toLocaleString('default', { month: 'long' }))
  }
</script>

<div data-ut-component="company-new-edit">
    <form class="grid grid-cols-2 gap-6"
      data-cy="{dataCy + '-form'}"
      id="company-form"
      on:input={handleInput}
    >

    {#if withLogoUI}
      <fieldset class="mt-4 col-span-full">
        <h3 class="text-lg font-bold mt-2 block">{t('companyEdit.logoLabel')}</h3>
        <CompanyLogo
          existingFileURL={!!localCompany.emailLogoURL ? localCompany.emailLogoURL : 'https://static.thenounproject.com/png/1003548-200.png'}
          existingFileName={!!localCompany.emailLogoURL ? 'company logo' : 'choose a company logo'}
          existingFileSizeBytes={0}
          existingFileLastModifiedUnixMilliseconds={0}
          addBoundingDivs={true}
          triggerNewFileSelectModal={false}
          triggerProgrammaticallyResetSelectedFile={triggerProgrammaticallyResetSelectedFile}
          dataCy="{dataCy + '-company-logo'}"
          on:chosenNewFileUpload={newFileUpload}
          on:chosenNewFileUploadAfterProcessing={newPostProcessedFileUpload}
          on:discardedFileUpload={discardedFileUpload}
        />
      </fieldset>
    {/if}

    <div class="mt-4 col-span-full flex flex-col">
      <h3 class="text-lg font-bold mt-2 block mb-4">{t('companyEdit.generalInfo')}</h3>

      {#if localCompany?.usualName}
        <div class="flex flex-col text-sm mb-4">
          <span class="font-bold">{t('companyEdit.formalName')}</span>
          <span>{localCompany.usualName}</span>
        </div>
      {/if}

      <div class="flex flex-col text-sm mb-4">
        <span class="font-bold">{t('companyLegalIdentification.siren')}</span>
        <span>{localCompany?.regulatory?.frRegulScope?.siret}</span>
      </div>

      {#if localCompany?.regulatory?.euRegulScope?.euIntraVat}
        <div class="flex flex-col text-sm mb-4">
          <span class="font-bold">{t('companyLegalIdentification.europeanVAT')}</span>
          <span>{localCompany?.regulatory?.euRegulScope?.euIntraVat}</span>
        </div>
      {/if}

      {#if withLegalStructureInfoUI}
        <div class="flex flex-col text-sm mb-4">
          <span class="font-bold">{t('companyEdit.legalStructure')}</span>
          <span>{localCompany?.regulatory?.frRegulScope?.inseeData.legalForm.level3CatFr}</span>
        </div>

        {#if isShareCapitalVisible}
          <div class="flex flex-col text-sm mb-4">
            <span class="font-bold">{t('companyEdit.shareCapital')}</span>
            <span>{localCompany?.shareCapital}</span>
          </div>
        {/if}
      {/if}

      {#if localCompany?.mailAddress?.countryCode === 'FR'}
        <div class="flex flex-col text-sm mb-4">
          <span class="font-bold">{t('companyEdit.closingDate')}</span>
          {closingDate}
        </div>
      {/if}
    </div>

    <div class="col-span-full grid grid-cols-2 gap-6">
      <h3 class="text-lg font-bold mt-2 block">{t('companyEdit.companyAddress')}</h3>
      <TextInput
        bind:value={localCompany.mailAddress.street}
        class="col-span-full"
        dataCy="{dataCy + '-company-address-street'}"
        error={t('companyEdit.address.error')}
        id="street"
        label={t('companyEdit.address.label') + ' *'}
        on:focus={e => inputFocus = e.detail.srcElement.id}
        showError={inputFocus === 'street' && !localCompany.mailAddress.street}
      />

      <TextInput
        bind:value={localCompany.mailAddress.zipCode}
        dataCy="{dataCy + '-company-address-zip-code'}"
        error={t('companyEdit.zipCode.error')}
        id="zipcode"
        label={t('companyEdit.zipCode.label') + ' *'}
        on:focus={e => inputFocus = e.detail.srcElement.id}
        showError={inputFocus === 'zipcode' && !localCompany.mailAddress.zipCode}
        type="number"
      />

      <TextInput
        bind:value={localCompany.mailAddress.city}
        dataCy="{dataCy + '-company-address-city'}"
        error={t('companyEdit.city.error')}
        id="city"
        label={t('companyEdit.city.label') + ' *'}
        on:focus={e => inputFocus = e.detail.srcElement.id}
        pattern="[a-zA-Z -]"
        showError={inputFocus === 'city' && !localCompany.mailAddress.city}
      />

      <fieldset class="col-span-full">
        <b class="block text-sm font-normal text-black mb-1">{t('companyEdit.country')} *</b>
        <StyledSelect
          bind:value={localCompany.mailAddress.countryCode}
          dataCy="{dataCy + '-company-address-country-code'}"
          disabled={false}
          isSearchable={true}
          items={countriesList}
          label={getCountryNameForCountryCode(currentProfileLanguage(), localCompany.mailAddress.countryCode)}
          labelIdentifier='label'
          on:change={handleCountrySelectionChange}
          on:input={handleInput}
          optionIdentifier='value'
          placeholder={''}
          selected={''}
        />
      </fieldset>

      {#if withTimeZoneInfoUI}
        <fieldset class="col-span-full">
          <b class="block text-sm font-normal text-black mb-1">{t('companyEdit.timezone')} *</b>
          <StyledSelect
            bind:value={localCompany.timeZoneIANACode}
            disabled={false}
            isSearchable={true}
            items={timezonesList}
            placeholder={''}
            selected={''}
            label=""
            dataCy="{dataCy + '-company-address-time-zone-iana-code'}"
            on:change={handleTimeZoneSelectionChange}
            on:input={handleInput}
          />
        </fieldset>
      {/if}
    </div>

  </form>

  {#if showFormButtons}
    {#if localCompanyDataChangedSinceLastSaveOrLoad && !isDuringComponentInitializationPhase}
      <div class="fixed max-w-xl px-4 bg-white bg-gradient-to-t from-white flex bottom-0 w-full justify-end py-8 space-x-7" transition:fade>
        <button class="btn action-cancel" on:click={cancelChanges}>
            {t('companyEdit.cancel')}
        </button>

        <button
          data-cy="{dataCy + '-company-save-button'}"
          class="ml-6 inline-flex items-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 disabled:bg-white disabled:border-athensGray disabled:text-loblolly disabled:shadow-none disabled:cursor-not-allowed"
          on:click|preventDefault|stopPropagation={handleSubmit}
          disabled='{!canSubmit}'>
            {t('companyEdit.save')}
        </button>
      </div>
    {/if}
  {/if}
</div>

<style global lang="postcss">
  .cropper-modal {
    background-color: #fff !important;
  }

  .img-preview {
    @apply border border-black h-48 w-48;
  }
</style>

import { navigate } from 'svelte-routing'
import { get } from 'svelte/store'
import type { Customer } from '../../models/customer'
import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
import { DunningInvoicesStore } from '../../../dundy-app/stores/dunning-invoices.store'
import type Invoice from '../../../dundy-app/models/invoice'
import type { ICellRendererParams, IRowNode } from 'ag-grid-community'
import type { CellValueChangeItem } from '../../../dundy-app/models/cell-value-change-item'
import { eventsManager } from '../../../core-app/events/event-manager'
import { EventType } from '../../../core-app/events/event-type'
import { CustomersStore } from '../../stores/customers.store'


class SelectedCustomer {
  isFromWorkspaceListOfCustomers: boolean
  label: string
  companyId: string
  customer: Customer
  consideredEntry: string
}

export default class CustomerEditor {
  eGui: HTMLDivElement
  eInput: HTMLInputElement
  items: HTMLDivElement
  initParams: ICellRendererParams
  initRowNode: IRowNode
  initRowData: IRowNode
  hasTypedSomething: boolean = false
  consideredInputValue: string = ''
  rowId: number
  customers: Customer[] = get(CustomersStore)
  initialSelectedCustomer: Customer
  selectedCustomer: SelectedCustomer
  // gets called once before the renderer is used
  init(params: ICellRendererParams) {
    // input
    this.eInput = document.createElement('input')
    this.eInput.classList.add(
      'ag-input-field-input',
      'ag-text-field-input',
      'input',
    )

    // select
    this.items = document.createElement('div')
    this.items.classList.add('items-container')

    // init state
    this.initParams = params
    this.initRowData = JSON.parse(JSON.stringify(params.data))
    this.initRowNode = params.node
    this.rowId = params.rowIndex
    this.selectedCustomer = this.customerSelectionHasChangedThenUpdateSelectedCustomer(params.data.toName, params.data.toId)
    this.initialSelectedCustomer = (!!this.selectedCustomer) ? JSON.parse(JSON.stringify(this.selectedCustomer)) : null

    // prepare list
    this.buildCustomerList()

    // wrappers
    const wrapper: HTMLDivElement = document.createElement('div')
    wrapper.classList.add(
      'ag-wrapper',
      'ag-input-wrapper',
      'ag-text-field-input-wrapper',
      'relative',
      'h-full',
      'customer-editor',
    )
    wrapper.append(this.eInput, this.items)

    const cellEditor: HTMLDivElement = document.createElement('div')
    cellEditor.classList.add(
      'ag-cell-editor',
      'ag-text-field',
      'ag-input-field',
      'h-full',
    )
    cellEditor.append(wrapper)

    this.eGui = document.createElement('div')
    this.eGui.classList.add('ag-cell-edit-wrapper', 'h-full', 'w-60')
    this.eGui.append(cellEditor)

    /* console.log('input currently = ', this.eInput.value) */
    /* console.log('considered input currently = ', this.consideredInputValue) */

    document.addEventListener('input', () => {
      this.hasTypedSomething = true
      this.selectedCustomer = this.customerSelectionHasChangedThenUpdateSelectedCustomer(JSON.parse((JSON.stringify(this.eInput.value))), '')
      /* console.log('input currently = ', this.eInput.value) */
      /* console.log('selected customer = ', JSON.stringify(this.selectedCustomer, null, 4)) */
      this.buildCustomerList()
    })
  }
  getListOfPossibleItems(): SelectedCustomer[] {
    let byName: {} = {}
    get(DunningInvoicesStore).forEach((node: Invoice) => {
      if (!node.toId || node.toId === '') {
        if (node.toName && node.toName !== '') {
          byName[node.toName] = <SelectedCustomer>{
            isFromWorkspaceListOfCustomers: false,
            label: node.toName,
            customer: null,
            companyId: null
          }
        }
      }
    })
    this.customers
      .forEach((item: Customer) => {
        byName[item.company.formalName] = <SelectedCustomer>{
          isFromWorkspaceListOfCustomers: true,
          label: item.company.formalName,
          customer: item,
          companyId: item.company.companyId
        }
      })
    let resultingList: SelectedCustomer[] = <SelectedCustomer[]>[]
    for (let name of Object.keys(byName)) {
      resultingList.push(<SelectedCustomer>byName[name])
    }
    resultingList.sort((el1, el2): number => {
      if (el1.label.toLowerCase() < el2.label.toLowerCase()) return -1
      if (el1.label.toLowerCase() > el2.label.toLowerCase()) return 1
      
      return 0
    })
    
    return resultingList
  }
  /**
     * label can be '' or something new or corresponding to an existing customer in the workspace
     * if label is '' it means the 'still chosen' customer is the initial one
     * currentToID can be '' or undefined or null
     * if currentID is valid, it has priority over label
     * if label represents an existing toId, then toId will be changed accordingly
     * if label does not represent an existing toId, currentID will be set to ''
     * @param enteredLabel
     * @param enteredToID
     */
  customerSelectionHasChangedThenUpdateSelectedCustomer(enteredLabel: string, enteredToID: string): SelectedCustomer {
    let consideredEnteredLabel = !!enteredLabel ? JSON.parse(JSON.stringify(enteredLabel)) : ''
    // if enteredToID is supplied (different from ''), then it has priority, regardless of enteredLabel
    if (!!enteredToID && enteredToID !== '') {
      const foundCustomer = this.customers
        .find((c) => (enteredToID === c.company.companyId))
                || null
      if (!!foundCustomer) {
        consideredEnteredLabel = foundCustomer.company.formalName
        // update this.eInput.value if needed
        // LATER: check that no change trigger if this.eInput.value updated, otherwise it will be back in here
        if (this.eInput.value !== consideredEnteredLabel) {
          this.eInput.value = consideredEnteredLabel
        }
        const newSelectedCustomer = <SelectedCustomer>{
          isFromWorkspaceListOfCustomers: true,
          customer: JSON.parse(JSON.stringify(foundCustomer)),
          companyId: JSON.parse(JSON.stringify(foundCustomer.company.companyId)),
          label: JSON.parse(JSON.stringify(foundCustomer.company.formalName)),
          consideredEntry: JSON.parse(JSON.stringify(consideredEnteredLabel))
        }
        /* console.log('newSelectedCustomer 1 ', JSON.stringify(newSelectedCustomer, null, 4)) */
        
        return newSelectedCustomer
      } else {
        // the supplied toId is wrong, hence we only consider the toName (in the further executed code)
      }
    }
    let consideredInputValue: string
    if (this.hasTypedSomething) { // consideredEnteredLabel==='' means nothing and 'something' expects to look for Customer or suggest to create it
      consideredInputValue = consideredEnteredLabel
    } else {// consideredEnteredLabel==='' means initial initRowData['toName'] and 'something' expects to look for Customer or suggest to create it
      consideredInputValue = consideredEnteredLabel || this.initRowData.toName || ''
    }
    // update this.eInput.value if needed
    // LATER: check that no change trigger if this.eInput.value updated, otherwise it will be back in here
    if (this.eInput.value !== consideredInputValue) {
      this.eInput.value = consideredInputValue
    }
    let foundCustomer: Customer
    if (consideredInputValue !== '') {
      foundCustomer = this.customers
        .find((c: Customer): boolean => (consideredInputValue.toLowerCase() === c.company.formalName.toLowerCase()))
                || null
    } else {
      foundCustomer = null
    }
    
    return <SelectedCustomer>{
      isFromWorkspaceListOfCustomers: !!foundCustomer,
      customer: ((!!foundCustomer) ? JSON.parse(JSON.stringify(foundCustomer)) : null),
      companyId: ((!!foundCustomer) ? JSON.parse(JSON.stringify(foundCustomer.company.companyId)) : ''),
      label: ((!!foundCustomer) ? JSON.parse(JSON.stringify(foundCustomer.company.formalName)) : consideredInputValue),
      consideredEntry: consideredInputValue
    }
  }
  /**
     * Click on a customer in the list
     * select listed customer
     * @param customer
     */
  clickOnListedCustomer(customer: SelectedCustomer) {
    return (): boolean => {
      this.selectedCustomer = this.customerSelectionHasChangedThenUpdateSelectedCustomer(JSON.parse((JSON.stringify(customer.label))), JSON.parse((JSON.stringify(customer.companyId))))
      this.dismissCustomerDropdownList()
      
      return true
    }
  }
  /**
     * Dismiss the customer dropdown list
     */
  dismissCustomerDropdownList() {
    this.eInput.dispatchEvent(new KeyboardEvent('keydown', {
      altKey: false,
      bubbles: true,
      cancelable: true,
      charCode: 0,
      code: 'Enter',
      composed: true,
      ctrlKey: false,
      detail: 0,
      isComposing: false,
      key: 'Enter',
      keyCode: 13,
      location: 0,
      metaKey: false,
      repeat: false,
      shiftKey: false,
      which: 13
    }),
    )
  }
  /**
     * Click on the create new customer button
     * create new customer
     */
  clickOnCreateNewCustomer() {
    return () => {
      /* console.log('it is supposed to be saved before going out to the other page') */
      // important to clean up what we cannot know now
      this.initParams.data.toId = ''
      this.initParams.data.toName = this.selectedCustomer.consideredEntry
      // https://www.ag-grid.com/javascript-data-grid/grid-api/#reference-navigation-tabToNextCell
      this.initParams.api.tabToNextCell()
      // https://www.ag-grid.com/javascript-data-grid/cell-editing/#example-cell-editing
      // this.initParams.api.stopEditing(); // stop editing, save
      let gotoURL: string
      if (this.selectedCustomer.label !== '') {
        gotoURL = '/clients/new?companyLabel=' + encodeURIComponent('' + this.selectedCustomer.label)
      } else {
        gotoURL = '/clients/new'
      }
      this.eventuallySaveFieldToName()
      setTimeout(() => {
        navigate(gotoURL)
      }, 800)
    }
  }
  buildCustomerList() {
    this.items.innerHTML = ''
    // this.updateConsideredInputValues();
    this.getListOfPossibleItems()
      .filter((c: SelectedCustomer) =>
        (this.selectedCustomer.label === '')
                || (!this.hasTypedSomething)
                || (c.label.toLowerCase().includes((this.selectedCustomer.label).toLowerCase())),
      )
      .forEach((c: SelectedCustomer) => {
        const item: HTMLButtonElement = document.createElement('button')
        item.innerHTML = c.label
        item.onclick = this.clickOnListedCustomer(c)
        item.classList.add('item')
        if ((c.label.toLowerCase() === this.initRowData.toName.toLowerCase()) || (c.companyId === this.initRowData.toId)) {
          item.classList.add('item-selected')
        }
        if (!c.isFromWorkspaceListOfCustomers) {
          item.classList.add('item-to-create')
        }
        this.items.append(item)
      })

    const addIcon: HTMLElement = document.createElement('i')
    addIcon.classList.add('icon-plus_circle_outline')

    const createCustomer: HTMLButtonElement = document.createElement('button')
    createCustomer.classList.add('create-item')
    createCustomer.setAttribute('data-cy', 'create-customer-from-datatable-button')
    createCustomer.innerHTML = this.showCreateCustomerFromName() ?
      t('invoices.datatable.createSearchedCustomerOne') + (this.eInput.value || this.initRowData.toName) + t('invoices.datatable.createSearchedCustomerTwo')
      : t('invoices.datatable.createNewCustomer')
    createCustomer.prepend(addIcon)
    createCustomer.onclick = this.clickOnCreateNewCustomer()

    this.items.append(createCustomer)
  }
  showCreateCustomerFromName(): boolean {
    return ((!this.selectedCustomer.customer) || (!this.selectedCustomer.label))
            && (this.selectedCustomer.label !== '')
  }
  /**
     * Gets called once before editing starts, to give editor a chance to
     * cancel the editing before it even starts.
     */
  getGui(): HTMLDivElement {
    return this.eGui || this.createDefaultDiv()
  }
  /**
     * Returns a new div element with the text content -Company-.
     * this is how the cell will appear initially
     * @private
     */
  private createDefaultDiv(): HTMLDivElement {
    const defaultDiv: HTMLDivElement = document.createElement('div')
    defaultDiv.textContent = '-Company-'
    
    return defaultDiv
  }
  /**
     * Gets called once after GUI is attached to DOM.
     * focus and select can be done after the gui is attached
     */
  afterGuiAttached(): void {
    this.eInput.focus()
  }
  /**
     * If doing full line edit, then gets called when focus should be put into the editor
     */
  isCancelAfterEnd(): boolean {
    return this.selectedCustomer === undefined
  }
  /**
     * Save the field toName and toId
     */
  eventuallySaveFieldToName(): void {
    this.initParams.data.toName = this.selectedCustomer.label
    this.initParams.data.toId = this.selectedCustomer.companyId
    const rowIndex = JSON.parse(JSON.stringify(this.initParams.rowIndex))
    const rowData = JSON.parse(JSON.stringify(this.initRowData))
    const newLabel = JSON.parse(JSON.stringify(this.selectedCustomer.label))
    const newCompanyId = JSON.parse(JSON.stringify(this.selectedCustomer.companyId))
    const allChanges: CellValueChangeItem[] = [
            <CellValueChangeItem>{
              row: rowIndex,
              rowData: rowData,
              type: 'cellValueChanged',
              colId: 'toId',
              oldValue: rowData.toId,
              newValue: newCompanyId,
              api: this.initParams.api,
              node: this.initParams.node
            },
            <CellValueChangeItem>{
              row: rowIndex,
              rowData: rowData,
              type: 'cellValueChanged',
              colId: 'toName',
              oldValue: rowData.toName,
              newValue: newLabel,
              api: this.initParams.api,
              node: this.initParams.node
            }
    ]
    if (newCompanyId === '') {
      allChanges.push(<CellValueChangeItem>{
        row: rowIndex,
        rowData: rowData,
        type: 'cellValueChanged',
        colId: 'completed',
        oldValue: rowData.completed,
        newValue: false,
        api: this.initParams.api,
        node: this.initParams.node
      })
      // applying a business rule : if an invoice is un-complete/invalid, it should not be tracked
      allChanges.push(<CellValueChangeItem>{
        row: rowIndex,
        rowData: rowData,
        type: 'cellValueChanged',
        colId: 'isTracked',
        oldValue: rowData.isTracked,
        newValue: false,
        api: this.initParams.api,
        node: this.initParams.node
      })
    }
    // LATER not the right thing to do to program a future change in toName, it should be done in a more 'AgGrid' way
    
    // if (delayMilliSecsForToID > 0) {
    // setTimeout(() => {
    eventsManager.emit(EventType.CELL_VALUE_CHANGED, allChanges, 'DatatableCustomerEditor.svelte eventuallySaveFieldToName')
    // }, /*delayMilliSecsForToID || 100*/0);
    // }
    // if (delayMilliSecsForToName > 0) {
    //     setTimeout(() => {
    //         publishEventCellValueChanged('DatatableCustomerEditor.svelte eventuallySaveFieldToName',
    //             <EventCellValueChanged>{
    //                 changes: [
    //                     <CellValueChangeItem>{
    //                         row: rowIndex,
    //                         rowData: rowData,
    //                         type: 'cellValueChanged',
    //                         colId: 'toName',
    //                         oldValue: rowData['toName'],
    //                         newValue: newLabel,
    //                         api: this.initParams.api,
    //                         node: this.initParams.node,
    //                     },
    //                     <CellValueChangeItem>{
    //                         row: rowIndex,
    //                         rowData: rowData,
    //                         type: 'cellValueChanged',
    //                         colId: 'toName',
    //                         oldValue: rowData['toName'],
    //                         newValue: newLabel,
    //                         api: this.initParams.api,
    //                         node: this.initParams.node,
    //                     },
    //                 ]
    //             }
    //         );
    //     }, delayMilliSecsForToName || 100);
    // }
  }
  /**
     *  Returns the new value after editing
     */
  getValue(): string {
    // update another cell simultaneously with transactions
    const invoiceEditingNode: IRowNode = this.initParams.api.getRowNode(this.rowId.toString())
    const updatedInvoiceItem: Invoice = { ...invoiceEditingNode.data }
    updatedInvoiceItem.toId = this.selectedCustomer.companyId
    updatedInvoiceItem.toName = this.selectedCustomer.consideredEntry
    if (this.selectedCustomer.companyId === '') {
      updatedInvoiceItem.completed = false
      updatedInvoiceItem.isTracked = false
    }
    /* console.log('>>>> updating TO', JSON.stringify(updatedInvoiceItem, null, 4)) */
    // official ag-grid way to update data, and it triggers
    // const onCellValueChanged = (e: CellValueChangedEvent) in InvoicesDatatable.svelte
    this.initRowNode.setData(updatedInvoiceItem)
    this.initParams.api.refreshCells({
      force: false,
      suppressFlash: false
    })
    //
    this.eventuallySaveFieldToName()
    if (!!this.selectedCustomer.customer) {
      return this.selectedCustomer.companyId
    }
    
    return !!this.selectedCustomer ? this.selectedCustomer.companyId : ''
  }
  /**
     * if true, then this editor will appear in a popup
     */
  isPopup(): boolean {
    // and we could leave this method out also, false is the default
    return true
  }
  /**
     * Returns the Unicode code point of the first character in the key property of the event
     * @param event
     */
  getCharCodeFromEvent(event: KeyboardEvent): number {
    return event.key.charCodeAt(0)
  }
  /**
     * Returns true if the string passed is a number
     * @param charStr
     */
  isCharNumeric(charStr: string): boolean {
    return !!/\d/.test(charStr)
  }
  /**
     * Returns true if the key pressed is a number
     * @param event
     */
  isKeyPressedNumeric(event: KeyboardEvent): boolean {
    this.hasTypedSomething = true
    const charCode: number = this.getCharCodeFromEvent(event)
    const charStr: string = String.fromCharCode(charCode)
    
    return this.isCharNumeric(charStr)
  }
}

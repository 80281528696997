import { APICallOptions, apiDelete, APIEntity, apiPost, apiPut } from '../../core-app/services/api.service'
import { get } from 'svelte/store'
import { WorkspaceStore } from '../stores/workspace.store'
import { eventsManager } from '../../core-app/events/event-manager'
import { EventType } from '../../core-app/events/event-type'
import type { Contact } from '../models/contact'
import type { DundyEvent } from '../../dundy-app/events/dundy-event'
import { ApiOkStatusResponse } from '../../core-app/models/api-standard-responses'
import { ApiContactCreated } from '../models/contact'

class ContactsServiceClass {

  initialized: boolean = false
  initialize(): void {
    if (!this.initialized) {

      this.initialized = true

      eventsManager.on<Contact>(EventType.CONTACT_CREATED, (e: DundyEvent<Contact>) => {
        if (e.data) {
          this.saveNew(e.data)
            .then(() => {
              // handle successful save
            })
            .catch(error => {
              // handle error during save
              console.error(error)
            })
        }
      }, 'contactsService')

      eventsManager.on<Contact>(EventType.CONTACT_CHANGED, (e: DundyEvent<Contact>) => {
        if (e.data) {
          this.save(e.data)
            .then(() => {
              // handle successful save
            })
            .catch(error => {
              // handle error during save
              console.error(error)
            })
        }
      }, 'contactsService')

      eventsManager.on<Contact>(EventType.CONTACT_DELETED, (e: DundyEvent<Contact>) => {
        if (e.data) {
          this.delete(e.data)
            .then(() => {
              // handle successful save
            })
            .catch(error => {
              // handle error during save
              console.error(error)
            })
        }
      }, 'contactsService')
    }
  }
  private saveNew(contact: Contact): Promise<ApiContactCreated> {
    return apiPost(`/workspaces/${ get(WorkspaceStore).workspaceId }/contacts`, contact, <APICallOptions> {
      entity: APIEntity.CONTACTS
    })
  }
  private save(contact: Contact): Promise<ApiOkStatusResponse> {
    return apiPut(`/workspaces/${ get(WorkspaceStore).workspaceId }/contacts`, contact, <APICallOptions> {
      entity: APIEntity.CONTACTS
    })
  }
  private delete(contact: Contact): Promise<ApiOkStatusResponse> {
    return apiDelete(`/workspaces/${ get(WorkspaceStore).workspaceId }/contacts/${ contact.contactId }`, contact, <APICallOptions> {
      entity: APIEntity.CONTACTS
    })
  }
}

export const contactsService: ContactsServiceClass = new ContactsServiceClass()

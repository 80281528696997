<script lang="ts">
    import { t } from '../../lib/i18n/i18nextWrapper'
    import { navigate } from 'svelte-routing'
    import { onMount } from 'svelte'
    import { OnboardingStore } from '../../stores/onboarding.store'
    import mixpanel from 'mixpanel-browser'

    function handleClickContinue() {
      /* console.log('navigate to /today') */
      navigate('/today')
    }

    onMount(() => {
      mixpanel.track('CV10 Onboarding - End App Intro', {
        'Description': 'Open AppIntro.svelte'
      })
      $OnboardingStore.isOnboarding = false
    })
</script>

<main class="flex">
    <section class="flex-col w-3/12 justify-left relative max-w-lg p-10 pl-12 h-screen hidden md:block bg-gradient-to-t from-[#0d081e] to-[#152c3c] text-[#8CA3BE]">
        <div class="flex flex-col w-full text-left align-center">
            <span class="text-base block mt-16 font-semibold text-white">{t('onboarding.appIntro.sidebar.title')}</span>
            <div class="flex flex-col w-full -ml-2">
                <div class="flex mt-6 justify-start">
                    <i class="icon icon-done text-3xl relative bottom-1"></i>
                    <span class="text-sm">{t('onboarding.appIntro.sidebar.bullet1')}</span>
                </div>
                <div class="flex mt-6 justify-start">
                    <i class="icon icon-done text-3xl relative bottom-1"></i>
                    <span class="text-sm">{t('onboarding.appIntro.sidebar.bullet2')}</span>
                </div>
                <div class="flex mt-6 justify-start">
                    <i class="icon icon-done text-3xl relative bottom-1"></i>
                    <span class="text-sm">{t('onboarding.appIntro.sidebar.bullet3')}</span>
                </div>
            </div>
        </div>
    </section>
    <section class="flex w-8/12 2xl:w-9/12">
        <div class="flex flex-col w-10/12 2xl:w-8/12 max-w-md mx-auto">
            <span class="text-3xl font-bold mb-8 text-center">{t('onboarding.appIntro.main.title')}</span>
            <div class="flex mt-12 mb-20 mx-auto">
                <img src="/img/app-screenshot.svg" class="w-full"/>
            </div>
            <button data-cy="app-intro-button" class="btn action-bla primary animated w-8/12 mx-auto"
                    on:click={handleClickContinue}>
                {t('onboarding.appIntro.main.button')}
            </button>
        </div>
    </section>
</main>

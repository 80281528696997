/**
 * GET SCREEN AND WINDOW INFORMATIONS
 * @param dpi 
 * @returns 
 */
export const getScreenInfo = (dpi:number = 96) => {
  const pxToCm = (px: number) => {
    const cmPerInch = 2.54
    const inches = px / dpi
    const cm = inches * cmPerInch
    
    return Number(cm.toFixed(2))
  }

  const devicePixelRatio = window.devicePixelRatio || 1
  dpi = 96 * devicePixelRatio

  const screenWidthInPixels = window.screen.width
  const screenHeightInPixels = window.screen.height
  const screenWidthInCm = pxToCm(screenWidthInPixels)
  const screenHeightInCm = pxToCm(screenHeightInPixels)

  let screenDiagonalInPixels = Math.sqrt(Math.pow(screenWidthInPixels, 2) + Math.pow(screenHeightInPixels, 2))
  const screenDiagonalInInches = Number((screenDiagonalInPixels / dpi).toFixed(2))
  screenDiagonalInPixels = Number(screenDiagonalInPixels.toFixed(2))

  const windowWidth = window.innerWidth
  const windowHeight = window.innerHeight

  const orientation = screen?.orientation?.type ?? (window.innerWidth > window.innerHeight ? 'landscape' : 'portrait')

  return {
    dpi,
    screenWidthInPixels,
    screenHeightInPixels,
    screenWidthInCm,
    screenHeightInCm,
    screenDiagonalInPixels,
    screenDiagonalInInches,
    windowWidth,
    windowHeight,
    orientation
  }
}

/**
 * GET OS
 * @returns 
 */
export const getOsInfo = () => {
  const userAgent = window.navigator.userAgent

  if (/Windows/i.test(userAgent)) return 'Windows'
  if (/Mac/i.test(userAgent)) return 'MacOS'
  if (/Linux/i.test(userAgent)) return 'Linux'
  if (/iPhone|iPad|iPod/i.test(userAgent)) return 'iOS'
  if (/Android/i.test(userAgent)) return 'Android'
  if (/CrOS/i.test(userAgent)) return 'Chrome OS'

  return 'Unknown'
}

/**
 * GET DEVICE TYPE
 * @returns 
 */
export const getDeviceType = () => {
  const userAgent = window.navigator.userAgent

  if (/Mobi/.test(userAgent)) return 'Phone'
  if (/tablet|ipad|playbook|silk/i.test(userAgent)) return 'Tablet'
  if (/Mac|Windows|Linux/.test(userAgent)) return 'Desktop'

  return 'Unknown'
}

/**
 * GET BROWSER INFORMATIONS
 * @returns 
 */
export const getBrowserInfo = () => {
  const userAgent = navigator.userAgent
  let browserName: string = 'Unknown'
  let fullVersion: string = 'Unknown'

  if (userAgent.indexOf('Firefox') > -1) {
    browserName = 'Mozilla Firefox'
    fullVersion = userAgent.substring(userAgent.indexOf('Firefox') + 8)
  } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
    browserName = 'Opera'
    fullVersion = userAgent.indexOf('OPR') > -1 
      ? userAgent.substring(userAgent.indexOf('OPR') + 4) 
      : userAgent.substring(userAgent.indexOf('Opera') + 6)
  } else if (userAgent.indexOf('Trident') > -1) {
    browserName = 'Internet Explorer'
    fullVersion = userAgent.substring(userAgent.indexOf('rv:') + 3)
  } else if (userAgent.indexOf('Edge') > -1) {
    browserName = 'Microsoft Edge'
    fullVersion = userAgent.substring(userAgent.indexOf('Edge') + 5)
  } else if (userAgent.indexOf('Chrome') > -1) {
    browserName = 'Google Chrome'
    fullVersion = userAgent.substring(userAgent.indexOf('Chrome') + 7)
  } else if (userAgent.indexOf('Safari') > -1) {
    browserName = 'Apple Safari'
    fullVersion = userAgent.substring(userAgent.indexOf('Safari') + 7, userAgent.indexOf('Version'))
  }

  const version = fullVersion.split(' ')[0] //.split('.')[0] // only for major value

  return {
    name: browserName,
    version: version,
    fullVersion: fullVersion,
    language: navigator.language,
    cookieEnabled: navigator.cookieEnabled,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  }
}

/**
 * GET ALL CLIENT INFORMATIONS
 * @param {number} dpi 
 * @returns 
 */
export const getClientInfo = (dpi:number = 96) => ({
  browser: getBrowserInfo(),
  screen: getScreenInfo(dpi),
  os: getOsInfo(),
  device: getDeviceType()
})
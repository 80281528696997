import { htmlToText } from 'html-to-text'
import { currentProfileLanguage, t } from '../../../core-app/lib/i18n/i18nextWrapper'
import { getCountryNameForCountryCode } from '../../../core-app/services/countries-pure-functions'

const generateLetter = ({
  workspaceData,
  profileData,
  todo,
  customer,
  selectedContact,
  invoice,
  renderedSubjectMessage,
  renderedBodyMessage,
  dateNow
}) => {
  const letter = []


  letter.push(
    {
      columns: [
        {
          text: `${ workspaceData.company.formalName }`,
          bold: true,
          color: '#1F2533',
          alignment: 'left',
          fontSize: 10,
          lineHeight: 1.1
        }
      ]
    },
    {
      columns: [
        {
          text: `${ workspaceData.company.mailAddress.street }
                            ${ workspaceData.company.mailAddress.zipCode } ${ workspaceData.company.mailAddress.city }
                            ${ getCountryNameForCountryCode(currentProfileLanguage(), workspaceData.company.mailAddress.countryCode) }`,
          fontSize: 9,
          lineHeight: 1.1
        }
      ]
    },
  )

  letter.push(
    {
      columns: [
        {
        },
        {
          text: `${ customer.company.formalName }`,
          bold: true,
          color: '#1F2533',
          alignment: 'right',
          fontSize: 10,
          lineHeight: 1.1
        }
      ]
    },
    {
      columns: [
        {

        },
        {
          text: `${t('todoAction.actionKind.StrongLastReminderAfterDueDate.intendedFor')} ${ selectedContact.firstName } ${ selectedContact.lastName } 
                        ${ customer.company.mailAddress.street }
                        ${ customer.company.mailAddress.zipCode } ${ customer.company.mailAddress.city }
                        ${ getCountryNameForCountryCode(currentProfileLanguage(), customer.company.mailAddress.countryCode) } `,
          fontSize: 9,
          alignment: 'right',
          lineHeight: 1.1
        }
      ]
    },
  )

  letter.push({
    width: '100%',
    alignment: 'center',
    text: '',
    margin: [ 0, 10, 0, 10 ],
    fontSize: 15
  },
  '\n\n',
  {
    columns: [
      {
        text: `${ workspaceData.company.mailAddress.city }${t('todoAction.actionKind.StrongLastReminderAfterDueDate.letterOnTime')}${dateNow} `,
        fontSize: 9,
        alignment: 'right'
      }
    ]
  },
  '\n\n',
  )

  letter.push({
    width: '100%',
    alignment: 'center',
    text: '',
    margin: [ 0, 10, 0, 10 ],
    fontSize: 15
  },
  '\n\n',
  {
    columns: [
      {
        text: `${renderedSubjectMessage} `,
        bold: true,
        fontSize: 9,
        alignment: 'left'
      }
    ]
  },
  '\n\n',
  )

  letter.push({
    width: '100%',
    alignment: 'center',
    text: '',
    margin: [ 0, 10, 0, 10 ],
    fontSize: 15
  },
  '\n\n',
  {
    columns: [
      {
        text: htmlToText(renderedBodyMessage, {
          wordwrap: 130
        }),
        fontSize: 9,
        alignment: 'left',
        lineHeight: 1.1
      }
    ]
  },
  '\n\n',
  )

  return letter
}


export const pdfMakeInvoiceDefinition = ({
  workspaceData,
  profileData,
  todo,
  customer,
  selectedContact,
  invoice,
  renderedSubjectMessage,
  renderedBodyMessage,
  dateNow
}) => ({
  content: generateLetter({
    workspaceData,
    profileData,
    todo,
    customer,
    selectedContact,
    invoice,
    renderedSubjectMessage,
    renderedBodyMessage,
    dateNow
  }),
  footer: function (currentPage, pageCount) {
    const company = workspaceData.company
    
    return {
      canvas: [ { type: 'line', x1: 40, y1: 0, x2: 595 - 40, y2: 0, lineWidth: 1, color: 'black' } ],
      table: {
        headerRows: 1,
        widths: [340, '*'],
        body: [
          [
            {
              text: `${ workspaceData.company.formalName } - ${ company.regulatory.frRegulScope.siret }: ${ company.regulatory.euRegulScope.euIntraVat } - ${ workspaceData.company.mailAddress.street } - ${ workspaceData.company.mailAddress.zipCode } ${ workspaceData.company.mailAddress.city }
                                `,
              border: [false, true, false, false],
              alignment: 'left',
              color: '#1F2533',
              margin: [40, 5, 0, 0],
              fontSize: 7
            },
            /*{
                                image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAA7EAAAOxAGVKw4bAAADcElEQVRYhbXXXYhVVRQH8N8cZCg9RGT4MEhIRIlYRD5FH0RQvUhlYF8mEmeIokAoPeZD4UNB7CKILBJnQ1RED2WpoRXRB/nQ11NFgVTEGGYxiMSZQaZhpodzrl1nzjn3XqX/091nr/9a/732x1p3yICY3DaazM3NjWAFLqg+n8A4Q+NpGJsdxN9QP0ZFng3jVqzHjbiwwfQ4PsM72JOGePKsBFSBH8B2jPQjtgt/4VnsbBPSKKDIsyvxOlYPGHg+DmNTGuKXfQso8uxeRJzTpA8v40PM4FpsxrIG+xk8lIY41lNAkWej2N3gCH7DTWmIP8/jLcNBXNXCfSwN8flGAUWercVeJA0OTmJNGuKPdZNFno3ge//djjpsSEN8szM4FajIs4uUe94UnPJA1QaHNMSjeLqFD7uLPFu5QABewvkNpCkcw64ezuG1yrZomF+MXUWeJVRbUOTZDfi0gTCOy9MQ/+4j+CkUebYY32BVg8ltaYj7Ohn4ARvxRqX+NAGDBoc0xCn82mKyjZpbMJlnyRxX4GbcguuxE3/ggPIg3lGZ142ncTuW4lEsahFxWc+nuMizLcoXDe7HBPZX403KOrC3Gm9U7v27vfxW2Np24js43qezM8E1temZfPzBZG72n9W4C4/8jwJWn9qCuR07ksmpI6uqoHfi0i7DV5RnYJ/ySt5dfX9PeQa6x9MVf2klvi3LU0NFnnUHXVljdCgN8brBFlaiyLP9WNtmk+BzPNkQHJZXd3rQ4MPKpqUN04mybrdhBY5M5qP9HNhu/KR3KZ9IlPW6F2aX1LRaRZ4tKvKsqWT305odTvBVH4ZNWfoCvzSI6JVZ+DrBB2chYJWyVasrYv0IOJgM8R0aS2yFiT6cDcr5HYeSJSHO4oUexk2rWaesFycG4HTwYhriTOclfBVbcUmDce1q0hA/aQnwZ8vcUWVPWb5SaYjTePgMnTWhbQs2pyEWdJXKNMSPijx7DltqCAvSWeTZxcqqeB7W17TdTVswlob4dmcw/3HZjj01pLrV3Ke8BcuVLXk/nI/Ny/RpAtIQZ3AP3ppHrFvNuV2/h2vm53PeV7Zh040CKhHT2KDMxkyLgF7oZGAWT2Fd1aadhtr3PQ1xNg3xGaxRpm3gpqRayAFcnYb4RJXdBejr33Edqt6+c22PpSF+eyZ+/gV1fwtPK+U3hQAAAABJRU5ErkJggg==',
                                fit:[12,12],
                                border: [false, true, false, false],
                                alignment: 'left',
                                margin: [0, 5, 0, 0],
                            },*/
            {
              text: 'Page ' + currentPage.toString() + ' / ' + pageCount,
              border: [false, true, false, false],
              alignment: 'right',
              color: '#1F2533',
              margin: [0, 7, 40, 0],
              fontSize: 7
            }
          ]
        ]
      },
      layout: {
        defaultBorder: false,
        paddingLeft: function (i, node) {
          return 0
        },
        paddingRight: function (i, node) {
          return 0
        },
        paddingTop: function (i, node) {
          return 4
        },
        paddingBottom: function (i, node) {
          return 4
        },
        hLineColor: function (i, node) {
          if (i === 1 || i === 0) {
            return '#eaeaea'
          }

          return '#eaeaea'
        }
      }
    }
  },
  styles: {
    metaCell: {
      fontSize: 11,
      fillColor: '#f2f2f2'
    }
  }
})
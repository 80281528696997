import type { BusinessDocumentAllDataPersisted } from '../models/business-document'
import { voxyInvoicingService } from '../services/business-document-voxy/voxy.service'
import type { Workspace } from '../../crm-app/models/workspace'
import type { Customer } from '../../crm-app/models/customer'
import type { Contact } from '../../crm-app/models/contact'
import type { BusinessDocument, BusinessDocumentCollateralData } from '../models/business-document'
import { v4 as uuidv4 } from 'uuid'
import { ExchangeDate } from '../../core-app/models/exchange-date'
import { calculateBankWireReference } from './voxy-helper-pure-functions'
import type { BusinessDocumentRelation, BusinessDocumentSource } from '../models/business-document'
import { BusinessDocumentStatus } from '../enums/business-document-status'
import {
  getNewBusinessDocumentSource,
  getNewContactAccount,
  getNewCustomerAccount
} from '../services/business-document-various-functions'
import { DealDetails } from '../models/business-document'

/**
 * Helper Function to Call Duplicate Function
 * @param businessDocumentAllDataPersisted
 *
 */
export async function duplicateInvoiceData(businessDocumentAllDataPersisted: BusinessDocumentAllDataPersisted): Promise<BusinessDocumentAllDataPersisted> {
  return voxyInvoicingService.duplicateVoxyInvoice(businessDocumentAllDataPersisted)
}

/**
 * Duplicates the BusinessDocument and Returns Copy
 * @param originalBusinessDocumentAllDataPersisted - The original BusinessDocument to duplicate
 * @param workspaceStore - The current workspace store
 * @param customersStore - The current customers store
 * @param contactsStore - The current contacts store
 *
 * **/
export function transformationDuplicateBusinessDocument(
  originalBusinessDocumentAllDataPersisted: BusinessDocumentAllDataPersisted,
  workspaceStore: Workspace,
  customersStore: Customer[],
  contactsStore: Contact[],
): BusinessDocumentAllDataPersisted {

  const now: Date = new Date()
  const originalBusinessDocument: BusinessDocument = originalBusinessDocumentAllDataPersisted.coreDocument.businessDocument
  const originalCollateralData: BusinessDocumentCollateralData = originalBusinessDocumentAllDataPersisted.collateralDocument.businessDocumentCollateralData
  const newBusinessDocumentId: string = uuidv4()

  const newBusinessDocumentSource: BusinessDocumentSource = getNewBusinessDocumentSource(originalBusinessDocument.businessDocumentSource, now)
  const newCustomerAccount: Customer = getNewCustomerAccount(customersStore, originalBusinessDocument.customerCustomer.company.companyId)
  const newContactAccount: Contact = getNewContactAccount(contactsStore, originalBusinessDocument.customerContact.contactId)

  const newBusinessDocument: BusinessDocument = {
    ...originalBusinessDocument,
    accountBankingInformation: {
      ...originalBusinessDocument.accountBankingInformation,
      bankWireReference: calculateBankWireReference(10)
    },
    businessDocumentStatus: BusinessDocumentStatus.DRAFT,
    customerCustomer: newCustomerAccount,
    customerContact: newContactAccount,
    accountCompany: workspaceStore.company,
    businessDocumentId: newBusinessDocumentId,
    linkedDeal: <DealDetails>{
      dealId: uuidv4()
    },
    businessDocumentSource: newBusinessDocumentSource,
    businessDocumentNumber: '',
    relatedBusinessDocuments: <BusinessDocumentRelation[]>[],
    finalizedDate: null,
    deleted: false,
    deletedDate: null,
    createdDate: ExchangeDate.newDate(now),
    modifiedDate: ExchangeDate.newDate(now),
    issuedDate: originalBusinessDocument.issuedDate,
    timeZoneIANACode: Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  const newCollateralData: BusinessDocumentCollateralData = {
    ...originalCollateralData,
    businessDocumentId: newBusinessDocumentId,
    paymentInformation: {
      ...originalCollateralData.paymentInformation,
      paymentURL: ''
    }
  }

  return {
    collateralDocument: {
      businessDocumentCollateralData: newCollateralData,
      modifiedDate: ExchangeDate.newDate(now),
      requesterId: originalBusinessDocumentAllDataPersisted.coreDocument.requesterId,
      workspaceId: originalBusinessDocumentAllDataPersisted.coreDocument.workspaceId
    },
    coreDocument: {
      requesterId: originalBusinessDocumentAllDataPersisted.coreDocument.requesterId,
      workspaceId: originalBusinessDocumentAllDataPersisted.coreDocument.workspaceId,
      businessDocument: newBusinessDocument,
      modifiedDate: ExchangeDate.newDate(now)
    }
  }
}

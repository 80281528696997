// Ref: https://www.html-code-generator.com/javascript/json/country-names
// All countries
// length 252
export const countriesFr = {
  'AF': { 'name': 'L\'Afghanistan', 'symbol': '؋' },
  'AX': { 'name': 'Iles Aland', 'symbol': '€' },
  'AL': { 'name': 'Albanie', 'symbol': 'Lek' },
  'DZ': { 'name': 'Algérie', 'symbol': 'دج' },
  'AS': { 'name': 'Samoa américaines', 'symbol': '$' },
  'AD': { 'name': 'Andorre', 'symbol': '€' },
  'AO': { 'name': 'L\'Angola', 'symbol': 'Kz' },
  'AI': { 'name': 'Anguilla', 'symbol': '$' },
  'AQ': { 'name': 'Antarctique', 'symbol': '$' },
  'AG': { 'name': 'Antigua-et-Barbuda', 'symbol': '$' },
  'AR': { 'name': 'Argentine', 'symbol': '$' },
  'AM': { 'name': 'Arménie', 'symbol': '֏' },
  'AW': { 'name': 'Aruba', 'symbol': 'ƒ' },
  'AU': { 'name': 'Australie', 'symbol': '$' },
  'AT': { 'name': 'L\'Autriche', 'symbol': '€' },
  'AZ': { 'name': 'Azerbaïdjan', 'symbol': 'm' },
  'BS': { 'name': 'Bahamas', 'symbol': 'B$' },
  'BH': { 'name': 'Bahreïn', 'symbol': '.د.ب' },
  'BD': { 'name': 'Bangladesh', 'symbol': '৳' },
  'BB': { 'name': 'Barbade', 'symbol': 'Bds$' },
  'BY': { 'name': 'Biélorussie', 'symbol': 'Br' },
  'BE': { 'name': 'Belgique', 'symbol': '€' },
  'BZ': { 'name': 'Belize', 'symbol': '$' },
  'BJ': { 'name': 'Bénin', 'symbol': 'CFA' },
  'BM': { 'name': 'Bermudes', 'symbol': '$' },
  'BT': { 'name': 'Bhoutan', 'symbol': 'Nu.' },
  'BO': { 'name': 'Bolivie', 'symbol': 'Bs.' },
  'BQ': { 'name': 'Bonaire, Saint-Eustache et Saba', 'symbol': '$' },
  'BA': { 'name': 'Bosnie Herzégovine', 'symbol': 'KM' },
  'BW': { 'name': 'Botswana', 'symbol': 'P' },
  'BV': { 'name': 'Île Bouvet', 'symbol': 'kr' },
  'BR': { 'name': 'Brésil', 'symbol': 'R$' },
  'IO': { 'name': 'Territoire britannique de l\'océan Indien', 'symbol': '$' },
  'BN': { 'name': 'Brunei Darussalam', 'symbol': 'B$' },
  'BG': { 'name': 'Bulgarie', 'symbol': 'Лв.' },
  'BF': { 'name': 'Burkina Faso', 'symbol': 'CFA' },
  'BI': { 'name': 'Burundi', 'symbol': 'FBu' },
  'KH': { 'name': 'Cambodge', 'symbol': 'KHR' },
  'CM': { 'name': 'Cameroun', 'symbol': 'FCFA' },
  'CA': { 'name': 'Canada', 'symbol': '$' },
  'CV': { 'name': 'Cap-Vert', 'symbol': '$' },
  'KY': { 'name': 'Îles Caïmans', 'symbol': '$' },
  'CF': { 'name': 'République centrafricaine', 'symbol': 'FCFA' },
  'TD': { 'name': 'Tchad', 'symbol': 'FCFA' },
  'CL': { 'name': 'Chili', 'symbol': '$' },
  'CN': { 'name': 'Chine', 'symbol': '¥' },
  'CX': { 'name': 'L\'île de noël', 'symbol': '$' },
  'CC': { 'name': 'Îles Cocos (Keeling)', 'symbol': '$' },
  'CO': { 'name': 'Colombie', 'symbol': '$' },
  'KM': { 'name': 'Comores', 'symbol': 'CF' },
  'CG': { 'name': 'Congo', 'symbol': 'FC' },
  'CD': { 'name': 'Congo, République démocratique du Congo', 'symbol': 'FC' },
  'CK': { 'name': 'les Îles Cook', 'symbol': '$' },
  'CR': { 'name': 'Costa Rica', 'symbol': '₡' },
  'CI': { 'name': 'Côte d\'Ivoire', 'symbol': 'CFA' },
  'HR': { 'name': 'Croatie', 'symbol': 'kn' },
  'CU': { 'name': 'Cuba', 'symbol': '$' },
  'CW': { 'name': 'Curacao', 'symbol': 'ƒ' },
  'CY': { 'name': 'Chypre', 'symbol': '€' },
  'CZ': { 'name': 'République Tchèque', 'symbol': 'Kč' },
  'DK': { 'name': 'Danemark', 'symbol': 'Kr.' },
  'DJ': { 'name': 'Djibouti', 'symbol': 'Fdj' },
  'DM': { 'name': 'Dominique', 'symbol': '$' },
  'DO': { 'name': 'République dominicaine', 'symbol': '$' },
  'EC': { 'name': 'Equateur', 'symbol': '$' },
  'EG': { 'name': 'Egypte', 'symbol': 'ج.م' },
  'SV': { 'name': 'Le Salvador', 'symbol': '$' },
  'GQ': { 'name': 'Guinée Équatoriale', 'symbol': 'FCFA' },
  'ER': { 'name': 'Érythrée', 'symbol': 'Nfk' },
  'EE': { 'name': 'Estonie', 'symbol': '€' },
  'ET': { 'name': 'Ethiopie', 'symbol': 'Nkf' },
  'FK': { 'name': 'Îles Falkland (Malvinas)', 'symbol': '£' },
  'FO': { 'name': 'Îles Féroé', 'symbol': 'Kr.' },
  'FJ': { 'name': 'Fidji', 'symbol': 'FJ$' },
  'FI': { 'name': 'Finlande', 'symbol': '€' },
  'FR': { 'name': 'France', 'symbol': '€' },
  'GF': { 'name': 'Guyane Française', 'symbol': '€' },
  'PF': { 'name': 'Polynésie française', 'symbol': '₣' },
  'TF': { 'name': 'Terres australes françaises', 'symbol': '€' },
  'GA': { 'name': 'Gabon', 'symbol': 'FCFA' },
  'GM': { 'name': 'Gambie', 'symbol': 'D' },
  'GE': { 'name': 'Géorgie', 'symbol': 'ლ' },
  'DE': { 'name': 'Allemagne', 'symbol': '€' },
  'GH': { 'name': 'Ghana', 'symbol': 'GH₵' },
  'GI': { 'name': 'Gibraltar', 'symbol': '£' },
  'GR': { 'name': 'Grèce', 'symbol': '€' },
  'GL': { 'name': 'Groenland', 'symbol': 'Kr.' },
  'GD': { 'name': 'Grenade', 'symbol': '$' },
  'GP': { 'name': 'Guadeloupe', 'symbol': '€' },
  'GU': { 'name': 'Guam', 'symbol': '$' },
  'GT': { 'name': 'Guatemala', 'symbol': 'Q' },
  'GG': { 'name': 'Guernesey', 'symbol': '£' },
  'GN': { 'name': 'Guinée', 'symbol': 'FG' },
  'GW': { 'name': 'Guinée-Bissau', 'symbol': 'CFA' },
  'GY': { 'name': 'Guyane', 'symbol': '$' },
  'HT': { 'name': 'Haïti', 'symbol': 'G' },
  'HM': { 'name': 'Îles Heard et McDonald', 'symbol': '$' },
  'VA': { 'name': 'Saint-Siège (État de la Cité du Vatican)', 'symbol': '€' },
  'HN': { 'name': 'Honduras', 'symbol': 'L' },
  'HK': { 'name': 'Hong Kong', 'symbol': '$' },
  'HU': { 'name': 'Hongrie', 'symbol': 'Ft' },
  'IS': { 'name': 'Islande', 'symbol': 'kr' },
  'IN': { 'name': 'Inde', 'symbol': '₹' },
  'ID': { 'name': 'Indonésie', 'symbol': 'Rp' },
  'IR': { 'name': 'Iran (République islamique d', 'symbol': '﷼' },
  'IQ': { 'name': 'Irak', 'symbol': 'د.ع' },
  'IE': { 'name': 'Irlande', 'symbol': '€' },
  'IM': { 'name': 'île de Man', 'symbol': '£' },
  'IL': { 'name': 'Israël', 'symbol': '₪' },
  'IT': { 'name': 'Italie', 'symbol': '€' },
  'JM': { 'name': 'Jamaïque', 'symbol': 'J$' },
  'JP': { 'name': 'Japon', 'symbol': '¥' },
  'JE': { 'name': 'Jersey', 'symbol': '£' },
  'JO': { 'name': 'Jordan', 'symbol': 'ا.د' },
  'KZ': { 'name': 'Kazakhstan', 'symbol': 'лв' },
  'KE': { 'name': 'Kenya', 'symbol': 'KSh' },
  'KI': { 'name': 'Kiribati', 'symbol': '$' },
  'KP': { 'name': 'République populaire démocratique de Corée', 'symbol': '₩' },
  'KR': { 'name': 'Corée, République de', 'symbol': '₩' },
  'XK': { 'name': 'Kosovo', 'symbol': '€' },
  'KW': { 'name': 'Koweit', 'symbol': 'ك.د' },
  'KG': { 'name': 'Kirghizistan', 'symbol': 'лв' },
  'LA': { 'name': 'République démocratique populaire lao', 'symbol': '₭' },
  'LV': { 'name': 'Lettonie', 'symbol': '€' },
  'LB': { 'name': 'Liban', 'symbol': '£' },
  'LS': { 'name': 'Lesotho', 'symbol': 'L' },
  'LR': { 'name': 'Libéria', 'symbol': '$' },
  'LY': { 'name': 'Jamahiriya arabe libyenne', 'symbol': 'د.ل' },
  'LI': { 'name': 'Liechtenstein', 'symbol': 'CHf' },
  'LT': { 'name': 'Lituanie', 'symbol': '€' },
  'LU': { 'name': 'Luxembourg', 'symbol': '€' },
  'MO': { 'name': 'Macao', 'symbol': '$' },
  'MK': { 'name': 'Macédoine, ancienne République yougoslave de', 'symbol': 'ден' },
  'MG': { 'name': 'Madagascar', 'symbol': 'Ar' },
  'MW': { 'name': 'Malawi', 'symbol': 'MK' },
  'MY': { 'name': 'Malaisie', 'symbol': 'RM' },
  'MV': { 'name': 'Maldives', 'symbol': 'Rf' },
  'ML': { 'name': 'Mali', 'symbol': 'CFA' },
  'MT': { 'name': 'Malte', 'symbol': '€' },
  'MH': { 'name': 'Iles Marshall', 'symbol': '$' },
  'MQ': { 'name': 'Martinique', 'symbol': '€' },
  'MR': { 'name': 'Mauritanie', 'symbol': 'MRU' },
  'MU': { 'name': 'Ile Maurice', 'symbol': '₨' },
  'YT': { 'name': 'Mayotte', 'symbol': '€' },
  'MX': { 'name': 'Mexique', 'symbol': '$' },
  'FM': { 'name': 'Micronésie, États fédérés de', 'symbol': '$' },
  'MD': { 'name': 'Moldova, République de', 'symbol': 'L' },
  'MC': { 'name': 'Monaco', 'symbol': '€' },
  'MN': { 'name': 'Mongolie', 'symbol': '₮' },
  'ME': { 'name': 'Monténégro', 'symbol': '€' },
  'MS': { 'name': 'Montserrat', 'symbol': '$' },
  'MA': { 'name': 'Maroc', 'symbol': 'DH' },
  'MZ': { 'name': 'Mozambique', 'symbol': 'MT' },
  'MM': { 'name': 'Myanmar', 'symbol': 'K' },
  'NA': { 'name': 'Namibie', 'symbol': '$' },
  'NR': { 'name': 'Nauru', 'symbol': '$' },
  'NP': { 'name': 'Népal', 'symbol': '₨' },
  'NL': { 'name': 'Pays-Bas', 'symbol': '€' },
  'AN': { 'name': 'Antilles néerlandaises', 'symbol': 'NAf' },
  'NC': { 'name': 'Nouvelle Calédonie', 'symbol': '₣' },
  'NZ': { 'name': 'Nouvelle-Zélande', 'symbol': '$' },
  'NI': { 'name': 'Nicaragua', 'symbol': 'C$' },
  'NE': { 'name': 'Niger', 'symbol': 'CFA' },
  'NG': { 'name': 'Nigeria', 'symbol': '₦' },
  'NU': { 'name': 'Niue', 'symbol': '$' },
  'NF': { 'name': 'l\'ile de Norfolk', 'symbol': '$' },
  'MP': { 'name': 'Îles Mariannes du Nord', 'symbol': '$' },
  'NO': { 'name': 'Norvège', 'symbol': 'kr' },
  'OM': { 'name': 'Oman', 'symbol': '.ع.ر' },
  'PK': { 'name': 'Pakistan', 'symbol': '₨' },
  'PW': { 'name': 'Palau', 'symbol': '$' },
  'PS': { 'name': 'Territoire palestinien', 'symbol': '₪' },
  'PA': { 'name': 'Panama', 'symbol': 'B/.' },
  'PG': { 'name': 'Papouasie Nouvelle Guinée', 'symbol': 'K' },
  'PY': { 'name': 'Paraguay', 'symbol': '₲' },
  'PE': { 'name': 'Pérou', 'symbol': 'S/.' },
  'PH': { 'name': 'Philippines', 'symbol': '₱' },
  'PN': { 'name': 'Pitcairn', 'symbol': '$' },
  'PL': { 'name': 'Pologne', 'symbol': 'zł' },
  'PT': { 'name': 'Le Portugal', 'symbol': '€' },
  'PR': { 'name': 'Porto Rico', 'symbol': '$' },
  'QA': { 'name': 'Qatar', 'symbol': 'ق.ر' },
  'RE': { 'name': 'Réunion', 'symbol': '€' },
  'RO': { 'name': 'Roumanie', 'symbol': 'lei' },
  'RU': { 'name': 'Fédération Russe', 'symbol': '₽' },
  'RW': { 'name': 'Rwanda', 'symbol': 'FRw' },
  'BL': { 'name': 'Saint Barthélemy', 'symbol': '€' },
  'SH': { 'name': 'Sainte-Hélène', 'symbol': '£' },
  'KN': { 'name': 'Saint-Christophe-et-Niévès', 'symbol': '$' },
  'LC': { 'name': 'Sainte-Lucie', 'symbol': '$' },
  'MF': { 'name': 'Saint Martin', 'symbol': '€' },
  'PM': { 'name': 'Saint-Pierre-et-Miquelon', 'symbol': '€' },
  'VC': { 'name': 'Saint-Vincent-et-les-Grenadines', 'symbol': '$' },
  'WS': { 'name': 'Samoa', 'symbol': 'SAT' },
  'SM': { 'name': 'Saint Marin', 'symbol': '€' },
  'ST': { 'name': 'Sao Tomé et Principe', 'symbol': 'Db' },
  'SA': { 'name': 'Arabie Saoudite', 'symbol': '﷼' },
  'SN': { 'name': 'Sénégal', 'symbol': 'CFA' },
  'RS': { 'name': 'Serbie', 'symbol': 'din' },
  'CS': { 'name': 'Serbie et Monténégro', 'symbol': 'din' },
  'SC': { 'name': 'les Seychelles', 'symbol': 'SRe' },
  'SL': { 'name': 'Sierra Leone', 'symbol': 'Le' },
  'SG': { 'name': 'Singapour', 'symbol': '$' },
  'SX': { 'name': 'St Martin', 'symbol': 'ƒ' },
  'SK': { 'name': 'Slovaquie', 'symbol': '€' },
  'SI': { 'name': 'Slovénie', 'symbol': '€' },
  'SB': { 'name': 'Les îles Salomon', 'symbol': 'Si$' },
  'SO': { 'name': 'Somalie', 'symbol': 'Sh.so.' },
  'ZA': { 'name': 'Afrique du Sud', 'symbol': 'R' },
  'GS': { 'name': 'Géorgie du Sud et îles Sandwich du Sud', 'symbol': '£' },
  'SS': { 'name': 'Soudan du sud', 'symbol': '£' },
  'ES': { 'name': 'Espagne', 'symbol': '€' },
  'LK': { 'name': 'Sri Lanka', 'symbol': 'Rs' },
  'SD': { 'name': 'Soudan', 'symbol': '.س.ج' },
  'SR': { 'name': 'Suriname', 'symbol': '$' },
  'SJ': { 'name': 'Svalbard et Jan Mayen', 'symbol': 'kr' },
  'SZ': { 'name': 'Swaziland', 'symbol': 'E' },
  'SE': { 'name': 'Suède', 'symbol': 'kr' },
  'CH': { 'name': 'la Suisse', 'symbol': 'CHf' },
  'SY': { 'name': 'République arabe syrienne', 'symbol': 'LS' },
  'TW': { 'name': 'Taiwan, Province de Chine', 'symbol': '$' },
  'TJ': { 'name': 'Tadjikistan', 'symbol': 'SM' },
  'TZ': { 'name': 'Tanzanie, République-Unie de', 'symbol': 'TSh' },
  'TH': { 'name': 'Thaïlande', 'symbol': '฿' },
  'TL': { 'name': 'Timor-Leste', 'symbol': '$' },
  'TG': { 'name': 'Aller', 'symbol': 'CFA' },
  'TK': { 'name': 'Tokelau', 'symbol': '$' },
  'TO': { 'name': 'Tonga', 'symbol': '$' },
  'TT': { 'name': 'Trinité-et-Tobago', 'symbol': '$' },
  'TN': { 'name': 'Tunisie', 'symbol': 'ت.د' },
  'TR': { 'name': 'Turquie', 'symbol': '₺' },
  'TM': { 'name': 'Turkménistan', 'symbol': 'T' },
  'TC': { 'name': 'îles Turques-et-Caïques', 'symbol': '$' },
  'TV': { 'name': 'Tuvalu', 'symbol': '$' },
  'UG': { 'name': 'Ouganda', 'symbol': 'USh' },
  'UA': { 'name': 'Ukraine', 'symbol': '₴' },
  'AE': { 'name': 'Emirats Arabes Unis', 'symbol': 'إ.د' },
  'GB': { 'name': 'Royaume-Uni', 'symbol': '£' },
  'US': { 'name': 'États-Unis', 'symbol': '$' },
  'UM': { 'name': 'Îles mineures éloignées des États-Unis', 'symbol': '$' },
  'UY': { 'name': 'Uruguay', 'symbol': '$' },
  'UZ': { 'name': 'Ouzbékistan', 'symbol': 'лв' },
  'VU': { 'name': 'Vanuatu', 'symbol': 'VT' },
  'VE': { 'name': 'Venezuela', 'symbol': 'Bs' },
  'VN': { 'name': 'Viet Nam', 'symbol': '₫' },
  'VG': { 'name': 'Îles Vierges britanniques', 'symbol': '$' },
  'VI': { 'name': 'Îles Vierges américaines, États-Unis', 'symbol': '$' },
  'WF': { 'name': 'Wallis et Futuna', 'symbol': '₣' },
  'EH': { 'name': 'Sahara occidental', 'symbol': 'MAD' },
  'YE': { 'name': 'Yémen', 'symbol': '﷼' },
  'ZM': { 'name': 'Zambie', 'symbol': 'ZK' },
  'ZW': { 'name': 'Zimbabwe', 'symbol': '$' }
}
import { type Writable, writable } from 'svelte/store'
import { get } from 'svelte/store'
import initializationStore from '../../core-app/stores/initialization.store'
import type { CashApplicationDeclaredEvent } from '../models/cash-application-declared-model'

export const isFetching: Writable<boolean> = writable(true)

export let CashApplicationDeclaredEventsStore: Writable<CashApplicationDeclaredEvent[]> = writable()

export const initializeCashApplicationDeclaredEventsStore = (data): void => {

  if (!get(initializationStore).cashApplicationDeclaredEventsStoreInitialized) {

    initializationStore.update(store => {
      store.cashApplicationDeclaredEventsStoreInitialized = true

      return store
    })

    if (!Array.isArray(data)) data = <CashApplicationDeclaredEvent[]>[]

    CashApplicationDeclaredEventsStore.set(data)
    isFetching.set(false)

    let isCashApplicationDeclaredEventsStoreSubscriptionDefined: boolean = false
    // NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
    CashApplicationDeclaredEventsStore.subscribe((): void => {
      if (!isCashApplicationDeclaredEventsStoreSubscriptionDefined) {
        /* console.log('InvoicesHistoryStore subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently') */
        isCashApplicationDeclaredEventsStoreSubscriptionDefined = true

        return // we avoid the .subscribe() execution at the subscription occurrence
      }
    })
    /* console.log('%c CashApplicationDeclaredEventsStore initialized.', 'color: #8A99AC') */
  } else {
    /* console.log('%c CashApplicationDeclaredEventsStore not initialized.', 'color: #FF5555') */
  }
}

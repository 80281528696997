import { getBusinessDocumentWithCollateralDataById } from '$voxy/services/business-document-api/business-document-repository.api'
import { APICallOptions, APIEntity, apiGet } from '$core/services/api.service'
import { authZJsUserId, userUnderAuth0JsClient } from '$core/lib/auth0authentication/authStore'
import { BusinessDocument, BusinessDocumentAllDataPersisted } from '$voxy/models/business-document'
import { createPDFAndHandleFileUpload } from '$voxy/services/pdf-make/business-document-pdf-generator'
import { get } from 'svelte/store'
import * as Sentry from '@sentry/svelte'
import StorageBucketObject from '../models/storage-bucket-object'

let willCreatePDF: NodeJS.Timeout = null


/***
 * Downloads the latest file attachment for a given business document
 * NB: why ? "TS80006: This may be converted to an async function." ... see https://stackoverflow.com/questions/76818688/intellij-disable-ts80006-this-may-be-converted-to-an-async-function
 *          where the user states "I still prefer the .then() variant, as it is better readable for me."
 * @param businessDocumentNumber
 * @param businessDocumentId
 * @param workspaceId
 * @param isBusinessDocumentFinalized
 */
export function pdfAttachmentDownload(businessDocumentNumber: string, businessDocumentId: string, workspaceId: string, isBusinessDocumentFinalized: boolean): Promise<StorageBucketObject> {
  /* console.log('pdfAttachmentDownload', businessDocumentId, businessDocumentNumber) */
  // TODO: should use business document number after invoice is completed. Waiting for BE to support it.

  return apiGet<StorageBucketObject[]>(
    'workspace/' + workspaceId + '/invoice/' + window.btoa(isBusinessDocumentFinalized ? businessDocumentNumber : businessDocumentId).replace(/=/g, '') + '/file-attachments?justLatestFile',
        <APICallOptions>{
          entity: APIEntity.INVOICE_FILE,
          ignoreFeedback: true
        })
    .then((results: StorageBucketObject[]) => {
      /* console.log('File Download result', results) */
      if (!results || results.length < 1) {
        throw new Error('no resulting pdf attachment returned')
      }

      return results[0]
    })
    .catch((reason: Error) => {
      const msg = reason.message
      console.log('--------------------------', '\'' + msg + '\'')

      if (msg === 'no resulting pdf attachment returned') {
        if (willCreatePDF) { // temporal filter, otherwise runs 3x !!!
          clearTimeout(willCreatePDF)
        }
        willCreatePDF = setTimeout(() => {
          console.log('-----CREATING PDF again---------')
          Sentry.withScope(scope => {
            scope.setLevel('info')
            scope.setTag('tag', 'PDFIssue')
            scope.setTag('desc', 'PDF was not created')
            scope.setTag('workspaceId', workspaceId)
            scope.setTag('businessDocumentId', businessDocumentId)
            scope.setTag('businessDocumentNumber', businessDocumentNumber)
            scope.setTag('userId', get(authZJsUserId))
            scope.setTag('userEmail', get(userUnderAuth0JsClient).email)
            scope.setContext('Context example label', {
              workspaceId
            })
          })

          Sentry.captureMessage(`finalized Business Document PDF could not be loaded, re-creating it on the fly, reason: ${msg}`)
          // console.log("wsid", workspaceId, "bdid", businessDocumentId, "nb", businessDocumentNumber, "!!!!!", reason)

          getBusinessDocumentWithCollateralDataById(businessDocumentId)
            .then((inputBusinessDocument: void | BusinessDocumentAllDataPersisted): void => {
              
              if (!!inputBusinessDocument) {
                const gotBusinessDocument: BusinessDocumentAllDataPersisted = <BusinessDocumentAllDataPersisted>inputBusinessDocument
                const invoiceCollateralData = gotBusinessDocument.collateralDocument.businessDocumentCollateralData
                const targetBusinessDocument: BusinessDocument = gotBusinessDocument.coreDocument.businessDocument
                
                createPDFAndHandleFileUpload(
                  invoiceCollateralData.isPaidWhenFinalized,
                  invoiceCollateralData.paymentInformation.paymentURL,
                  targetBusinessDocument,
                  targetBusinessDocument?.accountCompany?.regulatory?.frRegulScope?.siret ?? '',
                  targetBusinessDocument?.accountCompany?.regulatory?.euRegulScope?.euIntraVat ?? '',
                  invoiceCollateralData.showPaymentLink
                )
                console.log('-----FINISHED CREATION PDF again---------')
              }
            })
            .catch(err => {
              console.log('! FAILED ! error while reading before updating bank info in business document id ' + businessDocumentId, err)
            })
        }, 3000)
      }
    })
}

<script lang="ts">
  import {createEventDispatcher, onMount} from 'svelte'
  import {t} from '$core/lib/i18n/i18nextWrapper'
  import {bridgeByBankingService} from '../services/bridge-by-banking.service'
  import Loader from '../../core-app/lib/ui-kit/Loader.svelte'
  import FullSizeModal from '../../core-app/lib/ui-kit/FullSizeModal.svelte'
  import mixpanel from 'mixpanel-browser'
  import { WorkspaceStore } from "$crm/stores/workspace.store";
  import PaymentLinkResponse from "$pay/models/payment-link-model";

  /** Local declarations */
  let loading = false
  const dispatch = createEventDispatcher()


  const onCancel = () => {
    dispatch('close')
  }

  const onBankConnection = () => {
    loading = true
    if(!($WorkspaceStore?.bankConfig)) {
      console.error("no bank config, aborting bbb connection")
      loading = false
      return
    }else{
      bridgeByBankingService.getConnectUrlForWorkspace($WorkspaceStore?.bankConfig)
        .then((urlResponse:PaymentLinkResponse) => {
          mixpanel.track('DC10 New Bank Consent Started', {
            'Description': 'Starting new bank consent bridgeByBankingService.getConnectUrlForWorkspace'
          })
          /*console.log("BankConnectionModal.svelte, data=", urlResponse)*/
          if (urlResponse.ok) {
            /*console.log("BankConnectionModal.svelte, urlResponse.ok")*/
            window.location.href = urlResponse.data
          } else {
            /*console.log("BankConnectionModal.svelte, !urlResponse.ok")*/
            loading = false
          }
        })
    }
  }

  onMount(() => {
    /*console.log('%c onMount()  ->  BankConnectionModal.svelte', 'color:green;font-size: 1.5em;')*/
    mixpanel.track('DA10 Bank Connection Modal', {
      'Description': 'Open BankConnectionModal.svelte'
    })
    loading = false
  })
</script>

<FullSizeModal
  class="w-full overflow-y-auto h-full"
  isCloseable={true}
  on:close={() => onCancel()}>
  <div class="grid w-full h-full overflow-y-auto pb-24" slot="content">
    <div class="w-full md:w-8/12 lg:w-8/12 mx-auto mb-8 mt-8 px-6 md:px-0">
      <div class="py-5 mb-7">
        <h2 class="text-black text-3xl font-bold">{t('bankConnectionModal.title')}</h2>
      </div>
      {#if !loading}
        <div class="flex flex-col lg:flex-row warning-note items-center">
          <div class="w-full lg:w-8/12 relative overflow-hidden">
            <div class="p-12 w-8/12 relative z-10">
              <p class="text-black text-3xl font-bold">{t('bankConnectionModal.title1')}</p>
              <ol class="ml-6 mt-4 list-numbers text-base">
                <li class="pl-2 mb-2 text-black">
                  <span>{t('bankConnectionModal.bullet1.text1')}</span>
                  <span class="font-semibold">{t('bankConnectionModal.bullet1.text2')}</span>
                  <span>{t('bankConnectionModal.bullet1.text3')}</span>
                </li>
                <li class="pl-2 mb-2 text-black">
                  <span>{t('bankConnectionModal.bullet2.text1')}</span>
                  <span class="font-semibold">{t('bankConnectionModal.bullet2.text2')}</span>
                  <span>{t('bankConnectionModal.bullet2.text3')}</span>
                </li>
                <li class="pl-2 mb-2 text-black">
                  <span>{t('bankConnectionModal.bullet3.text1')}</span>
                  <span class="font-semibold">{t('bankConnectionModal.bullet3.text2')}</span>
                </li>
              </ol>
            </div>
            <img src="/img/bank-wall/iphone-selection-de-la-banque-1240x1240.jpeg" alt="Bank" class="absolute top-0 -right-20 h-96 z-0" />
            <div class="flex w-full bg-creamBrulee absolute h-3 bottom-0"></div>
          </div>
          <div class="bg-black w-full lg:w-4/12">
            <ul class="ml-6 mt-4 text-sm p-4">
              <li class="relative inline-block pl-4 w-11/12 mb-4">
                <div class="absolute rounded-full w-4 h-4 bg-aquamarine p-0 align-top -ml-8 mt-1">
                  <i class="icon-done text-xl relative right-0.5 bottom-0.5"></i>
                </div>
                <span class="text-white">{@html t('bankConnectionModal.bullet4.text1')}</span>
              </li>
              <li class="relative inline-block pl-4 w-11/12 mb-4">
                <div class="absolute rounded-full w-4 h-4 bg-aquamarine p-0 align-top -ml-8 mt-1">
                  <i class="icon-done text-xl relative right-0.5 bottom-0.5"></i>
                </div>
                <span class="text-white">{@html t('bankConnectionModal.bullet5.text1')}</span>
              </li>
              <li class="relative inline-block pl-4 w-11/12 mb-4">
                <div class="absolute rounded-full w-4 h-4 bg-aquamarine p-0 align-top -ml-8 mt-1">
                  <i class="icon-done text-xl relative right-0.5 bottom-0.5"></i>
                </div>
                <span class="text-white">{@html t('bankConnectionModal.bullet6.text1')}</span>
              </li>
              <li class="relative inline-block pl-4 w-11/12 mb-4">
                <div class="absolute rounded-full w-4 h-4 bg-aquamarine p-0 align-top -ml-8 mt-1">
                  <i class="icon-done text-xl relative right-0.5 bottom-0.5"></i>
                </div>
                <span class="text-white">{@html t('bankConnectionModal.bullet7.text1')}</span>
              </li>
            </ul>
          </div>
        </div>

      {:else}
        <div class="grid place-items-center w-full z-[100] my-64">
          <Loader/>
        </div>
      {/if}
    </div>

    {#if !loading}
      <div class="w-full fixed bottom-0 pb-12">
        <div class="h-8 bg-gradient-to-t from-white"></div>
        <div class="flex justify-center pt-4 pb-6 bg-white">
          <button class="cursor-pointer btn action-cancel mr-5"
                  on:click={() => onCancel()}>{t('invoices.editInvoice.cancel')}</button>
          <button class="cursor-pointer btn action-bla bg-black text-white justify-self-end"
                  data-cy="bankConnectionModalSubmitButton"
                  on:click={() => onBankConnection()}>{t('bankConnectionModal.submit')}</button>
        </div>
      </div>
    {/if}
  </div>

</FullSizeModal>

<style lang="postcss">
  .warning-note {
    @apply bg-white border border-warmGray rounded-lg overflow-hidden ring-1 ring-black shadow-sm;
  }

  .list-numbers {
    list-style-type: decimal;
  }

</style>

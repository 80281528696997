import { BusinessDocumentKind } from '../enums/business-document-kind'
import { ExchangeDate } from '$core/models/exchange-date'
import { BusinessDocumentAttachmentMetadata } from '$core/models/attachment-metadata'
import { BusinessDocumentRelationKind } from '../enums/business-document-relation-kind'
import { BusinessDocumentStatus } from '../enums/business-document-status'
import { Contact } from '$crm/models/contact'
import { Customer } from '$src/crm-app/models/customer'
import type { DocumentSourceKindType } from '$src/order-to-cash-lib/models/document-source-kind'
import { ReasonType } from '../enums/reason-type'
import { TaxonomyTag } from '../enums/taxonomy-tag'
import { TaxRate } from './tax-rate'
import type { ValueObjectBusinessDocumentNumber } from './business-document-number.model'
import Company from '../../crm-app/models/company'
import { JsonProperty } from '@paddls/ts-serializer'


export class UpdateResult {
  MatchedCount: number
  ModifiedCount: number
  UpsertedCount: number
  UpsertedID: string
}


export class SavedBusinessDocumentResponse {
  @JsonProperty({ field: 'upsertResult', type: () => UpdateResult }) upsertResult: UpdateResult
  @JsonProperty('isBusinessDocumentNumberAllocated') isBusinessDocumentNumberAllocated: boolean
  @JsonProperty('allocatedBusinessDocumentNumber') allocatedBusinessDocumentNumber: ValueObjectBusinessDocumentNumber
}


export class CoreDocument {
  @JsonProperty('requesterId') requesterId: string // uuid
  @JsonProperty('workspaceId') workspaceId: string // uuid
  @JsonProperty({ field: 'businessDocument', type: () => BusinessDocument }) businessDocument: BusinessDocument
  @JsonProperty({ field: 'modifiedDate', type: () => ExchangeDate }) modifiedDate: ExchangeDate
}


export class BusinessDocumentRelation {
  @JsonProperty('fromBusinessDocumentId') fromBusinessDocumentId: string // uuid
  @JsonProperty({ field: 'fromBusinessDocumentKind', groups: Object.values(BusinessDocumentKind) }) fromBusinessDocumentKind: BusinessDocumentKind
  @JsonProperty({ field: 'fromBusinessDocumentTaxonomyTags', groups: Object.values(TaxonomyTag) }) fromBusinessDocumentTaxonomyTags: TaxonomyTag[]
  @JsonProperty('fromBusinessDocumentNumber') fromBusinessDocumentNumber: string
  @JsonProperty({ field: 'fromBusinessDocumentIssuedDate', type: () => ExchangeDate }) fromBusinessDocumentIssuedDate: ExchangeDate
  @JsonProperty({ field: 'fromBusinessDocumentSource', type: () => BusinessDocumentSource }) fromBusinessDocumentSource: BusinessDocumentSource
  @JsonProperty('toBusinessDocumentId') toBusinessDocumentId: string // uuid
  @JsonProperty({ field: 'toBusinessDocumentKind', groups: Object.values(BusinessDocumentKind) }) toBusinessDocumentKind: BusinessDocumentKind
  @JsonProperty({ field: 'toBusinessDocumentTaxonomyTags', groups: Object.values(TaxonomyTag) }) toBusinessDocumentTaxonomyTags: TaxonomyTag[]
  @JsonProperty('toBusinessDocumentNumber') toBusinessDocumentNumber: string
  @JsonProperty({ field: 'toBusinessDocumentIssuedDate', type: () => ExchangeDate }) toBusinessDocumentIssuedDate: ExchangeDate
  @JsonProperty({ field: 'toBusinessDocumentSource', type: () => BusinessDocumentSource }) toBusinessDocumentSource: BusinessDocumentSource
  @JsonProperty({ field: 'relationKind', groups: Object.values(BusinessDocumentRelationKind) }) relationKind: BusinessDocumentRelationKind // the link between 'from Doc' and 'to Doc'  Is A 'relationKind' (like 'to Doc' is ... 'from Doc')
  @JsonProperty({ field: 'createdDate', type: () => ExchangeDate }) createdDate: ExchangeDate
}


export class DealDetails {
  @JsonProperty('hasDealInfo') hasDealInfo: boolean
  @JsonProperty('dealId') dealId: string // uuid
  @JsonProperty('dealKind') dealKind: string
  @JsonProperty('dealSupplierReference') dealSupplierReference: string
  @JsonProperty('dealCustomerReference') dealCustomerReference: string
  @JsonProperty('dealTitle') dealTitle: string
  @JsonProperty('dealDescription') dealDescription: string
  @JsonProperty({ field: 'dealDate', type: () => ExchangeDate }) dealDate: ExchangeDate
  @JsonProperty('hasDealStart') hasDealStart: boolean
  @JsonProperty({ field: 'dealStart', type: () => ExchangeDate }) dealStart: ExchangeDate
  @JsonProperty('hasDealEnd') hasDealEnd: boolean
  @JsonProperty({ field: 'dealEnd', type: () => ExchangeDate }) dealEnd: ExchangeDate
}

export enum InstallmentComputationKind {
  PERCENT_OF_TOTAL_AMOUNT = 'PercentOfTotalAmount',
  ABSOLUTE_AMOUNT_INCLUDING_TAX = 'AbsoluteAmountIncludingTax',
  ABSOLUTE_AMOUNT_EXCLUDING_TAX = 'AbsoluteAmountExcludingTax',
  REMAINING_AMOUNT = 'RemainingAmount'
}

export class BusinessDocumentItemPrice {
  @JsonProperty('unit') unit: UnitOfMeasure // "unit_h", "unit_kg", "unit_day", etc.
  @JsonProperty('currency') currency: string // ISO-4217 https://en.wikipedia.org/wiki/ISO_4217 https://www.iso.org/iso-4217-currency-codes.html
  @JsonProperty('scaledValue') scaledValue: number // price per unit in the currency
}

export class FavoriteListSource {
  // listLabel is e.g. the label, name or author name of the price list that the item belongs to
  @JsonProperty('listLabel') listLabel: string
  // listDescription is e.g. the description of the price list that the item belongs to
  @JsonProperty('listDescription') listDescription: string
  // listDate is the date of the price list that the item belongs to
  @JsonProperty({ field: 'listDate', type: () => ExchangeDate }) listDate: ExchangeDate
}

export class BusinessDocumentLineItem {
  @JsonProperty('businessDocumentLineItemId') businessDocumentLineItemId: BusinessDocumentLineItemId // uuid
  @JsonProperty({ field: 'favoriteOrigin', type: () => FavoriteListSource }) favoriteOrigin: FavoriteListSource
  @JsonProperty('lineItemNumber') lineItemNumber: number
  @JsonProperty('supplierReference') supplierReference: SupplierReference
  @JsonProperty('customerReference') customerReference: CustomerReference
  @JsonProperty('title') title: string //  "Creation of a test website",
  @JsonProperty('description') description: string // "Creating a WordPress hello-world.io with 10 pages and according to template ABC",
  @JsonProperty({ field: 'itemPrice', type: () => BusinessDocumentItemPrice }) itemPrice: BusinessDocumentItemPrice
  @JsonProperty('quantity') quantity: number // can be a decimal number
  @JsonProperty({ field: 'taxRate', type: () => TaxRate }) taxRate: TaxRate
  @JsonProperty('taxScaledValue') taxScaledValue: number // the absolute value of the calculated tax in the currency
  @JsonProperty('lineItemTotalIncludingTaxScaledValue') lineItemTotalIncludingTaxScaledValue: number
  @JsonProperty('lineItemTotalExcludingTaxScaledValue') lineItemTotalExcludingTaxScaledValue: number
}

export class AccountBankingInformation {
  @JsonProperty('bankAccountHolder') bankAccountHolder: string
  @JsonProperty('bankAccountIBAN') bankAccountIBAN: string
  @JsonProperty('bankAccountBIC') bankAccountBIC: string
  @JsonProperty('bankWireReference') bankWireReference: string
}

export class PaymentLink {
  @JsonProperty('paymentURL') paymentURL: string // URL to the payment link or to the payment portal
}

export class BusinessDocumentCollateralData {
  @JsonProperty('businessDocumentId') businessDocumentId: string // uuid
  @JsonProperty('modelKind') modelKind: string
  @JsonProperty('modelVersion') modelVersion: number
  @JsonProperty({ field: 'Data', groups: Object.values(BusinessDocumentKind) }) businessDocumentKind: BusinessDocumentKind
  @JsonProperty('businessDocumentNumber') businessDocumentNumber: string
  @JsonProperty('paymentConditionsCustomText') paymentConditionsCustomText: string
  @JsonProperty('showLateFeesAndPenaltyText') showLateFeesAndPenaltyText: boolean
  @JsonProperty('showNoDiscountForEarlyBirds') showNoDiscountForEarlyBirds: boolean
  // @JsonProperty('showAcceptedChequesAndCreditCardsMention') showAcceptedChequesAndCreditCardsMention: boolean // in back-end but not in front-end yet
  // @JsonProperty('showTaxExemptionMention') showTaxExemptionMention: boolean // in back-end but not in front-end yet
  @JsonProperty('isBankConnectionActive') isBankConnectionActive: boolean
  @JsonProperty('showDiscountModule') showDiscountModule: boolean
  @JsonProperty('isBankInformationMissing') isBankInformationMissing: boolean
  @JsonProperty('isPaidWhenFinalized') isPaidWhenFinalized: boolean
  @JsonProperty('showPaymentLink') showPaymentLink: boolean
  @JsonProperty({ field: 'paymentInformation', type: () => PaymentLink }) paymentInformation: PaymentLink
  @JsonProperty({ field: 'fileAttachment', type: () => BusinessDocumentAttachmentMetadata }) fileAttachment: BusinessDocumentAttachmentMetadata
  @JsonProperty('savingStateExpiration') savingStateExpiration: number // in Unix time UTC Seconds
}

export class CollateralDocument {
  @JsonProperty({ field: 'businessDocumentCollateralData', type: () => BusinessDocumentCollateralData }) businessDocumentCollateralData: BusinessDocumentCollateralData
  @JsonProperty({ field: 'modifiedDate', type: () => ExchangeDate }) modifiedDate: ExchangeDate
  @JsonProperty('requesterId') requesterId: string
  @JsonProperty('workspaceId') workspaceId: string
}

export class BusinessDocumentAllDataPersisted {
  @JsonProperty({ field: 'collateralDocument', type: () => CollateralDocument }) collateralDocument: CollateralDocument
  @JsonProperty({ field: 'coreDocument', type: () => CoreDocument }) coreDocument: CoreDocument
}

export class BusinessDocumentSource {
  @JsonProperty('sourceLabel') sourceLabel: string
  // a short description of the source (can be automatically created like "download on 2022-10-22 at 16:00:23 by ABC for company DEF")
  @JsonProperty('sourceKind') sourceKind: DocumentSourceKindType
  // the type of source: looking like "FromDundyDunningManually" or "FromVoxyInvoicing" or "FromStripeInvoicing" or "FromQontoInvoicing" or "FromQuickbooksAccounting"
  @JsonProperty('sourceAccountReference') sourceAccountReference: string
  // a relevant id for the source account like the workspaceId, the companyId, etc
  @JsonProperty('sourceLogoURL') sourceLogoURL: string
  // simple public URL to the logo of the source type
  @JsonProperty('resourceURL') resourceURL: string
  // URL to the web app view or edit page of the source (full URL)
  @JsonProperty('attachmentURLs') attachmentURLs: string[]
  // URLs to the Google cloud storage of the attachments (full URL)
  @JsonProperty({ field: 'lastSourceUpdated', type: () => ExchangeDate }) lastSourceUpdated: ExchangeDate
  // last time the data was extracted from the source (maybe the source was updated since)
}

export type BusinessDocumentLineItemId = string;
export type SupplierReference = string;
export type CustomerReference = string;
export type UnitOfMeasure = string;

export class BusinessDocument {
  @JsonProperty('businessDocumentId') businessDocumentId: string // uuid
  @JsonProperty('modelKind') modelKind: string
  @JsonProperty('modelVersion') modelVersion: number
  @JsonProperty({ field: 'businessDocumentSource', type: () => BusinessDocumentSource }) businessDocumentSource: BusinessDocumentSource
  @JsonProperty({ field: 'businessDocumentKind', groups: Object.values(BusinessDocumentKind) }) businessDocumentKind: BusinessDocumentKind
  @JsonProperty({ field: 'taxonomyTags', groups: Object.values(TaxonomyTag) }) taxonomyTags: TaxonomyTag[]
  @JsonProperty('businessDocumentNumber') businessDocumentNumber: string
  @JsonProperty({ field: 'relatedBusinessDocuments', type: () => BusinessDocumentRelation }) relatedBusinessDocuments: BusinessDocumentRelation[]
  @JsonProperty('relatedPurchaseOrderNumbers') relatedPurchaseOrderNumbers: string[]
  @JsonProperty({ field: 'businessDocumentReason', groups: Object.values(ReasonType) }) businessDocumentReason: ReasonType
  @JsonProperty('reasonDetails') reasonDetails: string
  @JsonProperty({ field: 'accountCompany', type: () => Company })accountCompany: Company
  @JsonProperty({ field: 'accountContact', type: () => Contact }) accountContact: Contact
  @JsonProperty({ field: 'accountBankingInformation', type: () => AccountBankingInformation }) accountBankingInformation: AccountBankingInformation
  @JsonProperty({ field: 'customerCustomer', type: () => Customer }) customerCustomer: Customer
  @JsonProperty({ field: 'customerContact', type: () => Contact }) customerContact: Contact
  @JsonProperty('customerTaxExempt') customerTaxExempt: string // "none"
  @JsonProperty('memo') memo: string
  @JsonProperty({ field: 'issuedDate', type: () => ExchangeDate }) issuedDate: ExchangeDate
  @JsonProperty({ field: 'dueDate', type: () => ExchangeDate }) dueDate: ExchangeDate
  @JsonProperty({ field: 'finalizedDate', type: () => ExchangeDate }) finalizedDate: ExchangeDate
  @JsonProperty('timeZoneIANACode') timeZoneIANACode: string
  @JsonProperty({ field: 'linkedDeal', type: () => DealDetails }) linkedDeal: DealDetails
  @JsonProperty({ field: 'lineItems', type: () => BusinessDocumentLineItem }) lineItems: BusinessDocumentLineItem[]
  @JsonProperty('currency') currency: string // ISO-4217 https://en.wikipedia.org/wiki/ISO_4217 https://www.iso.org/iso-4217-currency-codes.html
  @JsonProperty('subtotalExcludingTaxScaledValue') subtotalExcludingTaxScaledValue: number
  @JsonProperty('subtotalIncludingTaxScaledValue') subtotalIncludingTaxScaledValue: number
  @JsonProperty('totalDiscountExcludingTaxChosenValue') totalDiscountExcludingTaxChosenValue: number
  @JsonProperty('totalDiscountExcludingTaxChosenUnit') totalDiscountExcludingTaxChosenUnit: string
  @JsonProperty('totalDiscountExcludingTaxDescription') totalDiscountExcludingTaxDescription: string
  @JsonProperty('totalDiscountExcludingTaxResultScaledValue') totalDiscountExcludingTaxResultScaledValue: number
  @JsonProperty('totalTaxScaledValue') totalTaxScaledValue: number
  @JsonProperty('totalExcludingTaxScaledValue') totalExcludingTaxScaledValue: number
  @JsonProperty('totalIncludingTaxScaledValue') totalIncludingTaxScaledValue: number
  // InstallmentResultIncludingTaxScaledValue is the actual amount to be paid for this business document.
  // If InstallmentResultIncludingTaxScaledValue is equal to TotalIncludingTaxScaledValue, we have a standard invoice where all the described line items are fully invoiced.
  // If InstallmentResultIncludingTaxScaledValue is different from TotalIncludingTaxScaledValue, we have a partial invoice.
  @JsonProperty('hasSpecificInstallment') hasSpecificInstallment: boolean
  @JsonProperty('installmentChosenValue') installmentChosenValue: number
  @JsonProperty({ field: 'installmentChosenKind', groups: Object.values(InstallmentComputationKind) }) installmentChosenKind: InstallmentComputationKind
  @JsonProperty('installmentDescription') installmentDescription: string
  @JsonProperty('installmentResultTaxScaledValue') installmentResultTaxScaledValue: number
  @JsonProperty('installmentResultExcludingTaxScaledValue') installmentResultExcludingTaxScaledValue: number
  @JsonProperty('installmentResultIncludingTaxScaledValue') installmentResultIncludingTaxScaledValue: number
  @JsonProperty('amountDueScaledValue') amountDueScaledValue: number
  @JsonProperty('paymentConditions') paymentConditions: string
  @JsonProperty('legalMentions') legalMentions: string
  @JsonProperty({ field: 'businessDocumentStatus', groups: Object.values(BusinessDocumentStatus) }) businessDocumentStatus: BusinessDocumentStatus
  @JsonProperty('deleted') deleted: boolean
  @JsonProperty({ field: 'deletedDate', type: () => ExchangeDate }) deletedDate: ExchangeDate
  @JsonProperty({ field: 'createdDate', type: () => ExchangeDate }) createdDate: ExchangeDate
  @JsonProperty({ field: 'modifiedDate', type: () => ExchangeDate }) modifiedDate: ExchangeDate
  constructor() {
    this.modelKind = ''
    this.modelVersion = 0
    this.businessDocumentId = ''
    this.businessDocumentKind = BusinessDocumentKind.INVOICE
    this.taxonomyTags = []
    this.businessDocumentNumber = ''
    this.timeZoneIANACode = ''
    this.linkedDeal = {} as DealDetails // Placeholder, needs proper initialization
    this.businessDocumentSource = {} as BusinessDocumentSource // Placeholder, needs proper initialization
    this.relatedBusinessDocuments = []
    this.relatedPurchaseOrderNumbers = []
    this.businessDocumentReason = {} as ReasonType // Placeholder, needs proper initialization
    this.reasonDetails = ''
    this.accountCompany = {} as Company // Placeholder, needs proper initialization
    this.accountContact = {} as Contact // Placeholder, needs proper initialization
    this.accountBankingInformation = {} as AccountBankingInformation // Placeholder, needs proper initialization
    this.customerCustomer = {} as Customer // Placeholder, needs proper initialization
    this.customerContact = {} as Contact // Placeholder, needs proper initialization
    this.customerTaxExempt = ''
    this.memo = ''
    this.issuedDate = ExchangeDate.empty()
    this.dueDate = ExchangeDate.empty()
    this.finalizedDate = ExchangeDate.empty()
    this.lineItems = []
    this.currency = '' // ISO-4217 code
    this.subtotalExcludingTaxScaledValue = 0
    this.subtotalIncludingTaxScaledValue = 0
    this.totalDiscountExcludingTaxChosenValue = 0
    this.totalDiscountExcludingTaxChosenUnit = ''
    this.totalDiscountExcludingTaxDescription = ''
    this.totalDiscountExcludingTaxResultScaledValue = 0
    this.totalTaxScaledValue = 0
    this.totalExcludingTaxScaledValue = 0
    this.totalIncludingTaxScaledValue = 0
    this.hasSpecificInstallment = false
    this.installmentChosenValue = 0
    this.installmentChosenKind = {} as InstallmentComputationKind // Placeholder, needs proper initialization
    this.installmentDescription = ''
    this.installmentResultTaxScaledValue = 0
    this.installmentResultExcludingTaxScaledValue = 0
    this.installmentResultIncludingTaxScaledValue = 0
    this.amountDueScaledValue = 0
    this.paymentConditions = ''
    this.legalMentions = ''
    this.businessDocumentStatus = {} as BusinessDocumentStatus // Placeholder, needs proper initialization
    this.deleted = false
    this.deletedDate = ExchangeDate.empty()
    this.createdDate = ExchangeDate.empty()
    this.modifiedDate = ExchangeDate.empty()
  }
}
import { t } from '../lib/i18n/i18nextWrapper'

export function getUserJobTitles(): { label: string; value: string; }[] {
  return [
    { label: t('onboarding.lists.role.ceo'), value: 'ceo' },
    { label: t('onboarding.lists.role.cfo'), value: 'cfo' },
    { label: t('onboarding.lists.role.accountant'), value: 'accountant' },
    { label: t('onboarding.lists.role.management'), value: 'management' },
    { label: t('onboarding.lists.role.administrative'), value: 'administrative' },
    { label: t('onboarding.lists.role.other'), value: 'other' }
  ]
}
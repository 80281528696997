import { TodoListItem } from './todo-list-item'
import { CashApplication } from '../../cash-application-app/models/cash-application-declared-model'
import Invoice from './invoice'
import { AmountDecimal, AmountDecimalBasicObject } from '../../cash-application-app/models/amount-of-money-decimal-model'
import { JsonProperty } from '@paddls/ts-serializer'

export type InvoicesHistoryResponse = Record<string, InvoicesHistoryItem>

type CommonProperties = {
  commonDate?: Date;
};

export type DoneToDoItem = CommonProperties & {
  type: 'doneToDoItem';
} & TaskDoneEventPersisted;

export type InvoiceEventItem = CommonProperties & {
  type: 'invoiceEvent';
} & InvoiceEvent;

export type HistoryItemWithType = DoneToDoItem | InvoiceEventItem;

export type EventKindType = string;

export class TaskDoneEventPersisted {
  @JsonProperty('created') created: number
  @JsonProperty('createdRFC3339') createdRFC3339: string
  @JsonProperty('dateDone') dateDone: number
  @JsonProperty('dateDoneIANACode') dateDoneIANACode: string
  @JsonProperty('dateDoneRFC3339') dateDoneRFC3339: string
  @JsonProperty({ field: 'doneToDoItem', type: () => TodoListItem }) doneToDoItem: TodoListItem
  @JsonProperty('eventId') eventId: string
  @JsonProperty('eventKind') eventKind: string
  @JsonProperty('userId') userId: string
  @JsonProperty('workspaceId') workspaceId: string
}

export class InvoiceEvent {
  @JsonProperty('eventId') eventId: string
  @JsonProperty('eventKind') eventKind: EventKindType
  @JsonProperty('userId') userId: string
  @JsonProperty('workspaceId') workspaceId: string
  @JsonProperty('customerId') customerId: string
  @JsonProperty('invoiceNumber') invoiceNumber: string
  @JsonProperty('invoiceDataId') invoiceDataId: string
  @JsonProperty('dateInvoiceIssued') dateInvoiceIssued?: number
  @JsonProperty('dateInvoiceIssuedRFC3339') dateInvoiceIssuedRFC3339?: string
  @JsonProperty('dateInvoiceDue') dateInvoiceDue?: number
  @JsonProperty('dateInvoiceDueRFC3339') dateInvoiceDueRFC3339?: string
  @JsonProperty({ field: 'invoice', type: () => Invoice }) invoice?: Invoice
  @JsonProperty({ field: 'cashApplication', type: () => CashApplication }) cashApplication?: CashApplication
  @JsonProperty({ field: 'invoiceAmount', type: () => [AmountDecimal, AmountDecimalBasicObject] }) invoiceAmount?: AmountDecimal | AmountDecimalBasicObject
  @JsonProperty('creatorEndpoint') creatorEndpoint: string
  @JsonProperty('creatorFunction') creatorFunction: string
  @JsonProperty('created') created: number
  @JsonProperty('createdRFC3339') createdRFC3339: string
}

export class InvoicesHistoryItem {
  @JsonProperty('customerId') customerId: string
  @JsonProperty({ field: 'doneToDoItems', type: () => TaskDoneEventPersisted }) doneToDoItems: TaskDoneEventPersisted[]
  @JsonProperty({ field: 'invoiceEvents', type: () => InvoiceEvent }) invoiceEvents: InvoiceEvent[]
  @JsonProperty('invoiceNumber') invoiceNumber: string
  @JsonProperty('workspaceId') workspaceId: string
}


/*
/!** Extend InvoicesHistoryItem For New And Improved Merged History List *!/
export class InvoicesHistoryItemWithDate extends InvoicesHistoryItem {
    commonDate?: Date;
}*/

/*export type HistoryItemWithType = InvoicesHistoryItemWithDate & {
    type: 'doneToDoItem' | 'invoiceEvent';
    commonDate?: Date;
} & (InvoiceEvent | TaskDoneEventPersisted);*/


/*export enum EventKind {
    InvoiceIssued = "InvoiceIssued",
    InvoiceRemindedToCustomer = "InvoiceRemindedToCustomer",
    CustomerFedBackOnInvoice = "CustomerFedBackOnInvoice",
    CashApplicationDeclared = "CashApplicationDeclared",
    TaskDone = "TaskDone",
    Error = "Error",
}*/

import type Invoice from '../models/invoice'
import { InvoiceStatus } from '../enums/invoice-status'
import { DunningBusinessDocumentKind } from '../enums/dunning-business-document-kind'
import { calculateDaysDue } from '../../order-to-cash-lib/helpers/calculate-days-due'
import type ComputedDataInvoice from '../models/computed-data-invoice'


/**
 * Returns all outstanding invoices from a list of invoices
 * excluding deleted invoices and invoices that are not tracked
 * @param invoices
 */
export function getOutstandingInvoicesExcludingUntrackedAndDeleted(invoices: Invoice[]): Invoice[] {
  return <Invoice[]>invoices
    ? invoices.filter(
      (invoice: Invoice) =>
        invoice?.status === InvoiceStatus.OUTSTANDING &&
                invoice.documentKind === DunningBusinessDocumentKind.INVOICE &&
                !invoice.deleted &&
                invoice.isTracked &&
                invoice.completed,
    )
    : []
}

/**
 * Returns all overdue invoices from a list of invoices
 * excluding deleted invoices and invoices that are not tracked
 * using the calculated days due function
 * @param invoices
 * @param computedDataInvoiceStore
 */
export function getOverdueInvoicesExcludingUntrackedAndDeleted(invoices: Invoice[], computedDataInvoiceStore: ComputedDataInvoice[]): Invoice[] {
  return <Invoice[]>invoices
    ? invoices.filter(
      (invoice: Invoice) => calculateDaysDue(invoice.invoiceNumber, computedDataInvoiceStore) > 0
                    && invoice.documentKind === DunningBusinessDocumentKind.INVOICE
                    && !invoice.deleted
                    && invoice.completed
                    && invoice.isTracked
                    && invoice.status === InvoiceStatus.OUTSTANDING,
    )
    : []
}

/**
 * Return Invoice object from invoiceId
 * @param invoiceStore
 * @param invoiceId
 */
export function findInvoiceFromId(invoiceStore: Invoice[], invoiceId: string): Invoice {
  return <Invoice>invoiceStore.find((invoice: Invoice): boolean => invoice.dataId === invoiceId)
}

import { Feedback, FeedbackDetails, FeedbackOnProperty } from '../models/feedback'
import { FeedbackEntity } from '../enums/feedback-enums'
import type FeedbacksOnInvoices from '../../dundy-app/models/feedbacks-on-invoices'

export class FeedbackHelper {
  static invoiceFeedbackToFeedback(invoiceFeedback: FeedbacksOnInvoices): Feedback {
    const feedback = new Feedback()
    feedback.numberOfInvalidItems = invoiceFeedback.numberOfInvalidInvoices
    feedback.feedbackDetails = new Array<FeedbackDetails>()
    feedback.feedbackLevel = 'None'
    if (invoiceFeedback.feedbackOnInvoiceList) {
      for (const invoiceFeedbackItem of invoiceFeedback.feedbackOnInvoiceList) {
        const feedbackDetails = new FeedbackDetails()
        if (invoiceFeedbackItem.allFeedbacks) {
          feedbackDetails.detailsOnProperties = new Map<string, FeedbackOnProperty>()
          for (const invoiceFeedBackOnPropertyKey of Object.keys(invoiceFeedbackItem.allFeedbacks)) {
            const invoiceFeedBackOnProperty = invoiceFeedbackItem.allFeedbacks[invoiceFeedBackOnPropertyKey]
            const feedbackOnProperty = new FeedbackOnProperty()
            feedbackOnProperty.canTheItemBeFinalized = invoiceFeedBackOnProperty.canTheInvoiceBeFinalized
            feedbackOnProperty.comment = invoiceFeedBackOnProperty.comment
            feedbackOnProperty.isFieldValid = invoiceFeedBackOnProperty.isInvoiceFieldValid
            feedbackOnProperty.validationLevel = invoiceFeedBackOnProperty.validationLevel
            feedbackDetails.detailsOnProperties.set(invoiceFeedBackOnPropertyKey, feedbackOnProperty)
          }
          feedbackDetails.itemId = invoiceFeedbackItem.invoiceDataId
          feedbackDetails.itemIncomplete = invoiceFeedbackItem.invoiceIncomplete
          feedbackDetails.itemIsNotTracked = invoiceFeedbackItem.invoiceIsNotTracked
          feedbackDetails.numberOfInvalidProperties = invoiceFeedbackItem.numberOfInvalidFields
        }
        feedback.feedbackDetails.push(feedbackDetails)
      }
    }
    
    return feedback
  }
  static createNewFeedbackStoreData() {
    const feedbackPerEntity = {}
    for (const value in FeedbackEntity) {
      feedbackPerEntity[value] = new Feedback()
    }
    
    return feedbackPerEntity
  }
}
import type { Contact } from '$crm/models/contact'
import type { ExchangeDate } from '$core/models/exchange-date'
import type { DunningWorkflowStep } from './dunning-worflow'

export type DunningPhoneCallTemplate = {
  callDestinations?: DunningContactDesignation[]
  callCanevas?: Record<LanguageCountryCode, string>
}

export type DunningPostalLetterTemplate = {
  contactPrimaryDestination?: DunningContactDesignation;
  contactSecondaryDestinations?: DunningContactDesignation[];
  letterSubject?: Record<LanguageCountryCode, string>;
  letterBody?: Record<LanguageCountryCode, string>;
}

export type DunningContactDesignation = {
  literalEmail?: string
  contactReference?: Contact
  dunningPlaceHolder?: string
}

export type DunningEmailTemplate = {
  appendEmailsToInsteadOfOverride: boolean
  emailsTo?: DunningContactDesignation[]
  appendEmailsCCInsteadOfOverride: boolean
  emailsCC?: DunningContactDesignation[]
  appendEmailsCCIInsteadOfOverride: boolean
  emailsCCI?: DunningContactDesignation[]
  emailFrom?: DunningContactDesignation
  subject?: Record<LanguageCountryCode, string>
  body?: Record<LanguageCountryCode, string>
}

export enum DunningMessageKind {
  '*' = '*',
  'DunningEmail' = 'DunningEmail',
  'DunningPhoneCall' = 'DunningPhoneCall',
  'DunningPostalLetter' = 'DunningPostalLetter'
}

export type DunningMessageTemplateReference = string

export type DunningMessageTemplateItem = {
  templateItemRef: DunningMessageTemplateReference // uuid
  kind: DunningMessageKind
  dunningEmail?: DunningEmailTemplate
  dunningPhoneCall?: DunningPhoneCallTemplate
  dunningPostalLetter?: DunningPostalLetterTemplate
  updatedByUserId: string
  updated: ExchangeDate,
  isSystemDefault:boolean,
  workspaceId: string
}

export type DunningMessageTemplatePrefKey = {
  workspaceId: string
  workflowStep: DunningWorkflowStep
  customerId?: string
  label: string
  userId?: string
}

export type PrefDunningMessageTemplate = {
  prefKey: DunningMessageTemplatePrefKey
  templateItemRef: DunningMessageTemplateReference
}

export type SearchedFoundAndAssembledDunningMessageTemplate = {
  searchedPrefKey: DunningMessageTemplatePrefKey;
  assembledDunningMessageTemplate?: DunningMessageTemplateItem;
  sortedDunningMessageTemplatePrefs?: PrefDunningMessageTemplate[];
  sortedDunningMessageTemplateItems?: DunningMessageTemplateItem[];
  isSystemDefault: boolean;
};
import {
  BusinessDocument,
  BusinessDocumentAllDataPersisted,
  BusinessDocumentCollateralData,
  SavedBusinessDocumentResponse
} from '../../models/business-document'
import { APICallOptions, apiDelete, APIEntity, apiGet, apiPut } from '../../../core-app/services/api.service'
import { get } from 'svelte/store'
import { WorkspaceStore } from '../../../crm-app/stores/workspace.store'
import { eventsManager } from '../../../core-app/events/event-manager'
import { EventType } from '../../../core-app/events/event-type'
import { feedbackService } from '../../../core-app/services/feedback.service'
import { Feedback } from '../../../core-app/models/feedback'
import { ApiError } from '../../../core-app/models/api-error'

/**
 * Get the business document with its collateral data (UI Info) from the server
 * Check usage with other implementation: getBusinessDocumentWithCollateralDataById
 * NB:
 * - getVoxyInvoiceWithCollateralDataFromServerById simply makes the call to the back-end API
 * - getBusinessDocumentWithCollateralDataById also makes the call to the back-end API and triggers events that update reference lists on the front-end * @param businessDocumentId
 */
export function getVoxyInvoiceWithCollateralDataFromServerById(businessDocumentId: string) {
  return apiGet<BusinessDocumentAllDataPersisted>(`/workspaces/${get(WorkspaceStore).workspaceId}/business-documents/${businessDocumentId}/with-collateral-data`, <APICallOptions>{
    entity: APIEntity.VOXY_BUSINESS_DOCUMENTS,
    overrideContentType: 'application/json',
    ignoreFeedback: true
  })
}


/**
 * Retrieve a Voxy Business Document along with its collateral data by its ID
 * Check usage with other implementation: getVoxyInvoiceWithCollateralDataFromServerById
 * NB:
 * - getVoxyInvoiceWithCollateralDataFromServerById simply makes the call to the back-end API
 * - getBusinessDocumentWithCollateralDataById also makes the call to the back-end API and triggers events that update reference lists on the front-end
 * @param businessDocumentId
 */
export function getBusinessDocumentWithCollateralDataById(businessDocumentId: string): Promise<void | BusinessDocumentAllDataPersisted> {
  return getVoxyInvoiceWithCollateralDataFromServerById(businessDocumentId)
    .then((persistedBusinessDocumentResponse: BusinessDocumentAllDataPersisted) => {
      /* console.log('Response from Get Request getBusinessDocumentWithCollateralDataById', persistedBusinessDocumentResponse) */
      /* console.log('Business document', persistedBusinessDocumentResponse.coreDocument.businessDocument) */
      setTimeout((): void => {
        /* console.log('request to update all when getting voxy collateral from back-end') */
        eventsManager.emit(EventType.REQUEST_INVOICE_LIST_AND_COMPUTED_INVOICE_LIST_TO_REFRESH, null, 'voxy.service.ts')
      }, 2000)

      return persistedBusinessDocumentResponse
    }).catch(e => feedbackService.displayFeedback(<Feedback>{
      feedbackLevel: 'Error',
      feedbackMessage: e.message || e.detail || e
    }))
}


/**
 * Get the business document from the server
 * Check usage with other implementation: getBusinessDocumentById
 * NB:
 * - getVoxyInvoiceFromServer simply makes the call to the back-end API
 * - getBusinessDocumentById also makes the call to the back-end API and triggers events that update reference lists on the front-end
 * @param businessDocument
 */
export function getVoxyInvoiceFromServer(businessDocumentId?: string): Promise<{ businessDocument: BusinessDocument }> {
  /* console.log('The businessDocument object to be pulled from server', businessDocumentId) */

  return apiGet<{
    businessDocument: BusinessDocument;
  }>(`/workspaces/${get(WorkspaceStore).workspaceId}/business-documents/${businessDocumentId}`, <APICallOptions>{
    entity: APIEntity.VOXY_BUSINESS_DOCUMENTS,
    overrideContentType: 'application/json',
    ignoreFeedback: true
  })
}

/**
 * Retrieve a Voxy Business Document by its ID
 * Check usage with other implementation: getVoxyInvoiceFromServer
 * NB:
 * - getVoxyInvoiceFromServer simply makes the call to the back-end API
 * - getBusinessDocumentById also makes the call to the back-end API and triggers events that update reference lists on the front-end * @param businessDocumentId
 */
export function getBusinessDocumentById(businessDocumentId: string): Promise<void | BusinessDocument> {
  return getVoxyInvoiceFromServer(businessDocumentId)
    .then((response: { businessDocument: BusinessDocument }) => {
      /* console.log('Response from Get Request', response) */
      setTimeout(() => {
        /* console.log('request to update all when getting voxy from back-end by id (deprecated)') */
        eventsManager.emit(EventType.REQUEST_INVOICE_LIST_AND_COMPUTED_INVOICE_LIST_TO_REFRESH, null,
          'voxy.service.ts')
      }, 2000)

      return response.businessDocument
    }).catch(e => feedbackService.displayFeedback(<Feedback>{
      feedbackLevel: 'Error',
      feedbackMessage: e.message || e.detail || e
    }))
}


/**
 * Save a voxy invoice with its collateral data (UI info) to the server
 * @param invoiceCollateralData
 */
export function saveVoxyBusinessDocumentCollateralDataToServer(invoiceCollateralData?: BusinessDocumentCollateralData) {
  /* console.log('The collateral object being saved', invoiceCollateralData) */

  return apiPut<{
    upsertResult: any;
  }>(`/workspaces/${get(WorkspaceStore).workspaceId}/business-document-collateral-data`,
    {
      workspaceId: `${get(WorkspaceStore).workspaceId}`,
      businessDocumentCollateralData: invoiceCollateralData
    }, <APICallOptions>{
      entity: APIEntity.VOXY_BUSINESS_DOCUMENTS,
      overrideContentType: 'application/json'
    }).then((response: { upsertResult: any }) =>
  /* console.log('%c VOXY saveVoxyBusinessDocumentCollateralDataToServer() response', 'color: purple;', response) */

    response
  ).catch((error: ApiError): void => {
    console.error('saveVoxyBusinessDocumentCollateralDataToServer', error)
  })
}

/**
 * Save a voxy invoice to the server
 * @param businessDocument
 */
export function saveVoxyInvoiceToServer(businessDocument?: BusinessDocument): Promise<void | SavedBusinessDocumentResponse> {
  /* console.log('saving businessDocument', businessDocument) */

  return apiPut<SavedBusinessDocumentResponse>(`/workspaces/${get(WorkspaceStore).workspaceId}/business-documents`,
    {
      workspaceId: `${get(WorkspaceStore).workspaceId}`,
      businessDocument
    }, <APICallOptions>{
      entity: APIEntity.VOXY_BUSINESS_DOCUMENTS,
      overrideContentType: 'application/json'
    }).then((response: SavedBusinessDocumentResponse) =>
  /* console.log('%c VOXY saveInvoiceToServer() response', 'color: purple;', response) */

    response
  ).catch((error: ApiError): void => {
    console.error(error)
  })
}

/**
 * Delete multiple draft invoices
 * @param businessDocumentIds
 */
export function deleteVoxyDraftInvoices(businessDocumentIds: string[]): Promise<any> {
  /* console.log('deleteVoxyDraftInvoices() businessDocumentIds', businessDocumentIds) */

  return apiDelete(`/workspaces/${get(WorkspaceStore).workspaceId}/business-documents`, businessDocumentIds, <APICallOptions>{
    entity: APIEntity.VOXY_BUSINESS_DOCUMENTS,
    ignoreFeedback: true
  })
    .then((response: any) => response)
    .catch((error) => {throw error})
}

export const legalCategoryLevel1 = [
  { level1CatCode: 0, level1CatCodeRaw: '0', level1CatFr: 'Organisme de placement collectif en valeurs mobilières sans personnalité morale' },
  { level1CatCode: 1, level1CatCodeRaw: '1', level1CatFr: 'Entrepreneur individuel' },
  { level1CatCode: 2, level1CatCodeRaw: '2', level1CatFr: 'Groupement de droit privé non doté de la personnalité morale' },
  { level1CatCode: 3, level1CatCodeRaw: '3', level1CatFr: 'Personne morale de droit étranger' },
  { level1CatCode: 4, level1CatCodeRaw: '4', level1CatFr: 'Personne morale de droit public soumise au droit commercial' },
  { level1CatCode: 5, level1CatCodeRaw: '5', level1CatFr: 'Société commerciale' },
  { level1CatCode: 6, level1CatCodeRaw: '6', level1CatFr: 'Autre personne morale immatriculée au RCS' },
  { level1CatCode: 7, level1CatCodeRaw: '7', level1CatFr: 'Personne morale et organisme soumis au droit administratif' },
  { level1CatCode: 8, level1CatCodeRaw: '8', level1CatFr: 'Organisme privé spécialisé' },
  { level1CatCode: 9, level1CatCodeRaw: '9', level1CatFr: 'Groupement de droit privé' }
]

export const legalCategoryLevel2 = [
  { level2CatCode: 0, level2CatCodeRaw: '00', level2CatFr: 'Organisme de placement collectif en valeurs mobilières sans personnalité morale' },
  { level2CatCode: 10, level2CatCodeRaw: '10', level2CatFr: 'Entrepreneur individuel' },
  { level2CatCode: 21, level2CatCodeRaw: '21', level2CatFr: 'Indivision' },
  { level2CatCode: 22, level2CatCodeRaw: '22', level2CatFr: 'Société créée de fait' },
  { level2CatCode: 23, level2CatCodeRaw: '23', level2CatFr: 'Société en participation' },
  { level2CatCode: 24, level2CatCodeRaw: '24', level2CatFr: 'Fiducie' },
  { level2CatCode: 27, level2CatCodeRaw: '27', level2CatFr: 'Paroisse hors zone concordataire' },
  { level2CatCode: 28, level2CatCodeRaw: '28', level2CatFr: 'Assujetti unique à la TVA' },
  { level2CatCode: 29, level2CatCodeRaw: '29', level2CatFr: 'Autre groupement de droit privé non doté de la personnalité morale' },
  { level2CatCode: 31, level2CatCodeRaw: '31', level2CatFr: 'Personne morale de droit étranger, immatriculée au RCS (registre du commerce et des sociétés)' },
  { level2CatCode: 32, level2CatCodeRaw: '32', level2CatFr: 'Personne morale de droit étranger, non immatriculée au RCS' },
  { level2CatCode: 41, level2CatCodeRaw: '41', level2CatFr: 'Etablissement public ou régie à caractère industriel ou commercial' },
  { level2CatCode: 51, level2CatCodeRaw: '51', level2CatFr: 'Société coopérative commerciale particulière' },
  { level2CatCode: 52, level2CatCodeRaw: '52', level2CatFr: 'Société en nom collectif' },
  { level2CatCode: 53, level2CatCodeRaw: '53', level2CatFr: 'Société en commandite' },
  { level2CatCode: 54, level2CatCodeRaw: '54', level2CatFr: 'Société à responsabilité limitée (SARL)' },
  { level2CatCode: 55, level2CatCodeRaw: '55', level2CatFr: 'Société anonyme à conseil d\'administration' },
  { level2CatCode: 56, level2CatCodeRaw: '56', level2CatFr: 'Société anonyme à directoire' },
  { level2CatCode: 57, level2CatCodeRaw: '57', level2CatFr: 'Société par actions simplifiée' },
  { level2CatCode: 58, level2CatCodeRaw: '58', level2CatFr: 'Société européenne' },
  { level2CatCode: 61, level2CatCodeRaw: '61', level2CatFr: 'Caisse d\'épargne et de prévoyance' },
  { level2CatCode: 62, level2CatCodeRaw: '62', level2CatFr: 'Groupement d\'intérêt économique' },
  { level2CatCode: 63, level2CatCodeRaw: '63', level2CatFr: 'Société coopérative agricole' },
  { level2CatCode: 64, level2CatCodeRaw: '64', level2CatFr: 'Société d\'assurance mutuelle' },
  { level2CatCode: 65, level2CatCodeRaw: '65', level2CatFr: 'Société civile' },
  { level2CatCode: 69, level2CatCodeRaw: '69', level2CatFr: 'Autre personne morale de droit privé inscrite au registre du commerce et des sociétés' },
  { level2CatCode: 71, level2CatCodeRaw: '71', level2CatFr: 'Administration de l\'état' },
  { level2CatCode: 72, level2CatCodeRaw: '72', level2CatFr: 'Collectivité territoriale' },
  { level2CatCode: 73, level2CatCodeRaw: '73', level2CatFr: 'Etablissement public administratif' },
  { level2CatCode: 74, level2CatCodeRaw: '74', level2CatFr: 'Autre personne morale de droit public administratif' },
  { level2CatCode: 81, level2CatCodeRaw: '81', level2CatFr: 'Organisme gérant un régime de protection sociale à adhésion obligatoire' },
  { level2CatCode: 82, level2CatCodeRaw: '82', level2CatFr: 'Organisme mutualiste' },
  { level2CatCode: 83, level2CatCodeRaw: '83', level2CatFr: 'Comité d\'entreprise' },
  { level2CatCode: 84, level2CatCodeRaw: '84', level2CatFr: 'Organisme professionnel' },
  { level2CatCode: 85, level2CatCodeRaw: '85', level2CatFr: 'Organisme de retraite à adhésion non obligatoire' },
  { level2CatCode: 91, level2CatCodeRaw: '91', level2CatFr: 'Syndicat de propriétaires' },
  { level2CatCode: 92, level2CatCodeRaw: '92', level2CatFr: 'Association loi 1901 ou assimilé' },
  { level2CatCode: 93, level2CatCodeRaw: '93', level2CatFr: 'Fondation' },
  { level2CatCode: 99, level2CatCodeRaw: '99', level2CatFr: 'Autre personne morale de droit privé' }
]

export const getLegalCategoryLevel1FromLevel2 = (level2CatCodeRaw: string) => {
  if (!level2CatCodeRaw || level2CatCodeRaw.length < 2) return null

  return legalCategoryLevel1.find(c => c.level1CatCode === Number(level2CatCodeRaw[0]))
}

export const legalCategoryLevel3 = [
  { level3CatCode: 0, level3CatCodeRaw: '0000', level3CatFr: 'Organisme de placement collectif en valeurs mobilières sans personnalité morale' },
  { level3CatCode: 1000, level3CatCodeRaw: '1000', level3CatFr: 'Entrepreneur individuel' },
  { level3CatCode: 2110, level3CatCodeRaw: '2110', level3CatFr: 'Indivision entre personnes physiques' },
  { level3CatCode: 2120, level3CatCodeRaw: '2120', level3CatFr: 'Indivision avec personne morale' },
  { level3CatCode: 2210, level3CatCodeRaw: '2210', level3CatFr: 'Société créée de fait entre personnes physiques' },
  { level3CatCode: 2220, level3CatCodeRaw: '2220', level3CatFr: 'Société créée de fait avec personne morale' },
  { level3CatCode: 2310, level3CatCodeRaw: '2310', level3CatFr: 'Société en participation entre personnes physiques' },
  { level3CatCode: 2320, level3CatCodeRaw: '2320', level3CatFr: 'Société en participation avec personne morale' },
  { level3CatCode: 2385, level3CatCodeRaw: '2385', level3CatFr: 'Société en participation de professions libérales' },
  { level3CatCode: 2400, level3CatCodeRaw: '2400', level3CatFr: 'Fiducie' },
  { level3CatCode: 2700, level3CatCodeRaw: '2700', level3CatFr: 'Paroisse hors zone concordataire' },
  { level3CatCode: 2800, level3CatCodeRaw: '2800', level3CatFr: 'Assujetti unique à la TVA' },
  { level3CatCode: 2900, level3CatCodeRaw: '2900', level3CatFr: 'Autre groupement de droit privé non doté de la personnalité morale' },
  { level3CatCode: 3110, level3CatCodeRaw: '3110', level3CatFr: 'Représentation ou agence commerciale d\'état ou organisme public étranger immatriculé au RCS' },
  { level3CatCode: 3120, level3CatCodeRaw: '3120', level3CatFr: 'Société commerciale étrangère immatriculée au RCS' },
  { level3CatCode: 3205, level3CatCodeRaw: '3205', level3CatFr: 'Organisation internationale' },
  { level3CatCode: 3210, level3CatCodeRaw: '3210', level3CatFr: 'État, collectivité ou établissement public étranger' },
  { level3CatCode: 3220, level3CatCodeRaw: '3220', level3CatFr: 'Société étrangère non immatriculée au RCS' },
  { level3CatCode: 3290, level3CatCodeRaw: '3290', level3CatFr: 'Autre personne morale de droit étranger' },
  { level3CatCode: 4110, level3CatCodeRaw: '4110', level3CatFr: 'Établissement public national à caractère industriel ou commercial doté d\'un comptable public' },
  { level3CatCode: 4120, level3CatCodeRaw: '4120', level3CatFr: 'Établissement public national à caractère industriel ou commercial non doté d\'un comptable public' },
  { level3CatCode: 4130, level3CatCodeRaw: '4130', level3CatFr: 'Exploitant public' },
  { level3CatCode: 4140, level3CatCodeRaw: '4140', level3CatFr: 'Établissement public local à caractère industriel ou commercial' },
  { level3CatCode: 4150, level3CatCodeRaw: '4150', level3CatFr: 'Régie d\'une collectivité locale à caractère industriel ou commercial' },
  { level3CatCode: 4160, level3CatCodeRaw: '4160', level3CatFr: 'Institution Banque de France' },
  { level3CatCode: 5191, level3CatCodeRaw: '5191', level3CatFr: 'Société de caution mutuelle' },
  { level3CatCode: 5192, level3CatCodeRaw: '5192', level3CatFr: 'Société coopérative de banque populaire' },
  { level3CatCode: 5193, level3CatCodeRaw: '5193', level3CatFr: 'Caisse de crédit maritime mutuel' },
  { level3CatCode: 5194, level3CatCodeRaw: '5194', level3CatFr: 'Caisse (fédérale) de crédit mutuel' },
  { level3CatCode: 5195, level3CatCodeRaw: '5195', level3CatFr: 'Association coopérative inscrite (droit local Alsace Moselle)' },
  { level3CatCode: 5196, level3CatCodeRaw: '5196', level3CatFr: 'Caisse d\'épargne et de prévoyance à forme coopérative' },
  { level3CatCode: 5202, level3CatCodeRaw: '5202', level3CatFr: 'Société en nom collectif' },
  { level3CatCode: 5203, level3CatCodeRaw: '5203', level3CatFr: 'Société en nom collectif coopérative' },
  { level3CatCode: 5306, level3CatCodeRaw: '5306', level3CatFr: 'Société en commandite simple' },
  { level3CatCode: 5307, level3CatCodeRaw: '5307', level3CatFr: 'Société en commandite simple coopérative' },
  { level3CatCode: 5308, level3CatCodeRaw: '5308', level3CatFr: 'Société en commandite par actions' },
  { level3CatCode: 5309, level3CatCodeRaw: '5309', level3CatFr: 'Société en commandite par actions coopérative' },
  { level3CatCode: 5310, level3CatCodeRaw: '5310', level3CatFr: 'Société en libre partenariat (SLP)' },
  { level3CatCode: 5370, level3CatCodeRaw: '5370', level3CatFr: 'Société de Participations Financières de Profession Libérale Société en commandite par actions (SPFPL SCA)' },
  { level3CatCode: 5385, level3CatCodeRaw: '5385', level3CatFr: 'Société d\'exercice libéral en commandite par actions' },
  { level3CatCode: 5410, level3CatCodeRaw: '5410', level3CatFr: 'SARL nationale' },
  { level3CatCode: 5415, level3CatCodeRaw: '5415', level3CatFr: 'SARL d\'économie mixte' },
  { level3CatCode: 5422, level3CatCodeRaw: '5422', level3CatFr: 'SARL immobilière pour le commerce et l\'industrie (SICOMI)' },
  { level3CatCode: 5426, level3CatCodeRaw: '5426', level3CatFr: 'SARL immobilière de gestion' },
  { level3CatCode: 5430, level3CatCodeRaw: '5430', level3CatFr: 'SARL d\'aménagement foncier et d\'équipement rural (SAFER)' },
  { level3CatCode: 5431, level3CatCodeRaw: '5431', level3CatFr: 'SARL mixte d\'intérêt agricole (SMIA)' },
  { level3CatCode: 5432, level3CatCodeRaw: '5432', level3CatFr: 'SARL d\'intérêt collectif agricole (SICA)' },
  { level3CatCode: 5442, level3CatCodeRaw: '5442', level3CatFr: 'SARL d\'attribution' },
  { level3CatCode: 5443, level3CatCodeRaw: '5443', level3CatFr: 'SARL coopérative de construction' },
  { level3CatCode: 5451, level3CatCodeRaw: '5451', level3CatFr: 'SARL coopérative de consommation' },
  { level3CatCode: 5453, level3CatCodeRaw: '5453', level3CatFr: 'SARL coopérative artisanale' },
  { level3CatCode: 5454, level3CatCodeRaw: '5454', level3CatFr: 'SARL coopérative d\'intérêt maritime' },
  { level3CatCode: 5455, level3CatCodeRaw: '5455', level3CatFr: 'SARL coopérative de transport' },
  { level3CatCode: 5458, level3CatCodeRaw: '5458', level3CatFr: 'SARL coopérative de production (SCOP)' },
  { level3CatCode: 5459, level3CatCodeRaw: '5459', level3CatFr: 'SARL union de sociétés coopératives' },
  { level3CatCode: 5460, level3CatCodeRaw: '5460', level3CatFr: 'Autre SARL coopérative' },
  { level3CatCode: 5470, level3CatCodeRaw: '5470', level3CatFr: 'Société de Participations Financières de Profession Libérale Société à responsabilité limitée (SPFPL SARL)' },
  { level3CatCode: 5485, level3CatCodeRaw: '5485', level3CatFr: 'Société d\'exercice libéral à responsabilité limitée' },
  { level3CatCode: 5499, level3CatCodeRaw: '5499', level3CatFr: 'Société à responsabilité limitée (sans autre indication)' },
  { level3CatCode: 5505, level3CatCodeRaw: '5505', level3CatFr: 'SA à participation ouvrière à conseil d\'administration' },
  { level3CatCode: 5510, level3CatCodeRaw: '5510', level3CatFr: 'SA nationale à conseil d\'administration' },
  { level3CatCode: 5515, level3CatCodeRaw: '5515', level3CatFr: 'SA d\'économie mixte à conseil d\'administration' },
  { level3CatCode: 5520, level3CatCodeRaw: '5520', level3CatFr: 'Fonds à forme sociétale à conseil d\'administration' },
  { level3CatCode: 5522, level3CatCodeRaw: '5522', level3CatFr: 'SA immobilière pour le commerce et l\'industrie (SICOMI) à conseil d\'administration' },
  { level3CatCode: 5525, level3CatCodeRaw: '5525', level3CatFr: 'SA immobilière d\'investissement à conseil d\'administration' },
  { level3CatCode: 5530, level3CatCodeRaw: '5530', level3CatFr: 'SA d\'aménagement foncier et d\'équipement rural (SAFER) à conseil d\'administration' },
  { level3CatCode: 5531, level3CatCodeRaw: '5531', level3CatFr: 'Société anonyme mixte d\'intérêt agricole (SMIA) à conseil d\'administration' },
  { level3CatCode: 5532, level3CatCodeRaw: '5532', level3CatFr: 'SA d\'intérêt collectif agricole (SICA) à conseil d\'administration' },
  { level3CatCode: 5542, level3CatCodeRaw: '5542', level3CatFr: 'SA d\'attribution à conseil d\'administration' },
  { level3CatCode: 5543, level3CatCodeRaw: '5543', level3CatFr: 'SA coopérative de construction à conseil d\'administration' },
  { level3CatCode: 5546, level3CatCodeRaw: '5546', level3CatFr: 'SA de HLM à conseil d\'administration' },
  { level3CatCode: 5547, level3CatCodeRaw: '5547', level3CatFr: 'SA coopérative de production de HLM à conseil d\'administration' },
  { level3CatCode: 5548, level3CatCodeRaw: '5548', level3CatFr: 'SA de crédit immobilier à conseil d\'administration' },
  { level3CatCode: 5551, level3CatCodeRaw: '5551', level3CatFr: 'SA coopérative de consommation à conseil d\'administration' },
  { level3CatCode: 5552, level3CatCodeRaw: '5552', level3CatFr: 'SA coopérative de commerçants-détaillants à conseil d\'administration' },
  { level3CatCode: 5553, level3CatCodeRaw: '5553', level3CatFr: 'SA coopérative artisanale à conseil d\'administration' },
  { level3CatCode: 5554, level3CatCodeRaw: '5554', level3CatFr: 'SA coopérative (d\'intérêt) maritime à conseil d\'administration' },
  { level3CatCode: 5555, level3CatCodeRaw: '5555', level3CatFr: 'SA coopérative de transport à conseil d\'administration' },
  { level3CatCode: 5558, level3CatCodeRaw: '5558', level3CatFr: 'SA coopérative de production  (SCOP) à conseil d\'administration' },
  { level3CatCode: 5559, level3CatCodeRaw: '5559', level3CatFr: 'SA union de sociétés coopératives à conseil d\'administration' },
  { level3CatCode: 5560, level3CatCodeRaw: '5560', level3CatFr: 'Autre SA coopérative à conseil d\'administration' },
  { level3CatCode: 5570, level3CatCodeRaw: '5570', level3CatFr: 'Société de Participations Financières de Profession Libérale Société anonyme à conseil d\'administration (SPFPL SA à conseil d\'administration)' },
  { level3CatCode: 5585, level3CatCodeRaw: '5585', level3CatFr: 'Société d\'exercice libéral à forme anonyme à conseil d\'administration' },
  { level3CatCode: 5599, level3CatCodeRaw: '5599', level3CatFr: 'SA à conseil d\'administration (s.a.i.)' },
  { level3CatCode: 5605, level3CatCodeRaw: '5605', level3CatFr: 'SA à participation ouvrière à directoire' },
  { level3CatCode: 5610, level3CatCodeRaw: '5610', level3CatFr: 'SA nationale à directoire' },
  { level3CatCode: 5615, level3CatCodeRaw: '5615', level3CatFr: 'SA d\'économie mixte à directoire' },
  { level3CatCode: 5620, level3CatCodeRaw: '5620', level3CatFr: 'Fonds à forme sociétale à directoire' },
  { level3CatCode: 5622, level3CatCodeRaw: '5622', level3CatFr: 'SA immobilière pour le commerce et l\'industrie (SICOMI) à directoire' },
  { level3CatCode: 5625, level3CatCodeRaw: '5625', level3CatFr: 'SA immobilière d\'investissement à directoire' },
  { level3CatCode: 5630, level3CatCodeRaw: '5630', level3CatFr: 'Safer anonyme à directoire' },
  { level3CatCode: 5631, level3CatCodeRaw: '5631', level3CatFr: 'SA mixte d\'intérêt agricole (SMIA)' },
  { level3CatCode: 5632, level3CatCodeRaw: '5632', level3CatFr: 'SA d\'intérêt collectif agricole (SICA)' },
  { level3CatCode: 5642, level3CatCodeRaw: '5642', level3CatFr: 'SA d\'attribution à directoire' },
  { level3CatCode: 5643, level3CatCodeRaw: '5643', level3CatFr: 'SA coopérative de construction à directoire' },
  { level3CatCode: 5646, level3CatCodeRaw: '5646', level3CatFr: 'SA de HLM à directoire' },
  { level3CatCode: 5647, level3CatCodeRaw: '5647', level3CatFr: 'Société coopérative de production de HLM anonyme à directoire' },
  { level3CatCode: 5648, level3CatCodeRaw: '5648', level3CatFr: 'SA de crédit immobilier à directoire' },
  { level3CatCode: 5651, level3CatCodeRaw: '5651', level3CatFr: 'SA coopérative de consommation à directoire' },
  { level3CatCode: 5652, level3CatCodeRaw: '5652', level3CatFr: 'SA coopérative de commerçants-détaillants à directoire' },
  { level3CatCode: 5653, level3CatCodeRaw: '5653', level3CatFr: 'SA coopérative artisanale à directoire' },
  { level3CatCode: 5654, level3CatCodeRaw: '5654', level3CatFr: 'SA coopérative d\'intérêt maritime à directoire' },
  { level3CatCode: 5655, level3CatCodeRaw: '5655', level3CatFr: 'SA coopérative de transport à directoire' },
  { level3CatCode: 5658, level3CatCodeRaw: '5658', level3CatFr: 'SA coopérative de production (SCOP) à directoire' },
  { level3CatCode: 5659, level3CatCodeRaw: '5659', level3CatFr: 'SA union de sociétés coopératives à directoire' },
  { level3CatCode: 5660, level3CatCodeRaw: '5660', level3CatFr: 'Autre SA coopérative à directoire' },
  { level3CatCode: 5670, level3CatCodeRaw: '5670', level3CatFr: 'Société de Participations Financières de Profession Libérale Société anonyme à Directoire (SPFPL SA à directoire)' },
  { level3CatCode: 5685, level3CatCodeRaw: '5685', level3CatFr: 'Société d\'exercice libéral à forme anonyme à directoire' },
  { level3CatCode: 5699, level3CatCodeRaw: '5699', level3CatFr: 'SA à directoire (s.a.i.)' },
  { level3CatCode: 5710, level3CatCodeRaw: '5710', level3CatFr: 'SAS, société par actions simplifiée' },
  { level3CatCode: 5770, level3CatCodeRaw: '5770', level3CatFr: 'Société de Participations Financières de Profession Libérale Société par actions simplifiée (SPFPL SAS)' },
  { level3CatCode: 5785, level3CatCodeRaw: '5785', level3CatFr: 'Société d\'exercice libéral par action simplifiée' },
  { level3CatCode: 5800, level3CatCodeRaw: '5800', level3CatFr: 'Société européenne' },
  { level3CatCode: 6100, level3CatCodeRaw: '6100', level3CatFr: 'Caisse d\'Épargne et de Prévoyance' },
  { level3CatCode: 6210, level3CatCodeRaw: '6210', level3CatFr: 'Groupement européen d\'intérêt économique (GEIE)' },
  { level3CatCode: 6220, level3CatCodeRaw: '6220', level3CatFr: 'Groupement d\'intérêt économique (GIE)' },
  { level3CatCode: 6316, level3CatCodeRaw: '6316', level3CatFr: 'Coopérative d\'utilisation de matériel agricole en commun (CUMA)' },
  { level3CatCode: 6317, level3CatCodeRaw: '6317', level3CatFr: 'Société coopérative agricole' },
  { level3CatCode: 6318, level3CatCodeRaw: '6318', level3CatFr: 'Union de sociétés coopératives agricoles' },
  { level3CatCode: 6411, level3CatCodeRaw: '6411', level3CatFr: 'Société d\'assurance à forme mutuelle' },
  { level3CatCode: 6511, level3CatCodeRaw: '6511', level3CatFr: 'Sociétés Interprofessionnelles de Soins Ambulatoires' },
  { level3CatCode: 6521, level3CatCodeRaw: '6521', level3CatFr: 'Société civile de placement collectif immobilier (SCPI)' },
  { level3CatCode: 6532, level3CatCodeRaw: '6532', level3CatFr: 'Société civile d\'intérêt collectif agricole (SICA)' },
  { level3CatCode: 6533, level3CatCodeRaw: '6533', level3CatFr: 'Groupement agricole d\'exploitation en commun (GAEC)' },
  { level3CatCode: 6534, level3CatCodeRaw: '6534', level3CatFr: 'Groupement foncier agricole' },
  { level3CatCode: 6535, level3CatCodeRaw: '6535', level3CatFr: 'Groupement agricole foncier' },
  { level3CatCode: 6536, level3CatCodeRaw: '6536', level3CatFr: 'Groupement forestier' },
  { level3CatCode: 6537, level3CatCodeRaw: '6537', level3CatFr: 'Groupement pastoral' },
  { level3CatCode: 6538, level3CatCodeRaw: '6538', level3CatFr: 'Groupement foncier et rural' },
  { level3CatCode: 6539, level3CatCodeRaw: '6539', level3CatFr: 'Société civile foncière' },
  { level3CatCode: 6540, level3CatCodeRaw: '6540', level3CatFr: 'Société civile immobilière' },
  { level3CatCode: 6541, level3CatCodeRaw: '6541', level3CatFr: 'Société civile immobilière de construction-vente' },
  { level3CatCode: 6542, level3CatCodeRaw: '6542', level3CatFr: 'Société civile d\'attribution' },
  { level3CatCode: 6543, level3CatCodeRaw: '6543', level3CatFr: 'Société civile coopérative de construction' },
  { level3CatCode: 6544, level3CatCodeRaw: '6544', level3CatFr: 'Société civile immobilière d\' accession progressive à la propriété' },
  { level3CatCode: 6551, level3CatCodeRaw: '6551', level3CatFr: 'Société civile coopérative de consommation' },
  { level3CatCode: 6554, level3CatCodeRaw: '6554', level3CatFr: 'Société civile coopérative d\'intérêt maritime' },
  { level3CatCode: 6558, level3CatCodeRaw: '6558', level3CatFr: 'Société civile coopérative entre médecins' },
  { level3CatCode: 6560, level3CatCodeRaw: '6560', level3CatFr: 'Autre société civile coopérative' },
  { level3CatCode: 6561, level3CatCodeRaw: '6561', level3CatFr: 'SCP d\'avocats' },
  { level3CatCode: 6562, level3CatCodeRaw: '6562', level3CatFr: 'SCP d\'avocats aux conseils' },
  { level3CatCode: 6563, level3CatCodeRaw: '6563', level3CatFr: 'SCP d\'avoués d\'appel' },
  { level3CatCode: 6564, level3CatCodeRaw: '6564', level3CatFr: 'SCP d\'huissiers' },
  { level3CatCode: 6565, level3CatCodeRaw: '6565', level3CatFr: 'SCP de notaires' },
  { level3CatCode: 6566, level3CatCodeRaw: '6566', level3CatFr: 'SCP de commissaires-priseurs' },
  { level3CatCode: 6567, level3CatCodeRaw: '6567', level3CatFr: 'SCP de greffiers de tribunal de commerce' },
  { level3CatCode: 6568, level3CatCodeRaw: '6568', level3CatFr: 'SCP de conseils juridiques' },
  { level3CatCode: 6569, level3CatCodeRaw: '6569', level3CatFr: 'SCP de commissaires aux comptes' },
  { level3CatCode: 6571, level3CatCodeRaw: '6571', level3CatFr: 'SCP de médecins' },
  { level3CatCode: 6572, level3CatCodeRaw: '6572', level3CatFr: 'SCP de dentistes' },
  { level3CatCode: 6573, level3CatCodeRaw: '6573', level3CatFr: 'SCP d\'infirmiers' },
  { level3CatCode: 6574, level3CatCodeRaw: '6574', level3CatFr: 'SCP de masseurs-kinésithérapeutes' },
  { level3CatCode: 6575, level3CatCodeRaw: '6575', level3CatFr: 'SCP de directeurs de laboratoire d\'analyse médicale' },
  { level3CatCode: 6576, level3CatCodeRaw: '6576', level3CatFr: 'SCP de vétérinaires' },
  { level3CatCode: 6577, level3CatCodeRaw: '6577', level3CatFr: 'SCP de géomètres experts' },
  { level3CatCode: 6578, level3CatCodeRaw: '6578', level3CatFr: 'SCP d\'architectes' },
  { level3CatCode: 6585, level3CatCodeRaw: '6585', level3CatFr: 'Autre société civile professionnelle' },
  { level3CatCode: 6589, level3CatCodeRaw: '6589', level3CatFr: 'Société civile de moyens' },
  { level3CatCode: 6595, level3CatCodeRaw: '6595', level3CatFr: 'Caisse locale de crédit mutuel' },
  { level3CatCode: 6596, level3CatCodeRaw: '6596', level3CatFr: 'Caisse de crédit agricole mutuel' },
  { level3CatCode: 6597, level3CatCodeRaw: '6597', level3CatFr: 'Société civile d\'exploitation agricole' },
  { level3CatCode: 6598, level3CatCodeRaw: '6598', level3CatFr: 'Exploitation agricole à responsabilité limitée' },
  { level3CatCode: 6599, level3CatCodeRaw: '6599', level3CatFr: 'Autre société civile' },
  { level3CatCode: 6901, level3CatCodeRaw: '6901', level3CatFr: 'Autre personne de droit privé inscrite au registre du commerce et des sociétés' },
  { level3CatCode: 7111, level3CatCodeRaw: '7111', level3CatFr: 'Autorité constitutionnelle' },
  { level3CatCode: 7112, level3CatCodeRaw: '7112', level3CatFr: 'Autorité administrative ou publique indépendante' },
  { level3CatCode: 7113, level3CatCodeRaw: '7113', level3CatFr: 'Ministère' },
  { level3CatCode: 7120, level3CatCodeRaw: '7120', level3CatFr: 'Service central d\'un ministère' },
  { level3CatCode: 7150, level3CatCodeRaw: '7150', level3CatFr: 'Service du ministère de la Défense' },
  { level3CatCode: 7160, level3CatCodeRaw: '7160', level3CatFr: 'Service déconcentré à compétence nationale d\'un ministère (hors Défense)' },
  { level3CatCode: 7171, level3CatCodeRaw: '7171', level3CatFr: 'Service déconcentré de l\'État à compétence (inter) régionale' },
  { level3CatCode: 7172, level3CatCodeRaw: '7172', level3CatFr: 'Service déconcentré de l\'État à compétence (inter) départementale' },
  { level3CatCode: 7179, level3CatCodeRaw: '7179', level3CatFr: '(Autre) Service déconcentré de l\'État à compétence territoriale' },
  { level3CatCode: 7190, level3CatCodeRaw: '7190', level3CatFr: 'Ecole nationale non dotée de la personnalité morale' },
  { level3CatCode: 7210, level3CatCodeRaw: '7210', level3CatFr: 'Commune et commune nouvelle' },
  { level3CatCode: 7220, level3CatCodeRaw: '7220', level3CatFr: 'Département' },
  { level3CatCode: 7225, level3CatCodeRaw: '7225', level3CatFr: 'Collectivité et territoire d\'Outre Mer' },
  { level3CatCode: 7229, level3CatCodeRaw: '7229', level3CatFr: '(Autre) Collectivité territoriale' },
  { level3CatCode: 7230, level3CatCodeRaw: '7230', level3CatFr: 'Région' },
  { level3CatCode: 7312, level3CatCodeRaw: '7312', level3CatFr: 'Commune associée et commune déléguée' },
  { level3CatCode: 7313, level3CatCodeRaw: '7313', level3CatFr: 'Section de commune' },
  { level3CatCode: 7314, level3CatCodeRaw: '7314', level3CatFr: 'Ensemble urbain' },
  { level3CatCode: 7321, level3CatCodeRaw: '7321', level3CatFr: 'Association syndicale autorisée' },
  { level3CatCode: 7322, level3CatCodeRaw: '7322', level3CatFr: 'Association foncière urbaine' },
  { level3CatCode: 7323, level3CatCodeRaw: '7323', level3CatFr: 'Association foncière de remembrement' },
  { level3CatCode: 7331, level3CatCodeRaw: '7331', level3CatFr: 'Établissement public local d\'enseignement' },
  { level3CatCode: 7340, level3CatCodeRaw: '7340', level3CatFr: 'Pôle métropolitain' },
  { level3CatCode: 7341, level3CatCodeRaw: '7341', level3CatFr: 'Secteur de commune' },
  { level3CatCode: 7342, level3CatCodeRaw: '7342', level3CatFr: 'District urbain' },
  { level3CatCode: 7343, level3CatCodeRaw: '7343', level3CatFr: 'Communauté urbaine' },
  { level3CatCode: 7344, level3CatCodeRaw: '7344', level3CatFr: 'Métropole' },
  { level3CatCode: 7345, level3CatCodeRaw: '7345', level3CatFr: 'Syndicat intercommunal à vocation multiple (SIVOM)' },
  { level3CatCode: 7346, level3CatCodeRaw: '7346', level3CatFr: 'Communauté de communes' },
  { level3CatCode: 7347, level3CatCodeRaw: '7347', level3CatFr: 'Communauté de villes' },
  { level3CatCode: 7348, level3CatCodeRaw: '7348', level3CatFr: 'Communauté d\'agglomération' },
  { level3CatCode: 7349, level3CatCodeRaw: '7349', level3CatFr: 'Autre établissement public local de coopération non spécialisé ou entente' },
  { level3CatCode: 7351, level3CatCodeRaw: '7351', level3CatFr: 'Institution interdépartementale ou entente' },
  { level3CatCode: 7352, level3CatCodeRaw: '7352', level3CatFr: 'Institution interrégionale ou entente' },
  { level3CatCode: 7353, level3CatCodeRaw: '7353', level3CatFr: 'Syndicat intercommunal à vocation unique (SIVU)' },
  { level3CatCode: 7354, level3CatCodeRaw: '7354', level3CatFr: 'Syndicat mixte fermé' },
  { level3CatCode: 7355, level3CatCodeRaw: '7355', level3CatFr: 'Syndicat mixte ouvert' },
  { level3CatCode: 7356, level3CatCodeRaw: '7356', level3CatFr: 'Commission syndicale pour la gestion des biens indivis des communes' },
  { level3CatCode: 7357, level3CatCodeRaw: '7357', level3CatFr: 'Pôle d\'équilibre territorial et rural (PETR)' },
  { level3CatCode: 7361, level3CatCodeRaw: '7361', level3CatFr: 'Centre communal d\'action sociale' },
  { level3CatCode: 7362, level3CatCodeRaw: '7362', level3CatFr: 'Caisse des écoles' },
  { level3CatCode: 7363, level3CatCodeRaw: '7363', level3CatFr: 'Caisse de crédit municipal' },
  { level3CatCode: 7364, level3CatCodeRaw: '7364', level3CatFr: 'Établissement d\'hospitalisation' },
  { level3CatCode: 7365, level3CatCodeRaw: '7365', level3CatFr: 'Syndicat inter hospitalier' },
  { level3CatCode: 7366, level3CatCodeRaw: '7366', level3CatFr: 'Établissement public local social et médico-social' },
  { level3CatCode: 7367, level3CatCodeRaw: '7367', level3CatFr: 'Centre Intercommunal d\'action sociale (CIAS)' },
  { level3CatCode: 7371, level3CatCodeRaw: '7371', level3CatFr: 'Office public d\'habitation à loyer modéré (OPHLM)' },
  { level3CatCode: 7372, level3CatCodeRaw: '7372', level3CatFr: 'Service départemental d\'incendie et de secours (SDIS)' },
  { level3CatCode: 7373, level3CatCodeRaw: '7373', level3CatFr: 'Établissement public local culturel' },
  { level3CatCode: 7378, level3CatCodeRaw: '7378', level3CatFr: 'Régie d\'une collectivité locale à caractère administratif' },
  { level3CatCode: 7379, level3CatCodeRaw: '7379', level3CatFr: '(Autre) Établissement public administratif local' },
  { level3CatCode: 7381, level3CatCodeRaw: '7381', level3CatFr: 'Organisme consulaire' },
  { level3CatCode: 7382, level3CatCodeRaw: '7382', level3CatFr: 'Établissement public national ayant fonction d\'administration centrale' },
  { level3CatCode: 7383, level3CatCodeRaw: '7383', level3CatFr: 'Établissement public national à caractère scientifique culturel et professionnel' },
  { level3CatCode: 7384, level3CatCodeRaw: '7384', level3CatFr: 'Autre établissement public national d\'enseignement' },
  { level3CatCode: 7385, level3CatCodeRaw: '7385', level3CatFr: 'Autre établissement public national administratif à compétence territoriale limitée' },
  { level3CatCode: 7389, level3CatCodeRaw: '7389', level3CatFr: 'Établissement public national à caractère administratif' },
  { level3CatCode: 7410, level3CatCodeRaw: '7410', level3CatFr: 'Groupement d\'intérêt public (GIP)' },
  { level3CatCode: 7430, level3CatCodeRaw: '7430', level3CatFr: 'Établissement public des cultes d\'Alsace-Lorraine' },
  { level3CatCode: 7450, level3CatCodeRaw: '7450', level3CatFr: 'Etablissement public administratif, cercle et foyer dans les armées' },
  { level3CatCode: 7470, level3CatCodeRaw: '7470', level3CatFr: 'Groupement de coopération sanitaire à gestion publique' },
  { level3CatCode: 7490, level3CatCodeRaw: '7490', level3CatFr: 'Autre personne morale de droit administratif' },
  { level3CatCode: 8110, level3CatCodeRaw: '8110', level3CatFr: 'Régime général de la Sécurité Sociale' },
  { level3CatCode: 8120, level3CatCodeRaw: '8120', level3CatFr: 'Régime spécial de Sécurité Sociale' },
  { level3CatCode: 8130, level3CatCodeRaw: '8130', level3CatFr: 'Institution de retraite complémentaire' },
  { level3CatCode: 8140, level3CatCodeRaw: '8140', level3CatFr: 'Mutualité sociale agricole' },
  { level3CatCode: 8150, level3CatCodeRaw: '8150', level3CatFr: 'Régime maladie des non-salariés non agricoles' },
  { level3CatCode: 8160, level3CatCodeRaw: '8160', level3CatFr: 'Régime vieillesse ne dépendant pas du régime général de la Sécurité Sociale' },
  { level3CatCode: 8170, level3CatCodeRaw: '8170', level3CatFr: 'Régime d\'assurance chômage' },
  { level3CatCode: 8190, level3CatCodeRaw: '8190', level3CatFr: 'Autre régime de prévoyance sociale' },
  { level3CatCode: 8210, level3CatCodeRaw: '8210', level3CatFr: 'Mutuelle' },
  { level3CatCode: 8250, level3CatCodeRaw: '8250', level3CatFr: 'Assurance mutuelle agricole' },
  { level3CatCode: 8290, level3CatCodeRaw: '8290', level3CatFr: 'Autre organisme mutualiste' },
  { level3CatCode: 8310, level3CatCodeRaw: '8310', level3CatFr: 'Comité social économique d\'entreprise' },
  { level3CatCode: 8311, level3CatCodeRaw: '8311', level3CatFr: 'Comité social économique d\'établissement' },
  { level3CatCode: 8410, level3CatCodeRaw: '8410', level3CatFr: 'Syndicat de salariés' },
  { level3CatCode: 8420, level3CatCodeRaw: '8420', level3CatFr: 'Syndicat patronal' },
  { level3CatCode: 8450, level3CatCodeRaw: '8450', level3CatFr: 'Ordre professionnel ou assimilé' },
  { level3CatCode: 8470, level3CatCodeRaw: '8470', level3CatFr: 'Centre technique industriel ou comité professionnel du développement économique' },
  { level3CatCode: 8490, level3CatCodeRaw: '8490', level3CatFr: 'Autre organisme professionnel' },
  { level3CatCode: 8510, level3CatCodeRaw: '8510', level3CatFr: 'Institution de prévoyance' },
  { level3CatCode: 8520, level3CatCodeRaw: '8520', level3CatFr: 'Institution de retraite supplémentaire' },
  { level3CatCode: 9110, level3CatCodeRaw: '9110', level3CatFr: 'Syndicat de copropriété' },
  { level3CatCode: 9150, level3CatCodeRaw: '9150', level3CatFr: 'Association syndicale libre' },
  { level3CatCode: 9210, level3CatCodeRaw: '9210', level3CatFr: 'Association non déclarée' },
  { level3CatCode: 9220, level3CatCodeRaw: '9220', level3CatFr: 'Association déclarée' },
  { level3CatCode: 9221, level3CatCodeRaw: '9221', level3CatFr: 'Association déclarée d\'insertion par l\'économique' },
  { level3CatCode: 9222, level3CatCodeRaw: '9222', level3CatFr: 'Association intermédiaire' },
  { level3CatCode: 9223, level3CatCodeRaw: '9223', level3CatFr: 'Groupement d\'employeurs' },
  { level3CatCode: 9224, level3CatCodeRaw: '9224', level3CatFr: 'Association d\'avocats à responsabilité professionnelle individuelle' },
  { level3CatCode: 9230, level3CatCodeRaw: '9230', level3CatFr: 'Association déclarée, reconnue d\'utilité publique' },
  { level3CatCode: 9240, level3CatCodeRaw: '9240', level3CatFr: 'Congrégation' },
  { level3CatCode: 9260, level3CatCodeRaw: '9260', level3CatFr: 'Association de droit local (Bas-Rhin, Haut-Rhin et Moselle)' },
  { level3CatCode: 9300, level3CatCodeRaw: '9300', level3CatFr: 'Fondation' },
  { level3CatCode: 9900, level3CatCodeRaw: '9900', level3CatFr: 'Autre personne morale de droit privé' },
  { level3CatCode: 9970, level3CatCodeRaw: '9970', level3CatFr: 'Groupement de coopération sanitaire à gestion privée' }
]

export const getLegalCategoryLevel3ByCode = (code :number) => legalCategoryLevel3.find(l => l.level3CatCode === code)

export const getLegalCategoryLevel2FromLevel3 = (level3CatCodeRaw: string) => {
  if (!level3CatCodeRaw || level3CatCodeRaw.length < 2) return null

  return legalCategoryLevel2.find(c => c.level2CatCode === Number(level3CatCodeRaw[0]))
}

export const getLegalCategoryTreeFromLevel3 = (level3CatCodeRaw: string) => {
  if (!level3CatCodeRaw || level3CatCodeRaw.length < 2) return null
  
  return {
    ... legalCategoryLevel1.find(c => c.level1CatCode === Number(level3CatCodeRaw.toString()[0])),
    ... legalCategoryLevel2.find(c => c.level2CatCode === Number(level3CatCodeRaw.toString().slice(0, 2)))
  }
}

export const legalOldCategories = [
  {
    value: 'SASU',
    label: 'SASU - Société par action simplifiée unipersonnelle',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 57),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 5710)
  },
  {
    value: 'SARL',
    label: 'SARL - Société à responsabilité limitée',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 54),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 5499)
  },
  {
    value: 'SAS',
    label: 'SAS - Société par action simplifiée',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 57),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 5710)
  },
  {
    value: 'EI/EIRL',
    label: 'EI/EIRL - Entrepreneur individuel (auto/micro-entrepreneur, freelance)',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 10),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 1000)
  },
  {
    value: 'EURL',
    label: 'EURL - Entreprise unipersonnelle à responsabilité limitée',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 54),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 5499)
  },
  {
    value: 'LIBERAL',
    label: 'Profession libérale',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 54),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 5485)
  },
  {
    value: 'ARTISAN',
    label: 'Artisan',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 54),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 5499)
  },
  {
    value: 'SCI',
    label: 'SCI - Société civile immobilière',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 65),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 6540)
  },
  {
    value: 'SC',
    label: 'SC - Autre société civile',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 65),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 6599)
  },
  {
    value: 'SA',
    label: 'SA - Société anonyme à conseil d\'administration',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 55),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 5599)
  },
  {
    value: 'SNC',
    label: 'SNC - Société en nom collectif',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 52),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 5202)
  },
  {
    value: 'SCA',
    label: 'SCA - Société en commandite',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 43),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 5308)
  },
  {
    value: 'SUCCURSALE',
    label: 'Succursale de société italienne, espagnole ou allemande',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 31),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 3110)
  },
  {
    value: 'GIE',
    label: 'GIE - Groupement d\'intérêt économique',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 62),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 6220)
  },
  {
    value: 'FONCIERE',
    label: 'Association foncière de remembrement',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 73),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 7323)
  },
  {
    value: 'ASSO-AVOCATS',
    label: 'Association d\'avocats à responsabilité professionnelle individuelle',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 92),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 9224)
  },
  {
    value: 'ASSO-DECLAREE',
    label: 'Association déclarée',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 92),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 9220)
  },
  {
    value: 'ASSO-DECLAREE-2',
    label: 'Association déclarée (entreprises d\'insertion par l\'économique)',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 92),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 9221)
  },
  {
    value: 'ASSO-DECLAREE-3',
    label: 'Association déclarée reconnue d\'utilité publique',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 92),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 9230)
  },
  {
    value: 'ASSO-INTER',
    label: 'Association intermédiaire',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 92),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 9222)
  },
  {
    value: 'SYND-1',
    label: 'Association syndicale autorisée',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 73),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 7321)
  },
  {
    value: 'SYND-2',
    label: 'Association syndicale libre',
    level2: legalCategoryLevel2.find(c => c.level2CatCode === 91),
    level3: legalCategoryLevel3.find(c => c.level3CatCode === 9150)
  }
]
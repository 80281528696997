<script lang="ts">
  import { onDestroy, onMount } from 'svelte'
  import { t } from '$core/lib/i18n/i18nextWrapper'
  import { get, type Unsubscriber } from 'svelte/store'
  import { BBBTransaction, type BBBTransactionsResponse } from '../models/bbb-transactions-model'
  import Loader from '../../core-app/lib/ui-kit/Loader.svelte'
  import AgGrid from '../../core-app/lib/ui-kit/AgGrid/AgGridDatable.svelte'
  import { WorkspaceStore } from '$crm/stores/workspace.store'
  import SearchBar from '../../core-app/lib/ui-kit/SearchBar.svelte'
  import type { ColDef, GridOptions, GridReadyEvent, RowClickedEvent } from 'ag-grid-community'
  import { RowNode, GridApi } from 'ag-grid-community'
  import { eventsManager } from '$core/events/event-manager'
  import { EventType } from '$core/events/event-type'
  import TransactionSidebar from '../components/cash-application/TransactionSidebar.svelte'
  import { TransactionsStore } from '../stores/transactions.store'
  import { transactionsColumnDefs } from '../lib/transactions/ag-grid/transactions-column-definitions-ag-grid'
  import { bankingTransactionsService } from '../services/banking-transactions.service'
  import { featureToggling } from '$config/feature-toggling'
  import {
    calculateCashBurnForCurrentMonthAlongWithVariationToPreviousMonth,
    calculateExpensesForCurrentMonthAlongWithVariationToPreviousMonth,
    calculateRevenueForCurrentMonthAlongWithVariationToPreviousMonth,
    generatePeriodDescription,
    returnMostUsedCurrencyInTransactionsAsSymbol
  } from '$cash/services/transactions-statistics-pure-functions'
  import { createCurrentDateObjectAccordingToUserTimezone } from '$core/util/date-utils'
  import { getUserCompanyTimezone } from '$core/util/timezone-utils'
  import TransactionsHUD from '../components/stats/TransactionsHUD.svelte'
  import type { PeriodDescription } from '$core/models/dashboard-statistics-analytics-model'
  import {
    ValueForCurrentMonthAlongWithVariationMonthToMonth
  } from '$core/models/dashboard-statistics-analytics-model'
  import BankAccountNotConfiguredModule from '../components/cash-application/BankAccountNotConfiguredModule.svelte'
  import mixpanel from 'mixpanel-browser'
  import BankWall from '$src/core-app/components/BankWall.svelte'
  import { bridgeByBankingService } from "$pay/services/bridge-by-banking.service";
  import type { DundyEvent } from "$dundy/events/dundy-event";

  /** Local declarations */
  let isLoading: boolean = true
  let searchValue: string = ''
  let collapsed: boolean = false
  let transactions: BBBTransaction[] = []
  let allTransactionsForThisAccount: BBBTransaction[] = []
  let positiveTransactionsForThisAccount: BBBTransaction[] = []
  let hasLoadedTransactions: boolean = false

  /** Set columns Definitions for AG GRID component */
  let columnDefs: ColDef[] = <ColDef[]>transactionsColumnDefs

  /** Get user timezone */
  const userTimezone = getUserCompanyTimezone(get(WorkspaceStore))

  /** Create Current Date Object according to user timezone */
  const dateInTimezone = createCurrentDateObjectAccordingToUserTimezone(userTimezone)

  /** Generate period description */
  const periodDescription: PeriodDescription = generatePeriodDescription(dateInTimezone)

  /** Initiate Empty Revenue Object */
  let calculatedRevenueForCurrentMonthWithVariation: ValueForCurrentMonthAlongWithVariationMonthToMonth

  /** Initiate Empty Expenses Object */
  let calculatedExpensesForCurrentMonthWithVariation: ValueForCurrentMonthAlongWithVariationMonthToMonth

  /** Initiate Empty Cash Burn Object */
  let calculatedCashBurnForCurrenMonthWithVariation: ValueForCurrentMonthAlongWithVariationMonthToMonth

  /** Init Main Currency In Transactions */
  let mainCurrencyInTransactions: string | null
  let selectedNode: RowNode | null = null
  let unSubscribeTransaction: Unsubscriber | null

  let hasBankNeverBeenLinkedOrBankLinkExpired: boolean
  let hasBankLinkExpired: boolean

  /**
   * Set AG GRID onGridReady event
   * @param params
   */
  const onGridReady = (params: GridReadyEvent): void => {
    // Following line to make the currently visible columns fit the screen
    params.api.sizeColumnsToFit()
    // Following line dynamic set height to row on content
    params.api.resetRowHeights()
    window.addEventListener('scroll', scrollHeader)
  }

  /**
   * Scroll header
   */
  const scrollHeader = () => {
    const header = document.querySelector('.ag-header') as HTMLElement
    if (window.scrollY > 500) {
      header.classList.add('ag-sticky-header')
    } else {
      header.classList.remove('ag-sticky-header')
    }
  }

  /** Set AG GRID options */
  const gridOptions: GridOptions = <GridOptions>{
    defaultColDef: {
      sortable: true,
      resizable: false,
      width: 120
    },
    rowHeight: 48,
    domLayout: 'autoHeight', // autoHeight or normal (fixed)
    singleClickEdit: false,
    suppressClickEdit: false,
    suppressRowClickSelection: true,
    suppressRowHoverHighlight: true,
    debug: true,
    enableRangeSelection: false,
    rowSelection: 'single',
    pagination: true,
    paginationPageSize: 50,
    suppressPaginationPanel: true,
    onRowClicked: function (params: RowClickedEvent) {
      if (featureToggling().isKchapEnable) {
        handleRowClicked(params, params.api)
      } else {
        /* console.log('isKchapEnable=', featureToggling().isKchapEnable) */
      }
    }
  }

  /**
   * Handle row click event
   * listens for the Event from DynamicTable close button
   * gets the clicked row node and column API
   *
   * @param params
   * @param api
   */
  function handleRowClicked(params: any, api: GridApi): void {
    const searchBar: HTMLElement | null = document.getElementById('transactions-search-bar-container')
    const agGridWrapper: HTMLElement | null = document.getElementById('ag-grid-wrapper')

    if (searchBar && agGridWrapper) {
      eventsManager.on(EventType.TRANSACTIONS_CLOSE_PANEL, () => {
        if (selectedNode) {
          api.setColumnVisible('amount', true)
          api.setColumnVisible('account_id', true)
          searchBar.style.width = 'calc(100%)'
          agGridWrapper.style.width = 'calc(100%)'
          collapsed = false
          selectedNode = null
        }
      }, 'Transactions.svelte')

      const clickedNode = params.node

      eventsManager.emit(EventType.TRANSACTIONS_ROW_SELECTED, {
        transaction: params.data
      }, 'Transactions.svelte')

      // Hide two columns
      api.setColumnVisible('amount', false)
      api.setColumnVisible('account_id', false)
      searchBar.style.width = 'calc(100% - 392px)'
      agGridWrapper.style.width = 'calc(100% - 392px)'
      collapsed = true

      // Close the overlay and show all columns when the same row is clicked again
      if (clickedNode === selectedNode) {
        api.setColumnVisible('amount', true)
        api.setColumnVisible('account_id', true)
        searchBar.style.width = '100%'
        agGridWrapper.style.width = '100%'
        collapsed = false
        selectedNode = null
      } else {
        if (selectedNode) {
          selectedNode.setSelected(false)
        }
        clickedNode.setSelected(true)
        selectedNode = clickedNode
      }
    }
  }


  onMount(() => {
    hasLoadedTransactions = false
    eventsManager.once<BBBTransactionsResponse>(EventType.TRANSACTIONS_LIST_FETCHED, (e: DundyEvent<BBBTransactionsResponse>) => {
      isLoading = false
    }, 'transactionsService')

    mixpanel.track('GA10 Bank Transactions Page', {
      'Description': 'Open Transactions.svelte'
    })
    isLoading = true

    hasBankNeverBeenLinkedOrBankLinkExpired = bridgeByBankingService.hasBankNeverBeenLinkedOrBankLinkExpired($WorkspaceStore?.bankConfig)
    hasBankLinkExpired = bridgeByBankingService.hasBankLinkExpired($WorkspaceStore?.bankConfig)

    if (!!unSubscribeTransaction) {
      unSubscribeTransaction()
      unSubscribeTransaction = null
    }

    unSubscribeTransaction = TransactionsStore.subscribe(() => {
      if(!get(TransactionsStore)) {
        hasLoadedTransactions = false
      } else {
        hasLoadedTransactions = true
      }
      if (get(TransactionsStore)?.transactions?.length > 0) {
        transactions = get(TransactionsStore).transactions
        mainCurrencyInTransactions = returnMostUsedCurrencyInTransactionsAsSymbol(transactions)

        const trans: {
          allForThisAccount: BBBTransaction[],
          positiveForThisAccount: BBBTransaction[]
        } = bankingTransactionsService.retrieveTransactionsAndReturnOnlyPositiveTransactions(transactions)
        allTransactionsForThisAccount = trans.allForThisAccount
        positiveTransactionsForThisAccount = trans.positiveForThisAccount

        calculatedRevenueForCurrentMonthWithVariation = calculateRevenueForCurrentMonthAlongWithVariationToPreviousMonth(transactions, dateInTimezone)
        calculatedExpensesForCurrentMonthWithVariation = calculateExpensesForCurrentMonthAlongWithVariationToPreviousMonth(transactions, dateInTimezone)
        calculatedCashBurnForCurrenMonthWithVariation = calculateCashBurnForCurrentMonthAlongWithVariationToPreviousMonth(transactions, dateInTimezone)
      }

      if (get(TransactionsStore)) isLoading = false
    })
  })

  onDestroy(() => {
    if (!!unSubscribeTransaction) {
      unSubscribeTransaction()
      unSubscribeTransaction = null
    }
    window.removeEventListener('scroll', scrollHeader)
  })

</script>

<svelte:head>
  <title>{t('topMenu.nav.transactions')} - Dundy</title>
  <style lang="postcss">
    body {
      @apply overflow-x-hidden;
    }
  </style>
</svelte:head>

{#if hasBankNeverBeenLinkedOrBankLinkExpired}
  <div class="background">
    <img src="/img/bank-wall/bg-transactions.png" alt="transactions" />
    <BankWall deprecated={hasBankLinkExpired}/>
  </div>
{:else}
  <main class="flex flex-col items-center relative">
    <div class="flex w-full flex-col flex-1">
      <div class="mb-10">
        <h1 class="text-black text-3xl font-bold">{t('transactions.pageTitle')}</h1>
      </div>
      {#if isLoading}
        <div class="grid mt-12 place-items-center w-full z-[100]">
          <Loader i18nKey="invoices.fetching"/>
        </div>
      {:else if !isLoading && positiveTransactionsForThisAccount.length === 0}
        <BankAccountNotConfiguredModule hasTransactions={allTransactionsForThisAccount?.length > 0} hasLoadedTransactions={hasLoadedTransactions}/>
      {:else if !isLoading && positiveTransactionsForThisAccount.length > 0}
        <TransactionsHUD
          mainCurrencyInTransactions={mainCurrencyInTransactions}
          userTimezone={userTimezone}
          periodDescription={periodDescription}
          calculatedRevenueForCurrentMonthWithVariation={calculatedRevenueForCurrentMonthWithVariation}
          calculatedExpensesForCurrentMonthWithVariation={calculatedExpensesForCurrentMonthWithVariation}
          calculatedCashBurnForCurrenMonthWithVariation={calculatedCashBurnForCurrenMonthWithVariation}
        />

        <div class="w-full mt-6 my-2">
          <SearchBar
            id="transactions-search-bar"
            hideLabel={true}
            bind:value={searchValue}/>
        </div>
        <div class="ag-grid-wrapper"
             id="ag-grid-wrapper">
          <AgGrid agGridTableData={positiveTransactionsForThisAccount}
                  thisAgGridComponentLabel="transactions aggrid"
                  bind:searchValue
                  {columnDefs}
                  firstRenderingItemsSelection={()=>{}},
                  userSelectedInvoiceNumbersSet={null}
                  applyUserSelectionOnItemsAsInvoices={false}
                  options={gridOptions}
                  {onGridReady}
                  on:select
          />
        </div>
      {/if}
    </div>
    <TransactionSidebar bind:collapsed={collapsed}/>
  </main>
{/if}

<style global lang="postcss">
  .background {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    height: calc(100vh - 2rem);
  }

  .chart-container {
    width: 95%;
  }

  :global(:root) {
    --grid-height: 500px;
  }

  .block-showing-stats-wrapper {
    @apply border rounded-md;
    box-shadow: 0 0 3px rgba(4, 4, 52, .09), 0 6px 10px rgba(4, 4, 52, .04), 0 10px 16px rgba(4, 4, 52, .02);
  }

  .block-showing-stats {
    @apply mx-auto grid grid-cols-1 gap-px sm:grid-cols-2 lg:grid-cols-3 rounded-t-md bg-black;
  }

  .ag-grid-wrapper {
    @apply flex flex-col w-full mt-5 mb-10 h-full relative;
    transition: all .5s cubic-bezier(.55, 0, .1, 1) .2s;
    will-change: width;
  }

  .ag-theme-alpine {
    transition: all .5s cubic-bezier(.55, 0, .1, 1) .2s;
    will-change: width;
  }

  .ag-theme-alpine .ag-header {
    @apply bg-white border-0 border-t border-zinc-100;
  }

  .ag-theme-alpine .ag-header-viewport {
    @apply bg-white;
  }

  .ag-theme-alpine .ag-row {
    @apply bg-transparent border-0 border-b border-zinc-100 hover:bg-whisper hover:rounded-md;
  }

  .ag-theme-alpine .ag-row-hover {
    @apply bg-whisper;
  }

  /* ROW SELECTION */
  .ag-theme-alpine .ag-row-selected {
    @apply bg-whisper;
  }

  /* RANGE SELECTION */
  .ag-theme-alpine .ag-cell-range-selected:not(.ag-cell-focus),
  .ag-theme-alpine .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-single-cell:not(.ag-cell-inline-editing) {
    @apply bg-white;
  }

  /* RANGE SELECTION ITERSECTION LEVELS */
  .ag-theme-alpine .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
    background-color: rgba(220, 53, 69, 0.2);
  }

  .ag-theme-alpine .ag-header-row {
    @apply bg-white;
  }

  .ag-theme-alpine .ag-row {
    @apply bg-transparent border-0 border-b border-zinc-100 hover:bg-whisper hover:rounded-md;
  }

  .ag-theme-alpine .ag-cell {
    @apply border-0 text-zinc-700 font-light;
    line-height: 48px;
  }

  .ag-theme-alpine .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    @apply border-0;
  }

  .ag-theme-alpine .ag-header-cell {
    @apply border-0 border-b border-zinc-100 bg-white;
  }

  .ag-theme-alpine .ag-column-hover {
    @apply bg-transparent;
  }

  .ag-icon-transaction {
    @apply text-black w-6 h-6 bg-zinc-100 rounded-full mr-2;
  }

  .transaction-title {
    @apply text-black font-normal;
  }

  .bank-amount-of-money {
    @apply text-black font-normal tracking-wide;
  }

  .bank-amount-of-money.positive {
    @apply text-oceanGreen;
  }

  .ag-theme-alpine .ag-paging-panel {
    @apply border-0;
  }

  .ag-icon-transaction-positive {
    @apply bg-zinc-100 text-zinc-500;
  }

  .collapsible {
    transition: all .5s ease;
    transition-delay: 0ms;
    transition-property: opacity, transform;
    z-index: 2;
  }

  .is-collapsed {
    opacity: 0;
    transform: translateX(-432px);
    transition-delay: .1s;
  }
</style>

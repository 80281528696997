<script lang="ts">
  import { t } from '../lib/i18n/i18nextWrapper'
  import { onMount } from 'svelte'
  import { validateEmail } from '../services/validator'
  import { link } from 'svelte-routing'
  import auth from '../lib/auth0authentication/authService.js'
  import AlertError from '../lib/ui-kit/Alert/AlertError.svelte'
  import { Alert } from '../stores/alert.store'
  import { feedbackService } from '../services/feedback.service'
  import { Feedback } from '../models/feedback'
  import TextInput from '../lib/ui-kit/TextInput.svelte'
  import PasswordInput from '../lib/ui-kit/PasswordInput.svelte'
  import type { ErrorWithDescription } from '../models/errors-and-validation'

  /** Local declarations */
  const urlParams: URLSearchParams = new URLSearchParams(window.location.search)
  const verifyEmail: boolean = urlParams.has('verifyEmail')
  let email: string = ''
  let password: string = ''
  let isEmailValid: boolean | '' = ''
  let canSignIn: '' | boolean
  let hasPasswordBeenTouched: boolean = false


  /** Reactive declarations */
  $: isEmailValid = !email || validateEmail(email)
  $: canSignIn = email && isEmailValid && !!(validatePassword(password))

  /**
   * Validate the password
   * Makes sure the password is at least 4 characters long
   * @param password
   */
  function validatePassword(password: string): boolean {
    return password.length > 3
  }

  /**
   * Handle the blur event on the password input
   */
  function handlePasswordBlur() {
    hasPasswordBeenTouched = true
  }

 const getDPI = () => {
   const dpiElement = document.getElementById('dpi')

   return dpiElement?.offsetWidth || 96
 }

  /**
   * Handle the click on the continue button
   */
  function handleClickContinue() {
    localStorage.setItem('dpi', String(getDPI()))

    auth.loginAuth0WithEmailAndPasswordWithCb(
      email,
      password,
      (err: ErrorWithDescription) => {
        if (err) {
          /* console.log(t('signIn.loginFailed'), err.description) */
          /* console.error(err) */
          Alert.open(AlertError, {
            header: t('signIn.loginFailed'),
            message: ''
          })
        }
      },
    )
  }

  /**
   * NB: we cannot clear local storage and
   *  make sure we are logged out here because
   *  this page is also the destination after sign-in
   */
  onMount(() => {
    if (window.location.search !== '?no-logout-first') auth.logout()

    if (verifyEmail) {
      feedbackService.displayFeedback(<Feedback>{
        feedbackLevel: 'Warning',
        feedbackMessage: t('feedback.verifyEmail')
      })
    }
  })
</script>
<svelte:head>
    <style lang="postcss">
        html {
            @apply h-full bg-white;
        }

        body {
            @apply h-full;
        }

        #app {
            @apply h-full;
        }
    </style>
</svelte:head>

<span id="dpi" style="width: 1in; height: 1in; position: absolute; top: -100%"/>

<div class="flex min-h-full">
    <div class="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div class="mx-auto w-full max-w-sm lg:w-96">
            <div>
                <img alt="Dundy.co" class="h-12 w-auto" src="/img/dundy-mark.svg">
                <h2 class="mt-6 text-3xl font-bold tracking-tight text-gray-900">{t('signIn.title')}</h2>
            </div>

            <div class="mt-8">

                <div class="mt-6">
                    <form class="space-y-8"
                          data-cy="sign-in-form"
                          on:submit|preventDefault={handleClickContinue}>
                        <div class="h-16">
                            <TextInput
                                    bind:value={email}
                                    class="w-full"
                                    dataCy="email"
                                    error={t('signIn.errorEmail')}
                                    label={t('signIn.emailLabel')}
                                    placeholder={t('signIn.emailPlaceholder')}
                                    showError={!isEmailValid}
                                    type="email"/>
                        </div>
                        <div class="space-y-1">
                            <div class="h-16">
                                <PasswordInput
                                        bind:value={password}
                                        class="w-full"
                                        dataCy="password"
                                        error={t('signIn.errorPassword')}
                                        label={t('signIn.passwordLabel')}
                                        on:blur={handlePasswordBlur}
                                        showError={hasPasswordBeenTouched && !validatePassword(password)}
                                />
                            </div>
                        </div>

                        <div class="flex items-center justify-end">
                            <div class="text-sm">
                                <a class="font-medium text-dundyOrange hover:text-dundyOrange" href="/reset"
                                   use:link>{t('signIn.forgotPassword')}</a>
                            </div>
                        </div>

                        <div>
                            <button class="btn action-bla primary flex w-full h-12" disabled={!canSignIn}
                                    type="submit">{t('signIn.login')}</button>
                        </div>
                    </form>
                </div>
            </div>

            <div class="divide-y divide-athensGray border-t border-athensGray my-10 py-4" data-ut="onboarding">
                <p class="mt-2 text-sm text-gray-600">
                    {t('signIn.notRegistered')}
                    <a class="font-medium text-dundyOrange" href="/onboarding/start"
                       use:link>{t('signIn.createAccount')}</a>
                </p>
            </div>

        </div>
    </div>
    <div class="relative hidden w-0 flex-1 lg:block bg-black">
        <div class="w-full h-full overflow-hidden text-center text-white py-10 md:py-12 flex flex-col md:flex-row items-center bg-gradient-b-sky ">
            <div class="container w-full mx-auto flex flex-col md:flex-row items-center justify-start sm:justify-center">
                <div class="w-full py-8 pl-10 pr-8 text-center ">
                    <h1 class="text-4xl font-bold mb-2 lg:mb-4">{t('signIn.tagLine')}</h1>
                    <p class="text-lg mb-2 lg:mb-4 opacity-75">{t(('signIn.tagLineDescription'))}</p>
                </div>
            </div>
        </div>

        <!-- <img class="absolute inset-0 h-full w-full object-cover"
              src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
              alt="">-->
    </div>
</div>

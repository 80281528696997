<script lang="ts">
    import { formatCurrencyWithOptions } from '../../../../voxy-app/utils/number-formatting-utils.js'
    import { t } from '../../i18n/i18nextWrapper.js'
    import {
      BalanceOfDealBusinessDocumentBuildingData
    } from '../../../../voxy-app/models/partial-business-document-building-data.model'

    /** Export let */
    export let classes: string
    export let newBalanceBusinessDocument: BalanceOfDealBusinessDocumentBuildingData
</script>

<div class="flex flex-col {classes}">
    <dl class="-my-3 py-4 text-sm leading-6">
        <div>
            <p class="text-sm font-semibold leading-6 text-gray-900"></p>
        </div>

        <div class="flex justify-between gap-x-4 py-3">
            <dd class="flex items-start gap-x-2">
                <p class="flex items-baseline gap-x-1 truncate">
                    <span class="font-bold tracking-tight text-gray-900">{formatCurrencyWithOptions(newBalanceBusinessDocument?.invoicingBalanceLimitsForBalanceInvoice?.dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesOnlyFinalized, t('locales'), newBalanceBusinessDocument?.businessDocument?.currency, true, 'symbol')}</span>
                </p>
            </dd>
        </div>
    </dl>
</div>
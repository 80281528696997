<script lang="ts">
  import {formatCurrencyWithOptions} from '$voxy/utils/number-formatting-utils'
  import {t} from '$core/lib/i18n/i18nextWrapper'
  import StatPill from './StatPill.svelte'
  import {ValueForCurrentMonthAlongWithVariationMonthToMonth} from "$core/models/dashboard-statistics-analytics-model";

  export let title: string
  export let currency: string | null
  export let hasTooltip: boolean = false
  export let data: ValueForCurrentMonthAlongWithVariationMonthToMonth
</script>

<div class="grid-item-wrapper">
  <div class="grid-item-main flex-1">
    <div class="grid-item" aria-label={title}>
      <div class="relative z-10 w-full">
        <div class="w-full flex items-center justify-between">
          <div class="flex items-center space-x-1">
            <dt class="text-snuff truncate -mt-1">{title}</dt>
            {#if hasTooltip}
              <div class="z-[600]">
                <slot name="tooltip"></slot>
              </div>
            {/if}
          </div>
          <StatPill data={data}/>
        </div>
      </div>
      <div>
        <dd class="w-full flex-none text-3xl leading-10 text-white truncate">
          {formatCurrencyWithOptions(data?.currentMonthValue, t('locales'), currency, false, 'symbol')}
        </dd>
        <dd class="w-full flex text-sm leading-6 space-x-1">
          <span class="text-paleSky">{t('transactions.lastMonth')}</span>
          <span
            class="text-white">{formatCurrencyWithOptions(data?.previousMonthValue, t('locales'), currency, false, 'symbol')}</span>
        </dd>
      </div>
    </div>
  </div>
</div>
<style lang="postcss">
  .grid-item-wrapper {
    @apply relative flex flex-row items-baseline justify-between gap-2 px-4 py-6 sm:px-8 shadow-md bg-black flex-auto;
    transition: all 300ms ease-in-out;
    border-radius: 20px;
  }
</style>
<script lang="ts">
  import {t} from '$core/lib/i18n/i18nextWrapper'
  import {afterUpdate, onDestroy, onMount} from 'svelte'
  import {fade} from 'svelte/transition'
  import {type Readable} from 'svelte/store'
  import {navigate} from 'svelte-routing'
  import Datatable from '../../core-app/lib/ui-kit/InvoicesDatatable.svelte'
  import Invoice from '../models/invoice'
  import {WorkspaceStore} from '$crm/stores/workspace.store'
  import SearchBar from '../../core-app/lib/ui-kit/SearchBar.svelte'
  import Loader from '../../core-app/lib/ui-kit/Loader.svelte'
  import type {ColDef, GridOptions} from 'ag-grid-community'
  import {eventsManager} from '$core/events/event-manager'
  import {EventType} from '$core/events/event-type'
  import {CellValueChangeItem} from '../models/cell-value-change-item'
  import {ComputedDataInvoicesStore} from '../stores/computed-data-invoice.store'
  import type ComputedDataInvoice from '../models/computed-data-invoice'
  import {deepClone} from '$core/util/object-deep-cloning'
  import BusinessDocumentListTab from '../../core-app/lib/ui-kit/BusinessDocumentListTab.svelte'
  import {updateNewNavigationHistoryItem} from '$core/stores/navigationHistory.store'
  import MarkPaidOrUnpaidInvoiceButton from '../lib/dunning/buttons/MarkPaidOrUnpaidInvoiceButton.svelte'
  import {DunningInvoicesStore, dunningListViewChoice} from '../stores/dunning-invoices.store'
  import {DunningViewListOption} from '../enums/dunning-view-list-options.js'
  import PauseOrResumeTrackingInvoice from '../lib/dunning/buttons/PauseOrResumeTrackingInvoiceButton.svelte'
  import DunningFilters from '../lib/dunning/filters/DunningFilters.svelte'
  import {AppliedDunningFilters} from '../models/applied-dunning-filters'
  import {agGridCommonService} from '../services/ag-grid-common.service'
  import {dunningFilterService} from '../services/dunning-filter.service'
  import RowContextualMenu from '../lib/dunning/menus/DunningInvoicesRowContextualMenu.svelte'
  import {dunningOptions} from '../lib/dunning/ag-grid/dunning-options-definitions-ag-grid'
  import {dunningColumnDefs} from '../lib/dunning/ag-grid/dunning-columns-definitions-ag-grid'
  import type UIDunningCounts from '../models/dunning-ui-updated-counts'
  import AddDunningInvoiceDropdown from '../lib/dunning/menus/AddDunningInvoiceDropdown.svelte'
  import {DunningInvoicesHelper} from '../helpers/dunning-invoices-helper'
  import DeleteOrRecoverInvoiceButton from '../lib/dunning/buttons/DeleteOrRecoverInvoiceButton.svelte'
  import TableFilter from '../../core-app/lib/ui-kit/TableFilter.svelte'
  import {
    CollateralCalculusForDashboard,
    updateCollateralCalculusOnInvoicesUpdate
  } from '$core/services/dashboard-update-calculus'
  import {CustomersStore} from '$crm/stores/customers.store'
  import type {AgingBalanceContent, AgingBalanceRawMap} from '../models/aging-balance'
  import {
    calculateTotalsForAllClients,
    mapInvoicesAccordingToDualKeyToIDAndTimeSlotCategoryWithAmounts,
    timeslotCategoriesAccordingToDaysDue
  } from '$crm/lib/aging-balance/aging-balance.pure-functions'
  import {getUserCompanyTimezone} from '$core/util/timezone-utils'
  import DunningHUDVisualization from '../lib/dunning/hud/DunningHUDVisualization.svelte'
  import {createDunningFilters} from '../lib/dunning/filters/dunning-filters-definitions'
  import {updateFilterBarStyles} from '../lib/dunning/filters/dunning-filter-bar-functions'
  import mixpanel from 'mixpanel-browser'
  import {deriveMostUsedCurrencyFromStores} from "$core/services/main-currency-identification-from-stores"
  import {SimpleDocumentsStore} from "$voxy/stores/business-documents.store"

  /** Let declarations **/
  let grid: any
  let searchValue: string = ''
  let loading: boolean
  let selectedInvoices: Invoice[] = []
  let invoice: Invoice = new Invoice()
  let currentDisplayedInvoices: Invoice[] = []
  let openFilters: boolean = false
  let activeFilters: boolean[] = []
  let updatedDunningCounts: UIDunningCounts = <UIDunningCounts>{}
  let computedCalculusForDashboard: CollateralCalculusForDashboard
  let scrollContainer: HTMLDivElement
  let canScrollLeft: boolean = false
  let canScrollRight: boolean = false
  const invoicesCurrency: Readable<string> = deriveMostUsedCurrencyFromStores(DunningInvoicesStore, SimpleDocumentsStore)
  let reactToEventCellValueChanged: Function
  let reactToEventInvoicesListChanged: Function
  let reactToEventComputedInvoicesListChanged: Function
  let reactToEventInvoicesListLoaded: Function
  let reactToEventInvoiceSearchValueChanged: Function
  let reactToEventLineSelected: Function
  let reactToInvoiceMarkedCompleted: Function

  /** Set the maps */
  let mappedInvoicesAccordingToCustomerIdAndTimeSlotCategories: AgingBalanceRawMap
  let totalAmountsForAllClientsAndTimeSlots: AgingBalanceContent = <AgingBalanceContent>{}
  let filters: AppliedDunningFilters = createDunningFilters(totalAmountsForAllClientsAndTimeSlots)

  /**
   * Reactively update the data whenever DunningInvoicesStore or WorkspaceStore change
   */
  $: {
    mappedInvoicesAccordingToCustomerIdAndTimeSlotCategories = mapInvoicesAccordingToDualKeyToIDAndTimeSlotCategoryWithAmounts($DunningInvoicesStore, getUserCompanyTimezone($WorkspaceStore), timeslotCategoriesAccordingToDaysDue)
    totalAmountsForAllClientsAndTimeSlots = calculateTotalsForAllClients(mappedInvoicesAccordingToCustomerIdAndTimeSlotCategories)
    if (totalAmountsForAllClientsAndTimeSlots) {
      filters.checkboxFilterMoreThan90TillDue.totalAmount = totalAmountsForAllClientsAndTimeSlots.dueMoreThan90dInvoicesAmount
      filters.checkboxFilterBetween61And90DaysTillDue.totalAmount = totalAmountsForAllClientsAndTimeSlots.due61And90dInvoicesAmount
      filters.checkboxFilterBetween31And60DaysTillDue.totalAmount = totalAmountsForAllClientsAndTimeSlots.due31And60dInvoicesAmount
      filters.checkboxFilterBetween0And30DaysTillDue.totalAmount = totalAmountsForAllClientsAndTimeSlots.due0And30dInvoicesAmount
      filters.checkboxFilterBetween1And30DaysOverdue.totalAmount = totalAmountsForAllClientsAndTimeSlots.overdue30And1dInvoicesAmount
      filters.checkboxFilterBetween31And60DaysOverdue.totalAmount = totalAmountsForAllClientsAndTimeSlots.overdue31And60dInvoicesAmount
      filters.checkboxFilterBetween61And90DaysOverdue.totalAmount = totalAmountsForAllClientsAndTimeSlots.overdue61And90dInvoicesAmount
      filters.checkboxFilterMoreThan90DaysOverdue.totalAmount = totalAmountsForAllClientsAndTimeSlots.overdueMoreThan90dInvoicesAmount
    }
    updateFilterBarStyles(filters, totalAmountsForAllClientsAndTimeSlots)
  }

  /**
   * Function used to close the dunning filter from TableFilter
   * @param filterKey
   * @param newValue
   */
  const updateFilter = (filterKey: string, newValue: string) => {
    filters = {...filters, [filterKey]: newValue}
  }

  /** Scroll Width Const for Labels */
  const scrollWidth = 300

  /**
   * This function is called when the user changes the view option in the dunning view
   * returns the list of invoices to display in the datatable
   * @param listViewChosenOption
   * @param filters
   */
  function updateExtractedInvoicesDataListForDatatableAccordingToViewOptionsAndInvoicesStore(
    listViewChosenOption: DunningViewListOption,
    filters: AppliedDunningFilters,
  ) {
    let invoicesFilterFunctions = dunningFilterService.getInvoicesFilterCorrespondingToUserSelectedFiltersInDunningFilters(filters)
    let filteredInvoices: Invoice[] = dunningFilterService.filterDunningInvoicesFromStore(listViewChosenOption, searchValue).filter(invoice => invoicesFilterFunctions.some(filterFunction => filterFunction(invoice)))

    if ((currentDisplayedInvoices && !currentDisplayedInvoices.length)
      || JSON.stringify(currentDisplayedInvoices) !== JSON.stringify(filteredInvoices)) {
      currentDisplayedInvoices = filteredInvoices
      updatedDunningCounts = dunningFilterService.updateCollateralCalculus(searchValue)
      selectedInvoices = []
    }
  }

  /**
   * Scroll function for labels
   * @param direction
   */
  function scroll(direction: string) {
    const newScrollPosition = scrollContainer.scrollLeft + (direction === 'left' ? -scrollWidth : scrollWidth)
    scrollContainer.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth'
    })
    checkScroll()
  }

  /**
   * Check if the scroll is possible
   */
  function checkScroll() {
    canScrollLeft = scrollContainer?.scrollLeft > 0
    canScrollRight = scrollContainer
      ? scrollContainer.scrollLeft < scrollContainer.scrollWidth - scrollContainer.clientWidth
      : false
  }

  /** Calculating Data for HUD */
  $: computedCalculusForDashboard = updateCollateralCalculusOnInvoicesUpdate($DunningInvoicesStore, $ComputedDataInvoicesStore, $CustomersStore, t('locales'), $invoicesCurrency)

  /**
   * Reactive Declaration that triggers everytime $invoicesListViewChoice is changed
   *  Update the displayed content of the AgGrid datatable each time a data is changed
   */
  $: updateExtractedInvoicesDataListForDatatableAccordingToViewOptionsAndInvoicesStore($dunningListViewChoice, filters)


  /** Reactive Declaration that triggers everytime $searchValue is changed
   *  Updates the displayed content of the AgGrid datatable each time the search bar is changed
   * **/
  $: {
    eventsManager.emit(EventType.DUNNING_SEARCH_VALUE_CHANGED, {
      newSearchValue: searchValue
    }, 'Dunning.svelte')
  }

  /** Show loading spinner when the list of business documents is empty **/
  $: loading = !($DunningInvoicesStore && $DunningInvoicesStore.length > 0)

  /**
   * View Selection Mechanism
   * @param listViewChoice
   */
  const selectDunningTab = (listViewChoice: DunningViewListOption) => {
    // changing views
    dunningListViewChoice.set(listViewChoice || DunningViewListOption.TRACKING)
    switch (listViewChoice) {
      case DunningViewListOption.TRACKING: {
        updateExtractedInvoicesDataListForDatatableAccordingToViewOptionsAndInvoicesStore($dunningListViewChoice, filters)
        break
      }
      case DunningViewListOption.PAUSED: {
        updateExtractedInvoicesDataListForDatatableAccordingToViewOptionsAndInvoicesStore($dunningListViewChoice, filters)
        break
      }
      case DunningViewListOption.DRAFT : {
        updateExtractedInvoicesDataListForDatatableAccordingToViewOptionsAndInvoicesStore($dunningListViewChoice, filters)
        break
      }
      case DunningViewListOption.PAID: {
        updateExtractedInvoicesDataListForDatatableAccordingToViewOptionsAndInvoicesStore($dunningListViewChoice, filters)
        break
      }
      default:
      /* console.error('selectDunningTab was called without a valid value!', listViewChoice) */
    }
  }

  /** Set columns Definitions for AG GRID component */
  let columnDefs: ColDef[] = <ColDef[]>dunningColumnDefs

  /** Set custom Options for AG-GRID component */
  const gridOptions: GridOptions = <GridOptions>dunningOptions


  onMount(() => {
    mixpanel.track('JA10 Dunning Page', {
      'Description': 'Open Dunning.svelte'
    })
    checkScroll()

    computedCalculusForDashboard = updateCollateralCalculusOnInvoicesUpdate($DunningInvoicesStore, $ComputedDataInvoicesStore, $CustomersStore, t('locales'), $invoicesCurrency)

    updateNewNavigationHistoryItem('Dunning.svelte')

    reactToEventCellValueChanged = eventsManager.on<Array<CellValueChangeItem>>(EventType.CELL_VALUE_CHANGED, (e) => {
      agGridCommonService.updateInvoicesStoreFromEventCellValueChanged(e.data)
    }, 'Dunning.svelte')
    reactToEventInvoicesListChanged = eventsManager.on<Invoice[]>(EventType.INVOICE_LIST_CHANGED, () => {
      updateExtractedInvoicesDataListForDatatableAccordingToViewOptionsAndInvoicesStore($dunningListViewChoice, filters)
    }, 'Dunning.svelte')
    reactToEventComputedInvoicesListChanged = eventsManager.on<ComputedDataInvoice[]>(EventType.COMPUTED_INVOICE_LIST_FETCHED, (e) => {
      $ComputedDataInvoicesStore = e.data
      updatedDunningCounts = dunningFilterService.updateCollateralCalculus(searchValue)
      updateExtractedInvoicesDataListForDatatableAccordingToViewOptionsAndInvoicesStore($dunningListViewChoice, filters)
    }, 'Dunning.svelte')
    reactToEventInvoicesListLoaded = eventsManager.on<Invoice[]>(EventType.INVOICE_LIST_LOADED, () => {
      updateExtractedInvoicesDataListForDatatableAccordingToViewOptionsAndInvoicesStore($dunningListViewChoice, filters)
    }, 'Dunning.svelte')
    reactToEventInvoiceSearchValueChanged = eventsManager.on<string>(EventType.DUNNING_SEARCH_VALUE_CHANGED, () => {
      updateExtractedInvoicesDataListForDatatableAccordingToViewOptionsAndInvoicesStore($dunningListViewChoice, filters)
    }, 'Dunning.svelte')
    reactToEventLineSelected = eventsManager.on<Array<any>>(EventType.INVOICES_TABLE_ROW_SELECTED, e => {
      selectedInvoices = e.data
    }, 'Dunning.svelte')
    reactToInvoiceMarkedCompleted = eventsManager.on(EventType.INVOICE_MARKED_COMPLETED, () => {
      selectDunningTab(DunningViewListOption.TRACKING)
    }, 'Dunning.svelte')

    selectDunningTab($dunningListViewChoice)
  })

  afterUpdate(async () => {
    checkScroll()
    setTimeout(() => loading = false, 1000) // Provides a more pleasant UX
  })

  onDestroy(() => {
    reactToInvoiceMarkedCompleted()
    reactToEventLineSelected()
    reactToEventInvoiceSearchValueChanged()
    reactToEventInvoicesListLoaded()
    reactToEventComputedInvoicesListChanged()
    reactToEventInvoicesListChanged()
    reactToEventCellValueChanged()
  })

</script>

<svelte:head>
  <title>{t('topMenu.nav.dunning')} - Dundy</title>
</svelte:head>

<main class="flex flex-col items-center">
  <div class="flex w-full flex-col flex-1">
    <div>
      <h1 class="text-black text-3xl font-bold">{t('dunning.pageTitle')}</h1>
    </div>

    <DunningHUDVisualization
      bind:filters
      computedCalculusForDashboard={computedCalculusForDashboard}
      currency={$invoicesCurrency}/>

    <div class="w-full mt-4">
      <div class="border-b border-gray-200">
        <nav aria-label="Tabs" class="-mb-px flex space-x-8">

          <!-- OUTSTANDING tab -->
          <BusinessDocumentListTab
            bind:currentStoreChoice={$dunningListViewChoice}
            bind:numberOfBusinessDocumentsInView={updatedDunningCounts.numberOfTrackingInvoices}
            bind:totalBusinessDocumentAmountNormalized={updatedDunningCounts.trackingInvoicesAmountNormalized}
            businessDocumentListType={DunningViewListOption.TRACKING}
            on:chosenViewListOption={(e)=>{selectDunningTab(e.detail)}}
            tabTitleEntry="dunning.tabs.tracking"
          />

          <!-- PAUSED tab -->
          <BusinessDocumentListTab
            bind:currentStoreChoice={$dunningListViewChoice}
            bind:numberOfBusinessDocumentsInView={updatedDunningCounts.numberOfPausedInvoices}
            bind:totalBusinessDocumentAmountNormalized={updatedDunningCounts.pausedInvoicesAmountNormalized}
            businessDocumentListType={DunningViewListOption.PAUSED}
            on:chosenViewListOption={(e)=>{selectDunningTab(e.detail)}}
            tabTitleEntry="dunning.tabs.paused"
          />

          <!-- PAID tab -->
          <BusinessDocumentListTab
            bind:currentStoreChoice={$dunningListViewChoice}
            bind:numberOfBusinessDocumentsInView={updatedDunningCounts.numberOfPaidInvoices}
            bind:totalBusinessDocumentAmountNormalized={updatedDunningCounts.paidInvoicesAmountNormalized}
            businessDocumentListType={DunningViewListOption.PAID}
            on:chosenViewListOption={(e)=>{selectDunningTab(e.detail)}}
            tabTitleEntry="dunning.tabs.paid"
          />
          {#if updatedDunningCounts.numberOfDraftInvoices > 0}
            <!-- DRAFT tab -->
            <BusinessDocumentListTab
              businessDocumentListType={DunningViewListOption.DRAFT}
              tabTitleEntry="dunning.tabs.draft"
              bind:totalBusinessDocumentAmountNormalized={updatedDunningCounts.draftInvoicesAmountNormalized}
              bind:numberOfBusinessDocumentsInView={updatedDunningCounts.numberOfDraftInvoices}
              bind:currentStoreChoice={$dunningListViewChoice}
              on:chosenViewListOption={(e)=>{selectDunningTab(e.detail)}}
            />
          {/if}
        </nav>
      </div>
    </div>


    <div class="flex flex-col w-full bg-white">
      <div class="flex flex-col lg:flex-row items-center py-6 xl:space-x-5 space-y-4 xl:space-y-0">
        <div class="flex-grow">
          <SearchBar bind:value={searchValue} hideLabel={true}/>
        </div>
        <div class="flex-shrink-0 space-x-5">
          <div class="flex flex-row space-x-5 justify-end">

            {#if $dunningListViewChoice === DunningViewListOption.TRACKING || $dunningListViewChoice === DunningViewListOption.PAID}
              <MarkPaidOrUnpaidInvoiceButton {selectedInvoices}/>
            {/if}

            {#if $dunningListViewChoice === DunningViewListOption.TRACKING || $dunningListViewChoice === DunningViewListOption.PAUSED}
              <PauseOrResumeTrackingInvoice
                isTracked={$dunningListViewChoice === DunningViewListOption.PAUSED}
                {selectedInvoices}/>
            {/if}

            {#if $dunningListViewChoice === DunningViewListOption.DRAFT}
              <DeleteOrRecoverInvoiceButton {selectedInvoices}/>
            {/if}

            {#if $dunningListViewChoice === DunningViewListOption.TRACKING || $dunningListViewChoice === DunningViewListOption.PAUSED || $dunningListViewChoice === DunningViewListOption.DRAFT}
              <AddDunningInvoiceDropdown
                on:addDundyInvoice={() => {
                  navigate('receivables/external/new')
                }}
                on:quickAddDundyInvoice={() => {
                  const newInv = DunningInvoicesHelper.newInvoice()
                  $DunningInvoicesStore = [newInv, ...$DunningInvoicesStore]
                  dunningListViewChoice.set(DunningViewListOption.DRAFT)
                }}
              />
            {/if}
            <div class="flex">
              <button
                aria-controls="disclosure-1"
                aria-expanded="false"
                class="group btn action-default"
                on:click={() => {openFilters = !openFilters}}
                type="button">
                {#if !openFilters}
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.75 7C4.33579 7 4 7.33579 4 7.75C4 8.16421 4.33579 8.5 4.75 8.5V7ZM19.25 8.5C19.6642 8.5 20 8.16421 20 7.75C20 7.33579 19.6642 7 19.25 7V8.5ZM4.75 8.5H19.25V7H4.75V8.5Z"
                      fill="currentColor"></path>
                    <path
                      d="M6.75 11C6.33579 11 6 11.3358 6 11.75C6 12.1642 6.33579 12.5 6.75 12.5V11ZM17.25 12.5C17.6642 12.5 18 12.1642 18 11.75C18 11.3358 17.6642 11 17.25 11V12.5ZM6.75 12.5H17.25V11H6.75V12.5Z"
                      fill="currentColor"></path>
                    <path
                      d="M8.75 15C8.33579 15 8 15.3358 8 15.75C8 16.1642 8.33579 16.5 8.75 16.5V15ZM15.25 16.5C15.6642 16.5 16 16.1642 16 15.75C16 15.3358 15.6642 15 15.25 15V16.5ZM8.75 16.5H15.25V15H8.75V16.5Z"
                      fill="currentColor"></path>
                  </svg>
                {:else}
                  <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="1.5"
                          d="M4.75 12C4.75 7.99594 7.99594 4.75 12 4.75V4.75C16.0041 4.75 19.25 7.99594 19.25 12V12C19.25 16.0041 16.0041 19.25 12 19.25V19.25C7.99594 19.25 4.75 16.0041 4.75 12V12Z"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="1.5" d="M9.75 9.75L14.25 14.25"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="1.5" d="M14.25 9.75L9.75 14.25"></path>
                  </svg>

                {/if}

                <span class="ml-1">{t('dunning.filters.filtersTitle')}</span>
              </button>
            </div>

          </div>
        </div>
      </div>
      {#if activeFilters.length > 0}
        <div class="relative flex items-center w-full mb-6" in:fade={{ duration: 300 }}>
          {#if canScrollLeft}
            <button
              class="absolute left-0 z-10 p-2 bg-white"
              on:click={() => scroll('left')}
            >
              <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="1.5" d="M10.25 6.75L4.75 12L10.25 17.25"></path>
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="1.5" d="M19.25 12H5"></path>
              </svg>

            </button>
          {/if}
          <div bind:this={scrollContainer}
               class="w-full overflow-x-auto whitespace-nowrap hide-scrollbar transition-all"
               on:scroll={checkScroll}>

            <div class="inline-block min-w-[300px] space-x-4 transition-all">
              {#each Object.entries(filters) as [filter, value]}
                <TableFilter
                  currency={$invoicesCurrency}
                  filter={filter}
                  bind:filterValue={value}
                  {updateFilter}/>
              {/each}
            </div>
          </div>
          {#if canScrollRight}
            <button
              class="absolute right-0 z-10 p-2 bg-white"
              on:click={() => scroll('right')}
            >
              <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="1.5" d="M13.75 6.75L19.25 12L13.75 17.25"></path>
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="1.5" d="M19 12H4.75"></path>
              </svg>

            </button>
          {/if}
        </div>
      {/if}
      <DunningFilters
        bind:activeFilters
        bind:filters={filters}
        bind:openFilters
        invoicesCurrency={$invoicesCurrency}
      />

      <div class="flex flex-col w-full  h-full relative ">
        {#if searchValue && !currentDisplayedInvoices.length}
          <div class="empty flex flex-col items-center mt-20 w-full">
            <h1 class="font-black text-base">{t('invoices.noResults.title')}</h1>
            <p class="font-medium text-paleSky mt-6 text-center">
              {t('invoices.noResults.one')}<br/> « {searchValue}
              ».
              <br/>
              {t('invoices.noResults.two')}
            </p>
          </div>
        {:else if currentDisplayedInvoices.length}
          <Datatable
            bind:grid
            class="invoices"
            {columnDefs}
            spreadsheetData={currentDisplayedInvoices}
            options={gridOptions}
          />
        {:else if loading}
          <div class="grid h-96 place-items-center w-full z-[100]">
            <Loader i18nKey="invoices.fetching"/>
          </div>
        {/if}
        <RowContextualMenu
          on:editDundyInvoiceClicked={(e) => {
                          invoice = deepClone(e.detail.invoice)
                          navigate(`receivables/external/edit/${invoice.dataId}`)
                        }}
          on:editVoxyInvoiceClicked={(e) => {
                          invoice = deepClone(e.detail.invoice)
                          navigate(`receivables/edit/${invoice.dataId}/details`)
                        }}
          on:viewDundyInvoiceClicked={(e) => {
                          invoice = deepClone(e.detail.invoice)
                          navigate(`receivables/external/view/${invoice.dataId}/details`)
                        }}
          on:viewVoxyInvoiceClicked={(e) => {
                          invoice = deepClone(e.detail.invoice)
                          navigate(`receivables/view/${invoice.dataId}/details`)
                        }}
        />
      </div>
    </div>
  </div>
</main>
<!--
<style global lang="postcss">
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .hide-scrollbar {
    -ms-overflow-style: none; /* Internet Explorer and Edge */
    scrollbar-width: none; /* Firefox */
    scroll-behavior: smooth; /* Smooth scrolling */
  }

  .ag-theme-alpine .ag-header {
    @apply bg-whisper border-0 border-t border-zinc-100;
  }

  .ag-theme-alpine .ag-row {
    @apply bg-transparent border-0 border-b border-zinc-100 hover:bg-whisper hover:rounded-md;
  }

  .ag-theme-alpine .ag-row-hover {
    @apply bg-whisper;
  }

  /* ROW SELECTION */
  .ag-theme-alpine .ag-row-selected {
    @apply bg-whisper;
  }

  /* RANGE SELECTION */
  .ag-theme-alpine .ag-cell-range-selected:not(.ag-cell-focus),
  .ag-theme-alpine .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-single-cell:not(.ag-cell-inline-editing) {
    @apply bg-whisper;
  }

  /* RANGE SELECTION INTERSECTION LEVELS */
  .ag-theme-alpine .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
    background-color: rgba(220, 53, 69, 0.2);
  }

  .ag-theme-alpine .ag-cell-range-selected-1:not(.ag-cell-focus),
  .ag-theme-alpine .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-1 {
    background-color: rgba(220, 53, 69, 0.2);
  }

  .ag-theme-alpine .ag-cell-range-selected-2:not(.ag-cell-focus),
  .ag-theme-alpine .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-2 {
    background-color: rgba(220, 53, 69, 0.36);
  }

  .ag-theme-alpine .ag-cell-range-selected-3:not(.ag-cell-focus),
  .ag-theme-alpine .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-3 {
    background-color: rgba(220, 53, 69, 0.488);
  }

  .ag-theme-alpine .ag-cell-range-selected-4:not(.ag-cell-focus),
  .ag-theme-alpine .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-4 {
    background-color: rgba(220, 53, 69, 0.5904);
  }

  .ag-theme-alpine div.ag-row-hover:not(.ag-row-group) {
    @apply bg-whisper;
  }

  .ag-theme-alpine .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    @apply border-black;
  }

  .ag-theme-alpine .ag-cell {
    @apply border-0 text-zinc-700 font-light flex;
    line-height: 48px;
  }

  .ag-theme-alpine .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    @apply border-0;
  }

  .ag-theme-alpine .ag-header-cell {
    @apply border-0 border-b border-zinc-100;
  }

  .ag-theme-alpine .ag-column-hover {
    @apply bg-transparent;
  }

  .ag-theme-alpine .dundy-businessDocument-number-data {
    @apply truncate;
  }
</style>-->

import { EmailAttachmentTag, type EmailAttachment } from '../models/email-attachment'
import type Invoice from '../models/invoice'
import { DunningInvoicesHelper } from './dunning-invoices-helper'

export async function buildAutomaticAttachmentsList(targetObject: Invoice | Array<Invoice>): Promise<Array<EmailAttachment>> {
  const attachments = new Array<EmailAttachment>()
  let invoices: Array<Invoice> = new Array<Invoice>
  if ((targetObject as any).length) {
    invoices = targetObject as Array<Invoice>
  } else {
    invoices = [targetObject as Invoice]
  }
  for (const invoice of invoices) {

    const downloadedInvoice = await DunningInvoicesHelper.downloadInvoicePDF(invoice)

    if (downloadedInvoice) {
      attachments.push({
        fileName: downloadedInvoice.fileName,
        size: downloadedInvoice.size,
        contentBase64: downloadedInvoice.contentBase64,
        tags: [EmailAttachmentTag.Programmatic, EmailAttachmentTag.Invoice],
        type: 'application/pdf'
      })
    }
  }

  return attachments
}
import Filter from 'bad-words'

/**
 * Validation Central
 * Validates the input name against a set of rules :
 * - Name cannot be empty
 * - Name cannot contain symbols, numbers, unusual capitalization, repeating characters or punctuation
 * - Name cannot contain characters from multiple languages
 * - Name cannot contain titles of any kind (professional, religious, etc.)
 * - Name cannot contain offensive or suggestive content
 * @param name
 */
export function validateName(name: string): boolean {
  // Name cannot be empty
  if (!name) return false

  // Check for symbols, numbers, unusual capitalization, repeating characters or punctuation
  if (containsForbiddenCharacters(name) || containsUnusualCapitalization(name) || containsRepeatingCharacters(name)) return false

  // Check for characters from multiple languages
  // if (containsMultipleLanguages(name)) return false

  // Check for titles of any kind (professional, religious, etc.)
  // if (containsTitles(name)) return false

  // Check for offensive or suggestive content
  // if (containsOffensiveContent(name)) return false

  // Name passes all validations
  return true
}

/**
 * Checks if the input contains any forbidden characters
 * */
function containsForbiddenCharacters(input: string): boolean {
  return /[\d!@#$%^&*()_+|~=\-`{}\[\]:;<>?,./\\]/.test(input)
}

/**
 * Checks if the input contains repeating characters
 * - Consecutive repeating characters are allowed up to 4
 * - Total repeating characters are allowed up to 10
 *
 * @param input
 */
function containsRepeatingCharacters(input: string): boolean {
  const repeatsThreshold: number = 4 // Allowed consecutive repeating characters
  const totalRepeatsThreshold: number = 10 // Allowed total repeating characters

  let currentChar: string = input[0]
  let consecutiveCount: number = 0
  let totalCount: number = 0

  for (let i = 1; i < input.length; i++) {
    if (input[i] === currentChar) {
      consecutiveCount++
      if (consecutiveCount > repeatsThreshold) return true
    } else {
      if (consecutiveCount > 0) totalCount += consecutiveCount
      currentChar = input[i]
      consecutiveCount = 0
    }
  }

  if (consecutiveCount > 0) totalCount += consecutiveCount

  return totalCount > totalRepeatsThreshold
}


/**
 * Checks if the input contains characters from multiple languages
 * (e.g., Chinese, Japanese, Korean, Arabic, Cyrillic, etc.)
 * - makes use of the Unicode property escapes (\p{}) to match characters belonging to different scripts.
 * - counts the number of scripts detected in the input string,
 * and if more than one is found, it returns true,
 * indicating that the input contains characters from multiple languages.
 * @param input
 */
function containsMultipleLanguages(input: string): boolean {
  const scripts: RegExp[] = [
    /\p{Script=Latin}/u,
    /\p{Script=Cyrillic}/u,
    /\p{Script=Arabic}/u,
    /\p{Script=Han}/u, // Chinese characters
    /\p{Script=Katakana}/u, // Japanese characters
    /\p{Script=Hangul}/u // Korean characters
    // ... Add other scripts as needed
  ]

  let detectedScripts: number = 0

  for (const script of scripts) {
    if (script.test(input)) {
      detectedScripts++
    }

    // If more than one script is detected, return true
    if (detectedScripts > 1) {
      return true
    }
  }

  return false
}


/**
 * Checks if the input contains any titles, religious, or professional
 * (input.includes(title)) might result in false positives if a title is part of a regular word
 * (e.g., "General" in "Generally"). To avoid this, we should implement additional logic to
 * detect word boundaries or use regular expressions.
 *
 * @param input
 */
export function containsTitles(input: string): boolean {
  // List of common titles, including professional, religious, and other honorifics
  const titles: string[] = [
    'Mr', 'Mrs', 'Ms', 'Miss', 'Dr', 'Sir', 'Rev', 'Father', 'Mother',
    'Pastor', 'Bishop', 'Rabbi', 'Imam', 'Monsignor', 'Deacon', 'Cardinal',
    'Professor', 'Captain', 'Major', 'General', 'Admiral', 'Judge', 'Officer',
    'President', 'Governor', 'Mayor', 'Minister', 'Secretary', 'Director', 'Chief',
    'Master', 'Sergeant', 'Lieutenant', 'Corporal', 'Commander', 'Archbishop',
    'Monseigneur', 'Honorable', 'His Excellency', 'Her Excellency', 'Senator', 'Chancellor',
    'Dean', 'Commissioner', 'Esquire', 'Lord', 'Lady', 'Duke', 'Duchess', 'Prince', 'Princess',
    'King', 'Queen', 'Emperor', 'Empress'
  ]

  // Check if the input string contains any of the titles
  return titles.some((title: string) => input.includes(title))
}


/**
 * Checks if the input contains any offensive content
 * using the bad-words library (en-US)
 * https://www.npmjs.com/package/bad-words
 * @param input
 */
export function containsOffensiveContent(input: string): boolean {
  let filter: Filter = new Filter()
  
  return filter.isProfane(input)
}

/**
 * Checks if the input contains any unusual capitalization
 * names follow the pattern of having the first letter capitalized
 * and the rest of the letters in lowercase, possibly repeating
 * this pattern for compound names or hyphenated names.
 * @param input
 */
export function containsUnusualCapitalization(input: string): boolean {
  // Split the input by spaces or hyphens, assuming that it might be a compound name
  const parts = input.split(/[\s-]/)

  // Check each part of the name
  for (const part of parts) {
    // If the part is empty (e.g., consecutive spaces or starting with a space), continue to next part
    if (!part) continue

    // Check if the first character is uppercase and the rest are lowercase
    if (part[0] !== part[0].toUpperCase() || part.slice(1) !== part.slice(1).toLowerCase()) {
      return true // Unusual capitalization found
    }
  }

  return false // No unusual capitalization found
}

<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount } from 'svelte'
  import { formatToDateWithSlashes } from '../../core-app/util/date-utils'
  import { t } from '../../core-app/lib/i18n/i18nextWrapper'
  import { get } from 'svelte/store'
  import { CustomersStore } from '../../crm-app/stores/customers.store'
  import { DunningInvoicesStore, dunningListViewChoice } from '../stores/dunning-invoices.store'
  import { ComputedDataInvoicesStore } from '../stores/computed-data-invoice.store'
  import { feedbackService } from '../../core-app/services/feedback.service'
  import TextInput from '../../core-app/lib/ui-kit/TextInput.svelte'
  import StyledSelect from '../../core-app/lib/ui-kit/StyledSelect.svelte'
  import currencies from '../lib/currency-list.json'
  import datepicker from 'js-datepicker'
  import type { Customer } from '../../crm-app/models/customer'
  import type Invoice from '../models/invoice'
  import { Feedback } from '../../core-app/models/feedback'
  import { dunningInvoicesService } from '../services/dunning-invoices.service'
  import FullSizeModal from '../../core-app/lib/ui-kit/FullSizeModal.svelte'
  import { navigate } from 'svelte-routing'
  import Loader from '../../core-app/lib/ui-kit/Loader.svelte'
  import { DunningInvoicesHelper } from '../helpers/dunning-invoices-helper'
  import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
  import { eventsManager } from '../../core-app/events/event-manager'
  import { EventType } from '../../core-app/events/event-type'
  import { DunningViewListOption } from '../enums/dunning-view-list-options'
  import { invoiceAttachmentDownload } from '../services/dundy-file-mgt/dundy-pdf-attachment.api'
  import { pdfAttachmentUpload } from '../../file-mgt-domain/services/pdf-attachment-upload.api'
  import UploadArea from '../../dundy-app/lib/dundy-file-mgt/UploadArea.svelte'
  import { bytesToHighestUnitDisplay } from '../../core-app/util/file-utils'
  import { DocumentSourceKind } from '../../order-to-cash-lib/models/document-source-kind'

  /** Exported Var **/
  export let invoiceId: string = null

  /** Const declarations **/
  const dispatch = createEventDispatcher()

  /** Var declarations **/
  let attachmentPreviewFile: string
  let windowHeight: number
  let invoice: Invoice = DunningInvoicesHelper.newInvoice()
  let customer: Customer
  let isContentLoading: boolean = false
  let currentDatePicker: any
  let invoiceIssuedDateReadable: string
  let invoiceDueDateReadable: string
  let customerDone: boolean
  let customerInitial: string
  let currentInvoiceAttachment: any = null
  let currentInvoiceHasAttachment: boolean
  let isPDFFileLoading: boolean = false
  let savingDraftToServer: boolean = false
  let savingFinalizedToServer: boolean = false
  let canFinalizeAndTrackInvoice: boolean
  let isCloseable: boolean
  let uploadedFile: {
    fileName: string
    type: string
    size: number
    contentBase64: string
    originalFile: File
  }

  const close = () => {
    if (savingFinalizedToServer || savingDraftToServer) {
      return
    }
    dispatch('close')
  }

  /** Reactive declarations **/
  $: invoiceIssuedDateReadable = formatToDateWithSlashes(new Date(invoice?.dateIssued))
  $: invoiceDueDateReadable = formatToDateWithSlashes(new Date(invoice?.dateDue))
  $: currentInvoiceHasAttachment = !!$ComputedDataInvoicesStore.find(computedInvoice => invoice && invoice.invoiceNumber && computedInvoice.invoiceNumber === invoice.invoiceNumber)?.invoiceMetadata?.businessDocumentAttachmentMetadata?.fileName

  $: customerDone = !!customer && !!customer.company && !!customer.company.formalName
  $: customerInitial = customerDone ? customer?.company.formalName.substring(0, 1) : null
  $: if (customerDone) {
    invoice.toName = customer?.company.formalName
    invoice.toId = customer?.company.companyId
  }
  $: canFinalizeAndTrackInvoice = !!invoice && !!invoice?.amountIncludingTaxes && !!invoice?.currency && invoice?.invoiceNumber !== '0000000' && !!invoice?.dateIssued && !!invoice?.dateDue
  $: isCloseable = !(savingDraftToServer || savingFinalizedToServer)

  /**
   * Save PDF to Server If Requirements are Met
   * And Refresh MetaData
   *
   * */
  function savePDFToInvoiceAndRefreshMetaData(file: File, isFinal: boolean): Promise<void> {

    return pdfAttachmentUpload(invoice.invoiceNumber, invoice.dataId, get(WorkspaceStore).workspaceId, isFinal, file)
      .then(() => {
        /* console.log('File upload result', result) */
        setTimeout(() => {
          invoiceAttachmentDownload(invoice, get(WorkspaceStore).workspaceId)
            .then(invoiceAttachment => {
              currentInvoiceAttachment = invoiceAttachment
            })
            .then(() => {
              setTimeout(() => {
                /* console.log('request to update all when finishing to save PDF attachment') */
                eventsManager.emit(EventType.REQUEST_INVOICE_LIST_AND_COMPUTED_INVOICE_LIST_TO_REFRESH, null,
                  'DundyInvoicePDFUploadArea.svelte')
              }, 4000)
            })
          attachmentPreviewFile = null
        }, 2000)
      })
  }

  /**
   * Function that transform PDFs into Base64 for preview
   */
  function getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result)
        } else {
          reject(new Error('Unexpected result type'))
        }
      }
      reader.onerror = error => reject(error)
    })
  }

  /**
   * Function that previews the PDF file
   */
  async function previewFile(): Promise<void> {
    isPDFFileLoading = true
    try {
      const base64: string = await getBase64(uploadedFile.originalFile)
      attachmentPreviewFile = base64

      setTimeout(() => {
        currentInvoiceAttachment = {
          fileName: uploadedFile.originalFile.name,
          contentBytesSizeRead: uploadedFile.originalFile.size,
          type: uploadedFile.originalFile.type
        }
      }, 1000)

    } catch (error) {
      /* console.error('Error reading the file:', error) */
    } finally {
      isPDFFileLoading = false
    }
  }

  /**
   * Function to Actually Save the Invoice
   * in the Store
   *
   **/
  function updateInvoiceStoreWithInvoiceObject(updateInvoice: Invoice, isFinal?: boolean) {
    return new Promise(async (resolve, reject) => {
      try {
        const invoiceToSave = {
          ...updateInvoice,
          modifiedRFC3339: new Date().toISOString(),
          modified: Math.round(new Date().getTime() / 1000.0),
          isTracked: isFinal,
          completed: isFinal
        }
        /* console.log(invoiceToSave, 'invoiceToSave') */
        const invoiceStore: Invoice[] = get(DunningInvoicesStore)
        // CASE 1: find and replace existing invoice
        let foundOriginalExistingInvoice: boolean = false
        /* console.log('--- invoiceStore', invoiceStore, 'type of invoiceStore', typeof invoiceStore) */
        let newInvoiceStore: Invoice[] = invoiceStore.map<Invoice>(anInvoice => {
          if (anInvoice.dataId === invoiceToSave.dataId) {
            foundOriginalExistingInvoice = true

            return updateInvoice
          } else {
            return anInvoice
          }
        })
        // CASE 2: add new invoice
        if (!foundOriginalExistingInvoice) {
          newInvoiceStore = [...invoiceStore, invoiceToSave]
        }
        /* console.log('the invoiceStore copy to be set', newInvoiceStore) */
        dunningInvoicesService.setInvoicesStoreToFetchedInvoiceListOnlyWheneverMostUpToDate(newInvoiceStore)
        resolve('')
      } catch (e) {
        reject(e)
      }
    })
  }

  /** Dispatch Function Called By Buttons **/
  function saveInvoiceToServer(isFinal?: boolean) {
    /* console.log('saveInvoiceToServer', isFinal) */
    if (isFinal && !canFinalizeAndTrackInvoice) {
      return
    }
    try {
      if (isFinal) {
        savingFinalizedToServer = true
      } else {
        savingDraftToServer = true
      }
      /* console.log(invoice, 'invoice in First Function') */
      updateInvoiceStoreWithInvoiceObject(invoice, isFinal)
        .then(() => {
          if (!!attachmentPreviewFile && !currentInvoiceHasAttachment && invoice.dataId || !!invoice?.invoiceNumber) {
            if (uploadedFile) {
              savePDFToInvoiceAndRefreshMetaData(uploadedFile.originalFile, isFinal)
                .then(() => {
                  /* console.log('File upload success') */
                })
                .catch((e) => {
                  console.log('Error saving PDF to invoice', e)
                  feedbackService.displayFeedback(<Feedback>{
                    feedbackLevel: 'Info',
                    feedbackMessage: t('invoices.createInvoice.errorSavingFinal')
                  })
                })
            }
          }
        })
        .catch((e) => {
          if (isFinal) {
            savingFinalizedToServer = false
          } else {
            savingDraftToServer = false
          }
          feedbackService.displayFeedback(<Feedback>{
            feedbackLevel: 'Info',
            feedbackMessage: t('invoices.createInvoice.errorSavingFinal')
          })
        })
    } catch (e) {
      feedbackService.displayFeedback(<Feedback>{
        feedbackLevel: 'Info',
        feedbackMessage: t('invoices.createInvoice.errorSavingFinal')
      })
    } finally {
      setTimeout(() => {
        if (isFinal) {
          savingFinalizedToServer = false
          feedbackService.displayFeedback(<Feedback>{
            feedbackMessage: t('invoices.externalInvoice.successfullySavedFinal'),
            feedbackLevel: 'Success'
          })
          dunningListViewChoice.set(DunningViewListOption.TRACKING)
          navigate('/dunning')
        } else {
          if (window.location.pathname.indexOf('new') > -1) {
            window.history.pushState('', '', '/receivables/external/edit/' + `${invoice.dataId}`)
          }
          savingDraftToServer = false
          feedbackService.displayFeedback(<Feedback>{
            feedbackMessage: t('invoices.externalInvoice.successfullySavedDraft'),
            feedbackLevel: 'Success'
          })
        }
      }, 1000)
    }
  }

  const handleOutsideClick = (event: Event) => {
    const target = event.target as HTMLElement // Cast event.target to HTMLElement
    const foundInDatePicker = document.querySelector(`.qs-datepicker-container ${target.nodeName}${target.getAttribute('class') ? ('.' + target.getAttribute('class').split(' ')[0]) : ''}`)
    if (!foundInDatePicker) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      closeDatePicker()
    }
  }

  /** Close datepicker */
  const closeDatePicker = () => {
    if (currentDatePicker) {
      currentDatePicker.remove()
      currentDatePicker = null
    }
    const footer = document.querySelector('.modal-footer')
    if (footer instanceof HTMLElement) {
      footer.style.zIndex = '20'
    } else {
      // handle the case when .modal-footer is not found
    }
    document.removeEventListener('click', handleOutsideClick, false)
  }

  const openDatePicker = (clazz: string) => {
    closeDatePicker()

    let dateSelected = new Date(formatToDateWithSlashes(new Date(invoice[clazz]), 'en-US'))
    let minDate = null
    if (clazz === 'dateDue') {
      const dateIssuedPlusOneDay = new Date(new Date(formatToDateWithSlashes(new Date(invoice.dateIssued), 'en-US')).getTime() + 86400000)
      if (dateSelected < dateIssuedPlusOneDay) {
        dateSelected = dateIssuedPlusOneDay
      }
      minDate = dateIssuedPlusOneDay
    }

    datepicker(`.${clazz}`, {
      formatter: (input: HTMLInputElement, date: Date) => {
        input.value = formatToDateWithSlashes(new Date(date))
      },
      startDay: 1, // Calendar week starts on a Monday.
      customDays: [t('calendar.days.sunday'), t('calendar.days.monday'), t('calendar.days.tuesday'), t('calendar.days.wednesday'), t('calendar.days.thursday'), t('calendar.days.friday'), t('calendar.days.saturday')],
      customMonths: [t('calendar.months.january'), t('calendar.months.february'), t('calendar.months.march'), t('calendar.months.april'), t('calendar.months.may'), t('calendar.months.june'), t('calendar.months.july'), t('calendar.months.august'), t('calendar.months.september'), t('calendar.months.october'), t('calendar.months.november'), t('calendar.months.december')],
      alwaysShow: true,
      dateSelected,
      minDate,
      showAllDates: true,
      disableYearOverlay: true,
      onSelect: (date: any, newDate) => {
        invoice[clazz] = new Date(formatToDateWithSlashes(new Date(newDate), 'en-US')).toISOString()
        if (clazz === 'dateIssued') {
          invoiceIssuedDateReadable = formatToDateWithSlashes(new Date(date))
          const dateIssuedPlusOneDay = new Date(new Date(formatToDateWithSlashes(new Date(invoice.dateIssued), 'en-US')).getTime() + 86400000)
          const dateDue = new Date(formatToDateWithSlashes(new Date(invoice.dateDue), 'en-US'))
          if (dateDue < dateIssuedPlusOneDay) {
            invoiceDueDateReadable = formatToDateWithSlashes(new Date(dateIssuedPlusOneDay))
            invoice.dateDue = dateIssuedPlusOneDay.toISOString()
          }
        } else {
          invoiceDueDateReadable = formatToDateWithSlashes(new Date(newDate))
        }
        closeDatePicker()
      },
      onShow: (picker: any) => {
        currentDatePicker = picker
        setTimeout(() => {
          document.addEventListener('click', handleOutsideClick, false)
        }, 500)
        const footer = document.querySelector('.modal-footer')
        if (footer instanceof HTMLElement) {
          footer.style.zIndex = '1'
        }

      },
      onHide: () => {
        const footer = document.querySelector('.modal-footer')
        if (footer instanceof HTMLElement) {
          footer.style.zIndex = '20'
        }
      }
    })
  }

  const newUpload = () => {
    currentInvoiceHasAttachment = null
    attachmentPreviewFile = null
    currentInvoiceAttachment = null
  }

  onMount(() => {

    isContentLoading = true
    isPDFFileLoading = true
    if (invoiceId) {
      invoice = $DunningInvoicesStore.find(storedInvoice => (storedInvoice.dataId === invoiceId))
      if (invoice) {
        customer = $CustomersStore.find(c => c.company.companyId === invoice.toId)
      } else {
        customer = null
      }
      if (invoice) {

        invoiceAttachmentDownload(invoice, get(WorkspaceStore).workspaceId)
          .then((attachment) => {
            setTimeout(() => {
              currentInvoiceAttachment = attachment
              isPDFFileLoading = false
            }, 1000)
          })
          .catch((e) => {
            feedbackService.displayFeedback(<Feedback>{
              feedbackLevel: 'Error',
              feedbackMessage: e.message || e.detail || e
            })
            isPDFFileLoading = false
          })
      } else {
        isPDFFileLoading = false
        isContentLoading = false
      }
      isContentLoading = false
    } else {
      isPDFFileLoading = false
      isContentLoading = false
    }
  })

  onDestroy(() => {
    document.removeEventListener('click', handleOutsideClick, false)
    isContentLoading = false
    isPDFFileLoading = false
  })

</script>

<svelte:window bind:innerHeight={windowHeight}/>

<FullSizeModal
  isCloseable={isCloseable}
  class="w-full overflow-hidden h-full"
  on:close={close}>

  <div slot="content" class="grid {!isContentLoading ? 'grid-cols-2' : ''} w-full h-full"
       style="{!isContentLoading ? 'grid-template-columns: 580px 1fr;' : '' }">
    {#if isContentLoading}
      <div class="grid h-screen place-items-center w-full z-[100]">
        <Loader/>
      </div>
      <div class="absolute top-0 left-0 w-full h-full opacity-20 bg-whisper z-50 min-h-screen"></div>
    {:else}
      <div class="flex flex-col overflow-y-auto row-span-1">
        <div class="pt-12 pb-10">
          <h1 class="font-bold text-3xl px-12">{t('invoices.externalInvoice.addInvoiceTitle')}</h1>
          <p class="mt-2 mb-4 px-12">{t('invoices.externalInvoice.addInvoiceCopy')}</p>
          <div class="flex flex-1 flex-col justify-between px1-2">
            <div class="divide-y divide-gray-200 sm:px-6">
              <div class="space-y-6 py-7">
                <div class="px-12 sm:px-6">
                  <h2 class="block text-xl font-bold text-gray-900 mb-3">{t('invoices.createInvoice.clientLabel')}</h2>
                  {#if customerDone}
                    <div class="flex justify-between items-center">
                      <div class="flex flex-row w-full justify-start items-center align-middle pt-1 ml-1.5">
                        <div class="h-10 w-10 py-0.2 bg-black flex justify-center items-center rounded-md uppercase text-center text-sm">
                          <span class="text-white">{customerInitial}</span>
                        </div>
                        <div class="flex flex-col ml-3">
                          <span class="text-lg font-medium text-gray-900">{customer.company.formalName}</span>
                          <span class="cursor-pointer text-xxs text-dundyOrange"
                                role="button"
                                tabindex="0"
                                on:click={() => {
															  navigate('/clients/' + customer.company.companyId + '/edit')
														  }}
                                on:keydown={(e) => {if (e.key === 'Enter') {navigate('/clients/' + customer.company.companyId + '/edit')}}}
                          >{t('invoices.externalInvoice.editClientFile')}</span>
                        </div>
                      </div>
                      <button type="button"
                              on:click={() => {
                                customer = null
                                customerDone = false
                              }}
                              class="inline-flex h-8 items-center rounded border border-gray-300 bg-white px-2.5 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-dundyOrange focus:ring-offset-2">
                        {t('invoices.externalInvoice.changeClient')}
                      </button>
                    </div>

                  {:else if !($CustomersStore.length)}
                    <p class="text-gray-800 text-xs mb-4">{t('invoices.createInvoice.noClientYet')}</p>
                    <button class="btn action-default" on:click={() => navigate('/clients/new')}>
                      {t('invoices.externalInvoice.createClient')}
                    </button>

                  {:else }
                    <StyledSelect
                      on:change={e => {
                        customer = $CustomersStore.find(c => c.company.formalName === e.detail)
                      }}
                      value={customer?.company?.formalName}
                      class="col-span-full h-14"
                      dataCy="customer"
                      items={$CustomersStore.map(c => c.company.formalName)}
                      placeholder={''}
                      selected={''}
                      optionIdentifier="value"
                      labelIdentifier="label"
                      label=""
                      showIndicator={true}
                      isSearchable={true}
                      disabled={false}
                    />
                    <button class="btn action-default" on:click={() => navigate('/clients/new')}>
                      {t('invoices.externalInvoice.createClient')}
                    </button>
                  {/if}
                </div>
              </div>
            </div>
            <div class="divide-y divide-gray-200 border-t border-whisper sm:px-6">
              <div class="space-y-6 py-7">
                <div class="px-12 sm:px-6">
                  <h2 class="block text-xl font-bold text-gray-900 mb-3">{t('invoices.externalInvoice.invoiceDetailsTitle')}</h2>
                  <fieldset class="grid grid-cols-2 gap-x-6 gap-y-3">
                    <TextInput
                      bind:value={invoice.invoiceNumber}
                      dataCy="invoice-number"
                      class="col-span-full form-input"
                      error={t('invoices.form.number.error')}
                      label={t('invoices.form.number.label')}
                    />
                    <TextInput
                      on:change={() => {
                        invoice.amountIncludingTaxes = Number(invoice.amountIncludingTaxes)
                      }}
                      bind:value={invoice.amountIncludingTaxes}
                      dataCy="invoice-amount"
                      class="form-input"
                      type="number"
                      error={t('invoices.form.amount.error')}
                      label={t('invoices.form.amount.label')}
                    />
                    <div class="flex flex-col">
                      <span class="block text-sm font-normal text-black">{t('invoices.editInvoice.currency')}</span>
                      <StyledSelect
                        selected="EUR"
                        bind:value={invoice.currency}
                        dataCy="invoice-currency"
                        class="form-input mt-1.5 shadow-sm"
                        items={currencies.map(c => c.code)}
                        error={t('invoices.form.currency.error')}
                        label={t('invoices.form.currency.label')}
                        optionIdentifier="value"
                        labelIdentifier="label"
                        showIndicator={true}
                        isSearchable={true}
                        disabled={false}
                      />
                    </div>
                    <div class="datatable">
                      <TextInput
                        on:focus={() => openDatePicker('dateIssued')}
                        dataCy="invoice-issued-date"
                        type="text"
                        label={t('invoices.form.dateIssued.label')}
                        error={t('invoices.form.dateIssued.error')}
                        inputStyle="padding-left: 2.5em;"
                        inputClass="input-date-picker dateIssued text-paleSky"
                        class="form-input"
                        bind:value={invoiceIssuedDateReadable}>
                        <i slot="icon"
                           class="icon-calendar text-2xl absolute left-2 text-ebonyClay cursor-pointer"
                           style="top: 15%;"
                           on:click={() => openDatePicker('dateIssued')}
                           on:keydown={(e) => {if (e.key === 'Enter') {openDatePicker('dateIssued')}}}
                           role="button"
                           tabindex="0"
                        >
                        </i>
                      </TextInput>
                    </div>
                    <div class="datatable">
                      <TextInput
                        on:focus={() => openDatePicker('dateDue')}
                        dataCy="invoice-due-date"
                        type="text"
                        label={t('invoices.form.dateDue.label')}
                        error={t('invoices.form.dateDue.error')}
                        inputStyle="padding-left: 2.5em;"
                        inputClass="input-date-picker dateDue text-paleSky"
                        class="form-input"
                        bind:value={invoiceDueDateReadable}>
                        <i slot="icon"
                           class="icon-calendar text-2xl absolute left-2 text-ebonyClay cursor-pointer"
                           style="top: 15%;"
                           role="button"
                           tabindex="0"
                           on:click={() => openDatePicker('dateDue')}
                           on:keydown={(e) => {if (e.key === 'Enter') {openDatePicker('dateDue')}}}
                        >
                        </i>
                      </TextInput>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="divide-y divide-gray-200 border-t border-whisper sm:px-6">
              <div class="space-y-6 py-7">
                <div class="px-12 sm:px-6">
                  <h2 class="block text-xl font-bold text-gray-900 mb-3">{t('invoices.externalInvoice.uploadInvoice')}</h2>
                  {#if isPDFFileLoading}
                    <div class="flex justify-center w-full items-center border-athensGray border-2 rounded p-8 bg-white mt-6 ">
                      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-dundyOrange"
                           xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </div>
                  {:else}
                    {#if !!currentInvoiceAttachment || !!currentInvoiceHasAttachment}
                      <div class="flex justify-between w-full items-center border-athensGray border-2 rounded p-8 bg-white mt-6">
                        <div class="flex">
                          <svg width="44px" height="52px" viewBox="0 0 44 52"
                               xmlns="http://www.w3.org/2000/svg">
                            <g id="Screens" stroke="none" stroke-width="1" fill="none"
                               fill-rule="evenodd">
                              <g id="9---Invoices---View-Invoice"
                                 transform="translate(-820.000000, -640.000000)">
                                <g id="Invoice-Actions&amp;info-ONHOVER"
                                   transform="translate(808.000000, 628.000000)">
                                  <g id="Group-3"
                                     transform="translate(12.000000, 12.000000)">
                                    <rect id="Rectangle" stroke="#E8EBF0"
                                          fill="#FFFFFF"
                                          x="0.5" y="0.5" width="43"
                                          height="51" rx="6"></rect>
                                    <rect id="Rectangle" fill="#D8D8D8" x="30" y="9"
                                          width="7" height="3" rx="1"></rect>
                                    <rect id="Rectangle" fill="#D8D8D8" x="24"
                                          y="14"
                                          width="13" height="3" rx="1"></rect>
                                    <rect id="Rectangle" fill="#D8D8D8" x="8" y="26"
                                          width="29" height="3" rx="1"></rect>
                                    <rect id="Rectangle" fill="#E8EBF0" x="8" y="32"
                                          width="29" height="3" rx="1"></rect>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                          <div class="flex flex-col ml-4">
                                                        <span class="text-ebonyClay font-semibold text-sm"
                                                              data-cy="invoice-attachment-file-name">{currentInvoiceAttachment?.fileName }</span>
                            <span class="text-baliHai text-s">PDF &middot; {bytesToHighestUnitDisplay(currentInvoiceAttachment?.contentBytesSizeRead, 1)}</span>
                          </div>
                        </div>
                        <div class="relative flex space-x-2">
                          <a download={currentInvoiceAttachment?.fileName}
                             href={attachmentPreviewFile ? attachmentPreviewFile : `data:application/pdf;base64,${currentInvoiceAttachment?.contentBase64}`}><i
                            class="icon-download text-ebonyClay text-3xl"></i></a>
                          {#if !(invoice?.documentSource) || !(invoice?.documentSource?.sourceKind) || invoice?.documentSource?.sourceKind === DocumentSourceKind.FROMDUNDYDUNNINGMANUALLY}
                            <span
                              class="cursor-pointer"
                              role="button"
                              tabindex="0"
                              on:click={() => newUpload()}
                              on:keydown={(e) => {if (e.key === 'Enter') {newUpload()}}}
                            >
                                <i class="icon-outline-close text-ebonyClay text-3xl"></i>
                            </span>
                          {/if}
                        </div>
                      </div>
                    {:else if !currentInvoiceHasAttachment || !currentInvoiceAttachment }
                        <UploadArea on:uploaded={e => {
                          if (e.detail.length > 1) {
                            feedbackService.displayFeedback({
                              feedbackDetails: null,
                              numberOfInvalidItems: 0,
                              responseStatus: 0,
                              feedbackLevel: 'Error',
                              feedbackMessage: t('invoices.createInvoice.errorMultipleFiles')
                            })

                            return
                          } else if (e.detail.length === 0) {
                            feedbackService.displayFeedback({
                              feedbackDetails: null,
                              numberOfInvalidItems: 0,
                              responseStatus: 0,
                              feedbackLevel: 'Error',
                              feedbackMessage: t('invoices.createInvoice.errorNoFile')
                            })

                            return
                          }
                          uploadedFile = e.detail[0]
                          previewFile()
                        }}/>
                    {/if}
                  {/if}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <aside class="bg-slate-50 border-l border-whisper relative overflow-y-auto">
        <div class="fixed top-0 w-9/12 z-20 text-right py-3.5 bg-white border-b border-whisper flex justify-center items-center"
             style="width: calc(100% - 580px);">
          <div class="flex justify-center">
            <button class="btn action-cancel"
                    on:click|preventDefault={() => {saveInvoiceToServer(false)}}>
              {#if !savingDraftToServer}
                {t('invoices.editInvoice.saveAsDraft')}
              {:else}
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-dundyOrange"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                          stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                {t('invoices.createInvoice.savingInProgress')}
              {/if}
            </button>
            <button class="ml-6 inline-flex items-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 disabled:bg-transparent disabled:border-athensGray disabled:text-loblolly disabled:shadow-none"
                    disabled={!customerDone || !canFinalizeAndTrackInvoice}
                    on:click|preventDefault={() => {
                      saveInvoiceToServer(canFinalizeAndTrackInvoice) // TODO correct this incoherent function argument: see saveInvoiceToServer()
                    }}
                    data-cy="invoice-submit-button">
              {#if !savingFinalizedToServer}
                {t('invoices.createInvoice.saveAndTrackInvoiceButton')}
              {:else}
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-dundyOrange"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                          stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                {t('invoices.createInvoice.savingInProgress')}
              {/if}
            </button>
          </div>
        </div>
        <div class="overflow-y-auto justify-center items-center" style="padding: 67px 0 0;">
          {#if currentInvoiceHasAttachment || attachmentPreviewFile}
            <div class="relative">
              <div class="pdf-container">
                {#if isPDFFileLoading}
                  <div class="grid h-screen place-items-center w-full z-[100]">
                    <Loader/>
                  </div>
                {/if}
                {#if currentInvoiceAttachment && currentInvoiceAttachment.contentBase64}
                  <object
                    width="100%"
                    height={windowHeight}
                    type="application/pdf"
                    aria-label="invoice-attachment"
                    aria-labelledby="invoice-attachment"
                    data="data:application/pdf;base64,{currentInvoiceAttachment.contentBase64}#toolbar=0">
                    <p>{t('invoices.viewInvoice.noAttachment')}</p>
                  </object>
                {/if}
                {#if attachmentPreviewFile}
                  <object
                    width="100%"
                    height={windowHeight}
                    type="application/pdf"
                    aria-label="invoice-attachment-preview"
                    aria-labelledby="invoice-attachment-preview"
                    data="{attachmentPreviewFile}#toolbar=0">
                    <p>{t('invoices.viewInvoice.noAttachment')}</p>
                  </object>
                {/if}
              </div>
            </div>

          {:else if !currentInvoiceHasAttachment}
            <div class="grid h-screen place-items-center w-5/12 justify-center items-center mx-auto z-[100]">
              <p>{t('invoices.externalInvoice.noAttachment')}</p>
            </div>
          {/if}
        </div>
      </aside>
    {/if}
  </div>

</FullSizeModal>

<style lang="postcss">
  .pdf-container {
    @apply w-full h-auto;
  }
</style>

export class FeatureToggling {
  isMindeeInvoiceOCREnabled: boolean
  isVoxyInvoicingEnabled: boolean
  isDundyDunningEnabled: boolean
  isBridgeByBankinPaymentEnabled: boolean
  isKotlyEnabled: boolean
  isKchapEnable: boolean
}

export function featureToggling(): FeatureToggling {
  return <FeatureToggling>{
    isBridgeByBankinPaymentEnabled: true,
    isMindeeInvoiceOCREnabled: false,
    isVoxyInvoicingEnabled: true,
    isDundyDunningEnabled: true,
    isKotlyEnabled: false,
    isKchapEnable: true
  }
}


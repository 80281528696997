<script lang="ts">
    import { t } from '../../core-app/lib/i18n/i18nextWrapper'
    import { createEventDispatcher, onMount } from 'svelte'
    import { navigate, Route, Router } from 'svelte-routing'
    import { get } from 'svelte/store'
    import type Invoice from '../models/invoice'
    import type { TodoListItem } from '../models/todo-list-item'
    import { TodosStore } from '../stores/todos.store'
    import { DunningInvoicesStore } from '../stores/dunning-invoices.store'
    import { dunningInvoicesService } from '../services/dunning-invoices.service'
    import { DunningInvoicesHelper } from '../helpers/dunning-invoices-helper'
    import FullSizeModal from '../../core-app/lib/ui-kit/FullSizeModal.svelte'
    import { feedbackService } from '../../core-app/services/feedback.service'
    import { Feedback } from '../../core-app/models/feedback'
    import { DocumentSourceKind } from '../../order-to-cash-lib/models/document-source-kind'
    import { Tooltip } from '../../core-app/lib/ui-kit/Tooltip'
    import ConfirmationModal from '../../core-app/lib/ui-kit/ConfirmationModal.svelte'
    import PDFViewer from '../lib/dundy-file-mgt/DundyInvoicePDFPDFViewer.svelte'
    import ViewInvoiceDetails from '../lib/view-excerpt-invoice/ViewInvoiceDetails.svelte'
    import ViewInvoiceActivity from '../lib/view-excerpt-invoice/ViewInvoiceActivity.svelte'
    import ViewInvoiceCompanyLogoAndName from '../lib/view-excerpt-invoice/ViewInvoiceCompanyLogoAndName.svelte'
    import ViewInvoiceKeyInformation from '../lib/view-excerpt-invoice/ViewInvoiceKeyInformation.svelte'
    import ViewInvoiceNavigation from '../lib/view-excerpt-invoice/ViewInvoiceNavigation.svelte'
    import DownloadPDFButton from '../lib/dundy-file-mgt/DownloadDundyPDFButton.svelte'
    import ViewTodoDunningButton from '../../order-to-cash-lib/components/ViewTodoDunningButton.svelte'
    import { updateNewNavigationHistoryItem } from '../../core-app/stores/navigationHistory.store'
    import {
      NavigationHistoryItem
    } from '../../core-app/models/navigation-history-item'
    import { getInvoiceExcerptMainTitle } from '../services/dunning-invoices-functions'
    import { InvoiceStatus } from '../enums/invoice-status.js'
    import mixpanel from 'mixpanel-browser'

    /** Init Const **/
    const dispatch = createEventDispatcher()

    const close = () => {
      dispatch('close', returnToPathOnClose)
    }

    /** Exported Var **/
    export let invoiceId: string
    export let invoice: Invoice = $DunningInvoicesStore.find((invoice) => invoice.dataId === invoiceId)
    export let returnToPathOnClose: NavigationHistoryItem

    /** Let declarations **/
    let windowHeight: number
    let isDuplicating: boolean = false
    let isVoiding: boolean = false
    let isMenuAccessible: boolean
    let isCloseable: boolean
    let displayInvoiceDeleteConfirmation: boolean

    /** Reactive declarations **/
    $: isCloseable = !(isDuplicating || isVoiding)
    $: isMenuAccessible = !(isDuplicating || isVoiding)

    const duplicateDundyInvoice = (invoice: Invoice) => new Promise(async (resolve, reject) => {
      try {
        const duplicatedInvoice: Invoice = DunningInvoicesHelper.duplicateInvoice(invoice)
        /* console.log('duplicatedInvoice', duplicatedInvoice) */
        const invoiceStore: Invoice[] = get(DunningInvoicesStore)
        let newInvoiceStore: Invoice[] = [...invoiceStore, duplicatedInvoice]
        dunningInvoicesService.setInvoicesStoreToFetchedInvoiceListOnlyWheneverMostUpToDate(newInvoiceStore)
        resolve(duplicatedInvoice)
      } catch (e) {
        reject(e)
      }
    })

    /** Central Function to Route & Duplicate Invoice **/
    const duplicateInvoice = (invoice: Invoice) => {
      isDuplicating = true
      if (invoice.documentSource.sourceKind !== DocumentSourceKind.FROMDUNDYDUNNINGMANUALLY) {
        isDuplicating = false

        return
      } else if (invoice.documentSource.sourceKind === DocumentSourceKind.FROMDUNDYDUNNINGMANUALLY) {

        duplicateDundyInvoice(invoice)
          .then((duplicatedInvoice: Invoice) => {
            isDuplicating = false
            navigate(`/receivables/external/edit/${duplicatedInvoice.dataId}`)
          })
          .catch(() => {
            isDuplicating = false
            feedbackService.displayFeedback(<Feedback>{
              feedbackMessage: t('invoices.duplicateInvoice.duplicateError'),
              feedbackLevel: 'Error'
            })
          })
      } else {
        isDuplicating = false
        feedbackService.displayFeedback(<Feedback>{
          feedbackLevel: 'Error',
          feedbackMessage: t('invoices.duplicateInvoice.duplicateError')
        })
        /* console.log('this is terribly wrong') */
      }
    }


    const deleteInvoice = (invoice: Invoice) => {
      /* console.log('this is a Dundy') */
      /* console.log('voidInvoice', invoice) */
      isVoiding = true
      if (invoice.documentSource.sourceKind === DocumentSourceKind.FROMDUNDYDUNNINGMANUALLY) {
        /* console.log('this is a Dundy') */
        // Todo - implement confirmation then delete of Dundy invoices
        isVoiding = false
      } else {
        isVoiding = false
        feedbackService.displayFeedback(<Feedback>{
          feedbackLevel: 'Error',
          feedbackMessage: t('invoices.voidInvoice.voidError')
        })
        /* console.log('this is terribly wrong') */
      }

    }

    onMount(() => {
      mixpanel.track('JE10 List of Invoices Modal', {
        'Description': 'Open DundyInvoiceViewFullModal.svelte'
      })
      updateNewNavigationHistoryItem('DundyInvoiceViewFullModal.svelte')

      if (!invoice.completed && !invoice.isTracked) {
        feedbackService.displayFeedback(<Feedback>{
          feedbackLevel: 'Error',
          feedbackMessage: t('invoices.viewInvoice.invoiceNotFinalized')
        })
        navigate('/invoices')
      }
    })
</script>

<svelte:window bind:innerHeight={windowHeight}/>

<FullSizeModal
        isCloseable={isCloseable}
        class="w-full overflow-y-auto h-full"
        on:close={close}>

    <div slot="content" class="grid w-full h-full"
         style="grid-template-rows: 72px 1fr; grid-template-columns: 1fr 455px;">

        <ConfirmationModal
                modalHeading={t('invoices.externalInvoice.deleteExternalInvoiceTitle')}
                primaryButtonText={t('invoices.externalInvoice.deleteConfirmButtonModal')}
                secondaryButtonText={t('invoices.externalInvoice.deleteCancelButtonModal')}
                open={displayInvoiceDeleteConfirmation}
                on:cancelAndClose={() => {displayInvoiceDeleteConfirmation = false}}
                on:confirmAction={() => deleteInvoice(invoice)}>{t('invoices.externalInvoice.deleteExternalInvoiceBody')}</ConfirmationModal>

        <header class="border-b border-athensGray flex align-middle items-center justify-center z-50"
                style="grid-column: 1/span 2; justify-content: flex-end; padding: 16px;">
            <h2 class="font-bold text-xl mr-auto">{t(getInvoiceExcerptMainTitle(invoice))} {invoice.invoiceNumber}</h2>

            <div class="flex items-center space-x-3 mr-16">

                <div class="relative flex flex-col items-center group">
                    <DownloadPDFButton invoiceId={invoice?.dataId}/>
                </div>


                <Tooltip>
                    <div slot="activator">
                        <button disabled={!isMenuAccessible}
                                on:click|preventDefault|stopPropagation={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  displayInvoiceDeleteConfirmation = true
                                }}
                                class="h-12 w-12 flex items-center justify-center rounded hover:border hover:border-black hover:text-black focus:z-10 focus:ring-2 focus:ring-whisper"
                                aria-label="{t('invoices.viewInvoice.buttons.voidButton')}" type="button">
                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="1.5"
                                      d="M10.75 9.75 13 12m0 0 2.25 2.25M13 12l2.25-2.25M13 12l-2.25 2.25m-6-2.25 3.41 5.328a2 2 0 0 0 1.685.922h7.405a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2H9.845a2 2 0 0 0-1.685.922L4.75 12Z"></path>
                            </svg>

                        </button>
                    </div>
                    {t('invoices.viewInvoice.buttons.deleteButton')}
                </Tooltip>


                <Tooltip>
                    <div slot="activator">
                        <button disabled={!isMenuAccessible} on:click={() => {duplicateInvoice(invoice)}}
                                class="h-12 w-12 flex items-center justify-center rounded hover:border hover:border-black hover:text-black focus:z-10 focus:ring-2 focus:ring-whisper"
                                aria-label="{t('invoices.viewInvoice.buttons.copyDundyButton')}" type="button">

                            {#if !isDuplicating}
                                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="1.5"
                                          d="M6.5 15.25V15.25C5.5335 15.25 4.75 14.4665 4.75 13.5V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H13.5C14.4665 4.75 15.25 5.5335 15.25 6.5V6.5"></path>
                                    <rect width="10.5" height="10.5" x="8.75" y="8.75" stroke="currentColor"
                                          stroke-linecap="round"
                                          stroke-linejoin="round" stroke-width="1.5" rx="2"></rect>
                                </svg>
                            {:else}
                                <svg class="animate-spin h-5 w-5 text-dundyOrange" xmlns="http://www.w3.org/2000/svg"
                                     fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            {/if}
                        </button>
                    </div>
                    {t('invoices.viewInvoice.buttons.copyDundyButton')}
                </Tooltip>

                {#if invoice?.status === InvoiceStatus.OUTSTANDING || invoice?.status === InvoiceStatus.DISPUTED}
                    <ViewTodoDunningButton
                            isDisabled={!isMenuAccessible}
                            invoiceNumber={invoice.invoiceNumber}
                            customerId={invoice.toId}
                            invoiceId={invoice.dataId}

                    />
                {/if}
            </div>
        </header>

        <main class="overflow-y-auto">
            <PDFViewer invoiceId={invoice.dataId}/>
        </main>

        <aside class="w-full border-l border-athensGray overflow-y-auto">
            <ViewInvoiceCompanyLogoAndName {invoice}/>

            <div class="py-3">
                <ViewInvoiceKeyInformation {invoice}/>
            </div>

            <ViewInvoiceNavigation {invoice}/>

            <Router>
                <Route path="details">
                    <ViewInvoiceDetails {invoice}/>
                </Route>
                <Route path="activity">
                    <ViewInvoiceActivity {invoice}/>
                </Route>
            </Router>

        </aside>

    </div>
</FullSizeModal>


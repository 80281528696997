<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper.js'
    import InvoiceLifeCycleStatus from '../../../order-to-cash-lib/components/InvoiceLifeCycleStatus.svelte'
    import { Link } from 'svelte-routing'
    import { DocumentSourceKind } from '../../../order-to-cash-lib/models/document-source-kind.js'
    import Invoice from '../../models/invoice'
    import type { Customer } from '../../../crm-app/models/customer'
    import { TodoListItem } from '../../models/todo-list-item'
    import { Amount } from '../../../cash-application-app/models/amount-of-money-model.js'
    import { formatCurrencyWithOptions } from '../../../voxy-app/utils/number-formatting-utils.js'

    /** Export Declarations */
    export let todo: TodoListItem
    export let invoice: Invoice
    export let customer: Customer
</script>

<section class="flex justify-between align-middle items-center">
    <div class="w-full bg-white border border-athensGray relative py-6 px-6 rounded">
        <div class="flex mx-auto -m-10  items-center align-middle justify-center bg-black w-12 h-12 p-3 rounded-lg font-bold text-3xl text-white shadow-sm">
            {customer?.company?.formalName.substring(0, 1).toUpperCase()}
        </div>
        <Link to="/today" class="flex items-center text-dundyOrange mt-6">
            <i class="icon-arrow-left"></i>
            <span class="text-sm">{t('todoAction.backLink')}</span>
        </Link>
        <div class="flex text-center justify-center items-center mt-1">
            <div class="w-full flex justify-center relative">
                <h1 class="font-bold text-xl text-black relative">{t('todoAction.invoiceLabel')} {invoice?.invoiceNumber}
                    <span class="absolute -right-32 top-0">
                        <InvoiceLifeCycleStatus
                                source={DocumentSourceKind.FROMDUNDYDUNNINGMANUALLY}
                                invoiceId={invoice?.dataId}/>
                    </span>
                </h1>
            </div>
        </div>

        <!-- <pre>{JSON.stringify(invoice, null, 2)}</pre> -->
        <h3 class="font-medium text-sm text-center">
          <Link to="{`/clients/${invoice?.toId}/overview`}">{customer?.company?.formalName}</Link>
        </h3>

        <div class="flex w-full justify-between mt-6">
            <div class="w-3/12">&nbsp;</div>
            <div class="w-9/12 flex justify-around">
                <div class="w-3/12 flex flex-col text-center">
                    <span class="font-bold text-baliHai text-s">{t('invoices.columns.issuedDate')}</span>
                    <span class="text-s">{new Intl.DateTimeFormat(t('locales'), {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    }).format(new Date(invoice?.dateIssued ? invoice?.dateIssued : (new Date).toISOString()))}</span>
                </div>
                <div class="w-3/12 flex flex-col text-center">
                    <span class="font-bold text-baliHai text-s">{t('invoices.columns.dueDate')}</span>
                    <span class="text-s">{new Intl.DateTimeFormat(t('locales'), {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    }).format(new Date(invoice?.dateDue ? invoice?.dateDue : (new Date).toISOString()))}</span>
                </div>
                <div class="w-3/12 flex flex-col text-right">
                    <span class="font-bold text-baliHai text-s">{t('todoItem.amountDueWithTaxes')}</span>
                    {#if !!todo}
                        <span class="text-2xl font-bold">
                            {formatCurrencyWithOptions(Amount.PrototypeToClass(todo?.totalScopeAmountOfMoneyDue).GetAmountScaledValue(), t('locales'), invoice?.currency, true, 'symbol')}
                        </span>
                    {:else}
                        <span class="text-2xl font-bold">
                            {formatCurrencyWithOptions(invoice?.amountIncludingTaxes, t('locales'), invoice?.currency, true, 'symbol')}
                        </span>
                    {/if}
                </div>
            </div>
        </div>
    </div>
</section>
import { AmountOfTaxesWithExcludingAndIncludingDecimal } from '../../../models/tax-rate'
import { BusinessDocument, InstallmentComputationKind } from '../../../models/business-document'
import { Decimal } from 'decimal.js'
import { deepClone } from '../../../../core-app/util/object-deep-cloning'
import type {
  NewBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange
} from '../../../models/business-documents-installments.model'
import { InstallmentChangeResultingValues } from '../../../models/business-documents-installments.model'
import {
  getInstallmentAbsoluteValueForInputInit,
  getInstallmentPercentageForInputInit
} from '../../business-document-various-functions'
import { NewAmountOfMoneyWithDecimal } from '../../../../cash-application-app/models/amount-of-money-decimal-model'
import {
  roundScaledAmountToTheCentDecimal
} from '../../taxes-excluding-and-including-calculus-using-decimal-js.pure-functions'
import { getInstallmentDescription } from '../../installment/installment.service'
import { InvoicingBalanceLimits } from '../../../models/invoicing-balance-limits.model'

export function initInvoicingBalanceLimits() {
  return <InvoicingBalanceLimits>{
    dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesOnlyFinalized: new Decimal(0),
    balanceInvoicingMetricsUpdated: false,
    dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesIncludingCurrentDraft: new Decimal(0),
    dealMaxRemainingPercentToInvoiceIncludingCurrentDraft: new Decimal(0),
    dealMaxRemainingPercentToInvoiceOnlyFinalized: new Decimal(0),
    dealTotalAmountToInvoiceScaledValueIncludingTaxes: new Decimal(0),
    dealInvoicedAmountScaledValueDraftedOnly: new Decimal(0),
    dealInvoicedPercentDraftedOnly: new Decimal(0)
  }
}


/**
 * Get Initially Displayed Input Percentage
 * @param {BusinessDocument} businessDocument - The business document.
 * @returns {number | undefined} Returns the initially displayed input percentage if the business document has a specific installment; otherwise returns `undefined`.
 */
export function initiallyDisplayedInputPercentage(businessDocument: BusinessDocument): number | undefined {
  if (businessDocument.hasSpecificInstallment) {
    return getInstallmentPercentageForInputInit(businessDocument)
  } else {
    return undefined
  }
}

/**
 * Get Initially Displayed Input Absolute Value
 * @param {BusinessDocument} businessDocument - The business document.
 * @returns {number | undefined} Returns the initially displayed input AbsoluteValue if the business document has a specific installment; otherwise returns `undefined`.
 */
export function initiallyDisplayedInputAbsoluteValue(businessDocument: BusinessDocument): number | undefined {
  if (businessDocument.hasSpecificInstallment) {
    return getInstallmentAbsoluteValueForInputInit(businessDocument)
  } else {
    return undefined
  }
}


/**
 * Update the business document based upon the new percentage
 * @param currentBusinessDocument
 * @param hasInstallmentSpecified
 * @param newAmountAndTaxesDecimal
 * @param installmentComputationKind
 * @param installmentChosenValue
 * @param installmentDescription
 */
export function updateInstallmentBasedUponNewAmountAndTaxesDecimal(
  currentBusinessDocument: BusinessDocument,
  hasInstallmentSpecified: boolean,
  newAmountAndTaxesDecimal: AmountOfTaxesWithExcludingAndIncludingDecimal,
  installmentComputationKind: InstallmentComputationKind,
  installmentChosenValue: Decimal,
  installmentDescription: string,
): BusinessDocument {
  const newBusinessDocument: BusinessDocument = <BusinessDocument>deepClone(currentBusinessDocument)
  if (hasInstallmentSpecified) {
    newBusinessDocument.hasSpecificInstallment = hasInstallmentSpecified
    newBusinessDocument.installmentResultIncludingTaxScaledValue = newAmountAndTaxesDecimal.amountIncludingTaxes.GetAmountDecimalScaledValue().toNumber()
    newBusinessDocument.installmentResultExcludingTaxScaledValue = newAmountAndTaxesDecimal.amountExcludingTaxes.GetAmountDecimalScaledValue().toNumber()
    newBusinessDocument.installmentResultTaxScaledValue = newAmountAndTaxesDecimal.taxesAmount.GetAmountDecimalScaledValue().toNumber()
    newBusinessDocument.installmentChosenKind = installmentComputationKind
    newBusinessDocument.installmentChosenValue = installmentChosenValue.toNumber()
    newBusinessDocument.installmentDescription = installmentDescription
  } else {
    newBusinessDocument.hasSpecificInstallment = hasInstallmentSpecified
    newBusinessDocument.installmentResultIncludingTaxScaledValue = newBusinessDocument.totalIncludingTaxScaledValue
    newBusinessDocument.installmentResultExcludingTaxScaledValue = newBusinessDocument.totalExcludingTaxScaledValue
    newBusinessDocument.installmentResultTaxScaledValue = newBusinessDocument.totalTaxScaledValue
    newBusinessDocument.installmentChosenKind = installmentComputationKind
    newBusinessDocument.installmentChosenValue = installmentChosenValue.toNumber()
    newBusinessDocument.installmentDescription = installmentDescription
  }

  return newBusinessDocument
}

// NB: sanitizeInstallmentPercentInputValue() needs invoicingBalanceLimits for its calculations
// - esp. the invoicingBalanceLimits finalized sums and theinvoicingBalanceLimits total deal amount to invoice are useful to prevent user values from tripping outside the expected range
// - HOWEVER we should never use the invoicingBalanceLimits values including the edited draft since the workflow in here transitioning between the old invoicingBalanceLimits value before user change and after user change
// - consequence: do not use invoicingBalanceLimits.dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesIncludingCurrentDraft and invoicingBalanceLimits.dealMaxRemainingPercentToInvoiceIncludingCurrentDraft in the below function
export function sanitizeInstallmentPercentInputValue(userInputPercentageInputByUser: Decimal, businessDocument: BusinessDocument, allConcernedBusinessDocuments: BusinessDocument[], invoicingBalanceLimits: InvoicingBalanceLimits): Decimal {
  if ((new Decimal(businessDocument.totalIncludingTaxScaledValue)).abs().lessThanOrEqualTo(0.0001)) {
    /* console.warn('sanitizeInstallmentPercentInputValue returns zero') */

    return new Decimal(0)
  }

  /* console.warn('sanitizeInstallmentPercentInputValue businessDocument.totalIncludingTaxScaledValue', businessDocument.totalIncludingTaxScaledValue) */
  const associatedAbsoluteValueScaledAmount: Decimal = roundScaledAmountToTheCentDecimal((new Decimal(businessDocument.totalIncludingTaxScaledValue).div(new Decimal(100)).mul(userInputPercentageInputByUser)))
  const actuallyPossiblePercentInputValue: Decimal = roundScaledAmountToTheCentDecimal(associatedAbsoluteValueScaledAmount.mul(new Decimal(100)).div(new Decimal(businessDocument.totalIncludingTaxScaledValue)))
  let userInputPercentageInputByUserCorrected: Decimal = actuallyPossiblePercentInputValue
  /* console.warn('sanitizeInstallmentPercentInputValue associatedAbsoluteValueScaledAmount', associatedAbsoluteValueScaledAmount.toNumber()) */
  /* console.warn('sanitizeInstallmentPercentInputValue actuallyPossiblePercentInputValue', actuallyPossiblePercentInputValue.toNumber()) */

  const epsilonInPercent: Decimal = (new Decimal(0.01)).div(new Decimal(businessDocument.totalIncludingTaxScaledValue)).mul(new Decimal(100)).abs()
  const value100MinusEpsilonInPercent: Decimal = (new Decimal(100)).minus(epsilonInPercent)

  if (invoicingBalanceLimits.balanceInvoicingMetricsUpdated) {
    if (userInputPercentageInputByUserCorrected.abs().greaterThanOrEqualTo(Decimal.min(new Decimal(100), invoicingBalanceLimits.dealMaxRemainingPercentToInvoiceOnlyFinalized))) {
      // if (Math.abs(userInputPercentageInputByUserCorrected) > Math.min(100, invoicingBalanceLimits.dealMaxRemainingPercentToInvoiceOnlyFinalized.toNumber())) {
      // userInputPercentageInputByUserCorrected = Math.sign(userInputPercentageInputByUserCorrected)
      //     * Math.min(100, invoicingBalanceLimits.dealMaxRemainingPercentToInvoiceOnlyFinalized.toNumber());
      userInputPercentageInputByUserCorrected = new Decimal(Decimal.sign(userInputPercentageInputByUserCorrected))
        .mul(Decimal.min(value100MinusEpsilonInPercent, invoicingBalanceLimits.dealMaxRemainingPercentToInvoiceOnlyFinalized))
      /* console.warn('case 1 now userInputPercentageInputByUserCorrected=', userInputPercentageInputByUserCorrected.toNumber()) */
    } else {
      /* console.warn('case 1 else now userInputPercentageInputByUserCorrected=', userInputPercentageInputByUserCorrected.toNumber()) */
    }
  } else {
    // if (Math.abs(userInputPercentageInputByUserCorrected) > 100) {
    if (userInputPercentageInputByUserCorrected.abs().greaterThanOrEqualTo(new Decimal(100))) {
      // userInputPercentageInputByUserCorrected = Math.sign(userInputPercentageInputByUserCorrected) * 100;
      userInputPercentageInputByUserCorrected = new Decimal(Decimal.sign(userInputPercentageInputByUserCorrected))
        .mul(value100MinusEpsilonInPercent)


      /* console.warn('case 2 now userInputPercentageInputByUserCorrected=', userInputPercentageInputByUserCorrected.toNumber()) */
    } else {
      /* console.warn('case 2 else now userInputPercentageInputByUserCorrected=', userInputPercentageInputByUserCorrected.toNumber()) */
    }
  }

  // if (userInputPercentageInputByUserCorrected < 0) {
  //     userInputPercentageInputByUserCorrected = 0;
  // }
  if (userInputPercentageInputByUserCorrected.lessThan(new Decimal(0))) {
    userInputPercentageInputByUserCorrected = new Decimal(0)
    /* console.warn('case 3 now userInputPercentageInputByUserCorrected=', userInputPercentageInputByUserCorrected.toNumber()) */
  }

  return userInputPercentageInputByUserCorrected
}

// NB: sanitizeInstallmentPercentInputValue() needs invoicingBalanceLimits for its calculations
// - esp. the invoicingBalanceLimits finalized sums and the invoicingBalanceLimits total deal amount to invoice are useful to prevent user values from tripping outside the expected range
// - HOWEVER we should never use the invoicingBalanceLimits values including the edited draft since the workflow in here transitioning between the old invoicingBalanceLimits value before user change and after user change
// - consequence: do not use invoicingBalanceLimits.dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesIncludingCurrentDraft and invoicingBalanceLimits.dealMaxRemainingPercentToInvoiceIncludingCurrentDraft in the below function
export function sanitizeInstallmentAbsoluteInputValue(userInputAbsoluteValueInputByUser: Decimal, businessDocument: BusinessDocument, allConcernedBusinessDocuments: BusinessDocument[], invoicingBalanceLimits: InvoicingBalanceLimits): Decimal {
  let userInputAbsoluteValueInputByUserCorrected: Decimal = roundScaledAmountToTheCentDecimal(userInputAbsoluteValueInputByUser)
  const epsilonInAbsoluteValue: Decimal = new Decimal(0.01)
  if (invoicingBalanceLimits.balanceInvoicingMetricsUpdated) {
    // if (Math.abs(userInputAbsoluteValueInputByUserCorrected) > Math.min(Math.abs(businessDocument.totalIncludingTaxScaledValue), invoicingBalanceLimits.dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesOnlyFinalized.abs().toNumber())) {
    if (userInputAbsoluteValueInputByUserCorrected.abs().greaterThanOrEqualTo(Decimal.min((new Decimal(businessDocument.totalIncludingTaxScaledValue)).abs(), invoicingBalanceLimits.dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesOnlyFinalized.abs()))) {
      userInputAbsoluteValueInputByUserCorrected =
                (
                  new Decimal(Decimal.sign(businessDocument.totalIncludingTaxScaledValue))
                    .mul(
                      Decimal.min(
                        (new Decimal(businessDocument.totalIncludingTaxScaledValue)).abs().minus(epsilonInAbsoluteValue),
                        invoicingBalanceLimits.dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesOnlyFinalized.abs(),
                      ),
                    )
                )

      // * Math.min(
      //     Math.abs(businessDocument.totalIncludingTaxScaledValue),
      //     invoicingBalanceLimits.dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesOnlyFinalized.abs().toNumber(),
      // );
    }
  } else {
    // if (Math.abs(userInputAbsoluteValueInputByUserCorrected) > Math.abs(businessDocument.totalIncludingTaxScaledValue)) {
    if (userInputAbsoluteValueInputByUserCorrected.abs().greaterThanOrEqualTo((new Decimal(businessDocument.totalIncludingTaxScaledValue)).abs())) {
      // userInputAbsoluteValueInputByUserCorrected = Math.sign(businessDocument.totalIncludingTaxScaledValue)
      //     * Math.abs(businessDocument.totalIncludingTaxScaledValue);
      userInputAbsoluteValueInputByUserCorrected =
                (
                  new Decimal(Decimal.sign(businessDocument.totalIncludingTaxScaledValue))
                    .mul(
                      (new Decimal(businessDocument.totalIncludingTaxScaledValue)).abs().minus(epsilonInAbsoluteValue),
                    )
                )

    }
  }

  // if (Math.sign(userInputAbsoluteValueInputByUserCorrected) !== Math.sign(businessDocument.totalIncludingTaxScaledValue)) {
  if (Decimal.sign(userInputAbsoluteValueInputByUserCorrected) !== Decimal.sign(new Decimal(businessDocument.totalIncludingTaxScaledValue))) {
    /* console.warn('changing sign of userInputAbsoluteValueInputByUserCorrected') */
    userInputAbsoluteValueInputByUserCorrected = userInputAbsoluteValueInputByUserCorrected.neg()
  }

  return userInputAbsoluteValueInputByUserCorrected
}


/**
 *
 * @param originalAmountAndTaxes
 * @param percentToKeepFromOriginalAmount
 */
export function calculateInstallmentChangeResultingValuesFromPercentInputChange(originalAmountAndTaxes: AmountOfTaxesWithExcludingAndIncludingDecimal, percentToKeepFromOriginalAmount: Decimal): InstallmentChangeResultingValues {
  const amountScaledExcludingTaxes: Decimal = roundScaledAmountToTheCentDecimal(originalAmountAndTaxes.amountExcludingTaxes.GetAmountDecimalScaledValue().mul(percentToKeepFromOriginalAmount).div(100))
  const amountScaledIncludingTaxes: Decimal = roundScaledAmountToTheCentDecimal(originalAmountAndTaxes.amountIncludingTaxes.GetAmountDecimalScaledValue().mul(percentToKeepFromOriginalAmount).div(100))
  const taxesScaledAmount: Decimal = roundScaledAmountToTheCentDecimal(amountScaledIncludingTaxes.minus(amountScaledExcludingTaxes))
  const resultingAmountAndTaxes: AmountOfTaxesWithExcludingAndIncludingDecimal = <AmountOfTaxesWithExcludingAndIncludingDecimal>{
    amountExcludingTaxes: NewAmountOfMoneyWithDecimal(amountScaledExcludingTaxes, new Decimal(2), originalAmountAndTaxes.amountExcludingTaxes.currencyCode),
    amountIncludingTaxes: NewAmountOfMoneyWithDecimal(amountScaledIncludingTaxes, new Decimal(2), originalAmountAndTaxes.amountIncludingTaxes.currencyCode),
    taxesAmount: NewAmountOfMoneyWithDecimal(taxesScaledAmount, new Decimal(2), originalAmountAndTaxes.taxesAmount.currencyCode)
  }

  return {
    resultingInstallmentPercentageDecimal: percentToKeepFromOriginalAmount,
    resultingBusinessDocumentInstallmentAmountAndTaxes: resultingAmountAndTaxes,
    resultingInstallmentChosenKind: InstallmentComputationKind.PERCENT_OF_TOTAL_AMOUNT,
    resultingInstallmentChosenValue: percentToKeepFromOriginalAmount
  }
}

export function calculateInstallmentChangeResultingValuesFromAbsoluteValueInputChange(
  originalAmountAndTaxes: AmountOfTaxesWithExcludingAndIncludingDecimal,
  targetScaledAmountIncludingTaxes: Decimal,
): InstallmentChangeResultingValues {
  const finalTargetScaledAmountIncludingTaxes: Decimal =
        roundScaledAmountToTheCentDecimal(targetScaledAmountIncludingTaxes)
  let ratioOriginalScaledAmountIncludingTaxesToFinalTargetScaledAmountIncludingTaxes: Decimal
  if (originalAmountAndTaxes.amountIncludingTaxes.GetAmountDecimalScaledValue().abs().lessThanOrEqualTo(0.001)) {
    /* console.error('decimalBusinessDocumentTotalIncludingTax is zero, cannot div by 0') */
    ratioOriginalScaledAmountIncludingTaxesToFinalTargetScaledAmountIncludingTaxes = new Decimal(9999999999999)
  } else {
    ratioOriginalScaledAmountIncludingTaxesToFinalTargetScaledAmountIncludingTaxes = finalTargetScaledAmountIncludingTaxes.div(originalAmountAndTaxes.amountIncludingTaxes.GetAmountDecimalScaledValue())
  }
  const resultingTargetScaledAmountExcludingTaxes: Decimal =
        roundScaledAmountToTheCentDecimal(
          originalAmountAndTaxes.amountExcludingTaxes.scaledValue.mul(ratioOriginalScaledAmountIncludingTaxesToFinalTargetScaledAmountIncludingTaxes),
        )
  const resultingTargetScaledTaxesAmount: Decimal =
        roundScaledAmountToTheCentDecimal(
          finalTargetScaledAmountIncludingTaxes.minus(resultingTargetScaledAmountExcludingTaxes),
        )
  const finalPercent: Decimal =
        roundScaledAmountToTheCentDecimal(
          ratioOriginalScaledAmountIncludingTaxesToFinalTargetScaledAmountIncludingTaxes.mul(100 * 100),
        ).div(100)
  const resultingAmountAndTaxes: AmountOfTaxesWithExcludingAndIncludingDecimal = <AmountOfTaxesWithExcludingAndIncludingDecimal>{
    amountExcludingTaxes: NewAmountOfMoneyWithDecimal(resultingTargetScaledAmountExcludingTaxes, new Decimal(2), originalAmountAndTaxes.amountExcludingTaxes.currencyCode),
    amountIncludingTaxes: NewAmountOfMoneyWithDecimal(finalTargetScaledAmountIncludingTaxes, new Decimal(2), originalAmountAndTaxes.amountIncludingTaxes.currencyCode),
    taxesAmount: NewAmountOfMoneyWithDecimal(resultingTargetScaledTaxesAmount, new Decimal(2), originalAmountAndTaxes.taxesAmount.currencyCode)
  }

  return {
    resultingInstallmentPercentageDecimal: finalPercent,
    resultingBusinessDocumentInstallmentAmountAndTaxes: resultingAmountAndTaxes,
    resultingInstallmentChosenKind: InstallmentComputationKind.ABSOLUTE_AMOUNT_INCLUDING_TAX,
    resultingInstallmentChosenValue: resultingAmountAndTaxes.amountIncludingTaxes.GetAmountDecimalScaledValue()
  }
}


/**
 * Detect Absolute Value Change, used as argument in applyInstallmentValueChangeUI
 * @param sanitizeInstallmentInputValue is whether sanitizeInstallmentAbsoluteInputValue (when Installment absolute value is changed)  or sanitizeInstallmentPercentInputValue (when Installment percent is changed)
 * @param calculateInstallmentChangeResultingValuesInputChange is whether calculateInstallmentChangeResultingValuesFromAbsoluteValueInputChange (when Installment absolute value is changed) or calculateInstallmentChangeResultingValuesFromPercentInputChange (when Installment percent is changed)
 * @param invoicingBalanceLimits
 */
export function applyInstallmentGenericallyChange<T extends Decimal>(
  sanitizeInstallmentInputValue: (newInputValue: T, businessDocument: BusinessDocument, allConcernedBusinessDocuments: BusinessDocument[], invoicingBalanceLimits: InvoicingBalanceLimits) => T,
  calculateInstallmentChangeResultingValuesInputChange: (originalAmountAndTaxes: AmountOfTaxesWithExcludingAndIncludingDecimal, newInstallmentValueInputByUser: Decimal) => InstallmentChangeResultingValues,
  invoicingBalanceLimits: InvoicingBalanceLimits,
): (newInstallmentValueInputByUser: T,
    businessDocument: BusinessDocument,
    allConcernedBusinessDocuments: BusinessDocument[],
  ) => NewBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange {
  return (newInstallmentValueInputByUser: T,
    businessDocument: BusinessDocument,
    allConcernedBusinessDocuments: BusinessDocument[],
  ): NewBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange => {
    /* console.log('a new document was built with newInputValue', newInstallmentValueInputByUser.toNumber()) */
    /* console.log('newInstallmentValueInputByUser', newInstallmentValueInputByUser.toNumber()) */
    /* console.log('invoicingBalanceLimits', JSON.stringify(invoicingBalanceLimits, null, 3)) */
    // INPUT VALIDATION
    if (!newInstallmentValueInputByUser) {
      return <NewBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange>{
        doNothing: true
      }
    }
    // NB: (1) generic sanitizeInstallmentInputValue() only uses the invoicingBalanceLimits finalized data and
    // (2) generic calculateInstallmentChangeResultingValuesInputChange() does not take invoicingBalanceLimits as argument at all and finally
    // (3) applyInstallmentGenericallyChange() do not use invoicingBalanceLimits anywhere else: so no coherence problem expected overall.
    let userInputPercentageInputByUserCorrected: T
            = sanitizeInstallmentInputValue(newInstallmentValueInputByUser, businessDocument, allConcernedBusinessDocuments, invoicingBalanceLimits)
    /* console.log('a new document was built with userInputPercentageInputByUserCorrected', userInputPercentageInputByUserCorrected.toNumber()) */
    // OTHER VALUE CALCULATION
    const originalAmountAndTaxes: AmountOfTaxesWithExcludingAndIncludingDecimal = <AmountOfTaxesWithExcludingAndIncludingDecimal>{
      amountExcludingTaxes: NewAmountOfMoneyWithDecimal(
        new Decimal(businessDocument.totalExcludingTaxScaledValue),
        new Decimal(2),
        businessDocument.currency,
      ),
      amountIncludingTaxes: NewAmountOfMoneyWithDecimal(
        new Decimal(businessDocument.totalIncludingTaxScaledValue),
        new Decimal(2),
        businessDocument.currency,
      ),
      taxesAmount: NewAmountOfMoneyWithDecimal(
        new Decimal(businessDocument.totalTaxScaledValue),
        new Decimal(2),
        businessDocument.currency,
      )
    }
    let hasInstallmentSpecified: boolean

    const result: InstallmentChangeResultingValues = calculateInstallmentChangeResultingValuesInputChange(
      originalAmountAndTaxes,
      new Decimal(userInputPercentageInputByUserCorrected),
    )

    hasInstallmentSpecified = true
    let description: string = getInstallmentDescription(businessDocument.businessDocumentId, businessDocument.businessDocumentKind, result.resultingInstallmentChosenKind, result.resultingInstallmentChosenValue.toNumber(), businessDocument.currency, businessDocument.taxonomyTags)
    /* console.log('new description', description) */
    const newBusinessDocumentWithAppliedValued: BusinessDocument = deepClone(updateInstallmentBasedUponNewAmountAndTaxesDecimal(
      businessDocument,
      hasInstallmentSpecified,
      result.resultingBusinessDocumentInstallmentAmountAndTaxes,
      result.resultingInstallmentChosenKind,
      result.resultingInstallmentChosenValue,
      description))

    // RESULT RETURN
    return <NewBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange>{
      doNothing: false,
      newBusinessDocument: newBusinessDocumentWithAppliedValued,
      newUserInputPercentage: result.resultingInstallmentPercentageDecimal.toNumber(),
      newUserInputAbsoluteValue: roundScaledAmountToTheCentDecimal(result.resultingBusinessDocumentInstallmentAmountAndTaxes.amountIncludingTaxes.GetAmountDecimalScaledValue()).toNumber()
    }
  }
}

<script lang="ts">
    import { scale } from 'svelte/transition'

    /**
     * Set an id for the tooltip
     */
    export let tooltipId: string = 'ccs-' + Math.random().toString(36)

    /** Obtain a reference to the tooltip HTML element */
    export let refTooltip = null

    /**
     * Set to `true` to open the tooltip
     */
    export let show: boolean = false

    /**
     * Set a time to close the tooltip after
     */
    export let timeout: number = null

    /**
     * Set the tooltip delay for closing
     */
    export let delayHide: number = 100

    /**
     * Set the tooltip delay for opening
     */
    export let delayShow: number = 100

    /**
     * Set the width of the tooltip
     */
    export let width: string = 'auto'

    /**
     * Specify the direction of the tooltip: 'left', 'right', or 'center'.
     */
    export let direction: string = 'center'

    /**
     * Close the tooltip on keyboard events
     * @param e
     */
    function onKeydown(e) {
      if (e.key === 'Escape' || e.key === 'Tab') {
        e.stopPropagation()
        show = false
      } else if (e.key === ' ' || e.key === 'Enter') {
        e.stopPropagation()
        e.preventDefault()
        show = true
      }
    }

    function showTooltip() {
      if (show) return

      show = true

      if (!timeout) return

      timeout = setTimeout(() => {
        show = false
      }, timeout) as unknown as number
    }

    function hideTooltip() {
      if (!show) return

      show = false
      clearTimeout(timeout)
    }

    function debounce(func: () => void, wait: number, immediate: boolean = false) {
      let timeout
      
      return function () {
        let context = this,
          args = arguments
        let later = function () {
          timeout = null
          if (!immediate) func.apply(context, args)
        }
        let callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
      }
    }
</script>

<div class="relative inline-block">
    <div
            role="button"
            tabindex="0"
            on:mouseenter={debounce(showTooltip, delayShow)}
            on:mouseleave={debounce(hideTooltip, delayHide)}
            on:mouseenter
            on:mouseleave
            on:mouseover
            on:mouseout
            on:blur
            on:focus
            on:keydown="{onKeydown}"
            bind:this="{refTooltip}"
            id="{tooltipId}"
    >
        <slot name="activator"/>
    </div>

    {#if show}
        <div
                aria-describedby="{tooltipId}"
                role="button"
                tabindex="0"
                class="tooltip {direction} {width === 'auto' ? 'whitespace-nowrap' : '' } text-sm font-normal absolute mt-2 bg-black text-gray-50 rounded py-4 px-6"
                in:scale={{ duration: 150 }}
                out:scale={{ duration: 150, delay: 100 }}
                bind:this="{refTooltip}"
                on:click|stopPropagation
                on:mousedown|stopPropagation
                on:keydown|stopPropagation
                tabIndex="-1"
                style="width: {width};"
        >
            <slot/>
        </div>
    {/if}
</div>
<style lang="postcss">

    .tooltip {
        z-index: 9990;
        position: absolute;
    }

    .tooltip.center {
        left: 50%;
        transform: translateX(-50%);
    }

    .tooltip.left {
        right: 100%; /* aligns the tooltip's right edge with the activator's left edge */
        margin-right: 5px; /* some space between activator and tooltip */
    }

    .tooltip.right {
        left: 100%; /* aligns the tooltip's left edge with the activator's right edge */
        margin-left: 0; /* some space between activator and tooltip */
    }
</style>

/**
 * CAPITALIZE STRING
 * @param {string} str 
 * @returns {string}
 */
export const stringCapitalize = (str:string): string => {
  if (!str) return str
  
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * GET STRING WITHOUT ACCENT AND CASE INSENSITIVE
 * @param {string} str 
 * @returns {string}
 */
export const stringNormalize = (str:string, uppercase: boolean = true): string => {
  if (!str) return str
  if (typeof str !== 'string') return str
  
  const s = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  
  return uppercase ? s.toUpperCase() : s
}
import { JsonProperty } from '@paddls/ts-serializer'

export default class StorageBucketObject {
  @JsonProperty('contentBase64') contentBase64: string
  @JsonProperty('contentBytesSizeRead') contentBytesSizeRead: number
  @JsonProperty('creation') creation: number
  @JsonProperty('creationRFC3339') creationRFC3339: string
  @JsonProperty('fileName') fileName: string
  @JsonProperty('invoiceNumber') invoiceNumber: string
  @JsonProperty('name') name: string
  @JsonProperty('objectType') objectType: 'invoiceAttachment'
  @JsonProperty('size') size: number
  @JsonProperty('workspaceId') workspaceId: string
  @JsonProperty('fileType') fileType: string
}
import type { ICellRendererParams } from 'ag-grid-community'
import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
import { DocumentSourceKind } from '../../../../order-to-cash-lib/models/document-source-kind'

export default class InvoiceNumberRenderer {
  eGui
  invoiceNumberLabel
  sourceOfDataLabel
  originalParams
  /**
     * gets called once before the renderer is used
     * @param params
     */
  init(params: ICellRendererParams): void {
    // create the cell
    // build the button properly (https://blog.ag-grid.com/cell-renderers-in-ag-grid-every-different-flavour/#vanillajs)
    this.originalParams = params
    this.buildElement(params.data)
  }
  /**
     * Build the element
     * @param elementToGenerateParamsData
     */
  buildElement(elementToGenerateParamsData: any) {
    

    if (this.sourceOfDataLabel) {
      this.sourceOfDataLabel.remove()
    }
    if (this.invoiceNumberLabel) {
      this.invoiceNumberLabel.remove()
    }
    if (this.eGui) {
      while (this.eGui.firstChild) {
        this.eGui.removeChild(this.eGui.firstChild)
      }
    } else {
      this.eGui = document.createElement('div')
      this.eGui.classList.add('status-ui-element')
      this.eGui.style.width = '175px'
      this.eGui.style.position = 'relative'
    }

    // businessDocument number label
    this.invoiceNumberLabel = document.createElement('div')
    this.invoiceNumberLabel.classList.add('dundy-businessDocument-number-data')
    this.invoiceNumberLabel.insertAdjacentHTML('beforeend', elementToGenerateParamsData.invoiceNumber)

    this.eGui.appendChild(this.invoiceNumberLabel)

    // source of data label
    this.sourceOfDataLabel = document.createElement('div')
    this.sourceOfDataLabel.classList.add('source-of-data')
    if (!!elementToGenerateParamsData.documentSource && !!elementToGenerateParamsData.documentSource.sourceKind && (elementToGenerateParamsData.documentSource.sourceKind === DocumentSourceKind.FROMDUNDYDUNNINGMANUALLY)) {
      this.sourceOfDataLabel.classList.add('internal-source-of-data')
      this.sourceOfDataLabel.classList.add('bottom-line-editable')
      this.sourceOfDataLabel.classList.add('dundy')
      /* this.sourceOfDataLabel.innerHTML = t('invoices.sources.dundy'); // 'copy'; */
      this.sourceOfDataLabel.insertAdjacentHTML('beforeend', t('invoices.sources.dundy')) // 'copy';
    } else if (!!elementToGenerateParamsData.documentSource && !!elementToGenerateParamsData.documentSource.sourceKind && (elementToGenerateParamsData.documentSource.sourceKind === DocumentSourceKind.FROMVOXYINVOICING)) {
      this.sourceOfDataLabel.classList.add('external-source-of-data')
      this.sourceOfDataLabel.classList.add('bottom-line-non-editable')
      this.sourceOfDataLabel.classList.add('voxy')
      /* this.sourceOfDataLabel.innerHTML = t('invoices.sources.voxy'); // 'copy';*/
      this.sourceOfDataLabel.insertAdjacentHTML('beforeend', t('invoices.sources.voxy')) // 'copy';
    } else {
      this.sourceOfDataLabel.classList.add('external-source-of-data')
      this.sourceOfDataLabel.classList.add('bottom-line-editable')
      this.sourceOfDataLabel.classList.add('dundy')
      this.sourceOfDataLabel.classList.add('old')
      /*this.sourceOfDataLabel.innerHTML = 'copy D *';*/
      this.sourceOfDataLabel.insertAdjacentHTML('beforeend', 'copy D *')
    }
    this.eGui.appendChild(this.sourceOfDataLabel)
  }
  getGui() {
    return this.eGui
  }
  // gets called whenever the cell refreshes
  refresh(params): boolean {
    // return true to tell the grid we refreshed successfully
    
    
    this.buildElement(params.data)
    
    return true
  }
  // gets called when the cell is removed from the grid
  destroy() {
  }


}


<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte'
  import type { Keyword, Keywords } from './template.model'

  export let id:string | null | undefined = undefined
  export let multiline:boolean = true
  export let keywords:Keywords = []
  export let value:string

  const dispatch = createEventDispatcher()

  let board: HTMLDivElement
  let dummy: HTMLDivElement
  let oldValue: string = ''
  let caret: { node: Node | null | undefined, offset: number | undefined }

  const getCaretPosition = () => {
    const selection = window.getSelection()
    caret = { node: selection?.anchorNode, offset: selection?.anchorOffset }
  }

  const setCaretPosition = (node: Node, pos?: number) => {
    const selection = window.getSelection()
    const range = document.createRange()

    if (pos) {
      range.selectNode(node)
      range.setStart(node, pos)
      range.setEnd(node, pos)
    } else {
      range.selectNodeContents(node)
      range.setStart(node, range.endOffset)
    }

    if (!selection) return
    selection.removeAllRanges()
    selection.addRange(range)
  }

  const colorize = () => {
    if (oldValue === board.innerHTML) return // unwanted rendering
    oldValue = board.innerHTML

    // no keyword to test
    if (keywords.length === 0) {
      dummy.innerHTML = board.innerHTML
      
      return
    }

    const rx = new RegExp(keywords.map(k => k.key).join('|'), 'ig')

    const stylist = (key:string):string => {
      const val = keywords.find(k => k.key === key)?.value

      return '<span class="relative text-[var(--primary-color)]">' +
               key + 
               '<span class="absolute gb-red text-[0.7rem] whitespace-nowrap left-1/2 translate-x-[-50%] mt-[-0.9rem]">' +
               val + 
               '</span>' +
              '</span>'
    }

    dummy.innerHTML = board.innerHTML.replace(rx, (key) => stylist(key))
  }

  const onFocus = () => {
    dispatch('focus', arguments[0]) // arguments[0] = get current instance component
  }

  const onKeyDown = (e:any) => {
    if (e.key !== 'Enter') return

    // Only for Enter key
    e.preventDefault()
    if (!multiline) return

    const selection = window.getSelection()

    if (!selection) return
    const range = selection.getRangeAt(0)
    const br = document.createElement('br')
    const textNode = document.createTextNode('\u00a0') // ' '
    range.deleteContents()
    range.insertNode(br)
    range.collapse(false)
    range.insertNode(textNode)
    range.selectNodeContents(textNode)
    selection.removeAllRanges()
    selection.addRange(range)
  }

  const onKeyUp = (e:any) => {
    if (!multiline && e.keyCode === 13) e.preventDefault()
    getCaretPosition()
    colorize()
  }

  const onScroll = () => {
    dummy.scrollTop = board.scrollTop
    dummy.scrollLeft = board.scrollLeft
  }

  export const injectKeyword = (keyword:Keyword) => {
    const k = keyword.key

    // empty input
    if (!caret?.node || !caret?.offset) {
      board.innerText = k
    } else {
      // input with data
      if (caret.node.nodeValue) {
        const strStart = caret.node.nodeValue.substring(0, caret.offset)
        const strEnd = caret.node.nodeValue.substring(caret.offset + 1)

        caret.node.nodeValue = strStart + k + strEnd
        caret.offset += k.length
        setCaretPosition(caret.node, caret.offset)
      } else {
        caret.node.textContent = k
        caret.node.nodeValue = k
        setCaretPosition(caret.node)
      }
    }

    colorize()
  }

  $: {
    // only executed on loading
    if (value && board && dummy && value !== board?.innerHTML && !dummy.innerHTML) {
      board.innerHTML = value
      colorize()
    }
  }

  onMount(() => colorize())
</script>

<div class="editor relative h-full w-full overflow-hidden">
  <div class="board original bg-transparent text-transparent marker:whitespace-pre-wrap caret-black z-10 overflow-y-hidden overflow-x-scroll flex" contenteditable="true" role="textbox" spellcheck="false" tabindex=0
      {id}
      bind:this={board} 
      bind:innerHTML={value}
      on:focus={() => onFocus()}
      on:click={() => getCaretPosition()}
      on:keydown={(e)=> onKeyDown(e)}
      on:keyup={(e)=> onKeyUp(e)}
      on:scroll={()=> onScroll()}
  />
  <div class="dummy original bg-white z-0 overflow-y-hidden overflow-x-scroll flex" spellcheck="false" bind:this={dummy} />
</div>

<style lang="postcss">
  .editor {
    @apply z-0;
  }
  .original {
    @apply absolute rounded text-sm shadow-none border border-loblolly overflow-auto py-[0.5rem] px-[0.75rem] w-full h-full;

    &:focus {
      @apply ring-1 ring-red-400 outline-none border-[var(--primary-color)];
    }
  }
</style>
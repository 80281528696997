<script lang="ts">
  import { WorkspaceListStore } from '../crm-app/stores/workspace.store'
  import { TodosStore } from '../dundy-app/stores/todos.store'
  import { ProfileStore } from '../core-app/stores/profile.store'
  import { DunningInvoicesStore } from '../dundy-app/stores/dunning-invoices.store'
  import { ComputedDataInvoicesStore } from '../dundy-app/stores/computed-data-invoice.store'
  import { VoxyPreferencesStore } from '../voxy-app/stores/voxy-preference.store'
  import { TransactionsStore } from '../bank-app/stores/transactions.store'
  import { BusinessDocumentsAllDataPersistedStore } from '../voxy-app/stores/business-documents.store'

  const p = performance.now()

  $:workspaceListStore = {
    name: 'WorkspaceListStore',
    length: $WorkspaceListStore?.length,
    time: $WorkspaceListStore?.length >= 0 ? ((performance.now() - p) / 1000).toFixed(3) : '~'
  }

  $:todosStore = {
    name: 'TodosStore',
    length: $TodosStore?.length,
    time: $TodosStore?.length >= 0 ? ((performance.now() - p) / 1000).toFixed(3) : '~'
  }
  $:profileStore = {
    name: 'ProfileStore',
    length: $ProfileStore?.firstName,
    time: $ProfileStore?.firstName ? ((performance.now() - p) / 1000).toFixed(3) : '~'
  }
  $:dunningInvoicesStore = {
    name: 'DunningInvoicesStore',
    length: $DunningInvoicesStore?.length,
    time: $DunningInvoicesStore?.length >= 0 ? ((performance.now() - p) / 1000).toFixed(3) : '~'
  }
  $:computedDataInvoicesStore = {
    name: 'ComputedDataInvoicesStore',
    length: $ComputedDataInvoicesStore?.length,
    time: $ComputedDataInvoicesStore?.length >= 0 ? ((performance.now() - p) / 1000).toFixed(3) : '~'
  }
  $:voxyPreferencesStore = {
    name: 'VoxyPreferencesStore',
    length: $VoxyPreferencesStore?.workspaceId,
    time: $VoxyPreferencesStore?.workspaceId ? ((performance.now() - p) / 1000).toFixed(3) : '~'
  }
  $:transactionsStore = {
    name: 'TransactionsStore',
    length: $TransactionsStore?.id,
    time: $TransactionsStore?.id ? ((performance.now() - p) / 1000).toFixed(3) : '~'
  }
  $:businessDocumentsAllDataPersistedStore = {
    name: 'BusinessDocumentsAllDataPersistedStore',
    length: $BusinessDocumentsAllDataPersistedStore?.length,
    time: $BusinessDocumentsAllDataPersistedStore?.length >= 0 ? ((performance.now() - p) / 1000).toFixed(3) : '~'
  }

  $:storesSorted = [
    workspaceListStore,
    todosStore,
    profileStore,
    dunningInvoicesStore,
    computedDataInvoicesStore,
    voxyPreferencesStore,
    transactionsStore,
    businessDocumentsAllDataPersistedStore
  ].sort((a, b) => a.time < b.time ? -1 : a.time > b.time ? 1 : 0)


</script>

<table>
    <tr>
        <td>STORE</td>
        <td>LENGTH / DATA</td>
        <td>LOAD TIME</td>
    </tr>
    {#each storesSorted as store}
        <tr>
            <td>{store.name}</td>
            <td>{store.length}</td>
            <td>{store.time} s</td>
        </tr>
    {/each}
</table>


<style>
    tr, td {
        border: 1px solid #9ca3af;
    }

    td {
        padding: 1rem;
        white-space: nowrap;
    }

    tr:nth-child(1) td {
        color: white;
        background: #00ADA2;
        border: 1px solid #00716b;
    }
</style>

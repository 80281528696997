import type CompanyLegalStructure from '../../crm-app/models/company-legal-structure'

/**
 * Returns the list of legal structures for a French company.
 */
export function getLegalStructuresForEntitiesOperatingInFrance(): CompanyLegalStructure[] {
  return [
    { legalStructureKey: 'fr-SASU', legalStructureName: 'SASU - Société par action simplifiée unipersonnelle' },
    { legalStructureKey: 'fr-SARL', legalStructureName: 'SARL - Société à responsabilité limitée' },
    { legalStructureKey: 'fr-SAS', legalStructureName: 'SAS - Société par action simplifiée' },
    { legalStructureKey: 'fr-EI/EIRL', legalStructureName: 'EI/EIRL - Entrepreneur individuel (auto/micro-entrepreneur, freelance)' },
    { legalStructureKey: 'fr-EURL', legalStructureName: 'EURL - Entreprise unipersonnelle à responsabilité limitée' },
    { legalStructureKey: 'fr-LIBERAL', legalStructureName: 'Profession libérale' },
    { legalStructureKey: 'fr-ARTISAN', legalStructureName: 'Artisan' },
    { legalStructureKey: 'fr-SCI', legalStructureName: 'SCI - Société civile immobilière' },
    { legalStructureKey: 'fr-SC', legalStructureName: 'SC - Autre société civile' },
    { legalStructureKey: 'fr-SA', legalStructureName: 'SA - Société anonyme à conseil d\'administration' },
    { legalStructureKey: 'fr-SNC', legalStructureName: 'SNC - Société en nom collectif' },
    { legalStructureKey: 'fr-SCA', legalStructureName: 'SCA - Société en commandite' },
    { legalStructureKey: 'fr-SUCCURSALE', legalStructureName: 'Succursale de société italienne, espagnole ou allemande' },
    { legalStructureKey: 'fr-GIE', legalStructureName: 'GIE - Groupement d’intérêt économique' },
    { legalStructureKey: 'fr-FONCIERE', legalStructureName: 'Association foncière de remembrement' },
    { legalStructureKey: 'fr-ASSO-AVOCATS', legalStructureName: 'Association d\'avocats à responsabilité professionnelle individuelle' },
    { legalStructureKey: 'fr-ASSO-DECLAREE', legalStructureName: 'Association déclarée' },
    { legalStructureKey: 'fr-ASSO-DECLAREE-2', legalStructureName: 'Association déclarée (entreprises d\'insertion par l\'économique)' },
    { legalStructureKey: 'fr-ASSO-DECLAREE-3', legalStructureName: 'Association déclarée reconnue d\'utilité publique' },
    { legalStructureKey: 'fr-ASSO-INTER', legalStructureName: 'Association intermédiaire' },
    { legalStructureKey: 'fr-SYND-1', legalStructureName: 'Association syndicale autorisée' },
    { legalStructureKey: 'fr-SYND-2', legalStructureName: 'Association syndicale libre' }

  ]
}


// /**
//  * Returns true if the legal structure is a French company with a share capital.
//  * @param legalStructureKey
//  */
// export function doesLegalStructureRequireShareCapital(legalStructureKey: string | undefined): boolean {
//   return legalStructureKey === 'fr-SA'
//         || legalStructureKey === 'fr-SARL'
//         || legalStructureKey === 'fr-SAS'
//         || legalStructureKey === 'fr-SASU'
//         || legalStructureKey === 'fr-SNC'
//         || legalStructureKey === 'fr-EURL'
// }

/**
 * Returns true if the legal structure is a French company with a share capital.
 * @param {string} legalStructureKey 
 * @returns {boolean}
 */
export function doesLegalStructureRequireShareCapital(legalStructureKey: string): boolean {
  return legalStructureKey === '57' // SAS, SASU
        || legalStructureKey === '54' // toutes formes de SARL / EURL
        || legalStructureKey === '52' // toutes formes de SNC
}

// /**
//  * Returns true if the legal structure is a French non-profit or similar legal structure.
//  * @param legalStructureKey
//  */
// export function isLegalStructureAFrenchNonProfitOrSimilar(legalStructureKey: string): boolean {
//   return legalStructureKey === 'fr-ASSO-AVOCATS'
//         || legalStructureKey === 'fr-ASSO-DECLAREE'
//         || legalStructureKey === 'fr-ASSO-DECLAREE-2'
//         || legalStructureKey === 'fr-ASSO-DECLAREE-3'
//         || legalStructureKey === 'fr-ASSO-INTER'
//         || legalStructureKey === 'fr-SYND-1'
//         || legalStructureKey === 'fr-SYND-2'
// }

/**
 * Returns true if the legal structure is a French non-profit or similar legal structure.
 * @param {string} legalStructureKey 
 * @returns {boolean}
 */
export function isLegalStructureAFrenchNonProfitOrSimilar(legalStructureKey: string): boolean {
  return legalStructureKey === '92' // Association loi 1901 ou assimilé
        || legalStructureKey === '93' // Fondation
}


// /**
//  * Returns true if the legal structure is dispensed from obtaining a VAT (TVA) number and/or paying VAT.
//  * @param legalStructureKey
//  */
// export function isFrenchLegalStructureDispensedFromVAT(legalStructureKey: string): boolean {
//   return legalStructureKey === 'fr-EI/EIRL'
//         || legalStructureKey === 'fr-ASSO-AVOCATS'
//         || legalStructureKey === 'fr-ASSO-DECLAREE'
//         || legalStructureKey === 'fr-ASSO-DECLAREE-2'
//         || legalStructureKey === 'fr-ASSO-DECLAREE-3'
//         || legalStructureKey === 'fr-ASSO-INTER'
//         || legalStructureKey === 'fr-SYND-1'
//         || legalStructureKey === 'fr-SYND-2'
// }

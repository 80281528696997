import type { TaxRate } from './tax-rate'

export function getVATRates(): TaxRate[] {
  return [
    {
      taxRateId: 'f4c55549-314d-4525-8105-22e4123e0002',
      displayName: '0%',
      percentage: 0,
      description: '',
      jurisdiction: 'FR'
    },
    {
      taxRateId: '12766884-a876-4e4c-96a1-b69f9397f80d',
      displayName: '2,1%',
      percentage: 2.1,
      description: '',
      jurisdiction: 'FR'
    },
    {
      taxRateId: 'b2f016bc-e043-49b4-9ea8-c042e0d9d238',
      displayName: '5,5%',
      percentage: 5.5,
      description: '',
      jurisdiction: 'FR'
    },
    {
      taxRateId: 'f9280232-0903-4a50-9491-100f3d425c84',
      displayName: '10%',
      percentage: 10,
      description: '',
      jurisdiction: 'FR'
    },
    {
      taxRateId: '23892b3c-c938-4847-a6fd-f85062dbfb20',
      displayName: '20%',
      percentage: 20,
      description: '',
      jurisdiction: 'FR'
    }
  ]
}
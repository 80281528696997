<script lang="ts">
    import { onMount } from 'svelte'
    import { authZeroJsAccessToken, authZJsUserId } from '../../core-app/lib/auth0authentication/authStore'
    import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
    import { navigate } from 'svelte-routing'
    import axios, { type AxiosPromise } from 'axios'
    import { dundyAPIBaseRootURL } from '../../core-app/services/api.service'
    import { actionWhenNotSignedIn } from '../../core-app/services/auth.service'
    import { WorkspaceBankConfig } from '../models/workspace-bank-config'

    onMount(() => connectToTinkOnMount())

    const connectToTinkOnMount = () => {
      if ($WorkspaceStore.testEndToEnd) {
        /* console.log('OK arrived in tinkCodeGrant, going to select-account') */
        setTimeout(() => {
          navigate('/onboarding/select-account')
        }, 1000)
      } else {
        const searchParams = new URLSearchParams(window.location.search)
        const codeGrant = searchParams.get('code')
        const credentialsIdData = searchParams.get('credentialsId')
        /* console.log('codeGrant', codeGrant) */
        /* console.log('credentialsId', credentialsIdData) */
        connectToTinkAfterUserConsent(codeGrant, credentialsIdData)
      }
    }

    function connectToTinkAfterUserConsent(codeGrantData, credentialsIdData) {
      /* console.log('going to Tink... knock knock knock') */
      /* console.log($WorkspaceStore) */
      /* console.log($authZJsUserId, $authZeroJsAccessToken) */
      postTinkUserConsentPromise(codeGrantData, credentialsIdData)
        .then(postTinkUserBankAccountPromiseThen)
        .catch(postTinkUserConsentPromiseCatch)
    }

    function postTinkUserConsentPromise(codeGrantData, credentialsIdData): AxiosPromise {
      return <Promise<axios.AxiosResponse<any>>> axios({
        method: 'post',
        url: dundyAPIBaseRootURL + '/user/' + $authZJsUserId + '/workspace/' + $WorkspaceStore.workspaceId + '/exchange-grant-code-for-access-token',
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer ' + $authZeroJsAccessToken
        },
        data: {
          tinkUserConsent: {
            code: codeGrantData,
            credentialsId: credentialsIdData
          }
        }
      })
    }

    function postTinkUserBankAccountPromiseThen(response) {
      /* console.log('HTTP RESPONSE FROM API : OK POST postTinkUserConsentPromiseThen') */
      /* console.log(response.data) */
      /* console.log('...going to accounts list...') */
      const today = new Date().toISOString()
      const workspaceBankConfig: WorkspaceBankConfig = <WorkspaceBankConfig>{ // fill in how it is supposed to be in case everything works well
        bankConnectionCompletedAtLeastOnce: true,
        bankConnectionFirstCompletedRFC3339: ($WorkspaceStore.bankConfig.bankConnectionCompletedAtLeastOnce ? $WorkspaceStore.bankConfig.bankConnectionFirstCompletedRFC3339 : today),
        bankConnectionCurrentlyKnownActive: true,
        bankConnectionLastCompletedRFC3339: today
      }
      putTinkUserBankAccountPromise(workspaceBankConfig)
        .then(putTinkUserBankAccountPromiseThen(workspaceBankConfig))
        .catch(putTinkUserBankAccountPromiseCatch(workspaceBankConfig))
    }

    function postTinkUserConsentPromiseCatch(error) {
      if (error.response != null && error.response.data != null) {
        /* console.log('HTTP RESPONSE FROM API', JSON.stringify(error.response.data, null, '   ')) */
      }
      if (error.response != null && error.response.status === 401) {
        // LATER: refactor to add interceptor to Axios 401 response
        /* console.log('========== expired authentication') */
        actionWhenNotSignedIn()
      } else if (error.response != null && error.response.status === 409) {
        /* console.log('========== Internal API error returned') */
        navigate('/settings/bank-account')
      }
      $WorkspaceStore.bankConfig.bankConnectionCurrentlyKnownActive = false
    }

    function putTinkUserBankAccountPromise(workspaceBankConfig: WorkspaceBankConfig): AxiosPromise {
      return axios.put(dundyAPIBaseRootURL + '/owner/' + $authZJsUserId + '/workspace/' + $WorkspaceStore.workspaceId + '/bank-account',
        {
          bankConfig: workspaceBankConfig
        },
        {
          headers: {
            'Accept': '*/*',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + $authZeroJsAccessToken
          }
        },
      )
    }

    function putTinkUserBankAccountPromiseThen(workspaceBankConfig: WorkspaceBankConfig) {
      return (response) => {
        /* console.log(response) */
        /* console.log('...saving to workspaceStore and navigate to select-account ...') */
        $WorkspaceStore.bankConfig.bankConnectionCompletedAtLeastOnce = true
        $WorkspaceStore.bankConfig.bankConnectionFirstCompletedRFC3339 = workspaceBankConfig.bankConnectionFirstCompletedRFC3339
        $WorkspaceStore.bankConfig.bankConnectionCurrentlyKnownActive = true
        $WorkspaceStore.bankConfig.bankConnectionLastCompletedRFC3339 = workspaceBankConfig.bankConnectionLastCompletedRFC3339

        navigate('/onboarding/select-account')
      }
    }

    function putTinkUserBankAccountPromiseCatch(workspaceBankConfig: WorkspaceBankConfig) {
      return (error) => {
        if (error.response != null && error.response.data != null) {
          /* console.log('HTTP ERR RESPONSE FROM putTinkUserBankAccountPromiseCatch API', JSON.stringify(error.response.data, null, '   ')) */
        }
        if (error.response != null && error.response.status === 401) {
          // LATER: refactor to add interceptor to Axios 401 response
          /* console.log('========== expired authentication') */
          actionWhenNotSignedIn()
        } else if (error.response != null && error.response.status === 409) {
          /* console.log('FAILED TO SAVE BANK CONFIG', JSON.stringify(workspaceBankConfig, null, 4)) */
          $WorkspaceStore.bankConfig.bankConnectionCompletedAtLeastOnce = workspaceBankConfig.bankConnectionCompletedAtLeastOnce
          $WorkspaceStore.bankConfig.bankConnectionFirstCompletedRFC3339 = workspaceBankConfig.bankConnectionFirstCompletedRFC3339
          $WorkspaceStore.bankConfig.bankConnectionCurrentlyKnownActive = false
          $WorkspaceStore.bankConfig.bankConnectionLastCompletedRFC3339 = $WorkspaceStore.bankConfig.bankConnectionLastCompletedRFC3339
          /* console.log('========== Internal API error returned') */
          navigate('/settings/bank-account')
        }
        $WorkspaceStore.bankConfig.bankConnectionCurrentlyKnownActive = false
      }
    }

</script>

import type Invoice from '../../dundy-app/models/invoice'
import {
  getOutstandingInvoicesExcludingUntrackedAndDeleted,
  getOverdueInvoicesExcludingUntrackedAndDeleted
} from '$dundy/services/invoices.pure-functions'
import type ComputedDataInvoice from '../../dundy-app/models/computed-data-invoice'
import { deepClone } from '../util/object-deep-cloning'
import { InvoiceStatus } from '$dundy/enums/invoice-status'
import { DunningBusinessDocumentKind } from '$dundy/enums/dunning-business-document-kind'
import { calculateDaysDue } from '$src/order-to-cash-lib/helpers/calculate-days-due'
import { reduceInvoicesSumOfAmount } from '$dundy/services/list-reduce'
import { formatCurrencyWithOptions } from '$voxy/utils/number-formatting-utils'
import type { Customer } from '$crm/models/customer'

export class CollateralCalculusForDashboard {
  overdueInvoicesAmount: number
  outstandingInvoicesAmount: number
  overdueInvoicesAmountNormalized: string
  dueInvoicesAmountNormalized: string
  outstandingInvoicesAmountNormalized: string
  numberOfOverdueClients: number
  numberOfOutstandingInvoices: number
  numberOfOverdueInvoices: number
  numberOfNewClientsThisMonth: number
  overdueClients: Customer[]

  constructor() {
    this.overdueInvoicesAmount = 0
    this.outstandingInvoicesAmount = 0
    this.overdueInvoicesAmountNormalized = ''
    this.dueInvoicesAmountNormalized = ''
    this.outstandingInvoicesAmountNormalized = ''
    this.numberOfOverdueClients = 0
    this.numberOfOutstandingInvoices = 0
    this.numberOfOverdueInvoices = 0
    this.numberOfNewClientsThisMonth = 0
    this.overdueClients = []
  }
}


/**
 * Function to update the overdue invoices calculus
 * We need to make it reusable, testable and move it to a better place.
 * @param invoices
 * @param computedDataInvoices
 * @param customerStore
 * @param locales
 * @param invoicesCurrency
 */
export function updateCollateralCalculusOnInvoicesUpdate(
  invoices: Invoice[],
  computedDataInvoices: ComputedDataInvoice[],
  customerStore: Customer[],
  locales: string,
  invoicesCurrency: string): CollateralCalculusForDashboard {
  if (!invoices) {
    return new CollateralCalculusForDashboard()
  }

  const outstandingInvoices: Invoice[] = getOutstandingInvoicesExcludingUntrackedAndDeleted(invoices)
  const overdueInvoices: Invoice[] = getOverdueInvoicesExcludingUntrackedAndDeleted(invoices, computedDataInvoices)

  // Calculate overdueInvoicesAmount by generating new array from overlapping overdueInvoices with DunningInvoicesStore
  let invoiceDataStoreAndInvoiceStoreOverlappingMap: Map<any, any> = new Map()
  overdueInvoices?.forEach((item: Invoice) => invoiceDataStoreAndInvoiceStoreOverlappingMap.set(item.dataId, item))
  invoices?.forEach((item: Invoice) => invoiceDataStoreAndInvoiceStoreOverlappingMap.set(item.dataId, { ...deepClone(invoiceDataStoreAndInvoiceStoreOverlappingMap.get(item.dataId)), ...deepClone(item) }))

  const listForOverdueInvoicesAmount: Invoice[] = filterOutstandingInvoices(Array.from(invoiceDataStoreAndInvoiceStoreOverlappingMap.values()), computedDataInvoices)
  const overdueInvoicesAmount: number = reduceInvoicesSumOfAmount(listForOverdueInvoicesAmount, 'amountIncludingTaxes')
  const outstandingInvoicesAmount: number = reduceInvoicesSumOfAmount(outstandingInvoices, 'amountIncludingTaxes')
  const overdueInvoicesAmountNormalized: string = formatCurrencyWithOptions(overdueInvoicesAmount, locales, invoicesCurrency, true, 'symbol')
  const dueInvoicesAmountNormalized: string = formatCurrencyWithOptions((outstandingInvoicesAmount - overdueInvoicesAmount), locales, invoicesCurrency, true, 'symbol')
  const outstandingInvoicesAmountNormalized: string = formatCurrencyWithOptions(outstandingInvoicesAmount, locales, invoicesCurrency, true, 'symbol')
  const objectOfOverdueClients: Record<string, number> = calculateOverdueClients(overdueInvoices)
  const overdueClients: Customer[] = customerStore?.filter((customer: Customer) => !!objectOfOverdueClients[customer.company?.companyId])
  const numberOfOverdueClients: number = overdueClients?.length
  const numberOfOutstandingInvoices: number = outstandingInvoices?.length ?? 0
  const numberOfOverdueInvoices: number = overdueInvoices?.length ?? 0
  const numberOfNewClientsThisMonth: number = countCustomersCreatedThisMonth(customerStore, new Date().getMonth(), new Date().getFullYear())

  return <CollateralCalculusForDashboard>{
    overdueInvoicesAmount,
    outstandingInvoicesAmount,
    overdueInvoicesAmountNormalized,
    dueInvoicesAmountNormalized,
    outstandingInvoicesAmountNormalized,
    numberOfOverdueClients,
    numberOfOutstandingInvoices,
    numberOfOverdueInvoices,
    numberOfNewClientsThisMonth,
    overdueClients

  }
}

/**
 * Returns the number of customers created in the current month.
 */
export function countCustomersCreatedThisMonth(customers: Customer[], currentMonth:number, currentYear: number): number {
  const customersCreatedThisMonth: Customer[] = customers.filter((customer: Customer):boolean => {
    if (customer?.company && customer?.company?.createdDate) {
      const createdDate:Date = new Date(customer.company.createdDate.rfc3339)
      
      return createdDate.getMonth() === currentMonth && createdDate.getFullYear() === currentYear
    } else {
      return false
    }
  })

  return customersCreatedThisMonth.length
}

/**
 * Filter out invoices that are not outstanding
 * @param invoices
 * @param computedDataInvoicesStore
 */
export function filterOutstandingInvoices(invoices: Invoice[], computedDataInvoicesStore: ComputedDataInvoice[]): Invoice[] {
  return invoices.filter((invoice: Invoice) =>
    invoice.status === InvoiceStatus.OUTSTANDING
        && invoice.documentKind === DunningBusinessDocumentKind.INVOICE
        && calculateDaysDue(invoice.invoiceNumber, computedDataInvoicesStore) > 0
        && !invoice.deleted && invoice.isTracked)
}

/**
 * Calculate overdue clients
 * @param invoices
 */
export function calculateOverdueClients(invoices: Invoice[]): Record<string, number> {
  const overdueClients: Record<string, number> = {}
  invoices.forEach((invoice: Invoice): void => {
    overdueClients[invoice.toId] = 1
  })

  return overdueClients
}

/**
 * Calculate the DSO (Days Sales Outstanding) of a company.
 * @param accountsReceivable
 * @param totalCreditSales
 * @param numberOfDays
 */
export function calculateSimpleDSO(accountsReceivable: number, totalCreditSales: number, numberOfDays: number): number {
  if (totalCreditSales === 0) {
    throw new Error('Total Credit Sales cannot be zero')
  }

  const dso: number = (accountsReceivable / totalCreditSales) * numberOfDays
  
  return Math.round(dso)
}

/**
 * Calculate the Accounts Receivable Turnover Ratio of a company.
 * Financial metric that measures a company's effectiveness in collecting its receivables or money owed by clients
 * Net Credit Sales - The total amount of sales made on credit, minus any returns or allowances, during a given period
 * Average Accounts Receivable (AAR) - The average amount of money owed to the company by its customers during a given period.
 * @param netCreditSales
 * @param startingAccountsReceivable
 * @param endingAccountsReceivable
 */
export function calculateAccountsReceivableTurnoverRatio(netCreditSales: number, startingAccountsReceivable: number, endingAccountsReceivable: number): number {
  const averageAccountsReceivable: number =
        (startingAccountsReceivable + endingAccountsReceivable) / 2

  if (averageAccountsReceivable === 0) {
    return 0
  }

  const accountsReceivableTurnoverRatio: number =
        netCreditSales / averageAccountsReceivable
  
  return accountsReceivableTurnoverRatio
}

import { ExchangeDate } from '$core/models/exchange-date'
import { JsonProperty } from '@paddls/ts-serializer'
import { BusinessDocumentKind } from '../enums/business-document-kind'
import { BusinessDocumentLineItem } from './business-document'

export class NumberingSchemeSettings {
  @JsonProperty('applicableDocumentKind') applicableDocumentKind: string
  @JsonProperty('automaticNumberingMode') automaticNumberingMode: boolean
  @JsonProperty('documentNumberTemplate') documentNumberTemplate: string
  @JsonProperty('sequenceNumberOfDigits') sequenceNumberOfDigits: number
  @JsonProperty('inTimeZoneIANACode') inTimeZoneIANACode: string
  @JsonProperty('continueExistingDocumentNumberSequence') continueExistingDocumentNumberSequence: boolean
  @JsonProperty('initialDocumentSequenceNumber') initialDocumentSequenceNumber: number
  @JsonProperty('description') description: string
}

export class VoxyPreferences {
  @JsonProperty('numberingSchemesByBusinessDocument') numberingSchemesByBusinessDocument: Map<BusinessDocumentKind, NumberingScheme>
  @JsonProperty('workspaceId') workspaceId: string
  @JsonProperty('lastModifyingRequesterId') lastModifyingRequesterId: string
  @JsonProperty({ field: 'modifiedDate', type: () => ExchangeDate }) modifiedDate: ExchangeDate
  @JsonProperty({ field: 'formerNumberingSchemes', type: () => FormerNumberingSchemes }) formerNumberingSchemes: FormerNumberingSchemes[]
  @JsonProperty({ field: 'recentSequenceNumberAllocations', type: () => RecentSequenceNumberAllocations }) recentSequenceNumberAllocations: RecentSequenceNumberAllocations[]
  @JsonProperty({ field: 'favoriteBusinessDocumentLineItems', type: () => BusinessDocumentLineItem }) favoriteBusinessDocumentLineItems: BusinessDocumentLineItem[]
}

export class NumberingScheme {
  @JsonProperty('numberingSchemeId') numberingSchemeId: string
  @JsonProperty({ field: 'currentNumberingScheme', type: () => NumberingSchemeSettings }) currentNumberingScheme: NumberingSchemeSettings
  @JsonProperty({ field: 'lastDocumentSequenceNumbering', type: () => AllocatedSequenceNumbering }) lastDocumentSequenceNumbering: AllocatedSequenceNumbering
  @JsonProperty('lastDocumentNumberNumberingSchemeID') lastDocumentNumberNumberingSchemeID: string
  @JsonProperty('noAllocationSinceNumberingSchemeChanged') noAllocationSinceNumberingSchemeChanged: boolean
  @JsonProperty('schemeSetupByRequesterId') schemeSetupByRequesterId: string
  @JsonProperty({ field: 'schemeSetupDateAndPeriod', type: () => AllocationDateAndPeriod }) schemeSetupDateAndPeriod: AllocationDateAndPeriod
  @JsonProperty('isFirstSchemeSetupCompleted') isFirstSchemeSetupCompleted: boolean
  @JsonProperty({ field: 'firstSchemeSetupDate', type: () => ExchangeDate }) firstSchemeSetupDate: ExchangeDate
}

export class FormerNumberingSchemes {
  @JsonProperty({ field: 'archivedDate', type: () => ExchangeDate }) archivedDate: ExchangeDate
  @JsonProperty({ field: 'archivedNumberingScheme', type: () => ArchivedNumberingScheme }) archivedNumberingScheme: ArchivedNumberingScheme
  @JsonProperty('archivedTimeUnixNano') archivedTimeUnixNano: string
}

export class ArchivedNumberingScheme {
  @JsonProperty({ field: 'currentNumberingScheme', type: () => NumberingSchemeSettings }) currentNumberingScheme: NumberingSchemeSettings
  @JsonProperty({ field: 'firstSchemeSetupDate', type: () => ExchangeDate }) firstSchemeSetupDate: ExchangeDate
  @JsonProperty('isFirstSchemeSetupCompleted') isFirstSchemeSetupCompleted: boolean
  @JsonProperty('lastDocumentNumberNumberingSchemeID') lastDocumentNumberNumberingSchemeID: string
  @JsonProperty({ field: 'lastDocumentSequenceNumbering', type: () => AllocatedSequenceNumbering }) lastDocumentSequenceNumbering: AllocatedSequenceNumbering
  @JsonProperty('noAllocationSinceNumberingSchemeChanged') noAllocationSinceNumberingSchemeChanged: boolean
  @JsonProperty('numberingSchemeId') numberingSchemeId: string
  @JsonProperty('schemeSetupByRequesterId') schemeSetupByRequesterId: string
  @JsonProperty({ field: 'schemeSetupDateAndPeriod', type: () => AllocationDateAndPeriod }) schemeSetupDateAndPeriod: AllocationDateAndPeriod
}

export class RecentSequenceNumberAllocations {
  @JsonProperty('allocatedBusinessDocumentId') allocatedBusinessDocumentId: string
  @JsonProperty('allocatedBusinessDocumentKind') allocatedBusinessDocumentKind: string
  @JsonProperty({ field: 'allocatedSequenceNumbering', type: () => AllocatedSequenceNumbering }) allocatedSequenceNumbering: AllocatedSequenceNumbering
  @JsonProperty({ field: 'allocationDateAndPeriod', type: () => AllocationDateAndPeriod }) allocationDateAndPeriod: AllocationDateAndPeriod
  @JsonProperty('allocationId') allocationId: string
  @JsonProperty({ field: 'currentNumberingScheme', type: () => NumberingSchemeSettings }) currentNumberingScheme: NumberingSchemeSettings
  @JsonProperty({ field: 'firstSchemeSetupDate', type: () => ExchangeDate }) firstSchemeSetupDate: ExchangeDate
  @JsonProperty('isFirstSchemeSetupCompleted') isFirstSchemeSetupCompleted: boolean
  @JsonProperty('lastDocumentNumberNumberingSchemeID') lastDocumentNumberNumberingSchemeID: string
  @JsonProperty({ field: 'lastDocumentSequenceNumbering', type: () => AllocatedSequenceNumbering }) lastDocumentSequenceNumbering: AllocatedSequenceNumbering
  @JsonProperty('noAllocationSinceNumberingSchemeChanged') noAllocationSinceNumberingSchemeChanged: boolean
  @JsonProperty('numberingSchemeId') numberingSchemeId: string
  @JsonProperty('schemeSetupByRequesterId') schemeSetupByRequesterId: string
  @JsonProperty({ field: 'schemeSetupDateAndPeriod', type: () => AllocationDateAndPeriod }) schemeSetupDateAndPeriod: AllocationDateAndPeriod
  @JsonProperty('isAllocationAppliedAndValidated') isAllocationAppliedAndValidated: boolean
  @JsonProperty('rank') rank: number
  @JsonProperty('requesterId') requesterId: string
  @JsonProperty('workspaceId') workspaceId: string

}

export class AllocatedSequenceNumbering {
  @JsonProperty('case') case: string
  @JsonProperty('number') number: number
}

export class AllocationDateAndPeriod {
  @JsonProperty({ field: 'date', type: () => ExchangeDate }) date: ExchangeDate
  @JsonProperty('periods') periods: string[]
  @JsonProperty('withinTimeZoneIANACode') withinTimeZoneIANACode: string
}
<script lang="ts">
    import { createEventDispatcher } from 'svelte'
    import UpdatedFileUpload from '../../models/updated-file-upload'
    import ConditionalFileInputWrapper
      from '../../../core-app/lib/ui-kit/fileInputComponents/ConditionalFileInputWrapper.svelte'
    import { currentLocales } from '../../../core-app/stores/i18n.store.js'
    import { feedbackService } from '../../../core-app/services/feedback.service'
    import { Feedback } from '../../../core-app/models/feedback'
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import FileInputWithResizingToSquare from '../../../core-app/lib/ui-kit/FileInputWithResizingToSquare.svelte'

    /**
     *  Usage like:
     *  <CompanyLogo
     *                 existingFileURL={!!localCompany.emailLogoURL ? localCompany.emailLogoURL :"https://static.thenounproject.com/png/1003548-200.png"}
     *                 existingFileName={!!localCompany.emailLogoURL ? "company logo" :"choose a company logo"}
     *                 existingFileSizeBytes=0
     *                 existingFileLastModifiedUnixMilliseconds=0
     *                 addBoundingDivs={true}
     *                 triggerNewFileSelectModal={false}
     *                 triggerProgrammaticallyResetSelectedFile={triggerProgrammaticallyResetSelectedFile}
     *                 on:chosenNewFileUpload={newFileUpload}
     *                 on:chosenNewFileUploadAfterProcessing={newPostProcessedFileUpload}
     *                 on:discardedFileUpload={discardedFileUpload}
     *         />
     *  main return from component:
     *     - on:chosenNewFileUpload=<UpdatedFileUpload>{...}
     *     - on:chosenNewFileUploadAfterProcessing=<UpdatedFileUpload>{...}
     */

    /** Export let variables */
    export let existingFileURL: string = 'https://thumbs.dreamstime.com/b/amazon-logo-editorial-vector-illustration-market-136495269.jpg'
    export let existingFileName: string = 'amazon-logo-editorial-vector-illustration-market-136495269.jpg'
    export let existingFileSizeBytes: number = 14294
    export let existingFileLastModifiedUnixMilliseconds: number = 1672502195 * 1000
    export let addBoundingDivs: boolean = false
    export let triggerNewFileSelectModal: boolean = false // defaults values, overridden by passed values
    export let triggerProgrammaticallyResetSelectedFile: boolean = false // defaults values, overridden by passed values

    /** Specify a name for the cypress selector */
    export let dataCy:string = ''

    /** Let variables */
    let maxFileSize = 1024 * 1024 * 8
    let allowedFileTypes: string[] = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
    const dispatch = createEventDispatcher()

    /**
     * step 1: choose an image
     */
    function chosenNewFileUpload(newFileUploadData: CustomEvent<UpdatedFileUpload>) {
      // bubble up the event
      dispatch('chosenNewFileUpload', newFileUploadData.detail)
    }

    /**
     * step 2: crop the image
     */
    function chosenNewFileUploadAfterProcessing(newFileUploadData: CustomEvent<UpdatedFileUpload>) {
      // bubble up the event
      /* console.log('dispatching event chosenNewFileUploadAfterProcessing from CompanyLogo') */
      dispatch('chosenNewFileUploadAfterProcessing', newFileUploadData.detail)
    }

    /**
     * Discard the image if user chooses to
     */
    function discardedFileUpload() {
      // bubble up the event
      dispatch('discardedFileUpload', <UpdatedFileUpload>{
        fileBase64: '',
        fileMIMEContentType: '',
        fileName: '',
        fileSizeBytes: 0,
        fileLastModifiedUnixMilliseconds: 0
      })
    }

    /**
     * Convert the allowed file extensions to accepted extensions
     * @param allAllowedFileTypes
     */
    function convertAllowedFileExtensionsToAcceptedExtensions(allAllowedFileTypes: string[]): string {
      // allowedFileExtensions is like ["jpg", "jpeg", "png", "gif", "bmp", "tiff"]
      // acceptedExtensions is like ".jpg, .jpeg, .png, .gif, .bmp, .tiff"
      const converted = allAllowedFileTypes.map((s) => '.' + s).join(', ')
      /* console.log('*!!! AcceptedExtensions', converted) */

      return converted
    }

    /**
     * Get the file extension from the MIME content type
     * @param fileMIMEContentType
     */
    function getFileExtension(fileMIMEContentType: string) {
      const filenameParts = fileMIMEContentType.split('/')
      const fileExtension = filenameParts[filenameParts.length - 1].toLowerCase()
      /* console.log('*!!! fileExtension', fileExtension) */

      return fileExtension
    }

    /**
     * Validate the file extension
     * @param extensionToValidate
     * @param allowedFileExtensions
     */
    function validateFileExtension(extensionToValidate: string, allowedFileExtensions: string[]): boolean {
      /* console.log('*!!! allowedFileExtensions', allowedFileExtensions) */
      /* console.log('*!!! extensionToValidate', extensionToValidate) */
      if (!allowedFileExtensions) {
        /* console.log('*!!! no allowedFileExtensions', allowedFileExtensions) */

        return false
      }
      if (!extensionToValidate) {
        /* console.log('*!!! no extensionToValidate', extensionToValidate) */

        return false
      }
      for (let anAllowedFileExtension of allowedFileExtensions) {
        if (extensionToValidate.toLowerCase() === anAllowedFileExtension.toLowerCase()) {
          return true
        }
      }

      return false
    }

    /**
     * Format bytes as human-readable text.
     * Ref: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
     * @param bytes
     * @param decimals
     */
    function formatBytes(bytes: number, decimals: number = 2) {
      if (!+bytes) {
        return '0 Bytes'
      }
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    /**
     * Validate the file before uploading
     * @param fileMIMEContentType
     * @param fileName
     * @param fileSizeBytes
     * @param fileLastModifiedUnixMilliseconds
     */
    function companyLogoFileValidator(fileMIMEContentType: string, fileName: string, fileSizeBytes: number, fileLastModifiedUnixMilliseconds: number): boolean {
      /* console.log('*!!! my companyLogoImageUploadValidator validator') */
      /* console.log('*!!! my companyLogoImageUploadValidator validator fileMIMEContentType', fileMIMEContentType) */
      /* console.log('*!!! my companyLogoImageUploadValidator validator fileName', fileName) */
      /* console.log('*!!! my companyLogoImageUploadValidator validator fileSizeBytes', fileSizeBytes) */
      /* console.log('*!!! my companyLogoImageUploadValidator validator fileLastModifiedUnixMilliseconds', fileLastModifiedUnixMilliseconds) */
      if (fileSizeBytes > maxFileSize) {
        feedbackService.displayFeedback(<Feedback>{
          feedbackMessage: t('companyEdit.feedbackSizeLimit', {
            count: formatBytes(maxFileSize),
            yourfile: formatBytes(fileSizeBytes)
          }),
          feedbackLevel: 'Error'
        })

        return false
      }
      if (!validateFileExtension(getFileExtension(fileMIMEContentType), allowedFileTypes)) {
        feedbackService.displayFeedback(<Feedback>{
          feedbackMessage: t('companyEdit.feedbackAllowedTypes', { count: allowedFileTypes.join(', ') }),
          feedbackLevel: 'Error'
        })

        return false
      }

      return true
    }
</script>


<div data-ut-component="company-logo">
    <ConditionalFileInputWrapper condition={addBoundingDivs}>


        <FileInputWithResizingToSquare
                chooseANewFileMessageTEntry="companyEdit.selectNewLogo"
                fileLimitationsMessageTEntry="companyEdit.uploadLimitations"
                fileUploadHelpMessageTEntry="companyEdit.uploadHelp"
                cancelChangesMessageTEntry="companyEdit.resetLogo"
                timeZoneIANACode={Intl.DateTimeFormat().resolvedOptions().timeZone}
                languageAndCountryCode={$currentLocales}
                existingFileURL={existingFileURL}
                existingFileName={existingFileName}
                existingFileSizeBytes={existingFileSizeBytes}
                existingFileLastModifiedUnixMilliseconds={existingFileLastModifiedUnixMilliseconds}
                maxFileSizeBytes={maxFileSize}
                acceptedExtensions={convertAllowedFileExtensionsToAcceptedExtensions(allowedFileTypes)}
                bind:triggerNewFileSelectModal={triggerNewFileSelectModal}
                bind:triggerProgrammaticallyResetSelectedFile={triggerProgrammaticallyResetSelectedFile}
                addBoundingDivs={false}
                debugInfoVisible={false}
                showImage={true}
                showNewUploadButton={true}
                showCancelChangesButton={true}
                showUploadHelpMessage={false}
                showAdditionalUploadHelpMessage={true}
                showAdditionalUploadHelpAlwaysMessage={false}
                showFileMetadata={false}
                specificFileValidator={companyLogoFileValidator}
                dataCy="{dataCy}"
                on:chosenNewFileUpload={chosenNewFileUpload}
                on:chosenNewFileUploadAfterProcessing={chosenNewFileUploadAfterProcessing}
                on:discardedFileUpload={discardedFileUpload}
        />


    </ConditionalFileInputWrapper>
</div>


<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper.js'
    import { BusinessDocument } from '../../models/business-document'
    import { Link } from 'svelte-routing'

    export let businessDocument: BusinessDocument

    function getProps({ href, isPartiallyCurrent, isCurrent }) {
      const isActive = href === '/receivables/view/' + `${businessDocument.businessDocumentId}/details` ? isCurrent : isPartiallyCurrent || isCurrent

      if (isActive) {
        return { class: 'tab-link active' }
      }

      return { class: 'tab-link' }
    }

</script>
<div data-ut-component="view-business-document-navigation">
    <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select id="tabs" name="tabs"
                class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
            <option>{t('invoices.viewInvoice.menu.itemDetails')}</option>
            <!--<option>Paiement</option>-->
        </select>
    </div>

    <div class="hidden sm:block">
        <div class="border-b border-gray-200">
            <nav class="-mb-px px-8 flex space-x-8" aria-label="Tabs">
                <Link to="/receivables/view/{businessDocument.businessDocumentId}/details"
                      {getProps}>
                    {t('invoices.viewInvoice.menu.itemDetails')}
                </Link>
                <Link to="/receivables/view/{businessDocument.businessDocumentId}/activity"
                      {getProps}>
                    {t('invoices.viewInvoice.menu.itemActivity')}
                </Link>
                {#if businessDocument.relatedBusinessDocuments?.length > 0}
                    <Link to="/receivables/view/{businessDocument.businessDocumentId}/documents"
                          {getProps}>
                        {t('invoices.viewInvoice.menu.itemRelated', { count: businessDocument.relatedBusinessDocuments.length })}
                        <span class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800 ml-2">{businessDocument.relatedBusinessDocuments.length}</span>
                    </Link>
                {/if}
            </nav>
        </div>
    </div>

    <!--<div class="hidden sm:block">
        <div class="border-b border-gray-200">
            <nav class="-mb-px px-8 flex space-x-8" aria-label="Tabs">
                <a class="border-black text-black whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                   aria-current="page">{t('invoices.viewInvoice.menu.itemDetails')}</a>

                &lt;!&ndash;<a href="#" class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">Paiement</a>&ndash;&gt;
                &lt;!&ndash;<a href="#" class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">Team Members</a> &ndash;&gt;
                &lt;!&ndash;<a href="#" class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">Billing</a>&ndash;&gt;
            </nav>
        </div>
    </div>-->
</div>
import type Invoice from '../models/invoice'
import { WorkspaceStore } from '$crm/stores/workspace.store'
import { get } from 'svelte/store'
import type { Customer } from '$crm/models/customer'
import { t } from '$core/lib/i18n/i18nextWrapper'
import { ComputedDataInvoicesStore } from '../stores/computed-data-invoice.store'
import { InvoiceStatus } from '../enums/invoice-status'
import { v4 as uuidv4 } from 'uuid'
import { FeedbackStore } from '$core/stores/feedback.store'
import { FeedbackEntity } from '$core/enums/feedback-enums'
import type { FeedbackDetails } from '$core/models/feedback'
import { DocumentSourceKind } from '$src/order-to-cash-lib/models/document-source-kind'
import { authZJsUserId } from '$core/lib/auth0authentication/authStore'
import { ExchangeDate } from '$core/models/exchange-date'
import type { InvoiceDocumentSource } from '../models/invoice-document-source'
import { deepClone } from '$core/util/object-deep-cloning'
import { DunningBusinessDocumentKind } from '../enums/dunning-business-document-kind'
import type AgGridExcerptInvoice from '../models/ag-grid-excerpt-invoice'
import type ComputedDataInvoice from '../models/computed-data-invoice'
import { getDefaultCurrencyAccordingToCountry } from '$core/services/currency-list'
import { getUserCountryCode } from '$core/services/countries-pure-functions'
import { maxDaysSinceDueDateToHigherEscalation } from '../services/dundy-config'
import type { Workspace } from '$crm/models/workspace'
import type { CellClassParams, ICellRendererParams, ValueSetterParams } from 'ag-grid-community'
import { escapeRegExp } from '$core/util/regexp-utils'
import type StorageBucketObject from '../../file-mgt-domain/models/storage-bucket-object'
import { pdfAttachmentDownload } from '$src/file-mgt-domain/services/pdf-attachment-download.api'

export class DunningInvoicesHelper {

  /**
     * Returns true if the invoice is a new invoice, false otherwise.
     * @param invoice
     */
  static isNewInvoice(invoice: Invoice): boolean {
    const replacer = (k: string, v: any) => k === 'dataId' || k === 'dateDue' || k === 'dateIssued' || k === 'modified' || k === 'modifiedRFC3339' ? undefined : v

    return JSON.stringify(invoice, replacer) === JSON.stringify(this.newInvoice(), replacer)
  }
  /**
     * Creates a new Dundy Invoice Excerpt.
     * // Todo : maybe add supplierContact to the model
     */
  static newInvoice(): Invoice {
    const newDataId: string = uuidv4()
    const tomorrow: Date = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    const workspaceStore: Workspace = get(WorkspaceStore)
    const documentURL: string = process.env.API_BASE_URL +
            '/api/workspaces/' + workspaceStore.workspaceId + '/single-businessDocument/' + newDataId
    let fromSupplierWithoutEmbeddedEmailLogo = deepClone(workspaceStore.company)
    fromSupplierWithoutEmbeddedEmailLogo.emailLogoBase64 = ''
    fromSupplierWithoutEmbeddedEmailLogo.emailLogoContentType = 'nulled'

    return <Invoice>{
      amountExcludingTaxes: 0,
      amountIncludingTaxes: 0,
      documentKind: DunningBusinessDocumentKind.INVOICE,
      dateDue: tomorrow.toISOString(),
      currency: getDefaultCurrencyAccordingToCountry(getUserCountryCode()),
      dataId: newDataId,
      dateIssued: new Date().toISOString(),
      fromName: workspaceStore.company.formalName,
      fromId: workspaceStore.company.companyId,
      fromSupplier: fromSupplierWithoutEmbeddedEmailLogo,
      invoiceNumber: '',
      invoiceDescription: t('addInvoice.invoiceDescription'),
      modifiedRFC3339: new Date().toISOString(),
      modified: Math.round(new Date().getTime() / 1000.0),
      taxesAmount: 0,
      status: InvoiceStatus.OUTSTANDING,
      documentSource: <InvoiceDocumentSource>{
        sourceLabel: 'Dundy dunning businessDocument by userId ' + get(authZJsUserId) + ' created on ' + (new Date()).toISOString() + ' for workspaceId ' + workspaceStore.workspaceId,
        sourceKind: DocumentSourceKind.FROMDUNDYDUNNINGMANUALLY,
        sourceAccountReference: workspaceStore.workspaceId,
        sourceLogoURL: 'https://storage.googleapis.com/assets-dundy/ut-platform/integration-logo/dundy_logo_from_web_app_512x512.png',
        resourceURL: documentURL,
        attachmentURLs: [],
        lastSourceUpdated: ExchangeDate.newDate(new Date())
      },
      toName: '',
      toId: '',
      deleted: false,
      completed: false,
      isTracked: false
    }
  }
  /**
     * Duplicates a Dundy Invoice Excerpt.
     * @param invoice
     */
  static duplicateInvoice(invoice: Invoice): Invoice {
    const newDataId: string = uuidv4()
    const documentURL: string = process.env.API_BASE_URL +
            '/api/workspaces/' + get(WorkspaceStore).workspaceId + '/single-businessDocument/' + newDataId
    let fromSupplierWithoutEmbeddedEmailLogo = deepClone(get(WorkspaceStore).company)
    fromSupplierWithoutEmbeddedEmailLogo.emailLogoBase64 = ''
    fromSupplierWithoutEmbeddedEmailLogo.emailLogoContentType = 'nulled'

    return <Invoice>{
      amountExcludingTaxes: invoice.amountExcludingTaxes,
      amountIncludingTaxes: invoice.amountIncludingTaxes,
      dateDue: invoice.dateDue,
      currency: invoice.currency,
      dataId: newDataId,
      dateIssued: invoice.dateIssued,
      fromName: invoice.fromName,
      fromId: invoice.fromId,
      fromSupplier: fromSupplierWithoutEmbeddedEmailLogo,
      invoiceNumber: invoice.invoiceNumber + '-' + t('invoices.duplicateInvoice.duplicateInvoiceSuffix'),
      invoiceDescription: invoice.invoiceDescription,
      modifiedRFC3339: new Date().toISOString(),
      modified: Math.round(new Date().getTime() / 1000.0),
      taxesAmount: invoice.taxesAmount,
      status: InvoiceStatus.OUTSTANDING,
      documentSource: <InvoiceDocumentSource>{
        sourceLabel: 'Dundy dunning businessDocument by userId ' + get(authZJsUserId) + ' created on ' + (new Date()).toISOString() + ' for workspaceId ' + get(WorkspaceStore).workspaceId,
        sourceKind: DocumentSourceKind.FROMDUNDYDUNNINGMANUALLY,
        sourceAccountReference: get(WorkspaceStore).workspaceId,
        sourceLogoURL: 'https://storage.googleapis.com/assets-dundy/ut-platform/integration-logo/dundy_logo_from_web_app_512x512.png',
        resourceURL: documentURL,
        attachmentURLs: [],
        lastSourceUpdated: ExchangeDate.newDate(new Date())
      },
      toName: invoice.toName,
      toId: invoice.toId,
      deleted: false,
      completed: false,
      isTracked: false
    }
  }
  /**
     * Search function used by Ag-grid to filter invoices
     * Returns true if the invoice contains the text in its toName, toId, invoiceNumber, amountIncludingTaxes, currency, dateDue, dateIssued
     * @param invoice
     * @param text
     */
  static isInvoiceContainingText(invoice: Invoice, text: string): boolean {
    const regexp: RegExp = new RegExp(escapeRegExp(text), 'i')
    const workspace: Workspace = get(WorkspaceStore)
    const finalCustomer: Customer | undefined = workspace.customers.find((c: Customer): boolean => c.company.companyId === invoice.toId)
    let lookIntoDocument: AgGridExcerptInvoice = <AgGridExcerptInvoice>{
      toId: invoice.toId,
      label: '',
      formalName: '',
      toName: invoice.toName,
      invoiceNumber: invoice.invoiceNumber,
      amountIncludingTaxes: invoice.amountIncludingTaxes,
      currency: invoice.currency,
      dateDue: new Intl.DateTimeFormat(t('locales'), { year: 'numeric', month: 'long', day: 'numeric' })
        .format(new Date(invoice.dateDue)),
      dateIssued: new Intl.DateTimeFormat(t('locales'), { year: 'numeric', month: 'long', day: 'numeric' })
        .format(new Date(invoice.dateIssued)),
      actions: ''

    }
    if (finalCustomer) {
      lookIntoDocument = {
        ...lookIntoDocument,
        label: finalCustomer.company.formalName,
        formalName: finalCustomer.company.formalName
      }
    }
    /* console.log(JSON.stringify(lookIntoDocument, null, 4)) */
    delete lookIntoDocument.toId

    for (const prop of Object.keys(lookIntoDocument)) {
      if (typeof lookIntoDocument[prop] === 'string' && lookIntoDocument[prop].search(regexp) !== -1) {
        return true
      } else if (typeof lookIntoDocument[prop] === 'number') {
        const numberAsString: string = new Intl.NumberFormat(t('locales')).format(lookIntoDocument[prop])
        if (numberAsString.search(regexp) !== -1) {
          return true
        }
      }
    }

    return false
  }
  /**
     * Returns the HTML and classes to be used for the status label of an invoice
     * @param status
     * @param invoiceDataId
     */
  static getStatusLabelHTMLAndClasses(status: string, invoiceDataId: string): {
    newElementClass: string[],
    newInnerHtml: string
  } {

    const computedDataInvoices: ComputedDataInvoice[] = get(ComputedDataInvoicesStore)
    let newElementClass: string[]
    let newInnerHtml: string

    switch (status) {
      case InvoiceStatus.PAID:
        newElementClass = ['success-outlined', 'paid-status']
        newInnerHtml = t('invoiceStatus.paid')
        break
      case InvoiceStatus.OUTSTANDING:
        const invoiceId: string = invoiceDataId
        const computedData: ComputedDataInvoice | undefined = computedDataInvoices.find(
          (c: ComputedDataInvoice): boolean => c.invoiceDataId === invoiceId,
        )
        if (!computedData) {
          /* console.log('no computedData') */
          newElementClass = ['success', 'to-be-defined', 'no-computed-data']
          newInnerHtml = t('invoiceStatus.tbd')
          break
        }
        if (computedData.daysOverdueNormalized <= 0) {
          newElementClass = ['success', 'on-time-status']
          newInnerHtml = t('invoiceStatus.onTime')
        } else {
          newElementClass = computedData.daysOverdueNormalized > maxDaysSinceDueDateToHigherEscalation ? ['error', 'late-payment-status', 'high-escalation'] : ['warning', 'just-late-payment-status', 'low-escalation']
          newInnerHtml = computedData.daysOverdueNormalized + t('invoiceStatus.daysLate', { count: computedData.daysOverdueNormalized })
        }
        break
      default:
        /* console.error('other case tbd error', status) */
        newElementClass = ['success', 'to-be-defined', 'other-tbd']
        newInnerHtml = t('invoiceStatus.tbd')
    }

    return { newInnerHtml, newElementClass }
  }
  /**
     * Renderer for the status column of the dunning / invoices table in AG GRID
     * @param params
     */
  static updateRenderedValue(params: ICellRendererParams | CellClassParams): {
    newElementClass: string[],
    newInnerHtml: string,
    completeButton: boolean,
    trackButton: boolean,
    isInvalid: boolean
  } {
    /** If document is finalized and the document is a credit note
         * Show the credit note status
         * NB we don't care that the document is tracked or not : it's not supposed to be dunned
         * */
    if (params.data.completed && params.data.documentKind === DunningBusinessDocumentKind.CREDITNOTE) {
      return {
        newElementClass: ['action-primary', 'credit-note'], //  credit note
        newInnerHtml: t('invoiceStatus.creditNote'),
        completeButton: false,
        trackButton: false,
        isInvalid: false
      }
    }
    /** If document is not finalized and the document is a credit note
         *  Generate a button to finalize the credit note
         *  NB we don't care that the document is tracked or not : it's not supposed to be dunned
         * */
    if (!params.data.completed && params.data.documentKind === DunningBusinessDocumentKind.CREDITNOTE) {
      return {
        newElementClass: ['action-create-credit-note', 'declare-businessDocument-completed'], //  credit note
        newInnerHtml: t('invoiceStatus.dataComplete') + ' ' + t('invoiceStatus.creditNote'),
        completeButton: true,
        trackButton: false,
        isInvalid: false
      }
    }
    /** If Document is not finalized / not tracked and if document is an invoice
         *  if information is missing, show a draft status
         *  if information is complete, show a button to finalize the document
         * */
    if (!params.data.completed && !params.data.isTracked && (params.data.documentKind === DunningBusinessDocumentKind.INVOICE)) {
      let isIncomplete: boolean = false
      let feedbackDetails: FeedbackDetails[] = get(FeedbackStore).get(FeedbackEntity.INVOICES)?.feedbackDetails ?? []
      let feedbackForInvoiceFound: FeedbackDetails | undefined
      if (feedbackDetails && feedbackDetails.length) {
        feedbackForInvoiceFound = feedbackDetails.find((inv: FeedbackDetails): boolean => inv.itemId === params.data.dataId)
        if (feedbackForInvoiceFound) {
          for (const propertyDetail of feedbackForInvoiceFound.detailsOnProperties.values()) {
            if (!propertyDetail.canTheItemBeFinalized) {
              isIncomplete = true
            }
          }
        }
      }

      return {
        newElementClass: ['action-track', 'draft-status'], // status draft
        newInnerHtml: t('invoiceStatus.draft'),
        completeButton: false,
        trackButton: false,
        isInvalid: isIncomplete
      }
    }


    /** If Document is finalized / not tracked for dunning / not paid and is an invoice
         *  Show a track button
         * */
    if (params.data.completed
            && !params.data.isTracked
            && params.data.documentKind === DunningBusinessDocumentKind.INVOICE
            && params.data.status !== InvoiceStatus.PAID
    ) {
      return {
        newElementClass: ['action-track', 'start-businessDocument-tracking'], //  complete-and-track
        newInnerHtml: t('invoiceStatus.startTracking'),
        completeButton: false,
        trackButton: true,
        isInvalid: false
      }
    }
    /** if Document is finalized / has a paid or oustanding status
         *  Generate the status from ComputedDataStore
         * */
    const status = params.value
    const {
      newInnerHtml,
      newElementClass
    } = DunningInvoicesHelper.getStatusLabelHTMLAndClasses(status, params.data.dataId)

    return {
      newElementClass,
      newInnerHtml,
      completeButton: false,
      trackButton: false,
      isInvalid: false
    }
  }
  /**
     * NumberSetter for AG GRID
     * @param params
     * @param property
     */
  static numberSetter(params: ValueSetterParams, property: string): boolean {
    const newValInt: number = parseFloat(params.newValue)
    const hasValueChanged: boolean = params.data[property] !== newValInt
    if (hasValueChanged) {
      params.data[property] = newValInt
    }

    return hasValueChanged
  }
  static async downloadInvoicePDF(invoice: Invoice): Promise<StorageBucketObject | null> {
    const invoicesStoreContent: ComputedDataInvoice[] = get(ComputedDataInvoicesStore)
    
    if (!!invoicesStoreContent && invoicesStoreContent.length) {
      const storedComputedInvoice: ComputedDataInvoice | undefined = invoicesStoreContent.find((computedInvoice: ComputedDataInvoice): boolean => invoice && computedInvoice.invoiceNumber === invoice.invoiceNumber)

      if (storedComputedInvoice?.invoiceMetadata?.businessDocumentAttachmentMetadata?.fileName) {
        const downloadedInvoice: StorageBucketObject = await pdfAttachmentDownload(invoice.invoiceNumber, invoice.dataId, get(WorkspaceStore).workspaceId, invoice.completed)

        return downloadedInvoice
      }
    }

    return null
  }

}


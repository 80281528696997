import { get } from 'svelte/store'
import { authZJsUserId, isAuthenticatedUnderAuth0JsClient } from '../lib/auth0authentication/authStore'
import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
import { actionWhenNotSignedIn } from './auth.service'

export function checkSignInOrRedirect() {
  /* console.log('---- checkSignInOrRedirect in guard.ts') */
  if (!get(isAuthenticatedUnderAuth0JsClient)) {
    actionWhenNotSignedIn()
  }
}

export function isUserWsOwner() {
  const currentWorkspaceStore = get(WorkspaceStore)
  const currentAuthZJsUserId = get(authZJsUserId)
  if (!currentWorkspaceStore) {
    return false
  } else if (!currentAuthZJsUserId) {
    return false
  } else {
    return currentWorkspaceStore.ownerId === currentAuthZJsUserId
  }
}

import { BusinessDocument } from './business-document'
import { BusinessDocumentKind } from '../enums/business-document-kind'
import { InvoicingBalanceLimits } from './invoicing-balance-limits.model'

export class BalanceOfDealBusinessDocumentBuildingData {
  businessDocument: BusinessDocument
  isCollateralDataSameAsAnotherOne: boolean
  businessDocumentIdForCollateralDataIfSameAsAnotherOne: string
  collateralDataBusinessDocumentId: string
  collateralDataBusinessDocumentKind: BusinessDocumentKind
  collateralDataNeedsPaymentURLResetAnyway: boolean
  invoicingBalanceLimitsForBalanceInvoice: InvoicingBalanceLimits
  existingDocument: boolean

  constructor(
    businessDocument: BusinessDocument,
    isCollateralDataSameAsAnotherOne: boolean = false,
    businessDocumentIdForCollateralDataIfSameAsAnotherOne: string = '',
    collateralDataBusinessDocumentId: string = '',
    collateralDataBusinessDocumentKind: BusinessDocumentKind,
    collateralDataNeedsPaymentURLResetAnyway: boolean = true,
    invoicingBalanceLimitsForBalanceInvoice: InvoicingBalanceLimits,
    existingDocument: boolean = false
  ) {
    this.businessDocument = businessDocument
    this.isCollateralDataSameAsAnotherOne = isCollateralDataSameAsAnotherOne
    this.businessDocumentIdForCollateralDataIfSameAsAnotherOne = businessDocumentIdForCollateralDataIfSameAsAnotherOne
    this.collateralDataBusinessDocumentId = collateralDataBusinessDocumentId
    this.collateralDataBusinessDocumentKind = collateralDataBusinessDocumentKind
    this.collateralDataNeedsPaymentURLResetAnyway = collateralDataNeedsPaymentURLResetAnyway
    this.invoicingBalanceLimitsForBalanceInvoice = invoicingBalanceLimitsForBalanceInvoice
    this.existingDocument = existingDocument
  }

  // Static method to return an empty instance
  static empty(): BalanceOfDealBusinessDocumentBuildingData {
    // Provide default empty values for all fields.
    // This requires a dummy BusinessDocument and InvoicingBalanceLimits, so adjust accordingly.
    return new BalanceOfDealBusinessDocumentBuildingData(
      new BusinessDocument(), // Assuming BusinessDocument can be instantiated like this; adjust as necessary.
      false,
      '',
      '',
      BusinessDocumentKind.INVOICE,
      true,
      InvoicingBalanceLimits.empty(),
      false
    )
  }
}

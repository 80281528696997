<script lang="ts">
    import { DunningInvoicesStore } from '../../../dundy-app/stores/dunning-invoices.store'
    import { getDefaultCurrencyAccordingToCountry } from '../../../core-app/services/currency-list'
    import { getUserCountryCode } from '../../../core-app/services/countries-pure-functions'
    import { Customer } from '../../models/customer'
    import { CustomersHelper } from '../../helpers/customers-helper'
    import { formatCurrencyWithOptions } from '../../../voxy-app/utils/number-formatting-utils'
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'

    /** Export declarations */
    export let customer: Customer = null

    /** Local declarations */
    const invoicesCurrency: string = $DunningInvoicesStore.find((invoice) => (invoice.currency))?.currency || getDefaultCurrencyAccordingToCountry(getUserCountryCode())

    /** Reactive declarations */
    $: overdueAmount = CustomersHelper.calculateOverdueAmount(customer)
</script>

<div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
    <p class="text-xs text-cabaret font-bold">{formatCurrencyWithOptions(overdueAmount, t('locales'), invoicesCurrency, 'symbol')}</p>
</div>
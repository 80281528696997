import { get, type Writable, writable } from 'svelte/store'
import { eventsManager } from '../events/event-manager'
import { EventType } from '../events/event-type'
import { encrypt256Hash } from '../util/encryption'
import initializationStore from './initialization.store'
import type { FeedbackEntity } from '../enums/feedback-enums'
import type { Feedback } from '../models/feedback'
import { MapEnabledJson, mapEnabledReplacer } from '../util/json'

/* console.log('feedback.store.ts') */

export let FeedbackStore: Writable<Map<FeedbackEntity, Feedback>> = writable(new Map<FeedbackEntity, Feedback>())

export const initializeFeedbacksStore = (data: Map<FeedbackEntity, Feedback>) => {

  if (!get(initializationStore).feedbacksStoreInitialized) {

    initializationStore.update(store => {
      store.feedbacksStoreInitialized = true

      return store
    })

    localStorage.setItem('FeedbackStore', MapEnabledJson.stringify(data))
    FeedbackStore.set(data)

    // NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
    FeedbackStore.subscribe(newFeedback => {

      if (newFeedback) {

        const oldFeedback: Map<FeedbackEntity, Feedback> = localStorage.getItem('FeedbackStore') ? MapEnabledJson.parse(localStorage.getItem('FeedbackStore')) : new Map<FeedbackEntity, Feedback>()
        const oldFeedbackHash = encrypt256Hash(oldFeedback, mapEnabledReplacer)
        const newFeedbackHash = encrypt256Hash(newFeedback, mapEnabledReplacer)

        if (oldFeedbackHash !== newFeedbackHash) {

          for (const k of data.keys()) {
            const newFeedbackOnEntity = newFeedback.get(k)
            const oldFeedbackOnEntity = oldFeedback && oldFeedback.get(k)
            const newFeedbackOnEntityHash = encrypt256Hash(newFeedbackOnEntity)
            const oldFeedbackOnEntityHash = oldFeedback && encrypt256Hash(oldFeedbackOnEntity)
            if (newFeedbackOnEntity && newFeedbackOnEntityHash !== oldFeedbackOnEntityHash) {
              eventsManager.emit(EventType[k + '_FEEDBACK_CHANGED'], newFeedbackOnEntity, 'FeedbackStore')
              eventsManager.emit(EventType.FEEDBACK_CHANGED, newFeedbackOnEntity, 'FeedbackStore')
            }
          }
          localStorage.setItem('FeedbackStore', MapEnabledJson.stringify(newFeedback))
        }
      }
    })

    /* console.log('%c FeedbackStore initialized.', 'color: #8A99AC') */
  }
}

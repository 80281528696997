<script lang="ts">
  import type Company from '$src/crm-app/models/company'
  import type { Contact } from '$src/crm-app/models/contact'
  import { t } from '$core/lib/i18n/i18nextWrapper'
  import { CustomerType } from '$crm/enums/customer-manage'
  import type MailAddress from '$crm/models/mail-address'

  export let company: Readonly<Company>
  export let contact: Readonly<Contact | null> = null
  export let isEditingContact: Readonly<boolean> = true
  export let isPrivateIndividual: Readonly<boolean> = false
  export let customerRadio: Readonly<string>

  // Check if mailAddress has any meaningful information
  const hasMeaningfulMailAddress = (address: MailAddress): boolean => !!(address.street || address.extraAddressLine || address.city || address.zipCode || address.state)

</script>

<div class="p-4">
  
  {#if company?.formalName || (isPrivateIndividual && contact?.firstName && contact?.lastName)}
    <div class="flex items-center pb-4">
      {#if isPrivateIndividual}
        <div
          class="contact-avatar w-10 h-10">{contact?.firstName?.substring(0, 1).toUpperCase()}{contact?.lastName?.substring(0, 1).toUpperCase()}</div>
      {:else}
        <div
          class="contact-avatar w-10 h-10">{company?.formalName?.substring(0, 1).toUpperCase()}</div>
      {/if}
      <div class="ml-4 flex-auto">
        {#if isPrivateIndividual}
          <h2 class="font-bold text-lg">{contact?.firstName} {contact?.lastName}</h2>
        {:else}
          <h2 class="font-bold text-lg">{company?.formalName}</h2>
          <h3>{company?.usualName}</h3>
        {/if}

        <p class="text-baliHai text-xs">
          {#if isPrivateIndividual}
            {t('customerManage.form.individualClient')}
          {:else}
            {t('customerManage.form.professionalClient')}
          {/if}
        </p>
      </div>
    </div>
    <div class="flex flex-col text-sm border-t py-2">
      {#if hasMeaningfulMailAddress(company.mailAddress)}
        <div class="sm:grid sm:grid-cols-3 items-start sm:gap-4 sm:py-6">
          <p class="block text-sm font-medium leading-6 text-gray-900">{t('customerManage.form.address')}</p>
          <div class="mt-2 sm:col-span-2 sm:mt-0 flex flex-col">
            <span>{company.mailAddress.street} {company.mailAddress.extraAddressLine}</span>
            <span>{company.mailAddress.zipCode} {company.mailAddress.city}</span>
            <span>{company.mailAddress.state}</span>
            <span>{company.mailAddress.country}</span>
          </div>
        </div>
      {/if}
    </div>

    {#if contact && isEditingContact && !isPrivateIndividual && !!contact.firstName && !!contact.lastName}
      <div class="my-4 py-4 flex flex-col">
        <div class="flex min-w-0 gap-x-4 rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 p-4">
          <div
            class="contact-avatar w-10 h-10">{contact?.firstName?.substring(0, 1).toUpperCase()}{contact?.lastName?.substring(0, 1).toUpperCase()}</div>
          <div class="min-w-0 flex-auto">
            <p class="text-sm font-semibold leading-6 text-gray-900">{contact?.firstName} {contact?.lastName}</p>
            {#if contact?.position || contact?.department}
              <p class="text-s truncate text-gray-900">{contact?.position} {#if contact?.department}-{/if} {contact?.department}</p>
            {/if}
            {#if contact?.email}
              <div class="mt-1 flex w-0 flex-1 items-center">
                <svg class="h-5 w-5 flex-shrink-0 text-gray-400" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 7.75C4.75 6.64543 5.64543 5.75 6.75 5.75H17.25C18.3546 5.75 19.25 6.64543 19.25 7.75V16.25C19.25 17.3546 18.3546 18.25 17.25 18.25H6.75C5.64543 18.25 4.75 17.3546 4.75 16.25V7.75Z"></path>
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5.5 6.5L12 12.25L18.5 6.5"></path>
                </svg>
                <div class="ml-4 flex flex-1 gap-2">
                  <dt class="sr-only">Email</dt>
                  <dd><a class="font-semibold truncate text-s leading-5 text-dundyOrange"
                         href="mailto:{contact.email}">{contact.email}</a></dd>
                </div>
              </div>
            {/if}
            {#if contact?.officePhone}
              <div class="mt-1 flex w-0 flex-1 items-center">
                <svg class="h-5 w-5 flex-shrink-0 text-gray-400" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.89286 4.75H6.06818C5.34017 4.75 4.75 5.34017 4.75 6.06818C4.75 13.3483 10.6517 19.25 17.9318 19.25C18.6598 19.25 19.25 18.6598 19.25 17.9318V15.1071L16.1429 13.0357L14.5317 14.6468C14.2519 14.9267 13.8337 15.0137 13.4821 14.8321C12.8858 14.524 11.9181 13.9452 10.9643 13.0357C9.98768 12.1045 9.41548 11.1011 9.12829 10.494C8.96734 10.1537 9.06052 9.76091 9.32669 9.49474L10.9643 7.85714L8.89286 4.75Z"></path>
                </svg>
                <div class="ml-4 flex flex-1 gap-2">
                  <dt class="sr-only">Office Phone Number</dt>
                  <dd class="truncate text-s leading-5">{contact.officePhone}</dd>
                </div>
              </div>
            {/if}
            {#if contact?.mobilePhone}
              <div class="mt-1 flex w-0 flex-1 items-center">
                <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.2502 19.25H8.75C7.64543 19.25 6.75 18.3546 6.75 17.25V6.75C6.75 5.64543 7.64543 4.75 8.75 4.75H15.2502C16.3548 4.75 17.2502 5.64543 17.2502 6.75V17.25C17.2502 18.3546 16.3548 19.25 15.2502 19.25Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M11.75 16.75H12.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>

                <div class="ml-4 flex flex-1 gap-2">
                  <dt class="sr-only">Mobile Phone Number</dt>
                  <dd class="truncate text-s leading-5">{contact.mobilePhone}</dd>
                </div>
              </div>
            {/if}
          </div>
        </div>
      </div>
    {/if}

  {:else}
    {#if customerRadio === CustomerType.FrenchCompany }
      <div class="aside-note"><p class="text-sm">{@html t('customerManage.form.asideNoteFrenchCompany')}</p></div>
    {:else}
      <div class="aside-note"><p class="text-sm">{@html t('customerManage.form.asideNoteOther')}</p>
      </div>
    {/if}
  {/if}
</div>

<style lang="postcss">
  .contact-avatar {
    @apply bg-slate-100 rounded-md p-3 flex items-center align-middle justify-center border-athensGray text-baliHai ring-1 ring-gray-900/5 shadow-sm;
  }

  .aside-note {
    @apply bg-creamBrulee flex items-center border border-warmGray rounded-md px-6 py-4;
    border-color: hsl(47, 65%, 84%);
    background-color: hsl(47, 87%, 94%);
  }
</style>
<script lang="ts">
    import { t } from '../i18n/i18nextWrapper.js'
    import { createEventDispatcher, onMount, onDestroy, beforeUpdate } from 'svelte'
    import { eventsManager } from '../../events/event-manager'
    import { EventType } from '../../events/event-type'

    /** Export declarations */

    export let titleTMessageEntry: string = 'settings.uploadLogo.uploadingLogo'

    /** Local const & let */
    let actualUploadProgress: number = 0
    let progressBarPercentage: number = 50
    let progressBarPercentageCalculationInterval: any = null
    const dispatch = createEventDispatcher()
    let isVisible: boolean = false
    let unsubscribe: Function

    let onMountCompleted: boolean = false

    $: {
      if (progressBarPercentage >= 100) {
        setTimeout(() => {
          isVisible = false
        }, 1000)
      } else if (progressBarPercentage <= 0) {
        isVisible = false
      } else {
        isVisible = true
      }
    }

    beforeUpdate(() => {
      if (!onMountCompleted) {
        isVisible = false
      }
    })

    onMount(() => {
      unsubscribe = eventsManager.on<number>(EventType.FILE_UPLOAD_PROGRESS_CHANGED, e => {
        actualUploadProgress = e.data
      }, 'NewWorkspaceAddModal.svelte')
      progressBarPercentageCalculationInterval = setInterval(() => {
        if (progressBarPercentage < 10 && actualUploadProgress > 10) progressBarPercentage = 10
        else if (progressBarPercentage < 20 && actualUploadProgress > 20) progressBarPercentage = 20
        else if (progressBarPercentage < 30 && actualUploadProgress > 30) progressBarPercentage = 30
        else if (progressBarPercentage < 40 && actualUploadProgress > 40) progressBarPercentage = 40
        else if (progressBarPercentage < 50 && actualUploadProgress > 50) progressBarPercentage = 50
        else if (progressBarPercentage < 60 && actualUploadProgress > 60) progressBarPercentage = 60
        else if (progressBarPercentage < 70 && actualUploadProgress > 70) progressBarPercentage = 70
        else if (progressBarPercentage < 80 && actualUploadProgress > 80) progressBarPercentage = 80
        else if (progressBarPercentage < 90 && actualUploadProgress > 90) progressBarPercentage = 90
        else if (progressBarPercentage < 100 && actualUploadProgress >= 100) progressBarPercentage = 100
        else progressBarPercentage = actualUploadProgress
      }, 150)
      onMountCompleted = true
    })

    onDestroy(() => {
      clearInterval(progressBarPercentageCalculationInterval)
      unsubscribe()
    })
</script>

{#if isVisible }
    <div data-ut-component="file-upload-progress-bar">
        <div class="file-upload-wrapper">
            <span class="font-bold">{t(titleTMessageEntry)}</span><br/>
            <span class="font-bold text-dundyOrange mb-2">{progressBarPercentage}%</span><br/>
            <div class="rounded-lg border border-loblolly bg-whisper h-3 mt-2"
                 style="width: 100%"></div>
            <div class="rounded border border-hotCinnamon bg-dundyOrange h-3 -mt-3"
                 style="width: {progressBarPercentage}%"></div>
        </div>
    </div>
{/if}


<style lang="postcss">
    .file-upload-wrapper {
        @apply w-full justify-self-center text-center my-6;
    }
</style>
import auth0 from 'auth0-js'
import {
  auth0JsClient,
  auth0JsExpiresAt,
  auth0JsExpiresIn,
  auth0JsIdToken,
  auth0JsRefreshToken,
  auth0JsUserRights,
  authZeroJsAccessToken,
  authZJsFirstName,
  authZJsFullName,
  authZJsLastName,
  authZJsUserId,
  isAuthenticatedUnderAuth0JsClient,
  userUnderAuth0JsClient
} from './authStore.js'
import config from './auth-config.js'
import { get } from 'svelte/store'
import { navigate } from 'svelte-routing'
import type Auth0User from './auth0-user-model'
import { stateManagementService } from '../../services/state-management.service'
import mixpanel from 'mixpanel-browser'
import customLog from '$src/shared/services/custom-log.service.js'

async function createClient() {
  // with auth0-js
  // ref : https://auth0.com/docs/libraries/auth0js
  //       https://auth0.github.io/auth0.js/index.html
  const newAuth0JsClient = new auth0.WebAuth({
    clientID: config.clientId,
    domain: config.domain,
    audience: config.apiAudience,
    responseType: 'token', // https://auth0.com/docs/libraries/auth0js#available-parameters
    redirectUri: config.createClientRedirectUri,
    responseMode: 'fragment',
    scope: 'openid email profile app_metadata user_metadata picture'
  })
  auth0JsClient.set(newAuth0JsClient)
}

/**
 * @param {any} signupEmail
 * @param {any} signupPassword
 * @param {string} signupFirstName
 * @param {string} signupLastName
 * @param {any} callback
 * @param {any} fullname
 */
function customSignUpWithFirstNameAndLastName(signupEmail: any, signupPassword: any, signupFirstName: string, signupLastName: string, callback: any, fullname: any) {
  // https://auth0.com/docs/libraries/auth0js#signup
  // https://auth0.github.io/auth0.js/global.html#signup
  // @ts-ignore
  get(auth0JsClient).signup({
    email: signupEmail,
    password: signupPassword,
    given_name: signupFirstName,
    family_name: signupLastName,
    // if there is a name specified then it is a test user
    name: (fullname ? 'testUser' + '/' + signupFirstName + ' ' + signupLastName : signupFirstName + ' ' + signupLastName),
    connection: config.clientDatabaseConnection,
    user_metadata: {
      origin: 'dundy.co'
    }
  },
  callback) // LATER: be coherent with cb in loginAuth0WithEmailAndPasswordWithCb(), i.e. (signUpErr, signUpResult)=>{callback(signUpErr, signUpResult);}

  mixpanel.track('AA00 Sign Up', {
    'Description': 'WebApp Received Sign Up to auth0',
    'email': signupEmail,
    'firstName': signupFirstName,
    'lastName': signupLastName,
    'connection': config.clientDatabaseConnection
  })
  mixpanel.register({
    'email': signupEmail,
    'firstName': signupLastName,
    'lastName': signupLastName,
    'connection': config.clientDatabaseConnection
  })
}

/**
 * @param {any} email
 * @param {any} callback
 */
function askEmailForForgotMyPassword(email, callback) {
  // https://auth0.github.io/auth0.js/global.html#changePassword
  get(auth0JsClient).changePassword({
    email: email,
    connection: config.clientDatabaseConnection
  }, callback) // LATER: be coherent with cb in loginAuth0WithEmailAndPasswordWithCb(), i.e. (err)=>{callback(err);}
}


function logout(gotoSignin: boolean = true) {
  /* console.log('logout : go to /signin first') */
  mixpanel.track('ZZ10 Sign Out', {
    'Description': 'WebApp Logout',
    'userId': get(userUnderAuth0JsClient)?.sub,
    'email': get(userUnderAuth0JsClient)?.email,
    'firstName': get(authZJsFirstName),
    'lastName': get(authZJsLastName),
    'connection': config.clientDatabaseConnection
  })
  mixpanel.register({
    'userId': get(userUnderAuth0JsClient)?.sub,
    'email': get(userUnderAuth0JsClient)?.email,
    'firstName': get(authZJsFirstName),
    'lastName': get(authZJsLastName),
    'connection': config.clientDatabaseConnection
  })
  stateManagementService.reset('logout reset')
  localStorage.clear()
  /* console.log('navigate in', 'authService.js logout()', ': at window.location.pathname=', window.location.pathname, 'nextNavigationURI=', '/signin') */
  // https://auth0.github.io/auth0.js/global.html#logout

  const logoutOptions: any = {
    clientID: config.clientId,
    returnTo: gotoSignin ? config.logoutRedirectUri : null,
    federated: true
  }
  // if (gotoSignin) logoutOptions.returnTo = config.logoutRedirectUri

  get(auth0JsClient).logout(logoutOptions)
  mixpanel.reset()
  isAuthenticatedUnderAuth0JsClient.set(false)
}

/**
 * @param {any} email
 * @param {any} password
 */
function loginAuth0WithEmailAndPassword(email, password) {
  // Makes a call to the oauth/token endpoint with password-realm grant type :
  // https://auth0.com/docs/libraries/auth0js#webauth-login-
  // https://auth0.github.io/auth0.js/Authentication.html#login
  /*
    Requires Implicit grant (https://auth0.com/docs/api-auth/grant/implicit).
    For more information, read https://auth0.com/docs/clients/client-grant-types.
    Logs the user in with username and password using the correct flow based on where it's called from:
    - If you're calling this method from the Universal Login Page, it will use the usernamepassword/login endpoint
    - If you're calling this method outside the Universal Login Page, it will use the cross origin authentication
        (/co/authenticate) flow You can use either username or email to identify the user,
        but username will take precedence over email.
        After the redirect to redirectUri, use parseHash to retrieve the authentication data.
        Notice that when using the cross origin authentication flow, some browsers might not be able
        to successfully authenticate if 3rd party cookies are disabled.
        See here for more information: https://auth0.com/docs/cross-origin-authentication
    */
  get(auth0JsClient).login({
    username: email,
    password: password,
    realm: config.clientDatabaseConnection,
    scope: 'openid email user_metadata profile',
    onRedirecting: (done) => {
      /* console.log('wow is it called ? yes before leaving the current front-end state') */
      // alert("called");
      done()
    }
  }, function (err, authResult) {
    // Callback function called only when an authentication error, like invalid username or password, occurs.
    // For other types of errors, there will be a redirect to the redirectUri.
    // alert("post loginAuth0WithEmailAndPassword : this is never executed except in case of error, despite it should also execute in case of success");
    actionAfterLoginErrOrAuthResultReceived(err, authResult)
  })
}


/**
 * the mainly used login function for the web app (interacting with the user)
 * @param {any} email
 * @param {any} password
 * @param {{ (done: any): void; (arg0: any, arg1: any): void; }} callback
 */
function loginAuth0WithEmailAndPasswordWithCb(email, password, callback) {
  // Makes a call to the oauth/token endpoint with password-realm grant type :
  // https://auth0.com/docs/libraries/auth0js#webauth-login-
  // https://auth0.github.io/auth0.js/Authentication.html#login
  /*
    Requires Implicit grant (https://auth0.com/docs/api-auth/grant/implicit).
    For more information, read https://auth0.com/docs/clients/client-grant-types.
    Logs the user in with username and password using the correct flow based on where it's called from:
    - If you're calling this method from the Universal Login Page, it will use the usernamepassword/login endpoint
    - If you're calling this method outside the Universal Login Page, it will use the cross origin authentication
        (/co/authenticate) flow You can use either username or email to identify the user,
        but username will take precedence over email.
        After the redirect to redirectUri, use parseHash to retrieve the authentication data.
        Notice that when using the cross origin authentication flow, some browsers might not be able
        to successfully authenticate if 3rd party cookies are disabled.
        See here for more information: https://auth0.com/docs/cross-origin-authentication
    */

  get(auth0JsClient).login({
    email: email,
    password: password,
    realm: config.clientDatabaseConnection,
    scope: 'openid email user_metadata profile'
    // https://auth0.github.io/auth0.js/global.html#onRedirectingCallback :
    // Hook function that is called before redirecting to /authorize, allowing you to handle custom code. You must call the done function to resume authentication.
    // onRedirecting: cb,
  }, (err, authResult) => {
    // alert("ho ho ho, this is never executed despite it should");
    actionAfterLoginErrOrAuthResultReceived(err, authResult)
    callback(err, authResult)
  })
}

/**
 * @param {any} email
 * @param {any} password
 */
function loginAuth0WithEmailAndPasswordThenRedirectTo(email, password) {
  loginAuth0WithEmailAndPasswordWithCb(email, password, function (done) {
    done()
  })
}

function loginWithRedirectUniversalLogin() {
  // https://auth0.com/docs/libraries/auth0js#webauth-authorize-
  // https://auth0.github.io/auth0.js/global.html#authorize
  get(auth0JsClient).authorize({
    clientID: config.clientId,
    redirectUri: config.universalLoginRedirectUri,
    responseType: 'token', // https://auth0.com/docs/libraries/auth0js#available-parameters
    scope: 'openid email profile user_metadata picture',
    audience: config.universalAudience
  })
}

function loginPopUpUniversalLogin() {
  // https://auth0.com/docs/libraries/auth0js#webauth-popup-authorize-
  // https://auth0.github.io/auth0.js/global.html#authorize
  get(auth0JsClient).popup.authorize({
    clientID: config.clientId,
    redirectUri: config.universalLoginRedirectUri,
    responseType: 'token', // https://auth0.com/docs/libraries/auth0js#available-parameters
    scope: 'openid email profile user_metadata picture',
    audience: config.universalAudience
  }, function (err, authResult) {
    actionAfterLoginErrOrAuthResultReceived(err, authResult)
  })

}


function isAuth0JsClientReady() {
  return get(auth0JsClient).hasOwnProperty('baseOptions')
}


/**
 * auth0-js login callback : url token data collection
 * @param {any} urlContent
 */
function parsePossiblyPresentLoginResultDataInUrl(urlContent) {

  if (isAuth0JsClientReady()) {
    get(auth0JsClient).parseHash({ hash: urlContent }, function (err, authResult) {
      actionAfterLoginErrOrAuthResultReceived(err, authResult)
    })
  }
}

/**
 * @param {any} err
 * @param {{ accessToken: string; expiresIn: number; idToken: string; refreshToken: string; } | null} authResult
 */
function actionAfterLoginErrOrAuthResultReceived(err, authResult) {
  if (err) {
    /* console.error('LogIn failed') */

    return /* console.log(err) */
  }
  /* console.log('%c actionAfterLoginErrOrAuthResultReceived() authResult', 'background: orange;', authResult) */
  const numberOfSecondsMarginBeforeExpiration = 120 // 120 for 2 minutes less than expiration theoretical expiration
  if (authResult != null) {

    authZeroJsAccessToken.set(authResult.accessToken)
    auth0JsExpiresIn.set(authResult.expiresIn)
    auth0JsExpiresAt.set(Math.round((new Date()).getTime() / 1000) + authResult.expiresIn - numberOfSecondsMarginBeforeExpiration)
    const newAuth0JsExpiresAt = get(auth0JsExpiresAt)

    /* console.log('auth0JsExpiresAt', newAuth0JsExpiresAt) */
    /* console.log('authResult.expiresIn', authResult.expiresIn) */
    /* console.log('numberOfSecondsMarginBeforeExpiration', numberOfSecondsMarginBeforeExpiration) */
    const dateUnixMs = new Date(newAuth0JsExpiresAt * 1000) // in ms with newAuth0JsExpiresAt*1000
    const dateDisplayable = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long' }).format(dateUnixMs)
    /* console.log('auth0JsExpiresAt dateDisplayable=', dateDisplayable) */
    auth0JsIdToken.set(authResult.idToken)
    auth0JsRefreshToken.set(authResult.refreshToken)
    isAuthenticatedUnderAuth0JsClient.set(true)
    useJwtAccessTokenContent(get(authZeroJsAccessToken))
    get(auth0JsClient).client.userInfo(authResult.accessToken, function (err, user: Auth0User) {
      if (err) {
        /* console.error('UserInfo failed') */

        return /* console.log(err) */
      }
      // console.error('%c actionAfterLoginErrOrAuthResultReceived() userInfo user', 'background: orange;', JSON.stringify(user,null,4))
      // Set this to a unique identifier for the user performing the event.
      mixpanel.identify(user.sub)
      // Track an event. It can be anything, but in this example, we're tracking a Sign In event.
      mixpanel.track('BA00 Sign In', {
        'Description': 'WebApp Received SignIn Redirection With Auth Result',
        'userId': user?.sub,
        'email': user?.email,
        'firstName': user?.given_name,
        'lastName': user?.family_name,
        'connection': config.clientDatabaseConnection
      })
      mixpanel.register({
        'userId': user?.sub,
        'email': user?.email,
        'firstName': user?.given_name,
        'lastName': user?.family_name,
        'connection': config.clientDatabaseConnection
      })
      if (!user.email_verified) {
        // console.error("%c email_verified FALSE", 'font-size: 1.2em; background: red; color:black;')
        // setTimeout(()=>{
        console.error('%c back to sign in', 'font-size: 1.2em; background: orange;')
        // OnboardingStore.set({ isOnboarding: true, currentOnboardingStep: OnboardingStep.CONFIRM })
        navigate('/onboarding/confirm')
        // },100)
      }
      userUnderAuth0JsClient.set(user)
      if (user != null) {
        authZJsFirstName.set(user.given_name)
        authZJsLastName.set(user.family_name)
        authZJsFullName.set(user.name)
      }
    })

    /*if (!!NewWorkspaceRequestedByUserWorkspaceId) {
            console.log("%c actionAfterLoginErrOrAuthResultReceived() navigate get(auth0JsPostLoginRedirect)", 'background: orange;', get(auth0JsPostLoginRedirect));
            navigate(get(auth0JsPostLoginRedirect) !== 'null' ? get(auth0JsPostLoginRedirect) : '/');
        } else {
            console.log('actionAfterLoginErrOrAuthResultReceived() navigate /select-workspace');
            navigate('/select-workspace');
        }*/

    /* console.log('%c actionAfterLoginErrOrAuthResultReceived() navigate get(auth0JsPostLoginRedirect)', 'background: orange;', get(auth0JsPostLoginRedirect)) */
    // if (get(auth0JsPostLoginRedirect) !== 'null') {
    //     navigate(get(auth0JsPostLoginRedirect));
    // }
    /*navigate(get(auth0JsPostLoginRedirect) !== 'null' ? get(auth0JsPostLoginRedirect) : '/');*/
  }
}

/**
 * @param {string} accessToken
 */
function showJwt(accessToken) {
  let resultingDecomposition = {
    jwtHeaderStr: '',
    jwtHeaderObj: <any>{},
    jwtPayloadStr: '',
    jwtPayloadObj: <any>{},
    jwtUserRights: <any>{
      DundyCo: <any>{},
      CompanionUTradeIo: <any>{}
    },
    jwtUserId: ''
  }
  const parts = accessToken.split('.')
  if (parts.length !== 3) {
    /* console.error('received access token is not JWT') */

    return
  }
  resultingDecomposition.jwtHeaderStr = window.atob(parts[0])
  resultingDecomposition.jwtPayloadStr = window.atob(parts[1])
  // const jwtVerifySignature = window.atob(parts[2]);
  resultingDecomposition.jwtHeaderObj = JSON.parse(resultingDecomposition.jwtHeaderStr)
  resultingDecomposition.jwtPayloadObj = JSON.parse(resultingDecomposition.jwtPayloadStr)
  /* console.log('jwtPayload') */
  /* console.log(JSON.stringify(resultingDecomposition.jwtPayloadObj, null, 4)) */


  if (resultingDecomposition.jwtPayloadObj.hasOwnProperty('https://dundy.co/metaRights')) {
    resultingDecomposition.jwtUserRights.DundyCo = resultingDecomposition.jwtPayloadObj['https://dundy.co/metaRights']
  }
  if (resultingDecomposition.jwtPayloadObj.hasOwnProperty('https://companion.u-trade.io/metaRights')) {
    resultingDecomposition.jwtUserRights.CompanionUTradeIo = resultingDecomposition.jwtPayloadObj['https://companion.u-trade.io/metaRights']
  }
  if (resultingDecomposition.jwtPayloadObj.hasOwnProperty('sub')) {
    resultingDecomposition.jwtUserId = resultingDecomposition.jwtPayloadObj.sub
  }

  return resultingDecomposition
}

/**
 * @param {string} accessToken
 */
function useJwtAccessTokenContent(accessToken) {
  const resultingDecomposition = showJwt(accessToken)
  auth0JsUserRights.set(resultingDecomposition.jwtUserRights)
  authZJsUserId.set(resultingDecomposition.jwtUserId)
}


export const auth = {
  createClient,
  customSignUpWithFirstNameAndLastName,
  loginAuth0WithEmailAndPassword,
  loginAuth0WithEmailAndPasswordWithCb,
  loginPopUpUniversalLogin,
  loginWithRedirectUniversalLogin,
  loginAuth0WithEmailAndPasswordThenRedirectTo,
  parsePossiblyPresentLoginResultDataInUrl,
  logout,
  askEmailForForgotMyPassword,
  showJwt
}

export default auth

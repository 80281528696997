
/**
 * CONVERT DATE TO RFC3339 FORMAT
 * @param {Date} date 
 * @returns {string}
 */
export const dateToRfc3339 = (date?: Date):string => {
  if (!date) date = new Date()
  
  return date.toISOString()
}

/**
 * CONVERT DATE TO ISO 8601 FORMAT
 * @param {Date} date 
 * @param {boolean} withTime 
 * @returns {string}
 */
export const dateToIso8601 = (date?: Date, withTime:boolean = false):string => {
  if (!date) date = new Date()

  const d = date.toISOString()
  
  return withTime ? d : d.split('T')[0]
}

/**
 * CONVERT DATE TO UNIX TIMESTAMP
 * @param {Date} date 
 * @returns {number}
 */
export const dateToTimestamp = (date?: Date):number => {
  if (!date) date = new Date()
  
  return Math.floor(date.getTime() / 1000)
}

/**
 * CONVERT UNIX TIMESTAMP TO DATE
 * @param {number} timestamp 
 * @returns {Date}
 */
export const dateFromTimestamp = (timestamp: number):Date => new Date(timestamp * 1000)


/**
 * CONCERT DATE TO YYYYMMDD NUMBER
 * @param date 
 * @returns {number} YYYYMMDD
 */
export const dateToNumberYYYYMMDD = (date: Date): number => {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  
  return parseInt(`${year}${month}${day}`)
}


/**
 * GET SECONDS BETWEEN TWO DATES
 * @param dateInitial 
 * @param dateFinal 
 * @returns {number}
 */
export const dateDiffInSeconds = (dateInitial:Date, dateFinal:Date):number => (dateFinal.getTime() - dateInitial.getTime()) / 1_000

/**
 * GET MINUTES BETWEEN TWO DATES
 * @param dateInitial 
 * @param dateFinal 
 * @returns {number}
 */
export const dateDiffInMinutes = (dateInitial:Date, dateFinal:Date):number => (dateFinal.getTime() - dateInitial.getTime()) / 60_000

/**
 * GET HOURS BETWEEN TWO DATES
 * @param {Date} dateInitial
 * @param {Date} dateFinal
 * @returns {number}
 */
export const dateDiffInHours = (dateInitial:Date, dateFinal:Date):number => (dateFinal.getTime() - dateInitial.getTime()) / 3_600_000

/**
 * 
 * @param {Date} dateInitial
 * @param {Date} dateFinal
 * @returns {number}
 */
export const dateDiffInDays = (dateInitial:Date, dateFinal:Date):number => (dateFinal.getTime() - dateInitial.getTime()) / 86_400_000

/**
 * GET WEEKS BETWEEN TWO DATES
 * @param {Date} dateInitial
 * @param {Date} dateFinal
 * @returns {number}
 */
export const dateDiffInWeeks = (dateInitial:Date, dateFinal:Date):number => (dateFinal.getTime() - dateInitial.getTime()) / 604_800_000
export const dateDiffInMonths = (dateInitial:Date, dateFinal:Date):number => Math.max(
  (dateFinal.getFullYear() - dateInitial.getFullYear()) * 12 +
      dateFinal.getMonth() -
      dateInitial.getMonth(),
  0
)

/**
 * GET YEARS BETWEEN TWO DATES
 * @param {Date} dateInitial
 * @param {Date} dateFinal
 * @returns {number}
 */
export const dateDiffInYears = (dateInitial:Date, dateFinal:Date):number => dateDiffInMonths(dateInitial, dateFinal) / 12

/**
 * CHECK IF TWO DATES ARE EQUAL
 * @param dateStart 
 * @param dateEnd 
 * @param date 
 * @returns {boolean}
 */
export const dateIsSame = (dateA:Date, dateB:Date):boolean => dateA.toISOString() === dateB.toISOString()

/**
 * CHECK IF ONE DATE IS BEFORE ANOTHER
 * @param {Date} dateA
 * @param {Date} dateB
 * @returns {boolean}
 */
export const dateIsBefore = (dateA:Date, dateB:Date, onlyDate:boolean = false):boolean => {
  if (!onlyDate) return dateA < dateB
  
  return dateToNumberYYYYMMDD(dateA) < dateToNumberYYYYMMDD(dateB)
}

/**
 * CHECK IF ONE DATE IS AFTER ANOTHER
 * @param {Date} dateA
 * @param {Date} dateB
 * @returns {boolean}
 */
export const dateIsAfter = (dateA:Date, dateB:Date):boolean => dateA > dateB

/**
 * CHECK IF ONE DATE IS BETWEEN TWO DATES
 * @param {Date} dateStart 
 * @param {Date} dateEnd 
 * @param {Date} date 
 * @returns {boolean}
 */
export const dateIsBetween = (dateStart:Date, dateEnd:Date, date:Date):boolean => date > dateStart && date < dateEnd
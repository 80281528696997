<script lang="ts">
    import { onMount } from 'svelte'
    import { ProfileStore } from '../../../core-app/stores/profile.store'
    import { navigate } from 'svelte-routing'
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper.js'
    import Tooltip from '../../../core-app/lib/ui-kit/Tooltip/Tooltip.svelte'


    let isLoading: boolean = true

    onMount(() => {
      setTimeout(() => {
        isLoading = false
      }, 200)
    })

</script>

<tr>
    {#if isLoading}
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div class="flex items-center">
                <div class="flex flex-wrap content-center justify-center h-10 w-10 bg-zinc-100 text-white rounded-full mr-3 flex-shrink-0"></div>
            </div>
        </td>
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div class="mr-auto">
                <p class="w-40 h-3 mb-3 bg-athensGray rounded"/>
                <p class="w-32 h-2 bg-athensGray rounded"/>
            </div>
        </td>
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div class="mr-auto">
                <p class="w-40 h-2 mb-3 bg-athensGray rounded"/>
                <p class="w-32 h-2 bg-athensGray rounded"/>
            </div>
        </td>
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div class="mr-auto">
                <p class="w-16   h-2 bg-athensGray rounded"/>
            </div>
        </td>

    {:else}
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div class="flex items-center">
                <div class="flex flex-wrap content-center justify-center h-10 w-10 text-white rounded-full bg-zinc-300 mr-3 flex-shrink-0">
                    <span>{$ProfileStore.firstName.substring(0, 1).toUpperCase()}{$ProfileStore.lastName.substring(0, 1).toUpperCase()}</span>
                </div>
                <div class="ml-4">
                    <div class="font-bold text-gray-900">{$ProfileStore.firstName} {$ProfileStore.lastName} {t('team.listMembers.content.youLabel')}</div>
                    <div class="text-gray-400">{$ProfileStore.email}</div>
                </div>
            </div>
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <div class="text-gray-900 capitalize">{$ProfileStore.roleInCompany === 'other' ? $ProfileStore.roleInCompanyCustom : t('onboarding.lists.role.' + $ProfileStore.roleInCompany)}</div>
            <div class="text-gray-400"></div>
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <span class="inline-flex rounded-md bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">{t('team.listMembers.content.status.active')}</span>
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{t('userRoles.owner')}</td>
        <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 collapsible">
            <Tooltip>
                <div slot="activator">
                    <button on:click={() => navigate('/settings/profile/edit')}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.75 19.25L9 18.25L18.9491 8.30083C19.3397 7.9103 19.3397 7.27714 18.9491 6.88661L17.1134 5.05083C16.7228 4.6603 16.0897 4.6603 15.6991 5.05083L5.75 15L4.75 19.25Z"
                                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path d="M14.0234 7.03906L17.0234 10.0391" stroke="currentColor" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>

                        <span class="sr-only">Edit {$ProfileStore.firstName} {$ProfileStore.lastName}</span>
                    </button>
                </div>
                {t('team.listMembers.actions.editSelf')}
            </Tooltip>
        </td>
    {/if}
</tr>
<style lang="postcss">
    button {
        @apply rounded border-2 border-transparent p-2;
        display: inherit;
    }

    button:active,
    button:hover {
        @apply bg-provincialPink border-dundyOrange;
    }
</style>

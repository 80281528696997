import { authZJsFirstName, authZJsLastName, userUnderAuth0JsClient, authZJsUserId } from '../lib/auth0authentication/authStore'
import { checkSignInOrRedirect } from './guard'
import { get } from 'svelte/store'
import { getCompaniesByName, getInpiCompanyInfo } from '$src/shared/services/company.service'
import { getTimeZoneIANACode } from './countries-pure-functions'
import { navigate } from 'svelte-routing'
import { OnboardingInfo, OnboardingStep } from '../enums/onboarding-steps'
import { OnboardingStore } from '../stores/onboarding.store'
import { ProfileStore } from '../stores/profile.store'
import { remoteDebuggingService } from './remote-debugging.service'
import { Workspace } from '../../crm-app/models/workspace'
import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
import Company from '$src/crm-app/models/company'
import currentPathStore from '../stores/current-path.store'
import mixpanel from 'mixpanel-browser'
import Profile from '../models/profile'
import type BiFrInpiCompanyBySirenAPIResponse from '$src/shared/models/inpi'

function urlNOTLike(urlStepDeterminationPattern: string): boolean {
  /* console.log('urlNOTLike currentPathStore', get(currentPathStore)) */

  return get(currentPathStore).indexOf(urlStepDeterminationPattern) === -1
}

function urlIsLike(urlStepDeterminationPattern: string): boolean {
  /* console.log('urlIsLike currentPathStore', get(currentPathStore)) */

  return get(currentPathStore).indexOf(urlStepDeterminationPattern) !== -1
}

class OnboardingPriorityStep {
  onboardingStep: OnboardingStep
  completenessCheck: Function
}

const onboardingPrioritySteps: Array<OnboardingPriorityStep> = [{
  onboardingStep: OnboardingStep.WAITING_FOR_PROFILE,
  completenessCheck: () => {
    if (!get(ProfileStore) || !get(ProfileStore).firstName || get(ProfileStore).firstName === '' || !get(ProfileStore).lastName || get(ProfileStore).lastName === '') {
      if (!get(ProfileStore)) {
        /* console.log('issue missing ProfileStore') */
      }
      if (!!get(ProfileStore) && !get(ProfileStore).firstName) {
        /* console.log('issue missing profile firstName') */
      }
      if (!!get(ProfileStore) && !!get(ProfileStore).firstName && get(ProfileStore).firstName === '') {
        /* console.log('issue profile firstName is empty') */
      }
      if (!!get(ProfileStore) && !get(ProfileStore).lastName) {
        /* console.log('issue missing profile lastName') */
      }
      if (!!get(ProfileStore) && !!get(ProfileStore).lastName && get(ProfileStore).lastName === '') {
        /* console.log('issue profile lastName is empty') */
      }
      /* console.log('OnboardingStep.WAITING_FOR_PROFILE') */

      return false
    }

    return true
  }
}, {
  onboardingStep: OnboardingStep.WAITING_FOR_WORKSPACE,
  completenessCheck: () => {
    if (!get(WorkspaceStore) || !get(WorkspaceStore).workspaceId || get(WorkspaceStore).workspaceId === '') {
      /* console.log('OnboardingStep.WAITING_FOR_WORKSPACE') */

      return false
    }

    return true
  }
}, {
  onboardingStep: OnboardingStep.COMPANY_INFO,
  completenessCheck: () => {
    const currentProfile: Profile = Profile.StoreToClass()
    const currentWorkspace: Workspace = Workspace.StoreToClass()
    // const isOnboardingProfileInfoDataComplete: boolean = currentProfile.isOnboardingProfileInfoDataComplete()
    const isOnboardingCompanyInfoDataComplete: boolean = currentWorkspace.isOnboardingCompanyInfoDataComplete()
    const userId = get(authZJsUserId)
    // console.log("isOnboardingProfileInfoDataComplete=",isOnboardingProfileInfoDataComplete,"isOnboardingCompanyInfoDataComplete=",isOnboardingCompanyInfoDataComplete)
    console.log("isOnboardingCompanyInfoDataComplete=",isOnboardingCompanyInfoDataComplete)
    // If invited team user, bypass company form
    if (currentWorkspace.teamUsersIds.includes(userId)) return true

    // if (!isOnboardingProfileInfoDataComplete || !isOnboardingCompanyInfoDataComplete) {
    if (!isOnboardingCompanyInfoDataComplete) {
      // if (!isOnboardingProfileInfoDataComplete) {
      //   remoteDebuggingService.addInfo('not currentProfile.isOnboardingProfileInfoDataComplete()', 'onboarding.service.ts')
      //   /* console.error('not currentProfile.isOnboardingProfileInfoDataComplete()') */
      // }
      if (!isOnboardingCompanyInfoDataComplete) {
        remoteDebuggingService.addInfo('not currentWorkspace.isOnboardingCompanyInfoDataComplete()', 'onboarding.service.ts')
        /* console.error('not currentWorkspace.isOnboardingCompanyInfoDataComplete()') */
      }
      /* console.log('%c ON-BOARDING -> COMPANY_INFO', 'color:pink;font-size: 1.3em;') */

      return false
    }

    return true
  }
}, {
  onboardingStep: OnboardingStep.COMPANY_INFO_PLUS,
  completenessCheck: () => {
    const currentProfile: Profile = Profile.StoreToClass()
    const currentWorkspace: Workspace = Workspace.StoreToClass()
    const isOnboardingProfileInfoDataComplete: boolean = currentProfile.isOnboardingProfileInfoDataComplete()
    const isOnboardingProfileInfoPlusDataComplete: boolean = currentProfile.isOnboardingProfileInfoPlusDataComplete()
    const isOnboardingCompanyInfoPlusDataComplete: boolean = currentWorkspace.isOnboardingCompanyInfoPlusDataComplete()
    
    if (!isOnboardingProfileInfoDataComplete || !isOnboardingProfileInfoPlusDataComplete || !isOnboardingCompanyInfoPlusDataComplete) {
      if (!isOnboardingProfileInfoDataComplete) {
        remoteDebuggingService.addInfo('not currentProfile.isOnboardingProfileInfoDataComplete()', 'onboarding.service.ts')
        /* console.error('not currentProfile.isOnboardingProfileInfoDataComplete()') */
      }
      if (!isOnboardingProfileInfoPlusDataComplete) {
        /* console.error('not currentProfile.isOnboardingProfileInfoPlusDataComplete()') */
        remoteDebuggingService.addInfo('not currentProfile.isOnboardingProfileInfoPlusDataComplete()', 'onboarding.service.ts')
      }
      if (!isOnboardingCompanyInfoPlusDataComplete) {
        /* console.error('not currentWorkspace.isOnboardingCompanyInfoPlusDataComplete()') */
        remoteDebuggingService.addInfo('not currentWorkspace.isOnboardingCompanyInfoPlusDataComplete()', 'onboarding.service.ts')
      }
      /* console.log('%c ON-BOARDING -> COMPANY_INFO_PLUS', 'color:pink;font-size: 1.3em;') */

      return false
    }

    return true
  }
}
]

onboardingPrioritySteps.push({
  onboardingStep: OnboardingStep.APP_NO_MISSING_INFO,
  completenessCheck: () => false
})

export function whatIsThePriorityMissingOnboardingStep(): OnboardingStep {
  for (const onboardingPriorityStep of onboardingPrioritySteps) {

    if (!onboardingPriorityStep.completenessCheck()) {
      /* console.log('Returning PriorityOnboardingStep', onboardingPriorityStep) */

      return onboardingPriorityStep.onboardingStep
    } else {
      if (onboardingPriorityStep.onboardingStep === get(OnboardingStore).currentOnboardingStep) { // we are validating the current step => that's an important event
        mixpanel.track('Cx90 Onboarding ' + onboardingPriorityStep.onboardingStep, {
          'Description': 'Onboarding step successfully completed',
          'userId': get(userUnderAuth0JsClient)?.sub,
          'email': get(userUnderAuth0JsClient)?.email,
          'firstName': get(authZJsFirstName),
          'lastName': get(authZJsLastName)
        })
      }
    }
  }
  /* console.error('No step was returned after iterating all onboardingPrioritySteps. Something went wrong. Should have returned the last step if all the info was completed.') */
}

export function onboardingInfoOf(onboardingStep: OnboardingStep): OnboardingInfo {

  const onboardingInfo = new Map<OnboardingStep, OnboardingInfo>()
  onboardingInfo.set(OnboardingStep.START, {
    onboardingStep: OnboardingStep.START,
    urlStepDeterminationPattern: 'start',
    navigationParentRoute: 'onboarding',
    navigationSubRoute: 'start',
    navigationRelativeURL: '/onboarding/start'
  })
  onboardingInfo.set(OnboardingStep.SIGNUP, {
    onboardingStep: OnboardingStep.SIGNUP,
    urlStepDeterminationPattern: 'signup',
    navigationParentRoute: 'onboarding',
    navigationSubRoute: 'signup',
    navigationRelativeURL: '/onboarding/signup'
  })
  onboardingInfo.set(OnboardingStep.CONFIRM, {
    onboardingStep: OnboardingStep.CONFIRM,
    urlStepDeterminationPattern: 'confirm',
    navigationParentRoute: 'onboarding',
    navigationSubRoute: 'confirm',
    navigationRelativeURL: '/onboarding/confirm'
  })
  onboardingInfo.set(OnboardingStep.COMPANY_INFO, {
    onboardingStep: OnboardingStep.COMPANY_INFO,
    urlStepDeterminationPattern: 'company-info',
    navigationParentRoute: 'onboarding',
    navigationSubRoute: 'company-info',
    navigationRelativeURL: '/onboarding/company-info'
  })
  onboardingInfo.set(OnboardingStep.COMPANY_INFO_PLUS, {
    onboardingStep: OnboardingStep.COMPANY_INFO_PLUS,
    urlStepDeterminationPattern: 'company-info-plus',
    navigationParentRoute: 'onboarding',
    navigationSubRoute: 'company-info-plus',
    navigationRelativeURL: '/onboarding/company-info-plus'
  })
  onboardingInfo.set(OnboardingStep.SELECT_ACCOUNT, {
    onboardingStep: OnboardingStep.SELECT_ACCOUNT,
    urlStepDeterminationPattern: 'select-account',
    navigationParentRoute: 'settings',
    navigationSubRoute: 'select-account',
    navigationRelativeURL: '/settings/bank/select-account'
  })
  onboardingInfo.set(OnboardingStep.APP_START_USING_HOME, {
    onboardingStep: OnboardingStep.APP_START_USING_HOME,
    urlStepDeterminationPattern: 'dashboard',
    navigationParentRoute: '',
    navigationSubRoute: 'today',
    navigationRelativeURL: '/today'
  })
  onboardingInfo.set(OnboardingStep.APP_NO_MISSING_INFO, {
    onboardingStep: OnboardingStep.APP_NO_MISSING_INFO,
    urlStepDeterminationPattern: 'n/a',
    navigationParentRoute: 'n/a',
    navigationSubRoute: 'n/a',
    navigationRelativeURL: 'n/a'
  })
  onboardingInfo.set(OnboardingStep.WAITING_FOR_PROFILE, {
    onboardingStep: OnboardingStep.WAITING_FOR_PROFILE,
    urlStepDeterminationPattern: 'n/a',
    navigationParentRoute: 'n/a',
    navigationSubRoute: 'n/a',
    navigationRelativeURL: 'n/a'
  })
  onboardingInfo.set(OnboardingStep.WAITING_FOR_WORKSPACE, {
    onboardingStep: OnboardingStep.WAITING_FOR_WORKSPACE,
    urlStepDeterminationPattern: 'n/a',
    navigationParentRoute: 'n/a',
    navigationSubRoute: 'n/a',
    navigationRelativeURL: 'n/a'
  })

  if (!onboardingInfo.get(onboardingStep)) {
    /* console.error('un-implemented / unknown onboarding step case=', onboardingStep) */

    return onboardingInfo.get(OnboardingStep.APP_START_USING_HOME)
  }
  
  return onboardingInfo.get(onboardingStep)
}

// we should not go for navigateToMissingOnboardingStepIfNeeded if we have not chosen any Workspace yet !!!!!!!!!!!
export function navigateToMissingOnboardingStepIfNeeded() {
  checkSignInOrRedirect()
  /* console.log('--- navigateToMissingOnboardingStepIfNeeded ---') */
  const stepWithMissingInfo: OnboardingStep = whatIsThePriorityMissingOnboardingStep()
  let onboardingInfo: OnboardingInfo = onboardingInfoOf(stepWithMissingInfo)
  
  if (onboardingInfo.onboardingStep === OnboardingStep.APP_NO_MISSING_INFO && window.location.pathname.startsWith('/onboarding/')) {
    onboardingInfo = (OnboardingStep.APP_START_USING_HOME, {
      onboardingStep: OnboardingStep.APP_START_USING_HOME,
      urlStepDeterminationPattern: 'dashboard',
      navigationParentRoute: '',
      navigationSubRoute: 'today',
      navigationRelativeURL: '/today'
    })
  }

  if (onboardingInfo.onboardingStep !== OnboardingStep.APP_NO_MISSING_INFO &&
        onboardingInfo.onboardingStep !== OnboardingStep.WAITING_FOR_PROFILE &&
        onboardingInfo.onboardingStep !== OnboardingStep.WAITING_FOR_WORKSPACE) {

    navigate(onboardingInfo.navigationRelativeURL)
  }
}

/**
 * SEARCH COMPANY BY NAME FROM INSEE API
 * @param {string} name 
 * @param {string} zipCode 
 * @returns Company[]
 */
export const companySearch = async (name : string, zipCode?:string, index?: number):Promise<Company[]> => {
  const workspaceId = get(WorkspaceStore).workspaceId
  
  return getCompaniesByName(workspaceId, name, zipCode, index)
}

export const companyGetInpiInfo = async (siren: string):Promise<BiFrInpiCompanyBySirenAPIResponse> => {
  const workspaceId = get(WorkspaceStore).workspaceId
  
  return getInpiCompanyInfo(workspaceId, siren)
}

/**
 * SAVE COMPANY OBJECT TO WORKSPACE + PROFILE
 * @param {Company} company 
 * @param {string} companyId 
 */
export const companyToWorkspace = (company: Company, companyId?: string):void => {
  const workspace = get(WorkspaceStore)
  
  workspace.company = company
  if (companyId) workspace.company.companyId = companyId
  workspace.company.timeZoneIANACode = getTimeZoneIANACode(company.mailAddress.countryCode, company.mailAddress.zipCode)
  
  WorkspaceStore.set(workspace)
}

/**
 * CONVERT WORKSPACE COMPANY TO COMPANY OBJECT
 * @returns {Company}
 */
export const companyFromWorkspace = (): Company => {
  const workspace = get(WorkspaceStore)

  return workspace?.company ? JSON.parse(JSON.stringify(workspace.company)) : {}
}
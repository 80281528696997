<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import PerPage from './PerPage.svelte'
  import Navigation from './Navigation.svelte'

  export let perPageList:Readonly<number[]> = [20, 50, 100]
  export let itemsPerPage:number = perPageList[0]

  export let items:Readonly<number> = 0
  export let currentPage:number = 1
  export let countDescription:Readonly<string> = ''

  const dispatch = createEventDispatcher()

  $:pageCount = items < itemsPerPage ? 1 : Math.ceil(items / itemsPerPage)

  const onPerPageChange = (e:CustomEvent) => {
    itemsPerPage = e.detail
    dispatch('perPageChange', e.detail)
  }

  const onPrevious = (e:CustomEvent) => {
    currentPage = e.detail
    dispatch('previous', e.detail)
    dispatch('pageChange', e.detail)
  }

  const onNext = (e:CustomEvent) => {
    currentPage = e.detail
    dispatch('next', e.detail)
    dispatch('pageChange', e.detail)
  }
</script>

<div class="pagination flex justify-between items-center text-sm mt-6">
  <PerPage list={perPageList} selected={itemsPerPage} on:change={e => onPerPageChange(e)}/>
  <span>{countDescription}</span>
  <Navigation current={currentPage} count={pageCount} on:previous={e => onPrevious(e)} on:next={e => onNext(e)}/>
</div>

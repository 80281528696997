<script lang="ts">
  import { t } from '$core/lib/i18n/i18nextWrapper'
  import { createEventDispatcher, onMount } from 'svelte'
  import { validateEmail } from '$core/services/validator'
  import TextInput from '../../../core-app/lib/ui-kit/TextInput.svelte'
  import type { Contact } from '$crm/models/contact'
  import mixpanel from 'mixpanel-browser'

  /** Exported Var **/
  export let editedContact: Contact = <Contact>{}

  /** Let declarations **/
  let canSubmitContact: boolean
  let isEmailValid: boolean
  let inputFocus: HTMLInputElement

  const dispatch = createEventDispatcher()
  const changedFields: any = {}

  function handleInput(event: Event) {
    const target = event.target

    if (target instanceof HTMLInputElement && target.id) {
      changedFields[target.id.replace('contact-field-', '')] = true
      dispatch('input')
      dispatch('change', editedContact)
    }
  }

  /** Reactive declarations **/
  $: isEmailValid = !!editedContact.email && validateEmail(editedContact.email)

  $: {
    if (editedContact) {
      canSubmitContact = !!editedContact.firstName && !!editedContact.lastName && !!isEmailValid
    } else {
      canSubmitContact = false
    }
  }

  onMount(()=>{
    mixpanel.track('FA10 Edit Contact Page', {
      'Description': 'Open ContactForm.svelte'
    })
  })

</script>

<form autocomplete="off"
      on:input={handleInput}
      on:submit|preventDefault={() => dispatch('submit')}>

    <fieldset class="grid grid-cols-2 gap-x-6 gap-y-3">
        <TextInput
          bind:value={editedContact.firstName}
          dataCy="company"
          error={t('customerForm.firstName.error')}
          id={'contact-field-firstName'}
          label={t('customerForm.firstName.label')}
          on:blur={(e) => {
            const el = e.detail.target
            changedFields[el.id.replace('contact-field-', '')] = true
          }}
          showError={changedFields.label && !editedContact.firstName}
        />
        <TextInput
          bind:value={editedContact.lastName}
          dataCy="company"
          error={t('customerForm.lastName.error')}
          id={'contact-field-lastName'}
          label={t('customerForm.lastName.label')}
          on:blur={(e) => {
            const el = e.detail.target
            changedFields[el.id.replace('contact-field-', '')] = true
          }}
          showError={changedFields.label && !editedContact.lastName}
        />
        <TextInput
          bind:value={editedContact.position}
          dataCy="company"
          error={t('customerForm.position.error')}
          id={'contact-field-position'}
          label={t('customerForm.position.label')}
          on:blur={(e) => {
            const el = e.detail.target
            changedFields[el.id.replace('contact-field-', '')] = true
          }}
          showError={changedFields.label && !editedContact.position}
        />
        <TextInput
          bind:value={editedContact.department}
          dataCy="company"
          error={t('customerForm.department.error')}
          id={'contact-field-department'}
          label={t('customerForm.department.label')}
          on:blur={(e) => {
            const el = e.detail.target
            changedFields[el.id.replace('contact-field-', '')] = true
          }}
          showError={changedFields.label && !editedContact.department}
        />
        <TextInput
          bind:value={editedContact.officePhone}
          dataCy="company"
          error={t('customerForm.officePhone.error')}
          id={'contact-field-officePhone'}
          label={t('customerForm.officePhone.label')}
          type='number'
          hideSpin={true}
          on:blur={(e) => {
            const el = e.detail.target
            changedFields[el.id.replace('contact-field-', '')] = true
          }}
          showError={changedFields.label && !editedContact.officePhone}
        />
        <TextInput
          bind:value={editedContact.mobilePhone}
          dataCy="company"
          error={t('customerForm.mobilePhone.error')}
          id={'contact-field-mobilePhone'}
          label={t('customerForm.mobilePhone.label')}
          type='number'
          hideSpin={true}
          on:blur={(e) => {
            const el = e.detail.target
            changedFields[el.id.replace('contact-field-', '')] = true
          }}
          showError={changedFields.label && !editedContact.mobilePhone}
        />
        <TextInput
          bind:value={editedContact.email}
          dataCy="company"
          error={isEmailValid || t('customerForm.email.error')}
          id={'contact-field-email'}
          label={t('customerForm.email.label')}
          on:blur={(e) => {
            const el = e.detail.target
            changedFields[el.id.replace('contact-field-', '')] = true
          }}
          on:focus={e => inputFocus = e.detail.srcElement.id}
          showError={!!(inputFocus === 'contact-field-email') && (!editedContact.email || !isEmailValid)}

        />
    </fieldset>
    
    <slot {canSubmitContact}/>
</form>

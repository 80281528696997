<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper.js'
    import Invoice from '../../models/invoice'
    import { onMount } from 'svelte'
    import { updateNewNavigationHistoryItem } from '../../../core-app/stores/navigationHistory.store'

    /** Export Let declarations */
    export let invoice: Invoice

    onMount(()=>{
      updateNewNavigationHistoryItem('ViewInvoiceDetails.svelte')
    })
</script>
<div data-ut-component="view-business-document-details">
    <div class="my-7 border border-athensGray rounded-lg mx-8">
        <div class="space-y-6 py-7">
            <div class="px-12 sm:px-8">
                <h4 class="font-medium text-sm mb-3">{t('invoices.viewInvoice.invoiceDetails')}</h4>

                <div class="">
                    <ul>
                        <li class="flex justify-between text-s py-1.5">

                            <span class="text-paleSky">{t('invoices.viewInvoice.invoiceNumberLabel')}</span>
                            <span class="font-medium">{invoice?.invoiceNumber}</span>
                        </li>
                        <li class="flex justify-between text-s py-1.5">
                            <span class="text-paleSky">{t('invoices.viewInvoice.issued')}</span>
                            <span class="font-medium">{new Intl.DateTimeFormat(t('locales'), {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric'
                            }).format(new Date(invoice?.dateIssued))}</span>
                        </li>
                        <li class="flex justify-between text-s py-1.5">
                            <span class="text-paleSky">{t('invoices.viewInvoice.due')}</span>
                            <span class="font-medium">{new Intl.DateTimeFormat(t('locales'), {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric'
                            }).format(new Date(invoice?.dateDue))}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
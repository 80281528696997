<script lang="ts">
    import { createEventDispatcher } from 'svelte'
    import {
      ELLIPSIS,
      generateNavigationOptions,
      NEXT_PAGE,
      PREVIOUS_PAGE
    } from '../../services/pagination/pagination'

    const dispatch = createEventDispatcher()

    export let totalItems = 0
    export let pageSize = 1
    export let currentPage = 1
    export let limit = null
    export let showStepOptions = false

    $: options = generateNavigationOptions({
      totalItems,
      pageSize,
      currentPage,
      limit,
      showStepOptions
    })

    $: totalPages = Math.ceil(totalItems / pageSize)

    function handleOptionClick(option) {
      dispatch('setPage', { page: option.value })
    }
</script>

<div class="flex flex-row justify-end items-center text-xs space-x-1">
    {#each options as option}
        {#if option.type === 'number'}
            <div role="button"
                 tabindex="0"
                 class="page cursor-pointer"
                 class:page-selected={option.value === currentPage}
                 class:inner-page-selected={option.value === currentPage}
                 class:first-page={option.value === 1 && (totalItems > pageSize)}
                 class:last-page={option.value === Math.ceil(totalItems / pageSize) && (totalItems > pageSize)}
                 class:first-page-selected={option.value === 1 && option.value === currentPage && (totalItems > pageSize)}
                 class:last-page-selected={option.value === Math.ceil(totalItems / pageSize) && option.value === currentPage && (totalItems > pageSize)}
                 on:click={() => handleOptionClick(option)}
                 on:keydown={(e) => {
                   if (e.key === 'Enter') {
                     handleOptionClick(option)
                   }
                 }}
            >
                <span class="mx-2">{option.value}</span>
            </div>
        {:else if option.type === 'symbol' && option.symbol === ELLIPSIS}
            <div role="button"
                 tabindex="0"
                 class="cursor-pointer inline-flex items-center justify-center border border-athensGray bg-whisper h-8 w-8"
                 on:click={() => handleOptionClick(option)}
                 on:keydown={(e) => {
                   if (e.key === 'Enter') {
                     handleOptionClick(option)
                   }
                 }}
            >
                <span class="mx-2"> ... </span>
            </div>
        {:else if option.type === 'symbol' && option.symbol === PREVIOUS_PAGE}
            <div role="button"
                 tabindex="0"
                 class="cursor-pointer relative inline-flex items-center justify-center rounded-l-md border border-athensGray bg-white h-8 w-8 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                 on:click={() => handleOptionClick(option)}
                 on:keydown={(e) => {
                   if (e.key === 'Enter') {
                     handleOptionClick(option)
                   }
                 }}
            >
                <i class="icon-chevron-left text-2xl"></i>
            </div>
        {:else if option.type === 'symbol' && option.symbol === NEXT_PAGE}
            <slot name="next">
                <div role="button"
                     tabindex="0"
                     class="cursor-pointer relative inline-flex items-center justify-center rounded-r-md border border-athensGray bg-white h-8 w-8 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                     on:click={() => handleOptionClick(option)}
                     on:keydown={(e) => {
                       if (e.key === 'Enter') {
                         handleOptionClick(option)
                       }
                     }}
                >
                    <i class="icon-chevron-right text-2xl"></i>
                </div>
            </slot>
        {/if}
    {/each}
</div>

<style lang="postcss">
    .page {
        @apply inline-flex items-center rounded justify-center border border-athensGray bg-white h-8 w-8 text-s font-medium;
    }

    .page-selected {
        @apply border-black rounded bg-black text-white h-8 w-8 items-center flex;
    }
</style>

import type { BusinessDocumentAllDataPersisted } from '../models/business-document'
import { get } from 'svelte/store'
import { BusinessDocumentStatus } from '../enums/business-document-status'
import type { Workspace } from '../../crm-app/models/workspace'
import type { WorkspaceBankConfig } from '../../pay-app/models/workspace-bank-config'
import { getUpdatedIsBankInformationMissing } from '../helpers/voxy-helper-pure-functions'
import { deepClone } from '../../core-app/util/object-deep-cloning'
import { BusinessDocumentsStore } from '../stores/business-documents.store'
import { CoreDocument } from '../models/business-document'
import {
  getBusinessDocumentWithCollateralDataById, saveVoxyBusinessDocumentCollateralDataToServer, saveVoxyInvoiceToServer
} from './business-document-api/business-document-repository.api'

/**
 * Update Draft Voxy BusinessDocuments When Bank Information Changes
 * This function is called when the user changes his bank information in the workspace.
 * It updates all the draft business documents with the new bank information.
 */
export function updateDraftVoxyBusinessDocumentsWhenBankInformationChanges(newBankInfoConfig: WorkspaceBankConfig): void {

  logBankInfo(newBankInfoConfig)

  get(BusinessDocumentsStore).forEach((aCoreDocumentToCheckAndUpdate: CoreDocument): void => {
    setTimeout((): void => {
      handleCoreDocumentToCheckAndUpdate(aCoreDocumentToCheckAndUpdate, newBankInfoConfig)
    }, 50)
  })
}

/**
 * Checks if CoreDoc is Draft then gets BusinessDoc with collateral Data
 * @param aCoreDocumentToCheckAndUpdate
 * @param newBankInfoConfig
 */
function handleCoreDocumentToCheckAndUpdate(aCoreDocumentToCheckAndUpdate: CoreDocument, newBankInfoConfig: WorkspaceBankConfig) {
  if (aCoreDocumentToCheckAndUpdate.businessDocument.businessDocumentStatus === BusinessDocumentStatus.DRAFT) {
    getBusinessDocumentWithCollateralDataById(aCoreDocumentToCheckAndUpdate.businessDocument.businessDocumentId)
      .then((inputBusinessDocument: void | BusinessDocumentAllDataPersisted): void => {
        if (!!inputBusinessDocument) {
          updateSingleVoxyDraftBusinessDocumentWithBankInfo(inputBusinessDocument, newBankInfoConfig)
        } else {
          /* console.log('! FAILED ! error VOID while reading before updating bank info in business document id (aCoreDocumentToCheckAndUpdate businessDocumentId) ' + aCoreDocumentToCheckAndUpdate.businessDocument.businessDocumentId) */
        }
      })
      .catch(reason => {
        console.log('! FAILED ! error while reading before updating bank info in business document id (aCoreDocumentToCheckAndUpdate businessDocumentId) ' + aCoreDocumentToCheckAndUpdate.businessDocument.businessDocumentId, reason)
      })
  }
}

function updateSingleVoxyDraftBusinessDocumentWithBankInfo(inputBusinessDocument: BusinessDocumentAllDataPersisted, newBankInfoConfig: WorkspaceBankConfig) {
  if (inputBusinessDocument.coreDocument.businessDocument.businessDocumentStatus === BusinessDocumentStatus.DRAFT) {
    const myBusinessDocument: BusinessDocumentAllDataPersisted = deepClone(<BusinessDocumentAllDataPersisted> inputBusinessDocument)
    updateBankInfo(myBusinessDocument, newBankInfoConfig)
    /* console.log('BANK - updating bank info in business document id (BusinessDoc businessDocumentId) ' + inputBusinessDocument.coreDocument.businessDocument.businessDocumentId) */
    saveVoxyInvoiceToServer(myBusinessDocument.coreDocument.businessDocument)
      .then(() =>
      /* console.log('BANK - OK saved updated bank info business core document for business document id ' + myBusinessDocument.coreDocument.businessDocument.businessDocumentId) */

        saveVoxyBusinessDocumentCollateralDataToServer(myBusinessDocument.collateralDocument.businessDocumentCollateralData)
      )
      .then((): void => {
        /* console.log('BANK - OK saved updated bank info business collateral document for business document id ' + myBusinessDocument.collateralDocument.businessDocumentCollateralData.businessDocumentId) */
      })
      .catch(reason => {
        console.log('! FAILED ! error while updating bank info in business core or collateral documents for document id ' + myBusinessDocument.coreDocument.businessDocument.businessDocumentId, reason)
      })
  } else {
    /* console.log('-- no need to update business document id (businessDocumentId) ' + inputBusinessDocument.coreDocument.businessDocument.businessDocumentId) */
  }
}

/**
 * Update Bank Info
 * @param myBusinessDocument
 * @param newBankInfoConfig
 */
function updateBankInfo(myBusinessDocument: BusinessDocumentAllDataPersisted, newBankInfoConfig: WorkspaceBankConfig): void {
  myBusinessDocument.coreDocument.businessDocument.accountBankingInformation.bankAccountBIC = newBankInfoConfig.selectedAccountBankBIC
  myBusinessDocument.coreDocument.businessDocument.accountBankingInformation.bankAccountIBAN = newBankInfoConfig.selectedAccountBankIBAN
  myBusinessDocument.collateralDocument.businessDocumentCollateralData.isBankInformationMissing = getUpdatedIsBankInformationMissing(newBankInfoConfig)
}

/**
 * Purely for logging purposes
 * @param newBankInfoConfig
 */
function logBankInfo(newBankInfoConfig: WorkspaceBankConfig): void {
  /* console.log('BANK -' + JSON.stringify(newBankInfoConfig, null, 4)) */
  /* console.log('BANK - updateDraftVoxyBusinessDocumentsWhenBankInformationChanges: ' + JSON.stringify(newBankInfoConfig, null, 4)) */
}

/**
 * Update Draft Voxy BusinessDocuments When Company Information Changes
 * This function is called when the user changes his company workspace information.
 * It updates all the draft business documents with the new account information.
 */
export function updateDraftVoxyBusinessDocumentsWhenWorkspaceCompanyDataChanges(newWorkspaceCompanyData: Workspace): void {
  get(BusinessDocumentsStore).forEach((aCoreDocumentToCheckAndUpdate: CoreDocument): void => {
    setTimeout((): void => {
      handleCoreDocumentToCheckAndUpdateForCompany(aCoreDocumentToCheckAndUpdate, newWorkspaceCompanyData)
    }, 50)
  })
}

/**
 * Checks if CoreDoc is Draft then gets BusinessDoc with collateral Data
 * @param aCoreDocumentToCheckAndUpdate
 * @param newWorkspaceCompanyData
 */
function handleCoreDocumentToCheckAndUpdateForCompany(aCoreDocumentToCheckAndUpdate: CoreDocument, newWorkspaceCompanyData: Workspace) {
  if (aCoreDocumentToCheckAndUpdate.businessDocument.businessDocumentStatus === BusinessDocumentStatus.DRAFT) {
    getBusinessDocumentWithCollateralDataById(aCoreDocumentToCheckAndUpdate.businessDocument.businessDocumentId)
      .then((inputBusinessDocument: void | BusinessDocumentAllDataPersisted): void => {
        if (!!inputBusinessDocument) {
          updateSingleVoxyDraftBusinessDocumentWithCompanyInfo(inputBusinessDocument, newWorkspaceCompanyData)
        } else {
          /* console.log('! FAILED ! error VOID while reading before updating company info in business document id (aCoreDocumentToCheckAndUpdate businessDocumentId) ' + aCoreDocumentToCheckAndUpdate.businessDocument.businessDocumentId) */
        }
      })
      .catch(reason => {
        console.log('! FAILED ! error while reading before updating company info in business document id (aCoreDocumentToCheckAndUpdate businessDocumentId) ' + aCoreDocumentToCheckAndUpdate.businessDocument.businessDocumentId, reason)
      })
  }
}

/**
 * Update Single Voxy Draft Business Document For Company
 * @param inputBusinessDocument
 * @param newWorkspaceCompanyData
 */
function updateSingleVoxyDraftBusinessDocumentWithCompanyInfo(inputBusinessDocument: BusinessDocumentAllDataPersisted, newWorkspaceCompanyData: Workspace) {
  if (inputBusinessDocument.coreDocument.businessDocument.businessDocumentStatus === BusinessDocumentStatus.DRAFT) {
    const myBusinessDocument: BusinessDocumentAllDataPersisted = deepClone(<BusinessDocumentAllDataPersisted> inputBusinessDocument)

    updateCompanyInfo(myBusinessDocument, newWorkspaceCompanyData)

    /* console.log('COMPANY - updating company info in business document id (BusinessDocument businessDocumentId) ' + inputBusinessDocument.coreDocument.businessDocument.businessDocumentId) */
    saveVoxyInvoiceToServer(myBusinessDocument.coreDocument.businessDocument)
      .then(() => {
        /* console.log('COMPANY - OK saved updated company info business core document for business document id ' + myBusinessDocument.coreDocument.businessDocument.businessDocumentId) */
      })
      .catch(reason => {
        console.log('! FAILED ! error while updating company info in business core documents for document id ' + myBusinessDocument.coreDocument.businessDocument.businessDocumentId, reason)
      })
  } else {
    /* console.log('-- no need to update business document id (businessDocumentId) ' + inputBusinessDocument.coreDocument.businessDocument.businessDocumentId) */
  }
}

/**
 * Update Company Info
 * @param myBusinessDocument
 * @param newWorkspaceCompanyData
 */
function updateCompanyInfo(myBusinessDocument: BusinessDocumentAllDataPersisted, newWorkspaceCompanyData: Workspace): void {
  myBusinessDocument.coreDocument.businessDocument.accountCompany = newWorkspaceCompanyData.company
}




export type Column = {
  name:string, // visible column name
  path:string, // technical path in JSON object
  color?:string, // custom text color (default = black)
  width?: string // custom column width (default = auto)
}

export type Columns = Column[]

export enum SortOrder {
  NONE = 'none',
  ASC = 'asc',
  DESC = 'desc'
}
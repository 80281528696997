import { Amount } from '../../cash-application-app/models/amount-of-money-model'
import {
  AmountDecimal,
  NewAmountOfMoneyWithDecimal
} from '../../cash-application-app/models/amount-of-money-decimal-model'
import { Decimal } from 'decimal.js'
import { JsonProperty } from '@paddls/ts-serializer'


export class TaxRate {
  @JsonProperty('taxRateId') taxRateId: string
  @JsonProperty('displayName') displayName: string
  @JsonProperty('percentage') percentage: number
  @JsonProperty('description') description: string
  @JsonProperty('jurisdiction') jurisdiction: string
}

/**
 * @deprecated use AmountOfTaxesWithExcludingAndIncludingDecimal class instead
 */
export class AmountOfTaxesWithExcludingAndIncluding {
  @JsonProperty({ field: 'taxesAmount', type: () => Amount }) taxesAmount: Amount
  @JsonProperty({ field: 'amountIncludingTaxes', type: () => Amount }) amountIncludingTaxes: Amount
  @JsonProperty({ field: 'amountExcludingTaxes', type: () => Amount }) amountExcludingTaxes: Amount
}

export class AmountOfTaxesWithExcludingAndIncludingDecimal {
  @JsonProperty({ field: 'taxesAmount', type: () => AmountDecimal }) taxesAmount: AmountDecimal
  @JsonProperty({ field: 'amountIncludingTaxes', type: () => AmountDecimal }) amountIncludingTaxes: AmountDecimal
  @JsonProperty({ field: 'amountExcludingTaxes', type: () => AmountDecimal }) amountExcludingTaxes: AmountDecimal
}

export function NewAmountOfTaxesWithExcludingAndIncludingDecimal(scaledAmountIncludingTaxes: number, scaledAmountExcludingTaxes: number, scaledTaxesAmount: number, currencyCode: string): AmountOfTaxesWithExcludingAndIncludingDecimal {
  return <AmountOfTaxesWithExcludingAndIncludingDecimal>{
    amountIncludingTaxes: NewAmountOfMoneyWithDecimal(new Decimal(scaledAmountIncludingTaxes), new Decimal(2), currencyCode),
    amountExcludingTaxes: NewAmountOfMoneyWithDecimal(new Decimal(scaledAmountExcludingTaxes), new Decimal(2), currencyCode),
    taxesAmount: NewAmountOfMoneyWithDecimal(new Decimal(scaledTaxesAmount), new Decimal(2), currencyCode)
  }
}

export enum TaxesAmountFromExcludingTaxesCalculusMethod {
  Basic = 'Basic',
  RoundedAmounts = 'RoundedAmounts',
  RoundedAmountsAndTaxesAdjustmentByOneCent = 'RoundedAmountsAndTaxesAdjustmentByOneCent',
  RoundedAmountsAndTaxesAdjustmentByOneCentWithCorrectedRound = 'RoundedAmountsAndTaxesAdjustmentByOneCentWithCorrectedRound'
}
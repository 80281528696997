<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount } from 'svelte'
  import type { GridApi, GridOptions, GridSizeChangedEvent } from 'ag-grid-community'
  import { type CellValueChangedEvent, type ColDef, Grid, type GridReadyEvent, type IRowNode } from 'ag-grid-community'
  import AgGridCustomPagination from './AgGridCustomPagination.svelte'
  import 'ag-grid-community/styles/ag-grid.css'
  import 'ag-grid-community/styles/ag-theme-alpine.css'
  import { eventsManager } from '../../../events/event-manager'
  import { EventType } from '../../../events/event-type'
  import { DundyEvent } from '../../../../dundy-app/events/dundy-event'
  import Invoice from '../../../../dundy-app/models/invoice'
  import type { InvoiceNumber } from '../../../../cash-application-app/models/cash-application-model'
  import {
    NewCashApplicationUserSelectionForTransaction
  } from '../../../../cash-application-app/models/cash-application-ui-model'

  /** Export declarations  */
  export let columnDefs: ColDef[]
  export let theme: string = 'alpine'
  export let agGridTableData: any
  export let options: GridOptions<GridOptions>
  export const loading: boolean = false
  export let searchValue: string = ''
  export let thisAgGridComponentLabel: string
  export const firstRenderingItemsSelection: (a: any) => void = () => {}

  export let userSelectedInvoiceNumbersSet: Set<InvoiceNumber>
  export let applyUserSelectionOnItemsAsInvoices: boolean

  /** Local declarations */
  let ref: HTMLElement
  export let grid: any
  let api: GridApi
  let gridOptions: GridOptions
  let y: number
  const dispatch = createEventDispatcher<any>()
  let reactToRefreshRequestedEvent: Function
  let reactToRefreshAndReselectRequestedEvent: Function
  let freezeItemSelectionChangeEvent: boolean = false
  let lastSelectionUpdateUnixMilliseconds: number = (new Date()).getUTCMilliseconds()
  let maxIterationsSelectionUpdate: number = 0
  let initLastSelectionUpdate: boolean = false
  let nonUserSelectionChangeOngoing: boolean = false


  /** Pagination Declarations */
  let currentPage: number
  let totalPage: number
  let totalResults: number
  let pageSize: number
  let startResult: number
  let endResult: number

  // Events
  export let onGridReadyFunction: GridOptions['onGridReady'] = undefined

  function onGoToNextPage() {
    grid?.gridOptions.api.paginationGoToNextPage()
    onCustomPaginationChanged()
  }

  $: if (!!agGridTableData) {
    updateData(agGridTableData, true)
  }

  function onGoToPreviousPage() {
    grid?.gridOptions.api.paginationGoToPreviousPage()
    onCustomPaginationChanged()
  }

  function getPaginationPageSize(): number {
    // fallback to return first page size, when grid is loaded from provided array
    return grid ? grid.gridOptions.api.paginationGetPageSize() : 50
  }

  function onPageSizeChangedToggleClassForThisElementAndRemoveClassFromOtherElements(size: number): void {
    const paginationSizeTab = document.querySelectorAll('.pagination-size-tab')
    paginationSizeTab.forEach((element) => {
      element.classList.remove('pagination-size-tab-active')
    })
    const paginationSizeTabActive = document.querySelector(`.pagination-size-tab-${size}`)
    paginationSizeTabActive.classList.add('pagination-size-tab-active')
  }

  function onPageSizeChanged(size: number): void {
    if (!!grid) {
      grid.gridOptions.cacheBlockSize = size
    }
    grid?.gridOptions.api.paginationSetPageSize(Number(size))
    onPageSizeChangedToggleClassForThisElementAndRemoveClassFromOtherElements(size)
    onCustomPaginationChanged()
  }

  const onCustomPaginationChanged = () => {
    currentPage = grid?.gridOptions.api.paginationGetCurrentPage()
    totalPage = grid?.gridOptions.api.paginationGetTotalPages()
    totalResults = grid?.gridOptions.api.paginationGetRowCount()
    pageSize = getPaginationPageSize()
    startResult = currentPage * pageSize + 1
    endResult = Math.min(startResult + pageSize - 1, totalResults)
  }

  // https://blog.ag-grid.com/how-to-get-the-data-of-selected-rows-in-ag-grid/
  // e is a AgGridEvent
  // e is a SelectionChangedEvent
  // TODO find a better way to do this. lastSelectedNode is a internal service of ag-grid for managing row selection and not a public api
  const onSelectionChanged = (e) => {
    let isItemRemoved: boolean
    let addedInvoice: Invoice
    if (e.api.selectionService.lastSelectedNode) {
      isItemRemoved = false
      addedInvoice = e.api.selectionService.lastSelectedNode.data
    } else {
      isItemRemoved = true
      addedInvoice = null
    }
    if (nonUserSelectionChangeOngoing) {
      return
    }
    if (!freezeItemSelectionChangeEvent) {
      const selectedRows = api.getSelectedRows()
      const newCashApplicationUSerSelectionForTransaction: NewCashApplicationUserSelectionForTransaction = <NewCashApplicationUserSelectionForTransaction>{
        NewInvoicesSelection: selectedRows,
        addedInvoiceByUser: addedInvoice,
        isRemovedItem: isItemRemoved
      }
      dispatch('selectedRows', newCashApplicationUSerSelectionForTransaction)
    }
  }

  const refreshSelectionForCashApplicationUI = () => {
    /* console.log('%crebuild selection in refreshSelectionForCashApplicationUI()', 'color: green; font-size: 1.2em') */
    grid?.gridOptions.api.forEachNode((node: IRowNode<Invoice>) => {

      const isSelected = !!node.data && userSelectedInvoiceNumbersSet.has(node.data.invoiceNumber)
      freezeItemSelectionChangeEvent = true
      nonUserSelectionChangeOngoing = true
      node.setSelected(isSelected, false)
      nonUserSelectionChangeOngoing = false
      freezeItemSelectionChangeEvent = false
    })
  }

  const onCellValueChanged = (e: CellValueChangedEvent) => {
    agGridTableData[e.rowIndex] = e.data
    dispatch('onCellValueChanged', { row: e.rowIndex, data: e.data })
  }

  const onGridSizeChanged = (params: GridSizeChangedEvent): void => {
    // Following line to make the currently visible columns fit the screen
    params.api.sizeColumnsToFit()
    // Following line dymanic set height to row on content
    params.api.resetRowHeights()
  }

  // NB: we do not want to update the selection systematically, especially since a user-changed selection will dispatch an event that may go down back to here (hence potentially creating an infinite event loop)
  const updateData = (newData: any, refreshSelection: boolean) => {
    // if (!!grid) {
    //     grid.destroy();
    // }
    // grid = new Grid(ref, {
    //     ...options,
    //     columnDefs,
    //     rowData: newData,
    //     onGridReady(event: GridReadyEvent<GridReadyEvent>) {
    //         api = event.api;
    //         onGridReady && onGridReady?.(event);
    //     },
    //     onGridSizeChanged,
    //     onCellValueChanged,
    //     onSelectionChanged,
    // });
    if (!!grid && !!newData) {
      /* console.log(withThisLabel() + 'aggrid ! api update', newData) */
      agGridTableData = newData
      grid?.gridOptions.api.setColumnDefs(columnDefs)
      grid?.gridOptions.api.setRowData(newData)
      // grid?.gridOptions.api.refreshCells({
      //     force: true,
      // });
      // grid?.gridOptions.api.forEachNode(node => {

      // });
      // if (!!firstRenderingItemsSelection && (typeof firstRenderingItemsSelection === "function")) {
      //     grid?.gridOptions.api.forEachNode(firstRenderingItemsSelection);
      // }
      if (applyUserSelectionOnItemsAsInvoices) {


        const isWithinNMilliseconds: boolean = lastSelectionUpdateUnixMilliseconds + 1000 * 4 < (new Date()).getUTCMilliseconds()
        if ((maxIterationsSelectionUpdate > 0) || (isWithinNMilliseconds)) {
          lastSelectionUpdateUnixMilliseconds = (new Date()).getUTCMilliseconds()
          if (!isWithinNMilliseconds) {
            maxIterationsSelectionUpdate = 5
          }
          maxIterationsSelectionUpdate--
          if (refreshSelection) {
            /* console.log('%crebuilding selection in refreshSelectionForCashApplicationUI() from updateData()', 'color: #8888FF; font-size: 1.2em') */
            refreshSelectionForCashApplicationUI()
          }
        }
      }
    }
  }

  const onFilterTextChange = (searchValue) => {
    grid?.gridOptions.api.setQuickFilter(searchValue)
  }

  $: if (grid && (searchValue !== '' || searchValue !== null || searchValue !== undefined)) {
    onFilterTextChange(searchValue)
    onCustomPaginationChanged()
  } else {
    // console.trace('%cupdateData() in reactive statement in AgGridDatable', 'color: green; font-size: 1.2em')
    updateData(agGridTableData, false)
    onCustomPaginationChanged()
  }

  // $: updateData(agGridTableData);

  function withThisLabel(): string {
    return ' agg_ \'' + thisAgGridComponentLabel + '\' '
  }

  onMount(() => {
    if (!initLastSelectionUpdate) {
      initLastSelectionUpdate = true
      lastSelectionUpdateUnixMilliseconds = (new Date()).getUTCMilliseconds()
      maxIterationsSelectionUpdate = 5
    }

    /* console.log('%c' + withThisLabel() + 'onMount() AgGrid', 'color: green; font-size: 1.2em') */


    if (!!grid && !!gridOptions) {
      /* console.log(withThisLabel() + 'in destroy before build') */
      gridOptions.api.destroy()
      // grid.destroy();
      api = undefined
      grid = undefined
    }
    /* console.log('%c' + withThisLabel() + 'build new', 'color: green; font-size: 1.2em') */
    gridOptions = {
      ...options,
      columnDefs,
      rowData: agGridTableData,
      onGridReady(event: GridReadyEvent<GridReadyEvent>) {
        api = event.api
        // if (!!firstRenderingItemsSelection && (typeof firstRenderingItemsSelection === "function")) {
        //     event.api.forEachNode(firstRenderingItemsSelection);
        // }
        if (applyUserSelectionOnItemsAsInvoices) {


          const isWithinNMilliseconds: boolean = lastSelectionUpdateUnixMilliseconds + 1000 * 4 < (new Date()).getUTCMilliseconds()
          /* console.log('%crebuilding selection in refreshSelectionForCashApplicationUI() from onGridReady()', 'color: green; font-size: 1.2em') */
          refreshSelectionForCashApplicationUI()
        }
        // onGridReady && onGridReady?.(event);
        if (!!onGridReadyFunction && (typeof onGridReadyFunction === 'function')) {
          onGridReadyFunction(event)
        }
      },
      onGridSizeChanged,
      onCellValueChanged,
      onSelectionChanged
      // onFirstDataRendered: (event: FirstDataRenderedEvent): void => {
      //     // event.api.forEachNode(firstRenderingItemsSelection);
      // },
    }
    grid = new Grid(ref, gridOptions)

    if (!!reactToRefreshRequestedEvent) {
      reactToRefreshRequestedEvent()
      reactToRefreshRequestedEvent = null
    }
    reactToRefreshRequestedEvent = eventsManager.on<Invoice[]>(EventType.CASH_APPLICATION_LIST_REFRESH_REQUESTED, (e: DundyEvent<any>) => {
      const refreshSelection: boolean = false
      /* console.log(withThisLabel() + 'updating AgGrid with data', e.data) */
      updateData(agGridTableData, refreshSelection)
      // options = grid.gridOptions;
      /* console.log(withThisLabel() + 'aggrid gridOptions', gridOptions) */
      /* console.log(withThisLabel() + 'aggrid options', options) */
      /* console.log(withThisLabel() + 'aggrid grid', grid) */
    }, 'CashApplicationService')

    if (!!reactToRefreshAndReselectRequestedEvent) {
      reactToRefreshAndReselectRequestedEvent()
      reactToRefreshAndReselectRequestedEvent = null
    }
    reactToRefreshAndReselectRequestedEvent = eventsManager.on<Invoice[]>(EventType.CASH_APPLICATION_LIST_AND_SELECTION_REFRESH_REQUESTED, (e: DundyEvent<any>) => {
      const refreshSelection: boolean = true
      /* console.log(withThisLabel() + 'updating AgGrid with data', e.data) */
      updateData(agGridTableData, refreshSelection)
      // options = grid.gridOptions;
      /* console.log(withThisLabel() + 'aggrid gridOptions', gridOptions) */
      /* console.log(withThisLabel() + 'aggrid options', options) */
      /* console.log(withThisLabel() + 'aggrid grid', grid) */
    }, 'CashApplicationService')
  })

  onDestroy(() => {
    /* console.log(withThisLabel() + 'onDestroy() AgGrid') */
    if (!!reactToRefreshRequestedEvent) {
      reactToRefreshRequestedEvent()
      reactToRefreshRequestedEvent = null
    }
    if (!!reactToRefreshAndReselectRequestedEvent) {
      reactToRefreshAndReselectRequestedEvent()
      reactToRefreshAndReselectRequestedEvent = null
    }
    if (!!grid) {
      grid.destroy()
      api = undefined
      gridOptions = undefined
      grid = undefined
    }
  })
</script>

<svelte:window on:scrollY={y}/>

<div bind:this={ref}
     class="ag-theme-{theme}"
     style="height: 100%; width:100%"></div>

<div class="sticky flex items-center bottom-0 bg-white py-4 px-0"
     style="
    box-shadow: inset 0 1px 0 #efeffd;
    height: 56px;
    margin-top: 56px;
    z-index: 2;">
    <AgGridCustomPagination
            bind:currentPage
            bind:endResult
            bind:grid
            bind:pageSize
            bind:startResult
            bind:totalPage
            bind:totalResults
            id="transactions-pagination"
            on:goToNextPage={onGoToNextPage}
            on:goToPreviousPage={onGoToPreviousPage}
            on:setPageSize={(e) => {onPageSizeChanged(e.detail.size)}}
    />
</div>

<style global>
    .ag-sticky-header {
        position: fixed;
        top: 0;
        z-index: 99;
        width: auto;
    }
    .ag-root-wrapper {
      border-width: 1px;
      border-color: transparent !important;
      @apply rounded-b;
      @apply overflow-visible !important;
    }
</style>

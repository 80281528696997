<script lang="ts">
  import { createEventDispatcher } from 'svelte'

  export let value:string = ''
  export let name:string = ''

  const dispatch = createEventDispatcher()

  const onClick = () => {
    value = name
    dispatch('select', name)
  }
</script>

<button class="input-radio" class:selected={name === value} on:click={() => onClick()}>
  <slot />
</button>

<style lang="postcss">
  .input-radio {
    @apply relative pl-6 pr-2 mx-1 text-sm;

    &:focus {
      @apply font-semibold;
    }

    &::before {
      content: '';
      @apply absolute left-0 -top-[1px] size-5 bg-white inline-block border-2 border-dundyOrange rounded-full;
    }

    &.selected::after {
      content: '';
      @apply absolute size-2.5 bg-dundyOrange inline-block rounded-full left-[5px] top-[4px];
    }
  }
</style>
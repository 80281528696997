<script lang="ts">
  import { SignUpStoreEmail, SignUpStoreFirstName, SignUpStoreLastName } from '../../stores/signup.store'
  import { navigate } from 'svelte-routing'
  import { t } from '../../lib/i18n/i18nextWrapper'
  import { onMount } from 'svelte'
  import { fakeData, isEndToEndTest } from '../../stores/endToEndTest.store'
  import { eventsManager } from '../../events/event-manager'
  import { EventType } from '../../events/event-type'
  import TextInput from '../../lib/ui-kit/TextInput.svelte'
  import { OnboardingStore } from '../../stores/onboarding.store'
  import { OnboardingStep } from '../../enums/onboarding-steps'
  import { validateName } from '../../services/names-validation'
  import { capitalizeName } from '../../services/text-format'
  import mixpanel from 'mixpanel-browser'
  import OnboardingLayout from '$core/lib/onboarding/OnboardingLayout.svelte'

  /** Local variables */
  let isFirstNameValid: boolean
  let isLastNameValid: boolean
  let canContinue: boolean

  /**
   * Reactive functions
   * - capitalize the first and last name automatically
   * */
  $: $SignUpStoreFirstName = capitalizeName($SignUpStoreFirstName)
  $: $SignUpStoreLastName = capitalizeName($SignUpStoreLastName)

  /**
   * Calls the validation function for the first and last name
   * Make sure the user can continue only if both names are valid
   */
  $: {
    isFirstNameValid = validateName($SignUpStoreFirstName)
    isLastNameValid = validateName($SignUpStoreLastName)
    canContinue = isFirstNameValid && isLastNameValid
  }


  const onNextStep = () => {
    mixpanel.track('onb.str.A01.u onboardind start next step', {
      'Description': 'Click on next step button'
    })

    navigate('signup')
  }

  onMount(() => {
    mixpanel.track('onb.str.A00.i onboardind start load', {
      'Description': 'Open Start.svelte'
    })

    eventsManager.emit(EventType.ONBOARDING_STARTED, null, 'Start.svelte')

    $OnboardingStore.isOnboarding = true
    $OnboardingStore.currentOnboardingStep = OnboardingStep.START

    let searchParams = new URLSearchParams(window.location.search)

    const isTestEndToEndInURLQuery = searchParams!.has('testEndToEnd') &&
      (searchParams!.get('testEndToEnd')?.toLowerCase() === 'true' || false)
    const isFakeSelectedAccountIdPresentInURLQuery = searchParams.has('fakeSelectedAccountId')
    const isFakeSelectedAccountNamePresentInURLQuery = searchParams.has('fakeSelectedAccountName')
    const isFakeSelectedAccountBankNamePresentInURLQuery = searchParams.has('fakeSelectedAccountBankName')
    if ($isEndToEndTest || isTestEndToEndInURLQuery) {
      /* console.log('**********<< A TEST END TO END MODE *********') */
      $isEndToEndTest = true
      if (isFakeSelectedAccountIdPresentInURLQuery) {
        $fakeData.fakeSelectedAccountId = decodeURIComponent(searchParams.get('fakeSelectedAccountId'))
      }
      if (isFakeSelectedAccountNamePresentInURLQuery) {
        $fakeData.fakeSelectedAccountName = decodeURIComponent(searchParams.get('fakeSelectedAccountName'))
      }
      if (isFakeSelectedAccountBankNamePresentInURLQuery) {
        $fakeData.fakeSelectedAccountBankName = decodeURIComponent(searchParams.get('fakeSelectedAccountBankName'))
      }
    } else {
      /* console.log('--------------<< A NO test end to end mode --------------') */
      $isEndToEndTest = false
    }

    if (searchParams.has('firstName')) {
      $SignUpStoreFirstName = searchParams.get('firstName')
    }
    if (searchParams.has('lastName')) {
      $SignUpStoreLastName = searchParams.get('lastName')
    }
    if (searchParams.has('email')) {
      $SignUpStoreEmail = searchParams.get('email')
    }
  })
</script>
<OnboardingLayout>
  <div slot="onboarding-sidebar">
    <div class="flex flex-col align-center w-10/12 mx-auto">
      <span class="text-3xl block mt-16 font-semibold text-white">
        {t('onboarding.start.sidebar.title')}
      </span>
      <div class="flex flex-col w-full m-auto mt-4 text-white">
        <span class="text-base block mt-16 font-semibold">{t('onboarding.start.sidebar.subTitle')}</span>
        <div class="flex flex-col w-10/12 text">
          <div class="flex mt-6 items-center">
            <span class="rounded-full bg-aquamarine p-1 text-black mr-2">
              <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none">
                <path d="M7.75 12.75L10 15.25L16.25 8.75" stroke="currentColor" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </span>
            <span class="text-sm">{t('onboarding.start.sidebar.bullet1')}</span>
          </div>
          <div class="flex mt-6 items-center">
            <span class="rounded-full bg-aquamarine p-1 text-black mr-2">
              <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none">
                <path d="M7.75 12.75L10 15.25L16.25 8.75" stroke="currentColor" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </span>
            <span class="text-sm">{t('onboarding.start.sidebar.bullet2')}</span>
          </div>
          <div class="flex mt-6 items-center">
            <span class="rounded-full bg-aquamarine p-1 text-black mr-2">
              <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none">
                <path d="M7.75 12.75L10 15.25L16.25 8.75" stroke="currentColor" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </span>
            <span class="text-sm">{t('onboarding.start.sidebar.bullet3')}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div slot="onboarding-content">
    <section class="onboarding">
      <div>
        <h1>
          {t('onboarding.start.main.title')}
        </h1>

        <form
          class="flex justify-center flex-col mt-10 w-80 max-w-sm mx-auto"
          data-cy="onboarding-start-form"
          on:submit|preventDefault={() => onNextStep()}>

          <div class="flex justify-self-start">
            <span class="input-label">{t('onboarding.start.main.form.firstName')}</span>
            <span class="mandatory-marker">*</span>
          </div>
          <TextInput
            bind:value={$SignUpStoreFirstName}
            setFocus={true}
            class="w-full"
            dataCy="first-name"
            error={t('start.firstName.error')}
            placeholder={t('start.firstName.placeholder')}
            showError={$SignUpStoreFirstName.length > 0 && !isFirstNameValid}
          />
          <div class="flex justify-self-start mt-4">
            <span class="input-label">{t('onboarding.start.main.form.lastName')}</span>
            <span class="mandatory-marker">*</span>
          </div>
          <TextInput
            bind:value={$SignUpStoreLastName}
            class="w-full"
            dataCy="last-name"
            error={t('start.lastName.error')}
            placeholder={t('start.lastName.placeholder')}
            showError={$SignUpStoreLastName.length > 0 && !isLastNameValid}
          />

          <button type="submit" class="btn action-bla primary h-10 mt-10" disabled={!canContinue}>
            {t('onboarding.next')}
          </button>

          <span class="text-xs mt-16 text-baliHai"><sup> * </sup>{t('onboarding.start.main.form.mandatory')}</span>
        </form>
      </div>
    </section>
  </div>
</OnboardingLayout>
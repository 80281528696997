import { navigate } from 'svelte-routing'
import { get } from 'svelte/store'
import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
import type Invoice from '../../dundy-app/models/invoice'
import type { BusinessDocument } from '../../voxy-app/models/business-document'
import { BusinessDocumentStatus } from '../../voxy-app/enums/business-document-status'
import { InvoiceStatus } from '../../dundy-app/enums/invoice-status'
import { DocumentSourceKind } from '../../order-to-cash-lib/models/document-source-kind'


/**
 * Detect if the data is of type Invoice
 * @param data
 */
function isOfTypeInvoice(data: any): data is Invoice {
  return !!data && typeof data === 'object' && 'invoiceNumber' in data
}

/**
 * Detect if the data is of type BusinessDocument
 * @param data
 */
function isOfTypeBusinessDocument(data: any): data is BusinessDocument {
  return !!data && typeof data === 'object' && 'businessDocumentKind' in data
}

/**
 * Determine if document originates from Dundy Integration
 * @param data
 */
function isFromDundyIntegration(data: any): boolean {
  return data?.documentSource?.sourceKind === DocumentSourceKind.FROMDUNDYDUNNINGMANUALLY
}

/**
 * Determine if document originates from Voxy Integration
 * @param data
 */
function isFromVoxyIntegration(data: any): boolean {
  return data?.documentSource?.sourceKind === DocumentSourceKind.FROMVOXYINVOICING
}


/**
 * This function is used to show the proper page depending on the type of document and the clicked row
 *  https://www.ag-grid.com/javascript-data-grid/grid-events/#reference-selection-rowClicked
 *  https://www.ag-grid.com/javascript-data-grid/grid-interface/#grid-options
 *  console.log('A row was clicked');
 *  console.log('row id is', rowClickedEvent.rowIndex);
 *  console.log('row  is', rowClickedEvent.node);
 *  console.log('row data is', rowClickedEvent.node.data);
 *  console.log('row cols are', rowClickedEvent.columnApi.getAllColumns());
 *  console.log('selected cell is ', rowClickedEvent.api.getFocusedCell().column.getColId())
 *  for(let aCol of rowClickedEvent.columnApi.getAllColumns()){}
 * @param rowClickedEvent
 */
export const showProperEditOrViewOnRowClick = rowClickedEvent => {
  const clickedColumn = rowClickedEvent.api.getFocusedCell()
  const doNotDisplayInvoiceOnStatusColumn: boolean = clickedColumn.column.getColId() === 'status' || clickedColumn.column.getColId() === 'actions'

  if (!doNotDisplayInvoiceOnStatusColumn) {
    const data = rowClickedEvent.node.data
    /* console.log('rowClickedEvent.node.data', data) */

    if (isOfTypeInvoice(data)) {
      handleDundyDomainInvoiceRouting(data)
    } else if (isOfTypeBusinessDocument(data)) {
      handleVoxyDomainBusinessDocumentRouting(data)
    } else {
      /* console.log('row id', rowClickedEvent.rowIndex, 'no action here') */
    }
  }
}

/**
 * Dundy Domain Routing to show the proper page
 * depending on the type of document and the clicked row
 * @param invoice
 */
function handleDundyDomainInvoiceRouting(invoice: Invoice) {
  if (invoice.completed && invoice.isTracked && invoice.status === InvoiceStatus.OUTSTANDING) {
    navigate(`/action/${get(WorkspaceStore).workspaceId}/${invoice.toId}/${invoice.dataId}/overview`)
  } else if (!invoice.completed) {
    if (isFromDundyIntegration(invoice)) {
      navigate(`receivables/external/edit/${invoice.dataId}`)
    } else if (isFromVoxyIntegration(invoice)) {
      navigate(`receivables/edit/${invoice.dataId}/details`)
    }
  } else {
    if (isFromDundyIntegration(invoice)) {
      navigate(`receivables/external/view/${invoice.dataId}/details`)
    } else if (isFromVoxyIntegration(invoice)) {
      navigate(`receivables/view/${invoice.dataId}/details`)
    }
  }
}

/**
 * Voxy Domain Routing to show the proper page
 * depending on the type of document and the clicked row
 * @param businessDocument
 */
function handleVoxyDomainBusinessDocumentRouting(businessDocument: BusinessDocument) {
  if (businessDocument.businessDocumentStatus === BusinessDocumentStatus.FINAL) {
    navigate(`receivables/view/${businessDocument.businessDocumentId}/details`)
  } else {
    navigate(`receivables/edit/${businessDocument.businessDocumentId}/details`)
  }
}

<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import { TodoType } from '../../enums/todo-type'
    import type { TodoListItem } from '../../models/todo-list-item'
    import { AmountDecimal } from '../../../cash-application-app/models/amount-of-money-decimal-model'

    /** Exported Var **/
    export let classes: string
    export let todo: TodoListItem

    /** Let declarations **/
    let totalScopeAmountNormalized: any

    /** Reactive declarations **/
    $: totalScopeAmountNormalized = new Intl.NumberFormat(t('locales'), {
      style: 'currency',
      currency: todo?.totalScopeAmountOfMoneyInvoiced.currencyCode
    }).format(Number(AmountDecimal.PrototypeToClass(todo?.totalScopeAmountOfMoneyInvoiced).GetAmountDecimalScaledValue()))


</script>

<div class="flex flex-col none {classes}">
    <h6 class="font-medium text-[1.3em]">{totalScopeAmountNormalized}</h6>
    <p class="text-[0.75em] text-paleSky">
        {#if todo.kind === TodoType.CASH_APPLICATION}{t('todo.item.type.needApproval')}{/if}
        {#if todo.kind === TodoType.DUNNING && Math.sign(todo.maxDueDays) === -1}{t('todo.item.time.overdue', { count: Math.abs(todo.maxDueDays) })}{/if}
        {#if todo.kind === TodoType.DUNNING && Math.sign(todo.maxDueDays) === 0}{t('todo.item.time.due_today')}{/if}
        {#if todo.kind === TodoType.DUNNING && Math.sign(todo.maxDueDays) === 1}{t('todo.item.time.due', { count: Math.abs(todo.maxDueDays) })}{/if}
    </p>
</div>
<script lang="ts">
    import Invoice from '../../models/invoice'
    import InvoiceLifeCycleStatus from '../../../order-to-cash-lib/components/InvoiceLifeCycleStatus.svelte'
    import { DocumentSourceKind } from '../../../order-to-cash-lib/models/document-source-kind.js'
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import { formatCurrencyWithOptions } from '../../../voxy-app/utils/number-formatting-utils.js'

    /** Export let declarations */
    export let invoice: Invoice
</script>
<div data-ut-component="view-invoice-key-info">
    <div class="px-12 sm:px-8">
        <InvoiceLifeCycleStatus
                source={DocumentSourceKind.FROMDUNDYDUNNINGMANUALLY}
                invoiceId={invoice?.dataId}
        />
        <h1 class="mt-4 text-4xl font-bold">
            {formatCurrencyWithOptions(invoice?.amountIncludingTaxes, t('locales'), invoice?.currency, true, 'symbol')}
        </h1>
    </div>
</div>

<script lang="ts">
    // Reference: https://svelte.dev/repl/2b5a2cf5759d4e1b8e70d3f73df14851?version=3.37.0
    export let condition = false
</script>
{#if condition }
    <div class="grid-cols-3" {...$$restProps}>
        <div class="mt-4 ">
            <div class="space-y-4 space-x-3 items-start py-3">
                <slot/>
            </div>
        </div>
    </div>
{:else}
    <slot/>
{/if}
<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import type { Keyword, Keywords } from './template.model'
  import { stringNormalize } from '$src/shared/utils/string'

  export let keywords:Keywords = []
  export let tag:string = ''
  export let placeholder:string = ''
  
  const dispatch = createEventDispatcher()
  
  let search: string = ''

  $:listSorted = keywords.filter(k => k.tags?.includes(tag)).sort()
  $:list = search ? listSorted.filter(k => stringNormalize(k.value).includes(stringNormalize(search))) : listSorted

  const onMouseUp = (item:Keyword) => {
    dispatch('select', item)
  }
</script>


<div class="variable relative h-full pr-3 flex flex-col">
  <input
    type="text"
    class="text-base shadow-none rounded border border-loblolly py-[0.5rem] px-[0.75rem] w-full"
    {placeholder}
    bind:value={search}
  />

  <ul class="mt-2 overflow-y-auto overflow-x-hidden border border-loblolly rounded grow">
    {#each list as keyword}
      <li role="presentation" class="cursor-pointer whitespace-nowrap py-1 px-2 hover:bg-[#FDF0EE]" on:mouseup={() => onMouseUp(keyword)}>
        {keyword.value}
      </li>
    {/each}
  </ul>
</div>
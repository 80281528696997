import { get } from 'svelte/store'
import { WorkspaceStore } from '../../../crm-app/stores/workspace.store'
import { eventsManager } from '../../../core-app/events/event-manager'
import { EventType } from '../../../core-app/events/event-type'
import { APICallOptions, APIEntity, apiGet } from '../../../core-app/services/api.service'
import type {
  BusinessDocument,
  BusinessDocumentRelation
} from '../../models/business-document'
import {
  BusinessDocumentAllDataPersisted
} from '../../models/business-document'
import { BusinessDocumentRelationKind } from '../../enums/business-document-relation-kind'
import { CustomersStore } from '../../../crm-app/stores/customers.store'
import { ContactsStore } from '../../../crm-app/stores/contacts.store'
import type { DundyEvent } from '../../../dundy-app/events/dundy-event'
import { transformationDuplicateBusinessDocument } from '../../helpers/business-document-duplicate-pure-functions'
import { transformationVoidBusinessDocument } from '../business-document-voiding/business-document-voiding.service'
import { BusinessDocumentsAllDataPersistedStore } from '../../stores/business-documents.store'
import { deleteVoxyDraftInvoices } from '../business-document-api/business-document-repository.api'

class VoxyInvoicingServiceClass {
  initialized: boolean
  initialize(): void {
    if (this.initialized) return

    this.initialized = true
    this.fetchBusinessDocumentsAndRefreshStore()

    eventsManager.on(EventType.BUSINESS_DOCUMENTS_LIST_FETCHED, (e: DundyEvent<BusinessDocumentAllDataPersisted[]>): void => {
      /* console.log('#### changing BusinessDocumentsStore.set') */
      BusinessDocumentsAllDataPersistedStore.set(e.data)
    }, 'businessDocumentsService')

    eventsManager.on(EventType.BUSINESS_DOCUMENTS_LIST_CHANGED, (): void => {
      this.fetchBusinessDocumentsAndRefreshStore()
    }, 'voxyInvoicingService')

    // Listen for the BUSINESS_DOCUMENT_DELETED event
    eventsManager.on(EventType.BUSINESS_DOCUMENT_DELETED, (e: DundyEvent<string[]>): void => {
      // Delete the draft invoices for the business documents
      deleteVoxyDraftInvoices(e.data).then(() => {
        /* console.log(`Business documents with ids ${e.data.join(', ')} deleted successfully`) */
      }).catch((error) => {
        console.error(`Error deleting business documents with ids ${e.data.join(', ')}:`, error)
      })
    }, 'voxyInvoicingService')

  }
  /**
   * Triggers the fetch invoices event
   * @private
   */
  public fetchBusinessDocumentsAndRefreshStore(): void {
    this.fetchBusinessDocumentsAndCollateralDataList()
      .then((d: BusinessDocumentAllDataPersisted[]): void => {
        /* console.log('+++ about to triggerFetchEvents') */
        this.triggerFetchEvents(d)
      })
      .catch(error => {
        if (error.status === 404) {
          /* console.log('+++ status 404') */
          this.triggerFetchEvents(<BusinessDocumentAllDataPersisted[]>[])
        } else {
          /* console.error('+++ error when fetchBusinessDocumentsList() in business-documents.service.ts', error) */
        }
      })
  }
  /**
   * Duplicate a voxy invoice
   * @param businessDocumentAllDataPersisted
   */
  duplicateVoxyInvoice(businessDocumentAllDataPersisted: BusinessDocumentAllDataPersisted): Promise<BusinessDocumentAllDataPersisted> {
    return new Promise(async (resolve, reject): Promise<void> => {
      try {
        const duplicatedBusinessDocumentAllDataPersisted: BusinessDocumentAllDataPersisted = transformationDuplicateBusinessDocument(businessDocumentAllDataPersisted, get(WorkspaceStore), get(CustomersStore), get(ContactsStore))
        resolve(duplicatedBusinessDocumentAllDataPersisted)
      } catch (e) {
        reject(e)
      }
    })
  }
  /**
   * Void a voxy invoice
   * @param toBeVoidedBusinessDocumentAllDataPersisted
   */
  createVoxyCreditNoteThatVoidsAnInvoice(toBeVoidedBusinessDocumentAllDataPersisted: BusinessDocumentAllDataPersisted): Promise<BusinessDocumentAllDataPersisted> {
    return new Promise<BusinessDocumentAllDataPersisted>(async (resolve, reject): Promise<void> => {
      try {
        const voidedBusinessDocumentAllDataPersisted: BusinessDocumentAllDataPersisted = transformationVoidBusinessDocument(toBeVoidedBusinessDocumentAllDataPersisted, get(WorkspaceStore), get(CustomersStore), get(ContactsStore))
        resolve(voidedBusinessDocumentAllDataPersisted)
      } catch (e) {
        reject(e)
      }
    })
  }
  /**
   * Get the invoice number from the related business document where the relation kind is voiding
   * @param businessDocument
   * @returns {string}
   */
  getInvoiceNumberFromRelatedBusinessDocumentWhereRelationKindIsVoiding(businessDocument: BusinessDocument): string {
    const voidingBusinessDocument: BusinessDocumentRelation = businessDocument.relatedBusinessDocuments.find(
      (relatedBusinessDocument: BusinessDocumentRelation): boolean =>
        relatedBusinessDocument.relationKind === BusinessDocumentRelationKind.CREDIT_NOTE_ON_INVOICE,
    )

    return voidingBusinessDocument?.fromBusinessDocumentNumber || ''
  }

  // TODO remove
  // /**
  //    * Fetches the list of business Documents for the current workspace
  //    * @param workspaceId
  //    * @private
  //    */
  // private fetchBusinessDocumentsList(workspaceId?: string): Promise<CoreDocument[]> {
  //   return apiGet<CoreDocument[]>(`/workspaces/${workspaceId || get(WorkspaceStore).workspaceId}/business-documents`, <APICallOptions>{
  //     entity: APIEntity.VOXY_BUSINESS_DOCUMENTS,
  //     ignoreFeedback: true
  //   })
  // }

  /**
   * Fetches the list of documents and collateral data for the current workspace
   * @param workspaceId
   * @private
   */
  private fetchBusinessDocumentsAndCollateralDataList(workspaceId?: string): Promise<BusinessDocumentAllDataPersisted[]> {
    return apiGet<BusinessDocumentAllDataPersisted[]>(`/workspaces/${workspaceId || get(WorkspaceStore).workspaceId}/business-documents/with-collateral-data`, <APICallOptions>{
      entity: APIEntity.VOXY_BUSINESS_DOCUMENTS,
      ignoreFeedback: true
    })
  }

  /**
   * Triggers the fetch invoices event
   * @param response
   * @private
   */

  private triggerFetchEvents(response: BusinessDocumentAllDataPersisted[]): void {
    /* console.log('+++ triggerFetchEvents executing and emitting BUSINESS_DOCUMENTS_LIST_FETCHED') */
    /* console.log('important new business documents downloaded from back-end with ' + (response.length) + ' items') */
    eventsManager.emit(EventType.BUSINESS_DOCUMENTS_LIST_FETCHED, response || [], 'businessDocumentsService')
  }
}

export const voxyInvoicingService: VoxyInvoicingServiceClass = new VoxyInvoicingServiceClass()


/**
 * Function to detect clicks outside of a node
 * @param node
 */
export function clickOutside(node: HTMLElement): {
  destroy(): void;
} {
  const handleClick = (event: Event) => {
    if (node && !node.contains(event.target as Node) && !event.defaultPrevented) {
      node.dispatchEvent(new CustomEvent('click_outside', { detail: node }))
    }
  }

  document.addEventListener('click', handleClick, true)

  return {
    destroy() {
      document.removeEventListener('click', handleClick, true)
    }
  }
}

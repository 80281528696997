/**
 * Format a French IBAN with spaces
 * @param iban
 */
/*export function checkIfIBANisFRValidThenFormatWithSpaces(iban: string): string {
    // Check if the IBAN is valid
    if (!/^FR[0-9A-Z]{2}\d{10}[0-9A-Z]{11}(\d{2})?$/.test(iban)) {
        throw new Error("Invalid IBAN");
    }
    // Insert spaces every 4 characters
    return iban.slice(0, 4) + ' ' + iban.slice(4, 8) + ' ' + iban.slice(8, 12) + ' ' + iban.slice(12, 16) + ' ' + iban.slice(16, 20) + ' ' + iban.slice(20);
}*/
// French IBAN validation regex
/*
const FR_IBAN_REGEX = /^FR\d{2}\s?\d{5}\s?\d{5}\s?\w{11}\s?\d{2}$/;

// Function to check if an IBAN is valid and format it with spaces
export function checkIfIBANisFRValidThenFormatWithSpaces(iban: string): string {
    // Remove any spaces from the input string
    const formattedIban = iban.replace(/\s/g, '');

    // Check if the IBAN is valid
    if (!FR_IBAN_REGEX.test(formattedIban)) {
        throw new Error("Invalid French IBAN");
    }

    // Insert spaces every 4 characters
    return formattedIban.replace(/(.{4})(?!$)/g, '$1 ');
}
*/

/**
 * Remove spaces from an IBAN
 * Turns this "FR76 3000 6000 0112 3456 7890 189" into this "FR7630006000011234567890189"
 * @param iban
 */
function removeSpacesFromIBAN(iban: string): string {
  return iban.replace(/\s/g, '')
}

/**
 * Insert spaces into an IBAN
 * Turns this "FR7630006000011234567890189" into this "FR76 3000 6000 0112 3456 7890 189"
 * @param iban
 */
function insertSpacesIntoIBAN(iban: string): string {
  return iban.replace(/(.{4})(?!$)/g, '$1 ')
}

/**
 * Format an IBAN for display to user by removing spaces then inserting spaces
 * @param iban
 */
export function formatIBANForDisplayByRemovingSpacesThenInsertingSpaces(iban: string): string {
  return insertSpacesIntoIBAN(removeSpacesFromIBAN(iban))
}

/**
 * Checks if an IBAN is valid
 * TODO - This function is not working properly
 * @param iban
 */
export function isValidIBAN(iban: string): boolean {
  return true
  // Remove all white space and convert to uppercase
  /* let spacelessIBAN: string = removeSpacesFromIBAN(iban).toUpperCase();

    console.log("spacelessIBAN: " + spacelessIBAN);

    // Check if the IBAN has a valid format
    if (!/^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/.test(spacelessIBAN)) {
        return false;
    }

    // Move the first four characters to the end of the string
    let [firstFourChars, ...rest] = spacelessIBAN;
    let newIban = `${rest.join('')}${firstFourChars}`;

    // Replace each letter in the string with two digits, as per the scheme A=10, B=11, ..., Z=35
    let numericIban: string = '';
    for (let i = 0; i < newIban.length; i++) {
        let charCode = newIban.charCodeAt(i);
        if (charCode >= 65 && charCode <= 90) {
            numericIban += (charCode - 55).toString();
        } else {
            numericIban += newIban.charAt(i);
        }
    }

    // Calculate the remainder of dividing the IBAN by 97
    let remainder = 0;
    for (let i = 0; i < numericIban.length; i++) {
        remainder = (remainder * 10 + parseInt(numericIban.charAt(i))) % 97;
    }

    // The IBAN is valid if the remainder is 1
    return remainder === 1;*/
}


/**
 * Parse a French IBAN to display the parts of the IBAN:
 * - Bank code
 * - Branch code
 * - Account number
 * - Key
 * @param iban
 */
export function parseFrenchIBAN(iban: string): any {
  // Check if the IBAN is valid and starts with "FR"
  if (!/^FR[0-9A-Z]{2}[0-9]{10}[0-9A-Z]{11}\d{2}$/.test(iban)) {
    throw new Error('Invalid IBAN')
  }

  // Extract the parts of the IBAN
  let bankCode = iban.substring(4, 8)
  let branchCode = iban.substring(8, 11)
  let accountNumber = iban.substring(11, 21)
  let key = iban.substring(21, 23)

  // Return the parsed parts of the IBAN
  return {
    bankCode,
    branchCode,
    accountNumber,
    key
  }
}

<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import { createEventDispatcher, getContext, onDestroy, onMount } from 'svelte'
    import { type Writable, writable } from 'svelte/store'
    import { slide } from 'svelte/transition'
    import type { CashApplicationDeclaredEvent } from '../../../cash-application-app/models/cash-application-declared-model'
    import { formatCurrencyWithOptions } from '../../../voxy-app/utils/number-formatting-utils'
    import type Invoice from '../../../dundy-app/models/invoice'
    import type AccordionCtxType from '../../../core-app/lib/ui-kit/Accordion/Accordion.svelte'
    import { Amount, NewAmountOfMoney } from '../../../cash-application-app/models/amount-of-money-model'
    import { findCustomerNameFromId } from '../../../crm-app/services/retrieve-customer-info.pure-functions'
    import { get } from 'svelte/store'
    import { CustomersStore } from '../../../crm-app/stores/customers.store'
    import { findInvoiceFromId } from '../../../dundy-app/services/invoices.pure-functions'
    import { DunningInvoicesStore } from '../../../dundy-app/stores/dunning-invoices.store.js'

    /** Set Cash Application Declared Event */
    export let cashApplicationDeclaredEvent: CashApplicationDeclaredEvent

    export let activeUserSelection:string | number = 1

    /** Set Open */
    export let open: boolean = false

    /** Set Allocated Amount */
    let singleInvoiceAllocatedAmount: string

    /** Set User Input Percentage */
    let userInputPercentage: number

    /** Set Customer Name */
    let customerName: string

    /** Set invoice */
    let invoice: Invoice

    /** Set formatted invoice amount */
    let formattedInvoiceAmount: string

    /** Set formatted invoice date */
    let dateIssuedFormatted: string

    /** Set Dispatch */
    const dispatch = createEventDispatcher()

    /** Set Context*/
    const ctx = getContext<AccordionCtxType>('ctx') as AccordionCtxType ?? {} as AccordionCtxType

    /** Set Single Selection */
    const self = {}

    /** Set Selection Store */
    const selected: Writable<object> = ctx.selected ?? writable()

    /** Set ref Open */
    let _open: boolean = open

    /** Set Reactive Declarations */
    $: customerName = findCustomerNameFromId(get(CustomersStore), cashApplicationDeclaredEvent.customerId)
    $: invoice = findInvoiceFromId(get(DunningInvoicesStore), cashApplicationDeclaredEvent.cashApplication.invoiceDataId)
    $: formattedInvoiceAmount = formatCurrencyWithOptions(invoice?.amountIncludingTaxes, t('locales'), invoice?.currency, true)
    $: dateIssuedFormatted = invoice?.dateIssued
      ? new Intl.DateTimeFormat(t('locales'), {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }).format(new Date(invoice.dateIssued))
      : 'Invalid Date'
    $: singleInvoiceAllocatedAmount = formatCurrencyWithOptions(Amount.PrototypeToClass(cashApplicationDeclaredEvent?.cashApplication.appliedAmount).GetAmountScaledValue(), t('locales'), invoice?.currency, true)

    /**
     * Handle Open/Close Row
     * @param _
     */
    const handleToggle = (_: Event) => {
      if (open) {
        selected.set({})
      } else {
        selected.set(self)
      }
    }

    /**
     * Handle Manual Input of Applied Amount By User
     * forces the input to be a number with 2 decimal places
     * updates the percentage
     */
    const handleManualUserInput = () => {
      if (Number(cashApplicationDeclaredEvent.cashApplication.appliedAmount.scaledValue) > Number(invoice?.amountIncludingTaxes)) {
        singleInvoiceAllocatedAmount = invoice?.amountIncludingTaxes.toString()
        cashApplicationDeclaredEvent.cashApplication.appliedAmount.scaledValue = invoice?.amountIncludingTaxes
      }
      cashApplicationDeclaredEvent.cashApplication.appliedAmount = NewAmountOfMoney(Number(Amount.PrototypeToClass(cashApplicationDeclaredEvent.cashApplication.appliedAmount).GetAmountScaledValue().toFixed(2)), 2, cashApplicationDeclaredEvent.cashApplication.appliedAmount.currencyCode)
      userInputPercentage = calculatePercentageFromAppliedAmount(Amount.PrototypeToClass(cashApplicationDeclaredEvent.cashApplication.appliedAmount).GetAmountScaledValue(), invoice)
      updateAppliedAmountAccordingToNewFigure(Amount.PrototypeToClass(cashApplicationDeclaredEvent.cashApplication.appliedAmount).GetAmountScaledValue(), invoice?.currency)
    }

    function handleClick(activeValue: number) {
      activeUserSelection = activeValue
    }

    /**
     * Update the applied amount according to the chosen percentage
     * @param percentage
     */
    function updateAppliedAmountAccordingToChosenPercentage(percentage: number) {
      let adjustedPercentage = preventPercentageFromGoingAbove100(percentage)
      const invoiceAmount = invoice.amountIncludingTaxes
      const appliedAmount = Number(((invoiceAmount * adjustedPercentage / 100)).toFixed(2))
      updateAppliedAmountAccordingToNewFigure(appliedAmount, invoice.currency)
      userInputPercentage = calculatePercentageFromAppliedAmount(Amount.PrototypeToClass(cashApplicationDeclaredEvent.cashApplication.appliedAmount).GetAmountScaledValue(), invoice)
    }


    /**
     * Calculate the percentage from the applied amount
     * @param appliedAmount
     * @param invoice
     */
    function calculatePercentageFromAppliedAmount(appliedAmount: number, invoice: Invoice): number {
      return Number(((appliedAmount / invoice.amountIncludingTaxes) * 100).toFixed(2))
    }

    /**
     * Prevent the percentage from going above 100
     * @param selectedPercentage
     */
    function preventPercentageFromGoingAbove100(selectedPercentage: number) {
      if (selectedPercentage > 100) {
        return 100
      } else {
        return selectedPercentage
      }
    }

    /**
     * Update the applied amount according to the new figure
     * Dispatch the update event to the Modal
     * @param appliedAmount
     * @param currencyCode
     */
    function updateAppliedAmountAccordingToNewFigure(appliedAmount: number, currencyCode: string) {
      cashApplicationDeclaredEvent.cashApplication.appliedAmount = NewAmountOfMoney(appliedAmount, 2, currencyCode)
      dispatch('updateAppliedAmount', cashApplicationDeclaredEvent)
    }

    onMount(() => {
      updateAppliedAmountAccordingToNewFigure(Amount.PrototypeToClass(cashApplicationDeclaredEvent.cashApplication.appliedAmount).GetAmountScaledValue(), invoice?.currency)
      userInputPercentage = calculatePercentageFromAppliedAmount(Amount.PrototypeToClass(cashApplicationDeclaredEvent.cashApplication.appliedAmount).GetAmountScaledValue(), invoice)

      if (_open) {
        $selected = self
      }
      // this will trigger unsubscribe on destroy
      // NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
      return selected.subscribe((x) => (open = x === self))
    })

    onDestroy(() => {
      /* console.log('onDestroy') */
      // dispatch('updateAppliedAmount', cashApplicationDeclaredEvent);
    },
    )

</script>

<div class="relative w-full shadow-md rounded-md border border-athensGray my-1"
     data-ut-component="cash-applied-invoices-row">
    <div on:click={handleToggle} class="cursor-pointer">
        <div class="w-full flex items-center px-4 py-4">
            <div class="flex flex-wrap content-center justify-center w-8 h-8 bg-black text-white rounded-md border border-solid border-black mr-3">
                <span class="text-xs">{customerName?.substring(0, 1).toUpperCase()}</span>
            </div>
            <div class="min-w-0 flex-1 text-sm">
                <div class="flex items-center justify-between">
                    <p class="font-medium text-gray-700 truncate">{customerName}</p>
                    <p class="truncate">{formattedInvoiceAmount}</p>
                </div>
                <div class="flex items-center justify-between">
                    <p class="text-gray-500 truncate">{invoice?.invoiceNumber}</p>
                    <p class="text-gray-500">{dateIssuedFormatted}</p>
                </div>
            </div>
        </div>
        <div class="w-full flex items-center justify-between px-4 py-2">
            <div class="flex space-x-3">
                <p class="text-xs text-zinc-500">{t('cashApplication.allocatedAmount')}</p>
                <p class="text-xs">{singleInvoiceAllocatedAmount}</p>
            </div>
            <div class="flex items-center cursor-pointer">
                <p class="text-xs">{t('cashApplication.controls.edit')}</p>
                <i class="icon-chevron-down cash-application-row__arrow"
                   class:rotate-180={open}
                   aria-label="Expand/collapse"></i>
            </div>
        </div>
    </div>
    {#if open}
        <div class="w-full border-t border-athensGray py-4 px-6" transition:slide|local={{ duration: 400 }}>
            <div class="flex items-center">
                <div class="flex space-x-1 rounded bg-whisper p-1 w-fit mx-auto"
                     role="tablist"
                     aria-orientation="horizontal">
                    <button
                            on:click|preventDefault={() => handleClick(1)}
                            class="flex items-center rounded-md py-[0.4375rem] px-4 text-s font-medium"
                            class:button-selected={activeUserSelection === 1}
                            role="tab"
                            type="button"
                            aria-selected={activeUserSelection === 1}
                            tabindex="-1">
                        <span class="sr-only lg:not-sr-only text-baliHai"
                              class:text-black={activeUserSelection === 1}>%</span>
                    </button>
                    <button
                            on:click|preventDefault={() => handleClick(2)}
                            class="flex items-center rounded-md py-[0.4375rem] px-4 text-sm font-medium"
                            class:button-selected={activeUserSelection === 2}
                            role="tab"
                            type="button"
                            aria-selected={activeUserSelection === 2 }
                            tabindex="0">
                        <span class="sr-only lg:not-sr-only text-baliHai"
                              class:text-black={activeUserSelection === 2}>EUR</span>
                    </button>
                </div>
                <div class="flex flex-1 px-1 py-1 flex-col pr-1">
                    {#if activeUserSelection === 1}
                        <div class="relative rounded-md">
                            <input
                                    bind:value={userInputPercentage}
                                    on:input={() => updateAppliedAmountAccordingToChosenPercentage(userInputPercentage)}
                                    id='amount-discount-percent'
                                    type="number"
                                    min="1"
                                    max="100"
                                    step="0.01"
                                    placeholder="0%"
                                    aria-describedby="amount-discount-percent"
                                    class="invoice-input-prefix"/>
                            <div class="invoice-input-postfix-wrapper">
                                <span class="text-black text-xxs w-full">{t('invoices.createInvoice.discountPercentageValueLabel')}</span>
                            </div>
                        </div>
                    {/if}
                    {#if activeUserSelection === 2}
                        <div class="relative rounded-md">
                            <input
                                    bind:value={cashApplicationDeclaredEvent.cashApplication.appliedAmount.scaledValue}
                                    on:input={() => handleManualUserInput()}
                                    id='amount-discount-numerary'
                                    type="number"
                                    placeholder="0,00"
                                    aria-describedby="amount-discount-numerary"
                                    class="invoice-input-prefix "/>
                            <div class="invoice-input-postfix-wrapper">
                                <span class="text-black text-xxs w-full">{t('invoices.createInvoice.discountEurValueLabel')}</span>
                            </div>
                        </div>
                    {/if}
                </div>
            </div>

            <div class="w-full flex items-center justify-center">
                <fieldset class="mt-4">
                    <legend class="sr-only">Choose a percentage</legend>
                    <div class="grid grid-cols-3 gap-3 sm:grid-cols-5">
                        <label on:click={() => updateAppliedAmountAccordingToChosenPercentage(10)}
                               class:selected-percentage={userInputPercentage === 10}
                               class="default-percentage">
                            <input type="radio"
                                   name="percentage-choice"
                                   value="10"
                                   class="sr-only"
                                   aria-labelledby="percentage-choice-0-label">
                            <span id="percentage-choice-0-label">10%</span>
                        </label>

                        <label on:click={() => updateAppliedAmountAccordingToChosenPercentage(25)}
                               class:selected-percentage={userInputPercentage === 25}
                               class="default-percentage">
                            <input type="radio"
                                   name="percentage-choice"
                                   value="25"
                                   class="sr-only"
                                   aria-labelledby="percentage-choice-1-label">
                            <span id="percentage-choice-1-label">25%</span>
                        </label>


                        <label on:click={() => updateAppliedAmountAccordingToChosenPercentage(50)}
                               class:selected-percentage={userInputPercentage === 50}
                               class="default-percentage">
                            <input type="radio"
                                   name="percentage-choice"
                                   value="S"
                                   class="sr-only"
                                   aria-labelledby="percentage-choice-2-label">
                            <span id="percentage-choice-2-label">50%</span>
                        </label>

                        <label on:click={() => updateAppliedAmountAccordingToChosenPercentage(75)}
                               class:selected-percentage={userInputPercentage === 75}
                               class="default-percentage">
                            <input type="radio"
                                   name="percentage-choice"
                                   value="75"
                                   class="sr-only"
                                   aria-labelledby="percentage-choice-3-label">
                            <span id="percentage-choice-3-label">75%</span>
                        </label>

                        <label on:click={() => updateAppliedAmountAccordingToChosenPercentage(100)}
                               class:selected-percentage={userInputPercentage === 100}
                               class="default-percentage">
                            <input type="radio"
                                   name="percentage-choice"
                                   value="100"
                                   class="sr-only"
                                   aria-labelledby="percentage-choice-4-label">
                            <span id="percentage-choice-4-label">100%</span>
                        </label>
                    </div>
                </fieldset>
            </div>

        </div>
    {/if}


    <!--<div class="ml-3 flex h-5 items-center"
         on:click={(e) => dispatch('removeAppliedInvoice', e)}>
        <Tooltip>
            <div slot="activator" class="h-5">
                <button on:click={() => {}}>
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="1.5" d="M17.25 6.75L6.75 17.25"></path>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="1.5" d="M6.75 6.75L17.25 17.25"></path>
                    </svg>

                </button>
            </div>
            {t('cashApplication.cashApplicationDeclaredRow.removeLink')}
        </Tooltip>
    </div>-->
</div>
<style lang="postcss">
    .invoice-input-postfix-wrapper {
        @apply pointer-events-none absolute right-0 top-0 flex items-center px-2.5 overflow-hidden border-l border-athensGray w-10 text-center;
        border-radius: 0 5px 5px 0;
        background: #e1e1;
        height: 42px;
        line-height: 42px;
    }

    .invoice-input-prefix {
        @apply w-full border border-athensGray box-border bg-white focus:ring-2 focus:ring-dundyOrange focus:border-transparent pl-12 pr-12;
        border-radius: 5px;
        height: 42px;
    }

    .button-selected {
        @apply bg-white shadow;
    }

    .default-percentage {
        @apply border border-transparent rounded-full py-3 px-3 flex items-center justify-center text-sm bg-whisper font-medium uppercase sm:flex-1 cursor-pointer focus:outline-none hover:bg-black hover:text-white;
    }

    .selected-percentage {
        @apply bg-black text-white;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
        -webkit-appearance: textfield;
        appearance: textfield;
    }

    .cash-application-row__arrow {
        @apply text-3xl text-black;
        transition: all .11s cubic-bezier(.2, 0, .38, .9);
    }
</style>

<script lang="ts">
  import { scale } from 'svelte/transition'
  import {
    NewWorkspaceRequestedByUserWorkspaceId,
    WorkspaceListStore,
    WorkspaceStore
  } from '$crm/stores/workspace.store'
  import { t } from '../i18n/i18nextWrapper.js'
  import { onMount } from 'svelte'
  import { navigate } from 'svelte-routing'
  import type { Workspace } from '$crm/models/workspace'
  import { ProfileStore } from '../../stores/profile.store.js'
  import { workspacesService } from '$crm/services/workspace.service'
  import { isUserWsOwner } from '../../services/guard.js'
  import { get } from 'svelte/store'

  const prodEnv = process.env.APP_ENV === 'prod remote'

  /** Set menu state */
  let open: boolean = false

  /** Set menu wrapper DOM ref */
  let menu: HTMLElement

  /** Var declarations **/
  let workspaces: Workspace[] = []

  /** Adapt text size based on label length */
  $: textSizeClass = $WorkspaceStore?.company?.usualName && $WorkspaceStore.company.usualName.length > 14 ? 'text-xs' : 'text-sm'

  function updateWorkspaceList() {
    const workspaceList = get(WorkspaceListStore)
    workspaces = workspaceList.filter(workspace => workspace.workspaceId !== $NewWorkspaceRequestedByUserWorkspaceId)
  }

  const handleSelectWorkspace = (workspace: Workspace) => {
    workspacesService.changeToSelectedWorkspace(workspace.workspaceId, '/today', true, 'workspaces.selectSuccess')
  }

  onMount(() => {
    /** Subscribe to changes in the WorkspaceListStore */
    const unsubscribeWorkspaceList = WorkspaceListStore.subscribe(updateWorkspaceList)
    /** Subscribe to workspace switch */
    const unsubscribeNewWorkspaceId = NewWorkspaceRequestedByUserWorkspaceId.subscribe(updateWorkspaceList)

    const mediaListener = window.matchMedia('(max-width: 767px)')
    const mediaQueryHandler = (e: MediaQueryListEvent) => {
      if (!e.matches) {
        open = false
      }
    }
    mediaListener.addEventListener('change', mediaQueryHandler)

    const handleOutsideClick = (event: MouseEvent) => {
      if (open && menu && !menu.contains(event.target as Node)) {
        open = false
      }
    }

    const handleEscape = (event: KeyboardEvent) => {
      if (open && event.key === 'Escape') {
        open = false
      }
    }

    document.addEventListener('click', handleOutsideClick, false)
    document.addEventListener('keyup', handleEscape, false)

    return () => {
      unsubscribeWorkspaceList()
      unsubscribeNewWorkspaceId()
      mediaListener.removeEventListener('change', mediaQueryHandler)
      document.removeEventListener('click', handleOutsideClick, false)
      document.removeEventListener('keyup', handleEscape, false)
    }
  })

</script>
<div bind:this={menu} class="relative w-full">
  <button aria-expanded="true"
          aria-haspopup="true"
          class="group user-dropdown__button"
          id="menu-button" on:click={() => open = !open} type="button">
        <span class="flex items-center justify-between">
            <span class="flex items-center w-full">
                <span>
                {#if $WorkspaceStore?.company?.emailLogoURL }
                    <img class="inline-block h-7 w-7 rounded-md"
                         src={$WorkspaceStore?.company?.emailLogoURL}
                         alt="{$WorkspaceStore?.company?.formalName}"/>
                {:else}
                    <div
                      class="main-label-initials flex flex-wrap content-center justify-center h-7 w-7 bg-zinc-700 text-white rounded-md">
                          <span>{$WorkspaceStore?.company?.formalName?.substring(0, 1).toUpperCase()}</span>
                    </div>
                {/if}
            </span>
              <span class="ml-3 truncate w-36 text-left">
                <span class={textSizeClass + ' font-medium text-white truncate'}
                      data-cy="sidebar-workspace-selector-selected-workspace-company">
                      {$WorkspaceStore?.company?.formalName}
                    </span>
              </span>
            </span>

            <span class="ml-3">
                <svg aria-hidden="true" class="h-5 w-5 text-zinc-600" fill="currentColor"
                     viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clip-rule="evenodd"
                          d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                          fill-rule="evenodd"/>
                </svg>
            </span>
        </span>
  </button>

  {#if open}
    <div in:scale={{ duration: 100, start: 0.95 }}
         out:scale={{ duration: 75, start: 0.95 }}
         class="absolute left-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
         role="menu"
         aria-orientation="vertical"
         aria-labelledby="menu-button"
         tabindex="-1">
      <div class="px-4 py-3 flex items-center" role="none">
                <span>
                    {#if $WorkspaceStore?.company?.emailLogoURL }
                        <img class="inline-block h-7 w-7 rounded-md mr-3 border border-zinc-300"
                             src={$WorkspaceStore?.company?.emailLogoURL}
                             alt="{$WorkspaceStore?.company?.formalName}"/>
                    {:else}
                        <div
                          class="main-label-initials flex flex-wrap content-center justify-center h-7 w-7 bg-zinc-700 text-white rounded-md mr-3">
                            <span>{$WorkspaceStore?.company?.formalName?.substring(0, 1).toUpperCase()}</span>
                        </div>
                    {/if}
                </span>
        <span class="ml-1.5 flex flex-col truncate">
                    <span class="text-sm font-medium text-black truncate">{$WorkspaceStore?.company?.formalName}</span>
                    <span class="text-xxs text-zinc-600">{$ProfileStore?.firstName}
                      <span
                        class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xxs font-medium text-gray-800 ml-4">
                            {isUserWsOwner() ? t('userRoles.owner') : t('userRoles.member') }
                        </span>
                    </span>
                </span>
      </div>
      <div class="py-1" role="none">
        {#each workspaces as workspace}
          <!-- eslint-disable-next-line -->
          <div class="hover:bg-whisper cursor-pointer"
               role="menuitem"
               on:click={() => { open = false; handleSelectWorkspace(workspace) }}>
                        <span class="flex items-center px-4 py-2">
                            <span>
                                {#if workspace?.company?.emailLogoURL }
                                    <img class="inline-block h-7 w-7 rounded-md mr-3"
                                         src={workspace?.company?.emailLogoURL}
                                         alt="{workspace?.company?.formalName}"/>
                                {:else}
                                    <div
                                      class="main-label-initials flex flex-wrap content-center justify-center h-7 w-7 bg-zinc-700 text-white rounded-md mr-3">
                                          <span>{workspace?.company?.formalName.substring(0, 1).toUpperCase()}</span>
                                    </div>
                                {/if}
                            </span>
                            <span class="ml-1.5 flex flex-col truncate">
                                <span
                                  class="text-sm font-medium text-black truncate">{workspace?.company?.formalName}</span>
                                <span
                                  class="text-xxs text-zinc-600">{t('topMenu.userNav.teamMember', { count: workspace?.teamUsersIds?.length + 1 })}</span>
                            </span>
                        </span>
          </div>
        {/each}
      </div>
      {#if !prodEnv}
        <div class="py-1 cursor-pointer" role="none">
                  <span
                    class="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left rounded hover:bg-whisper"
                    role="menuitem" tabindex="3"
                    on:click={() =>{ open = false; navigate('/workspaces') }}>{t('topMenu.userNav.manageWorkspaces')}</span>
        </div>
      {/if}
    </div>
  {/if}
</div>
<style lang="postcss">
  .user-dropdown__button {
    @apply block w-full flex-shrink-0 mt-5 rounded-md py-2 px-3 border-transparent bg-transparent hover:bg-rhino border-rhino;
    border-width: 1px !important;
  }

  .user-dropdown__button:hover svg {
    @apply text-white;
  }
</style>

<script lang="ts">
  import {ContactsStore} from "$crm/stores/contacts.store"
  import TasksDisplay from "$core/lib/dashboard/tasks/TasksDisplay.svelte"
  import SetupDisplay from "$core/lib/dashboard/tasks/SetupDisplay.svelte"
  import {derived, type Readable, writable} from "svelte/store"
  import {SimpleDocumentsStore} from "$voxy/stores/business-documents.store"

  export let isBankConfigLoaded: boolean = false

  let isBankConfigLoadedStore = writable(false)

  $: isBankConfigLoadedStore.set(isBankConfigLoaded)

  // Derived store to determine if setup is complete
  const isSetupComplete: Readable<boolean> = derived(
    [isBankConfigLoadedStore, ContactsStore, SimpleDocumentsStore],
    () =>
      $isBankConfigLoadedStore &&
      !!$ContactsStore && $ContactsStore.length > 0 &&
      !!$SimpleDocumentsStore && $SimpleDocumentsStore.length > 0
  )
</script>

<div class="main-todo-module">
  {#if $isSetupComplete}
    <TasksDisplay />
  {:else}
    <SetupDisplay {isBankConfigLoaded} />
  {/if}
</div>

<style lang="postcss">
  .main-todo-module {
    @apply bg-black h-full;
    border-radius: 20px;
  }
</style>
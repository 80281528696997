export function extractCountryNamePurely(countryTranslationWithFlag: string): string {
  // countryTranslationWithFlag is like '<span class="mr-4 iti__flag iti__je"></span> <span>Jersey</span>'
  const parts = countryTranslationWithFlag.split('</span> <span>')
  if (parts.length !== 2) {
    return 'error in \'' + countryTranslationWithFlag + '\''
  }
  const pureCountryName = parts[1].replace('</span>', '')

  /* console.log("converted html country", countryTranslationWithFlag, " into ", pureCountryName); */

  return pureCountryName
}

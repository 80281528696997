<script lang="ts">
  import { eventsManager } from '../../../core-app/events/event-manager.js'
  import { EventType } from '../../../core-app/events/event-type.js'
  import { ComputedDataInvoicesStore } from '../../stores/computed-data-invoice.store'
  import Loader from '../../../core-app/lib/ui-kit/Loader.svelte'
  import { DunningInvoicesStore } from '../../stores/dunning-invoices.store'
  import Invoice from '../../models/invoice'
  import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
  import type ComputedDataInvoice from '../../models/computed-data-invoice'
  import { get } from 'svelte/store'
  import { invoiceAttachmentDownload } from '../../services/dundy-file-mgt/dundy-pdf-attachment.api'
  import { WorkspaceStore } from '../../../crm-app/stores/workspace.store'
  import UploadArea from './UploadArea.svelte'
  import { pdfAttachmentUpload } from '../../../file-mgt-domain/services/pdf-attachment-upload.api'
  import { dunningInvoicesService } from '../../services/dunning-invoices.service'
  import { onMount } from 'svelte'

  export let invoiceId: string

    /** Let & Const declarations **/
    let windowHeight: number
    let currentInvoiceAttachment: any = null
    let isPDFFileLoading: boolean = false
    let invoice: Invoice = null

    function doesCurrentInvoiceHaveAttachment(): boolean {
      const invoicesStoreContent: ComputedDataInvoice[] = get(ComputedDataInvoicesStore)
      if (!!invoicesStoreContent) {
        const foundInvoice = invoicesStoreContent.find(computedInvoice => invoice && computedInvoice.invoiceNumber === invoice.invoiceNumber)

        return !!foundInvoice?.invoiceMetadata?.businessDocumentAttachmentMetadata?.fileName
      }

      return false
    }

    async function downloadInvoicePDF() {
      currentInvoiceAttachment = null
      isPDFFileLoading = true
      invoiceAttachmentDownload(invoice, get(WorkspaceStore).workspaceId)
        .then((attachment) => {
          setTimeout(() => {
            if (attachment) {
              currentInvoiceAttachment = attachment
              isPDFFileLoading = false
            }
          }, 1000)
        })
        .catch(() => {
          currentInvoiceAttachment = null
          isPDFFileLoading = false
        })
    }

    /** Reactive declarations **/
    $: {
      invoice = $DunningInvoicesStore.find((storedInvoice) => storedInvoice.dataId === invoiceId)

      if (invoice && invoiceId && doesCurrentInvoiceHaveAttachment()) {
        downloadInvoicePDF()
      }
    }

    const handleFilesUpload = e => {
      pdfAttachmentUpload(invoice.invoiceNumber, invoice.dataId, get(WorkspaceStore).workspaceId, invoice.completed, e.detail[0]?.originalFile)
        .then(() => {
          setTimeout(() => {
            downloadInvoicePDF().then(() => {
              eventsManager.emit(EventType.REQUEST_INVOICE_LIST_AND_COMPUTED_INVOICE_LIST_TO_REFRESH, null,
                'DundyInvoicePDFUploadArea.svelte')
              eventsManager.emit(EventType.INVOICE_UPLOADED, null, 'DundyInvoicePDFPDFViewer.svelte')
              dunningInvoicesService.fetchInvoicesForWorkspace(get(WorkspaceStore).workspaceId)
            })
          }, 2000)
        })
    }

    onMount(() => {
      dunningInvoicesService.fetchInvoicesForWorkspace(get(WorkspaceStore).workspaceId).then(() => {
        eventsManager.emit(EventType.REQUEST_INVOICE_LIST_AND_COMPUTED_INVOICE_LIST_TO_REFRESH, null,
          'DundyInvoicePDFPDFViewer.svelte')
      })
    })

</script>

<svelte:window bind:innerHeight={windowHeight}/>

<div data-ut-component="pdf-viewer">
    {#if isPDFFileLoading || currentInvoiceAttachment}
        <div class="relative">
            <div class="pdf-container">
                {#if isPDFFileLoading}
                    <div class="grid h-screen place-items-center w-full z-[100]">
                        <Loader/>
                    </div>
                {/if}
                {#if currentInvoiceAttachment && currentInvoiceAttachment.contentBase64}
                    <object width="100%" height={windowHeight} type="application/pdf"
                        data="data:application/pdf;base64,{currentInvoiceAttachment.contentBase64}#toolbar=0"
                        aria-label="Invoice PDF">
                      <p>{t('invoices.viewInvoice.noAttachment')}</p>
                    </object>
                {/if}
            </div>
        </div>
    {:else if !currentInvoiceAttachment && !isPDFFileLoading}
        <div class="grid h-screen place-items-center w-5/12 justify-center items-center mx-auto z-[100]">
          <UploadArea on:uploaded={handleFilesUpload}/>
        </div>
    {/if}
</div>

<style lang="postcss">
    .pdf-container {
        @apply w-full h-auto;
    }
</style>
<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte'
  import Tooltip from './TextInputTooltip.svelte'

  const dispatch = createEventDispatcher()

  /**
   * Specify the input value
   * @type {number | string}
   */
  export let value: number | string = ''

  /** Specify a name for the cypress selector */
  export let dataCy: string = ''

  /** Specify the label text */
  export let label: string = ''
  export let labelBold: boolean = false

  let input:HTMLInputElement
  export let setFocus:boolean = false 

  /** Helpers to set classes from component */
  let propClass = ''
  // @ts-ignore
  export { propClass as class }
  export let inputClass = ''
  export let inputStyle = ''
  export let min = null

  /**
   * Specify the input type
   * @type {'text'}
   */
  export let type: string = 'text'

  export let pattern: string = null

  /** Display Validation Tick */
  export let showValidationTick = true

  /** Specify the placeholder text */
  export let placeholder = ''

  /** Set to `true` to mark the field as required */
  export let required = false

  /** Specify the invalid state text */
  export let error: string = ''

  /** Set to `true` to indicate an invalid state */
  export let showError: boolean = false
  export let showErrorIcon: boolean = false

  /** Set to `true` to show Prefix */
  export let showPrefix: boolean = false

  /** Set Prefix Text */
  export let prefixLabel: string = ''

  /**
   * Specify a name attribute for the input
   * @type {string}
   */
  export let name: any = undefined

  /** Set to `true` to disable the input */
  export let disabled: boolean = false

  /** Set an id for the input element */
  export let id: string = 'ccs-' + Math.random().toString(36)

  /** Set to `true` to show tooltip */
  export let displayTooltipOnFocus: boolean = false

  /** Helper to set the tooltip position */
  export let tooltipWindowInjection:boolean = false
  export let tooltipPosition: 'left' | 'right' = 'left'

  /** Helper to set the tooltip text */
  export let displayTooltipAlways: boolean = false

  export let transform: 'uppercase' | 'lowercase' | 'capitalize' | null = null
  export let autocomplete: 'on' | 'off' = 'on'
  export let hideSpin: boolean = false

  /** Use Actions **/
  function typeAction(node: HTMLInputElement) {
    node.type = type

    if (min) node.min = min
  }

  /** Reactive error id */
  $: errorId = `error-${id}`

  const onInput = (e): void => {
    // transform
    switch (transform) {
      case 'capitalize':
        e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1).toLowerCase()
        break
      case 'lowercase':
        e.target.value = e.target.value.toLowerCase()
        break
      case 'uppercase':
        e.target.value = e.target.value.toUpperCase()
        break
    }

    dispatch('input', value)
  }

  const onKeyPress = (e): void => {
    // pattern
    if (pattern) {
      try {
        const rx = new RegExp(pattern)
        if (!rx.test(e.key)) {
          e.preventDefault()
          
          return
        }
      } catch (err) {
        console.error(err)
        
        return
      }
    }

    if (e.key === 'Enter') dispatch('enter', value)
  }

  onMount(() => {
    if (setFocus) input.focus()
  })
</script>

<fieldset class={propClass}>
    <label class={`block text-sm text-black ${labelBold ? 'font-semibold' : 'font-normal'}`}
           for={id}>{label}</label>
    <div class="mt-1.5 relative rounded-md shadow-sm {showPrefix ? 'prefix' : ''}">
        {#if showPrefix}
            <div class="invoice-input-prefix-wrapper">
                <span class="text-black text-xxs">{prefixLabel}</span>
            </div>
        {/if}
        <input aria-describedby="{showError ? errorId : undefined}"
          aria-invalid="{showError || undefined}"
          data-cy="{dataCy}"
          data-invalid="{showError || undefined}"
          bind:value
          bind:this={input}
          id="{id}"
          style={inputStyle}
          class={inputClass}
          class:hide-spin={hideSpin}
          class:error={error && (showError || showErrorIcon)}
          {disabled}
          {name}
          {autocomplete}
          {placeholder}
          {required}
          on:blur={e => {
            displayTooltipAlways = false
            dispatch('blur', e)

            return true
          }}
          on:change={() => dispatch('change', value)}
          on:focus={e => {
            if (displayTooltipOnFocus) displayTooltipAlways = true
            dispatch('focus', e)

            return true
          }}
          on:input={e => onInput(e)}
          on:keypress={e => onKeyPress(e)}
          use:typeAction
        />
        {#if showError || showErrorIcon}
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                    <path d="m9.983.746.144.135 4.992 4.992a3.008 3.008 0 0 1 .135 4.11l-.135.144-4.992 4.992-.144.135a3.008 3.008 0 0 1-3.966 0l-.144-.135-4.992-4.992-.135-.144a3.008 3.008 0 0 1 0-3.966l.135-.144L5.873.881l.144-.135a3.008 3.008 0 0 1 3.966 0ZM7.048 1.838l-.114.104-4.992 4.992-.104.114c-.45.553-.45 1.351 0 1.904l.104.114 4.992 4.992a1.508 1.508 0 0 0 2.018.104l.114-.104 4.992-4.992.104-.114c.45-.553.45-1.351 0-1.904l-.104-.114-4.992-4.992-.114-.104a1.508 1.508 0 0 0-1.904 0Zm.962 8.412.102.007a.75.75 0 0 1 0 1.486l-.102.007H8l-.102-.007a.75.75 0 0 1 0-1.486L8 10.25h.01ZM8 4a.75.75 0 0 1 .743.648l.007.102v3.5a.75.75 0 0 1-1.493.102L7.25 8.25v-3.5A.75.75 0 0 1 8 4Z"
                          fill="#DE496D" fill-rule="nonzero"/>
                </svg>
            </div>
        {:else if showValidationTick && value && !showError}
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <div class="rounded-full w-4 h-4 bg-cruise p-0">
                    <i class="icon-done text-xl relative right-0.5 bottom-0.5"></i>
                </div>
            </div>
        {/if}
        <slot name="icon"/>
    </div>
    {#if showError}
        <p class="mt-2 text-sm text-cabaret" id="{name}-error">{error}</p>
    {/if}

    {#if displayTooltipAlways}
        <Tooltip position={tooltipPosition} windowInject={tooltipWindowInjection}>
            <slot name="tooltip"></slot>
        </Tooltip>
    {/if}

    <slot/>
</fieldset>

<style lang="postcss">
    fieldset {
        @apply flex flex-col relative;
    }

    .prefix input {
        @apply pl-12 overflow-hidden;
        height: 40px;
    }

    input {
        padding: 0.5rem 0.75rem;
        @apply w-full rounded sm:text-sm shadow-none border-loblolly;

        &.error {
          @apply ring-2 ring-cabaret border-transparent;
        }
    }

    input:focus {
        @apply ring-2 ring-dundyOrange outline-none;
        border: solid 1px transparent !important;
    }

    input:disabled {
        @apply bg-whisper text-paleSky;
    }

    .input-date-picker {
        @apply rounded !important;
        @apply border-loblolly text-black;
        height: 100%;
        width: 100%;
    }

    .input-date-picker:focus {
        @apply ring-1 ring-red-400 outline-none;
        border-color: var(--primary-color);
    }

    .invoice-input-prefix-wrapper {
        @apply pointer-events-none absolute left-0 top-0 flex items-center px-2.5 overflow-hidden border-r border-loblolly h-full;
        border-radius: 5px 0 0 5px;
        background: #e1e1;
    }

    .hide-spin {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }
      -moz-appearance: textfield;
    }
</style>

import type ComputedDataInvoice from '../../dundy-app/models/computed-data-invoice'

/**
 * Returns the number of days due for a given invoice number
 * @param invoiceNumber
 * @param computedDataInvoicesStore
 */
export function calculateDaysDue(invoiceNumber: string, computedDataInvoicesStore: ComputedDataInvoice[]): number {
  const computedInvoice: ComputedDataInvoice = computedDataInvoicesStore.find((storedComputedInvoice: ComputedDataInvoice): boolean => storedComputedInvoice.invoiceNumber === invoiceNumber)
  
  return computedInvoice?.daysOverdueNormalized
}
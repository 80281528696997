import type { Contact } from '../../../crm-app/models/contact'

export function substDayMikesEmailWhenCreatingInvoice(companyId: string, originalContact: Contact): Contact {

  const dayMikesInfo: Contact = {
    'companyId': '635c26e7-4e25-4d2b-848a-97ed9f7d892d',
    'contactId': '',
    'department': '',
    'email': 'daymikes@globeetcolos.com',
    'firstName': 'Day-Mikes',
    'lastName': 'Assala',
    'mobilePhone': '0601084488',
    'officePhone': '',
    'phone': '',
    'position': ''
  }

  /*if (companyId === '3399da18-35c2-4f8c-8a10-8fe8afb92231') {*/
  if (companyId === '635c26e7-4e25-4d2b-848a-97ed9f7d892d') {
    return dayMikesInfo
  } else {
    return originalContact
  }
}

<script lang="ts">
  import { fade } from 'svelte/transition'
  import { t } from '../lib/i18n/i18nextWrapper'
  import { link } from 'svelte-routing'
  import { getSupportedLanguages } from '../services/supported-languages-list'
  import { userUnderAuth0JsClient } from '../lib/auth0authentication/authStore'
  import { ProfileStore } from '../stores/profile.store'
  import { WorkspaceStore } from '$crm/stores/workspace.store'
  import { feedbackService } from '../services/feedback.service'
  import TextInput from '../lib/ui-kit/TextInput.svelte'
  import StyledSelect from '../lib/ui-kit/StyledSelect.svelte'
  import Profile, { getDefaultProfile } from '../models/profile'
  import { Feedback } from '../models/feedback'
  import { getUserJobTitles } from '../services/user-job-titles-list'
  import { deepClone } from '../util/object-deep-cloning.js'
  import { onMount } from 'svelte'
  import mixpanel from 'mixpanel-browser'

  /** Variables declarations **/
  let localProfile: Profile = getDefaultProfile()
  // NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
  ProfileStore.subscribe((p: Profile) => {
    localProfile = <Profile>deepClone(p)
  })

  let hasChanged: boolean = false
  let showCustomRoleInput: boolean
  let isFirstNameValid: boolean
  let isLastNameValid: boolean

  const originalLang = localProfile.lang
  const supportedLanguages = getSupportedLanguages()
  const userJobTitles = getUserJobTitles()


  $: isFirstNameValid = !localProfile.firstName || localProfile.firstName.length > 1
  $: isLastNameValid = !localProfile.lastName || localProfile.lastName.length > 1
  $: if (localProfile.roleInCompany === 'other') {
    showCustomRoleInput = true
  } else {
    showCustomRoleInput = false
    localProfile.roleInCompanyCustom = ''
  }

  function handleInput() {
    hasChanged = true
  }

  async function handleSubmit() {
    localProfile.email = $userUnderAuth0JsClient.email
    $ProfileStore = localProfile
    hasChanged = false
    feedbackService.displayFeedback(<Feedback>{
      feedbackMessage: t('profileEdit.updateSuccess'),
      feedbackLevel: 'Success'
    })
    if (originalLang !== localProfile.lang) {
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }

  function reset() {
    hasChanged = false
    ProfileStore.update((p) => p)
  }

  onMount(() => {
    mixpanel.track('YA10 Edit User Profile Page', {
      'Description': 'Open ProfileEdit.svelte'
    })
  })

</script>

<section class="max-w-xl relative">

  <div class="pb-24">
    <div>
      <h2 class="text-black text-3xl font-bold">{t('profileEdit.account')}</h2>
      <p class="mt-2 text-sm">{t('profileEdit.accountCopy')}</p>
    </div>

    <div class="col-span-full mt-6 border-t border-athensGray pt-4">
      <h3 class="text-lg font-bold mt-2 block">{t('profileEdit.personalInformation')}</h3>
    </div>

    <form
      class="mt-6 grid grid-cols-2 gap-6"
      id="profile-form"
      on:input={handleInput}
      on:submit|preventDefault={handleSubmit}>
      <TextInput
        bind:value={localProfile.firstName}
        error={t('profileEdit.firstName.error')}
        label={t('profileEdit.firstName.label')}
        showError={!isFirstNameValid}/>

      <TextInput
        bind:value={localProfile.lastName}
        error={t('profileEdit.lastName.error')}
        label={t('profileEdit.lastName.label')}
        showError={!isLastNameValid}/>

      <fieldset class="col-span-full relative">
        <TextInput
          disabled
          label={t('profileEdit.email.label')}
          type="email"
          value={$userUnderAuth0JsClient.email}>
          {#if $userUnderAuth0JsClient.email_verified}
                            <span
                              class="absolute font-medium flex items-center text-dundyOrange border border-dundyOrange border-solid bg-provincialPink">
                              <i class="icon-circle-checked text-xl mr-1.5"></i>
                              {t('profileEdit.email.verified')}
                            </span>
          {:else}
                            <span
                              class="absolute font-medium flex items-center text-mojo border border-mojo border-solid bg-provincialPink">
                              <i class="icon-error text-sm mr-1.5"></i>
                              {t('profileEdit.email.notVerified')}
                            </span>
          {/if}
        </TextInput>
      </fieldset>

      <TextInput
        bind:value={localProfile.mobilePhone}
        error={t('profileEdit.mobilePhone.error')}
        label={t('profileEdit.mobilePhone.label')}/>

      <TextInput
        bind:value={localProfile.officePhone}
        error={t('profileEdit.officePhone.error')}
        label={t('profileEdit.officePhone.label')}/>

      <fieldset class="col-span-full relative">
        <b
          class="block text-sm font-medium text-black mb-1">{t('profileEdit.role.label', { company: $WorkspaceStore.company.formalName })}</b>
        <StyledSelect
          bind:value={localProfile.roleInCompany}
          isSearchable={false}
          items={userJobTitles}
          on:input={handleInput}
        />
      </fieldset>

      {#if localProfile.roleInCompanyCustom || showCustomRoleInput }
        <fieldset class="col-span-full relative">
          <TextInput
            bind:value={localProfile.roleInCompanyCustom}
            error={t('profileEdit.customRole.error')}
            label={t('profileEdit.customRole.label')}
          />
        </fieldset>
      {/if}
      <div class="col-span-full mt-6 border-t border-athensGray pt-4">
        <h3 class="text-lg font-bold mt-2 block">{t('profileEdit.language.heading')}</h3>
        <p class="mt-1 text-sm">{t('profileEdit.language.copy')}</p>
      </div>

      <fieldset class="col-span-full relative">
        <b class="block text-sm font-medium text-black mb-1">{t('profileEdit.language.label')}</b>
        <StyledSelect
          bind:value={localProfile.lang}
          isSearchable={false}
          label=""
          selected=""
          disabled={false}
          items={supportedLanguages}
          on:input={handleInput}
        />
      </fieldset>
    </form>

    <div class="my-6 border-t border-athensGray pt-4">
      <h3 class="text-lg font-bold mt-2 block">{t('profileEdit.security')}</h3>
      <p class="mt-1 text-sm">{t('profileEdit.securityCopy')}</p>
    </div>


    <TextInput
      class="relative"
      disabled
      label={t('profileEdit.current')}
      type="password"
      value="123456789012345">
      <a class="btn tiny absolute" href="/reset" use:link>
        {t('profileEdit.reset')}
      </a>
    </TextInput>
  </div>
  {#if hasChanged}
    <div class="lg:fixed max-w-xl px-4 bg-white flex bottom-0 w-full justify-between py-8 border-t border-athensGray"
         transition:fade>
      <button class="btn action-cancel" on:click={reset}>
        {t('profileEdit.cancel')}
      </button>
      <button
        class="btn action-bla primary"
        type="submit"
        form="profile-form"
        disabled={!isFirstNameValid || !isLastNameValid}
      >
        {t('profileEdit.save')}
      </button>
    </div>
  {/if}
</section>


<style lang="postcss">
  span.absolute {
    top: 2rem;
    right: 0.7rem;
    font-size: 11px;
    border-radius: 3px;
    padding: 0 0.5rem;
  }

  .tiny {
    top: 2rem;
    right: 0.7rem;
  }
</style>

import { t } from '../../core-app/lib/i18n/i18nextWrapper'

export class UnitType {
  label: string
  value: string
}

export function getDefaultUnitType(): UnitType {
  return {
    label: t('unitTypesList.unit_unit'),
    value: 'unit_unit'
  }
}

export function getUnitTypes(): UnitType[] {
  return [
    {
      label: t('unitTypesList.unit_unit_one'),
      value: 'unit_unit'
    },
    {
      label: t('unitTypesList.unit_gram_one'),
      value: 'unit_gram'
    },
    {
      label: t('unitTypesList.unit_hour_one'),
      value: 'unit_hour'
    },
    {
      label: t('unitTypesList.unit_day_one'),
      value: 'unit_day'
    },
    {
      label: t('unitTypesList.unit_week_one'),
      value: 'unit_week'
    },
    {
      label: t('unitTypesList.unit_month_one'),
      value: 'unit_month'
    },
    {
      label: t('unitTypesList.unit_kg_one'),
      value: 'unit_kg'
    },
    {
      label: t('unitTypesList.unit_km_one'),
      value: 'unit_km'
    },
    {
      label: t('unitTypesList.unit_liter_one'),
      value: 'unit_liter'
    },
    {
      label: t('unitTypesList.unit_lot_one'),
      value: 'unit_lot'
    },
    {
      label: t('unitTypesList.unit_meter_one'),
      value: 'unit_meter'
    },
    {
      label: t('unitTypesList.unit_square_meter_one'),
      value: 'unit_square_meter'
    },
    {
      label: t('unitTypesList.unit_cubic_meter_one'),
      value: 'unit_cubic_meter'
    },
    {
      label: t('unitTypesList.unit_linear_meter_one'),
      value: 'unit_linear_meter'
    },
    {
      label: t('unitTypesList.unit_person_one'),
      value: 'unit_person'
    },
    {
      label: t('unitTypesList.unit_ton_one'),
      value: 'unit_ton'
    }
  ]
}

export type BarChoice = {
  key: string,
  label: string,
  icon?: string,
} & {
  [key: string]: string | number | boolean;
}

export type BarChoices = BarChoice[]

export enum BarOrientation {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL'
}

export type BarWidth = 'full' | string
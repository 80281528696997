<script lang="ts">
  import './core-app/util/logger'
  import { onDestroy, onMount } from 'svelte'
  import { checkSignInOrRedirect } from '$core/services/guard.ts'
  import { Route, Router } from 'svelte-routing'
  import Onboarding from './core-app/pages/Onboarding.svelte'
  import Home from './core-app/pages/Home.svelte'
  import {
    auth0JsClient,
    authZJsUserId,
    isAuthenticatedUnderAuth0JsClient,
    userUnderAuth0JsClient
  } from './core-app/lib/auth0authentication/authStore'
  import auth from './core-app/lib/auth0authentication/authService.js'
  import SignIn from './core-app/pages/SignIn.svelte'
  import { ProfileStore } from './core-app/stores/profile.store'
  import { WorkspaceListStore, WorkspaceStore } from './crm-app/stores/workspace.store'
  import Alert from './core-app/lib/ui-kit/Alert/Alert.svelte'
  import ResetPassword from './core-app/pages/ResetPassword.svelte'
  import TinkCodeGrant from './pay-app/pages/TinkCodeGrant.svelte'
  import currentPathStore from './core-app/stores/current-path.store'
  import { DunningInvoicesStore } from './dundy-app/stores/dunning-invoices.store'
  import { TodosStore } from './dundy-app/stores/todos.store'
  import { globalHistory } from 'svelte-routing/src/history.js'
  import { ComputedDataInvoicesStore } from './dundy-app/stores/computed-data-invoice.store'
  import Error from './core-app/pages/Error.svelte'
  import { eventsManager } from './core-app/events/event-manager'
  import { EventType } from './core-app/events/event-type'
  // import { SlackEventsService } from './core-app/services/slack-events.service'
  import { SvelteToast } from '@zerodevx/svelte-toast'
  import { OnboardingStore } from './core-app/stores/onboarding.store'
  import { VoxyPreferencesStore } from './voxy-app/stores/voxy-preference.store'
  import { TransactionsStore } from './bank-app/stores/transactions.store.js'
  import { BusinessDocumentsAllDataPersistedStore } from './voxy-app/stores/business-documents.store'
  import Technical from './technical/Technical.svelte'
  import { remoteDebuggingService } from '$core/services/remote-debugging.service'
  import './shared/styles/global.css'
  import customLog from './shared/services/custom-log.service'
  import { debounce } from './core-app/util/function-utils'
  import { getClientInfo } from './core-app/util/client'

  const prodEnv = (process.env.APP_ENV === 'prod remote' || localStorage.getItem('debug')) // 'local' 'dev remote' 'prod remote'

  // SVELTE INSPECTOR ONLY FOR NON PROD ENV
  if (!prodEnv) {
    const svelteInspector = document.getElementById('svelte-inspector-toggle')
    if (svelteInspector) svelteInspector.style.zIndex = '9999999'
  }
  
  let unsubscribeWatchAuth0JsClient = null
  let unsubscribeIsAuth = null
  let unsubscribeHistory = null
  let checkForAuthenticationInterval

  /* console.log('currentPathStore.set() in', 'App.svelte', 'to', window.location.pathname, 'reason', 'always at import') */
  currentPathStore.set(window.location.pathname)
  /* console.log('%c HERE currentPathStore', 'color: blue;', $currentPathStore) */

  // LOG SCREEN SIZE + RESIZE EVENT
  let dpi:number = 0
  const onResize = () => {
    const clientSpecs = getClientInfo(dpi)
    const oldClientSpecs = localStorage.getItem('clientSpecs')
    
    const clientSpecsJson = JSON.stringify(clientSpecs)
    if (clientSpecsJson !== JSON.stringify(oldClientSpecs)) {
      localStorage.setItem('clientSpecs', clientSpecsJson)
      customLog.log('GetClientSpecs', { clientSpecs })
    }
  }
  
  const logClientSpecs = () => {
    const debouncedResize = debounce(onResize, 1000)
    window.addEventListener('resize', debouncedResize)

    // just after login
    const dpiStorage = localStorage.getItem('dpi')
    if (dpiStorage) {
      dpi = Number(dpiStorage)
      localStorage.removeItem('dpi')
      onResize()
    }
  }

  // WAIT MANDATORY STORE BEFORE LOG (Only 1 call)
  $:if ($WorkspaceStore?.workspaceId && $userUnderAuth0JsClient?.email && $authZJsUserId) logClientSpecs()
    
  onMount(() => {
    window.addEventListener('beforeunload', () => customLog.closeJourney('CloseApp'))

    /* console.log('starting App.svelte onMount()') */
    auth.createClient()

    unsubscribeHistory = globalHistory.listen(({ location, action }) => {
      /* console.log('currentPathStore.set() in', 'App.svelte', 'to', location.pathname, 'reason', 'globalHistory.listen', 'action', action) */
      $currentPathStore = location.pathname
    })

    let shouldAuth0JsClientStoreSubscribeExecuteAtSubscription: boolean = true
    // NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
    unsubscribeWatchAuth0JsClient = auth0JsClient.subscribe((newAuth0JsClientVal) => {
      if (!shouldAuth0JsClientStoreSubscribeExecuteAtSubscription) {
        /* console.log('auth0JsClient subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently') */
        shouldAuth0JsClientStoreSubscribeExecuteAtSubscription = true

        return // we avoid the .subscribe() execution at the subscription occurrence
      }
      auth.parsePossiblyPresentLoginResultDataInUrl(window.location.hash)
    })

    let shouldAuthenticatedUnderAuth0JsClientSubscribeExecuteAtSubscription: boolean = true
    // NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
    unsubscribeIsAuth = isAuthenticatedUnderAuth0JsClient.subscribe((isAuth) => {
      if (!shouldAuthenticatedUnderAuth0JsClientSubscribeExecuteAtSubscription) {
        /* console.log('isAuthenticatedUnderAuth0JsClient subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently') */
        shouldAuthenticatedUnderAuth0JsClientSubscribeExecuteAtSubscription = true

        return // we avoid the .subscribe() execution at the subscription occurrence
      }
      /* console.log('App.svelte loaded : now testing if authenticated and loading data', isAuth, $currentPathStore) */

      // SlackEventsService.initialize(isAuth, isAuth ? userUnderAuth0JsClient : {})

      if (isAuth) {
        /* console.log('App.svelte loaded : YES authenticated') */

        // LATER: after we have a way of knowing the bank provider fix this.
        // if ($WorkspaceStore && $WorkspaceStore.ownerId) {
        //     updateTinkCredentialsValidity();
        // } else {
        //     eventsManager.on<Workspace>(EventType.WORKSPACE_FETCHED, e => {
        //         if (e.data & e.data.bankConfig) {
        //             updateTinkCredentialsValidity();
        //         }
        //     }, 'App.svelte');
        // }

        if (!$WorkspaceListStore.length) {
          $OnboardingStore.isOnboarding = true
        }

        eventsManager.emit(EventType.AUTHENTICATED, true, 'App.svelte')
        /* console.log('%c Store values on app init (Some fetch request might still be occurring which might cause some stores to be updated): ', 'color: violet;') */
        /* console.log('%c $ProfileStore', 'color: violet;', $ProfileStore) */
        /* console.log('%c $WorkspaceStore', 'color: violet;', $WorkspaceStore) */
        /* console.log('%c $CustomersStore', 'color: violet;', $CustomersStore) */
        /* console.log('%c $ContactsStore', 'color: violet;', $ContactsStore) */
        /* console.log('%c $DunningInvoicesStore', 'color: violet;', $DunningInvoicesStore) */
        /* console.log('%c $ComputedDataInvoicesStore', 'color: violet;', $ComputedDataInvoicesStore) */
        /* console.log('%c $TodosStore', 'color: violet;', $TodosStore) */
        /* console.log('%c $VoxyPreferencesStore', 'color: violet;', $VoxyPreferencesStore) */
        /* console.log('%c $TransactionsStore', 'color: violet;', $TransactionsStore) */
        /* console.log('%c $BusinessDocumentsStore', 'color: violet;', $BusinessDocumentsStore) */

      } else {
        /* console.log('App.svelte loaded : NOT authenticated') */
        $ProfileStore = null
        $WorkspaceStore = null
        $DunningInvoicesStore = null
        $TodosStore = null
        $ComputedDataInvoicesStore = null
        $VoxyPreferencesStore = null
        $TransactionsStore = null
        $BusinessDocumentsAllDataPersistedStore = null
        checkSignInOrRedirect()
      }
    })

    // Help for debug on DEV env
    if (!prodEnv) {
      window.__store__ = {
        profile: $ProfileStore,
        workspace: $WorkspaceStore,
        dunning: $DunningInvoicesStore,
        todo: $TodosStore,
        invoices: $ComputedDataInvoicesStore,
        preference: $VoxyPreferencesStore,
        transaction: $TransactionsStore,
        businessDocuments: $BusinessDocumentsAllDataPersistedStore
      }
    }

    // Log version in Sentry for each page change
    window.history.pushState = new Proxy(window.history.pushState, {
      apply: (target, thisArg, argArray) => {
        // Log if user email
        if ($userUnderAuth0JsClient?.email) remoteDebuggingService.sendMsg(`VERSION ${process.env.APP_VERSION} - ${$userUnderAuth0JsClient?.email}`)

        target.apply(thisArg, argArray)
      }
    })
  })


  const allowTechPage = () => {
    const regex = new RegExp('(brice|franck|goncalo).*@u-trade.io')

    return regex.test($userUnderAuth0JsClient?.email)
  }

  onDestroy(() => {
    if (!!unsubscribeWatchAuth0JsClient) {
      unsubscribeWatchAuth0JsClient()
    }
    if (!!unsubscribeIsAuth) {
      unsubscribeIsAuth()
    }
    if (!!unsubscribeHistory) {
      unsubscribeHistory()
    }
    if (!!checkForAuthenticationInterval) {
      clearInterval(checkForAuthenticationInterval)
    }
  })
</script>

<Alert/>
<Router>
  <Route path="onboarding/*">
    <Onboarding/>
  </Route>
  <Route path="signin">
    <SignIn/>
  </Route>
  <!-- <Route path="signup"><SignUp /></Route> -->
  <Route path="reset">
    <ResetPassword/>
  </Route>
  <Route path="tinkCodeGrant">
    <TinkCodeGrant/>
  </Route>
  {#if allowTechPage()}
    <Route path="tech">
      <Technical/>
    </Route>
  {/if}
  <Route path="error">
    <Error/>
  </Route>
  <Route path="*">
    <Home/>
  </Route>
</Router>

<SvelteToast options={{ reversed: true, intro: { x: 192 } }}/>

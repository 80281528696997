import type { Workspace } from '../../crm-app/models/workspace'

export function isBankConnectionActive(workspace: Workspace): boolean {
  return !!workspace.bankConfig?.bankConnectionCurrentlyKnownActive
}

export function isBankInformationComplete(workspace: Workspace): boolean {
  /* console.log('!!workspace.bankConfig?.selectedAccountBankName', !!workspace.bankConfig?.selectedAccountBankName) */
  /* console.log('workspace.bankConfig?.selectedAccountBankName', workspace.bankConfig?.selectedAccountBankName) */
  /* console.log('!!workspace.bankConfig?.selectedAccountBankIBAN', !!workspace.bankConfig?.selectedAccountBankIBAN) */
  /* console.log('workspace.bankConfig?.selectedAccountBankIBAN', workspace.bankConfig?.selectedAccountBankIBAN) */
  /* console.log('!!workspace.bankConfig?.selectedAccountBankBIC', !!workspace.bankConfig?.selectedAccountBankBIC) */
  /* console.log('workspace.bankConfig?.selectedAccountBankBIC', workspace.bankConfig?.selectedAccountBankBIC) */
  
  return !!workspace.bankConfig?.selectedAccountBankName
        && !!workspace.bankConfig?.selectedAccountBankIBAN
        && !!workspace.bankConfig?.selectedAccountBankBIC
}

<script lang="ts">
    import { t } from '../../i18n/i18nextWrapper'
    import { createEventDispatcher } from 'svelte'
    import Pagination from '../Pagination.svelte'
    import { paginate } from '../../../services/pagination/pagination'
    import type PaginatedDisplayListConfig from './PaginatedListConfig'
    import DisplayCustomerNameAndLegalIdentification
      from '../../../../crm-app/lib/customer/DisplayCustomerNameAndLegalIdentification.svelte'
    import DisplayContact from '../../../../crm-app/lib/customer/DisplayContact.svelte'
    import DisplayCustomerAmounts from '../../../../crm-app/lib/customer/DisplayCustomerAmounts.svelte'
    import DisplayWorkspaceNameAndCountry from '../../../../crm-app/lib/workspace/DisplayWorkspaceNameAndCountry.svelte'
    import DisplayWorkspaceAccount from '../../../../crm-app/lib/workspace/DisplayWorkspaceAccount.svelte'
    import { CustomersHelper } from '../../../../crm-app/helpers/customers-helper'
    import DisplayTodoTitle from '../../../../dundy-app/lib/todo/DisplayTodoTitle.svelte'
    import DisplayTodoAmount from '../../../../dundy-app/lib/todo/DisplayTodoAmount.svelte'
    import DisplayStatus from './DisplayStatus.svelte'
    import DisplayDunningStep from '../../../../dundy-app/lib/todo/DisplayDunningStep.svelte'
    import DisplayInvoiceNumber from '../../../../dundy-app/lib/dunning/ag-grid/invoices/DisplayInvoiceNumber.svelte'
    import DisplayDueDate from '../../../../dundy-app/lib/dunning/ag-grid/invoices/DisplayDueDate.svelte'
    import DisplayInvoiceAmount from '../../../../dundy-app/lib/dunning/ag-grid/invoices/DisplayInvoiceAmount.svelte'
    import objectPath from 'object-path'
    import { PaginatedDisplayListSort, PaginatedDisplayListType } from './PaginatedListConfig'
    import type { Customer } from '$crm/models/customer'
    import DisplayOriginalBusinessDocument
      from '../../dashboard/partial-invoices/DisplayOriginalBusinessDocument.svelte'
    import DisplayNewBalanceBusinessDocument
      from '../../dashboard/partial-invoices/DisplayNewBalanceBusinessDocument.svelte'

    const dispatch = createEventDispatcher()

    export let config: PaginatedDisplayListConfig = null
    export let highlightRowFn: (rowData: any) => boolean = () => false

    /** Variables declarations */
    let currentPage: number = 1
    let sortByColumnIndex: number = 0
    let sortDirection: PaginatedDisplayListSort = PaginatedDisplayListSort.NEUTRAL

    let rowsDisplayed: Array<any>
    $: rowsDisplayed = paginate({
      items: config?.data,
      pageSize: config?.pageSize,
      currentPage
    })
    let customerListDisplayedTotal: number
    $: customerListDisplayedTotal = config?.data.length

    $: if (config?.data) {
      currentPage = 1
    }


    const selectRow = (rowData: any) => {
      dispatch('selectRow', rowData)
    }

    const sort = (columnIndex: number) => {
      sortByColumnIndex = columnIndex
      const colum = config.columns[columnIndex]

      // Update the sort direction
      if (sortDirection === 'asc') {
        sortDirection = PaginatedDisplayListSort.DESC
      } else if (sortDirection === 'desc') {
        sortDirection = PaginatedDisplayListSort.NEUTRAL
      } else {
        sortDirection = PaginatedDisplayListSort.ASC
      }
      switch (colum.type) {
        case 'amounts':
          sortHelper('customer', CustomersHelper.calculateOverdueAmount)
          sortHelper('customer', CustomersHelper.calculateDueAmount)
          break
        case 'bank-account':
          sortHelper('workspace.bankConfig.selectedAccountName')
          break
        case 'status-label':
          sortHelper('todo.maxDueDays')
          break
        default:
          switch (colum.dataKey) {
            case 'customer':
              sortHelper('customer.company.formalName')
              break
            case 'workspace':
              sortHelper('workspace.company.formalName')
              break
            case 'contact':
              sortHelper('contact.firstName')
              break
            case 'invoice':
              sortHelper('invoice.amountIncludingTaxes')
              break
            case 'todo':
              sortHelper('todo.totalScopeAmountOfMoneyDue.scaledValue')
              break
            case 'originalDocument':
              sortHelper('originalDocument.installmentResultIncludingTaxScaledValue')
              break
            case 'newBalanceBusinessDocument':
              sortHelper('newBalanceBusinessDocument.invoicingBalanceLimitsForBalanceInvoice.dealMaxRemainingAmountToInvoiceScaledValueIncludingTaxesOnlyFinalized')
              break
          }
          break
      }
    }

    /**
     * Sorts the list by the given property path
     * @param propertyPath
     * @param valueAccessor
     */
    const sortHelper = (propertyPath: any, valueAccessor?: (customer: Customer) => number) => {
      config.data = config.data.sort((rowA, rowB) => {
        let result = 0
        let valueA = null
        let valueB = null
        if (valueAccessor) {
          valueA = rowA && valueAccessor(objectPath.get(rowA, propertyPath))
          valueB = rowB && valueAccessor(objectPath.get(rowB, propertyPath))
        } else {
          valueA = rowA && objectPath.get(rowA, propertyPath)
          valueB = rowB && objectPath.get(rowB, propertyPath)
        }
        if (valueA > valueB) {
          result = sortDirection === 'asc' ? 1 : -1
        }
        if (valueA < valueB) {
          result = sortDirection === 'desc' ? 1 : -1
        }
        
        return result
      })
    }
</script>
<div class="w-full">
    <div class="mt-8 flex flex-col">
        <div class="md:-my-2 md:-mx-4 sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div class=" ring-1 ring-athensGray rounded-md">
                    <table class="min-w-full divide-y divide-athensGray border-collapse border-spacing-0">

                        <thead id="list-header">
                            <tr>
                                {#each config.columns as column, i}
                                    <th scope="col"
                                        class="py-1.5 pl-5 pr-3 bg-white text-left text-xxs font-semibold uppercase {column.headerClasses}
                                        {i === 0 ? 'rounded-tl border-r-0' : ''}
                                        {i === config.columns.length - 1 ? 'rounded-tr' : ''}">
                                        <span
                                            class="group inline-flex items-center py-1 px-1.5 rounded-md {sortByColumnIndex === i && sortDirection !== PaginatedDisplayListSort.NEUTRAL ? 'bg-whisper group-hover:bg-gray-300' : ''}"
                                            role="button"
                                            tabindex="0"
                                            on:click={() => sort(i)}
                                            on:keydown={(e) => {
                                              if (e.key === 'Enter') {
                                                sort(i)
                                              }
                                            }}
                                        >
                                            <span>{column.label || ' '}</span>
                                            {#if column.label}
                                                <span class="cursor-pointer ml-2 flex-none rounded text-gray-900">
                                                    {#if sortDirection === 'desc'}
                                                       <svg class="inline h-3 w-3" fill="none" viewBox="0 0 24 24">
                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.25 13.75L12 19.25L6.75 13.75"></path>
                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 18.25V4.75"></path>
                                                       </svg>

                                                    {:else if sortDirection === 'asc'}

                                                        <svg class="inline h-3 w-3" fill="none" viewBox="0 0 24 24">
                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.25 10.25L12 4.75L6.75 10.25"></path>
                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 19.25V5.75"></path>
                                                        </svg>
                                                    {:else}
                                                        <svg class="inline h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.75 15.75L16 19.25L19.25 15.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                            <path d="M4.75 8.25L8 4.75L11.25 8.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                            <path d="M16 8.75V19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                            <path d="M8 4.75V15.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                        </svg>

                                                    {/if}
                                                </span>
                                            {/if}
                                        </span>
                                    </th>
                                {/each}
                                <th scope="col" class="relative py-3.5 px-2 rounded-tr">
                                    <span class="sr-only">View</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                        {#each rowsDisplayed as row, i}
                            <tr class="cursor-pointer hover:bg-whisper {i === 0 ? 'list-row-border-top' : ''} cursor-pointer {highlightRowFn(config.data[i]) ? 'highlight' : ''}"
                                on:click={() => {selectRow(row)}}>
                                {#each config.columns as column}
                                    <td class="py-3 pl-4 pr-3 sm:pl-6 truncate {column.classes} {row[column.dataKey]?.isDone ? 'opacity-50' : ''}">
                                        {#if column.type === PaginatedDisplayListType.CUSTOMER}
                                            <DisplayCustomerNameAndLegalIdentification customer={row[column.dataKey]}
                                                                                       classes={column.componentClasses}/>
                                        {/if}
                                        {#if column.type === PaginatedDisplayListType.LATE_CUSTOMER}
                                            <DisplayCustomerNameAndLegalIdentification customer={row[column.dataKey]}
                                                                                       displayLateInvoices={true}
                                                                                       classes={column.componentClasses}/>
                                        {/if}
                                        {#if column.type === PaginatedDisplayListType.CONTACT}
                                            <DisplayContact contact={row[column.dataKey]}
                                                            classes={column.componentClasses}/>
                                        {/if}
                                        {#if column.type === PaginatedDisplayListType.AMOUNTS}
                                            <DisplayCustomerAmounts customer={row[column.dataKey]}
                                                                    classes={column.componentClasses}
                                                                    showTooltip={column.showTooltip}
                                                                    showAmountBars={column.showAmountBars}
                                            />
                                        {/if}
                                        {#if column.type === PaginatedDisplayListType.WORKSPACE}
                                            <DisplayWorkspaceNameAndCountry workspace={row[column.dataKey]}
                                                                            classes={column.componentClasses}/>
                                        {/if}
                                        {#if column.type === PaginatedDisplayListType.BANK_ACCOUNT}
                                            <DisplayWorkspaceAccount workspace={row[column.dataKey]}
                                                                     classes={column.componentClasses}/>
                                        {/if}
                                        {#if column.type === PaginatedDisplayListType.TODO_TITLE}
                                            <DisplayTodoTitle todo={row[column.dataKey]}
                                                              classes={column.componentClasses}/>
                                        {/if}
                                        {#if column.type === PaginatedDisplayListType.TODO_AMOUNT}
                                            <DisplayTodoAmount todo={row[column.dataKey]}
                                                               classes={column.componentClasses}/>
                                        {/if}
                                        {#if column.type === PaginatedDisplayListType.STATUS_LABEL}
                                            <DisplayStatus todo={row[column.dataKey]}
                                                           invoice={row.invoice}
                                                           classes={column.componentClasses}/>
                                        {/if}
                                        {#if column.type === PaginatedDisplayListType.INVOICE_NUMBER}
                                            <DisplayInvoiceNumber invoice={row[column.dataKey]}/>
                                        {/if}
                                        {#if column.type === PaginatedDisplayListType.INVOICE_AMOUNT}
                                            <DisplayInvoiceAmount invoice={row[column.dataKey]}
                                                                  classes={column.componentClasses}/>
                                        {/if}
                                        {#if column.type === PaginatedDisplayListType.DUE_DATE}
                                            <DisplayDueDate invoice={row[column.dataKey]}
                                                            classes={column.componentClasses}/>
                                        {/if}
                                        {#if column.type === PaginatedDisplayListType.DUNNING_STEP}
                                            <DisplayDunningStep todo={row[column.dataKey]} />
                                        {/if}
                                        {#if column.type === PaginatedDisplayListType.PARTIAL_BUSINESS_DOCUMENT}
                                            <DisplayOriginalBusinessDocument originalBusinessDocument={row[column.dataKey]}
                                                                                   classes={column.componentClasses}/>
                                        {/if}
                                        {#if column.type === PaginatedDisplayListType.NEW_BALANCE_BUSINESS_DOCUMENT}
                                            <DisplayNewBalanceBusinessDocument newBalanceBusinessDocument={row[column.dataKey]}
                                                                             classes={column.componentClasses}/>
                                        {/if}
                                    </td>
                                {/each}
                                <td class="relative whitespace-nowrap py-4 px-2 text-right text-sm">
                                    <div class="flex none w-8 right-0 text-right justify-end">
                                        <svg class="w-5 h-5 flex justify-center align-middle items-center" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10.75 8.75L14.25 12L10.75 15.25"></path>
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                        {/each}
                        </tbody>
                    </table>
                    <div class="border-t border-athensGray rounded-bl rounded-br bg-white mt-4 flex justify-between items-center overflow-hidden">
                        <div class="px-5 py-3">
                            <div class="text-paleSky text-xs">
                                <span>{t('pagination.label1')}</span>
                                <span class="font-bold">{currentPage * config.pageSize - (config.pageSize - 1)}</span>
                                <span class="font-bold">{t('pagination.label2')}</span>
                                <span class="font-bold">{currentPage * config.pageSize}</span>
                                <span>{t('pagination.label3')}</span>
                                <span>{customerListDisplayedTotal}</span>
                                <span>{t('pagination.label4')}</span>
                            </div>
                        </div>

                        <div class="flex-grow px-5 py-3 flex justify-end">
                            <Pagination
                                    currentPage={currentPage}
                                    pageSize={config.pageSize}
                                    totalItems={config.data.length}
                                    limit={1}
                                    showStepOptions={true}
                                    on:setPage={(e) => currentPage = e.detail.page}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


import { t } from '../i18n/i18nextWrapper'
import type { ICellRendererParams } from 'ag-grid-community'

export default class DatatableDateRenderer {
  private params: any
  private cellContent: HTMLElement
  public content: HTMLElement
  constructor() {}
  public init(params: ICellRendererParams) {
    this.params = params
    let valueDisplayed: any
    if (!params.value) {
      valueDisplayed = 0
    } else {
      valueDisplayed = params.value
    }
    this.cellContent = document.createElement('span')
    this.content = this.cellContent

    this.content.innerHTML = new Intl.DateTimeFormat(t('locales'), {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(new Date(valueDisplayed))
    this.content.classList.add('dundy-date')
    this.content.setAttribute('dundy-value', params.value)
  }
  public getGui() {
    return this.cellContent
  }
  public destroy() {}
}
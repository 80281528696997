<script lang="ts">
    import { onMount } from 'svelte'
    import { updateNewNavigationHistoryItem } from '../../../core-app/stores/navigationHistory.store'
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper.js'

    export let programmedActionKind: string

    onMount(() => {
      /* console.log('ccc onMount ViewActionReceivableInvoiceTip.svelte') */
      updateNewNavigationHistoryItem('ViewActionReceivableInvoiceTip.svelte')
    })
</script>

<div class="mt-10 aside-note relative">
    <svg class="absolute -top-7 m-auto left-0 right-0" width="50" height="49"
         xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <path fill="#FFE6A0" d="m25 .917 22.576 24.041L25 49.001 2.424 24.958z"/>
            <path d="M25.529 17.208c3.397 0 5.646 2.732 5.646 6 0 1.921-.552 3.242-1.599 4.447l-.899.976-.127.149c-.146.191-.198.316-.198.428v2.25c0 .967-.737 1.75-1.647 1.75h-2.353c-.91 0-1.647-.783-1.647-1.75v-2.25c0-.112-.052-.237-.198-.428a1.578 1.578 0 0 0-.051-.062l-.171-.193-.804-.87c-1.047-1.205-1.6-2.526-1.6-4.447 0-3.268 2.25-6 5.648-6Zm1.41 13.5h-2.823v.75c0 .116.074.212.174.241l.062.01h2.353c.13 0 .235-.112.235-.25v-.75Zm-1.41-12c-2.573 0-4.235 2.019-4.235 4.5 0 1.517.405 2.486 1.224 3.43l.035.038.739.798.256.292.055.069c.333.436.514.868.514 1.373h2.823c0-.505.181-.937.514-1.373.03-.04.074-.092.128-.154l.184-.207.738-.798.184-.216c.72-.889 1.076-1.83 1.076-3.252 0-2.481-1.663-4.5-4.235-4.5Z"
                  fill="#1F2533"/>
        </g>
    </svg>
    <h3 class="text-black font-bold mt-0.5 mb-2 text-center">{t(`todoAction.actionKind.${ programmedActionKind }.explainerTitle`)}</h3>
    <p class="text-sm my-4">{@html t(`todoAction.actionKind.${ programmedActionKind }.explainerText`)}</p>
</div>
<style lang="postcss">
    .aside-note {
        @apply bg-creamBrulee border border-warmGray rounded-md px-6 py-4;
        border-color: hsl(47, 65%, 84%);
        background-color: hsl(47, 87%, 94%);
    }
</style>

<script lang="ts">
    import { t } from '../i18n/i18nextWrapper.js'

    /** Export let */
    export let filter: string
    export let currency: string
    export let filterValue: { value: boolean }
    export let updateFilter: Function

    function toggleFilter() {
      filterValue.value = !filterValue.value
      updateFilter(filter, filterValue)
    }
</script>

{#if filterValue.value}
    <span class="inline-flex items-center gap-x-0.5 rounded-md bg-black px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-gray-500/10">
        {t(`dunning.filters.${filter}`, {currency: currency})}
        <button type="button"
                on:click={toggleFilter}
                class="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20">
            <span class="sr-only">Remove</span>
            <svg viewBox="0 0 14 14"
                 class="h-3.5 w-3.5 stroke-zinc-50/50 group-hover:stroke-white/75">
                <path d="M4 4l6 6m0-6l-6 6"/>
            </svg>
            <span class="absolute -inset-1"></span>
        </button>
    </span>
{/if}

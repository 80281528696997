/**
 * Returns a formatted Number with sign
 * for the given locale and currency code.
 * @param locale
 * @param currencyCode
 */
export function getInvoiceFormatter(locale: string, currencyCode: string): Intl.NumberFormat {
  try {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      signDisplay: 'exceptZero',
      currency: currencyCode,
      currencyDisplay: 'code'
    })
  } catch (e) {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      signDisplay: 'exceptZero',
      currency: 'EUR',
      currencyDisplay: 'code'
    })
  }
}
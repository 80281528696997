import Handlebars from 'handlebars'

/**
 * Builds an email from a template and data
 * @param emailTemplate
 * @param data
 */
export const buildEmailFromTemplate = (emailTemplate: any, data: any) => {
  const template: HandlebarsTemplateDelegate = Handlebars.compile(emailTemplate)
  
  return template(data)
}
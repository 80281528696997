import type { CellClassParams, ColDef, ValueSetterParams } from 'ag-grid-community'
import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
import DatatableCustomerRenderer from '../../../crm-app/lib/customer/DatatableCustomerRenderer'
import DatatableCustomerEditor from '../../../crm-app/lib/customer/DatatableCustomerEditor'
import InvoiceNumberRenderer from '../../../dundy-app/lib/dunning/ag-grid/InvoiceNumberRenderer'
import DatatableDateRenderer from '../../../core-app/lib/ui-kit/DatatableDateRenderer'
import DatatableDateEditor from '../../../core-app/lib/ui-kit/DatatableDateEditor'
import DunningStatusRenderer from '../../../dundy-app/lib/dunning/ag-grid/DunningStatusRenderer'
import { DunningInvoicesHelper } from '../../../dundy-app/helpers/dunning-invoices-helper'
import DatatableNumberEditor from '../../../core-app/lib/ui-kit/DatatableNumberEditor'
import DatatableAmountOfMoneyRendererWithoutCurrencySymbol
  from '../../../core-app/lib/ui-kit/DatatableAmountOfMoneyRendererWithCurrencyInLetter'
import { agGridCommonService } from '../../../dundy-app/services/ag-grid-common.service'
import { ValidationLevel } from '../../../dundy-app/models/validation-level'
import DatatableInvoiceActionsRenderer from '../../../dundy-app/lib/dunning/ag-grid/DatatableInvoiceActionsRenderer'

export const quotesColumnDefs: ColDef[] = [
  {
    headerName: 'Did',
    field: 'did',
    sortable: false,
    hide: true,
    suppressColumnsToolPanel: true
  },
  {
    headerName: t('quotes.columns.client'),
    field: 'toId',
    cellRenderer: DatatableCustomerRenderer,
    cellEditor: DatatableCustomerEditor,
    sortable: true,
    editable: false,
    cellStyle: { 'text-overflow': 'ellipsis', 'white-space': 'nowrap', 'overflow': 'hidden' },
    unSortIcon: true
  },
  {
    headerName: 'toId',
    field: 'toId',
    cellClass: 'font-medium',
    sortable: true,
    editable: false,
    hide: true,
    suppressColumnsToolPanel: true
  },
  {
    headerName: t('quotes.columns.quoteNumber'),
    field: 'quoteNumber',
    cellRenderer: InvoiceNumberRenderer,
    cellClass: 'default',
    sortable: true,
    editable: false,
    cellStyle: { 'text-overflow': 'ellipsis', 'white-space': 'nowrap', 'overflow': 'hidden' },
    unSortIcon: true
  },
  {
    headerName: t('quotes.columns.issuedDate'),
    field: 'dateIssued',
    cellClass: 'default',
    cellRenderer: DatatableDateRenderer,
    cellEditor: DatatableDateEditor,
    sortable: true,
    editable: false,
    unSortIcon: true
  },
  {
    headerName: t('quotes.columns.status'),
    field: 'status',
    cellRenderer: DunningStatusRenderer,
    sortable: true,
    cellClassRules: {
      'status-success-outlined': (params: CellClassParams) => DunningInvoicesHelper.updateRenderedValue(params).newElementClass.indexOf('success-outlined') > -1,
      'status-warning-outlined': (params: CellClassParams) => DunningInvoicesHelper.updateRenderedValue(params).newElementClass.indexOf('warning-outlined') > -1,
      'status-success': (params: CellClassParams) => DunningInvoicesHelper.updateRenderedValue(params).newElementClass.indexOf('success') > -1,
      'status-error': (params: CellClassParams) => DunningInvoicesHelper.updateRenderedValue(params).newElementClass.indexOf('error') > -1,
      'status-warning': (params: CellClassParams) => DunningInvoicesHelper.updateRenderedValue(params).newElementClass.indexOf('warning') > -1
    },
    unSortIcon: true
  },
  {
    headerName: t('quotes.columns.amount'),
    field: 'amountIncludingTaxes',
    cellClass: 'totalAmount',
    cellEditor: DatatableNumberEditor,
    cellRenderer: DatatableAmountOfMoneyRendererWithoutCurrencySymbol,
    valueSetter: (params: ValueSetterParams<any>) => DunningInvoicesHelper.numberSetter(params, 'amountIncludingTaxes'),
    sortable: true,
    editable: agGridCommonService.isAnEditableCell,
    cellClassRules: {
      'has-info': (params: CellClassParams) => agGridCommonService.propertyHasFeedbackLevel(params.data, 'amountIncludingTaxes', ValidationLevel.INFO),
      'has-warning': (params: CellClassParams) => agGridCommonService.propertyHasFeedbackLevel(params.data, 'amountIncludingTaxes', ValidationLevel.WARN),
      'has-error': (params: CellClassParams) => agGridCommonService.propertyHasFeedbackLevel(params.data, 'amountIncludingTaxes', ValidationLevel.ERROR)
    },
    unSortIcon: true
  },

  {
    headerName: '',
    field: 'actions',
    cellClass: 'default',
    cellRenderer: DatatableInvoiceActionsRenderer,
    sortable: false,
    width: 20,
    maxWidth: 20,
    minWidth: 20
  }
]
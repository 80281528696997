import type { InvoiceStatusType } from '../enums/invoice-status'
import type { InvoiceDocumentSource } from './invoice-document-source'
import type Company from '../../crm-app/models/company'
import type { Contact } from '$crm/models/contact'
import {
  DunningBusinessDocumentKind,
  type DunningBusinessDocumentKindType
} from '../enums/dunning-business-document-kind'
import { deepClone } from "$core/util/object-deep-cloning"
import { get } from "svelte/store"
import {WorkspaceStore } from '$crm/stores/workspace.store'

export default class Invoice {
  documentKind!: DunningBusinessDocumentKindType
  amountExcludingTaxes!: number
  amountIncludingTaxes!: number
  currency!: string
  dateDue!: string
  dateIssued!: string
  dataId!: string
  fromId!: string
  fromName!: string
  fromSupplier!: Company
  supplierContact!: Contact
  invoiceDescription!: string
  invoiceNumber!: string
  deleted!: boolean
  completed!: boolean
  isTracked!: boolean
  modified!: number
  modifiedRFC3339!: string
  status!: InvoiceStatusType
  taxesAmount!: number
  toId!: string // customer ID
  toName!: string
  toFinalCustomer!: Company
  finalCustomerContact!: Contact
  documentSource!: InvoiceDocumentSource
  purchaseOrderNumbers!: string[]

  constructor(documentKind: DunningBusinessDocumentKindType = DunningBusinessDocumentKind.INVOICE) {
    this.documentKind = documentKind;
    this.amountExcludingTaxes = 0;
    this.amountIncludingTaxes = 0;
    this.currency = '';
    this.dateDue = '';
    this.dateIssued = '';
    this.dataId = '';
    this.fromId = '';
    this.fromName = '';
    this.fromSupplier = deepClone(get(WorkspaceStore).company)
    this.supplierContact = {} as Contact;
    this.invoiceDescription = '';
    this.invoiceNumber = '';
    this.deleted = false;
    this.completed = false;
    this.isTracked = false;
    this.modified = 0;
    this.modifiedRFC3339 = '';
    this.status = '' as InvoiceStatusType;
    this.taxesAmount = 0;
    this.toId = '';
    this.toName = '';
    this.toFinalCustomer = {} as Company;
    this.finalCustomerContact = {} as Contact;
    this.documentSource = {} as InvoiceDocumentSource;
    this.purchaseOrderNumbers = [];
  }
}

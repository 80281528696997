<script lang="ts">
  import { CustomersHelper } from '$src/crm-app/helpers/customers-helper'
  import { CustomersStore } from '$src/crm-app/stores/customers.store'
  import { deleteCustomerFromCustomerStore } from '$src/crm-app/lib/customer/create-update-delete-customer'
  import { DunningInvoicesStore } from '$src/dundy-app/stores/dunning-invoices.store'
  import { feedbackService } from '$src/core-app/services/feedback.service'
  import { formatCurrencyWithOptions } from '$src/voxy-app/utils/number-formatting-utils'
  import { getDefaultCurrencyAccordingToCountry } from '$src/core-app/services/currency-list'
  import { getUserCountryCode, isClientCountryCodeAMemberOfEuropeanUnion } from '$src/core-app/services/countries-pure-functions'
  import { navigate } from 'svelte-routing'
  import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
  import PageWithGrid from '$src/shared/components/templates/PageWithGrid.svelte'
  import type { Customer } from '$src/crm-app/models/customer'
  import type { Feedback } from '$src/core-app/models/feedback'

  const invoicesCurrency:string = $DunningInvoicesStore.find((invoice) => (invoice.currency))?.currency || getDefaultCurrencyAccordingToCountry(getUserCountryCode())

  const getDueAmount = (customer:any) => {
    const val = CustomersHelper.calculateDueAmount(customer)
  
    return formatCurrencyWithOptions(val, t('locales'), invoicesCurrency, true, 'symbol')
  }

  const getOverdueAmount = (customer:any) => {
    const val = CustomersHelper.calculateOverdueAmount(customer)
    
    return formatCurrencyWithOptions(val, t('locales'), invoicesCurrency, true, 'symbol')
  }

  const getOrigin = (customer:Customer) => {
    if (customer.isPrivateIndividual) return t('customerList.table.privateIndividual')
    if (customer.company.mailAddress.countryCode === 'FR') return t('customerList.table.french')
    if (isClientCountryCodeAMemberOfEuropeanUnion(customer.company?.mailAddress?.countryCode, 'FR')) return t('customerList.table.inEU')

    return t('customerList.table.noEU')
  }
  
  const columns = [
    { name: t('customerList.table.name'), path: 'company.formalName' },
    { name: t('customerList.table.type'), path: 'origin' },
    { name: t('customerList.table.overdue'), path: 'overdue', color: 'dundyOrange' },
    { name: t('customerList.table.onTime'), path: 'due' }
  ]

  $:rows = $CustomersStore.map(c => ({ 
    ...c, 
    due: getDueAmount(c),
    origin: getOrigin(c),
    overdue: getOverdueAmount(c)
  })) ?? []

  $:txtCountDescription = t(rows.length < 2
    ? 'customerList.countDescription_one'
    : 'customerList.countDescription_more', { count: rows.length })

  const toast = (type:string, message:string) => feedbackService.displayFeedback({
    feedbackMessage: message,
    feedbackLevel: type
  } as Feedback)

  const onCustomerSelect = (c:Customer) => navigate(`/clients/${c.company.companyId}?from=list`)

  const onCustomerEdit = (c:Customer) => navigate(`/clients/${c.company.companyId}/edit?from=list`)

  const onCustomerDelete = async (customer:Customer) => {
    try {
      await deleteCustomerFromCustomerStore(customer.company.companyId)
      
      toast('Success', t('customerList.feedback.delete'))
    } catch (err) {
      toast('Error', String(err))
    }
  }
</script>

<PageWithGrid
  title={t('topMenu.nav.clients.manage')}
  addButtonLink={'/clients/new'}
  addButtonValue={t('actions.add')}
  footerClass=''
  allowActions={true}
  contextName='customer'
  searchPath='company.formalName'
  allowDuplicate={false}
  {txtCountDescription}
  {columns}
  {rows}
  on:select={(e) => onCustomerSelect(e.detail)}
  on:edit={(e) => onCustomerEdit(e.detail)}
  on:delete={(e) => onCustomerDelete(e.detail)}
 />
import { get, type Writable, writable } from 'svelte/store'
import { eventsManager } from '../../core-app/events/event-manager'
import { EventType } from '../../core-app/events/event-type'
import { encrypt256Hash } from '../../core-app/util/encryption'
import type { Customer } from '../models/customer'
import initializationStore from '../../core-app/stores/initialization.store'
import { deepClone } from '../../core-app/util/object-deep-cloning'
import { ExchangeDate } from '../../core-app/models/exchange-date'

const getCustomers = (): Customer[] => {
  const existingValue: string | null = localStorage.getItem('CustomersStore')
  const emptyValue: Customer[] = <Customer[]>[]
  if (!existingValue || existingValue === 'undefined') {
    return emptyValue
  }
  let parsed: Customer[]
  try {
    parsed = <Customer[]>Object.assign([], JSON.parse(existingValue))

  } catch (e) {
    parsed = <Customer[]>{}
  }
  
  return parsed
}
export let CustomersStore: Writable<Customer[]> = writable<Customer[]>(getCustomers())

export const initializeCustomersStore = (data: Customer[]) => {

  if (!get(initializationStore).customersStoreInitialized) {

    initializationStore.update(store => {
      store.customersStoreInitialized = true
      
      return store
    })

    localStorage.setItem('CustomersStore', JSON.stringify(data, null, 4))
    CustomersStore.set(data)
    
    let isCustomersStoreSubscriptionDefined: boolean = false
    // NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable,
    // then it is executed immediately (including when the store.ts file is imported at the beginning of the web app)
    // and its argument is whatever the store variable contains at this point
    CustomersStore.subscribe((newCustomerList: Customer[]) => {
      
      if (!isCustomersStoreSubscriptionDefined) {
        /* console.log('CustomersStore subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently') */
        isCustomersStoreSubscriptionDefined = true
        
        return // we avoid the .subscribe() execution at the subscription occurrence
      }
      if (newCustomerList) {
        const oldCustomerList = JSON.parse(<string>localStorage.getItem('CustomersStore'))
        const oldCustomerListHash: string = encrypt256Hash(oldCustomerList)
        const newCustomerListHash: string = encrypt256Hash(newCustomerList)

        if (oldCustomerListHash !== newCustomerListHash) {
          newCustomerList.forEach((newCustomer: Customer) => {
            const oldCustomer: Customer = oldCustomerList.find((customer: Customer): boolean => customer.company?.companyId === newCustomer.company?.companyId)
            if (oldCustomer) {
              const oldCustomerHash: string = encrypt256Hash(oldCustomer)
              const newCustomerHash: string = encrypt256Hash(newCustomer)
              if (oldCustomerHash !== newCustomerHash) {
                const customerCreatedDate: ExchangeDate = (!!newCustomer?.company?.createdDate ? newCustomer?.company?.createdDate : ExchangeDate.newDate(new Date()))
                const newCustomerWithModifiedDate: Customer = <Customer>{
                  ...deepClone(newCustomer),
                  company: {
                    ...deepClone(newCustomer.company),
                    modifiedDate: ExchangeDate.newDate(new Date()),
                    createdDate: customerCreatedDate
                  }
                }
                eventsManager.emit(EventType.CUSTOMER_CHANGED, newCustomerWithModifiedDate, 'CustomersStore')
                // Typescript cannot guarantee the existence of a dynamically accessed property
                // so we need to cast the object to Record<string, any> to avoid the error
                // but we are bypassing the type checking
                Object.keys(newCustomerWithModifiedDate).forEach((k: string) => {
                  const newCustomerRecord: Record<string, any> = newCustomerWithModifiedDate as Record<string, any>
                  const oldCustomerRecord: Record<string, any> = oldCustomer as Record<string, any>
                  if (newCustomerRecord[k] !== oldCustomerRecord[k]) {
                    eventsManager.emit(EventType.CUSTOMER_PROPERTY_CHANGED, newCustomerRecord[k], 'CustomersStore')
                  }
                })
              }
            } else {
              eventsManager.emit(EventType.CUSTOMER_CREATED, newCustomer, 'CustomersStore')
            }
          })
          oldCustomerList.forEach((oldCustomer: { company: { companyId: string; }; }) => {
            if (oldCustomer.company?.companyId) {
              const customerFoundInNewList: Customer | undefined = newCustomerList.find((customer: Customer): boolean => oldCustomer.company?.companyId === customer.company?.companyId)
              if (!customerFoundInNewList) {
                eventsManager.emit(EventType.CUSTOMER_DELETED, oldCustomer, 'CustomersStore')
              }
            }
          })
          localStorage.setItem('CustomersStore', JSON.stringify(newCustomerList, null, 4))
          eventsManager.emit(EventType.CUSTOMER_LIST_CHANGED, newCustomerList, 'CustomersStore')
        }
      }
    })

    eventsManager.emit(EventType.CUSTOMER_LIST_LOADED, data, 'CustomersStore')

    /* console.log('%c CustomersStore initialized.', 'color: #8A99AC') */
  }
}

import { BusinessDocumentRelation } from '../../models/business-document'
import { deepClone } from '$core/util/object-deep-cloning'

/**
 * sort from recent to oldest (biggest unixSeconds to smallest unixSeconds)
 * Sorts the related documents by date (descending order)
 * @param relatedDocuments
 */
export function sortRelatedDocumentsByDate(relatedDocuments: BusinessDocumentRelation[]): BusinessDocumentRelation[] {
  const sortedRelatedDocuments = deepClone(relatedDocuments)
  sortedRelatedDocuments.sort((el1: BusinessDocumentRelation, el2: BusinessDocumentRelation): number => {
    if (el1.createdDate.unixSeconds < el2.createdDate.unixSeconds) {
      return 1
    }
    if (el1.createdDate.unixSeconds > el2.createdDate.unixSeconds) {
      return -1
    }
    
    return 0
  })

  return sortedRelatedDocuments
}

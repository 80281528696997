<script lang="ts">
    import Invoice from '../../models/invoice'
    import InvoiceTimeline from '../action/InvoiceTimeline.svelte'
    import { onMount } from 'svelte'
    import { updateNewNavigationHistoryItem } from '../../../core-app/stores/navigationHistory.store'

    export let invoice: Invoice

    onMount(()=>{
      updateNewNavigationHistoryItem('ViewInvoiceActivity.svelte')
    })
</script>

<div data-ut-component="view-business-document-activity">
    <div class="py-7">
        <div class="px-12 sm:px-8">
            <InvoiceTimeline
                    invoiceId={invoice.dataId}
                    class=""/>
        </div>
    </div>
</div>
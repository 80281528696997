<script lang="ts">
  import {formatCurrencyWithOptions} from '$voxy/utils/number-formatting-utils'
  import {t} from '../i18n/i18nextWrapper'
  import StatDownOrUpArrow from '../../../bank-app/components/stats/StatDownOrUpArrow.svelte'
  import * as d3 from 'd3'
  import {afterUpdate, onMount} from 'svelte'
  import DSO from "$core/lib/dashboard/DSO.svelte"
  import type {CollateralCalculusForDashboard} from "$core/services/dashboard-update-calculus"
  import {v4 as uuidv4} from "uuid";
  import {DunningInvoicesStore} from "$dundy/stores/dunning-invoices.store";
  import {SimpleDocumentsStore} from "$voxy/stores/business-documents.store";
  import {ValueForCurrentMonthAlongWithVariationMonthToMonth} from "$core/models/dashboard-statistics-analytics-model";

  /** Export let */
  export let currency: string
  export let title: string
  export let data: ValueForCurrentMonthAlongWithVariationMonthToMonth
  export let hasTooltip: boolean = false
  export let svgData: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  export let computedCalculusForDashboard: CollateralCalculusForDashboard

  /** Local variables */
  let id = uuidv4()
  let svg: SVGElement
  let gradientId: string = `graph-gradient-${id}-${data?.variationFavorability}`

  /** Reactive declarations */

  $: if (svgData || computedCalculusForDashboard || $DunningInvoicesStore || $SimpleDocumentsStore) {
    generateKPISVG()
  }

  const generateKPISVG = () => {

    d3.select(svg)
      .selectAll('*').remove()

    const width: number = 300
    const height: number = 70

    const gradientColors: any = {
      'favorable': ['#40FFCE', '#40FFCE00'],
      'unfavorable': ['#F97676', '#F9767600'],
      'neutral': ['#60A5FA', '#60A5FA00']
    }

    // Check if svgData is empty or not structured as expected
    const isEmptyData = !Object.keys(svgData).length || Object.values(svgData).every(val => val === 0);

    // Ensure the months are ordered chronologically
    const orderedMonths: string[] = Object.keys(svgData).sort((a, b) =>
      +new Date(+a.split('-')[0], +a.split('-')[1] - 1) - +new Date(+b.split('-')[0], +b.split('-')[1] - 1),
    )

    const dataArray: any[] = isEmptyData ? Array(10).fill(0) : orderedMonths.map(month => svgData[month]);

    const color: any = gradientColors[data?.variationFavorability]

    const xScale = d3.scaleLinear()
      .domain([0, dataArray?.length - 1])
      .range([0, width])

    const yDomain = d3.extent(dataArray);
    const yPadding = (yDomain[1] - yDomain[0]) * 0.2;
    const yScale = d3.scaleLinear()
      .domain([yDomain[0] - yPadding, yDomain[1] + yPadding])
      .range([height, 0])

    const line = d3.line()
      .x((d, i) => xScale(i))
      .y(d => yScale(d))
      .curve(d3.curveBasis)

    d3.select(svg)
      .selectAll('*').remove()

    d3.select(svg)
      .append('path')
      .datum(dataArray)
      .attr('fill', 'none')
      .attr('stroke', color[0])
      .attr('stroke-width', '2')
      .attr('stroke-linecap', 'round')
      .attr('d', line)
  }

  onMount(() => {
    generateKPISVG()
  })

  afterUpdate(() => {
    generateKPISVG();
  });

</script>

<div class="grid-item-wrapper">
  <div class="grid-item-main flex-1">
    <div class="grid-item" aria-label="Grid item">
      <div class="relative z-10 w-full">
        <div class="w-full flex items-center justify-between">
          <div class="flex items-center space-x-1">
            <dt class="text-snuff truncate -mt-1">{title}</dt>
            {#if hasTooltip}
              <div class="z-[600]">
                <slot name="tooltip"></slot>
              </div>
            {/if}
          </div>
          <dd class="-mt-1">
            {#if data?.variationFavorability !== 'neutral'}
              <span class="inline-flex items-center px-1.5 py-0.5 space-x-1 text-s text-white">
                  <span class="sr-only"> Increased by </span>{data?.monthToMonthVariationPercentage}
                <StatDownOrUpArrow direction={data?.variationDirection} favorability={data?.variationFavorability}/>
              </span>
            {/if}
          </dd>
        </div>
        <div class="relative w-full z-20 min-h-[70px] my-2">
          <svg bind:this={svg}
               id={gradientId}
               class="absolute w-full h-full z-0"
               viewBox="0 0 300 70"
               preserveAspectRatio="none"
               style="visibility: {svgData ? 'visible' : 'hidden'};"></svg>
        </div>

        <div class="">
          <dd class="w-full flex-none text-3xl leading-10 tracking-tight text-white truncate">
            {formatCurrencyWithOptions(data?.currentMonthValue, t('locales'), currency, false, 'symbol')}
          </dd>
          <dd class="w-full flex text-sm leading-6 space-x-1">
            <span class="text-paleSky">{t('transactions.lastMonth')}</span>
            <span
              class="text-white">{formatCurrencyWithOptions(data?.previousMonthValue, t('locales'), currency, false, 'symbol')}</span>
          </dd>
        </div>
      </div>
    </div>
  </div>
  <div class="grid-item-aside">
    <ul class="space-y-3">
      <li>
        <div class="flex flex-col flex-grow">
          <div
            class="text-sm text-baliHai">{t('todo.account_position.outstandingInvoices', {count: computedCalculusForDashboard?.numberOfOutstandingInvoices})}</div>
          <div class="text-lg text-white">{computedCalculusForDashboard?.numberOfOutstandingInvoices}</div>
        </div>
      </li>
      <li>
        <div class="flex flex-col flex-grow">
          <div
            class="text-sm text-baliHai">{t('todo.account_position.clientsOverdue', {count: computedCalculusForDashboard?.numberOfOverdueClients})}</div>
          <div class="text-lg text-white">{computedCalculusForDashboard?.numberOfOverdueClients}</div>
        </div>
      </li>
      <li>
        <DSO {computedCalculusForDashboard}/>
      </li>
    </ul>
  </div>
</div>

<style lang="postcss">
  .grid-item-wrapper {
    @apply relative flex flex-row items-stretch justify-between shadow-md lg:flex-grow;
    transition: all 300ms ease-in-out;
    border-radius: 20px;
  }

  .grid-item-main {
    @apply flex-1 gap-2 px-4 py-6 sm:px-8 bg-black flex-grow;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .grid-item-aside {
    @apply flex-none gap-2 px-4 py-6 sm:px-6 border-l border-rhino bg-[#1A1F38] sm:min-w-[340px] md:min-w-[0] lg:min-w-[240px];
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
</style>

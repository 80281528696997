<script lang="ts">
  import type { Customer } from '$crm/models/customer'
  import { link } from 'svelte-routing'
  import DisplayCustomerOverdueAmount from '../../../crm-app/lib/customer/DisplayCustomerOverdueAmount.svelte'
  import { CustomersHelper } from '$crm/helpers/customers-helper'
  import { t } from '../i18n/i18nextWrapper'
  import { createEventDispatcher } from 'svelte'
  import DisplayCustomerNameAndLateInvoices from './DisplayCustomerNameAndLateInvoices.svelte'

  /** Export declarations */
  export let overdueClients: Customer[]

  /** Type */
  type overdueClient = {
    customer: Customer,
    overdueAmount: number
  }

  /** Local declarations */
  let sortedOverdueClients: overdueClient[]
  let isThereOneOverdueClient: boolean = false

  const dispatch = createEventDispatcher()
  const selectRow = (rowData: any) => {
    dispatch('selectRow', rowData.company)
  }

  $: {
    // Calculate overdue amounts for each client, sort them, and then limit the list to 5 items
    sortedOverdueClients = overdueClients?.map(customer => ({
      customer,
      overdueAmount: CustomersHelper.calculateOverdueAmount(customer)
    }))
      .sort((a, b) => b.overdueAmount - a.overdueAmount)
      .slice(0, 4) // Limit the array to the first 4 elements

    isThereOneOverdueClient = sortedOverdueClients.length > 0
  }

</script>
<div class="main-debtors-module">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto flex justify-between items-center py-8 px-6">
      <h1 class="text-base leading-6 text-black">{t('dashboard.debtorsModule.title')}</h1>
    </div>
  </div>
  {#if sortedOverdueClients.length > 0}
    <ul role="list" class="divide-y divide-athensGray overflow-y-auto overflow-x-hidden min-h-72 lg:min-h-full">
      {#each sortedOverdueClients as { customer: overdueClient }}
        <li class="py-3 bg-transparent hover:bg-whisper cursor-pointer px-6">
          <button class="flex justify-between items-center w-full text-left"
                  on:click={() => selectRow(overdueClient)}
                  aria-labelledby="listbox-item-0"
                  aria-label="Select client">
            <DisplayCustomerNameAndLateInvoices classes="" customer={overdueClient}/>
            <DisplayCustomerOverdueAmount customer={overdueClient}/>
          </button>
        </li>
      {/each}

    </ul>
    <span
      class="w-full absolute bottom-0 mx-auto bg-black text-center text-xs text-white py-2 leading-6 hover:text-zinc-300 flex items-center justify-center cursor-pointer">
      <a class="text-center" href="/clients/manage" use:link>{t('dashboard.debtorsModule.seeAll')}</a>
    </span>
  {:else if isThereOneOverdueClient}
    <div class="grid my-12 place-items-center w-full z-[100] px-3 text-center">
      <h3 class="text-sm font-semibold leading-6 text-black">{t('dashboard.debtorsModule.noMoreDebtors.title')}</h3>
      <p class="mt-1 text-xs leading-5 text-gray-500">{t('dashboard.debtorsModule.noMoreDebtors.subtitle')}</p>
    </div>
  {:else}
    <div class="grid mt-4 mb-6 place-items-center w-full z-[100] px-3 text-center">
      <img src="/img/dashboard/main-debtors-empty.png" alt="{t('dashboard.debtorsModule.NoDebtorsYet.copy')}"
           class="h-32 mb-6">
      <p class="my-3 text-s leading-5 text-black px-12">{t('dashboard.debtorsModule.NoDebtorsYet.copy')}</p>
    </div>
  {/if}

</div>
<style lang="postcss">
  .main-debtors-module {
    @apply border border-athensGray overflow-hidden h-full relative;
    border-radius: 20px;
  }
</style>
import { v4 as uuidv4 } from 'uuid'
import type { CashApplicationDeclaredEvent } from '../models/cash-application-declared-model'
import type Invoice from '../../dundy-app/models/invoice'
import type { BBBTransaction } from '../../bank-app/models/bbb-transactions-model'
import { ExchangeDate } from '../../core-app/models/exchange-date'
import { NewAmountOfMoney } from '../models/amount-of-money-model'

export class CashApplicationContext {
  WorkspaceId: string
  RequesterId: string
  BankName: string
  BankId: string
  BankAccountName: string
  BankAccountId: string
}

export function newCashApplicationContext(workspaceId: string, requesterId: string, bankName: string, bankId: string, bankAccountName: string, bankAccountId: string): CashApplicationContext {
  return <CashApplicationContext>{
    WorkspaceId: workspaceId,
    RequesterId: requesterId,
    BankName: bankName,
    BankId: bankId,
    BankAccountName: bankAccountName,
    BankAccountId: bankAccountId
  }
}

/**
 * Create a new cash application declared event
 * @param transaction
 * @param invoice
 * @param cashApplicationContext
 */
export function newCashApplicationDeclaredEvent(transaction: BBBTransaction, invoice: Invoice, cashApplicationContext: CashApplicationContext): CashApplicationDeclaredEvent {
  return <CashApplicationDeclaredEvent>{
    cashApplication: {
      applicationId: uuidv4(),
      appliedAmount: NewAmountOfMoney(invoice.amountIncludingTaxes, 2, invoice.currency),
      bankTransaction: {
        amount: NewAmountOfMoney(transaction.amount, 2, transaction.currency_code),
        bankAccountId: cashApplicationContext.BankAccountId,
        bankAccountName: cashApplicationContext.BankAccountName,
        bankId: cashApplicationContext.BankId,
        bankName: cashApplicationContext.BankName,
        cleanDescription: transaction.clean_description,
        extractDate: ExchangeDate.newDateRFC3339(transaction.updated_at),
        origin: 'BBB',
        originVersion: '1.0',
        rawDescription: transaction.bank_description,
        transactionDate: ExchangeDate.newDateRFC3339(transaction.updated_at),
        transactionId: transaction.id.toString()
      },
      created: ExchangeDate.newDate(new Date()),
      creatorEndpoint: '',
      creatorFunction: '',
      customerId: invoice.toId,
      invoiceAmount: NewAmountOfMoney(invoice.amountIncludingTaxes, 2, invoice.currency),
      invoiceAmountDueAfterApplication: NewAmountOfMoney(0.0, 2, invoice.currency),
      invoiceAmountDueBeforeApplication: NewAmountOfMoney(invoice.amountIncludingTaxes, 2, invoice.currency),
      invoiceDataId: invoice.dataId,
      invoiceNumber: invoice.invoiceNumber,
      requesterId: cashApplicationContext.RequesterId,
      workspaceId: cashApplicationContext.WorkspaceId
    },
    created: Math.round((new Date()).getTime() / 1000.0),
    createdRFC3339: new Date().toISOString(),
    creatorEndpoint: 'Dundy Web App',
    creatorFunction: '',
    customerId: invoice.toId,
    eventId: uuidv4(),
    eventKind: 'CashApplicationDeclared',
    invoiceDataId: invoice.dataId,
    invoiceNumber: invoice.invoiceNumber,
    userId: cashApplicationContext.RequesterId,
    workspaceId: cashApplicationContext.WorkspaceId
  }
}
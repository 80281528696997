<script lang="ts">
    import type { BusinessDocumentLineItem } from '../../../models/business-document'
    import { createEventDispatcher, onMount } from 'svelte'
    import type { DispatchOptions } from 'svelte'
    import { clickOutside } from '../../../../core-app/util/layout-utils'

    /** Export let */
    export let label: string
    export let placeholder: string
    export let optional: string
    export let favoriteBusinessDocumentLineItems: BusinessDocumentLineItem[]
    export let item: BusinessDocumentLineItem

    /** Set isLoading */
    let isLoading: boolean = false

    /** Set search Results */
    let searchResults: any[] = []

    /** Set search term */
    let searchTerm: string = ''

    /** Set showDropdown */
    let showDropdown: boolean

    /** Set binded div element for click outside */
    let div: HTMLDivElement

    /** Set copy of favoriteBusinessDocumentLineItems */
    let itemsCopy: BusinessDocumentLineItem[] = []

    /** Set result index */
    let selectedResultIndex: number = -1

    /** Set an id for the input element */
    let id: string = Math.random().toString(36)

    /** create the event dispatcher */
    const dispatch: <EventKey extends Extract<keyof any, string>>(
      type: EventKey,
      detail?: any,
      options?: DispatchOptions
    ) => boolean = createEventDispatcher()

    /** Reactive declarations */
    $: if (favoriteBusinessDocumentLineItems && favoriteBusinessDocumentLineItems.length > 0) {
      itemsCopy = favoriteBusinessDocumentLineItems.slice()
    }

    /**
     * Searches the items array for items that match the search term.
     */
    function search(): void {
      if (searchTerm.trim() === '') {
        searchResults = itemsCopy
        
        return
      }
      searchResults = itemsCopy.filter(p =>
        p.supplierReference.toLowerCase().includes(searchTerm.toLowerCase()),
      )
      if (searchResults.length > 0) {
        selectedResultIndex = 0
      } else {
        selectedResultIndex = -1
      }
      // Display the dropdown only if there are items in the list
      showDropdown = searchResults.length > 0
    }

    /**
     * Dispatches the selectItem event to update the item in the parent component.
     * @param selectedItem
     */
    function selectItem(selectedItem: BusinessDocumentLineItem) {
      dispatch('selectItem', { item: selectedItem })
      showDropdown = false
      selectedResultIndex = -1
    }

    /**
     * Handles input events on the input field.
     * @param event
     */
    function handleInput(event: Event): void {
      searchTerm = (event.target as HTMLInputElement).value
      search()

      // Display the dropdown only if there are items in the list
      showDropdown = searchResults.length > 0
    }

    /**
     * Handles keydown events on the input field.
     * @param event
     */
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        event.preventDefault()
        if (searchResults.length > 0 && selectedResultIndex !== -1) {
          item = searchResults[selectedResultIndex]
          selectItem(item)
        } else if (searchTerm.trim() !== '') {
          item.title = searchTerm.trim()
          selectItem(item)
        }
        showDropdown = false
        selectedResultIndex = -1
      } else if (event.key === 'ArrowDown') {
        event.preventDefault()
        if (selectedResultIndex < searchResults.length - 1) {
          selectedResultIndex += 1
        } else {
          selectedResultIndex = 0
        }
      } else if (event.key === 'ArrowUp') {
        event.preventDefault()
        if (selectedResultIndex > 0) {
          selectedResultIndex -= 1
        } else {
          selectedResultIndex = searchResults.length - 1
        }
      }
    }

    /**
     * Handles focus events on the input field.
     */
    function handleFocus() {
      showDropdown = true
      search()
    }

    /**
     * Handles blur events on the input field.
     */
    function handleBlur() {
      showDropdown = false
      selectedResultIndex = -1
    }

    /**
     * Closes the dropdown when clicking outside of the component.
     */
    function handleClickOutside() {
      showDropdown = false
    }

    /**
     * Sets the active row.
     * @param index
     */
    const setActiveRow = (index: number) => {
      selectedResultIndex = index
    }

    onMount(() => {
      // Register the event listener
      div.addEventListener('click_outside', handleClickOutside as EventListener)
      
      return () => {
        // Optional: Unregister the event listener when the component is destroyed
        div.removeEventListener('click_outside', handleClickOutside as EventListener)
      }
    })
</script>

<div class="flex flex-1 w-96 px-1 flex-col pr-1">
    <label for="{label}-{id}" class="block text-sm font-normal text-black">{label} <span
            class="text-xs">{optional}</span></label>
    <div class="relative"
         bind:this={div}
         use:clickOutside>
        <input
                id="{label}-{id}"
                type="text"
                bind:value={item.supplierReference}

                on:input={handleInput}
                on:focus={handleFocus}
                on:keydown={handleKeyDown}
                on:blur={handleBlur}
                placeholder={placeholder}
                class="invoice-input mt-1.5"
        />
        {#if isLoading}
            <div class="loader">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-dundyOrange"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                            stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
        {/if}
        {#if showDropdown && searchResults.length > 0}
            <ul class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                id="options" role="listbox">
                {#each searchResults as result, i}
                    <li class="dropdown-item"
                        class:active={selectedResultIndex === i}
                        id="option-{i}"
                        role="option"
                        tabindex="-1"
                        on:click={() => selectItem(result)}
                        on:mousedown={() => selectItem(result)}
                        on:mouseenter={() => setActiveRow(i)}>
                        <span class="text-xs text-zinc-600">{result.supplierReference} - </span> {result.title}
                    </li>
                {/each}
            </ul>
        {/if}
    </div>
</div>
<style lang="postcss">
    .invoice-input {
        @apply border-t border-b border-r border-loblolly box-border bg-white focus:ring-2 focus:ring-dundyOrange focus:border-transparent text-s h-10 px-3 py-2 w-full;
        border-radius: 5px;
    }

    .loader {
        @apply absolute right-0 top-1 mr-1 mt-3;
    }

    .dropdown-item {
        @apply relative cursor-default select-none py-3 pl-3 mx-1.5 pr-9 text-gray-900 hover:bg-whisper rounded-md
    }

    .dropdown-item.active {
        @apply bg-whisper;
    }
</style>
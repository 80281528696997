<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper.js'
    import { BBBTransaction } from '../../../bank-app/models/bbb-transactions-model'
    import { formatCurrencyWithOptions } from '../../../voxy-app/utils/number-formatting-utils'
    import { onMount } from 'svelte'
    import mixpanel from 'mixpanel-browser'

    /** Exported variables */
    export let selectedTransaction: BBBTransaction
    export let isTransactionFullyCashApplied: boolean
    export let amountLeftToAllocateOnTransaction: number
    export let transactionAmountWithSign: string
    export let transactionDate: string
    export let amountCashApplied: string
    export let transactionAmountWithoutSign: string
    export let percentageCashApplied: number

    /** Local variables */
    let amountLeftToAllocateOnTransactionFormatted: string

    /** Reactive declarations */
    $: amountLeftToAllocateOnTransactionFormatted = formatCurrencyWithOptions(amountLeftToAllocateOnTransaction, t('locales'), selectedTransaction?.currency_code, true)

    onMount(()=>{
      mixpanel.track('KA20 Open Cash Application Panel', {
        'Description': 'Open CashApplicationTransactionInfoPanel.svelte'
      })
    })

</script>


<div class="flex flex-col justify-center items-center bg-black shadow border border-athensGray rounded-lg my-8 py-8">
    {#if !isTransactionFullyCashApplied}
        <span class="inline-flex items-center rounded-md bg-peachCream border border-bourbon px-2.5 py-0.5 text-xs font-medium text-bourbon">{t('cashApplication.status.toCashApply')}</span>
    {:else}
        <span class="inline-flex items-center rounded-md bg-frenchLilac border border-grape px-2.5 py-0.5 text-xs font-medium text-grape">{t('cashApplication.status.cashApplied')}</span>
    {/if}
    <div class="flex flex-col justify-center items-center space-y-1">
        <h1 class="text-white text-3xl relative flex items-center mt-2">{ transactionAmountWithSign }</h1>
        <div class="text-zinc-100 text-sm font-light truncate">{ selectedTransaction?.clean_description }</div>
        <div class="text-zinc-500 text-s font-light truncate">{ transactionDate }</div>
    </div>

    <hr class="border-t border-zinc-700 h-0.5 w-full my-4">

    <div class="flex mb-4">
        <div class="flex flex-col text-center space-y-0.5">
            <p class="text-base"><span class="text-white text-xl"> { amountCashApplied}</span> <span class="text-zinc-300 text-xl">/</span>  <span class="text-zinc-300">{transactionAmountWithoutSign }</span></p>
            {#if amountLeftToAllocateOnTransaction > 0}
                <p class="text-dundyOrange text-s">{t('cashApplication.leftToAllocate')} {amountLeftToAllocateOnTransactionFormatted}</p>
            {/if}
        </div>
    </div>
    <div class="w-full px-6 mx-1">
        <div class="w-full h-2 bg-zinc-600 rounded-full">
            <div class="percentageBar"
                 class:percentageBar--cashApplied={ isTransactionFullyCashApplied }
                 style="width: { percentageCashApplied }%">
            </div>
        </div>

    </div>

</div>
<style lang="postcss">
    .percentageBar {
        @apply h-full text-center text-xs text-white bg-dundyOrange rounded-full;
        transition: all .5s cubic-bezier(.55, 0, .1, 1) .2s;
        will-change: width;
    }

    .percentageBar--cashApplied {
        @apply bg-bermuda;
    }
</style>
import { dateToIso8601, dateToRfc3339, dateToTimestamp } from '$src/shared/utils/date'
import { ExchangeDate } from '$src/core-app/models/exchange-date'
import { JsonProperty } from '@paddls/ts-serializer'

export class DayMonth {
  @JsonProperty('day') day: number
  @JsonProperty('month') month: number
  constructor(day: number = 0, month:number = 0) {
    this.day = day
    this.month = month
  }
  static readonly fromString = (dayMonth:string):DayMonth => {
    const day = Number(dayMonth.slice(0, 2))
    const month = Number(dayMonth.slice(2))
    
    return new DayMonth(day, month)
  }
}

export class FiscalYearDetails {
  @JsonProperty({ field: 'fyClosingDay', type: () => DayMonth }) fyClosingDay: DayMonth
  @JsonProperty({ field: 'firstFYClosingDate', type: () => ExchangeDate }) firstFYClosingDate: ExchangeDate
  constructor(fyClosingDay?:DayMonth, firstFYClosingDate?:ExchangeDate) {
    if (fyClosingDay) this.fyClosingDay = fyClosingDay
    if (firstFYClosingDate) this.firstFYClosingDate = firstFYClosingDate
  }
  static readonly fromString = (fyClosingDay: string, firstFYClosingDate:string):FiscalYearDetails => {
    let closingDay: DayMonth
    let firstClosingDay: ExchangeDate

    if (fyClosingDay && firstFYClosingDate) {
      closingDay = DayMonth.fromString(fyClosingDay)
      firstClosingDay = ExchangeDate.fromString(firstFYClosingDate)
    } else {
      const tmp = FiscalYearDetails.empty()
      closingDay = tmp.fyClosingDay
      firstClosingDay = tmp.firstFYClosingDate
    }
    
    return new FiscalYearDetails(closingDay, firstClosingDay)
  }
  static readonly empty = ():FiscalYearDetails => <FiscalYearDetails>{
    fyClosingDay: new DayMonth(),
    firstFYClosingDate: new ExchangeDate()
  }
}

export class MailAddress {
  @JsonProperty('street') street: string
  @JsonProperty('extraAddressLine') extraAddressLine: string
  @JsonProperty('city') city: string
  @JsonProperty('zipCode') zipCode: string
  @JsonProperty('state') state: string
  @JsonProperty('country') country: string
  @JsonProperty('countryCode') countryCode: string
  static readonly empty = ():MailAddress => <MailAddress>{
    street: '',
    extraAddressLine: '',
    city: '',
    zipCode: '',
    state: '',
    country: 'France',
    countryCode: 'FR'
  }
}

export class FrNAFActivityCodeAndLabel {
  @JsonProperty('code') code: string
  @JsonProperty('descFr') descFr: string
}

export class FrActivityNAF {
  @JsonProperty({ field: 'section', type: () => FrNAFActivityCodeAndLabel }) section: FrNAFActivityCodeAndLabel
  @JsonProperty({ field: 'level1Top', type: () => FrNAFActivityCodeAndLabel }) level1Top: FrNAFActivityCodeAndLabel 
  @JsonProperty({ field: 'level2Middle', type: () => FrNAFActivityCodeAndLabel }) level2Middle: FrNAFActivityCodeAndLabel
  @JsonProperty({ field: 'level3Detailed', type: () => FrNAFActivityCodeAndLabel }) level3Detailed: FrNAFActivityCodeAndLabel
  @JsonProperty({ field: 'level4FullyDetailed', type: () => FrNAFActivityCodeAndLabel }) level4FullyDetailed: FrNAFActivityCodeAndLabel
  @JsonProperty('mainActivityNAFStandards') mainActivityNAFStandards: string
}

export class FrINSEECompanyHeadcount {
  @JsonProperty('headcountRangeCode') headcountRangeCode: string
  @JsonProperty('headcountRangeLabelIntl') headcountRangeLabelIntl: string
  @JsonProperty('headcountMin') headcountMin: number
  @JsonProperty('headcountMax') headcountMax: number
  @JsonProperty('headcountYearYYYY') headcountYearYYYY: string
}

export class FrINSEECompanyName {
  @JsonProperty('moralPersonAdminName') moralPersonAdminName: string
  @JsonProperty('moralPersonUsualName') moralPersonUsualName: string
  @JsonProperty('physicalPersonAdminName') physicalPersonAdminName: string
  @JsonProperty('physicalPersonUsualName') physicalPersonUsualName: string
  @JsonProperty('physicalPersonSex') physicalPersonSex: string
}

export class FrLegalFormData {
  @JsonProperty('level1CatCode') level1CatCode: number
  @JsonProperty('level1CatCodeRaw') level1CatCodeRaw: string
  @JsonProperty('level1CatFr') level1CatFr: string
  @JsonProperty('level2CatCode') level2CatCode: number
  @JsonProperty('level2CatCodeRaw') level2CatCodeRaw: string
  @JsonProperty('level2CatFr') level2CatFr: string
  @JsonProperty('level3CatCode') level3CatCode: number
  @JsonProperty('level3CatCodeRaw') level3CatCodeRaw: string
  @JsonProperty('level3CatFr') level3CatFr: string
  static readonly empty = ():FrLegalFormData => ({
    level1CatCode: 0,
    level1CatCodeRaw: '',
    level1CatFr: '',
    level2CatCode: 0,
    level2CatCodeRaw: '',
    level2CatFr: '',
    level3CatCode: 0,
    level3CatCodeRaw: '',
    level3CatFr: ''
  })
}

export class FrINSEECompanyDetails {
  @JsonProperty('nonPublicInformation') nonPublicInformation: boolean
  @JsonProperty({ field: 'name', type: () => FrINSEECompanyName }) name: FrINSEECompanyName
  @JsonProperty({ field: 'headcount', type: () => FrINSEECompanyHeadcount }) headcount: FrINSEECompanyHeadcount
  @JsonProperty({ field: 'activity', type: () => FrActivityNAF }) activity: FrActivityNAF
  @JsonProperty({ field: 'legalForm', type: () => FrLegalFormData }) legalForm: FrLegalFormData
  @JsonProperty('postalAddress') postalAddress: MailAddress
  @JsonProperty('regDateRaw') regDateRaw: string
}

export class FrCompanyRegulatory {
  @JsonProperty('eligibleToVAT') eligibleToVAT: boolean
  @JsonProperty('isAutoEntrepreneur') isAutoEntrepreneur: boolean
  @JsonProperty('adminName') adminName: string
  @JsonProperty('usualName') usualName: string
  @JsonProperty('siren') siren: string
  @JsonProperty('siret') siret: string
  @JsonProperty('rcs') rcs: string
  @JsonProperty('nafCodeLevel4') nafCodeLevel4: string
  @JsonProperty('legalFormCodeLevel2') legalFormCodeLevel2: number
  @JsonProperty('legalFormCodeLevel3') legalFormCodeLevel3: number
  @JsonProperty({ field: 'inseeData', type: () => FrINSEECompanyDetails }) inseeData: FrINSEECompanyDetails
}

export class EuCompanyRegulatory {
  @JsonProperty('euIntraVat') euIntraVat: string
}

export class CompanyRegulatory {
  @JsonProperty('isEuRegulated') isEuRegulated: boolean
  @JsonProperty({ field: 'frRegulScope', type: () => FrCompanyRegulatory }) frRegulScope: FrCompanyRegulatory | null
  @JsonProperty({ field: 'euRegulScope', type: () => EuCompanyRegulatory }) euRegulScope: EuCompanyRegulatory | null
}

export default class Company {
  @JsonProperty('companyId') companyId: string
  @JsonProperty('formalName') formalName: string
  @JsonProperty('usualName') usualName: string
  @JsonProperty('registrationPending') registrationPending: boolean
  @JsonProperty({ field: 'registrationDate', type: () => ExchangeDate }) registrationDate: ExchangeDate
  @JsonProperty({ field: 'fyDetails', type: () => FiscalYearDetails }) fyDetails: FiscalYearDetails | null
  @JsonProperty({ field: 'regulatory', type: () => CompanyRegulatory }) regulatory: CompanyRegulatory
  @JsonProperty('shareCapital') shareCapital: string
  @JsonProperty('timeZoneIANACode') timeZoneIANACode: string
  @JsonProperty({ field: 'mailAddress', type: () => MailAddress }) mailAddress: MailAddress
  @JsonProperty('emailLogoURL') emailLogoURL: string
  @JsonProperty({ field: 'createdDate', type: () => ExchangeDate }) createdDate: ExchangeDate
  @JsonProperty({ field: 'modifiedDate', type: () => ExchangeDate }) modifiedDate: ExchangeDate
  static readonly empty = (id?:string):Company => {
    const d = new Date()
    const dateUnix = dateToTimestamp(d)
    const dateRfc3339 = dateToRfc3339(d)
    const dateIso8601 = dateToIso8601(d)

    return <Company>{
      companyId: id ?? '',
      formalName: '',
      usualName: '',
      registrationPending: true,
      registrationDate: {
        unixSeconds: dateUnix,
        rfc3339: dateRfc3339
      },
      fyDetails: FiscalYearDetails.empty(),
      regulatory: {
        isEuRegulated: false,
        euRegulScope: {
          euIntraVat: ''
        },
        frRegulScope: {
          eligibleToVAT: false,
          isAutoEntrepreneur: false,
          adminName: '',
          usualName: '',
          siren: '',
          siret: '',
          rcs: '',
          nafCodeLevel4: '',
          legalFormCodeLevel2: 0,
          inseeData: {
            nonPublicInformation: false,
            name: {
              moralPersonAdminName: '',
              moralPersonUsualName: '',
              physicalPersonAdminName: '',
              physicalPersonUsualName: '',
              physicalPersonSex: ''
            },
            headcount: {
              headcountRangeCode: '',
              headcountRangeLabelIntl: '',
              headcountMin: 0,
              headcountMax: 0,
              headcountYearYYYY: ''
            },
            activity: {
              section: {
                code: '',
                descFr: ''
              },
              level1Top: {
                code: '',
                descFr: ''
              },
              level2Middle: {
                code: '',
                descFr: ''
              },
              level3Detailed: {
                code: '',
                descFr: ''
              },
              level4FullyDetailed: {
                code: '',
                descFr: ''
              },
              mainActivityNAFStandards: ''
            },
            legalForm: {
              level1CatCode: 0,
              level1CatCodeRaw: '',
              level1CatFr: '',
              level2CatCode: 0,
              level2CatCodeRaw: '',
              level2CatFr: '',
              level3CatCode: 0,
              level3CatCodeRaw: '',
              level3CatFr: ''
            },
            postalAddress: MailAddress.empty(),
            regDateRaw: dateIso8601
          }
        }
      },
      shareCapital: '',
      timeZoneIANACode: 'Europe/Paris',
      mailAddress: MailAddress.empty(),
      emailLogoURL: '',
      createdDate: {
        unixSeconds: dateUnix,
        rfc3339: dateRfc3339
      },
      modifiedDate: {
        unixSeconds: dateUnix,
        rfc3339: dateRfc3339
      }
    }
  }
}
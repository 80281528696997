import { APICallOptions, apiDelete, APIEntity, apiGet, apiPost, apiPut } from '../../core-app/services/api.service'
import { get } from 'svelte/store'
import { eventsManager } from '../../core-app/events/event-manager'
import { EventType } from '../../core-app/events/event-type'
import { authZJsUserId } from '../../core-app/lib/auth0authentication/authStore'
import { WorkspaceHelper } from '../helpers/workspace-helper'
import type { Workspace } from '../models/workspace'
import { CustomersStore } from '../stores/customers.store'
import { navigate } from 'svelte-routing'
import { NewWorkspaceRequestedByUserWorkspaceId } from '../stores/workspace.store'
import type NewWorkspaceCreatedResponse from '../models/create-workspace-response'
import { feedbackService } from '../../core-app/services/feedback.service'
import { t } from '../../core-app/lib/i18n/i18nextWrapper'
import type { Feedback } from '../../core-app/models/feedback'
import type PersistedWorkspace from '../models/persisted-workspace'
import type PersistedWorkspacesAndWorkspaceId from '../models/persisted-workspaces-and-workspace-id'
import { stateManagementService } from '../../core-app/services/state-management.service'
import { deepClone } from '../../core-app/util/object-deep-cloning'
import type { DundyEvent } from '../../dundy-app/events/dundy-event'
import type Company from '../models/company'
import type {
  WorkspaceCompanyUpdatedResponse,
  WorkspaceIdAndWorkspaceCompanyUpdatedResponse
} from '../models/workspace-company-updated-response'
import { navigateToMissingOnboardingStepIfNeeded } from '../../core-app/services/onboarding.service'
import { ApiOkStatusResponse } from '../../core-app/models/api-standard-responses'
import mixpanel from 'mixpanel-browser'
import customLog from '$src/shared/services/custom-log.service'

class WorkspacesServiceClass {

  initialized: boolean
  initialize(): void {
    /* console.log('WorkspacesServiceClass initialize()') */
    if (!this.initialized) {

      this.initialized = true

      /**
       * When the workspace bank account is changed,
       * we need to update the workspace
       */
      eventsManager.on<Workspace>(EventType.WORKSPACE_BANK_ACCOUNT_CHANGED, (e: DundyEvent<Workspace>) => {
        if (!!e.data && e.data.workspaceId) {
          this.saveBankAccount(e.data)
            .then(() => {
              eventsManager.emit(EventType.WORKSPACE_BANK_ACCOUNT_SAVED, null, 'WorkspaceService')
              console.log('Workspace saved OK')
            })
            .catch((reason) => {
              console.log('saving Workspace with error', reason)
            })
        }
      }, 'workspace.service.ts')

      /**
       * When a new team member is deleted,
       * we need to update the workspace
       */
      eventsManager.on<any>(EventType.WORKSPACE_TEAM_MEMBER_DELETED, (e: DundyEvent<any>) => {
        if (!!e.data && e.data.oldWorkspaceTeamUserId) {
          this.deleteTeamMember(e.data.newWorkspace, e.data.oldWorkspaceTeamUserId)
            .then(() => {
              // handle successful save
            })
            .catch(error => {
              // handle error during save
              /* console.error(error) */
            })
        }
      }, 'workspace.service.ts')

      /**
       * When the company is changed, we need to update the workspace
       */
      eventsManager.on<Workspace>(EventType.WORKSPACE_COMPANY_CHANGED, (e: DundyEvent<Workspace>) => {
        if (!!e.data && e.data.workspaceId) {
          this.updateWorkspaceCompany(e.data.workspaceId, e.data.company)
            .then(() => {
              // handle successful update
            })
            .catch(error => {
              // handle error during update
              /* console.error(error) */
            })
        }
      }, 'workspace.service.ts')


      eventsManager.on<Workspace>(EventType.WORKSPACE_TEST_END_TO_END_CHANGED, (e: DundyEvent<Workspace>) => {
        if (!!e.data && e.data.workspaceId) {
          // let's save the added workspace
          // formerly this.saveTestEndToEndStatus(e.data);
          this.createAndSaveNewWorkspaceThenTryAgain(e.data)
        }
      }, 'workspace.service.ts')

      /**
       * Workspace added
       */
      eventsManager.on<Workspace>(EventType.WORKSPACE_ADDED, (e: DundyEvent<Workspace>) => {
        /* console.log('eventsManager.on<Workspace>(EventType.WORKSPACE_ADDED, e => {', e.data) */
        // let's save the added workspace
        // formerly this.createNewWorkspaceForNewUserAsOwner(e.data);
        this.createAndSaveNewWorkspaceThenTryAgain(e.data)
      }, 'workspace.service.ts')

      eventsManager.on(EventType.INVOICE_MARKED_COMPLETED, () => {
        eventsManager.on(EventType.INVOICE_LIST_CHANGED, () => {
          eventsManager.once<Workspace>(EventType.WORKSPACE_FETCHED, (e: DundyEvent<Workspace>) => {
            CustomersStore.set(e.data.customers)
          }, 'workspace.service.ts')
          this.initializeWorkspaceFlowBasedOnAuth(get(authZJsUserId))
        }, 'workspace.service.ts')
      }, 'workspace.service.ts')

      /**
       * Initialize the workspace flow
       */
      this.initializeWorkspaceFlowBasedOnAuth(get(authZJsUserId))
    }
  }
  uriPostWorkspace(ownerId: string): string {
    return `/owner/${ownerId}/workspace`
  }
  uriGetWorkspacesAsOwner(ownerId: string): string {
    return `/owners/${ownerId}/workspaces`
  }
  uriGetWorkspacesAsTeamUser(teamUserId: string): string {
    return `/team-users/${teamUserId}/workspaces`
  }
  uriPutWorkspaceCompany(ownerId: string, workspaceId: string): string {
    return `/owner/${ownerId}/workspace/${workspaceId}/company`
  }

  /* uriPutWorkspaceTestEndToEndStatus(ownerId: string, workspaceId: string): string {
         return `/owner/${ownerId}/workspace/${workspaceId}/test-end-to-end-status`;
     }*/

  uriPutWorkspaceTeamUserInvitation(workspaceId: string): string {
    return `/workspaces/${workspaceId}/team-users`
  }
  uriDeleteWorkspaceTeamUser(workspaceId: string, userId: string): string {
    return `/workspaces/${workspaceId}/team-users/${userId}`
  }
  uriPutWorkspaceBankAccount(ownerId: string, workspaceId: string): string {
    return `/owner/${ownerId}/workspace/${workspaceId}/bank-account`
  }
  /**
   * Fetches all workspaces from the backend for the current user.
   * If the user has no workspaces, a new one is created.
   * If the user has only one workspace, it is automatically selected.
   */
  fetchAllUserWorkspacesFromBackEnd(): Promise<Workspace[]> {
    /* console.log('WorkspacesServiceClass fetchAllUserWorkspacesFromBackEnd()') */

    return Promise.all([
      // expected to return [] instead of error 404 for no workspace as owner found
      apiGet<Array<PersistedWorkspace>>(this.uriGetWorkspacesAsOwner(get(authZJsUserId)), <APICallOptions>{
        entity: APIEntity.WORKSPACE,
        ignoreFeedback: true
      }),
      // expected to return [] instead of error 404 for no workspace as team-user found
      apiGet<Array<PersistedWorkspace>>(this.uriGetWorkspacesAsTeamUser(get(authZJsUserId)), <APICallOptions>{
        entity: APIEntity.WORKSPACE,
        ignoreFeedback: true
      })
    ])
      .then((responses: PersistedWorkspace[][]) => {
        /* console.log('WORKSPACES AS TEAM USER', responses[1]) */
        /* console.log('WORKSPACES AS OWNER', responses[0]) */
        let ownerWorkspaces: PersistedWorkspace[] = responses[0]
        let teamUserWorkspaces: PersistedWorkspace[] = responses[1]
        const allWorkspaces: Workspace[] = <Workspace[]>([
          ...ownerWorkspaces.map((w: PersistedWorkspace) => w.workspace),
          ...teamUserWorkspaces.map((w: PersistedWorkspace) => w.workspace)
        ].sort((a: Workspace, b: Workspace): number => {
          if (a.company?.formalName?.toLowerCase() < b.company?.formalName?.toLowerCase()) {
            return -1
          }
          if (b.company?.formalName?.toLowerCase() < a.company?.formalName?.toLowerCase()) {
            return 1
          }

          return 0
        }))
        eventsManager.emit(EventType.WORKSPACE_LIST_FETCHED, allWorkspaces, 'workspace.service.ts')

        return allWorkspaces
      })
      .catch((reason) => {
        console.log('WorkspacesServiceClass fetchAllUserWorkspacesFromBackEnd() Promise.All() UNEXPECTED error: reason=', reason)

        return Promise.reject('fetchAllUserWorkspacesFromBackEnd failed: ' + reason)
      })
  }
  /**
   * POST a new Workspace from scratch, gather the created workspaceId
   * the GET the created empty Workspace that contains the userId, the workspaceId and the companyId
   */
  getNewBlankWorkspaceFromBackEnd(callerInfo: string): Promise<Workspace> {
    /* console.log('%c !!!= WorkspacesServiceClass getNewBlankWorkspaceFromBackEnd from caller', 'color: orange; font-size: 1.6em', callerInfo) */
    const blankWorkspace: Workspace = WorkspaceHelper.newWorkspace(get(authZJsUserId))

    return apiPost<NewWorkspaceCreatedResponse>(this.uriPostWorkspace(get(authZJsUserId)), { workspace: blankWorkspace }, <APICallOptions>{
      entity: APIEntity.WORKSPACE,
      ignoreFeedback: true
    })
      .then((response: NewWorkspaceCreatedResponse) => {
        const newWorkspaceId: string = response.createdWorkspaceId
        /* console.log('!!!= \'POST\' apiPost<NewWorkspaceCreatedResponse> returned workspaceId', newWorkspaceId) */

        return apiGet<Array<PersistedWorkspace>>(this.uriGetWorkspacesAsOwner(get(authZJsUserId)), <APICallOptions>{
          entity: APIEntity.WORKSPACE,
          ignoreFeedback: true
        })
          .then((persistedWorkspacesAsOwner: Array<PersistedWorkspace>) =>
          /* console.log('!!!= \'POST\' apiPost<NewWorkspaceCreatedResponse> returned workspaceId and new list of workspaces as owner', newWorkspaceId, persistedWorkspacesAsOwner) */

            <PersistedWorkspacesAndWorkspaceId>{
              persistedWorkspacesAsOwner: persistedWorkspacesAsOwner,
              newWorkspaceId: newWorkspaceId
            }
          )
      })
      .then((persistedWorkspacesAsOwnerAndNewWorkspaceId: PersistedWorkspacesAndWorkspaceId) => {
        const newWorkspaceReadBack: PersistedWorkspace = persistedWorkspacesAsOwnerAndNewWorkspaceId.persistedWorkspacesAsOwner
          .find((p: PersistedWorkspace): boolean => (p.workspaceId === persistedWorkspacesAsOwnerAndNewWorkspaceId.newWorkspaceId)) || {} as PersistedWorkspace
        /* console.log('!!!= \'POST\' apiPost<NewWorkspaceCreatedResponse> found corresponding PersistedWorkspace with searched workspaceId and found id and found id', newWorkspaceReadBack, persistedWorkspacesAsOwnerAndNewWorkspaceId.newWorkspaceId, newWorkspaceReadBack.workspaceId, newWorkspaceReadBack.workspace.workspaceId) */

        return newWorkspaceReadBack?.workspace || {} as Workspace
      })
  }
  /**
   * Add a new workspace with a company
   * returns the workspaceId and the WorkspaceCompanyUpdatedResponse
   * @param newCompany
   * @param caller
   */
  addNewWorkspaceWithCompany(newCompany: Company, caller: string): Promise<Workspace> {
    return this.getNewBlankWorkspaceFromBackEnd('addNewWorkspaceWithCompany from: ' + caller)
      .then((newWorkspace: Workspace) => {
        const correctedCompany: Company = <Company>{ ...newCompany, companyId: newWorkspace.company.companyId }

        return this.updateWorkspaceCompany(newWorkspace.workspaceId, correctedCompany)
          .then((workspaceCompanyUpdateResponse: WorkspaceCompanyUpdatedResponse) => <WorkspaceIdAndWorkspaceCompanyUpdatedResponse>{
            workspaceId: newWorkspace.workspaceId,
            workspaceCompanyUpdateResponse: workspaceCompanyUpdateResponse
          })
      })
      .then((updateResponseAndWorkspaceId: WorkspaceIdAndWorkspaceCompanyUpdatedResponse) => this.fetchAllUserWorkspacesFromBackEnd()
        .then((allWorkspacesFromBackEnd: Workspace[]) => allWorkspacesFromBackEnd.find((aWorkspace: Workspace): boolean => aWorkspace.workspaceId === updateResponseAndWorkspaceId.workspaceId)))
  }
  /**
   * Former way to create a workspace
   * @deprecated
   */
  createAndSaveNewWorkspace(ignoreFeedback: boolean, callerInfo: string, workspace?: Workspace): Promise<Workspace> {
    /* console.log('%c WorkspacesServiceClass createAndSaveNewWorkspace', 'color: blue; font-size: 1.2em') */
    /* console.log('%c !!!= WorkspacesServiceClass createAndSaveNewWorkspace from caller', 'color: purple; font-size: 1.6em', callerInfo) */
    if (!workspace) {
      /* console.log('%c WorkspacesServiceClass !workspace', 'color: blue; font-size: 1.2em') */
      workspace = WorkspaceHelper.newWorkspace(get(authZJsUserId))
    }
    if (workspace.company.timeZoneIANACode === '') {
      /* console.log('abnormal new workspace without timeZoneIANACode - fixing it here') */
      workspace.company.timeZoneIANACode = 'Europe/Paris'
    }
    /* console.log('%c WorkspacesServiceClass createAndSaveNewWorkspace apiPost<NewWorkspaceCreatedResponse>', 'color: blue; font-size: 1.2em') */
    /* console.log('!!!= \'POST\' apiPost<NewWorkspaceCreatedResponse> workspace', workspace) */
    /* console.log('!!!= we should never be able to create more than a blank workspace. We can eventually update the workspace company.') */

    return apiPost<NewWorkspaceCreatedResponse>(this.uriPostWorkspace(get(authZJsUserId)), { workspace }, <APICallOptions>{
      entity: APIEntity.WORKSPACE,
      ignoreFeedback: ignoreFeedback
    })
      .then((response: NewWorkspaceCreatedResponse) => {
        const newWorkspaceId: string = response.createdWorkspaceId
        /* console.log('!!!= \'POST\' apiPost<NewWorkspaceCreatedResponse> returned workspaceId', newWorkspaceId) */

        return newWorkspaceId
      }).then((newWorkspaceId: string) => apiGet<Array<PersistedWorkspace>>(this.uriGetWorkspacesAsOwner(get(authZJsUserId)), <APICallOptions>{
        entity: APIEntity.WORKSPACE,
        ignoreFeedback: true
      }).then((persistedWorkspacesAsOwner: Array<PersistedWorkspace>) =>
      /* console.log('!!!= \'POST\' apiPost<NewWorkspaceCreatedResponse> returned workspaceId and \'GET\' workspace back', newWorkspaceId, persistedWorkspacesAsOwner) */

        <PersistedWorkspacesAndWorkspaceId>{
          persistedWorkspacesAsOwner: persistedWorkspacesAsOwner,
          newWorkspaceId: newWorkspaceId
        }
      ))
      .then((persistedWorkspacesAsOwnerAndNewWorkspaceId: PersistedWorkspacesAndWorkspaceId) => {

        // workspace.workspaceId = response.createdWorkspaceId;
        const newWorkspaceReadBack: PersistedWorkspace = persistedWorkspacesAsOwnerAndNewWorkspaceId.persistedWorkspacesAsOwner
          .find((p: PersistedWorkspace): boolean => (p.workspaceId === persistedWorkspacesAsOwnerAndNewWorkspaceId.newWorkspaceId))
        /* console.log('!!!= \'POST\' apiPost<NewWorkspaceCreatedResponse> found corresponding PersistedWorkspace with searched workspaceId and found id and found id', newWorkspaceReadBack, persistedWorkspacesAsOwnerAndNewWorkspaceId.newWorkspaceId, newWorkspaceReadBack.workspaceId, newWorkspaceReadBack.workspace.workspaceId) */

        return newWorkspaceReadBack.workspace
      })
      .then((newWorkspaceLoadedBack: Workspace) => {
        /* console.log('!!!= \'POST\' apiPost<NewWorkspaceCreatedResponse> emit event EventType.WORKSPACE_CREATED based upon workspace', newWorkspaceLoadedBack) */
        eventsManager.emit(EventType.WORKSPACE_CREATED, newWorkspaceLoadedBack, 'workspace.service.ts')

        return newWorkspaceLoadedBack
      })
      .then((endWorkspace: Workspace) => {
        const finalResultingBlankSavedThenReadBackWorkspace: Workspace = <Workspace>deepClone(endWorkspace)
        /* console.log('!!!= \'POST\' apiPost<NewWorkspaceCreatedResponse> end workspace', finalResultingBlankSavedThenReadBackWorkspace) */
        /* console.log('%c Workspace created', 'color: blue; font-size: 1.2em', finalResultingBlankSavedThenReadBackWorkspace) */

        return finalResultingBlankSavedThenReadBackWorkspace
      })
  }
  /**
   * Update a workspace company
   * @param workspaceId
   * @param company
   */
  updateWorkspaceCompany(workspaceId: string, company: Company): Promise<WorkspaceCompanyUpdatedResponse> {
    return apiPut<WorkspaceCompanyUpdatedResponse>(this.uriPutWorkspaceCompany(get(authZJsUserId), workspaceId), { workspaceCompany: { company: company } }, <APICallOptions>{
      entity: APIEntity.WORKSPACE
    })
  }
  /**
   * Invite a team member to a workspace
   * @param workspace
   * @param email
   * @param firstName
   * @param lastName
   */
  public inviteTeamMember(workspace: Workspace, email: string, firstName: string, lastName: string): Promise<ApiOkStatusResponse> {
    return apiPut(this.uriPutWorkspaceTeamUserInvitation(workspace.workspaceId), {
      workspaceTeamUserInvitation: {
        advancedInvitations: [
          { email, firstName, lastName, invitationType: 'TeamUser' }
        ]
      }
    }, <APICallOptions>{
      entity: APIEntity.WORKSPACE
    })
  }

  /*private createAndSaveNewWorkspaceThenTryAgain(workspace?: Workspace) {
        console.log("WorkspacesServiceClass createNewWorkspace()", workspace);
        this.createAndSaveNewWorkspace(true, "workspace.service.ts createAndSaveNewWorkspaceThenTryAgain()", workspace)
            .then((newWorkspace: Workspace) => {
                console.log('%c !!!!= GOOD passing NewWorkspaceRequestedByUserWorkspaceId from newWorkspace to newFetchOrCreateNewWorkspace()', 'color: red; font-size: 1.8em', newWorkspace);
                if (!!newWorkspace && !!newWorkspace.workspaceId && newWorkspace.workspaceId !== "") {
                    NewWorkspaceRequestedByUserWorkspaceId.set(newWorkspace.workspaceId);
                }
                this.newFetchOrCreateNewWorkspace()
                    .then((w: Workspace) => {
                        this.changeToSelectedWorkspace(w.workspaceId, '/today', false, '');
                        setTimeout(() => {
                            navigateToMissingOnboardingStepIfNeeded(false)
                        }, 2000)
                    })
                    .catch(reason => {
                        console.error('workspacesService.initialize() createAndSaveNewWorkspaceThenTryAgain() this.createAndSaveNewWorkspace.then()... failed:', reason)
                    });
            })
            .catch(error => {
                if (error.status === 403) {
                    console.log('navigate in', 'workspace.service.ts createAndSaveNewWorkspaceThenTryAgain()', ': at window.location.pathname=', window.location.pathname, 'nextNavigationURI=', '/signin?verifyEmail=true');
                    navigate('/signin?verifyEmail=true');
                } else {
                    feedbackService.displayFeedback(<Feedback>{
                        feedbackLevel: 'Warning',
                        feedbackMessage: t('editWorkspace.errorCreatingWorkspace')
                    })
                }
            });
    }*/

  /**
   * How does changing workspaces work ?
   * to move the app to a new workspace,
   * - we need to set the store variable NewWorkspaceRequestedByUserWorkspaceId
   * - then we stateManagementService.reset()
   *  - which will reset all initialization variables like initializationStore,
   *  - then will do a new initialization again with stateManagementService.initialize()
   *      - which will run newFetchOrCreateNewWorkspace() where the store variable NewWorkspaceRequestedByUserWorkspaceId is used to load the new chosen workspace
   * @param newChosenWorkspaceId
   * @param nextNavigationURI
   * @param needFeedback
   * @param feedbackTMessageKey
   */
  changeToSelectedWorkspace(newChosenWorkspaceId: string, nextNavigationURI: string, needFeedback: boolean, feedbackTMessageKey: string) {
    /* console.log('changeToSelectedWorkspace()', newChosenWorkspaceId, nextNavigationURI) */
    NewWorkspaceRequestedByUserWorkspaceId.set(newChosenWorkspaceId)
    stateManagementService.reset('in SelectWorkspace.svelte !!!!!!, chosen new workspace and currently running handleSelectWorkspace()')
    /* console.log('about to navigate to ', nextNavigationURI) */

    customLog.log('SelectNewWorkspace')

    setTimeout(() => {
      /* console.log('navigate in', 'workspace.service.ts changeToSelectedWorkspace()', ': at window.location.pathname=', window.location.pathname, 'nextNavigationURI=', nextNavigationURI) */
      navigate(nextNavigationURI)
    }, 1000)
    /* console.log('now in ', nextNavigationURI) */
    if (needFeedback) {
      feedbackService.displayFeedback(<Feedback>{
        feedbackLevel: 'Success',
        feedbackMessage: t(feedbackTMessageKey)
      })
    }
  }
  /**
   * Launching the workspace flow
   * If the user is logged in, let's fetch the workspace
   * If information is missing, let's go back to the onboarding
   * Else let's go to select-workspace
   *
   * @param authZJsUserIdKey
   * @private
   */
  private initializeWorkspaceFlowBasedOnAuth(authZJsUserIdKey: string): void {
    if (authZJsUserIdKey) {
      this.newFetchOrCreateNewWorkspace()
        .then((w: Workspace) => {
          if (w) {
            this.changeToSelectedWorkspace(w.workspaceId, '/today', false, '')
            setTimeout(() => {
              navigateToMissingOnboardingStepIfNeeded()
            }, 2000)
          } else {
            navigate('select-workspace')
          }
        })
        .catch(reason => {
          /* console.error('workspacesService.initialize() if (get(authZJsUserId)) newFetchOrCreateNewWorkspace() failed:', reason) */
        })
    }
  }
  /**
   *  Master Routing method for fetching or creating a new workspace.
   *  Calls three helper methods: fetchSingleWorkspaceFromList, handleSingleWorkspace, and createNewWorkspaceForNewUserAsOwner based on different conditions.
   *  It fetches all user workspaces from the backend and processes them accordingly.
   *  NB: if no "obvious" workspace is found, the <SelectWorkspace/> will show up in Home.svelte unless there is just one existing Workspace to choose from (then de facto this is the one)
   *  NB 2 : the promises don't resolve if an event is fired because it would create an infinite loop; only when no workspace is fired, the promise does resolve to null
   * @private
   */
  private newFetchOrCreateNewWorkspace(): Promise<Workspace> {
    /* console.log('WorkspacesServiceClass newFetchOrCreateNewWorkspace()') */

    return this.fetchAllUserWorkspacesFromBackEnd()
      .then((allWorkspaces: Workspace[]) => {
        const currentUserId: string = get(authZJsUserId)
        const workspacesAsTeamUser: Workspace[] = allWorkspaces.filter((w: Workspace): boolean => w.ownerId !== currentUserId)
        const workspacesAsOwner: Workspace[] = allWorkspaces.filter((w: Workspace): boolean => w.ownerId === currentUserId)
        const requestedWorkspaceId: string = get(NewWorkspaceRequestedByUserWorkspaceId)

        if (requestedWorkspaceId) {
          return this.fetchSingleWorkspaceFromList(requestedWorkspaceId, workspacesAsTeamUser, workspacesAsOwner)
        } else if (workspacesAsTeamUser.length + workspacesAsOwner.length === 1) {
          return this.handleSingleWorkspace(workspacesAsTeamUser, workspacesAsOwner)
        } else if (workspacesAsTeamUser.length + workspacesAsOwner.length === 0) {
          return this.createNewWorkspaceForNewUserAsOwner(workspacesAsTeamUser, workspacesAsOwner)
        }
      })
      .catch((error: string) =>
      /* console.error('Failed to fetch or create new workspace: ', error) */

        Promise.resolve(null)
      )
  }
  /**
   * Handles the case when there's only one workspace to choose from.
   * It emits the appropriate events and returns the selected workspace.
   * if there's no workspace, it resolves to null.
   * @param workspacesAsTeamUser
   * @param workspacesAsOwner
   * @private
   */
  private handleSingleWorkspace(workspacesAsTeamUser: Workspace[], workspacesAsOwner: Workspace[]): Promise<Workspace> {
    return new Promise<Workspace>((resolve): void => {

      let selectedWorkspace: Workspace

      if (workspacesAsOwner.length) {
        selectedWorkspace = workspacesAsOwner[0]
        mixpanel.track('BM20 Load New Workspace as owner', {
          'Description': 'Loading a new workspace as owner',
          'workspaceId': selectedWorkspace?.workspaceId,
          'workspaceCompany': selectedWorkspace?.company?.formalName
        })
        mixpanel.register({
          'workspaceId': selectedWorkspace?.workspaceId,
          'workspaceCompany': selectedWorkspace?.company?.formalName,
          'workspaceAsOwner': true,
          'workspaceAsTeamUser': false,
          'source': 'only related workspace'
        })
        eventsManager.emit(EventType.WORKSPACE_FETCHED, selectedWorkspace, 'workspace.service.ts')
        eventsManager.emit(EventType.WORKSPACE_FETCHED_AS_OWNER, selectedWorkspace, 'workspace.service.ts')
      } else if (workspacesAsTeamUser.length) {
        selectedWorkspace = workspacesAsTeamUser[0]
        mixpanel.track('BM25 Load New Workspace as Team User', {
          'Description': 'Loading a new workspace as team user',
          'workspaceId': selectedWorkspace?.workspaceId,
          'workspaceCompany': selectedWorkspace?.company?.formalName
        })
        mixpanel.register({
          'workspaceId': selectedWorkspace?.workspaceId,
          'workspaceCompany': selectedWorkspace?.company?.formalName,
          'workspaceAsOwner': false,
          'workspaceAsTeamUser': true,
          'source': 'only related workspace'
        })
        eventsManager.emit(EventType.WORKSPACE_FETCHED, selectedWorkspace, 'workspace.service.ts')
        eventsManager.emit(EventType.WORKSPACE_FETCHED_AS_TEAM_USER, selectedWorkspace, 'workspace.service.ts')
      } else {
        NewWorkspaceRequestedByUserWorkspaceId.set('')
        selectedWorkspace = undefined
        resolve(selectedWorkspace)
      }
    })
  }
  /**
   * Fetches a single workspace based on the requested workspace ID.
   * If the requested workspace is found either as a team workspace or an owner workspace,
   * it emits the corresponding events and returns the selected workspace.
   * If the requested workspace is not found, the promise resolves to null.
   * @param requestedWorkspaceId
   * @param workspacesAsTeamUser
   * @param workspacesAsOwner
   * @private
   */
  private fetchSingleWorkspaceFromList(requestedWorkspaceId: string, workspacesAsTeamUser: Workspace[], workspacesAsOwner: Workspace[]): Promise<Workspace | null> {
    return new Promise<Workspace | null>((resolve) => {
      let selectedWorkspace: Workspace | null = null

      if (requestedWorkspaceId) {
        // Try to find the workspace in the owner's workspaces
        const ownerWorkspace: Workspace = workspacesAsOwner.find((w: Workspace): boolean => w.workspaceId === requestedWorkspaceId)
        if (ownerWorkspace) {
          selectedWorkspace = ownerWorkspace
          mixpanel.track('BM20 Load New Workspace as owner', {
            'Description': 'Loading a new workspace as owner',
            'workspaceId': selectedWorkspace?.workspaceId,
            'workspaceCompany': selectedWorkspace?.company?.formalName
          })
          mixpanel.register({
            'workspaceId': selectedWorkspace?.workspaceId,
            'workspaceCompany': selectedWorkspace?.company?.formalName,
            'workspaceAsOwner': true,
            'workspaceAsTeamUser': false,
            'source': 'workspace selected from list'
          })
          eventsManager.emit(EventType.WORKSPACE_FETCHED, selectedWorkspace, 'workspace.service.ts')
          eventsManager.emit(EventType.WORKSPACE_FETCHED_AS_OWNER, selectedWorkspace, 'workspace.service.ts')
        } else {
          // If not found, try to find the workspace in the team user's workspaces
          const teamWorkspace: Workspace = workspacesAsTeamUser.find((w: Workspace): boolean => w.workspaceId === requestedWorkspaceId)
          if (teamWorkspace) {
            selectedWorkspace = teamWorkspace
            mixpanel.track('BM25 Load New Workspace as Team User', {
              'Description': 'Loading a new workspace as team user',
              'workspaceId': selectedWorkspace?.workspaceId,
              'workspaceCompany': selectedWorkspace?.company?.formalName
            })
            mixpanel.register({
              'workspaceId': selectedWorkspace?.workspaceId,
              'workspaceCompany': selectedWorkspace?.company?.formalName,
              'workspaceAsOwner': false,
              'workspaceAsTeamUser': true,
              'source': 'only related workspace'
            })
            eventsManager.emit(EventType.WORKSPACE_FETCHED, selectedWorkspace, 'workspace.service.ts')
            eventsManager.emit(EventType.WORKSPACE_FETCHED_AS_TEAM_USER, selectedWorkspace, 'workspace.service.ts')
          }
        }
      }

      // If no workspace was found with the provided ID, resolve the promise with a null value
      if (!selectedWorkspace) {
        /* console.log('No workspace found with the provided ID.') */
        resolve(null)
      }
    })
  }
  /**
   * Creates a new workspace if there are no workspaces for the user.
   * It calls getNewBlankWorkspaceFromBackEnd to create a new workspace, emits the appropriate events which returns the newly created workspace.
   * If the workspace creation fails, it resolves to null.
   * @param workspacesAsTeamUser
   * @param workspacesAsOwner
   * @private
   */
  private createNewWorkspaceForNewUserAsOwner(workspacesAsTeamUser: Workspace[], workspacesAsOwner: Workspace[]): Promise<Workspace | null> {
    return new Promise((resolve) => {
      if ((workspacesAsTeamUser.length + workspacesAsOwner.length) === 0) {
        /* console.log('!!!- IN CASE 6-!!!', 'we create a new workspace as owner: } else { this.createNewWorkspaceForNewUserAsOwner();') */
        this.getNewBlankWorkspaceFromBackEnd('newFetchOrCreateNewWorkspace case 5 - first time sign-in no workspace')
          .then((newlyCreatedWorkspace: Workspace) => {
            mixpanel.track('BM10 Create New Workspace', {
              'Description': 'Creating a new workspace',
              'workspaceId': newlyCreatedWorkspace?.workspaceId,
              'workspaceCompany': newlyCreatedWorkspace?.company?.formalName
            })
            mixpanel.register({
              'workspaceId': newlyCreatedWorkspace?.workspaceId,
              'workspaceCompany': newlyCreatedWorkspace?.company?.formalName
            })
            eventsManager.emit(EventType.WORKSPACE_FETCHED, newlyCreatedWorkspace, 'workspace.service.ts')
            eventsManager.emit(EventType.WORKSPACE_FETCHED_AS_OWNER, newlyCreatedWorkspace, 'workspace.service.ts')

          })
          .catch((reason: string) => {
            /* console.error('failed to create new empty workspace from scratch: ' + reason) */
            resolve(null)
          })
      } else {
        resolve(null)
      }
    })
  }

  /*private saveTestEndToEndStatus(workspace: Workspace) {
        return apiPut(this.uriPutWorkspaceTestEndToEndStatus(get(authZJsUserId), workspace.workspaceId), { testEndToEnd: workspace.testEndToEnd }, <APICallOptions>{
            entity: APIEntity.WORKSPACE
        });
    }*/

  /**
   * CreateAndSaveNewWorkspaceThenTryAgain() is called when the user has no workspace
   * formerly createNewWorkspaceForNewUserAsOwner() / this.createNewWorkspaceForNewUserAsOwner();
   * todo : remove deprecated createAndSaveNewWorkspace function in favor of newFetchOrCreateNewWorkspace
   */
  private createAndSaveNewWorkspaceThenTryAgain(workspace?: Workspace) {
    /* console.log('WorkspacesServiceClass createNewWorkspace()', workspace) */
    this.newFetchOrCreateNewWorkspace()
      .then((newWorkspace: Workspace) => {
        /* console.log('%c !!!!= GOOD passing NewWorkspaceRequestedByUserWorkspaceId from newWorkspace to newFetchOrCreateNewWorkspace()', 'color: red; font-size: 1.8em', newWorkspace) */
        if (!!newWorkspace && !!newWorkspace.workspaceId && newWorkspace.workspaceId !== '') {
          NewWorkspaceRequestedByUserWorkspaceId.set(newWorkspace.workspaceId)
        }
        this.newFetchOrCreateNewWorkspace()
          .then((w: Workspace) => {
            this.changeToSelectedWorkspace(w.workspaceId, '/today', false, '')
            setTimeout(() => {
              navigateToMissingOnboardingStepIfNeeded()
            }, 2000)
          })
          .catch(reason => {
            /* console.error('workspacesService.initialize() createAndSaveNewWorkspaceThenTryAgain() this.createAndSaveNewWorkspace.then()... failed:', reason) */
          })
      })
      .catch(error => {
        if (error.status === 403) {
          /* console.log('navigate in', 'workspace.service.ts createAndSaveNewWorkspaceThenTryAgain()', ': at window.location.pathname=', window.location.pathname, 'nextNavigationURI=', '/signin?verifyEmail=true') */
          navigate('/signin?verifyEmail=true')
        } else {
          feedbackService.displayFeedback(<Feedback>{
            feedbackLevel: 'Warning',
            feedbackMessage: t('editWorkspace.errorCreatingWorkspace')
          })
        }
      })
  }
  private saveBankAccount(workspace: Workspace): Promise<ApiOkStatusResponse> {
    /*console.log('%c saveBankAccount, input bank config %s', 'color:purple;font-size: 1.5em;', JSON.stringify({ bankConfig: workspace.bankConfig }, null, 3))*/
    return apiPut(this.uriPutWorkspaceBankAccount(get(authZJsUserId), workspace.workspaceId), { bankConfig: workspace.bankConfig }, <APICallOptions>{
      entity: APIEntity.WORKSPACE
    })
  }
  private deleteTeamMember(workspace: Workspace, userId: string): Promise<ApiOkStatusResponse> {
    return apiDelete(this.uriDeleteWorkspaceTeamUser(workspace.workspaceId, userId), <APICallOptions>{
      entity: APIEntity.WORKSPACE
    })
  }
}

export const workspacesService: WorkspacesServiceClass = new WorkspacesServiceClass()

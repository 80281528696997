<script lang="ts">
    import { AppliedDunningFilters, AppliedFilterItem } from '../../../models/applied-dunning-filters'

    /** Exported variables */
    export let filterData: AppliedFilterItem
    export let bgColor: string
    export let totalOutstandingAmount: number
    export let roundedEdge: string
    export let filters: AppliedDunningFilters
    export let filterKey: string

    const handleClick = () => {
      filters[filterKey].value = !filters[filterKey].value
    }

    /** Check if at least one filter is active */
    $: atLeastOneActive = Object.values(filters).some(filter => filter.value)

    /** Check if this filter is active */
    $: isActive = filters[filterKey]?.value

    /** Computed width of the bar */
    let width: number = filterData.totalAmount / totalOutstandingAmount * 100
</script>

<div
        class={`cursor-pointer overflow-hidden bg-gray-200 ${width === 0 ? 'hidden-bar' : ''} ${roundedEdge} ${atLeastOneActive && !isActive ? 'opacity-30' : ''}`}
        style="width: {width}%"
        role="button"
        tabindex="0"
        on:click={handleClick}
        on:keydown={(e) => e.key === 'Enter' && handleClick()}
>
    <div class={`h-3 ${bgColor} ${roundedEdge}`}></div>
</div>

<style lang="postcss">
    .hidden-bar {
        @apply hidden;
    }
</style>
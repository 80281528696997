import emailBlacklist from '../util/email-blacklist.json'
import type { PasswordValidationDetails } from '../models/errors-and-validation'

/**
 * Validate email with regexp & blacklist
 * return true if email is valid
 * @param email
 */
export function validateEmail(email: string): boolean {
  const regexp: RegExp =
        /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return regexp.test(email.toLowerCase()) && !!validateEmailDomain(email)
}


/**
 * Validate email domain with blacklist
 * return true if email domain is valid
 * @param email
 */
export function validateEmailDomain(email: string): boolean {
  const domain: string = email.split('@')[1]

  return emailBlacklist.indexOf(domain) === -1
}

/**
 * Validate password with regexp
 * @param password
 */
export function validatePassword(password: string): boolean {
  const regexp =
        /(?=^.{12,}$)(?=.*\d)(?=.*[!@#$%^&*_\-";:',.<>/=+{}\[\]()|?\\]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/

  return regexp.test(password)
}


/**
 * Validate password details
 * @param password
 */
export function validatePasswordDetails(password: string): PasswordValidationDetails {
  const minimumChars: boolean = /^.{12,}$/.test(password)
  const upperCaseAndLoweCase: boolean = /(?=.*[a-z])(?=.*[A-Z])/.test(password)
  const oneDigit: boolean = /(?=.*\d)/.test(password)
  const specialChar: boolean = /(?=.*\W)/.test(password)

  return {
    minimumChars,
    upperCaseAndLoweCase,
    oneDigit,
    specialChar
  }
}

/**
 * Validate SIRET/SIREN by calculating number of digits and checking if it's a number
 * return true if SIRET/SIREN is valid
 * @param siret
 */
export function validateSiret(siret: string): boolean {
  const regexp: RegExp = /(^\d{9}$)|(^\d{14}$)/

  return regexp.test(siret)
}

/**
 * Only a filled "siren-siret" for France is mandatory
 */
export const onboardingMandatoryLegalIdentificationTypes: string[] = <string[]>['siren-siret', 'european-vat']

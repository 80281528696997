export enum BusinessDocumentViewListOption {
  DELETED = 'deleted',
  FINALIZED = 'finalized',
  CREDIT_NOTES_AND_VOIDED_INVOICES = 'creditNotesAndVoidedInvoices',
  INVOICES = 'invoices',
  DRAFT = 'draft',
  TO_SEND = 'to_send',
  ALL = 'all',
  VOIDED = 'voided',
  VOIDING_PENDING = 'voidingPending'
}

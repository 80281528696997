import type Timezone from '../models/timezone'
import { t } from '../lib/i18n/i18nextWrapper'

export function getTimezones(): Timezone[] {
  return [
    { label: t('timezonesList.europeParis'), value: 'Europe/Paris' },
    { label: t('timezonesList.europeBerlin'), value: 'Europe/Berlin' },
    { label: t('timezonesList.europeLondon'), value: 'Europe/London' },
    { label: t('timezonesList.europeBudapest'), value: 'Europe/Budapest' },
    { label: t('timezonesList.europeWarsaw'), value: 'Europe/Warsaw' },
    { label: t('timezonesList.utc'), value: 'UTC' },
    { label: t('timezonesList.etcGMT12'), value: 'Etc/GMT+12' },
    { label: t('timezonesList.etcGMT11'), value: 'Etc/GMT+11' },
    { label: t('timezonesList.pacificSamoa'), value: 'Pacific/Samoa' },
    { label: t('timezonesList.pacificHonolulu'), value: 'Pacific/Honolulu' },
    { label: t('timezonesList.pacificMarquesas'), value: 'Pacific/Marquesas' },
    { label: t('timezonesList.usAlaska'), value: 'US/Alaska' },
    { label: t('timezonesList.americaTijuana'), value: 'America/Tijuana' },
    { label: t('timezonesList.americaLosAngeles'), value: 'America/Los_Angeles' },
    { label: t('timezonesList.americaChihuahua'), value: 'America/Chihuahua' },
    { label: t('timezonesList.americaPhoenix'), value: 'America/Phoenix' },
    { label: t('timezonesList.americaDenver'), value: 'America/Denver' },
    { label: t('timezonesList.americaChicago'), value: 'America/Chicago' },
    { label: t('timezonesList.americaGuatemala'), value: 'America/Guatemala' },
    { label: t('timezonesList.americaMexicoCity'), value: 'America/Mexico_City' },
    { label: t('timezonesList.americaRegina'), value: 'America/Regina' },
    { label: t('timezonesList.americaBogota'), value: 'America/Bogota' },
    { label: t('timezonesList.americaNew_York'), value: 'America/New_York' },
    { label: t('timezonesList.americaIndianapolis'), value: 'America/Indianapolis' },
    { label: t('timezonesList.americaCaracas'), value: 'America/Caracas' },
    { label: t('timezonesList.americaHalifax'), value: 'America/Halifax' },
    { label: t('timezonesList.americaAsuncion'), value: 'America/Asuncion' },
    { label: t('timezonesList.americaCuiaba'), value: 'America/Cuiaba' },
    { label: t('timezonesList.americaSantiago'), value: 'America/Santiago' },
    { label: t('timezonesList.americaLa_Paz'), value: 'America/La_Paz' },
    { label: t('timezonesList.americaSt_Johns'), value: 'America/St_Johns' },
    { label: t('timezonesList.americaBuenos_Aires'), value: 'America/Buenos_Aires' },
    { label: t('timezonesList.americaSao_Paulo'), value: 'America/Sao_Paulo' },
    { label: t('timezonesList.americaCayenne'), value: 'America/Cayenne' },
    { label: t('timezonesList.americaMontevideo'), value: 'America/Montevideo' },
    { label: t('timezonesList.americaGodthab'), value: 'America/Godthab' },
    { label: t('timezonesList.etcGMT2'), value: 'Etc/GMT+2' },
    { label: t('timezonesList.atlanticAzores'), value: 'Atlantic/Azores' },
    { label: t('timezonesList.atlanticCapeVerde'), value: 'Atlantic/Cape_Verde' },
    { label: t('timezonesList.africaCasablanca'), value: 'Africa/Casablanca' },
    { label: t('timezonesList.atlanticReykjavik'), value: 'Atlantic/Reykjavik' },
    { label: t('timezonesList.africaLagos'), value: 'Africa/Lagos' },
    { label: t('timezonesList.africaJohannesburg'), value: 'Africa/Johannesburg' },
    { label: t('timezonesList.asiaDamascus'), value: 'Asia/Damascus' },
    { label: t('timezonesList.europeKiev'), value: 'Europe/Kiev' },
    { label: t('timezonesList.africaWindhoek'), value: 'Africa/Windhoek' },
    { label: t('timezonesList.europeMinsk'), value: 'Europe/Minsk' },
    { label: t('timezonesList.europeIstanbul'), value: 'Europe/Istanbul' },
    { label: t('timezonesList.asiaAmman'), value: 'Asia/Amman' },
    { label: t('timezonesList.asiaBeirut'), value: 'Asia/Beirut' },
    { label: t('timezonesList.asiaJerusalem'), value: 'Asia/Jerusalem' },
    { label: t('timezonesList.asiaCairo'), value: 'Asia/Cairo' },
    { label: t('timezonesList.africaCairo'), value: 'Africa/Cairo' },
    { label: t('timezonesList.asiaRiyadh'), value: 'Asia/Riyadh' },
    { label: t('timezonesList.europeMoscow'), value: 'Europe/Moscow' },
    { label: t('timezonesList.asiaBaghdad'), value: 'Asia/Baghdad' },
    { label: t('timezonesList.africaNairobi'), value: 'Africa/Nairobi' },
    { label: t('timezonesList.asiaTehran'), value: 'Asia/Tehran' },
    { label: t('timezonesList.indianMauritius'), value: 'Indian/Mauritius' },
    { label: t('timezonesList.asiaTbilisi'), value: 'Asia/Tbilisi' },
    { label: t('timezonesList.asiaBaku'), value: 'Asia/Baku' },
    { label: t('timezonesList.asiaYerevan'), value: 'Asia/Yerevan' },
    { label: t('timezonesList.asiaDubai'), value: 'Asia/Dubai' },
    { label: t('timezonesList.asiaKabul'), value: 'Asia/Kabul' },
    { label: t('timezonesList.asiaYekaterinburg'), value: 'Asia/Yekaterinburg' },
    { label: t('timezonesList.asiaKarachi'), value: 'Asia/Karachi' },
    { label: t('timezonesList.asiaTashkent'), value: 'Asia/Tashkent' },
    { label: t('timezonesList.asiaCalcutta'), value: 'Asia/Calcutta' },
    { label: t('timezonesList.asiaColombo'), value: 'Asia/Colombo' },
    { label: t('timezonesList.asiaKathmandu'), value: 'Asia/Kathmandu' },
    { label: t('timezonesList.asiaDhaka'), value: 'Asia/Dhaka' },
    { label: t('timezonesList.asiaAlmaty'), value: 'Asia/Almaty' },
    { label: t('timezonesList.asiaNovosibirsk'), value: 'Asia/Novosibirsk' },
    { label: t('timezonesList.asiaRangoon'), value: 'Asia/Rangoon' },
    { label: t('timezonesList.asiaKrasnoyarsk'), value: 'Asia/Krasnoyarsk' },
    { label: t('timezonesList.asiaBangkok'), value: 'Asia/Bangkok' },
    { label: t('timezonesList.asiaUlaanbaatar'), value: 'Asia/Ulaanbaatar' },
    { label: t('timezonesList.australiaPerth'), value: 'Australia/Perth' },
    { label: t('timezonesList.asiaTaipei'), value: 'Asia/Taipei' },
    { label: t('timezonesList.asiaSingapore'), value: 'Asia/Singapore' },
    { label: t('timezonesList.asiaShanghai'), value: 'Asia/Shanghai' },
    { label: t('timezonesList.asiaIrkutsk'), value: 'Asia/Irkutsk' },
    { label: t('timezonesList.asiaPyongyang'), value: 'Asia/Pyongyang' },
    { label: t('timezonesList.australiaEucla'), value: 'Australia/Eucla' },
    { label: t('timezonesList.asiaSeoul'), value: 'Asia/Seoul' },
    { label: t('timezonesList.asiaTokyo'), value: 'Asia/Tokyo' },
    { label: t('timezonesList.asiaYakutsk'), value: 'Asia/Yakutsk' },
    { label: t('timezonesList.australiaDarwin'), value: 'Australia/Darwin' },
    { label: t('timezonesList.australiaAdelaide'), value: 'Australia/Adelaide' },
    { label: t('timezonesList.australiaHobart'), value: 'Australia/Hobart' },
    { label: t('timezonesList.asiaVladivostok'), value: 'Asia/Vladivostok' },
    { label: t('timezonesList.pacificPort_Moresby'), value: 'Pacific/Port_Moresby' },
    { label: t('timezonesList.australiaBrisbane'), value: 'Australia/Brisbane' },
    { label: t('timezonesList.australiaSydney'), value: 'Australia/Sydney' },
    { label: t('timezonesList.australiaLHI'), value: 'Australia/LHI' },
    { label: t('timezonesList.asiaMagadan'), value: 'Asia/Magadan' },
    { label: t('timezonesList.pacificFiji'), value: 'Pacific/Fiji' },
    { label: t('timezonesList.asiaKamchatka'), value: 'Asia/Kamchatka' },
    { label: t('timezonesList.pacificAuckland'), value: 'Pacific/Auckland' },
    { label: t('timezonesList.etcGMTMinus12'), value: 'Etc/GMT-12' },
    { label: t('timezonesList.pacificChatham'), value: 'Pacific/Chatham' },
    { label: t('timezonesList.pacificEnderbury'), value: 'Pacific/Enderbury' },
    { label: t('timezonesList.pacificKiritimati'), value: 'Pacific/Kiritimati' },
    { label: t('timezonesList.indianReunion'), value: 'Indian/Reunion' },
    { label: t('timezonesList.indianMayotte'), value: 'Indian/Mayotte' },
    { label: t('timezonesList.americaGuadeloupe'), value: 'America/Guadeloupe' },
    { label: t('timezonesList.americaMartinique'), value: 'America/Martinique' },
    { label: t('timezonesList.pacificGambier'), value: 'Pacific/Gambier' },
    { label: t('timezonesList.pacificMidway'), value: 'Pacific/Midway' },
    { label: t('timezonesList.pacificWallis'), value: 'Pacific/Wallis' },
    { label: t('timezonesList.pacificTahiti'), value: 'Pacific/Tahiti' },
    { label: t('timezonesList.pacificNoumea'), value: 'Pacific/Noumea' },
    { label: t('timezonesList.antarticaTroll'), value: 'Antarctica/Troll' }
  ]
}
<script lang="ts">
  import { createEventDispatcher } from 'svelte'

  export let list:Readonly<number[]> = []
  export let selected:number

  const dispatch = createEventDispatcher()

  const onSelect = (item:number) => {
    selected = item
    dispatch('change', item)
  }
</script>

<div class="per-page">
  {#each list as item}
    <button class:selected={item === selected} on:click={() => onSelect(item)}>
      {item}
    </button>
  {/each}
</div>

<style lang="postcss">
  .per-page {
    @apply rounded border;

    button {
      @apply py-1 px-2 min-w-12;

      &:not(:first-child) {
        @apply border-l;
      }

      &:hover {
        @apply bg-gray-50;
      }

      &.selected {
        @apply bg-gray-100;
      }
    }
  }
</style>


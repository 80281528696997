import { BusinessDocument, BusinessDocumentRelation, CoreDocument } from '../../models/business-document'
import { BusinessDocumentRelationKind } from '../../enums/business-document-relation-kind'
import {
  filterAllBusinessDocumentsFinalized
} from '../business-document-array-filters/business-documents-filters.service'
import {
  hasAtLeastAnotherFinalizedBusinessDocumentWithSameDeal
} from '../installment/at-least-another-finalized-business-document-with-same-deal.service'

/**
 * Determine if a line item should be locked (non-editable) or not.
 * First loops through all relatedBusinessDocuments.
 * If the relationKind of a BusinessDocumentRelation is INVOICE_ON_INVOICE,
 * it searches the businessDocumentsStore for a BusinessDocument that matches the fromBusinessDocumentId.
 * If it finds such a document, it then loops through its lineItems and checks if any of them have an id that matches lineId.
 * If it finds a match, it returns true. If no match is found after checking all related business documents, it returns false.
 * @param lineId
 * @param businessDocument
 * @param allBusinessDocuments
 * @constructor
 */
export function isLockedLineItem(lineId: string, businessDocument: BusinessDocument, allBusinessDocuments: BusinessDocument[]): boolean {
  return isLockedLineItemListInBusinessDocument(businessDocument, allBusinessDocuments)
}

/**
 * Locked when there is at least another finalized business document with same deal or if deal is whether undefined or irrelevant
 * @param businessDocument
 * @param allBusinessDocuments
 */
export function isLockedLineItemListInBusinessDocument(businessDocument: BusinessDocument, allBusinessDocuments: BusinessDocument[]): boolean {
  return hasAtLeastAnotherFinalizedBusinessDocumentWithSameDeal(businessDocument, allBusinessDocuments)
}

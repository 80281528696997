import type { InvoicesHistoryItem } from '../../dundy-app/models/invoices-history'
import { CashApplicationDeclaredEvent } from '../models/cash-application-declared-model'
import type { InvoiceEvent } from '../../dundy-app/models/invoices-history'
import type { InvoiceNumber, TransactionId } from '../models/cash-application-model'
import { Amount, NewAmountOfMoney } from '../models/amount-of-money-model'
import type { BankTransaction } from '../models/cash-application-declared-model'
import { deepClone } from '../../core-app/util/object-deep-cloning'
import type { BBBTransaction } from '../../bank-app/models/bbb-transactions-model'
import type { CADEStatusForTransaction } from '../models/cades-status-for-transaction'
import { eventsManager } from '../../core-app/events/event-manager'
import { EventType } from '../../core-app/events/event-type'
import { formatCurrencyWithOptions } from '../../voxy-app/utils/number-formatting-utils'
import { ConvertToBankTransaction } from '../../bank-app/models/bbb-transactions-model'
import type { ExchangeDate } from '../../core-app/models/exchange-date'
import { v4 as uuidv4 } from 'uuid'
import type { CADEE } from '../models/cade-excerpt-cadee-model'
import type Invoice from '../../dundy-app/models/invoice'
import { CashApplicationContext, newCashApplicationDeclaredEvent } from './cash-application-helper-pure-functions'


export const filterCADEsForSelectedTransaction = (selectedTransaction: BBBTransaction) => (cadeInUI: CashApplicationDeclaredEvent, index: number, array: CashApplicationDeclaredEvent[]): boolean => cadeInUI.cashApplication.bankTransaction.transactionId === selectedTransaction.id.toString()

export function extractCashApplicationDeclaredEventsFromInvoicesHistory(historyList: InvoicesHistoryItem[]): CashApplicationDeclaredEvent[] {
  /* console.log('extractCashApplicationDeclaredEventsFromInvoicesHistory on ' + historyList.length + ' InvoicesHistoryItem(s)') */
  let cashApplicationDeclaredList: CashApplicationDeclaredEvent[] = <CashApplicationDeclaredEvent[]>[]
  for (let aNewHistoryList of historyList) {
    for (let anEvent of aNewHistoryList.invoiceEvents) {
      if (isCashApplicationDeclaredEvent(anEvent)) {
        const newCashApplicationDeclaredEvent: CashApplicationDeclaredEvent = anEvent as CashApplicationDeclaredEvent
        let foundAlreadyCollectedEvent: boolean = false
        for (let alreadyCollectedItem of cashApplicationDeclaredList) {
          if (alreadyCollectedItem.eventId === anEvent.eventId) {
            foundAlreadyCollectedEvent = true
          }
        }
        if (!foundAlreadyCollectedEvent) {
          cashApplicationDeclaredList.push(newCashApplicationDeclaredEvent)
        }
      }
    }
  }

  return cashApplicationDeclaredList
}

/**
 * Determines if the event is a cash application declared event
 * @param event
 */
export function isCashApplicationDeclaredEvent(event: InvoiceEvent): boolean {
  if (event.eventKind === 'CashApplicationDeclared') {
    return true
  }

  return false
}


/**
 * Calculate the remaining amount to apply for a given transaction except for the given invoice
 * @param invoiceNumber
 * @param invoiceAmount
 * @param allCADEsInStore
 * @param transactionId
 */
export function getRemainingAmountToApplyForInvoiceExceptWithGivenTransaction(invoiceNumber: InvoiceNumber, invoiceAmount: Amount, allCADEsInStore: CashApplicationDeclaredEvent[], /*allCADEsInUI: CashApplicationDeclaredEvent[],*/ transactionId: TransactionId): number {
  let invoiceTotalAmount: number = Amount.PrototypeToClass(invoiceAmount).GetAmountScaledValue()
  let remainingAmountToCashApplyExceptOnTransactionId: number = invoiceTotalAmount

  for (let aCADE of allCADEsInStore) {
    if (aCADE.invoiceNumber === invoiceNumber && aCADE.cashApplication.bankTransaction.transactionId !== transactionId) {
      remainingAmountToCashApplyExceptOnTransactionId = remainingAmountToCashApplyExceptOnTransactionId - Amount.PrototypeToClass(aCADE.cashApplication.appliedAmount).GetAmountScaledValue()
    }
  }

  return remainingAmountToCashApplyExceptOnTransactionId
}

/**
 * Returns the amount left to apply on a transaction, except for the given invoice
 * @param transactionId
 * @param transactionAmount
 * @param allCADEsInUI
 * @param invoiceNumber
 */
export function getRemainingAmountToApplyForTransactionExceptForGivenInvoice(transactionId: TransactionId, transactionAmount: Amount, /*allCADEsInStore: CashApplicationDeclaredEvent[],*/ allCADEsInUI: CashApplicationDeclaredEvent[], invoiceNumber: InvoiceNumber): number {
  let transactionTotalAmount: number = Amount.PrototypeToClass(transactionAmount).GetAmountScaledValue()
  let remainingAmountToCashApplyExceptOnInvoiceNumber: number = transactionTotalAmount
  for (let aCADE of allCADEsInUI) {
    if (aCADE.cashApplication.bankTransaction.transactionId === transactionId && aCADE.invoiceNumber !== invoiceNumber) {

      remainingAmountToCashApplyExceptOnInvoiceNumber = remainingAmountToCashApplyExceptOnInvoiceNumber - Amount.PrototypeToClass(aCADE.cashApplication.appliedAmount).GetAmountScaledValue()
    }
  }

  return remainingAmountToCashApplyExceptOnInvoiceNumber
}

/**
 * Get List of Transactions Requiring Partial or Full Cash Application
 * NB: this does not filter out transactions that are not supposed to be cash applied like spendings (if you only cash apply invoices e.g.)
 * NB: this does not remove transactions that hav been refunded !
 * NB: the returned transactions do not have altered amount (nothing like returning them with the remaining amount to cash apply, but the original amount instead)
 * NB: it just removes transactions which amounts are broght down to zero by the application of the specified CADEs and returns the remaining other transactions
 * Using Amount.PrototypeToClass() to get the method
 * @param allTransactions
 * @param allCashApplicationDeclared
 */
export function getListOfTransactionsRequiringCashApplication(allTransactions: BankTransaction[], allCashApplicationDeclared: CashApplicationDeclaredEvent[]): BankTransaction[] {
  let result: BankTransaction[] = []
  if (allCashApplicationDeclared === undefined || allCashApplicationDeclared === null) {
    return result
  }
  for (let aTransaction of allTransactions) {
    let qualifiedTransaction: BankTransaction
    qualifiedTransaction = deepClone(aTransaction)
    let remaining: number = Amount.PrototypeToClass(qualifiedTransaction.amount).GetAmountScaledValue()
    for (let aCashApplicationDeclaredEvent of allCashApplicationDeclared) {
      if (aCashApplicationDeclaredEvent.cashApplication.bankTransaction.transactionId === aTransaction.transactionId) {
        remaining -= Amount.PrototypeToClass(aCashApplicationDeclaredEvent.cashApplication.appliedAmount).GetAmountScaledValue()
      }
    }
    if (remaining !== 0) {
      result.push(qualifiedTransaction)
    }
  }

  return result
}

export function getListOfTransactionsWithPartialCashApplication(allTransactions: BankTransaction[], allCashApplicationDeclared: CashApplicationDeclaredEvent[]): BankTransaction[] {
  let result: BankTransaction[] = []
  if (allCashApplicationDeclared === undefined || allCashApplicationDeclared === null) {
    return result
  }
  for (let aTransaction of allTransactions) {
    let qualifiedTransaction: BankTransaction
    qualifiedTransaction = deepClone(aTransaction)
    let remaining: number = Amount.PrototypeToClass(qualifiedTransaction.amount).GetAmountScaledValue()
    for (let aCashApplicationDeclaredEvent of allCashApplicationDeclared) {
      if (aCashApplicationDeclaredEvent.cashApplication.bankTransaction.transactionId === aTransaction.transactionId) {
        remaining -= Amount.PrototypeToClass(aCashApplicationDeclaredEvent.cashApplication.appliedAmount).GetAmountScaledValue()
      }
    }
    if (remaining !== 0 && remaining !== aTransaction.amount.scaledValue) {
      result.push(qualifiedTransaction)
    }
  }

  return result
}

/**
 * Helper to sum the applied amounts
 * Assumes that you can sum them (ie they form a compatible set of CADEs for a given transaction or a given invoice e.g.)
 * NB: no coherence check is done!
 * @param array
 * @private
 */
export function sumAppliedAmounts(array: CashApplicationDeclaredEvent[]): number {
  if (!array) {
    return 0
  }
  const sum = array.reduce((acc: number, curr: CashApplicationDeclaredEvent) =>


    acc + (Amount.PrototypeToClass(curr.cashApplication?.appliedAmount).GetAmountScaledValue() && !isNaN(Amount.PrototypeToClass(curr.cashApplication.appliedAmount).GetAmountScaledValue()) ? Amount.PrototypeToClass(curr.cashApplication.appliedAmount).GetAmountScaledValue() : 0)
  , 0)

  return parseFloat(sum.toFixed(2))
}

/**
 * Find cash declared event for transaction
 * @param transaction
 * @param CashApplicationDeclaredEventsStore
 */
export function findCashApplicationDeclaredEventForTransaction(transaction: BBBTransaction, CashApplicationDeclaredEventsStore: CashApplicationDeclaredEvent[]): CashApplicationDeclaredEvent[] {
  return CashApplicationDeclaredEventsStore.filter((cashApplicationDeclaredEvent: CashApplicationDeclaredEvent) => cashApplicationDeclaredEvent.cashApplication.bankTransaction.transactionId === transaction?.id?.toString())
}

/**
 * Update cash application calculus
 * @param transaction
 * @param cashApplicationDeclaredEvents
 * @param locale (string like 'de-DE' for German, 'fr-FR' for France French, etc.
 */
export function updateCashApplicationStatusForTransaction(
  transaction: BBBTransaction,
  cashApplicationDeclaredEvents: CashApplicationDeclaredEvent[],
  locale: string,
): CADEStatusForTransaction {
  /* console.log('%c updateCashApplicationStatusForTransaction()', 'color: green; font-size: 1.2em') */


  const rawAmountCashApplied: number = sumAppliedAmounts(cashApplicationDeclaredEvents.filter(filterCADEsForSelectedTransaction(transaction)))
  const isTransactionFullyCashApplied = (rawAmountCashApplied >= transaction?.amount)
  if (isTransactionFullyCashApplied) {
    eventsManager.emit(EventType.TRANSACTION_FULLY_CASH_APPLIED, true, 'cash-application.service')

  } else {
    eventsManager.emit(EventType.TRANSACTION_FULLY_CASH_APPLIED, false, 'cash-application.service')

  }
  const amountCashApplied: string = formatCurrencyWithOptions(Math.min(rawAmountCashApplied, transaction?.amount), locale, transaction?.currency_code, true)
  const percentageCashApplied: number = calculatePercentageCashApplied(rawAmountCashApplied, transaction?.amount)
  const amountLeftToAllocateOnTransaction: number = calculateAmountLeftToAllocateOnTransaction(rawAmountCashApplied, transaction?.amount)

  return <CADEStatusForTransaction>{
    amountLeftToAllocateOnTransaction,
    isTransactionFullyCashApplied,
    amountCashApplied,
    percentageCashApplied
  }
}

/**
 * Helper to calculate the amount left to allocate on transaction
 * @param partialAmountCashApplied
 * @param totalAmountExpectedToBeCashApplied
 * @private
 */
export function calculateAmountLeftToAllocateOnTransaction(partialAmountCashApplied: number, totalAmountExpectedToBeCashApplied: number): number {
  return Number((totalAmountExpectedToBeCashApplied - partialAmountCashApplied).toFixed(2))
}

/**
 * Helper to calculate the percentage of cash applied
 * @param partialAmountCashApplied
 * @param totalAmountExpectedToBeCashApplied
 * @private
 */
export function calculatePercentageCashApplied(partialAmountCashApplied: number, totalAmountExpectedToBeCashApplied: number): number {
  let percentageCashApplied = partialAmountCashApplied / totalAmountExpectedToBeCashApplied * 100
  if (percentageCashApplied > 100) {
    percentageCashApplied = 100
  }

  return percentageCashApplied
}

/**
 * Check if transaction is requiring cash application
 * Returns true if the transaction is requiring cash application
 * @param transactionId
 * @param transactions
 * @param cashApplicationDeclaredEvents
 */
export function isTransactionRequiringCashApplication(transactionId: string, transactions: BBBTransaction[], cashApplicationDeclaredEvents: CashApplicationDeclaredEvent[]): boolean {
  const convertedTransactions: BankTransaction[] = transactions.map((transaction: BBBTransaction) => ConvertToBankTransaction(transaction))

  const listOfTransactionsRequiringCashApplication: BankTransaction[] = getListOfTransactionsRequiringCashApplication(convertedTransactions, cashApplicationDeclaredEvents)

  return listOfTransactionsRequiringCashApplication.some((transaction: BankTransaction) => transaction.transactionId === transactionId)
}

export function isTransactionPartiallyCashApplied(transactionId: string, transactions: BBBTransaction[], cashApplicationDeclaredEvents: CashApplicationDeclaredEvent[]): boolean {
  const convertedTransactions: BankTransaction[] = transactions.map((transaction: BBBTransaction) => ConvertToBankTransaction(transaction))

  const listOfTransactionsRequiringCashApplication: BankTransaction[] = getListOfTransactionsWithPartialCashApplication(convertedTransactions, cashApplicationDeclaredEvents)

  return listOfTransactionsRequiringCashApplication.some((transaction: BankTransaction) => transaction.transactionId === transactionId)
}

/**
 * Context: the user has modified a CADE in allCADEsInUI, the tested function reduceAmountIfNeededOfCADEChangedByUserInCADEsInUI() evaluates if the new amount is not too high
 * compared what is left to Cash Apply for both the transactgion and the invoice that both are target by the modified CADE.
 * NB: the specified modified CADE is supposed to be one of the items in allCADEsInUI (exactly).
 * NB: reduceAmountIfNeededOfCADEChangedByUserInCADEsInUI() returns a new allCADEsInUI but potentially with the user-modified-CADE having a new applied amount.  *
 * @param newCADEOrCADEModificationRequestedByUser
 * @param allCADEsInUIIncludingRequestedChange
 * @param allCADEsInStore
 */
export function reduceAmountIfNeededOfCADEChangedByUserInCADEsInUI(newCADEOrCADEModificationRequestedByUser: CashApplicationDeclaredEvent, allCADEsInUIIncludingRequestedChange: CashApplicationDeclaredEvent[], allCADEsInStore: CashApplicationDeclaredEvent[]): CashApplicationDeclaredEvent[] {
  const newCADEsInUIAfterRequestedChange: CashApplicationDeclaredEvent[] = <CashApplicationDeclaredEvent[]>[]
  if (!newCADEOrCADEModificationRequestedByUser) {
    return allCADEsInUIIncludingRequestedChange
  }
  const userRequestedNewCashAppliedAmount: number = Amount.PrototypeToClass(newCADEOrCADEModificationRequestedByUser.cashApplication.appliedAmount).GetAmountScaledValue()
  let isUserCADENew: boolean = true
  for (let aCashApplicationDeclaredEventInUI of allCADEsInUIIncludingRequestedChange) {
    if (aCashApplicationDeclaredEventInUI.eventId === newCADEOrCADEModificationRequestedByUser.eventId) {
      isUserCADENew = false
      const maxAmountRemainingForTransaction: number = getRemainingAmountToApplyForTransactionExceptForGivenInvoice(
        newCADEOrCADEModificationRequestedByUser.cashApplication.bankTransaction.transactionId,
        Amount.PrototypeToClass(newCADEOrCADEModificationRequestedByUser.cashApplication.bankTransaction.amount),
        allCADEsInUIIncludingRequestedChange,
        newCADEOrCADEModificationRequestedByUser.invoiceNumber,
      )
      const maxAmountRemainingForInvoice: number = getRemainingAmountToApplyForInvoiceExceptWithGivenTransaction(
        newCADEOrCADEModificationRequestedByUser.invoiceNumber,
        Amount.PrototypeToClass(newCADEOrCADEModificationRequestedByUser.cashApplication.invoiceAmount),
        allCADEsInStore,
        newCADEOrCADEModificationRequestedByUser.cashApplication.bankTransaction.transactionId,
      )
      const correctedAppliedAmountScaledValue: number = Math.min(
        userRequestedNewCashAppliedAmount,
        maxAmountRemainingForTransaction,
        maxAmountRemainingForInvoice,
      )
      if (correctedAppliedAmountScaledValue !== userRequestedNewCashAppliedAmount) {
        /* console.log('newCADEOrCADEModificationRequestedByUser.cashApplication.bankTransaction.amount', Amount.PrototypeToClass(newCADEOrCADEModificationRequestedByUser.cashApplication.bankTransaction.amount).GetAmountScaledValue()) */
        /* console.log('userRequestedNewCashAppliedAmount', userRequestedNewCashAppliedAmount) */
        /* console.log('maxAmountRemainingForTransaction', maxAmountRemainingForTransaction) */
        /* console.log('maxAmountRemainingForInvoice', maxAmountRemainingForInvoice) */
        /* console.log('correctedAppliedAmountScaledValue', correctedAppliedAmountScaledValue) */
        /* console.log('isUserCADENew', isUserCADENew) */
      }
      const newCorrectedCADE: CashApplicationDeclaredEvent = <CashApplicationDeclaredEvent>deepClone(newCADEOrCADEModificationRequestedByUser)
      newCorrectedCADE.cashApplication.appliedAmount = NewAmountOfMoney(correctedAppliedAmountScaledValue, 2, newCADEOrCADEModificationRequestedByUser.cashApplication.appliedAmount.currencyCode)
      newCADEsInUIAfterRequestedChange.push(newCorrectedCADE)
    } else {
      newCADEsInUIAfterRequestedChange.push(aCashApplicationDeclaredEventInUI)
    }
  }
  if (isUserCADENew) {
    /* console.log('isUserCADENew', isUserCADENew) */
    newCADEsInUIAfterRequestedChange.push(newCADEOrCADEModificationRequestedByUser)
  }

  return newCADEsInUIAfterRequestedChange
}

/**
 * Fuse Cash Application Declared Events From Store to UI
 * @param rawCashApplicationDeclaredEvents
 */
export function fuseCashApplicationDeclaredEvents(rawCashApplicationDeclaredEvents: CashApplicationDeclaredEvent[]): CashApplicationDeclaredEvent[] {
  /* console.log('fuseCashApplicationDeclaredEvents()') */

  const mappedCADEs = new Map<string, CashApplicationDeclaredEvent>()
  for (let cadeItem of rawCashApplicationDeclaredEvents) {
    try {
      const aCADEKey = cadeItem.cashApplication.bankTransaction.transactionId + ' - ' + cadeItem.invoiceNumber
      let mapped = mappedCADEs.get(aCADEKey)
      if (!mapped) {

        const newMapped: CashApplicationDeclaredEvent = CashApplicationDeclaredEvent.PrototypeToClass(<CashApplicationDeclaredEvent>deepClone(cadeItem))
        newMapped.cashApplication.invoiceAmountDueBeforeApplication = <Amount>deepClone(newMapped.cashApplication.invoiceAmount)
        newMapped.cashApplication.invoiceAmountDueAfterApplication = <Amount>deepClone(NewAmountOfMoney(Amount.PrototypeToClass(newMapped.cashApplication.invoiceAmount).GetAmountScaledValue() - Amount.PrototypeToClass(newMapped.cashApplication.appliedAmount).GetAmountScaledValue(), 2, newMapped.cashApplication.invoiceAmount.currencyCode))
        mappedCADEs.set(aCADEKey, newMapped)
      } else {
        const newMapped: CashApplicationDeclaredEvent = <CashApplicationDeclaredEvent>deepClone(mapped)
        newMapped.cashApplication.appliedAmount = NewAmountOfMoney(Amount.PrototypeToClass(newMapped.cashApplication.appliedAmount).GetAmountScaledValue() + Amount.PrototypeToClass(cadeItem.cashApplication.appliedAmount).GetAmountScaledValue(), 2, newMapped.cashApplication.appliedAmount.currencyCode)
        newMapped.cashApplication.invoiceAmountDueBeforeApplication = <Amount>deepClone(newMapped.cashApplication.invoiceAmountDueAfterApplication)
        newMapped.cashApplication.invoiceAmountDueAfterApplication = <Amount>deepClone(NewAmountOfMoney(Amount.PrototypeToClass(newMapped.cashApplication.invoiceAmountDueAfterApplication).GetAmountScaledValue() - Amount.PrototypeToClass(newMapped.cashApplication.appliedAmount).GetAmountScaledValue(), 2, newMapped.cashApplication.invoiceAmount.currencyCode))
        if (mapped.cashApplication.created?.unixSeconds > cadeItem.cashApplication.created?.unixSeconds) {
          newMapped.cashApplication.created = <ExchangeDate>deepClone(mapped.cashApplication.created)
          newMapped.created = mapped.cashApplication.created.unixSeconds
          newMapped.createdRFC3339 = mapped.cashApplication.created.rfc3339
        } else {
          newMapped.cashApplication.created = <ExchangeDate>deepClone(cadeItem.cashApplication.created)
          newMapped.created = cadeItem.cashApplication.created?.unixSeconds
          newMapped.createdRFC3339 = cadeItem.cashApplication.created?.rfc3339
        }
        newMapped.eventId = uuidv4()
        newMapped.cashApplication.applicationId = uuidv4()
        mappedCADEs.set(aCADEKey, newMapped)
      }
    } catch (e) {
      /* console.error('fuseCashApplicationDeclaredEvents() error', e) */
    }
  }
  const fusedCADEs: CashApplicationDeclaredEvent[] = []
  if (!!mappedCADEs) {
    for (let cadeKey of mappedCADEs.keys()) {
      if (!!mappedCADEs && !!(mappedCADEs.get(cadeKey)) && (Math.abs(Amount.PrototypeToClass(mappedCADEs.get(cadeKey).cashApplication.appliedAmount).GetAmountScaledValue()) > 0.00499999)) {
        fusedCADEs.push(mappedCADEs.get(cadeKey))
      }
    }
  }

  return fusedCADEs
}

export function toCADEExcerpts(cades: CashApplicationDeclaredEvent[]): CADEE[] {
  return cades.map<CADEE>(
    (aCADE: CashApplicationDeclaredEvent) => <CADEE>{
      TransactionId: aCADE.cashApplication.bankTransaction.transactionId,
      BusinessDocumentNumber: aCADE.invoiceNumber,
      AppliedAmount: aCADE.cashApplication.appliedAmount.scaledValue
    },
  )
}

export function allCADEEsInAExistInB(cadeesInA: CADEE[], cadeesInB: CADEE[], showMissing: boolean): boolean {
  let allItemsInAPresentInB: boolean = true
  for (let itemInA of cadeesInA) {
    let found: boolean = false
    for (let itemInB of cadeesInB) {
      if (itemInA.AppliedAmount === itemInB.AppliedAmount &&
        itemInA.TransactionId === itemInB.TransactionId &&
        itemInA.BusinessDocumentNumber === itemInB.BusinessDocumentNumber
      ) {
        found = true
        break
      }
    }
    if (!found) {
      if (showMissing) {
        /* console.log('item in A \'' + JSON.stringify(itemInA) + '\' missing in B') */
      }
      allItemsInAPresentInB = false
      break
    }
  }

  return allItemsInAPresentInB
}

export function allStringsInSetAExistInSetB(stringsInSetA: Set<string>, stringsInSetB: Set<string>, showMissing: boolean): boolean {
  let allItemsInAPresentInB: boolean = true
  /*const setAIsSetOfStrings: boolean = stringsInSetA instanceof Set<string>;
    const setBIsSetOfStrings: boolean = stringsInSetB instanceof Set<string>;*/


  for (let itemInA of stringsInSetA) {
    let found: boolean = false
    for (let itemInB of stringsInSetB) {
      if (itemInA === itemInB) {
        found = true
        break
      }
    }
    if (!found) {
      if (showMissing) {
        /* console.log('item in A \'' + itemInA + '\' missing in B') */
      }
      allItemsInAPresentInB = false
      break
    }
  }
  /* console.log('allStringsInSetAExistInSetB()', allItemsInAPresentInB) */

  return allItemsInAPresentInB
}

export function getInvoiceNumbersSetFromCADEs(allCADEs: CashApplicationDeclaredEvent[]): Set<InvoiceNumber> {
  const allCADEsInUIInvoiceNumbersSet: Set<InvoiceNumber> = new Set(allCADEs.map((aCADE: CashApplicationDeclaredEvent) => (aCADE.invoiceNumber as InvoiceNumber)))

  return allCADEsInUIInvoiceNumbersSet
}

export function getInvoiceNumbersSetFromInvoices(allInvoices: Invoice[]): Set<string> {
  const allInvoicesNumbersSet: Set<string> = new Set(allInvoices.map((anInvoice: Invoice) => (anInvoice.invoiceNumber as string)))

  return allInvoicesNumbersSet
}

export function newCashApplicationDeclaredEventFromInvoiceNumber(selectedTransaction: BBBTransaction, invoiceNumber: InvoiceNumber, allInvoices: Invoice[], cashApplicationContext: CashApplicationContext): CashApplicationDeclaredEvent {


  const correspondingInvoice: Invoice = allInvoices.find(i => (i.invoiceNumber === invoiceNumber))
  if (!!correspondingInvoice) {
    return newCashApplicationDeclaredEvent(selectedTransaction, correspondingInvoice, cashApplicationContext)
  }

  return undefined
}

// B - we shall add CADEs corresponding to newly selected invoices by the user (where new selected invoices will have the full invoice amount as applied amount)
//         We add the items using the latestCADEsInStore, not the items of the newCADEsInStore. Otherwise, we could have duplicate items when adding the difference between the latestCADEsInStore and the newCADEsInStore.
//         We can possibly return several CADEs for a pair of TransactionId and InvoiceNumber since we source our data from a CADEsInStore.
export function getNewCADEsForInvoicesNewlySelectedByUserHenceNotPresentInCADEsInUIYet(currentCADEsInUIInvoiceNumbersSet: Set<InvoiceNumber>, selectedInvoiceNumbersSet: Set<InvoiceNumber>, selectedTransaction: BBBTransaction, allInvoices: Invoice[], currentCashApplicationContext: CashApplicationContext): CashApplicationDeclaredEvent[] {
  /* console.log('getNewCADEsForInvoicesNewlySelectedByUserHenceNotPresentInCADEsInUIYet()') */
  const newlySelectedCADEsInUI: CashApplicationDeclaredEvent[] = <CashApplicationDeclaredEvent[]>[]
  for (let aSelectedInvoiceNumber of selectedInvoiceNumbersSet) {
    if (!currentCADEsInUIInvoiceNumbersSet.has(aSelectedInvoiceNumber)) {
      const newCADEForNewUserSelection = newCashApplicationDeclaredEventFromInvoiceNumber(selectedTransaction, aSelectedInvoiceNumber, allInvoices, currentCashApplicationContext)
      newlySelectedCADEsInUI.push(newCADEForNewUserSelection)
    }
  }
  // if (selectedInvoiceNumbersSet.size > newlySelectedCADEsInUI.length) {


  // }
  return newlySelectedCADEsInUI
}

import { JsonProperty } from '@paddls/ts-serializer'

export default class BiFrInpiCompanyBySirenAPIResponse {
  @JsonProperty('UpdatedAtRFC3339') updatedAtRFC3339: string
  @JsonProperty('Id') id: string
  @JsonProperty('ComSIRENpanyId') siren: string
  @JsonProperty('EntityType') entityType: boolean
  @JsonProperty('CompPublicationanyId') legalForm: string
  @JsonProperty('LegalForm') companyId: string
  @JsonProperty('CreationDate') creationDate: string // YYYY-MM-DD
  @JsonProperty('ForeignCompany') foreignCompany: string
  @JsonProperty('EstablishedInFrance') establishedInFrance: boolean
  @JsonProperty('HeadcountInFrance') headcountInFrance: boolean
  @JsonProperty('CompanyName') companyName: string
  @JsonProperty('CompanyStreet') companyStreet: string
  @JsonProperty('CompanyZipCode') companyZipCode: string
  @JsonProperty('CompanyCity') companyCity: string
  @JsonProperty('CompanyCountryCode') companyCountryCode: string
  @JsonProperty('HeadquarterNIC') headquarterNIC: string
  @JsonProperty('APECode') apeCode: string
  @JsonProperty('RegistrationDateRFC3339') registrationDateRFC3339: string
  @JsonProperty('ActivityStartDate') activityStartDate: string // YYYY-MM-DD
  @JsonProperty('ValidatedCompany') validatedCompany: boolean
  @JsonProperty('EntrepriseRdd') entrepriseRdd: boolean
  @JsonProperty('Object') object: string
  @JsonProperty('DurationNbYears') durationNbYears: number
  @JsonProperty('FiscalYearClosingDay') fiscalYearClosingDay: string // DDMM
  @JsonProperty('FirstFiscalYearClosingDate') firstFiscalYearClosingDate: string // YYYY-MM-DD
  @JsonProperty('ESS') ess: boolean
  @JsonProperty('VariableShareCapital') variableShareCapital: boolean
  @JsonProperty('ShareCapitalAmount') shareCapitalAmount: number
  @JsonProperty('ShareCapitalCurrency') shareCapitalCurrency: string
  @JsonProperty('MergeSpinOffOriginIndicator') mergeSpinOffOriginIndicator: number
  @JsonProperty('UniqueShareholderIndicator') uniqueShareholderIndicator: number
  @JsonProperty('UniqueExecutiveIndicator') uniqueExecutiveIndicator: number
}
import { derived, get, type Readable, writable, type Writable } from 'svelte/store'
import initializationStore from '../../core-app/stores/initialization.store'
import type GlobalInitializationStore from '../../core-app/models/initialization-store'
import { encrypt256Hash } from '../../core-app/util/encryption'
import { eventsManager } from '../../core-app/events/event-manager'
import { EventType } from '../../core-app/events/event-type'
import { isEqual } from 'lodash'
import type { CoreDocument } from '../models/business-document'
import { BusinessDocument, BusinessDocumentAllDataPersisted, CollateralDocument } from '../models/business-document'
import { BusinessDocumentViewListOption } from '../enums/business-documents-view-list-options'
import { deepClone } from '../../core-app/util/object-deep-cloning'
import {
  emitBusinessDocumentsChangedEvents,
  emitBusinessDocumentsMissingEvents
} from '../services/business-document-various-functions'
import { BusinessDocumentStatus } from '../enums/business-document-status'
import { BusinessDocumentState } from '../enums/business-document-state'
import { BusinessDocumentKind } from '../enums/business-document-kind'

/**
 * Compare the new and current state
 * const currentBusinessDocumentList: CoreDocument[] = get(BusinessDocumentsStore); // Get the current state
 */
let currentBusinessDocumentAllDataPersistedList: BusinessDocumentAllDataPersisted[] = <BusinessDocumentAllDataPersisted[]>[] // <-- Define it outside the subscribe function
let isBusinessDocumentsListViewChoiceSubscriptionDefined: boolean = false

/**
 * Boolean flag to indicate if the store is initialized
 */
export const isFetchingBusinessStore: Writable<boolean> = writable(true)

const getFromLocalStorage = <T>(key: string, defaultValue: T = null): T[] | T => {
  const emptyValue: T[] = <T[]>[]
  if (!localStorage) {
    return defaultValue ?? emptyValue
  }
  const existingValue: string | null = localStorage.getItem(key)
  if (!existingValue || existingValue === 'undefined') return defaultValue ?? emptyValue
  if (['null', '', '{}'].includes(existingValue)) return defaultValue ?? emptyValue

  return defaultValue ? <T>existingValue : <T[]>JSON.parse(existingValue)
}

/**
 * STORES
 */
// TODO : BusinessAllDocuments (CoreDocument + CollateralDocument)
export let BusinessDocumentsAllDataPersistedStore: Writable<BusinessDocumentAllDataPersisted[]> = writable<BusinessDocumentAllDataPersisted[]>([])
// TODO : CoreDocument (...BusinessDocument)
export let BusinessDocumentsStore: Readable<CoreDocument[]> = derived(
  BusinessDocumentsAllDataPersistedStore,
  ($store: BusinessDocumentAllDataPersisted[]) => {
    if (!$store) return []

    return $store.map((d: BusinessDocumentAllDataPersisted) => d.coreDocument)
  }
)
// TODO : CollateralDocument
export let CollateralDocumentsStore: Readable<CollateralDocument[]> = derived(
  BusinessDocumentsAllDataPersistedStore,
  ($store: BusinessDocumentAllDataPersisted[]) => {
    if (!$store) return []

    return $store.map((d: BusinessDocumentAllDataPersisted) => d.collateralDocument)
  }
)
// TODO BusinessDocument
export let SimpleDocumentsStore: Readable<BusinessDocument[]> = derived(
  BusinessDocumentsStore,
  ($businessDocumentsStore: CoreDocument[]) => {
    if (!$businessDocumentsStore) return []

    return $businessDocumentsStore.map<BusinessDocument>((coreDocument: CoreDocument) => coreDocument.businessDocument)
  },
)


const getBusinessDocumentStateFromKind = (d : BusinessDocument):BusinessDocumentState => {
  const isDraft = d.businessDocumentStatus === BusinessDocumentStatus.DRAFT

  switch (d.businessDocumentKind) {
    case BusinessDocumentKind.CREDITNOTE: return isDraft ? BusinessDocumentState.CREDIT_NOTE_DRAFT : BusinessDocumentState.CREDIT_NOTE
    case BusinessDocumentKind.INVOICE: return isDraft ? BusinessDocumentState.INVOICE_DRAFT : BusinessDocumentState.INVOICE
    case BusinessDocumentKind.PURCHASEORDER: return isDraft ? BusinessDocumentState.PURCHASE_ORDER_DRAFT : BusinessDocumentState.PURCHASE_ORDER
    case BusinessDocumentKind.QUOTE: return isDraft ? BusinessDocumentState.QUOTE_DRAFT : BusinessDocumentState.QUOTE
  }
}

export let HistoryStore = derived(SimpleDocumentsStore, ($store: BusinessDocument[]) => {
  let res = []

  if (!$store) return res
  
  // sort by relation length max to min
  const storeSorted = $store
    .map(d => d.relatedBusinessDocuments ? d : { ...d, relatedBusinessDocuments: [] } )
    .sort((da, db) => db.relatedBusinessDocuments.length - da.relatedBusinessDocuments.length)

  // array of business document already treated
  const treated = []

  storeSorted.forEach(d => {
    const id = d.businessDocumentId

    const tmp = new Map()
    const length = d.relatedBusinessDocuments.length

    if (treated.includes(id)) return

    if (length === 0 && !treated.includes(d)) {
      tmp.set(id, { state: getBusinessDocumentStateFromKind(d) })
      treated.push(id)
    } else if (length === 1) {
      const r = d.relatedBusinessDocuments[0]

      const leave = [r.fromBusinessDocumentId, r.toBusinessDocumentId].filter(e => !treated.includes(e))
      if (leave) {
        const to = storeSorted.find(e => e.businessDocumentId === r.toBusinessDocumentId)
        const isDraft = to?.businessDocumentStatus === BusinessDocumentStatus.DRAFT

        tmp.set(r.fromBusinessDocumentId, { 
          state: isDraft ? BusinessDocumentState.PENDING_VOIDED : BusinessDocumentState.VOIDED
        })

        tmp.set(r.toBusinessDocumentId, { 
          state: isDraft ? BusinessDocumentState.PENDING_VOIDING : BusinessDocumentState.VOIDING
        })
        treated.push(...tmp.keys())
      }
    } else if (length > 1) {
      d.relatedBusinessDocuments.forEach((r, i) => {
        if (i === length - 1) {
          const isDraft = d.businessDocumentStatus === BusinessDocumentStatus.DRAFT
        
          // tmp.set(id, d)
          tmp.set(id, { state: isDraft ? BusinessDocumentState.PENDING_VOIDING : BusinessDocumentState.VOIDING_AND_LAST })
          
          return
        }

        // check if voided is voiding to
        const fromVoiding = d.relatedBusinessDocuments.find(e => e.toBusinessDocumentId === r.fromBusinessDocumentId)

        // check if voiding is voided to
        const toVoided = d.relatedBusinessDocuments.find(e => e.fromBusinessDocumentId === r.toBusinessDocumentId)

        // check if to is Draft
        const to = storeSorted.find(e => e.businessDocumentId === r.toBusinessDocumentId)
        const isDraft = to?.businessDocumentStatus === BusinessDocumentStatus.DRAFT

        tmp.set(r.fromBusinessDocumentId, { 
          state: fromVoiding ? BusinessDocumentState.VOIDING_AND_VOIDED
            : isDraft ? BusinessDocumentState.PENDING_VOIDED : BusinessDocumentState.VOIDED
        })

        tmp.set(r.toBusinessDocumentId, { 
          state: toVoided ? BusinessDocumentState.VOIDING_AND_VOIDED 
            : isDraft ? BusinessDocumentState.PENDING_VOIDING : BusinessDocumentState.VOIDING
        })

        treated.push(...tmp.keys())
      })
    }

    res.push(tmp)
  })

  return res
})

// export let businessDocumentsListViewChoice: Writable<BusinessDocumentViewListOption> = writable(<BusinessDocumentViewListOption>getFromLocalStorage('businessDocumentsListViewChoice', BusinessDocumentViewListOption.INVOICES))
export let businessDocumentsListViewChoice: Writable<BusinessDocumentViewListOption> = writable<BusinessDocumentViewListOption>(BusinessDocumentViewListOption.INVOICES)

/**
 * Initialize the BusinessDocumentsStore
 * - Uses the safelyGetArray utility function to ensure the input data is an array.
 * - Sets the isFetchingBusinessStore flag to false.
 * - Sets the BusinessDocumentsStore to the input data.
 * @param data
 */
export const initializeBusinessDocumentsAllDataPersistedStore = (data: BusinessDocumentAllDataPersisted[]): void => {
  if (get(initializationStore).businessDocumentsStoreInitialized) return

  initializationStore.update((store: GlobalInitializationStore) => {
    store.businessDocumentsStoreInitialized = true

    return store
  })

  BusinessDocumentsAllDataPersistedStore.set(data)
  isFetchingBusinessStore.set(false)

  /* console.log('### subscribing to BusinessDocumentsStore') */
  let isBusinessDocumentsStoreSubscriptionDefined: boolean = false

  eventsManager.on(EventType.AGGRID_ADD_TEMPORARY_DOCUMENT, e => AddTempBusinessDocument(e.data))

  // Subscribe to the TodosStore, handling updates with the extracted functions and emitting appropriate events.
  BusinessDocumentsAllDataPersistedStore.subscribe((newList: BusinessDocumentAllDataPersisted[]): void => {

    /* console.log('BusinessDocumentsStore.subscribe, store updated') */
    if (!isBusinessDocumentsStoreSubscriptionDefined) {
      /* console.log('BusinessDocumentsStore subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently') */
      isBusinessDocumentsStoreSubscriptionDefined = true
      currentBusinessDocumentAllDataPersistedList = deepClone(newList)

      return
    }
    /* console.log('Before isEqual statement') */
    /* console.log(isEqual(newBusinessDocumentList, currentBusinessDocumentList), 'isEqual(newBusinessDocumentList, currentBusinessDocumentList)') */

    if (isEqual(newList, currentBusinessDocumentAllDataPersistedList)) return // Compare the states using lodash's isEqual function
    /* console.log('Inside isEqual statement') */
    /* console.log('currentBusinessDocumentList:', currentBusinessDocumentList) */
    /* console.log('newBusinessDocumentList:', newBusinessDocumentList) */

    const validatedNewBusinessDocumentList: BusinessDocumentAllDataPersisted[] = newList

    if (validatedNewBusinessDocumentList?.length) {
      /* console.log('Condition #1 - validatedNewBusinessDocumentList.length:', validatedNewBusinessDocumentList.length) */
      /* console.log('Condition #1 - currentBusinessDocumentList.length:', currentBusinessDocumentList.length) */
      if (currentBusinessDocumentAllDataPersistedList) {
        const currentBusinessDocumentListHash: string = encrypt256Hash(currentBusinessDocumentAllDataPersistedList)
        const newBusinessDocumentListHash: string = encrypt256Hash(validatedNewBusinessDocumentList)

        if (currentBusinessDocumentListHash !== newBusinessDocumentListHash) {
          emitBusinessDocumentsChangedEvents(currentBusinessDocumentAllDataPersistedList.map((b: BusinessDocumentAllDataPersisted) => b.coreDocument.businessDocument), validatedNewBusinessDocumentList.map((b: BusinessDocumentAllDataPersisted) => b.coreDocument.businessDocument))
          // emitBusinessDocumentsMissingEvents(currentBusinessDocumentAllDataPersistedList.map((b: BusinessDocumentAllDataPersisted) => b.coreDocument), validatedNewBusinessDocumentList.map((b: BusinessDocumentAllDataPersisted) => b.coreDocument))
          emitBusinessDocumentsMissingEvents(currentBusinessDocumentAllDataPersistedList, validatedNewBusinessDocumentList)

          currentBusinessDocumentAllDataPersistedList = deepClone(newList) // <-- Update the current state after handling the update
          // setTimeout(() => {
          eventsManager.emit(EventType.BUSINESS_DOCUMENTS_LIST_CHANGED, validatedNewBusinessDocumentList, 'BusinessDocumentsStore')
          // }, 3000)
        }
      }
    } else {
      /* console.log('Condition #2 - validatedNewBusinessDocumentList.length:', validatedNewBusinessDocumentList.length) */

      currentBusinessDocumentAllDataPersistedList = deepClone(newList) // <-- Update the current state after handling the update
      eventsManager.emit(EventType.BUSINESS_DOCUMENTS_LIST_CHANGED, [], 'BusinessDocumentsStore')
    }
  })
  /* console.log('%c BusinessDocumentsStore initialized.', 'color: #8A99AC') */
}

// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable,
// then it is executed immediately (including when the store.ts file is imported at the beginning of the web app)
// and its argument is whatever the store variable contains at this point
businessDocumentsListViewChoice.subscribe((val: BusinessDocumentViewListOption) => {
  if (!isBusinessDocumentsListViewChoiceSubscriptionDefined) {
    /* console.log('businessDocumentsListViewChoice subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently') */
    isBusinessDocumentsListViewChoiceSubscriptionDefined = true

    return // we avoid the .subscribe() execution at the subscription occurrence
  }
  localStorage.setItem('businessDocumentsListViewChoice', val)
})


export const AddTempBusinessDocument = ({ businessDocument, invoiceCollateralData }): void => {
  businessDocument.businessDocumentStatus = BusinessDocumentStatus.FINAL

  const doc: BusinessDocumentAllDataPersisted = {
    collateralDocument: {
      businessDocumentCollateralData: invoiceCollateralData,
      modifiedDate: null,
      requesterId: null,
      workspaceId: null
    },
    coreDocument: {
      businessDocument,
      requesterId: null,
      workspaceId: null,
      modifiedDate: null
    }
  }

  BusinessDocumentsAllDataPersistedStore.update((store: BusinessDocumentAllDataPersisted[]) => [...store, doc])
}

import { DundyEvent } from '../../dundy-app/events/dundy-event'
import { EventType } from './event-type'
import { v4 as uuidv4 } from 'uuid'

class EventListener {
  uid: string
  callback: Function
  subscriber: string
}

class EventsManagerClass {

  private listeners: Map<EventType, Array<EventListener>> = new Map<EventType, Array<EventListener>>()
  emit(event: EventType, data: any, publisher: string) {
    

    const now = new Date()
    // https://stackoverflow.com/questions/9772955/how-can-i-get-the-timezone-name-in-javascript
    const timeZoneIANACode = Intl.DateTimeFormat().resolvedOptions().timeZone

    const dundyEvent: DundyEvent<any> = new DundyEvent()
    dundyEvent.metadata = {
      eventTag: event.toString(),
      publisher: publisher,
      eventTimeStamp: now,
      eventTimeStampUnixSeconds: Math.round(now.getTime() / 1000.0),
      eventTimeStampUnixMilliseconds: now.getTime(),
      // ISO8601 vs RFC3339 : https://stackoverflow.com/questions/522251/whats-the-difference-between-iso-8601-and-rfc-3339-date-formats
      eventTimeStampRFC3339: now.toISOString(),
      // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones e.g. Europe/Paris or Etc/UTC
      eventTimeZoneIANACode: timeZoneIANACode
    }
    dundyEvent.data = data

    if (this.listeners.get(event)) {
      this.listeners.get(event).forEach(eventListener => {
        if (event !== EventType.INVOICES_PAGINATION_CHANGED) {
          console.log('%c EventsManager Calling listener - event: %s, eventListener.subscriber: %s',
            'color: #F47663', EventType[event], eventListener.subscriber, dundyEvent)
        }
        eventListener.callback(dundyEvent)
      })
    }
  }
  on<T>(event: EventType, callback: (e: DundyEvent<T>) => any, subscriber: string): Function {
    

    if (!this.listeners.get(event)) {
      this.listeners.set(event, [])
    }
    const uid = uuidv4()
    this.listeners.get(event).push({ uid, callback, subscriber })
    
    return ((event, uid) => () => this.off(event, uid))(event, uid)
  }
  once<T>(event: EventType, callback: (e: DundyEvent<T>) => any, subscriber: string): Function {
    

    if (!this.listeners.get(event)) {
      this.listeners.set(event, [])
    }
    const uid = uuidv4()
    this.listeners.get(event).push({
      uid, callback: (e) => {
        this.off(event, uid)
        callback(e)
      }, subscriber
    })
    
    return ((event, uid) => () => this.off(event, uid))(event, uid)
  }
  clear() {
    this.listeners = new Map<EventType, Array<EventListener>>()
  }
  private off(event: EventType, uid: string) {
    if (this.listeners.get(event)) {
      const index: number = this.listeners.get(event).findIndex(l => l.uid === uid)
      if (index !== -1) {
        this.listeners.get(event).splice(index, 1)
      }
    }
  }
}

export const eventsManager: EventsManagerClass = new EventsManagerClass()

export enum OnboardingStep {
  START = 'start',
  SIGNUP = 'signup',
  CONFIRM = 'confirm',
  WAITING_FOR_PROFILE = 'waiting-for-profile',
  WAITING_FOR_WORKSPACE = 'waiting-for-workspace',
  COMPANY_INFO = 'company-info',
  COMPANY_INFO_PLUS = 'company-info-plus',
  SELECT_ACCOUNT = 'select-account',
  APP_START_USING_HOME = 'app-start-using-home',
  APP_NO_MISSING_INFO = 'app-no-missing-info'
}

export class OnboardingInfo {
  onboardingStep: OnboardingStep
  urlStepDeterminationPattern: string
  navigationRelativeURL: string
  navigationParentRoute: string
  navigationSubRoute: string
}

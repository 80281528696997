<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { stringNormalize } from '$shared/utils/string'
  import type { Variable, Variables } from '$src/shared/types/variable'
  export let variables:Variables = []
  export let placeholder:string = ''
  export let disabled:boolean = false
  export let searchValue: string = ''
  
  $:vars = searchValue 
    ? variables.filter(p => stringNormalize(p.key).includes(stringNormalize(searchValue)) || stringNormalize(p.value).includes(stringNormalize(searchValue)))
    : variables

  const dispatch = createEventDispatcher()
  
  const onMouseUp = (item:Variable) => {
    if (!disabled) dispatch('select', item)
  }

</script>

<div class="variable relative h-full pr-3 flex flex-col">
  <input
    type="text"
    class="text-sm font-normal shadow-none rounded border border-loblolly py-[8px] px-[12px] w-full focus:border-2 focus:py-[7px] focus:px-[11px]"
    {placeholder}
    bind:value={searchValue}
  />

  <ul class="min-h-full mt-2 overflow-y-auto overflow-x-hidden border border-loblolly rounded grow">
    {#each vars as v}
      <li role="presentation"
        class="cursor-pointer whitespace-nowrap py-1 px-2 text-sm font-normal hover:bg-[#FDF0EE]"
        class:disabled={disabled}
        on:mouseup={() => onMouseUp(v)}
      >
        {v.value}
      </li>
    {/each}
  </ul>
</div>

<style lang="postcss">
  li.disabled {
    @apply text-gray-500 hover:bg-transparent cursor-default;
  }
</style>
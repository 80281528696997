import { t } from '$core/lib/i18n/i18nextWrapper'
import { agGridCommonService } from '$dundy/services/ag-grid-common.service'
import type { GridOptions, GetLocaleTextParams } from 'ag-grid-community'

export const dunningOptions: GridOptions<GridOptions> = {
  defaultColDef: {
    resizable: false,
    headerCheckboxSelection: agGridCommonService.isFirstColumn,
    checkboxSelection: agGridCommonService.isFirstColumn
  },
  rowHeight: 64,
  domLayout: 'autoHeight',
  rowClassRules: {
    'dundy-invoice-row-deleted': params => !!(params.api.getValue('deleted', params.node)),
    'dundy-invoice-row-active': params => !params.api.getValue('deleted', params.node)
  },
  singleClickEdit: false,
  suppressClickEdit: false,
  suppressRowClickSelection: true,
  stopEditingWhenCellsLoseFocus: true,
  suppressRowHoverHighlight: true,
  debug: true,
  rowSelection: 'multiple',
  pagination: true,
  paginationPageSize: 50,
  suppressPaginationPanel: true,
  getLocaleText: (params: GetLocaleTextParams<GridOptions>): string => {
    const translation: string = t(`agGrid.${params.key}`)
    return translation === `agGrid.${params.key}` ? params.key : translation
  }
}
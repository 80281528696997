// DatatableAmountOfMoneyWithCurrencyInLetterRenderer.ts
import { t } from '../i18n/i18nextWrapper'

export default class DatatableAmountOfMoneyWithCurrencyInLetterRenderer {

  eGui: HTMLDivElement
  params: any
  constructor() {
  }
  init(params: any) {
    this.params = params

    let valueDisplayed: number | bigint
    let invoiceFormatter: Intl.NumberFormat
    if (params.value) {
      valueDisplayed = params.value
    } else {
      valueDisplayed = 0
    }
    this.eGui = document.createElement('div')

    // The NumberFormat throws an unchecked exception for invalid currencies. This way we don't halt execution.
    try {
      if (params.data.currency_code) {
        invoiceFormatter = new Intl.NumberFormat(t('locales'), {
          style: 'currency',
          currency: params.data.currency_code,
          currencyDisplay: 'code'
        })
      } else {
        invoiceFormatter = new Intl.NumberFormat(t('locales'), {
          style: 'currency',
          currency: params.data.currency,
          currencyDisplay: 'code'
        })
      }
    } catch (err) {
      invoiceFormatter = new Intl.NumberFormat(t('locales'), {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'code'
      })
      
    }
    this.eGui.insertAdjacentHTML('beforeend', invoiceFormatter.format(valueDisplayed))
    this.eGui.classList.add('bank-amount-of-money')
    this.eGui.setAttribute('bank-amount-value', params.value)
    this.eGui.setAttribute('bank-amount-currency', params.data.currency)
  }
  getGui(): HTMLDivElement {
    return this.eGui
  }
  destroy() {
  }
}

<script lang='ts'>
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper.js'
    import { navigate } from 'svelte-routing'
    import { formatToDateWithSlashes } from '../../../../core-app/util/date-utils.js'
    import InvoiceIssuer from './InvoiceIssuer.svelte'
    import InvoiceReceiver from './InvoiceReceiver.svelte'
    import BusinessDocumentList from './BusinessDocumentList.svelte'
    import BusinessDocumentFooter from './BusinessDocumentFooter.svelte'
    import type { BusinessDocument, BusinessDocumentCollateralData } from '../../../models/business-document'
    import { WorkspaceStore } from '../../../../crm-app/stores/workspace.store.js'
    import BusinessDocumentInstallmentsPreview from './BusinessDocumentInstallmentsPreview.svelte'
    import { BusinessDocumentKind } from '../../../enums/business-document-kind'
    import { ProfileStore } from '../../../../core-app/stores/profile.store'
    import { getBusinessDocumentMainTitle } from '../../../services/business-documents-functions'
    import {
      getBusinessDocumentSubjectMainTitleMainDescriptionRelatedDocumentsListDescription
    } from '../../../services/business-document-description/business-document-subject-main-title-main-description.service'

    /** Export let declarations **/
    export let businessDocument: BusinessDocument
    export let invoiceCollateralData: BusinessDocumentCollateralData
    let businessDocumentSubjectMainTitleMainDescriptionRelatedListDescription: string[] = []

    $:reactiveBusinessDocument = businessDocument
    $:reactiveInstallmentValue = businessDocument.installmentResultIncludingTaxScaledValue
    $: {
      if (reactiveBusinessDocument?.taxonomyTags?.length < -1 && reactiveBusinessDocument?.relatedBusinessDocuments?.length < -1
            && !!reactiveBusinessDocument?.installmentChosenValue && !!reactiveInstallmentValue) {
        // expose some parameters to provoke a reaction
      }
      /* console.warn('update business document description getBusinessDocumentSubjectMainTitleMainDescriptionRelatedDocumentsListDescription()') */
      /* console.log('install', businessDocument.installmentResultIncludingTaxScaledValue) */
      /* console.log('taxo', businessDocument.taxonomyTags) */
      /* console.log('relat', businessDocument.relatedBusinessDocuments) */
      businessDocumentSubjectMainTitleMainDescriptionRelatedListDescription = [...getBusinessDocumentSubjectMainTitleMainDescriptionRelatedDocumentsListDescription(businessDocument, $ProfileStore.lang)]
      /* console.warn('updated .-.-. businessDocumentSubjectMainTitleMainDescriptionRelatedListDescription') */

      // setTimeout(() => {
      // }, 3000)
    }

</script>

<div class='invoice-generator-preview'>
    <div id='invoice' class='a4-paper'>
        <header class='bg-whisper px-12 pt-12 pb-8'>
            <div class='flex'>
                {#if businessDocument.accountCompany.emailLogoURL}
                    <div class='w-6/12'>
                        <img class='rounded-lg overflow-hidden flex relative z-10 object-scale-down h-24 w-24'
                             src={businessDocument.accountCompany.emailLogoURL}
                             alt='Company Logo' />
                    </div>
                {/if}
                <div class='flex flex-col'>
                    <div class='flex flex-col flex-1 justify-end mb-8'>
                        <h1 class='text-xl font-bold justify-end'>
                            {getBusinessDocumentMainTitle(businessDocument.businessDocumentKind, businessDocument.taxonomyTags, businessDocument.hasSpecificInstallment)}
                        </h1>
                    </div>
                    <div class='flex flex-1'>
                        <div class='flex space-x-12'>
                            <p class='flex flex-col mb-2 text-xs'>
                                <span class='text-black font-bold'>{t('invoices.createInvoice.dateIssued')}</span>
                                <span>{formatToDateWithSlashes(new Date(businessDocument.issuedDate.rfc3339))}</span>
                            </p>
                            <p class='flex flex-col mb-2 text-xs'>
                                <span class='text-black font-bold'>{t('invoices.createInvoice.dateDue')}</span>
                                <span>{formatToDateWithSlashes(new Date(businessDocument.dueDate.rfc3339))}</span>
                            </p>
                        </div>
                    </div>
                </div>

            </div>

        </header>
        <main style='min-height: 610px;'>
            <div class='px-12 pt-8 flex space-x-24'>
                <InvoiceIssuer {businessDocument} />
                {#if businessDocument.customerCustomer.company}
                    <InvoiceReceiver {businessDocument} />
                {/if}
            </div>
            {#if businessDocumentSubjectMainTitleMainDescriptionRelatedListDescription.length > 0}
                <div class='px-12 pt-8 text-xs'>
                    <div><b>{t('invoices.businessDocument.commonWords.description')}</b></div>
                    {#each businessDocumentSubjectMainTitleMainDescriptionRelatedListDescription as relatedDocumentDescriptionItem}
                        <div>{relatedDocumentDescriptionItem}</div>
                    {/each}
                </div>
            {/if}
            <div class='px-12 pt-8'>
                <BusinessDocumentList
                        {businessDocument}
                        {invoiceCollateralData}
                />
            </div>
            {#if businessDocument.hasSpecificInstallment}
                <div class='px-12 pt-8'>
                    <BusinessDocumentInstallmentsPreview {businessDocument} />
                </div>
            {/if}
        </main>
        <footer class='w-full bg-whisper px-12 pt-4 pb-8'>
            {#if businessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE}
                <div class='flex items-center justify-between space-x-10 border-b border-athensGray py-2'>
                    <div class='w-6/12'>
                        <h3 class='font-bold text-xs mb-4'>{t('invoices.createInvoice.paymentInformation')}</h3>
                        <!--{#if !isBankInformationComplete($WorkspaceStore)}-->
                        {#if invoiceCollateralData.isBankInformationMissing}
                            <div class='flex space-x-3 cursor-pointer'
                                 on:click|stopPropagation={() => navigate('/settings/bank/view')}
                                 on:keyup={() => navigate('/settings/bank/view')}
                            >
                                <svg width='48' height='48' class='text-cabaret' viewBox='0 0 24 24'
                                     fill='none'
                                     xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                            d='M12 14.25C11.5858 14.25 11.25 14.5858 11.25 15C11.25 15.4142 11.5858 15.75 12 15.75V14.25ZM12.01 15.75C12.4242 15.75 12.76 15.4142 12.76 15C12.76 14.5858 12.4242 14.25 12.01 14.25V15.75ZM12 15.75H12.01V14.25H12V15.75Z'
                                            fill='currentColor'></path>
                                    <path
                                            d='M10.4033 5.41136L10.9337 5.94169L10.4033 5.41136ZM5.41136 10.4033L4.88103 9.87301L4.88103 9.87301L5.41136 10.4033ZM5.41136 13.5967L5.94169 13.0663L5.94169 13.0663L5.41136 13.5967ZM10.4033 18.5886L10.9337 18.0583L10.4033 18.5886ZM13.5967 18.5886L14.127 19.119L14.127 19.119L13.5967 18.5886ZM18.5886 10.4033L19.119 9.87301L19.119 9.87301L18.5886 10.4033ZM13.5967 5.41136L14.127 4.88103L14.127 4.88103L13.5967 5.41136ZM9.87301 4.88103L4.88103 9.87301L5.94169 10.9337L10.9337 5.94169L9.87301 4.88103ZM4.88103 14.127L9.87301 19.119L10.9337 18.0583L5.94169 13.0663L4.88103 14.127ZM14.127 19.119L19.119 14.127L18.0583 13.0663L13.0663 18.0583L14.127 19.119ZM19.119 9.87301L14.127 4.88103L13.0663 5.94169L18.0583 10.9337L19.119 9.87301ZM19.119 14.127C20.2937 12.9523 20.2937 11.0477 19.119 9.87301L18.0583 10.9337C18.6472 11.5226 18.6472 12.4774 18.0583 13.0663L19.119 14.127ZM9.87301 19.119C11.0477 20.2937 12.9523 20.2937 14.127 19.119L13.0663 18.0583C12.4774 18.6472 11.5226 18.6472 10.9337 18.0583L9.87301 19.119ZM4.88103 9.87301C3.70632 11.0477 3.70632 12.9523 4.88103 14.127L5.94169 13.0663C5.35277 12.4774 5.35277 11.5226 5.94169 10.9337L4.88103 9.87301ZM10.9337 5.94169C11.5226 5.35277 12.4774 5.35277 13.0663 5.94169L14.127 4.88103C12.9523 3.70632 11.0477 3.70632 9.87301 4.88103L10.9337 5.94169Z'
                                            fill='currentColor'></path>
                                    <path d='M12 8.75V12.25' stroke='currentColor' stroke-width='1.5'
                                          stroke-linecap='round' stroke-linejoin='round'></path>
                                </svg>

                                <p class='italic text-cabaret text-xxs'>{t('invoices.createInvoice.missingBankInformation')}</p>
                            </div>
                        {:else}
                            <p class='mb-2 flex'>
                                <span
                                        class='text-xxs text-loblolly w-32'>{t('invoices.createInvoice.beneficiaryCompanyName')}</span>
                                <span
                                        class='text-xxs text-black font-medium'>{businessDocument.accountCompany.formalName}</span>
                            </p>
                            <p class='mb-2 flex'>
                                <span
                                        class='text-xxs text-loblolly w-32'>{t('invoices.createInvoice.beneficiaryIBAN')}</span>
                                <span
                                        class='text-xxs text-black font-medium'>{businessDocument.accountBankingInformation.bankAccountIBAN}</span>
                            </p>
                            <p class='mb-2 flex'>
                                <span
                                        class='text-xxs text-loblolly w-32'>{t('invoices.createInvoice.beneficiaryBIC')}</span>
                                <span
                                        class='text-xxs text-black font-medium'>{businessDocument.accountBankingInformation.bankAccountBIC}</span>
                            </p>
                            <p class='mb-2 flex'>
                                <span
                                        class='text-xxs text-loblolly w-32'>{t('invoices.createInvoice.invoiceReference')}</span>
                                <span
                                        class='text-xxs text-black font-medium'>{businessDocument.accountBankingInformation.bankWireReference}</span>
                            </p>
                        {/if}
                    </div>
                    {#if invoiceCollateralData.showPaymentLink}
                        <div class='w-5/12 justify-end'>
                            {#if $WorkspaceStore.bankConfig.selectedAccountBankBIC}
                                <span class='btn action-bla primary'>{t('invoices.createInvoice.payOnlineLabel')}</span>
                            {:else}
                                <span class='btn action-bla bg-whisper text-slate-500'>{t('invoices.createInvoice.payOnlineLabel')}</span>
                            {/if}
                        </div>
                    {/if}
                </div>
            {/if}
            <BusinessDocumentFooter {businessDocument} />
        </footer>
    </div>
</div>
<style lang='postcss'>
    .invoice-generator-preview {
        @apply flex overflow-hidden;
        padding: 80px 30px 30px;
        overflow: auto;
        justify-content: center;
        align-items: center;
    }

    .a4-paper {
        @apply md:rounded-lg relative;
        width: 708px;
        min-height: 985px;
        overflow: hidden;
        background: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .invoice-generator-preview main {
        min-height: 610px;
        padding-bottom: 24px;
    }
</style>

<script lang="ts">
    import { defaultActions, type ReplaceFields, type ToolbarItem } from './html-editor'
    import { sanitizeHTML } from '../../../services/text-format'

    /** Export let */
    export let onchange: Function = (_html: string): Promise<Awaited<{}>> => Promise.resolve({})
    export let html: string = ''
    export let show_editor_toolbar: boolean = true
    export let replace_fields: ReplaceFields[] | null = null
    export let focus_body_onload: boolean = false
    export const id: string = 'html-editor'

    export const dataCy: string = ''

    /** Let declarations */
    let container: HTMLDivElement
    let toolbar: ToolbarItem[] = defaultActions
    let focusing: boolean = false

    $: if (focus_body_onload && container) {
      container.focus()
      handleHtmlBodyFocus()
    }

    $: if (html) {
      html = sanitizeHTML(html)
      const selection = window.getSelection()

      if (typeof replace_fields === 'string') {
        replace_fields = JSON.parse(replace_fields)
      }
      if (selection && Array.isArray(replace_fields)) {
        for (const field of replace_fields) {
          html = html.replace(new RegExp(field.from, 'g'), field.to)
          const currentFocusedNode: Node = selection.focusNode
          const currentCaretPosition: number = selection.focusOffset

          if (currentFocusedNode && currentFocusedNode.textContent) {
            if (currentFocusedNode.textContent.includes(field.from)) {
              const matchCount =
                                currentFocusedNode.textContent.split(field.from).length - 1
              const replaceNode = document.createTextNode(
                currentFocusedNode.textContent.replace(new RegExp(field.from, 'g'), field.to),
              );

              (currentFocusedNode as ChildNode).replaceWith(replaceNode)

              if (
                selection.focusNode &&
                                selection.focusNode.nodeType === Node.TEXT_NODE
              ) {
                if (matchCount > 1) {
                  setCaretPosition(
                    replaceNode,
                    currentCaretPosition +
                                        (field.to.length - field.from.length) +
                                        (matchCount - 1),
                  )
                } else {
                  setCaretPosition(
                    replaceNode,
                    currentCaretPosition + field.to.length - field.from.length,
                  )
                }
              }
            }
          }
        }
      }
      if (onchange) {
        onchange(html)
      }
    }
    /**
     * Sets the caret position at a given offset for a specific
     * node.
     *
     * @param node Node
     * @param offset Number
     */
    function setCaretPosition(node: Node, offset: number) {
      const range = document.createRange()
      const selection = window.getSelection()
      range.selectNode(node)
      range.setStart(node, offset)
      range.collapse(true)
      range.detach()
      selection?.removeAllRanges()
      selection?.addRange(range)
    }

    /**
     * This function is used to handle the toolbar actions
     * @param item
     */
    const handleAction = (item: ToolbarItem) => () => {
      if (item.result) {
        if (container) {
          container.focus()
        }
        item.result()
      }

      updateToolbarUI()
    }

    /***
     * This function updates the toolbar UI state when you select text (eg. select bold text)
     */
    function updateToolbarUI(): void {
      toolbar = toolbar.map((item: ToolbarItem) => {
        if (item.state) {
          item.active = item.state()
        }
        
        return item
      })
    }


    /**
     * This function is used to handle the focus of the contenteditable area
     */
    const handleHtmlBodyFocus = () => {
      // refocus editor to return cursor to editor - workaround for
      // firefox selection API / shadow DOM issues)
      if (focusing) {
        focusing = false
      } else {
        focusing = true
        setTimeout(() => {
          container.blur()
          container.focus()
        }, 0)
      }

      // if contenteditable area is empty we need to add something to add range
      if (container.innerHTML === '') {
        container.innerHTML = '\u00a0'
        const selection = window.getSelection()
        const range = document.createRange()
        range.selectNodeContents(container)
        range.collapse(false) // collapse range to the end

        selection.removeAllRanges()
        selection.addRange(range)
      }
    }


</script>

<style lang="postcss">
    .html-editor {
        @apply min-h-[180px] max-h-[400px]  rounded sm:text-sm shadow-none border border-loblolly mt-1.5;
    }
    .html-editor-content {
        @apply min-h-[180px] max-h-[400px] bg-white focus:ring-2 focus:ring-dundyOrange rounded-bl rounded-br;
    }
    .html-editor-content--no-toolbar {
        @apply rounded;
    }
    a {
        @apply text-dundyOrange hover:text-dundyOrange;
    }
    [contenteditable] {
        @apply text-black overflow-y-auto m-0 outline-0 border-loblolly;
        padding: 15px;
        line-height: 1.3;
    }
    .toolbar {
        @apply flex items-center justify-start border-b border-gray-200;
    }

    .toolbar button {
        @apply flex text-gray-500 hover:text-gray-700 border-0 cursor-pointer outline-none p-2.5 rounded-md active:bg-gray-100 hover:bg-gray-100 first-of-type:ml-0 transition-colors duration-300;
        transition: background-color 0.3s;
    }
</style>

<!-- Toolbar -->
<div class="html-editor">
    {#if show_editor_toolbar}
        <div class="toolbar">
            {#each toolbar as item}
                <button
                        on:click|stopPropagation|capture={handleAction(item)}
                        title={item.title}
                        class={item.state && item.state() ? 'active' : ''}>
                    {#if item.icon}
                        <slot>
                            {@html item.icon}
                        </slot>
                       <!-- <div
                                this={item.icon}
                                class="icon"
                                style="fill: var(&#45;&#45;composer-icons-color, #666774) !important; width: 12px; height: 12px;" />-->
                    {:else}{item.title.charAt(0)}{/if}
                </button>
            {/each}
        </div>
    {/if}

    {#if false}
        <!-- The following if statement exists to prevent svelte from stripping styling associated
          with the HTML tags within this if statement. This is required to add styling to the
          HTML added in the WYSIWYG editor.

          This hack was implemented because svelte does not parse the :global() selector in
          custom elements.

          Note: While the CSS gets added in the final bundle, none of the HTML is generated.
      -->
        <!-- svelte-ignore a11y-invalid-attribute -->
        <!-- svelte-ignore a11y-missing-attribute -->
        <!-- svelte-ignore a11y-missing-content -->
        <a />
    {/if}

    <div
            bind:this={container}
            bind:innerHTML={html}
            on:focus={handleHtmlBodyFocus}
            contenteditable="true"
            class="html-editor-content"
            class:html-editor-content--no-toolbar={!show_editor_toolbar}
            role="textbox"
            aria-label="HTML Editor"
            on:keyup={updateToolbarUI}
            on:mouseup={updateToolbarUI}
    ></div>
</div>
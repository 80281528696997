export const nafList = [
  {
    'code': '01',
    'descFr': 'Culture et production animale, chasse et services annexes'
  },
  {
    'code': '01.1',
    'descFr': 'Cultures non permanentes'
  },
  {
    'code': '01.11',
    'descFr': 'Culture de céréales (à l\'exception du riz), de légumineuses et de graines oléagineuses'
  },
  {
    'code': '01.11Z',
    'descFr': 'Culture de céréales (à l\'exception du riz), de légumineuses et de graines oléagineuses'
  },
  {
    'code': '01.12',
    'descFr': 'Culture du riz'
  },
  {
    'code': '01.12Z',
    'descFr': 'Culture du riz'
  },
  {
    'code': '01.13',
    'descFr': 'Culture de légumes, de melons, de racines et de tubercules'
  },
  {
    'code': '01.13Z',
    'descFr': 'Culture de légumes, de melons, de racines et de tubercules'
  },
  {
    'code': '01.14',
    'descFr': 'Culture de la canne à sucre'
  },
  {
    'code': '01.14Z',
    'descFr': 'Culture de la canne à sucre'
  },
  {
    'code': '01.15',
    'descFr': 'Culture du tabac'
  },
  {
    'code': '01.15Z',
    'descFr': 'Culture du tabac'
  },
  {
    'code': '01.16',
    'descFr': 'Culture de plantes à fibres'
  },
  {
    'code': '01.16Z',
    'descFr': 'Culture de plantes à fibres'
  },
  {
    'code': '01.19',
    'descFr': 'Autres cultures non permanentes'
  },
  {
    'code': '01.19Z',
    'descFr': 'Autres cultures non permanentes'
  },
  {
    'code': '01.2',
    'descFr': 'Cultures permanentes'
  },
  {
    'code': '01.21',
    'descFr': 'Culture de la vigne'
  },
  {
    'code': '01.21Z',
    'descFr': 'Culture de la vigne'
  },
  {
    'code': '01.22',
    'descFr': 'Culture de fruits tropicaux et subtropicaux'
  },
  {
    'code': '01.22Z',
    'descFr': 'Culture de fruits tropicaux et subtropicaux'
  },
  {
    'code': '01.23',
    'descFr': 'Culture d\'agrumes'
  },
  {
    'code': '01.23Z',
    'descFr': 'Culture d\'agrumes'
  },
  {
    'code': '01.24',
    'descFr': 'Culture de fruits à pépins et à noyau'
  },
  {
    'code': '01.24Z',
    'descFr': 'Culture de fruits à pépins et à noyau'
  },
  {
    'code': '01.25',
    'descFr': 'Culture d\'autres fruits d\'arbres ou d\'arbustes et de fruits à coque'
  },
  {
    'code': '01.25Z',
    'descFr': 'Culture d\'autres fruits d\'arbres ou d\'arbustes et de fruits à coque'
  },
  {
    'code': '01.26',
    'descFr': 'Culture de fruits oléagineux'
  },
  {
    'code': '01.26Z',
    'descFr': 'Culture de fruits oléagineux'
  },
  {
    'code': '01.27',
    'descFr': 'Culture de plantes à boissons'
  },
  {
    'code': '01.27Z',
    'descFr': 'Culture de plantes à boissons'
  },
  {
    'code': '01.28',
    'descFr': 'Culture de plantes à épices, aromatiques, médicinales et pharmaceutiques'
  },
  {
    'code': '01.28Z',
    'descFr': 'Culture de plantes à épices, aromatiques, médicinales et pharmaceutiques'
  },
  {
    'code': '01.29',
    'descFr': 'Autres cultures permanentes'
  },
  {
    'code': '01.29Z',
    'descFr': 'Autres cultures permanentes'
  },
  {
    'code': '01.3',
    'descFr': 'Reproduction de plantes'
  },
  {
    'code': '01.30',
    'descFr': 'Reproduction de plantes'
  },
  {
    'code': '01.30Z',
    'descFr': 'Reproduction de plantes'
  },
  {
    'code': '01.4',
    'descFr': 'Production animale'
  },
  {
    'code': '01.41',
    'descFr': 'Élevage de vaches laitières'
  },
  {
    'code': '01.41Z',
    'descFr': 'Élevage de vaches laitières'
  },
  {
    'code': '01.42',
    'descFr': 'Élevage d\'autres bovins et de buffles'
  },
  {
    'code': '01.42Z',
    'descFr': 'Élevage d\'autres bovins et de buffles'
  },
  {
    'code': '01.43',
    'descFr': 'Élevage de chevaux et d\'autres équidés'
  },
  {
    'code': '01.43Z',
    'descFr': 'Élevage de chevaux et d\'autres équidés'
  },
  {
    'code': '01.44',
    'descFr': 'Élevage de chameaux et d\'autres camélidés'
  },
  {
    'code': '01.44Z',
    'descFr': 'Élevage de chameaux et d\'autres camélidés'
  },
  {
    'code': '01.45',
    'descFr': 'Élevage d\'ovins et de caprins'
  },
  {
    'code': '01.45Z',
    'descFr': 'Élevage d\'ovins et de caprins'
  },
  {
    'code': '01.46',
    'descFr': 'Élevage de porcins'
  },
  {
    'code': '01.46Z',
    'descFr': 'Élevage de porcins'
  },
  {
    'code': '01.47',
    'descFr': 'Élevage de volailles'
  },
  {
    'code': '01.47Z',
    'descFr': 'Élevage de volailles'
  },
  {
    'code': '01.49',
    'descFr': 'Élevage d\'autres animaux'
  },
  {
    'code': '01.49Z',
    'descFr': 'Élevage d\'autres animaux'
  },
  {
    'code': '01.5',
    'descFr': 'Culture et élevage associés'
  },
  {
    'code': '01.50',
    'descFr': 'Culture et élevage associés'
  },
  {
    'code': '01.50Z',
    'descFr': 'Culture et élevage associés'
  },
  {
    'code': '01.6',
    'descFr': 'Activités de soutien à l\'agriculture et traitement primaire des récoltes'
  },
  {
    'code': '01.61',
    'descFr': 'Activités de soutien aux cultures'
  },
  {
    'code': '01.61Z',
    'descFr': 'Activités de soutien aux cultures'
  },
  {
    'code': '01.62',
    'descFr': 'Activités de soutien à la production animale'
  },
  {
    'code': '01.62Z',
    'descFr': 'Activités de soutien à la production animale'
  },
  {
    'code': '01.63',
    'descFr': 'Traitement primaire des récoltes'
  },
  {
    'code': '01.63Z',
    'descFr': 'Traitement primaire des récoltes'
  },
  {
    'code': '01.64',
    'descFr': 'Traitement des semences'
  },
  {
    'code': '01.64Z',
    'descFr': 'Traitement des semences'
  },
  {
    'code': '01.7',
    'descFr': 'Chasse, piégeage et services annexes'
  },
  {
    'code': '01.70',
    'descFr': 'Chasse, piégeage et services annexes'
  },
  {
    'code': '01.70Z',
    'descFr': 'Chasse, piégeage et services annexes'
  },
  {
    'code': '02',
    'descFr': 'Sylviculture et exploitation forestière'
  },
  {
    'code': '02.1',
    'descFr': 'Sylviculture et autres activités forestières'
  },
  {
    'code': '02.10',
    'descFr': 'Sylviculture et autres activités forestières'
  },
  {
    'code': '02.10Z',
    'descFr': 'Sylviculture et autres activités forestières'
  },
  {
    'code': '02.2',
    'descFr': 'Exploitation forestière'
  },
  {
    'code': '02.20',
    'descFr': 'Exploitation forestière'
  },
  {
    'code': '02.20Z',
    'descFr': 'Exploitation forestière'
  },
  {
    'code': '02.3',
    'descFr': 'Récolte de produits forestiers non ligneux poussant à l\'état sauvage'
  },
  {
    'code': '02.30',
    'descFr': 'Récolte de produits forestiers non ligneux poussant à l\'état sauvage'
  },
  {
    'code': '02.30Z',
    'descFr': 'Récolte de produits forestiers non ligneux poussant à l\'état sauvage'
  },
  {
    'code': '02.4',
    'descFr': 'Services de soutien à l\'exploitation forestière'
  },
  {
    'code': '02.40',
    'descFr': 'Services de soutien à l\'exploitation forestière'
  },
  {
    'code': '02.40Z',
    'descFr': 'Services de soutien à l\'exploitation forestière'
  },
  {
    'code': '03',
    'descFr': 'Pêche et aquaculture'
  },
  {
    'code': '03.1',
    'descFr': 'Pêche'
  },
  {
    'code': '03.11',
    'descFr': 'Pêche en mer'
  },
  {
    'code': '03.11Z',
    'descFr': 'Pêche en mer'
  },
  {
    'code': '03.12',
    'descFr': 'Pêche en eau douce'
  },
  {
    'code': '03.12Z',
    'descFr': 'Pêche en eau douce'
  },
  {
    'code': '03.2',
    'descFr': 'Aquaculture'
  },
  {
    'code': '03.21',
    'descFr': 'Aquaculture en mer'
  },
  {
    'code': '03.21Z',
    'descFr': 'Aquaculture en mer'
  },
  {
    'code': '03.22',
    'descFr': 'Aquaculture en eau douce'
  },
  {
    'code': '03.22Z',
    'descFr': 'Aquaculture en eau douce'
  },
  {
    'code': '05',
    'descFr': 'Extraction de houille et de lignite'
  },
  {
    'code': '05.1',
    'descFr': 'Extraction de houille'
  },
  {
    'code': '05.10',
    'descFr': 'Extraction de houille'
  },
  {
    'code': '05.10Z',
    'descFr': 'Extraction de houille'
  },
  {
    'code': '05.2',
    'descFr': 'Extraction de lignite'
  },
  {
    'code': '05.20',
    'descFr': 'Extraction de lignite'
  },
  {
    'code': '05.20Z',
    'descFr': 'Extraction de lignite'
  },
  {
    'code': '06',
    'descFr': 'Extraction d\'hydrocarbures'
  },
  {
    'code': '06.1',
    'descFr': 'Extraction de pétrole brut'
  },
  {
    'code': '06.10',
    'descFr': 'Extraction de pétrole brut'
  },
  {
    'code': '06.10Z',
    'descFr': 'Extraction de pétrole brut'
  },
  {
    'code': '06.2',
    'descFr': 'Extraction de gaz naturel'
  },
  {
    'code': '06.20',
    'descFr': 'Extraction de gaz naturel'
  },
  {
    'code': '06.20Z',
    'descFr': 'Extraction de gaz naturel'
  },
  {
    'code': '07',
    'descFr': 'Extraction de minerais métalliques'
  },
  {
    'code': '07.1',
    'descFr': 'Extraction de minerais de fer'
  },
  {
    'code': '07.10',
    'descFr': 'Extraction de minerais de fer'
  },
  {
    'code': '07.10Z',
    'descFr': 'Extraction de minerais de fer'
  },
  {
    'code': '07.2',
    'descFr': 'Extraction de minerais de métaux non ferreux'
  },
  {
    'code': '07.21',
    'descFr': 'Extraction de minerais d\'uranium et de thorium'
  },
  {
    'code': '07.21Z',
    'descFr': 'Extraction de minerais d\'uranium et de thorium'
  },
  {
    'code': '07.29',
    'descFr': 'Extraction d\'autres minerais de métaux non ferreux'
  },
  {
    'code': '07.29Z',
    'descFr': 'Extraction d\'autres minerais de métaux non ferreux'
  },
  {
    'code': '08',
    'descFr': 'Autres industries extractives'
  },
  {
    'code': '08.1',
    'descFr': 'Extraction de pierres, de sables et d\'argiles'
  },
  {
    'code': '08.11',
    'descFr': 'Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d\'ardoise'
  },
  {
    'code': '08.11Z',
    'descFr': 'Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d\'ardoise'
  },
  {
    'code': '08.12',
    'descFr': 'Exploitation de gravières et sablières, extraction d’argiles et de kaolin'
  },
  {
    'code': '08.12Z',
    'descFr': 'Exploitation de gravières et sablières, extraction d’argiles et de kaolin'
  },
  {
    'code': '08.9',
    'descFr': 'Activités extractives n.c.a.'
  },
  {
    'code': '08.91',
    'descFr': 'Extraction des minéraux chimiques et d\'engrais minéraux'
  },
  {
    'code': '08.91Z',
    'descFr': 'Extraction des minéraux chimiques et d\'engrais minéraux'
  },
  {
    'code': '08.92',
    'descFr': 'Extraction de tourbe'
  },
  {
    'code': '08.92Z',
    'descFr': 'Extraction de tourbe'
  },
  {
    'code': '08.93',
    'descFr': 'Production de sel'
  },
  {
    'code': '08.93Z',
    'descFr': 'Production de sel'
  },
  {
    'code': '08.99',
    'descFr': 'Autres activités extractives n.c.a.'
  },
  {
    'code': '08.99Z',
    'descFr': 'Autres activités extractives n.c.a.'
  },
  {
    'code': '09',
    'descFr': 'Services de soutien aux industries extractives'
  },
  {
    'code': '09.1',
    'descFr': 'Activités de soutien à l\'extraction d\'hydrocarbures'
  },
  {
    'code': '09.10',
    'descFr': 'Activités de soutien à l\'extraction d\'hydrocarbures'
  },
  {
    'code': '09.10Z',
    'descFr': 'Activités de soutien à l\'extraction d\'hydrocarbures'
  },
  {
    'code': '09.9',
    'descFr': 'Activités de soutien aux autres industries extractives'
  },
  {
    'code': '09.90',
    'descFr': 'Activités de soutien aux autres industries extractives'
  },
  {
    'code': '09.90Z',
    'descFr': 'Activités de soutien aux autres industries extractives'
  },
  {
    'code': '10',
    'descFr': 'Industries alimentaires'
  },
  {
    'code': '10.1',
    'descFr': 'Transformation et conservation de la viande et préparation de produits à base de viande'
  },
  {
    'code': '10.11',
    'descFr': 'Transformation et conservation de la viande de boucherie'
  },
  {
    'code': '10.11Z',
    'descFr': 'Transformation et conservation de la viande de boucherie'
  },
  {
    'code': '10.12',
    'descFr': 'Transformation et conservation de la viande de volaille'
  },
  {
    'code': '10.12Z',
    'descFr': 'Transformation et conservation de la viande de volaille'
  },
  {
    'code': '10.13',
    'descFr': 'Préparation de produits à base de viande'
  },
  {
    'code': '10.13A',
    'descFr': 'Préparation industrielle de produits à base de viande'
  },
  {
    'code': '10.13B',
    'descFr': 'Charcuterie'
  },
  {
    'code': '10.2',
    'descFr': 'Transformation et conservation de poisson, de crustacés et de mollusques'
  },
  {
    'code': '10.20',
    'descFr': 'Transformation et conservation de poisson, de crustacés et de mollusques'
  },
  {
    'code': '10.20Z',
    'descFr': 'Transformation et conservation de poisson, de crustacés et de mollusques'
  },
  {
    'code': '10.3',
    'descFr': 'Transformation et conservation de fruits et légumes'
  },
  {
    'code': '10.31',
    'descFr': 'Transformation et conservation de pommes de terre'
  },
  {
    'code': '10.31Z',
    'descFr': 'Transformation et conservation de pommes de terre'
  },
  {
    'code': '10.32',
    'descFr': 'Préparation de jus de fruits et légumes'
  },
  {
    'code': '10.32Z',
    'descFr': 'Préparation de jus de fruits et légumes'
  },
  {
    'code': '10.39',
    'descFr': 'Autre transformation et conservation de fruits et légumes'
  },
  {
    'code': '10.39A',
    'descFr': 'Autre transformation et conservation de légumes'
  },
  {
    'code': '10.39B',
    'descFr': 'Transformation et conservation de fruits'
  },
  {
    'code': '10.4',
    'descFr': 'Fabrication d’huiles et graisses végétales et animales'
  },
  {
    'code': '10.41',
    'descFr': 'Fabrication d\'huiles et graisses'
  },
  {
    'code': '10.41A',
    'descFr': 'Fabrication d\'huiles et graisses brutes'
  },
  {
    'code': '10.41B',
    'descFr': 'Fabrication d\'huiles et graisses raffinées'
  },
  {
    'code': '10.42',
    'descFr': 'Fabrication de margarine et graisses comestibles similaires'
  },
  {
    'code': '10.42Z',
    'descFr': 'Fabrication de margarine et graisses comestibles similaires'
  },
  {
    'code': '10.5',
    'descFr': 'Fabrication de produits laitiers'
  },
  {
    'code': '10.51',
    'descFr': 'Exploitation de laiteries et fabrication de fromage'
  },
  {
    'code': '10.51A',
    'descFr': 'Fabrication de lait liquide et de produits frais'
  },
  {
    'code': '10.51B',
    'descFr': 'Fabrication de beurre'
  },
  {
    'code': '10.51C',
    'descFr': 'Fabrication de fromage'
  },
  {
    'code': '10.51D',
    'descFr': 'Fabrication d\'autres produits laitiers'
  },
  {
    'code': '10.52',
    'descFr': 'Fabrication de glaces et sorbets'
  },
  {
    'code': '10.52Z',
    'descFr': 'Fabrication de glaces et sorbets'
  },
  {
    'code': '10.6',
    'descFr': 'Travail des grains ; fabrication de produits amylacés'
  },
  {
    'code': '10.61',
    'descFr': 'Travail des grains'
  },
  {
    'code': '10.61A',
    'descFr': 'Meunerie'
  },
  {
    'code': '10.61B',
    'descFr': 'Autres activités du travail des grains'
  },
  {
    'code': '10.62',
    'descFr': 'Fabrication de produits amylacés'
  },
  {
    'code': '10.62Z',
    'descFr': 'Fabrication de produits amylacés'
  },
  {
    'code': '10.7',
    'descFr': 'Fabrication de produits de boulangerie-pâtisserie et de pâtes alimentaires'
  },
  {
    'code': '10.71',
    'descFr': 'Fabrication de pain et de pâtisserie fraîche'
  },
  {
    'code': '10.71A',
    'descFr': 'Fabrication industrielle de pain et de pâtisserie fraîche'
  },
  {
    'code': '10.71B',
    'descFr': 'Cuisson de produits de boulangerie'
  },
  {
    'code': '10.71C',
    'descFr': 'Boulangerie et boulangerie-pâtisserie'
  },
  {
    'code': '10.71D',
    'descFr': 'Pâtisserie'
  },
  {
    'code': '10.72',
    'descFr': 'Fabrication de biscuits, biscottes et pâtisseries de conservation'
  },
  {
    'code': '10.72Z',
    'descFr': 'Fabrication de biscuits, biscottes et pâtisseries de conservation'
  },
  {
    'code': '10.73',
    'descFr': 'Fabrication de pâtes alimentaires'
  },
  {
    'code': '10.73Z',
    'descFr': 'Fabrication de pâtes alimentaires'
  },
  {
    'code': '10.8',
    'descFr': 'Fabrication d\'autres produits alimentaires'
  },
  {
    'code': '10.81',
    'descFr': 'Fabrication de sucre'
  },
  {
    'code': '10.81Z',
    'descFr': 'Fabrication de sucre'
  },
  {
    'code': '10.82',
    'descFr': 'Fabrication de cacao, chocolat et de produits de confiserie'
  },
  {
    'code': '10.82Z',
    'descFr': 'Fabrication de cacao, chocolat et de produits de confiserie'
  },
  {
    'code': '10.83',
    'descFr': 'Transformation du thé et du café'
  },
  {
    'code': '10.83Z',
    'descFr': 'Transformation du thé et du café'
  },
  {
    'code': '10.84',
    'descFr': 'Fabrication de condiments et assaisonnements'
  },
  {
    'code': '10.84Z',
    'descFr': 'Fabrication de condiments et assaisonnements'
  },
  {
    'code': '10.85',
    'descFr': 'Fabrication de plats préparés'
  },
  {
    'code': '10.85Z',
    'descFr': 'Fabrication de plats préparés'
  },
  {
    'code': '10.86',
    'descFr': 'Fabrication d\'aliments homogénéisés et diététiques'
  },
  {
    'code': '10.86Z',
    'descFr': 'Fabrication d\'aliments homogénéisés et diététiques'
  },
  {
    'code': '10.89',
    'descFr': 'Fabrication d\'autres produits alimentaires n.c.a.'
  },
  {
    'code': '10.89Z',
    'descFr': 'Fabrication d\'autres produits alimentaires n.c.a.'
  },
  {
    'code': '10.9',
    'descFr': 'Fabrication d\'aliments pour animaux'
  },
  {
    'code': '10.91',
    'descFr': 'Fabrication d\'aliments pour animaux de ferme'
  },
  {
    'code': '10.91Z',
    'descFr': 'Fabrication d\'aliments pour animaux de ferme'
  },
  {
    'code': '10.92',
    'descFr': 'Fabrication d\'aliments pour animaux de compagnie'
  },
  {
    'code': '10.92Z',
    'descFr': 'Fabrication d\'aliments pour animaux de compagnie'
  },
  {
    'code': '11',
    'descFr': 'Fabrication de boissons'
  },
  {
    'code': '11.0',
    'descFr': 'Fabrication de boissons'
  },
  {
    'code': '11.01',
    'descFr': 'Production de boissons alcooliques distillées'
  },
  {
    'code': '11.01Z',
    'descFr': 'Production de boissons alcooliques distillées'
  },
  {
    'code': '11.02',
    'descFr': 'Production de vin (de raisin)'
  },
  {
    'code': '11.02A',
    'descFr': 'Fabrication de vins effervescents'
  },
  {
    'code': '11.02B',
    'descFr': 'Vinification'
  },
  {
    'code': '11.03',
    'descFr': 'Fabrication de cidre et de vins de fruits'
  },
  {
    'code': '11.03Z',
    'descFr': 'Fabrication de cidre et de vins de fruits'
  },
  {
    'code': '11.04',
    'descFr': 'Production d\'autres boissons fermentées non distillées'
  },
  {
    'code': '11.04Z',
    'descFr': 'Production d\'autres boissons fermentées non distillées'
  },
  {
    'code': '11.05',
    'descFr': 'Fabrication de bière'
  },
  {
    'code': '11.05Z',
    'descFr': 'Fabrication de bière'
  },
  {
    'code': '11.06',
    'descFr': 'Fabrication de malt'
  },
  {
    'code': '11.06Z',
    'descFr': 'Fabrication de malt'
  },
  {
    'code': '11.07',
    'descFr': 'Industrie des eaux minérales et autres eaux embouteillées et des boissons rafraîchissantes'
  },
  {
    'code': '11.07A',
    'descFr': 'Industrie des eaux de table'
  },
  {
    'code': '11.07B',
    'descFr': 'Production de boissons rafraîchissantes'
  },
  {
    'code': '12',
    'descFr': 'Fabrication de produits à base de tabac'
  },
  {
    'code': '12.0',
    'descFr': 'Fabrication de produits à base de tabac'
  },
  {
    'code': '12.00',
    'descFr': 'Fabrication de produits à base de tabac'
  },
  {
    'code': '12.00Z',
    'descFr': 'Fabrication de produits à base de tabac'
  },
  {
    'code': '13',
    'descFr': 'Fabrication de textiles'
  },
  {
    'code': '13.1',
    'descFr': 'Préparation de fibres textiles et filature'
  },
  {
    'code': '13.10',
    'descFr': 'Préparation de fibres textiles et filature'
  },
  {
    'code': '13.10Z',
    'descFr': 'Préparation de fibres textiles et filature'
  },
  {
    'code': '13.2',
    'descFr': 'Tissage'
  },
  {
    'code': '13.20',
    'descFr': 'Tissage'
  },
  {
    'code': '13.20Z',
    'descFr': 'Tissage'
  },
  {
    'code': '13.3',
    'descFr': 'Ennoblissement textile'
  },
  {
    'code': '13.30',
    'descFr': 'Ennoblissement textile'
  },
  {
    'code': '13.30Z',
    'descFr': 'Ennoblissement textile'
  },
  {
    'code': '13.9',
    'descFr': 'Fabrication d\'autres textiles'
  },
  {
    'code': '13.91',
    'descFr': 'Fabrication d\'étoffes à mailles'
  },
  {
    'code': '13.91Z',
    'descFr': 'Fabrication d\'étoffes à mailles'
  },
  {
    'code': '13.92',
    'descFr': 'Fabrication d\'articles textiles, sauf habillement'
  },
  {
    'code': '13.92Z',
    'descFr': 'Fabrication d\'articles textiles, sauf habillement'
  },
  {
    'code': '13.93',
    'descFr': 'Fabrication de tapis et moquettes'
  },
  {
    'code': '13.93Z',
    'descFr': 'Fabrication de tapis et moquettes'
  },
  {
    'code': '13.94',
    'descFr': 'Fabrication de ficelles, cordes et filets'
  },
  {
    'code': '13.94Z',
    'descFr': 'Fabrication de ficelles, cordes et filets'
  },
  {
    'code': '13.95',
    'descFr': 'Fabrication de non-tissés, sauf habillement'
  },
  {
    'code': '13.95Z',
    'descFr': 'Fabrication de non-tissés, sauf habillement'
  },
  {
    'code': '13.96',
    'descFr': 'Fabrication d\'autres textiles techniques et industriels'
  },
  {
    'code': '13.96Z',
    'descFr': 'Fabrication d\'autres textiles techniques et industriels'
  },
  {
    'code': '13.99',
    'descFr': 'Fabrication d\'autres textiles n.c.a.'
  },
  {
    'code': '13.99Z',
    'descFr': 'Fabrication d\'autres textiles n.c.a.'
  },
  {
    'code': '14',
    'descFr': 'Industrie de l\'habillement'
  },
  {
    'code': '14.1',
    'descFr': 'Fabrication de vêtements, autres qu\'en fourrure'
  },
  {
    'code': '14.11',
    'descFr': 'Fabrication de vêtements en cuir'
  },
  {
    'code': '14.11Z',
    'descFr': 'Fabrication de vêtements en cuir'
  },
  {
    'code': '14.12',
    'descFr': 'Fabrication de vêtements de travail'
  },
  {
    'code': '14.12Z',
    'descFr': 'Fabrication de vêtements de travail'
  },
  {
    'code': '14.13',
    'descFr': 'Fabrication de vêtements de dessus'
  },
  {
    'code': '14.13Z',
    'descFr': 'Fabrication de vêtements de dessus'
  },
  {
    'code': '14.14',
    'descFr': 'Fabrication de vêtements de dessous'
  },
  {
    'code': '14.14Z',
    'descFr': 'Fabrication de vêtements de dessous'
  },
  {
    'code': '14.19',
    'descFr': 'Fabrication d\'autres vêtements et accessoires'
  },
  {
    'code': '14.19Z',
    'descFr': 'Fabrication d\'autres vêtements et accessoires'
  },
  {
    'code': '14.2',
    'descFr': 'Fabrication d\'articles en fourrure'
  },
  {
    'code': '14.20',
    'descFr': 'Fabrication d\'articles en fourrure'
  },
  {
    'code': '14.20Z',
    'descFr': 'Fabrication d\'articles en fourrure'
  },
  {
    'code': '14.3',
    'descFr': 'Fabrication d\'articles à mailles'
  },
  {
    'code': '14.31',
    'descFr': 'Fabrication d\'articles chaussants à mailles'
  },
  {
    'code': '14.31Z',
    'descFr': 'Fabrication d\'articles chaussants à mailles'
  },
  {
    'code': '14.39',
    'descFr': 'Fabrication d\'autres articles à mailles'
  },
  {
    'code': '14.39Z',
    'descFr': 'Fabrication d\'autres articles à mailles'
  },
  {
    'code': '15',
    'descFr': 'Industrie du cuir et de la chaussure'
  },
  {
    'code': '15.1',
    'descFr': 'Apprêt et tannage des cuirs ; préparation et teinture des fourrures ; fabrication d\'articles de voyage, de maroquinerie et de sellerie'
  },
  {
    'code': '15.11',
    'descFr': 'Apprêt et tannage des cuirs ; préparation et teinture des fourrures'
  },
  {
    'code': '15.11Z',
    'descFr': 'Apprêt et tannage des cuirs ; préparation et teinture des fourrures'
  },
  {
    'code': '15.12',
    'descFr': 'Fabrication d\'articles de voyage, de maroquinerie et de sellerie'
  },
  {
    'code': '15.12Z',
    'descFr': 'Fabrication d\'articles de voyage, de maroquinerie et de sellerie'
  },
  {
    'code': '15.2',
    'descFr': 'Fabrication de chaussures'
  },
  {
    'code': '15.20',
    'descFr': 'Fabrication de chaussures'
  },
  {
    'code': '15.20Z',
    'descFr': 'Fabrication de chaussures'
  },
  {
    'code': '16',
    'descFr': 'Travail du bois et fabrication d\'articles en bois et en liège, à l’exception des meubles ; fabrication d’articles en vannerie et sparterie'
  },
  {
    'code': '16.1',
    'descFr': 'Sciage et rabotage du bois'
  },
  {
    'code': '16.10',
    'descFr': 'Sciage et rabotage du bois'
  },
  {
    'code': '16.10A',
    'descFr': 'Sciage et rabotage du bois, hors imprégnation'
  },
  {
    'code': '16.10B',
    'descFr': 'Imprégnation du bois'
  },
  {
    'code': '16.2',
    'descFr': 'Fabrication d\'articles en bois, liège, vannerie et sparterie'
  },
  {
    'code': '16.21',
    'descFr': 'Fabrication de placage et de panneaux de bois'
  },
  {
    'code': '16.21Z',
    'descFr': 'Fabrication de placage et de panneaux de bois'
  },
  {
    'code': '16.22',
    'descFr': 'Fabrication de parquets assemblés'
  },
  {
    'code': '16.22Z',
    'descFr': 'Fabrication de parquets assemblés'
  },
  {
    'code': '16.23',
    'descFr': 'Fabrication de charpentes et d\'autres menuiseries'
  },
  {
    'code': '16.23Z',
    'descFr': 'Fabrication de charpentes et d\'autres menuiseries'
  },
  {
    'code': '16.24',
    'descFr': 'Fabrication d\'emballages en bois'
  },
  {
    'code': '16.24Z',
    'descFr': 'Fabrication d\'emballages en bois'
  },
  {
    'code': '16.29',
    'descFr': 'Fabrication d\'objets divers en bois ; fabrication d\'objets en liège, vannerie et sparterie'
  },
  {
    'code': '16.29Z',
    'descFr': 'Fabrication d\'objets divers en bois ; fabrication d\'objets en liège, vannerie et sparterie'
  },
  {
    'code': '17',
    'descFr': 'Industrie du papier et du carton'
  },
  {
    'code': '17.1',
    'descFr': 'Fabrication de pâte à papier, de papier et de carton'
  },
  {
    'code': '17.11',
    'descFr': 'Fabrication de pâte à papier'
  },
  {
    'code': '17.11Z',
    'descFr': 'Fabrication de pâte à papier'
  },
  {
    'code': '17.12',
    'descFr': 'Fabrication de papier et de carton'
  },
  {
    'code': '17.12Z',
    'descFr': 'Fabrication de papier et de carton'
  },
  {
    'code': '17.2',
    'descFr': 'Fabrication d\'articles en papier ou en carton'
  },
  {
    'code': '17.21',
    'descFr': 'Fabrication de papier et carton ondulés et d\'emballages en papier ou en carton'
  },
  {
    'code': '17.21A',
    'descFr': 'Fabrication de carton ondulé'
  },
  {
    'code': '17.21B',
    'descFr': 'Fabrication de cartonnages'
  },
  {
    'code': '17.21C',
    'descFr': 'Fabrication d\'emballages en papier'
  },
  {
    'code': '17.22',
    'descFr': 'Fabrication d\'articles en papier à usage sanitaire ou domestique'
  },
  {
    'code': '17.22Z',
    'descFr': 'Fabrication d\'articles en papier à usage sanitaire ou domestique'
  },
  {
    'code': '17.23',
    'descFr': 'Fabrication d\'articles de papeterie'
  },
  {
    'code': '17.23Z',
    'descFr': 'Fabrication d\'articles de papeterie'
  },
  {
    'code': '17.24',
    'descFr': 'Fabrication de papiers peints'
  },
  {
    'code': '17.24Z',
    'descFr': 'Fabrication de papiers peints'
  },
  {
    'code': '17.29',
    'descFr': 'Fabrication d\'autres articles en papier ou en carton'
  },
  {
    'code': '17.29Z',
    'descFr': 'Fabrication d\'autres articles en papier ou en carton'
  },
  {
    'code': '18',
    'descFr': 'Imprimerie et reproduction d\'enregistrements'
  },
  {
    'code': '18.1',
    'descFr': 'Imprimerie et services annexes'
  },
  {
    'code': '18.11',
    'descFr': 'Imprimerie de journaux'
  },
  {
    'code': '18.11Z',
    'descFr': 'Imprimerie de journaux'
  },
  {
    'code': '18.12',
    'descFr': 'Autre imprimerie (labeur)'
  },
  {
    'code': '18.12Z',
    'descFr': 'Autre imprimerie (labeur)'
  },
  {
    'code': '18.13',
    'descFr': 'Activités de pré-presse'
  },
  {
    'code': '18.13Z',
    'descFr': 'Activités de pré-presse'
  },
  {
    'code': '18.14',
    'descFr': 'Reliure et activités connexes'
  },
  {
    'code': '18.14Z',
    'descFr': 'Reliure et activités connexes'
  },
  {
    'code': '18.2',
    'descFr': 'Reproduction d\'enregistrements'
  },
  {
    'code': '18.20',
    'descFr': 'Reproduction d\'enregistrements'
  },
  {
    'code': '18.20Z',
    'descFr': 'Reproduction d\'enregistrements'
  },
  {
    'code': '19',
    'descFr': 'Cokéfaction et raffinage'
  },
  {
    'code': '19.1',
    'descFr': 'Cokéfaction'
  },
  {
    'code': '19.10',
    'descFr': 'Cokéfaction'
  },
  {
    'code': '19.10Z',
    'descFr': 'Cokéfaction'
  },
  {
    'code': '19.2',
    'descFr': 'Raffinage du pétrole'
  },
  {
    'code': '19.20',
    'descFr': 'Raffinage du pétrole'
  },
  {
    'code': '19.20Z',
    'descFr': 'Raffinage du pétrole'
  },
  {
    'code': '20',
    'descFr': 'Industrie chimique'
  },
  {
    'code': '20.1',
    'descFr': 'Fabrication de produits chimiques de base, de produits azotés et d\'engrais, de matières plastiques de base et de caoutchouc synthétique'
  },
  {
    'code': '20.11',
    'descFr': 'Fabrication de gaz industriels'
  },
  {
    'code': '20.11Z',
    'descFr': 'Fabrication de gaz industriels'
  },
  {
    'code': '20.12',
    'descFr': 'Fabrication de colorants et de pigments'
  },
  {
    'code': '20.12Z',
    'descFr': 'Fabrication de colorants et de pigments'
  },
  {
    'code': '20.13',
    'descFr': 'Fabrication d\'autres produits chimiques inorganiques de base'
  },
  {
    'code': '20.13A',
    'descFr': 'Enrichissement et  retraitement de matières nucléaires'
  },
  {
    'code': '20.13B',
    'descFr': 'Fabrication d\'autres produits chimiques inorganiques de base n.c.a.'
  },
  {
    'code': '20.14',
    'descFr': 'Fabrication d\'autres produits chimiques organiques de base'
  },
  {
    'code': '20.14Z',
    'descFr': 'Fabrication d\'autres produits chimiques organiques de base'
  },
  {
    'code': '20.15',
    'descFr': 'Fabrication de produits azotés et d\'engrais'
  },
  {
    'code': '20.15Z',
    'descFr': 'Fabrication de produits azotés et d\'engrais'
  },
  {
    'code': '20.16',
    'descFr': 'Fabrication de matières plastiques de base'
  },
  {
    'code': '20.16Z',
    'descFr': 'Fabrication de matières plastiques de base'
  },
  {
    'code': '20.17',
    'descFr': 'Fabrication de caoutchouc synthétique'
  },
  {
    'code': '20.17Z',
    'descFr': 'Fabrication de caoutchouc synthétique'
  },
  {
    'code': '20.2',
    'descFr': 'Fabrication de pesticides et d’autres produits agrochimiques'
  },
  {
    'code': '20.20',
    'descFr': 'Fabrication de pesticides et d’autres produits agrochimiques'
  },
  {
    'code': '20.20Z',
    'descFr': 'Fabrication de pesticides et d’autres produits agrochimiques'
  },
  {
    'code': '20.3',
    'descFr': 'Fabrication de peintures, vernis, encres et mastics'
  },
  {
    'code': '20.30',
    'descFr': 'Fabrication de peintures, vernis, encres et mastics'
  },
  {
    'code': '20.30Z',
    'descFr': 'Fabrication de peintures, vernis, encres et mastics'
  },
  {
    'code': '20.4',
    'descFr': 'Fabrication de savons, de produits d\'entretien et de parfums'
  },
  {
    'code': '20.41',
    'descFr': 'Fabrication de savons, détergents et produits d\'entretien'
  },
  {
    'code': '20.41Z',
    'descFr': 'Fabrication de savons, détergents et produits d\'entretien'
  },
  {
    'code': '20.42',
    'descFr': 'Fabrication de parfums et de produits pour la toilette'
  },
  {
    'code': '20.42Z',
    'descFr': 'Fabrication de parfums et de produits pour la toilette'
  },
  {
    'code': '20.5',
    'descFr': 'Fabrication d\'autres produits chimiques'
  },
  {
    'code': '20.51',
    'descFr': 'Fabrication de produits explosifs'
  },
  {
    'code': '20.51Z',
    'descFr': 'Fabrication de produits explosifs'
  },
  {
    'code': '20.52',
    'descFr': 'Fabrication de colles'
  },
  {
    'code': '20.52Z',
    'descFr': 'Fabrication de colles'
  },
  {
    'code': '20.53',
    'descFr': 'Fabrication d\'huiles essentielles'
  },
  {
    'code': '20.53Z',
    'descFr': 'Fabrication d\'huiles essentielles'
  },
  {
    'code': '20.59',
    'descFr': 'Fabrication d\'autres produits chimiques n.c.a.'
  },
  {
    'code': '20.59Z',
    'descFr': 'Fabrication d\'autres produits chimiques n.c.a.'
  },
  {
    'code': '20.6',
    'descFr': 'Fabrication de fibres artificielles ou synthétiques'
  },
  {
    'code': '20.60',
    'descFr': 'Fabrication de fibres artificielles ou synthétiques'
  },
  {
    'code': '20.60Z',
    'descFr': 'Fabrication de fibres artificielles ou synthétiques'
  },
  {
    'code': '21',
    'descFr': 'Industrie pharmaceutique'
  },
  {
    'code': '21.1',
    'descFr': 'Fabrication de produits pharmaceutiques de base'
  },
  {
    'code': '21.10',
    'descFr': 'Fabrication de produits pharmaceutiques de base'
  },
  {
    'code': '21.10Z',
    'descFr': 'Fabrication de produits pharmaceutiques de base'
  },
  {
    'code': '21.2',
    'descFr': 'Fabrication de préparations pharmaceutiques'
  },
  {
    'code': '21.20',
    'descFr': 'Fabrication de préparations pharmaceutiques'
  },
  {
    'code': '21.20Z',
    'descFr': 'Fabrication de préparations pharmaceutiques'
  },
  {
    'code': '22',
    'descFr': 'Fabrication de produits en caoutchouc et en plastique'
  },
  {
    'code': '22.1',
    'descFr': 'Fabrication de produits en caoutchouc'
  },
  {
    'code': '22.11',
    'descFr': 'Fabrication et rechapage de pneumatiques'
  },
  {
    'code': '22.11Z',
    'descFr': 'Fabrication et rechapage de pneumatiques'
  },
  {
    'code': '22.19',
    'descFr': 'Fabrication d\'autres articles en caoutchouc'
  },
  {
    'code': '22.19Z',
    'descFr': 'Fabrication d\'autres articles en caoutchouc'
  },
  {
    'code': '22.2',
    'descFr': 'Fabrication  de produits en plastique'
  },
  {
    'code': '22.21',
    'descFr': 'Fabrication de plaques, feuilles, tubes et profilés en matières plastiques'
  },
  {
    'code': '22.21Z',
    'descFr': 'Fabrication de plaques, feuilles, tubes et profilés en matières plastiques'
  },
  {
    'code': '22.22',
    'descFr': 'Fabrication d\'emballages en matières plastiques'
  },
  {
    'code': '22.22Z',
    'descFr': 'Fabrication d\'emballages en matières plastiques'
  },
  {
    'code': '22.23',
    'descFr': 'Fabrication d\'éléments en matières plastiques pour la construction'
  },
  {
    'code': '22.23Z',
    'descFr': 'Fabrication d\'éléments en matières plastiques pour la construction'
  },
  {
    'code': '22.29',
    'descFr': 'Fabrication d\'autres articles en matières plastiques'
  },
  {
    'code': '22.29A',
    'descFr': 'Fabrication de pièces techniques à base de matières plastiques'
  },
  {
    'code': '22.29B',
    'descFr': 'Fabrication de produits de consommation courante en matières plastiques'
  },
  {
    'code': '23',
    'descFr': 'Fabrication d\'autres produits minéraux non métalliques'
  },
  {
    'code': '23.1',
    'descFr': 'Fabrication de verre et d\'articles en verre'
  },
  {
    'code': '23.11',
    'descFr': 'Fabrication de verre plat'
  },
  {
    'code': '23.11Z',
    'descFr': 'Fabrication de verre plat'
  },
  {
    'code': '23.12',
    'descFr': 'Façonnage et transformation du verre plat'
  },
  {
    'code': '23.12Z',
    'descFr': 'Façonnage et transformation du verre plat'
  },
  {
    'code': '23.13',
    'descFr': 'Fabrication de verre creux'
  },
  {
    'code': '23.13Z',
    'descFr': 'Fabrication de verre creux'
  },
  {
    'code': '23.14',
    'descFr': 'Fabrication de fibres de verre'
  },
  {
    'code': '23.14Z',
    'descFr': 'Fabrication de fibres de verre'
  },
  {
    'code': '23.19',
    'descFr': 'Fabrication et façonnage d\'autres articles en verre, y compris verre technique'
  },
  {
    'code': '23.19Z',
    'descFr': 'Fabrication et façonnage d\'autres articles en verre, y compris verre technique'
  },
  {
    'code': '23.2',
    'descFr': 'Fabrication de produits réfractaires'
  },
  {
    'code': '23.20',
    'descFr': 'Fabrication de produits réfractaires'
  },
  {
    'code': '23.20Z',
    'descFr': 'Fabrication de produits réfractaires'
  },
  {
    'code': '23.3',
    'descFr': 'Fabrication de matériaux de construction en terre cuite'
  },
  {
    'code': '23.31',
    'descFr': 'Fabrication de carreaux en céramique'
  },
  {
    'code': '23.31Z',
    'descFr': 'Fabrication de carreaux en céramique'
  },
  {
    'code': '23.32',
    'descFr': 'Fabrication de briques, tuiles et produits de construction, en terre cuite'
  },
  {
    'code': '23.32Z',
    'descFr': 'Fabrication de briques, tuiles et produits de construction, en terre cuite'
  },
  {
    'code': '23.4',
    'descFr': 'Fabrication d\'autres produits en céramique et en porcelaine'
  },
  {
    'code': '23.41',
    'descFr': 'Fabrication d\'articles céramiques à usage domestique ou ornemental'
  },
  {
    'code': '23.41Z',
    'descFr': 'Fabrication d\'articles céramiques à usage domestique ou ornemental'
  },
  {
    'code': '23.42',
    'descFr': 'Fabrication d\'appareils sanitaires en céramique'
  },
  {
    'code': '23.42Z',
    'descFr': 'Fabrication d\'appareils sanitaires en céramique'
  },
  {
    'code': '23.43',
    'descFr': 'Fabrication d\'isolateurs et pièces isolantes en céramique'
  },
  {
    'code': '23.43Z',
    'descFr': 'Fabrication d\'isolateurs et pièces isolantes en céramique'
  },
  {
    'code': '23.44',
    'descFr': 'Fabrication d\'autres produits céramiques à usage technique'
  },
  {
    'code': '23.44Z',
    'descFr': 'Fabrication d\'autres produits céramiques à usage technique'
  },
  {
    'code': '23.49',
    'descFr': 'Fabrication d\'autres produits céramiques'
  },
  {
    'code': '23.49Z',
    'descFr': 'Fabrication d\'autres produits céramiques'
  },
  {
    'code': '23.5',
    'descFr': 'Fabrication de ciment, chaux et plâtre'
  },
  {
    'code': '23.51',
    'descFr': 'Fabrication de ciment'
  },
  {
    'code': '23.51Z',
    'descFr': 'Fabrication de ciment'
  },
  {
    'code': '23.52',
    'descFr': 'Fabrication de chaux et plâtre'
  },
  {
    'code': '23.52Z',
    'descFr': 'Fabrication de chaux et plâtre'
  },
  {
    'code': '23.6',
    'descFr': 'Fabrication d\'ouvrages en béton, en ciment ou en plâtre'
  },
  {
    'code': '23.61',
    'descFr': 'Fabrication d\'éléments en béton pour la construction'
  },
  {
    'code': '23.61Z',
    'descFr': 'Fabrication d\'éléments en béton pour la construction'
  },
  {
    'code': '23.62',
    'descFr': 'Fabrication d\'éléments en plâtre pour la construction'
  },
  {
    'code': '23.62Z',
    'descFr': 'Fabrication d\'éléments en plâtre pour la construction'
  },
  {
    'code': '23.63',
    'descFr': 'Fabrication de béton prêt à l\'emploi'
  },
  {
    'code': '23.63Z',
    'descFr': 'Fabrication de béton prêt à l\'emploi'
  },
  {
    'code': '23.64',
    'descFr': 'Fabrication de mortiers et bétons secs'
  },
  {
    'code': '23.64Z',
    'descFr': 'Fabrication de mortiers et bétons secs'
  },
  {
    'code': '23.65',
    'descFr': 'Fabrication d\'ouvrages en fibre-ciment'
  },
  {
    'code': '23.65Z',
    'descFr': 'Fabrication d\'ouvrages en fibre-ciment'
  },
  {
    'code': '23.69',
    'descFr': 'Fabrication d\'autres ouvrages en béton, en ciment ou en plâtre'
  },
  {
    'code': '23.69Z',
    'descFr': 'Fabrication d\'autres ouvrages en béton, en ciment ou en plâtre'
  },
  {
    'code': '23.7',
    'descFr': 'Taille, façonnage et finissage de pierres'
  },
  {
    'code': '23.70',
    'descFr': 'Taille, façonnage et finissage de pierres'
  },
  {
    'code': '23.70Z',
    'descFr': 'Taille, façonnage et finissage de pierres'
  },
  {
    'code': '23.9',
    'descFr': 'Fabrication de produits abrasifs et de produits minéraux non métalliques n.c.a.'
  },
  {
    'code': '23.91',
    'descFr': 'Fabrication de produits abrasifs'
  },
  {
    'code': '23.91Z',
    'descFr': 'Fabrication de produits abrasifs'
  },
  {
    'code': '23.99',
    'descFr': 'Fabrication d\'autres produits minéraux non métalliques n.c.a.'
  },
  {
    'code': '23.99Z',
    'descFr': 'Fabrication d\'autres produits minéraux non métalliques n.c.a.'
  },
  {
    'code': '24',
    'descFr': 'Métallurgie'
  },
  {
    'code': '24.1',
    'descFr': 'Sidérurgie'
  },
  {
    'code': '24.10',
    'descFr': 'Sidérurgie'
  },
  {
    'code': '24.10Z',
    'descFr': 'Sidérurgie'
  },
  {
    'code': '24.2',
    'descFr': 'Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier'
  },
  {
    'code': '24.20',
    'descFr': 'Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier'
  },
  {
    'code': '24.20Z',
    'descFr': 'Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier'
  },
  {
    'code': '24.3',
    'descFr': 'Fabrication d\'autres produits de première transformation de l\'acier'
  },
  {
    'code': '24.31',
    'descFr': 'Étirage à froid de barres'
  },
  {
    'code': '24.31Z',
    'descFr': 'Étirage à froid de barres'
  },
  {
    'code': '24.32',
    'descFr': 'Laminage à froid de feuillards'
  },
  {
    'code': '24.32Z',
    'descFr': 'Laminage à froid de feuillards'
  },
  {
    'code': '24.33',
    'descFr': 'Profilage à froid par formage ou pliage'
  },
  {
    'code': '24.33Z',
    'descFr': 'Profilage à froid par formage ou pliage'
  },
  {
    'code': '24.34',
    'descFr': 'Tréfilage à froid'
  },
  {
    'code': '24.34Z',
    'descFr': 'Tréfilage à froid'
  },
  {
    'code': '24.4',
    'descFr': 'Production de métaux précieux et d\'autres métaux non ferreux'
  },
  {
    'code': '24.41',
    'descFr': 'Production de métaux précieux'
  },
  {
    'code': '24.41Z',
    'descFr': 'Production de métaux précieux'
  },
  {
    'code': '24.42',
    'descFr': 'Métallurgie de l\'aluminium'
  },
  {
    'code': '24.42Z',
    'descFr': 'Métallurgie de l\'aluminium'
  },
  {
    'code': '24.43',
    'descFr': 'Métallurgie du plomb, du zinc ou de l\'étain'
  },
  {
    'code': '24.43Z',
    'descFr': 'Métallurgie du plomb, du zinc ou de l\'étain'
  },
  {
    'code': '24.44',
    'descFr': 'Métallurgie du cuivre'
  },
  {
    'code': '24.44Z',
    'descFr': 'Métallurgie du cuivre'
  },
  {
    'code': '24.45',
    'descFr': 'Métallurgie des autres métaux non ferreux'
  },
  {
    'code': '24.45Z',
    'descFr': 'Métallurgie des autres métaux non ferreux'
  },
  {
    'code': '24.46',
    'descFr': 'Élaboration et transformation de matières nucléaires'
  },
  {
    'code': '24.46Z',
    'descFr': 'Élaboration et transformation de matières nucléaires'
  },
  {
    'code': '24.5',
    'descFr': 'Fonderie'
  },
  {
    'code': '24.51',
    'descFr': 'Fonderie de fonte'
  },
  {
    'code': '24.51Z',
    'descFr': 'Fonderie de fonte'
  },
  {
    'code': '24.52',
    'descFr': 'Fonderie d\'acier'
  },
  {
    'code': '24.52Z',
    'descFr': 'Fonderie d\'acier'
  },
  {
    'code': '24.53',
    'descFr': 'Fonderie de métaux légers'
  },
  {
    'code': '24.53Z',
    'descFr': 'Fonderie de métaux légers'
  },
  {
    'code': '24.54',
    'descFr': 'Fonderie d\'autres métaux non ferreux'
  },
  {
    'code': '24.54Z',
    'descFr': 'Fonderie d\'autres métaux non ferreux'
  },
  {
    'code': '25',
    'descFr': 'Fabrication de produits métalliques, à l’exception des machines et des équipements'
  },
  {
    'code': '25.1',
    'descFr': 'Fabrication d\'éléments en métal pour la construction'
  },
  {
    'code': '25.11',
    'descFr': 'Fabrication de structures métalliques et de parties de structures'
  },
  {
    'code': '25.11Z',
    'descFr': 'Fabrication de structures métalliques et de parties de structures'
  },
  {
    'code': '25.12',
    'descFr': 'Fabrication de portes et fenêtres en métal'
  },
  {
    'code': '25.12Z',
    'descFr': 'Fabrication de portes et fenêtres en métal'
  },
  {
    'code': '25.2',
    'descFr': 'Fabrication de réservoirs, citernes et conteneurs métalliques'
  },
  {
    'code': '25.21',
    'descFr': 'Fabrication de radiateurs et de chaudières pour le chauffage central'
  },
  {
    'code': '25.21Z',
    'descFr': 'Fabrication de radiateurs et de chaudières pour le chauffage central'
  },
  {
    'code': '25.29',
    'descFr': 'Fabrication d\'autres réservoirs, citernes et conteneurs métalliques'
  },
  {
    'code': '25.29Z',
    'descFr': 'Fabrication d\'autres réservoirs, citernes et conteneurs métalliques'
  },
  {
    'code': '25.3',
    'descFr': 'Fabrication de générateurs de vapeur, à l\'exception des chaudières pour le chauffage central'
  },
  {
    'code': '25.30',
    'descFr': 'Fabrication de générateurs de vapeur, à l\'exception des chaudières pour le chauffage central'
  },
  {
    'code': '25.30Z',
    'descFr': 'Fabrication de générateurs de vapeur, à l\'exception des chaudières pour le chauffage central'
  },
  {
    'code': '25.4',
    'descFr': 'Fabrication d\'armes et de munitions'
  },
  {
    'code': '25.40',
    'descFr': 'Fabrication d\'armes et de munitions'
  },
  {
    'code': '25.40Z',
    'descFr': 'Fabrication d\'armes et de munitions'
  },
  {
    'code': '25.5',
    'descFr': 'Forge, emboutissage, estampage ; métallurgie des poudres'
  },
  {
    'code': '25.50',
    'descFr': 'Forge, emboutissage, estampage ; métallurgie des poudres'
  },
  {
    'code': '25.50A',
    'descFr': 'Forge, estampage, matriçage ; métallurgie des poudres'
  },
  {
    'code': '25.50B',
    'descFr': 'Découpage, emboutissage'
  },
  {
    'code': '25.6',
    'descFr': 'Traitement et revêtement des métaux ; usinage'
  },
  {
    'code': '25.61',
    'descFr': 'Traitement et revêtement des métaux'
  },
  {
    'code': '25.61Z',
    'descFr': 'Traitement et revêtement des métaux'
  },
  {
    'code': '25.62',
    'descFr': 'Usinage'
  },
  {
    'code': '25.62A',
    'descFr': 'Décolletage'
  },
  {
    'code': '25.62B',
    'descFr': 'Mécanique industrielle'
  },
  {
    'code': '25.7',
    'descFr': 'Fabrication de coutellerie, d\'outillage et de quincaillerie'
  },
  {
    'code': '25.71',
    'descFr': 'Fabrication de coutellerie'
  },
  {
    'code': '25.71Z',
    'descFr': 'Fabrication de coutellerie'
  },
  {
    'code': '25.72',
    'descFr': 'Fabrication de serrures et de ferrures'
  },
  {
    'code': '25.72Z',
    'descFr': 'Fabrication de serrures et de ferrures'
  },
  {
    'code': '25.73',
    'descFr': 'Fabrication d\'outillage'
  },
  {
    'code': '25.73A',
    'descFr': 'Fabrication de moules et modèles'
  },
  {
    'code': '25.73B',
    'descFr': 'Fabrication d\'autres outillages'
  },
  {
    'code': '25.9',
    'descFr': 'Fabrication d\'autres ouvrages en métaux'
  },
  {
    'code': '25.91',
    'descFr': 'Fabrication de fûts et emballages métalliques similaires'
  },
  {
    'code': '25.91Z',
    'descFr': 'Fabrication de fûts et emballages métalliques similaires'
  },
  {
    'code': '25.92',
    'descFr': 'Fabrication d\'emballages métalliques légers'
  },
  {
    'code': '25.92Z',
    'descFr': 'Fabrication d\'emballages métalliques légers'
  },
  {
    'code': '25.93',
    'descFr': 'Fabrication d\'articles en fils métalliques, de chaînes et de ressorts'
  },
  {
    'code': '25.93Z',
    'descFr': 'Fabrication d\'articles en fils métalliques, de chaînes et de ressorts'
  },
  {
    'code': '25.94',
    'descFr': 'Fabrication de vis et de boulons'
  },
  {
    'code': '25.94Z',
    'descFr': 'Fabrication de vis et de boulons'
  },
  {
    'code': '25.99',
    'descFr': 'Fabrication d\'autres produits métalliques n.c.a.'
  },
  {
    'code': '25.99A',
    'descFr': 'Fabrication d\'articles métalliques ménagers'
  },
  {
    'code': '25.99B',
    'descFr': 'Fabrication d\'autres articles métalliques'
  },
  {
    'code': '26',
    'descFr': 'Fabrication de produits informatiques, électroniques et optiques'
  },
  {
    'code': '26.1',
    'descFr': 'Fabrication de composants et cartes électroniques'
  },
  {
    'code': '26.11',
    'descFr': 'Fabrication de composants électroniques'
  },
  {
    'code': '26.11Z',
    'descFr': 'Fabrication de composants électroniques'
  },
  {
    'code': '26.12',
    'descFr': 'Fabrication de cartes électroniques assemblées'
  },
  {
    'code': '26.12Z',
    'descFr': 'Fabrication de cartes électroniques assemblées'
  },
  {
    'code': '26.2',
    'descFr': 'Fabrication d\'ordinateurs et d\'équipements périphériques'
  },
  {
    'code': '26.20',
    'descFr': 'Fabrication d\'ordinateurs et d\'équipements périphériques'
  },
  {
    'code': '26.20Z',
    'descFr': 'Fabrication d\'ordinateurs et d\'équipements périphériques'
  },
  {
    'code': '26.3',
    'descFr': 'Fabrication d\'équipements de communication'
  },
  {
    'code': '26.30',
    'descFr': 'Fabrication d\'équipements de communication'
  },
  {
    'code': '26.30Z',
    'descFr': 'Fabrication d\'équipements de communication'
  },
  {
    'code': '26.4',
    'descFr': 'Fabrication de produits électroniques grand public'
  },
  {
    'code': '26.40',
    'descFr': 'Fabrication de produits électroniques grand public'
  },
  {
    'code': '26.40Z',
    'descFr': 'Fabrication de produits électroniques grand public'
  },
  {
    'code': '26.5',
    'descFr': 'Fabrication d\'instruments et d\'appareils de mesure, d\'essai et de navigation ; horlogerie'
  },
  {
    'code': '26.51',
    'descFr': 'Fabrication d\'instruments et d\'appareils de mesure, d\'essai et de navigation'
  },
  {
    'code': '26.51A',
    'descFr': 'Fabrication d\'équipements d\'aide à la navigation'
  },
  {
    'code': '26.51B',
    'descFr': 'Fabrication d\'instrumentation scientifique et technique'
  },
  {
    'code': '26.52',
    'descFr': 'Horlogerie'
  },
  {
    'code': '26.52Z',
    'descFr': 'Horlogerie'
  },
  {
    'code': '26.6',
    'descFr': 'Fabrication d\'équipements d\'irradiation médicale, d\'équipements électromédicaux et électrothérapeutiques'
  },
  {
    'code': '26.60',
    'descFr': 'Fabrication d\'équipements d\'irradiation médicale, d\'équipements électromédicaux et électrothérapeutiques'
  },
  {
    'code': '26.60Z',
    'descFr': 'Fabrication d\'équipements d\'irradiation médicale, d\'équipements électromédicaux et électrothérapeutiques'
  },
  {
    'code': '26.7',
    'descFr': 'Fabrication de matériels optique et photographique'
  },
  {
    'code': '26.70',
    'descFr': 'Fabrication de matériels optique et photographique'
  },
  {
    'code': '26.70Z',
    'descFr': 'Fabrication de matériels optique et photographique'
  },
  {
    'code': '26.8',
    'descFr': 'Fabrication de supports magnétiques et optiques'
  },
  {
    'code': '26.80',
    'descFr': 'Fabrication de supports magnétiques et optiques'
  },
  {
    'code': '26.80Z',
    'descFr': 'Fabrication de supports magnétiques et optiques'
  },
  {
    'code': '27',
    'descFr': 'Fabrication d\'équipements électriques'
  },
  {
    'code': '27.1',
    'descFr': 'Fabrication de moteurs, génératrices et transformateurs électriques et de matériel de distribution et de commande électrique'
  },
  {
    'code': '27.11',
    'descFr': 'Fabrication de moteurs, génératrices et transformateurs électriques'
  },
  {
    'code': '27.11Z',
    'descFr': 'Fabrication de moteurs, génératrices et transformateurs électriques'
  },
  {
    'code': '27.12',
    'descFr': 'Fabrication de matériel de distribution et de commande électrique'
  },
  {
    'code': '27.12Z',
    'descFr': 'Fabrication de matériel de distribution et de commande électrique'
  },
  {
    'code': '27.2',
    'descFr': 'Fabrication de piles et d\'accumulateurs électriques'
  },
  {
    'code': '27.20',
    'descFr': 'Fabrication de piles et d\'accumulateurs électriques'
  },
  {
    'code': '27.20Z',
    'descFr': 'Fabrication de piles et d\'accumulateurs électriques'
  },
  {
    'code': '27.3',
    'descFr': 'Fabrication de fils et câbles et de matériel d\'installation électrique'
  },
  {
    'code': '27.31',
    'descFr': 'Fabrication de câbles de fibres optiques'
  },
  {
    'code': '27.31Z',
    'descFr': 'Fabrication de câbles de fibres optiques'
  },
  {
    'code': '27.32',
    'descFr': 'Fabrication d\'autres fils et câbles électroniques ou électriques'
  },
  {
    'code': '27.32Z',
    'descFr': 'Fabrication d\'autres fils et câbles électroniques ou électriques'
  },
  {
    'code': '27.33',
    'descFr': 'Fabrication de matériel d\'installation électrique'
  },
  {
    'code': '27.33Z',
    'descFr': 'Fabrication de matériel d\'installation électrique'
  },
  {
    'code': '27.4',
    'descFr': 'Fabrication d\'appareils d\'éclairage électrique'
  },
  {
    'code': '27.40',
    'descFr': 'Fabrication d\'appareils d\'éclairage électrique'
  },
  {
    'code': '27.40Z',
    'descFr': 'Fabrication d\'appareils d\'éclairage électrique'
  },
  {
    'code': '27.5',
    'descFr': 'Fabrication d\'appareils ménagers'
  },
  {
    'code': '27.51',
    'descFr': 'Fabrication d\'appareils électroménagers'
  },
  {
    'code': '27.51Z',
    'descFr': 'Fabrication d\'appareils électroménagers'
  },
  {
    'code': '27.52',
    'descFr': 'Fabrication d\'appareils ménagers non électriques'
  },
  {
    'code': '27.52Z',
    'descFr': 'Fabrication d\'appareils ménagers non électriques'
  },
  {
    'code': '27.9',
    'descFr': 'Fabrication d\'autres matériels électriques'
  },
  {
    'code': '27.90',
    'descFr': 'Fabrication d\'autres matériels électriques'
  },
  {
    'code': '27.90Z',
    'descFr': 'Fabrication d\'autres matériels électriques'
  },
  {
    'code': '28',
    'descFr': 'Fabrication de machines et équipements n.c.a.'
  },
  {
    'code': '28.1',
    'descFr': 'Fabrication de machines d\'usage général'
  },
  {
    'code': '28.11',
    'descFr': 'Fabrication de moteurs et turbines, à l\'exception des moteurs d’avions et de véhicules'
  },
  {
    'code': '28.11Z',
    'descFr': 'Fabrication de moteurs et turbines, à l\'exception des moteurs d’avions et de véhicules'
  },
  {
    'code': '28.12',
    'descFr': 'Fabrication d\'équipements hydrauliques et pneumatiques'
  },
  {
    'code': '28.12Z',
    'descFr': 'Fabrication d\'équipements hydrauliques et pneumatiques'
  },
  {
    'code': '28.13',
    'descFr': 'Fabrication d\'autres pompes et compresseurs'
  },
  {
    'code': '28.13Z',
    'descFr': 'Fabrication d\'autres pompes et compresseurs'
  },
  {
    'code': '28.14',
    'descFr': 'Fabrication d\'autres articles de robinetterie'
  },
  {
    'code': '28.14Z',
    'descFr': 'Fabrication d\'autres articles de robinetterie'
  },
  {
    'code': '28.15',
    'descFr': 'Fabrication d\'engrenages et d\'organes mécaniques de transmission'
  },
  {
    'code': '28.15Z',
    'descFr': 'Fabrication d\'engrenages et d\'organes mécaniques de transmission'
  },
  {
    'code': '28.2',
    'descFr': 'Fabrication d\'autres machines d\'usage général'
  },
  {
    'code': '28.21',
    'descFr': 'Fabrication de fours et brûleurs'
  },
  {
    'code': '28.21Z',
    'descFr': 'Fabrication de fours et brûleurs'
  },
  {
    'code': '28.22',
    'descFr': 'Fabrication de matériel de levage et de manutention'
  },
  {
    'code': '28.22Z',
    'descFr': 'Fabrication de matériel de levage et de manutention'
  },
  {
    'code': '28.23',
    'descFr': 'Fabrication de machines et d\'équipements de bureau (à l\'exception des ordinateurs et équipements périphériques)'
  },
  {
    'code': '28.23Z',
    'descFr': 'Fabrication de machines et d\'équipements de bureau (à l\'exception des ordinateurs et équipements périphériques)'
  },
  {
    'code': '28.24',
    'descFr': 'Fabrication d\'outillage portatif à moteur incorporé'
  },
  {
    'code': '28.24Z',
    'descFr': 'Fabrication d\'outillage portatif à moteur incorporé'
  },
  {
    'code': '28.25',
    'descFr': 'Fabrication d\'équipements aérauliques et frigorifiques industriels'
  },
  {
    'code': '28.25Z',
    'descFr': 'Fabrication d\'équipements aérauliques et frigorifiques industriels'
  },
  {
    'code': '28.29',
    'descFr': 'Fabrication de machines diverses d\'usage général'
  },
  {
    'code': '28.29A',
    'descFr': 'Fabrication d\'équipements d\'emballage, de conditionnement et de pesage'
  },
  {
    'code': '28.29B',
    'descFr': 'Fabrication d\'autres machines d\'usage général'
  },
  {
    'code': '28.3',
    'descFr': 'Fabrication de machines agricoles et forestières'
  },
  {
    'code': '28.30',
    'descFr': 'Fabrication de machines agricoles et forestières'
  },
  {
    'code': '28.30Z',
    'descFr': 'Fabrication de machines agricoles et forestières'
  },
  {
    'code': '28.4',
    'descFr': 'Fabrication de machines de formage des métaux et de machines-outils'
  },
  {
    'code': '28.41',
    'descFr': 'Fabrication de machines de formage des métaux'
  },
  {
    'code': '28.41Z',
    'descFr': 'Fabrication de machines-outils pour le travail des métaux'
  },
  {
    'code': '28.49',
    'descFr': 'Fabrication d\'autres machines-outils'
  },
  {
    'code': '28.49Z',
    'descFr': 'Fabrication d\'autres machines-outils'
  },
  {
    'code': '28.9',
    'descFr': 'Fabrication d\'autres machines d\'usage spécifique'
  },
  {
    'code': '28.91',
    'descFr': 'Fabrication de machines pour la métallurgie'
  },
  {
    'code': '28.91Z',
    'descFr': 'Fabrication de machines pour la métallurgie'
  },
  {
    'code': '28.92',
    'descFr': 'Fabrication de machines pour l\'extraction ou la construction'
  },
  {
    'code': '28.92Z',
    'descFr': 'Fabrication de machines pour l\'extraction ou la construction'
  },
  {
    'code': '28.93',
    'descFr': 'Fabrication de machines pour l\'industrie agro-alimentaire'
  },
  {
    'code': '28.93Z',
    'descFr': 'Fabrication de machines pour l\'industrie agro-alimentaire'
  },
  {
    'code': '28.94',
    'descFr': 'Fabrication de machines pour les industries textiles'
  },
  {
    'code': '28.94Z',
    'descFr': 'Fabrication de machines pour les industries textiles'
  },
  {
    'code': '28.95',
    'descFr': 'Fabrication de machines pour les industries du papier et du carton'
  },
  {
    'code': '28.95Z',
    'descFr': 'Fabrication de machines pour les industries du papier et du carton'
  },
  {
    'code': '28.96',
    'descFr': 'Fabrication de machines pour le travail du caoutchouc ou des plastiques'
  },
  {
    'code': '28.96Z',
    'descFr': 'Fabrication de machines pour le travail du caoutchouc ou des plastiques'
  },
  {
    'code': '28.99',
    'descFr': 'Fabrication d\'autres machines d\'usage spécifique n.c.a.'
  },
  {
    'code': '28.99A',
    'descFr': 'Fabrication de machines d\'imprimerie'
  },
  {
    'code': '28.99B',
    'descFr': 'Fabrication d\'autres machines spécialisées'
  },
  {
    'code': '29',
    'descFr': 'Industrie automobile'
  },
  {
    'code': '29.1',
    'descFr': 'Construction de véhicules automobiles'
  },
  {
    'code': '29.10',
    'descFr': 'Construction de véhicules automobiles'
  },
  {
    'code': '29.10Z',
    'descFr': 'Construction de véhicules automobiles'
  },
  {
    'code': '29.2',
    'descFr': 'Fabrication de carrosseries et remorques'
  },
  {
    'code': '29.20',
    'descFr': 'Fabrication de carrosseries et remorques'
  },
  {
    'code': '29.20Z',
    'descFr': 'Fabrication de carrosseries et remorques'
  },
  {
    'code': '29.3',
    'descFr': 'Fabrication d\'équipements automobiles'
  },
  {
    'code': '29.31',
    'descFr': 'Fabrication d\'équipements électriques et électroniques automobiles'
  },
  {
    'code': '29.31Z',
    'descFr': 'Fabrication d\'équipements électriques et électroniques automobiles'
  },
  {
    'code': '29.32',
    'descFr': 'Fabrication d\'autres équipements automobiles'
  },
  {
    'code': '29.32Z',
    'descFr': 'Fabrication d\'autres équipements automobiles'
  },
  {
    'code': '30',
    'descFr': 'Fabrication d\'autres matériels de transport'
  },
  {
    'code': '30.1',
    'descFr': 'Construction navale'
  },
  {
    'code': '30.11',
    'descFr': 'Construction de navires et de structures flottantes'
  },
  {
    'code': '30.11Z',
    'descFr': 'Construction de navires et de structures flottantes'
  },
  {
    'code': '30.12',
    'descFr': 'Construction de bateaux de plaisance'
  },
  {
    'code': '30.12Z',
    'descFr': 'Construction de bateaux de plaisance'
  },
  {
    'code': '30.2',
    'descFr': 'Construction de locomotives et d\'autre matériel ferroviaire roulant'
  },
  {
    'code': '30.20',
    'descFr': 'Construction de locomotives et d\'autre matériel ferroviaire roulant'
  },
  {
    'code': '30.20Z',
    'descFr': 'Construction de locomotives et d\'autre matériel ferroviaire roulant'
  },
  {
    'code': '30.3',
    'descFr': 'Construction aéronautique et spatiale'
  },
  {
    'code': '30.30',
    'descFr': 'Construction aéronautique et spatiale'
  },
  {
    'code': '30.30Z',
    'descFr': 'Construction aéronautique et spatiale'
  },
  {
    'code': '30.4',
    'descFr': 'Construction de véhicules militaires de combat'
  },
  {
    'code': '30.40',
    'descFr': 'Construction de véhicules militaires de combat'
  },
  {
    'code': '30.40Z',
    'descFr': 'Construction de véhicules militaires de combat'
  },
  {
    'code': '30.9',
    'descFr': 'Fabrication de matériels de transport n.c.a.'
  },
  {
    'code': '30.91',
    'descFr': 'Fabrication de motocycles'
  },
  {
    'code': '30.91Z',
    'descFr': 'Fabrication de motocycles'
  },
  {
    'code': '30.92',
    'descFr': 'Fabrication de bicyclettes et de véhicules pour invalides'
  },
  {
    'code': '30.92Z',
    'descFr': 'Fabrication de bicyclettes et de véhicules pour invalides'
  },
  {
    'code': '30.99',
    'descFr': 'Fabrication d’autres équipements de transport n.c.a.'
  },
  {
    'code': '30.99Z',
    'descFr': 'Fabrication d’autres équipements de transport n.c.a.'
  },
  {
    'code': '31',
    'descFr': 'Fabrication de meubles'
  },
  {
    'code': '31.0',
    'descFr': 'Fabrication de meubles'
  },
  {
    'code': '31.01',
    'descFr': 'Fabrication de meubles de bureau et de magasin'
  },
  {
    'code': '31.01Z',
    'descFr': 'Fabrication de meubles de bureau et de magasin'
  },
  {
    'code': '31.02',
    'descFr': 'Fabrication de meubles de cuisine'
  },
  {
    'code': '31.02Z',
    'descFr': 'Fabrication de meubles de cuisine'
  },
  {
    'code': '31.03',
    'descFr': 'Fabrication de matelas'
  },
  {
    'code': '31.03Z',
    'descFr': 'Fabrication de matelas'
  },
  {
    'code': '31.09',
    'descFr': 'Fabrication d\'autres meubles'
  },
  {
    'code': '31.09A',
    'descFr': 'Fabrication de sièges d\'ameublement d\'intérieur'
  },
  {
    'code': '31.09B',
    'descFr': 'Fabrication d’autres meubles et industries connexes de l’ameublement'
  },
  {
    'code': '32',
    'descFr': 'Autres industries manufacturières'
  },
  {
    'code': '32.1',
    'descFr': 'Fabrication d’articles de joaillerie, bijouterie et articles similaires'
  },
  {
    'code': '32.11',
    'descFr': 'Frappe de monnaie'
  },
  {
    'code': '32.11Z',
    'descFr': 'Frappe de monnaie'
  },
  {
    'code': '32.12',
    'descFr': 'Fabrication d’articles de joaillerie et bijouterie'
  },
  {
    'code': '32.12Z',
    'descFr': 'Fabrication d’articles de joaillerie et bijouterie'
  },
  {
    'code': '32.13',
    'descFr': 'Fabrication d’articles de bijouterie fantaisie et articles similaires'
  },
  {
    'code': '32.13Z',
    'descFr': 'Fabrication d’articles de bijouterie fantaisie et articles similaires'
  },
  {
    'code': '32.2',
    'descFr': 'Fabrication d\'instruments de musique'
  },
  {
    'code': '32.20',
    'descFr': 'Fabrication d\'instruments de musique'
  },
  {
    'code': '32.20Z',
    'descFr': 'Fabrication d\'instruments de musique'
  },
  {
    'code': '32.3',
    'descFr': 'Fabrication d\'articles de sport'
  },
  {
    'code': '32.30',
    'descFr': 'Fabrication d\'articles de sport'
  },
  {
    'code': '32.30Z',
    'descFr': 'Fabrication d\'articles de sport'
  },
  {
    'code': '32.4',
    'descFr': 'Fabrication de jeux et jouets'
  },
  {
    'code': '32.40',
    'descFr': 'Fabrication de jeux et jouets'
  },
  {
    'code': '32.40Z',
    'descFr': 'Fabrication de jeux et jouets'
  },
  {
    'code': '32.5',
    'descFr': 'Fabrication d\'instruments et de fournitures à usage médical et dentaire'
  },
  {
    'code': '32.50',
    'descFr': 'Fabrication d\'instruments et de fournitures à usage médical et dentaire'
  },
  {
    'code': '32.50A',
    'descFr': 'Fabrication de matériel médico-chirurgical et dentaire'
  },
  {
    'code': '32.50B',
    'descFr': 'Fabrication de lunettes'
  },
  {
    'code': '32.9',
    'descFr': 'Activités manufacturières n.c.a.'
  },
  {
    'code': '32.91',
    'descFr': 'Fabrication d’articles de brosserie'
  },
  {
    'code': '32.91Z',
    'descFr': 'Fabrication d’articles de brosserie'
  },
  {
    'code': '32.99',
    'descFr': 'Autres activités manufacturières n.c.a.'
  },
  {
    'code': '32.99Z',
    'descFr': 'Autres activités manufacturières n.c.a.'
  },
  {
    'code': '33',
    'descFr': 'Réparation et installation de machines et d\'équipements'
  },
  {
    'code': '33.1',
    'descFr': 'Réparation d\'ouvrages en métaux, de machines et d\'équipements'
  },
  {
    'code': '33.11',
    'descFr': 'Réparation d\'ouvrages en métaux'
  },
  {
    'code': '33.11Z',
    'descFr': 'Réparation d\'ouvrages en métaux'
  },
  {
    'code': '33.12',
    'descFr': 'Réparation de machines et équipements mécaniques'
  },
  {
    'code': '33.12Z',
    'descFr': 'Réparation de machines et équipements mécaniques'
  },
  {
    'code': '33.13',
    'descFr': 'Réparation de matériels électroniques et optiques'
  },
  {
    'code': '33.13Z',
    'descFr': 'Réparation de matériels électroniques et optiques'
  },
  {
    'code': '33.14',
    'descFr': 'Réparation d\'équipements électriques'
  },
  {
    'code': '33.14Z',
    'descFr': 'Réparation d\'équipements électriques'
  },
  {
    'code': '33.15',
    'descFr': 'Réparation et maintenance navale'
  },
  {
    'code': '33.15Z',
    'descFr': 'Réparation et maintenance navale'
  },
  {
    'code': '33.16',
    'descFr': 'Réparation et maintenance d\'aéronefs et d\'engins spatiaux'
  },
  {
    'code': '33.16Z',
    'descFr': 'Réparation et maintenance d\'aéronefs et d\'engins spatiaux'
  },
  {
    'code': '33.17',
    'descFr': 'Réparation et maintenance d\'autres équipements de transport'
  },
  {
    'code': '33.17Z',
    'descFr': 'Réparation et maintenance d\'autres équipements de transport'
  },
  {
    'code': '33.19',
    'descFr': 'Réparation d\'autres équipements'
  },
  {
    'code': '33.19Z',
    'descFr': 'Réparation d\'autres équipements'
  },
  {
    'code': '33.2',
    'descFr': 'Installation de machines et d\'équipements industriels'
  },
  {
    'code': '33.20',
    'descFr': 'Installation de machines et d\'équipements industriels'
  },
  {
    'code': '33.20A',
    'descFr': 'Installation de structures métalliques, chaudronnées et de tuyauterie'
  },
  {
    'code': '33.20B',
    'descFr': 'Installation de machines et équipements mécaniques'
  },
  {
    'code': '33.20C',
    'descFr': 'Conception d\'ensemble et assemblage sur site industriel d\'équipements de contrôle des processus industriels'
  },
  {
    'code': '33.20D',
    'descFr': 'Installation d\'équipements électriques, de matériels électroniques et optiques ou d\'autres matériels'
  },
  {
    'code': '35',
    'descFr': 'Production et distribution d\'électricité, de gaz, de vapeur et d\'air conditionné'
  },
  {
    'code': '35.1',
    'descFr': 'Production, transport et distribution d\'électricité'
  },
  {
    'code': '35.11',
    'descFr': 'Production d\'électricité'
  },
  {
    'code': '35.11Z',
    'descFr': 'Production d\'électricité'
  },
  {
    'code': '35.12',
    'descFr': 'Transport d\'électricité'
  },
  {
    'code': '35.12Z',
    'descFr': 'Transport d\'électricité'
  },
  {
    'code': '35.13',
    'descFr': 'Distribution d\'électricité'
  },
  {
    'code': '35.13Z',
    'descFr': 'Distribution d\'électricité'
  },
  {
    'code': '35.14',
    'descFr': 'Commerce d\'électricité'
  },
  {
    'code': '35.14Z',
    'descFr': 'Commerce d\'électricité'
  },
  {
    'code': '35.2',
    'descFr': 'Production et distribution de combustibles gazeux'
  },
  {
    'code': '35.21',
    'descFr': 'Production de combustibles gazeux'
  },
  {
    'code': '35.21Z',
    'descFr': 'Production de combustibles gazeux'
  },
  {
    'code': '35.22',
    'descFr': 'Distribution de combustibles gazeux par conduites'
  },
  {
    'code': '35.22Z',
    'descFr': 'Distribution de combustibles gazeux par conduites'
  },
  {
    'code': '35.23',
    'descFr': 'Commerce de combustibles gazeux par conduites'
  },
  {
    'code': '35.23Z',
    'descFr': 'Commerce de combustibles gazeux par conduites'
  },
  {
    'code': '35.3',
    'descFr': 'Production et distribution de vapeur et d\'air conditionné'
  },
  {
    'code': '35.30',
    'descFr': 'Production et distribution de vapeur et d\'air conditionné'
  },
  {
    'code': '35.30Z',
    'descFr': 'Production et distribution de vapeur et d\'air conditionné'
  },
  {
    'code': '36',
    'descFr': 'Captage, traitement et distribution d\'eau'
  },
  {
    'code': '36.0',
    'descFr': 'Captage, traitement et distribution d\'eau'
  },
  {
    'code': '36.00',
    'descFr': 'Captage, traitement et distribution d\'eau'
  },
  {
    'code': '36.00Z',
    'descFr': 'Captage, traitement et distribution d\'eau'
  },
  {
    'code': '37',
    'descFr': 'Collecte et traitement des eaux usées'
  },
  {
    'code': '37.0',
    'descFr': 'Collecte et traitement des eaux usées'
  },
  {
    'code': '37.00',
    'descFr': 'Collecte et traitement des eaux usées'
  },
  {
    'code': '37.00Z',
    'descFr': 'Collecte et traitement des eaux usées'
  },
  {
    'code': '38',
    'descFr': 'Collecte, traitement et élimination des déchets ; récupération'
  },
  {
    'code': '38.1',
    'descFr': 'Collecte des déchets'
  },
  {
    'code': '38.11',
    'descFr': 'Collecte des déchets non dangereux'
  },
  {
    'code': '38.11Z',
    'descFr': 'Collecte des déchets non dangereux'
  },
  {
    'code': '38.12',
    'descFr': 'Collecte des déchets dangereux'
  },
  {
    'code': '38.12Z',
    'descFr': 'Collecte des déchets dangereux'
  },
  {
    'code': '38.2',
    'descFr': 'Traitement et élimination des déchets'
  },
  {
    'code': '38.21',
    'descFr': 'Traitement et élimination des déchets non dangereux'
  },
  {
    'code': '38.21Z',
    'descFr': 'Traitement et élimination des déchets non dangereux'
  },
  {
    'code': '38.22',
    'descFr': 'Traitement et élimination des déchets dangereux'
  },
  {
    'code': '38.22Z',
    'descFr': 'Traitement et élimination des déchets dangereux'
  },
  {
    'code': '38.3',
    'descFr': 'Récupération'
  },
  {
    'code': '38.31',
    'descFr': 'Démantèlement d\'épaves'
  },
  {
    'code': '38.31Z',
    'descFr': 'Démantèlement d\'épaves'
  },
  {
    'code': '38.32',
    'descFr': 'Récupération de déchets triés'
  },
  {
    'code': '38.32Z',
    'descFr': 'Récupération de déchets triés'
  },
  {
    'code': '39',
    'descFr': 'Dépollution et autres services de gestion des déchets'
  },
  {
    'code': '39.0',
    'descFr': 'Dépollution et autres services de gestion des déchets'
  },
  {
    'code': '39.00',
    'descFr': 'Dépollution et autres services de gestion des déchets'
  },
  {
    'code': '39.00Z',
    'descFr': 'Dépollution et autres services de gestion des déchets'
  },
  {
    'code': '41',
    'descFr': 'Construction de bâtiments'
  },
  {
    'code': '41.1',
    'descFr': 'Promotion immobilière'
  },
  {
    'code': '41.10',
    'descFr': 'Promotion immobilière'
  },
  {
    'code': '41.10A',
    'descFr': 'Promotion immobilière de logements'
  },
  {
    'code': '41.10B',
    'descFr': 'Promotion immobilière de bureaux'
  },
  {
    'code': '41.10C',
    'descFr': 'Promotion immobilière d\'autres bâtiments'
  },
  {
    'code': '41.10D',
    'descFr': 'Supports juridiques de programmes'
  },
  {
    'code': '41.2',
    'descFr': 'Construction de bâtiments résidentiels et non résidentiels'
  },
  {
    'code': '41.20',
    'descFr': 'Construction de bâtiments résidentiels et non résidentiels'
  },
  {
    'code': '41.20A',
    'descFr': 'Construction de maisons individuelles'
  },
  {
    'code': '41.20B',
    'descFr': 'Construction d\'autres bâtiments'
  },
  {
    'code': '42',
    'descFr': 'Génie civil'
  },
  {
    'code': '42.1',
    'descFr': 'Construction de routes et de voies ferrées'
  },
  {
    'code': '42.11',
    'descFr': 'Construction de routes et autoroutes'
  },
  {
    'code': '42.11Z',
    'descFr': 'Construction de routes et autoroutes'
  },
  {
    'code': '42.12',
    'descFr': 'Construction de voies ferrées de surface et souterraines'
  },
  {
    'code': '42.12Z',
    'descFr': 'Construction de voies ferrées de surface et souterraines'
  },
  {
    'code': '42.13',
    'descFr': 'Construction de ponts et tunnels'
  },
  {
    'code': '42.13A',
    'descFr': 'Construction d\'ouvrages d\'art'
  },
  {
    'code': '42.13B',
    'descFr': 'Construction et entretien de tunnels'
  },
  {
    'code': '42.2',
    'descFr': 'Construction de réseaux et de lignes'
  },
  {
    'code': '42.21',
    'descFr': 'Construction de réseaux pour fluides'
  },
  {
    'code': '42.21Z',
    'descFr': 'Construction de réseaux pour fluides'
  },
  {
    'code': '42.22',
    'descFr': 'Construction de réseaux électriques et de télécommunications'
  },
  {
    'code': '42.22Z',
    'descFr': 'Construction de réseaux électriques et de télécommunications'
  },
  {
    'code': '42.9',
    'descFr': 'Construction d\'autres ouvrages de génie civil'
  },
  {
    'code': '42.91',
    'descFr': 'Construction d\'ouvrages maritimes et fluviaux'
  },
  {
    'code': '42.91Z',
    'descFr': 'Construction d\'ouvrages maritimes et fluviaux'
  },
  {
    'code': '42.99',
    'descFr': 'Construction d\'autres ouvrages de génie civil n.c.a.'
  },
  {
    'code': '42.99Z',
    'descFr': 'Construction d\'autres ouvrages de génie civil n.c.a.'
  },
  {
    'code': '43',
    'descFr': 'Travaux de construction spécialisés'
  },
  {
    'code': '43.1',
    'descFr': 'Démolition et préparation des sites'
  },
  {
    'code': '43.11',
    'descFr': 'Travaux de démolition'
  },
  {
    'code': '43.11Z',
    'descFr': 'Travaux de démolition'
  },
  {
    'code': '43.12',
    'descFr': 'Travaux de préparation des sites'
  },
  {
    'code': '43.12A',
    'descFr': 'Travaux de terrassement courants et travaux préparatoires'
  },
  {
    'code': '43.12B',
    'descFr': 'Travaux de terrassement spécialisés ou de grande masse'
  },
  {
    'code': '43.13',
    'descFr': 'Forages et sondages'
  },
  {
    'code': '43.13Z',
    'descFr': 'Forages et sondages'
  },
  {
    'code': '43.2',
    'descFr': 'Travaux d\'installation électrique, plomberie et autres travaux d\'installation'
  },
  {
    'code': '43.21',
    'descFr': 'Installation électrique'
  },
  {
    'code': '43.21A',
    'descFr': 'Travaux d\'installation électrique dans tous locaux'
  },
  {
    'code': '43.21B',
    'descFr': 'Travaux d\'installation électrique sur la voie publique'
  },
  {
    'code': '43.22',
    'descFr': 'Travaux de plomberie et installation de chauffage et de conditionnement d\'air'
  },
  {
    'code': '43.22A',
    'descFr': 'Travaux d\'installation d\'eau et de gaz en tous locaux'
  },
  {
    'code': '43.22B',
    'descFr': 'Travaux d\'installation d\'équipements thermiques et de climatisation'
  },
  {
    'code': '43.29',
    'descFr': 'Autres travaux d\'installation'
  },
  {
    'code': '43.29A',
    'descFr': 'Travaux d\'isolation'
  },
  {
    'code': '43.29B',
    'descFr': 'Autres travaux d\'installation n.c.a.'
  },
  {
    'code': '43.3',
    'descFr': 'Travaux de finition'
  },
  {
    'code': '43.31',
    'descFr': 'Travaux de plâtrerie'
  },
  {
    'code': '43.31Z',
    'descFr': 'Travaux de plâtrerie'
  },
  {
    'code': '43.32',
    'descFr': 'Travaux de menuiserie'
  },
  {
    'code': '43.32A',
    'descFr': 'Travaux de menuiserie bois et PVC'
  },
  {
    'code': '43.32B',
    'descFr': 'Travaux de menuiserie métallique et serrurerie'
  },
  {
    'code': '43.32C',
    'descFr': 'Agencement de lieux de vente'
  },
  {
    'code': '43.33',
    'descFr': 'Travaux de revêtement des sols et des murs'
  },
  {
    'code': '43.33Z',
    'descFr': 'Travaux de revêtement des sols et des murs'
  },
  {
    'code': '43.34',
    'descFr': 'Travaux de peinture et vitrerie'
  },
  {
    'code': '43.34Z',
    'descFr': 'Travaux de peinture et vitrerie'
  },
  {
    'code': '43.39',
    'descFr': 'Autres travaux de finition'
  },
  {
    'code': '43.39Z',
    'descFr': 'Autres travaux de finition'
  },
  {
    'code': '43.9',
    'descFr': 'Autres travaux de construction spécialisés'
  },
  {
    'code': '43.91',
    'descFr': 'Travaux de couverture'
  },
  {
    'code': '43.91A',
    'descFr': 'Travaux de charpente'
  },
  {
    'code': '43.91B',
    'descFr': 'Travaux de couverture par éléments'
  },
  {
    'code': '43.99',
    'descFr': 'Autres travaux de construction spécialisés n.c.a.'
  },
  {
    'code': '43.99A',
    'descFr': 'Travaux d\'étanchéification'
  },
  {
    'code': '43.99B',
    'descFr': 'Travaux de montage de structures métalliques'
  },
  {
    'code': '43.99C',
    'descFr': 'Travaux de maçonnerie générale et gros œuvre de bâtiment'
  },
  {
    'code': '43.99D',
    'descFr': 'Autres travaux spécialisés de construction'
  },
  {
    'code': '43.99E',
    'descFr': 'Location avec opérateur de matériel de construction'
  },
  {
    'code': '45',
    'descFr': 'Commerce et réparation d\'automobiles et de motocycles'
  },
  {
    'code': '45.1',
    'descFr': 'Commerce de véhicules automobiles'
  },
  {
    'code': '45.11',
    'descFr': 'Commerce de voitures et de véhicules automobiles légers'
  },
  {
    'code': '45.11Z',
    'descFr': 'Commerce de voitures et de véhicules automobiles légers'
  },
  {
    'code': '45.19',
    'descFr': 'Commerce d\'autres véhicules automobiles'
  },
  {
    'code': '45.19Z',
    'descFr': 'Commerce d\'autres véhicules automobiles'
  },
  {
    'code': '45.2',
    'descFr': 'Entretien et réparation de véhicules automobiles'
  },
  {
    'code': '45.20',
    'descFr': 'Entretien et réparation de véhicules automobiles'
  },
  {
    'code': '45.20A',
    'descFr': 'Entretien et réparation de véhicules automobiles légers'
  },
  {
    'code': '45.20B',
    'descFr': 'Entretien et réparation d\'autres véhicules automobiles'
  },
  {
    'code': '45.3',
    'descFr': 'Commerce d\'équipements automobiles'
  },
  {
    'code': '45.31',
    'descFr': 'Commerce de gros d\'équipements automobiles'
  },
  {
    'code': '45.31Z',
    'descFr': 'Commerce de gros d\'équipements automobiles'
  },
  {
    'code': '45.32',
    'descFr': 'Commerce de détail d\'équipements automobiles'
  },
  {
    'code': '45.32Z',
    'descFr': 'Commerce de détail d\'équipements automobiles'
  },
  {
    'code': '45.4',
    'descFr': 'Commerce et réparation de motocycles'
  },
  {
    'code': '45.40',
    'descFr': 'Commerce et réparation de motocycles'
  },
  {
    'code': '45.40Z',
    'descFr': 'Commerce et réparation de motocycles'
  },
  {
    'code': '46',
    'descFr': 'Commerce de gros, à l’exception des automobiles et des motocycles'
  },
  {
    'code': '46.1',
    'descFr': 'Intermédiaires du commerce de gros'
  },
  {
    'code': '46.11',
    'descFr': 'Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et produits semi-finis'
  },
  {
    'code': '46.11Z',
    'descFr': 'Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et produits semi-finis'
  },
  {
    'code': '46.12',
    'descFr': 'Intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques'
  },
  {
    'code': '46.12A',
    'descFr': 'Centrales d\'achat de carburant'
  },
  {
    'code': '46.12B',
    'descFr': 'Autres intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques'
  },
  {
    'code': '46.13',
    'descFr': 'Intermédiaires du commerce en bois et matériaux de construction'
  },
  {
    'code': '46.13Z',
    'descFr': 'Intermédiaires du commerce en bois et matériaux de construction'
  },
  {
    'code': '46.14',
    'descFr': 'Intermédiaires du commerce en machines, équipements industriels, navires et avions'
  },
  {
    'code': '46.14Z',
    'descFr': 'Intermédiaires du commerce en machines, équipements industriels, navires et avions'
  },
  {
    'code': '46.15',
    'descFr': 'Intermédiaires du commerce en meubles, articles de ménage et quincaillerie'
  },
  {
    'code': '46.15Z',
    'descFr': 'Intermédiaires du commerce en meubles, articles de ménage et quincaillerie'
  },
  {
    'code': '46.16',
    'descFr': 'Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir'
  },
  {
    'code': '46.16Z',
    'descFr': 'Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir'
  },
  {
    'code': '46.17',
    'descFr': 'Intermédiaires du commerce en denrées, boissons et tabac'
  },
  {
    'code': '46.17A',
    'descFr': 'Centrales d\'achat alimentaires'
  },
  {
    'code': '46.17B',
    'descFr': 'Autres intermédiaires du commerce en denrées, boissons et tabac'
  },
  {
    'code': '46.18',
    'descFr': 'Intermédiaires spécialisés dans le commerce d\'autres produits spécifiques'
  },
  {
    'code': '46.18Z',
    'descFr': 'Intermédiaires spécialisés dans le commerce d\'autres produits spécifiques'
  },
  {
    'code': '46.19',
    'descFr': 'Intermédiaires du commerce en produits divers'
  },
  {
    'code': '46.19A',
    'descFr': 'Centrales d\'achat non alimentaires'
  },
  {
    'code': '46.19B',
    'descFr': 'Autres intermédiaires du commerce en produits divers'
  },
  {
    'code': '46.2',
    'descFr': 'Commerce de gros de produits agricoles bruts et d\'animaux vivants'
  },
  {
    'code': '46.21',
    'descFr': 'Commerce de gros de céréales, de tabac non manufacturé, de semences et d\'aliments pour le bétail'
  },
  {
    'code': '46.21Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de céréales, de tabac non manufacturé, de semences et d\'aliments pour le bétail'
  },
  {
    'code': '46.22',
    'descFr': 'Commerce de gros de fleurs et plantes'
  },
  {
    'code': '46.22Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de fleurs et plantes'
  },
  {
    'code': '46.23',
    'descFr': 'Commerce de gros d\'animaux vivants'
  },
  {
    'code': '46.23Z',
    'descFr': 'Commerce de gros (commerce interentreprises) d\'animaux vivants'
  },
  {
    'code': '46.24',
    'descFr': 'Commerce de gros de cuirs et peaux'
  },
  {
    'code': '46.24Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de cuirs et peaux'
  },
  {
    'code': '46.3',
    'descFr': 'Commerce de gros de produits alimentaires, de boissons et de tabac'
  },
  {
    'code': '46.31',
    'descFr': 'Commerce de gros de fruits et légumes'
  },
  {
    'code': '46.31Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de fruits et légumes'
  },
  {
    'code': '46.32',
    'descFr': 'Commerce de gros de viandes et de produits à base de viande'
  },
  {
    'code': '46.32A',
    'descFr': 'Commerce de gros (commerce interentreprises) de viandes de boucherie'
  },
  {
    'code': '46.32B',
    'descFr': 'Commerce de gros (commerce interentreprises) de produits à base de viande'
  },
  {
    'code': '46.32C',
    'descFr': 'Commerce de gros (commerce interentreprises) de volailles et gibier'
  },
  {
    'code': '46.33',
    'descFr': 'Commerce de gros de produits laitiers, œufs, huiles et matières grasses comestibles'
  },
  {
    'code': '46.33Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de produits laitiers, œufs, huiles et matières grasses comestibles'
  },
  {
    'code': '46.34',
    'descFr': 'Commerce de gros de boissons'
  },
  {
    'code': '46.34Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de boissons'
  },
  {
    'code': '46.35',
    'descFr': 'Commerce de gros de produits à base de tabac'
  },
  {
    'code': '46.35Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de produits à base de tabac'
  },
  {
    'code': '46.36',
    'descFr': 'Commerce de gros de sucre, chocolat et confiserie'
  },
  {
    'code': '46.36Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de sucre, chocolat et confiserie'
  },
  {
    'code': '46.37',
    'descFr': 'Commerce de gros de café, thé, cacao et épices'
  },
  {
    'code': '46.37Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de café, thé, cacao et épices'
  },
  {
    'code': '46.38',
    'descFr': 'Commerce de gros d\'autres produits alimentaires, y compris poissons, crustacés et mollusques'
  },
  {
    'code': '46.38A',
    'descFr': 'Commerce de gros (commerce interentreprises) de poissons, crustacés et mollusques'
  },
  {
    'code': '46.38B',
    'descFr': 'Commerce de gros (commerce interentreprises) alimentaire spécialisé divers'
  },
  {
    'code': '46.39',
    'descFr': 'Commerce de gros non spécialisé de denrées, boissons et tabac'
  },
  {
    'code': '46.39A',
    'descFr': 'Commerce de gros (commerce interentreprises) de produits surgelés'
  },
  {
    'code': '46.39B',
    'descFr': 'Commerce de gros (commerce interentreprises) alimentaire non spécialisé'
  },
  {
    'code': '46.4',
    'descFr': 'Commerce de gros de biens domestiques'
  },
  {
    'code': '46.41',
    'descFr': 'Commerce de gros de textiles'
  },
  {
    'code': '46.41Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de textiles'
  },
  {
    'code': '46.42',
    'descFr': 'Commerce de gros d\'habillement et de chaussures'
  },
  {
    'code': '46.42Z',
    'descFr': 'Commerce de gros (commerce interentreprises) d\'habillement et de chaussures'
  },
  {
    'code': '46.43',
    'descFr': 'Commerce de gros d\'appareils électroménagers'
  },
  {
    'code': '46.43Z',
    'descFr': 'Commerce de gros (commerce interentreprises) d\'appareils électroménagers'
  },
  {
    'code': '46.44',
    'descFr': 'Commerce de gros de vaisselle, verrerie et produits d\'entretien'
  },
  {
    'code': '46.44Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de vaisselle, verrerie et produits d\'entretien'
  },
  {
    'code': '46.45',
    'descFr': 'Commerce de gros de parfumerie et de produits de beauté'
  },
  {
    'code': '46.45Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de parfumerie et de produits de beauté'
  },
  {
    'code': '46.46',
    'descFr': 'Commerce de gros de produits pharmaceutiques'
  },
  {
    'code': '46.46Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de produits pharmaceutiques'
  },
  {
    'code': '46.47',
    'descFr': 'Commerce de gros de meubles, de tapis et d\'appareils d\'éclairage'
  },
  {
    'code': '46.47Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de meubles, de tapis et d\'appareils d\'éclairage'
  },
  {
    'code': '46.48',
    'descFr': 'Commerce de gros d\'articles d\'horlogerie et de bijouterie'
  },
  {
    'code': '46.48Z',
    'descFr': 'Commerce de gros (commerce interentreprises) d\'articles d\'horlogerie et de bijouterie'
  },
  {
    'code': '46.49',
    'descFr': 'Commerce de gros d\'autres biens domestiques'
  },
  {
    'code': '46.49Z',
    'descFr': 'Commerce de gros (commerce interentreprises) d\'autres biens domestiques'
  },
  {
    'code': '46.5',
    'descFr': 'Commerce de gros d\'équipements de l\'information et de la communication'
  },
  {
    'code': '46.51',
    'descFr': 'Commerce de gros d\'ordinateurs, d\'équipements informatiques périphériques et de logiciels'
  },
  {
    'code': '46.51Z',
    'descFr': 'Commerce de gros (commerce interentreprises) d\'ordinateurs, d\'équipements informatiques périphériques et de logiciels'
  },
  {
    'code': '46.52',
    'descFr': 'Commerce de gros de composants et d\'équipements électroniques et de télécommunication'
  },
  {
    'code': '46.52Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de composants et d\'équipements électroniques et de télécommunication'
  },
  {
    'code': '46.6',
    'descFr': 'Commerce de gros d\'autres équipements industriels'
  },
  {
    'code': '46.61',
    'descFr': 'Commerce de gros de matériel agricole'
  },
  {
    'code': '46.61Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de matériel agricole'
  },
  {
    'code': '46.62',
    'descFr': 'Commerce de gros de machines-outils'
  },
  {
    'code': '46.62Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de machines-outils'
  },
  {
    'code': '46.63',
    'descFr': 'Commerce de gros de machines pour l\'extraction, la construction et le génie civil'
  },
  {
    'code': '46.63Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de machines pour l\'extraction, la construction et le génie civil'
  },
  {
    'code': '46.64',
    'descFr': 'Commerce de gros de machines pour l\'industrie textile et l\'habillement'
  },
  {
    'code': '46.64Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de machines pour l\'industrie textile et l\'habillement'
  },
  {
    'code': '46.65',
    'descFr': 'Commerce de gros de mobilier de bureau'
  },
  {
    'code': '46.65Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de mobilier de bureau'
  },
  {
    'code': '46.66',
    'descFr': 'Commerce de gros d\'autres machines et équipements de bureau'
  },
  {
    'code': '46.66Z',
    'descFr': 'Commerce de gros (commerce interentreprises) d\'autres machines et équipements de bureau'
  },
  {
    'code': '46.69',
    'descFr': 'Commerce de gros d\'autres machines et équipements'
  },
  {
    'code': '46.69A',
    'descFr': 'Commerce de gros (commerce interentreprises) de matériel électrique'
  },
  {
    'code': '46.69B',
    'descFr': 'Commerce de gros (commerce interentreprises) de fournitures et équipements industriels divers'
  },
  {
    'code': '46.69C',
    'descFr': 'Commerce de gros (commerce interentreprises) de fournitures et équipements divers pour le commerce et les services'
  },
  {
    'code': '46.7',
    'descFr': 'Autres commerces de gros spécialisés'
  },
  {
    'code': '46.71',
    'descFr': 'Commerce de gros de combustibles et de produits annexes'
  },
  {
    'code': '46.71Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de combustibles et de produits annexes'
  },
  {
    'code': '46.72',
    'descFr': 'Commerce de gros de minerais et métaux'
  },
  {
    'code': '46.72Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de minerais et métaux'
  },
  {
    'code': '46.73',
    'descFr': 'Commerce de gros de bois, de matériaux de construction et d\'appareils sanitaires'
  },
  {
    'code': '46.73A',
    'descFr': 'Commerce de gros (commerce interentreprises) de bois et de matériaux de construction'
  },
  {
    'code': '46.73B',
    'descFr': 'Commerce de gros (commerce interentreprises) d\'appareils sanitaires et de produits de décoration'
  },
  {
    'code': '46.74',
    'descFr': 'Commerce de gros de quincaillerie et fournitures pour plomberie et chauffage'
  },
  {
    'code': '46.74A',
    'descFr': 'Commerce de gros (commerce interentreprises) de quincaillerie'
  },
  {
    'code': '46.74B',
    'descFr': 'Commerce de gros (commerce interentreprises) de fournitures pour la plomberie et le chauffage'
  },
  {
    'code': '46.75',
    'descFr': 'Commerce de gros de produits chimiques'
  },
  {
    'code': '46.75Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de produits chimiques'
  },
  {
    'code': '46.76',
    'descFr': 'Commerce de gros d\'autres produits intermédiaires'
  },
  {
    'code': '46.76Z',
    'descFr': 'Commerce de gros (commerce interentreprises) d\'autres produits intermédiaires'
  },
  {
    'code': '46.77',
    'descFr': 'Commerce de gros de déchets et débris'
  },
  {
    'code': '46.77Z',
    'descFr': 'Commerce de gros (commerce interentreprises) de déchets et débris'
  },
  {
    'code': '46.9',
    'descFr': 'Commerce de gros non spécialisé'
  },
  {
    'code': '46.90',
    'descFr': 'Commerce de gros non spécialisé'
  },
  {
    'code': '46.90Z',
    'descFr': 'Commerce de gros (commerce interentreprises) non spécialisé'
  },
  {
    'code': '47',
    'descFr': 'Commerce de détail, à l’exception des automobiles et des motocycles'
  },
  {
    'code': '47.1',
    'descFr': 'Commerce de détail en magasin non spécialisé'
  },
  {
    'code': '47.11',
    'descFr': 'Commerce de détail en magasin non spécialisé à prédominance alimentaire'
  },
  {
    'code': '47.11A',
    'descFr': 'Commerce de détail de produits surgelés'
  },
  {
    'code': '47.11B',
    'descFr': 'Commerce d\'alimentation générale'
  },
  {
    'code': '47.11C',
    'descFr': 'Supérettes'
  },
  {
    'code': '47.11D',
    'descFr': 'Supermarchés'
  },
  {
    'code': '47.11E',
    'descFr': 'Magasins multi-commerces'
  },
  {
    'code': '47.11F',
    'descFr': 'Hypermarchés'
  },
  {
    'code': '47.19',
    'descFr': 'Autre commerce de détail en magasin non spécialisé'
  },
  {
    'code': '47.19A',
    'descFr': 'Grands magasins'
  },
  {
    'code': '47.19B',
    'descFr': 'Autres commerces de détail en magasin non spécialisé'
  },
  {
    'code': '47.2',
    'descFr': 'Commerce de détail alimentaire en magasin spécialisé'
  },
  {
    'code': '47.21',
    'descFr': 'Commerce de détail de fruits et légumes en magasin spécialisé'
  },
  {
    'code': '47.21Z',
    'descFr': 'Commerce de détail de fruits et légumes en magasin spécialisé'
  },
  {
    'code': '47.22',
    'descFr': 'Commerce de détail de viandes et de produits à base de viande en magasin spécialisé'
  },
  {
    'code': '47.22Z',
    'descFr': 'Commerce de détail de viandes et de produits à base de viande en magasin spécialisé'
  },
  {
    'code': '47.23',
    'descFr': 'Commerce de détail de poissons, crustacés et mollusques en magasin spécialisé'
  },
  {
    'code': '47.23Z',
    'descFr': 'Commerce de détail de poissons, crustacés et mollusques en magasin spécialisé'
  },
  {
    'code': '47.24',
    'descFr': 'Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé'
  },
  {
    'code': '47.24Z',
    'descFr': 'Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé'
  },
  {
    'code': '47.25',
    'descFr': 'Commerce de détail de boissons en magasin spécialisé'
  },
  {
    'code': '47.25Z',
    'descFr': 'Commerce de détail de boissons en magasin spécialisé'
  },
  {
    'code': '47.26',
    'descFr': 'Commerce de détail de produits à base de tabac en magasin spécialisé'
  },
  {
    'code': '47.26Z',
    'descFr': 'Commerce de détail de produits à base de tabac en magasin spécialisé'
  },
  {
    'code': '47.29',
    'descFr': 'Autres commerces de détail alimentaires en magasin spécialisé'
  },
  {
    'code': '47.29Z',
    'descFr': 'Autres commerces de détail alimentaires en magasin spécialisé'
  },
  {
    'code': '47.3',
    'descFr': 'Commerce de détail de carburants en magasin spécialisé'
  },
  {
    'code': '47.30',
    'descFr': 'Commerce de détail de carburants en magasin spécialisé'
  },
  {
    'code': '47.30Z',
    'descFr': 'Commerce de détail de carburants en magasin spécialisé'
  },
  {
    'code': '47.4',
    'descFr': 'Commerce de détail d\'équipements de l\'information et de la communication en magasin spécialisé'
  },
  {
    'code': '47.41',
    'descFr': 'Commerce de détail d\'ordinateurs, d\'unités périphériques et de logiciels en magasin spécialisé'
  },
  {
    'code': '47.41Z',
    'descFr': 'Commerce de détail d\'ordinateurs, d\'unités périphériques et de logiciels en magasin spécialisé'
  },
  {
    'code': '47.42',
    'descFr': 'Commerce de détail de matériels de télécommunication en magasin spécialisé'
  },
  {
    'code': '47.42Z',
    'descFr': 'Commerce de détail de matériels de télécommunication en magasin spécialisé'
  },
  {
    'code': '47.43',
    'descFr': 'Commerce de détail de matériels audio/vidéo en magasin spécialisé'
  },
  {
    'code': '47.43Z',
    'descFr': 'Commerce de détail de matériels audio et vidéo en magasin spécialisé'
  },
  {
    'code': '47.5',
    'descFr': 'Commerce de détail d\'autres équipements du foyer en magasin spécialisé'
  },
  {
    'code': '47.51',
    'descFr': 'Commerce de détail de textiles en magasin spécialisé'
  },
  {
    'code': '47.51Z',
    'descFr': 'Commerce de détail de textiles en magasin spécialisé'
  },
  {
    'code': '47.52',
    'descFr': 'Commerce de détail de quincaillerie, peintures et verres en magasin spécialisé'
  },
  {
    'code': '47.52A',
    'descFr': 'Commerce de détail de quincaillerie, peintures et verres en petites surfaces (moins de 400 m2)'
  },
  {
    'code': '47.52B',
    'descFr': 'Commerce de détail de quincaillerie, peintures et verres en grandes surfaces (400 m2et plus)'
  },
  {
    'code': '47.53',
    'descFr': 'Commerce de détail de tapis, moquettes et revêtements de murs et de sols en magasin spécialisé'
  },
  {
    'code': '47.53Z',
    'descFr': 'Commerce de détail de tapis, moquettes et revêtements de murs et de sols en magasin spécialisé'
  },
  {
    'code': '47.54',
    'descFr': 'Commerce de détail d\'appareils électroménagers en magasin spécialisé'
  },
  {
    'code': '47.54Z',
    'descFr': 'Commerce de détail d\'appareils électroménagers en magasin spécialisé'
  },
  {
    'code': '47.59',
    'descFr': 'Commerce de détail de meubles, appareils d\'éclairage et autres articles de ménage en magasin spécialisé'
  },
  {
    'code': '47.59A',
    'descFr': 'Commerce de détail de meubles'
  },
  {
    'code': '47.59B',
    'descFr': 'Commerce de détail d\'autres équipements du foyer'
  },
  {
    'code': '47.6',
    'descFr': 'Commerce de détail de biens culturels et de loisirs en magasin spécialisé'
  },
  {
    'code': '47.61',
    'descFr': 'Commerce de détail de livres en magasin spécialisé'
  },
  {
    'code': '47.61Z',
    'descFr': 'Commerce de détail de livres en magasin spécialisé'
  },
  {
    'code': '47.62',
    'descFr': 'Commerce de détail de journaux et papeterie en magasin spécialisé'
  },
  {
    'code': '47.62Z',
    'descFr': 'Commerce de détail de journaux et papeterie en magasin spécialisé'
  },
  {
    'code': '47.63',
    'descFr': 'Commerce de détail d\'enregistrements musicaux et vidéo en magasin spécialisé'
  },
  {
    'code': '47.63Z',
    'descFr': 'Commerce de détail d\'enregistrements musicaux et vidéo en magasin spécialisé'
  },
  {
    'code': '47.64',
    'descFr': 'Commerce de détail d\'articles de sport en magasin spécialisé'
  },
  {
    'code': '47.64Z',
    'descFr': 'Commerce de détail d\'articles de sport en magasin spécialisé'
  },
  {
    'code': '47.65',
    'descFr': 'Commerce de détail de jeux et jouets en magasin spécialisé'
  },
  {
    'code': '47.65Z',
    'descFr': 'Commerce de détail de jeux et jouets en magasin spécialisé'
  },
  {
    'code': '47.7',
    'descFr': 'Autres commerces de détail en magasin spécialisé'
  },
  {
    'code': '47.71',
    'descFr': 'Commerce de détail d\'habillement en magasin spécialisé'
  },
  {
    'code': '47.71Z',
    'descFr': 'Commerce de détail d\'habillement en magasin spécialisé'
  },
  {
    'code': '47.72',
    'descFr': 'Commerce de détail de chaussures et d\'articles en cuir en magasin spécialisé'
  },
  {
    'code': '47.72A',
    'descFr': 'Commerce de détail de la chaussure'
  },
  {
    'code': '47.72B',
    'descFr': 'Commerce de détail de maroquinerie et d\'articles de voyage'
  },
  {
    'code': '47.73',
    'descFr': 'Commerce de détail de produits pharmaceutiques en magasin spécialisé'
  },
  {
    'code': '47.73Z',
    'descFr': 'Commerce de détail de produits pharmaceutiques en magasin spécialisé'
  },
  {
    'code': '47.74',
    'descFr': 'Commerce de détail d\'articles médicaux et orthopédiques en magasin spécialisé'
  },
  {
    'code': '47.74Z',
    'descFr': 'Commerce de détail d\'articles médicaux et orthopédiques en magasin spécialisé'
  },
  {
    'code': '47.75',
    'descFr': 'Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé'
  },
  {
    'code': '47.75Z',
    'descFr': 'Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé'
  },
  {
    'code': '47.76',
    'descFr': 'Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé'
  },
  {
    'code': '47.76Z',
    'descFr': 'Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé'
  },
  {
    'code': '47.77',
    'descFr': 'Commerce de détail d\'articles d\'horlogerie et de bijouterie en magasin spécialisé'
  },
  {
    'code': '47.77Z',
    'descFr': 'Commerce de détail d\'articles d\'horlogerie et de bijouterie en magasin spécialisé'
  },
  {
    'code': '47.78',
    'descFr': 'Autre commerce de détail de biens neufs en magasin spécialisé'
  },
  {
    'code': '47.78A',
    'descFr': 'Commerces de détail d\'optique'
  },
  {
    'code': '47.78B',
    'descFr': 'Commerces de détail de charbons et combustibles'
  },
  {
    'code': '47.78C',
    'descFr': 'Autres commerces de détail spécialisés divers'
  },
  {
    'code': '47.79',
    'descFr': 'Commerce de détail de biens d\'occasion en magasin'
  },
  {
    'code': '47.79Z',
    'descFr': 'Commerce de détail de biens d\'occasion en magasin'
  },
  {
    'code': '47.8',
    'descFr': 'Commerce de détail sur éventaires et marchés'
  },
  {
    'code': '47.81',
    'descFr': 'Commerce de détail alimentaire sur éventaires et marchés'
  },
  {
    'code': '47.81Z',
    'descFr': 'Commerce de détail alimentaire sur éventaires et marchés'
  },
  {
    'code': '47.82',
    'descFr': 'Commerce de détail de textiles, d\'habillement et de chaussures sur éventaires et marchés'
  },
  {
    'code': '47.82Z',
    'descFr': 'Commerce de détail de textiles, d\'habillement et de chaussures sur éventaires et marchés'
  },
  {
    'code': '47.89',
    'descFr': 'Autres commerces de détail sur éventaires et marchés'
  },
  {
    'code': '47.89Z',
    'descFr': 'Autres commerces de détail sur éventaires et marchés'
  },
  {
    'code': '47.9',
    'descFr': 'Commerce de détail hors magasin, éventaires ou marchés'
  },
  {
    'code': '47.91',
    'descFr': 'Vente à distance'
  },
  {
    'code': '47.91A',
    'descFr': 'Vente à distance sur catalogue général'
  },
  {
    'code': '47.91B',
    'descFr': 'Vente à distance sur catalogue spécialisé'
  },
  {
    'code': '47.99',
    'descFr': 'Autres commerces de détail hors magasin, éventaires ou marchés'
  },
  {
    'code': '47.99A',
    'descFr': 'Vente à domicile'
  },
  {
    'code': '47.99B',
    'descFr': 'Vente par automates et autres commerces de détail hors magasin, éventaires ou marchés n.c.a.'
  },
  {
    'code': '49',
    'descFr': 'Transports terrestres et transport par conduites'
  },
  {
    'code': '49.1',
    'descFr': 'Transport ferroviaire interurbain de voyageurs'
  },
  {
    'code': '49.10',
    'descFr': 'Transport ferroviaire interurbain de voyageurs'
  },
  {
    'code': '49.10Z',
    'descFr': 'Transport ferroviaire interurbain de voyageurs'
  },
  {
    'code': '49.2',
    'descFr': 'Transports ferroviaires de fret'
  },
  {
    'code': '49.20',
    'descFr': 'Transports ferroviaires de fret'
  },
  {
    'code': '49.20Z',
    'descFr': 'Transports ferroviaires de fret'
  },
  {
    'code': '49.3',
    'descFr': 'Autres transports terrestres de voyageurs'
  },
  {
    'code': '49.31',
    'descFr': 'Transports urbains et suburbains de voyageurs'
  },
  {
    'code': '49.31Z',
    'descFr': 'Transports urbains et suburbains de voyageurs'
  },
  {
    'code': '49.32',
    'descFr': 'Transports de voyageurs par taxis'
  },
  {
    'code': '49.32Z',
    'descFr': 'Transports de voyageurs par taxis'
  },
  {
    'code': '49.39',
    'descFr': 'Autres transports terrestres de voyageurs n.c.a.'
  },
  {
    'code': '49.39A',
    'descFr': 'Transports routiers réguliers de voyageurs'
  },
  {
    'code': '49.39B',
    'descFr': 'Autres transports routiers de voyageurs'
  },
  {
    'code': '49.39C',
    'descFr': 'Téléphériques et remontées mécaniques'
  },
  {
    'code': '49.4',
    'descFr': 'Transports routiers de fret et services de déménagement'
  },
  {
    'code': '49.41',
    'descFr': 'Transports routiers de fret'
  },
  {
    'code': '49.41A',
    'descFr': 'Transports routiers de fret interurbains'
  },
  {
    'code': '49.41B',
    'descFr': 'Transports routiers de fret de proximité'
  },
  {
    'code': '49.41C',
    'descFr': 'Location de camions avec chauffeur'
  },
  {
    'code': '49.42',
    'descFr': 'Services de déménagement'
  },
  {
    'code': '49.42Z',
    'descFr': 'Services de déménagement'
  },
  {
    'code': '49.5',
    'descFr': 'Transports par conduites'
  },
  {
    'code': '49.50',
    'descFr': 'Transports par conduites'
  },
  {
    'code': '49.50Z',
    'descFr': 'Transports par conduites'
  },
  {
    'code': '50',
    'descFr': 'Transports par eau'
  },
  {
    'code': '50.1',
    'descFr': 'Transports maritimes et côtiers de passagers'
  },
  {
    'code': '50.10',
    'descFr': 'Transports maritimes et côtiers de passagers'
  },
  {
    'code': '50.10Z',
    'descFr': 'Transports maritimes et côtiers de passagers'
  },
  {
    'code': '50.2',
    'descFr': 'Transports maritimes et côtiers de fret'
  },
  {
    'code': '50.20',
    'descFr': 'Transports maritimes et côtiers de fret'
  },
  {
    'code': '50.20Z',
    'descFr': 'Transports maritimes et côtiers de fret'
  },
  {
    'code': '50.3',
    'descFr': 'Transports fluviaux de passagers'
  },
  {
    'code': '50.30',
    'descFr': 'Transports fluviaux de passagers'
  },
  {
    'code': '50.30Z',
    'descFr': 'Transports fluviaux de passagers'
  },
  {
    'code': '50.4',
    'descFr': 'Transports fluviaux de fret'
  },
  {
    'code': '50.40',
    'descFr': 'Transports fluviaux de fret'
  },
  {
    'code': '50.40Z',
    'descFr': 'Transports fluviaux de fret'
  },
  {
    'code': '51',
    'descFr': 'Transports aériens'
  },
  {
    'code': '51.1',
    'descFr': 'Transports aériens de passagers'
  },
  {
    'code': '51.10',
    'descFr': 'Transports aériens de passagers'
  },
  {
    'code': '51.10Z',
    'descFr': 'Transports aériens de passagers'
  },
  {
    'code': '51.2',
    'descFr': 'Transports aériens de fret et transports spatiaux'
  },
  {
    'code': '51.21',
    'descFr': 'Transports aériens de fret'
  },
  {
    'code': '51.21Z',
    'descFr': 'Transports aériens de fret'
  },
  {
    'code': '51.22',
    'descFr': 'Transports spatiaux'
  },
  {
    'code': '51.22Z',
    'descFr': 'Transports spatiaux'
  },
  {
    'code': '52',
    'descFr': 'Entreposage et services auxiliaires des transports'
  },
  {
    'code': '52.1',
    'descFr': 'Entreposage et stockage'
  },
  {
    'code': '52.10',
    'descFr': 'Entreposage et stockage'
  },
  {
    'code': '52.10A',
    'descFr': 'Entreposage et stockage frigorifique'
  },
  {
    'code': '52.10B',
    'descFr': 'Entreposage et stockage non frigorifique'
  },
  {
    'code': '52.2',
    'descFr': 'Services auxiliaires des transports'
  },
  {
    'code': '52.21',
    'descFr': 'Services auxiliaires des transports terrestres'
  },
  {
    'code': '52.21Z',
    'descFr': 'Services auxiliaires des transports terrestres'
  },
  {
    'code': '52.22',
    'descFr': 'Services auxiliaires des transports par eau'
  },
  {
    'code': '52.22Z',
    'descFr': 'Services auxiliaires des transports par eau'
  },
  {
    'code': '52.23',
    'descFr': 'Services auxiliaires des transports aériens'
  },
  {
    'code': '52.23Z',
    'descFr': 'Services auxiliaires des transports aériens'
  },
  {
    'code': '52.24',
    'descFr': 'Manutention'
  },
  {
    'code': '52.24A',
    'descFr': 'Manutention portuaire'
  },
  {
    'code': '52.24B',
    'descFr': 'Manutention non portuaire'
  },
  {
    'code': '52.29',
    'descFr': 'Autres services auxiliaires des transports'
  },
  {
    'code': '52.29A',
    'descFr': 'Messagerie, fret express'
  },
  {
    'code': '52.29B',
    'descFr': 'Affrètement et organisation des transports'
  },
  {
    'code': '53',
    'descFr': 'Activités de poste et de courrier'
  },
  {
    'code': '53.1',
    'descFr': 'Activités de poste dans le cadre d\'une obligation de service universel'
  },
  {
    'code': '53.10',
    'descFr': 'Activités de poste dans le cadre d\'une obligation de service universel'
  },
  {
    'code': '53.10Z',
    'descFr': 'Activités de poste dans le cadre d\'une obligation de service universel'
  },
  {
    'code': '53.2',
    'descFr': 'Autres activités de poste et de courrier'
  },
  {
    'code': '53.20',
    'descFr': 'Autres activités de poste et de courrier'
  },
  {
    'code': '53.20Z',
    'descFr': 'Autres activités de poste et de courrier'
  },
  {
    'code': '55',
    'descFr': 'Hébergement'
  },
  {
    'code': '55.1',
    'descFr': 'Hôtels et hébergement similaire'
  },
  {
    'code': '55.10',
    'descFr': 'Hôtels et hébergement similaire'
  },
  {
    'code': '55.10Z',
    'descFr': 'Hôtels et hébergement similaire'
  },
  {
    'code': '55.2',
    'descFr': 'Hébergement touristique et autre hébergement de courte durée'
  },
  {
    'code': '55.20',
    'descFr': 'Hébergement touristique et autre hébergement de courte durée'
  },
  {
    'code': '55.20Z',
    'descFr': 'Hébergement touristique et autre hébergement de courte durée'
  },
  {
    'code': '55.3',
    'descFr': 'Terrains de camping et parcs pour caravanes ou véhicules de loisirs'
  },
  {
    'code': '55.30',
    'descFr': 'Terrains de camping et parcs pour caravanes ou véhicules de loisirs'
  },
  {
    'code': '55.30Z',
    'descFr': 'Terrains de camping et parcs pour caravanes ou véhicules de loisirs'
  },
  {
    'code': '55.9',
    'descFr': 'Autres hébergements'
  },
  {
    'code': '55.90',
    'descFr': 'Autres hébergements'
  },
  {
    'code': '55.90Z',
    'descFr': 'Autres hébergements'
  },
  {
    'code': '56',
    'descFr': 'Restauration'
  },
  {
    'code': '56.1',
    'descFr': 'Restaurants et services de restauration mobile'
  },
  {
    'code': '56.10',
    'descFr': 'Restaurants et services de restauration mobile'
  },
  {
    'code': '56.10A',
    'descFr': 'Restauration traditionnelle'
  },
  {
    'code': '56.10B',
    'descFr': 'Cafétérias et autres libres-services'
  },
  {
    'code': '56.10C',
    'descFr': 'Restauration de type rapide'
  },
  {
    'code': '56.2',
    'descFr': 'Traiteurs et autres services de restauration'
  },
  {
    'code': '56.21',
    'descFr': 'Services des traiteurs'
  },
  {
    'code': '56.21Z',
    'descFr': 'Services des traiteurs'
  },
  {
    'code': '56.29',
    'descFr': 'Autres services de restauration'
  },
  {
    'code': '56.29A',
    'descFr': 'Restauration collective sous contrat'
  },
  {
    'code': '56.29B',
    'descFr': 'Autres services de restauration n.c.a.'
  },
  {
    'code': '56.3',
    'descFr': 'Débits de boissons'
  },
  {
    'code': '56.30',
    'descFr': 'Débits de boissons'
  },
  {
    'code': '56.30Z',
    'descFr': 'Débits de boissons'
  },
  {
    'code': '58',
    'descFr': 'Édition'
  },
  {
    'code': '58.1',
    'descFr': 'Édition de livres et périodiques et autres activités d\'édition'
  },
  {
    'code': '58.11',
    'descFr': 'Édition de livres'
  },
  {
    'code': '58.11Z',
    'descFr': 'Édition de livres'
  },
  {
    'code': '58.12',
    'descFr': 'Édition de répertoires et de fichiers d\'adresses'
  },
  {
    'code': '58.12Z',
    'descFr': 'Édition de répertoires et de fichiers d\'adresses'
  },
  {
    'code': '58.13',
    'descFr': 'Édition de journaux'
  },
  {
    'code': '58.13Z',
    'descFr': 'Édition de journaux'
  },
  {
    'code': '58.14',
    'descFr': 'Édition de revues et périodiques'
  },
  {
    'code': '58.14Z',
    'descFr': 'Édition de revues et périodiques'
  },
  {
    'code': '58.19',
    'descFr': 'Autres activités d\'édition'
  },
  {
    'code': '58.19Z',
    'descFr': 'Autres activités d\'édition'
  },
  {
    'code': '58.2',
    'descFr': 'Édition de logiciels'
  },
  {
    'code': '58.21',
    'descFr': 'Édition de jeux électroniques'
  },
  {
    'code': '58.21Z',
    'descFr': 'Édition de jeux électroniques'
  },
  {
    'code': '58.29',
    'descFr': 'Édition d\'autres logiciels'
  },
  {
    'code': '58.29A',
    'descFr': 'Édition de logiciels système et de réseau'
  },
  {
    'code': '58.29B',
    'descFr': 'Edition de logiciels outils de développement et de langages'
  },
  {
    'code': '58.29C',
    'descFr': 'Edition de logiciels applicatifs'
  },
  {
    'code': '59',
    'descFr': 'Production de films cinématographiques, de vidéo et de programmes de télévision ; enregistrement sonore et édition musicale'
  },
  {
    'code': '59.1',
    'descFr': 'Activités cinématographiques, vidéo et de télévision'
  },
  {
    'code': '59.11',
    'descFr': 'Production de films cinématographiques, de vidéo et de programmes de télévision'
  },
  {
    'code': '59.11A',
    'descFr': 'Production de films et de programmes pour la télévision'
  },
  {
    'code': '59.11B',
    'descFr': 'Production de films institutionnels et publicitaires'
  },
  {
    'code': '59.11C',
    'descFr': 'Production de films pour le cinéma'
  },
  {
    'code': '59.12',
    'descFr': 'Post-production de films cinématographiques, de vidéo et de programmes de télévision'
  },
  {
    'code': '59.12Z',
    'descFr': 'Post-production de films cinématographiques, de vidéo et de programmes de télévision'
  },
  {
    'code': '59.13',
    'descFr': 'Distribution de films cinématographiques, de vidéo et de programmes de télévision'
  },
  {
    'code': '59.13A',
    'descFr': 'Distribution de films cinématographiques'
  },
  {
    'code': '59.13B',
    'descFr': 'Edition et distribution vidéo'
  },
  {
    'code': '59.14',
    'descFr': 'Projection de films cinématographiques'
  },
  {
    'code': '59.14Z',
    'descFr': 'Projection de films cinématographiques'
  },
  {
    'code': '59.2',
    'descFr': 'Enregistrement sonore et édition musicale'
  },
  {
    'code': '59.20',
    'descFr': 'Enregistrement sonore et édition musicale'
  },
  {
    'code': '59.20Z',
    'descFr': 'Enregistrement sonore et édition musicale'
  },
  {
    'code': '60',
    'descFr': 'Programmation et diffusion'
  },
  {
    'code': '60.1',
    'descFr': 'Édition et diffusion de programmes radio'
  },
  {
    'code': '60.10',
    'descFr': 'Édition et diffusion de programmes radio'
  },
  {
    'code': '60.10Z',
    'descFr': 'Édition et diffusion de programmes radio'
  },
  {
    'code': '60.2',
    'descFr': 'Programmation de télévision et télédiffusion'
  },
  {
    'code': '60.20',
    'descFr': 'Programmation de télévision et télédiffusion'
  },
  {
    'code': '60.20A',
    'descFr': 'Edition de chaînes généralistes'
  },
  {
    'code': '60.20B',
    'descFr': 'Edition de chaînes thématiques'
  },
  {
    'code': '61',
    'descFr': 'Télécommunications'
  },
  {
    'code': '61.1',
    'descFr': 'Télécommunications filaires'
  },
  {
    'code': '61.10',
    'descFr': 'Télécommunications filaires'
  },
  {
    'code': '61.10Z',
    'descFr': 'Télécommunications filaires'
  },
  {
    'code': '61.2',
    'descFr': 'Télécommunications sans fil'
  },
  {
    'code': '61.20',
    'descFr': 'Télécommunications sans fil'
  },
  {
    'code': '61.20Z',
    'descFr': 'Télécommunications sans fil'
  },
  {
    'code': '61.3',
    'descFr': 'Télécommunications par satellite'
  },
  {
    'code': '61.30',
    'descFr': 'Télécommunications par satellite'
  },
  {
    'code': '61.30Z',
    'descFr': 'Télécommunications par satellite'
  },
  {
    'code': '61.9',
    'descFr': 'Autres activités de télécommunication'
  },
  {
    'code': '61.90',
    'descFr': 'Autres activités de télécommunication'
  },
  {
    'code': '61.90Z',
    'descFr': 'Autres activités de télécommunication'
  },
  {
    'code': '62',
    'descFr': 'Programmation, conseil et autres activités informatiques'
  },
  {
    'code': '62.0',
    'descFr': 'Programmation, conseil et autres activités informatiques'
  },
  {
    'code': '62.01',
    'descFr': 'Programmation informatique'
  },
  {
    'code': '62.01Z',
    'descFr': 'Programmation informatique'
  },
  {
    'code': '62.02',
    'descFr': 'Conseil informatique'
  },
  {
    'code': '62.02A',
    'descFr': 'Conseil en systèmes et logiciels informatiques'
  },
  {
    'code': '62.02B',
    'descFr': 'Tierce maintenance de systèmes et d’applications informatiques'
  },
  {
    'code': '62.03',
    'descFr': 'Gestion d\'installations informatiques'
  },
  {
    'code': '62.03Z',
    'descFr': 'Gestion d\'installations informatiques'
  },
  {
    'code': '62.09',
    'descFr': 'Autres activités informatiques'
  },
  {
    'code': '62.09Z',
    'descFr': 'Autres activités informatiques'
  },
  {
    'code': '63',
    'descFr': 'Services d\'information'
  },
  {
    'code': '63.1',
    'descFr': 'Traitement de données, hébergement et activités connexes ; portails Internet'
  },
  {
    'code': '63.11',
    'descFr': 'Traitement de données, hébergement et activités connexes'
  },
  {
    'code': '63.11Z',
    'descFr': 'Traitement de données, hébergement et activités connexes'
  },
  {
    'code': '63.12',
    'descFr': 'Portails Internet'
  },
  {
    'code': '63.12Z',
    'descFr': 'Portails Internet'
  },
  {
    'code': '63.9',
    'descFr': 'Autres services d\'information'
  },
  {
    'code': '63.91',
    'descFr': 'Activités des agences de presse'
  },
  {
    'code': '63.91Z',
    'descFr': 'Activités des agences de presse'
  },
  {
    'code': '63.99',
    'descFr': 'Autres services d\'information n.c.a.'
  },
  {
    'code': '63.99Z',
    'descFr': 'Autres services d\'information n.c.a.'
  },
  {
    'code': '64',
    'descFr': 'Activités des services financiers, hors assurance et caisses de retraite'
  },
  {
    'code': '64.1',
    'descFr': 'Intermédiation monétaire'
  },
  {
    'code': '64.11',
    'descFr': 'Activités de banque centrale'
  },
  {
    'code': '64.11Z',
    'descFr': 'Activités de banque centrale'
  },
  {
    'code': '64.19',
    'descFr': 'Autres intermédiations monétaires'
  },
  {
    'code': '64.19Z',
    'descFr': 'Autres intermédiations monétaires'
  },
  {
    'code': '64.2',
    'descFr': 'Activités des sociétés holding'
  },
  {
    'code': '64.20',
    'descFr': 'Activités des sociétés holding'
  },
  {
    'code': '64.20Z',
    'descFr': 'Activités des sociétés holding'
  },
  {
    'code': '64.3',
    'descFr': 'Fonds de placement et entités financières similaires'
  },
  {
    'code': '64.30',
    'descFr': 'Fonds de placement et entités financières similaires'
  },
  {
    'code': '64.30Z',
    'descFr': 'Fonds de placement et entités financières similaires'
  },
  {
    'code': '64.9',
    'descFr': 'Autres activités des services financiers, hors assurance et caisses de retraite'
  },
  {
    'code': '64.91',
    'descFr': 'Crédit-bail'
  },
  {
    'code': '64.91Z',
    'descFr': 'Crédit-bail'
  },
  {
    'code': '64.92',
    'descFr': 'Autre distribution de crédit'
  },
  {
    'code': '64.92Z',
    'descFr': 'Autre distribution de crédit'
  },
  {
    'code': '64.99',
    'descFr': 'Autres activités des services financiers, hors assurance et caisses de retraite, n.c.a.'
  },
  {
    'code': '64.99Z',
    'descFr': 'Autres activités des services financiers, hors assurance et caisses de retraite, n.c.a.'
  },
  {
    'code': '65',
    'descFr': 'Assurance'
  },
  {
    'code': '65.1',
    'descFr': 'Assurance'
  },
  {
    'code': '65.11',
    'descFr': 'Assurance vie'
  },
  {
    'code': '65.11Z',
    'descFr': 'Assurance vie'
  },
  {
    'code': '65.12',
    'descFr': 'Autres assurances'
  },
  {
    'code': '65.12Z',
    'descFr': 'Autres assurances'
  },
  {
    'code': '65.2',
    'descFr': 'Réassurance'
  },
  {
    'code': '65.20',
    'descFr': 'Réassurance'
  },
  {
    'code': '65.20Z',
    'descFr': 'Réassurance'
  },
  {
    'code': '65.3',
    'descFr': 'Caisses de retraite'
  },
  {
    'code': '65.30',
    'descFr': 'Caisses de retraite'
  },
  {
    'code': '65.30Z',
    'descFr': 'Caisses de retraite'
  },
  {
    'code': '66',
    'descFr': 'Activités auxiliaires de services financiers et d\'assurance'
  },
  {
    'code': '66.1',
    'descFr': 'Activités auxiliaires de services financiers, hors assurance et caisses de retraite'
  },
  {
    'code': '66.11',
    'descFr': 'Administration de marchés financiers'
  },
  {
    'code': '66.11Z',
    'descFr': 'Administration de marchés financiers'
  },
  {
    'code': '66.12',
    'descFr': 'Courtage de valeurs mobilières et de marchandises'
  },
  {
    'code': '66.12Z',
    'descFr': 'Courtage de valeurs mobilières et de marchandises'
  },
  {
    'code': '66.19',
    'descFr': 'Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite'
  },
  {
    'code': '66.19A',
    'descFr': 'Supports juridiques de gestion de patrimoine mobilier'
  },
  {
    'code': '66.19B',
    'descFr': 'Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite, n.c.a.'
  },
  {
    'code': '66.2',
    'descFr': 'Activités auxiliaires d\'assurance et de caisses de retraite'
  },
  {
    'code': '66.21',
    'descFr': 'Évaluation des risques et dommages'
  },
  {
    'code': '66.21Z',
    'descFr': 'Évaluation des risques et dommages'
  },
  {
    'code': '66.22',
    'descFr': 'Activités des agents et courtiers d\'assurances'
  },
  {
    'code': '66.22Z',
    'descFr': 'Activités des agents et courtiers d\'assurances'
  },
  {
    'code': '66.29',
    'descFr': 'Autres activités auxiliaires d\'assurance et de caisses de retraite'
  },
  {
    'code': '66.29Z',
    'descFr': 'Autres activités auxiliaires d\'assurance et de caisses de retraite'
  },
  {
    'code': '66.3',
    'descFr': 'Gestion de fonds'
  },
  {
    'code': '66.30',
    'descFr': 'Gestion de fonds'
  },
  {
    'code': '66.30Z',
    'descFr': 'Gestion de fonds'
  },
  {
    'code': '68',
    'descFr': 'Activités immobilières'
  },
  {
    'code': '68.1',
    'descFr': 'Activités des marchands de biens immobiliers'
  },
  {
    'code': '68.10',
    'descFr': 'Activités des marchands de biens immobiliers'
  },
  {
    'code': '68.10Z',
    'descFr': 'Activités des marchands de biens immobiliers'
  },
  {
    'code': '68.2',
    'descFr': 'Location et exploitation de biens immobiliers propres ou loués'
  },
  {
    'code': '68.20',
    'descFr': 'Location et exploitation de biens immobiliers propres ou loués'
  },
  {
    'code': '68.20A',
    'descFr': 'Location de logements'
  },
  {
    'code': '68.20B',
    'descFr': 'Location de terrains et d\'autres biens immobiliers'
  },
  {
    'code': '68.3',
    'descFr': 'Activités immobilières pour compte de tiers'
  },
  {
    'code': '68.31',
    'descFr': 'Agences immobilières'
  },
  {
    'code': '68.31Z',
    'descFr': 'Agences immobilières'
  },
  {
    'code': '68.32',
    'descFr': 'Administration de biens immobiliers'
  },
  {
    'code': '68.32A',
    'descFr': 'Administration d\'immeubles et autres biens immobiliers'
  },
  {
    'code': '68.32B',
    'descFr': 'Supports juridiques de gestion de patrimoine immobilier'
  },
  {
    'code': '69',
    'descFr': 'Activités juridiques et comptables'
  },
  {
    'code': '69.1',
    'descFr': 'Activités juridiques'
  },
  {
    'code': '69.10',
    'descFr': 'Activités juridiques'
  },
  {
    'code': '69.10Z',
    'descFr': 'Activités juridiques'
  },
  {
    'code': '69.2',
    'descFr': 'Activités comptables'
  },
  {
    'code': '69.20',
    'descFr': 'Activités comptables'
  },
  {
    'code': '69.20Z',
    'descFr': 'Activités comptables'
  },
  {
    'code': '70',
    'descFr': 'Activités des sièges sociaux ; conseil de gestion'
  },
  {
    'code': '70.1',
    'descFr': 'Activités des sièges sociaux'
  },
  {
    'code': '70.10',
    'descFr': 'Activités des sièges sociaux'
  },
  {
    'code': '70.10Z',
    'descFr': 'Activités des sièges sociaux'
  },
  {
    'code': '70.2',
    'descFr': 'Conseil de gestion'
  },
  {
    'code': '70.21',
    'descFr': 'Conseil en relations publiques et communication'
  },
  {
    'code': '70.21Z',
    'descFr': 'Conseil en relations publiques et communication'
  },
  {
    'code': '70.22',
    'descFr': 'Conseil pour les affaires et autres conseils de gestion'
  },
  {
    'code': '70.22Z',
    'descFr': 'Conseil pour les affaires et autres conseils de gestion'
  },
  {
    'code': '71',
    'descFr': 'Activités d\'architecture et d\'ingénierie ; activités de contrôle et analyses techniques'
  },
  {
    'code': '71.1',
    'descFr': 'Activités d\'architecture et d\'ingénierie'
  },
  {
    'code': '71.11',
    'descFr': 'Activités d\'architecture'
  },
  {
    'code': '71.11Z',
    'descFr': 'Activités d\'architecture'
  },
  {
    'code': '71.12',
    'descFr': 'Activités d\'ingénierie'
  },
  {
    'code': '71.12A',
    'descFr': 'Activité des géomètres'
  },
  {
    'code': '71.12B',
    'descFr': 'Ingénierie, études techniques'
  },
  {
    'code': '71.2',
    'descFr': 'Activités de contrôle et analyses techniques'
  },
  {
    'code': '71.20',
    'descFr': 'Activités de contrôle et analyses techniques'
  },
  {
    'code': '71.20A',
    'descFr': 'Contrôle technique automobile'
  },
  {
    'code': '71.20B',
    'descFr': 'Analyses, essais et inspections techniques'
  },
  {
    'code': '72',
    'descFr': 'Recherche-développement scientifique'
  },
  {
    'code': '72.1',
    'descFr': 'Recherche-développement en sciences physiques et naturelles'
  },
  {
    'code': '72.11',
    'descFr': 'Recherche-développement en biotechnologie'
  },
  {
    'code': '72.11Z',
    'descFr': 'Recherche-développement en biotechnologie'
  },
  {
    'code': '72.19',
    'descFr': 'Recherche-développement en autres sciences physiques et naturelles'
  },
  {
    'code': '72.19Z',
    'descFr': 'Recherche-développement en autres sciences physiques et naturelles'
  },
  {
    'code': '72.2',
    'descFr': 'Recherche-développement en sciences humaines et sociales'
  },
  {
    'code': '72.20',
    'descFr': 'Recherche-développement en sciences humaines et sociales'
  },
  {
    'code': '72.20Z',
    'descFr': 'Recherche-développement en sciences humaines et sociales'
  },
  {
    'code': '73',
    'descFr': 'Publicité et études de marché'
  },
  {
    'code': '73.1',
    'descFr': 'Publicité'
  },
  {
    'code': '73.11',
    'descFr': 'Activités des agences de publicité'
  },
  {
    'code': '73.11Z',
    'descFr': 'Activités des agences de publicité'
  },
  {
    'code': '73.12',
    'descFr': 'Régie publicitaire de médias'
  },
  {
    'code': '73.12Z',
    'descFr': 'Régie publicitaire de médias'
  },
  {
    'code': '73.2',
    'descFr': 'Études de marché et sondages'
  },
  {
    'code': '73.20',
    'descFr': 'Études de marché et sondages'
  },
  {
    'code': '73.20Z',
    'descFr': 'Études de marché et sondages'
  },
  {
    'code': '74',
    'descFr': 'Autres activités spécialisées, scientifiques et techniques'
  },
  {
    'code': '74.1',
    'descFr': 'Activités spécialisées de design'
  },
  {
    'code': '74.10',
    'descFr': 'Activités spécialisées de design'
  },
  {
    'code': '74.10Z',
    'descFr': 'Activités spécialisées de design'
  },
  {
    'code': '74.2',
    'descFr': 'Activités photographiques'
  },
  {
    'code': '74.20',
    'descFr': 'Activités photographiques'
  },
  {
    'code': '74.20Z',
    'descFr': 'Activités photographiques'
  },
  {
    'code': '74.3',
    'descFr': 'Traduction et interprétation'
  },
  {
    'code': '74.30',
    'descFr': 'Traduction et interprétation'
  },
  {
    'code': '74.30Z',
    'descFr': 'Traduction et interprétation'
  },
  {
    'code': '74.9',
    'descFr': 'Autres activités spécialisées, scientifiques et techniques n.c.a.'
  },
  {
    'code': '74.90',
    'descFr': 'Autres activités spécialisées, scientifiques et techniques n.c.a.'
  },
  {
    'code': '74.90A',
    'descFr': 'Activité des économistes de la construction'
  },
  {
    'code': '74.90B',
    'descFr': 'Activités spécialisées, scientifiques et techniques diverses'
  },
  {
    'code': '75',
    'descFr': 'Activités vétérinaires'
  },
  {
    'code': '75.0',
    'descFr': 'Activités vétérinaires'
  },
  {
    'code': '75.00',
    'descFr': 'Activités vétérinaires'
  },
  {
    'code': '75.00Z',
    'descFr': 'Activités vétérinaires'
  },
  {
    'code': '77',
    'descFr': 'Activités de location et location-bail'
  },
  {
    'code': '77.1',
    'descFr': 'Location et location-bail de véhicules automobiles'
  },
  {
    'code': '77.11',
    'descFr': 'Location et location-bail de voitures et de véhicules automobiles légers'
  },
  {
    'code': '77.11A',
    'descFr': 'Location de courte durée de voitures et de véhicules automobiles légers'
  },
  {
    'code': '77.11B',
    'descFr': 'Location de longue durée de voitures et de véhicules automobiles légers'
  },
  {
    'code': '77.12',
    'descFr': 'Location et location-bail de camions'
  },
  {
    'code': '77.12Z',
    'descFr': 'Location et location-bail de camions'
  },
  {
    'code': '77.2',
    'descFr': 'Location et location-bail de biens personnels et domestiques'
  },
  {
    'code': '77.21',
    'descFr': 'Location et location-bail d\'articles de loisirs et de sport'
  },
  {
    'code': '77.21Z',
    'descFr': 'Location et location-bail d\'articles de loisirs et de sport'
  },
  {
    'code': '77.22',
    'descFr': 'Location de vidéocassettes et disques vidéo'
  },
  {
    'code': '77.22Z',
    'descFr': 'Location de vidéocassettes et disques vidéo'
  },
  {
    'code': '77.29',
    'descFr': 'Location et location-bail d\'autres biens personnels et domestiques'
  },
  {
    'code': '77.29Z',
    'descFr': 'Location et location-bail d\'autres biens personnels et domestiques'
  },
  {
    'code': '77.3',
    'descFr': 'Location et location-bail d\'autres machines, équipements et biens'
  },
  {
    'code': '77.31',
    'descFr': 'Location et location-bail de machines et équipements agricoles'
  },
  {
    'code': '77.31Z',
    'descFr': 'Location et location-bail de machines et équipements agricoles'
  },
  {
    'code': '77.32',
    'descFr': 'Location et location-bail de machines et équipements pour la construction'
  },
  {
    'code': '77.32Z',
    'descFr': 'Location et location-bail de machines et équipements pour la construction'
  },
  {
    'code': '77.33',
    'descFr': 'Location et location-bail de machines de bureau et de matériel informatique'
  },
  {
    'code': '77.33Z',
    'descFr': 'Location et location-bail de machines de bureau et de matériel informatique'
  },
  {
    'code': '77.34',
    'descFr': 'Location et location-bail de matériels de transport par eau'
  },
  {
    'code': '77.34Z',
    'descFr': 'Location et location-bail de matériels de transport par eau'
  },
  {
    'code': '77.35',
    'descFr': 'Location et location-bail de matériels de transport aérien'
  },
  {
    'code': '77.35Z',
    'descFr': 'Location et location-bail de matériels de transport aérien'
  },
  {
    'code': '77.39',
    'descFr': 'Location et location-bail d\'autres machines, équipements et biens matériels n.c.a.'
  },
  {
    'code': '77.39Z',
    'descFr': 'Location et location-bail d\'autres machines, équipements et biens matériels n.c.a.'
  },
  {
    'code': '77.4',
    'descFr': 'Location-bail de propriété intellectuelle et de produits similaires, à l\'exception des œuvres soumises à copyright'
  },
  {
    'code': '77.40',
    'descFr': 'Location-bail de propriété intellectuelle et de produits similaires, à l\'exception des œuvres soumises à copyright'
  },
  {
    'code': '77.40Z',
    'descFr': 'Location-bail de propriété intellectuelle et de produits similaires, à l\'exception des œuvres soumises à copyright'
  },
  {
    'code': '78',
    'descFr': 'Activités liées à l\'emploi'
  },
  {
    'code': '78.1',
    'descFr': 'Activités des agences de placement de main-d\'œuvre'
  },
  {
    'code': '78.10',
    'descFr': 'Activités des agences de placement de main-d\'œuvre'
  },
  {
    'code': '78.10Z',
    'descFr': 'Activités des agences de placement de main-d\'œuvre'
  },
  {
    'code': '78.2',
    'descFr': 'Activités des agences de travail temporaire'
  },
  {
    'code': '78.20',
    'descFr': 'Activités des agences de travail temporaire'
  },
  {
    'code': '78.20Z',
    'descFr': 'Activités des agences de travail temporaire'
  },
  {
    'code': '78.3',
    'descFr': 'Autre mise à disposition de ressources humaines'
  },
  {
    'code': '78.30',
    'descFr': 'Autre mise à disposition de ressources humaines'
  },
  {
    'code': '78.30Z',
    'descFr': 'Autre mise à disposition de ressources humaines'
  },
  {
    'code': '79',
    'descFr': 'Activités des agences de voyage, voyagistes, services de réservation et activités connexes'
  },
  {
    'code': '79.1',
    'descFr': 'Activités des agences de voyage et voyagistes'
  },
  {
    'code': '79.11',
    'descFr': 'Activités des agences de voyage'
  },
  {
    'code': '79.11Z',
    'descFr': 'Activités des agences de voyage'
  },
  {
    'code': '79.12',
    'descFr': 'Activités des voyagistes'
  },
  {
    'code': '79.12Z',
    'descFr': 'Activités des voyagistes'
  },
  {
    'code': '79.9',
    'descFr': 'Autres services de réservation et activités connexes'
  },
  {
    'code': '79.90',
    'descFr': 'Autres services de réservation et activités connexes'
  },
  {
    'code': '79.90Z',
    'descFr': 'Autres services de réservation et activités connexes'
  },
  {
    'code': '80',
    'descFr': 'Enquêtes et sécurité'
  },
  {
    'code': '80.1',
    'descFr': 'Activités de sécurité privée'
  },
  {
    'code': '80.10',
    'descFr': 'Activités de sécurité privée'
  },
  {
    'code': '80.10Z',
    'descFr': 'Activités de sécurité privée'
  },
  {
    'code': '80.2',
    'descFr': 'Activités liées aux systèmes de sécurité'
  },
  {
    'code': '80.20',
    'descFr': 'Activités liées aux systèmes de sécurité'
  },
  {
    'code': '80.20Z',
    'descFr': 'Activités liées aux systèmes de sécurité'
  },
  {
    'code': '80.3',
    'descFr': 'Activités d\'enquête'
  },
  {
    'code': '80.30',
    'descFr': 'Activités d\'enquête'
  },
  {
    'code': '80.30Z',
    'descFr': 'Activités d\'enquête'
  },
  {
    'code': '81',
    'descFr': 'Services relatifs aux bâtiments et aménagement paysager'
  },
  {
    'code': '81.1',
    'descFr': 'Activités combinées de soutien lié aux bâtiments'
  },
  {
    'code': '81.10',
    'descFr': 'Activités combinées de soutien lié aux bâtiments'
  },
  {
    'code': '81.10Z',
    'descFr': 'Activités combinées de soutien lié aux bâtiments'
  },
  {
    'code': '81.2',
    'descFr': 'Activités de nettoyage'
  },
  {
    'code': '81.21',
    'descFr': 'Nettoyage courant des bâtiments'
  },
  {
    'code': '81.21Z',
    'descFr': 'Nettoyage courant des bâtiments'
  },
  {
    'code': '81.22',
    'descFr': 'Autres activités de nettoyage des bâtiments et nettoyage industriel'
  },
  {
    'code': '81.22Z',
    'descFr': 'Autres activités de nettoyage des bâtiments et nettoyage industriel'
  },
  {
    'code': '81.29',
    'descFr': 'Autres activités de nettoyage'
  },
  {
    'code': '81.29A',
    'descFr': 'Désinfection, désinsectisation, dératisation'
  },
  {
    'code': '81.29B',
    'descFr': 'Autres activités de nettoyage n.c.a.'
  },
  {
    'code': '81.3',
    'descFr': 'Services d\'aménagement paysager'
  },
  {
    'code': '81.30',
    'descFr': 'Services d\'aménagement paysager'
  },
  {
    'code': '81.30Z',
    'descFr': 'Services d\'aménagement paysager'
  },
  {
    'code': '82',
    'descFr': 'Activités administratives et autres activités de soutien aux entreprises'
  },
  {
    'code': '82.1',
    'descFr': 'Activités administratives'
  },
  {
    'code': '82.11',
    'descFr': 'Services administratifs combinés de bureau'
  },
  {
    'code': '82.11Z',
    'descFr': 'Services administratifs combinés de bureau'
  },
  {
    'code': '82.19',
    'descFr': 'Photocopie, préparation de documents et autres activités spécialisées de soutien de bureau'
  },
  {
    'code': '82.19Z',
    'descFr': 'Photocopie, préparation de documents et autres activités spécialisées de soutien de bureau'
  },
  {
    'code': '82.2',
    'descFr': 'Activités de centres d\'appels'
  },
  {
    'code': '82.20',
    'descFr': 'Activités de centres d\'appels'
  },
  {
    'code': '82.20Z',
    'descFr': 'Activités de centres d\'appels'
  },
  {
    'code': '82.3',
    'descFr': 'Organisation de salons professionnels et congrès'
  },
  {
    'code': '82.30',
    'descFr': 'Organisation de salons professionnels et congrès'
  },
  {
    'code': '82.30Z',
    'descFr': 'Organisation de foires, salons professionnels et congrès'
  },
  {
    'code': '82.9',
    'descFr': 'Activités de soutien aux entreprises n.c.a.'
  },
  {
    'code': '82.91',
    'descFr': 'Activités des agences de recouvrement de factures et des sociétés d\'information financière sur la clientèle'
  },
  {
    'code': '82.91Z',
    'descFr': 'Activités des agences de recouvrement de factures et des sociétés d\'information financière sur la clientèle'
  },
  {
    'code': '82.92',
    'descFr': 'Activités de conditionnement'
  },
  {
    'code': '82.92Z',
    'descFr': 'Activités de conditionnement'
  },
  {
    'code': '82.99',
    'descFr': 'Autres activités de soutien aux entreprises n.c.a.'
  },
  {
    'code': '82.99Z',
    'descFr': 'Autres activités de soutien aux entreprises n.c.a.'
  },
  {
    'code': '84',
    'descFr': 'Administration publique et défense ; sécurité sociale obligatoire'
  },
  {
    'code': '84.1',
    'descFr': 'Administration générale, économique et sociale'
  },
  {
    'code': '84.11',
    'descFr': 'Administration publique générale'
  },
  {
    'code': '84.11Z',
    'descFr': 'Administration publique générale'
  },
  {
    'code': '84.12',
    'descFr': 'Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité sociale'
  },
  {
    'code': '84.12Z',
    'descFr': 'Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité sociale'
  },
  {
    'code': '84.13',
    'descFr': 'Administration publique (tutelle) des activités économiques'
  },
  {
    'code': '84.13Z',
    'descFr': 'Administration publique (tutelle) des activités économiques'
  },
  {
    'code': '84.2',
    'descFr': 'Services de prérogative publique'
  },
  {
    'code': '84.21',
    'descFr': 'Affaires étrangères'
  },
  {
    'code': '84.21Z',
    'descFr': 'Affaires étrangères'
  },
  {
    'code': '84.22',
    'descFr': 'Défense'
  },
  {
    'code': '84.22Z',
    'descFr': 'Défense'
  },
  {
    'code': '84.23',
    'descFr': 'Justice'
  },
  {
    'code': '84.23Z',
    'descFr': 'Justice'
  },
  {
    'code': '84.24',
    'descFr': 'Activités d’ordre public et de sécurité'
  },
  {
    'code': '84.24Z',
    'descFr': 'Activités d’ordre public et de sécurité'
  },
  {
    'code': '84.25',
    'descFr': 'Services du feu et de secours'
  },
  {
    'code': '84.25Z',
    'descFr': 'Services du feu et de secours'
  },
  {
    'code': '84.3',
    'descFr': 'Sécurité sociale obligatoire'
  },
  {
    'code': '84.30',
    'descFr': 'Sécurité sociale obligatoire'
  },
  {
    'code': '84.30A',
    'descFr': 'Activités générales de sécurité sociale'
  },
  {
    'code': '84.30B',
    'descFr': 'Gestion des retraites complémentaires'
  },
  {
    'code': '84.30C',
    'descFr': 'Distribution sociale de revenus'
  },
  {
    'code': '85',
    'descFr': 'Enseignement'
  },
  {
    'code': '85.1',
    'descFr': 'Enseignement pré-primaire'
  },
  {
    'code': '85.10',
    'descFr': 'Enseignement pré-primaire'
  },
  {
    'code': '85.10Z',
    'descFr': 'Enseignement pré-primaire'
  },
  {
    'code': '85.2',
    'descFr': 'Enseignement primaire'
  },
  {
    'code': '85.20',
    'descFr': 'Enseignement primaire'
  },
  {
    'code': '85.20Z',
    'descFr': 'Enseignement primaire'
  },
  {
    'code': '85.3',
    'descFr': 'Enseignement secondaire'
  },
  {
    'code': '85.31',
    'descFr': 'Enseignement secondaire général'
  },
  {
    'code': '85.31Z',
    'descFr': 'Enseignement secondaire général'
  },
  {
    'code': '85.32',
    'descFr': 'Enseignement secondaire technique ou professionnel'
  },
  {
    'code': '85.32Z',
    'descFr': 'Enseignement secondaire technique ou professionnel'
  },
  {
    'code': '85.4',
    'descFr': 'Enseignement supérieur et post-secondaire non supérieur'
  },
  {
    'code': '85.41',
    'descFr': 'Enseignement post-secondaire non supérieur'
  },
  {
    'code': '85.41Z',
    'descFr': 'Enseignement post-secondaire non supérieur'
  },
  {
    'code': '85.42',
    'descFr': 'Enseignement supérieur'
  },
  {
    'code': '85.42Z',
    'descFr': 'Enseignement supérieur'
  },
  {
    'code': '85.5',
    'descFr': 'Autres activités d\'enseignement'
  },
  {
    'code': '85.51',
    'descFr': 'Enseignement de disciplines sportives et d\'activités de loisirs'
  },
  {
    'code': '85.51Z',
    'descFr': 'Enseignement de disciplines sportives et d\'activités de loisirs'
  },
  {
    'code': '85.52',
    'descFr': 'Enseignement culturel'
  },
  {
    'code': '85.52Z',
    'descFr': 'Enseignement culturel'
  },
  {
    'code': '85.53',
    'descFr': 'Enseignement de la conduite'
  },
  {
    'code': '85.53Z',
    'descFr': 'Enseignement de la conduite'
  },
  {
    'code': '85.59',
    'descFr': 'Enseignements divers'
  },
  {
    'code': '85.59A',
    'descFr': 'Formation continue d\'adultes'
  },
  {
    'code': '85.59B',
    'descFr': 'Autres enseignements'
  },
  {
    'code': '85.6',
    'descFr': 'Activités de soutien à l\'enseignement'
  },
  {
    'code': '85.60',
    'descFr': 'Activités de soutien à l\'enseignement'
  },
  {
    'code': '85.60Z',
    'descFr': 'Activités de soutien à l\'enseignement'
  },
  {
    'code': '86',
    'descFr': 'Activités pour la santé humaine'
  },
  {
    'code': '86.1',
    'descFr': 'Activités hospitalières'
  },
  {
    'code': '86.10',
    'descFr': 'Activités hospitalières'
  },
  {
    'code': '86.10Z',
    'descFr': 'Activités hospitalières'
  },
  {
    'code': '86.2',
    'descFr': 'Activité des médecins et des dentistes'
  },
  {
    'code': '86.21',
    'descFr': 'Activité des médecins généralistes'
  },
  {
    'code': '86.21Z',
    'descFr': 'Activité des médecins généralistes'
  },
  {
    'code': '86.22',
    'descFr': 'Activité des médecins spécialistes'
  },
  {
    'code': '86.22A',
    'descFr': 'Activités de radiodiagnostic et de radiothérapie'
  },
  {
    'code': '86.22B',
    'descFr': 'Activités chirurgicales'
  },
  {
    'code': '86.22C',
    'descFr': 'Autres activités des médecins spécialistes'
  },
  {
    'code': '86.23',
    'descFr': 'Pratique dentaire'
  },
  {
    'code': '86.23Z',
    'descFr': 'Pratique dentaire'
  },
  {
    'code': '86.9',
    'descFr': 'Autres activités pour la santé humaine'
  },
  {
    'code': '86.90',
    'descFr': 'Autres activités pour la santé humaine'
  },
  {
    'code': '86.90A',
    'descFr': 'Ambulances'
  },
  {
    'code': '86.90B',
    'descFr': 'Laboratoires d\'analyses médicales'
  },
  {
    'code': '86.90C',
    'descFr': 'Centres de collecte et banques d\'organes'
  },
  {
    'code': '86.90D',
    'descFr': 'Activités des infirmiers et des sages-femmes'
  },
  {
    'code': '86.90E',
    'descFr': 'Activités des professionnels de la rééducation, de l’appareillage et des pédicures-podologues'
  },
  {
    'code': '86.90F',
    'descFr': 'Activités de santé humaine non classées ailleurs'
  },
  {
    'code': '87',
    'descFr': 'Hébergement médico-social et social'
  },
  {
    'code': '87.1',
    'descFr': 'Hébergement médicalisé'
  },
  {
    'code': '87.10',
    'descFr': 'Hébergement médicalisé'
  },
  {
    'code': '87.10A',
    'descFr': 'Hébergement médicalisé pour personnes âgées'
  },
  {
    'code': '87.10B',
    'descFr': 'Hébergement médicalisé pour enfants handicapés'
  },
  {
    'code': '87.10C',
    'descFr': 'Hébergement médicalisé pour adultes handicapés et autre hébergement médicalisé'
  },
  {
    'code': '87.2',
    'descFr': 'Hébergement social pour personnes handicapées mentales, malades mentales et toxicomanes'
  },
  {
    'code': '87.20',
    'descFr': 'Hébergement social pour personnes handicapées mentales, malades mentales et toxicomanes'
  },
  {
    'code': '87.20A',
    'descFr': 'Hébergement social pour handicapés mentaux et malades mentaux'
  },
  {
    'code': '87.20B',
    'descFr': 'Hébergement social pour toxicomanes'
  },
  {
    'code': '87.3',
    'descFr': 'Hébergement social pour personnes âgées ou handicapées physiques'
  },
  {
    'code': '87.30',
    'descFr': 'Hébergement social pour personnes âgées ou handicapées physiques'
  },
  {
    'code': '87.30A',
    'descFr': 'Hébergement social pour personnes âgées'
  },
  {
    'code': '87.30B',
    'descFr': 'Hébergement social pour handicapés  physiques'
  },
  {
    'code': '87.9',
    'descFr': 'Autres activités d’hébergement social'
  },
  {
    'code': '87.90',
    'descFr': 'Autres activités d’hébergement social'
  },
  {
    'code': '87.90A',
    'descFr': 'Hébergement social pour enfants en difficultés'
  },
  {
    'code': '87.90B',
    'descFr': 'Hébergement social pour adultes et familles en difficultés et autre hébergement social'
  },
  {
    'code': '88',
    'descFr': 'Action sociale sans hébergement'
  },
  {
    'code': '88.1',
    'descFr': 'Action sociale sans hébergement pour personnes âgées et pour personnes handicapées'
  },
  {
    'code': '88.10',
    'descFr': 'Action sociale sans hébergement pour personnes âgées et pour personnes handicapées'
  },
  {
    'code': '88.10A',
    'descFr': 'Aide à domicile'
  },
  {
    'code': '88.10B',
    'descFr': 'Accueil ou accompagnement sans hébergement d’adultes handicapés ou de  personnes âgées'
  },
  {
    'code': '88.10C',
    'descFr': 'Aide par le travail'
  },
  {
    'code': '88.9',
    'descFr': 'Autre action sociale sans hébergement'
  },
  {
    'code': '88.91',
    'descFr': 'Action sociale sans hébergement pour jeunes enfants'
  },
  {
    'code': '88.91A',
    'descFr': 'Accueil de jeunes enfants'
  },
  {
    'code': '88.91B',
    'descFr': 'Accueil ou accompagnement sans hébergement d’enfants handicapés'
  },
  {
    'code': '88.99',
    'descFr': 'Autre action sociale sans hébergement n.c.a.'
  },
  {
    'code': '88.99A',
    'descFr': 'Autre accueil ou accompagnement sans hébergement d’enfants et d’adolescents'
  },
  {
    'code': '88.99B',
    'descFr': 'Action sociale sans hébergement n.c.a.'
  },
  {
    'code': '90',
    'descFr': 'Activités créatives, artistiques et de spectacle'
  },
  {
    'code': '90.0',
    'descFr': 'Activités créatives, artistiques et de spectacle'
  },
  {
    'code': '90.01',
    'descFr': 'Arts du spectacle vivant'
  },
  {
    'code': '90.01Z',
    'descFr': 'Arts du spectacle vivant'
  },
  {
    'code': '90.02',
    'descFr': 'Activités de soutien au spectacle vivant'
  },
  {
    'code': '90.02Z',
    'descFr': 'Activités de soutien au spectacle vivant'
  },
  {
    'code': '90.03',
    'descFr': 'Création artistique'
  },
  {
    'code': '90.03A',
    'descFr': 'Création artistique relevant des arts plastiques'
  },
  {
    'code': '90.03B',
    'descFr': 'Autre création artistique'
  },
  {
    'code': '90.04',
    'descFr': 'Gestion de salles de spectacles'
  },
  {
    'code': '90.04Z',
    'descFr': 'Gestion de salles de spectacles'
  },
  {
    'code': '91',
    'descFr': 'Bibliothèques, archives, musées et autres activités culturelles'
  },
  {
    'code': '91.0',
    'descFr': 'Bibliothèques, archives, musées et autres activités culturelles'
  },
  {
    'code': '91.01',
    'descFr': 'Gestion des bibliothèques et des archives'
  },
  {
    'code': '91.01Z',
    'descFr': 'Gestion des bibliothèques et des archives'
  },
  {
    'code': '91.02',
    'descFr': 'Gestion des musées'
  },
  {
    'code': '91.02Z',
    'descFr': 'Gestion des musées'
  },
  {
    'code': '91.03',
    'descFr': 'Gestion des sites et monuments historiques et des attractions touristiques similaires'
  },
  {
    'code': '91.03Z',
    'descFr': 'Gestion des sites et monuments historiques et des attractions touristiques similaires'
  },
  {
    'code': '91.04',
    'descFr': 'Gestion des jardins botaniques et zoologiques et des réserves naturelles'
  },
  {
    'code': '91.04Z',
    'descFr': 'Gestion des jardins botaniques et zoologiques et des réserves naturelles'
  },
  {
    'code': '92',
    'descFr': 'Organisation de jeux de hasard et d\'argent'
  },
  {
    'code': '92.0',
    'descFr': 'Organisation de jeux de hasard et d\'argent'
  },
  {
    'code': '92.00',
    'descFr': 'Organisation de jeux de hasard et d\'argent'
  },
  {
    'code': '92.00Z',
    'descFr': 'Organisation de jeux de hasard et d\'argent'
  },
  {
    'code': '93',
    'descFr': 'Activités sportives, récréatives et de loisirs'
  },
  {
    'code': '93.1',
    'descFr': 'Activités liées au sport'
  },
  {
    'code': '93.11',
    'descFr': 'Gestion d\'installations sportives'
  },
  {
    'code': '93.11Z',
    'descFr': 'Gestion d\'installations sportives'
  },
  {
    'code': '93.12',
    'descFr': 'Activités de clubs de sports'
  },
  {
    'code': '93.12Z',
    'descFr': 'Activités de clubs de sports'
  },
  {
    'code': '93.13',
    'descFr': 'Activités des centres de culture physique'
  },
  {
    'code': '93.13Z',
    'descFr': 'Activités des centres de culture physique'
  },
  {
    'code': '93.19',
    'descFr': 'Autres activités liées au sport'
  },
  {
    'code': '93.19Z',
    'descFr': 'Autres activités liées au sport'
  },
  {
    'code': '93.2',
    'descFr': 'Activités récréatives et de loisirs'
  },
  {
    'code': '93.21',
    'descFr': 'Activités des parcs d\'attractions et parcs à thèmes'
  },
  {
    'code': '93.21Z',
    'descFr': 'Activités des parcs d\'attractions et parcs à thèmes'
  },
  {
    'code': '93.29',
    'descFr': 'Autres activités récréatives et de loisirs'
  },
  {
    'code': '93.29Z',
    'descFr': 'Autres activités récréatives et de loisirs'
  },
  {
    'code': '94',
    'descFr': 'Activités des organisations associatives'
  },
  {
    'code': '94.1',
    'descFr': 'Activités des organisations économiques, patronales et professionnelles'
  },
  {
    'code': '94.11',
    'descFr': 'Activités des organisations patronales et consulaires'
  },
  {
    'code': '94.11Z',
    'descFr': 'Activités des organisations patronales et consulaires'
  },
  {
    'code': '94.12',
    'descFr': 'Activités des organisations professionnelles'
  },
  {
    'code': '94.12Z',
    'descFr': 'Activités des organisations professionnelles'
  },
  {
    'code': '94.2',
    'descFr': 'Activités des syndicats de salariés'
  },
  {
    'code': '94.20',
    'descFr': 'Activités des syndicats de salariés'
  },
  {
    'code': '94.20Z',
    'descFr': 'Activités des syndicats de salariés'
  },
  {
    'code': '94.9',
    'descFr': 'Activités des autres organisations associatives'
  },
  {
    'code': '94.91',
    'descFr': 'Activités des organisations religieuses'
  },
  {
    'code': '94.91Z',
    'descFr': 'Activités des organisations religieuses'
  },
  {
    'code': '94.92',
    'descFr': 'Activités des organisations politiques'
  },
  {
    'code': '94.92Z',
    'descFr': 'Activités des organisations politiques'
  },
  {
    'code': '94.99',
    'descFr': 'Activités des organisations associatives n.c.a.'
  },
  {
    'code': '94.99Z',
    'descFr': 'Autres organisations fonctionnant par adhésion volontaire'
  },
  {
    'code': '95',
    'descFr': 'Réparation d\'ordinateurs et de biens personnels et domestiques'
  },
  {
    'code': '95.1',
    'descFr': 'Réparation d\'ordinateurs et d\'équipements de communication'
  },
  {
    'code': '95.11',
    'descFr': 'Réparation d\'ordinateurs et d\'équipements périphériques'
  },
  {
    'code': '95.11Z',
    'descFr': 'Réparation d\'ordinateurs et d\'équipements périphériques'
  },
  {
    'code': '95.12',
    'descFr': 'Réparation d\'équipements de communication'
  },
  {
    'code': '95.12Z',
    'descFr': 'Réparation d\'équipements de communication'
  },
  {
    'code': '95.2',
    'descFr': 'Réparation de biens personnels et domestiques'
  },
  {
    'code': '95.21',
    'descFr': 'Réparation de produits électroniques grand public'
  },
  {
    'code': '95.21Z',
    'descFr': 'Réparation de produits électroniques grand public'
  },
  {
    'code': '95.22',
    'descFr': 'Réparation d\'appareils électroménagers et d\'équipements pour la maison et le jardin'
  },
  {
    'code': '95.22Z',
    'descFr': 'Réparation d\'appareils électroménagers et d\'équipements pour la maison et le jardin'
  },
  {
    'code': '95.23',
    'descFr': 'Réparation de chaussures et d\'articles en cuir'
  },
  {
    'code': '95.23Z',
    'descFr': 'Réparation de chaussures et d\'articles en cuir'
  },
  {
    'code': '95.24',
    'descFr': 'Réparation de meubles et d\'équipements du foyer'
  },
  {
    'code': '95.24Z',
    'descFr': 'Réparation de meubles et d\'équipements du foyer'
  },
  {
    'code': '95.25',
    'descFr': 'Réparation d\'articles d\'horlogerie et de bijouterie'
  },
  {
    'code': '95.25Z',
    'descFr': 'Réparation d\'articles d\'horlogerie et de bijouterie'
  },
  {
    'code': '95.29',
    'descFr': 'Réparation d\'autres biens personnels et domestiques'
  },
  {
    'code': '95.29Z',
    'descFr': 'Réparation d\'autres biens personnels et domestiques'
  },
  {
    'code': '96',
    'descFr': 'Autres services personnels'
  },
  {
    'code': '96.0',
    'descFr': 'Autres services personnels'
  },
  {
    'code': '96.01',
    'descFr': 'Blanchisserie-teinturerie'
  },
  {
    'code': '96.01A',
    'descFr': 'Blanchisserie-teinturerie de gros'
  },
  {
    'code': '96.01B',
    'descFr': 'Blanchisserie-teinturerie de détail'
  },
  {
    'code': '96.02',
    'descFr': 'Coiffure et soins de beauté'
  },
  {
    'code': '96.02A',
    'descFr': 'Coiffure'
  },
  {
    'code': '96.02B',
    'descFr': 'Soins de beauté'
  },
  {
    'code': '96.03',
    'descFr': 'Services funéraires'
  },
  {
    'code': '96.03Z',
    'descFr': 'Services funéraires'
  },
  {
    'code': '96.04',
    'descFr': 'Entretien corporel'
  },
  {
    'code': '96.04Z',
    'descFr': 'Entretien corporel'
  },
  {
    'code': '96.09',
    'descFr': 'Autres services personnels n.c.a.'
  },
  {
    'code': '96.09Z',
    'descFr': 'Autres services personnels n.c.a.'
  },
  {
    'code': '97',
    'descFr': 'Activités des ménages en tant qu\'employeurs de personnel domestique'
  },
  {
    'code': '97.0',
    'descFr': 'Activités des ménages en tant qu\'employeurs de personnel domestique'
  },
  {
    'code': '97.00',
    'descFr': 'Activités des ménages en tant qu\'employeurs de personnel domestique'
  },
  {
    'code': '97.00Z',
    'descFr': 'Activités des ménages en tant qu\'employeurs de personnel domestique'
  },
  {
    'code': '98',
    'descFr': 'Activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre'
  },
  {
    'code': '98.1',
    'descFr': 'Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre'
  },
  {
    'code': '98.10',
    'descFr': 'Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre'
  },
  {
    'code': '98.10Z',
    'descFr': 'Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre'
  },
  {
    'code': '98.2',
    'descFr': 'Activités indifférenciées des ménages en tant que producteurs de services pour usage propre'
  },
  {
    'code': '98.20',
    'descFr': 'Activités indifférenciées des ménages en tant que producteurs de services pour usage propre'
  },
  {
    'code': '98.20Z',
    'descFr': 'Activités indifférenciées des ménages en tant que producteurs de services pour usage propre'
  },
  {
    'code': '99',
    'descFr': 'Activités des organisations et organismes extraterritoriaux'
  },
  {
    'code': '99.0',
    'descFr': 'Activités des organisations et organismes extraterritoriaux'
  },
  {
    'code': '99.00',
    'descFr': 'Activités des organisations et organismes extraterritoriaux'
  },
  {
    'code': '99.00Z',
    'descFr': 'Activités des organisations et organismes extraterritoriaux'
  }
]

export const getNafByCode = (code :string) => nafList.find(n => n.code.replace('.', '') === code.replace('.', '')) ?? { code: '', descFr: '' }
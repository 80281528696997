<script lang="ts">
    import { Link, navigate, Route, Router } from 'svelte-routing'
    import ListMembers from './team-management/ListMembers.svelte'
    import { t } from '../../core-app/lib/i18n/i18nextWrapper'
    import SectionHeading from '../../core-app/lib/ui-kit/SectionHeading.svelte'
    import { WorkspaceStore } from '../stores/workspace.store.js'
    import { isUserWsOwner } from '../../core-app/services/guard.js'
    import { onMount } from 'svelte'
    import AddEditNewUser from './team-management/AddEditNewUser.svelte'
    import mixpanel from 'mixpanel-browser'

    function getProps({ href, isPartiallyCurrent, isCurrent }) {
      const isActive = href === '/' ? isCurrent : isPartiallyCurrent || isCurrent

      if (isActive) {
        return { class: 'tab-link active' }
      }

      return { class: 'tab-link' }
    }

    onMount(() => {
      mixpanel.track('XA10 Team Page', {
        'Description': 'Open Team.svelte'
      })
      if (!isUserWsOwner()) {
        navigate('/today')
      }
    })
</script>

<svelte:head>
    <title>{t('topMenu.nav.team.label')} - Dundy</title>
</svelte:head>

{#if isUserWsOwner()}
    <main>
        <div class="w-full flex justify-between">
            <div class="flex-col flex-1">
                <h1 class="text-black text-3xl font-bold">{t('team.title')}</h1>
            </div>

            <div class="flex justify-end justify-self-end self-end add-invoice-button-container">
                <button class="btn action-primary cursor-pointer flex justify-between"
                        on:click|preventDefault|stopPropagation={() => navigate('/team/manage')}
                        on:keydown={(e) => {if (e.key === 'Enter') {navigate('/team/manage')}}}
                        data-cy="add-new-customer-button">
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 5.75V18.25"></path>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18.25 12L5.75 12"></path>
                    </svg>

                    <span class="mx-3">{t('team.manage.add')}</span>
                </button>
            </div>
        </div>
      <!--  <div class="flex w-full flex-col flex-1">
            <h1 class="text-black text-3xl font-bold">{t("team.title")}</h1>
        </div>-->


        <div class="py-4">
            <div class="mt-4">
                <SectionHeading>
                    <option value="/team/manage">{t('team.manage.title')} ({$WorkspaceStore?.teamUsersIds?.length + 1})
                    </option>
                    <option value="/team/invite">{t('team.invite.title')}</option>
                    <nav class="flex justify-between -mb-px space-x-8 w-full" aria-label="tabs"
                         slot="desktopNavigation">
                        <div class="flex space-x-8">
                            <Link to="/team/" {getProps}>
                                {t('team.manage.title')}
                                <span class="tab-link-badge">{$WorkspaceStore?.teamUsersIds?.length + 1}</span>
                            </Link>
                        </div>
                    </nav>
                </SectionHeading>

                <Router>
                    <Route path="manage">
                        <AddEditNewUser />
                    </Route>
                    <Route path="/">
                        <ListMembers/>
                    </Route>
                    <Route let:params path="manage/:id">
                        <AddEditNewUser contactId={params.id} />
                    </Route>
                </Router>
            </div>
        </div>
    </main>
{:else}
    <p>not allowed</p>
{/if}
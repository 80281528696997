<script lang="ts">
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
    import { navigate } from 'svelte-routing'
    import { BusinessDocument, BusinessDocumentCollateralData } from '../../../models/business-document'
    import Switch from '../../../../core-app/lib/ui-kit/Switch.svelte'
    import { WorkspaceStore } from '../../../../crm-app/stores/workspace.store'
    import { BusinessDocumentKind } from '../../../enums/business-document-kind'

    /** Export let declarations **/
    export let businessDocument: BusinessDocument
    export let invoiceCollateralData: BusinessDocumentCollateralData

    /** Local declarations */
    let showPaymentLinkButtonDisabled: boolean = (!!invoiceCollateralData ? (invoiceCollateralData?.isPaidWhenFinalized || !invoiceCollateralData?.isBankConnectionActive) : true)
    let chooseToShowPaymentLinkIfPossible: boolean = (!!invoiceCollateralData ? invoiceCollateralData.showPaymentLink : true)

    /** Reactive declarations **/
    $:if (!!invoiceCollateralData) {
      invoiceCollateralData.isBankConnectionActive = $WorkspaceStore?.bankConfig?.bankConnectionCurrentlyKnownActive
    }
    $:if (!!invoiceCollateralData) {
      showPaymentLinkButtonDisabled = (invoiceCollateralData?.isPaidWhenFinalized || !invoiceCollateralData?.isBankConnectionActive)
    }
    $: if (!!invoiceCollateralData && !!businessDocument) {
      if (invoiceCollateralData?.isPaidWhenFinalized) {
        businessDocument.amountDueScaledValue = 0
        invoiceCollateralData.showPaymentLink = false
      } else {
        businessDocument.amountDueScaledValue = businessDocument.installmentResultIncludingTaxScaledValue
        invoiceCollateralData.showPaymentLink = !invoiceCollateralData?.isPaidWhenFinalized && invoiceCollateralData?.isBankConnectionActive && chooseToShowPaymentLinkIfPossible
      }
    } else {
      chooseToShowPaymentLinkIfPossible = false
    }
    $: if (!!invoiceCollateralData && !!businessDocument) {
      businessDocument.paymentConditions = (invoiceCollateralData?.showLateFeesAndPenaltyText ? t('invoices.createInvoice.defaultFrenchPenaltyTerms') : '') + (invoiceCollateralData?.showNoDiscountForEarlyBirds ? t('invoices.createInvoice.defaultFrenchNoDiscountEarlyBirds') : '') + invoiceCollateralData?.paymentConditionsCustomText
    }

</script>
{#if businessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE}
<div class="divide-y divide-gray-200 border-t border-whisper sm:px-6">
    <div class="space-y-6 py-7">
        <div class="px-12 sm:px-6">
            <h2 class="block text-2xl font-bold text-gray-900 mb-3">{t('invoices.createInvoice.paymentTitle')}</h2>
            <fieldset class="space-y-2">
                <label class="rounded-md rounded-tr-md relative flex cursor-pointer focus:outline-none items-center py-3.5">
                    <Switch bind:toggled={invoiceCollateralData.isPaidWhenFinalized}/>
                    <span class="ml-3 flex flex-col">
                        <span id="invoice-paid-0-label"
                              class="block text-sm font-medium text-black">{t('invoices.createInvoice.paidInFullToggle')}</span>
                        <span id="invoice-paid-0-description"
                              class="block text-s text-gray-800">{t('invoices.createInvoice.paidInFullToggleCopy')}</span>
                    </span>
                </label>
            </fieldset>
            <fieldset class="space-y-2">
                <label class="rounded-md rounded-tr-md relative flex cursor-pointer focus:outline-none items-center py-3.5">
                    <Switch
                            bind:toggled={chooseToShowPaymentLinkIfPossible}
                            disabled={showPaymentLinkButtonDisabled}/>
                    <span class="ml-3 flex flex-col">
                        <span id="payment-url-setting-0-label"
                              class="block text-sm font-medium text-black">{t('invoices.createInvoice.paymentLink')}</span>
                        <span id="payment-url-setting-0-description"
                              class="block text-s text-gray-800">{t('invoices.createInvoice.paymentLinkCopy')}</span>
                    </span>
                </label>
                {#if !invoiceCollateralData.isBankConnectionActive}
                    <div class="flex cursor-pointer items-center rounded  py-1.5"
                         on:click={() => navigate('/settings/bank/view')}>
                        <svg width="24" height="24" class="text-cabaret"
                             viewBox="0 0 24 24"
                             fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 14.25C11.5858 14.25 11.25 14.5858 11.25 15C11.25 15.4142 11.5858 15.75 12 15.75V14.25ZM12.01 15.75C12.4242 15.75 12.76 15.4142 12.76 15C12.76 14.5858 12.4242 14.25 12.01 14.25V15.75ZM12 15.75H12.01V14.25H12V15.75Z"
                                  fill="currentColor"></path>
                            <path d="M10.4033 5.41136L10.9337 5.94169L10.4033 5.41136ZM5.41136 10.4033L4.88103 9.87301L4.88103 9.87301L5.41136 10.4033ZM5.41136 13.5967L5.94169 13.0663L5.94169 13.0663L5.41136 13.5967ZM10.4033 18.5886L10.9337 18.0583L10.4033 18.5886ZM13.5967 18.5886L14.127 19.119L14.127 19.119L13.5967 18.5886ZM18.5886 10.4033L19.119 9.87301L19.119 9.87301L18.5886 10.4033ZM13.5967 5.41136L14.127 4.88103L14.127 4.88103L13.5967 5.41136ZM9.87301 4.88103L4.88103 9.87301L5.94169 10.9337L10.9337 5.94169L9.87301 4.88103ZM4.88103 14.127L9.87301 19.119L10.9337 18.0583L5.94169 13.0663L4.88103 14.127ZM14.127 19.119L19.119 14.127L18.0583 13.0663L13.0663 18.0583L14.127 19.119ZM19.119 9.87301L14.127 4.88103L13.0663 5.94169L18.0583 10.9337L19.119 9.87301ZM19.119 14.127C20.2937 12.9523 20.2937 11.0477 19.119 9.87301L18.0583 10.9337C18.6472 11.5226 18.6472 12.4774 18.0583 13.0663L19.119 14.127ZM9.87301 19.119C11.0477 20.2937 12.9523 20.2937 14.127 19.119L13.0663 18.0583C12.4774 18.6472 11.5226 18.6472 10.9337 18.0583L9.87301 19.119ZM4.88103 9.87301C3.70632 11.0477 3.70632 12.9523 4.88103 14.127L5.94169 13.0663C5.35277 12.4774 5.35277 11.5226 5.94169 10.9337L4.88103 9.87301ZM10.9337 5.94169C11.5226 5.35277 12.4774 5.35277 13.0663 5.94169L14.127 4.88103C12.9523 3.70632 11.0477 3.70632 9.87301 4.88103L10.9337 5.94169Z"
                                  fill="currentColor"></path>
                            <path d="M12 8.75V12.25" stroke="currentColor"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                        </svg>

                        <p class="text-xs">{@html t('invoices.createInvoice.activatePaymentNotice')}</p>
                    </div>
                    <hr class="w-full border-athensGray my-2 h-px"/>
                {/if}
            </fieldset>
        </div>
    </div>
</div>
{/if}
<div class="divide-y divide-gray-200 border-t border-whisper sm:px-6">
    <div class="space-y-6 py-7">
        <div class="px-12 sm:px-6">
            <h2 class="block text-2xl font-bold text-gray-900 mb-3">{t('invoices.createInvoice.paymentConditions')}</h2>
            <fieldset class="space-y-5">
                <legend class="sr-only">{t('invoices.createInvoice.paymentConditions')}</legend>
                <div class="relative flex items-start">
                    <div class="flex h-5 items-center">
                        <input id="showLateFeesAndPenaltyText"
                               aria-describedby="invoiceCollateralData.showLateFeesAndPenaltyText-description"
                               name="invoiceCollateralData.showLateFeesAndPenaltyText"
                               type="checkbox"
                               bind:checked={invoiceCollateralData.showLateFeesAndPenaltyText}
                               class="h-4 w-4 rounded border-gray-300 text-persianGreen focus:ring-persianGreen">
                    </div>
                    <div class="ml-3 text-xs">
                        <label for="showLateFeesAndPenaltyText"
                               class="font-medium text-black cursor-pointer">{t('invoices.createInvoice.showPenaltyText')}</label>
                        <span id="invoiceCollateralData.showLateFeesAndPenaltyText-description"
                              class="inline-flex items-center rounded bg-pink-100 px-2 py-0.5 text-xs font-medium text-pink-800">{t('invoices.createInvoice.recommendedNotice')}</span>
                    </div>
                </div>
                <div class="relative flex items-start">
                    <div class="flex h-5 items-center">
                        <input id="showNoDiscountForEarlyBirds"
                               aria-describedby="showNoDiscountForEarlyBirds-description"
                               name="showNoDiscountForEarlyBirds"
                               type="checkbox"
                               bind:checked={invoiceCollateralData.showNoDiscountForEarlyBirds}
                               class="h-4 w-4 rounded border-gray-300 text-persianGreen focus:ring-persianGreen">
                    </div>
                    <div class="ml-3 text-xs">
                        <label for="showNoDiscountForEarlyBirds"
                               class="font-medium text-black cursor-pointer">{t('invoices.createInvoice.showNoDiscountText')}</label>
                        <span id="showNoDiscountForEarlyBirds-description"
                              class="inline-flex items-center rounded bg-pink-100 px-2 py-0.5 text-xs font-medium text-pink-800">{t('invoices.createInvoice.recommendedNotice')}</span>
                    </div>
                </div>

                <div class="flex py-2">
                    <div class="flex flex-1 w-96 flex-col">
                        <label class="block text-sm font-normal text-black">{t('invoices.createInvoice.addCustomTerms')}</label>
                        <textarea class="invoice-textarea mt-1.5"
                                  bind:value={invoiceCollateralData.paymentConditionsCustomText}
                                  aria-placeholder="{t('invoices.createInvoice.customTermsPlaceholder')}"></textarea>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
</div>
<style lang="postcss">
    .invoice-textarea {
        @apply border border-loblolly box-border bg-white focus:outline-none focus:ring-2 focus:ring-dundyOrange focus:border-transparent focus-visible:ring-2 focus-visible:ring-dundyOrange focus-visible:border-transparent text-s;
        min-height: 112px;
        border-radius: 5px;
        padding: 10px 12px;
    }
</style>
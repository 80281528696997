<script lang="ts">
    import { createEventDispatcher, onMount } from 'svelte'
    import FileInput from './FileInput.svelte'
    import UpdatedFileUpload from '../../../crm-app/models/updated-file-upload'
    import { t } from '../i18n/i18nextWrapper.js'
    import ConditionalFileInputWrapper from './fileInputComponents/ConditionalFileInputWrapper.svelte'
    import { encodeFileToBase64 } from '../../util/file-utils'

    // Post-Processing: make the input image square using Cropper JS
    // Usage like:
    /*
        <FileInputWithCropper
                chooseANewFileMessageTEntry="companyEdit.selectNewLogo"
                fileLimitationsMessageTEntry="companyEdit.uploadLimitations"
                fileUploadHelpMessageTEntry="companyEdit.uploadHelp"
                cancelChangesMessageTEntry="companyEdit.resetLogo"
                reFramePictureMessageTEntry="companyEdit.reFramePicture"
                resizePictureMessageTEntry="companyEdit.resizePicture"
                confirmReFramingMessageTEntry="companyEdit.confirmReFraming"
                cancelReFramingMessageTEntry="companyEdit.cancelReFraming"
                timeZoneIANACode="Europe/Paris"
                languageAndCountryCode="fr-FR"
                existingFileURL={!!localCompany.emailLogoURL ? localCompany.emailLogoURL :"https://static.thenounproject.com/png/1003548-200.png"}
                existingFileName={!!localCompany.emailLogoURL ? "company logo" :"choose a company logo"}
                existingFileSizeBytes=0
                existingFileLastModifiedUnixMilliseconds=0
                acceptedExtensions=".jpg, .jpeg, .png"
                triggerNewFileSelectModal={false}
                triggerProgrammaticallyResetSelectedFile={triggerProgrammaticallyResetSelectedFile}
                addBoundingDivs={false}
                showImage={true}
                showNewUploadButton={true}
                showCancelChangesButton={true}
                showUploadHelpMessage={true}
                showAdditionalUploadHelpMessage={true}
                showAdditionalUploadHelpAlwaysMessage={true}
                showFileMetadata={false}
                specificFileValidator={companyLogoImageUploadValidator}
                newPostProcessedFileUploadAvailable={newPostProcessedFileUploadAvailable}
                on:chosenNewFileUpload={newFileUpload}
                on:chosenNewFileUploadAfterProcessing={newPostProcessedFileUpload}
                on:discardedFileUpload={discardedFileUpload}
        />
     */

    // Reference for cropper: https://svelte.dev/repl/5cc1a73f6438414088ab69ee4915e4ce?version=3.46.3

    export let chooseANewFileMessageTEntry: string = 'companyEdit.selectNewLogo' // defaults values, overridden by passed values
    export let fileLimitationsMessageTEntry: string = 'companyEdit.uploadLimitations' // defaults values, overridden by passed values
    export let fileUploadHelpMessageTEntry: string = 'companyEdit.uploadHelp' // defaults values, overridden by passed values
    export let cancelChangesMessageTEntry: string = 'companyEdit.resetLogo' // defaults values, overridden by passed values
    export let reFramePictureMessageTEntry: string = 'companyEdit.reFramePicture' // defaults values, overridden by passed values
    export let resizePictureMessageTEntry: string = 'companyEdit.reSizePicture' // defaults values, overridden by passed values
    export let confirmReFramingMessageTEntry: string = 'companyEdit.confirmReFraming' // defaults values, overridden by passed values
    export let cancelReFramingMessageTEntry: string = 'companyEdit.cancelReFraming' // defaults values, overridden by passed values
    export let timeZoneIANACode: string = 'Europe/Paris' // defaults values, overridden by passed values
    export let languageAndCountryCode: string = 'fr-FR' // defaults values, overridden by passed values

    export let addBoundingDivs: boolean = true // defaults values, overridden by passed values

    export let existingFileURL: string = 'https://thumbs.dreamstime.com/b/amazon-logo-editorial-vector-illustration-market-136495269.jpg' // defaults values, overridden by passed values
    export let existingFileName: string = 'amazon-logo-editorial-vector-illustration-market-136495269.jpg' // defaults values, overridden by passed values
    export let existingFileSizeBytes: number = 14294 // defaults values, overridden by passed values
    export let existingFileLastModifiedUnixMilliseconds: number = 1672502195 * 1000 // defaults values, overridden by passed values

    export let maxFileSizeBytes: number = 1024 * 1024 * 2 // 1024*1024*2 for 2MB max // defaults values, overridden by passed values
    export let acceptedExtensions: string = '.jpg, .jpeg, .png' // defaults values, overridden by passed values

    export let triggerNewFileSelectModal: boolean = false // defaults values, overridden by passed values
    export let triggerProgrammaticallyResetSelectedFile: boolean = false // defaults values, overridden by passed values

    export let debugInfoVisible: boolean = false // defaults values, overridden by passed values
    export let showImage: boolean = true // defaults values, overridden by passed values
    export let showNewUploadButton: boolean = true // defaults values, overridden by passed values
    export let showCancelChangesButton: boolean = true // defaults values, overridden by passed values
    export let showUploadHelpMessage: boolean = true // defaults values, overridden by passed values
    export let showAdditionalUploadHelpMessage: boolean = true // defaults values, overridden by passed values
    export let showAdditionalUploadHelpAlwaysMessage: boolean = true // defaults values, overridden by passed values
    export let showFileMetadata: boolean = true // defaults values, overridden by passed values
    export let newPostProcessedFileUploadAvailable: boolean = false // output / read only // defaults values, overridden by passed values

    export let specificFileValidator: (fileMIMEContentType: string, fileName: string, fileSizeBytes: number, fileLastModifiedUnixMilliseconds: number) => boolean

    // main return from component:
    //   on:chosenNewFileUpload=<UpdatedFileUpload>{...}
    //   on:chosenNewFileUploadAfterProcessing=<UpdatedFileUpload>{...}
    //   on:discardedFileUpload=<UpdatedFileUpload>{} (empty UpdatedFileUpload)

    let newFileUploadAvailable: boolean // output / read only from FileInput component

    let newChosenUploadedFileBase64: string | ArrayBuffer
    let newChosenUploadedFileMIMEContentType: string
    let newChosenUploadedOriginalFileName: string
    let newChosenUploadedOriginalFileSizeBytes: number
    let newChosenUploadedOriginalFileLastModifiedUnixMilliseconds: number

    let newChosenUploadedPostProcessedFileBase64: string | ArrayBuffer
    let newChosenUploadedPostProcessedFileMIMEContentType: string
    let newChosenUploadedPostProcessedFileName: string
    let newChosenUploadedPostProcessedFileSizeBytes: number
    let newChosenUploadedPostProcessedFileLastModifiedUnixMilliseconds: number

    const dispatch = createEventDispatcher()
    let noNewFileChosenByUserYet: boolean

    let cropper
    let isCropModalOpen: boolean = false
    let postProcessedHtmlImage

    let paddingAroundPostProcessed = 0
    let delayBetweenImageProcesses = 500

    function showCropperStatus(title: string) {
      /* console.log('----------------------------------') */
      /* console.log(`-- ${title} --`) */
      /* console.log('----------------------------------') */
      let zoomImg = cropper.getImageData()
      // let zoomImgHeightWidth=Math.max(zoomImg.width,zoomImg.height);
      let zoomCont = cropper.getContainerData()
      // let zoomContHeightWidth=Math.max(zoomCont.width,zoomCont.height);
      let zoomCanv = cropper.getCanvasData()
      let crop = cropper.getCroppedCanvas()
      // let zoomCanvHeightWidth=Math.max(zoomCanv.width,zoomCanv.height);
      /* console.log('x-> image ', JSON.stringify(zoomImg, null, 3)) */
      /* console.log('x-> container ', JSON.stringify(zoomCont, null, 3)) */
      /* console.log('x-> canvas ', JSON.stringify(zoomCanv, null, 3)) */
      /* console.log('x-> crop ', JSON.stringify(crop, null, 3)) */


    }

    const preProcessSteps = {
      init: (whenToDoIt: number) => {
        setTimeout(() => {
          // let i=0;

          // for(i=1;i<6;i++){
          //     setTimeout(()=>{
          //
          // // cropper.zoom(0.8/i);
          // cropper.zoomTo(0.8/i)
          //     },500*i)
          // }

          // let zoomImg = cropper.getImageData();
          // let zoomCanv = cropper.getCanvasData();
          // let zoomImgHeightWidth = Math.max(zoomImg.width, zoomImg.height);
          // let zoomCont = cropper.getContainerData();
          // let zoomContHeightWidth = Math.max(zoomCont.width, zoomCont.height);
          // let zoomCanvHeightWidth = Math.max(zoomCanv.width, zoomCanv.height);
          if (debugInfoVisible) {
            showCropperStatus('initial')
          }
        }, whenToDoIt)
      },
      zoom: (whenToDoIt: number) => {
        setTimeout(() => {
          let zoomCanv = cropper.getCanvasData()
          let zoomCont = cropper.getContainerData()
          // if (zoomCont.width < zoomCanv.width || zoomCanv.naturalHeight < zoomCanv.height) {
          if (zoomCanv.naturalWidth < zoomCanv.width || zoomCont.height < zoomCanv.height) {
            let ratio = Math.min(zoomCanv.naturalWidth / zoomCanv.width, zoomCanv.naturalHeight / zoomCanv.height)
            // let ratio = Math.min(zoomCont.width / zoomCanv.width, zoomCont.height / zoomCanv.height);
            cropper.zoom(ratio)
            /* console.log('zooming ratio', ratio) */
            if (debugInfoVisible) {
              showCropperStatus('zooming')
            }
          } else {
            /* console.log('not zooming') */
          }
          // cropper.zoom(0.8)
        }, whenToDoIt)
      },
      canvAndCont: (whenToDoIt: number) => {
        setTimeout(() => {
          let zoomCanv = cropper.getCanvasData()
          let zoomCont = cropper.getContainerData()
          let squareSide = Math.max(zoomCanv.width, zoomCanv.height, zoomCont.width, zoomCont.height)
          cropper.setCanvasData({ left: 0, top: 0, width: squareSide, height: squareSide })
          if (debugInfoVisible) {
            showCropperStatus('canvas and container setup')
          }
        }, whenToDoIt)
      },
      rescale: (whenToDoIt: number) => {
        setTimeout(() => {
          let zoomImgScale = cropper.getImageData()
          if (zoomImgScale.naturalWidth > 1200 || zoomImgScale.naturalHeight > 1200) {
            const rescale = 1200 / Math.max(zoomImgScale.naturalWidth, zoomImgScale.naturalHeight)
            cropper.scale(rescale, rescale)
            if (debugInfoVisible) {
              showCropperStatus('rescale image')
            }
          }
        }, whenToDoIt)
      },
      growCanvas: (whenToDoIt: number) => {
        setTimeout(() => {
          let zoomCanv = cropper.getCanvasData()
          cropper.setCanvasData({
            left: 0,
            top: 0,
            width: zoomCanv.naturalWidth,
            height: zoomCanv.naturalHeight
          })
          cropper.scale(1, 1)
          if (debugInfoVisible) {
            showCropperStatus('grow canvas')
          }
        }, whenToDoIt)
      },
      makeItSquare: (whenToDoIt: number) => {
        setTimeout(() => {
          let zoomCanv = cropper.getCanvasData()
          let cropSize = Math.max(zoomCanv.width, zoomCanv.height)
          let diff2 = Math.abs(zoomCanv.width - zoomCanv.height) / 2.0
          let diff2x, diff2y
          if (zoomCanv.width > zoomCanv.height) {
            diff2x = 0
            diff2y = -diff2
          } else {
            diff2x = -diff2
            diff2y = 0
          }
          cropper.setCropBoxData({
            left: zoomCanv.left + diff2x - paddingAroundPostProcessed,
            top: zoomCanv.top + diff2y - paddingAroundPostProcessed,
            width: cropSize + 2 * paddingAroundPostProcessed,
            height: cropSize + 2 * paddingAroundPostProcessed
          })
          // cropper.setCropBoxData({left:0,top:0,width:cropSize,height:cropSize });

          if (debugInfoVisible) {
            showCropperStatus('select it square')
          }
          // }
        }, whenToDoIt)
      },
      test: (whenToDoIt: number) => {
        setTimeout(() => {
          // let loadedImg= cropper.getImageData();
          // let maxHeightWidth=Math.max(loadedImg.width,loadedImg.height);
          // let ratio=Math.min(loadedImg.width,loadedImg.height)/Math.max(loadedImg.width,loadedImg.height)
          // // cropper.zoomTo(ratio); // 1:1 (canvasData.width === canvasData.naturalWidth)
          // // cropper.minCanvasWidth=Math.max(loadedImg.width,loadedImg.height);
          // // cropper.minCanvasHeight=Math.max(loadedImg.width,loadedImg.height);
          // cropper.rotate(0)
          // cropper.setCanvasData({left:0,top:0,width:maxHeightWidth,height:maxHeightWidth});
          // cropper.setCropBoxData({left:0,top:0,width:maxHeightWidth,height:maxHeightWidth});
          // // const containerData = cropper.getContainerData();
          // // // const minCanvasWidth=containerData.
          // // // cropper.setCanvasData()
          // // cropper.zoomTo(.5, {
          // //     x: containerData.width / 2,
          // //     y: containerData.height / 2,
          // // });
          if (debugInfoVisible) {
            showCropperStatus('select it square')
          }
        }, whenToDoIt)
      }
    }

    function autoAdjust() {
      let whenDoingIt = -delayBetweenImageProcesses

      whenDoingIt += delayBetweenImageProcesses
      preProcessSteps.init(whenDoingIt)

      // whenDoingIt += delayBetweenImageProcesses;
      // preProcessSteps.zoom(whenDoingIt);

      whenDoingIt += delayBetweenImageProcesses
      preProcessSteps.rescale(whenDoingIt)

      whenDoingIt += delayBetweenImageProcesses
      preProcessSteps.canvAndCont(whenDoingIt)

      whenDoingIt += delayBetweenImageProcesses
      preProcessSteps.growCanvas(whenDoingIt)

      whenDoingIt += delayBetweenImageProcesses
      preProcessSteps.makeItSquare(whenDoingIt)

      // whenDoingIt += delayBetweenImageProcesses;
      // preProcessSteps.test(whenDoingIt);
    }


    function initCropper(node) {
      try {
        /* console.log('loaded', node.complete) */
        /* console.log('initCropper: node loaded ', node) */
        // https://github.com/fengyuanchen/cropperjs
        cropper = new Cropper(node, {
          viewMode: 0,
          guides: true,
          aspectRatio: 1, // 1 for 1:1, NaN for removing any constraint on the aspect ratio of the logo
          background: true,
          modal: true,
          preview: '.img-preview',
          // minCropBoxHeight: initSize,
          // minCropBoxWidth: initSize,
          // minCanvasWidth: initSize,
          // minCanvasHeight: initSize,
          // minContainerWidth: initSize,
          // minContainerHeight: initSize,
          crop(event) {
            /* console.log('****************************') */
            /* console.log('crop(event)', event.detail) */
            /* console.log(event.detail.y) */
            /* console.log(event.detail.width) */
            /* console.log(event.detail.height) */
            /* console.log(event.detail.rotate) */
            /* console.log(event.detail.scaleX) */
            /* console.log(event.detail.scaleY) */
          },
          ready() {
            autoAdjust()
          }
        })
      } catch (e) {
        /* console.log('initCropper err', e) */
      }
    }

    // step 1: choose an image
    function chosenNewFileUpload(newFileUploadData: CustomEvent<UpdatedFileUpload>) {
      // bubble up the event
      dispatch('chosenNewFileUpload', newFileUploadData.detail)
      // then fill with data
      /* console.log('*!!! newFileUploadData', newFileUploadData) */
      newChosenUploadedFileBase64 = newFileUploadData.detail.fileBase64
      newChosenUploadedFileMIMEContentType = newFileUploadData.detail.fileMIMEContentType
      newChosenUploadedOriginalFileName = newFileUploadData.detail.fileName
      newChosenUploadedOriginalFileSizeBytes = newFileUploadData.detail.fileSizeBytes
      newChosenUploadedOriginalFileLastModifiedUnixMilliseconds = newFileUploadData.detail.fileLastModifiedUnixMilliseconds

      // trigger cropper
      // this will display the image to be post-processed in img represented by postProcessedHtmlImage
      // and the post-processed will be visible in div with class img-preview
      isCropModalOpen = true
    }

    // step 2: crop the image
    function savePostProcessedImage() {
      /* console.log('*!!! shall we save the image now or wait for an official \'save\' action?') */

      // we keep companyEmailLogoOriginalName unchanged here
      newChosenUploadedPostProcessedFileBase64 = cropper.getCroppedCanvas({
        maxWidth: 1200,
        maxHeight: 1200
      }).toDataURL('image/png')
      newChosenUploadedPostProcessedFileMIMEContentType = 'image/png'
      newChosenUploadedPostProcessedFileName = newChosenUploadedOriginalFileName
      newChosenUploadedPostProcessedFileSizeBytes = newChosenUploadedPostProcessedFileBase64.length
      newChosenUploadedPostProcessedFileLastModifiedUnixMilliseconds = newChosenUploadedOriginalFileLastModifiedUnixMilliseconds
      newPostProcessedFileUploadAvailable = true
      cropper.getCroppedCanvas({
        maxWidth: 1200,
        maxHeight: 1200
      }).toBlob((blob) => {
        const file = new File([blob], newChosenUploadedPostProcessedFileName, { type: newChosenUploadedPostProcessedFileMIMEContentType })
        // bubble up the event
        dispatch('chosenNewFileUploadAfterProcessing', <UpdatedFileUpload>{
          fileBase64: file,
          fileMIMEContentType: newChosenUploadedPostProcessedFileMIMEContentType,
          fileName: newChosenUploadedPostProcessedFileName,
          fileSizeBytes: newChosenUploadedPostProcessedFileSizeBytes,
          fileLastModifiedUnixMilliseconds: newChosenUploadedPostProcessedFileLastModifiedUnixMilliseconds
        })
        isCropModalOpen = false
        encodeFileToBase64(file)
          .then((base64Result) => {
          })
      }, 'image/png')
    }

    function resetFileUpload(cancelFileUploadData: CustomEvent<UpdatedFileUpload>) {
      // bubble up the event
      dispatch('discardedFileUpload', <UpdatedFileUpload>{
        fileBase64: '',
        fileMIMEContentType: '',
        fileName: '',
        fileSizeBytes: 0,
        fileLastModifiedUnixMilliseconds: 0
      })
    }

    function cancelCropping() {
      newChosenUploadedPostProcessedFileBase64 = ''
      newChosenUploadedPostProcessedFileMIMEContentType = ''
      newChosenUploadedPostProcessedFileName = ''
      newChosenUploadedPostProcessedFileSizeBytes = 0
      newChosenUploadedPostProcessedFileLastModifiedUnixMilliseconds = 0
      newPostProcessedFileUploadAvailable = false
      isCropModalOpen = false
      // ask FileInput to reset / clean the place
      triggerProgrammaticallyResetSelectedFile = true
      // same effect as receiving a 'cancelFileUploadData' event
      // bubble up the event
      dispatch('discardedFileUpload', <UpdatedFileUpload>{
        fileBase64: '',
        fileMIMEContentType: '',
        fileName: '',
        fileSizeBytes: 0,
        fileLastModifiedUnixMilliseconds: 0
      })
    }

    $: {
      if (!!newChosenUploadedPostProcessedFileBase64 && newChosenUploadedPostProcessedFileBase64 !== '') {
        showImage = false
      } else {
        showImage = true
      }
    }

    $: {
      if (!newFileUploadAvailable) {
        cancelCropping()
        showImage = true
      }
    }

    $: {
      noNewFileChosenByUserYet =
                (!newChosenUploadedFileBase64 || newChosenUploadedFileBase64 === '')
                && (!!existingFileURL || existingFileURL !== '')
      /* console.log('*!!! noNewFileChosenByUserYet', noNewFileChosenByUserYet) */
    }

    onMount(() => {


      newPostProcessedFileUploadAvailable = false
      /* console.log('*!!! image loaded', postProcessedHtmlImage?.complete) */
      postProcessedHtmlImage?.addEventListener('*!!! load', initCropper)
    })
</script>

<svelte:head>
    <!-- Ref: https://svelte.dev/repl/5cc1a73f6438414088ab69ee4915e4ce?version=3.46.3 -->
    <link href="https://cdnjs.cloudflare.com/ajax/libs/cropperjs/2.0.0-alpha.2/cropper.css" rel="stylesheet">
    <script src="https://cdnjs.cloudflare.com/ajax/libs/cropperjs/2.0.0-alpha.2/cropper.min.js"></script>
    <!-- https://stackoverflow.com/questions/8155064/how-to-programmatically-empty-browser-cache -->
    <meta http-equiv='cache-control' content='no-cache'>
    <meta http-equiv='expires' content='0'>
    <meta http-equiv='pragma' content='no-cache'>
</svelte:head>

{#if isCropModalOpen}
    <div class="fixed z-20 ease-in-out {isCropModalOpen ? 'opacity-100 block' : 'opacity-0 hidden'}"
         aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed inset-0 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 {isCropModalOpen ? 'opacity-100 translate-y-0 sm:scale-100' : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'}">
                <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
                     style="height: 600px;">
                    <div class="sm:flex sm:items-start">
                        <div class="text-center sm:text-left">
                            <h1 class="text-3xl font-medium leading-6 text-black" id="modal-title">
                                {t(reFramePictureMessageTEntry)}</h1>
                            <div class="mt-2">
                                <p class="text-sm text-gray-500">
                                    {t(resizePictureMessageTEntry)}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 flex items-center">
                        {#if !noNewFileChosenByUserYet}
                            <div class="block w-6/12 " style="height: 400px;">
                                <p>src</p>
                                <img bind:this={postProcessedHtmlImage}
                                     use:initCropper src={newChosenUploadedFileBase64}
                                     alt="image source">
                            </div>
                            <div class="img-preview overflow-hidden w-1024 h-1024"></div>
                        {/if}
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button type="button"
                                on:click|preventDefault|stopPropagation={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  savePostProcessedImage()
                                }}
                                class="inline-flex w-full justify-center rounded-md border border-transparent bg-black px-4 py-2 text-base text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-mojo focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">
                            {t(confirmReFramingMessageTEntry)}
                        </button>
                        <button type="button"
                                on:click|preventDefault|stopPropagation={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  cancelCropping()
                                }}
                                class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-athensGray focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm">
                            {t(cancelReFramingMessageTEntry)}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
{/if}


<div data-ut-component="file-input-with-cropper">
    <ConditionalFileInputWrapper condition={addBoundingDivs}>


        {#if ((!!newChosenUploadedPostProcessedFileBase64) && (newChosenUploadedPostProcessedFileBase64 !== ''))}
            <div class="relative rounded-lg text-center border border-athensGray border-dotted flex justify-center items-center overflow-hidden w-full h-32 w-32">
                <img class="rounded-lg overflow-hidden flex align-middle relative z-10 object-scale-down h-44 w-full"
                     data-ut-img="file-input-post-processed"
                     on:click={()=>{
                       /* console.log('*!!! click') */
                       /* console.log('+*!!! triggerNewFileSelectModal = true in FileInputWithCropper') */
                       triggerNewFileSelectModal = true
                     }}
                     src={newChosenUploadedPostProcessedFileBase64} alt="post processed image source"
                >
            </div>
        {/if}
        <FileInput
                chooseANewFileMessageTEntry={chooseANewFileMessageTEntry}
                fileLimitationsMessageTEntry={fileLimitationsMessageTEntry}
                fileUploadHelpMessageTEntry={fileUploadHelpMessageTEntry}
                cancelChangesMessageTEntry={cancelChangesMessageTEntry}
                timeZoneIANACode={timeZoneIANACode}
                languageAndCountryCode={languageAndCountryCode}
                addBoundingDivs={false}
                existingFileURL={existingFileURL}
                existingFileName={existingFileName}
                existingFileSizeBytes={existingFileSizeBytes}
                existingFileLastModifiedUnixMilliseconds={existingFileLastModifiedUnixMilliseconds}
                maxFileSizeBytes={maxFileSizeBytes}
                acceptedExtensions={acceptedExtensions}
                bind:triggerNewFileSelectModal={triggerNewFileSelectModal}
                bind:triggerProgrammaticallyResetSelectedFile={triggerProgrammaticallyResetSelectedFile}
                debugInfoVisible={debugInfoVisible}
                showImage={showImage}
                showNewUploadButton={showNewUploadButton}
                showCancelChangesButton={showCancelChangesButton}
                showUploadHelpMessage={showUploadHelpMessage}
                showAdditionalUploadHelpMessage={showAdditionalUploadHelpMessage}
                showAdditionalUploadHelpAlwaysMessage={showAdditionalUploadHelpAlwaysMessage}
                showFileMetadata={showFileMetadata}
                specificFileValidator={specificFileValidator}
                bind:newFileUploadAvailable={newFileUploadAvailable}
                on:chosenNewFileUpload={chosenNewFileUpload}
                on:discardedFileUpload={resetFileUpload}
        />


    </ConditionalFileInputWrapper>
</div>


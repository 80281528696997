import { TaxonomyTag } from '../../enums/taxonomy-tag'
import { BusinessDocumentKind } from '../../enums/business-document-kind'
import type { BusinessDocument } from '../../models/business-document'
import { getBusinessDocumentUpdatedInstallmentTaxonomyTags } from './determine-business-document-taxonomy-tags'

/**
 * Returns the TaxonomyTags for the voided BusinessDocument
 * @param originalBusinessDocumentBeingVoidedKind
 * @param originalBusinessDocumentBeingVoidedTaxonomyTags
 */
export function getTagsForVoidedBusinessDocument(originalBusinessDocumentBeingVoidedKind: BusinessDocumentKind, originalBusinessDocumentBeingVoidedTaxonomyTags: TaxonomyTag[]): TaxonomyTag[] {
  let resultTagsForVoidedBusinessDocument: TaxonomyTag[] = <TaxonomyTag[]>[<TaxonomyTag>('errGeneral')]
  switch (originalBusinessDocumentBeingVoidedKind) {
    case BusinessDocumentKind.INVOICE:
      if (!originalBusinessDocumentBeingVoidedTaxonomyTags || originalBusinessDocumentBeingVoidedTaxonomyTags.length < 1) {
        resultTagsForVoidedBusinessDocument = <TaxonomyTag[]>[TaxonomyTag.VOIDING_CREDIT_NOTE]
      } else if (originalBusinessDocumentBeingVoidedTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_INVOICE) !== -1) {
        resultTagsForVoidedBusinessDocument = <TaxonomyTag[]>[TaxonomyTag.VOIDING_CREDIT_NOTE]
      } else if (originalBusinessDocumentBeingVoidedTaxonomyTags.indexOf(TaxonomyTag.CORRECTIVE_INVOICE) !== -1) {
        resultTagsForVoidedBusinessDocument = <TaxonomyTag[]>[TaxonomyTag.VOIDING_CREDIT_NOTE]
      } else if (originalBusinessDocumentBeingVoidedTaxonomyTags.indexOf(TaxonomyTag.INTERMEDIATE_INVOICE) !== -1) {
        resultTagsForVoidedBusinessDocument = <TaxonomyTag[]>[TaxonomyTag.VOIDING_CREDIT_NOTE]
      } else if (originalBusinessDocumentBeingVoidedTaxonomyTags.indexOf(TaxonomyTag.DEPOSIT_INVOICE) !== -1) {
        resultTagsForVoidedBusinessDocument = <TaxonomyTag[]>[TaxonomyTag.VOIDING_CREDIT_NOTE]
      } else if (originalBusinessDocumentBeingVoidedTaxonomyTags.indexOf(TaxonomyTag.BALANCE_INVOICE) !== -1) {
        resultTagsForVoidedBusinessDocument = <TaxonomyTag[]>[TaxonomyTag.VOIDING_CREDIT_NOTE]
      } else {
        /* console.error('getTagsForVoidedBusinessDocument unknown case 1', 'originalBusinessDocumentBeingVoidedKind', originalBusinessDocumentBeingVoidedKind, 'originalBusinessDocumentBeingVoidedTaxonomyTags', originalBusinessDocumentBeingVoidedTaxonomyTags) */
        resultTagsForVoidedBusinessDocument = <TaxonomyTag[]>[<TaxonomyTag>('err1')] // aka error, ask the user to choose manually from all forms of Credit Notes
      }
      break
    case BusinessDocumentKind.CREDITNOTE:
      if (!originalBusinessDocumentBeingVoidedTaxonomyTags || originalBusinessDocumentBeingVoidedTaxonomyTags.length < 1) {
        resultTagsForVoidedBusinessDocument = <TaxonomyTag[]>[TaxonomyTag.CORRECTIVE_INVOICE]
      } else if (originalBusinessDocumentBeingVoidedTaxonomyTags.indexOf(TaxonomyTag.COMMERCIAL_CREDIT_NOTE) !== -1) {
        resultTagsForVoidedBusinessDocument = <TaxonomyTag[]>[TaxonomyTag.CORRECTIVE_INVOICE]
      } else if (originalBusinessDocumentBeingVoidedTaxonomyTags.indexOf(TaxonomyTag.VOIDING_CREDIT_NOTE) !== -1) {
        resultTagsForVoidedBusinessDocument = <TaxonomyTag[]>[TaxonomyTag.CORRECTIVE_INVOICE]
      } else {
        /* console.error('getTagsForVoidedBusinessDocument unknown case 2', 'originalBusinessDocumentBeingVoidedKind', originalBusinessDocumentBeingVoidedKind, 'originalBusinessDocumentBeingVoidedTaxonomyTags', originalBusinessDocumentBeingVoidedTaxonomyTags) */
        resultTagsForVoidedBusinessDocument = <TaxonomyTag[]>[<TaxonomyTag>('err2')] // aka error, ask the user to choose manually from all forms of Credit Notes
      }
      break
    default:
      /* console.error('getTagsForVoidedBusinessDocument unknown case 3', 'originalBusinessDocumentBeingVoidedKind', originalBusinessDocumentBeingVoidedKind, 'originalBusinessDocumentBeingVoidedTaxonomyTags', originalBusinessDocumentBeingVoidedTaxonomyTags) */
      resultTagsForVoidedBusinessDocument = <TaxonomyTag[]>[<TaxonomyTag>('err3')]
      break
  }
  /*console.log('getTagsForVoidedBusinessDocument result for', 'originalBusinessDocumentBeingVoidedKind', originalBusinessDocumentBeingVoidedKind,
    'originalBusinessDocumentBeingVoidedTaxonomyTags', originalBusinessDocumentBeingVoidedTaxonomyTags,
    'getTagsForVoidedBusinessDocument result =', resultTagsForVoidedBusinessDocument)*/

  return resultTagsForVoidedBusinessDocument
}

/**
 * Returns the tags for the intermediate or balance business document :
 *  - checks if the originalBusinessDocumentKind is BusinessDocumentKind.INVOICE,
 *  - then checks for specific tags (TaxonomyTag.DEPOSIT_INVOICE or TaxonomyTag.INTERMEDIATE_INVOICE).
 *  - Depending on the tag and the accumulated percentage, it either returns INTERMEDIATE_INVOICE or BALANCE_INVOICE.
 *  - If none of the expected tags are found, it returns the originalBusinessDocument TaxonomyTags.
 * @param currentBusinessDocument
 * @param allBusinessDocuments
 */
export function getTagsForIntermediateOrBalanceBusinessDocument(/*originalBusinessDocumentKind: BusinessDocumentKind, originalBusinessDocumentTaxonomyTags: TaxonomyTag[], accumulatedPercentage: Decimal,*/ currentBusinessDocument: BusinessDocument, allBusinessDocuments: BusinessDocument[]): TaxonomyTag[] {
  /* console.warn('getTagsForIntermediateOrBalanceBusinessDocument') */
  // // if (originalBusinessDocumentKind === BusinessDocumentKind.INVOICE && originalBusinessDocumentTaxonomyTags.includes(TaxonomyTag.PARTIAL_BUSINESS_DOCUMENT)) {

  //
  //     let newTags: TaxonomyTag[] = deepClone(originalBusinessDocumentTaxonomyTags);
  //
  //     // Replace DepositInvoice with IntermediateInvoice if accumulatedPercentage < 100
  //     // or replace it with BalanceInvoice if accumulatedPercentage >= 100
  //     if (newTags.includes(TaxonomyTag.DEPOSIT_INVOICE)) {
  //         newTags[newTags.indexOf(TaxonomyTag.DEPOSIT_INVOICE)] = accumulatedPercentage.greaterThanOrEqualTo(new Decimal(100)) ? TaxonomyTag.BALANCE_INVOICE : TaxonomyTag.INTERMEDIATE_INVOICE;
  //     }
  //     // Replace IntermediateInvoice with BalanceInvoice if accumulatedPercentage >= 100
  //     else if (newTags.includes(TaxonomyTag.INTERMEDIATE_INVOICE)) {
  //         if (accumulatedPercentage.abs().greaterThanOrEqualTo(new Decimal(100))) {
  //             newTags[newTags.indexOf(TaxonomyTag.INTERMEDIATE_INVOICE)] = TaxonomyTag.BALANCE_INVOICE;
  //         }
  //     }
  //

  //
  //     return newTags;
  // // } else {
  // //     // If the originalBusinessDocumentKind is not INVOICE or the TaxonomyTag array does not include PARTIAL_BUSINESS_DOCUMENT, return the original array
  // //     // Since this is a balance invoice generation calling, originalBusinessDocumentKind is supposed to be INVOICE

  // //     return originalBusinessDocumentTaxonomyTags;
  // // }
  const res = getBusinessDocumentUpdatedInstallmentTaxonomyTags(currentBusinessDocument, allBusinessDocuments)

  return res.newTaxonomyTagsForBusinessDocument
}


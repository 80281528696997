
/**
 * Returns the number of days overdue for a given due date and timezone
 * Notes :
 *  - The due date is converted to the user's timezone
 *  - The difference between the due date and today's date is calculated in days
 *  - first parse the dueDate string into a Date object in UTC using new Date(dueDate) and toUTCString() (Date objects in JavaScript are UTC internally)
 *  - We convert the UTC date to the specified timezone using toLocaleString('en-US', options).
 *  - We also use the options object with the timeZoneIANACode parameter when getting today's date, to make sure it's in the correct timezone.
 *  - A negative value if the task is not yet overdue.
 *  - A positive value if the task is overdue.
 *  - Zero if the due date is today according to the user's timezone.
 * @param dueDate
 * @param timeZoneIANACode
 * @param now
 */

export function getDaysOverdueAccordingToUserTimeZone(
  dueDate: string,
  timeZoneIANACode: string,
  now: Date = new Date(), // Default to the current time if no time is provided
): number {
  if (!timeZoneIANACode || timeZoneIANACode === '') {
    /* console.error('No timeZoneIANACode provided. Using the system timezone instead.') */
    throw new Error('Missing Timezone')
  }

  const dueDateObj: Date = new Date(dueDate)
  const dueDateInUTC: Date = new Date(Date.UTC(
    dueDateObj.getUTCFullYear(),
    dueDateObj.getUTCMonth(),
    dueDateObj.getUTCDate(),
    dueDateObj.getUTCHours(),
    dueDateObj.getUTCMinutes(),
    dueDateObj.getUTCSeconds(),
    dueDateObj.getUTCMilliseconds(),
  ))

  const options = { timeZone: timeZoneIANACode }
  const today: Date = new Date(now.toLocaleString('en-US', options))

  const isToday: boolean = dueDateInUTC.toISOString().substring(0, 10) === today.toISOString().substring(0, 10)

  const timeDiff: number = today.getTime() - dueDateInUTC.getTime()
  const daysDiff: number = Math.floor(timeDiff / (1000 * 3600 * 24))

  if (isToday) {
    return 0
  } else {
    return daysDiff
  }
}



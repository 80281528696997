<script lang="ts">
    import { WorkspaceBankConfig } from '../models/workspace-bank-config'
    import { t } from '../../core-app/lib/i18n/i18nextWrapper.js'
    import { copyToClipboard } from '../../core-app/util/copy-text-utils'

    export let localBankConfig: WorkspaceBankConfig
    export let hasChanged: boolean
    export let formattedIBAN: string

    const focus = {
      name: false,
      iban: false,
      bic: false
    }

    const setFocus = (key:string) => {
      focus[key] = true
    }

    function handleInput() {
      hasChanged = true
    }
</script>

<div class="flex flex-col sm:px-16 py-6 border-t border-zinc-200" on:input={handleInput}>
    <p class="text-sm py-6 aside-note">{t('editBank.subHeadingBankDetails')}</p>

    {#if localBankConfig?.selectedAccountName}
        <div class="flex items-center space-x-4 my-2 mt-6 mb-4">
            <div class="block font-medium text-gray-700 w-36">{t('editBank.bankAccountName')}</div>
            <div>{localBankConfig.selectedAccountName}</div>
        </div>
    {/if}

    <div class="flex items-center space-x-4 my-2">
        <label class="block font-medium text-gray-700 w-36"
               for="bank-name">{t('editBank.bankNameLabel')}</label>
        <div class="mt-1 flex-grow relative">
            <input bind:value={localBankConfig.selectedAccountBankName}
                   class="block w-full rounded-md focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-black"
                   disabled={!!(localBankConfig.bankConnectionCurrentlyKnownActive) && !!(localBankConfig.selectedAccountBankName)}
                   id="bank-name"
                   name="bank-name"
                   placeholder={t('editBank.bankNamePlaceholder')}
                   readonly={!!(localBankConfig.bankConnectionCurrentlyKnownActive) && !!(localBankConfig.selectedAccountBankName)}
                   type="text"
                   on:input={() => setFocus('name')}>
            {#if localBankConfig.bankConnectionCurrentlyKnownActive && localBankConfig.selectedAccountBankName}
                <button class="absolute top-1/2 right-1.5 -translate-y-1/2 inline-flex w-20 rounded border border-transparent bg-black px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                        on:click={() => copyToClipboard(`${localBankConfig.selectedAccountBankName}`, t('entities.bankAccount'))}
                        type="button">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                         class="mr-2">
                        <path d="M10.5 1.5h-6V3h3A1.5 1.5 0 019 4.5v3h1.5v-6zm-9 9h6v-6h-6v6zm9-1.5H9v1.5A1.5 1.5 0 017.5 12h-6A1.5 1.5 0 010 10.5v-6A1.5 1.5 0 011.5 3H3V1.5A1.5 1.5 0 014.5 0h6A1.5 1.5 0 0112 1.5v6A1.5 1.5 0 0110.5 9z"
                              fill="#FFFFFF"></path>
                    </svg>
                    {t('editBank.copyButton')}
                </button>
            {/if}
            {#if focus.name && !localBankConfig?.selectedAccountBankName}
                <p class="mt-2 text-sm text-cabaret"
                   id="bank-name-error">{t('editBank.addBankName')}</p>
            {/if}
        </div>
    </div>
    <div class="flex items-center space-x-4 my-2">
        <label class="block font-medium text-gray-700 w-36"
               for="bank-iban-number-one">{t('editBank.bankIbanNumberLabel')}</label>
        <div class="mt-1 flex-grow relative">
            {#if localBankConfig.bankConnectionCurrentlyKnownActive && localBankConfig.selectedAccountBankIBAN}
                <input type="text"
                       name="bank-iban-number-one"
                       id="bank-iban-number-one"
                       disabled={!!(localBankConfig.bankConnectionCurrentlyKnownActive) && !!(localBankConfig?.selectedAccountBankIBAN)}
                       readonly={!!(localBankConfig.bankConnectionCurrentlyKnownActive) && !!(localBankConfig?.selectedAccountBankIBAN)}
                       class="block w-full rounded-md focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-black"
                       placeholder={t('editBank.bankIbanNumberLabel')}
                       bind:value={formattedIBAN}>
            {:else}
                {#if !!localBankConfig}
                    <input type="text"
                           name="bank-iban-number-two"
                           id="bank-iban-number-two"
                           class:error={localBankConfig?.selectedAccountBankIBAN === ''}
                           class="block w-full rounded-md focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-black"
                           placeholder={t('editBank.bankIbanNumberLabel')}
                           bind:value={localBankConfig.selectedAccountBankIBAN}
                           on:input={() => setFocus('iban')}>
                {:else }
                    <p>no local bank config</p>
                {/if}
            {/if}
            {#if localBankConfig.bankConnectionCurrentlyKnownActive && localBankConfig?.selectedAccountBankIBAN}
                <button class="absolute top-1/2 right-1.5 -translate-y-1/2 inline-flex w-20 rounded border border-transparent bg-black px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                        on:click={() => copyToClipboard(`${localBankConfig.selectedAccountBankIBAN}`, t('invoices.createInvoice.beneficiaryIBANLabel'))}
                        type="button">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                         class="mr-2">
                        <path d="M10.5 1.5h-6V3h3A1.5 1.5 0 019 4.5v3h1.5v-6zm-9 9h6v-6h-6v6zm9-1.5H9v1.5A1.5 1.5 0 017.5 12h-6A1.5 1.5 0 010 10.5v-6A1.5 1.5 0 011.5 3H3V1.5A1.5 1.5 0 014.5 0h6A1.5 1.5 0 0112 1.5v6A1.5 1.5 0 0110.5 9z"
                              fill="#FFFFFF"></path>
                    </svg>
                    {t('editBank.copyButton')}
                </button>
            {/if}
            {#if focus.iban && !localBankConfig?.selectedAccountBankIBAN}
                <p class="mt-2 text-sm text-cabaret"
                   id="bank-iban-error">{t('editBank.addIBAN')}</p>
            {/if}
        </div>
    </div>
    <div class="flex items-center space-x-4 my-2">
        <label class="block font-medium text-gray-700 w-36"
               for="bank-bic-number">{t('editBank.bankBICLabel')}</label>
        <div class="mt-1 relative flex-grow">
            {#if !!localBankConfig}
                <input type="text"
                       name="bank-bic-number"
                       id="bank-bic-number"
                       class:error={localBankConfig?.selectedAccountBankBIC === ''}
                       class="block w-full rounded-md focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-black"
                       placeholder="XXXXXXX"
                       on:input={handleInput}
                       bind:value={localBankConfig.selectedAccountBankBIC}
                       on:input={() => setFocus('bic')}
                >
            {:else }
                <p>no local bank config</p>
            {/if}
            {#if focus.bic && !localBankConfig?.selectedAccountBankBIC}
                <p class="mt-2 text-sm text-cabaret"
                   id="bank-bic-number-error">{t('editBank.addBICCode')}</p>
            {/if}
        </div>
    </div>
</div>
<style lang="postcss">
    .aside-note {
        @apply bg-creamBrulee border border-warmGray rounded-md px-6 py-4;
        border-color: hsl(47, 65%, 84%);
        background-color: hsl(47, 87%, 94%);
    }
</style>

<script lang="ts">
  import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
  import { createEventDispatcher, onDestroy, onMount } from 'svelte'
  import { get } from 'svelte/store'
  import { BusinessDocument } from '../../../models/business-document'
  import { BusinessDocumentKind } from '../../../enums/business-document-kind.js'
  import { VoxyPreferencesStore } from '../../../stores/voxy-preference.store'
  import { NumberingScheme } from '../../../models/voxy-preferences'
  import {
    deleteDoubleAccoladesAndReplacePlaceholdersWithDateValues,
    replaceDefaultFirstLetterOfStringFromServerWithTranslatedOne,
    replaceNumberOfDigitsWithXs
  } from '../../../utils/numbering-utils'

  import { ProfileStore } from '../../../../core-app/stores/profile.store'
  import { eventsManager } from '../../../../core-app/events/event-manager'
  import { EventType } from '../../../../core-app/events/event-type'
  import type { DundyEvent } from '../../../../dundy-app/events/dundy-event'

  /** Export let declarations **/
  export let businessDocument: BusinessDocument
  export let documentKind: BusinessDocumentKind

  /** Local declarations **/
  let isBusinessDocumentNumberingWindowLoading: boolean = false
  let numberingSchemesByBusinessDocument: Map<BusinessDocumentKind, NumberingScheme>
  let invoiceTemplatePreview: string
  let invoiceTemplateValue: string
  let scheme: NumberingScheme
  let unSubscribeOnVoxyPreferences: Function
  const dispatch = createEventDispatcher()


  $: if (!!$VoxyPreferencesStore?.numberingSchemesByBusinessDocument) {
    numberingSchemesByBusinessDocument = get(VoxyPreferencesStore)?.numberingSchemesByBusinessDocument
    updateAllVariablesFromCurrentNumberingSchemeByBusinessDocument(numberingSchemesByBusinessDocument, documentKind)
  }

  /**
   * This function is used to activate the invoice numbering window.
   */
  function activateInvoiceNumberingWindow() {
    isBusinessDocumentNumberingWindowLoading = true
    setTimeout(() => {
      dispatch('activateInvoiceNumberingWindow')
    }, 200)
  }

  /**
   * This function is used to update all variables from current numbering scheme by business document.
   * @param numberingSchemesByBusinessDocument
   * @param documentKind
   */
  function updateAllVariablesFromCurrentNumberingSchemeByBusinessDocument(numberingSchemesByBusinessDocument: Map<BusinessDocumentKind, NumberingScheme>, documentKind: BusinessDocumentKind) {
    scheme = numberingSchemesByBusinessDocument[documentKind]
    const numberingSchemeSettings = scheme.currentNumberingScheme
    const documentNumberTemplate = replaceDefaultFirstLetterOfStringFromServerWithTranslatedOne(numberingSchemeSettings.documentNumberTemplate, get(ProfileStore).lang)
    const sequenceNumberOfDigits = numberingSchemeSettings.sequenceNumberOfDigits

    let nextSequenceNumber
    if (!numberingSchemeSettings.continueExistingDocumentNumberSequence && scheme.noAllocationSinceNumberingSchemeChanged) {
      nextSequenceNumber = numberingSchemeSettings.initialDocumentSequenceNumber
    } else {
      nextSequenceNumber = scheme.lastDocumentSequenceNumbering.number + 1
    }

    invoiceTemplatePreview = deleteDoubleAccoladesAndReplacePlaceholdersWithDateValues(documentNumberTemplate, get(ProfileStore).lang.split('-')[0].toLocaleLowerCase()) + replaceNumberOfDigitsWithXs(sequenceNumberOfDigits)
    invoiceTemplateValue = deleteDoubleAccoladesAndReplacePlaceholdersWithDateValues(documentNumberTemplate, get(ProfileStore).lang.split('-')[0].toLocaleLowerCase()) + String(nextSequenceNumber).padStart(sequenceNumberOfDigits, '0')
  }

  onMount(() => {
    unSubscribeOnVoxyPreferences = eventsManager.on(EventType.VOXY_PREFERENCES_FETCHED, (e: DundyEvent<any>) => {
      numberingSchemesByBusinessDocument = e.data.numberingSchemesByBusinessDocument
      updateAllVariablesFromCurrentNumberingSchemeByBusinessDocument(numberingSchemesByBusinessDocument, documentKind)
    }, 'BusinessDocumentNumber.svelte')
  })

  onDestroy(() => {
    if (!!unSubscribeOnVoxyPreferences) {
      unSubscribeOnVoxyPreferences()
      unSubscribeOnVoxyPreferences = null
    }
  })

</script>


<div class="col-span-full my-4">
    <div class="flex justify-between items-center">
        <div class="flex flex-col">
            <div class="flex flex-row items-center">
                <p class="text-s">
                    {#if businessDocument?.businessDocumentKind === BusinessDocumentKind.INVOICE}
                        {t('invoices.createInvoice.automaticNumberingLabel')}
                    {:else if businessDocument?.businessDocumentKind === BusinessDocumentKind.CREDITNOTE}
                        {t('invoices.createCreditNote.automaticNumberingLabel')}
                    {:else if businessDocument?.businessDocumentKind === BusinessDocumentKind.QUOTE}
                        {t('quotes.createQuote.automaticNumberingLabel')}
                    {/if}
                </p>
                <div class="relative flex flex-col items-center group ml-1 whitespace-normal">
                    <i class="icon-info relative text-lg text-loblolly"></i>
                    <div class="absolute bottom-0 flex-col items-center hidden mb-6 group-hover:flex">
                        <span class="relative w-52 z-10 px-6 py-4 text-xs text-black font-normal whitespace-no-wrap bg-barleyWhite border border-goldSand rounded-md">{t(`automaticNumbering.${businessDocument?.businessDocumentKind}.tipAboutFinalInvoiceNumber`)}</span>
                        <div class="w-3 h-3 -mt-2 rotate-45 border border-goldSand bg-barleyWhite"></div>
                    </div>
                </div>
            </div>
            {#if invoiceTemplatePreview}
                <h4 class="font-medium">{@html invoiceTemplatePreview}</h4>
                <p class="text-s">{@html t(`automaticNumbering.${documentKind}.nextInvoiceNumberPreview`, { previewFinalBusinessDocumentNumber: invoiceTemplateValue })}</p>
            {:else}
                <div class="flex flex-row items-center ml-4">
                    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-dundyOrange"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            {/if}
        </div>

        <button class="inline-flex h-8 items-center justify-center rounded border border-gray-300 bg-white px-2.5 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-dundyOrange focus:ring-offset-2"
                on:click={() => {
                  isBusinessDocumentNumberingWindowLoading = !isBusinessDocumentNumberingWindowLoading
                  activateInvoiceNumberingWindow()
                }}
                type="button">
            {#if !isBusinessDocumentNumberingWindowLoading}
                {t('invoicingAppEdit.automaticNumbering.modifyButtonLabel')}
            {:else}
                <svg class="animate-spin h-5 w-5 text-dundyOrange"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                            stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            {/if}
        </button>
    </div>
</div>

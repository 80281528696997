import type { TodoItemMessageKind } from './todo-list-item'

export enum TodoUIDunningEscalationTabs {
  INFORMATION = 'Information',
  REMINDER = 'Reminder',
  WARNING = 'Warning',
  LEGAL = 'Legal'
}

export type EscalationTabToMessageKind = {
  [key in TodoUIDunningEscalationTabs]: TodoItemMessageKind;
};
import { writable } from 'svelte/store'
import { QuotesViewListOption } from '../models/quote-view-list-options'

const getQuotesListViewChoiceInitialValue = (): QuotesViewListOption => {
  const existingValue: string = localStorage.getItem('quotesListViewChoice')
  const defaultValue = QuotesViewListOption.ALL
  if (!existingValue || existingValue === 'undefined') {
    return defaultValue
  }
  if (existingValue === 'null') {
    return defaultValue
  }
  if (existingValue === '') {
    return defaultValue
  }
  if (existingValue === '{}') {
    return defaultValue
  }
  
  return <QuotesViewListOption>existingValue
}
export let quotesListViewChoice = writable(getQuotesListViewChoiceInitialValue())
let isQuotesListViewChoiceSubscriptionDefined: boolean = false
// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
quotesListViewChoice.subscribe(val => {
  if (!isQuotesListViewChoiceSubscriptionDefined) {
    /* console.log('invoicesListViewChoice subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently') */
    isQuotesListViewChoiceSubscriptionDefined = true
    
    return // we avoid the .subscribe() execution at the subscription occurrence
  }
  localStorage.setItem('quotesListViewChoice', val)
})

import type { CellValueChangeItem } from '../models/cell-value-change-item'
import { DunningInvoicesStore } from '../stores/dunning-invoices.store'
import type { ValidationLevel } from '../models/validation-level'
import { DunningInvoicesHelper } from '../helpers/dunning-invoices-helper'
import { FeedbackStore } from '$core/stores/feedback.store'
import { FeedbackEntity } from '$core/enums/feedback-enums'
import { get } from 'svelte/store'
import type { HeaderCheckboxSelectionCallbackParams, ICellEditorParams } from 'ag-grid-community'
import { DocumentSourceKind } from '$src/order-to-cash-lib/models/document-source-kind'
import type Invoice from '../models/invoice'
import type { Feedback } from '$core/models/feedback'
import type { FeedbackDetails } from '$core/models/feedback'
import { Column } from 'ag-grid-community'

class AGGridCommonServiceClass {

  /**
     * Update the DunningInvoicesStore from the event "cellValueChanged"
     * @param allChanges
     */
  updateInvoicesStoreFromEventCellValueChanged(allChanges: Array<CellValueChangeItem>) {
    DunningInvoicesStore.update((invoices: Invoice[]) => {
      for (let e of allChanges) {
        let invoiceToUpdate: Invoice = invoices.find((item: Invoice): boolean => item.dataId === e.rowData.dataId)
        if (!e.editing) {
          invoiceToUpdate[e.colId] = e.newValue
          const now: Date = new Date()
          invoiceToUpdate.modified = Math.round(now.getTime() / 1000)
          invoiceToUpdate.modifiedRFC3339 = now.toISOString()
        }
      }
      
      return invoices
    })
  }
  /**
     * Update the DunningInvoicesStore from the event "rowValueChanged"
     * @param invoice
     * @param property
     * @param level
     */
  propertyHasFeedbackLevel(invoice: any, property: string, level: ValidationLevel) {
    const feedbackStore: Map<FeedbackEntity, Feedback> = get(FeedbackStore)
    
    const isNewInvoice = DunningInvoicesHelper.isNewInvoice(invoice)
    
    
    let feedbackDetails: FeedbackDetails[] = feedbackStore.get(FeedbackEntity.INVOICES).feedbackDetails
    
    let feedbackForPropertyFound: FeedbackDetails
    let feedbackForPropertyLevel: boolean
    if (feedbackDetails && feedbackDetails.length) {
      feedbackForPropertyFound = feedbackDetails.find(inv => inv.itemId === invoice.dataId)
      
      if (feedbackForPropertyFound) {
        feedbackForPropertyLevel = feedbackForPropertyFound.detailsOnProperties.get(property)?.validationLevel === level.valueOf()
      }
    }
    
    return !isNewInvoice && feedbackForPropertyLevel
  }
  /**
     * Check if the column is the first column
     * @param params
     */
  isFirstColumn(params: HeaderCheckboxSelectionCallbackParams): boolean {
    let displayedColumns: Column<any>[] = params.api.getAllDisplayedColumns()
    return displayedColumns[0] === params.column
  }
  /**
     * Check if the Cell is Editable (VOXY or DUNDY)
     * @param params
     */
  isAnEditableCell(params: ICellEditorParams): boolean {
    const anExternallySourcedInvoice: boolean = !!params.data.documentSource
            && !!params.data.documentSource.sourceKind
            && (params.data.documentSource.sourceKind === DocumentSourceKind.FROMVOXYINVOICING
                || params.data.documentSource.sourceKind === DocumentSourceKind.UNKNOWNSOURCE)
    const nonFinalizedInvoice: boolean = !params.data.completed
    
    return nonFinalizedInvoice && !anExternallySourcedInvoice
  }
}
export const agGridCommonService: AGGridCommonServiceClass = new AGGridCommonServiceClass()

<script lang="ts">
    import Invoice from '../../../../models/invoice'
    import { formatToShortDate } from '../../../../../core-app/util/date-utils'

    export let classes: string
    export let invoice: Invoice

</script>

<div class="whitespace-nowrap text-s text-gray-900 {classes}">
    <span>{formatToShortDate(new Date(invoice.dateDue))}</span>
</div>
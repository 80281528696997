import type Invoice from '../../../dundy-app/models/invoice'
import { InvoiceStatus } from '$dundy/enums/invoice-status'
import type { Customer } from '$crm/models/customer'
import type { AgingBalanceArray, AgingBalanceContent, AgingBalanceRawMap, AgingBalanceRow } from '$dundy/models/aging-balance'
import { getDaysOverdueAccordingToUserTimeZone } from '$dundy/services/days-overdue.pure-functions'
import type { TimeSlotCategory } from '$dundy/models/aging-balance'
import type { TimeSlotCategoriesDefinition } from '$dundy/models/aging-balance'
import { DunningBusinessDocumentKind } from '$dundy/enums/dunning-business-document-kind'


/**
 * Calculates the aging balance for a list of client ID and a list of time slot categories (i.e. the columns in the aging balance table)
 * returns an aging balance array (i.e. a list of rows in the aging balance table)
 * @param mapOfClientIdAndSlotCategories
 */
export function calculateTotalsForAllClients(mapOfClientIdAndSlotCategories: AgingBalanceRawMap): AgingBalanceContent {
  const totals: AgingBalanceContent = {
    outstandingAmount: 0,
    overdueMoreThan90dInvoicesAmount: 0,
    overdue61And90dInvoicesAmount: 0,
    overdue31And60dInvoicesAmount: 0,
    overdue30And1dInvoicesAmount: 0,
    due0And30dInvoicesAmount: 0,
    due31And60dInvoicesAmount: 0,
    due61And90dInvoicesAmount: 0,
    dueMoreThan90dInvoicesAmount: 0
  }

  Object.values(mapOfClientIdAndSlotCategories).forEach((slotCategories: Record<string, number>): void => {
    Object.entries(slotCategories).forEach(([key, value]): void => {
      if (!key.startsWith('outstanding')) {
        if (key.startsWith('overdueMoreThan90dInvoicesAmount')) {
          totals.overdueMoreThan90dInvoicesAmount += value
        } else if (key.startsWith('overdue61And90d')) {
          totals.overdue61And90dInvoicesAmount += value
        } else if (key.startsWith('overdue31And60d')) {
          totals.overdue31And60dInvoicesAmount += value
        } else if (key.startsWith('overdue30And1d')) {
          totals.overdue30And1dInvoicesAmount += value
        } else if (key.startsWith('due0And30d')) {
          totals.due0And30dInvoicesAmount += value
        } else if (key.startsWith('due31And60d')) {
          totals.due31And60dInvoicesAmount += value
        } else if (key.startsWith('due61And90d')) {
          totals.due61And90dInvoicesAmount += value
        } else if (key.startsWith('dueMoreThan90d')) {
          totals.dueMoreThan90dInvoicesAmount += value
        }
      }
      totals.outstandingAmount += value
    })
  })
  
  return totals
}


/**
 * Transforms the map of invoices into an array of objects with the total amount
 * @param map
 * @param customerStore
 */
export function transformMapIntoArrayWithTotalDueOverdueAndCustomerInfo(map: AgingBalanceRawMap, customerStore: Customer[]): AgingBalanceArray {
  if (!map || typeof map !== 'object') {
    return []
  }
  
  return Object.entries(map).map(([id, item]): AgingBalanceRow => {
    const totalDue: number = [
      Number(item.due0And30dInvoicesAmount),
      Number(item.due31And60dInvoicesAmount),
      Number(item.due61And90dInvoicesAmount),
      Number(item.dueMoreThan90dInvoicesAmount)
    ].reduce((acc: number, curr: number) => acc + (isNaN(curr) ? 0 : curr), 0)

    const totalOverdue: number = [
      Number(item.overdue30And1dInvoicesAmount),
      Number(item.overdue31And60dInvoicesAmount),
      Number(item.overdue61And90dInvoicesAmount),
      Number(item.overdueMoreThan90dInvoicesAmount)
    ].reduce((acc: number, curr: number) => acc + (isNaN(curr) ? 0 : curr), 0)

    const total: number = totalDue + totalOverdue

    return <AgingBalanceRow>{
      customer: customerStore.find((customer: Customer): boolean => customer.company.companyId === id),
      total,
      totalOverdue,
      totalDue,
      due0And30dInvoicesAmount: item.due0And30dInvoicesAmount ? item.due0And30dInvoicesAmount : 0,
      due31And60dInvoicesAmount: item.due31And60dInvoicesAmount ? item.due31And60dInvoicesAmount : 0,
      due61And90dInvoicesAmount: item.due61And90dInvoicesAmount ? item.due61And90dInvoicesAmount : 0,
      dueMoreThan90dInvoicesAmount: item.dueMoreThan90dInvoicesAmount ? item.dueMoreThan90dInvoicesAmount : 0,
      overdue30And1dInvoicesAmount: item.overdue30And1dInvoicesAmount ? item.overdue30And1dInvoicesAmount : 0,
      overdue31And60dInvoicesAmount: item.overdue31And60dInvoicesAmount ? item.overdue31And60dInvoicesAmount : 0,
      overdue61And90dInvoicesAmount: item.overdue61And90dInvoicesAmount ? item.overdue61And90dInvoicesAmount : 0,
      overdueMoreThan90dInvoicesAmount: item.overdueMoreThan90dInvoicesAmount ? item.overdueMoreThan90dInvoicesAmount : 0
    }
  })
    .sort((a, b) => {
      // Ensure that the customers exist and have a label. If not, handle accordingly.
      const labelA = a.customer && a.customer.company && a.customer.company.formalName ? a.customer.company.formalName : ''
      const labelB = b.customer && b.customer.company && b.customer.company.formalName ? b.customer.company.formalName : ''

      if (labelA < labelB) return -1
      if (labelA > labelB) return 1
      
      return 0
    })
}


/**
 * Returns an object customer ToID as key and the value an object wit label
 * of the category as key and the value the amount of the accumulated invoices in that category.
 * @param invoices
 * @param timeZoneIANACode
 * @param timeslotCategoriesAccordingToDaysDue
 */
export function mapInvoicesAccordingToDualKeyToIDAndTimeSlotCategoryWithAmounts(
  invoices: Invoice[],
  timeZoneIANACode: string,
  timeslotCategoriesAccordingToDaysDue: TimeSlotCategoriesDefinition,
): AgingBalanceRawMap {
  return invoices.reduce((acc: Record<string, Record<string, number>>, invoice: Invoice): Record<string, Record<string, number>> => {
    if (invoice.completed
            && invoice.status === InvoiceStatus.OUTSTANDING
            && !invoice.deleted
            && invoice.documentKind === DunningBusinessDocumentKind.INVOICE
            && invoice.isTracked ) {
      if (!acc[invoice.toId]) {
        acc[invoice.toId] = {}
      }
      const timeSlotCategory: string = getWhichCategoryAnInvoiceBelongsTo(invoice.dateDue, timeZoneIANACode, timeslotCategoriesAccordingToDaysDue)
      if (!acc[invoice.toId][timeSlotCategory]) {
        acc[invoice.toId][timeSlotCategory] = 0
      }
      acc[invoice.toId][timeSlotCategory] += invoice.amountIncludingTaxes
      
      return acc
    }
    
    return acc
  }, {})
}


/**
 * Map of time slot categories according to days due/overdue
 * The key is the label of the category, and the value is the number of days from now.
 * The number of days from now is negative for categories that are due in the future, and positive for categories that are overdue.
 */
export const timeslotCategoriesAccordingToDaysDue: TimeSlotCategoriesDefinition = <TimeSlotCategoriesDefinition>{
  timeSlotCategories: <TimeSlotCategory[]>[
        <TimeSlotCategory>{ label: 'dueMoreThan90dInvoicesAmount', nbDaysFromNow: -90 }, // due in more than 90 days
        <TimeSlotCategory>{ label: 'due61And90dInvoicesAmount', nbDaysFromNow: -60 }, // due 61-90 days from current time
        <TimeSlotCategory>{ label: 'due31And60dInvoicesAmount', nbDaysFromNow: -30 }, // due 31-60 days from current time
        <TimeSlotCategory>{ label: 'due0And30dInvoicesAmount', nbDaysFromNow: 0 }, // due today
        <TimeSlotCategory>{ label: 'overdue30And1dInvoicesAmount', nbDaysFromNow: 30 }, // overdue between 1 and 30 days
        <TimeSlotCategory>{ label: 'overdue31And60dInvoicesAmount', nbDaysFromNow: 60 }, // overdue by 31-60 days
        <TimeSlotCategory>{ label: 'overdue61And90dInvoicesAmount', nbDaysFromNow: 90 } // overdue by 61-90 days
  ],
  defaultTimeSlotCategoryLabel: 'overdueMoreThan90dInvoicesAmount' // overdue by more than 90 days
}


/**
 * Returns the category an invoice belongs to according to the days overdue
 * For each entry, check if the daysOverdue is less than or equal to the corresponding number of days for that category.
 * If it is, we return the category string.
 * If we've iterated through all categories and haven't returned a value yet, we return the default category 'dueMoreThan90dInvoicesAmount'.
 * @param dueDate
 * @param timeZoneIANACode
 * @param timeslotCategoriesAccordingToDaysDue
 * @param now
 */
export function getWhichCategoryAnInvoiceBelongsTo(
  dueDate: string,
  timeZoneIANACode: string,
  timeslotCategoriesAccordingToDaysDue: TimeSlotCategoriesDefinition,
  now?: Date,
): string {
  const daysDiff: number = getDaysOverdueAccordingToUserTimeZone(
    dueDate,
    timeZoneIANACode,
    now ? now : new Date(),
  )

  if (timeslotCategoriesAccordingToDaysDue.timeSlotCategories) {
    for (const timeSlotCategory of timeslotCategoriesAccordingToDaysDue.timeSlotCategories) {
      if (timeSlotCategory && timeSlotCategory.nbDaysFromNow !== undefined && timeSlotCategory.nbDaysFromNow !== null && daysDiff <= timeSlotCategory.nbDaysFromNow) {
        return <string>timeSlotCategory.label
      }
    }
  }

  return <string>timeslotCategoriesAccordingToDaysDue.defaultTimeSlotCategoryLabel
}



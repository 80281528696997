<script lang="ts">
    import { createEventDispatcher } from 'svelte'
    import Tooltip from './TextInputTooltip.svelte'

    const dispatch = createEventDispatcher()

    /**
     * Specify the input value.
     * Use `null` to denote "no value"
     * @type {null | number}
     */
    export let value = null

    /** Specify a name for the cypress selector */
    export let dataCy: string = ''

    /** Specify the label text */
    export let label: string = ''

    /** Helpers to set classes from component */
    let propClass = ''
    export { propClass as class }
    export let inputClass = ''
    export let inputStyle = ''

    /** Specify the step increment */
    export let step = 1
    /**
     * Specify the maximum value
     * @type {number}
     */
    export let max = undefined
    /**
     * Specify the minimum value
     * @type {number}
     */
    export let min = undefined

    /** Display Validation Tick */
    export let showValidationTick = true

    /** Specify the placeholder text */
    export let placeholder = ''

    /** Set to `true` to mark the field as required */
    export let required = false

    /** Specify the invalid state text */
    export let error: string = ''

    /** Set to `true` to indicate an invalid state */
    export let showError: boolean = false

    /** Set to `true` to show Prefix */
    export let showPrefix: boolean = false

    /** Set Prefix Text */
    export let prefixLabel: string = ''

    /**
     * Specify a name attribute for the input
     * @type {string}
     */
    export let name: any = undefined

    /** Set to `true` to disable the input */
    export let disabled: boolean = false

    /** Set an id for the input element */
    export let id: string = 'ccs-' + Math.random().toString(36)

    $: errorId = `error-${id}`

    /** Set to `true` to show tooltip */
    export const displayTooltipOnFocus: boolean = false

    /** Helper to set the tooltip position */
    export let tooltipPosition: 'left' | 'right' = 'left'

    export const delay: number = 500

    /** Helper to set the tooltip text */
    let displayTooltip

    /** Obtain a reference to the input HTML element
     * We use a ref to avoid using two-way binding with the value
     * The bind: directive automatically converts the input value to a string, even if the input type is set to "number".
     *
     * */
    export let ref = null

    function parse(raw) {
      return raw === '' ? null : Number(raw)
    }

    const onInput = e => {
      value = parse(e.target.value)
      dispatch('input', value)
    }

    function onChange({ target }) {
      dispatch('change', parse(target.value))
    }

</script>

<fieldset class={propClass}>
    <label class="block text-sm font-normal text-black"
           for={id}>{label}</label>
    <div class="mt-1.5 relative rounded-md {showPrefix ? 'prefix' : ''}">
        {#if showPrefix}
            <div class="input-prefix-wrapper">
                <span class="text-black text-xxs">{prefixLabel}</span>
            </div>
        {/if}
        <input {...$$restProps}
               aria-describedby="{showError ? errorId : undefined}"
               aria-invalid="{showError || undefined}"
               bind:this="{ref}"
               class={inputClass}
               data-cy="{dataCy}"
               data-invalid="{showError || undefined}"
               {disabled}
               id="{id}"
               max="{max}"
               min="{min}"
               {name}
               on:blur
               on:change="{onChange}"
               on:focus
               on:input="{onInput}"
               on:keydown
               on:keyup
               on:paste
               pattern="[0-9]*"
               placeholder="{placeholder}"
               required="{required}"
               step="{step}"
               style={inputStyle}
               type="number"
               value="{value ?? ''}"
        />
        {#if showError}
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                    <path d="m9.983.746.144.135 4.992 4.992a3.008 3.008 0 0 1 .135 4.11l-.135.144-4.992 4.992-.144.135a3.008 3.008 0 0 1-3.966 0l-.144-.135-4.992-4.992-.135-.144a3.008 3.008 0 0 1 0-3.966l.135-.144L5.873.881l.144-.135a3.008 3.008 0 0 1 3.966 0ZM7.048 1.838l-.114.104-4.992 4.992-.104.114c-.45.553-.45 1.351 0 1.904l.104.114 4.992 4.992a1.508 1.508 0 0 0 2.018.104l.114-.104 4.992-4.992.104-.114c.45-.553.45-1.351 0-1.904l-.104-.114-4.992-4.992-.114-.104a1.508 1.508 0 0 0-1.904 0Zm.962 8.412.102.007a.75.75 0 0 1 0 1.486l-.102.007H8l-.102-.007a.75.75 0 0 1 0-1.486L8 10.25h.01ZM8 4a.75.75 0 0 1 .743.648l.007.102v3.5a.75.75 0 0 1-1.493.102L7.25 8.25v-3.5A.75.75 0 0 1 8 4Z"
                          fill="#DE496D" fill-rule="nonzero"/>
                </svg>
            </div>
        {:else if showValidationTick && value && !showError}
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <div class="rounded-full w-4 h-4 bg-cruise p-0">
                    <i class="icon-done text-xl relative right-0.5 bottom-0.5"></i>
                </div>
            </div>
        {/if}
        <slot name="icon"/>
    </div>
    {#if showError}
        <p class="mt-2 text-sm text-cabaret" id="{name}-error">{error}</p>
    {/if}

    {#if displayTooltip}
        <Tooltip position={tooltipPosition}>
            <slot name="tooltip"></slot>
        </Tooltip>
    {/if}

    <slot/>
</fieldset>

<style lang="postcss">
    fieldset {
        @apply flex flex-col relative;
    }

    .prefix input {
        @apply overflow-hidden;
        height: 40px;
    }

    input {
        padding: 0.5rem 0.75rem;
        @apply w-full rounded sm:text-sm shadow-none border-loblolly;
    }

    input:focus {
        @apply ring-2 ring-dundyOrange outline-none;
        border: solid 1px transparent !important;
    }

    input:disabled {
        @apply bg-whisper text-paleSky;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .input-date-picker {
        @apply rounded !important;
        @apply border-loblolly text-black;
        height: 100%;
        width: 100%;
    }

    .input-date-picker:focus {
        @apply ring-1 ring-red-400 outline-none;
        border-color: var(--primary-color);
    }

    .input-prefix-wrapper {
        @apply pointer-events-none absolute right-0 top-0 items-center px-2.5 overflow-hidden border-l border-loblolly h-full text-center m-auto grid p-0;
        border-radius: 0 5px 5px 0;
        background: #e1e1;
        min-width: 40px;
        margin: 0 auto;
    }
</style>

<script lang="ts">
    import type { CashApplicationDeclaredEvent } from '../../../cash-application-app/models/cash-application-declared-model'
    import { CustomersStore } from '../../../crm-app/stores/customers.store'
    import { get } from 'svelte/store'
    import {
      formatCurrencyWithOptions
    } from '../../../voxy-app/utils/number-formatting-utils'
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import { DunningInvoicesStore } from '../../../dundy-app/stores/dunning-invoices.store'
    import type Invoice from '../../../dundy-app/models/invoice'
    import { findCustomerNameFromId } from '../../../crm-app/services/retrieve-customer-info.pure-functions'
    import { findInvoiceFromId } from '../../../dundy-app/services/invoices.pure-functions'
    import { Amount } from '../../../cash-application-app/models/amount-of-money-model'

    export let cashApplicationDeclaredEvent: CashApplicationDeclaredEvent

    /** Local declarations */
    let customerName: string
    let invoice: Invoice
    let formattedInvoiceAmount: string
    let formattedAllocatedAmount: string
    let dateIssuedFormatted: string

    $: customerName = findCustomerNameFromId(get(CustomersStore), cashApplicationDeclaredEvent.customerId)
    $: invoice = findInvoiceFromId(get(DunningInvoicesStore), cashApplicationDeclaredEvent.cashApplication.invoiceDataId)
    $: formattedInvoiceAmount = formatCurrencyWithOptions(invoice?.amountIncludingTaxes, t('locales'), invoice?.currency, true)
    $: formattedAllocatedAmount = formatCurrencyWithOptions(Amount.PrototypeToClass(cashApplicationDeclaredEvent?.cashApplication.appliedAmount).GetAmountScaledValue(), t('locales'), invoice?.currency, true)
    $: dateIssuedFormatted = invoice?.dateIssued
      ? new Intl.DateTimeFormat(t('locales'), {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }).format(new Date(invoice.dateIssued))
      : 'Invalid Date'

</script>

<div class="relative flex flex-col items-center py-4" data-ut-component="cash-applied-invoices-row">
    <div class="flex w-full items-center">
        <div class="flex flex-wrap content-center justify-center w-8 h-8 bg-black text-white rounded-md border border-solid border-black mr-3">
            <span class="text-xs">{customerName?.substring(0, 1).toUpperCase()}</span>
        </div>
        <div class="min-w-0 flex-1 text-sm">
            <div class="flex items-center justify-between">
                <p class="font-medium text-gray-700 truncate">{customerName}</p>
                <p class="truncate"><span class="font-medium">{formattedAllocatedAmount}</span> <span class="text-xxs">/ {formattedInvoiceAmount}</span></p>
            </div>
            <div class="flex items-center justify-between">
                <p class="text-gray-500 truncate">{invoice?.invoiceNumber}</p>
                <p class="text-gray-500">{dateIssuedFormatted}</p>
            </div>
        </div>
    </div>
</div>

<script lang="ts">
  import { onMount } from 'svelte'
  import { buildAutomaticAttachmentsList } from '../../helpers/email-attachments-helper'
  import { EmailAttachmentTag, type EmailAttachment } from '../../models/email-attachment'
  import type Invoice from '../../models/invoice'
  import Attachment from './Attachment.svelte'
  import UploadArea from './UploadArea.svelte'

  export let invoice: Invoice
  export let showAutomaticAttachments = true

  export let attachments = Array<EmailAttachment>()
  const handleFilesUpload = e => {
    attachments = [...attachments, ...e.detail.map(file => ({ ...file, tags: [EmailAttachmentTag.Manual] }))]
  }

  function removeAttachment(attachment) {
    attachments = [...attachments.filter(a => a.fileName !== attachment.fileName)]
  }

  onMount(() => {
    if (showAutomaticAttachments) {
      buildAutomaticAttachmentsList(invoice).then((result) => {
        attachments = result
      })
    } else {
      attachments = []
    }
  })

</script>

{#if !!attachments.length}
    {#each attachments as attachment}
      {#if (showAutomaticAttachments || !attachment.tags.includes(EmailAttachmentTag.Programmatic))}
        <Attachment {attachment} on:removeAttachment={() => removeAttachment(attachment)}/>
      {/if}
    {/each}
{/if}

<UploadArea on:uploaded={handleFilesUpload}/>

<script lang="ts">
    import type { Customer } from '../../models/customer'

    /** Exported declarations */
    export let classes: string
    export let customer: Customer

</script>

<div class="flex items-center {classes}">
    <div class=" flex flex-wrap content-center justify-center w-8 h-8 bg-black text-white rounded-md border border-solid border-black mr-3">
        <span>{customer?.company?.formalName?.substring(0, 1).toUpperCase()}</span>
    </div>
    <div class="w-8/12 flex items-center {classes}">
        <span class="truncate w-40 text-sm font-semibold leading-6 text-black">{customer?.company?.formalName}</span>
    </div>
</div>

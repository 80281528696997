<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import { DocumentSourceKind } from '../../../order-to-cash-lib/models/document-source-kind'
    import type { BusinessDocument } from '../../models/business-document'
    import { BusinessDocumentAllDataPersisted } from '../../models/business-document'
    import { BusinessDocumentsAllDataPersistedStore } from '../../stores/business-documents.store'
    import { BusinessDocumentStatus } from '../../enums/business-document-status'

    export let selectedBusinessDocuments: BusinessDocument[]

    /** Local declarations */
    let aNonDeletableInvoiceIsSelected: boolean
    let deleteButtonDisabled: boolean
    let isDeleteInProgress: boolean = false

    /** Reactive declarations */
    $: aNonDeletableInvoiceIsSelected = !!selectedBusinessDocuments.find((anInvoice: BusinessDocument) => { // is there an invoice that cannot be deleted
      if (!anInvoice.businessDocumentSource) {
        return true // if there is no .documentSource property, it is an old Dundy invoice bottom line
      }
      if (!anInvoice.businessDocumentSource.sourceKind) {
        return true // if there is no .documentSource.sourceKind property, it is an old Dundy invoice bottom line
      }
      if (anInvoice.businessDocumentSource.sourceKind === DocumentSourceKind.FROMDUNDYDUNNINGMANUALLY) {
        return true
      } else if (anInvoice.businessDocumentSource.sourceKind === DocumentSourceKind.FROMVOXYINVOICING) {
        return false
      } else if (anInvoice.businessDocumentSource.sourceKind === DocumentSourceKind.UNKNOWNSOURCE) {
        return true // true for the sake of preventing the deletion on unknown source
      } else {
        return true // true for the sake of preventing the deletion on unexpected source
      }
    })
    $: deleteButtonDisabled = (selectedBusinessDocuments.length < 1) || aNonDeletableInvoiceIsSelected


    /**
     * Switch the status of the selected invoices between deleted and not deleted
     * updates the store
     */
    function switchDeleteStatus() {
      if (!selectedBusinessDocuments || !selectedBusinessDocuments.length) {
        return
      }

      BusinessDocumentsAllDataPersistedStore.update((store: BusinessDocumentAllDataPersisted[]) => {
        try {
          const newStore = store.filter((doc: BusinessDocumentAllDataPersisted) => {
            isDeleteInProgress = true
            const businessDocument = doc.coreDocument.businessDocument
            const isInvoiceSelected: boolean = !!selectedBusinessDocuments.find(aSelectedInvoice => (aSelectedInvoice.businessDocumentId === businessDocument.businessDocumentId))

            const isInvoiceDeletable: boolean = (!businessDocument.businessDocumentSource
                            || !businessDocument.businessDocumentSource.sourceKind
                            || businessDocument.businessDocumentSource.sourceKind === DocumentSourceKind.FROMVOXYINVOICING)
                        && businessDocument.businessDocumentStatus === BusinessDocumentStatus.DRAFT
            // Keep the item in the store only if it's not selected and deletable

            return !(isInvoiceSelected && isInvoiceDeletable)
          })
          isDeleteInProgress = false

          return newStore
        } catch (error) {
          return store
        }
      })


    }

</script>

<button class="btn action-secondary"
        disabled={deleteButtonDisabled}
        on:click|stopPropagation={switchDeleteStatus}>
    {#if !isDeleteInProgress}
        <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.75 7.75L6.59115 17.4233C6.68102 18.4568 7.54622 19.25 8.58363 19.25H14.4164C15.4538 19.25 16.319 18.4568 16.4088 17.4233L17.25 7.75H5.75Z"
                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M9.75 10.75V16.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
            <path d="M13.25 10.75V16.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
            <path d="M8.75 7.75V6.75C8.75 5.64543 9.64543 4.75 10.75 4.75H12.25C13.3546 4.75 14.25 5.64543 14.25 6.75V7.75"
                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M4.75 7.75H18.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
        </svg>
        {t('invoices.controls.deleteSelectedInvoice', { count: selectedBusinessDocuments.length })}
    {:else}
        <svg class="animate-spin -ml-1 mr-3 h-6 w-6 text-dundyOrange"
             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                    stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        {t('invoices.controls.deleteInProgress')}
    {/if}
</button>

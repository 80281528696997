<script lang="ts">
  import { onDestroy, onMount } from 'svelte'
  import { BusinessDocument, type BusinessDocumentCollateralData } from '../models/business-document'
  import { BusinessDocumentAllDataPersisted } from '../models/business-document'
  import { businessDocumentsListViewChoice } from '../stores/business-documents.store'
  import { BusinessDocumentSlugs } from '../enums/business-document-slugs'
  import { BusinessDocumentViewListOption } from '../enums/business-documents-view-list-options'
  import { ContactsStore } from '$src/crm-app/stores/contacts.store'
  import { deepClone } from '$core/util/object-deep-cloning'
  import { eventsManager } from '$core/events/event-manager'
  import { EventType } from '$core/events/event-type'
  import { get } from 'svelte/store'
  import { invoicesColumnDefs } from '../lib/view-list/ag-grid/invoices-columns-definitions-ag-grid'
  import { invoicesOptions } from '../lib/view-list/ag-grid/invoices-options-definitions-ag-grid'
  import { navigate } from 'svelte-routing'
  import { searchValueStore, listViewChoiceStore, InvoicesCountsStore, InvoicesStore } from '../stores/invoices.store'
  import { t } from '$core/lib/i18n/i18nextWrapper'
  import { updateNewNavigationHistoryItem } from '$core/stores/navigationHistory.store'
  import AddInvoiceDropdown from '../lib/view-list/AddInvoiceDropdown.svelte'
  import BusinessDocumentListTab from '../../core-app/lib/ui-kit/BusinessDocumentListTab.svelte'
  import BusinessDocumentsRowContextualMenu from '../lib/view-list/ag-grid/BusinessDocumentsRowContextualMenu.svelte'
  import Datatable from '../../core-app/lib/ui-kit/InvoicesDatatable.svelte'
  import DeleteOrRecoverDraftBusinessDocumentButton from '../lib/view-list/DeleteDraftBusinessDocumentButton.svelte'
  import Loader from '../../core-app/lib/ui-kit/Loader.svelte'
  import mixpanel from 'mixpanel-browser'
  import SearchBar from '../../core-app/lib/ui-kit/SearchBar.svelte'
  import StartByCreatingNewContact from '$src/dundy-app/lib/todo/StartByCreatingNewContact.svelte'
  import type { ColDef, GridApi, GridOptions } from 'ag-grid-community'
  import type { DundyEvent } from '$dundy/events/dundy-event'
  import UIBusinessDocumentsUpdatedCounts from '../models/business-documents-ui-updated-counts'
  import { debounce } from '$src/core-app/util/function-utils'

  type BusinessDocumentForGrid = BusinessDocument & {
    collateralData: Partial<BusinessDocumentCollateralData>
  }

  /** Let declarations **/
  let grid: GridApi
  let selectedBusinessDocuments: BusinessDocument[] = []
  let businessDocument: BusinessDocument = new BusinessDocument()
  let currentDisplayedBusinessDocuments: BusinessDocumentForGrid[] = []
  let updatedInvoicesCounts: UIBusinessDocumentsUpdatedCounts = new UIBusinessDocumentsUpdatedCounts()
  let reactToEventBusinessDocumentsListChanged: Function
  let reactToEventBusinessDocumentsListFetched: Function
  let reactToEventBusinessDocumentsSearchValueChanged: Function
  let reactToEventRowSelected: Function
  let isLoading: boolean = true

  /**
   * TODO : adapt with web socket
   * SetTimeout  check if ag-grid line must be enable
   */
  let timers = new Map()

  const setTimers = (list: BusinessDocument[]) => {
    if (!list) return
    if (list.length === 0) return

    const now = Math.round(new Date().getTime() / 1000.0)

    list.forEach((doc: BusinessDocumentForGrid) => {
      if (!doc?.collateralData?.savingStateExpiration) return
      if (timers.has(doc.businessDocumentId)) return

      const docExpiry = doc.collateralData.savingStateExpiration
      const delay = docExpiry - now + 2 // + 2 = time for event cycle

      if (delay <= 0) return
      const timer = setTimeout(() => {
        currentDisplayedBusinessDocuments = currentDisplayedBusinessDocuments
        clearTimeout(timer)
        timers.delete(doc.businessDocumentId)
      }, delay * 1000)

      timers.set(doc.businessDocumentId, timer)
    })
  }

  $: {
    if ($InvoicesStore) setTimeout(() => (isLoading = false), 1500) // Provides a more pleasant UX
    currentDisplayedBusinessDocuments = $InvoicesStore
    updatedInvoicesCounts = $InvoicesCountsStore
  }

  /** Reactive Declaration that triggers everytime $searchValue is changed
   *  Updates the displayed content of the AgGrid datatable each time the search bar is changed
   * **/
  $: eventsManager.emit(
    EventType.INVOICES_SEARCH_VALUE_CHANGED,
    {
      newSearchValue: get(searchValueStore)
    },
    'Invoices.svelte',
  )

  const onSearchChange = debounce(value => {
    searchValueStore.set(value?.text)
  }, 500)

  /** Reactive Declaration that triggers everytime the selectTab is changed
   *  Updates the displayed content of the AgGrid datatable
   * **/
  const selectTab = (listViewChoice: BusinessDocumentViewListOption) => {
    isLoading = true
    businessDocumentsListViewChoice.set(listViewChoice || BusinessDocumentViewListOption.INVOICES)
    listViewChoiceStore.set($businessDocumentsListViewChoice)

    /* console.error('selectTab was called without a valid value!', listViewChoice) */
  }

  /** Set columns Definitions for AG GRID component */
  let columnDefs: ColDef[] = <ColDef[]>invoicesColumnDefs

  /** Set custom Options for AG-GRID component */
  const gridOptions: GridOptions = <GridOptions>invoicesOptions

  /**
   * ACTION AFTER BUSINESS DOCUMENT SAVED
   * TODO :
   * @param {string} action - invoices | draft | credit-note
   */
  const businessDocumentSaved = (action: string) => {
    switch (action) {
      case 'invoice':
        businessDocumentsListViewChoice.set(BusinessDocumentViewListOption.INVOICES)
        listViewChoiceStore.set($businessDocumentsListViewChoice)
        break
      case 'draft':
        businessDocumentsListViewChoice.set(BusinessDocumentViewListOption.DRAFT)
        listViewChoiceStore.set($businessDocumentsListViewChoice)
        break
      case 'creditnote':
        businessDocumentsListViewChoice.set(BusinessDocumentViewListOption.CREDIT_NOTES_AND_VOIDED_INVOICES)
        listViewChoiceStore.set($businessDocumentsListViewChoice)
        break
      case 'voided':
        businessDocumentsListViewChoice.set(BusinessDocumentViewListOption.VOIDED)
        listViewChoiceStore.set($businessDocumentsListViewChoice)
        break
    }
  }

  onMount(async () => {
    mixpanel.track('IA10 Voxy Invoices Page', {
      'Description': 'Open Invoices.svelte'
    })

    updateNewNavigationHistoryItem('Invoices.svelte')
    selectTab($businessDocumentsListViewChoice)

    if (!!reactToEventBusinessDocumentsListChanged) reactToEventBusinessDocumentsListChanged()

    reactToEventBusinessDocumentsListChanged = eventsManager.on<BusinessDocumentAllDataPersisted[]>(
      EventType.BUSINESS_DOCUMENTS_LIST_CHANGED,
      () => {
        // console.log('reactToEventBusinessDocumentsListChanged BUSINESS_DOCUMENTS_LIST_CHANGED', e.data)
        setTimers($InvoicesStore)
      },
      'Invoices.svelte',
    )

    reactToEventBusinessDocumentsListFetched = eventsManager.on<BusinessDocumentAllDataPersisted[]>(
      EventType.BUSINESS_DOCUMENTS_LIST_FETCHED,
      () => {
        // console.log('reactToEventInvoicesListLoaded BUSINESS_DOCUMENTS_LIST_FETCHED', e.data)
        setTimers($InvoicesStore)
      },
      'Invoices.svelte',
    )

    reactToEventBusinessDocumentsSearchValueChanged = eventsManager.on<string>(
      EventType.INVOICES_SEARCH_VALUE_CHANGED,
      () => {
        // console.log('reactToEventInvoicesListLoaded INVOICES_SEARCH_VALUE_CHANGED', e.data)
        setTimers($InvoicesStore)
      },
      'Invoices.svelte',
    )

    reactToEventRowSelected = eventsManager.on<Array<any>>(
      EventType.INVOICES_TABLE_ROW_SELECTED,
      (e: DundyEvent<any[]>) => {
        selectedBusinessDocuments = e.data
      },
      'Invoices.svelte',
    )

    // check if come from invoice edition
    const search = new URL(window.location.toString()).searchParams?.get('saved')
    if (search) businessDocumentSaved(search)
  })

  // afterUpdate(async () => {
  //   setTimeout(() => (isLoading = false), 1500) // Provides a more pleasant UX
  // })

  onDestroy(() => {
    reactToEventRowSelected()
    reactToEventBusinessDocumentsSearchValueChanged()
    reactToEventBusinessDocumentsListFetched()
    reactToEventBusinessDocumentsListChanged()
  })
</script>

<svelte:head>
  <title>{t('topMenu.nav.invoices')} - Dundy</title>
</svelte:head>

<main class="flex flex-col items-center">
  <div class="flex w-full flex-col flex-1">
    <div class="mb-10">
      <h1 class="text-black text-3xl font-bold">{t('invoices.pageTitle')}</h1>
    </div>

    {#if !$ContactsStore.length}
      <div class="mt-12">
        <StartByCreatingNewContact/>
      </div>
    {:else}

      <div class="w-full">
        <div class="border-b border-gray-200">
          <nav aria-label="Tabs" class="-mb-px flex space-x-8">
            <!-- INVOICES tab -->
            <BusinessDocumentListTab
              bind:currentStoreChoice={$businessDocumentsListViewChoice}
              bind:numberOfBusinessDocumentsInView={updatedInvoicesCounts.numberOfFinalizedInvoices}
              bind:totalBusinessDocumentAmountNormalized={updatedInvoicesCounts.finalizedInvoicesAmountNormalized}
              businessDocumentListType={BusinessDocumentViewListOption.INVOICES}
              on:chosenViewListOption={(e) => {
                selectTab(e.detail)
              }}
              showTotalInTab={false}
              tabTitleEntry="invoices.tabs.invoices"
            />
            <!-- CREDIT NOTE tab -->
            <BusinessDocumentListTab
              bind:currentStoreChoice={$businessDocumentsListViewChoice}
              bind:numberOfBusinessDocumentsInView={updatedInvoicesCounts.numberOfCreditNoteDocuments}
              bind:totalBusinessDocumentAmountNormalized={updatedInvoicesCounts.creditNoteDocumentsAmountNormalized}
              businessDocumentListType={BusinessDocumentViewListOption.CREDIT_NOTES_AND_VOIDED_INVOICES}
              on:chosenViewListOption={(e) => {
                selectTab(e.detail)
              }}
              showTotalInTab={false}
              tabTitleEntry="invoices.tabs.creditNotesAndVoidedInvoices"
            />
            <!-- VOIDED tab -->
            <BusinessDocumentListTab
              bind:currentStoreChoice={$businessDocumentsListViewChoice}
              bind:numberOfBusinessDocumentsInView={updatedInvoicesCounts.numberOfVoidedDocuments}
              bind:totalBusinessDocumentAmountNormalized={updatedInvoicesCounts.voidedDocumentAmountNormalized}
              businessDocumentListType={BusinessDocumentViewListOption.VOIDED}
              on:chosenViewListOption={(e) => {
                selectTab(e.detail)
              }}
              showTotalInTab={false}
              tabTitleEntry="invoices.tabs.voided"
            />
            <!-- DRAFT tab -->
            <BusinessDocumentListTab
              bind:currentStoreChoice={$businessDocumentsListViewChoice}
              bind:numberOfBusinessDocumentsInView={updatedInvoicesCounts.numberOfDraftInvoices}
              bind:totalBusinessDocumentAmountNormalized={updatedInvoicesCounts.draftInvoicesAmountNormalized}
              businessDocumentListType={BusinessDocumentViewListOption.DRAFT}
              on:chosenViewListOption={(e) => {
                selectTab(e.detail)
              }}
              showTotalInTab={false}
              tabTitleEntry="invoices.tabs.draft"
            />

            <div class="flex justify-end w-3/12 mb-4 float-right justify-self-end self-end add-invoice-button-container">
              <AddInvoiceDropdown
                on:createCreditNote={() => {
                  navigate(`receivables/new/${BusinessDocumentSlugs.COMMERCIAL_CREDIT_NOTE}/details`)
                }}
                on:createVoxyInvoice={() => {
                  navigate(`receivables/new/${BusinessDocumentSlugs.COMMERCIAL_INVOICE}/details`)
                }}
              />
            </div>
          </nav>
        </div>
      </div>

      <div class="flex flex-col w-full bg-white">
        <div class="flex flex-col lg:flex-row items-center py-6 lg:space-x-5 space-y-4 lg:space-y-0">
          <div class="w-full lg:w-6/12 xl:w-6/12">
            <SearchBar
              on:search={(e) => onSearchChange(e.detail)}
              on:clear={(e) => onSearchChange(e.detail)}
              on:type={(e) => onSearchChange(e.detail)}
              value={get(searchValueStore)}
              hideLabel={true}
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-6/12">
            <div class="flex flex-col space-y-3 lg:space-y-0 lg:flex-row lg:space-x-5 lg:justify-end lg:justify-self-end">
              {#if $businessDocumentsListViewChoice === BusinessDocumentViewListOption.DRAFT}
                <DeleteOrRecoverDraftBusinessDocumentButton {selectedBusinessDocuments} />
              {/if}
            </div>
          </div>
        </div>
        <div class="flex flex-col w-full h-full relative">
          {#if get(searchValueStore) && !currentDisplayedBusinessDocuments.length}
            <div class="empty flex flex-col items-center mt-20 w-full">
              <h1 class="font-black text-base">{t('invoices.noResults.title')}</h1>
              <p class="font-medium text-paleSky mt-6 text-center">
                {t('invoices.noResults.one')}<br /> « {get(searchValueStore)} ».
                <br />
                {t('invoices.noResults.two')}
              </p>
            </div>
          {:else if currentDisplayedBusinessDocuments.length}
            <Datatable
              bind:grid
              class="invoices"
              {columnDefs}
              spreadsheetData={currentDisplayedBusinessDocuments}
              options={gridOptions}
            />
          {:else if isLoading}
            <div class="grid h-96 place-items-center w-full z-[100]">
              <Loader i18nKey="invoices.fetching" />
            </div>
          {/if}
          <BusinessDocumentsRowContextualMenu
            on:editVoxyInvoiceClicked={(e) => {
              businessDocument = deepClone(e.detail.businessDocument)
              navigate(`receivables/edit/${businessDocument.businessDocumentId}/details`)
            }}
            on:viewVoxyInvoiceClicked={(e) => {
              businessDocument = deepClone(e.detail.businessDocument)
              navigate(`receivables/view/${businessDocument.businessDocumentId}/details`)
            }}
          />
        </div>
      </div>

    {/if}
  </div>
</main>

<style global lang="postcss">
  .ag-theme-alpine .ag-header {
    @apply bg-whisper border-0 border-t border-zinc-100;
  }

  .ag-theme-alpine .ag-row {
    @apply bg-transparent border-0 border-b border-zinc-100 hover:bg-whisper hover:rounded-md;
  }

  .ag-theme-alpine .ag-row-hover {
    @apply bg-whisper;
  }

  /* ROW SELECTION */
  .ag-theme-alpine .ag-row-selected {
    @apply bg-whisper;
  }

  /* RANGE SELECTION */
  .ag-theme-alpine .ag-cell-range-selected:not(.ag-cell-focus),
  .ag-theme-alpine .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-single-cell:not(.ag-cell-inline-editing) {
    @apply bg-whisper;
  }

  /* RANGE SELECTION INTERSECTION LEVELS */
  .ag-theme-alpine .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
    background-color: rgba(220, 53, 69, 0.2);
  }

  .ag-theme-alpine .ag-cell-range-selected-1:not(.ag-cell-focus),
  .ag-theme-alpine .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-1 {
    background-color: rgba(220, 53, 69, 0.2);
  }

  .ag-theme-alpine .ag-cell-range-selected-2:not(.ag-cell-focus),
  .ag-theme-alpine .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-2 {
    background-color: rgba(220, 53, 69, 0.36);
  }

  .ag-theme-alpine .ag-cell-range-selected-3:not(.ag-cell-focus),
  .ag-theme-alpine .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-3 {
    background-color: rgba(220, 53, 69, 0.488);
  }

  .ag-theme-alpine .ag-cell-range-selected-4:not(.ag-cell-focus),
  .ag-theme-alpine .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-4 {
    background-color: rgba(220, 53, 69, 0.5904);
  }

  .ag-theme-alpine div.ag-row-hover:not(.ag-row-group) {
    @apply bg-whisper;
  }

  .ag-theme-alpine .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    @apply border-black;
  }

  .ag-theme-alpine .ag-cell {
    @apply border-0 text-zinc-700 font-light flex;
    line-height: 48px;
  }

  .ag-theme-alpine .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    @apply border-0;
  }

  .ag-theme-alpine .ag-header-cell {
    @apply border-0 border-b border-zinc-100;
  }

  .ag-theme-alpine .ag-column-hover {
    @apply bg-transparent;
  }

  .ag-theme-alpine .dundy-businessDocument-number-data {
    @apply truncate;
  }

  .ag-theme-alpine .ag-row.voxy-invoice-row-draft .ag-cell-value {
    @apply text-paleSky;
  }

  .ag-row.voxy-invoice-row-disabled {
    @apply pointer-events-none;

    &::before {
      content: "";
      @apply absolute top-6 left-5 w-5 h-5 z-10 -ml-px rounded-full border-2 border-r-dundyOrange;
      animation: disabled-loader 1s infinite linear;
    }
  }

  .ag-row.voxy-invoice-row-disabled > div {
    @apply opacity-50;
  }

  .ag-row.voxy-invoice-row-disabled .ag-checkbox-input-wrapper::after {
    display: none !important;
  }

  @keyframes disabled-loader {
    to {
      transform: rotate(1turn);
    }
  }
</style>

import type { BusinessDocument, BusinessDocumentSource } from '../../models/business-document'
import {
  BusinessDocumentLineItem,
  BusinessDocumentRelation,
  DealDetails,
  InstallmentComputationKind
} from '../../models/business-document'
import { v4 as uuidv4 } from 'uuid'
import type { Workspace } from '$crm/models/workspace'
import type { Customer } from '$crm/models/customer'
import type { Contact } from '$crm/models/contact'
import { ExchangeDate } from '$core/models/exchange-date'
import type { TaxonomyTag } from '../../enums/taxonomy-tag'

import { getCurrentAccountCompany } from '$crm/services/workspace.pure-functions'
import {
  getNewBusinessDocumentSource,
  getNewContactAccount,
  getNewCustomerAccount
} from '../business-document-various-functions'
import { BusinessDocumentStatus } from '../../enums/business-document-status'
import { getTagsForIntermediateOrBalanceBusinessDocument } from '../taxonomy/taxonomy-tag-pure-functions'
import { BusinessDocumentRelationKind } from '../../enums/business-document-relation-kind'
import { BusinessDocumentKind } from '../../enums/business-document-kind'
import {
  applyInstallmentGenericallyChange,
  calculateInstallmentChangeResultingValuesFromPercentInputChange,
  sanitizeInstallmentPercentInputValue
} from '../ui-services/businessDocumentInstallments/business-document-installments.ui'
import { NewBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange } from '../../models/business-documents-installments.model'
import { deepClone } from '$core/util/object-deep-cloning'
import type Company from '../../../crm-app/models/company'
import { BalanceOfDealBusinessDocumentBuildingData } from '../../models/partial-business-document-building-data.model'
import {
  filterAllBusinessDocumentsDraft,
  filterAllBusinessDocumentsWithDealId,
  filterAllOtherBusinessDocumentsButCurrentOne
} from '../business-document-array-filters/business-documents-filters.service'
import {
  calculateCumulatedInvoicedInstallmentsIncludingTax,
  calculateRemainingInstallmentIncludingTaxStillToInvoiceIncludingAllSpecifiedDocumentsAndCurrentDocument
} from '../deal-balance-calculation/incomplete-deals-calculation.service'
import type { ComputedRemainingValues } from '../../models/installment-computed-remaining-values.model'
import type { ComputedInstallmentValues } from '../../models/installment-computed-accumulated-values.model'
import { InvoicingBalanceLimits } from '../../models/invoicing-balance-limits.model'
import { getUpToDateInvoicingBalanceLimits } from '../deal-balance-calculation/invoicing-balance-limits-update.service'
import { Decimal } from 'decimal.js'
import { calculateTotalPrice, calculateTotalVAT } from '../../helpers/voxy-helper-pure-functions'
import { getDealFinalizedBusinessDocumentsForBalanceOfDealCalculus } from './get-deal-finalized-business-documents-for-balance-of-deal-calculus.service'


/**
 * Generates an intermediate or balance business document
 *
 * @param latestBusinessDocumentOfSelectedDealToBalance
 * @param allBusinessDocuments
 * @param currentWorkspace
 * @param allCustomers
 * @param allContacts
 * @param invoicingBalanceLimits
 */
export function newGenerateBalanceBusinessDocumentFromOriginalCoreDocument(
  latestBusinessDocumentOfSelectedDealToBalance: BusinessDocument,
  allBusinessDocuments: BusinessDocument[],
  currentWorkspace: Workspace,
  allCustomers: Customer[],
  allContacts: Contact[],
  invoicingBalanceLimits: InvoicingBalanceLimits,
): BalanceOfDealBusinessDocumentBuildingData {
  // select existing draft business document of this deal or generate new business document cloning original one
  const existingDraftPartialBusinessDocumentWithDeal: BusinessDocument | undefined = findExistingDraftBusinessDocumentWithThisDeal(
    latestBusinessDocumentOfSelectedDealToBalance,
    allBusinessDocuments,
  )
  let wipPartialBusinessDocumentAndCollateralData: BalanceOfDealBusinessDocumentBuildingData
  if (!existingDraftPartialBusinessDocumentWithDeal) {
    // no existing draft business document, then we take the latest business document of the deal as a base
    wipPartialBusinessDocumentAndCollateralData = <BalanceOfDealBusinessDocumentBuildingData>{
      businessDocument: <BusinessDocument>{ ...deepClone(latestBusinessDocumentOfSelectedDealToBalance), businessDocumentKind: BusinessDocumentKind.INVOICE },

      isCollateralDataSameAsAnotherOne: true,
      businessDocumentIdForCollateralDataIfSameAsAnotherOne: latestBusinessDocumentOfSelectedDealToBalance.businessDocumentId,
      collateralDataBusinessDocumentId: latestBusinessDocumentOfSelectedDealToBalance.businessDocumentId,
      collateralDataBusinessDocumentKind: BusinessDocumentKind.INVOICE,
      collateralDataNeedsPaymentURLResetAnyway: true,

      existingDocument: false
    }
  } else {
    // existing draft business document, we use it as a base
    // todo make sure no other derivedBusinessDocumentCollateralData properties should be reset or initialized actively here
    wipPartialBusinessDocumentAndCollateralData = <BalanceOfDealBusinessDocumentBuildingData>{
      businessDocument: <BusinessDocument>{ ...deepClone(existingDraftPartialBusinessDocumentWithDeal), businessDocumentKind: BusinessDocumentKind.INVOICE },

      isCollateralDataSameAsAnotherOne: true,
      businessDocumentIdForCollateralDataIfSameAsAnotherOne: existingDraftPartialBusinessDocumentWithDeal.businessDocumentId,
      collateralDataBusinessDocumentId: existingDraftPartialBusinessDocumentWithDeal.businessDocumentId,
      collateralDataBusinessDocumentKind: BusinessDocumentKind.INVOICE,
      collateralDataNeedsPaymentURLResetAnyway: true,

      existingDocument: true
    }

  }
  // attach deal + locked line items + taxonomy + relation documents
  const newBusinessDocumentWithDealAttached = attachPreviousBusinessDocumentDealToCurrentBusinessDocument(
    wipPartialBusinessDocumentAndCollateralData.businessDocument,
    latestBusinessDocumentOfSelectedDealToBalance,
    !(wipPartialBusinessDocumentAndCollateralData.existingDocument),
  )

  // apply balance of deal
  const docAndNewValues: {
    createdBusinessDocumentWithAppliedBalanceOfDeal: BusinessDocument,
    computedSumOfInvoicedOnlyFinalized: ComputedInstallmentValues,
    computedRemainingToInvoiceApartFromFinalized: ComputedRemainingValues,
  } = newApplyBalanceOfDealOnBusinessDocument(
    wipPartialBusinessDocumentAndCollateralData.existingDocument,
    latestBusinessDocumentOfSelectedDealToBalance,
    newBusinessDocumentWithDealAttached,
    allBusinessDocuments,
    currentWorkspace,
    allCustomers,
    allContacts,
    invoicingBalanceLimits,
  )
  const newBusinessDocumentWithAppliedBalanceOfDeal: BusinessDocument = docAndNewValues.createdBusinessDocumentWithAppliedBalanceOfDeal
  
  /* console.log('new docAndNewValues', docAndNewValues) */
  
  const newInvoicingBalanceLimits: InvoicingBalanceLimits = getUpToDateInvoicingBalanceLimits(
    newBusinessDocumentWithAppliedBalanceOfDeal.totalIncludingTaxScaledValue,
    docAndNewValues.computedSumOfInvoicedOnlyFinalized.accumulatedInstallmentsSum.toNumber(),
    // (new Decimal(newBusinessDocumentWithAppliedBalanceOfDeal.totalIncludingTaxScaledValue))
    //     .sub(new Decimal(newBusinessDocumentWithAppliedBalanceOfDeal.installmentResultIncludingTaxScaledValue)).toNumber(),
    newBusinessDocumentWithAppliedBalanceOfDeal.installmentResultIncludingTaxScaledValue,
  )
  

  return <BalanceOfDealBusinessDocumentBuildingData>{
    businessDocument: newBusinessDocumentWithAppliedBalanceOfDeal,

    isCollateralDataSameAsAnotherOne: wipPartialBusinessDocumentAndCollateralData.isCollateralDataSameAsAnotherOne,
    businessDocumentIdForCollateralDataIfSameAsAnotherOne: wipPartialBusinessDocumentAndCollateralData.businessDocumentIdForCollateralDataIfSameAsAnotherOne,
    collateralDataBusinessDocumentId: wipPartialBusinessDocumentAndCollateralData.collateralDataBusinessDocumentId,
    collateralDataBusinessDocumentKind: BusinessDocumentKind.INVOICE,
    invoicingBalanceLimitsForBalanceInvoice: newInvoicingBalanceLimits,
    collateralDataNeedsPaymentURLResetAnyway: true,

    existingDocument: wipPartialBusinessDocumentAndCollateralData.existingDocument
  }
}


/**
 * Find Existing Draft Business Document in a list With Specific DealId using Original Business Document
 * @param originalBusinessDocument
 * @param allBusinessDocuments
 */
function findExistingDraftBusinessDocumentWithThisDeal(
  originalBusinessDocument: BusinessDocument,
  allBusinessDocuments: BusinessDocument[],
): BusinessDocument | undefined {
  return allBusinessDocuments
    .filter(filterAllOtherBusinessDocumentsButCurrentOne(originalBusinessDocument.businessDocumentId))
    .filter(filterAllBusinessDocumentsWithDealId(originalBusinessDocument.linkedDeal.dealId))
    .find(filterAllBusinessDocumentsDraft)
}

/**
 * Attach Previous BD Deals to Current Business Document
 * @param currentBusinessDocument
 * @param latestBusinessDocumentWithDeal
 * @param generateNewCurrentBusinessDocumentId
 */
export function attachPreviousBusinessDocumentDealToCurrentBusinessDocument(
  currentBusinessDocument: BusinessDocument,
  latestBusinessDocumentWithDeal: BusinessDocument,
  generateNewCurrentBusinessDocumentId: boolean,
): BusinessDocument {
  const newBusinessDocument: BusinessDocument = <BusinessDocument>deepClone(currentBusinessDocument)
  if (generateNewCurrentBusinessDocumentId) {
    newBusinessDocument.businessDocumentId = uuidv4()
  }

  newBusinessDocument.businessDocumentStatus = BusinessDocumentStatus.DRAFT
  newBusinessDocument.businessDocumentKind = currentBusinessDocument.businessDocumentKind
  newBusinessDocument.businessDocumentNumber = ''

  // reset installment to zero
  newBusinessDocument.hasSpecificInstallment = latestBusinessDocumentWithDeal.hasSpecificInstallment
  newBusinessDocument.installmentResultIncludingTaxScaledValue = 0
  newBusinessDocument.installmentResultExcludingTaxScaledValue = 0
  newBusinessDocument.installmentResultTaxScaledValue = 0
  newBusinessDocument.installmentDescription = ''
  newBusinessDocument.installmentChosenValue = 0
  newBusinessDocument.installmentChosenKind = InstallmentComputationKind.ABSOLUTE_AMOUNT_INCLUDING_TAX

  newBusinessDocument.linkedDeal = <DealDetails>deepClone(latestBusinessDocumentWithDeal.linkedDeal)

  const latestLineItemsToTurnIntoInvoiceLineItems: BusinessDocumentLineItem[] = <BusinessDocumentLineItem[]>deepClone(latestBusinessDocumentWithDeal.lineItems)
  if (latestBusinessDocumentWithDeal.businessDocumentKind === BusinessDocumentKind.CREDITNOTE) {
    const latestLineItemsTurnedIntoInvoiceLineItems: BusinessDocumentLineItem[] = <BusinessDocumentLineItem[]>latestLineItemsToTurnIntoInvoiceLineItems
      .map((li: BusinessDocumentLineItem) => <BusinessDocumentLineItem>{
        ...li,
        taxScaledValue: -(li.taxScaledValue),
        itemPrice: {
          ...li.itemPrice,
          scaledValue: -(li.itemPrice.scaledValue)
        },
        lineItemTotalIncludingTaxScaledValue: -(li.lineItemTotalIncludingTaxScaledValue),
        lineItemTotalExcludingTaxScaledValue: -(li.lineItemTotalExcludingTaxScaledValue)
      })
    newBusinessDocument.lineItems = <BusinessDocumentLineItem[]>deepClone(latestLineItemsTurnedIntoInvoiceLineItems)
  } else {
    const latestLineItemsTurnedIntoInvoiceLineItems: BusinessDocumentLineItem[] = <BusinessDocumentLineItem[]>latestLineItemsToTurnIntoInvoiceLineItems
    newBusinessDocument.lineItems = <BusinessDocumentLineItem[]>deepClone(latestLineItemsTurnedIntoInvoiceLineItems)
  }
  // keep discount
  newBusinessDocument.totalDiscountExcludingTaxResultScaledValue = currentBusinessDocument.totalDiscountExcludingTaxResultScaledValue
  newBusinessDocument.totalDiscountExcludingTaxChosenUnit = currentBusinessDocument.totalDiscountExcludingTaxChosenUnit
  newBusinessDocument.totalDiscountExcludingTaxChosenValue = currentBusinessDocument.totalDiscountExcludingTaxChosenValue
  newBusinessDocument.totalDiscountExcludingTaxDescription = currentBusinessDocument.totalDiscountExcludingTaxDescription

  // calculating totals
  newBusinessDocument.subtotalExcludingTaxScaledValue = Math.round((calculateTotalPrice(newBusinessDocument.lineItems) + Number.EPSILON) * 100) / 100
  newBusinessDocument.totalTaxScaledValue = Math.round((calculateTotalVAT(newBusinessDocument.lineItems) + Number.EPSILON) * 100) / 100
  newBusinessDocument.subtotalIncludingTaxScaledValue = Math.round(((newBusinessDocument.subtotalExcludingTaxScaledValue + newBusinessDocument.totalTaxScaledValue) + Number.EPSILON) * 100) / 100
  newBusinessDocument.totalExcludingTaxScaledValue = Math.round(((newBusinessDocument.subtotalExcludingTaxScaledValue - newBusinessDocument.totalDiscountExcludingTaxResultScaledValue) + Number.EPSILON) * 100) / 100
  newBusinessDocument.totalIncludingTaxScaledValue = Math.round(((newBusinessDocument.subtotalExcludingTaxScaledValue - newBusinessDocument.totalDiscountExcludingTaxResultScaledValue) + newBusinessDocument.totalTaxScaledValue + Number.EPSILON) * 100) / 100

  return newBusinessDocument
}


export function newApplyBalanceOfDealOnBusinessDocument(
  existingBusinessDocumentHenceKeepBusinessDocumentId: boolean,
  latestBusinessDocumentOfSelectedDealToBalance: BusinessDocument,
  currentBusinessDocument: BusinessDocument,
  allBusinessDocuments: BusinessDocument[],
  currentWorkspace: Workspace,
  allCustomers: Customer[],
  allContacts: Contact[],
  invoicingBalanceLimits: InvoicingBalanceLimits,
): {
    createdBusinessDocumentWithAppliedBalanceOfDeal: BusinessDocument,
    computedSumOfInvoicedOnlyFinalized: ComputedInstallmentValues,
    computedRemainingToInvoiceApartFromFinalized: ComputedRemainingValues,
  } {
  // todo change deal handling to allow to use linkedDeal.hasDealInfo
  // keep only the relevant business documents for calculations
  // if (!currentBusinessDocument.linkedDeal.hasDealInfo) {
  
  //     return {
  //         businessDocument: currentBusinessDocument,
  //         businessDocumentCollateralData: currentBusinessDocumentCollateralData,
  //     }
  // }
  const allDealFinalizedBusinessDocuments: BusinessDocument[] = getDealFinalizedBusinessDocumentsForBalanceOfDealCalculus(
    allBusinessDocuments,
    currentBusinessDocument.businessDocumentId,
    currentBusinessDocument.linkedDeal.dealId,
  )
  
  // TODO this section was developed wrongly thinking that calculateRemainingInstallmentIncludingTaxStillToInvoiceIncludingAllSpecifiedDocumentsAndCurrentDocument() does not include

  // Calculate the remaining amount
  /* console.log('currentBusinessDocument.installmentResultIncludingTaxScaledValue getBusinessDocumentUpdatedInstallmentTaxonomyTags', currentBusinessDocument.installmentResultIncludingTaxScaledValue) */
  const computedRemainingToInvoiceApartFromFinalized: ComputedRemainingValues = calculateRemainingInstallmentIncludingTaxStillToInvoiceIncludingAllSpecifiedDocumentsAndCurrentDocument(currentBusinessDocument, allDealFinalizedBusinessDocuments)

  // Calculate the accumulated percentage from the related business documents
  const computedSumOfInvoicedOnlyFinalized: ComputedInstallmentValues = calculateCumulatedInvoicedInstallmentsIncludingTax(currentBusinessDocument.totalIncludingTaxScaledValue, allDealFinalizedBusinessDocuments)

  /*const totalPercentageOfDeal: Decimal = computedSumOfInvoicedOnlyFinalized.accumulatedInstallmentsPercentage.add(computedRemainingToInvoiceApartFromFinalized.remainingPercentage)*/

  /* console.log('computedRemainingToInvoiceApartFromFinalized', JSON.stringify(computedRemainingToInvoiceApartFromFinalized, null, 3)) */
  /* console.log('computedSumOfInvoicedOnlyFinalized', JSON.stringify(computedSumOfInvoicedOnlyFinalized, null, 3)) */
  /* console.log('totalPercentageOfDeal getBusinessDocumentUpdatedInstallmentTaxonomyTags', totalPercentageOfDeal.toNumber()) */
  

  // Populate the installment properties of the original document
  const newBusinessDocumentWithAppliedBalanceOfDeal: BusinessDocument = deepClone(
    populateInstallmentProperties(currentBusinessDocument, computedRemainingToInvoiceApartFromFinalized, allBusinessDocuments, invoicingBalanceLimits),
  )
  /* console.log('newBusinessDocumentWithAppliedBalanceOfDeal.installmentResultTaxScaledValue', newBusinessDocumentWithAppliedBalanceOfDeal.installmentResultTaxScaledValue) */
  /* console.log('newBusinessDocumentWithAppliedBalanceOfDeal.installmentResultIncludingTaxScaledValue', newBusinessDocumentWithAppliedBalanceOfDeal.installmentResultIncludingTaxScaledValue) */
  /* console.log('newBusinessDocumentWithAppliedBalanceOfDeal.installmentResultExcludingTaxScaledValue', newBusinessDocumentWithAppliedBalanceOfDeal.installmentResultExcludingTaxScaledValue) */

  // Update business document properties
  const now: Date = new Date()
  const newBusinessDocumentId: string = existingBusinessDocumentHenceKeepBusinessDocumentId ? currentBusinessDocument.businessDocumentId : uuidv4()
  const newBusinessDocumentNumber: string = existingBusinessDocumentHenceKeepBusinessDocumentId ? currentBusinessDocument.businessDocumentNumber : ''
  const newBusinessDocumentTaxonomyTags: TaxonomyTag[] = getTagsForIntermediateOrBalanceBusinessDocument(
    /*newBusinessDocumentWithAppliedBalanceOfDeal.businessDocumentKind,
        newBusinessDocumentWithAppliedBalanceOfDeal.taxonomyTags,
        computedRemainingToInvoiceApartFromFinalized.remainingPercentage,*/
    newBusinessDocumentWithAppliedBalanceOfDeal,
    allBusinessDocuments,
  )
  /* console.warn('computedSumOfInvoicedOnlyFinalized.accumulatedInstallmentsPercentage', computedSumOfInvoicedOnlyFinalized.accumulatedInstallmentsPercentage.toNumber()) */
  /* console.warn('newBusinessDocumentTaxonomyTags', newBusinessDocumentTaxonomyTags) */
  const upToDateBusinessDocumentSource: BusinessDocumentSource = getNewBusinessDocumentSource(currentBusinessDocument.businessDocumentSource, now)
  const upToDateCustomer: Customer = getNewCustomerAccount(allCustomers, currentBusinessDocument.customerCustomer.company.companyId)
  const upToDateCustomerContact: Contact = getNewContactAccount(allContacts, currentBusinessDocument.customerContact.contactId)
  const upToDateAccountCompany: Company = getCurrentAccountCompany(currentWorkspace)

  newBusinessDocumentWithAppliedBalanceOfDeal.taxonomyTags = newBusinessDocumentTaxonomyTags
  newBusinessDocumentWithAppliedBalanceOfDeal.businessDocumentId = newBusinessDocumentId
  newBusinessDocumentWithAppliedBalanceOfDeal.businessDocumentStatus = BusinessDocumentStatus.DRAFT
  newBusinessDocumentWithAppliedBalanceOfDeal.businessDocumentSource = upToDateBusinessDocumentSource
  newBusinessDocumentWithAppliedBalanceOfDeal.businessDocumentNumber = newBusinessDocumentNumber
  if (!existingBusinessDocumentHenceKeepBusinessDocumentId) {
    newBusinessDocumentWithAppliedBalanceOfDeal.relatedBusinessDocuments = generateRelatedBusinessDocumentsForNewPartialOrBalanceInvoice(
      latestBusinessDocumentOfSelectedDealToBalance, // currentBusinessDocument,
      newBusinessDocumentId,
      currentBusinessDocument.businessDocumentKind,
      newBusinessDocumentTaxonomyTags,
      newBusinessDocumentNumber,
      upToDateBusinessDocumentSource,
      ExchangeDate.newDate(now),
      now,
    )
  }
  newBusinessDocumentWithAppliedBalanceOfDeal.finalizedDate = ExchangeDate.empty()
  newBusinessDocumentWithAppliedBalanceOfDeal.deleted = false
  newBusinessDocumentWithAppliedBalanceOfDeal.deletedDate = ExchangeDate.empty()
  newBusinessDocumentWithAppliedBalanceOfDeal.createdDate = ExchangeDate.newDate(now)
  newBusinessDocumentWithAppliedBalanceOfDeal.modifiedDate = ExchangeDate.newDate(now)
  newBusinessDocumentWithAppliedBalanceOfDeal.issuedDate = ExchangeDate.newDate(now)
  newBusinessDocumentWithAppliedBalanceOfDeal.timeZoneIANACode = Intl.DateTimeFormat().resolvedOptions().timeZone
  
  newBusinessDocumentWithAppliedBalanceOfDeal.customerCustomer = upToDateCustomer
  if (newBusinessDocumentWithAppliedBalanceOfDeal.customerCustomer)
    newBusinessDocumentWithAppliedBalanceOfDeal.customerCustomer.company = upToDateCustomer?.company
  newBusinessDocumentWithAppliedBalanceOfDeal.customerContact = upToDateCustomerContact
  newBusinessDocumentWithAppliedBalanceOfDeal.accountCompany = upToDateAccountCompany

  // arbitrary choice of absolute value for the installment
  /* console.warn('a new document was built with installment data newBusinessDocumentWithAppliedBalanceOfDeal', 'installmentChosenValue', newBusinessDocumentWithAppliedBalanceOfDeal.installmentChosenValue, 'installmentChosenKind', newBusinessDocumentWithAppliedBalanceOfDeal.installmentChosenKind, 'installmentResultIncludingTaxScaledValue', newBusinessDocumentWithAppliedBalanceOfDeal.installmentResultIncludingTaxScaledValue) */
  newBusinessDocumentWithAppliedBalanceOfDeal.installmentChosenValue = newBusinessDocumentWithAppliedBalanceOfDeal.installmentResultIncludingTaxScaledValue
  newBusinessDocumentWithAppliedBalanceOfDeal.installmentChosenKind = InstallmentComputationKind.ABSOLUTE_AMOUNT_INCLUDING_TAX

  // const newCollateralDataWithAppliedBalanceOfDeal: BusinessDocumentCollateralData = deepClone(currentBusinessDocumentCollateralData);
  // newCollateralDataWithAppliedBalanceOfDeal.businessDocumentId = newBusinessDocumentId;
  // if (!!newCollateralDataWithAppliedBalanceOfDeal.paymentInformation) {
  //     newCollateralDataWithAppliedBalanceOfDeal.paymentInformation.paymentURL = '';
  // }

  return {
    createdBusinessDocumentWithAppliedBalanceOfDeal: newBusinessDocumentWithAppliedBalanceOfDeal,
    computedSumOfInvoicedOnlyFinalized: computedSumOfInvoicedOnlyFinalized,
    computedRemainingToInvoiceApartFromFinalized: computedRemainingToInvoiceApartFromFinalized
  }
}

/**
 * Populate the installment properties of the original document
 * @param document
 * @param remainingValues
 * @param allConcernedBusinessDocuments
 * @param invoicingBalanceLimits
 */
function populateInstallmentProperties(
  document: BusinessDocument,
  remainingValues: ComputedRemainingValues,
  allConcernedBusinessDocuments: BusinessDocument[],
  invoicingBalanceLimits: InvoicingBalanceLimits,
): BusinessDocument {
  // const newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange: NewBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange = detectPercentageChange(remainingValues.remainingPercentage, document, allConcernedBusinessDocuments)

  // NB: (1) generic sanitizeInstallmentInputValue() or sanitizeInstallmentPercentInputValue() only use the invoicingBalanceLimits finalized data and
  // (2) generic calculateInstallmentChangeResultingValuesInputChange() or calculateInstallmentChangeResultingValuesFromPercentInputChange() do not take invoicingBalanceLimits as argument at all and finally
  // (3) applyInstallmentGenericallyChange() do not use invoicingBalanceLimits anywhere else: so no coherence problem expected overall.
  const newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange: NewBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange =
        (applyInstallmentGenericallyChange<Decimal>(
          sanitizeInstallmentPercentInputValue, // arbitrary, just for the sake of having values - should not override the nature of the actual change
          calculateInstallmentChangeResultingValuesFromPercentInputChange, // arbitrary, just for the sake of having values - should not override the nature of the actual change
          invoicingBalanceLimits, // see NB above
        ))(remainingValues.remainingPercentage,
          document,
          allConcernedBusinessDocuments,
        )
  console.warn('a new document was built with installment data newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange',
    'installmentChosenValue', newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange.newBusinessDocument.installmentChosenValue,
    'installmentChosenKind', newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange.newBusinessDocument.installmentChosenKind,
    'installmentResultIncludingTaxScaledValue', newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange.newBusinessDocument.installmentResultIncludingTaxScaledValue,
    'newUserInputAbsoluteValue', newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange.newUserInputAbsoluteValue,
    'newUserInputPercentage', newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange.newUserInputPercentage,
  )

  // const newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange: NewBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange = applyInstallmentValueChange<Decimal>(
  //     remainingValues.remainingPercentage,
  //     sanitizeInstallmentPercentInputValue, // arbitrary, just for the sake of having values - should not override the nature of the actual change
  //     calculateInstallmentChangeResultingValuesFromPercentInputChange, // arbitrary, just for the sake of having values - should not override the nature of the actual change
  //     document,
  //     allConcernedBusinessDocuments,
  //     invoicingBalanceLimits,
  // );


  return newBusinessDocumentAndUIInstallmentValuesAfterInstallmentChange.newBusinessDocument
}

/**
 * Generate related business documents
 * @param latestBusinessDocumentWithChosenDeal
 * @param newBusinessDocumentId
 * @param newBusinessDocumentKind
 * @param newBusinessDocumentTaxonomyTags
 * @param newBusinessDocumentNumber
 * @param newBusinessDocumentSource
 * @param newBusinessDocumentIssuedDate
 * @param now
 */
function generateRelatedBusinessDocumentsForNewPartialOrBalanceInvoice(
  latestBusinessDocumentWithChosenDeal: BusinessDocument,
  newBusinessDocumentId: string,
  newBusinessDocumentKind: BusinessDocumentKind,
  newBusinessDocumentTaxonomyTags: TaxonomyTag[],
  newBusinessDocumentNumber: string,
  newBusinessDocumentSource: BusinessDocumentSource,
  newBusinessDocumentIssuedDate: ExchangeDate,
  now: Date,
): BusinessDocumentRelation[] {
  let newRelatedBusinessDocuments: BusinessDocumentRelation[] = []

  if (!!latestBusinessDocumentWithChosenDeal.relatedBusinessDocuments) {
    newRelatedBusinessDocuments = deepClone(latestBusinessDocumentWithChosenDeal.relatedBusinessDocuments)
  }

  let newRelationKind: BusinessDocumentRelationKind = BusinessDocumentRelationKind.TBD
  switch (latestBusinessDocumentWithChosenDeal.businessDocumentKind) {
    case BusinessDocumentKind.INVOICE: // the "FROM" business document
      switch (newBusinessDocumentKind) {
        case BusinessDocumentKind.INVOICE: // the "TO" business document
          newRelationKind = BusinessDocumentRelationKind.INVOICE_ON_INVOICE
          break
        case BusinessDocumentKind.CREDITNOTE: // the "TO" business document
          newRelationKind = BusinessDocumentRelationKind.CREDIT_NOTE_ON_INVOICE
          break
        default: // the "TO" business document
          newRelationKind = BusinessDocumentRelationKind.UNKNOWN_ON_INVOICE
          break
      }
      break
    case BusinessDocumentKind.CREDITNOTE: // the "FROM" business document
      switch (newBusinessDocumentKind) {
        case BusinessDocumentKind.INVOICE: // the "TO" business document
          newRelationKind = BusinessDocumentRelationKind.INVOICE_ON_CREDIT_NOTE
          break
        case BusinessDocumentKind.CREDITNOTE: // the "TO" business document
          newRelationKind = BusinessDocumentRelationKind.CREDIT_NOTE_ON_CREDIT_NOTE
          break
        default: // the "TO" business document
          newRelationKind = BusinessDocumentRelationKind.UNKNOWN_ON_CREDIT_NOTE
          break
      }
      break
    default: // the "FROM" business document
      newRelationKind = BusinessDocumentRelationKind.WHATEVER_ON_UNKNOWN
      break
  }

  // The only legitimate ways to create a new business document in the same deal as a series of business documents...
  // NB: creating a credit note is directly done on the business document to void
  const newRelationKindIsCorrect: boolean = [
    BusinessDocumentRelationKind.INVOICE_ON_INVOICE,
    BusinessDocumentRelationKind.INVOICE_ON_CREDIT_NOTE
  ].includes(newRelationKind)
  if (!newRelationKindIsCorrect) {
    const errMsg: string = 'impossible to determine a correct relation kind when creating balance business document'
    /* console.error(errMsg) */
    throw new Error(errMsg)
  }

  const newRelatedBusinessDocument: BusinessDocumentRelation = {
    fromBusinessDocumentId: latestBusinessDocumentWithChosenDeal.businessDocumentId,
    fromBusinessDocumentKind: latestBusinessDocumentWithChosenDeal.businessDocumentKind,
    fromBusinessDocumentTaxonomyTags: latestBusinessDocumentWithChosenDeal.taxonomyTags,
    fromBusinessDocumentNumber: latestBusinessDocumentWithChosenDeal.businessDocumentNumber,
    fromBusinessDocumentSource: latestBusinessDocumentWithChosenDeal.businessDocumentSource,
    fromBusinessDocumentIssuedDate: latestBusinessDocumentWithChosenDeal.issuedDate,
    toBusinessDocumentId: newBusinessDocumentId,
    toBusinessDocumentKind: newBusinessDocumentKind,
    toBusinessDocumentTaxonomyTags: newBusinessDocumentTaxonomyTags,
    toBusinessDocumentNumber: newBusinessDocumentNumber,
    toBusinessDocumentSource: newBusinessDocumentSource,
    toBusinessDocumentIssuedDate: newBusinessDocumentIssuedDate,
    relationKind: newRelationKind,
    createdDate: ExchangeDate.newDate(now)
  }
  newRelatedBusinessDocuments.push(newRelatedBusinessDocument)

  return newRelatedBusinessDocuments
}

<script lang="ts">
  import {formatCurrencyWithOptions} from '$voxy/utils/number-formatting-utils'
  import {t} from '../i18n/i18nextWrapper'
  import * as d3 from 'd3'
  import {onMount} from 'svelte'
  import {navigate} from "svelte-routing"
  import {v4 as uuidv4} from 'uuid'
  import {ValueForCurrentMonthAlongWithVariationMonthToMonth} from "$core/models/dashboard-statistics-analytics-model";
  import StatPill from "$bank/components/stats/StatPill.svelte";

  /** Export let */
  export let currency: string
  export let title: string
  export let data: ValueForCurrentMonthAlongWithVariationMonthToMonth
  export let isBankConfigLoaded: boolean = false
  export let hasTooltip: boolean = false
  export let svgData: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

  /** Local variables */
  let id = uuidv4()
  let svg: SVGElement
  let gradientId: string = `graph-gradient-${id}-${data.variationFavorability}`

  /** Reactive declarations */
  $: if (svgData || isBankConfigLoaded) {
    generateKPISVG()
  }

  const generateKPISVG = () => {
    d3.select(svg)
      .selectAll('*').remove()

    const width: number = 300
    const height: number = 70

    const gradientColors: any = {
      'favorable': ['#40FFCE', '#40FFCE00'],
      'unfavorable': ['#F97676', '#F9767600'],
      'neutral': ['#60A5FA', '#60A5FA00']
    }

    // Ensure the months are ordered chronologically
    const orderedMonths: string[] = Object.keys(svgData).sort((a, b) =>
      +new Date(+a.split('-')[0], +a.split('-')[1] - 1) - +new Date(+b.split('-')[0], +b.split('-')[1] - 1),
    )

    const dataArray: any[] = orderedMonths.map(month => svgData[month])

    const color: any = gradientColors[data?.variationFavorability]

    const xScale = d3.scaleLinear()
      .domain([0, dataArray?.length - 1])
      .range([0, width])

    const yDomain = d3.extent(dataArray);
    const yPadding = (yDomain[1] - yDomain[0]) * 0.2;
    const yScale = d3.scaleLinear()
      .domain([yDomain[0] - yPadding, yDomain[1] + yPadding])
      .range([height, 0])

    const line = d3.line()
      .x((d, i) => xScale(i))
      .y(d => yScale(d))
      .curve(d3.curveBasis)

    d3.select(svg)
      .selectAll('*').remove()

    d3.select(svg)
      .append('path')
      .datum(dataArray)
      .attr('fill', 'none')
      .attr('stroke', color[0])
      .attr('stroke-width', '2')
      .attr('stroke-linecap', 'round')
      .attr('d', line)
  }

  onMount(() => {
    generateKPISVG()
  })
</script>

<div class="grid-item-wrapper">
  <div class="grid-item-main flex-1">
    <div class="grid-item" aria-label="Grid item">
      <div class="relative z-10 w-full">
        <div class="w-full flex items-center justify-between">
          <div class="flex items-center space-x-1">
            <dt class="text-snuff truncate -mt-1">{title}</dt>
            {#if hasTooltip}
              <div class="z-[600]">
                <slot name="tooltip"></slot>
              </div>
            {/if}
          </div>
          {#if data?.variationFavorability !== 'neutral'}
            <dd class="-mt-1">
              <StatPill data={data}/>
            </dd>
          {/if}
        </div>

        <div class="relative w-full z-20 min-h-[70px] my-2 " class:hidden={!isBankConfigLoaded}>
          <svg bind:this={svg}
               id={gradientId}
               class="absolute w-full h-full z-0"
               viewBox="0 0 300 70"
               preserveAspectRatio="none"
               style="visibility: {isBankConfigLoaded ? 'visible' : 'hidden'};"></svg>
        </div>

        {#if isBankConfigLoaded}
          <div>
            <dd class="w-full flex-none text-3xl leading-10 text-white truncate">
              {formatCurrencyWithOptions(data?.currentMonthValue, t('locales'), currency, false, 'symbol')}
            </dd>
            <dd class="w-full flex text-sm leading-6 space-x-1">
              <span class="text-paleSky">{t('transactions.lastMonth')}</span>
              <span
                class="text-white">{formatCurrencyWithOptions(data?.previousMonthValue, t('locales'), currency, false, 'symbol')}</span>
            </dd>
          </div>
        {:else}
          <div class="cursor-pointer" on:click={() => navigate('/settings/bank/view')}>
            <img src="/img/dashboard/connect-bank-grid.png" alt="Connect" class="bank-connect-image"/>
            <p class="bank-connect-copy"><span
              class="bank-connect-highlight">{t('dashboard.gridSoloItem.noBank.copy_highlight')}</span>
              {t('dashboard.gridSoloItem.noBank.copy_regular')}</p>
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>

<style lang="postcss">
  .grid-item-wrapper {
    @apply relative flex flex-row items-baseline justify-between gap-2 px-4 py-6 sm:px-8 shadow-md bg-black flex-auto lg:max-w-[300px] xl:min-w-[280px] xl:max-w-fit;
    transition: all 300ms ease-in-out;
    border-radius: 20px;
  }

  .bank-connect-image {
    @apply h-[62px] mx-auto my-3;
  }

  .bank-connect-copy {
    @apply text-center text-white text-s mt-2;
  }

  .bank-connect-highlight {
    @apply font-bold text-sm;
    color: #FFB42D;
  }
</style>

export enum BusinessDocumentKind {
  QUOTE = 'Quote',
  PURCHASEORDER = 'PurchaseOrder',
  INVOICE = 'Invoice',
  CREDITNOTE = 'CreditNote'
}

export const GetAllBusinessDocumentKinds = ():Array<BusinessDocumentKind>=>[
  BusinessDocumentKind.QUOTE,
  BusinessDocumentKind.PURCHASEORDER,
  BusinessDocumentKind.INVOICE,
  BusinessDocumentKind.CREDITNOTE
]

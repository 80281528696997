import { LegalIdType } from './legal-id-type'
import { t } from '../../core-app/lib/i18n/i18nextWrapper'
import { JsonProperty } from '@paddls/ts-serializer'


export default class CompanyLegalIdentification {
  @JsonProperty('legalIdentificationType') legalIdentificationType: string
  @JsonProperty('legalIdentificationNumber') legalIdentificationNumber: string
}

export const companyLegalId_labels = () => {
  let companyLegalIdsMap = {}
  companyLegalIdsMap[LegalIdType.EUROPEAN_VAT.toString()] =
        {
          labelKey: LegalIdType.EUROPEAN_VAT.toString(),
          labelName: t('companyLegalIdentification.europeanVAT'),
          labelId: LegalIdType.EUROPEAN_VAT.toString() + '-label'
        }
  companyLegalIdsMap[LegalIdType.DUNS.toString()] =
        {
          labelKey: LegalIdType.DUNS.toString(),
          labelName: t('companyLegalIdentification.duns'),
          labelId: LegalIdType.DUNS.toString() + '-label'
        }
  companyLegalIdsMap[LegalIdType.SIREN_SIRET.toString()] =
        {
          labelKey: LegalIdType.SIREN_SIRET.toString(),
          labelName: t('companyLegalIdentification.sirenSiret'),
          labelId: LegalIdType.SIREN_SIRET.toString() + '-label'
        }
  
  return companyLegalIdsMap
}

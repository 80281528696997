<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import { navigate } from 'svelte-routing'
    import type { Workspace } from '../../models/workspace'
    import { NewWorkspaceRequestedByUserWorkspaceId } from '../../stores/workspace.store'

    export let classes: string
    export let workspace: Workspace

</script>

<div class="flex {classes}">
    {#if workspace && workspace.bankConfig && workspace.bankConfig?.selectedAccountBankName}
        <div class="flex flex-col">
            <span class="text-sm">{`${ workspace.bankConfig?.selectedAccountBankName || '' }`}</span>
            <span class="text-xxs text-dundyOrange">{`${ workspace.bankConfig?.selectedAccountName || '' }`}</span>
        </div>
    {:else}
        <div class="flex flex-wrap content-center justify-center w-8 h-8 text-base mr-2">
            <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                <path d="m9.983.746.144.135 4.992 4.992a3.008 3.008 0 0 1 .135 4.11l-.135.144-4.992 4.992-.144.135a3.008 3.008 0 0 1-3.966 0l-.144-.135-4.992-4.992-.135-.144a3.008 3.008 0 0 1 0-3.966l.135-.144L5.873.881l.144-.135a3.008 3.008 0 0 1 3.966 0ZM7.048 1.838l-.114.104-4.992 4.992-.104.114c-.45.553-.45 1.351 0 1.904l.104.114 4.992 4.992a1.508 1.508 0 0 0 2.018.104l.114-.104 4.992-4.992.104-.114c.45-.553.45-1.351 0-1.904l-.104-.114-4.992-4.992-.114-.104a1.508 1.508 0 0 0-1.904 0Zm.962 8.412.102.007a.75.75 0 0 1 0 1.486l-.102.007H8l-.102-.007a.75.75 0 0 1 0-1.486L8 10.25h.01ZM8 4a.75.75 0 0 1 .743.648l.007.102v3.5a.75.75 0 0 1-1.493.102L7.25 8.25v-3.5A.75.75 0 0 1 8 4Z"
                      fill="#DE496D" fill-rule="nonzero"/>
            </svg>
        </div>
        <div
                class="flex flex-col cursor-pointer"
                role="button"
                tabindex="0"
                on:click={e => {
                  e.stopPropagation()
                  $NewWorkspaceRequestedByUserWorkspaceId = workspace.workspaceId
                  navigate('/settings/bank/view')
                }}
                on:keydown={e => {(e.key === 'Enter' || e.key === ' ') && e.target.click()}}
        >
            <span class="text-sm">{t('missingBankAccount.text')}</span>
            <span class="text-xxs text-cabaret">{t('missingBankAccount.link')}</span>
        </div>
    {/if}
</div>
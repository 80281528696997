import {
  businessDocumentAttachmentDownload
} from '../../voxy-app/services/pdf-file-mgt/business-document-pdf-attachment.api'
import { get } from 'svelte/store'
import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
import { SimpleDocumentsStore } from '../../voxy-app/stores/business-documents.store'
import JSZip from 'jszip'
import type StorageBucketObject from '../../file-mgt-domain/models/storage-bucket-object'
import { BusinessDocumentStatus } from '../../voxy-app/enums/business-document-status'
import { BusinessDocument } from '../../voxy-app/models/business-document'

/**
 * Generate a ZIP file from a list of invoices and credit-notes
 * @param startDate
 * @param endDate
 */
export const generateAndDownloadZIPFile = async (startDate?: string, endDate?: string): Promise<boolean> => {

  const businessDocuments: BusinessDocument[] = get(SimpleDocumentsStore).filter((d: BusinessDocument) => {
    let res: boolean = d.businessDocumentStatus === BusinessDocumentStatus.FINAL

    const dte: string = d.createdDate.rfc3339.toString().substring(0, 10).replaceAll('-', '')
    if (res && startDate) res = dte >= startDate
    if (res && endDate) res = dte <= endDate

    return res
  })


  try {
    const zip: JSZip = new JSZip()

    if (businessDocuments.length === 0) return false

    for (const doc of businessDocuments) {
      const res: StorageBucketObject = await businessDocumentAttachmentDownload(doc.businessDocumentNumber, doc.businessDocumentId, get(WorkspaceStore).workspaceId)

      zip.file(res.fileName, res.contentBase64, { base64: true })
    }

    const blob: Blob = await zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: {
        level: 9
      }
    })

    const link: HTMLAnchorElement = document.createElement('a')
    if (link.download !== undefined) {
      const url: string = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', 'export.zip')
      link.style.visibility = 'hidden'
      document.documentElement.appendChild(link)
      link.click()
      document.documentElement.removeChild(link)
    }

    return true
  } catch (err) {
    console.error('generateZipFile', err)
  }
}

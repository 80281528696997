import { APICallOptions, APIEntity, apiGet, apiPost } from '$src/core-app/services/api.service'
import { authZJsUserId, userUnderAuth0JsClient } from '$core/lib/auth0authentication/authStore'
import { ExchangeDate } from '$src/core-app/models/exchange-date'
import { get } from 'svelte/store'
import { v4 as uuidv4 } from 'uuid'
import { WorkspaceStore } from '$src/crm-app/stores/workspace.store'
import type { BusinessDocument } from '$src/voxy-app/models/business-document'
import { remoteDebuggingService } from '$src/core-app/services/remote-debugging.service'
import { dateIsBefore } from '../utils/date'

const urlSendLog:string = '/monitoring/totum-user-event'
const urlSendJourney:string = '/monitoring/totum-stacked-user-journey'
const urlGetJourney:string = '/monitoring/totum-stacked-user-journeys'

type ClientSpecs = {
  browser: {
    name: string
    version: string
    fullVersion: string
    language: string
    cookieEnabled: boolean
    timeZone: string
  }
  screen: {
    dpi: number
    screenWidthInPixels: number
    screenHeightInPixels: number
    screenWidthInCm: number
    screenHeightInCm: number
    screenDiagonalInPixels: number
    screenDiagonalInInches: number
    windowWidth: number
    windowHeight: number
    orientation: string
  }
  os: string
  device: string
}

type TotumUserActivityEvent = {
  eventId: string;
  eventKind: string;
  timestamp: ExchangeDate;
  timeZoneIANACode: string;
  workspaceId: string;
  workspaceCompanyName: string;
  userId: string;
  userFirstName: string;
  userLastName: string;
  userEmail: string;

  // for BusinessDocument only:
  businessDocumentId?: string;
  businessDocumentNumber?: string;

  // for CompanySearched only:
  companySearchedInputPattern?: string;
  companySearchedOutputResult?: unknown;

  // client specs
  clientSpecs?: ClientSpecs;

  // createdAt: Date; // will be set by back-end
}

type TotumStackedUserJourney = {
  logId: string; // mandatory and unique (uuid)
  sessionId: string; // mandatory (uuid)
  packetId: number; // mandatory (allows to have a managed maximum nb of items per TotumStackedUserJourney)
  replacesLogId?: string;// only if this log simply adds an item to a former one (allows to only keep latest TotumStackedUserJourney)
  timestamp: ExchangeDate;
  timeZoneIANACode: string;
  workspaceId: string;
  workspaceCompanyName: string;
  userId: string;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  journey: TotumUserActivityEvent[];
  
  // createdAt: Date; // will be set by back-end
}

export type CustomLogOptions = {
  businessDocument?: BusinessDocument,
  companySearchedInput?: string
  companySearchedOutput?: unknown,
  clientSpecs?: ClientSpecs
}

export type CustomLog = {
  eventKind: string,
  options?: CustomLogOptions
}

/**
 * SEND CUSTOM LOG
 * @param totumUserActivityEvent 
 */
const sendLog = (log:TotumUserActivityEvent):Promise<void> => apiPost(urlSendLog, log,
    <APICallOptions>{
      entity: APIEntity.TOTUM_USER_EVENT,
      ignoreFeedback: true
    }
)

/**
 * SEND JOURNEY
 * @param journey 
 */
const sendJourney = (journey:TotumStackedUserJourney):Promise<void> => apiPost(urlSendJourney, journey,
    <APICallOptions>{
      entity: APIEntity.TOTUM_USER_EVENT,
      ignoreFeedback: true
    }
)

/**
 * APPEND CUSTOM LOG TO JOURNEY
 * @param log 
 */
const appendLogToJourney = (log: TotumUserActivityEvent):Promise<void> => {
  // Get max item from config
  let maxLogPerJourney:number = 1000
  try {
    maxLogPerJourney = Number(process.env.CUSTOM_LOG_JOURNEY_MAX_ITEMS) ?? 1000
  } catch (e) {
    remoteDebuggingService.addInfo(e, 'error')
  }

  // Load from local storage if exist
  let prevJourneyLog: TotumStackedUserJourney = {} as TotumStackedUserJourney
  let prevJourneyLogStorage = localStorage.getItem('journey')
  if (prevJourneyLogStorage) prevJourneyLog = JSON.parse(prevJourneyLogStorage)

  let isNewPacket:boolean = false
  let packetId:number = 1
  let journeyLogs:TotumUserActivityEvent[] = [log]

  // JOURNEY RULES
  if (prevJourneyLog?.sessionId) {
    // RULE 1 : New Journey (timestamp)
    if (dateIsBefore(new Date(prevJourneyLog.timestamp.rfc3339), new Date(), true)) {
      packetId = 1
      journeyLogs = [log]
    } else {
      // RULE 2 : Existing previous journey log
      // Max log per journey reached
      if (prevJourneyLog.journey.length >= maxLogPerJourney) {
        packetId = packetId + 1
        isNewPacket = true
      } else {
      // Max log per journey not reached
        journeyLogs = [...prevJourneyLog.journey, log]
      }
    }
  }

  const date = new Date()

  const journey:TotumStackedUserJourney = {
    logId: uuidv4(),
    sessionId: prevJourneyLog?.sessionId ?? uuidv4(),
    packetId,
    replacesLogId: isNewPacket ? '' : prevJourneyLog?.logId,
    timestamp: ExchangeDate.newDate(date),
    timeZoneIANACode: log?.timeZoneIANACode,
    workspaceId: log?.workspaceId,
    workspaceCompanyName: log?.workspaceCompanyName,
    userId: log?.userId,
    userFirstName: log?.userFirstName,
    userLastName: log?.userLastName,
    userEmail: log?.userEmail,
    journey: journeyLogs
  }

  localStorage.setItem('journey', JSON.stringify(journey))
  
  return sendJourney(journey)
}

/**
 * SEND CUSTOM LOG
 * @param eventKind 
 * @param options 
 * @returns 
 */
const log = (eventKind: string, options?: CustomLogOptions):Promise<unknown> => {
  // Check config if log is allow
  if (!process.env.CUSTOM_LOG_ENABLED) return new Promise(resolve => resolve(null))

  const date = new Date()

  const totumUserActivityEvent:TotumUserActivityEvent = {
    eventId: uuidv4(),
    eventKind,
    timestamp: ExchangeDate.newDate(date),
    timeZoneIANACode: '',
    workspaceId: '',
    workspaceCompanyName: '',
    userId: '',
    userFirstName: '',
    userLastName: '',
    userEmail: ''
  }
  
  const workspace = get(WorkspaceStore)
  if (workspace?.workspaceId) totumUserActivityEvent.workspaceId = workspace.workspaceId
  if (workspace?.company?.formalName) totumUserActivityEvent.workspaceCompanyName = workspace.company.formalName
  if (workspace?.company?.timeZoneIANACode) totumUserActivityEvent.timeZoneIANACode = workspace.company.timeZoneIANACode
  if (!(totumUserActivityEvent.workspaceId || totumUserActivityEvent.workspaceCompanyName || totumUserActivityEvent.timeZoneIANACode)) return new Promise(resolve => resolve(null))
    
  const user = get(userUnderAuth0JsClient)
  if (user?.given_name) totumUserActivityEvent.userFirstName = user.given_name
  if (user?.family_name) totumUserActivityEvent.userLastName = user.family_name
  if (user?.email) totumUserActivityEvent.userEmail = user.email
  if (!(totumUserActivityEvent.userFirstName || totumUserActivityEvent.userLastName || totumUserActivityEvent.userEmail)) return new Promise(resolve => resolve(null))

  const userId = get(authZJsUserId)
  if (!userId) return new Promise(resolve => resolve(null))
  if (userId) totumUserActivityEvent.userId = userId

  if (options?.businessDocument?.businessDocumentId) totumUserActivityEvent.businessDocumentId = options.businessDocument.businessDocumentId
  if (options?.businessDocument?.businessDocumentId) totumUserActivityEvent.businessDocumentId = options.businessDocument.businessDocumentId

  if (options?.companySearchedInput) totumUserActivityEvent.companySearchedInputPattern = options.companySearchedInput
  if (options?.companySearchedOutput) totumUserActivityEvent.companySearchedOutputResult = options.companySearchedOutput

  if (options?.clientSpecs) totumUserActivityEvent.clientSpecs = options.clientSpecs
  
  return Promise.all([
    sendLog(totumUserActivityEvent),
    appendLogToJourney(totumUserActivityEvent)
  ])
}

/**
 * REMOVE CURRENT JOURNEY
 */
const closeJourney = async (eventKind?: string):Promise<void> => {
  if (eventKind) await log(eventKind)
  
  return localStorage.removeItem('journey')
}


const getJourney = async (count:number = 1):Promise<any> => 
  apiGet(`${urlGetJourney}/last/${count}`,
    <APICallOptions>{
      entity: APIEntity.TOTUM_USER_EVENT,
      ignoreFeedback: true
    }
  )

/**
 * MAIN EXPORT
 */
const customLog = {
  log,
  closeJourney,
  getJourney
}

export default customLog

import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
import type { CellClassParams, ColDef, ValueSetterParams } from 'ag-grid-community'
import DatatableCustomerRenderer from '../../../../crm-app/lib/customer/DatatableCustomerRenderer'
import DatatableCustomerEditor from '../../../../crm-app/lib/customer/DatatableCustomerEditor'
import { agGridCommonService } from '../../../services/ag-grid-common.service'
import { ValidationLevel } from '../../../models/validation-level'
import InvoiceNumberRenderer from './InvoiceNumberRenderer'
import DatatableDateRenderer from '../../../../core-app/lib/ui-kit/DatatableDateRenderer'
import DatatableDateEditor from '../../../../core-app/lib/ui-kit/DatatableDateEditor'
import DunningStatusRenderer from './DunningStatusRenderer'
import { DunningInvoicesHelper } from '../../../helpers/dunning-invoices-helper'
import DatatableNumberEditor from '../../../../core-app/lib/ui-kit/DatatableNumberEditor'
import DatatableAmountOfMoneyRendererWithoutCurrencySymbol
  from '../../../../core-app/lib/ui-kit/DatatableAmountOfMoneyRendererWithCurrencyInLetter'
import DatatableInvoiceActionsRenderer from './DatatableInvoiceActionsRenderer'
import DatatableFollowUpRenderer from './DatatableFollowUpRenderer'
import { type ICellRendererParams } from 'ag-grid-community'

export const dunningColumnDefs: ColDef[] = [
  {
    headerName: 'Did',
    field: 'did',
    sortable: false,
    // hide: false,
    // suppressColumnsToolPanel: false,
    hide: true,
    suppressColumnsToolPanel: true
  },
  {
    headerName: t('dunning.columns.client'),
    field: 'toId',
    cellRenderer: DatatableCustomerRenderer,
    cellEditor: DatatableCustomerEditor,
    sortable: true,
    editable: agGridCommonService.isAnEditableCell,
    cellClassRules: {
      'has-info': (params: CellClassParams) => agGridCommonService.propertyHasFeedbackLevel(params.data, 'toId', ValidationLevel.INFO) ||
                agGridCommonService.propertyHasFeedbackLevel(params.data, 'toName', ValidationLevel.INFO),
      'has-warning': (params: CellClassParams) => agGridCommonService.propertyHasFeedbackLevel(params.data, 'toId', ValidationLevel.WARN) ||
                agGridCommonService.propertyHasFeedbackLevel(params.data, 'toName', ValidationLevel.WARN),
      'has-error': (params: CellClassParams) => agGridCommonService.propertyHasFeedbackLevel(params.data, 'toId', ValidationLevel.ERROR) ||
                agGridCommonService.propertyHasFeedbackLevel(params.data, 'toName', ValidationLevel.ERROR),
      'font-medium truncate no-related-customer-item': (params: CellClassParams) => (!params.data.toId),
      'font-medium truncate with-related-customer-item': (params: CellClassParams) => (!!params.data.toId)
    },
    unSortIcon: true,
    maxWidth: 150
  },
  {
    headerName: 'toId',
    field: 'toId',
    cellClass: 'font-medium',
    sortable: true,
    editable: false,
    hide: true,
    suppressColumnsToolPanel: true
    // hide: false,
    // suppressColumnsToolPanel: false,
  },
  {
    headerName: 'completed',
    field: 'completed',
    cellClass: 'font-medium',
    sortable: true,
    editable: false,
    cellRenderer: (params: ICellRendererParams): string => params.value ? '[x]' : '[ ]',
    hide: true,
    suppressColumnsToolPanel: true
    // hide: false,
    // suppressColumnsToolPanel: false,
  },
  {
    headerName: 'tracked',
    field: 'isTracked',
    cellClass: 'font-medium',
    sortable: true,
    editable: false,
    cellRenderer: (params: ICellRendererParams): string => params.value ? '[x]' : '[ ]',
    hide: true,
    suppressColumnsToolPanel: true
  },
  {
    headerName: t('dunning.columns.customerName'),
    field: 'toName',
    // cellRenderer: DatatableCustomerRenderer,
    // cellEditor: DatatableCustomerEditor,
    sortable: false,
    editable: false,
    hide: true,
    suppressColumnsToolPanel: true
    // hide: false,
    // suppressColumnsToolPanel: false,
  },
  {
    headerName: t('dunning.columns.invoiceNumber'),
    field: 'invoiceNumber',
    cellRenderer: InvoiceNumberRenderer,
    cellClass: 'default',
    sortable: true,
    editable: agGridCommonService.isAnEditableCell,
    cellStyle: { 'text-overflow': 'ellipsis', 'white-space': 'nowrap', 'overflow': 'hidden' },
    cellClassRules: {
      'has-info': (params: CellClassParams) => agGridCommonService.propertyHasFeedbackLevel(params.data, 'invoiceNumber', ValidationLevel.INFO),
      'has-warning': (params: CellClassParams) => agGridCommonService.propertyHasFeedbackLevel(params.data, 'invoiceNumber', ValidationLevel.WARN),
      'has-error': (params: CellClassParams) => agGridCommonService.propertyHasFeedbackLevel(params.data, 'invoiceNumber', ValidationLevel.ERROR)
    },
    unSortIcon: true,
    maxWidth: 150
  },
  {
    headerName: t('dunning.columns.issuedDate'),
    field: 'dateIssued',
    cellClass: 'default',
    cellRenderer: DatatableDateRenderer,
    cellEditor: DatatableDateEditor,
    sortable: true,
    editable: agGridCommonService.isAnEditableCell,
    cellClassRules: {
      'has-info': (params: CellClassParams) => agGridCommonService.propertyHasFeedbackLevel(params.data, 'dateIssued', ValidationLevel.INFO),
      'has-warning': (params: CellClassParams) => agGridCommonService.propertyHasFeedbackLevel(params.data, 'dateIssued', ValidationLevel.WARN),
      'has-error': (params: CellClassParams) => agGridCommonService.propertyHasFeedbackLevel(params.data, 'dateIssued', ValidationLevel.ERROR)
    },
    unSortIcon: true,
    maxWidth: 130
  },
  {
    headerName: t('dunning.columns.dueDate'),
    field: 'dateDue',
    cellClass: 'default',
    cellRenderer: DatatableDateRenderer,
    cellEditor: DatatableDateEditor,
    sortable: true,
    editable: agGridCommonService.isAnEditableCell,
    cellClassRules: {
      'has-info': (params: CellClassParams) => agGridCommonService.propertyHasFeedbackLevel(params.data, 'dateDue', ValidationLevel.INFO),
      'has-warning': (params: CellClassParams) => agGridCommonService.propertyHasFeedbackLevel(params.data, 'dateDue', ValidationLevel.WARN),
      'has-error': (params: CellClassParams) => agGridCommonService.propertyHasFeedbackLevel(params.data, 'dateDue', ValidationLevel.ERROR)
    },
    unSortIcon: true,
    maxWidth: 130
  },
  {
    headerName: t('dunning.columns.status'),
    field: 'status',
    cellRenderer: DunningStatusRenderer,
    sortable: false,
    cellClassRules: {
      'status-success-outlined': (params: CellClassParams): boolean => DunningInvoicesHelper.updateRenderedValue(params).newElementClass.indexOf('success-outlined') > -1,
      'status-warning-outlined': (params: CellClassParams): boolean => DunningInvoicesHelper.updateRenderedValue(params).newElementClass.indexOf('warning-outlined') > -1,
      'status-success': (params: CellClassParams): boolean => DunningInvoicesHelper.updateRenderedValue(params).newElementClass.indexOf('success') > -1,
      'status-error': (params: CellClassParams): boolean => DunningInvoicesHelper.updateRenderedValue(params).newElementClass.indexOf('error') > -1,
      'status-warning': (params: CellClassParams): boolean => DunningInvoicesHelper.updateRenderedValue(params).newElementClass.indexOf('warning') > -1
    },
    width: 130
  },
  {
    headerName: t('dunning.columns.amount'),
    field: 'amountIncludingTaxes',
    cellClass: 'ag-right-aligned-cell',
    cellEditor: DatatableNumberEditor,
    cellRenderer: DatatableAmountOfMoneyRendererWithoutCurrencySymbol,
    cellStyle: { justifyContent: 'flex-end' },
    valueSetter: (params: ValueSetterParams) => DunningInvoicesHelper.numberSetter(params, 'amountIncludingTaxes'),
    sortable: true,
    editable: agGridCommonService.isAnEditableCell,
    cellClassRules: {
      'has-info': (params: CellClassParams) => agGridCommonService.propertyHasFeedbackLevel(params.data, 'amountIncludingTaxes', ValidationLevel.INFO),
      'has-warning': (params: CellClassParams) => agGridCommonService.propertyHasFeedbackLevel(params.data, 'amountIncludingTaxes', ValidationLevel.WARN),
      'has-error': (params: CellClassParams) => agGridCommonService.propertyHasFeedbackLevel(params.data, 'amountIncludingTaxes', ValidationLevel.ERROR)
    },
    unSortIcon: true,
    maxWidth: 160,
    type: 'rightAligned'
  },
  {
    headerName: t('dunning.columns.followUp'),
    field: 'follow-up',
    cellRenderer: DatatableFollowUpRenderer,
    width: 230, // Set the desired width value here,
    flex: 1
  },
  {
    headerName: '',
    field: 'actions',
    cellClass: 'default',
    cellRenderer: DatatableInvoiceActionsRenderer,
    sortable: false,
    width: 20,
    maxWidth: 20,
    minWidth: 20
  }
]
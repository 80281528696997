<script lang="ts">
    import { createEventDispatcher, onMount } from 'svelte'
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper.js'
    import Tooltip from '../../../core-app/lib/ui-kit/Tooltip/Tooltip.svelte'
    import type { Contact } from '../../models/contact'
    import { navigate } from 'svelte-routing'


    export let contact: Contact

    /** local declarations */
    const dispatch = createEventDispatcher()

/*    let fetchUserProfile = apiGet<TeamUserProfile>(
            "/workspaces/" +
            $WorkspaceStore.workspaceId +
            "/team-users/" +
            teamUserId +
            "/user-info"
    );*/

    let isLoading = true

    onMount(() => {
      setTimeout(() => {
        isLoading = false
      }, 1000)
    })


</script>

<tr>
    {#if isLoading}
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div class="flex items-center">
                <div class="flex flex-wrap content-center justify-center h-10 w-10 bg-zinc-100 text-white rounded-full mr-3 flex-shrink-0"></div>
            </div>
        </td>
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div class="mr-auto">
                <p class="w-40 h-3 mb-3 bg-athensGray rounded"/>
                <p class="w-32 h-2 bg-athensGray rounded"/>
            </div>
        </td>
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div class="mr-auto">
                <p class="w-40 h-2 mb-3 bg-athensGray rounded"/>
                <p class="w-32 h-2 bg-athensGray rounded"/>
            </div>
        </td>
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div class="mr-auto">
                <p class="w-16   h-2 bg-athensGray rounded"/>
            </div>
        </td>

    {:else}
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div class="flex items-center">
                <div class="flex flex-wrap content-center justify-center h-10 w-10 text-white rounded-full bg-zinc-300 mr-3 flex-shrink-0">
                    <span>{contact.firstName.substring(0, 1).toUpperCase()}{contact.lastName.substring(0, 1).toUpperCase()}</span>
                </div>
                <div class="ml-4">
                    <div class="font-medium text-gray-900">{contact.firstName} {contact.lastName}</div>
                    <div class="text-gray-400">{contact.email}</div>
                </div>
            </div>
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <div class="text-gray-900 capitalize">{contact.position ? contact.position : ''}</div>
            <div class="text-gray-400">{contact.department ? contact.department : ''}</div>
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {#if (contact.isWorkspaceMember && contact.email)}
                <span class="inline-flex rounded-md bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">{t('team.listMembers.content.status.active')}</span>
            {:else if (contact.isWorkspaceMember)}
                <span class="inline-flex items-center rounded-md bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-800">{t('team.listMembers.content.status.inactive')}</span>
            {:else}
                <span class="inline-flex items-center rounded-md px-2.5 py-0.5 text-xs font-medium text-gray-800">-</span>
            {/if}
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
            {#if (contact.isWorkspaceMember)}
                {t('userRoles.member')}
            {:else}
                <span class="inline-flex items-center rounded-md px-2.5 py-0.5 text-xs font-medium text-gray-800">-</span>
            {/if}
        </td>
        <td class="relative flex items-center justify-end whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
            <Tooltip>
                <div slot="activator">
                    <button on:click={() => navigate(`/team/manage/${contact?.contactId}`)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.75 19.25L9 18.25L18.9491 8.30083C19.3397 7.9103 19.3397 7.27714 18.9491 6.88661L17.1134 5.05083C16.7228 4.6603 16.0897 4.6603 15.6991 5.05083L5.75 15L4.75 19.25Z"
                                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path d="M14.0234 7.03906L17.0234 10.0391" stroke="currentColor" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>

                        <span class="sr-only">Edit {contact.firstName} {contact.lastName}</span>
                    </button>
                </div>
                {t('team.listMembers.actions.edit')}
            </Tooltip>

            <Tooltip>
                <div slot="activator">
                    <button on:click={() => dispatch('delete')}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.75 7.75L6.59115 17.4233C6.68102 18.4568 7.54622 19.25 8.58363 19.25H14.4164C15.4538 19.25 16.319 18.4568 16.4088 17.4233L17.25 7.75H5.75Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M9.75 10.75V16.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M13.25 10.75V16.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M8.75 7.75V6.75C8.75 5.64543 9.64543 4.75 10.75 4.75H12.25C13.3546 4.75 14.25 5.64543 14.25 6.75V7.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M4.75 7.75H18.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>

                        <span class="sr-only">Delete {contact.firstName} {contact.lastName}</span>
                    </button>
                </div>
                {t('team.listMembers.actions.delete')}
            </Tooltip>
        </td>
    {/if}
</tr>


<style lang="postcss">
    button {
        @apply rounded border-2 border-transparent p-2;
        display: inherit;
    }

    button:active,
    button:hover {
        @apply bg-provincialPink border-dundyOrange;
    }
</style>

import { get } from "svelte/store";
import { WorkspaceStore } from "$crm/stores/workspace.store";
import { APICallOptions, apiDelete, APIEntity, apiGet, apiPost } from "$core/services/api.service";
import { BBBItemsResp, BBBItemsRespData } from "$bank/models/bbb-item-model";
import type BBBConnectionProcessUrlResponse from "$pay/models/bbb-connection-process-url-response-model";
import mixpanel from "mixpanel-browser";
import type { BBBBankAccount, BBBTransactionsResponse } from "$bank/models/bbb-transactions-model";
import { BBBBankAccountsListResp } from "$bank/models/bbb-bank-accounts-list-model";
import { BridgeUserModelResponse } from "$bank/models/bbb-user-response";
import type PaymentLinkResponse from "$pay/models/payment-link-model";

class BBBAPIPrimitivesServiceClass {

  /******************************************************************
    BASICS
   ******************************************************************/

  // bbbAPIPrimitivesService.getCurrentWorkspaceIdForBBB
  getCurrentWorkspaceIdForBBB(): string {
    return get(WorkspaceStore)?.workspaceId || ""
  }

  // bbbAPIPrimitivesService.getBBBUserEmailForCurrentWorkspace
  getBBBUserEmailForCurrentWorkspace(): string {
    const userItemId: string = "wk"+this.getCurrentWorkspaceIdForBBB().replaceAll("-","")+"@u-trade.io"// response.data.emailForBBB
    return userItemId
  }

  // bbbAPIPrimitivesService.getBBBUserCountryForCurrentWorkspace
  getBBBUserCountryForCurrentWorkspace(): string {
    const countryCode: string = get(WorkspaceStore).company?.mailAddress?.countryCode?.toLowerCase()
    return countryCode
  }

  // bbbAPIPrimitivesService.getBBBCurrentSelectedBankAccountIBAN
  getBBBCurrentSelectedBankAccountIBAN(): string {
    const iban: string = get(WorkspaceStore)?.bankConfig?.selectedAccountBankIBAN || ""
    return iban
  }

  // bbbAPIPrimitivesService.getBBBCurrentBeneficiaryName
  getBBBCurrentBeneficiaryName(): string {
    const beneficiaryName: string = get(WorkspaceStore)?.company?.formalName || ""
    return beneficiaryName
  }

  // bbbAPIPrimitivesService.delay
  delay<T>(timeMilliseconds: number, passOnObject:T):Promise<T> {
    return new Promise<T>((resolve) => setTimeout(()=>{resolve(passOnObject)}, timeMilliseconds));
  }

  /******************************************************************
    BBB Bank Account Connection Process URL
   ******************************************************************/

  // bbbAPIPrimitivesService.getBBBUserConnectionFunnelURLWithDelaysPromise
  getBBBUserConnectionFunnelURLWithDelaysPromise(): Promise<BBBConnectionProcessUrlResponse> {
    return this.getBBBUserConnectionFunnelURLStandardized()
      .then((response:BBBConnectionProcessUrlResponse) => {
        return this.delay<BBBConnectionProcessUrlResponse>(1730,response)
      })
      .then((response:BBBConnectionProcessUrlResponse) => {
        /* console.log('%c bridgeByBankingService getConnectUrlForWorkspace() response', 'color: purple;', response) */
        return new Promise<BBBConnectionProcessUrlResponse>((resolve, reject) => {
          resolve(response)
        })
      })
  }

  // bbbAPIPrimitivesService.getBBBUserConnectionFunnelURLStandardized
  getBBBUserConnectionFunnelURLStandardized(): Promise<BBBConnectionProcessUrlResponse> {
    return this.getBBBUserConnectionFunnelURL(
      this.getBBBUserCountryForCurrentWorkspace(),
      this.getBBBUserEmailForCurrentWorkspace(),
      this.getCurrentWorkspaceIdForBBB())
  }

  // NB:
  // countryCode should always be getBBBUserCountryForCurrentWorkspace() (aka get(WorkspaceStore).company?.mailAddress?.countryCode?.toLowerCase()) for the sake of coherence and compliance
  // bbbUserEmail is the email arbitrarily created as bridge-by-bankin workspace user (erroneously used to be signed-in user email such as (<any>get(userUnderAuth0JsClient)).email but should be getBBBUserEmailForCurrentWorkspace() instead)
  private getBBBUserConnectionFunnelURL(countryCode: string, bbbUserEmail: string, workspaceId: string): Promise<BBBConnectionProcessUrlResponse> {
    return apiPost<BBBConnectionProcessUrlResponse>(`/workspaces-as-bbb-users/${workspaceId}/connect?sync=true`, {
      country: countryCode, // bbbAPIPrimitivesService.getBBBUserCountryForCurrentWorkspace() aka get(WorkspaceStore).company?.mailAddress?.countryCode?.toLowerCase(),
      email: bbbUserEmail, // bbbAPIPrimitivesService.getBBBUserEmailForCurrentWorkspace() instead of wrong (<any>get(userUnderAuth0JsClient)).email
    }, <APICallOptions>{
      entity: APIEntity.BBB_BANK_CONNECTION,
      dontRedirectToSignInOnUnAuthorized: true
    })
  }

  /******************************************************************
    Bank Connections Items
   ******************************************************************/

  // bbbAPIPrimitivesService.deleteBBBConnectionsItemsStandardized
  deleteBBBConnectionsItemsStandardized():Promise<void> {
    return this.deleteBBBConnectionsItems(this.getCurrentWorkspaceIdForBBB())
  }

  private deleteBBBConnectionsItems(workspaceId: string):Promise<void> {
    return apiDelete<void>(`/workspaces-as-bbb-users/${workspaceId}/items`,null, <APICallOptions>{
      entity: APIEntity.BBB_BANK_CONNECTION,
      dontRedirectToSignInOnUnAuthorized: true
    })
  }

  // bbbAPIPrimitivesService.getBBBWorkspaceBankConnectionsItemsStandardized
  getBBBWorkspaceBankConnectionsItemsStandardized():Promise<BBBItemsRespData> {
    return this.getBBBWorkspaceBankConnectionsItems(this.getCurrentWorkspaceIdForBBB())
  }

  private getBBBWorkspaceBankConnectionsItems(workspaceId: string):Promise<BBBItemsRespData> {
    return apiGet<BBBItemsResp>(`/workspaces-as-bbb-users/${workspaceId}/items`, <APICallOptions>{
      entity: APIEntity.BBB_BANK_CONNECTION,
      dontRedirectToSignInOnUnAuthorized: true
    }).then((response:BBBItemsResp) => {
        return this.delay<BBBItemsResp>(0 /*1730*/,response)
      })
      .then((response:BBBItemsResp) => {
        return response.data
      })
      .then((response:BBBItemsRespData) => {
        return this.delay<BBBItemsRespData>(0/*18410*/,response)
      })
      .then((response: BBBItemsRespData) => {
        return response
      })
  }

  /******************************************************************
   BBB User based upon Workspace
   ******************************************************************/

  // bbbAPIPrimitivesService.createBBBUserAsWorkspaceStandardized
  createBBBUserAsWorkspaceStandardized():Promise<string> {
    return this.createBBBUserAsWorkspace(this.getCurrentWorkspaceIdForBBB())
  }

  private createBBBUserAsWorkspace(workspaceId: string): Promise<string> {
    if(workspaceId !== ""){
      return apiPost<string>('/workspaces-as-bbb-users', { workspaceId: workspaceId }, <APICallOptions>{
        entity: APIEntity.BBB_BANK_CONNECTION,
        dontRedirectToSignInOnUnAuthorized: true
      })
        .then(response => {
          /* console.log('%c bridgeByBankingService createBBBUserAsWorkspace() response', 'color: purple;', response) */

          mixpanel.track('DB10 New Bank User for Workspace Successful', {
            'Description': 'Successfully created new bank user for workspace'
          })

          return response
        })
    } else {
      return Promise.reject("cannot create BBB user as workspace for undefined workspace")
    }
  }

  // bbbAPIPrimitivesService.getBBBUserAsWorkspaceStandardized
  getBBBUserAsWorkspaceStandardized():Promise<BridgeUserModelResponse> {
    return this.getBBBUserAsWorkspace(this.getCurrentWorkspaceIdForBBB())
  }

  private getBBBUserAsWorkspace(workspaceId: string):Promise<BridgeUserModelResponse>  {
    return apiGet<BridgeUserModelResponse>(`/workspaces-as-bbb-users/${workspaceId}?hideSecrets`, <APICallOptions>{
      entity: APIEntity.BBB_BANK_CONNECTION,
      ignoreFeedback: true,
      dontRedirectToSignInOnUnAuthorized: true
    })
      .then((response:BridgeUserModelResponse) => {
        /* console.log('%c bridgeByBankingService getBBBUserAsWorkspace() response', 'color: purple;', response) */
        return this.delay<BridgeUserModelResponse>(1730,response)
      })
      .then((response2:BridgeUserModelResponse) => {
        /* console.log('%c bridgeByBankingService getBBBUserAsWorkspace() response', 'color: purple;', response) */
        return response2
      })
  }

  // bbbAPIPrimitivesService.deleteBBBUserAsWorkspaceStandardized
  deleteBBBUserAsWorkspaceStandardized():Promise<void> {
    return this.deleteBBBUserAsWorkspace(this.getCurrentWorkspaceIdForBBB())
  }

  private deleteBBBUserAsWorkspace(workspaceId: string): Promise<void> {
    return apiDelete(`/workspaces-as-bbb-users/${workspaceId}`, null, <APICallOptions>{
      entity: APIEntity.BBB_BANK_CONNECTION,
      ignoreFeedback: true,
      dontRedirectToSignInOnUnAuthorized: true
    })
      .then((response: any) =>
        {
          /* console.log('%c bridgeByBankingService deleteBBBUserAsWorkspace() response', 'color: purple;', response) */
        }
      )
  }

  /******************************************************************
   Bank Connections SCA
   ******************************************************************/

  // bbbAPIPrimitivesService.getScaUrlForWorkspaceStandardized
  getScaUrlForWorkspaceStandardized(itemId: number): Promise<string> {
    return this.getScaUrlForWorkspace(this.getCurrentWorkspaceIdForBBB(), itemId)
  }

  private getScaUrlForWorkspace(workspaceId: string, itemId: number): Promise<string> {
    if (workspaceId !== "") {
      return apiPost<string>(`/workspaces-as-bbb-users/${workspaceId}/sca`, {
        item_id: itemId
      }, <APICallOptions>{
        entity: APIEntity.BBB_BANK_CONNECTION,
        dontRedirectToSignInOnUnAuthorized: true
      })
        .then(response =>
          /* console.log('%c bridgeByBankingService getScaUrlForWorkspace() response', 'color: purple;', response) */
          response
        )
    } else {
      return Promise.reject('bbb sca failed: no specified workspace')
    }
  }

  /******************************************************************
   Bank Accounts List for Connected Bank Account
   ******************************************************************/

  // bbbAPIPrimitivesService.getBBBWorkspaceBankAccountsListStandardized
  getBBBWorkspaceBankAccountsListStandardized(): Promise<BBBBankAccount[]> {
    return this.getBBBWorkspaceBankAccountsList(this.getCurrentWorkspaceIdForBBB())
  }

  private getBBBWorkspaceBankAccountsList(workspaceId: string): Promise<BBBBankAccount[]> {
    return apiGet<BBBBankAccountsListResp>(`/workspaces-as-bbb-users/${workspaceId}/accounts?sync=true`, <APICallOptions>{
      entity: APIEntity.BBB_BANK_CONNECTION,
      dontRedirectToSignInOnUnAuthorized: true
    }).then((response: BBBBankAccountsListResp) =>{
        /*console.log('<BBBBankAccount[]>=', response)*/
        return response.data
      })
      .then((response: BBBBankAccount[]) =>{
        /*console.log('%c bridgeByBankingService getUserAccounts() response', 'color: purple;', response)*/
        return response
      })
  }

  /******************************************************************
   Transactions for Connected Bank Accounts
   ******************************************************************/

  // bbbAPIPrimitivesService.getBBBWorkspaceTransactionsStandardized
  getBBBWorkspaceTransactionsStandardized(): Promise<BBBTransactionsResponse> {
    return this.getBBBWorkspaceTransactions(this.getCurrentWorkspaceIdForBBB())
  }

  private getBBBWorkspaceTransactions(workspaceId: string): Promise<BBBTransactionsResponse> {
    return apiGet<BBBTransactionsResponse>(`/workspaces-as-bbb-users/${workspaceId}/load-transactions?limit=100`, <APICallOptions>{
      entity: APIEntity.BBB_BANK_CONNECTION,
      dontRedirectToSignInOnUnAuthorized: true
    }).then((response: any) => {
      /* console.log('%c bridgeByBankingService transactions ready', 'color: purple;') */
      return response
    })
  }

  /******************************************************************
   Generate Payment Link
   ******************************************************************/

  // bbbAPIPrimitivesService.generatePaymentLinkStandardized
  generatePaymentLinkStandardized(invoiceNumber: string, amount: number, customerName: string, paymentDescription: string): Promise<string> {
    let inSixWeeks: Date = new Date()
    inSixWeeks.setDate(inSixWeeks.getDate() + 6 * 7)
    return this.generatePaymentLink(invoiceNumber, amount, customerName, paymentDescription, this.getBBBCurrentSelectedBankAccountIBAN(), inSixWeeks, this.getBBBCurrentBeneficiaryName())
  }

  /**
   * Generate a payment link for a given invoice
   * @param invoiceNumber
   * @param amount
   * @param customerName
   * @param paymentDescription
   * @param bankAccountIBAN
   * @param expirationDate
   * @param beneficiaryName
   */
  private generatePaymentLink(invoiceNumber: string, amount: number, customerName: string, paymentDescription: string, bankAccountIBAN: string, expirationDate: Date, beneficiaryName: string): Promise<string> {
    return apiPost<PaymentLinkResponse>('/payment-links', {
        amount,
        'beneficiary_name': beneficiaryName,
        'callback_url': `${process.env.APP_BASE_URL}/payment-portal/payment-completed/${invoiceNumber}`,
        'iban': bankAccountIBAN,
        'expired_date': expirationDate.toISOString(), // now + 2 weeks = now + 2 * 7 days //  "2022-03-24T22:00:00.000Z",
        'label': paymentDescription,
        'payer_name': customerName
      },
      <APICallOptions>{
      entity: APIEntity.BBB_BANK_CONNECTION,
      dontRedirectToSignInOnUnAuthorized: true
    }).then((response: PaymentLinkResponse) => response.data)
      .then((url: string) =>
        /* console.log('%c bridgeByBankingService generatePaymentLink() response', 'color: purple;', response) */

        url
      )
  }

  /******************************************************************
   banks list
   ******************************************************************/

  getBanks(): Promise<any> {
    return apiGet('/banks?limit=1000', <APICallOptions>{
      entity: APIEntity.BBB_BANK_CONNECTION,
      dontRedirectToSignInOnUnAuthorized: true
    }).then((response: any) => response.data)
      .then(response =>
        /* console.log('%c bridgeByBankingService getBanks() response', 'color: purple;', response) */

        response
      )
  }

}

export const bbbAPIPrimitivesService: BBBAPIPrimitivesServiceClass = new BBBAPIPrimitivesServiceClass()

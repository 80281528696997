<script lang="ts">
  import { BarOrientation } from '$shared/components/bar/bar.d'
  import { ContactsStore } from '$src/crm-app/stores/contacts.store'
  import { convertRaw2Html, getDunningTemplate } from '$src/dundy-app/services/dunning-template.service'
  import { createEventDispatcher, onMount } from 'svelte'
  import { DunningMessageKind, type DunningMessageTemplateItem, type DunningMessageTemplatePrefKey } from '$dundy/models/dunning-message-template'
  import { fields, medias, type Field, type FieldsErrors, type FormField, type Media } from '$src/dundy-app/data/template'
  import { getDunningWorkflow } from '$src/dundy-app/services/dunning-workflow.service'
  import { objectFindByPath } from '$shared/utils/object'
  import { type Placeholders } from '$dundy/data/placeholder'
  import Bar from '$src/shared/components/bar/Bar.svelte'
  import InputPlaceholder from '$src/shared/components/input/InputPlaceholder.svelte'
  import languages from '$core/lib/i18n/languages-all'
  import Loader from '$src/core-app/lib/ui-kit/Loader.svelte'
  import StyledSelect from '$src/core-app/lib/ui-kit/StyledSelect.svelte'
  import type { Contact } from '$src/crm-app/models/contact'
  import { t } from '$src/core-app/lib/i18n/i18nextWrapper'

  // readonly
  export let stepId:Readonly<string> = ''
  export let customerId:Readonly<string> = ''
  export let contacts:Readonly<Contact[]> = []
  export let placeholders:Readonly<Placeholders[]> = []
  export let hideMedia:boolean = false
  export let hideLanguage:boolean = false
  export let hidePreview:boolean = false
  
  // bind
  export let media:Media = DunningMessageKind.DunningEmail.toString() as Media
  export let language:string = 'en'
  export let isEditing:boolean = true // edit or preview
  export let inputAllowInject:boolean = true
  export let formErrors:FieldsErrors = {}
  
  // local
  const dispatch = createEventDispatcher()
  let isLoading:boolean = true
  let input:InputPlaceholder | null
  let locales:Record<string, string>[] = languages
  let prefKey: DunningMessageTemplatePrefKey | null
  let prefValue: DunningMessageTemplateItem | null
  let prefValueDefault: DunningMessageTemplateItem | null
  let formFields:FormField[] = []

  const dispatchTemplateUpdate = () => {
    dispatch('templateChange', { prefKey, prefValue, formFields })
  }

  /**
   * CONVERT RAW DATA TO HTML FROM STRING/OBJECT 
   * @param data
   */
  const convertData2Editor = (data:any) => {
    if (typeof data === 'string') return convertRaw2Html(data)

    if (typeof data === 'object') {
      return Object.entries(data).reduce((acc:any, [key, value]) => {
        acc[key] = convertRaw2Html(value as string)
      
        return acc
      }, {})
    }
  }

  /**
   * CONVERT RAW VALUES INTO CHARACTER STRINGS (e.g. emails)
   * @param data
   */
  const valueConverter = (data:any) => {
    if (!data) return ''

    if (typeof data === 'string') return data
  
    const newVal:string[] = []
    const lst = Array.isArray(data) ? [...data] : [data]
    lst.forEach(item => {
      // DunningEmailAddressTemplate
      if (item.literalEmail) item.literalEmail.split(',').forEach((m:string) => newVal.push(m))
      if (item.dunningPlaceHolder) item.dunningPlaceHolder.split(',').forEach((m:string) => newVal.push(m))
      if (item.contactReference) {
        const contact = $ContactsStore.find(c =>c.contactId === item.contactReference) 
        if (contact) newVal.push(contact.email)
      }

      // MailAddress
      if (item.street) newVal.push(item.street)

      // Contact
      if (item.email) {
        const contact = $ContactsStore.find(c =>c.contactId === item.contactReference) 
        if (contact) newVal.push(contact.email)
      }
    })

    return newVal.join(',')
  }

  /**
   * GET THE RAW VALUE OF prefValue FOR A SPECIFIC KEY key IN MULTILANGUAGE
   * @param {boolean} isSystemDefault
   * @param {boolean} hasLocale
   * @param {string} key
   */
  const valueInit = (isSystemDefault:boolean, hasLocale: boolean, key:string):any => {
    if (!hasLocale) {
      if (isSystemDefault) return ''
    
      return objectFindByPath(prefValue, key)
    }

    const val = objectFindByPath(prefValue, key)
  
    if (isSystemDefault) return Object.keys(val).reduce((acc:any, cur) => {
      acc[cur] = ''
    
      return acc
    }, {})
  
    return val
  }

  /**
   * GENERATE FORM FIELDS FROM TEMPLATE (API)
   */
  const loadTemplate = () => {
    isLoading = true
    
    const mediaKey = (media[0].toLowerCase() + media.slice(1)) as Media
  
    formFields = (fields[mediaKey]).reduce((acc:FormField[], cur:Field) => {
      const f = Object.assign({}, cur) as FormField
      const key = `${mediaKey}.${cur.key}`
      const value = valueInit(prefValue?.isSystemDefault || false, f.hasLocale ?? false, key)
      const placeholder = objectFindByPath(prefValueDefault, key)
    
      f.placeholder = f.hasLocale ? placeholder : valueConverter(placeholder)
      f.placeholder = f.multiline ? convertData2Editor(f.placeholder) : f.placeholder
      // debugger // TODO : placeholder est erroné car prefValueDefault = null (voir API)

      if (value) {
        f.value = f.hasLocale ? value : valueConverter(value)
      } else {
        f.value = f.placeholder
      }
      f.value = f.multiline ? convertData2Editor(f.value) : f.value

      acc.push(f)
    
      return acc
    }, [])
  
    isLoading = false
  }

  const onBarChange = () => {
    input = null
    dispatch('inputKeyChange', '')

    loadTemplate()
  }

  const onChangeLanguage = (l:any) => language = l.value

  const onInputFocus = (e:any, key: string) => {
    input = e
    dispatch('inputKeyChange', key)
    dispatch('searchChange', '')
  }

  const onInputChange = (field:FormField, value:string) => {
    if (field.value.hasOwnProperty([language])) {
      field.value[language] = value
    } else {
      field.value = value
    }

    dispatchTemplateUpdate()
  }

  const onInputChangeWord = (value:string) => dispatch('searchChange', value)


  const getFieldValue = (field:FormField):string => {
    if (field.hasLocale) {
      return field.value[language] === field.placeholder[language] ? '' : field.value[language]
    }

    return field.value
  }
  
  const getFieldPreview = (field:FormField):string => {
    let value = getFieldValue(field)
    
    return value
  }

  onMount(async () => {
    let workflowKind:string = ''

    // language
    const resLng = await getDunningWorkflow(customerId)
    if (resLng) {
      workflowKind = resLng.workflow?.prefItem?.defaultDunningWorkflowKind || ''
      language = resLng.workflow?.prefItem?.defaultL10n || ''
      locales = languages.filter(l => resLng.workflow?.prefItem.dunningWorkflowL10ns.includes(l.value))
    }
  
    // template
    const res = await getDunningTemplate(workflowKind, stepId)
    prefKey = res.prefKey
    prefValue = res.prefValue
    prefValueDefault = res.prefValueDefault
    
    // select media
    if (prefValue && !media) {
      if (prefValue?.kind === DunningMessageKind['*']) prefValue.kind = DunningMessageKind[medias[0].key as keyof typeof DunningMessageKind]

      // debugger // TODO : API erronée car kind = null
      prefValue.kind = DunningMessageKind.DunningEmail
      // fin temp

      media = prefValue.kind.toString() as Media
    }

    loadTemplate()
  })

  export const injectPlaceholder = (placeholderKey:string, separator: string = '', replaceWord:boolean = false) => {
    if (input) input.injectPlaceholder(placeholderKey, separator, replaceWord)
  }
</script>

<div class="relative size-full flex flex-col bg-white border border-loblolly rounded p-6 overflow-hidden">

  {#if isLoading}
    <div class="absolute top-1/2 left-1/2 -translate-y-[50%] -translate-x-[50%]">
      <Loader />
    </div>
  {:else}

    {#if !hideMedia || !hideLanguage || !hidePreview}
      <div class="flex items-center mb-4 {hideMedia && hideLanguage ? 'justify-end' : 'justify-between'}">
        {#if !hideMedia}
          <Bar
            choices={medias}
            orientation={BarOrientation.HORIZONTAL}
            minimal={true}
            bind:value={media}
            on:change={() => onBarChange()}
          />
        {/if}

        {#if !hideLanguage}
          <StyledSelect
            value={language} items={locales} isSearchable={false} zIndex={99}
            on:select={e => onChangeLanguage(e.detail)}
            class={'grow mx-2'}
          />
        {/if}

        {#if !hidePreview}
          <button
            class="btn action-cancel mode mode-{isEditing ? 'preview' : 'edit'}"
            on:click={() => isEditing = !isEditing}>
            {isEditing ? t('actions.preview') : t('actions.edit')}
          </button>
        {/if}
      </div>
    {/if}

    {#each formFields as field}
      <div class="relative flex items-center {!!field.multiline ? 'flex-1' : ''}">
        {#if field.label}
          <span class="w-3/12 text-sm">{field.label}</span>
        {/if}

        <div class="size-full py-2" class:flex={!!field.hasLocale}>

          {#if isEditing}
            <InputPlaceholder
              error={formErrors[field.key] ?? ''}
              getCurrentWord={!['subject', 'body', 'callCanevas', 'letterBody'].includes(field.key)}
              multiline={!!field.multiline}
              variables={contacts}
              placeholder={field.hasLocale ? field.placeholder[language] : field.placeholder}
              placeholders={placeholders.filter(p => !p.fields || p.fields.includes(field.key))}
              value={getFieldValue(field)}
              bind:allowInject={inputAllowInject}
              on:focus={e => onInputFocus(e.detail, field.key)}
              on:change={e => onInputChange(field, e.detail)}
              on:changeWord={e => onInputChangeWord(e.detail)}
            />
            {:else}

              <div class='inline-block w-full p-2 text-sm bg-gray-100'>
                {getFieldPreview(field)}
              </div>

            {/if}

        </div>
      </div>
    {/each}

  {/if}
    
</div>

<style lang="postcss">
  .mode {
    @apply relative pl-9 !important;

    &::before {
      content : '';
      background: #6738ea;
      @apply absolute left-2.5 size-5 mr-2;
    }

    &-preview::before {
      top: 10px;
      mask: url("/img/icons/view.svg") no-repeat;
    }

    &-edit::before {
      top: 6px;
      mask: url("/img/icons/edit.svg") no-repeat;
    }
  }
</style>
/**
 * Reduces a list of invoices to a single number
 * @param invoiceListToReduce
 * @param invoiceFieldToReduceOn
 */
export function reduceInvoicesSumOfAmount(invoiceListToReduce: any[], invoiceFieldToReduceOn: string): number {
  if (!invoiceListToReduce) {
    return 0
  }
  
  return invoiceListToReduce
    .reduce((acc, currentInvoice): any => {
      if (currentInvoice && currentInvoice.hasOwnProperty(invoiceFieldToReduceOn) && currentInvoice[invoiceFieldToReduceOn]) {
        return acc + currentInvoice[invoiceFieldToReduceOn]
      } else return acc
    }, 0)
}

/**
 * Reduces a list of invoices to a single number using a secondary field if the primary field is not present
 * @param invoiceListToReduce
 * @param primaryField
 * @param secondaryField
 */
export function reduceInvoicesSumOfAmountWithOptionalField(invoiceListToReduce: any[], primaryField: string, secondaryField: string): number {
  if (!invoiceListToReduce) {
    return 0
  }
  
  return invoiceListToReduce
    .reduce((acc, currentInvoice): any => {
      let valueToUse
      if (currentInvoice && currentInvoice[primaryField] !== null && currentInvoice[primaryField] !== undefined && currentInvoice[primaryField] !== '') {
        valueToUse = currentInvoice[primaryField]
      } else if (currentInvoice && currentInvoice[secondaryField] !== null && currentInvoice[secondaryField] !== undefined && currentInvoice[secondaryField] !== '') {
        valueToUse = currentInvoice[secondaryField]
      } else {
        valueToUse = 0
      }
      
      return acc + valueToUse
    }, 0)
}


/**
 * Reduces count of invoices based on a condition
 * @param invoiceListToReduce
 * @param invoiceFieldToReduceOn
 * @param condition
 */
export function reduceCountIfCondition(invoiceListToReduce: any[], invoiceFieldToReduceOn: string, condition: (accumulated: number, invoiceKPIs: any) => Boolean): number {
  if (!invoiceListToReduce) {
    return 0
  }
  
  return invoiceListToReduce
    .reduce((acc, currentInvoice): any => {
      if (condition(acc, currentInvoice)) {
        return acc + 1
      }
      
      return acc
    }, 0)
}

/**
 * Reduces a list of invoices on two levels to a single number
 * @param invoiceListToReduce
 * @param invoiceFieldToReduceOn
 * @param invoiceSubFieldToReduceOn
 */
export function reduceInvoicesSumOfAmountTwoLevels(invoiceListToReduce: any[], invoiceFieldToReduceOn: string, invoiceSubFieldToReduceOn: string): number {
  if (!invoiceListToReduce) {
    return 0
  }
  
  return invoiceListToReduce
    .reduce((acc, currentInvoice): any => {
      if (currentInvoice && currentInvoice.hasOwnProperty(invoiceFieldToReduceOn) && currentInvoice[invoiceFieldToReduceOn] && currentInvoice[invoiceFieldToReduceOn].hasOwnProperty(invoiceSubFieldToReduceOn) && currentInvoice[invoiceFieldToReduceOn][invoiceSubFieldToReduceOn]) {
        return acc + currentInvoice[invoiceFieldToReduceOn][invoiceSubFieldToReduceOn]
      } else {
        return acc
      }
    }, 0)
}

/**
 * Reduces two-level invoices to a single float number
 * @param invoiceListToReduce
 * @param invoiceFieldToReduceOn
 * @param invoiceSubFieldToReduceOn
 */
export function reduceInvoicesSumOfStringToFloatAmountTwoLevels(invoiceListToReduce: any[], invoiceFieldToReduceOn: string, invoiceSubFieldToReduceOn: string): number {
  if (!invoiceListToReduce) {
    return 0
  }
  
  return invoiceListToReduce
    .reduce((acc, currentInvoice): any => {
      if (currentInvoice && currentInvoice.hasOwnProperty(invoiceFieldToReduceOn) && currentInvoice[invoiceFieldToReduceOn] && currentInvoice[invoiceFieldToReduceOn].hasOwnProperty(invoiceSubFieldToReduceOn) && currentInvoice[invoiceFieldToReduceOn][invoiceSubFieldToReduceOn]) {
        return acc + currentInvoice[invoiceFieldToReduceOn][invoiceSubFieldToReduceOn]
      } else {
        return acc
      }
    }, 0)
}
<script lang="ts">
  import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
  import { BusinessDocument } from '../../../models/business-document'
  import { voxyInvoicingService } from '../../../services/business-document-voxy/voxy.service'

  /** Exported Var **/
  export let businessDocument: BusinessDocument

  /** Local declarations */
  let relatedInvoiceNumber: string = businessDocument?.relatedBusinessDocuments.length
    ? voxyInvoicingService.getInvoiceNumberFromRelatedBusinessDocumentWhereRelationKindIsVoiding(businessDocument)
    : null
</script>

<div class="aside-help w-10/12 col-span-full mx-auto my-4">
  {#if !businessDocument.relatedBusinessDocuments.length}
    {@html t('invoices.createCreditNote.creditNoteInformation')}
  {:else}
    {@html t('invoices.createCreditNote.creditNoteInformationWithRelatedDocuments', {
      invoiceNumber: relatedInvoiceNumber,
      clientName: businessDocument.customerCustomer.company.formalName
    })}
  {/if}
</div>

<style lang="postcss">
  .aside-help {
    @apply bg-creamBrulee border border-warmGray rounded-md px-6 py-4;
    border-color: hsl(47, 65%, 84%);
    background-color: hsl(47, 87%, 94%);
  }
</style>

<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte'
  import { objectFindByPath } from '$src/shared/utils/object'
  import { SortOrder, type Columns } from '../grid/grid.d'
  import { stringNormalize } from '$src/shared/utils/string'
  import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
  import ConfirmationModal from '$src/core-app/lib/ui-kit/ConfirmationModal.svelte'
  import Grid from '../grid/Grid.svelte'
  import InputSearch from '../input/InputSearch.svelte'
  import Page from './Page.svelte'
  import Pagination from '../pagination/Pagination.svelte'
  import TextInput from '$src/core-app/lib/ui-kit/TextInput.svelte'
  
  // DATA
  export let contextName:Readonly<string>
  export let rows:Readonly<any[]> = []
  export let searchPath:Readonly<string>
  export let searchValue:string = ''
  export let columns:Readonly<Columns> = []
    
    // UI
  export let addButtonLink:Readonly<string> = ''
  export let searchPlaceholder:string = ''
  export let title:Readonly<string> = ''
  export let addButtonValue:Readonly<string> = ''
  export let allowActions:Readonly<boolean> = false
  export let allowDelete:Readonly<boolean> = true
  export let allowDuplicate:Readonly<boolean> = true
  export let allowEdit:Readonly<boolean> = true
  export let allowSelect:Readonly<boolean> = true
  export let footerClass:Readonly<string> = ''
  export let showAvatar:Readonly<boolean> = true
  export let txtCountDescription:Readonly<string> = ''
  export let txtEmptyGrid:Readonly<string> = t('grid.empty')
  export let txtModalDelete:Readonly<string> = t('grid.deleteContent')
  export let txtModalDeleteHeading:Readonly<string> = t('grid.deleteHeading')
  export let txtModalDeletePrimaryButton:Readonly<string> = t('actions.delete')
  export let txtModalDeleteSecondaryButton:Readonly<string> = t('actions.cancel')
  export let txtModalDuplicate:Readonly<string> = t('grid.duplicateContent')
  export let txtModalDuplicateError:Readonly<string> = t('grid.duplicateError')
  export let txtModalDuplicateHeading:Readonly<string> = t('grid.duplicateHeading')
  export let txtModalDuplicatePlaceholder:Readonly<string> = t('grid.duplicatePlaceholder')
  export let txtModalDuplicatePrimaryButton:Readonly<string> = t('actions.duplicate')
  export let txtModalDuplicateSecondaryButton:Readonly<string> = t('actions.cancel')

  // UI EVENT
  export let currentPage:number = 1
  export let itemsPerPage:number = 20
  export let sort:string = ''
  export let sortOrder:SortOrder = SortOrder.NONE
  export let urlManagement:boolean = true

  const dispatch = createEventDispatcher()

  // VARIABLES
  enum Action {
    DUPLICATE,
    DELETE
  }
  let action: Action
  let displayModal:boolean = false
  let modalProps: {
    modalHeading:string,
    primaryButtonText:string,
    secondaryButtonText:string
    primaryColor: string
  }
  let modalNewNameItem:string = ''
  let currentItem:any

  $:listSearch = !searchValue
    ? rows
    : rows?.filter(c => stringNormalize(objectFindByPath(c, searchPath).toString()).indexOf(stringNormalize(searchValue)) >= 0)
  
  $:listSort = !sort ? listSearch : [...listSearch].sort((a:any, b:any) => {
    if (objectFindByPath(a, sort) > objectFindByPath(b, sort)) {
      switch (sortOrder) {
        case SortOrder.ASC : return 1
        case SortOrder.DESC: return -1
        case SortOrder.NONE: return 0
      }
    } else if (objectFindByPath(a, sort) < objectFindByPath(b, sort)) {
      switch (sortOrder) {
        case SortOrder.ASC : return -1
        case SortOrder.DESC: return 1
        case SortOrder.NONE: return 0
      }
    } else {
      return 0
    }
  })

  $:listPaginated = listSort.slice(itemsPerPage * (currentPage - 1), itemsPerPage * currentPage)

  $:showDuplicateError = Boolean(listSort.find(i => stringNormalize(objectFindByPath(i, searchPath).toString()) === stringNormalize(modalNewNameItem)))

  const saveHistory = () => {
    const prf = localStorage.getItem('preferences')
    const prfJson = prf ? JSON.parse(prf) : {}
    prfJson[contextName] = { currentPage, itemsPerPage, searchValue, sort, sortOrder }
    localStorage.setItem('preferences', JSON.stringify(prfJson))
  }

  const changeHistory = () => {
    if (!urlManagement) return
    const searchParams = new URLSearchParams()

    if (currentPage && itemsPerPage) searchParams.set('p', `${currentPage}_${itemsPerPage}`)
    if (searchValue) searchParams.set('s', searchValue)
    if (sort && sortOrder) searchParams.set('sort', `${sort}_${sortOrder}`)
    
    window.history.replaceState(null, '', `?${searchParams.toString()}`)

    saveHistory()
  }

  const loadHistory = () => {
    if (!urlManagement) return
    
    const prf = localStorage.getItem('preferences')
    if (prf) {
      const prkKey = JSON.parse(prf)[contextName]
      if (prkKey) {
        if (prkKey.currentPage) currentPage = prkKey.currentPage
        if (prkKey.itemsPerPage) itemsPerPage = prkKey.itemsPerPage
        if (prkKey.searchName) searchValue = prkKey.searchName
        if (prkKey.sort) sort = prkKey.sort
        if (prkKey.sortOrder) sortOrder = prkKey.sortOrder

        changeHistory()
      }
    }

    const searchParams = new URLSearchParams(window.location.search)

    // page
    let param = searchParams.get('p')
    if (param) {
      const p = param.split('_')
      currentPage = Number(p[0])
      itemsPerPage = Number(p[1])
    }

    // search
    param = searchParams.get('s') ?? ''
    if (param && searchValue !== param) searchValue = param

    // sort
    param = searchParams.get('sort')
    if (param) {
      const p = param.split('_')
      sort = p[0]
      sortOrder = p[1] as SortOrder
    }
  }

  // EVENTS
  const onInputSearch = () => {
    currentPage = 1

    changeHistory()
  }

  const onGridSort = (value:{ sort:string, sortOrder:SortOrder }) => {
    sort = value.sort
    sortOrder = value.sortOrder

    changeHistory()
  }

  const onGridSelect = (value:any) => {
    dispatch('select', value)
  }

  const onGridEdit = (value:any) => {
    dispatch('edit', value)
  }

  const onGridDuplicate = (id: number) => {
    currentItem = id
    action = Action.DUPLICATE
    modalProps = {
      modalHeading: txtModalDuplicateHeading,
      primaryButtonText: txtModalDuplicatePrimaryButton,
      secondaryButtonText: txtModalDuplicateSecondaryButton,
      primaryColor: 'dundyOrange'
    }
    displayModal = true
  }

  const onGridDelete = (id: number) => {
    currentItem = id
    action = Action.DELETE
    modalProps = {
      modalHeading: txtModalDeleteHeading,
      primaryButtonText: txtModalDeletePrimaryButton,
      secondaryButtonText: txtModalDeleteSecondaryButton,
      primaryColor: 'mojo'
    }
    displayModal = true
  }

  const onModalValidate = async () => {
    displayModal = false

    if (action === Action.DELETE) dispatch('delete', currentItem)  
    if (action === Action.DUPLICATE) dispatch('duplicate', currentItem)  
  }

  const onPerPageChange = (e:CustomEvent) => {
    itemsPerPage = e.detail
    currentPage = 1

    changeHistory()
  }

  const onPageChange = (e:CustomEvent) => {
    currentPage = e.detail

    changeHistory()
  }

  onMount(() => loadHistory())
</script>

<Page {title} {addButtonLink} {addButtonValue} {footerClass}>

  {#if !rows.length}
    <div class="w-1/2 text-center absolute left-1/2 -translate-x-1/2 mt-48">
      {txtEmptyGrid}
    </div>
    <img src='/img/arrowToButton.svg' alt="add customer" class="absolute top-40 right-24"/>
  {:else}
    <div class="relative">
      <div class="sticky top-0 z-50">
        <InputSearch placeholder={searchPlaceholder} bind:value={searchValue} on:search={() => onInputSearch()} />
      </div>

      <div>
        <Grid {columns} rows={listPaginated} {sort} {sortOrder} {allowDuplicate} {allowActions} {allowDelete} {allowEdit} {allowSelect} {showAvatar}
          on:sort={(e) => onGridSort(e.detail)}
          on:select={(e) => onGridSelect(e.detail)}
          on:edit={(e) => onGridEdit(e.detail)}
          on:delete={(e) => onGridDelete(e.detail)}
          on:duplicate={(e) => onGridDuplicate(e.detail)}
        />
      </div>
    </div>
  {/if}

  <div slot="footer">
    <Pagination {itemsPerPage} {currentPage} items={listSearch.length} countDescription={txtCountDescription}
    on:perPageChange={e => onPerPageChange(e)}
    on:pageChange={e => onPageChange(e)}/>
  </div>

  <ConfirmationModal
    on:cancelAndClose={() => {displayModal = false}}
    on:confirmAction={() => onModalValidate()}
    open={displayModal}
    {...modalProps}
  >
    {#if action === Action.DUPLICATE}
      {txtModalDuplicate}
      <TextInput type="text" 
        bind:value={modalNewNameItem}
        error={txtModalDuplicateError}
        showError={showDuplicateError}
        showValidationTick={false}
        autocomplete="off"
        transform="uppercase"
        placeholder={txtModalDuplicatePlaceholder}
        class="mb-3"
      />
    {:else if action === Action.DELETE}
      {txtModalDelete}
    {/if}
  </ConfirmationModal>

</Page>
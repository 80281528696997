import { JsonProperty } from '@paddls/ts-serializer'
import { AmountDecimal, AmountDecimalBasicObject } from '../../cash-application-app/models/amount-of-money-decimal-model'

export type TodoItemMessageKindType = string

export enum TodoItemMessageKind {
  EMAIL = 'Email',
  DIRECT_FACE_TO_FACE = 'DirectFaceToFace',
  TEXTING = 'Texting',
  PHONE_CALL = 'PhoneCall',
  REGULAR_POST_MAIL = 'RegularPostMail',
  EMAIL_AND_REGULAR_POST_MAIL = 'EmailAndRegularPostMail',
  LAWYER_AND_OTHER_LEGAL_SERVICES = 'LawyerAndOtherLegalServices',
  LAWYER_LETTER = 'LawyerLetter',
  NONE = 'None',
  TO_BE_DEFINED = 'ToBeDefined',
  ERROR = 'Error'
}

export enum TodoItemActionKind {
  INVOICE_ISSUED = 'InvoiceIssued',
  INVOICE_ISSUANCE = 'InvoiceIssuance',
  NO_ACTION_AT_THIS_STAGE = 'NoActionAtThisStage',
  COURTESY_REMINDER_SHORTLY_BEFORE_DUE_DATE = 'CourtesyReminderShortlyBeforeDueDate',
  REMINDER_JUST_AFTER_DUE_DATE = 'ReminderJustAfterDueDate',
  REMINDER_SHORTLY_AFTER_DUE_DATE = 'ReminderShortlyAfterDueDate',
  DIRECT_REMINDER_SOMETIME_AFTER_DUE_DATE = 'DirectReminderSomeTimeAfterDueDate',
  STRONG_LAST_REMINDER_AFTER_DUE_DATE = 'StrongLastReminderAfterDueDate',
  COLLECTION_LITIGATION = 'CollectionLitigation',
  DISPUTE_RESOLUTION = 'DisputeResolution',
  CELEBRATE_AND_CLOSE = 'CelebrateAndClose',
  CHECK_CASH_APPLICATION_PROPOSAL = 'CheckCashApplicationProposal',
  NOTHING_ANYMORE = 'NothingAnymore',
  TO_BE_DEFINED = 'ToBeDefined'
}

export type TodoItemDunningActionType = string

export enum TodoItemDunningAction {
  UNDETERMINED = 'Undetermined',
  INFORMATION_ABOUT_NEW_INVOICE = 'InformationAboutNewInvoice',
  INFORMATION_ABOUT_DUE_INVOICE = 'InformationAboutDueInvoice',
  INFORMATION_ABOUT_OVERDUE_INVOICE = 'InformationAboutOverdueInvoice',
  GENTLE_REMINDER_TO_PAY = 'GentleReminderToPay',
  FIRM_BUT_GENTLE_REMINDER_TO_PAY = 'FirmButGentleReminderToPay',
  FIRM_DIRECT_REMINDER_CALL_WITH_COACHING = 'FirmDirectReminderCallWithCoaching',
  STRONG_FORMAL_REMINDER_BEFORE_LEGAL_ACTIONS = 'StrongFormalReminderBeforeLegalActions',
  LEGAL_ACTIONS = 'LegalActions',
  CLOSED_AND_INVOICE_PAID = 'ClosedAndInvoicePaid',
  STUDY_AND_NEGOTIATE = 'StudyAndNegotiate',
  PLEASE_VALIDATE_PROPOSED_CASH_APPLICATION = 'PleaseValidateProposedCashApplication',
  INFORM_YOUR_CUSTOMER_BY_DEFAULT = 'InformYourCustomerByDefault',
  WOW_WHAT_A_MESS_UNDETERMINED_ACTION = 'WowWhatAMessUndeterminedAction',
  DO_NOTHING = 'DoNothing'
}

export type TodoItemEscalationType = string

export enum TodoItemEscalation {
  COURTESY_REMINDER = 'CourtesyReminder',
  FIRST_REMINDER = 'FirstReminder',
  SECOND_REMINDER = 'SecondReminder',
  LAST_CALL = 'LastCall',
  POSTAL_LETTER = 'PostalLetter',
  LEGAL_ACTION = 'LegalAction',
  DISPUTE_RESOLUTION = 'DisputeResolution',
  ABANDONED = 'Abandoned',
  CLOSED = 'Closed',
  CASH_APPLICATION_VALIDATION = 'CashApplicationValidation',
  TO_BE_DEFINED = 'ToBeDefined'
}

export type TodoItemKindType = string

export enum TodoItemKind {
  DUNNING = 'Dunning',
  CASH_APPLICATION = 'CashApplication',
  INVOICE_DISPUTE = 'InvoiceDispute',
  TO_BE_DEFINED = 'ToBeDefined'
}

export type TodoItemStatusType = string

export enum TodoItemStatus {
  OUTSTANDING = 'Outstanding',
  DISPUTED = 'Disputed',
  PAID = 'Paid',
  ACCEPTED = 'Accepted',
  RESOLVED = 'Resolved',
  CASH_APPLICATION_TO_VALIDATE = 'CashApplicationToValidate',
  TO_BE_DEFINED = 'ToBeDefined'
}

export type TodoItemStepType = string

export enum TodoItemStep {
  DUE = 'Due',
  OVERDUE = 'Overdue',
  DISPUTE_RESOLUTION = 'DisputeResolution',
  PAID = 'Paid',
  LOSS = 'Loss',
  CASH_APPLICATION_VALIDATION = 'CashApplicationValidation',
  TO_BE_DEFINED = 'ToBeDefined'
}

export class TodoListItem {
  @JsonProperty({ field: 'actionKind', groups: Object.values(TodoItemActionKind) }) actionKind: TodoItemActionKind | any
  @JsonProperty('actionMessage') actionMessage: string
  @JsonProperty('actionPlanned') actionPlanned: number
  @JsonProperty('actionPlannedRFC3339') actionPlannedRFC3339: string
  @JsonProperty('computationReferenceIANACode') computationReferenceIANACode: string
  @JsonProperty('created') created: number
  @JsonProperty('createdRFC3339') createdRFC3339: string
  @JsonProperty('customerId') customerId: string
  @JsonProperty('dateDone') dateDone: number
  @JsonProperty('dateDoneIANACode') dateDoneIANACode: string
  @JsonProperty('dateDoneRFC3339') dateDoneRFC3339: string
  @JsonProperty('dunningAction') dunningAction: TodoItemDunningActionType
  @JsonProperty('dunningCaseFound') dunningCaseFound: string
  @JsonProperty('escalation') escalation: TodoItemEscalationType
  @JsonProperty('id') id: string
  @JsonProperty('isDone') isDone: boolean
  @JsonProperty('isWorkflowSuspended') isWorkflowSuspended: boolean
  @JsonProperty('kind') kind: TodoItemKindType
  @JsonProperty('maxDueDays') maxDueDays: number
  @JsonProperty('messageKind') messageKind: TodoItemMessageKindType
  @JsonProperty('oldestDueDate') oldestDueDate: number
  @JsonProperty('oldestDueDateRFC3339') oldestDueDateRFC3339: string
  @JsonProperty('percentToOldestDueDate') percentToOldestDueDate: number
  @JsonProperty('priority') priority: number
  @JsonProperty('scopeInvoiceNumbers') scopeInvoiceNumbers: string[]
  @JsonProperty('status') status: TodoItemStatusType
  @JsonProperty('step') step: TodoItemStepType
  @JsonProperty('strategy') strategy: string
  @JsonProperty({ field: 'totalScopeAmountOfMoneyDue', type: () => [AmountDecimal, AmountDecimalBasicObject] }) totalScopeAmountOfMoneyDue: AmountDecimal | AmountDecimalBasicObject
  @JsonProperty({ field: 'totalScopeAmountOfMoneyInvoiced', type: () => [AmountDecimal, AmountDecimalBasicObject] }) totalScopeAmountOfMoneyInvoiced: AmountDecimal | AmountDecimalBasicObject
  @JsonProperty('version') version: string
  @JsonProperty('workspaceId') workspaceId: string
}



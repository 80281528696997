import type { BusinessDocument } from '../../models/business-document'
import {
  hasAtLeastAnotherFinalizedBusinessDocumentWithSameDeal
} from '../installment/at-least-another-finalized-business-document-with-same-deal.service'
import { BusinessDocumentKind } from '../../enums/business-document-kind'
import { TaxonomyTag } from '../../enums/taxonomy-tag'

/**
 * When editing a draft business document, the UI manage two aspects of installment change:
 * - level 1: can the user switch between partial and full business document
 * - level 2: can the user change the installment itself (value and type)
 * As of 20230906, there is no case where level 1 and level 2 are different.
 * For example, in a credit note on a partial invoice, the credit note is partial and the user shall neither change between partial and full nor change the installment value: both are what the original invoice was.
 * For example, a new invoice from scratch can: whether be partial with changeable installment value and type or be full with fixed installment value and type.
 * The base idea being that a full business document has no user-changeable installment parameters (aka the parameters are programmatically changed e.g. when the line items change, but they cannot be arbitrary).
 * @param currentBusinessDocument
 * @param allBusinessDocuments
 */
export function isUserAllowedToChangeBetweenFullAndPartialDocument(currentBusinessDocument: BusinessDocument, allBusinessDocuments: BusinessDocument[]): boolean {
  const hasAnotherFinalizedBusinessDocumentWithSameDeal: boolean = hasAtLeastAnotherFinalizedBusinessDocumentWithSameDeal(currentBusinessDocument, allBusinessDocuments)

  if (currentBusinessDocument.businessDocumentKind === BusinessDocumentKind.CREDITNOTE) {
    return false
  } else if (currentBusinessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE && currentBusinessDocument.taxonomyTags.isContainingAtLeastOneItemAmong([TaxonomyTag.CORRECTIVE_INVOICE])) {
    return false
  } else if (currentBusinessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE) {
    return !hasAnotherFinalizedBusinessDocumentWithSameDeal
  } else {
    return true
  }
}
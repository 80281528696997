import { eventsManager } from '../events/event-manager'
import { EventType } from '../events/event-type'
import { contactsService } from '../../crm-app/services/contacts.service'
import { customersService } from '../../crm-app/services/customers.service'
import { dunningInvoicesService } from '../../dundy-app/services/dunning-invoices.service'
import { profileService } from './profile.service'
import { todosService } from '../../dundy-app/services/todo.service'
import { workspacesService } from '../../crm-app/services/workspace.service'
import {
  initializeWorkspaceStore,
  unUnsubscribeWorkspaceStore,
  WorkspaceStore
} from '../../crm-app/stores/workspace.store'
import { initializeCustomersStore } from '../../crm-app/stores/customers.store'
import { Workspace, WorkspacePrototypeToClass } from '../../crm-app/models/workspace'
import { initializeContactsStore } from '../../crm-app/stores/contacts.store'
import { initializeProfileStore, ProfileStore, unsubscribeProfileStore } from '../stores/profile.store'
import { initializeCashApplicationDeclaredEventsStore } from '../../cash-application-app/stores/cash-application.store'
import { initializeInvoicesStore } from '../../dundy-app/stores/dunning-invoices.store'
import { initializeTodosStore } from '../../dundy-app/stores/todos.store'
import { initializeComputedDataInvoicesStore } from '../../dundy-app/stores/computed-data-invoice.store'
import { feedbackService } from './feedback.service'
import initializationStore from '../stores/initialization.store'
import { voxyPreferencesService } from '../../voxy-app/services/voxy-preferences.service'
import {
  initializeVoxyPreferencesStore,
  unUnsubscribeVoxyPreferencesStore
} from '../../voxy-app/stores/voxy-preference.store'
import type { VoxyPreferences } from '../../voxy-app/models/voxy-preferences'
import type { DundyEvent } from '../../dundy-app/events/dundy-event'
import { navigateToMissingOnboardingStepIfNeeded } from './onboarding.service'
import type { Unsubscriber } from 'svelte/store'
import { get } from 'svelte/store'
import { authZJsFirstName, authZJsLastName, userUnderAuth0JsClient } from '../lib/auth0authentication/authStore'
import { initializeTransactionsStore } from '../../bank-app/stores/transactions.store'
import { bankingTransactionsService } from '../../bank-app/services/banking-transactions.service'
import { cashApplicationService } from '../../cash-application-app/services/cash-application.service'
import Profile from '../models/profile'
import type Invoice from '../../dundy-app/models/invoice'
import type { TodoListItem } from '../../dundy-app/models/todo-list-item'
import { invoicesHistoryService } from '../../dundy-app/services/invoices-history.service'
import { initializeInvoicesHistoryStore } from '../../dundy-app/stores/invoices-history.store'
import type { InvoicesHistoryItem } from '../../dundy-app/models/invoices-history'
import type { CashApplicationDeclaredEvent } from '../../cash-application-app/models/cash-application-declared-model'
import type { BBBTransactionsResponse } from '../../bank-app/models/bbb-transactions-model'
import { initializeBusinessDocumentsAllDataPersistedStore } from '../../voxy-app/stores/business-documents.store'
import { BusinessDocumentAllDataPersisted } from '../../voxy-app/models/business-document'
import { voxyInvoicingService } from '../../voxy-app/services/business-document-voxy/voxy.service'
import { navigate } from 'svelte-routing'
import type Auth0User from '../lib/auth0authentication/auth0-user-model'

class StateManagementServiceClass {

  initialized: boolean
  unsubscriberAuthenticated: Function
  unsubscriberProfileFetched: Function
  unsubscriberWorkspaceFetched: Function
  unsubscriberWorkspaceStore: Unsubscriber
  unsubscriberInvoiceListFetched: Function
  unsubscriberComputedInvoiceListFetched: Function
  unsubscriberToDoListFetched: Function
  unsubscriberInvoicesHistoryListFetched: Function
  unsubscriberVoxyPreferencesListFetched: Function
  unsubscriberTransactionsListFetched: Function
  unsubscriberCashApplicationListFetched: Function
  unsubscriberBusinessDocumentsListFetched: Function
  isUserEmailVerified(aUserUnderAuth0JsClient: Auth0User): boolean {
    return !!aUserUnderAuth0JsClient && aUserUnderAuth0JsClient.hasOwnProperty('sub') && aUserUnderAuth0JsClient.sub !== '' && !aUserUnderAuth0JsClient.email_verified
  }
  /**
     * Initializing the services will cause them to fetch the initial data from the API.
     * Here we will listen for the respective FETCH events and initialize the data Stores.
     * The specific order here is because some entities depend on data from others.
     */
  initialize(originOfCall: string) {
    /* console.log('initialize(originOfCall: string)', originOfCall) */
    if (!this.initialized) {

      this.initialized = true

      feedbackService.initialize()

      if (!!this.unsubscriberAuthenticated) {
        this.unsubscriberAuthenticated()
      }
      this.unsubscriberAuthenticated = eventsManager.once(EventType.AUTHENTICATED, () => {

        /* console.log('%c AUTHENTICATED. ABOUT TO REGISTER LISTENERS AND INITIALIZING SERVICES.', 'font-size: 1.3em;') */

        if (!!this.unsubscriberProfileFetched) {
          this.unsubscriberProfileFetched()
        }
        this.unsubscriberProfileFetched = eventsManager.once<Profile>(EventType.PROFILE_FETCHED, (e: DundyEvent<Profile>): void => {
          if (this.isUserEmailVerified(get(userUnderAuth0JsClient))) {
            navigate('/onboarding/confirm')
            
            return
          }
          //TODO why initializeProfileStore on each EventType.PROFILE_FETCHED ??? indeed EventType.PROFILE_FETCHED does not just mean
          //   we just changed users, it can also mean we simply updated the user profile (so why reset the store in such a case?)
          initializeProfileStore(e.data)

          const newProfile: Profile = Profile.PrototypeToClass(e.data)
          if (!!get(ProfileStore)) {
            if (!get(ProfileStore).firstName || get(ProfileStore).firstName === '') {
              /* console.log('profile autofill first name') */
              ProfileStore.update((oldProfile: Profile) => {
                oldProfile.firstName = get(authZJsFirstName)

                return oldProfile
              })
            }
            if (!get(ProfileStore).lastName || get(ProfileStore).lastName === '') {
              /* console.log('profile autofill last name') */
              ProfileStore.update((oldProfile: Profile) => {
                oldProfile.lastName = get(authZJsLastName)

                return oldProfile
              })
            }
          }
          navigateToMissingOnboardingStepIfNeeded()

          if (!!this.unsubscriberWorkspaceFetched) {
            this.unsubscriberWorkspaceFetched()
          }
          this.unsubscriberWorkspaceFetched = eventsManager.once<Workspace>(EventType.WORKSPACE_FETCHED, async (e: DundyEvent<Workspace>): Promise<void> => {

            if (!!this.unsubscriberWorkspaceStore) {
              this.unsubscriberWorkspaceStore()
            }
            this.unsubscriberWorkspaceStore = WorkspaceStore.subscribe((newWorkspaceStore: Workspace): void => {
              const newWorkspace: Workspace = WorkspacePrototypeToClass(newWorkspaceStore)
              navigateToMissingOnboardingStepIfNeeded()
            })

            customersService.initialize()
            contactsService.initialize()
            await initializeWorkspaceStore(e.data)
            initializeCustomersStore(e.data.customers)
            initializeContactsStore(e.data.contacts)

            /** Invoices List Subscription */
            if (!!this.unsubscriberInvoiceListFetched) {
              this.unsubscriberInvoiceListFetched()
            }
            this.unsubscriberInvoiceListFetched = eventsManager.on<Invoice[]>(EventType.INVOICE_LIST_FETCHED, (de: DundyEvent<Invoice[]>): void => {
              initializeInvoicesStore(de.data)
            }, 'StateManagementServiceClass')
            dunningInvoicesService.initialize()

            /** Computed Invoice List Subscription */
            if (!!this.unsubscriberComputedInvoiceListFetched) {
              this.unsubscriberComputedInvoiceListFetched()
            }
            this.unsubscriberComputedInvoiceListFetched = eventsManager.on<Invoice[]>(EventType.COMPUTED_INVOICE_LIST_FETCHED, (de: DundyEvent<Invoice[]>): void => {
              initializeComputedDataInvoicesStore(de.data)
            }, 'StateManagementServiceClass')

            /** To Do List Subscription */
            if (!!this.unsubscriberToDoListFetched) {
              this.unsubscriberToDoListFetched()
            }
            this.unsubscriberToDoListFetched = eventsManager.on<TodoListItem[]>(EventType.TODO_LIST_FETCHED, (de: DundyEvent<TodoListItem[]>): void => {
              initializeTodosStore(de.data)
            }, 'StateManagementServiceClass')
            todosService.initialize()

            /** Invoices History Subscription */
            if (!!this.unsubscriberInvoicesHistoryListFetched) {
              this.unsubscriberInvoicesHistoryListFetched()
            }
            this.unsubscriberInvoicesHistoryListFetched = eventsManager.on<InvoicesHistoryItem[]>(EventType.HISTORY_LIST_FETCHED, (de: DundyEvent<InvoicesHistoryItem[]>): void => {
              initializeInvoicesHistoryStore(de.data)
            }, 'StateManagementServiceClass')
            invoicesHistoryService.initialize()

            /** Transactions Subscription */
            if (!!this.unsubscriberTransactionsListFetched) {
              this.unsubscriberTransactionsListFetched()
            }
            this.unsubscriberTransactionsListFetched = eventsManager.on<BBBTransactionsResponse>(EventType.TRANSACTIONS_LIST_FETCHED, (de: DundyEvent<BBBTransactionsResponse>): void => {
              initializeTransactionsStore(de.data)
            }, 'StateManagementServiceClass')
            bankingTransactionsService.initialize()

            /** Cash Application Subscription */
            if (!!this.unsubscriberCashApplicationListFetched) {
              this.unsubscriberCashApplicationListFetched()
            }

            this.unsubscriberCashApplicationListFetched = eventsManager.on<CashApplicationDeclaredEvent[]>(EventType.CASH_APPLICATION_LIST_FETCHED, (de: DundyEvent<CashApplicationDeclaredEvent[]>): void => {
              initializeCashApplicationDeclaredEventsStore(de.data)
            }, 'StateManagementServiceClass')
            cashApplicationService.initialize()

            /** Business Documents List Subscription */
            if (!!this.unsubscriberBusinessDocumentsListFetched) {
              this.unsubscriberBusinessDocumentsListFetched()
            }
            this.unsubscriberBusinessDocumentsListFetched = eventsManager.on<BusinessDocumentAllDataPersisted[]>(EventType.BUSINESS_DOCUMENTS_LIST_FETCHED, (de: DundyEvent<BusinessDocumentAllDataPersisted[]>): void => {
              initializeBusinessDocumentsAllDataPersistedStore(de.data)
            }, 'StateManagementServiceClass')
            voxyInvoicingService.initialize()

            /** Voxy Preferences Subscription */
            if (!!this.unsubscriberVoxyPreferencesListFetched) {
              this.unsubscriberVoxyPreferencesListFetched()
            }
            this.unsubscriberVoxyPreferencesListFetched = eventsManager.on<VoxyPreferences>(EventType.VOXY_PREFERENCES_FETCHED, (de: DundyEvent<VoxyPreferences>): void => {
              /* console.log('on EventType.VOXY_PREFERENCES_FETCHED: VoxyPreferencesStore initializing  vxp !!!!!! with value', e.data) */
              initializeVoxyPreferencesStore(de.data)
            }, 'StateManagementServiceClass')
            /* console.log('voxyPreferencesService loads first  vxp -_-_+=') */
            voxyPreferencesService.initialize('StateManagementServiceClass.initialize  vxp !!!!!! : from ' + originOfCall)

          }, 'StateManagementServiceClass')
          workspacesService.initialize()

        }, 'StateManagementServiceClass')
        profileService.initialize()

      }, 'StateManagementServiceClass')
    }
  }
  reset(originOfCall: string) {
    eventsManager.clear()
    initializationStore.set({
      navigationHistoryStoreInitialized: false,
      workspaceStoreInitialized: false,
      profileStoreInitialized: false,
      invoicesStoreInitialized: false,
      customersStoreInitialized: false,
      contactsStoreInitialized: false,
      todosStoreInitialized: false,
      computedInvoicesStoreInitialized: false,
      feedbacksStoreInitialized: false,
      invoicesHistoryStoreInitialized: false,
      voxyPreferencesStoreInitialized: false,
      transactionsStoreInitialized: false,
      cashApplicationDeclaredEventsStoreInitialized: false,
      businessDocumentsStoreInitialized: false
    })
    todosService.initialized = false
    contactsService.initialized = false
    customersService.initialized = false
    dunningInvoicesService.initialized = false
    profileService.initialized = false
    invoicesHistoryService.initialized = false
    workspacesService.initialized = false
    voxyPreferencesService.initialized = false
    bankingTransactionsService.initialized = false
    cashApplicationService.initialized = false
    voxyInvoicingService.initialized = false

    this.initialized = false
    if (!!unsubscribeProfileStore) {
      unsubscribeProfileStore()
    }
    if (!!unUnsubscribeWorkspaceStore) {
      unUnsubscribeWorkspaceStore()
    }
    if (!!unUnsubscribeVoxyPreferencesStore) {
      unUnsubscribeVoxyPreferencesStore()
    }
    this.initialize('StateManagementServiceClass.reset() !!!!!! : from ' + originOfCall)
    eventsManager.emit(EventType.AUTHENTICATED, true, 'StateManagementService')
  }
}

export const stateManagementService: StateManagementServiceClass = new StateManagementServiceClass()

import { eventsManager } from '../events/event-manager'
import { EventType } from '../events/event-type'
import * as Sentry from '@sentry/svelte'
import { ProfileStore } from '../stores/profile.store'
import { get } from 'svelte/store'
import type Profile from '../models/profile'
import { auth0JsIdToken } from '../lib/auth0authentication/authStore'
import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
import { isObject } from '$src/shared/utils/object'

export enum Severity {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warning',
  Log = 'log',
  Info = 'info',
  Debug = 'debug'
}
export type SeverityLevel = 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';

class RemoteDebuggingServiceClass {

  initialized: boolean
  initialize() {
    if (!this.initialized) {

      this.initialized = true

      //----- SUBSCRIBE TO INTERESTING EVENTS AND LOG THEM TO SENTRY ------

      eventsManager.on(EventType.AUTHENTICATED, event => {
        remoteDebuggingService.addInfo(`Authenticated event received. Data: ${JSON.stringify(event.data)}`, 'auth')
        const profile: Profile = get(ProfileStore)
        Sentry.setUser({
          email: profile.email,
          id: get(auth0JsIdToken),
          username: profile.firstName + ' ' + profile.lastName
        })
      }, 'RemoteDebuggingService')

      eventsManager.on(EventType.WORKSPACE_ADDED, event => {
        remoteDebuggingService.addInfo(`WORKSPACE_ADDED event received. Data: ${JSON.stringify(event.data)}`, 'auth')
      }, 'RemoteDebuggingService')
    }
  }
  addInfo(info: any, category?: string, level:SeverityLevel = Severity.Info ): void {
    let message

    if (isObject('object')) {
      message = JSON.stringify(info, null, 2)
    } else {
      if (Array.isArray(info)) message = info.join(' ')
      message = info.toString()
    }

    Sentry.addBreadcrumb({
      category: category || 'info',
      message,
      level,
      data: {
        workspaceId: get(WorkspaceStore)?.workspaceId
      }
    })
  }
  sendEvent(message: string, customData: string = ''): void {
    Sentry.captureEvent({
      message,
      extra: {
        customData
      }
    })
  }
  sendMsg(message: string): void {
    Sentry.captureMessage(message)
  }
}

export const remoteDebuggingService: RemoteDebuggingServiceClass = new RemoteDebuggingServiceClass()
remoteDebuggingService.initialize()

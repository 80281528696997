<script lang="ts">
  import { t } from '../i18n/i18nextWrapper.js'
  import { link } from 'svelte-routing'
  import { isUserWsOwner } from '../../services/guard'
  import Accordion from '../ui-kit/Accordion/Accordion.svelte'
  import AccordionItem from '../ui-kit/Accordion/AccordionItem.svelte'
  import WorkspaceDropdownButton from './WorkspaceDropdownButton.svelte'
  import NavLink from '../ui-kit/NavLink.svelte'
  import { onDestroy, onMount } from 'svelte'
  import type { Unsubscriber } from 'svelte/store'
  import { WorkspaceStore } from '$crm/stores/workspace.store'
  import { Workspace } from '$crm/models/workspace'
  import { featureToggling } from '$config/feature-toggling'
  import { BusinessDocumentSlugs } from '$voxy/enums/business-document-slugs'
  import { ContactsStore } from '$src/crm-app/stores/contacts.store.js'
  import { bridgeByBankingService } from "$pay/services/bridge-by-banking.service";

  let isUserWorkspaceOwner: boolean = false
  let unSubscriberWorkspace: Unsubscriber
  let isBankConfigLoaded: boolean = false

  const prodEnv = process.env.APP_ENV === 'prod remote'

  // Provide a default value if APP_VERSION is not set
  const version = process.env.APP_VERSION ?? 'v1.0.0'

  $: getVersion = () => {
    const v = version.split('v')
    
    return v[0] !== '' ? `v${v[1]} - ${v[0].slice(0, 6)}` : `v${v[1]}`
  }


  onMount(() => {
    if (!!unSubscriberWorkspace) {
      unSubscriberWorkspace()
    }
    unSubscriberWorkspace = WorkspaceStore.subscribe((newWorkspace: Workspace) => {
      isUserWorkspaceOwner = isUserWsOwner()
      isBankConfigLoaded = !bridgeByBankingService.hasBankNeverBeenLinkedOrBankLinkExpired(newWorkspace?.bankConfig)
    })
  })

  onDestroy(() => {
    if (!!unSubscriberWorkspace) {
      unSubscriberWorkspace()
    }
  })
</script>

<div class="sidebar-container">

  <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 rounded-lg">
    <div class="flex h-16 shrink-0 items-center">
      <a aria-label="Home"
         href="/today"
         use:link>
        <img alt="Dundy" class="mr-16 h-6" src="/img/dundy-icon.svg"/>
      </a>
    </div>

    <div class="hidden lg:flex">
      <WorkspaceDropdownButton/>
    </div>

    <nav class="flex flex-1 flex-col">
      <ul role="list" class="flex flex-1 flex-col gap-y-3">
        <li class="md:hidden">
          <a
            class="block py-1 text-sm text-zinc-600 transition hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-white"
            href="/quickstart#"
            use:link>Aide</a>
        </li>

        <li>
          <div class="text-xs leading-6 text-baliHai mt-2">{t('sidebar.sections.driveBusiness')}</div>
          <ul role="list" class="mt-2 space-y-1">
            <li class="relative">
              <NavLink to="/today">
                <svg fill="none" height="24" viewBox="0 0 24 24" width="24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.75 17.25H17.25C18.3546 17.25 19.25 16.3546 19.25 15.25V8.75C19.25 7.64543 18.3546 6.75 17.25 6.75H6.75C5.64543 6.75 4.75 7.64543 4.75 8.75V15.25C4.75 16.3546 5.64543 17.25 6.75 17.25H7.25"
                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="1.5"></path>
                  <path
                    d="M12 19C13.1046 19 14 18.1046 14 17H12.5C12.5 17.2761 12.2761 17.5 12 17.5V19ZM14 17C14 15.8954 13.1046 15 12 15V16.5C12.2761 16.5 12.5 16.7239 12.5 17H14ZM11.5 17C11.5 16.7239 11.7239 16.5 12 16.5V15C10.8954 15 10 15.8954 10 17H11.5ZM12 17.5C11.7239 17.5 11.5 17.2761 11.5 17H10C10 18.1046 10.8954 19 12 19V17.5Z"
                    fill="currentColor"></path>
                  <path d="M11 16L8.75 11.75" stroke="currentColor" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="1.5"></path>
                  <path d="M12 9.75V10.25" stroke="currentColor" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="1.5"></path>
                  <path d="M15.625 10.7213L15.375 11.1543" stroke="currentColor" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="1.5"></path>
                </svg>
                <span class="w-full text-sm pl-2 text-left z-10">{t('topMenu.nav.dashboard')}</span>
              </NavLink>
            </li>

            <li class="relative">
              <NavLink to="/transactions">
                <svg fill="none" height="24" viewBox="0 0 24 24" width="24">
                  <path
                    d="M19.25 8.25V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V6.75"
                    stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"></path>
                  <path
                    d="M16.5 13C16.5 13.2761 16.2761 13.5 16 13.5C15.7239 13.5 15.5 13.2761 15.5 13C15.5 12.7239 15.7239 12.5 16 12.5C16.2761 12.5 16.5 12.7239 16.5 13Z"
                    stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round"></path>
                  <path
                    d="M17.25 8.25H6.5C5.5335 8.25 4.75 7.4665 4.75 6.5C4.75 5.5335 5.5335 4.75 6.5 4.75H15.25C16.3546 4.75 17.25 5.64543 17.25 6.75V8.25ZM17.25 8.25H19.25"
                    stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"></path>
                </svg>
                <span class="w-full text-sm pl-2 text-left z-10">{t('topMenu.nav.transactions')}</span>
                {#if !isBankConfigLoaded}
                  <span class="lock align-middle"/>
                {/if}
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <div class="text-xs leading-6 text-baliHai mt-2">{t('sidebar.sections.getPaid')}</div>
          <ul role="list" class="mt-2 space-y-1">
            <li class="relative">
              {#if featureToggling().isKotlyEnabled}
                <NavLink to="/quotes">
                  <span class="truncate ml-8">{t('topMenu.nav.quotes')}</span>
                  <a href="/quotes/new/details" use:link class="text-zinc-500 hover:text-zinc-100"
                     style="margin-right: .18rem;">
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M4.75 12C4.75 7.99594 7.99594 4.75 12 4.75V4.75C16.0041 4.75 19.25 7.99594 19.25 12V12C19.25 16.0041 16.0041 19.25 12 19.25V19.25C7.99594 19.25 4.75 16.0041 4.75 12V12Z"></path>
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="1.5" d="M12 8.75003V15.25"></path>
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="1.5" d="M15.25 12L8.75 12"></path>
                    </svg>
                  </a>
                </NavLink>
              {/if}
            </li>
            <li class="relative">
              <NavLink to="/invoices">
                <div class="flex rounded-md text-sm bg-none relative w-full text-white cursor-pointer items-center justify-between appearance-none my-1 h-[37px]">
                  <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7.75 19.25H16.25C17.3546 19.25 18.25 18.3546 18.25 17.25V9L14 4.75H7.75C6.64543 4.75 5.75 5.64543 5.75 6.75V17.25C5.75 18.3546 6.64543 19.25 7.75 19.25Z"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18 9.25H13.75V5"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.75 15.25H14.25"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.75 12.25H14.25"></path>
                  </svg>
                  <span class="w-full text-sm pl-2 text-left z-10">{t('topMenu.nav.invoices')}</span>
                </div>

                <a class="text-zinc-500 hover:text-zinc-100"
                   class:disabled={!$ContactsStore.length}
                   href="/receivables/new/{BusinessDocumentSlugs.COMMERCIAL_INVOICE}/details"
                   style="margin-right: .18rem;"
                   use:link>
                  <svg fill="none" height="24" viewBox="0 0 24 24" width="24">
                    <path
                      d="M4.75 12C4.75 7.99594 7.99594 4.75 12 4.75V4.75C16.0041 4.75 19.25 7.99594 19.25 12V12C19.25 16.0041 16.0041 19.25 12 19.25V19.25C7.99594 19.25 4.75 16.0041 4.75 12V12Z"
                      stroke="currentColor" stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"></path>
                    <path d="M12 8.75003V15.25" stroke="currentColor" stroke-linecap="round"
                          stroke-linejoin="round" stroke-width="1.5"></path>
                    <path d="M15.25 12L8.75 12" stroke="currentColor" stroke-linecap="round"
                          stroke-linejoin="round" stroke-width="1.5"></path>
                  </svg>
                </a>
              </NavLink>
            </li>
            <li class="relative">
              <NavLink to="/dunning">
                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 6.75v-2m0 2H8.75m3.25 0h3.25m-6.5 6.5H6.639a1 1 0 0 1-.988-.843L4.75 6.75h4m0 6.5v6m0-6h.5m6 0h2.111a1 1 0 0 0 .988-.843l.901-5.657h-4m0 6.5v6m0-6h-.5m-5.5 0-.5-6.5m.5 6.5h5.5m0 0 .5-6.5"></path>
                </svg>
                <span class="w-full text-sm pl-2 text-left z-10">{t('topMenu.nav.dunning')}</span>
              </NavLink>
            </li>
            <Accordion multiple>
              <AccordionItem icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.75 7.77502H9.25M7.75 10.775H9.25M14.75 13.775H16.25M14.75 10.775H16.25M12.25 19.2496V5.74963C12.25 5.19735 11.8023 4.74963 11.25 4.74963H5.75C5.19772 4.74963 4.75 5.19735 4.75 5.74963V18.2496C4.75 18.8019 5.19772 19.2496 5.75 19.2496H12.25ZM12.25 19.2496H18.25C18.8023 19.2496 19.25 18.8019 19.25 18.2496V8.74963C19.25 8.19735 18.8023 7.74963 18.25 7.74963H12.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>' open title="{t('topMenu.nav.clients.title')}" urls={['/clients/manage']}>
                <ul role="list">
                  <li class="relative">
                    <NavLink to="/clients/manage">
                      <span class="truncate ml-8">{t('topMenu.nav.clients.manage')}</span>
                    </NavLink>
                  </li>
                  <li class="relative">
                    <NavLink to="/clients/aging-balance">
                      <span class="truncate ml-8">{t('topMenu.nav.clients.agingBalance')}</span>
                      {#if !isBankConfigLoaded}
                        <span class="lock"/>
                      {/if}
                    </NavLink>
                  </li>
                </ul>
              </AccordionItem>
            </Accordion>
          </ul>
        </li>
        <li>
          <div class="text-xs leading-6 text-baliHai mt-2">{t('sidebar.sections.manageStructure')}</div>
          <ul role="list" class="mt-2 space-y-1">
            <Accordion multiple>
              {#if isUserWorkspaceOwner && !prodEnv}
                <li class="relative">
                  <NavLink to="/team/">
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M5.78168 19.25H13.2183C13.7828 19.25 14.227 18.7817 14.1145 18.2285C13.804 16.7012 12.7897 14 9.5 14C6.21031 14 5.19605 16.7012 4.88549 18.2285C4.773 18.7817 5.21718 19.25 5.78168 19.25Z"></path>
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M15.75 14C17.8288 14 18.6802 16.1479 19.0239 17.696C19.2095 18.532 18.5333 19.25 17.6769 19.25H16.75"></path>
                      <circle cx="9.5" cy="7.5" r="2.75" stroke="currentColor" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="1.5"></circle>
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M14.75 10.25C16.2688 10.25 17.25 9.01878 17.25 7.5C17.25 5.98122 16.2688 4.75 14.75 4.75"></path>
                    </svg>
                    <span class="w-full text-sm pl-2 text-left z-10">{t('topMenu.nav.team.label')}</span>
                  </NavLink>
                </li>
              {/if}

              <AccordionItem
                icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.62117 14.9627L6.72197 15.1351C7.53458 15.2623 8.11491 16.0066 8.05506 16.8451L7.97396 17.9816C7.95034 18.3127 8.12672 18.6244 8.41885 18.7686L9.23303 19.1697C9.52516 19.3139 9.87399 19.2599 10.1126 19.0352L10.9307 18.262C11.5339 17.6917 12.4646 17.6917 13.0685 18.262L13.8866 19.0352C14.1252 19.2608 14.4733 19.3139 14.7662 19.1697L15.5819 18.7678C15.8733 18.6244 16.0489 18.3135 16.0253 17.9833L15.9441 16.8451C15.8843 16.0066 16.4646 15.2623 17.2772 15.1351L18.378 14.9627C18.6985 14.9128 18.9568 14.6671 19.0292 14.3433L19.23 13.4428C19.3025 13.119 19.1741 12.7831 18.9064 12.5962L17.9875 11.9526C17.3095 11.4774 17.1024 10.5495 17.5119 9.82051L18.067 8.83299C18.2284 8.54543 18.2017 8.18538 17.9993 7.92602L17.4363 7.2035C17.2339 6.94413 16.8969 6.83701 16.5867 6.93447L15.5221 7.26794C14.7355 7.51441 13.8969 7.1012 13.5945 6.31908L13.1866 5.26148C13.0669 4.95218 12.7748 4.7492 12.4496 4.75L11.5472 4.75242C11.222 4.75322 10.9307 4.95782 10.8126 5.26793L10.4149 6.31344C10.1157 7.1004 9.27319 7.51683 8.4842 7.26874L7.37553 6.92078C7.0645 6.82251 6.72591 6.93044 6.52355 7.19142L5.96448 7.91474C5.76212 8.17652 5.73771 8.53738 5.90228 8.82493L6.47 9.81487C6.88812 10.5446 6.68339 11.4814 6.00149 11.9591L5.0936 12.5954C4.82588 12.7831 4.69754 13.119 4.76998 13.442L4.97077 14.3425C5.04242 14.6671 5.30069 14.9128 5.62117 14.9627Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                              <path d="M13.5911 10.4089C14.4696 11.2875 14.4696 12.7125 13.5911 13.5911C12.7125 14.4696 11.2875 14.4696 10.4089 13.5911C9.53036 12.7125 9.53036 11.2875 10.4089 10.4089C11.2875 9.53036 12.7125 9.53036 13.5911 10.4089Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>'
                title={t('topMenu.nav.profileSection')}
                urls={['/settings/invoicing-app/edit, /settings/profile/edit, /settings/company/edit, /settings/bank/view']}>
                <ul role="list">
                  {#if !prodEnv}
                    <li class="relative">
                      <NavLink to="/workflow">
                        <span class="truncate ml-8">{t('topMenu.nav.settings.reminderWorkflow')}</span>
                      </NavLink>
                    </li>
                  {/if}
                  <li class="relative">
                    <NavLink to="/settings/invoicing-app/edit">
                      <span class="truncate ml-8">{t('topMenu.nav.settings.invoicingSettings')}</span>
                    </NavLink>
                  </li>
                  <!--{#if isUserWorkspaceOwner}-->
                  <li class="relative">
                    <NavLink to="/settings/company/edit">
                      <span class="truncate ml-8">{t('topMenu.nav.settings.companySettings')}</span>
                    </NavLink>
                  </li>
                  <li class="relative">
                    <NavLink to="/settings/bank/view">
                      <span class="truncate ml-8">{t('topMenu.nav.settings.bankAccounts')}</span>
                    </NavLink>
                  </li>
                  <!--{/if}-->
                  <li class="relative">
                    <NavLink to="/settings/exports">
                      <span class="truncate ml-8">{t('topMenu.nav.settings.exports')}</span>
                    </NavLink>
                  </li>
                  <li class="relative">
                    <span class="absolute end-6 text-zinc-500 text-xs">{getVersion()}</span>
                  </li>
                </ul>
              </AccordionItem>
            </Accordion>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</div>

<style lang="postcss">
  .sidebar-container {
    @apply relative hidden lg:pl-1.5 lg:py-1.5 lg:pointer-events-auto lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col;
    transition: all 0.3s ease-in-out;
  }
  .lock {
    mask: url("/img/icons/lock.svg");
    mask-repeat: no-repeat;
    background: #FFB42D;
    height: 24px;
    width: 24px;
    margin-top: 16px;
  }
  .disabled {
    pointer-events: none;
  }
</style>
<script lang="ts">
    export let header = ''
    export let message = ''
    export let button = null
</script>

<span class="alert bg-cruise">
  <i class="icon-circle_checked text-2xl mr-2"/>
  <span> {header} </span> &nbsp; {message}
    {#if button}
    <button>{button}</button>
  {/if}
</span>
import { writable } from 'svelte/store'

/* console.log('current-path.store.ts') */

const currentPathStore = writable('')
export default currentPathStore

// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable, then it is executed immediately (including when the store.ts file is imported at the beginning of the web app) and its argument is whatever the store variable contains at this point
currentPathStore.subscribe(path => {
  /* console.log('%c Path changed %s', 'font-size: 1.2em; background: #FFC796;', path) */
})

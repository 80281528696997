import Handlebars from 'handlebars'
import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
import { buildEmailFromTemplate } from './email-template-builder.js'
import { deepClone } from '../../../core-app/util/object-deep-cloning'

const todoActionBaseEmailTemplate: string = `
    <tr>
        <td class="pt-28 td_content" style="background:#fff; padding:44px 64px 57px; border:1px solid #dcdcd9; border-radius:8px;">
            <table align="center" cellpadding="0" cellspacing="0" class="w-100p" style="max-width:472px; margin:0 auto;" width="472">
                <table>
                    <tr>
                        <td>
                            <table style="border-spacing: 0;">
                                <tr>
                                    <td valign="middle">
                                        {{> titleImagePartial}}
                                    </td>
                                    <td valign="middle">
                                        <span style="font-weight:bold;color:#1F2533;font:10px Arial-Bold, Helvetica-Bold, sans-serif;text-transform:uppercase;">{{title}}</span>
                                        <span style="color:transparent;display:none;height:0;max-height:0;max-width:0;opacity:0;overflow:hidden;mso-hide:all;visibility:hidden;width:0;">&nbsp;|&nbsp;</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span style="color:transparent;display:none;height:0;max-height:0;max-width:0;opacity:0;overflow:hidden;mso-hide:all;visibility:hidden;width:0;">&nbsp;|&nbsp;</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{> invoiceDetailsPartial}}
                        </td>
                    </tr>
                    {{#if showPaymentLink}}
                    <tr>
                        <td>
                            <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;"><br />${t('todoAction.baseActionEmailItemBody.bankDetails')}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                    <tr>
                        <td style="padding: 24px 0;">
                            <table style="width:100%;border-spacing: 0;padding: 0;">
                                <tr>
                                    <td class="active-t btn" align="center" style="background:#1F2533;font:13px/16px Arial, Helvetica, sans-serif;border-radius:6px;height:48px;"><a style="color:#fff; text-decoration:none; display:block; padding:16px 10px;" target="_blank" href="{{paymentLink}}">${t('todoAction.baseActionEmailItemBody.makePayment')}</a>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    {{/if}}
                    {{#unless showPaymentLink}}
                        <tr style="width: 100%; height: 4vh;"></tr>
                    {{/unless}}
                    <tr>
                        <td style="padding: 20px;background: #F6F5F9; border-radius: 10px;">
                            <p style="font-weight:400;color:#606F82;font:12px/20px Arial, Helvetica-Light, sans-serif; margin: 0 0 5px 0;">${t('todoAction.baseActionEmailItemBody.bankDetailsTitle')} :</p>
                            <p style="font-weight:400;color:#606F82;font:12px/20px Arial, Helvetica-Light, sans-serif;">${t('todoAction.baseActionEmailItemBody.bank')} : <span style="color: #1F2533">{{bankName}}</span></p>
                            <p style="font-weight:400;color:#606F82;font:12px/20px Arial, Helvetica-Light, sans-serif;">${t('todoAction.baseActionEmailItemBody.iban')} : <span style="color: #1F2533">{{bankIBAN}}</span></p>
                            {{#if bankBIC}}
                            <p style="font-weight:400;color:#606F82;font:12px/20px Arial, Helvetica-Light, sans-serif;">${t('todoAction.baseActionEmailItemBody.bic')} : <span style="color: #1F2533">{{bankBIC}}</span></p>
                            {{/if}}
                            <p style="font-weight:400;color:#606F82;font:12px/20px Arial, Helvetica-Light, sans-serif;margin: 0 0 5px 0;">${t('todoAction.baseActionEmailItemBody.reference')} : <span style="color: #1F2533">{{invoiceNumber}}</span></p>
                            <p style="font-weight:400;color:#606F82;font:12px/20px Arial, Helvetica-Light, sans-serif;">${t('todoAction.baseActionEmailItemBody.thanks')}</p>
                        </td>
                    </tr>
                  
                    <tr>
                        <td>
                            <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;padding-top:32px;">${t('todoAction.baseActionEmailItemBody.thoughtfully')},</p>
                            <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;">{{senderFirstName}} {{senderLastName}}</p>
                            <p style="font-weight:400;color:#1F2533;font:12px/20px Arial, Helvetica-Light, sans-serif;">{{role}} - {{senderCompanyName}}</p>
                        </td>
                    </tr>
                </table>
            </table>
        </td>
    </tr>
`

const commonInvoiceDetailsPartial: string = `
    <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;margin: 0 0 24px 0;">
        {{#if firstName}}
            ${t('todoAction.baseActionEmailItemBody.goodMorning')} {{firstName}},<br/><br />
        {{/if}}
        {{#unless firstName}}
            ${t('todoAction.baseActionEmailItemBody.goodMorning')},<br/><br />
        {{/unless}}
        {{#if showInvoiceAttachment}}
            ${t('todoAction.baseActionEmailItemBody.hereIs', { invoiceNumber: '{{invoiceNumber}}', senderCompanyName: '{{senderCompanyName}}' })}
        {{/if}}
        {{#unless showInvoiceAttachment}}
                ${t('todoAction.baseActionEmailItemBody.invoiceAvailable', { invoiceNumber: '{{invoiceNumber}}', senderCompanyName: '{{senderCompanyName}}' })}
        {{/unless}}
       
    </p>
    <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;">${t('todoAction.baseActionEmailItemBody.amount')} : <span style="font-weight:600; color: #1F2533">{{invoiceAmount}} ${t('todoAction.baseActionEmailItemBody.ttc')}</span></p>
    <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;margin: 0 0 24px 0;">${t('todoAction.baseActionEmailItemBody.dueDate')} : <span style="font-weight:600; color: #1F2533">{{dueDate}}</span></p>
    <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;">${t('todoAction.baseActionEmailItemBody.objection')}</p>
`

const invoiceDetailsPartials = {
  InvoiceIssuance: commonInvoiceDetailsPartial,
  CourtesyReminderShortlyBeforeDueDate: commonInvoiceDetailsPartial,
  ReminderJustAfterDueDate: `
        <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;margin: 0 0 24px 0;">
            {{#if firstName}}
                ${t('todoAction.baseActionEmailItemBody.goodMorning')} {{firstName}},<br/><br />
            {{/if}}
            {{#unless firstName}}
                ${t('todoAction.baseActionEmailItemBody.goodMorning')},<br/><br />
            {{/unless}}
            {{#if showInvoiceAttachment}}
                ${t('todoAction.baseActionEmailItemBody.hereIs', { invoiceNumber: '{{invoiceNumber}}', senderCompanyName: '{{senderCompanyName}}' })} 
            {{/if}}
            {{#unless showInvoiceAttachment}}
                ${t('todoAction.baseActionEmailItemBody.invoiceAvailable', { invoiceNumber: '{{invoiceNumber}}', senderCompanyName: '{{senderCompanyName}}' })}
            {{/unless}}
           
        </p>
        <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;">${t('todoAction.baseActionEmailItemBody.amount')} : <span style="font-weight:600; color: #1F2533">{{invoiceAmount}} ${t('todoAction.baseActionEmailItemBody.ttc')}</span></p>
        <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;margin: 0 0 24px 0;">${t('todoAction.baseActionEmailItemBody.dueDate')} : <span style="font-weight:600; color: #1F2533">{{dueDate}}</span></p>
        ${t('todoAction.actionKind.ReminderJustAfterDueDate.messageKind.Email.itemBody.thanksForPaying')}
    `,
  ReminderShortlyAfterDueDate: `
        <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;margin: 0 0 24px 0;">
            {{#if firstName}}
                ${t('todoAction.baseActionEmailItemBody.goodMorning')} {{firstName}},<br/><br />
            {{/if}}
            {{#unless firstName}}
                ${t('todoAction.baseActionEmailItemBody.goodMorning')},<br/><br />
            {{/unless}}
            ${ t('todoAction.actionKind.ReminderShortlyAfterDueDate.messageKind.Email.itemBody.ifNotMistaken', { invoiceNumber: '{{invoiceNumber}}', senderCompanyName: '{{senderCompanyName}}' })}
        </p>
        <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;">${t('todoAction.baseActionEmailItemBody.amount')} : <span style="font-weight:600; color: #1F2533">{{invoiceAmount}} ${t('todoAction.baseActionEmailItemBody.ttc')}</span></p>
        <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif; margin: 0 0 24px 0;">${t('todoAction.baseActionEmailItemBody.dueDate')} : <span style="font-weight:600; color: #1F2533">{{dueDate}}</span></p>
        <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif; margin: 0 0 24px 0;">${t('todoAction.actionKind.ReminderShortlyAfterDueDate.messageKind.Email.itemBody.thanksForPaying')}</p>
        <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;">${t('todoAction.actionKind.ReminderShortlyAfterDueDate.messageKind.Email.itemBody.ifYouFind', { senderFirstName: '{{senderFirstName}}', senderLastName: '{{senderLastName}}', senderEmail: '{{senderEmail}}', senderPhoneNumber: '{{senderPhoneNumber}}' })}</p>
    `,
  DirectReminderSomeTimeAfterDueDate: `
        <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;margin: 0 0 24px 0;">
            {{#if firstName}}
                ${ t('todoAction.baseActionEmailItemBody.goodMorning') } {{firstName}},<br/>
            {{/if}}
            {{#unless firstName}}
                ${ t('todoAction.baseActionEmailItemBody.goodMorning') },<br/>
            {{/unless}}
            </p>
            <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;margin: 0 0 24px 0;">
                ${ t('todoAction.actionKind.DirectReminderSomeTimeAfterDueDate.messageKind.Email.itemBody.ifNotMistaken', { invoiceNumber: '{{invoiceNumber}}', invoiceAmount: '{{invoiceAmount}}', senderCompanyName: '{{senderCompanyName}}', daysLate: '{{daysLate}}' }) }</p>
            <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;margin: 0 0 24px 0;">
                ${ t('todoAction.actionKind.DirectReminderSomeTimeAfterDueDate.messageKind.Email.itemBody.howToPay') }
            </p>
            <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;margin: 0 0 0 0;">
                ${ t('todoAction.actionKind.DirectReminderSomeTimeAfterDueDate.messageKind.Email.itemBody.ifYouPaid') }
        </p>`,
  StrongLastReminderAfterDueDate: `
        <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;margin: 0 0 24px 0;">
            {{#if firstName}}
                ${t('todoAction.baseActionEmailItemBody.goodMorning')} {{firstName}},<br/><br />
            {{/if}}
            {{#unless firstName}}
                ${t('todoAction.baseActionEmailItemBody.goodMorning')},<br/><br />
            {{/unless}}
            ${t('todoAction.actionKind.StrongLastReminderAfterDueDate.messageKind.Email.itemBody.strongReminder', { invoiceNumber: '{{invoiceNumber}}', senderCompanyName: '{{senderCompanyName}}', dueDays: '{{dueDays}}' })}
        </p>`
}

const titleImagePartials = {
  InvoiceIssuance: '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAgCAYAAAB3j6rJAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAIqADAAQAAAABAAAAIAAAAAB4uvI0AAAB/ElEQVRYCcVXPS9EURBdolJoNHREr9FqdbRarZ9ARGy0Wn9CfJXabVVCQ6LQC1GQSBQ4R97ZzM7ed+/dt+89k5zceXNnzsyc7Fu206luiyi9L7BQnWb8ynNQ/BSg/y+2jK7fgAahz1jrZtXQMK2rYtWgElKmdVWsGmcYhGhdFa8Gn0MxhJs1r4a6tapKbPPYnYat7SxTQw1aUSVn45wcDV35TKkh4kZVGWXTUXI1fPaZq4YIG1GlyoZVarRE6TmqGiKqVZXUZkvo+gjcAPOaoDhTtS49/phSYxfl+huzE6Cy9fQrWc5GXTBrkINAlxyOftlk3xt0SDxRhC5w3g1eZz2x5rLIJFdo2ChR7iZdsMQUYZNcrk5IkTrU4BC0yqrkbjCNJieAFKHPWMhyOQdq7Sed3wXeZhDg2/IMaAidjPGOOd4sb/INik0+C+ZD4A1Q47KTOcxljSzGrZz+aaeWGnO4PQI+AN/4CbHtAvT9/TtirCUHzfKXquInXkfhMfAJ+AYPiG0BU4CMPmO88/nkINcGkPyP3077hQLCE94itgmE3jSE/4x3zGGur/e8Q6pYNXwxn68BbqMvOLhJYy5rWBviZGzod5BVwxb1kLwGjGvk6AGWW/6pJfdvwhUuV21CTT45ya0heL5Y7n08vAJUZsVeNOSzB3txiD31+AXgqB4he53mGgAAAABJRU5ErkJggg==" width="16" height="16" style="margin: 0 7px 0 0; vertical-align: middle;" />',
  CourtesyReminderShortlyBeforeDueDate: '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAgCAYAAAB3j6rJAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAIqADAAQAAAABAAAAIAAAAAB4uvI0AAAB/ElEQVRYCcVXPS9EURBdolJoNHREr9FqdbRarZ9ARGy0Wn9CfJXabVVCQ6LQC1GQSBQ4R97ZzM7ed+/dt+89k5zceXNnzsyc7Fu206luiyi9L7BQnWb8ynNQ/BSg/y+2jK7fgAahz1jrZtXQMK2rYtWgElKmdVWsGmcYhGhdFa8Gn0MxhJs1r4a6tapKbPPYnYat7SxTQw1aUSVn45wcDV35TKkh4kZVGWXTUXI1fPaZq4YIG1GlyoZVarRE6TmqGiKqVZXUZkvo+gjcAPOaoDhTtS49/phSYxfl+huzE6Cy9fQrWc5GXTBrkINAlxyOftlk3xt0SDxRhC5w3g1eZz2x5rLIJFdo2ChR7iZdsMQUYZNcrk5IkTrU4BC0yqrkbjCNJieAFKHPWMhyOQdq7Sed3wXeZhDg2/IMaAidjPGOOd4sb/INik0+C+ZD4A1Q47KTOcxljSzGrZz+aaeWGnO4PQI+AN/4CbHtAvT9/TtirCUHzfKXquInXkfhMfAJ+AYPiG0BU4CMPmO88/nkINcGkPyP3077hQLCE94itgmE3jSE/4x3zGGur/e8Q6pYNXwxn68BbqMvOLhJYy5rWBviZGzod5BVwxb1kLwGjGvk6AGWW/6pJfdvwhUuV21CTT45ya0heL5Y7n08vAJUZsVeNOSzB3txiD31+AXgqB4he53mGgAAAABJRU5ErkJggg==" width="16" height="16" style="margin: 0 7px 0 0; vertical-align: middle;" />',
  ReminderJustAfterDueDate: '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAIKADAAQAAAABAAAAIAAAAACshmLzAAAC9ElEQVRYCbWXvYoUQRSFewxmA8VYN1QwWFCfYRZls8XEFxAzNzNRI1FhNVkwUBP1BUw0FtxwEQQjZV2z9QejBX8QwUDPV85x71R30zXNzIUzdavq3nPuVHdXdVdVVQ2EdeGTcFlos6EmVoR7wqbwTvg+Bj5jzBFDbJuh8UXYENBOzh+1gIncjmjgvvBVcFxXSyw55ObGH3U+fzyJeoCqbAtybgg/BM9P25ILB1w2RM1DMWnZcZhIS6KWyrcEB7rd1tgd4YxwQjg0Bj5jzHE5HO8WLq8GGvxRVrvxkp/SxAfBybSvhGWh1IglJ3LAebKLgCqj+G/1Lwlema78OE8OuXC4ELi9EnInbUHduOx76o8mQ3r14IDLRaCBVs24WRxE1bMQtwhccSXQmjCWJd7tLF2XHVfAe+G1cLQrWPNrgv8gWhOX4kGY5OYZCF12VQEmvNIVrHk4443JPpGMXStuMqV3+3XluQD8EoPbOWgOD+iH63NYwHiGXyRvPj9w74yp0RxRwOp4gOZZ8OflPg3EqxSwFAaeB39ebtRYooDFoLQb/Hm5UWMxL+BzT9XTyit5cqCPGqmAnprVm5B4Tv5DoaSIWkw8vTjVSg2iR4IfK1r6NQGNRUPDOWinNxkPcKROY32KQMN6m9wDb4Pi2eCXuBBdFB6H4Avyb4d+7kaNpL2iCFe0nUcX9vOVaHq1M1W85GinF8hval3EsiOnbCliQ0D8Wksu3NZBc+g4DgZPlB5Gzi1tKbDxMIKgz3FcKuy4NTn+k7XjmKCbIYCXhxGDMzK44HQBaNWM16QtwUF78mdRBBxwmRcNtBqNS/FRcDBVz/KlFG40Wm2gmSeCC3DLzTPN00FsvOHMAzcajcZEvrU60S37RJ8PE+fTtp4ZPMMxkN3tlvArG48xXT65cMAVY9fVrxkbiIPioXJM4+wT8b3RcW0tseSQi+Wrm74J/03t//Kdln8j7s9W1UF1zgt3hZfCrvBzDHzGmCOG2Nwowjvl/2/Cvz3cO+YmlRULAAAAAElFTkSuQmCC" width="16" height="16" alt="" style="margin: 0 7px 0 0; vertical-align: middle;" />',
  ReminderShortlyAfterDueDate: '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAIKADAAQAAAABAAAAIAAAAACshmLzAAAC9ElEQVRYCbWXvYoUQRSFewxmA8VYN1QwWFCfYRZls8XEFxAzNzNRI1FhNVkwUBP1BUw0FtxwEQQjZV2z9QejBX8QwUDPV85x71R30zXNzIUzdavq3nPuVHdXdVdVVQ2EdeGTcFlos6EmVoR7wqbwTvg+Bj5jzBFDbJuh8UXYENBOzh+1gIncjmjgvvBVcFxXSyw55ObGH3U+fzyJeoCqbAtybgg/BM9P25ILB1w2RM1DMWnZcZhIS6KWyrcEB7rd1tgd4YxwQjg0Bj5jzHE5HO8WLq8GGvxRVrvxkp/SxAfBybSvhGWh1IglJ3LAebKLgCqj+G/1Lwlema78OE8OuXC4ELi9EnInbUHduOx76o8mQ3r14IDLRaCBVs24WRxE1bMQtwhccSXQmjCWJd7tLF2XHVfAe+G1cLQrWPNrgv8gWhOX4kGY5OYZCF12VQEmvNIVrHk4443JPpGMXStuMqV3+3XluQD8EoPbOWgOD+iH63NYwHiGXyRvPj9w74yp0RxRwOp4gOZZ8OflPg3EqxSwFAaeB39ebtRYooDFoLQb/Hm5UWMxL+BzT9XTyit5cqCPGqmAnprVm5B4Tv5DoaSIWkw8vTjVSg2iR4IfK1r6NQGNRUPDOWinNxkPcKROY32KQMN6m9wDb4Pi2eCXuBBdFB6H4Avyb4d+7kaNpL2iCFe0nUcX9vOVaHq1M1W85GinF8hval3EsiOnbCliQ0D8Wksu3NZBc+g4DgZPlB5Gzi1tKbDxMIKgz3FcKuy4NTn+k7XjmKCbIYCXhxGDMzK44HQBaNWM16QtwUF78mdRBBxwmRcNtBqNS/FRcDBVz/KlFG40Wm2gmSeCC3DLzTPN00FsvOHMAzcajcZEvrU60S37RJ8PE+fTtp4ZPMMxkN3tlvArG48xXT65cMAVY9fVrxkbiIPioXJM4+wT8b3RcW0tseSQi+Wrm74J/03t//Kdln8j7s9W1UF1zgt3hZfCrvBzDHzGmCOG2Nwowjvl/2/Cvz3cO+YmlRULAAAAAElFTkSuQmCC" width="16" height="16" alt="" style="margin: 0 7px 0 0; vertical-align: middle;" />',
  DirectReminderSomeTimeAfterDueDate: '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAIKADAAQAAAABAAAAIAAAAACshmLzAAAC9ElEQVRYCbWXvYoUQRSFewxmA8VYN1QwWFCfYRZls8XEFxAzNzNRI1FhNVkwUBP1BUw0FtxwEQQjZV2z9QejBX8QwUDPV85x71R30zXNzIUzdavq3nPuVHdXdVdVVQ2EdeGTcFlos6EmVoR7wqbwTvg+Bj5jzBFDbJuh8UXYENBOzh+1gIncjmjgvvBVcFxXSyw55ObGH3U+fzyJeoCqbAtybgg/BM9P25ILB1w2RM1DMWnZcZhIS6KWyrcEB7rd1tgd4YxwQjg0Bj5jzHE5HO8WLq8GGvxRVrvxkp/SxAfBybSvhGWh1IglJ3LAebKLgCqj+G/1Lwlema78OE8OuXC4ELi9EnInbUHduOx76o8mQ3r14IDLRaCBVs24WRxE1bMQtwhccSXQmjCWJd7tLF2XHVfAe+G1cLQrWPNrgv8gWhOX4kGY5OYZCF12VQEmvNIVrHk4443JPpGMXStuMqV3+3XluQD8EoPbOWgOD+iH63NYwHiGXyRvPj9w74yp0RxRwOp4gOZZ8OflPg3EqxSwFAaeB39ebtRYooDFoLQb/Hm5UWMxL+BzT9XTyit5cqCPGqmAnprVm5B4Tv5DoaSIWkw8vTjVSg2iR4IfK1r6NQGNRUPDOWinNxkPcKROY32KQMN6m9wDb4Pi2eCXuBBdFB6H4Avyb4d+7kaNpL2iCFe0nUcX9vOVaHq1M1W85GinF8hval3EsiOnbCliQ0D8Wksu3NZBc+g4DgZPlB5Gzi1tKbDxMIKgz3FcKuy4NTn+k7XjmKCbIYCXhxGDMzK44HQBaNWM16QtwUF78mdRBBxwmRcNtBqNS/FRcDBVz/KlFG40Wm2gmSeCC3DLzTPN00FsvOHMAzcajcZEvrU60S37RJ8PE+fTtp4ZPMMxkN3tlvArG48xXT65cMAVY9fVrxkbiIPioXJM4+wT8b3RcW0tseSQi+Wrm74J/03t//Kdln8j7s9W1UF1zgt3hZfCrvBzDHzGmCOG2Nwowjvl/2/Cvz3cO+YmlRULAAAAAElFTkSuQmCC" width="16" height="16" alt="" style="margin: 0 7px 0 0; vertical-align: middle;" />',
  StrongLastReminderAfterDueDate: '<img src="data:image/png;base654,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuMjUgMTUuMjVDNiAxNS4yNSA0Ljc1IDEzLjc5NDkgNC43NSAxMkM0Ljc1IDEwLjI4NjkgNi4wNzU0MiA4Ljg4MzM5IDcuNzU2NzIgOC43NTg5N0M3Ljg4MTY4IDYuNTIzOSA5LjczMzY4IDQuNzUgMTIgNC43NUMxNC4yNjYzIDQuNzUgMTYuMTE4MyA2LjUyMzkgMTYuMjQzMyA4Ljc1ODk3QzE3LjkyNDYgOC44ODMzOSAxOS4yNSAxMC4yODY5IDE5LjI1IDEyQzE5LjI1IDEzLjc5NDkgMTggMTUuMjUgMTYuNzUgMTUuMjUiIHN0cm9rZT0iY3VycmVudENvbG9yIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xMi4yNSAxMi43NUwxMC43NSAxNi4yNUgxMy4yNUwxMS43NSAxOS4yNSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==" width="16" height="16" alt="" style="margin: 0 7px 0 0; vertical-align: middle;" />'
}

const specificData = {
  InvoiceIssuance: {
    title: t('todoAction.actionKind.InvoiceIssuance.messageKind.Email.itemBody.title')
  },
  CourtesyReminderShortlyBeforeDueDate: {
    title: t('todoAction.actionKind.CourtesyReminderShortlyBeforeDueDate.messageKind.Email.itemBody.title')
  },
  ReminderJustAfterDueDate: {
    title: t('todoAction.actionKind.ReminderJustAfterDueDate.messageKind.Email.itemBody.title')
  },
  ReminderShortlyAfterDueDate: {
    title: t('todoAction.actionKind.ReminderShortlyAfterDueDate.messageKind.Email.itemBody.title')
  },
  DirectReminderSomeTimeAfterDueDate: {
    title: t('todoAction.actionKind.DirectReminderSomeTimeAfterDueDate.messageKind.Email.itemBody.title')
  },
  StrongLastReminderAfterDueDate: {
    title: t('todoAction.actionKind.StrongLastReminderAfterDueDate.messageKind.Email.itemBody.title')
  }
}

export const buildTodoActionEmail = (actionKind: any, data: any) => {
  Handlebars.registerPartial('titleImagePartial', titleImagePartials[actionKind] || '')
  Handlebars.registerPartial('invoiceDetailsPartial', invoiceDetailsPartials[actionKind] || '')
  
  return buildEmailFromTemplate(todoActionBaseEmailTemplate, { ...deepClone(data), ...deepClone(specificData[actionKind]) })
}
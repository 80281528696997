<script lang="ts">
    import { t } from '../i18n/i18nextWrapper'
    import { formatCurrencyWithOptions } from '$voxy/utils/number-formatting-utils'
    import type {Readable} from "svelte/store"

    /** Export let */
    export let classes: string
    export let overdueAmount: number
    export let dueAmount: number
    export let duePercentage: number
    export let overduePercentage: number
    export let invoicesCurrency: string | Readable<string>
    export let stackLabelsOnSmallerScreens: boolean
    export let showTooltip: boolean = true
    export let showAmountBars: boolean = true
</script>

<div style="width: 300px;" class="{classes}" data-ut-component="amounts-meter">
    {#if showAmountBars}
        <div class="flex flex-row items-center">
            {#if overduePercentage}
                <div class="border border-hotCinnamon bg-apricotPeach h-4 mr-px"
                     class:rounded-l={duePercentage}
                     class:rounded={!duePercentage}
                     style="width: {overduePercentage ? overduePercentage : '0'}%"></div>
            {/if}
            {#if duePercentage}
                <div class="border border-blueStone bg-ottoman h-4 ml-px"
                     class:rounded-r={overduePercentage}
                     class:rounded={!overduePercentage}
                     style="width: {duePercentage ? duePercentage : '0'}%"></div>
            {/if}
        </div>
    {/if}
    <div class="flex xl:flex-row mt-3 justify-between"
         class:flex-col={stackLabelsOnSmallerScreens}
         class:flex-row={!stackLabelsOnSmallerScreens}>
        <div class="flex flex-row items-start">
            <div class="rounded-full border border-hotCinnamon bg-apricotPeach w-3 h-3 mt-1 mr-1"></div>
            <div class="flex flex-col">
                <div class="flex flex-row items-center">
                    <span class="ml-1 text-xs text-paleSky">{t('amountsMeter.overdue')}</span>
                    {#if showTooltip}
                        <div class="relative flex flex-col items-center group ml-1 whitespace-normal">
                            <i class="icon-info relative text-lg text-loblolly"></i>
                            <div class="absolute bottom-0 flex-col items-center hidden mb-6 group-hover:flex">
                                <span class="relative w-52 z-10 px-6 py-4 text-xs text-black font-normal whitespace-no-wrap bg-barleyWhite border border-goldSand rounded-md">{@html t('amountsMeter.overdueTip')}</span>
                                <div class="w-3 h-3 -mt-2 rotate-45 border border-goldSand bg-barleyWhite"></div>
                            </div>
                        </div>
                    {/if}
                </div>
                <div class="ml-1 text-sm font-bold">
                    {formatCurrencyWithOptions(overdueAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                </div>
            </div>
        </div>
        <div class="flex flex-row items-start">
            <div class="rounded-full border border-blueStone bg-ottoman w-3 h-3 mt-1 mr-1"></div>
            <div class="flex flex-col items-start">
                <div class="flex flex-row items-center">
                    <span class="ml-1 text-xs text-paleSky">{t('amountsMeter.onTime')}</span>
                    {#if showTooltip}
                        <div class="relative flex flex-col items-center group ml-1 whitespace-normal">
                            <i class="icon-info relative text-lg text-loblolly"></i>
                            <div class="absolute bottom-0 flex-col items-center hidden mb-6 group-hover:flex">
                                <span class="relative w-52 z-10 px-6 py-4 text-xs text-black font-normal whitespace-no-wrap bg-barleyWhite border border-goldSand rounded-md">{@html t('amountsMeter.onTimeTip')}</span>
                                <div class="w-3 h-3 -mt-2 rotate-45 border border-goldSand bg-barleyWhite"></div>
                            </div>
                        </div>
                    {/if}
                </div>
                <div class="ml-1 text-sm font-bold">
                    {formatCurrencyWithOptions(dueAmount, t('locales'), invoicesCurrency, true, 'symbol')}
                </div>
            </div>
        </div>
    </div>
</div>
import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
import { type WorkflowMedia, type WorkflowTemplate, WorkflowMediaType, WorkflowTemplateType } from '$dundy/data/workflow'

export const WorkflowMedias:WorkflowMedia[] = [
  { 
    key: WorkflowMediaType.MAIL,
    label: t('todoAction.dunningMeansTab.email'),
    icon: '/img/icons/mail.svg' 
  },
  { 
    key: WorkflowMediaType.PHONE,
    label: t('todoAction.dunningMeansTab.phoneCall'),
    icon: '/img/icons/phone.svg' 
  },
  { 
    key: WorkflowMediaType.LETTER,
    label: t('todoAction.dunningMeansTab.postalLetter'),
    icon: '/img/icons/mailbox.svg'
  }
]

export const WorkflowTemplates:WorkflowTemplate[] = [
  {
    key: WorkflowTemplateType.DEFAULT,
    label: t('workflow.defaultTemplate'),
    icon: '/img/icons/file-text.svg' 
  },
  {
    key: WorkflowTemplateType.CUSTOM,
    label: t('workflow.customTemplate'),
    icon: '/img/icons/file-code.svg' 
  }
]
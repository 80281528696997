import Company from '../../../crm-app/models/company'
import { BusinessDocument } from '../../models/business-document'
import { BusinessDocumentKind } from '../../enums/business-document-kind'
import {
  filterAllBusinessDocumentsAccordingToKind,
  filterAllBusinessDocumentsFinalized,
  filterAllBusinessDocumentsWithCustomerId,
} from '../business-document-array-filters/business-documents-filters.service'
import { calculateCumulatedInvoicedInstallmentsIncludingTax, isDealOpenToInvoicing } from './incomplete-deals-calculation.service'
import type { ComputedInstallmentValues } from '../../models/installment-computed-accumulated-values.model'
import type { Workspace } from "$crm/models/workspace";
import type { Customer } from "$crm/models/customer";
import type { Contact } from "$crm/models/contact";
import { DealWithRemainingBalanceToInvoice } from "$voxy/models/deal-with-remaining-balance-to-invoice.model";
import {
  getLatestFinalBusinessDocumentWithDealId,
  newBalanceBusinessDocumentAndCollateralDataFromUserSelectedDeal
} from "$voxy/services/deal-balance-document-make/balance-business-document-and-collateral-for-selected-deal.service";
import type { BalanceOfDealBusinessDocumentBuildingData } from "$voxy/models/partial-business-document-building-data.model";
import { TaxonomyTag } from "$voxy/enums/taxonomy-tag";

/**
 *
 * Get lists of partial business documents for a customer
 * - Filter for business documents that belong to the company,
 * - have BusinessDocumentStatus of FINAL,
 * - have hasSpecificInstallment set to true
 * - and don't already have a balance invoice related to them
 * - and have BusinessDocumentKind of either INVOICE or CREDIT_NOTE
 * @param dealId
 * @param customerCompany
 * @param businessDocuments
 * @param businessDocumentKind
 */
/*export function listOfBusinessDocumentsWithDealHavingARemainingAmountToInvoiceForGivenCustomerCompany(customerCompany: Company, businessDocuments: BusinessDocument[], businessDocumentKind: BusinessDocumentKind): BusinessDocument[] {
  const relevantBusinessDocumentsForCustomerId: BusinessDocument[] = businessDocuments
    .filter(filterAllBusinessDocumentsWithCustomerId(customerCompany.companyId))
    .filter(filterAllBusinessDocumentsFinalized)
  const allDealIdsSet: Set<string> = new Set<string>()
  for (let aBusinessDocument of relevantBusinessDocumentsForCustomerId) {
    if (!!aBusinessDocument.linkedDeal) {
      allDealIdsSet.add(aBusinessDocument.linkedDeal.dealId)
    }
  }
  const allUniqueDealIds: Array<string> = Array.from<string>(allDealIdsSet.values())
  const allUncompletelyInvoicedDealsBusinessDocuments: BusinessDocument[] = []
  for (let aDealId of allUniqueDealIds) {
    const relevantBusinessDocumentsWithDealId: BusinessDocument[] = relevantBusinessDocumentsForCustomerId
      .filter(filterAllBusinessDocumentsWithDealId(aDealId))
    if (relevantBusinessDocumentsWithDealId.length > 0) {
      const originalBusinessDocumentTotalIncludingTaxScaledValue = relevantBusinessDocumentsWithDealId[0].totalIncludingTaxScaledValue
      const resultingDealCumulation: ComputedInstallmentValues = calculateCumulatedInvoicedInstallmentsIncludingTax(originalBusinessDocumentTotalIncludingTaxScaledValue, relevantBusinessDocumentsWithDealId)
      if (isDealOpenToInvoicing(resultingDealCumulation)) {
        allUncompletelyInvoicedDealsBusinessDocuments.push(...relevantBusinessDocumentsWithDealId)
      }
    }
  }

  return allUncompletelyInvoicedDealsBusinessDocuments
}*/

export function listOfBusinessDocumentsWithDealHavingARemainingAmountToInvoiceForGivenCustomerCompany(customerCompany: Company, businessDocuments: BusinessDocument[], businessDocumentKind: BusinessDocumentKind): BusinessDocument[] {
  return coreListOfBusinessDocumentsWithDealHavingARemainingAmountToInvoice(businessDocuments, businessDocumentKind, customerCompany);
}

export function listOfBusinessDocumentsWithDealHavingARemainingAmountToInvoice(businessDocuments: BusinessDocument[], businessDocumentKind: BusinessDocumentKind): BusinessDocument[] {
  return coreListOfBusinessDocumentsWithDealHavingARemainingAmountToInvoice(businessDocuments, businessDocumentKind);
}

export function generateDealsWithRemainingBalanceToInvoice(
  allBusinessDocuments: BusinessDocument[],
  currentWorkspace: Workspace,
  allCustomers: Customer[],
  allContacts: Contact[],
): DealWithRemainingBalanceToInvoice[] {

  const dealsId: Set<string> = new Set()
  const result: DealWithRemainingBalanceToInvoice[] = []

  for (const document of allBusinessDocuments) {
    if (!document.linkedDeal?.dealId || dealsId.has(document.linkedDeal.dealId)) continue

    // Check if any document associated with the deal has the BALANCE_INVOICE tag
    const hasBalanceInvoiceTag: boolean = allBusinessDocuments.some((d: BusinessDocument) => d.linkedDeal?.dealId === document.linkedDeal.dealId && d.taxonomyTags?.includes(TaxonomyTag.BALANCE_INVOICE));
    if (hasBalanceInvoiceTag) continue // Skip this deal if any associated document has the BALANCE_INVOICE tag

    dealsId.add(document.linkedDeal.dealId)

    const existingLatestBusinessDocument: BusinessDocument = getLatestFinalBusinessDocumentWithDealId(document.linkedDeal.dealId, allBusinessDocuments)
    if (!existingLatestBusinessDocument) continue // Deal with only 1 draft invoice = Do not display !

    const newBalanceBusinessDocument: BalanceOfDealBusinessDocumentBuildingData = newBalanceBusinessDocumentAndCollateralDataFromUserSelectedDeal(document.linkedDeal.dealId, allBusinessDocuments, currentWorkspace, allCustomers, allContacts)

    result.push(<DealWithRemainingBalanceToInvoice>{
      dealId: document.linkedDeal.dealId,
      existingLatestBusinessDocument,
      newBalanceBusinessDocument
    })

  }

  return result
}



/**
 * Get lists of partial business documents for a customer or for all existing business Documents
 * - Optional Filter for business documents that belong to the company,
 * - have BusinessDocumentStatus of FINAL,
 * - Filter to documents which have dealId
 * - Calculate resultingDealCumulation for each document
 * @param businessDocuments
 * @param businessDocumentKind
 * @param customerCompany
 */
function coreListOfBusinessDocumentsWithDealHavingARemainingAmountToInvoice(businessDocuments: BusinessDocument[], businessDocumentKind: BusinessDocumentKind, customerCompany?: Company): BusinessDocument[] {
  let filteredBusinessDocuments: BusinessDocument[] = businessDocuments.filter(filterAllBusinessDocumentsAccordingToKind(businessDocumentKind))


  // If a customerCompany is provided, filter the businessDocuments by the given customerCompany's companyId
  if (customerCompany) {
    filteredBusinessDocuments = filteredBusinessDocuments.filter(filterAllBusinessDocumentsWithCustomerId(customerCompany.companyId))
  }

  // Proceed with the filtering for finalized documents and processing as before
  const finalizedBusinessDocuments: BusinessDocument[] = filteredBusinessDocuments.filter(filterAllBusinessDocumentsFinalized)

  const allDealIdsSet: Set<string> = new Set<string>()
  finalizedBusinessDocuments.forEach((document: BusinessDocument) => {
    if (document.linkedDeal) {
      allDealIdsSet.add(document.linkedDeal.dealId)
    }
  });

  const allUniqueDealIds: string[] = Array.from(allDealIdsSet);
  const allUncompletelyInvoicedDealsBusinessDocuments: BusinessDocument[] = [];
  allUniqueDealIds.forEach((dealId: string) => {
    const relevantBusinessDocumentsWithDealId: BusinessDocument[] = finalizedBusinessDocuments.filter((document: BusinessDocument): boolean => document.linkedDeal?.dealId === dealId)
    if (relevantBusinessDocumentsWithDealId.length > 0) {
      const originalBusinessDocumentTotalIncludingTaxScaledValue: number = relevantBusinessDocumentsWithDealId[0].totalIncludingTaxScaledValue
      const resultingDealCumulation: ComputedInstallmentValues = calculateCumulatedInvoicedInstallmentsIncludingTax(originalBusinessDocumentTotalIncludingTaxScaledValue, relevantBusinessDocumentsWithDealId)
      if (isDealOpenToInvoicing(resultingDealCumulation)) {
        allUncompletelyInvoicedDealsBusinessDocuments.push(...relevantBusinessDocumentsWithDealId)
      }
    }
  });

  return allUncompletelyInvoicedDealsBusinessDocuments
}

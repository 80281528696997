<script lang="ts">
    import { link } from 'svelte-routing'
    import { t } from '../lib/i18n/i18nextWrapper.js'
    import { onMount } from 'svelte'
    import Loader from '../lib/ui-kit/Loader.svelte'
  import { remoteDebuggingService } from '../services/remote-debugging.service.js'

    let showEscape: boolean = false

    onMount(() => {
      setTimeout(() => {
        showEscape = true
      }, 4000)
      
      // Temporary bypass error for dashboard
      if (location.hash.startsWith('#access_token')) return
      if (location.pathname === '/today') return
      
      // Sentry log
      remoteDebuggingService.addInfo(`Customer error 404 from ${document?.referrer}`)
      throw new Error(`Customer error 404 from ${document?.referrer}`)
    })
</script>

<div class="relative z-[100]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-black transition-opacity"></div>
    <div class="fixed inset-0 z-90 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div class="flex flex-col justify-center text-center sm:items-center">

                {#if showEscape}
                    <h1 class="text-9xl font-extrabold text-white tracking-widest">404</h1>
                    <div class="bg-[#FF6A3D] px-2 text-sm rounded rotate-12 absolute">{t('errorPage.pageNotFound.title')}</div>
                    <button class="mt-5">
                        <a class="relative inline-block text-sm font-medium text-[#FF6A3D] group active:text-orange-500 focus:outline-none focus:ring">
                            <span class="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-[#FF6A3D] group-hover:translate-y-0 group-hover:translate-x-0"></span>
                            <span class="relative block px-8 py-3 bg-[#1A2238] border border-current">
                                <a href="/today" use:link>{t('errorPage.pageNotFound.backHome')}</a>
                            </span>
                        </a>
                    </button>
                {:else}
                    <div class="">
                        <Loader/>
                    </div>
                {/if}

            </div>
        </div>
    </div>
</div>

<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper.js'
    import { extractCountryNamePurely } from '../../../core-app/lib/i18n/i18n-country'
    import type { Workspace } from '../../models/workspace'
    import { authZJsUserId } from '../../../core-app/lib/auth0authentication/authStore'

    export let classes: string
    export let workspace: Workspace

</script>

<div class="flex {classes}">
    <div class="flex items-center mr-3">
        {#if workspace?.company?.emailLogoURL }
            <img class="inline-block h-8 w-8 rounded-md"
                 src={workspace?.company?.emailLogoURL}
                 alt="{workspace?.company?.usualName}"/>
        {:else}
            <div class="main-label-initials flex flex-wrap content-center justify-center h-12 w-12 bg-zinc-700 text-white rounded-md">
                <span>{workspace?.company?.usualName?.substring(0, 1).toUpperCase()}</span>
            </div>
        {/if}
    </div>

    <div class="main-label w-8/12 font-medium mt-1">
        <span class="inline-block truncate w-40">{!!(workspace?.company?.usualName) ? workspace?.company?.usualName : workspace?.company?.formalName}</span><span>{(workspace?.ownerId === $authZJsUserId ? t('userRoles.owner') : t('userRoles.member') )}</span>
        <div class="-mt-1 p-0">
            <span class="rounded-sm bg-whisper border border-athensGray text-paleSky px-1 text-xxs font-normal">{`${workspace.company.mailAddress.city}, ${extractCountryNamePurely(t('countriesList.' + (workspace.company.mailAddress.countryCode).toLowerCase()))}`}</span>
        </div>
    </div>
</div>

export function ReplaceWithLineBreaks(str: string) {
  return str.replace(new RegExp('\r?\n|\/', 'g'), '<br />')
}

/**
 * Sanitize HTML by removing all style and class attributes.
 * Used by the HTML Editor to format for PDF export.
 * @param html
 */
export function sanitizeHTML(html: string): string {
  const parser: DOMParser = new DOMParser()
  const doc: Document = parser.parseFromString(html, 'text/html')

  const elements: NodeListOf<Element> = doc.querySelectorAll('*')

  for (let i = 0; i < elements.length; i++) {
    const el: Element = elements[i]

    el.removeAttribute('style')
    el.removeAttribute('class')
  }

  return doc.body.innerHTML
}

/**
 * Capitalizes the first letter of each word in the input string
 * @param input
 */
export function capitalizeName(input: string): string {
  return input
    .split(/[\s-]/) // Split by spaces or hyphens
    .map((part: string) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()) // Capitalize the first letter and make the rest lowercase
    .join(' ') // Join the parts back together
}
<script lang="ts">
    import type { BusinessDocument } from '../../../models/business-document'
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
    import { formatCurrencyWithOptions } from '../../../utils/number-formatting-utils'
    import { BusinessDocumentPartialKindFormatter } from '../../../helpers/business-document-partial-data'

    /** Export let */
    export let thisBusinessDocument: BusinessDocument
    export let isSelected: boolean = false
    export let onSelect: (event: MouseEvent) => void = () => {}

    function onKeyDown(event: KeyboardEvent) {
      if (event.key === 'Enter' || event.key === ' ') {
        onSelect(new MouseEvent('click'))
      }
    }

    /** Local variables */
    let issuedDate: string = new Intl.DateTimeFormat(t('locales'), { year: 'numeric', month: 'long', day: 'numeric' })
      .format(new Date(thisBusinessDocument?.issuedDate.rfc3339))

    /** Get the right kind of Partial Invoice along with its percentage */
    let thisBusinessDocumentPartialKindFormatter: BusinessDocumentPartialKindFormatter = new BusinessDocumentPartialKindFormatter(t, thisBusinessDocument)

    /** Reactive */
    $: {
      thisBusinessDocumentPartialKindFormatter.setInvoiceData()
    }
</script>


<li class="flex items-center justify-between gap-x-6 px-4 py-5 shadow-md rounded-md cursor-pointer ring-2  { isSelected ? 'ring-2 ring-dundyOrange' : 'ring-transparent'} transition-colors"
    on:click={onSelect}
    on:keydown={onKeyDown}
    tabindex="-1">
    <div class="w-full flex flex-col">
        <div class="w-full flex justify-between gap-x-3">
            <div class="flex flex-col">
                <p class="text-sm font-semibold leading-6 text-gray-900">{thisBusinessDocument?.businessDocumentNumber}</p>
                <p class="rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset text-yellow-800 bg-yellow-50 ring-yellow-600/20">
                    {thisBusinessDocumentPartialKindFormatter.businessDocumentDescription || thisBusinessDocument?.installmentDescription || ''}
                </p>
            </div>
            <div class="flex justify-end flex-col text-right">
                <p class="text-xs leading-5 text-black whitespace-nowrap">{t('invoices.createInvoice.dueLabel')}
                    <time datetime="{issuedDate}">{issuedDate}</time>
                </p>
                <p class="flex items-baseline gap-x-1 truncate">
                    <span class="font-bold tracking-tight text-gray-900">{formatCurrencyWithOptions(thisBusinessDocument?.installmentResultIncludingTaxScaledValue, t('locales'), thisBusinessDocument?.currency, true, 'symbol')}</span>
                    <span class="leading-6 text-s text-zinc-800">/ {formatCurrencyWithOptions(thisBusinessDocument?.totalIncludingTaxScaledValue, t('locales'), thisBusinessDocument?.currency, true, 'symbol')}</span>
                </p>
            </div>

        </div>
        <div class="mt-2 flex flex-col gap-x-2 text-xs leading-5 text-gray-500 truncate">
            {#if isSelected}
                <table class="rounded-lg bg-whisper border border-whisper overflow-hidden px-2">
                    {#each thisBusinessDocument?.lineItems as item}
                        <tr class="border-b border-gray-200">
                            <td class="py-4 px-4 text-sm w-60 sm:pl-6 md:pl-0 break-words"
                                style="max-width: 250px;">
                                <div class="flex flex-col pl-2">
                                    <div class="font-medium text-black text-s leading-3 mb-0.5 truncate">
                                        {#if item.supplierReference}{item.supplierReference} -{/if} {item?.title}
                                    </div>
                                    <div class="text-black text-xxs leading-3 truncate">{@html item?.description}</div>
                                </div>
                            </td>

                            <td class="py-4 pl-3 pr-4 text-right text-sm text-paleSky sm:pr-6 md:pr-0">
                                <div class="flex flex-col pr-2">
                                    <p>{formatCurrencyWithOptions(item?.lineItemTotalExcludingTaxScaledValue, t('locales'), item?.itemPrice.currency, true, 'symbol')}</p>
                                    <p class="text-xxs">{item?.quantity} {t('unitTypesList.' + item?.itemPrice.unit, { count: item?.quantity })}
                                        x {formatCurrencyWithOptions(item?.itemPrice.scaledValue, t('locales'), item?.itemPrice.currency, true, 'symbol')}</p>
                                </div>
                            </td>
                        </tr>
                    {/each}
                </table>
            {/if}
        </div>
    </div>
</li>

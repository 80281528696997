import { BusinessDocument } from '../../models/business-document'
import type BusinessDocumentInstallmentRow from '../../models/business-documents-installments.model'
import { getInstallmentDescription } from './installment.service'
import { formatCurrencyWithOptions } from '../../utils/number-formatting-utils'
import {
  filterAllBusinessDocumentsFinalized,
  filterAllBusinessDocumentsWithCustomerId,
  filterAllBusinessDocumentsWithDealId
} from '../business-document-array-filters/business-documents-filters.service'
import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
import {
  getShowedNumber
} from '../business-document-description/business-document-subject-main-title-main-description.service'

/**
 * Generate the table rows for each installment
 * @param currentBusinessDocument
 * @param allBusinessDocument
 * @param locale
 * @param withCurrentDocumentMention
 * @param withDocumentNumberMention
 * @param withIssueDate
 * @param withTimeZoneMention
 * @param withIssueDateFirst
 */
export function generateInstallmentRecapTableRows(currentBusinessDocument: BusinessDocument, allBusinessDocument: BusinessDocument[], locale: string, withCurrentDocumentMention: boolean, withDocumentNumberMention: boolean, withIssueDate: boolean, withTimeZoneMention: boolean, withIssueDateFirst: boolean): BusinessDocumentInstallmentRow[] {
  if (!currentBusinessDocument || !currentBusinessDocument.linkedDeal || !currentBusinessDocument.customerCustomer.company) {
    /* console.error('nothing to return in generateInstallmentRecapTableRows') */
    
    return <BusinessDocumentInstallmentRow[]>[]
  }
  const businessDocumentsForCurrentCustomerAndDeal: BusinessDocument[] = allBusinessDocument
    .filter(filterAllBusinessDocumentsFinalized)
    .filter(filterAllBusinessDocumentsWithDealId(currentBusinessDocument.linkedDeal.dealId))
    .filter(filterAllBusinessDocumentsWithCustomerId(currentBusinessDocument.customerCustomer.company.companyId))
  const tableRows: BusinessDocumentInstallmentRow[] = []

  // Process related business documents
  for (const businessDocument of businessDocumentsForCurrentCustomerAndDeal) {
    if (businessDocument.businessDocumentId !== currentBusinessDocument.businessDocumentId) {
      const aLine: BusinessDocumentInstallmentRow = getSingleItemBusinessDocumentInstallmentRow(currentBusinessDocument, businessDocument, locale, withCurrentDocumentMention, withDocumentNumberMention, withIssueDate, withTimeZoneMention, withIssueDateFirst)
      tableRows.push(aLine)
    }
  }
  const currentLine: BusinessDocumentInstallmentRow = getSingleItemBusinessDocumentInstallmentRow(currentBusinessDocument, currentBusinessDocument, locale, withCurrentDocumentMention, withDocumentNumberMention, withIssueDate, withTimeZoneMention, withIssueDateFirst)
  tableRows.push(currentLine)

  return tableRows
}

function getSingleItemBusinessDocumentInstallmentRow(currentBusinessDocument: BusinessDocument, aBusinessDocument: BusinessDocument, locale: string, withCurrentDocumentMention: boolean, withDocumentNumberMention: boolean, withIssueDate: boolean, withTimeZoneMention: boolean, withIssueDateFirst: boolean): BusinessDocumentInstallmentRow {
  const installmentResultExcludingTaxScaledValueString: string = formatCurrencyWithOptions(aBusinessDocument.installmentResultExcludingTaxScaledValue, locale, aBusinessDocument.currency, true, 'symbol')
  const installmentResultTaxScaledValueString: string = formatCurrencyWithOptions(aBusinessDocument.installmentResultTaxScaledValue, locale, aBusinessDocument.currency, true, 'symbol')
  const installmentResultIncludingTaxScaledValueString: string = formatCurrencyWithOptions(aBusinessDocument.installmentResultIncludingTaxScaledValue, locale, aBusinessDocument.currency, true, 'symbol')

  const additionalThisDocumentMention: string = (withCurrentDocumentMention && (aBusinessDocument.businessDocumentId === currentBusinessDocument.businessDocumentId) ? t('invoices.createInvoice.recapThisDocument') : '')

  const documentNumberToDisplay: string = getShowedNumber(aBusinessDocument.businessDocumentId, aBusinessDocument.businessDocumentNumber, currentBusinessDocument.businessDocumentId, aBusinessDocument.businessDocumentKind, locale)
  
  const additionalDocumentNumberMention: string = ((withDocumentNumberMention && (documentNumberToDisplay !== '')) ? documentNumberToDisplay : '')

  let additionalGlobalDocumentRefMention: string = ''
  if ((additionalThisDocumentMention !== '') || (additionalDocumentNumberMention !== '')) {
    additionalGlobalDocumentRefMention = ' ' + t('invoices.createInvoice.recapThisDocumentPrefix')
    if (additionalThisDocumentMention !== '') {
      additionalGlobalDocumentRefMention += additionalThisDocumentMention
    }
    if ((additionalThisDocumentMention !== '') && (additionalDocumentNumberMention !== '')) {
      additionalGlobalDocumentRefMention += ' '
    }
    if (additionalDocumentNumberMention !== '') {
      additionalGlobalDocumentRefMention += additionalDocumentNumberMention
    }
    additionalGlobalDocumentRefMention += t('invoices.createInvoice.recapThisDocumentSuffix')
  }
  let timeZoneParts: string[] = new Intl.DateTimeFormat(t('locales'), {
    timeZoneName: 'shortGeneric',
    // timeZoneName:'longGeneric',
    timeZone: (currentBusinessDocument.timeZoneIANACode !== '' ? currentBusinessDocument.timeZoneIANACode : 'UTC')
  }).format(new Date(aBusinessDocument.issuedDate.unixSeconds * 1000)).split(' ')
  timeZoneParts.shift()
  const timeZoneClean: string = timeZoneParts.join(' ')
  const longAndVeryHumanDetailedFormat = <any>{ // DateTimeFormatOptions
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    // hourCycle: "h24",
    hourCycle: 'h12',
    dayPeriod: 'long',
    minute: 'numeric',
    second: 'numeric'
    // timeZoneName:'shortGeneric',
    // timeZoneName:'longGeneric',
    // dateStyle: 'full', timeStyle: 'long',
    // timeZone: (currentBusinessDocument.timeZoneIANACode !== "" ? currentBusinessDocument.timeZoneIANACode : "UTC"),
  }
  const rationalFormat = <any>{ // DateTimeFormatOptions
    year: 'numeric',
    month: 'short',
    day: 'numeric'
    // hour: "numeric",
    // hourCycle: "h24",
    // hourCycle: "h12",
    // dayPeriod: "long",
    // minute: "numeric",
    // second: "numeric",
    // timeZoneName:'shortGeneric',
    // timeZoneName:'longGeneric',
    // dateStyle: 'full', timeStyle: 'long',
    // timeZone: (currentBusinessDocument.timeZoneIANACode !== "" ? currentBusinessDocument.timeZoneIANACode : "UTC"),
  }
  const issueDateFormatted: string = new Intl.DateTimeFormat(t('locales'), rationalFormat).format(new Date(aBusinessDocument.issuedDate.unixSeconds * 1000))
        + (withTimeZoneMention ? ' (' + timeZoneClean + ')' : '')
  const prefixIssuedDateMention: string = (withIssueDateFirst ? (withIssueDate ? issueDateFormatted + ': ' : '') : '')
  const suffixIssuedDateMention: string = (withIssueDateFirst ? '' : (withIssueDate ? t('invoices.createInvoice.issuedOnDateMentionPrefix') + issueDateFormatted : ''))

  const installmentDescription: string = prefixIssuedDateMention + getInstallmentDescription(aBusinessDocument.businessDocumentId, aBusinessDocument.businessDocumentKind, aBusinessDocument.installmentChosenKind, aBusinessDocument.installmentChosenValue, aBusinessDocument.currency, aBusinessDocument.taxonomyTags)
        + additionalGlobalDocumentRefMention + suffixIssuedDateMention

  return <BusinessDocumentInstallmentRow>{
    businessDocumentId: aBusinessDocument.businessDocumentId,
    businessDocumentNumber: aBusinessDocument.businessDocumentNumber,
    installmentDescription: installmentDescription,
    installmentResultExcludingTaxScaledValueString: installmentResultExcludingTaxScaledValueString,
    installmentResultTaxScaledValueString: installmentResultTaxScaledValueString,
    installmentResultIncludingTaxScaledValueString: installmentResultIncludingTaxScaledValueString
  }
}

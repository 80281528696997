export interface EmailAttachment {
  type: string;
  fileName: string,
  size: number,
  contentBase64: string;
  tags: Array<EmailAttachmentTag>;
}

export enum EmailAttachmentTag {
  Manual = 'manual',
  Programmatic = 'programmatic',
  Terms = 'terms',
  Invoice = 'invoice',
  Receipt = 'receipt',
  Other = 'other'
}
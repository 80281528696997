<script>
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import pdfMake from 'pdfmake/build/pdfmake.js'
    import { pdfMakeFont } from '../../../core-app/lib/pdf-generator/index.js'
    import { pdfMakeInvoiceDefinition } from './pdfmake-dunning-letter-definitions'
    import { WorkspaceStore } from '../../../crm-app/stores/workspace.store'
    import { ProfileStore } from '../../../core-app/stores/profile.store'

    export let todo
    export let customer
    export let selectedContact
    export let invoice
    export let renderedSubjectMessage
    export let renderedBodyMessage


    let dateNow = new Intl.DateTimeFormat(t('locales'), {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    }).format(new Date())

    function exportToPDF() {
      pdfMake.vfs = pdfMakeFont
      pdfMake
        .createPdf(
          pdfMakeInvoiceDefinition({
            workspaceData: $WorkspaceStore,
            profileData: $ProfileStore,
            todo,
            customer,
            selectedContact,
            invoice,
            renderedSubjectMessage,
            renderedBodyMessage,
            dateNow
          }),
        )
        .download(
          `${t('todoAction.actionKind.StrongLastReminderAfterDueDate.downloadLetterPrefix')}-${$WorkspaceStore.company?.formalName}-${new Intl.DateTimeFormat(t('locales'), {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
          }).format(new Date())}`,
        )
    }
</script>

<button on:click={exportToPDF} class="btn action-secondary inline-flex justify-center items-center w-full mt-4">{t('todoAction.actionKind.StrongLastReminderAfterDueDate.downloadLetter')}</button>

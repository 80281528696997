<script lang="ts">
    import { t } from '../i18n/i18nextWrapper.js'
    import { createEventDispatcher } from 'svelte'
    import { BusinessDocumentViewListOption } from '../../../voxy-app/enums/business-documents-view-list-options'
    import { DunningViewListOption } from '../../../dundy-app/enums/dunning-view-list-options'

    export let businessDocumentListType: BusinessDocumentViewListOption | DunningViewListOption
    export let currentStoreChoice: BusinessDocumentViewListOption | DunningViewListOption
    export let tabTitleEntry: string
    export let totalBusinessDocumentAmountNormalized: string = ''
    export let numberOfBusinessDocumentsInView: number
    export let showTotalInTab: boolean = true
    export let showBadgeInTab: boolean = true

    const dispatch = createEventDispatcher()

    function selectTab(chosenViewListOption: BusinessDocumentViewListOption | DunningViewListOption) {
      dispatch('chosenViewListOption', chosenViewListOption)
    }
</script>


<div data-ut-component="business-document-list-tag"
     class:activeTab={currentStoreChoice === businessDocumentListType}
     class:inactiveTab={currentStoreChoice !== businessDocumentListType}
     class="flex flex-row items-center justify-between cursor-pointer w-3/12 py-2 relative space-x-4 hover:border-b-2 group-hover:text-zinc-800"
     on:click={() => selectTab(businessDocumentListType)}>
    <div class="flex flex-col truncate"
         class:activeTabContent={currentStoreChoice === businessDocumentListType}>
        <h1 class="font-semibold text-sm md:text-lg">{t(tabTitleEntry)}</h1>
        {#if showTotalInTab}
            <span class="text-xs md:text-sm">{totalBusinessDocumentAmountNormalized}</span>
        {/if}
    </div>
    {#if showBadgeInTab}
        <div class="defaultBadge"
             class:activeBadge={currentStoreChoice === businessDocumentListType}>
            {numberOfBusinessDocumentsInView}
        </div>
    {/if}
</div>


<style lang="postcss">
    .activeTab {
        @apply border-b-2 border-b-black bg-white;
    }

    .inactiveTab {
        @apply border-b-2 border-transparent text-slate-400 hover:text-black hover:border-b-gray-200
    }

    .activeTabContent {
        @apply text-black;
    }

    .defaultBadge {
        @apply hidden md:flex mt-2 rounded-full bg-whisper text-paleSky px-2 py-1 h-6 text-xs;
        min-width: 24px;
    }

    .activeBadge {
        @apply bg-dundyOrange text-white;
    }
</style>
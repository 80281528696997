const LOCALSTORAGE_ENTRY = 'preferences'
const SEARCH_PARAMS = '_SEARCH_PARAMS_'

/**
 * CHANGE BROWSER HISTORY (url params + localstorage value)
 * @param params (url params. ie. {s:search, p: pageNumber...})
 * @param contextName (ONLY FOR LOCALSTORAGE SAVE : name of current context)
 * @param data (ONLY FOR LOCALSTORAGE SAVE : raw data to be retrieve on historyLoad)
 * @returns 
 */
export const historyChange = (params:{ [key:string]: string }, contextName?:string, data?:any) => {
  // change URL
  const searchParams = new URLSearchParams()

  for (const [key, value] of Object.entries(params)) {
    searchParams.set(key, value)
  }

  window.history.replaceState(null, '', `?${searchParams.toString()}`)

  // save parameters to localstorage
  if (!contextName) return

  const prf = localStorage.getItem(LOCALSTORAGE_ENTRY)
  const prfJson = prf ? JSON.parse(prf) : {}
  prfJson[contextName] = { ...data, [SEARCH_PARAMS]: searchParams.toString() }
  localStorage.setItem(LOCALSTORAGE_ENTRY, JSON.stringify(prfJson))
}

/**
 * LOAD BROWSER HISTORY (url params + localstorage value)
 * @param {string} contextName 
 * @returns null | { params:any, data:any }
 */
export const historyLoad = (contextName:string): null | { params:any, data:any } => {

  const prf = localStorage.getItem(LOCALSTORAGE_ENTRY)

  let params = {}
  let data:{ [key: string]: any } = {}
  
  if (!prf) return null

  data = JSON.parse(prf)[contextName]
  if (!data) return null

  params = data[SEARCH_PARAMS].split('&').reduce((acc:any, cur:string) => {
    const [key, value] = cur.split('=')
    acc[key] = value
        
    return acc
  }, {})

  delete data[SEARCH_PARAMS]
  historyChange(params, contextName, data)
  
  return { params, data }
}

/**
 * REMOVE PREFERENCE ENTRY TO LOCALSTORAGE
 * @param {string} contextName 
 * @returns 
 */
export const historyClean = (contextName:string) => {
  const prf = localStorage.getItem(LOCALSTORAGE_ENTRY)
  if (!prf) return

  let data = JSON.parse(prf)
  delete data[contextName]

  localStorage.setItem(LOCALSTORAGE_ENTRY, JSON.stringify(data))
}
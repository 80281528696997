import { type Writable, writable } from 'svelte/store'
import type { OnboardingStep } from '../enums/onboarding-steps'

/**
 * NB: we need to create our own onboarding store
 * because we need to set the previous URL to the attribute of some components
 */
export const OnboardingStore: Writable<{ isOnboarding: boolean, currentOnboardingStep: OnboardingStep }> = writable({
  isOnboarding: false,
  currentOnboardingStep: null
} as {
  isOnboarding: boolean,
  currentOnboardingStep: OnboardingStep
})
<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import { WorkspaceStore } from '../../stores/workspace.store'
    import OwnerCard from '../../lib/member/OwnerCard.svelte'
    import MemberCard from '../../lib/member/MemberCard.svelte'
    import { onMount } from 'svelte'
    import { isUserWsOwner } from '../../../core-app/services/guard'
    import { navigate } from 'svelte-routing'
    import { ContactsStore } from '../../stores/contacts.store'
    import type { Contact } from '../../models/contact'
    import { getListOfContactsFromCompanyWorkspace } from '../../services/contacts.pure-functions'
    import { feedbackService } from '../../../core-app/services/feedback.service'
    import { Feedback } from '../../../core-app/models/feedback'
    import ConfirmationModal from '../../../core-app/lib/ui-kit/ConfirmationModal.svelte'

    /** Vars */
    let contactsFromWorkspace: Contact[]
    let displayCustomerDeleteConfirmation: boolean
    let localContact: Contact

    /** Reactive vars */
    $: contactsFromWorkspace = getListOfContactsFromCompanyWorkspace($WorkspaceStore.workspaceId, $ContactsStore)

    /**
     * Delete Contact from workspace
     * @param contactId
     */
    const deleteContactFromWorkspace = (contactId: string) => {
      ContactsStore.update(contacts => {
        contacts.splice(contacts.findIndex(storeContact => storeContact.contactId === contactId), 1)
        
        return contacts
      })
      displayCustomerDeleteConfirmation = false
      feedbackService.displayFeedback({
        feedbackMessage: t('editCustomer.deleteFeedback'),
        feedbackLevel: 'Success'
      } as Feedback)
    }

    onMount(() => {
      if (!isUserWsOwner()) {
        navigate('/today')
      }
    })

</script>

{#if isUserWsOwner()}
    <section class="flex-grow">

        <ConfirmationModal
                modalHeading={t('team.editMember.deleteContactModalTitle')}
                primaryButtonText={t('team.editMember.deleteConfirmButtonModal')}
                secondaryButtonText={t('team.editMember.deleteCancelButtonModal')}
                open={displayCustomerDeleteConfirmation}
                on:cancelAndClose={() => {displayCustomerDeleteConfirmation = false}}
                on:confirmAction={() => deleteContactFromWorkspace(localContact.contactId)}>{t('team.editMember.deleteContactModalCaption1')}</ConfirmationModal>

        <div class="mt-8 flex flex-col">
            <div class="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div>
                        <table class="min-w-full divide-y divide-zinc-300">
                            <thead>
                            <tr>
                                <th scope="col"
                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{t('team.listMembers.header.name')}</th>
                                <th scope="col"
                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{t('team.listMembers.header.title')}</th>
                                <th scope="col"
                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{t('team.listMembers.header.status')}</th>
                                <th scope="col"
                                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{t('team.listMembers.header.role')}</th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                            <OwnerCard/>
                            {#each contactsFromWorkspace as contact}
                                <MemberCard
                                        {contact}
                                        on:delete={() => {
                                          localContact = contact
                                          displayCustomerDeleteConfirmation = true
                                        }}
                                />
                            {/each}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
{:else}
    <p>not allowed</p>
{/if}
<script lang="ts">
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper'
    import InvoiceItem from './InvoiceItem.svelte'
    import { BusinessDocument, BusinessDocumentCollateralData } from '../../../models/business-document'
    import { formatCurrencyWithOptions } from '../../../utils/number-formatting-utils.js'
    import { getAmountOfInstallmentValueLabel } from '../../../services/installment/installment.service'

    /** Exported Var **/
    export let invoiceCollateralData: BusinessDocumentCollateralData
    export let businessDocument: BusinessDocument
</script>

<div class="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
    <table class="min-w-full divide-y divide-gray-300">
        <thead>
            <tr>
                <th scope="col" class="w-60 py-2.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 md:pl-0">{t('invoices.createInvoice.designationLabel')}</th>
                <th scope="col" class="hidden py-2.5 px-3 text-right text-xs font-semibold text-gray-900 sm:table-cell">{t('invoices.createInvoice.quantityLabel')}</th>
                <th scope="col" class="hidden py-2.5 px-3 text-right text-xs font-semibold text-gray-900 sm:table-cell">{t('invoices.createInvoice.unitPriceNoTaxLabel')}</th>
                <th scope="col" class="hidden py-2.5 px-3 text-right text-xs font-semibold text-gray-900 sm:table-cell">{t('invoices.createInvoice.taxRateLabel')}</th>
                <th scope="col" class="py-2.5 pl-3 pr-4 text-right text-xs font-semibold text-gray-900 sm:pr-6 md:pr-0">{t('invoices.createInvoice.totalPriceNoTaxLabel')}</th>
            </tr>
        </thead>
        <tbody>
            {#each businessDocument?.lineItems as item}
                <InvoiceItem item={item} />
            {/each}
        </tbody>
        <tfoot>
            <tr>
                <th scope="row" colspan="4" class="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0">{t('invoices.createInvoice.finalTotalPriceNoTaxLabel')}</th>
                <th scope="row" class="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden">{t('invoices.createInvoice.finalTotalPriceNoTaxLabel')}</th>
                <td class="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                    {formatCurrencyWithOptions(businessDocument?.subtotalExcludingTaxScaledValue, t('locales'), businessDocument?.currency, false, 'symbol')}
                </td>
            </tr>
            {#if invoiceCollateralData?.showDiscountModule}
                <tr>
                    <th scope="row" colspan="4" class="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0">{t('invoices.createInvoice.discountLabel')}</th>
                    <th scope="row" class="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden">{t('invoices.createInvoice.discountLabel')}</th>
                    <td class="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                        {formatCurrencyWithOptions(businessDocument?.totalDiscountExcludingTaxResultScaledValue, t('locales'), businessDocument?.currency, false, 'symbol')}
                    </td>
                </tr>
            {/if}
            <tr>
                <th scope="row" colspan="4" class="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0">{t('invoices.createInvoice.finalPriceTaxLabel')}</th>
                <th scope="row" class="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden">{t('invoices.createInvoice.finalPriceTaxLabel')}</th>
                <td class="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                    {formatCurrencyWithOptions(businessDocument?.totalTaxScaledValue, t('locales'), businessDocument?.currency, false, 'symbol')}
                </td>
            </tr>
            <tr>
                <th scope="row" colspan="4" class="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0">{t('invoices.createInvoice.finalTotalPriceWithTaxLabel')}</th>
                <th scope="row" class="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">{t('invoices.createInvoice.finalTotalPriceWithTaxLabel')}</th>
                <td class="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                    {formatCurrencyWithOptions(businessDocument?.totalIncludingTaxScaledValue, t('locales'), businessDocument?.currency, false, 'symbol')}
                </td>
            </tr>
            {#if businessDocument?.hasSpecificInstallment}
                <tr>
                    <th scope="row" colspan="4" class="hidden pl-6 pr-3 pt-4 text-right text-sm  {invoiceCollateralData?.isPaidWhenFinalized ? 'text-black font-bold' : 'font-bold text-black' }  sm:table-cell md:pl-0">{getAmountOfInstallmentValueLabel(businessDocument)}</th>
                    <th scope="row" class="pl-4 pr-3 pt-4 text-left text-sm {invoiceCollateralData?.isPaidWhenFinalized ? '' : 'font-bold' } text-gray-500 sm:hidden">{getAmountOfInstallmentValueLabel(businessDocument)}</th>
                    <td class="pl-3 pr-4 pt-4 text-right {invoiceCollateralData?.isPaidWhenFinalized ? 'text-black font-bold text-sm' : 'text-lg font-bold text-black' } sm:pr-6 md:pr-0">
                        {formatCurrencyWithOptions(businessDocument?.installmentResultIncludingTaxScaledValue, t('locales'), businessDocument?.currency, false, 'symbol')}
                    </td>
                </tr>
            {/if}
            {#if invoiceCollateralData?.isPaidWhenFinalized}
                <tr>
                    <th scope="row" colspan="4" class="hidden pl-6 pr-3 pt-4 text-right text-lg text-black font-bold sm:table-cell md:pl-0">{t('invoices.createInvoice.amountDueScaledValueLabel')}</th>
                    <th scope="row" class="pl-4 pr-3 pt-4 text-left text-black font-bold text-sm sm:hidden">{t('invoices.createInvoice.amountDueScaledValueLabel')}</th>
                    <td class="pl-3 pr-4 pt-4 text-right text-lg font-bold text-black sm:pr-6 md:pr-0">
                        {formatCurrencyWithOptions(businessDocument?.amountDueScaledValue, t('locales'), businessDocument?.currency, false, 'symbol')}
                    </td>
                </tr>
            {/if}
        </tfoot>
    </table>
</div>


import { APICallOptions, APIEntity, apiPost } from '$core/services/api.service'
import type { SearchedFoundAndAssembledDunningMessageTemplate } from '$src/dundy-app/models/dunning-message-template'

export type SortedDunningWorkflowPrefs = {
  sortedDunningWorkflowPrefs : {
    prefItem: any,
    prefKey: any
  }
}

export type PreferenceByKind = {
  successes: SearchedFoundAndAssembledDunningMessageTemplate[] | SortedDunningWorkflowPrefs,
  failures: any[]
}

/**
 * GET PREFERENCE BY KIND
 * @param workspaceId 
 * @param kind 
 * @param payload 
 * @returns {PersistedPreference}
 */
export const getPreferenceByKind = (workspaceId:string, kind: string, payload: unknown):Promise<PreferenceByKind> => apiPost(
  `/search/workspace/${workspaceId}/preferences/kind/${kind}?loadComposingItems`,
  payload,
    <APICallOptions>{
      entity: APIEntity.TOTUM_PREFERENCE,
      ignoreFeedback: true
    }
)


/**
 * SAVE PREFERENCES
 * @param workspaceId 
 * @param {any[]} preferences
 * @returns 
 */
export const savePreferences = (workspaceId:string, preferences:any[]):Promise<PreferenceResponse> => apiPost(
  `/workspace/${workspaceId}/preferences`,
  preferences,
    <APICallOptions>{
      entity: APIEntity.TOTUM_PREFERENCE,
      ignoreFeedback: true
    }
)

type PreferenceResponse = {
  successs: any[],
  failures: any[]
}

/**
 * SAVE PREFERENCE
 * @param workspaceId 
 * @param {any} preference 
 * @returns 
 */
export const savePreference = (workspaceId:string, preference:any):Promise<PreferenceResponse> => savePreferences(workspaceId, [preference])
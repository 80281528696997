<script lang="ts">
    import { t } from '../../core-app/lib/i18n/i18nextWrapper.js'
    import FullSizeModal from '../../core-app/lib/ui-kit/FullSizeModal.svelte'
    import { createEventDispatcher, onDestroy, onMount } from 'svelte'
    import { BBBTransaction, BBBTransactionsResponse } from '../../bank-app/models/bbb-transactions-model'
    import { get, type Unsubscriber } from 'svelte/store'
    import { TransactionsStore } from '../../bank-app/stores/transactions.store'
    import { getInvoiceFormatter } from '../../bank-app/helpers/invoice-formatter'
    import AgGrid from '../../core-app/lib/ui-kit/AgGrid/AgGridDatable.svelte'
    import Invoice from '../../dundy-app/models/invoice'
    import SearchBar from '../../core-app/lib/ui-kit/SearchBar.svelte'
    import type { ColDef, GridOptions, IRowNode } from 'ag-grid-community'
    import {
      cashApplicationReadyInvoicesColumnDefs
    } from '../../bank-app/lib/transactions/ag-grid/cash-application-invoices-column-definitions-ag-grid'
    import { DunningInvoicesStore } from '../../dundy-app/stores/dunning-invoices.store'
    import { DunningBusinessDocumentKind } from '../../dundy-app/enums/dunning-business-document-kind'
    import { InvoiceStatus } from '../../dundy-app/enums/invoice-status'
    import {
      getCashApplicationReadyInvoicesOptions
    } from '../../bank-app/lib/transactions/ag-grid/cash-application-options-definitions-ag-grid'
    import { cashApplicationService } from '../services/cash-application.service'
    import {
      CashApplicationDeclaredEvent,
      CashApplicationDeclaredResponse
    } from '../models/cash-application-declared-model'
    import { CashApplicationDeclaredEventsStore } from '../stores/cash-application.store'
    import { formatCurrencyWithOptions } from '../../voxy-app/utils/number-formatting-utils'
    import CashAppliedInvoicesRowWithControls
      from '../../bank-app/components/cash-application/CashAppliedInvoicesRowWithControls.svelte'
    import CashApplicationTransactionInfoPanel
      from '../components/cash-application/CashApplicationTransactionInfoPanel.svelte'
    import {
      compileCashApplicationDeclaredEventsForBackendFromCashApplicationDeclaredEventsUI,
      getInitialCashApplicationDeclaredEventsUIForTransactionId,
      updateCADEsInUI,
      userSelectedInvoiceNumbersAccordingToUserSelectionInUIList
    } from '../services/cash-application-ui-to-events.service'
    import type { InvoiceNumber } from '../models/cash-application-model'
    import { Amount } from '../models/amount-of-money-model'
    import { eventsManager } from '../../core-app/events/event-manager'
    import { EventType } from '../../core-app/events/event-type'
    import { DundyEvent } from '../../dundy-app/events/dundy-event'
    import { feedbackService } from '../../core-app/services/feedback.service'
    import { Feedback } from '../../core-app/models/feedback'
    import { ExchangeDate } from '../../core-app/models/exchange-date'
    import { deepClone } from '../../core-app/util/object-deep-cloning'
    import { CADEStatusForTransaction } from '../models/cades-status-for-transaction'
    import {
      allStringsInSetAExistInSetB, fuseCashApplicationDeclaredEvents,
      getInvoiceNumbersSetFromCADEs, getInvoiceNumbersSetFromInvoices,
      reduceAmountIfNeededOfCADEChangedByUserInCADEsInUI,
      updateCashApplicationStatusForTransaction
    } from '../services/cash-application-pure-functions'
    import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
    import { authZJsUserId } from '../../core-app/lib/auth0authentication/authStore'
    import {
      CashApplicationContext,
      newCashApplicationContext
    } from '../services/cash-application-helper-pure-functions'
    import { Workspace } from '../../crm-app/models/workspace'
    import { NewCashApplicationUserSelectionForTransaction } from '../models/cash-application-ui-model.js'
    import mixpanel from 'mixpanel-browser'

    /** get TransactionId from Params */
    export let transactionId: string
    /* console.log('init transactionId', transactionId) */
    /*$: {
       console.log('init CHANGED transactionId', transactionId)
    }*/

    /** Set Local declarations */
    let cashApplicationDeclaredEventsUIForTransactionIsUpdating: boolean = false
    let cadesInUIIsUpdating: boolean = false
    let selectedTransaction: BBBTransaction
    let initialCashApplicationDeclaredEventsUIForTransaction: CashApplicationDeclaredEvent[] = undefined
    let cashApplicationDeclaredEventsUIForTransaction: CashApplicationDeclaredEvent[] = []
    let amountLeftToAllocateOnTransaction: number
    let transactionAmountWithSign: string
    let transactionAmountWithoutSign: string
    let transactionDate: string
    /*
        let invoices: Invoice[];
    */
    let searchValue: string = ''
    let userSelectedInvoiceNumbersSet: Set<InvoiceNumber>
    let amountCashApplied: string
    let percentageCashApplied: number
    const dispatch = createEventDispatcher()
    let isTransactionFullyCashApplied: boolean
    let isButtonDisabled: boolean
    let savingCashApplicationToServer: boolean = false
    let lastSetOfInvoicesNumbersFromInvoicesList: Set<string> = new Set<string>([''])
    let isInitLastSetOfInvoicesNumbersFromInvoicesList: boolean = false //

    const filterInvoicesForCashApplication = (invoice?: Invoice) => (invoice.documentKind === DunningBusinessDocumentKind.INVOICE)
            && (invoice.status === InvoiceStatus.OUTSTANDING) && (invoice.isTracked)
            && (!invoice.deleted)

    const filterCADEsForTransaction = (currentTransactionId: number) => (cade: CashApplicationDeclaredEvent) => (cade.cashApplication.bankTransaction.transactionId === currentTransactionId.toString())
    const filterCADEsWithNonNullAppliedAmount = (cade: CashApplicationDeclaredEvent) => (cade.cashApplication.appliedAmount.scaledValue !== 0)

    let invoicesCandidateForTransactionCashApplication: Invoice[]
    let readyToUseAgGrid: boolean = false
    /* console.log('init readyToUseAgGrid 1 =', readyToUseAgGrid) */

    /** Set AG GRID component */
    /*let columnDefs: ColDef[] = <ColDef[]>cashApplicationReadyInvoicesColumnDefs();
    let gridOptions: GridOptions= <GridOptions>getCashApplicationReadyInvoicesOptions(userSelectedInvoiceNumbersAccordingToCashApplicationDeclaredEvents(cashApplicationDeclaredEventsUIForTransaction));*/

    let columnDefs: ColDef[]
    let gridOptions: GridOptions


    // $: {
    //     if (!!cashApplicationDeclaredEventsUIForTransaction){
    //         // gridOptions = <GridOptions>getCashApplicationReadyInvoicesOptions(userSelectedInvoiceNumbersAccordingToCashApplicationDeclaredEvents(cashApplicationDeclaredEventsUIForTransaction));
    //     }
    // }

    /** Set list of store Cash Application Declared Events at the time of pop-up opening or latest fusion after store change
     *  It is representing the CADEs in Store that will change over time (optimistic changes and updates from back-end)
     *  Used in , used in getNewCADEsInStoreNewItemsVSLatestCADESInStoreForUserSelectedInvoices()
     */
    let latestCashApplicationDeclaredEventsInStore: CashApplicationDeclaredEvent[]

    /** Set Unsubscriber for Cash Application Declared Events Store */
    let unSubscribeToCADEsInStoreChange: Unsubscriber
    let unSubscribeToInvoicesStoreChange: Unsubscriber
    let unSubscribeToTransactionsStoreChange: Unsubscriber
    let reactToFullCashApplicationEvent: Function
    let isInitInvoicesStore: boolean = false
    let isInitTransactionsStore: boolean = false
    let isInitCADEsInStore: boolean = false
    let isInitInitialLatestCADEsInStoreList: boolean = false
    let initInitialCADEsInUIList: boolean = false
    let isInitAgGridSettings: boolean = false
    let initSubscribeToTransactionsStoreChange: boolean = false
    let initSelectedInvoiceNumbersSet: boolean = false

    function GetCashApplicationContext(workspaceStore: Workspace, userId: string, transactionsStore: BBBTransactionsResponse) {
      const workspaceId = workspaceStore?.workspaceId
      const bankName = workspaceStore?.bankConfig?.selectedAccountBankName
      const selectedAccountId = workspaceStore?.bankConfig?.selectedAccountId
      const bankId = /*get(TransactionsStore)*/transactionsStore?.accounts?.find(account => account.id === selectedAccountId)?.bank_id
      const bankAccountName = workspaceStore?.bankConfig?.selectedAccountName
      const bankAccountId = workspaceStore?.bankConfig?.selectedAccountId

      return newCashApplicationContext(
        workspaceId,
        userId,
        bankName,
        bankId,
        bankAccountName,
        bankAccountId,
      )
    }

    let currentCashApplicationContext: CashApplicationContext
    $:currentCashApplicationContext = GetCashApplicationContext($WorkspaceStore, $authZJsUserId, $TransactionsStore)

    function refreshCashApplicationUI(allCADEsInStore: CashApplicationDeclaredEvent[], allInvoices: Invoice[], allTransactionsInStore: BBBTransactionsResponse, currentCashApplicationContext: CashApplicationContext, newCashApplicationUserSelectionForTransaction: NewCashApplicationUserSelectionForTransaction) {
      /* console.log('refreshCashApplicationUI()') */
      if (!cadesInUIIsUpdating) {
        cadesInUIIsUpdating = true
        if (!allCADEsInStore || !allInvoices) {
          return
        }
        if (!isInitInitialLatestCADEsInStoreList) {
          latestCashApplicationDeclaredEventsInStore = deepClone(allCADEsInStore)
          isInitInitialLatestCADEsInStoreList = true
        }
        if (isInitTransactionsStore && !!allTransactionsInStore && !!allTransactionsInStore.transactions) {
          selectedTransaction = allTransactionsInStore.transactions.find((transaction: BBBTransaction) => transaction.id === Number(transactionId))
          initSubscribeToTransactionsStoreChange = true
        }
        if (!initSelectedInvoiceNumbersSet) {
          userSelectedInvoiceNumbersSet = getInvoiceNumbersSetFromCADEs(
            fuseCashApplicationDeclaredEvents(allCADEsInStore)
              .filter(filterCADEsForTransaction(transactionId)).filter(filterCADEsWithNonNullAppliedAmount),
          )
          initSelectedInvoiceNumbersSet = true
        }
        if (!initInitialCADEsInUIList && !!selectedTransaction && isInitCADEsInStore) {
          cashApplicationDeclaredEventsUIForTransaction = getInitialCashApplicationDeclaredEventsUIForTransactionId(selectedTransaction, allCADEsInStore)
          /* console.log('init cashApplicationDeclaredEventsUIForTransaction', cashApplicationDeclaredEventsUIForTransaction) */
          initInitialCADEsInUIList = true
        }
        if (isInitCADEsInStore && isInitInvoicesStore && isInitTransactionsStore && initInitialCADEsInUIList && isInitInitialLatestCADEsInStoreList
                    && initSelectedInvoiceNumbersSet && initSubscribeToTransactionsStoreChange && !!cashApplicationDeclaredEventsUIForTransaction) {
          logMainData('in refreshCashApplicationUI')
          if (!!selectedTransaction && !!userSelectedInvoiceNumbersSet) {
            const updatedCADEsInUIResponse = updateCADEsInUI(selectedTransaction, userSelectedInvoiceNumbersSet, cashApplicationDeclaredEventsUIForTransaction, latestCashApplicationDeclaredEventsInStore, allCADEsInStore, allInvoices, currentCashApplicationContext, newCashApplicationUserSelectionForTransaction)
            cashApplicationDeclaredEventsUIForTransaction = updatedCADEsInUIResponse.newCADEsInUI
            latestCashApplicationDeclaredEventsInStore = updatedCADEsInUIResponse.newLatestCADEsInStore
            if (!isInitAgGridSettings) {
              columnDefs = <ColDef[]>cashApplicationReadyInvoicesColumnDefs()
              gridOptions = <GridOptions>getCashApplicationReadyInvoicesOptions(getInvoiceNumbersSetFromCADEs(cashApplicationDeclaredEventsUIForTransaction))
              /* console.log('init readyToUseAgGrid 2 =', readyToUseAgGrid) */
              if (!isInitAgGridSettings) {
                eventsManager.emit(EventType.CASH_APPLICATION_LIST_AND_SELECTION_REFRESH_REQUESTED, invoicesCandidateForTransactionCashApplication, 'cash-application.service')
              } else {
                eventsManager.emit(EventType.CASH_APPLICATION_LIST_AND_SELECTION_REFRESH_REQUESTED, invoicesCandidateForTransactionCashApplication, 'cash-application.service')
                // eventsManager.emit(EventType.CASH_APPLICATION_LIST_REFRESH_REQUESTED, invoicesCandidateForTransactionCashApplication, 'cash-application.service');
              }
              isInitAgGridSettings = true
              readyToUseAgGrid = true
            }
          }
        } else {
          // if (!isInitCADEsInStore) {

          // }
          // if (!isInitInvoicesStore) {

          // }
          // if (!isInitTransactionsStore) {

          // }
          // if (!initInitialCADEsInUIList) {

          //     if (!selectedTransaction) {

          //     }
          //     if (!isInitCADEsInStore) {

          //     }
          // }
          // if (!isInitInitialLatestCADEsInStoreList) {

          // }
          // if (!initSelectedInvoiceNumbersSet) {

          // }
          // if (!initSubscribeToTransactionsStoreChange) {

          // }
          // if (!cashApplicationDeclaredEventsUIForTransaction) {

          // }
          readyToUseAgGrid = false
          /* console.log('init readyToUseAgGrid 3 =', readyToUseAgGrid) */
        }
        cadesInUIIsUpdating = false
      }
    }

    /**
     * Local onGridReady function
     * Listens for the TRANSACTION_FULLY_CASH_APPLIED event and updates the grid row display accordingly
     */
    const onGridReady = (): void => {
      if (!!reactToFullCashApplicationEvent) {
        reactToFullCashApplicationEvent()
        reactToFullCashApplicationEvent = null
      }
      reactToFullCashApplicationEvent = eventsManager.on(EventType.TRANSACTION_FULLY_CASH_APPLIED, (e: DundyEvent<any>) => {
        const rows: NodeListOf<HTMLElement> = document.querySelectorAll('.ag-center-cols-container > .ag-row:not(.ag-row-selected)')
        rows.forEach((row: HTMLElement) => {
          if (e.data && !row.classList.contains('ag-row-selected')) {
            row.classList.add('row-not-cash-applied')
          } else {
            row.classList.remove('row-not-cash-applied')
          }
        })
      }, 'CashApplicationService')
    }

    /** Reactive declarations */
    $: isButtonDisabled = savingCashApplicationToServer || !userSelectedInvoiceNumbersSet

    /*$:  console.log('gridOptions', gridOptions)
    $:  console.log('isTransactionFullyCashApplied', isTransactionFullyCashApplied)
    $:  console.log('amountLeftToAllocateOnTransaction', amountLeftToAllocateOnTransaction) */
    $: {
      /* console.log('%cuserSelectedInvoiceNumbersSet source reactive', 'color: #AA00AA; font-size: 1.2em', userSelectedInvoiceNumbersSet) */
      if (!!$DunningInvoicesStore && !!$CashApplicationDeclaredEventsStore && !!selectedTransaction) {
        /* console.log('init invoices : final') */
        invoicesCandidateForTransactionCashApplication = returnInvoicesThatAreNotFullyCashAppliedExceptOneForSelectedTransaction(
          $DunningInvoicesStore.filter(filterInvoicesForCashApplication),
          $CashApplicationDeclaredEventsStore,
          selectedTransaction,
        )
      } else {
        /* console.log('%cinvoicesCandidateForTransactionCashApplication source plain init', 'color: green; font-size: 1.2em') */
        invoicesCandidateForTransactionCashApplication = $DunningInvoicesStore?.filter(filterInvoicesForCashApplication)
      }
      eventsManager.emit(EventType.CASH_APPLICATION_LIST_AND_SELECTION_REFRESH_REQUESTED, invoicesCandidateForTransactionCashApplication, 'cash-application.service')
      // eventsManager.emit(EventType.CASH_APPLICATION_LIST_REFRESH_REQUESTED, invoicesCandidateForTransactionCashApplication, 'cash-application.service');

      if (!isInitLastSetOfInvoicesNumbersFromInvoicesList) {
        isInitLastSetOfInvoicesNumbersFromInvoicesList = true
        lastSetOfInvoicesNumbersFromInvoicesList = getInvoiceNumbersSetFromInvoices(invoicesCandidateForTransactionCashApplication)
      }
    }
    // $: selectedTransaction = $TransactionsStore?.transactions?.find((transaction:BBBTransaction) => transaction.id === Number(transactionId));
    $:transactionAmountWithSign = getInvoiceFormatter(t('locales'), selectedTransaction?.currency_code).format(selectedTransaction?.amount).replace(/^(\D+)/, '$1 ')
    $:transactionAmountWithoutSign = formatCurrencyWithOptions(selectedTransaction?.amount, t('locales'), selectedTransaction?.currency_code, true)
    $: {
      if (!!selectedTransaction) {
        transactionDate = new Intl.DateTimeFormat(t('locales'), {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        })?.format(new Date(selectedTransaction?.date))
      }
    }
    let alreadyUpdatingCADEsInUI: boolean = false
    // $:userSelectedInvoiceNumbersSet = userSelectedInvoiceNumbersAccordingToCashApplicationDeclaredEvents(cashApplicationDeclaredEventsUIForTransaction);
    $: {


      if (!isInitCADEsInStore) {
        /* console.log('init readyToUseAgGrid not ready because not isInitCADEsInStore') */
      }
      if (!isInitInvoicesStore) {
        /* console.log('init readyToUseAgGrid not ready because not isInitInvoicesStore') */
      }
      if (!isInitTransactionsStore) {
        /* console.log('init readyToUseAgGrid not ready because not isInitTransactionsStore') */
      }
      if (!initInitialCADEsInUIList) {
        /* console.log('init readyToUseAgGrid not ready because not initInitialCADEsInUIList') */
        if (!selectedTransaction) {
          /* console.log('init readyToUseAgGrid not ready because not selectedTransaction') */
        }
        if (!isInitCADEsInStore) {
          /* console.log('init readyToUseAgGrid not ready because not isInitCADEsInStore') */
        }
      }
      if (!isInitInitialLatestCADEsInStoreList) {
        /* console.log('init readyToUseAgGrid not ready because not isInitInitialLatestCADEsInStoreList') */
      }
      if (!initSelectedInvoiceNumbersSet) {
        /* console.log('init readyToUseAgGrid not ready because not initSelectedInvoiceNumbersSet') */
      }
      if (!initSubscribeToTransactionsStoreChange) {
        /* console.log('init readyToUseAgGrid not ready because not initSubscribeToTransactionsStoreChange') */
      }
      if (!cashApplicationDeclaredEventsUIForTransaction) {
        /* console.log('init readyToUseAgGrid not ready because not cashApplicationDeclaredEventsUIForTransaction') */
      }

      logMainData('in reactive expression')
      refreshCashApplicationUI($CashApplicationDeclaredEventsStore, $DunningInvoicesStore, $TransactionsStore, currentCashApplicationContext, null)
    }


    /*
        $:  console.log("filterInvoicesWithClientMatchingSelectedInUICADE", filterInvoicesWithClientMatchingSelectedInUICADE(invoices, cashApplicationDeclaredEventsUIForTransaction));
    */


    /** Local Functions */
    const close = () => {
      dispatch('close')
    }

    /**
     * Returns invoices that are not fully cash applied except ones for selected transaction
     * uses the filter method to iterate through the invoices array and return a new array containing only the invoices that meet certain conditions.
     * - For each invoice, the function checks if it is fully cash applied.
     * - If it is fully cash applied, the function checks if it is related to the selected transaction.
     * @param invoices
     * @param cashApplicationDeclaredEvents
     * @param selectedTransaction
     */
    function returnInvoicesThatAreNotFullyCashAppliedExceptOneForSelectedTransaction(invoices: Invoice[], cashApplicationDeclaredEvents: CashApplicationDeclaredEvent[], selectedTransaction: BBBTransaction): Invoice[] {
      /* console.log('%creturnInvoicesThatAreNotFullyCashAppliedExceptOneForSelectedTransaction', 'color: green; font-size: 1.2em') */

      return invoices.filter((invoice: Invoice) => {
        // Check if the invoice is fully cash applied
        const isFullyCashApplied: boolean = cashApplicationDeclaredEvents.some((cashApplicationDeclaredEvent: CashApplicationDeclaredEvent) => Number(Amount.PrototypeToClass(cashApplicationDeclaredEvent.cashApplication.appliedAmount).GetAmountScaledValue()) === invoice.amountIncludingTaxes)

        // Return false if the invoice is fully cash applied, except for the selected transaction
        const isRelatedToSelectedTransaction: boolean = cashApplicationDeclaredEvents.some((cashApplicationDeclaredEvent: CashApplicationDeclaredEvent) => cashApplicationDeclaredEvent.invoiceNumber === invoice.invoiceNumber
                        && cashApplicationDeclaredEvent.cashApplication.bankTransaction.transactionId === selectedTransaction.id.toString())

        return !isFullyCashApplied || isRelatedToSelectedTransaction
      })
    }

    /*/!**
     * Returns invoices that match the selected Client
     * @param invoices
     * @param cashApplicationDeclaredEvents
     *!/
    function filterInvoicesWithClientMatchingSelectedInUICADE(invoices: Invoice[], cashApplicationDeclaredEvents: CashApplicationDeclaredEvent[]): Invoice[] {
        const customerIds = new Set(cashApplicationDeclaredEvents.map(event => event.customerId));
        return invoices.filter((invoice: Invoice) => customerIds.has(invoice.toFinalCustomer.companyId) &&
                cashApplicationDeclaredEvents.some((cashApplicationDeclaredEvent: CashApplicationDeclaredEvent) =>
                        cashApplicationDeclaredEvent.customerId === invoice.toFinalCustomer.companyId
                )
        );
    }*/


    /**
     * The subcomponent is asking the master component to update the cash application but
     * @param e
     */
    function updateAppliedAmountFromEvent(e: CustomEvent<CashApplicationDeclaredEvent>): void {
      const newOrModifiedCADE: CashApplicationDeclaredEvent = e.detail
      let res: CADEStatusForTransaction = updateAppliedAmount(newOrModifiedCADE, selectedTransaction)
      amountCashApplied = res.amountCashApplied
      percentageCashApplied = res.percentageCashApplied
      amountLeftToAllocateOnTransaction = res.amountLeftToAllocateOnTransaction
      isTransactionFullyCashApplied = res.isTransactionFullyCashApplied
    }

    /**
     * Update Applied Amount Master Component
     * This master component is responsible for the final compute;
     * It will:
     * - cap the applied amount according to how much of the invoice is left to be applied
     * - cap the applied amount according to how much of the transaction is left to be applied
     * - return the final applied amount back to the subcomponent
     * @param newOrModifiedCADERequestedByUser
     * @param selectedTransaction
     */
    function updateAppliedAmount(newOrModifiedCADERequestedByUser: CashApplicationDeclaredEvent, selectedTransaction: BBBTransaction): CADEStatusForTransaction {
      cashApplicationDeclaredEventsUIForTransaction = reduceAmountIfNeededOfCADEChangedByUserInCADEsInUI(newOrModifiedCADERequestedByUser, cashApplicationDeclaredEventsUIForTransaction, get(CashApplicationDeclaredEventsStore))
      /*let i = 0;
        for (let anItem of cashApplicationDeclaredEventsUIForTransaction) {
            i++;
            console.log(i, "---- anItem", Amount.PrototypeToClass(anItem.cashApplication.appliedAmount).GetAmountScaledValue());
            console.log(i, "  -- anItem", anItem.cashApplication.appliedAmount)
        }*/
      return updateCashApplicationStatusForTransaction(selectedTransaction, cashApplicationDeclaredEventsUIForTransaction, t('locales'))
    }


    function removeAppliedInvoice(row: CustomEvent<CashApplicationDeclaredEvent>) {
      /* console.log('removeAppliedInvoice', row) */
    }

    /**
     * This function is called when the user selects a row in the table
     * @param newCashApplicationUserSelectionForTransaction
     * @param selectedTransaction
     */
    function updateCADEsInUIFromChangesInCalculusAndInSelectedRows(newCashApplicationUserSelectionForTransaction: NewCashApplicationUserSelectionForTransaction, selectedTransaction: BBBTransaction): void {
      // console.trace('%caggrid updateCADEsInUIFromChangesInCalculusAndInSelectedRows() ...triggered by dispatch...', 'color: green; font-size: 1.2em')
      const setOfCurrentlySelectedInvoiceNumbers = getInvoiceNumbersSetFromInvoices(newCashApplicationUserSelectionForTransaction.NewInvoicesSelection)
      const allAInB: boolean = allStringsInSetAExistInSetB(setOfCurrentlySelectedInvoiceNumbers, lastSetOfInvoicesNumbersFromInvoicesList, false)
      const allBInA: boolean = allStringsInSetAExistInSetB(lastSetOfInvoicesNumbersFromInvoicesList, setOfCurrentlySelectedInvoiceNumbers, false)
      /* console.log('updateCADEsInUIFromChangesInCalculusAndInSelectedRows()', 'setOfCurrentlySelectedInvoiceNumbers', setOfCurrentlySelectedInvoiceNumbers, 'lastSetOfInvoicesNumbersFromInvoicesList', lastSetOfInvoicesNumbersFromInvoicesList) */
      /* console.log('updateCADEsInUIFromChangesInCalculusAndInSelectedRows()', 'allAInB', allAInB, 'allBInA', allBInA, 'allAInB && allBInA', allAInB && allBInA) */
      if (allAInB && allBInA) {
        return
      }
      /* console.log('updateCADEsInUIFromChangesInCalculusAndInSelectedRows proceed with update') */
      lastSetOfInvoicesNumbersFromInvoicesList = setOfCurrentlySelectedInvoiceNumbers
      userSelectedInvoiceNumbersSet = userSelectedInvoiceNumbersAccordingToUserSelectionInUIList(newCashApplicationUserSelectionForTransaction.NewInvoicesSelection)
      /* console.log('??? newCashApplicationUSerSelectionForTransaction ???', newCashApplicationUserSelectionForTransaction) */
      /* console.log('??? userSelectedInvoiceNumbersSet ???', userSelectedInvoiceNumbersSet) */

      refreshCashApplicationUI(get(CashApplicationDeclaredEventsStore), get(DunningInvoicesStore), get(TransactionsStore), currentCashApplicationContext, newCashApplicationUserSelectionForTransaction)

      if (!userSelectedInvoiceNumbersSet.size) {
        updateAppliedAmount(null, selectedTransaction)
      }

      /* console.log('cashApplicationDeclaredEventsUIForTransaction Updated after row selection', cashApplicationDeclaredEventsUIForTransaction) */
    }


    /**
     * Generate CashApplicationDeclaredEvents according to cashApplicationDeclaredEventsUIForTransaction And Posts Them to Backend
     * @param selectedTransaction
     * @param cashApplicationDeclaredEventsUIForTransaction
     */
    function saveUserCashApplication(selectedTransaction: BBBTransaction, cashApplicationDeclaredEventsUIForTransaction: CashApplicationDeclaredEvent[]): void {
      savingCashApplicationToServer = true

      const cashApplicationDeclaredEvents: CashApplicationDeclaredEvent[] = compileCashApplicationDeclaredEventsForBackendFromCashApplicationDeclaredEventsUI(selectedTransaction, cashApplicationDeclaredEventsUIForTransaction, get(CashApplicationDeclaredEventsStore))

      if (!!cashApplicationDeclaredEvents && cashApplicationDeclaredEvents.length > 0) {
        const now = new Date()
        const nowExchangeDate = ExchangeDate.newDate(now)
        const updatedCADEsListToSend: CashApplicationDeclaredEvent[] = []
        for (let aCashApplicationDeclaredEventToSend of cashApplicationDeclaredEvents) {
          const updatedCADEToSend: CashApplicationDeclaredEvent = deepClone(aCashApplicationDeclaredEventToSend)
          updatedCADEToSend.created = nowExchangeDate.unixSeconds
          updatedCADEToSend.createdRFC3339 = nowExchangeDate.rfc3339
          updatedCADEToSend.cashApplication.created = nowExchangeDate
          updatedCADEsListToSend.push(updatedCADEToSend)
        }
        cashApplicationService.postCashApplicationDeclaredEvent(updatedCADEsListToSend)
          .then((response: CashApplicationDeclaredResponse) => {
            /* console.log('response', response) */
            /* console.log('newly optimistic local update get(CashApplicationDeclaredEventsStore)', get(CashApplicationDeclaredEventsStore).length, get(CashApplicationDeclaredEventsStore)) */
            /* console.log('newly optimistic local update fused', [...get(CashApplicationDeclaredEventsStore), ...updatedCADEsListToSend].length, [...get(CashApplicationDeclaredEventsStore), ...updatedCADEsListToSend]) */
            /* console.log('newly optimistic local update updatedCADEsListToSend sent to b-e', updatedCADEsListToSend.length, updatedCADEsListToSend) */
            CashApplicationDeclaredEventsStore.set([...get(CashApplicationDeclaredEventsStore), ...updatedCADEsListToSend])
            feedbackService.displayFeedback(<Feedback>{
              feedbackMessage: '<div class="flex items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> ' +
                                    '<path d="M18.2499 14C18.2499 18 15.5 19.25 11.9999 19.25C8 19.25 5.75 16.4 5.75 14C5.75 11.6 7 9.41667 8 8.75C8 11.55 10.6666 13.3333 11.9999 13.25C9.59994 9.65 11.6666 5.66667 12.9999 4.75C12.9999 9.25 18.2499 10 18.2499 14Z" stroke="#FFE6A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> ' +
                                    '</svg><p class="ml-2">' + t('cashApplication.feedbacks.successfullySavedCashApplication') + '</p> </div>',
              feedbackLevel: 'Success'
            })
            dispatch('close')
            savingCashApplicationToServer = false
          })
          .catch((reason) => {
            feedbackService.displayFeedback(<Feedback>{
              feedbackLevel: 'Error',
              feedbackMessage: reason.message || reason.detail || reason
            })
            /* console.error('error postCashApplicationDeclaredEvent', reason) */
            savingCashApplicationToServer = false
          })
      } else {
        /* console.log('No cash application event to save') */
        savingCashApplicationToServer = false
      }

    }

    function logMainData(source: string) {


    }

    const selectionFunction = (selectedInvoices: Set<InvoiceNumber>) => (node: IRowNode<Invoice>) => {
      /* console.log(' selekt selecting programmatically node.data: ', node.data) */
      /* console.log(' selekt selecting programmatically selectedInvoices: ', selectedInvoices) */
      const isSelected = !!node.data && selectedInvoices.has(node.data.invoiceNumber)
      node.setSelected(isSelected)
      if (isSelected) {
        /* console.log(' selekt selecting programmatically ', node.data.invoiceNumber) */
      }
    }

    const dummyFunction = (a) => {
    }

    let cashApplicationStatusForTransaction: CADEStatusForTransaction

    $: {
      if (!!selectedTransaction && !!cashApplicationDeclaredEventsUIForTransaction) {
        cashApplicationStatusForTransaction = updateCashApplicationStatusForTransaction(selectedTransaction, cashApplicationDeclaredEventsUIForTransaction, t('locales'));
        ({
          isTransactionFullyCashApplied,
          amountLeftToAllocateOnTransaction,
          percentageCashApplied,
          amountCashApplied
        } = cashApplicationStatusForTransaction)

      }
    }

    onMount(() => {
      mixpanel.track('KA30 Open Cash Application Modal', {
        'Description': 'Open CashApplicationModal.svelte'
      })
      /* console.log('init onMount() CashApplicationModal.svelte') */
      if (!!unSubscribeToTransactionsStoreChange) {
        unSubscribeToTransactionsStoreChange()
        unSubscribeToTransactionsStoreChange = undefined
      }
      unSubscribeToTransactionsStoreChange = TransactionsStore.subscribe((newTransactionsStore: BBBTransactionsResponse) => {
        logMainData('init in sub TransactionsStore.subscribe')
        if (!isInitTransactionsStore) {
          logMainData('init in sub TransactionsStore.subscribe change')
          isInitTransactionsStore = true
          if (isInitTransactionsStore) {
            selectedTransaction = newTransactionsStore?.transactions?.find((transaction: BBBTransaction) => transaction.id === Number(transactionId))
            initSubscribeToTransactionsStoreChange = true
          }
          refreshCashApplicationUI(get(CashApplicationDeclaredEventsStore), get(DunningInvoicesStore), newTransactionsStore, currentCashApplicationContext, null)
        }
      })

      if (!!unSubscribeToInvoicesStoreChange) {
        unSubscribeToInvoicesStoreChange()
        unSubscribeToInvoicesStoreChange = undefined
      }
      unSubscribeToInvoicesStoreChange = DunningInvoicesStore.subscribe((newInvoicesStore: Invoice[]) => {
        logMainData('init in sub DunningInvoicesStore.subscribe')
        if (!isInitInvoicesStore) {
          isInitInvoicesStore = true
          refreshCashApplicationUI(get(CashApplicationDeclaredEventsStore), newInvoicesStore, get(TransactionsStore), currentCashApplicationContext, null)
        }
      })

      if (!!unSubscribeToCADEsInStoreChange) {
        unSubscribeToCADEsInStoreChange()
        unSubscribeToCADEsInStoreChange = undefined
      }
      unSubscribeToCADEsInStoreChange = CashApplicationDeclaredEventsStore.subscribe((newCashApplicationDeclaredEventsStore: CashApplicationDeclaredEvent[]) => {
        logMainData('init in sub CashApplicationDeclaredEventsStore.subscribe')
        if (!isInitCADEsInStore) {
          isInitCADEsInStore = true
          refreshCashApplicationUI(newCashApplicationDeclaredEventsStore, get(DunningInvoicesStore), get(TransactionsStore), currentCashApplicationContext, null)
        }
      })

    })

    onDestroy(() => {
      /* console.log('init onDestroy() CashApplicationModal.svelte') */
      if (!!unSubscribeToInvoicesStoreChange) {
        unSubscribeToInvoicesStoreChange()
        unSubscribeToInvoicesStoreChange = undefined
      }
      if (!!unSubscribeToCADEsInStoreChange) {
        unSubscribeToCADEsInStoreChange()
        unSubscribeToCADEsInStoreChange = undefined
      }
      if (!!unSubscribeToTransactionsStoreChange) {
        unSubscribeToTransactionsStoreChange()
        unSubscribeToTransactionsStoreChange = undefined
      }
      if (!!reactToFullCashApplicationEvent) {
        reactToFullCashApplicationEvent()
        reactToFullCashApplicationEvent = null
      }
    })

</script>

<FullSizeModal
        class="w-full overflow-y-auto h-full"
        isCloseable={true}
        on:close={close}>

    <div slot="content" class="grid w-full h-full overflow-y-auto pb-24">
        <main class="pb-8">
            <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-6xl lg:px-8">
                <div class="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8 mt-8">
                    <!-- Left column -->
                    <div class="grid grid-cols-1 gap-4 lg:col-span-2">
                        <section aria-labelledby="section-1-title">
                            <div class="my-8">
                                <div class="w-full my-2">
                                    <h2 class="text-2xl font-bold"
                                        id="section-1-title">{t('cashApplication.modal.title')}</h2>
                                    <p class="mt-2 mb-8 text-zinc-600 leading-relaxed">{t('cashApplication.modal.subtitle')}</p>
                                </div>
                                <div class="w-full my-2">
                                    <SearchBar
                                            id="transactions-search-bar"
                                            hideLabel={true}
                                            bind:value={searchValue}/>
                                </div>
                                <div class="ag-grid-wrapper"
                                     id="ag-grid-wrapper">
                                    {#if readyToUseAgGrid}
                                        <AgGrid agGridTableData={invoicesCandidateForTransactionCashApplication}
                                                thisAgGridComponentLabel="cades aggrid"
                                                columnDefs={columnDefs}
                                                bind:searchValue={searchValue}
                                                {onGridReady}
                                                options={gridOptions}
                                                on:selectedRows={(selectedRowsEvent) => {
                                                  // NB: selectedRowsEvent is a CustomEvent<NewCashApplicationUserSelectionForTransaction>
                                                  updateCADEsInUIFromChangesInCalculusAndInSelectedRows(selectedRowsEvent.detail, selectedTransaction)
                                                }}
                                                theme="cash-application"
                                                firstRenderingItemsSelection={dummyFunction}
                                                userSelectedInvoiceNumbersSet={userSelectedInvoiceNumbersSet}
                                                applyUserSelectionOnItemsAsInvoices={true}
                                        />
                                        <!--                                                firstRenderingItemsSelection={selectionFunction(userSelectedInvoiceNumbersSet)}-->
                                    {/if}

                                </div>
                            </div>

                        </section>
                    </div>

                    <!-- Right column -->
                    <div class="grid grid-cols-1 gap-4">
                        <section aria-labelledby="section-2-title">
                            <CashApplicationTransactionInfoPanel
                                    {selectedTransaction}
                                    {isTransactionFullyCashApplied}
                                    {transactionAmountWithSign}
                                    {transactionDate}
                                    {amountCashApplied}
                                    {amountLeftToAllocateOnTransaction}
                                    {transactionAmountWithoutSign}
                                    {percentageCashApplied}
                            />


                            {#if Array.isArray(cashApplicationDeclaredEventsUIForTransaction)}
                                <div class="space-y-6">
                                    {#each cashApplicationDeclaredEventsUIForTransaction as cashApplicationDeclaredEvent}
                                        <CashAppliedInvoicesRowWithControls
                                                amountLeftToAllocateOnTransaction
                                                cashApplicationDeclaredEvent={cashApplicationDeclaredEvent}
                                                on:removeAppliedInvoice={(e) => {removeAppliedInvoice(e)}}
                                                on:updateAppliedAmount={(e) => {updateAppliedAmountFromEvent(e)}}
                                        />
                                    {/each}
                                </div>
                            {:else}
                                <!-- handle the case where cashApplicationDeclaredEvents is not an array -->
                                <p>No cash application declared events found.</p>

                            {/if}
                        </section>
                    </div>
                </div>
            </div>
        </main>
    </div>

    <svelte:fragment slot="footer">
        <div class="modal-footer">
            <span class="cursor-pointer btn action-cancel mr-5" on:click|preventDefault|stopPropagation={close}>{t('cashApplication.modal.controls.cancel')}</span>
            <button class="ml-6 inline-flex items-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 disabled:bg-transparent disabled:border-athensGray disabled:text-loblolly disabled:shadow-none disabled:cursor-not-allowed"
                    disabled={isButtonDisabled}
                    on:click|preventDefault={() => {saveUserCashApplication(selectedTransaction, cashApplicationDeclaredEventsUIForTransaction)}}>
                {#if !savingCashApplicationToServer}
                    {t('cashApplication.modal.controls.save')}
                {:else}
                    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-dundyOrange"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10"
                                stroke="currentColor"
                                stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    {t('cashApplication.modal.controls.savingInProgress')}
                {/if}
            </button>
        </div>
    </svelte:fragment>
</FullSizeModal>

<style global>
    .modal-footer {
        @apply border-t border-athensGray absolute bottom-0 right-0 bg-white w-full flex items-center justify-center space-x-80 px-16 py-8 mx-auto z-10;
    }
    .cash-app-company-invoice {
        @apply flex flex-col w-full;
    }

    .ag-cell--amountIncludingTaxesAndIssueDate {
        @apply justify-end pr-12;
    }

    .ag-theme-cash-application {
        --ag-alpine-active-color: #2196f3;
        --ag-selected-row-background-color: #EEF9EF;
        --ag-row-hover-color: #F6F5F9;
        --ag-column-hover-color: #F6F5F9;
        --ag-input-focus-border-color: rgba(33, 150, 243, 0.4);
        --ag-range-selection-background-color: rgba(33, 150, 243, 0.2);
        --ag-range-selection-background-color-2: rgba(33, 150, 243, 0.36);
        --ag-range-selection-background-color-3: rgba(33, 150, 243, 0.49);
        --ag-range-selection-background-color-4: rgba(33, 150, 243, 0.59);
        --ag-background-color: #fff;
        --ag-foreground-color: #181d1f;
        --ag-border-color: #babfc7;
        --ag-secondary-border-color: #dde2eb;
        --ag-header-background-color: #f8f8f8;
        --ag-tooltip-background-color: #f8f8f8;
        --ag-odd-row-background-color: #ffffff;
        --ag-control-panel-background-color: #f8f8f8;
        --ag-subheader-background-color: #fff;
        --ag-invalid-color: #e02525;
        --ag-checkbox-unchecked-color: #999;
        --ag-checkbox-background-color: var(--ag-background-color);
        --ag-checkbox-checked-color: var(--ag-alpine-active-color);
        --ag-range-selection-border-color: var(--ag-alpine-active-color);
        --ag-secondary-foreground-color: var(--ag-foreground-color);
        --ag-input-border-color: var(--ag-border-color);
        --ag-input-border-color-invalid: var(--ag-invalid-color);
        --ag-input-focus-box-shadow: 0 0 2px 0.1rem var(--ag-input-focus-border-color);
        --ag-disabled-foreground-color: rgba(24, 29, 31, 0.5);
        --ag-chip-background-color: rgba(24, 29, 31, 0.07);
        --ag-input-disabled-border-color: rgba(186, 191, 199, 0.3);
        --ag-input-disabled-background-color: rgba(186, 191, 199, 0.15);
        --ag-borders: solid 1px;
        --ag-border-radius: 3px;
        --ag-borders-side-button: none;
        --ag-side-button-selected-background-color: transparent;
        --ag-header-column-resize-handle-display: block;
        --ag-header-column-resize-handle-width: 2px;
        --ag-header-column-resize-handle-height: 30%;
        --ag-grid-size: 6px;
        --ag-icon-size: 16px;
        --ag-row-height: calc(var(--ag-grid-size) * 7);
        --ag-header-height: calc(var(--ag-grid-size) * 8);
        --ag-list-item-height: calc(var(--ag-grid-size) * 4);
        --ag-column-select-indent-size: var(--ag-icon-size);
        --ag-set-filter-indent-size: var(--ag-icon-size);
        --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 3);
        --ag-cell-widget-spacing: calc(var(--ag-grid-size) * 2);
        --ag-widget-container-vertical-padding: calc(var(--ag-grid-size) * 2);
        --ag-widget-container-horizontal-padding: calc(var(--ag-grid-size) * 2);
        --ag-widget-vertical-spacing: calc(var(--ag-grid-size) * 1.5);
        --ag-toggle-button-height: 18px;
        --ag-toggle-button-width: 28px;
        --ag-font-size: 13px;
        --ag-icon-font-family: agGridAlpine;
        --ag-selected-tab-underline-color: var(--ag-alpine-active-color);
        --ag-selected-tab-underline-width: 2px;
        --ag-selected-tab-underline-transition-speed: 0.3s;
        --ag-tab-min-width: 240px;
        --ag-card-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
        --ag-popup-shadow: var(--ag-card-shadow);
        --ag-side-bar-panel-width: 250px;
    }

    .ag-theme-cash-application .ag-root-wrapper {
        @apply border border-athensGray rounded-lg;
    }

    .ag-theme-cash-application .ag-header {
        display: none;
    }

    .ag-theme-cash-application .ag-row-last {
        background-color: transparent;
        border: none
    }

    .ag-theme-cash-application .row-not-cash-applied {
        @apply opacity-20 bg-whisper pointer-events-none;
    }

    .ag-theme-cash-application .ag-row-selected.row-not-cash-applied {
        @apply opacity-100 pointer-events-auto;
        background: inherit;
    }

    .ag-theme-cash-application .ag-cell {
        @apply flex items-center;
    }

    .ag-theme-cash-application .ag-checkbox-input-wrapper {
        box-shadow: none !important;
        font-family: "icomoon" !important;
    }

    .ag-theme-cash-application .ag-checkbox-input-wrapper::after {
        content: "" !important;
        width: 16px !important;
        height: 16px !important;
        border-radius: 3px !important;
        border-width: 1px !important;
        border-color: #D9E0EA !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ag-theme-cash-application .ag-checkbox-input-wrapper.ag-checked::after {
        background: #00ADA2 !important;
        color: #FFF !important;
        content: "\e90f" !important;
        width: 16px !important;
        height: 16px !important;
        border-radius: 3px !important;
        border-color: #00ADA2 !important;
    }

</style>

import { APICallOptions, apiDelete, APIEntity, apiPost, apiPut } from '../../core-app/services/api.service'
import { CustomersStore } from '../stores/customers.store'
import { eventsManager } from '../../core-app/events/event-manager'
import { EventType } from '../../core-app/events/event-type'
import { get } from 'svelte/store'
import { remoteDebuggingService } from '$core/services/remote-debugging.service'
import { v4 as uuidv4 } from 'uuid'
import { WorkspaceStore } from '../stores/workspace.store'
import type { Customer } from '../models/customer'
import type { DundyEvent } from '../../dundy-app/events/dundy-event'
import type { Workspace } from '../models/workspace'
import type { WorkspaceCompanyUpdatedResponse } from '../models/workspace-company-updated-response'

class CustomersServiceClass {

  initialized: boolean = false
  initialize(): void {
    if (!this.initialized) {

      this.initialized = true

      eventsManager.on<Customer>(EventType.CUSTOMER_CREATED, (e: DundyEvent<Customer>) => {
        if (e.data) {
          this.saveNew(e.data)
            .then(() => {
              // handle successful save
            })
            .catch(error => {
              // handle error during save
              console.error(error)
            })
        }
      }, 'customersService')

      eventsManager.on<Customer>(EventType.CUSTOMER_CHANGED, (e: DundyEvent<Customer>) => {
        if (e.data) {
          this.save(e.data)
            .then(() => {
              // handle successful save
            })
            .catch(error => {
              // handle error during save
              console.error(error)
            })
        }
      }, 'customersService')

      eventsManager.on<Customer>(EventType.CUSTOMER_DELETED, (e: DundyEvent<Customer>) => {
        if (e.data) {
          this.delete(e.data)
            .then(() => {
              // handle successful save
            })
            .catch(error => {
              // handle error during save
              console.error(error)
            })
        }
      }, 'customersService')

      eventsManager.on<Workspace>(EventType.WORKSPACE_FETCHED, (e: DundyEvent<Workspace>) => {
        if (e.data.customers) {
          CustomersStore.set(e.data.customers)
        }
      }, 'customersService')
    }
  }
  private saveNew(customer: Customer): Promise<{ customeruuid: string }> {
    return apiPost<{ customeruuid: string }>(`/workspaces/${get(WorkspaceStore).workspaceId}/customers`, customer, {
      entity: APIEntity.CUSTOMERS
    } as APICallOptions)
  }
  private save(customer: Customer): Promise<Customer> {
    if (!customer.company.companyId) {
      customer.company.companyId = uuidv4()
      remoteDebuggingService.addInfo('customers.services.ts -> save : companyId is empty')
    }

    return apiPut<Customer>(`/workspaces/${get(WorkspaceStore).workspaceId}/customers`, customer, {
      entity: APIEntity.CUSTOMERS
    } as APICallOptions)
  }
  private delete(customer: Customer): Promise<WorkspaceCompanyUpdatedResponse> {
    return apiDelete<WorkspaceCompanyUpdatedResponse>(`/workspaces/${get(WorkspaceStore).workspaceId}/customers/${customer.company.companyId}`, customer, {
      entity: APIEntity.CUSTOMERS
    } as APICallOptions)
  }
}

export const customersService: CustomersServiceClass = new CustomersServiceClass()

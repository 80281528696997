<script lang="ts">
  import { BusinessDocument } from '../../models/business-document'
  import { eventsManager } from '$core/events/event-manager'
  import { EventType } from '$core/events/event-type'
  import { t } from '$core/lib/i18n/i18nextWrapper'
  import BusinessDocumentPDFUploadArea from './BusinessDocumentPDFUploadArea.svelte'
  import Loader from '$core/lib/ui-kit/Loader.svelte'
  import type StorageBucketObject from '$src/file-mgt-domain/models/storage-bucket-object'

  export let attachment: StorageBucketObject
  export let isDownloadingAttachment: boolean
  export let businessDocument: BusinessDocument

  let windowHeight: number

  $:doesCurrentBusinessDocumentHavePDFAttachment = businessDocument?.businessDocumentNumber !== ''
</script>

<svelte:window bind:innerHeight={windowHeight}/>

<div data-ut-component="pdf-viewer">
  {#if doesCurrentBusinessDocumentHavePDFAttachment}
    <div class="relative">
      <div class="w-full h-auto">
        {#if isDownloadingAttachment}
          <div class="grid h-screen place-items-center w-full z-[100]">
            <Loader/>
          </div>
        {/if}
        {#if attachment && attachment.contentBase64}
          <object width="100%" height={windowHeight} type="application/pdf"
            title="invoice preview" aria-label="invoice preview" aria-labelledby="invoice preview"
            data="data:application/pdf;base64,{attachment.contentBase64}#toolbar=0">
            <p>{t('invoices.viewInvoice.noAttachment')}</p>
          </object>
        {/if}
      </div>
    </div>
  {:else}
    <div class="grid h-screen place-items-center w-5/12 justify-center items-center mx-auto z-[100]">
      {#if !!businessDocument}
        <BusinessDocumentPDFUploadArea
          businessDocumentId={businessDocument.businessDocumentId}
          businessDocumentNumber={businessDocument.businessDocumentNumber}
          bind:currentInvoiceAttachment={attachment}
          on:uploaded={() => {
            eventsManager.emit(EventType.INVOICE_UPLOADED, null, 'DundyInvoicePDFPDFViewer.svelte')
          }}/>
      {/if}
    </div>
  {/if}
</div>

import { get } from 'svelte/store'
import { TodosStore } from '$dundy/stores/todos.store'
import type { TodoListItem } from '$dundy/models/todo-list-item'
import { TodoItemActionKind } from '$dundy/models/todo-list-item'
import { InvoicesHistoryStore } from '$dundy/stores/invoices-history.store'
import type { InvoiceEvent, InvoicesHistoryItem, TaskDoneEventPersisted } from '$dundy/models/invoices-history'
import { t } from '$core/lib/i18n/i18nextWrapper'
import { InvoiceStatus } from '$dundy/enums/invoice-status'
import { formatTimeAgo, formatToShortDate } from '$core/util/date-utils'

export default class DatatableFollowUpRenderer {
  private readonly eGui: HTMLElement
  constructor() {
    this.eGui = document.createElement('div')
    this.eGui.classList.add('status-ui-element')
  }
  public init(params: any) {
    // Retrieve the invoiceNumber from params
    const invoiceNumber = params.data.invoiceNumber

    if (!get(TodosStore) || !get(InvoicesHistoryStore)) return
    
    // Retrieve the follow-up data from the TodosStore
    const followUpData: TodoListItem | undefined = get(TodosStore).find((todo: TodoListItem) =>
      todo.scopeInvoiceNumbers.find((n: string): boolean => n === invoiceNumber && !todo.isDone),
    )

    // Retrieve the History data
    const historyData: InvoicesHistoryItem | undefined = get(InvoicesHistoryStore).find((h: InvoicesHistoryItem): boolean =>
      h.invoiceNumber === invoiceNumber)

    // Get latest event from history
    const latestDoneEvent: TaskDoneEventPersisted | undefined = historyData?.doneToDoItems[historyData.doneToDoItems.length - 1]

    // // Get issued event from invoice events in history
    // const issuedEvent: InvoiceEvent | undefined = historyData?.invoiceEvents.find((e: InvoiceEvent): boolean =>
    //   e.eventKind === TodoItemActionKind.INVOICE_ISSUED)

    if (params.data.status === InvoiceStatus.OUTSTANDING && params.data.isTracked && params.data.completed && !params.data.deleted) {
      if (followUpData && followUpData.actionKind !== TodoItemActionKind.NO_ACTION_AT_THIS_STAGE) {
        const actionDate: Date = new Date(followUpData.actionPlannedRFC3339)
        const currentDate: Date = new Date()
        const timeDifference: number = actionDate.getTime() - currentDate.getTime()
        const daysDifference: number = Math.ceil(timeDifference / (1000 * 3600 * 24))
        let actionText

        if (daysDifference === 0) {
          actionText = 'Act now'
        } else {
          actionText = `Action in ${daysDifference} days`
        }
        
        let badgeClass: string = ''
        let svgFillClass: string = ''
        let svgPath: string = '' // Initialize the SVG path

        switch (followUpData.actionKind) {
          case TodoItemActionKind.INVOICE_ISSUED:
            badgeClass = 'bg-gray-100'
            svgPath = ''
            break
          case TodoItemActionKind.INVOICE_ISSUANCE:
            badgeClass = 'bg-indigo-100'
            svgPath = `
                        <svg class="h-6 w-6 py-0.5" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 19.25L12 4.75L19.25 19.25L12 15.75L4.75 19.25Z"></path>
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 15.5V12.75"></path>
                        </svg>
                        `
            break
          /*case TodoItemActionKind.NO_ACTION_AT_THIS_STAGE:
                        badgeClass = 'bg-green-100';
                        svgPath = `
                        <svg class="h-6 w-6 py-0.5" fill="none" viewBox="0 0 24 24">
                            <path d="M12 14.75C6 14.75 4.75 19.25 4.75 19.25H19.25C19.25 19.25 18 14.75 12 14.75Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12 16.25V10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12 4.75C9.23858 4.75 6.75 7.23858 6.75 10C6.75 10 7.75 8.75 9.375 8.75C11 8.75 12 10 12 10C12 10 13 8.75 14.625 8.75C16.25 8.75 17.25 10 17.25 10C17.25 7.23858 14.7614 4.75 12 4.75Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        `;
                        break;*/
          case TodoItemActionKind.COURTESY_REMINDER_SHORTLY_BEFORE_DUE_DATE:
            badgeClass = 'bg-blue-100'
            svgPath = `
                        <svg class="h-6 w-6 py-0.5" fill="none" viewBox="0 0 24 24">
                            <path d="M17.25 12V10C17.25 7.1005 14.8995 4.75 12 4.75C9.10051 4.75 6.75 7.10051 6.75 10V12L4.75 16.25H19.25L17.25 12Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M9 16.5C9 16.5 9 19.25 12 19.25C15 19.25 15 16.5 15 16.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M17.75 4.75C17.75 4.75 18.3981 4.89794 18.7501 5.24996C19.1021 5.60197 19.25 6.25 19.25 6.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M6.25 4.75C6.25 4.75 5.60193 4.89794 5.24992 5.24996C4.8979 5.60197 4.75 6.25 4.75 6.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        `
            break
          case TodoItemActionKind.REMINDER_JUST_AFTER_DUE_DATE:
            badgeClass = 'bg-yellow-100'
            svgPath = `
                        <svg class="h-6 w-6 py-0.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 18L5.75 19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M17 18L18.25 19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12 8.75V12L14.25 14.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M18.75 4.75L19.25 5.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M5.25 4.75L4.75 5.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        `
            break
          case TodoItemActionKind.REMINDER_SHORTLY_AFTER_DUE_DATE:
            badgeClass = 'bg-purple-100'
            svgPath = `
                        <svg class="h-6 w-6 py-0.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.25 4.75L4.75 5.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12 8.75V12L14.25 14.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M19.1295 13.3166C18.4024 17.2541 14.621 19.8566 10.6835 19.1295C6.746 18.4024 4.14348 14.621 4.8706 10.6835C5.59772 6.746 9.37914 4.14348 13.3166 4.8706" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M7 18L5.75 19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M17 18L18.25 19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M18 4.75V7.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M18.5 10C18.5 10.2761 18.2761 10.5 18 10.5C17.7239 10.5 17.5 10.2761 17.5 10C17.5 9.72386 17.7239 9.5 18 9.5C18.2761 9.5 18.5 9.72386 18.5 10Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        `
            break
          case TodoItemActionKind.DIRECT_REMINDER_SOMETIME_AFTER_DUE_DATE:
            badgeClass = 'bg-pink-100'
            svgPath = `
                        <svg class="h-6 w-6 py-0.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 14.25C11.5858 14.25 11.25 14.5858 11.25 15C11.25 15.4142 11.5858 15.75 12 15.75V14.25ZM12.01 15.75C12.4242 15.75 12.76 15.4142 12.76 15C12.76 14.5858 12.4242 14.25 12.01 14.25V15.75ZM12 15.75H12.01V14.25H12V15.75Z" fill="currentColor"></path>
                            <path d="M10.4033 5.41136L10.9337 5.94169L10.4033 5.41136ZM5.41136 10.4033L4.88103 9.87301L4.88103 9.87301L5.41136 10.4033ZM5.41136 13.5967L5.94169 13.0663L5.94169 13.0663L5.41136 13.5967ZM10.4033 18.5886L10.9337 18.0583L10.4033 18.5886ZM13.5967 18.5886L14.127 19.119L14.127 19.119L13.5967 18.5886ZM18.5886 10.4033L19.119 9.87301L19.119 9.87301L18.5886 10.4033ZM13.5967 5.41136L14.127 4.88103L14.127 4.88103L13.5967 5.41136ZM9.87301 4.88103L4.88103 9.87301L5.94169 10.9337L10.9337 5.94169L9.87301 4.88103ZM4.88103 14.127L9.87301 19.119L10.9337 18.0583L5.94169 13.0663L4.88103 14.127ZM14.127 19.119L19.119 14.127L18.0583 13.0663L13.0663 18.0583L14.127 19.119ZM19.119 9.87301L14.127 4.88103L13.0663 5.94169L18.0583 10.9337L19.119 9.87301ZM19.119 14.127C20.2937 12.9523 20.2937 11.0477 19.119 9.87301L18.0583 10.9337C18.6472 11.5226 18.6472 12.4774 18.0583 13.0663L19.119 14.127ZM9.87301 19.119C11.0477 20.2937 12.9523 20.2937 14.127 19.119L13.0663 18.0583C12.4774 18.6472 11.5226 18.6472 10.9337 18.0583L9.87301 19.119ZM4.88103 9.87301C3.70632 11.0477 3.70632 12.9523 4.88103 14.127L5.94169 13.0663C5.35277 12.4774 5.35277 11.5226 5.94169 10.9337L4.88103 9.87301ZM10.9337 5.94169C11.5226 5.35277 12.4774 5.35277 13.0663 5.94169L14.127 4.88103C12.9523 3.70632 11.0477 3.70632 9.87301 4.88103L10.9337 5.94169Z" fill="currentColor"></path>
                            <path d="M12 8.75V12.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        `
            break
          case TodoItemActionKind.STRONG_LAST_REMINDER_AFTER_DUE_DATE:
            badgeClass = 'bg-yellow-50'
            svgPath = `
                            <svg class="h-6 w-6 py-0.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.5 8V15.25H12V8H10.5ZM5.5 15.25V8H4V15.25H5.5ZM10.25 15.5H5.75V17H10.25V15.5ZM4 15.25C4 16.2165 4.7835 17 5.75 17V15.5C5.61193 15.5 5.5 15.3881 5.5 15.25H4ZM10.5 15.25C10.5 15.3881 10.3881 15.5 10.25 15.5V17C11.2165 17 12 16.2165 12 15.25H10.5ZM8 5.5C9.38071 5.5 10.5 6.61929 10.5 8H12C12 5.79086 10.2091 4 8 4V5.5ZM8 4C5.79086 4 4 5.79086 4 8H5.5C5.5 6.61929 6.61929 5.5 8 5.5V4Z" fill="currentColor"></path>
                              <path d="M8 9V10C8.55228 10 9 9.55228 9 9H8ZM8 9H7C7 9.55228 7.44772 10 8 10V9ZM8 9V8C7.44772 8 7 8.44772 7 9H8ZM8 9H9C9 8.44772 8.55228 8 8 8V9Z" fill="currentColor"></path>
                              <path d="M8.75 4C8.33579 4 8 4.33579 8 4.75C8 5.16421 8.33579 5.5 8.75 5.5V4ZM8.75 15.5C8.33579 15.5 8 15.8358 8 16.25C8 16.6642 8.33579 17 8.75 17V15.5ZM8.75 5.5H15.25V4H8.75V5.5ZM18.5 8.75V15.25H20V8.75H18.5ZM18.25 15.5H8.75V17H18.25V15.5ZM18.5 15.25C18.5 15.3881 18.3881 15.5 18.25 15.5V17C19.2165 17 20 16.2165 20 15.25H18.5ZM15.25 5.5C17.0449 5.5 18.5 6.95507 18.5 8.75H20C20 6.12665 17.8734 4 15.25 4V5.5Z" fill="currentColor"></path>
                              <path d="M14.75 8.25C14.3358 8.25 14 8.58579 14 9C14 9.41421 14.3358 9.75 14.75 9.75V8.25ZM16.25 9.75C16.6642 9.75 17 9.41421 17 9C17 8.58579 16.6642 8.25 16.25 8.25V9.75ZM14.75 9.75H16.25V8.25H14.75V9.75Z" fill="currentColor"></path>
                              <path d="M16 16.5C16 16.0858 15.6642 15.75 15.25 15.75C14.8358 15.75 14.5 16.0858 14.5 16.5H16ZM14.5 19.25C14.5 19.6642 14.8358 20 15.25 20C15.6642 20 16 19.6642 16 19.25H14.5ZM14.5 16.5V19.25H16V16.5H14.5Z" fill="currentColor"></path>
                            </svg>
                          `
            break
          case TodoItemActionKind.COLLECTION_LITIGATION:
            badgeClass = 'bg-red-50'
            svgPath = `
                            <svg class="h-6 w-6 py-0.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 14.25L5.75 10L7 8.75L7.5 9.25L10 6.75L9.5 6.25L11 4.75L15.25 9L14 10.25L13.5 9.75L10.75 12.5L11.25 13L10 14.25Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12 12L19.25 19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M4.75 18.75V19.25H13.25V18.75C13.25 17.6454 12.3546 16.75 11.25 16.75H6.75C5.64543 16.75 4.75 17.6454 4.75 18.75Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M9 8L12 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                          `
            break
          case TodoItemActionKind.DISPUTE_RESOLUTION:
            badgeClass = 'bg-yellow-100'
            svgPath = `<svg class="h-6 w-6 py-0.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.75 15.75L8.25 19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M8.25 15.75L4.75 19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M11.75 19.25H15.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M8.75 8.75H15.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M9.75 11.75H14.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M19.25 19.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V12.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        `
            break
          case TodoItemActionKind.CELEBRATE_AND_CLOSE:
            badgeClass = 'bg-green-100'
            svgFillClass = 'fill-green-500'
            break
          case TodoItemActionKind.CHECK_CASH_APPLICATION_PROPOSAL:
            badgeClass = 'bg-blue-100'
            svgFillClass = 'fill-blue-500'
            break
          case TodoItemActionKind.NOTHING_ANYMORE:
            badgeClass = 'bg-indigo-100'
            svgPath = `
                        <svg class="h-6 w-6 py-0.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M17 7L7 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        `
            break
          case TodoItemActionKind.TO_BE_DEFINED:
            badgeClass = 'bg-purple-100'
            svgFillClass = 'fill-purple-500'
            break
          default:
            badgeClass = ''
            svgPath = ''
        }

        this.eGui.innerHTML =
          `<span class="cursor-pointer inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-black border border-transparent ring-1 ring-gray-200 hover:shadow-md hover:border-black ${badgeClass}">
                    ${svgPath}
                    <span class="border-l border-black py-1 pl-2">${t(`dunning.actionKind.${followUpData.actionKind}`)}</span></span>
                    <!--<span style="display: block; margin-top: 4px;">${actionText}</span>-->`

      } else if (latestDoneEvent) {
        this.eGui.innerHTML =
          `<span class="text-xs font-medium text-paleSky">${t('dunning.latestContact')} ${formatTimeAgo(new Date(latestDoneEvent?.dateDoneRFC3339), t('locales'))}</span>`

      } /*else if (!latestDoneEvent && issuedEvent ) {
                this.eGui.innerHTML =
                    `<h4 class="text-xxs font-medium">${t(`history.kind.${issuedEvent.eventKind}.timelineDoneState`)}</h4>
            <p class="text-xxs">${t(`history.kind.${issuedEvent.eventKind}.timelineDoneCopy`, { dateDone: formatToShortDate(new Date(issuedEvent.dateInvoiceIssued * 1000))})}</p>`;
                 console.log('DatatableFollowUpRenderer: issuedEvent', issuedEvent);
                 console.log('historyData', historyData);
            }*/
      else {

        this.eGui.innerHTML = `
                <span class="cursor-pointer inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-black border border-transparent ring-1 ring-gray-200 hover:shadow-md hover:border-black bg-green-50">
                <svg class="h-6 w-6 py-0.5" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 19.25L12 4.75L19.25 19.25L12 15.75L4.75 19.25Z"></path>
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 15.5V12.75"></path>
                </svg>
                <span class="border-l border-gray-200 py-1 pl-2">${t('dunning.actionKind.InvoiceIssuance')}</span></span>`
      }
    } else if (historyData) {
      const date = historyData.invoiceEvents.find(i => i.dateInvoiceIssuedRFC3339)
      
      if (date && date.dateInvoiceDueRFC3339) {
        const dateFormat = formatToShortDate(new Date(date.dateInvoiceDueRFC3339))
        this.eGui.innerHTML = `<span class="text-xs font-medium text-paleSky">${t('dunning.paidAt')} ${dateFormat}</span>`
      }
    }
  }
  public getGui(): HTMLElement {
    return this.eGui
  }
}

<script lang="ts">
    import { t } from '$core/lib/i18n/i18nextWrapper'
    import { WorkspaceStore } from '$crm/stores/workspace.store'
    import ConfirmationModal from '../../core-app/lib/ui-kit/ConfirmationModal.svelte'
    import SingleBankAccountView from '../components/SingleBankAccountView.svelte'
    import Loader from '../../core-app/lib/ui-kit/Loader.svelte'
    import { onMount } from 'svelte'
    import mixpanel from 'mixpanel-browser'
    import { bridgeByBankingService } from "$pay/services/bridge-by-banking.service";

    /** Local Declarations */
    let loadingData: boolean
    let refreshData: boolean
    let displayDeleteBankConnectionConfirmation: boolean = false

    /** Const & Functions */
    const removeBankAccount = () => {
      bridgeByBankingService.removeBankConnection()
      displayDeleteBankConnectionConfirmation = false
      refreshData = true
    }

    onMount(()=>{
      /*console.log('%c onMount()  ->  BankView.svelte', 'color:green;font-size: 1.5em;')*/
      mixpanel.track('CS70 Onboarding - Bank View', {
        'Description': 'Open BankView.svelte'
      })
    })
</script>

<ConfirmationModal
        modalHeading={t('editBank.removeBankConnectionConfirmation.deleteAccountModalTitle')}
        primaryButtonText={t('editBank.removeBankConnectionConfirmation.deleteConfirmButtonModal')}
        secondaryButtonText={t('editBank.removeBankConnectionConfirmation.deleteCancelButtonModal')}
        open={displayDeleteBankConnectionConfirmation}
        on:cancelAndClose={() => {displayDeleteBankConnectionConfirmation = false}}
        on:confirmAction={() => removeBankAccount()}>{t('editBank.removeBankConnectionConfirmation.deleteAccountModalCaption1')}</ConfirmationModal>

<div class="flex w-full flex-col flex-1">
    <h1 class="text-black text-3xl font-bold">{t('editBank.title')}</h1>
    {#if $WorkspaceStore?.bankConfig?.bankConnectionCurrentlyKnownActive}
        <p class="mt-2 text-sm">{t('editBank.subtitle', { count: 1 })}</p>
    {/if}
</div>

{#if loadingData}
    <div class="grid mt-24 place-items-center max-w-3xl z-[100]">
        <Loader i18nKey="editBank.loadingData"/>
    </div>
{:else}
    <section class="max-w-3xl relative">
        <div class="pb-24">
            <div class="rounded">
                <SingleBankAccountView
                        bind:loadingData
                        bind:refreshData
                        on:displayDeleteBankConnectionConfirmation={() => { displayDeleteBankConnectionConfirmation = true}}
                />
            </div>
        </div>
    </section>
{/if}

<script lang="ts">
    import { t } from '../../core-app/lib/i18n/i18nextWrapper'
    import UpdatedCompany from '../models/updated-company'
    import CompanyEdit from '../components/ui-kit/CompanyEdit.svelte'
    import { WorkspaceStore } from '../stores/workspace.store.js'
    import { get } from 'svelte/store'
    import {
      uploadCompanyLogoAndUpdateCurrentWorkspaceUpdatedCompany
    } from '../services/save-new-workspace-or-update-company-workspace'
    import FileUploadProgressBar from '../../core-app/lib/ui-kit/FileUploadProgressBar.svelte'
    import { onMount } from 'svelte'
    import mixpanel from 'mixpanel-browser'

    function onSaveCompanyChanges(updatedCompany: CustomEvent<UpdatedCompany>) {
      const changedCompany: UpdatedCompany = updatedCompany.detail
      uploadCompanyLogoAndUpdateCurrentWorkspaceUpdatedCompany(changedCompany)
    }

    function onFailedToUploadCompanyLogo(e: CustomEvent<string>) {
      const reason: string = e.detail
    }

    onMount(()=>{
      mixpanel.track('NJ10 Edit Workspace Company Page', {
        'Description': 'Open WorkspaceCompanyEditPage.svelte'
      })
    })

</script>

<div data-ut-component="workspace-company-edit-page">
    <section class="max-w-xl">

        <div class="pb-24">
            <div>
                <h2 class="text-black text-3xl font-bold">{t('companyEdit.title')}</h2>
                <p class="mt-2 text-sm">{t('companyEdit.titleCopy')}</p>
            </div>

            <div id="workspace-company-new-edit" class="col-span-full mt-6 border-t border-athensGray pt-4">

                <FileUploadProgressBar titleTMessageEntry='settings.uploadLogo.uploadingLogo'/>

                <CompanyEdit
                        localCompanyInput={get(WorkspaceStore).company}
                        withLogoUI={true}
                        withLegalStructureInfoUI={true}
                        withTimeZoneInfoUI={true}
                        needsAtLeastOneLegalIdentificationEntry={true}
                        dataCy="cy-workspace-company-edit-page"
                        on:saveCompanyChanges={onSaveCompanyChanges}
                        on:failedToUploadCompanyLogo={onFailedToUploadCompanyLogo}
                />
            </div>

        </div>

    </section>
</div>

<style global lang="postcss">
    .cropper-modal {
        background-color: #fff !important;
    }

    .img-preview {
        @apply border border-black h-48 w-48;
    }
</style>
import { get } from 'svelte/store'
import { WorkspaceStore } from '$crm/stores/workspace.store'
import { BBBBankAccount, type BBBTransaction, type BBBTransactionsResponse } from '../models/bbb-transactions-model'
import { eventsManager } from '$core/events/event-manager'
import { EventType } from '$core/events/event-type'
import { TransactionsStore } from '../stores/transactions.store'
import type { DundyEvent } from '$dundy/events/dundy-event'
import type { WorkspaceBankConfig } from "$pay/models/workspace-bank-config";
import { Workspace } from "$crm/models/workspace";
import { bbbAPIPrimitivesService } from "$pay/services/bbb-api-primitives.service";
import BBBAccountsConnectionStatusResponse from "$pay/models/bbb-connection-items-list-status-model";
import { bridgeByBankingService } from "$pay/services/bridge-by-banking.service";
import mixpanel from "mixpanel-browser";

class BankingTransactionsServiceClass {
  initialized: boolean = false
  initialize() {
    if (!this.initialized) {
      /* console.log('init TransactionsStore !!!!') */
      this.initialized = true

      // when a new Transactions list has just been loaded from the back-end
      eventsManager.on<BBBTransactionsResponse>(EventType.TRANSACTIONS_LIST_FETCHED, (e: DundyEvent<BBBTransactionsResponse>) => {
        const bankAccountId = get(WorkspaceStore)?.bankConfig?.selectedAccountId
        e.data.transactions = e.data.transactions.filter(t => t.account_id === Number(bankAccountId))

        TransactionsStore.set(e.data)
      }, 'transactionsService')

      this.reloadTransactions()
    }
  }

  /**
     * Filters positive transactions from the list of transactions.
     * @param transactions
     */
  retrieveTransactionsAndReturnOnlyPositiveTransactions(transactions: BBBTransaction[]): { allForThisAccount: BBBTransaction[], positiveForThisAccount: BBBTransaction[] } {
    const bankAccountId: string = get(WorkspaceStore)?.bankConfig?.selectedAccountId

    const allForThisAccount: BBBTransaction[] = transactions.filter((transaction: BBBTransaction) => transaction.account_id.toString() === bankAccountId)
    const positiveForThisAccount: BBBTransaction[] = allForThisAccount.filter((transaction: BBBTransaction) => transaction.amount > 0)

    return {
      allForThisAccount,
      positiveForThisAccount
    }
  }

  /**
     * Check if the workspace has a valid banking setting
     * Then get the BBB transactions from back-end
     */
  checkAndGetWorkspaceTransactionsFromBridge(): Promise<BBBTransactionsResponse> {
    const isBankingSettingValid: boolean = !!get(WorkspaceStore)?.bankConfig?.bankConnectionCurrentlyKnownActive
    if (isBankingSettingValid) {
      return bbbAPIPrimitivesService.getBBBWorkspaceTransactionsStandardized()
        .then((transactionsResponse: BBBTransactionsResponse) => {
          // asynchronously check bank connection status
          // TODO reject instead of resolve if cannot get properly new transactions update ? tbd
          bridgeByBankingService.determineBBBStatusForBankAccountsList(transactionsResponse.accounts)
            .then((accountsInfoResp: BBBAccountsConnectionStatusResponse) =>
              bridgeByBankingService.consequencesOfBankAccountsListStatus(accountsInfoResp)
            )
          return transactionsResponse
        })
    } else {
      TransactionsStore.set({} as BBBTransactionsResponse)
      return new Promise<BBBTransactionsResponse>((resolve, reject) => {
        // TODO reject instead of resolve if cannot get transactions ? tbd
        resolve({} as BBBTransactionsResponse)
      })
    }
  }

  reloadTransactions(): void {
    bankingTransactionsService.checkAndGetWorkspaceTransactionsFromBridge()
      .then((bbbResp: BBBTransactionsResponse)=>{
        mixpanel.track('DG10 New Workspace Bank Account Transactions Loaded Successfully', {
          'Description': 'Successfully loaded bank account data for workspace from BBB'
        })
        if (bbbResp.ok) eventsManager.emit(EventType.TRANSACTIONS_LIST_FETCHED, bbbResp, 'BankAccountSelectModal.svelte')
      })
      .catch(reason => {
         console.error('%c banking transactions FAILED to load from back-end: %s', 'color:red;font-size: 1.1em;', reason)
      })
  }

  // bankingTransactionsService.setBBBConnectionAsExpiredGlobally()
  setBBBConnectionAsExpiredGlobally() {
    let workspace: Workspace = get(WorkspaceStore)
    let bankConfig: WorkspaceBankConfig = get(WorkspaceStore)?.bankConfig || {} as WorkspaceBankConfig
    bankConfig.bankConnectionCurrentlyKnownActive = false
    workspace.bankConfig = bankConfig
    WorkspaceStore.set(workspace)
  }
}

export const bankingTransactionsService: BankingTransactionsServiceClass = new BankingTransactionsServiceClass()
bankingTransactionsService.initialize()

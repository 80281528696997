import { WorkspaceStore } from '$crm/stores/workspace.store'
import { getPreferenceByKind, savePreference } from '$shared/services/preference.service'
import { get } from 'svelte/store'

type DunningWorflowPrefItem = {
  dunningWorkflowL10ns: string[]
  defaultL10n: string,
  mandatoryFallbackL10n: string,
  defaultDunningWorkflowKind: string,
  isSystemDefault: boolean
}

export type DunningWorkflow = {
  prefKey: any,
  prefItem: DunningWorflowPrefItem
}

type DunningWorflowResponse = {
  err: any[]
  workflow: DunningWorkflow | null;
}

/**
 * GET DUNNING WORKFLOW
 * @param {string} customerId 
 * @returns 
 */
export const getDunningWorkflow = async (customerId: string):Promise<DunningWorflowResponse> => {
  const workspaceId = get(WorkspaceStore).workspaceId

  let obj = { workspaceId } as any
  if (customerId) obj = { ...obj, customerId }

  const res = await getPreferenceByKind(workspaceId, 'DunningWorkflow', [obj ])

  const err: any[] = res?.failures
  let workflow: any

  if (!err.length) {
    const tmp = (res?.successes as any[])[0]
    if (tmp) workflow = tmp?.sortedDunningWorkflowPrefs?.at(0)
  }

  return {
    err,
    workflow
  }
}

/**
 * SAVE DUNNING WORKFLOW
 * @param {DunningWorkflow} dunningWorkflow 
 * @param {string} customerId 
 * @returns 
 */
export const saveDunningWorkflow = async (dunningWorkflow:DunningWorkflow, customerId: string = ''):Promise<void> => {
  const workspaceId = get(WorkspaceStore).workspaceId

  let obj = {
    'kind': 'DunningWorkflow',
    workspaceId,
    dunningWorkflow
  } as any

  if (customerId) obj.dunningWorkflow.prefKey = { ...obj.dunningWorkflow.prefKey, customerId }
  
  await savePreference(workspaceId, obj)
}
<script lang="ts">
  import { t } from '../../../core-app/lib/i18n/i18nextWrapper.js'
  import { isUserWsOwner } from '../../../core-app/services/guard.js'
  import { link } from 'svelte-routing'
  import { onMount } from "svelte";
  import { bankingTransactionsService } from "$bank/services/banking-transactions.service";

  export let hasTransactions: boolean = false
  export let hasLoadedTransactions: boolean = false

  let hasRefreshed: boolean

  const mustRefreshTransactions = () => {
    hasRefreshed = true
    bankingTransactionsService.reloadTransactions()
  }

  onMount(() => {
    hasRefreshed = false
  })
</script>

<div class="flex flex-col items-center justify-center mt-12">
  <h1 class="text-2xl font-bold">{hasTransactions ? t('transactions.empty.title') : t('transactions.error.title')}</h1>
  <p class="my-2">{hasTransactions ? t('transactions.empty.subtitle') : t('transactions.error.subtitle')}</p>
  {#if isUserWsOwner()}
    <a use:link
       href="/settings/bank/view"
       class="mt-6 inline-flex items-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 disabled:bg-transparent disabled:border-athensGray disabled:text-loblolly disabled:shadow-none disabled:cursor-not-allowed">
      {hasTransactions ? t('transactions.empty.button') : t('transactions.error.button')}
    </a>
    {#if !hasRefreshed}
      <a on:click={() => mustRefreshTransactions()}
         class="mt-6 inline-flex items-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 disabled:bg-transparent disabled:border-athensGray disabled:text-loblolly disabled:shadow-none disabled:cursor-not-allowed">
        {t('transactions.refreshTransactions.refreshTransactionsButtonTitle')}
      </a>
    {/if}
    {#if hasLoadedTransactions}
      <p class="mt-12">
        {t('transactions.refreshTransactions.refreshTransactionsNoTransaction')}
      </p>
    {/if}
  {:else}
    <p>{t('cashApplication.missingBankAccount.notOwner')}</p>
  {/if}
</div>

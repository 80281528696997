<script lang="ts">
    import { createEventDispatcher } from 'svelte'
    import Tooltip from './TextInputTooltip.svelte'

    const dispatch = createEventDispatcher()

    /**
     * Specify the input value
     * @type {number | string}
     */
    export let value = ''

    /** Obtain a reference to the input HTML element */
    export let ref = null

    /** Specify a name for the cypress selector */
    export let dataCy:string = ''

    /** Specify the label text */
    export let label = ''

    let propClass = ''
    export { propClass as class }
    export let inputClass = ''
    export let inputStyle = ''

    /**
     * Set to `"text"` to toggle the password visibility
     * @type {"text" | "password"}
     */
    export let type = 'password'

    /** Specify the placeholder text */
    export let placeholder = ''

    /** Set to `true` to mark the field as required */
    export let required = false

    /** Specify the invalid state text */
    export let error = ''

    /** Set to `true` to indicate an invalid state */
    export let showError = false

    /**
     * Specify a name attribute for the input
     * @type {string}
     */
    export let name: any = undefined

    /** Set to `true` to disable the input */
    export let disabled = false

    /** Set an id for the input element */
    export let id: string = 'ccs-' + Math.random().toString(36)

    $: errorId = `error-${id}`

    export let displayTooltipOnFocus = false

    export let tooltipPosition: 'left' | 'right' = 'left'

    /** Specify the hide password label text */
    export let hidePasswordLabel: string = 'Hide'

    /** Specify the show password label text */
    export let showPasswordLabel: string = 'Show'

    /** Should Tooltip be displayed */
    export let displayTooltip: boolean = false

    /**
     * Handle the input event
     * @param event
     */
    function handleInput(event: Event) {
      value = (event.target as HTMLInputElement).value
    }
</script>

<fieldset class={propClass}>
    <label for={id}
           class="block text-sm font-normal text-black">{label}</label>
    <div class="mt-1.5 relative rounded-md shadow-sm">
        <input bind:this="{ref}"
               on:input={handleInput}
               data-invalid="{showError || undefined}"
               aria-invalid="{showError || undefined}"
               aria-describedby="{showError ? errorId : undefined}"
               class={inputClass}
               style={inputStyle}
               {disabled}
               data-cy="{dataCy}"
               id="{id}"
               {name}
               placeholder="{placeholder}"
               required="{required}"
               type="{type}"
               on:change={() => { dispatch('change', value)}}
               on:focus={e => {
                 if (displayTooltipOnFocus) {
                   displayTooltip = true
                 }
                 dispatch('focus', e)
                 
                 return true
               }}
               on:blur={e => {
                 displayTooltip = false
                 dispatch('blur', e)
                 
                 return true
               }}/>
        <span
                on:click="{() => {
                  type = type === 'password' ? 'text' : 'password'
                }}"
                class="button-secondary-border">
            {#if type === 'text'}
                {hidePasswordLabel}
            {:else}{showPasswordLabel}{/if}
        </span>
        {#if showError}
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                    <path d="m9.983.746.144.135 4.992 4.992a3.008 3.008 0 0 1 .135 4.11l-.135.144-4.992 4.992-.144.135a3.008 3.008 0 0 1-3.966 0l-.144-.135-4.992-4.992-.135-.144a3.008 3.008 0 0 1 0-3.966l.135-.144L5.873.881l.144-.135a3.008 3.008 0 0 1 3.966 0ZM7.048 1.838l-.114.104-4.992 4.992-.104.114c-.45.553-.45 1.351 0 1.904l.104.114 4.992 4.992a1.508 1.508 0 0 0 2.018.104l.114-.104 4.992-4.992.104-.114c.45-.553.45-1.351 0-1.904l-.104-.114-4.992-4.992-.114-.104a1.508 1.508 0 0 0-1.904 0Zm.962 8.412.102.007a.75.75 0 0 1 0 1.486l-.102.007H8l-.102-.007a.75.75 0 0 1 0-1.486L8 10.25h.01ZM8 4a.75.75 0 0 1 .743.648l.007.102v3.5a.75.75 0 0 1-1.493.102L7.25 8.25v-3.5A.75.75 0 0 1 8 4Z"
                          fill="#DE496D" fill-rule="nonzero"/>
                </svg>
            </div>
        {:else if value && !showError}
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <div class="rounded-full w-4 h-4 bg-cruise p-0">
                    <i class="icon-done text-xl relative right-0.5 bottom-0.5"></i>
                </div>
            </div>
        {/if}
        <slot name="icon"/>
    </div>
    {#if showError}
        <p class="mt-2 text-sm text-cabaret" id="{name}-error">{error}</p>
    {/if}

    {#if displayTooltip}
        <Tooltip position={tooltipPosition}>
            <slot name="tooltip"></slot>
        </Tooltip>
    {/if}

    <slot/>
</fieldset>

<style lang="postcss">
    fieldset {
        @apply flex flex-col relative;
    }

    input {
        padding: 0.5rem 0.75rem;
        @apply w-full rounded sm:text-sm shadow-none border-loblolly;
    }

    input:focus {
        @apply ring-1 ring-red-400 outline-none;
    }

    input:disabled {
        @apply bg-whisper text-paleSky;
    }

    .button-secondary-border {
        @apply cursor-pointer absolute right-10 top-2 bottom-2 my-auto inline-flex items-center justify-center px-4 py-2 space-x-1 bg-white border border-mercury hover:border-black rounded-md hover:bg-opacity-20 text-xs font-medium;
    }

    .input-date-picker {
        @apply rounded !important;
        @apply border-loblolly text-black;
        height: 100%;
        width: 100%;
    }

    .input-date-picker:focus {
        @apply ring-1 ring-red-400 outline-none;
        border-color: var(--primary-color);
    }
</style>

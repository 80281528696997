<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import { copyTextToClipboard } from '../../../core-app/services/copy-clipboard'
    import { toast } from '@zerodevx/svelte-toast'
    import { createEventDispatcher } from 'svelte'
    import TodoActionSelectContactArea from '../todo/TodoActionSelectContactArea.svelte'
    import SingleContactDropdown from './SingleContactDropdown.svelte'
    import type { Contact } from '../../../crm-app/models/contact'
    import type { Customer } from '../../../crm-app/models/customer'
    const dispatch = createEventDispatcher()

    /** Export let */
    export let renderedBodyMessage: string
    export let customerContacts: Contact[]
    export let customer: Customer
    export let selectedContact: Contact

    function updateContact(e: CustomEvent<Contact>) {
      selectedContact = e.detail
    }

    const copyToClipboard = (text, area) => {
      copyTextToClipboard(text)
      toast.push(t('todoAction.copySuccess', { area }))
    }
</script>

<div class="my-6 px-4">
    {#if (!!customerContacts && customerContacts.length || selectedContact)}
        <div class="flex items-center my-2">
            <div class="w-full">
                <SingleContactDropdown
                        bind:selectedContact
                        {customerContacts}
                        on:contactSelected={(e) => {
                          dispatch('contactSelected', e.detail)
                          updateContact(e.detail)
                        }}
                />
            </div>
        </div>
        {#if (selectedContact?.officePhone)}
        <div class="flex items-center align-middle my-4">
            <div class="w-full">
                <h3 class="block text-xs font-medium text-black">{t('customerForm.officePhone.label')}</h3>
                <div class="mt-1 flex rounded-md shadow-sm">
                    <div class="relative flex items-stretch flex-grow focus-within:z-10">
                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                            </svg>
                        </div>
                        <p class="block w-full rounded-none rounded-l-md pl-10 sm:text-sm border border-athensGray w-full h-full px-2 py-2 text-s">{selectedContact?.officePhone}</p>
                    </div>
                    <button on:click={() => copyToClipboard(selectedContact?.officePhone, t('customerForm.officePhone.label'))} type="button" class="text-xs -ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-dundyOrange focus:border-dundyOrange">
                        <i class="icon-copy text-sm mr-1"/>
                        <span>{t('todoAction.copy')}</span>
                    </button>
                </div>
            </div>
        </div>
        {/if}
        {#if (selectedContact?.mobilePhone)}
        <div class="flex items-center align-middle my-4">
            <div class="w-full">
                <h3 class="block text-xs font-medium text-black">{t('customerForm.mobilePhone.label')}</h3>
                <div class="mt-1 flex rounded-md shadow-sm">
                    <div class="relative flex items-stretch flex-grow focus-within:z-10">
                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <p class="block w-full rounded-none rounded-l-md pl-10 sm:text-sm border border-athensGray w-full h-full px-2 py-2 text-s">{selectedContact?.mobilePhone}</p>
                    </div>
                    <button on:click={() => copyToClipboard(selectedContact?.mobilePhone, t('customerForm.mobilePhone.label'))} type="button" class="text-xs s-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-dundyOrange focus:border-dundyOrange">
                        <i class="icon-copy text-sm mr-1"/>
                        <span>{t('todoAction.copy')}</span>
                    </button>
                </div>
            </div>
        </div>
        {/if}
    {:else}
        <TodoActionSelectContactArea {customer}/>
    {/if}
    <div class="w-full mt-4 rounded-lg border border-athensGray my-6 text-s relative bg-black text-white">
        <div class="w-full h-full px-8 pt-6 pb-6"
             contenteditable="true"
             id="renderedBody"
             bind:innerHTML={renderedBodyMessage}>
        </div>
    </div>
</div>

import type Invoice from '../models/invoice'
import type { Customer } from '../../crm-app/models/customer'
import { currentProfileLanguage, t } from '../../core-app/lib/i18n/i18nextWrapper'
import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
import { ProfileStore } from '../../core-app/stores/profile.store'
import { buildTodoActionEmail } from '../lib/templates/template-email-todo-action'
import { get } from 'svelte/store'
import type { Contact } from '../../crm-app/models/contact'
import type { TodoItemActionKind, TodoListItem } from '../models/todo-list-item'
import { TodoItemMessageKind } from '../models/todo-list-item'
import { DunningInvoicesStore } from '../stores/dunning-invoices.store'
import { TodosStore } from '../stores/todos.store'
import { formatCurrencyWithOptions } from '../../voxy-app/utils/number-formatting-utils'
import type { Workspace } from '../../crm-app/models/workspace'
import type { senderInformationHeader } from '../models/sendgrid-email-configuration'
import type Profile from '../../core-app/models/profile'
import { getCountryNameForCountryCode } from '../../core-app/services/countries-pure-functions'
import { bbbAPIPrimitivesService } from "$pay/services/bbb-api-primitives.service";


/**
 * Returns the todo item associated with the given invoice
 * returns undefined if no todo item is found
 * returns todo item if found
 * @param invoiceId
 */
export function getTodoFromInvoiceId(invoiceId: string): TodoListItem | undefined {
  const invoice: Invoice = get(DunningInvoicesStore).find((inv: Invoice): boolean => inv.dataId === invoiceId) || {} as Invoice
  if (!invoice || invoice.dataId === "") {
    return
  }

  return get(TodosStore).find((todo: TodoListItem) => todo.scopeInvoiceNumbers.some((invoiceNumber: string): boolean => invoiceNumber === invoice.invoiceNumber))
}

/**
 * Call the Bridge For Bankin Service to generate a payment link for the given invoice and customer
 * @param invoice
 * @param customer
 */
export function generatePaymentLinkForInvoiceAndCustomer(invoice: Invoice, customer: Customer): Promise<string> {
  return new Promise((resolve, reject): void => {
    bbbAPIPrimitivesService.generatePaymentLinkStandardized(invoice?.invoiceNumber,
      invoice?.amountIncludingTaxes, customer?.company.formalName, t('todoAction.paymentDescription',
        { invoiceNumber: invoice?.invoiceNumber }))
      .then((link: string) => resolve(link))
      .catch(err => {
        reject(err)
      })
  })
}


/**
 * Builds the email header for the action email
 * returns an object with the email header
 * @param workspaceStore
 */
export function populateEmailHeader(workspaceStore: Workspace): senderInformationHeader {
  let senderCompanyLegalIdentificationTypeFRSIREN: string = workspaceStore?.company?.regulatory?.frRegulScope?.siret || ''
  let senderCompanyLegalIdentificationTypeFRTVA: string = workspaceStore?.company?.regulatory?.euRegulScope?.euIntraVat || ''

  return {
    'senderCompanyName': workspaceStore.company?.formalName,
    'senderCompanyLogo': workspaceStore.company.emailLogoURL ? workspaceStore.company.emailLogoURL : '',
    'senderStreet': workspaceStore.company?.mailAddress.street,
    'senderZipCode': workspaceStore.company?.mailAddress.zipCode,
    'senderCity': workspaceStore.company?.mailAddress.city,
    'senderCountry': getCountryNameForCountryCode(currentProfileLanguage(), workspaceStore.company?.mailAddress.countryCode),
    'senderCompanyID': workspaceStore.company?.mailAddress.countryCode === 'FR' ? senderCompanyLegalIdentificationTypeFRSIREN : senderCompanyLegalIdentificationTypeFRTVA
  }
}

/**
 * Builds the subject/title for the action
 * @param todo
 * @param invoice
 * @param programmedActionKind
 * @param activeItemMessageTab
 * @param workspaceStore
 */
export function populateSubjectMessage(todo: TodoListItem, invoice: Invoice, programmedActionKind: TodoItemActionKind, activeItemMessageTab: TodoItemMessageKind, workspaceStore: Workspace): string {
  return t(`todoAction.actionKind.${programmedActionKind}.messageKind.${activeItemMessageTab}.subject`, {
    senderCompanyName: workspaceStore.company?.formalName,
    invoiceNumber: invoice?.invoiceNumber,
    dueDays: Math.abs(todo?.maxDueDays)
  })
}

/**
 * Builds the email body for the action email
 * @param programmedActionKind
 * @param activeItemMessageTab
 * @param selectedContact
 * @param customer
 * @param todo
 * @param invoice
 * @param paymentLink
 * @param showPaymentLink
 * @param showInvoiceAttachment
 */
export function populateActionBody(
  programmedActionKind: TodoItemActionKind,
  activeItemMessageTab: TodoItemMessageKind,
  selectedContact: Contact,
  customer: Customer,
  todo: TodoListItem,
  invoice: Invoice,
  paymentLink: string,
  showPaymentLink: boolean,
  showInvoiceAttachment: boolean,
): string {
  const workspaceStore: Workspace = get(WorkspaceStore)
  const profileStore: Profile = get(ProfileStore)

  const daysLate: number = Math.abs(todo?.maxDueDays)

  const invoiceDueDate: string = new Intl.DateTimeFormat(t('locales'), {
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  }).format(new Date(!!invoice?.dateDue ? invoice?.dateDue : (new Date).toISOString()))

  const invoiceIssuedDate: string = new Intl.DateTimeFormat(t('locales'), {
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  }).format(new Date(!!invoice?.dateIssued ? invoice?.dateIssued : (new Date).toISOString()))

  const data = {
    firstName: selectedContact?.firstName,
    lastName: selectedContact?.lastName,
    senderCompanyName: workspaceStore?.company?.formalName,
    companyName: customer?.company?.formalName,
    companyLogo: customer?.company?.emailLogoURL,
    invoiceNumber: invoice?.invoiceNumber,
    dueDate: invoiceDueDate,
    issuedDate: invoiceIssuedDate,
    daysLate: daysLate,
    invoiceAmount: formatCurrencyWithOptions(invoice?.amountIncludingTaxes, t('locales'), invoice?.currency, true, 'symbol'),
    senderFirstName: profileStore.firstName,
    senderLastName: profileStore.lastName,
    senderEmail: profileStore.email,
    senderPhoneNumber: profileStore.mobilePhone,
    role: profileStore.roleInCompany === 'other' ? profileStore.roleInCompanyCustom : t('onboarding.lists.role.' + profileStore.roleInCompany),
    dueDays: Math.abs(todo?.maxDueDays),
    paymentLink: paymentLink ? paymentLink : '',
    bankName: workspaceStore.bankConfig?.selectedAccountBankName,
    bankIBAN: workspaceStore.bankConfig?.selectedAccountBankIBAN,
    bankBIC: workspaceStore.bankConfig?.selectedAccountBankBIC,
    showPaymentLink,
    showInvoiceAttachment
  }
  if (activeItemMessageTab === TodoItemMessageKind.EMAIL) {
    return buildTodoActionEmail(programmedActionKind, data)
  } else {
    return t(`todoAction.actionKind.${programmedActionKind}.messageKind.${activeItemMessageTab}.itemBody`, {
      senderCompanyName: workspaceStore.company?.formalName,
      senderCompanyAddress: workspaceStore.company?.mailAddress?.street,
      senderCompanyPostalCode: workspaceStore.company?.mailAddress?.zipCode,
      senderCompanyCity: workspaceStore.company?.mailAddress?.city,
      senderFirstName: profileStore.firstName,
      senderLastName: profileStore.lastName,
      senderEmail: profileStore.email,
      senderPhoneNumber: profileStore.mobilePhone,
      senderRole: profileStore.roleInCompany === 'other' ? profileStore.roleInCompanyCustom : t('onboarding.lists.role.' + profileStore.roleInCompany),
      customerFirstName: selectedContact?.firstName,
      customerLastName: selectedContact?.lastName,
      customerCompanyName: customer?.company?.formalName,
      customerCompanyLogo: customer?.company?.emailLogoURL,
      customerCompanyAddress: customer?.company?.mailAddress?.street,
      customerCompanyPostalCode: customer?.company?.mailAddress?.zipCode,
      customerCompanyCity: customer?.company?.mailAddress?.city,
      customerCompanyCountry: getCountryNameForCountryCode(currentProfileLanguage(), customer?.company?.mailAddress?.countryCode),
      invoiceNumber: invoice?.invoiceNumber,
      invoiceAmount: formatCurrencyWithOptions(invoice?.amountIncludingTaxes, t('locales'), invoice?.currency, true, 'symbol').replace(/\u202f/g, ' '),
      todayDate: new Intl.DateTimeFormat(t('locales'), {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
      }).format(new Date()),
      dueDate: invoiceDueDate,
      issuedDate: invoiceIssuedDate,
      maxDueDays: Math.abs(todo?.maxDueDays),
      daysLate: daysLate
    })
  }
}
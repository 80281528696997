import type Invoice from '../../dundy-app/models/invoice'
import type {
  CalculationNature,
  ValueForCurrentMonthAlongWithVariationMonthToMonth
} from '../models/dashboard-statistics-analytics-model'
import { AnalyticsElements } from '../enums/analytics-enums'
import type ComputedDataInvoice from '../../dundy-app/models/computed-data-invoice'
import {
  getOverdueInvoicesExcludingUntrackedAndDeleted
} from '$dundy/services/invoices.pure-functions'
import { DunningBusinessDocumentKind } from '$dundy/enums/dunning-business-document-kind'


/**
 * Calculate the outstanding invoices (NOT YET DUE) for the current month
 * along with the variation to the previous month
 * uses getOutstandingInvoicesExcludingUntrackedAndDeleted
 */
export function calculateOutstandingNotYetDueDunningInvoicesForCurrentMonthAlongWithVariationToPreviousMonth(
  invoices: Invoice[],
  dateInTimezone: Date,
): ValueForCurrentMonthAlongWithVariationMonthToMonth {

  /* console.log(invoices, 'invoices from calculateOutstandingNotYetDueDunningInvoicesForCurrentMonthAlongWithVariationToPreviousMonth') */

  if (!(dateInTimezone instanceof Date)) {
    throw new Error('Invalid input: currentMonth should be a Date object')
  }

  const newDateInTimezone: Date = new Date(dateInTimezone.getTime())


  const currentMonthDates: { startDate: Date, endDate: Date } = getStartAndEndDates(newDateInTimezone)

  /* console.log('currentMonthDates', currentMonthDates) */

  const currentMonthOutstandingInvoices: Invoice[] = invoices.filter(
    (invoice: Invoice) =>
      new Date(invoice.dateDue) > currentMonthDates.endDate &&
            new Date(invoice.dateIssued) <= currentMonthDates.endDate &&
            invoice.documentKind === DunningBusinessDocumentKind.INVOICE &&
            !invoice.deleted &&
            invoice.completed &&
            invoice.isTracked,
  )

  /* console.log('notYetDueInvoices from calculateOutstandingNotYetDueDunningInvoicesForCurrentMonthAlongWithVariationToPreviousMonth', currentMonthOutstandingInvoices) */


  const currentMonthOutstandingAmount: number = currentMonthOutstandingInvoices.reduce((total: number, invoice: Invoice) => total + invoice.amountIncludingTaxes, 0)

  const previousMonth: Date = subMonths(dateInTimezone, 1)
  const previousMonthDates: { startDate: Date, endDate: Date } = getStartAndEndDates(previousMonth)

  const previousMonthOutstandingInvoices: Invoice[] = invoices.filter(
    (invoice: Invoice) =>
      new Date(invoice.dateDue) > previousMonthDates.endDate &&
            new Date(invoice.dateIssued) <= previousMonthDates.endDate &&
            invoice.documentKind === DunningBusinessDocumentKind.INVOICE &&
            !invoice.deleted &&
            invoice.completed &&
            invoice.isTracked,
  )

  const previousMonthOutstandingAmount: number = previousMonthOutstandingInvoices.reduce(
    (total: number, invoice: Invoice) => total + invoice.amountIncludingTaxes, 0)

  const rawVariationMonthToMonth: number = calculateVariationMonthToMonth(currentMonthOutstandingAmount, previousMonthOutstandingAmount)

  let variationMonthToMonthInPercentWithPositiveOrNegativeSign: number

  if (previousMonthOutstandingAmount === 0 && currentMonthOutstandingAmount === 0) {
    variationMonthToMonthInPercentWithPositiveOrNegativeSign = 0
  } else if (previousMonthOutstandingAmount === 0) {
    variationMonthToMonthInPercentWithPositiveOrNegativeSign = 100 // or "N/A" or "New"
  } else {
    variationMonthToMonthInPercentWithPositiveOrNegativeSign = rawVariationMonthToMonth / previousMonthOutstandingAmount * 100
  }

  const variationDirection: string = calculateVariationDirection(rawVariationMonthToMonth)
  const variationFavorability: string = calculateVariationFavorability(rawVariationMonthToMonth, AnalyticsElements.NOT_YET_DUE_INVOICES)

  /* console.log('currentMonthOutstandingAmount for currentMonthDates', currentMonthOutstandingAmount, currentMonthDates) */
  /* console.log('previousMonthOutstandingAmount for previousMonthDates', previousMonthOutstandingAmount, previousMonthDates) */

  return {
    currentMonthValue: currentMonthOutstandingAmount,
    previousMonthValue: previousMonthOutstandingAmount,
    monthToMonthVariationPercentage: variationMonthToMonthInPercentWithPositiveOrNegativeSign.toFixed(2) + '%',
    variationDirection: variationDirection,
    variationFavorability: variationFavorability
  }
}


/**
 * Calculate the overdue invoices for the current month
 * along with the variation to the previous month
 * uses getOverdueInvoicesExcludingUntrackedAndDeleted
 * @param invoices
 * @param computedDataInvoiceStore
 * @param currentMonth
 */
export function calculateOverdueDunningInvoicesForCurrentMonthAlongWithVariationToPreviousMonth(
  invoices: Invoice[],
  computedDataInvoiceStore: ComputedDataInvoice[],
  currentMonth: Date,
): ValueForCurrentMonthAlongWithVariationMonthToMonth {

  if (!(currentMonth instanceof Date)) {
    throw new Error('Invalid input: currentMonth should be a Date object')
  }

  const currentMonthOverdueInvoices: Invoice[] = invoices.filter(
    (invoice: Invoice): boolean => new Date(invoice.dateDue) <= currentMonth,
  )

  const currentMonthFilteredOverdueInvoices: Invoice[] = getOverdueInvoicesExcludingUntrackedAndDeleted(currentMonthOverdueInvoices, computedDataInvoiceStore)
  const currentMonthOverdueAmount: number = currentMonthFilteredOverdueInvoices.reduce(
    (total: number, invoice: Invoice) => total + invoice.amountIncludingTaxes, 0)

  const previousMonth: Date = subMonths(currentMonth, 1)

  const previousMonthOverdueInvoices: Invoice[] = invoices.filter(
    (invoice: Invoice): boolean => new Date(invoice.dateDue) <= previousMonth,
  )

  const previousMonthFilteredOverdueInvoices: Invoice[] = getOverdueInvoicesExcludingUntrackedAndDeleted(previousMonthOverdueInvoices, computedDataInvoiceStore)
  const previousMonthOverdueAmount: number = previousMonthFilteredOverdueInvoices.reduce(
    (total: number, invoice: Invoice) => total + invoice.amountIncludingTaxes, 0)

  const rawVariationMonthToMonth: number = calculateVariationMonthToMonth(currentMonthOverdueAmount, previousMonthOverdueAmount)

  let variationMonthToMonthInPercentWithPositiveOrNegativeSign: number
  if (previousMonthOverdueAmount === 0) {
    variationMonthToMonthInPercentWithPositiveOrNegativeSign = 0
  } else {
    variationMonthToMonthInPercentWithPositiveOrNegativeSign = rawVariationMonthToMonth / previousMonthOverdueAmount * 100
  }

  const variationDirection: string = calculateVariationDirection(rawVariationMonthToMonth)
  const variationFavorability: string = calculateVariationFavorability(rawVariationMonthToMonth, AnalyticsElements.OVERDUE_INVOICES)

  return {
    currentMonthValue: currentMonthOverdueAmount,
    previousMonthValue: previousMonthOverdueAmount,
    monthToMonthVariationPercentage: variationMonthToMonthInPercentWithPositiveOrNegativeSign.toFixed(2) + '%',
    variationDirection: variationDirection,
    variationFavorability: variationFavorability
  }
}


/**
 * Subtract months from a date
 * @param currentMonth
 * @param number
 */
export function subMonths(currentMonth: string | number | Date, number: unknown): Date {
  if (!(currentMonth instanceof Date) || typeof number !== 'number' || !Number.isInteger(number)) {
    throw new Error('Invalid input: currentMonth should be a Date object and number should be an integer')
  }

  const resultDate: Date = new Date(currentMonth)

  resultDate.setMonth(currentMonth.getMonth() - number)

  // Check if the month has been correctly subtracted, and handle edge cases
  if (resultDate.getMonth() === (currentMonth.getMonth() - number + 12) % 12) {
    return resultDate
  }

  // Adjust for cases when the target month has fewer days than the original month
  resultDate.setDate(0) // Set the date to the last day of the previous month

  return resultDate
}

/**
 * Calculate the variation between the current month amount and the previous month amount
 * Accounts for negative values
 * @param currentMonthAmount
 * @param previousMonthAmount
 */
export function calculateVariationMonthToMonth(currentMonthAmount: number | null | undefined, previousMonthAmount: number | null | undefined): number | null {
  // Check for null or undefined values and return null if either argument is invalid
  if (currentMonthAmount === null || currentMonthAmount === undefined || previousMonthAmount === null || previousMonthAmount === undefined) {
    return null
  }

  // Ensure both arguments are finite numbers
  if (!isFinite(currentMonthAmount) || !isFinite(previousMonthAmount)) {
    throw new Error('Input values must be finite numbers')
  }

  // Calculate the variation
  const variation: number = currentMonthAmount - previousMonthAmount

  // Check for potential floating point precision issues and return the corrected result
  return Math.abs(variation) < Number.EPSILON ? 0 : variation
}

export /**
 * Check if a date is in the same month as the current month
 * @param date
 * @param currentMonth
 */
function isSameMonth(date: string, currentMonth: Date): boolean {
  if (typeof date !== 'string' || !(currentMonth instanceof Date)) {
    throw new Error('Invalid input: date should be a string and currentMonth should be a Date object')
  }

  const parsedDate: Date = new Date(date)

  // Check if the parsed date is a valid date
  if (isNaN(parsedDate.getTime())) {
    throw new Error('Invalid date string')
  }

  // Compare the month and year to ensure the same month
  return parsedDate.getMonth() === currentMonth.getMonth() && parsedDate.getFullYear() === currentMonth.getFullYear()
}


/**
 * Calculate the raw variation direction (up, down, or neutral)
 * @param variation
 */
export function calculateVariationDirection(variation: number): string {
  if (variation === 0) {
    return 'neutral'
  }

  return variation > 0 ? 'up' : 'down'
}


/**
 * Calculate the favorability variation direction (favorable or unfavorable) based on the nature of the calculation
 * @param variation
 * @param nature
 */
export function calculateVariationFavorability(variation: number, nature: CalculationNature): string {
  if (variation === 0) {
    return 'neutral'
  }

  switch (nature) {
    case AnalyticsElements.REVENUE:
      return variation > 0 ? 'favorable' : 'unfavorable'
    case AnalyticsElements.EXPENSES:
    case AnalyticsElements.CASHBURN:
      return variation > 0 ? 'unfavorable' : 'favorable'
    case AnalyticsElements.OVERDUE_INVOICES:
      return variation > 0 ? 'unfavorable' : 'favorable'
    case AnalyticsElements.NOT_YET_DUE_INVOICES:
      return variation > 0 ? 'favorable' : 'unfavorable'
    default:
      throw new Error('Invalid nature: should be either "revenue", "expenses", or "cashburn"')
  }
}

/**
 * Helper function to extract start and end dates for a month
 * @param date
 */
export function getStartAndEndDates(date: Date): { startDate: Date, endDate: Date } {
  const month: number = date.getMonth()
  const year: number = date.getFullYear()

  return {
    startDate: new Date(year, month, 1),
    endDate: new Date(year, month + 1, 0)
  }
}

import type { allSelectionsAndFilter, AppliedDunningFilters } from '../models/applied-dunning-filters'
import type Invoice from '../models/invoice'
import { DunningViewListOption } from '../enums/dunning-view-list-options'
import { get } from 'svelte/store'
import { DunningInvoicesStore } from '../stores/dunning-invoices.store'
import { DunningBusinessDocumentKind } from '../enums/dunning-business-document-kind'
import { InvoiceStatus } from '../enums/invoice-status'
import { DunningInvoicesHelper } from '../helpers/dunning-invoices-helper'
import { t } from '$core/lib/i18n/i18nextWrapper'
import { reduceInvoicesSumOfAmount } from './list-reduce'
import type UIDunningCounts from '../models/dunning-ui-updated-counts'
import { DocumentSourceKind } from '$src/order-to-cash-lib/models/document-source-kind'


class DunningFilterServiceClass {

  /** Collateral calculus
     *  This function is called when the list of dunning invoices is updated, and when the search value is updated.
     *  @param searchValue
     * **/
  updateCollateralCalculus(searchValue: string): UIDunningCounts {
    const invoicesCurrency: string = get(DunningInvoicesStore).find((invoice: Invoice) => (invoice.currency))?.currency || 'EUR'
    let invoiceFormatter: Intl.NumberFormat = new Intl.NumberFormat(t('locales'), {
      style: 'currency',
      currency: invoicesCurrency
    })

    const trackingInvoices: Invoice[] = dunningFilterService.filterDunningInvoicesFromStore(DunningViewListOption.TRACKING, searchValue)
    const pausedInvoices: Invoice[] = dunningFilterService.filterDunningInvoicesFromStore(DunningViewListOption.PAUSED, searchValue)
    const paidInvoices: Invoice[] = dunningFilterService.filterDunningInvoicesFromStore(DunningViewListOption.PAID, searchValue)
    const draftInvoices: Invoice[] = dunningFilterService.filterDunningInvoicesFromStore(DunningViewListOption.DRAFT, searchValue)

    const trackingInvoicesAmountNormalized: string =
            invoiceFormatter.format(reduceInvoicesSumOfAmount(trackingInvoices, 'amountIncludingTaxes') || 0)
    const pausedInvoicesAmountNormalized: string =
            invoiceFormatter.format(reduceInvoicesSumOfAmount(pausedInvoices, 'amountIncludingTaxes') || 0)
    const paidInvoicesAmountNormalized: string =
            invoiceFormatter.format(reduceInvoicesSumOfAmount(paidInvoices, 'amountIncludingTaxes') || 0)
    const draftInvoicesAmountNormalized: string =
            invoiceFormatter.format(reduceInvoicesSumOfAmount(draftInvoices, 'amountIncludingTaxes') || 0)

    const numberOfTrackingInvoices: number = trackingInvoices.length
    const numberOfPausedInvoices: number = pausedInvoices.length
    const numberOfPaidInvoices: number = paidInvoices.length
    const numberOfDraftInvoices: number = draftInvoices.length

    return <UIDunningCounts> {
      trackingInvoicesAmountNormalized,
      pausedInvoicesAmountNormalized,
      paidInvoicesAmountNormalized,
      draftInvoicesAmountNormalized,
      numberOfTrackingInvoices,
      numberOfPausedInvoices,
      numberOfPaidInvoices,
      numberOfDraftInvoices
    }
  }
  /**
     * Filter the invoices from the store
     * @param listViewChosenOption
     * @param searchValue
     */
  filterDunningInvoicesFromStore(listViewChosenOption: DunningViewListOption, searchValue: string): Invoice[] {
    const sourceList: Invoice[] = get(DunningInvoicesStore)
    if (!sourceList) return []
    
    switch (listViewChosenOption) {
      case DunningViewListOption.TRACKING:
        return sourceList.filter((invoice: Invoice) =>
          (invoice.documentKind === DunningBusinessDocumentKind.INVOICE)
                    && (invoice.status === InvoiceStatus.OUTSTANDING)
                    && (invoice.completed)
                    && (invoice.isTracked)
                    && (!invoice.deleted)
                    && (!searchValue || DunningInvoicesHelper.isInvoiceContainingText(invoice, searchValue)))

      case DunningViewListOption.PAUSED:
        return sourceList.filter((invoice: Invoice) =>
          (invoice.documentKind === DunningBusinessDocumentKind.INVOICE)
                    && (invoice.status === InvoiceStatus.OUTSTANDING)
                    && (invoice.completed)
                    && (!invoice.isTracked)
                    && (!invoice.deleted)
                    && (!searchValue || DunningInvoicesHelper.isInvoiceContainingText(invoice, searchValue)))

      case DunningViewListOption.DRAFT:
        return sourceList.filter((invoice: Invoice) =>
          (invoice.documentKind === DunningBusinessDocumentKind.INVOICE)
                    && (invoice.documentSource.sourceKind === DocumentSourceKind.FROMDUNDYDUNNINGMANUALLY)
                    && (!invoice.completed)
                    && (!invoice.isTracked)
                    && (!invoice.deleted)
                    && (!searchValue || DunningInvoicesHelper.isInvoiceContainingText(invoice, searchValue)))

      case DunningViewListOption.DELETED:
        return sourceList.filter((invoice: Invoice) =>
          (invoice.deleted)
                    && (!searchValue || DunningInvoicesHelper.isInvoiceContainingText(invoice, searchValue)))

      case DunningViewListOption.PAID:
        return sourceList.filter((invoice: Invoice) =>
          (invoice.documentKind === DunningBusinessDocumentKind.INVOICE)
                    && (invoice.status === InvoiceStatus.PAID)
                    && (!invoice.deleted)
                    && (!searchValue || DunningInvoicesHelper.isInvoiceContainingText(invoice, searchValue)))
      
      default: return []
    }
  }
  /**
     * Get the invoices filter corresponding to the user selected filters in the dunning filters
     * @param appliedFilters
     */
  getInvoicesFilterCorrespondingToUserSelectedFiltersInDunningFilters(appliedFilters: AppliedDunningFilters): Array<(Invoice) => boolean> {
    const allSelectionsAndFilters: allSelectionsAndFilter[] = [
      {
        isSelected: appliedFilters.checkboxFilterMoreThan90TillDue.value,
        filterFunction: ((invoice): boolean => invoice.dateDue > new Date(new Date().setDate(new Date().getDate() + 91)).toISOString().slice(0, 10))
      },
      {
        isSelected: appliedFilters.checkboxFilterBetween61And90DaysTillDue.value,
        filterFunction: ((invoice) => invoice.dateDue > new Date(new Date().setDate(new Date().getDate() + 61)).toISOString().slice(0, 10) && invoice.dateDue < new Date(new Date().setDate(new Date().getDate() + 90)).toISOString().slice(0, 10))
      },
      {
        isSelected: appliedFilters.checkboxFilterBetween31And60DaysTillDue.value,
        filterFunction: ((invoice) => invoice.dateDue > new Date(new Date().setDate(new Date().getDate() + 31)).toISOString().slice(0, 10) && invoice.dateDue < new Date(new Date().setDate(new Date().getDate() + 60)).toISOString().slice(0, 10))
      },
      {
        isSelected: appliedFilters.checkboxFilterBetween0And30DaysTillDue.value,
        filterFunction: ((invoice) => invoice.dateDue > new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10) && invoice.dateDue < new Date(new Date().setDate(new Date().getDate() + 30)).toISOString().slice(0, 10))
      },
      {
        isSelected: appliedFilters.checkboxFilterBetween1And30DaysOverdue.value,
        filterFunction: ((invoice) => invoice.dateDue < new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10) && invoice.dateDue > new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().slice(0, 10))
      },
      {
        isSelected: appliedFilters.checkboxFilterBetween31And60DaysOverdue.value,
        filterFunction: ((invoice) => invoice.dateDue < new Date(new Date().setDate(new Date().getDate() - 31)).toISOString().slice(0, 10) && invoice.dateDue > new Date(new Date().setDate(new Date().getDate() - 60)).toISOString().slice(0, 10))
      },
      {
        isSelected: appliedFilters.checkboxFilterBetween61And90DaysOverdue.value,
        filterFunction: ((invoice) => invoice.dateDue < new Date(new Date().setDate(new Date().getDate() - 61)).toISOString().slice(0, 10) && invoice.dateDue > new Date(new Date().setDate(new Date().getDate() - 90)).toISOString().slice(0, 10))
      },
      {
        isSelected: appliedFilters.checkboxFilterMoreThan90DaysOverdue.value,
        filterFunction: ((invoice: Invoice): boolean => invoice.dateDue < new Date(new Date().setDate(new Date().getDate() - 90)).toISOString().slice(0, 10))
      },
      {
        isSelected: appliedFilters.checkboxFilterAmountLessThan500.value,
        filterFunction: ((invoice): boolean => invoice.amountIncludingTaxes < 500)
      },
      {
        isSelected: appliedFilters.checkboxFilterAmountBetween500And5000.value,
        filterFunction: ((invoice) => invoice.amountIncludingTaxes > 500 && invoice.amountIncludingTaxes < 5000)
      },
      {
        isSelected: appliedFilters.checkboxFilterAmountBetween5000And20000.value,
        filterFunction: ((invoice) => invoice.amountIncludingTaxes > 5000 && invoice.amountIncludingTaxes < 20000)
      },
      {
        isSelected: appliedFilters.checkboxFilterAmountBetween20000And75000.value,
        filterFunction: ((invoice) => invoice.amountIncludingTaxes > 20000 && invoice.amountIncludingTaxes < 75000)
      },
      {
        isSelected: appliedFilters.checkboxFilterAmountBetween75000And150000.value,
        filterFunction: ((invoice) => invoice.amountIncludingTaxes > 75000 && invoice.amountIncludingTaxes < 150000)
      },
      {
        isSelected: appliedFilters.checkboxFilterAmountMoreThan150000.value,
        filterFunction: ((invoice): boolean => invoice.amountIncludingTaxes > 150000)
      }
    ]
    const activeSelectionsAndFilters: allSelectionsAndFilter[] = allSelectionsAndFilters.filter((aSelectionAndFilter: allSelectionsAndFilter) => aSelectionAndFilter.isSelected)
    const combinedUserSelectedFilters: ((arg0: any) => boolean)[] = activeSelectionsAndFilters.map((aSelectionAndFilter: allSelectionsAndFilter) => aSelectionAndFilter.filterFunction)
    if (combinedUserSelectedFilters.length > 0) {
      return combinedUserSelectedFilters
    } else {
      const everythingFilter = (): boolean => true
      
      return [everythingFilter] // if no filter is selected, we consider that the user wants everything and not nothing
    }
  }
}
export const dunningFilterService: DunningFilterServiceClass = new DunningFilterServiceClass()
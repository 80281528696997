<script lang="ts">
  import type { Customer } from '../../models/customer'
  import { get as getStore } from 'svelte/store'
  import { CustomersStore } from '../../stores/customers.store'
  import { navigate } from 'svelte-routing'
  import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
  import { DunningInvoicesStore } from '../../../dundy-app/stores/dunning-invoices.store'
  import { InvoiceStatus } from '../../../dundy-app/enums/invoice-status'
  import { reduceInvoicesSumOfAmount } from '../../../dundy-app/services/list-reduce'
  import { byPropertiesOf } from '../../../core-app/util/array-utils'
  import { TodosStore } from '../../../dundy-app/stores/todos.store'
  import type Invoice from '../../../dundy-app/models/invoice'
  import { onMount } from 'svelte'
  import type { Contact } from '../../models/contact'
  import { ContactsStore } from '../../stores/contacts.store'
  import type PaginatedDisplayListConfig from '../../../core-app/lib/ui-kit/paginated-list/PaginatedListConfig'
  import type { TodoListItem } from '../../../dundy-app/models/todo-list-item'
  import PaginatedDisplayList from '../../../core-app/lib/ui-kit/paginated-list/PaginatedDisplayList.svelte'
  import DisplayCustomerAmounts from '../../lib/customer/DisplayCustomerAmounts.svelte'
  import { formatCurrencyWithOptions } from '../../../voxy-app/utils/number-formatting-utils.js'
  import { getUserCountryCode } from '../../../core-app/services/countries-pure-functions.js'
  import { getDefaultCurrencyAccordingToCountry } from '../../../core-app/services/currency-list.js'
  import { DunningBusinessDocumentKind } from '../../../dundy-app/enums/dunning-business-document-kind'
  import { CustomerOverviewActiveTab } from '../../enums/customer-overview-active-tab'
  import { getCustomerInvoicesForTab } from '../../lib/customer/ui-customer-overview-tab-selection'
  import CustomerBreadCrumb from '../../lib/customer/CustomerBreadCrumb.svelte'


  /** Export declarations **/
  export let customerId: string = null
  export let activeTab: CustomerOverviewActiveTab = CustomerOverviewActiveTab.Overdue

  /** Local declarations **/
  let customer: Customer = getStore(CustomersStore).find((c) => c.company.companyId === customerId)
  let invoices: Invoice[] = $DunningInvoicesStore.filter(invoice => invoice.toId === customer?.company?.companyId).sort(byPropertiesOf<Invoice>(['dateDue']))
  const outstandingInvoices: Array<Invoice> = invoices.filter((invoice: Invoice) =>
    invoice.status === InvoiceStatus.OUTSTANDING
    && invoice.documentKind === DunningBusinessDocumentKind.INVOICE
    && !invoice.deleted
    && invoice.completed)
  let dueInvoices: Invoice[] = outstandingInvoices.filter(invoice => new Date(invoice.dateDue) > new Date() && invoice.isTracked)
  let overdueInvoices: Invoice[] = outstandingInvoices.filter((invoice: Invoice) => new Date(invoice.dateDue) < new Date() && invoice.isTracked)
  let paidInvoices: Invoice[] = invoices.filter(invoice => invoice.status === InvoiceStatus.PAID).sort(byPropertiesOf<Invoice>(['modified']))
  let untrackedInvoices: Invoice[] = outstandingInvoices.filter(invoice => !invoice.isTracked)
  let outstandingAmount: number = reduceInvoicesSumOfAmount(outstandingInvoices, 'amountIncludingTaxes')
  let customerContacts: Contact[]
  let todos: TodoListItem[] = $TodosStore ? $TodosStore.filter(t => t.customerId === customerId) : []
  let mostRecentDoneTodoForCustomer: TodoListItem = todos.find(todo => todo.customerId === customer.company.companyId && todo.isDone)


  /** Reactive declarations **/
  $: customerContacts = $ContactsStore.filter(c => c.companyId === customer.company?.companyId)
  $: contacts = customer?.isPrivateIndividual ? [$ContactsStore.find(contact => contact.companyId === customer.company.companyId)] : $ContactsStore.filter(contact => contact.companyId === customer.company.companyId)

  const listConfig: PaginatedDisplayListConfig = <PaginatedDisplayListConfig>{
    columns: [{
      dataKey: 'invoice',
      label: t('customerOverview.invoiceNumber'),
      type: 'invoice-number',
      classes: ''
    }, {
      dataKey: 'invoice',
      label: t('customerOverview.dueDate'),
      type: 'due-date',
      classes: ''
    }, {
      dataKey: 'invoice',
      label: t('customerOverview.amount'),
      type: 'invoice-amount',
      componentClasses: 'text-sm',
      classes: ''
    }, {
      dataKey: 'todo',
      label: t('customerOverview.dunningStep'),
      type: 'dunning-step',
      classes: ''
    }, {
      dataKey: 'todo',
      label: t('customerOverview.status'),
      type: 'status-label',
      classes: ''
    }],
    data: [],
    pageSize: 5
  }


  const getContactInitials = (contact:Contact | undefined) => {
    if (!contact || contact.firstName || contact.lastName) return ''
    
    return contact?.firstName[0].toUpperCase() + ' ' + contact?.lastName[0].toUpperCase()
  }

  const getContactFullName = (contact:Contact | undefined) => {
    if (!contact || contact.firstName || contact.lastName) return ''
    
    return contact?.firstName + ' ' + contact?.lastName
  }

  /***
   * UI Function to select the tab
   * @param tab
   */
  function selectTab(tab: CustomerOverviewActiveTab) {
    activeTab = tab
    const invoices: Invoice[] = getCustomerInvoicesForTab(tab, overdueInvoices, dueInvoices, paidInvoices, untrackedInvoices)
    populateListData(invoices)
  }

  const populateListData = (invoicesDisplayed: Invoice[]) => {
    if (invoicesDisplayed) {
      const data = []
      for (const invoice of invoicesDisplayed) {
        const todo: TodoListItem = $TodosStore.find(todo => !!todo.scopeInvoiceNumbers.find(invoiceNumber => invoice.invoiceNumber === invoiceNumber))
        data.push({
          invoice,
          todo
        })
      }
      listConfig.data = data
    }
  }

  const onClickAddContact = () => {
    navigate(`/clients/${customer.company.companyId}/contacts/new?from=card`)
  }

  const onContactSelect = (contact:Contact | undefined) => {
    if (!contact) return 
    
    // customer is a company
    if (!customer.isPrivateIndividual) {
      navigate(`/clients/${customer.company.companyId}/contacts/${contact.contactId}/edit?from=card`)
    } else {
      // customer is private individual
      if (contact.email) window.location.href = `mailto:${contact.email}`
    }
  }

  onMount(() => {
    populateListData(overdueInvoices)
  })
</script>
<svelte:head>
    <title>{customer?.company.formalName} - Dundy</title>
</svelte:head>
<main class="container mx-auto flex flex-col">
    <CustomerBreadCrumb {customer}/>

    <section class="flex justify-between align-middle items-center">
        <div class="w-full bg-whisper border border-athensGray relative p-6 rounded">
            <div class="flex w-full justify-between">
                <div class="w-3/12"></div>
                <div class="w-6/12">
                    <div class="flex mx-auto -m-12 mb-4 items-center align-middle justify-center bg-black w-16 h-16 p-3 rounded-lg font-bold text-3xl text-white shadow-sm">
                        {customer?.company.formalName.substring(0, 1).toUpperCase()}
                    </div>
                </div>
                <div class="w-3/12">
                </div>
            </div>
            <div class="flex flex-col md:flex-row w-full md:justify-between md:items-center">
                <div class="w-full md:w-3/12"></div>
                <div class="w-full md:w-6/12">
                    <h3 class="font-medium text-3xl text-center">{customer?.company.formalName}</h3>
                </div>
                <div class="w-full md:w-3/12 flex flex-col">
                    <span
                            class="btn action-default cursor-pointer mt-3 md:mt-0 md:float-right px-3 relative mb-2"
                            on:click={() => navigate(`/clients/${customer?.company.companyId}/edit?from=card`)}
                            on:keydown={(e) => {
                              if (e.key === 'Enter') {
                                navigate(`/clients/${customer?.company.companyId}/edit?from=card`)
                              }
                            }}
                            role="button"
                            tabindex="0"
                    >
                        <span>{t('customerOverview.edit')}</span>
                    </span>

                    {#if !customer.isPrivateIndividual}
                      <span class="btn action-default cursor-pointer mt-3 md:mt-0 md:float-right px-3 relative"
                            on:click={() => {
                              navigate(`/clients/${customer.company.companyId}/contacts/new?from=card`)
                            }}
                            on:keydown={(e) => {
                              if (e.key === 'Enter') {
                                navigate(`/clients/${customer.company.companyId}/contacts/new?from=card`)
                              }
                            }}
                            role="button"
                            tabindex="0"
                      >
                          <span>{t('customerOverview.addContact')}</span>
                      </span>
                    {/if}
                </div>
            </div>

            <hr class="w-10-12 align-center mx-auto border-athensGray mt-8"/>

            <div class="flex flex-col md:flex-row w-full md:justify-between md:items-center pt-4 space-y-5 md:space-y-0">
                <div class="w-full md:w-3/12 text-center">
                    <h4 class="text-baliHai text-s">{t('todo.account_position.awaitingPayment')}</h4>
                    <p class="font-medium text-2xl">
                        {formatCurrencyWithOptions(outstandingAmount, t('locales'), getDefaultCurrencyAccordingToCountry(getUserCountryCode()), true, 'symbol')}
                    </p>

                </div>
                <div class="w-full md:w-6/12 text-center">
                    <DisplayCustomerAmounts
                            classes="m-auto"
                            {customer}/>
                </div>
                {#if mostRecentDoneTodoForCustomer}
                    <div class="w-full md:w-3/12 text-center">
                        <h4 class="text-baliHai text-s">{t('customerOverview.latestContact')}</h4>
                        <!--<p class="font-medium text-2xl">{formatTimeAgo(new Date(mostRecentDoneTodoForCustomer.dateDoneRFC3339), t('locales'))}</p>-->
                        <p class="font-medium text-2xl">{new Intl.DateTimeFormat(t('locales'), {}).format(new Date(mostRecentDoneTodoForCustomer.dateDoneRFC3339))}</p>
                    </div>
                {:else}
                    <div class="w-full md:w-3/12 text-center">
                    </div>
                {/if}
            </div>
        </div>
    </section>

    <section class="flex flex-row items-top mt-6 space-x-12">
        <div class="w-full lg:w-9/12">
            <div>
                <div class="sm:hidden">
                    <label class="sr-only" for="tabs">{t('customerOverview.selectTab')}</label>
                    <!-- TODO // Use an "on:change" listener to redirect the user to the selected tab URL. -->
                    <select class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            id="tabs"
                            name="tabs">
                        <option>{t('customerOverview.overdue')} ({overdueInvoices.length})</option>
                        <option>{t('customerOverview.onTime')} ({dueInvoices.length})</option>
                        <option selected>{t('customerOverview.paid', { count: paidInvoices.length })}
                            ({paidInvoices.length})
                        </option>

                    </select>
                </div>
                <div class="hidden sm:block">
                    <div class="border-b border-gray-200">
                        <nav aria-label="Tabs" class="-mb-px flex space-x-8">
                            <button
                                    class="tab-link"
                                    class:active={activeTab === CustomerOverviewActiveTab.Overdue}
                                    class:inactive={overdueInvoices.length === 0}
                                    on:click={() => {
                                      if (overdueInvoices.length !== 0) {
                                        selectTab(CustomerOverviewActiveTab.Overdue)
                                      }
                                    }}
                                    on:keydown={(e) => {
                                      if (e.key === 'Enter' && overdueInvoices.length !== 0) {
                                        selectTab(CustomerOverviewActiveTab.Overdue)
                                      }
                                    }}
                            >
                                {t('customerOverview.overdue')}
                                <span class="tab-link-badge">{overdueInvoices.length}</span>
                            </button>

                            <button
                                    class="tab-link"
                                    class:active={activeTab === CustomerOverviewActiveTab.Due}
                                    class:inactive={dueInvoices.length === 0}
                                    on:click={() => {
                                      if (dueInvoices.length !== 0) {
                                        selectTab(CustomerOverviewActiveTab.Due)
                                      }
                                    }}
                                    on:keydown={(e) => {
                                      if (e.key === 'Enter' && dueInvoices.length !== 0) {
                                        selectTab(CustomerOverviewActiveTab.Due)
                                      }
                                    }}
                            >
                                {t('customerOverview.onTime')}
                                <span class="tab-link-badge">{dueInvoices.length}</span>
                            </button>

                            <button
                                    class="tab-link"
                                    class:active={activeTab === CustomerOverviewActiveTab.Paid}
                                    class:inactive={paidInvoices.length === 0}
                                    on:click={() => {
                                      if (paidInvoices.length !== 0) {
                                        selectTab(CustomerOverviewActiveTab.Paid)
                                      }
                                    }}
                                    on:keydown={(e) => {
                                      if (e.key === 'Enter' && paidInvoices.length !== 0) {
                                        selectTab(CustomerOverviewActiveTab.Paid)
                                      }
                                    }}
                            >
                                {t('customerOverview.paid', { count: paidInvoices.length })}
                                <span class="tab-link-badge">{paidInvoices.length}</span>
                            </button>
                            <button
                                    class="tab-link"
                                    class:active={activeTab === CustomerOverviewActiveTab.Untracked}
                                    class:inactive={untrackedInvoices.length === 0}
                                    on:click={() => {
                                      if (untrackedInvoices.length !== 0) {
                                        selectTab(CustomerOverviewActiveTab.Untracked)
                                      }
                                    }
                                    }
                                    on:keydown={(e) => {
                                      if (e.key === 'Enter' && untrackedInvoices.length !== 0) {
                                        selectTab(CustomerOverviewActiveTab.Untracked)
                                      }
                                    }}
                            >
                                {t('customerOverview.untracked', { count: untrackedInvoices.length })}
                                <span class="tab-link-badge">{untrackedInvoices.length}</span>
                            </button>
                        </nav>
                    </div>
                </div>
            </div>

            <PaginatedDisplayList config={listConfig}
                                  on:selectRow={e => e.detail.todo ? navigate(`/action/${e.detail.todo.workspaceId}/${e.detail.todo.customerId}/${e.detail.invoice.dataId}/overview`) : navigate(`/receivables/external/view/${e.detail.invoice.dataId}/details`)}/>

        </div>
        <div class="hidden lg:block lg:w-3/12 lg:mt-12">
            <aside class="bg-white rounded-lg border border-athensGray pb-4">
                <!-- // to Keep: future component for latest contact when dunning is coalesced by client
                <h3 class="font-bold px-5 mt-5 mb-4">{t('customerOverview.upcomingAction')}</h3>
                <div class="activity-card-wrapper px-5">
                    <div class="flex bg-white shadow-sm items-center cursor-pointer">
                        <div class="w-3 h-3 bg-dundyOrange border border-fieryOrange absolute transform rounded-full z-10 mt-2 md:mt-0"></div>
                        <div class="ml-6 flex-auto">
                            <h4 class="text-sm font-medium">Courrier postal à envoyer</h4>
                            <p class="text-s text-dundyOrange">Effectuer cette action au plus vite</p>
                        </div>
                        <div class="flex none w-8 right-0 text-right justify-end">
                            <i class="w-8 h-8 flex justify-end align-middle items-center text-center icon-chevron-right font-bold text-3xl"/>
                        </div>
                    </div>
                </div>
                <hr class="w-full border-1 border-athensGray my-4"/>-->

                <div class="flex justify-between items-center px-5 my-4">
                    <h3 class="font-bold">{t('customerOverview.contactLabel', { count: customerContacts.length })} <span
                            class="rounded-full py-1 px-2 text-xs font-medium ring-1 ring-inset text-zinc-700 bg-zinc-100 ring-zinc-400/20">{contacts?.length ?? 0}</span>
                    </h3>
                    {#if !customer.isPrivateIndividual}
                      <!-- svelte-ignore a11y-click-events-have-key-events -->
                      <div
                              class="flex none w-6 right-0 text-right justify-end cursor-pointer"
                              on:click|preventDefault|stopPropagation={() => onClickAddContact()}
                              role="button"
                              tabindex="0"
                      >
                          <svg class="w-6 h-6 flex justify-end align-middle items-center text-center font-bold text-2xl"
                              fill="none" viewBox="0 0 24 24">
                              <path d="M17 14.75V19.25M11.25 19.25H5.78165C5.21714 19.25 4.77296 18.7817 4.88545 18.2285C5.19601 16.7012 6.21027 14 9.49996 14C10.1744 14 10.7532 14.0563 11.25 14.25M19.25 17H14.75M14.75 10.25C16.2687 10.25 17.25 9.01878 17.25 7.5C17.25 5.98122 16.2687 4.75 14.75 4.75M12.25 7.5C12.25 9.01878 11.0187 10.25 9.49996 10.25C7.98118 10.25 6.74996 9.01878 6.74996 7.5C6.74996 5.98122 7.98118 4.75 9.49996 4.75C11.0187 4.75 12.25 5.98122 12.25 7.5Z"
                                    stroke="currentColor" stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"></path>
                          </svg>

                      </div>
                    {/if}
                </div>

                {#if contacts && contacts.length > 0}
                  <div>
                    {#each contacts as contact}
                      <div class="flex items-center px-5 py-2 hover:bg-whisper">
                        <button class="leading-8 text-center size-8 bg-dundyOrange rounded-full text-white text-xs mr-2"
                          on:click|preventDefault|stopPropagation={() => onContactSelect(contact)}>
                          {getContactInitials(contact)}
                        </button>
                        <div class="flex flex-col items-start">
                          <button class="text-sm font-medium "
                            on:click|preventDefault|stopPropagation={() => onContactSelect(contact)}>
                            {getContactFullName(contact)}
                          </button>
                          <a href="{`mailto:${contact?.email}`}" class="text-xxs text-dundyOrange">{contact?.email || ''}</a>
                        </div>
                      </div>
                    {/each}
                  </div>
                {:else}
                  <div class="w-10/12 mx-auto mt-2">
                  {#if !customer.isPrivateIndividual}
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <span
                      class="btn action-default py-2 px-4 w-full cursor-pointer whitespace-pre-line"
                      on:click={() => onClickAddContact()}
                      role="button" tabindex="0"
                    >
                      <i class="icon-plus add-new-client-icon"></i>
                      {t('clients.addContact')}
                    </span>
                  {/if}
                  </div>
                {/if}
            </aside>
        </div>
    </section>

</main>

<script lang="ts">
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import { createEventDispatcher, onDestroy, onMount } from 'svelte'
    import { filedrop } from 'filedrop-svelte'
    import { bytesToHighestUnitDisplay } from '../../../core-app/util/file-utils'
    import type { Files } from 'filedrop-svelte'
    import { eventsManager } from '../../../core-app/events/event-manager'
    import { EventType } from '../../../core-app/events/event-type'
    import { WorkspaceStore } from '../../../crm-app/stores/workspace.store'
    import { navigate } from 'svelte-routing'
    import StorageBucketObject from '../../../file-mgt-domain/models/storage-bucket-object'
    import { get } from 'svelte/store'
    import { businessDocumentAttachmentDownload } from '../../services/pdf-file-mgt/business-document-pdf-attachment.api'
    import { pdfAttachmentUpload } from '../../../file-mgt-domain/services/pdf-attachment-upload.api'

    /** Exported Let **/
    export let businessDocumentId: string
    export let businessDocumentNumber: string
    export let currentInvoiceAttachment: StorageBucketObject = {} as StorageBucketObject

    /** Let declarations **/
    let files: Files
    let uploadProgress: number = 0
    let progressBarPercentage: number = 0
    let progressBarPercentageCalculationInterval: any = null

    const dispatch = createEventDispatcher()

    const handleFileUpload = () => {
      eventsManager.on<number>(EventType.FILE_UPLOAD_PROGRESS_CHANGED, e => {
        uploadProgress = e.data
      }, 'DundyInvoicePDFUploadArea.svelte')
      const isBusinessDocumentFinalized: boolean = (!!businessDocumentNumber && (businessDocumentNumber !== ''))
      const firstSelectedFile: File = files.accepted[0]
      pdfAttachmentUpload(businessDocumentNumber, businessDocumentId, get(WorkspaceStore).workspaceId, isBusinessDocumentFinalized, firstSelectedFile)
        .then((result) => {
          /* console.log('File upload result', result) */
          setTimeout(() => {
            dispatch('uploaded')
            businessDocumentAttachmentDownload(businessDocumentNumber, businessDocumentId, get(WorkspaceStore).workspaceId).then((invoiceAttachment: StorageBucketObject) => {
              currentInvoiceAttachment = invoiceAttachment
            }).then(() => {
              /* console.log('request to update all when uploaded invoice PDF file') */
              eventsManager.emit(EventType.REQUEST_INVOICE_LIST_AND_COMPUTED_INVOICE_LIST_TO_REFRESH, null,
                'DundyInvoicePDFUploadArea.svelte')
            })
          }, 2000)
        })
        .catch((reason) => {
          console.error('err in BusinessDocumentPDFUploadArea.svelte pdfAttachmentUpload', reason)
          progressBarPercentage = 0
          uploadProgress = 0
        })
    }

    const newUpload = () => {
      currentInvoiceAttachment = null
      progressBarPercentage = 0
      uploadProgress = 0
    }

    onMount(() => {
      let intervalTime = 500 // Set the initial interval time
      progressBarPercentageCalculationInterval = setInterval(() => {
        const totalProgress = 100 // Set the total progress to 100%
        const newProgressBarPercentage = Math.round((uploadProgress / totalProgress) * 100)

        // Slow down the progress bar towards the end
        const remainingProgress = totalProgress - newProgressBarPercentage
        if (remainingProgress <= 20) {
          intervalTime = 1000
        }
        progressBarPercentage = newProgressBarPercentage
      }, intervalTime)
    })

    onDestroy(() => {
      clearInterval(progressBarPercentageCalculationInterval)
    })
</script>

{#if !currentInvoiceAttachment}
    <div on:filedrop={(e) => {files = e.detail.files; handleFileUpload()}}
         use:filedrop={{ fileLimit: 1, maxSize: 10000000, accept: 'application/pdf' }}
         data-cy="invoice-upload-area"
         class="flex w-full align-center justify-center hover:border-mojo cursor-pointer border border-athensGray rounded p-8 bg-white mt-6">

        {#if !uploadProgress}
            <div class="w-full justify-self-center text-center">
                <span class="font-bold">{t('invoices.uploadInvoice.dragAndDrop')} <span
                        class="text-dundyOrange">{t('invoices.uploadInvoice.invoice')}</span></span><br/>
                <span class="text-sm">{t('invoices.uploadInvoice.or')} <span
                        class="text-dundyOrange underline">{t('invoices.uploadInvoice.browseFiles')}</span> {t('invoices.uploadInvoice.onYourComputer')}</span>
            </div>
        {/if}
        {#if uploadProgress}
            <div class="w-full justify-self-center text-center">
                <span class="font-bold">{t('invoices.uploadInvoice.uploadingInvoice')}</span><br/>
                <span class="font-bold text-dundyOrange mb-2">{progressBarPercentage}%</span><br/>
                <div class="rounded-lg border border-loblolly bg-whisper h-3 mt-2" style="width: 100%"></div>
                <div class="rounded border border-hotCinnamon bg-dundyOrange h-3 -mt-3"
                     style="width: {progressBarPercentage}%"></div>
            </div>
        {/if}
        {#if files && files.rejected && files.rejected.length}
            {#if files.rejected[0].error.code === 2 || files.rejected[0].error.code === 3}
                <span class="text-mojo">{t('invoices.uploadInvoice.errors.maxSize')}</span>
            {:else if files.rejected[0].error.code === 0}
                <span class="text-mojo">{t('invoices.uploadInvoice.errors.accept')}</span>
            {:else}
                <span class="text-mojo">{files.rejected[0].file.name} - {files.rejected[0].error.message}</span>
            {/if}
        {/if}
    </div>
{/if}

{#if !!currentInvoiceAttachment}
    <div
            class="flex justify-between w-full items-center hover:border-mojo cursor-pointer border-athensGray border rounded p-8 bg-white mt-6"
            on:click={() => navigate(`/receivables/view/${businessDocumentId}/details`)}>
        <div class="flex">
            <svg width="44px" height="52px" viewBox="0 0 44 52" xmlns="http://www.w3.org/2000/svg">
                <g id="Screens" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="9---Invoices---View-Invoice" transform="translate(-820.000000, -640.000000)">
                        <g id="Invoice-Actions&amp;info-ONHOVER" transform="translate(808.000000, 628.000000)">
                            <g id="Group-3" transform="translate(12.000000, 12.000000)">
                                <rect id="Rectangle" stroke="#E8EBF0" fill="#FFFFFF" x="0.5" y="0.5" width="43"
                                      height="51" rx="6"></rect>
                                <rect id="Rectangle" fill="#D8D8D8" x="30" y="9" width="7" height="3" rx="1"></rect>
                                <rect id="Rectangle" fill="#D8D8D8" x="24" y="14" width="13" height="3" rx="1"></rect>
                                <rect id="Rectangle" fill="#D8D8D8" x="8" y="26" width="29" height="3" rx="1"></rect>
                                <rect id="Rectangle" fill="#E8EBF0" x="8" y="32" width="29" height="3" rx="1"></rect>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
            <div class="flex flex-col ml-4">
                <span class="text-ebonyClay font-semibold text-sm"
                      data-cy="invoice-attachment-file-name">{currentInvoiceAttachment.fileName}</span>
                <span
                        class="text-baliHai text-s">PDF &middot; {bytesToHighestUnitDisplay(currentInvoiceAttachment.contentBytesSizeRead, 1)}</span>
            </div>
        </div>
        <div class="relative flex space-x-2">
            <!--<a download={currentInvoiceAttachment.fileName} href={`data:application/pdf;base64,${currentInvoiceAttachment.contentBase64}`}><i class="icon-download text-ebonyClay text-3xl"></i></a>-->
            {#if !(businessDocumentId) || !(businessDocumentNumber)}
                <span on:click={() => newUpload()}>
                    <i class="icon-outline-close text-ebonyClay text-3xl"></i>
                </span>
            {/if}
        </div>
    </div>
{/if}

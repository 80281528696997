export function isReferenceArrayContainingAtLeastOneItemInSeekedArray<T>(refArray: T[], seekedArray: T[]): boolean {
  return seekedArray.reduce((accumulator, seekedItem: T) => {
    const res: boolean = refArray.reduce((acc, refItem) => {
      const resB: boolean = JSON.stringify(JSON.parse(JSON.stringify(seekedItem))) === JSON.stringify(JSON.parse(JSON.stringify(refItem, null, 0)))
      // if (resB) {
      
      // }else{
      
      // }
      return acc || resB
    }, false)
    // if (res) {
    
    // }
    return accumulator || res
  }, false)
}

<script lang="ts">
    import { get } from 'svelte/store'
    import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
    import { createEventDispatcher } from 'svelte'
    import { WorkspaceStore } from '../../../crm-app/stores/workspace.store'
    import TodoActionSelectContactArea from '../todo/TodoActionSelectContactArea.svelte'
    import SingleContactDropdown from './SingleContactDropdown.svelte'
    import type { Customer } from '../../../crm-app/models/customer'
    import { getCountryNameForCountryCode } from '../../../core-app/services/countries-pure-functions'
    import Company from '../../../crm-app/models/company'
    import type { Contact } from '../../../crm-app/models/contact'
    import { currentProfileLanguage } from '../../../core-app/lib/i18n/i18nextWrapper.js'

    /** Export let */
    export let renderedBodyMessage: string
    export let renderedSubjectMessage: string
    export let customerContacts: Contact[]
    export let customer: Customer
    export let activeTabValue: number = 1
    export let selectedContact: Contact

    /** Local declarations */
    let userCompany: Company = get(WorkspaceStore).company
    let customerCountry: string = customer?.company?.mailAddress?.countryCode ? getCountryNameForCountryCode(currentProfileLanguage(), customer?.company?.mailAddress?.countryCode) : ''
    const dispatch = createEventDispatcher()

    $: if (!selectedContact) {
      selectedContact = customerContacts[0]
    }

    const handleClick = () => {
      if (customerContacts && customerContacts.length && activeTabValue !== 1) {
        activeTabValue = 1
      } else if (customerContacts && customerContacts.length && activeTabValue !== 2) {
        activeTabValue = 2
      }
    }

</script>
<div class="w-full mt-4 rounded y-6 text-s relative">
    <div class="flex space-x-1 rounded bg-whisper p-0.5 w-fit mx-auto"
         role="tablist"
         aria-orientation="horizontal">
        <button on:click={() => handleClick()}
                class="flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-medium lg:pr-3 {activeTabValue === 1 ? 'bg-white shadow' : ''}"
                role="tab"
                type="button"
                aria-selected="false"
                tabindex="-1">
            <svg class="h-5 w-5 flex-none {activeTabValue === 1 ? 'stroke-dundyOrange' : 'stroke-loblolly'}" fill="none"
                 stroke-width="1.5" stroke-linecap="round"
                 stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg">
                <path d="m13.75 6.75 3.5 3.25-3.5 3.25M6.25 13.25 2.75 10l3.5-3.25"></path>
            </svg>
            <span class="sr-only lg:not-sr-only lg:ml-2 {activeTabValue === 1 ? 'text-black' : 'text-loblolly'}">{t('todoAction.editingUILabel')}</span>
        </button>
        <button on:click={() => handleClick()}
                class="flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-medium lg:pr-3 {activeTabValue === 2 ? 'bg-white shadow' : ''} "
                role="tab"
                type="button"
                aria-selected="true"
                tabindex="0">
            <svg class="h-5 w-5 flex-none {activeTabValue === 2 ? 'stroke-dundyOrange' : 'stroke-loblolly'}" fill="none"
                 stroke-width="1.5" stroke-linecap="round"
                 stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.25 10c0 1-1.75 6.25-7.25 6.25S2.75 11 2.75 10 4.5 3.75 10 3.75 17.25 9 17.25 10Z"></path>
                <circle cx="10" cy="10" r="2.25"></circle>
            </svg>
            <span class="sr-only lg:not-sr-only lg:ml-2 {activeTabValue === 2 ? 'text-black' : 'text-loblolly'}">{t('todoAction.previewUILabel')}</span>
        </button>
    </div>

    {#if activeTabValue === 1}
        <div class="my-4">
            {#if selectedContact}
                <div class="flex items-center my-2">
                    <p class="w-2/12 text-s">{t('todoAction.toLabel')}</p>
                    <div class="w-10/12">
                        <SingleContactDropdown
                                {selectedContact}
                                {customerContacts}
                                on:selectedContactChanged={ (e) => {
                                  dispatch('selectedContactChanged', e.detail)
                                }}
                        />
                    </div>
                </div>
            {:else}
                <TodoActionSelectContactArea {customer}/>
            {/if}
            <div class="flex items-center align-middle my-2">
                <p class="w-2/12 text-s">{t('todoAction.subjectLabel')}</p>
                <div class="w-10/12 mt-2 flex items-center justify-between align-middle rounded border border-athensGray hover:border-dundyOrange focus:border-dundyOrange active:border-dundyOrange relative">
                    <p class="w-full h-full px-2 py-2 text-s"
                       contenteditable="true"
                       bind:innerHTML={renderedSubjectMessage}>
                    </p>
                </div>
            </div>
        </div>
        <div class="w-full mt-4 rounded border border-athensGray my-6 text-s relative">
            <div
                    class="w-full h-full px-8 py-6"
                    contenteditable=true
                    id="renderedBody"
                    bind:innerHTML={renderedBodyMessage}>
            </div>
        </div>
    {/if}

    {#if activeTabValue === 2}
        <div class="w-full h-full mt-6 px-8 pt-16 pb-6 border border-athensGray">
            <div class="w-full flex-col">
                <p class="font-bold">{userCompany?.formalName}</p>
                <p>{userCompany?.mailAddress.street}</p>
                <p>{userCompany?.mailAddress.zipCode}, {userCompany?.mailAddress.city}</p>
                <p>{getCountryNameForCountryCode(currentProfileLanguage(), userCompany?.mailAddress.countryCode)}</p>
            </div>
            <div class="w-full text-right flex-col">
                <p class="font-bold">{customer?.company?.formalName}</p>
                <p>{t('todoAction.baseActionLetterItemBody.intendedFor')} {selectedContact?.firstName} {selectedContact?.lastName}</p>
                <p>{customer?.company?.mailAddress.street}</p>
                <p>{customer?.company?.mailAddress.zipCode} {customer?.company?.mailAddress.city}</p>
                <p>{customerCountry}</p><br><br>
            </div>
            <div class="w-full my-3 text-right">{userCompany?.mailAddress.city}{t('todoAction.baseActionLetterItemBody.letterOnTime')} {new Intl.DateTimeFormat(t('locales'), {
              day: '2-digit',
              month: 'long',
              year: 'numeric'
            }).format(new Date())}</div>
            <br>
            <div class="w-full my-3 font-bold">{renderedSubjectMessage}</div>
            <br><br>
            {@html renderedBodyMessage}
        </div>
    {/if}
</div>


<script lang="ts">
  import { companyFromWorkspace, companyToWorkspace } from '$src/core-app/services/onboarding.service'
  import { doesLegalStructureRequireShareCapital } from '$src/core-app/services/companies-legal-structures'
  import { navigate } from 'svelte-routing'
  import { OnboardingStep } from '$src/core-app/enums/onboarding-steps'
  import { OnboardingStore } from '$src/core-app/stores/onboarding.store'
  import { onMount } from 'svelte'
  import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
  import Company from '$src/crm-app/models/company'
  import CompanyEdition from '$core/components/Onboarding/CompanyEdition.svelte'
  import CompanySearch from '$core/components/Onboarding/CompanySearch.svelte'
  import mixpanel from 'mixpanel-browser'
  import OnboardingLayout from '$core/lib/onboarding/OnboardingLayout.svelte'
  import { objectDeepClone } from '$src/shared/utils/object'

  let title:string = ''
  let isFetching: boolean = true
  let company: Company | null = null
  let isEditing: boolean = false
  let isManual: boolean = false
  
  let companyId: string = ''
  
  $: {
    if (company) {
      if (isManual) {
        title = t('onboarding.companyInfo.edit.titleNew')  
      } else {
        title = t('onboarding.companyInfo.edit.title')
      }
    } else {
      title = t('onboarding.companyInfo.main.title')
    }
  }

  $:showShareCapital = doesLegalStructureRequireShareCapital(company?.regulatory?.frRegulScope?.inseeData?.legalForm?.level2CatCode?.toString() ?? '')

  $:errors = !company ? 'no company' :
    !company.formalName ? 'formalName' :
      (showShareCapital && !company.shareCapital) ? 'shareCapital' :
        !company.mailAddress?.street ? 'street' :
          !company.mailAddress?.zipCode ? 'zipCode' :
            !company.mailAddress?.city ? 'city' :
              !company.mailAddress?.countryCode ? 'countryCode' : ''

  const onCompanySelected = async (c: Company) => {
    mixpanel.track('onb.cpn.inf.C13.u onboarding select company', {
      'Description': 'Select company'
    })

    isManual = false
    company = objectDeepClone(c) as Company
  }

  const onCompanyEdition = () => {
    mixpanel.track('onb.cpn.inf.C11.u onboarding company pending button', {
      'Description': 'Click on previous step button'
    })

    company = Company.empty()
    company.registrationPending = false

    isManual = true
    isEditing = true
  }

  const onPreviousStep = () => {
    mixpanel.track('onb.cpn.inf.C02.u onboarding company info previous step', {
      'Description': 'Click on previous step button'
    })

    company = null
  }

  const onNextStep = () => {
    if (!company) return

    mixpanel.track('onb.cpn.inf.C01.u onboarding company info next step', {
      'Description': 'Click on next step button'
    })

    companyToWorkspace(company, companyId)
    navigate('/onboarding/company-info-plus')
  }

  onMount(() => {
    mixpanel.track('onb.cpn.inf.C00.i onboarding company info load', {
      'Description': 'Open CompanyInfo.svelte'
    })

    // load existing company from workspace
    const tmp = companyFromWorkspace()

    if (tmp instanceof Company) {
      company = tmp
    } else {
      companyId = tmp.companyId // TODO : TEMPORARY FOR COMPANY MODEL MIGRATION
    }

    isFetching = false
    $OnboardingStore.currentOnboardingStep = OnboardingStep.COMPANY_INFO
  })
</script>

<OnboardingLayout>
  <div slot="onboarding-sidebar">
    <div class="flex flex-col w-full text-center align-center">
      <div class="flex mt-20">
        <img alt="dundy logo" class="mx-auto" src="/img/flying-balloon.svg"/>
      </div>
      <span class="text-base block mt-16 font-semibold text-white">
        {t('onboarding.companyInfo.sidebar.title')}
      </span>
      <div class="flex flex-col w-full leading-6">
        <span class="text-baliHai mt-4 text-sm/6 w-9/12 mx-auto relative">
          {t('onboarding.companyInfo.sidebar.text')}
          </span>
      </div>
    </div>
  </div>
  <div slot="onboarding-content">
    <section class="onboarding">
      <div>
        <h1>{title}</h1>

        {#if company}
          <CompanyEdition
            {isManual}
            allowChangeCountry={false}
            bind:company={company}
            bind:isEditing={isEditing}
          />
        {/if}

        <CompanySearch
          {isFetching}
          display={company ? 'none' : 'block'}
          on:selectCompany={c => onCompanySelected(c.detail)}
        />

        {#if !isEditing && !company}
          <div class="h-3.5 border-b-[1px] border-athensGray text-center">
            <span class="bg-white px-4 text-xs text-loblolly uppercase">
              {t('onboarding.companyInfo.search.or')}
            </span>
          </div>

          <div class="">
            <button type="button" disabled="{isFetching}" class="btn action-default mt-11 w-full"
                    on:click={() => onCompanyEdition()}>
              <img src="/img/icons/plus.svg" alt="edit" class="mr-2"/>
              {t('onboarding.companyInfo.search.add')}
            </button>
          </div>
        {/if}

        <div class="mt-9 pb-9 flex items-center justify-between">
          {#if company?.formalName}
            <button type="button"
                    class="btn action-default mr-5 h-10"
                    disabled={isEditing}
                    on:click={onPreviousStep}
            >
              {t('onboarding.previous')}
            </button>
          {/if}

          <button type="button"
                  class="btn action-bla primary h-10"
                  disabled={isEditing || !company || Boolean(errors !== '')}
                  on:click={onNextStep}
          >
            {t('onboarding.next')}
          </button>
        </div>

      </div>
    </section>
  </div>
</OnboardingLayout>

<style global lang="postcss">
  .onboarding {
    @apply flex w-full md:w-11/12 2xl:w-11/12 m-auto;

    & > div {
      @apply w-full 2xl:w-8/12 mx-auto max-w-[40rem];
    }

    .loader {
      @apply absolute left-[50%] top-[20em];
    }

    h1 {
      @apply text-3xl font-bold mb-6 text-center;
    }

    form {

      label {
        @apply font-medium text-sm;

        &.required::after {
          content: " *";
          @apply text-dundyOrange ml-0.5 text-s;
        }
      }

    }
  }
</style>

<script lang="ts">
  import {onMount} from 'svelte'
  import {t} from '$core/lib/i18n/i18nextWrapper'
  import FilterBar from '../filters/FilterBar.svelte'
  import type {AppliedDunningFilters} from '$dundy/models/applied-dunning-filters'
  import type {CollateralCalculusForDashboard} from '$core/services/dashboard-update-calculus'
  import {roundedEdges} from '$core/util/rounded-edges-utils'
  import FilterDescription from '../filters/FilterDescription.svelte'
  import {basicCubicBezier} from '$core/util/cubic-beziers-utils'
  import DunningDataCarousel from './DunningDataCarousel.svelte'

  /** Export let */
  export let filters: AppliedDunningFilters
  export let currency: string
  export let computedCalculusForDashboard: CollateralCalculusForDashboard

  /** Local variables */
  let scrollContainer: HTMLDivElement
  let isLeftButtonVisible: boolean = false
  let isRightButtonVisible: boolean = true

  /**
   * Filter the entries to exclude the filters that are not needed
   */
  const excludedKeys: string[] = [
    'checkboxFilterAmountLessThan500',
    'checkboxFilterAmountBetween500And5000',
    'checkboxFilterAmountBetween5000And20000',
    'checkboxFilterAmountBetween20000And75000',
    'checkboxFilterAmountBetween75000And150000',
    'checkboxFilterAmountMoreThan150000'
  ]

  /**
   * Filter the entries based on whether the key is not in excludedKeys
   */
  const filteredEntries: [string, any][] = Object.entries(filters).filter(([key, value]) => !excludedKeys.includes(key))

  /**
   * Filter to get only active filters
   */
  let existingFilters: [string, any][] = filteredEntries.filter(([filter, data]) => data.totalAmount > 0)

  /**
   * Smooth scroll to a target position
   * ref: https://stackoverflow.com/questions/17722497/scroll-smoothly-to-specific-element-on-page
   * @param element
   * @param target
   * @param duration
   */
  function smoothScrollTo(element: HTMLDivElement, target: number, duration: number) {
    const start = element.scrollLeft,
      change = target - start,
      startTime = performance.now(),
      animateScroll = function (currentTime: number) {
        const timeElapsed = currentTime - startTime
        const progress = timeElapsed / duration

        element.scrollLeft = start + change * basicCubicBezier(progress)

        if (timeElapsed < duration) {
          window.requestAnimationFrame(animateScroll.bind(null, performance.now()))
        }
      }

    window.requestAnimationFrame(animateScroll.bind(null, performance.now()))
  }

  /**
   * Scroll to the left
   * uses timeout to wait for the scroll to finish
   * as scroll event and the conditional check to update
   * the opacity of the arrows are asynchronous so arrows might not be updated
   */
  function scrollLeft() {
    if (scrollContainer) {
      smoothScrollTo(scrollContainer, scrollContainer.scrollLeft - scrollContainer.offsetWidth, 500)
      setTimeout(() => {
        if (scrollContainer.scrollLeft === 0) {
          isLeftButtonVisible = false
        }
        isRightButtonVisible = true
      }, 500)
    }
  }

  /**
   * Scroll to the right
   * uses timeout to wait for the scroll to finish
   * as scroll event and the conditional check to update
   * the opacity of the arrows are asynchronous so arrows might not be updated
   */
  function scrollRight() {
    if (scrollContainer) {
      smoothScrollTo(scrollContainer, scrollContainer.scrollLeft + scrollContainer.offsetWidth, 500)
      setTimeout(() => {
        if (scrollContainer.scrollLeft + scrollContainer.offsetWidth >= scrollContainer.scrollWidth) {
          isRightButtonVisible = false
        }
        isLeftButtonVisible = true
      }, 500)
    }
  }

  onMount(() => {
    scrollContainer = document.querySelector('.scroll-container')

    if (scrollContainer.scrollLeft === 0) {
      isLeftButtonVisible = false
    }
    // Don't attach event listeners if not needed
    if (existingFilters.length < 4) {
      return
    }

    const scrollAmount: number = 100

    document.querySelector('.arrow-left').addEventListener('click', () => {
      scrollContainer.scrollBy({left: -scrollAmount, behavior: 'smooth'})
    })

    document.querySelector('.arrow-right').addEventListener('click', () => {
      scrollContainer.scrollBy({left: scrollAmount, behavior: 'smooth'})
    })
  })
</script>

<div id="dunning-hud" class="dunning-hud">
  <div class="w-full lg:w-3/12">
    <DunningDataCarousel computedCalculusForDashboard={computedCalculusForDashboard}/>
  </div>
  <div class="w-full lg:w-9/12 px-6">
    <div class="flex items-center justify-center">
      {#if existingFilters.length >= 4}
        <button class="arrow-left" on:click={scrollLeft} style="opacity: {isLeftButtonVisible ? 1 : 0.5};">
          <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  d="M10.25 6.75L4.75 12L10.25 17.25"></path>
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  d="M19.25 12H5"></path>
          </svg>
        </button>
      {/if}
      <div class="flex overflow-x-auto scroll-container" bind:this={scrollContainer}>
        {#each existingFilters as [filter, filterData] (filter)}
          <FilterDescription
            color={filterData.color}
            title={t(`dunning.filters.${filter}`)}
            totalAmount={filterData.totalAmount}
            currency={currency}
            bind:filters
            filterKey={filter}
          />
        {/each}
      </div>
      {#if existingFilters.length >= 4}
        <button class="arrow-right" on:click={scrollRight} style="opacity: {isRightButtonVisible ? 1 : 0.5};">
          <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  d="M13.75 6.75L19.25 12L13.75 17.25"></path>
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  d="M19 12H4.75"></path>
          </svg>
        </button>
      {/if}
    </div>
    <div class=" flex gap-0.5" aria-hidden="true">
      {#each filteredEntries as [filter, filterData] (filter)}
        <FilterBar
          bgColor={filterData.color}
          roundedEdge={roundedEdges((filterData.totalAmount / computedCalculusForDashboard.outstandingInvoicesAmount * 100), filterData.isFirstVisible, filterData.isLastVisible)}
          totalOutstandingAmount={computedCalculusForDashboard.outstandingInvoicesAmount}
          bind:filterData={filterData}
          bind:filters
          filterKey={filter}
        />
      {/each}
    </div>
  </div>
</div>
<style lang="postcss">
  .dunning-hud {
    @apply flex flex-col lg:flex-row items-center bg-black w-full my-6 space-y-4 lg:space-y-0 overflow-hidden;
    border-radius: 20px;
  }

  .scroll-container {
    @apply flex overflow-x-auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }

  .scroll-container::-webkit-scrollbar {
    @apply hidden;
  }

  .arrow-left, .arrow-right {
    @apply text-zinc-400 hover:text-white cursor-pointer;
    user-select: none;
    margin: 10px;
  }
</style>
<script lang="ts">
  import { t } from '$core/lib/i18n/i18nextWrapper'
  import StorageBucketObject from '$src/file-mgt-domain/models/storage-bucket-object'
  import Tooltip from '$core/lib/ui-kit/Tooltip/Tooltip.svelte'
  import type { BusinessDocument } from '$voxy/models/business-document'

  export let attachment: StorageBucketObject
  export let isDownloadingAttachment: boolean
  export let businessDocument: BusinessDocument

  $:doesCurrentBusinessDocumentHaveAttachment = businessDocument?.businessDocumentNumber !== ''
</script>

<div data-ut-component="download-pdf-button">
  {#if doesCurrentBusinessDocumentHaveAttachment}
    {#if !isDownloadingAttachment && !!attachment?.contentBase64 }
      <Tooltip>
        <div slot="activator">
          <a download={attachment.fileName}
            href={`data:application/pdf;base64,${attachment.contentBase64}`}
            class="relative group h-12 w-12 flex items-center justify-center rounded hover:border hover:border-black hover:text-black focus:z-10 focus:ring-2 focus:ring-whisper"
            aria-label="{t('invoices.viewInvoice.buttons.downloadButtonLabel')}"
            type="link">
              <span class="download"></span>
          </a>
        </div>
        {t('invoices.viewInvoice.buttons.downloadButtonLabel')}
      </Tooltip>
    {:else}
      <div class="download disabled size-12 mr-2" />
    {/if}
  {/if}
</div>

<style lang="postcss">
  .download {
    mask: url("/img/icons/download.svg");
    mask-repeat: no-repeat;
    background: rgb(3,30,53);
    height: 24px;
    width: 24px;
  }
  .disabled {
    background: rgb(169, 169, 169);
    pointer-events: none;
  }
</style>
/**
 * Calculate the rounded corners of the bar
 * @param width
 * @param isFirstVisible
 * @param isLastVisible
 */
export function roundedEdges(width: string, isFirstVisible: boolean, isLastVisible: boolean): string {
  if (width === '0%') {
    return ''
  }

  let classes: string = ''

  // If it is the first visible bar, round top-left and bottom-left corners
  if (isFirstVisible) {
    classes += 'rounded-tl-md rounded-bl-md'
  }

  // If it is the last visible bar, round top-right and bottom-right corners
  if (isLastVisible) {
    classes += ' rounded-tr-md rounded-br-md'
  }

  // If it is the last visible bar, round top-right and bottom-right corners
  // but only if it's also the first visible bar (meaning it's the only visible bar)
  if (isLastVisible && isFirstVisible) {
    classes += ' rounded-tr-md rounded-br-md'
  }

  return classes.trim()
}
import { JsonProperty } from '@paddls/ts-serializer'
import { ExchangeDate } from '../../core-app/models/exchange-date'
import { Amount, AmountBasicObject } from './amount-of-money-model'

export class BankTransaction {
  @JsonProperty({ field: 'amount', type: () => [Amount, AmountBasicObject] }) amount: Amount | AmountBasicObject
  @JsonProperty('bankAccountId') bankAccountId: string
  @JsonProperty('bankAccountName') bankAccountName: string
  @JsonProperty('bankId') bankId: string
  @JsonProperty('bankName') bankName: string
  @JsonProperty('cleanDescription') cleanDescription: string
  @JsonProperty({ field: 'scale', type: () => ExchangeDate }) extractDate: ExchangeDate
  @JsonProperty('origin') origin: string
  @JsonProperty('originVersion') originVersion: string
  @JsonProperty('rawDescription') rawDescription: string
  @JsonProperty({ field: 'scale', type: () => ExchangeDate }) transactionDate: ExchangeDate
  @JsonProperty('transactionId') transactionId: string
}

export class CashApplication {
  @JsonProperty('applicationId') applicationId: string
  @JsonProperty({ field: 'appliedAmount', type: () => [Amount, AmountBasicObject] }) appliedAmount: Amount | AmountBasicObject
  @JsonProperty({ field: 'bankTransaction', type: () => BankTransaction }) bankTransaction: BankTransaction
  @JsonProperty({ field: 'created', type: () => ExchangeDate }) created: ExchangeDate
  @JsonProperty('creatorEndpoint') creatorEndpoint: string
  @JsonProperty('creatorFunction') creatorFunction: string
  @JsonProperty('customerId') customerId: string
  @JsonProperty({ field: 'invoiceAmount', type: () => [Amount, AmountBasicObject] }) invoiceAmount: Amount | AmountBasicObject
  @JsonProperty({ field: 'invoiceAmountDueAfterApplication', type: () => [Amount, AmountBasicObject] }) invoiceAmountDueAfterApplication: Amount | AmountBasicObject
  @JsonProperty({ field: 'invoiceAmountDueBeforeApplication', type: () => [Amount, AmountBasicObject] }) invoiceAmountDueBeforeApplication: Amount | AmountBasicObject
  @JsonProperty('invoiceDataId') invoiceDataId: string
  @JsonProperty('invoiceNumber') invoiceNumber: string
  @JsonProperty('requesterId') requesterId: string
  @JsonProperty('workspaceId') workspaceId: string
}

export class CashApplicationDeclaredEvent {
  @JsonProperty({ field: 'cashApplication', type: () => CashApplication }) cashApplication: CashApplication
  @JsonProperty('created') created: number
  @JsonProperty('createdRFC3339') createdRFC3339: string
  @JsonProperty('creatorEndpoint') creatorEndpoint: string
  @JsonProperty('creatorFunction') creatorFunction: string
  @JsonProperty('customerId') customerId: string
  @JsonProperty('eventId') eventId: string
  @JsonProperty('eventKind') eventKind: string
  @JsonProperty('invoiceDataId') invoiceDataId: string
  @JsonProperty('invoiceNumber') invoiceNumber: string
  @JsonProperty('userId') userId: string
  @JsonProperty('workspaceId') workspaceId: string
  // converts the js prototype into the TypeScript class
  static PrototypeToClass(jsPrototype: CashApplicationDeclaredEvent): CashApplicationDeclaredEvent {
    return <CashApplicationDeclaredEvent>Object.assign(new CashApplicationDeclaredEvent(), jsPrototype)
  }
}

export class CashApplicationDeclaredResponse {
  @JsonProperty('failedCashApplicationDeclaredEvents') failedCashApplicationDeclaredEvents: FailedCashApplicationDeclaredEvent[]
  @JsonProperty('successfullyInsertedCashApplicationDeclaredEvents') successfullyInsertedCashApplicationDeclaredEvents: SuccessfullyInsertedCashApplicationDeclaredEvent[]
}

export class FailedCashApplicationDeclaredEvent {
  @JsonProperty('desc') desc: string
  @JsonProperty('endpoint') endpoint: string
  @JsonProperty('message') message: string
  @JsonProperty('statusCode') statusCode: number
}

export class UpdateResult {
  @JsonProperty('MatchedCount') MatchedCount: number
  @JsonProperty('ModifiedCount') ModifiedCount: number
  @JsonProperty('UpsertedCount') UpsertedCount: number
  @JsonProperty('UpsertedID') UpsertedID: string
}

export class SuccessfullyInsertedCashApplicationDeclaredEvent {
  @JsonProperty('eventId') eventId: string
  @JsonProperty({ field: 'updateResult', type: () => UpdateResult }) updateResult: UpdateResult
}


<script lang="ts">
  import {t} from '../i18n/i18nextWrapper.js'
  import Tooltip from '../ui-kit/Tooltip/Tooltip.svelte'
  import {get} from 'svelte/store'
  import {DunningInvoicesStore} from '$dundy/stores/dunning-invoices.store'
  import GridItem from './GridItem.svelte'
  import {
    ValueForCurrentMonthAlongWithVariationMonthToMonth
  } from '../../models/dashboard-statistics-analytics-model'
  import {
    calculateExpensesForCurrentMonthAlongWithVariationToPreviousMonth,
    calculateRevenueForCurrentMonthAlongWithVariationToPreviousMonth
  } from '$cash/services/transactions-statistics-pure-functions'
  import {TransactionsStore} from '$bank/stores/transactions.store'
  import {createCurrentDateObjectAccordingToUserTimezone} from '../../util/date-utils'
  import {getUserCompanyTimezone} from '../../util/timezone-utils'
  import {WorkspaceStore} from '$crm/stores/workspace.store'
  import {BBBTransaction} from '$bank/models/bbb-transactions-model'
  import {
    calculateOverdueDunningInvoicesForCurrentMonthAlongWithVariationToPreviousMonth
  } from '../../services/dashboard-statistics-pure-functions'
  import {ComputedDataInvoicesStore} from '$dundy/stores/computed-data-invoice.store'
  import {
    calculate12MonthsExpenseInMonthlySums,
    calculate12MonthsLateInvoicesInMonthlySums,
    calculate12MonthsRevenueInMonthlySums
  } from './charts/calculus-and-statistics-for-chart-rendering'
  import LateInvoicesGridItem from "$core/lib/dashboard/LateInvoicesGridItem.svelte"
  import type {CollateralCalculusForDashboard} from "$core/services/dashboard-update-calculus"

  /** Export declarations */
  export let computedCalculusForDashboard: CollateralCalculusForDashboard
  export let currency: string
  export let isBankConfigLoaded: boolean = false

  /** Get user timezone */
  const userTimezone = getUserCompanyTimezone($WorkspaceStore)

  /** Create Current Date Object according to user timezone */
  const dateInTimezone = createCurrentDateObjectAccordingToUserTimezone(userTimezone)

  /** Transactions */
  let transactions: BBBTransaction[] = []

  /** Initiate Empty Revenue Object */
  let calculatedRevenueForCurrentMonthWithVariation: ValueForCurrentMonthAlongWithVariationMonthToMonth

  /** Initiate Expenses Object */
  let calculatedExpensesForCurrentMonthWithVariation: ValueForCurrentMonthAlongWithVariationMonthToMonth

  /** Initiate Overdue Invoices Object */
  let calculatedOverdueInvoicesForCurrentMonthWithVariation: ValueForCurrentMonthAlongWithVariationMonthToMonth

  /** Revenue Code */
  const currentMonth = new Date().getMonth()
  const currentYear = new Date().getFullYear()

  /** Calculus & Chart Generation */
  let positiveTransactions: BBBTransaction[] = []
  let negativeTransactions: BBBTransaction[] = []
  let monthlySums12MonthsRevenue: { [key: string]: number } = {}
  let monthlySums12MonthsExpense: { [key: string]: number } = {}
  let monthlySums12MonthsLateInvoices: { [key: string]: number } = {}

  /** Reactive declarations */
  $: $TransactionsStore ? transactions = $TransactionsStore.transactions : transactions = []

  $: if ($TransactionsStore?.transactions && $TransactionsStore?.transactions?.length > 0) {
    positiveTransactions = get(TransactionsStore).transactions?.filter(transaction => transaction.amount > 0)
    negativeTransactions = get(TransactionsStore).transactions?.filter(transaction => transaction.amount < 0)
  } else {
    positiveTransactions = []
    negativeTransactions = []
  }

  $: if ($TransactionsStore && $TransactionsStore?.transactions?.length > 0) {
    calculatedRevenueForCurrentMonthWithVariation = calculateRevenueForCurrentMonthAlongWithVariationToPreviousMonth(transactions, dateInTimezone)
    calculatedExpensesForCurrentMonthWithVariation = calculateExpensesForCurrentMonthAlongWithVariationToPreviousMonth(transactions, dateInTimezone)
  } else {
    calculatedRevenueForCurrentMonthWithVariation = ValueForCurrentMonthAlongWithVariationMonthToMonth.createDefault()
    calculatedExpensesForCurrentMonthWithVariation = ValueForCurrentMonthAlongWithVariationMonthToMonth.createDefault()
  }

  $: if ($DunningInvoicesStore && $DunningInvoicesStore?.length > 0) {
    calculatedOverdueInvoicesForCurrentMonthWithVariation = calculateOverdueDunningInvoicesForCurrentMonthAlongWithVariationToPreviousMonth(get(DunningInvoicesStore), get(ComputedDataInvoicesStore), dateInTimezone)
  } else {
    calculatedOverdueInvoicesForCurrentMonthWithVariation = ValueForCurrentMonthAlongWithVariationMonthToMonth.createDefault()
  }

  $: if (positiveTransactions) {
    monthlySums12MonthsRevenue = calculate12MonthsRevenueInMonthlySums(positiveTransactions, currentMonth, currentYear)
  }

  $: if (negativeTransactions) {
    monthlySums12MonthsExpense = calculate12MonthsExpenseInMonthlySums(negativeTransactions, currentMonth, currentYear)
  }

  $: if ($DunningInvoicesStore && $DunningInvoicesStore.length > 0 && $ComputedDataInvoicesStore) {
    monthlySums12MonthsLateInvoices = calculate12MonthsLateInvoicesInMonthlySums($DunningInvoicesStore, $ComputedDataInvoicesStore, dateInTimezone)
  } else {
    monthlySums12MonthsLateInvoices = {}
  }

</script>

<div class="w-full mt-4">
  <div class="grid-container">
    <GridItem
      currency={currency}
      title={t('dashboard.hud.revenue.title')}
      data={calculatedRevenueForCurrentMonthWithVariation}
      hasTooltip={true}
      svgData={monthlySums12MonthsRevenue}
      isBankConfigLoaded={isBankConfigLoaded}
    >
      <div slot="tooltip">
        <Tooltip width="300px">
          <div slot="activator">
            <svg class="text-paleSky" width="16" height="16" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2" d="M12 13V15"/>
              <circle cx="12" cy="9" r="1" fill="currentColor"/>
              <circle cx="12" cy="12" r="7.25" stroke="currentColor" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="1.5"/>
            </svg>
          </div>
          <p>{t('dashboard.hud.revenue.tooltip')}</p>
        </Tooltip>
      </div>
    </GridItem>

    <GridItem
      currency={currency}
      title={t('dashboard.hud.expenses.title')}
      data={calculatedExpensesForCurrentMonthWithVariation}
      hasTooltip={true}
      svgData={monthlySums12MonthsExpense}
      isBankConfigLoaded={isBankConfigLoaded}
    >
      <div slot="tooltip">
        <Tooltip width="300px">
          <div slot="activator">
            <svg class="text-paleSky" width="16" height="16" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2" d="M12 13V15"/>
              <circle cx="12" cy="9" r="1" fill="currentColor"/>
              <circle cx="12" cy="12" r="7.25" stroke="currentColor" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="1.5"/>
            </svg>
          </div>
          <p>{t('dashboard.hud.expenses.tooltip')}</p>
        </Tooltip>
      </div>
    </GridItem>

    <LateInvoicesGridItem
      currency={currency}
      title={t('dashboard.hud.lateInvoices.title')}
      data={calculatedOverdueInvoicesForCurrentMonthWithVariation}
      hasTooltip={true}
      svgData={monthlySums12MonthsLateInvoices}
      computedCalculusForDashboard={computedCalculusForDashboard}
    >
      <div slot="tooltip">
        <Tooltip width="300px">
          <div slot="activator">
            <svg class="text-paleSky" width="16" height="16" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2" d="M12 13V15"/>
              <circle cx="12" cy="9" r="1" fill="currentColor"/>
              <circle cx="12" cy="12" r="7.25" stroke="currentColor" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="1.5"/>
            </svg>
          </div>
          <p>{t('todo.stats.overdueAmountTooltip')}</p>
        </Tooltip>
      </div>
    </LateInvoicesGridItem>
  </div>
</div>

<style lang="postcss">
  .grid-container {
    @apply flex flex-col lg:flex-row gap-2.5;
  }
</style>

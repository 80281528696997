export default class UIBusinessDocumentsUpdatedCounts {
  allInvoicesAmountNormalized: string
  finalizedInvoicesAmountNormalized: string
  draftInvoicesAmountNormalized: string
  toSendInvoicesAmountNormalized: string
  creditNoteDocumentsAmountNormalized: string
  voidedDocumentAmountNormalized: string
  numberOfAllInvoices: number
  numberOfFinalizedInvoices: number
  numberOfToSendInvoices: number
  numberOfCreditNoteDocuments: number
  numberOfDraftInvoices: number
  numberOfVoidedDocuments: number
  constructor() {
    this.allInvoicesAmountNormalized = ''
    this.finalizedInvoicesAmountNormalized = ''
    this.draftInvoicesAmountNormalized = ''
    this.toSendInvoicesAmountNormalized = ''
    this.creditNoteDocumentsAmountNormalized = ''
    this.voidedDocumentAmountNormalized = ''
    this.numberOfAllInvoices = 0
    this.numberOfFinalizedInvoices = 0
    this.numberOfToSendInvoices = 0
    this.numberOfCreditNoteDocuments = 0
    this.numberOfDraftInvoices = 0
    this.numberOfVoidedDocuments = 0
  }
}

import type { BusinessDocument } from '../models/business-document'
import { TaxonomyTag } from '../enums/taxonomy-tag'

/**
 * Returns the sentence for a partial BusinessDocument
 * made of its type (deposit, intermediate, balance) along with the percentage of the partial BusinessDocument
 * uses a mapping object (tagsMapping) to relate each TaxonomyTag to the corresponding key used in the translation function (this.t)
 */
export class BusinessDocumentPartialKindFormatter {
  businessDocumentDescription: string
  constructor(private t: Function, private businessDocument: BusinessDocument) {
    this.setInvoiceData()
  }
  setInvoiceData(): void {
    const percentage: number = (this.businessDocument.installmentResultIncludingTaxScaledValue / this.businessDocument.totalIncludingTaxScaledValue) * 100
    const tagsMapping: { [key in TaxonomyTag]?: string } = {
      [TaxonomyTag.DEPOSIT_INVOICE]: 'depositInvoice',
      [TaxonomyTag.INTERMEDIATE_INVOICE]: 'partialInvoice',
      [TaxonomyTag.BALANCE_INVOICE]: 'balanceInvoice'
    }

    let descriptionKey: string = ''

    for (const [tag, key] of Object.entries(tagsMapping)) {
      if (this.businessDocument.taxonomyTags?.includes(tag as TaxonomyTag)) {
        descriptionKey = key!
        break
      }
    }

    this.businessDocumentDescription = descriptionKey
      ? this.t(`invoices.businessDocument.businessDocumentKindWithPercentage.${descriptionKey}`, { percentage: percentage.toFixed(2) })
      : ''
  }
}
<script lang="ts">
    import { t } from '../../../../core-app/lib/i18n/i18nextWrapper.js'
    import { fly, slide } from 'svelte/transition'
    import { backInOut } from 'svelte/easing'
    import { createEventDispatcher, onMount } from 'svelte'
    import type {
      BusinessDocument
    } from '../../../models/business-document'
    import { updateNewNavigationHistoryItem } from '../../../../core-app/stores/navigationHistory.store'
    import Loader from '../../../../core-app/lib/ui-kit/Loader.svelte'
    import PartialBusinessDocumentRow from './PartialBusinessDocumentRow.svelte'

    /** Exported Variables **/
    export let listOfPreviousPartialBusinessDocumentsForCustomer: BusinessDocument[]

    /** Let declarations **/
    const dispatch = createEventDispatcher()
    let isContentLoading: boolean = false
    let selectedDealIdForBalanceInvoiceCreation: string = null

    /**
     *  Close the window
     */
    function closeDealsAvailableForInvoicingSelectionWindow() {
      /* console.log('closing DealsAvailableForInvoicingSelectionWindow') */
      dispatch('closeDealsAvailableForInvoicingSelectionWindow')
    }

    function closeDealSelectionWindowAndApplyNewSelectedDealForBalanceInvoiceEdition(selectedDealIdForBalanceInvoiceCreation: string) {
      dispatch('closeDealSelectionWindowAndApplyNewSelectedDealForBalanceInvoiceEdition', selectedDealIdForBalanceInvoiceCreation)
      // NB: dispatch('abc', aVarOfTypeT) in the sub-Component will be received by the parent Component as a aVarOfTypeTCustomEvent : CustomEvent<T>
      /*
            on:abc={(aVarOfTypeTCustomEvent)=>{
                        return doSomething(aVarOfTypeTCustomEvent.detail); // aVarOfTypeTCustomEvent : CustomEvent<T> where aVarOfTypeTCustomEvent.detail is the sent aVarOfTypeT
                    }}
         */
      // NB: here like (selectedDealIdForBalanceInvoiceCreationCustomEvent : CustomEvent<string>)=>{}
      closeDealsAvailableForInvoicingSelectionWindow()
    }

    onMount(() => {
      updateNewNavigationHistoryItem('BusinessDocumentRelatedDocumentsPanel.svelte')
      isContentLoading = true

      setTimeout(() => {
        isContentLoading = false
      }, 800)
    })

</script>
<div class="bg-white rounded-md flex flex-col h-screen overflow-hidden"
     in:fly|local="{{ y: 100, duration: 1000, easing: backInOut }}"
     out:slide|local="{{ easing: backInOut }}">

    <div class="divide-y divide-gray-200 border-t border-whisper sm:px-6 overflow-y-auto pb-16">
        <div class="space-y-6 py-7">
            <div class="px-12 sm:px-6">
                <div class="col-span-2">
                    {#if isContentLoading}
                        <div class="grid h-24 place-items-center w-full z-[100]">
                            <Loader/>
                        </div>
                    {:else}
                        <h1 class="text-xl font-bold">{t('invoices.resumeInvoicing.resumeTitle')}</h1>
                        <p class="text-zinc-800 text-s border-b border-whisper pb-6">{t('invoices.resumeInvoicing.resumeText')}</p>
                        <ul role="list" class="divide-y divide-gray-100 space-y-4">
                            {#each listOfPreviousPartialBusinessDocumentsForCustomer as aBusinessDocument (aBusinessDocument.businessDocumentId)}
                                <PartialBusinessDocumentRow
                                    isSelected={selectedDealIdForBalanceInvoiceCreation === aBusinessDocument.linkedDeal.dealId}
                                    onSelect={() => {
                                      /* console.log('Row clicked:', aBusinessDocument.linkedDeal.dealId) */
                                      selectedDealIdForBalanceInvoiceCreation = selectedDealIdForBalanceInvoiceCreation === aBusinessDocument.linkedDeal.dealId ? null : aBusinessDocument.linkedDeal.dealId
                                      /* console.log('New selectedDealIdForBalanceInvoiceCreation:', selectedDealIdForBalanceInvoiceCreation) */
                                    }}
                                    thisBusinessDocument={aBusinessDocument}
                                />
                            {/each}
                        </ul>
                    {/if}
                </div>
            </div>
        </div>
        <div class="flex flex-shrink-0 justify-end px-10 py-4 absolute bottom-0 left-0 w-full bg-white">
            <button type="button"
                    on:click|preventDefault|stopPropagation={() => closeDealsAvailableForInvoicingSelectionWindow()}
                    class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                {t('invoices.resumeInvoicing.buttonCancel')}
            </button>
            <button type="button"
                    on:click|preventDefault|stopPropagation={() => closeDealSelectionWindowAndApplyNewSelectedDealForBalanceInvoiceEdition(selectedDealIdForBalanceInvoiceCreation)}
                    role="tab"
                    tabindex="-1"
                    class="ml-6 inline-flex items-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 disabled:bg-transparent disabled:border-athensGray disabled:text-loblolly disabled:shadow-none disabled:cursor-not-allowed"
                    disabled={!selectedDealIdForBalanceInvoiceCreation}>
                {t('invoices.resumeInvoicing.buttonResume')}
            </button>
        </div>
    </div>

</div>

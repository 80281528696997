<script lang="ts">
  import StatDownOrUpArrow from './StatDownOrUpArrow.svelte'
  import {ValueForCurrentMonthAlongWithVariationMonthToMonth} from '$core/models/dashboard-statistics-analytics-model'
  export let data: ValueForCurrentMonthAlongWithVariationMonthToMonth
</script>

<span class="inline-flex items-center px-1.5 py-0.5 space-x-1 text-s text-white">
  <span class="sr-only"> Increased by </span>{data?.monthToMonthVariationPercentage}
  <StatDownOrUpArrow direction={data?.variationDirection} favorability={data?.variationFavorability}/>
</span>


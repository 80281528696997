<script lang="ts">
    import currentPathStore from '../../stores/current-path.store'
    import { navigate } from 'svelte-routing'
    import { get } from 'svelte/store'

    /** Local declarations */
    let selectedPathForMobile:string = get(currentPathStore)

</script>

<div data-ut-component="section-heading" class="mb-4">
    <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>

        <select id="tabs" name="tabs"
                bind:value={selectedPathForMobile}
                on:change="{() => navigate(selectedPathForMobile, { replace: true })}"
                class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-dundyOrange focus:border-dundyOrange sm:text-sm rounded-md">
            <slot/>

        </select>
    </div>
    <div class="hidden sm:block">
        <div class="border-b border-gray-200 flex items-center justify-between">
            <slot name="desktopNavigation" />
        </div>
    </div>
</div>

<script lang="ts">
  import { navigate } from 'svelte-routing'

  export let title: Readonly<string> = ''
  export let subtitle: Readonly<string> = ''
  export let addButtonValue: Readonly<string> = ''
  export let addButtonLink: Readonly<string> = ''
  export let footerClass: Readonly<string> = 'mt-8 flex justify-center items-center'

  const SLOTS = $$props.$$slots

  const onAddButtonClick = () => navigate(addButtonLink)
</script>

<svelte:head>
  <title>{title} - Dundy</title>
</svelte:head>

<div>
  <div class="flex justify-between">
    {#if title}
      <h1 class="text-black text-3xl font-bold">{title}</h1>
    {/if}

    {#if addButtonValue && addButtonLink}
      <button class="btn action-primary" on:click|preventDefault|stopPropagation={() => onAddButtonClick()}>
        <div class="icon add" />
        {addButtonValue}
      </button>
    {/if}
  </div>

  {#if subtitle}
    <h2 class="font-ligh text-paleSky">{subtitle}</h2>
  {/if}

  <div class="h-[calc(100vh-10rem)] flex flex-col">
    <div class="grow mt-4 overflow-auto">
      <slot />
    </div>

    {#if SLOTS.footer}
      <div class="w-full {footerClass}">
        <slot name="footer" />
      </div>
    {/if}
  </div>
  
</div>

<style lang="postcss">
  button {
    &::before {
      content:'';
      mask: url("/img/icons/plus.svg");
      mask-repeat: no-repeat;
      background: white;
      height: 24px;
      width: 24px;
      margin-right: 0.5rem;
      transition: 300ms;
    }

    &:hover::before {
      background: black;
    }
  }
</style>
import { APICallOptions, APIEntity, apiGet } from '../../core-app/services/api.service'
import { get } from 'svelte/store'
import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
import { eventsManager } from '../../core-app/events/event-manager'
import { EventType } from '../../core-app/events/event-type'
import type { InvoicesHistoryResponse } from '../models/invoices-history'
import type Invoice from '../models/invoice'
import type { InvoicesHistoryItem } from '../models/invoices-history'

class InvoicesHistoryServiceClass {

  initialized
  initialize(): void {
    /* console.log('+++ about to initialize history') */
    if (!this.initialized) {

      this.initialized = true

      /* THIS IS REDUNDANT, WE ALREADY HAVE A LISTENER FOR INVOICE_LIST_FETCHED WHICH CALLS THIS FETCH METHOD
            eventsManager.on<InvoicesHistoryItem[]>(EventType.HISTORY_LIST_FETCHED, (e: DundyEvent<InvoicesHistoryItem[]>): void => {
                console.log('history gotten here after HISTORY_LIST_FETCHED', e.data);
               InvoicesHistoryStore.set(e.data);
            }, 'HistoryService');*/

      eventsManager.on<Invoice[]>(EventType.INVOICE_LIST_FETCHED, () => {
        /* console.log('+++ calling fetch history from ON INVOICE_LIST_FETCHED') */
        this.fetch()
      }, 'HistoryService')
    }
  }
  /**
     * Fetches the list of invoices history items
     * @param workspaceId
     * @private
     */
  private fetchList(workspaceId?): Promise<InvoicesHistoryResponse> {
    return apiGet<InvoicesHistoryResponse>(`/workspaces/${workspaceId || get(WorkspaceStore).workspaceId}/invoices-history`, <APICallOptions>{
      entity: APIEntity.HISTORY
    })
  }
  /**
     * Fetches the invoices history items
     * @private
     */
  private fetch(): void {
    /* console.log('+++ about to fetch invoices history') */
    this.fetchList()
      .then((response: InvoicesHistoryResponse): void => {
        // Extract the invoice history items from the response object
        const invoiceHistoryItems: InvoicesHistoryItem[] = Object.values(response)
        /* console.log('+++ invoiceHistoryItems', invoiceHistoryItems) */
        eventsManager.emit(EventType.HISTORY_LIST_FETCHED, invoiceHistoryItems, 'invoicesHistoryService')
      })
      .catch(err => console.warn(err))
  }
}

export const invoicesHistoryService: InvoicesHistoryServiceClass = new InvoicesHistoryServiceClass()

<script lang="ts">
    import { t } from '../i18n/i18nextWrapper'
    import { onMount } from 'svelte'

    export let i18nKey = 'loading'
    export let color = ''
    export let textColor = 'paleSky'
    export let center:boolean = true

    onMount(() => {
      if (color) {
        document.querySelector(':root').style.setProperty('--loader-color', color)
      }
    })
</script>

<div class="loader flex flex-row items-center mt-3" class:center={center}>
    <div class="dots"></div>
    <p class="font-medium text-{textColor} ml-7">{t(i18nKey)}</p>
</div>

<style>

    :root {
        --loader-color: #F47663;
    }

    .center {
      display:flex;
      align-items: center;
      justify-content: center;
    }

    .dots {
        position: relative;
        left: -9999px;
        width: 6px;
        height: 6px;
        border-radius: 5px;
        @apply bg-dundyOrange;
        @apply text-dundyOrange;
        box-shadow: 9984px 0 0 0 var(--loader-color), 9999px 0 0 0 var(--loader-color), 10014px 0 0 0 var(--loader-color);
        animation: bounce 1.5s infinite linear;
    }

    @keyframes bounce {
        0% {
            box-shadow: 9984px 0 0 0 var(--loader-color), 9999px 0 0 0 var(--loader-color),
            10014px 0 0 0 var(--loader-color);
        }
        16.667% {
            box-shadow: 9984px -10px 0 0 var(--loader-color), 9999px 0 0 0 var(--loader-color),
            10014px 0 0 0 var(--loader-color);
        }
        33.333% {
            box-shadow: 9984px 0 0 0 var(--loader-color), 9999px 0 0 0 var(--loader-color),
            10014px 0 0 0 var(--loader-color);
        }
        50% {
            box-shadow: 9984px 0 0 0 var(--loader-color), 9999px -10px 0 0 var(--loader-color),
            10014px 0 0 0 var(--loader-color);
        }
        66.667% {
            box-shadow: 9984px 0 0 0 var(--loader-color), 9999px 0 0 0 var(--loader-color),
            10014px 0 0 0 var(--loader-color);
        }
        83.333% {
            box-shadow: 9984px 0 0 0 var(--loader-color), 9999px 0 0 0 var(--loader-color),
            10014px -10px 0 0 var(--loader-color);
        }
        100% {
            box-shadow: 9984px 0 0 0 var(--loader-color), 9999px 0 0 0 var(--loader-color),
            10014px 0 0 0 var(--loader-color);
        }
    }

    .loader p {
        font-size: 13px;
    }
</style>

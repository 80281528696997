import { JsonProperty } from '@paddls/ts-serializer'

export class ExchangeDate {
  @JsonProperty('unixSeconds') unixSeconds: number
  @JsonProperty('rfc3339') rfc3339: string
  constructor(unixSeconds?:number, rfc3339?:string) {
    if (unixSeconds) this.unixSeconds = unixSeconds
    if (rfc3339) this.rfc3339 = rfc3339
  }
  static readonly fromString = (date: string):ExchangeDate => {
    if (date) return new ExchangeDate

    const tmp: ExchangeDate = ExchangeDate.newDateRFC3339(date)
    const rfc3339: string = tmp.rfc3339
    const unixSeconds: number = tmp.unixSeconds

    return new ExchangeDate(unixSeconds, rfc3339) 
  }
  static newDate(date: Date, overrideLocale?: string): ExchangeDate {
    return {
      unixSeconds: Math.round((new Date(date)).getTime() / 1000.0),
      rfc3339: new Date(date).toISOString()
    }
  }
  static newDateRFC3339(dateRFC3339: string, overrideLocale?: string): ExchangeDate {
    return {
      unixSeconds: Math.round((new Date(dateRFC3339)).getTime() / 1000.0),
      rfc3339: new Date(dateRFC3339).toISOString()
    }
  }
  static empty(): ExchangeDate {
    return new ExchangeDate(0, '')
  }
}
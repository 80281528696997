import type { ICellRendererParams } from 'ag-grid-community'
import { eventsManager } from '$core/events/event-manager'
import { EventType } from '$core/events/event-type'
import { DunningInvoicesHelper } from '$dundy/helpers/dunning-invoices-helper'
import { t } from '$core/lib/i18n/i18nextWrapper'
import { feedbackService } from '$core/services/feedback.service'
import type { Feedback } from '$core/models/feedback'

/**
 * Render the status of an invoice in the dunning list
 * status is either :
 * - on-time
 * - tracked (with days overdue)
 * - draft
 * - error
 * - tbd
 */
export default class DunningStatusRenderer {
  eGui: HTMLDivElement
  completeButton: HTMLButtonElement
  trackButton: HTMLButtonElement
  statusLabel: HTMLSpanElement
  originalParams: ICellRendererParams
  invoiceCompletedClickHandler
  invoiceStartTrackingClickHandler
  /**
     * Gets called once before the renderer is used.
     * create the cell
     * build the button properly (https://blog.ag-grid.com/cell-renderers-in-ag-grid-every-different-flavour/#vanillajs)
     */
  init(params: ICellRendererParams) {

    this.originalParams = params
    this.invoiceCompletedClickHandler = () => {
      // NB: event workflow from here :
      // 1- the cell value change is first requested with publishEventCellValueChangeRequested
      // 2- in the subscribe of this event, subscribeToEventCellValueChangeRequested, first : rowNode.setDataValue(e.colId, e.newValue); which triggers onCellValueChanged = (e: CellValueChangedEvent) which triggers publishEventCellValueChanged
      // 3- it publishEventCellValueChanged trigger inside subscribeToEventCellValueChanged() which postNewListOfInvoicesPromise() (i.e. saves invoices data to API) then fetchInvoices then fetchWorkspace
      // recommended way to change a cell value from outside in Ag-Grid
      // https://www.ag-grid.com/javascript-data-grid/component-cell-renderer/#events-causing-refresh
      // this.originalParams.rowNode.setDataValue('completed', true); // fails to make any change

      // NB: isTracked will be conditionally updated in the ...const onCellValueChanged = (e: CellValueChangedEvent) => {... of Datatable
      //     instead of here (prevent incoherent database data)
      eventsManager.emit(EventType.CELL_VALUE_CHANGED, [{
        rowIndex: JSON.parse(JSON.stringify(this.originalParams.rowIndex)),
        rowData: JSON.parse(JSON.stringify(this.originalParams.data)),
        colId: 'completed',
        oldValue: false,
        newValue: true
      }, {
        rowIndex: JSON.parse(JSON.stringify(this.originalParams.rowIndex)),
        rowData: JSON.parse(JSON.stringify(this.originalParams.data)),
        colId: 'isTracked',
        oldValue: this.originalParams.data.isTracked,
        newValue: true
      }], 'DunningStatusRenderer')

      feedbackService.displayFeedback(<Feedback>{
        feedbackMessage: t('invoices.finalised'),
        feedbackLevel: 'Success'
      })
    }
    this.invoiceStartTrackingClickHandler = () => {
      // NB: event workflow from here :
      // 1- the cell value change is first requested with publishEventCellValueChangeRequested
      // 2- in the subscribe of this event, subscribeToEventCellValueChangeRequested, first : rowNode.setDataValue(e.colId, e.newValue); which triggers onCellValueChanged = (e: CellValueChangedEvent) which triggers publishEventCellValueChanged
      // 3- it publishEventCellValueChanged trigger inside subscribeToEventCellValueChanged() which postNewListOfInvoicesPromise() (i.e. saves invoices data to API) then fetchInvoices then fetchWorkspace

      // NB: isTracked will be conditionally updated in the ...const onCellValueChanged = (e: CellValueChangedEvent) => {... of Datatable
      eventsManager.emit(EventType.CELL_VALUE_CHANGED, [{
        rowIndex: JSON.parse(JSON.stringify(this.originalParams.rowIndex)),
        rowData: JSON.parse(JSON.stringify(this.originalParams.data)),
        colId: 'isTracked',
        oldValue: this.originalParams.data.isTracked,
        newValue: true
      }], 'DunningStatusRenderer')
    }
    const {
      newElementClass,
      newInnerHtml,
      completeButton,
      trackButton,
      isInvalid
    } = DunningInvoicesHelper.updateRenderedValue(params)
    this.buildElement({ newElementClass, newInnerHtml, completeButton, trackButton, isInvalid, data: params.data })
  }
  buildElement(elementToGenerate: { newElementClass: string[], newInnerHtml: string, completeButton: boolean, trackButton: boolean, isInvalid: boolean, data: any }) {
    

    if (this.statusLabel) {
      this.statusLabel.remove()
    }
    if (this.completeButton) {
      this.completeButton.removeEventListener('click', this.invoiceCompletedClickHandler)
      this.completeButton.remove()
    }
    if (this.trackButton) {
      this.trackButton.removeEventListener('click', this.invoiceStartTrackingClickHandler)
      this.trackButton.remove()
    }
    if (this.eGui) {
      while (this.eGui.firstChild) {
        this.eGui.removeChild(this.eGui.firstChild)
      }
    } else {
      this.eGui = document.createElement('div')
      this.eGui.classList.add('status-ui-element')
      this.eGui.style.width = '175px'
      this.eGui.style.position = 'relative'
    }
    if (elementToGenerate.completeButton && !elementToGenerate.isInvalid) {
      this.completeButton = document.createElement('button')
      this.completeButton.classList.add('inline-block')
      this.completeButton.classList.add('btn')
      this.completeButton.classList.add(...elementToGenerate.newElementClass)
      this.completeButton.innerHTML = elementToGenerate.newInnerHtml
      this.completeButton.addEventListener('click', (event: Event & { target }) => {

        const row = event.target.parentNode.parentNode.parentNode.parentNode.parentNode

        const confirmationContainer: HTMLDivElement = document.createElement('div')
        confirmationContainer.setAttribute('id', 'finalizeConfirmationContainer')
        confirmationContainer.style.height = row.clientHeight + 'px'
        confirmationContainer.style.width = row.clientWidth + 'px'
        confirmationContainer.style.position = 'absolute'
        confirmationContainer.style.left = '0'
        confirmationContainer.style.top = '0'
        confirmationContainer.style.background = 'white'
        confirmationContainer.style.zIndex = '99999999'

        const confirmationInnerContainer: HTMLDivElement = document.createElement('div')
        confirmationContainer.style.padding = '1%'
        confirmationInnerContainer.style.float = 'right'
        confirmationContainer.appendChild(confirmationInnerContainer)

        const confirmationText: HTMLSpanElement = document.createElement('span')
        confirmationText.innerText = t('invoices.finalizeConfirmation.text')
        confirmationInnerContainer.appendChild(confirmationText)

        const yesButton: HTMLButtonElement = document.createElement('button')
        yesButton.style.display = 'inline'
        yesButton.style.margin = '0 1em 0 1em'
        yesButton.classList.add('btn')
        yesButton.classList.add('action-primary')
        yesButton.innerText = t('invoices.finalizeConfirmation.yes')
        yesButton.addEventListener('click', () => {
          this.invoiceCompletedClickHandler()
          document.getElementById('finalizeConfirmationContainer').remove()
        })
        confirmationInnerContainer.appendChild(yesButton)

        const noButton: HTMLButtonElement = document.createElement('button')
        noButton.style.display = 'inline'
        noButton.style.margin = '0 1em 0 1em'
        noButton.classList.add('btn')
        noButton.classList.add('action-secondary')
        noButton.innerText = t('invoices.finalizeConfirmation.no')
        noButton.addEventListener('click', () => {
          document.getElementById('finalizeConfirmationContainer').remove()
        })
        confirmationInnerContainer.appendChild(noButton)

        row.appendChild(confirmationContainer)
      })
      this.eGui.appendChild(this.completeButton)
    } else if (elementToGenerate.completeButton && elementToGenerate.isInvalid) {
      this.statusLabel = document.createElement('span')
      this.statusLabel.classList.add('label')
      const draftLabel = document.createElement('span')
      draftLabel.innerText = t('invoices.states.draft')
      this.statusLabel.appendChild(draftLabel)
      this.eGui.appendChild(this.statusLabel)
    } else if (elementToGenerate.trackButton) {
      this.trackButton = document.createElement('button')
      this.trackButton.classList.add('btn')
      this.trackButton.classList.add(...elementToGenerate.newElementClass)
      this.trackButton.innerHTML = elementToGenerate.newInnerHtml
      this.invoiceStartTrackingClickHandler = this.invoiceStartTrackingClickHandler.bind(this)
      this.trackButton.addEventListener('click', this.invoiceStartTrackingClickHandler)
      if (elementToGenerate.isInvalid) {
        this.trackButton.setAttribute('disabled', 'disabled')
        this.completeButton.classList.add('action-third')
      }
      this.eGui.appendChild(this.trackButton)
    } else {
      this.statusLabel = document.createElement('span')
      this.statusLabel.classList.add('label')
      this.statusLabel.classList.add(...elementToGenerate.newElementClass)
      this.statusLabel.innerHTML = elementToGenerate.newInnerHtml
      this.eGui.appendChild(this.statusLabel)
    }
  }
  /**
     * Return the DOM element of the editor,
     * this is what the grid puts into the DOM
     */
  getGui(): HTMLDivElement {
    return this.eGui
  }
  /**
     * gets called whenever the cell refreshes
     * return true to tell the grid we refreshed successfully
     */
  refresh(params): boolean {
    
    
    const {
      newElementClass,
      newInnerHtml,
      completeButton,
      trackButton,
      isInvalid
    } = DunningInvoicesHelper.updateRenderedValue(params)
    this.buildElement({ newElementClass, newInnerHtml, completeButton, trackButton, isInvalid, data: params.data })
    
    return true
  }
  /**
     * gets called when the cell is removed from the grid
     */
  destroy(): void {
    this.eGui.removeEventListener('click', this.invoiceCompletedClickHandler)
  }
}

import { t } from '../../../../../core-app/lib/i18n/i18nextWrapper'
import type { ICellRendererParams } from 'ag-grid-community'

export default class DatatableAmountIncludingTaxesAndIssueDateRenderer {
  private params: ICellRendererParams
  private cellContent: HTMLElement
  public content: HTMLElement
  constructor() {}
  public init(params: ICellRendererParams) {
    const { data } = params
    const { amountIncludingTaxes, dateIssued } = data
    let valueDisplayed: number | bigint
    let invoiceFormatter: Intl.NumberFormat
    let dateIssuedFormatted = new Intl.DateTimeFormat(t('locales'), {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(new Date(data.dateIssued))
    if (!data.amountIncludingTaxes) {
      valueDisplayed = 0
    } else {
      valueDisplayed = data.amountIncludingTaxes
    }
    this.cellContent = document.createElement('span')
    this.content = this.cellContent

    try {
      if (params.data.currency_code) {
        invoiceFormatter = new Intl.NumberFormat(t('locales'), {
          style: 'currency',
          currency: params.data.currency_code,
          currencyDisplay: 'code'
        })
      } else if (params.data.currency) {
        invoiceFormatter = new Intl.NumberFormat(t('locales'), {
          style: 'currency',
          currency: params.data.currency,
          currencyDisplay: 'code'
        })
      }
    } catch (err) {
      invoiceFormatter = new Intl.NumberFormat(t('locales'), {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'code'
      })
      
    }
    this.cellContent = document.createElement('span')
    this.content = this.cellContent
    this.content.classList.add('cash-app-company-invoice')
    this.content.innerHTML = `
            <div class="flex flex-col w-full justify-end items-center">
                <div class="font-bold leading-normal">${ invoiceFormatter.format(valueDisplayed) }</div>
                <div class="leading-normal text-zinc-500">${ dateIssuedFormatted }</div>
            </div>
        `
  }
  public getGui() {
    return this.cellContent
  }
  public destroy() {}
}

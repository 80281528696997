import type { Contact } from '../models/contact'
import { v4 as uuidv4 } from 'uuid'
import { ExchangeDate } from '../../core-app/models/exchange-date'

export class ContactsHelper {
  static newContact(companyId?: string): Contact {
    return <Contact>{
      contactId: uuidv4(),
      companyId: companyId ? companyId : '',
      firstName: '',
      lastName: '',
      department: '',
      email: '',
      officePhone: '',
      mobilePhone: '',
      phone: '',
      position: '',
      createdDate: ExchangeDate.newDate(new Date()),
      modifiedDate: ExchangeDate.newDate(new Date())
    }
  }
}
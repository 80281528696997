/**
 * Fallback to old school copy to clipboard
 * @param text
 * @returns {void}
 */
function fallbackCopyTextToClipboard(text): void {
  let textArea: HTMLTextAreaElement = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    let successful: boolean = document.execCommand('copy')
    /*let msg: string = successful ? 'successful' : 'unsuccessful'
     console.log('Fallback: Copying text command was ' + msg) */
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}

/**
 * Copy text to clipboard
 * First try with navigator.clipboard, fallback to old school copy to clipboard
 * @param text
 * @returns {void}
 */
export function copyTextToClipboard(text): void {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)

    return
  }
  navigator.clipboard.writeText(text).then(function (): void {
    /* console.log('Async: Copying to clipboard was successful!') */
  }, function (err): void {
    console.error('Async: Could not copy text: ', err)
  })
}

import { JsonProperty } from '@paddls/ts-serializer'
import Company from './company'

export class Customer {
  @JsonProperty('company') company: Company
  @JsonProperty('isPrivateIndividual') isPrivateIndividual: boolean
  static readonly empty = (idCompany?:string, privateIndividual?:boolean):Customer => ({
    company: Company.empty(idCompany),
    isPrivateIndividual: privateIndividual ?? false
  })
}

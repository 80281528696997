<script lang="ts">
  import { feedbackService } from '$src/core-app/services/feedback.service'
  import { navigate } from 'svelte-routing'
  import { onMount } from 'svelte'
  import { removeTemplate, getAllTemplate, duplicateTemplate } from '../services/template.service'
  import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
  import PageWithGrid from '$src/shared/components/templates/PageWithGrid.svelte'
  import Template from './Template.svelte'
  import type { Feedback } from '$src/core-app/models/feedback'


  const columns = [
    { name: t('customerList.table.name'), path: 'name' },
    { name: t('customerList.table.type'), path: 'media' }
  ]

  let rows:any[] = []

  $:txtCountDescription = t(rows.length < 2
    ? 'template.countDescription_one'
    : 'template.countDescription_more', { count: rows.length })

  const toast = (type:string, message:string) => feedbackService.displayFeedback({
    feedbackMessage: message,
    feedbackLevel: type
  } as Feedback)

  const onTemplateSelect = () => {}

  const onTemplateEdit = (template:Template) => navigate(`/template/edit/${template.id}`)

  const onTemplateDuplicate = async (template:Template) => {
    const error = await duplicateTemplate(template.id)
    if (!error) {
      toast('Success', t('template.duplicateFeedback'))
    } else {
      toast('Error', error)
    }
  }

  const onTemplateDelete = async (template:Template) => {
    const error = await removeTemplate(template.id)
    if (!error) {
      toast('Success', t('template.deleteFeedback'))
    } else {
      toast('Error', error)
    }
  }

  onMount(async () => {
    rows = await getAllTemplate()
  })
</script>

<PageWithGrid
  title={t('topMenu.nav.settings.templates')}
  addButtonLink={'/templates/new'}
  addButtonValue={t('actions.add')}
  footerClass=''
  allowActions={true}
  contextName='template'
  searchPath='name'
  {txtCountDescription}
  {columns}
  {rows}
  on:select={() => onTemplateSelect()}
  on:edit={(e) => onTemplateEdit(e.detail)}
  on:duplicate={(e) => onTemplateDuplicate(e.detail)}
  on:delete={(e) => onTemplateDelete(e.detail)}
 />
<script lang="ts">
    import { t } from '../../core-app/lib/i18n/i18nextWrapper'
    import { createEventDispatcher, onMount } from 'svelte'
    import {
      formatIBANForDisplayByRemovingSpacesThenInsertingSpaces,
      isValidIBAN
    } from '../helpers/iban-operations'
    import { expoOut } from 'svelte/easing'
    import { deepClone } from '../../core-app/util/object-deep-cloning'
    import { fly } from 'svelte/transition'
    import { navigate } from 'svelte-routing'
    import { WorkspaceBankConfig } from '../models/workspace-bank-config'
    import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
    import { feedbackService } from '../../core-app/services/feedback.service'
    import { Feedback } from '../../core-app/models/feedback'
    import SingleBankAccountDetailsForm from './SingleBankAccountDetailsForm.svelte'
    import SingleBankAccountHeader from './SingleBankAccountHeader.svelte'
    import SingleBankAccountConnectContent from './SingleBankAccountConnectContent.svelte'
    import { get } from 'svelte/store'
    import { Workspace } from '../../crm-app/models/workspace'

    /** Export let */
    export let loadingData: boolean = true
    export let refreshData: boolean

    /** Local Declarations */
    let localBankConfig: WorkspaceBankConfig = <WorkspaceBankConfig>{}
    let formattedIBAN: string = ''
    let hasChanged: boolean = false
    let showSingleBankAccountConnectContentBoolean: boolean = false
    let showSingleBankAccountHeaderBoolean: boolean = false
    let showConnectToAnotherBankAccountBoolean: boolean = false
    let showChooseAnotherBankAccountBoolean: boolean = false
    let showRemoveBankConnectionConsentBoolean: boolean = false

    $:{
      if(refreshData){
        refreshData = false
        console.error("refresh data")
        refreshUIRelatedData()
      }
    }

    /** Functions declarations */
    const dispatch = createEventDispatcher()

    const isBankConnectionSuccessfulButSpecificBankAccountNotSelected = (): boolean => {
      return !!$WorkspaceStore?.bankConfig?.bankConnectionCurrentlyKnownActive && (($WorkspaceStore?.bankConfig?.selectedAccountId || '') === '')
    }

    const showSingleBankAccountConnectContent = (): boolean => {
      return !$WorkspaceStore?.bankConfig?.bankConnectionCurrentlyKnownActive
    }

    const showSingleBankAccountHeader = (): boolean => {
      return !!$WorkspaceStore?.bankConfig?.bankConnectionCurrentlyKnownActive
    }

    const showConnectToAnotherBankAccount = (): boolean => {
      return true // always
    }

    const showChooseAnotherBankAccount = (): boolean => {
      return $WorkspaceStore?.bankConfig?.bankConnectionCurrentlyKnownActive || false
    }

    const showRemoveBankConnectionConsent = (): boolean => {
      return $WorkspaceStore?.bankConfig?.bankConnectionCurrentlyKnownActive || false
    }

    // editBank.addBankAccount
    const executeConnectToAnotherBank = () => {
      navigate('connect-new')
    }

    const executeChooseAnotherBankAccount = () => {
      navigate('select-account')
    }

    const executeRemoveBankConnectionConsent = () => {
      dispatch('displayDeleteBankConnectionConfirmation')
    }

    const handleManualDetailsSubmit = () => {
      if (localBankConfig.selectedAccountBankBIC
          && localBankConfig.selectedAccountBankIBAN
          && localBankConfig.selectedAccountBankName
      ) {
        // TODO : check if IBAN is valid
        if (isValidIBAN(localBankConfig.selectedAccountBankIBAN)) {
          WorkspaceStore.update((ws: Workspace) => {
            ws.bankConfig = localBankConfig

            return ws
          })
          feedbackService.displayFeedback(<Feedback>{
            feedbackLevel: 'Success',
            feedbackMessage: t('editBank.detailsSaved')
          })
        } else {
          feedbackService.displayFeedback(<Feedback>{
            feedbackLevel: 'Error',
            feedbackMessage: t('editBank.invalidIBAN')
          })

          return
        }
      } else {
        feedbackService.displayFeedback(<Feedback>{
          feedbackLevel: 'Error',
          feedbackMessage: t('editBank.incompleteDetails')
        })
      }
      hasChanged = false
    }

    const refreshUIRelatedData = () => {
      localBankConfig = !!((get(WorkspaceStore)).bankConfig) ? deepClone((get(WorkspaceStore)).bankConfig) : <WorkspaceBankConfig>{}
      if (!!(get(WorkspaceStore)).bankConfig.selectedAccountBankIBAN) {
        try {
          formattedIBAN = formatIBANForDisplayByRemovingSpacesThenInsertingSpaces((get(WorkspaceStore).bankConfig).selectedAccountBankIBAN)
        } catch (e) {
          console.error(e)
        }
      }
      setTimeout(() => {
        loadingData = false
        showSingleBankAccountConnectContentBoolean = showSingleBankAccountConnectContent()
        showSingleBankAccountHeaderBoolean = showSingleBankAccountHeader()
        showConnectToAnotherBankAccountBoolean = showConnectToAnotherBankAccount()
        showChooseAnotherBankAccountBoolean = showChooseAnotherBankAccount()
        showRemoveBankConnectionConsentBoolean = showRemoveBankConnectionConsent()
      }, 500)
    }

    const handleCancelEditing = () => {
      localBankConfig = deepClone((get(WorkspaceStore)).bankConfig)
      hasChanged = false
    }

    onMount(() => {
      refreshUIRelatedData()
    })

</script>

<div class="w-full rounded bg-white border border-athensGray mt-6">
    {#if showSingleBankAccountConnectContentBoolean}
        <SingleBankAccountConnectContent/>
    {/if}

    <div class="w-full">
        <div class="flex flex-col justify-between">
            {#if showSingleBankAccountHeaderBoolean}
                <SingleBankAccountHeader
                        bind:localBankConfig
                        bind:formattedIBAN/>
            {/if}
            <SingleBankAccountDetailsForm
                    bind:localBankConfig
                    bind:hasChanged
                    bind:formattedIBAN
            />
            <div class="border-b border-t border-zinc-200  sm:px-16 py-6 bg-whisper">
                {#if hasChanged}
                    <div class="flex justify-end space-x-5"
                         in:fly={{ duration: 500, easing: expoOut, x: 0, y: 25 }}
                         out:fly={{ duration: 100, easing: expoOut, x: 0, y: 25 }}>
                        <button class="btn action-cancel"
                                on:click={()=> handleCancelEditing()}>
                            {t('editBank.cancel')}
                        </button>
                        <button class="btn action-bla primary"
                                on:click={()=> handleManualDetailsSubmit()}>
                            {t('editBank.save')}
                        </button>
                    </div>
                {:else}
                    <div class="flex justify-between space-x-4">
                        {#if showConnectToAnotherBankAccountBoolean}
                            <div class="flex items-center hover:bg-white py-1.5 px-2 rounded-md cursor-pointer"
                                 on:click|stopPropagation|preventDefault={() => executeConnectToAnotherBank()}>
                                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round"
                                          stroke-linejoin="round" stroke-width="1.5"
                                          d="M18.25 11.5V19.25M5.75 19.25V11.5M9.75 19.25V11.5M14.25 19.25V11.5"></path>
                                    <path stroke="currentColor" stroke-linecap="round"
                                          stroke-linejoin="round" stroke-width="1.5"
                                          d="M12 4.75L19.25 11.25H4.75L12 4.75Z"></path>
                                    <path stroke="currentColor" stroke-linecap="round"
                                          stroke-linejoin="round" stroke-width="1.5"
                                          d="M4.75 19.25H19.25"></path>
                                </svg>
                                <span class="text-sm text-black font-medium ml-2">{t('editBank.addBankAccount')}</span>
                            </div>
                        {/if}
                        {#if showChooseAnotherBankAccountBoolean}
                            <div class="flex items-center hover:bg-white py-1.5 px-2 rounded-md cursor-pointer"
                                 on:click|stopPropagation|preventDefault={() => executeChooseAnotherBankAccount()}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.75 7.75C4.75 6.64543 5.64543 5.75 6.75 5.75H17.25C18.3546 5.75 19.25 6.64543 19.25 7.75V16.25C19.25 17.3546 18.3546 18.25 17.25 18.25H6.75C5.64543 18.25 4.75 17.3546 4.75 16.25V7.75Z"
                                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                          stroke-linejoin="round"></path>
                                    <path d="M5 10.25H19" stroke="currentColor" stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M7.75 14.25H10.25" stroke="currentColor" stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M15.75 14.25H16.25" stroke="currentColor" stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                                <span class="text-sm text-black font-medium ml-2">{t('editBank.switchAccount')}</span>
                            </div>
                        {/if}
                        {#if showRemoveBankConnectionConsentBoolean}
                            <div class="flex items-center hover:bg-white py-1.5 px-2 rounded-md cursor-pointer"
                                 on:click|stopPropagation|preventDefault={() => { executeRemoveBankConnectionConsent()} }>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.75 7.75L6.59115 17.4233C6.68102 18.4568 7.54622 19.25 8.58363 19.25H14.4164C15.4538 19.25 16.319 18.4568 16.4088 17.4233L17.25 7.75H5.75Z"
                                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                          stroke-linejoin="round"></path>
                                    <path d="M9.75 10.75V16.25" stroke="currentColor" stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M13.25 10.75V16.25" stroke="currentColor" stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M8.75 7.75V6.75C8.75 5.64543 9.64543 4.75 10.75 4.75H12.25C13.3546 4.75 14.25 5.64543 14.25 6.75V7.75"
                                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                          stroke-linejoin="round"></path>
                                    <path d="M4.75 7.75H18.25" stroke="currentColor" stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>

                                <span class="text-sm text-black font-medium ml-2">{t('editBank.deleteBankAccount')}</span>
                            </div>
                        {/if}
                    </div>
                {/if}
            </div>
        </div>
    </div>
    <div class="flex flex-row w-full p-6 text-xxs text-paleSky">
        <i class="icon-lock text-3xl inline-block text-dundyOrange"></i>
        <div class="flex flex-col relative left-4 bottom-1">
            <span>{t('editBank.dontStore1')}</span>
            <span>{t('editBank.dontStore2')}</span>
        </div>
    </div>
</div>

const config = {
  domain: process.env.AUTH0_TENANT_DOMAIN, // "dev-dundy.eu.auth0.com",
  clientId: process.env.AUTH0_TENANT_CLIENT_ID, // "uOul47Okz9zHtjmeU2UwHNRrUz6OyEdr"
  clientDatabaseConnection: process.env.AUTH0_CLIENT_DB_CONNECTION, // "dundy-co-ui-app-local-db"
  apiAudience: process.env.AUTH0_TENANT_API_AUDIENCE, // 'https://api.dundy.co/madndy',
  universalAudience: process.env.AUTH0_TENANT_UNIVERSAL_AUDIENCE, // 'https://dev-dundy.eu.auth0.com/api/v2/'
  createClientRedirectUri: process.env.APP_BASE_URL,
  universalLoginRedirectUri: process.env.APP_BASE_URL + '/logincb',
  logoutRedirectUri: process.env.APP_BASE_URL + '/signin?no-logout-first',
  loginFinalRedirect: process.env.APP_BASE_URL + '/hello'
}

export default config

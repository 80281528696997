<script lang="ts">
  import { onMount } from 'svelte'
  import { DunningInvoicesStore } from '../stores/dunning-invoices.store'
  import { TodosStore } from '../stores/todos.store'
  import type Invoice from '../models/invoice'
  import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
  import { getDunningWorkflow } from '../services/dunning-workflow.service'
  import type { TodoListItem } from '../models/todo-list-item'
  import { workflowSteps } from '../data/workflow'
  import Loader from '$src/core-app/lib/ui-kit/Loader.svelte'
  import TemplateEditor from '../components/template/TemplateEditor.svelte'

  export let params:any

  let from:string = ''
  let loadingTemplate:boolean = false

  let dunningWorkflowPreference:any = {}

  $:invoice = $DunningInvoicesStore.find(invoiceItem => invoiceItem.dataId === params.invoiceId) || {} as Invoice
  $:todo = $TodosStore.find(todoItem => todoItem.scopeInvoiceNumbers.some(s => s === invoice?.invoiceNumber)) || {} as Invoice

  const getWorkflowStep = (item:TodoListItem) => {
    if (!item) return

    let step = ''
    switch (item.actionKind) {
      case 'InvoiceIssuance':case 'InvoiceIssuance':
        step = 'STEP00_ISSUE_BUSINESS_DOCUMENT'
        break
      case 'NoActionAtThisStage':
        step = 'STEP01_BETWEEN_ISSUANCE_AND_FIRST_REMINDER'
        break
      case 'CourtesyReminderShortlyBeforeDueDate':
        step = 'STEP02_FIRST_REMINDER_BEFORE_DUE_DATE'
        break
      case 'ReminderJustAfterDueDate':
        if (item.escalation === 'CourtesyReminder' && item.dunningAction === 'InformationAboutOverdueInvoice') step = 'STEP03_FIRST_REMINDER_AFTER_DUE_DATE' 
        if (item.escalation === 'FirstReminder' && item.dunningAction === 'GentleReminderToPay') step = 'STEP04_SECOND_REMINDER_AFTER_DUE_DATE' 
        break
      case 'ReminderShortlyAfterDueDate':
        step = 'STEP05_THIRD_FIRM_REMINDER_AFTER_DUE_DATE'
        break
      case 'DirectReminderSomeTimeAfterDueDate':
        step = 'STEP06_FOURTH_REMINDER_BY_PHONE_AFTER_DUE_DATE'
        break
      case 'StrongLastReminderAfterDueDate':
        step = 'STEP07_FIFTH_REMINDER_LAST_CALL_BEFORE_LEGAL_ACTIONS'
        break
      case 'CollectionLitigation':
        step = 'STEP08_LEGAL_ACTIONS'
        break
    }

    if (step) return workflowSteps.find(s => s.id === step)
  }

  $:workflowStep = todo ? getWorkflowStep(todo as TodoListItem) : null

  onMount(() => {
    // url origin
    const searchParams = new URLSearchParams(window.location.search)
    from = searchParams.get('from') || ''

    // // customer language
    // getDunningWorkflow(params.customerId).then(async res => {
    //   dunningWorkflowPreference = res.workflow || {}

    //   loadingTemplate = false
    //   //   const template = await getDunningTemplate(dunningWorkflowPreference.prefItem.defaultDunningWorkflowKind, workflowStep.name)
    //   //   console.log('tempalte', template)
    //   // } else {
    //   //   alert('ERROR workflowStep')

    // })
    
  })

</script>

<svelte:head>
  <style lang="postcss">
    body {
      @apply bg-whisper;
    }
  </style>
</svelte:head>

<main class="h-[calc(100vh-8rem)] flex flex-col mx-6">

  <div class="w-full px-6 py-4 panel">
    {#if from === 'invoice'}
      <a href="/receivables/view/{params.invoiceId}/details" class="text-dundyOrange text-sm">&#8592; Retour à la facture</a>
    {:else if from === 'todo'}
      <a href="/dunning" class="text-dundyOrange text-sm">&#8592; Retour aux relances</a>
    {/if}

    <div class="">
      <div>Facture {invoice.invoiceNumber}</div>
      <div>{invoice.toFinalCustomer.formalName}</div>
      <div>{-1 * todo.maxDueDays} jour(s) de retard</div>
      <div>Date emission : 
        {new Intl.DateTimeFormat(t('locales'), {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }).format(new Date(invoice?.dateIssued ? invoice?.dateIssued : (new Date).toISOString()))}
      </div>
      <div>
        Echéance
        {new Intl.DateTimeFormat(t('locales'), {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }).format(new Date(invoice?.dateDue ? invoice?.dateDue : (new Date).toISOString()))}
      </div>
      <div>
        Montant TTC
        <!-- {formatCurrencyWithOptions(Amount.PrototypeToClass(todo?.totalScopeAmountOfMoneyDue).GetAmountScaledValue(), t('locales'), invoice?.currency, true, 'symbol')} -->
      </div>

      <div class="mt-4 text-red-500">TODO : attente design</div>
    </div>

    <!-- <pre style="font-size:10px">{JSON.stringify(params, null, 2)}</pre>
    <pre style="font-size:10px">{JSON.stringify(invoice, null, 2)}</pre> -->
    <!-- <pre style="font-size:10px">{JSON.stringify(todo, null, 2)}</pre> -->
    
  </div>

  <div class="h-full flex mt-6">

    {#if workflowStep && !loadingTemplate}
      <TemplateEditor
        hideMedia={true}
        hideLanguage={true}
        isEditing={false}
        stepId={workflowStep.id}
        customerId={params.customerId}
      />
    {:else}
      <div class="w-4/6 px-6 py-4 panel flex items-center justify-center">
        <Loader />
      </div>
    {/if}

    <div class="ml-2 w-2/6 panel">
      <button class="btn action-bla primary" on:click={() => alert('todo')}>
        TEST MAIL
      </button>
    </div>
  </div>

</main>

<style lang="postcss">
  .panel {
    @apply px-6 py-4 bg-white border border-athensGray rounded;
  }
</style>
import { AppliedDunningFilters } from '../../../models/applied-dunning-filters'
import { type AgingBalanceContent } from '../../../models/aging-balance'

/**
 * Initialize / configure the dunning filters
 * @param totalAmountsForAllClientsAndTimeSlots
 */
export const createDunningFilters = (totalAmountsForAllClientsAndTimeSlots: AgingBalanceContent): AppliedDunningFilters => ({
  checkboxFilterMoreThan90TillDue: {
    id: 'checkboxFilterMoreThan90TillDue',
    index: 0,
    value: false,
    totalAmount: totalAmountsForAllClientsAndTimeSlots.dueMoreThan90dInvoicesAmount,
    color: 'bg-greenHaze',
    isFirstVisible: false,
    isLastVisible: false
  },
  checkboxFilterBetween61And90DaysTillDue: {
    id: 'checkboxFilterBetween61And90DaysTillDue',
    index: 1,
    value: false,
    totalAmount: totalAmountsForAllClientsAndTimeSlots.due61And90dInvoicesAmount,
    color: 'bg-persianGreen',
    isFirstVisible: false,
    isLastVisible: false
  },
  checkboxFilterBetween31And60DaysTillDue: {
    id: 'checkboxFilterBetween31And60DaysTillDue',
    index: 2,
    value: false,
    totalAmount: totalAmountsForAllClientsAndTimeSlots.due31And60dInvoicesAmount,
    color: 'bg-bermuda',
    isFirstVisible: false,
    isLastVisible: false
  },
  checkboxFilterBetween0And30DaysTillDue: {
    id: 'checkboxFilterBetween0And30DaysTillDue',
    index: 3,
    value: false,
    totalAmount: totalAmountsForAllClientsAndTimeSlots.due0And30dInvoicesAmount,
    color: 'bg-cruise',
    isFirstVisible: false,
    isLastVisible: false
  },
  checkboxFilterBetween1And30DaysOverdue: {
    id: 'checkboxFilterBetween1And30DaysOverdue',
    index: 4,
    value: false,
    totalAmount: totalAmountsForAllClientsAndTimeSlots.overdue30And1dInvoicesAmount,
    color: 'bg-creamBrulee',
    isFirstVisible: false,
    isLastVisible: false
  },
  checkboxFilterBetween31And60DaysOverdue: {
    id: 'checkboxFilterBetween31And60DaysOverdue',
    index: 5,
    value: false,
    totalAmount: totalAmountsForAllClientsAndTimeSlots.overdue31And60dInvoicesAmount,
    color: 'bg-yellowOrange',
    isFirstVisible: false,
    isLastVisible: false
  },
  checkboxFilterBetween61And90DaysOverdue: {
    id: 'checkboxFilterBetween61And90DaysOverdue',
    index: 6,
    value: false,
    totalAmount: totalAmountsForAllClientsAndTimeSlots.overdue61And90dInvoicesAmount,
    color: 'bg-hotCinnamon',
    isFirstVisible: false,
    isLastVisible: false
  },
  checkboxFilterMoreThan90DaysOverdue: {
    id: 'checkboxFilterMoreThan90DaysOverdue',
    index: 7,
    value: false,
    totalAmount: totalAmountsForAllClientsAndTimeSlots.overdueMoreThan90dInvoicesAmount,
    color: 'bg-mojo',
    isFirstVisible: false,
    isLastVisible: false
  },
  checkboxFilterAmountLessThan500: { id: 'checkboxFilterAmountLessThan500', index: 8, value: false, totalAmount: 0, color: '', isFirstVisible: false, isLastVisible: false },
  checkboxFilterAmountBetween500And5000: { id: 'checkboxFilterAmountBetween500And5000', index: 9, value: false, totalAmount: 0, color: '', isFirstVisible: false, isLastVisible: false },
  checkboxFilterAmountBetween5000And20000: { id: 'checkboxFilterAmountBetween5000And20000', index: 10, value: false, totalAmount: 0, color: '', isFirstVisible: false, isLastVisible: false },
  checkboxFilterAmountBetween20000And75000: { id: 'checkboxFilterAmountBetween20000And75000', index: 11, value: false, totalAmount: 0, color: '', isFirstVisible: false, isLastVisible: false },
  checkboxFilterAmountBetween75000And150000: { id: 'checkboxFilterAmountBetween75000And150000', index: 12, value: false, totalAmount: 0, color: '', isFirstVisible: false, isLastVisible: false },
  checkboxFilterAmountMoreThan150000: { id: 'checkboxFilterAmountMoreThan150000', index: 13, value: false, totalAmount: 0, color: '', isFirstVisible: false, isLastVisible: false }
})
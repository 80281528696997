<script lang="ts">
  import { HistoryStore } from '$voxy/stores/business-documents.store'
  import { onMount } from 'svelte'
  import Components from './Components.svelte'
  import Journey from './Journey.svelte'
  import Stores from './Stores.svelte'

  let menuDisplay = 'stores'

  const menu = [{
    key: 'stores',
    value: 'Stores'
  }, {
    key: 'components',
    value: 'Components'
  },
  {
    key: 'history',
    value: 'History Store'
  },
  {
    key: 'journey',
    value: 'Journey'
  }]

  onMount(async () => { })
</script>

<main>
    <h1>TECHNICAL SANDBOX</h1>

    <div class="menu">
      {#each menu as m}
        <button class="{menuDisplay === m.key ? 'focus' : ''} mr-2" on:click={() => menuDisplay = m.key}>{m.value}</button>
      {/each}
    </div>

    <div class="{menuDisplay === 'stores' ? 'block' : 'none'}">
        <Stores/>
    </div>
    <div class="{menuDisplay === 'components' ? 'block' : 'none'}">
        <Components/>
    </div>
    <div class="{menuDisplay === 'history' ? 'block' : 'none'}">
      <pre>
        {JSON.stringify($HistoryStore.map(d => [...d.keys()].map(k => `${[k]} - ${[d.get(k).state]}`)), null, 2)}
      </pre>
    </div>
    <div class="{menuDisplay === 'journey' ? 'block' : 'none'}">
        <Journey/>
    </div>

</main>

<style>
    main {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 1rem;
    }

    h1 {
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
        margin: 1rem 0;
    }

    .menu {
        text-align: center;
        margin-bottom: 2rem;
    }

    button {
        color: white;
        background: #00ADA2;
        padding: 0.5rem 1rem;
    }

    button.focus {
        border-bottom: 3px solid #d9311b;
        padding-bottom: 0.3rem;
    }

    .block {
        display: block;
    }

    .none {
        display: none
    }
</style>

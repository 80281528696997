import type { ICellRendererParams } from 'ag-grid-community'

export default class NumberEditor {
  eGui: HTMLDivElement
  eInput: HTMLInputElement
  cancelBeforeStart: boolean
  // gets called once before the renderer is used
  init(params: ICellRendererParams): void {
    // create the cell
    this.eInput = document.createElement('input')
    this.eInput.classList.add('ag-input-field-input', 'ag-text-field-input')

    const div: HTMLDivElement = document.createElement('div')
    div.classList.add(
      'ag-wrapper',
      'ag-input-wrapper',
      'ag-text-field-input-wrapper',
    )
    div.append(this.eInput)

    const div_wrapper: HTMLDivElement = document.createElement('div')
    div_wrapper.classList.add(
      'ag-cell-editor',
      'ag-text-field',
      'ag-input-field',
    )
    div_wrapper.append(div)

    this.eGui = document.createElement('div')
    this.eGui.classList.add('ag-cell-edit-wrapper')
    this.eGui.append(div_wrapper)

    let inputValue: string = '0' // Default value

    // Check if value is a number
    if (typeof params.value === 'number' && !Number.isNaN(params.value)) {
      inputValue = params.value.toString()
    }

    this.eInput.value = inputValue
    this.eInput.select()

    this.eInput.addEventListener('keypress', (event: KeyboardEvent): void => {
      if (!this.isKeyPressedNumeric(event)) {
        this.eInput.focus()
        if (event.preventDefault) event.preventDefault()
      } else if (this.isKeyPressedNavigation(event)) {
        event.stopPropagation()
      }
    })

    this.cancelBeforeStart = false
  }
  /**
     * Returns true if the key pressed is a navigation key
     * @param event
     */
  isKeyPressedNavigation(event: KeyboardEvent): boolean {
    return event.key === 'ArrowRight' || event.key === 'ArrowLeft'
  }
  /**
     * Gets called once when grid ready to insert the element
     */
  getGui(): HTMLDivElement {
    return this.eGui
  }
  /**
     * Focus and select can be done after the gui is attached
     */
  afterGuiAttached(): void {
    this.eInput.focus()
  }
  /**
     * Returns the new value after editing
     */
  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart
  }
  isCancelAfterEnd(): boolean {
    return false
  }
  /**
     * Returns the new value after editing
     */
  getValue(): string {
    // filter spaces out and decimal to point
    
    
    this.eInput.value = (this.eInput.value).replaceAll('+', '').replaceAll(' ', '').replaceAll(',', '.')
    if (Number.isNaN(Number(this.eInput.value)) || this.eInput.value === '') {
      this.eInput.value = '0'
    }
    this.eInput.value = this.removeDecimalsBeyondHundredth(this.eInput.value).toString()
    
    return this.eInput.value
  }
  /**
     * Removes decimals beyond hundredth
     * @param valueAsString
     */
  removeDecimalsBeyondHundredth(valueAsString: string): string | number {
    const valueAsNumber: number = Number(valueAsString)
    if (Number.isNaN(valueAsNumber)) {
      return 0
    }
    // decimalsAsNumber=(valueAsNumber % 1).toFixed(2); // beyond 9 decimal digits we discard anyway
    const finalValueWithTwoDecimalsMaximum: string = valueAsNumber.toFixed(2)
    
    return finalValueWithTwoDecimalsMaximum.toString()
  }
  /**
     * Gets called once by grid after editing is finished
     * any cleanup we need to be done here
      */
  destroy(): void {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
  }
  /**
     * // if true, then this editor will appear in a popup
     */
  isPopup(): boolean {
    // and we could leave this method out also, false is the default
    return false
  }
  /**
     * Returns the Unicode code point of the first character in the key property of the event
     * @param event
     */
  getCharCodeFromEvent(event: KeyboardEvent): number {
    return event.key.charCodeAt(0)
  }
  /**
     * Returns true if the string passed is a number
     * @param charStr
     */
  isCharNumeric(charStr: string): boolean {
    return '0123456789 .,-+'.indexOf(charStr) > -1
  }
  /**
     * Returns true if the key pressed is a number
     * @param event
     */
  isKeyPressedNumeric(event: KeyboardEvent): boolean {
    const charCode: number = this.getCharCodeFromEvent(event)
    const charStr: string = String.fromCharCode(charCode)
    
    return this.isCharNumeric(charStr)
  }
}
